angular.module('app.shared.ui')
  .controller('VideoModalController', function ($scope, $element, $attrs, $parse, VideoModalService) {
    var _this = this;
    var getIsOpen = null;
    var setIsOpen = null;
    var modal = $scope.$new();

    _this.toggleElements = [];

    _this.init = function () {
      if ($attrs.isOpen) {
        getIsOpen = $parse($attrs.isOpen);
        setIsOpen = getIsOpen.assign;

        $scope.$watch(getIsOpen, function (value) {
          console.debug('getIsOpen Changed: value =', value);
          modal.isOpen = !!value;
        });
      }
    };

    _this.toggle = function (open) {
      modal.isOpen = arguments.length ? !!open : !modal.isOpen;
      return modal.isOpen;
    };

    _this.isOpen = function () {
      return modal.isOpen;
    };

    _this.addToggleElement = function (element) {
      _this.toggleElements.push(element);
    };

    modal.getElement = function () {
      return $element;
    };

    modal.getToggleElements = function () {
      return _this.toggleElements;
    };

    modal.getVideoElement = function () {
      return _this.videoElement;
    };

    modal.$watch('isOpen', function (isOpen, wasOpen) {
      if (isOpen) {
        VideoModalService.open(modal);
      } else {
        VideoModalService.close(modal);
      }

      if (angular.isFunction(setIsOpen)) {
        setIsOpen($scope, isOpen);
      }
    });

    $scope.$on('$locationChangeSuccess', function () {
      modal.isOpen = false;
    });

    //
    // Destroy child scope 'modal' when the parent scope goes away.
    //
    var offDestroy = $scope.$on('$destory', function () {
      modal.$destroy();
    });

    //
    // Remove the $destroy listener if the child scope goes away.
    //
    modal.$on('$destroy', offDestroy);
  });

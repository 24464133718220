(function () {
  'use strict';
  
  angular
  .module('sowReports')
  .controller('MedicationListReportController', MedicationListReportController);
  
  function MedicationListReportController ($scope, $filter, $rootScope, errorService, sowMedicationService) {
    /*jshint validthis: true */
    var ctrl = this;
    
    ctrl.products = [];
    ctrl.displayProducts = [];
    ctrl.sortOrder = 'name';
    ctrl.searchText = '';
    ctrl.state = 'report';
    var t_today = $filter('translate')('TIME.TODAY');
    var t_this_year = $filter('translate')('TIME.THIS_YEAR');
    var t_all_time = $filter('translate')('TIME.ALL_TIME');
    // ctrl.selectedRange = 'This Year';
    // ctrl.rangeOptions = ['This Year', 'All Time'];
    ctrl.updateRange = updateRange;
    ctrl.update_filter = update_filter;
    
    initialize();
    
    return ctrl;
    
    //
    // Initialize the Controller
    //
    function initialize () {
      updateRange();
      init_range();
      generate_years();
      
      sowMedicationService.getMedications({has_actions: 1})
      .then(function(medications){
        ctrl.medications = medications;
        
        if (ctrl.medications.length < 1) {
          ctrl.state = 'empty';
        } else {
          ctrl.state = 'report';
        }
        update_filter();
        // updateRangeLabels();
      }).catch(function(error){
        ctrl.state = 'error';
        errorService.uiErrorHandler(error);
      });
    }
    
    function init_range(){
      if (ctrl.selected_year) {
        ctrl.start_of_year = moment().year(ctrl.selected_year).startOf('year');
        ctrl.end_of_year = moment().year(ctrl.selected_year).endOf('year');
      } else{
        ctrl.selected_year = moment().format("YYYY");
        ctrl.start_of_year = moment().startOf('year');
        ctrl.end_of_year = moment();
      }
    }
    
    function generate_years () {
      ctrl.years_list = [];
      
      ctrl.office_first_date = moment.utc(_.get($rootScope, 'current_office.medications_start_date'));
      var first_year = ctrl.office_first_date.format("YYYY") * 1;
      var current_year = moment.utc().format("YYYY") * 1;
      
      for (var i = first_year; i <= current_year; i++) {
        ctrl.years_list.push(i+'');
      }
    }
    
    //
    // Update the list of products/rows to display based on the filter
    // parameters.
    //
    function update_filter () {
      init_range();
      ctrl.displayProducts = _.filter(ctrl.medications, filterRow);
    }
    
    //
    // Filter a row/product by the search text
    //
    function filterRow (row) {
      var lcSearchText = (ctrl.searchText || '').toLowerCase();
      var lcProductName = (row.name || '').toLowerCase();
      var lcManufacturerName = (row.manufacturer_name || '').toLowerCase();
      
      if(ctrl.selected_year){
        return (lcProductName.includes(lcSearchText) ||
          lcManufacturerName.includes(lcSearchText)
          ) && moment(row.created_at).isBetween(ctrl.start_of_year, ctrl.end_of_year);
      }
        
      return (
        lcProductName.includes(lcSearchText) ||
        lcManufacturerName.includes(lcSearchText)
        );
    }
        
    //
    // Update Data for the New Date Range
    //
    function updateRange () {
      ctrl.state = 'loading';
      
      if (ctrl.selectedRange === 'This Year') {
        ctrl.startMonth = moment().startOf('year');
        ctrl.endMonth = moment();
      }
      
      else if (ctrl.selectedRange === 'All Time') {
        var currentOffice = $scope.$root.current_office;
        ctrl.startMonth = moment(currentOffice.created_at);
        ctrl.endMonth = moment();
      }
      
      else {
        ctrl.startMonth = null;
        ctrl.endMonth = null;
        return;
      }
      
      
    }
    
    //
    // Update The Display Labels for the Date Range
    //
    function updateRangeLabels () {
      var format = 'MMMM YYYY';
      
      ctrl.startMonthLabel = (
        moment.isMoment(ctrl.startMonth) ? ctrl.startMonth.format(format)
        : ctrl.startMonth instanceof Date ? moment(ctrl.startMonth).format(format)
        : null
      );
        
      ctrl.endMonthLabel = (
        isToday(ctrl.endMonth) ? t_today
        : moment.isMoment(ctrl.endMonth) ? ctrl.endMonth.format(format)
        : ctrl.endMonth instanceof Date ? moment(ctrl.endMonth).format(format)
        : null
        );
    }

    //
    // Just a quick check if a date is for today.
    //
    function isToday (date) {
      date = (
        moment.isMoment(date) ? date
        : date instanceof Date ? moment(date)
        : null
        );
      return date && date.isSame(moment(), 'day');
    }
  }
}());

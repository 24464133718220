angular.module('app.marketplace.ui.inventory')
.controller('packagingToggleController', packagingToggleController)
.directive('packagingToggle', function() {
  return {
    restrict: 'E',
    controllerAs: 'ptCtrl',
    templateUrl: 'templates/marketplace/inventory/directives/packaging-toggle.html',
    scope: true,
    bindToController: {
      item: '='
    },
    controller: 'packagingToggleController',
  };
});

function packagingToggleController (poHelperService, AccessService) {
  var ctrl = this;

  ctrl.showItemPackageToggle = false;
  ctrl.hide_packaging_info = false;
  ctrl.canToggle = canToggle;
  ctrl.showItemPackaging = showItemPackaging;
  ctrl.toggleItemPackaging = toggleItemPackaging;
  ctrl.itemIsInInventory = itemIsInInventory;

  ctrl.$onInit = function onInit() {
    ctrl.hide_packaging_info = AccessService.getProperty('purchase_orders.enable_mrkt_product_pull');
  }

  ////////////

  function canToggle(item) {
    // We have currently disabled all toggling, but removing the following line
    // and uncommenting the logic below will restore the previous functionality
    return '';

    // if (!item || !item.inventory_item) {
    //   return;
    // }

    // return item.inventory_item.buy_by_case && item.inventory_item.tracking_method === 'Item' ? 'clickable' : '';
  }

  function showItemPackaging(item) {
    if (!canToggle(item)) {
      return null;
    }

    ctrl.showItemPackageToggle = !ctrl.showItemPackageToggle;
    ctrl.buyByCaseToggle = !item.buy_by_case;
  }

  function toggleItemPackaging(item) {
    ctrl.showItemPackageToggle = false;
    item.buy_by_case = !item.buy_by_case;
    item.unit_cost = poHelperService.getUnitCost(item);
    item.sku = item.inventory_item.sku;
    item.quantity = 1;
  }

  function itemIsInInventory (item) {
    return !!(_.isObject(item.inventory_item) && _.get(item, 'inventory_item.id', false))
  }
}

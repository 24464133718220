angular.module('app.marketplace.ui.inventory')

.directive('inventoryProductDetailsForm', function() {
  return {
    restrict: 'E',
    controllerAs: 'ctrl',
    templateUrl: 'templates/marketplace/inventory/directives/inventory-product-details-form.html',
    scope: true,
    bindToController: {
      itemClone: '=',
      form: '=',
      action: '@'
    },
    controller: function($filter, inventoryItemService, officeService, elementService ,errorService) {
      var ctrl = this;

      getCategories();

      ctrl.addExpiryDate = addExpiryDate;
      ctrl.addLocation = addLocation;
      ctrl.displayExpiryDate = displayExpiryDate;
      ctrl.isAction = isAction;
      ctrl.layout = getLayout();
      ctrl.locationSearch = locationSearch;
      ctrl.manufacturerSearch = manufacturerSearch;
      ctrl.spacingClass = getSpacing();
      ctrl.initLog = initLog;

      ctrl.$onInit = init;

      return ctrl;

      function init () {
        if (!ctrl.locations) {
          getLocations();
        } else {
          locations = angular.extend([], ctrl.locations);
        }

        if (ctrl.itemClone.manufacturer_name) {
          _.set(ctrl.itemClone, 'manufacturerSearchText', ctrl.itemClone.manufacturer_name);
        }
      }

      ////////

      //
      // for testing purposes
      //
      function initLog ( item ) {
        var _item = _.pick(item, ['name', 'manufacturer', 'manufacturer_name', 'manufacturer_id', 'manufacturerSearchText']);
      }

      function addExpiryDate() {
        ctrl.itemClone.expiry_dates = ctrl.itemClone.expiry_dates || [];

        if(ctrl.itemClone.new_expiry_date){
          var newDate = true;
          _.map(ctrl.itemClone.expiry_dates, function(expiry_date, di){
            if(moment(new Date(expiry_date.date)).startOf('day').isSame(moment(ctrl.itemClone.new_expiry_date).startOf('day'))){
              newDate = false;
            }
          });
          if(newDate){
            ctrl.itemClone.expiry_dates = formatExpiryDates(
              ctrl.itemClone.expiry_dates,
              ctrl.itemClone.new_expiry_date
            );
          }
        }
        delete ctrl.itemClone.new_expiry_date; //clear
      }

      function addLocation(location, locationName){
        if (!ctrl.itemClone || (!locationName && (!location || !location.name))) {
          return false;
        }

        if (!ctrl.itemClone.locations) {
          ctrl.itemClone.locations = [];
        }

        location = location || {name: locationName};

        //
        // Check for a case-insensitive name match in pre-existing locations if
        // we were just passed a location name.
        //
        if (!location.id) {
          _.forEach(locations, function (loc) {
            if (loc.name.toLowerCase() === location.name.toLowerCase()) {
              location = loc;
              return false;
            }
          });
        }

        var existsOnItem = false;

        _.forEach(ctrl.itemClone.locations, function (loc) {
          if (location.id && loc.id === location.id) {
            existsOnItem = true;
            return false;
          }

          if (loc.name.toLowerCase() === location.name.toLowerCase()) {
            existsOnItem = true;
            return false;
          }
        });

        if (!existsOnItem) {
          ctrl.itemClone.locations.push(location);
        }

        //
        // TODO This could get merged into the loop over ctrl.locations
        // above.
        //
        var newLoc = true;
        _.map(locations, function(this_location, li){
          if (this_location.name.toLowerCase() === location.name.toLowerCase()) {
            newLoc = false;
          }
        });

        if (newLoc) {
          locations.push(location);
        }

        // DOM Manipulatiion like this should be done in a directive link
        // method as opposed to a controller.
        var inputEle = angular.element(document.getElementById('location-add-input'));
        if (inputEle) {
          inputEle.val('');
          inputEle.scope().$mdAutocompleteCtrl.hidden = true;
          inputEle.blur();
        }
      }

      function locationSearch(query) {
        if (!query) {
          return locations;
        }

        var results = [];

        _.map(locations, function(location, l){
          if (location.name.toLowerCase().indexOf(query.toLowerCase()) > -1) {
            results.push(location);
          }
        });

        return results;
      }

      function getInventory() {
        officeService
          .getInventory()
          .then(function(inventory){
            locations = angular.extend([], inventory.locations);
          }).catch(function(error){
            var t_message = $filter('translate')('ERRORS.INVENTORY_LOCATIONS_LOAD')
            errorService.uiErrorHandler(error || t_message, 0);
          });
      }

      function getCategories() {
        inventoryItemService.getInventoryCategories()
          .then(function(response) {
            var categories = response.data;
            var removed_cat = _.remove(categories,function(obj){ return obj.name === "Other";});
            categories = _.sortBy(categories, ['name']);
            categories.push(removed_cat[0]);
            ctrl.categories = categories;
          });
      }

      function getLocations () {
        return officeService.getLocations().then(function (locations) {
          ctrl.locations = locations;
          return locations;
        });
      }

      function getSpacing() {
        return ctrl.action === 'update'
          ? 'pr-36px'
          : '';
      }

      function getLayout() {
        return ctrl.action === 'update'
          ? 'row'
          : 'column';
      }

      // POST to /user-products expects an array of date string. PUT to
      // /inventory-items expects an array of objects with a date field.
      function formatExpiryDates(expiry_dates, new_expiry_date) {
        var dates = angular.copy(expiry_dates);

        var item = ctrl.action === 'update'
          ? {date: new_expiry_date}
          : new_expiry_date;

        dates.push(item);
        return dates;
      }

      function displayExpiryDate(expiry_date) {
        return ctrl.action === 'update'
          ? expiry_date.date
          : expiry_date;
      }

      function manufacturerSearch(name) {
        if (!name) {
          return false;
        }

        return elementService.get('manufacturer', null, {
          endpoint: 'search',
          name: name
        })
        .then(function (manufacturers) {
          return _.map(manufacturers, 'name');
        });
      }

      function isAction(action) {
        return ctrl.action === action;
      }
    }
  };
});

(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.shopping')
    .filter('activeSuppliers', activeSuppliers);

  //
  // Filter a list of vendors/suppliers down to the list that actually have
  // products on offer for the current country and medical field.
  //
  function activeSuppliers($filter) {
    return function (suppliers) {
      if (!suppliers) {
        return [];
      } else {
        return suppliers.filter(function (vendor) {
          return $filter('supplierProductCount')(vendor) > 0;
        });
      }
    };
  }

}());



angular.module('app.marketplace.ui.implants')

.directive('sowSlideoutAlertsRemoveImplant', function() {
  return {
    transclude: true,
    restrict: 'E',
    scope: {
      item: '=',
      removeItem: '&onRemove'
    },
    bindToController: true,
    templateUrl: 'templates/marketplace/implants/directives/alerts-remove-slideout.html',
    controllerAs: 'ctrl',
    controller: [
      '$scope', '$filter', '$mdDialog','$mdSidenav','sgToast','errorService','inventoryItemService','impHelperService', 'sowImplantService','sessionService','officeService','alertsDataCarrierService', 'referringDoctorsService', 'membershipService', 'formService', 'AccessService', 'PatientService', 'PatientHelperService',
      function sowSlideoutAlertsRemoveImplant($scope,  $filter, $mdDialog, $mdSidenav, sgToast, errorService, inventoryItemService, impHelperService, sowImplantService, sessionService, officeService,alertsDataCarrierService, referringDoctorsService, membershipService, formService, 
        AccessService, PatientService, PatientHelperService
      ) {

      var ctrl = this;

      ctrl.table_types = {
        "Implant": {
          product_name: "Implant", title: "Implants", short_name: 'implants',
          icon: { svg: "styles/img/implants/implants-icon-v3.svg", color: "blue", },
        },
        "Closure Cap": {
          product_name: "Closure Cap", title: "Cover Screws", short_name: 'caps',
          icon: { svg: "styles/img/implants/caps-icon.svg", color: "purple", },
        },
        "Healing Abutment": {
          product_name: "Healing Abutment", title: "Healing Abutments", short_name: 'abutments',
          icon: { svg: "styles/img/implants/healing-icon.svg", color: "orange", },
        },
        "Bone Graft": {
          product_name: "Bone Graft", title: "Bone Grafts", short_name: 'grafts',
          icon: { material: "grain", color: "green3", },
        },
        "Membrane": {
          product_name: "Membrane", title: "Membranes", short_name: 'membranes',
          icon: { material: "healing", color: "red-pink", },
        },
      };

      ctrl.delete_item = delete_item;

      ctrl.close_dialogs = close_dialogs;

      ctrl.item_is_expiring = item_is_expiring;

      ctrl.select_tooth = select_tooth;

      ctrl.discard_item = discard_item;

      ctrl.confirm_item_remove = confirm_item_remove;
      ctrl.disable_procedure_selection = true;
      ctrl.searchPatients = searchPatients;
      ctrl.selectedPatientRemovalChanged = selectedPatientRemovalChanged;
      ctrl.selectedProcedureChanged = selectedProcedureChanged;

      init();

      return ctrl;

      function init() {
        var session = sessionService.get();
        countryId = _.get(session, 'country.id');
        ctrl.current_tooth_format = impHelperService.getToothPlacementFormats(countryId);
        // ctrl.item.was_placed_on = new Date();

        officeService.getOfficeUsers().then(function(users){
          ctrl.officeUsers = impHelperService.getMedicUsers(users);

          officeService.getPendingInvitations().then(function (invites) {
            if (_.size(invites)) {
              ctrl.officeUsersInvites = impHelperService.getMedicUserInvites(invites);
            }
          });
        })
        .catch(function(error){
          var t_message = $filter('translate')('ERRORS.OFFICE_USERS_LOAD')
          errorService.uiErrorHandler(error.message || t_message);
        });

        referringDoctorsService.getReferringDoctors()
        .then(function (doctors) {
          ctrl.referring_doctors = doctors;
        }).catch(function (error) {
          var t_message = $filter('translate')('ERRORS.DOCTORS_RETRIEVE')
          errorService.uiErrorHandler(error || t_message, 3000);
        });

        $scope.$on('open-remove-implant-slideout', populateReservationId)

        defineLocks();
      }

      /**
       * @method item_is_expiring
       * @description check expired or expiring state for the item
       * @param {*} item
       */
      function item_is_expiring (item) {
        if(!item){
          return false;
        }
        var expiry_date = moment([item.expiry_year, (item.expiry_month*1 - 1)]);
        var diff = expiry_date.diff(moment(), 'months');
        return diff < ctrl.expiration_limit;
      }

      /**
       * @method confirm_item_remove
       */
      function confirm_item_remove () {
        if(!formService.checkRequiredFields(ctrl.removeImplantForm)){
          return 0;
        }

        ctrl.item.tooth_placement_value ||= '';
        ctrl.item.reservation_id = _.trim(ctrl.item.reservation_id);
        ctrl.item.was_placed = true;
        sowImplantService.saveInventoryItem(ctrl.item)
          .then(async function(response){
            // alertsDataCarrierService.load_alerts();

            if (ctrl.has_pwf_access && ctrl.selected_procedure) {
              const payload = {
                office_inventory_item: _generateOfficeInventoryItem(),
                procedure_id: ctrl.selected_procedure.id,
                date: ctrl.item.was_placed_on,
                quantity: 1,
              };
              await PatientService.linkProduct(payload);
            }
            ctrl.removeItem({item: ctrl.item});
            close_dialogs('alert-remove-implant');
            var t_message = $filter('translate')('TOAST.REMOVED');
            sgToast.showSimple(ctrl.table_types[ctrl.item.inventory_group.catalog_item.category].product_name +t_message);

          });
      }

      /**
       * @method discard_item
       */
      function discard_item() {
        close_dialogs('alert-remove-implant');
        ctrl.removeImplantForm.$setPristine();
        ctrl.removeImplantForm.$setUntouched();
      }

      /**
       * @method select_tooth
       * @param {*} number
       * @param {*} section_v
       * @param {*} section_h
       */
      function select_tooth (number, section_v, section_h) {
        ctrl.item.tooth_placement_value = number;
        ctrl.teeth_section_v = section_v;
        ctrl.teeth_section_h = section_h;
      }


      /**
       * @method close_dialogs
       * @description close slideout based on name
       */
      function close_dialogs(name) {
        $mdSidenav(name).close();
      }

      /**
       * @method  delete_item
       */
      function delete_item(inventory_item) {
        $mdDialog.show({
          controller: 'poDialogController',
          controllerAs: 'dialogCtrl',
          templateUrl: 'templates/marketplace/implants/modals/imp-delete-warning.html',
          parent: angular.element(document.body),
          clickOutsideToClose: false,
          locals: {
            product_name: ctrl.table_types[ctrl.item.inventory_group.catalog_item.category].product_name,
            manufacturer_name: inventory_item.manufacturer_name,
            item: {
              lot_number: inventory_item.lot_number,
              ref_number: inventory_item.inventory_group.reference_no,
              expiry: inventory_item.expiry_year +'-'+ $filter('leftpad')(inventory_item.expiry_month,2),
            },
          },
          bindToController: true,
        })
        .then(function (answer) {

          if (answer) {
            sowImplantService.removeInventoryItem(inventory_item.id)
            .then(function(response){

              ctrl.removeItem({item: inventory_item});
              var t_message = $filter('translate')('TOAST.DELETED');
              sgToast.showSimple(ctrl.table_types[ctrl.item.inventory_group.catalog_item.category].product_name +t_message);
              // alertsDataCarrierService.load_alerts();
              close_dialogs('alert-remove-implant');

            }).catch(function(error){
              var t_message = $filter('translate')('ERRORS.SERVER_TRY_AGAIN')
              errorService.uiErrorHandler(t_message);
            });
          }
        });
      }

      function defineLocks() {
        const membership = membershipService.get();
        const admin_tools_lock_condition = _.get(membership, "user_properties.admin_tools.disable_page", false);
        ctrl.disabled_admin_tools_page = admin_tools_lock_condition;
        
        const delete_lock_condition = _.get(membership, 'user_properties.implants.disable_delete', false);
        ctrl.delete_locked = delete_lock_condition;

        ctrl.has_pwf_access = AccessService.hasPmsIntegration();
      }

      /**
       * Searches for patients in the API and returns an array of parsed patient objects.
       * Triggered by the autocomplete element when typing patient names or IDs into it.
       * 
       * @param {string} query_term
       * @return {Promise<ParsedPatient[]>}
       */
      function searchPatients () { 
        const query_term = ctrl.patient_search_text || ''    
        return PatientService.searchPatients(query_term).then((response) => {
          return _.map(response.patients, (patient) => PatientHelperService.parsePatient(patient));
        });
      }

      /**
       * Updates the Implant and patient procedures based on the selected patient.
       * Triggered by selecting an option from the autocomplete in a 'remove implant' dialog.
       * 
       */
      function selectedPatientRemovalChanged () {
        if (!ctrl.selected_patient_removal) {
          ctrl.item.placement_id = null;

          return;
        }

        ctrl.item.placement_id = ctrl.selected_patient_removal.id;
        ctrl.item.is_external_id = true;
        ctrl.item.referring_doctor_name = ctrl.selected_patient_removal.referring_doctor.name ?? '';
        ctrl.item.referring_doctor_id = ctrl.selected_patient_removal.referring_doctor.id ?? null;
        ctrl.item.was_placed_by = ctrl.selected_patient_removal.provider_doctor.name ?? '';
        ctrl.item.was_placed_by_id = ctrl.selected_patient_removal.provider_doctor.id ?? null;
        _updatePatientProcedures(ctrl.selected_patient_removal) 
      }

    /**
     * Retrieves the care plans for a patient, extracts the
     * first procedure from each care plan, formats the procedure date, and updates the patient's
     * procedures list.
     * 
     * @param {ParsedPatient} patient 
     */
    function _updatePatientProcedures (patient) {
      ctrl.disable_procedure_selection = true;
      return PatientService.getPatientCarePlans(patient.id).then((response) => {
        ctrl.patient_procedures = _.map(response, (care_plan) => {
          const procedure = care_plan.procedures[0];
          return {
            ...procedure,
            formatted_date: moment(procedure.performedAt).format('MM/DD/YYYY'),
          };
        });
        ctrl.disable_procedure_selection = false;
        ctrl.procedure_required = _.size(ctrl.patient_procedures) > 0;
      });
    }

    /**
     * Gets the patient info from the API and populates the patient removal field.
     * @param {object} _event
     * @param {object} selected_item 
     */
    function populateReservationId(_event, selected_item) {
      ctrl.patient_search_text = '';
      ctrl.reserved_patient_name = null;
      if (ctrl.has_pwf_access && selected_item.reservation_id) {
        ctrl.loading_patient = true;
        PatientService.getPatient(selected_item.reservation_id)
          .then((response) => {
            const patient = PatientHelperService.parsePatient(response);
            ctrl.reserved_patient_name = patient.name;
            ctrl.selected_patient_removal = patient;
            selectedPatientRemovalChanged();
          }).finally(() => {
            ctrl.loading_patient = false;
          })
      }
    }

    /**
     * When a procedure is selected, update the implant's placement date to match the procedure's date.
     * 
     * @param {PatientProcedure} procedure
     */
    function selectedProcedureChanged () {
      const formatted_date = new Date(ctrl.selected_procedure.performedAt);
      ctrl.item.was_placed_on = formatted_date;
    }

    function _generateOfficeInventoryItem () {
      return {
        id: ctrl.item.inventory_group.inventory_item_id,
        image: ctrl.item.inventory_group.catalog_item.product_image_url,
        manufacturer: ctrl.item.manufacturer_name,
        name: ctrl.item.inventory_group.catalog_item.name,
        price: ctrl.item.price,
        sku: ctrl.item.inventory_group.reference_no,
        tracking_method: 'Item',
        type: 'Implant Item',
        implant: {
          category: ctrl.item.inventory_group.catalog_item.category,
          expiry_month: ctrl.item.expiry_month,
          expiry_year: ctrl.item.expiry_year,
          imp_inventory_group_id: ctrl.item.inventory_group.id,
          lot_number: ctrl.item.lot_number,
          properties: ctrl.item.inventory_group.catalog_item.properties,
          serial_number: ctrl.item.serial ?? '',
          sow_implant_inventory_item_id: ctrl.item.id,
          tooth_placement_format: ctrl.current_tooth_format.format_name,
          tooth_placement_value: _.toString(ctrl.item.tooth_placement_value),
        }
      };
    }

    }]
  };
});


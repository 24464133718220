(function () {
  "use strict";

  const markOrderReceived = {
    bindings: {},
    controller: "markOrderReceivedCtrl",
    controllerAs: "morCtrl",
    selector: "markOrderReceived",
    templateUrl: "sow-orders/slideouts/mark-order-received.html",
  }

  /** @ngInject */
  class markOrderReceivedCtrl {
    constructor($mdSidenav, $rootScope, $scope, AccessService, sowOrderService, poHelperService) {
      this.$mdSidenav = $mdSidenav;
      this.$rootScope = $rootScope;
      this.$scope = $scope;
      this.AccessService = AccessService;
      this.sowOrderService = sowOrderService;
      this.poHelperService = poHelperService;
    }

    openCalendar($event) {
      this.poHelperService.openCalendar($event);
    }

    $onInit() {
      this.defineLocks();
      this.$scope.$on("mark-order-received-open", (_event, order) => {
        this.openSlideout(order);
      });
    }

    openSlideout(order) {
      this.submitted = false;
      this.order = order;
      this.order.UI.receiving_date = moment().utc().format();
      this.parseItems(this.order.UI.receivable_items);
      if (this.has_inventory_access) {
        /* users with inventory access are able to select inventory update options */
        this.$mdSidenav("mark-order-received").onClose(this._onCloseSlideout.bind(this));
        this.$mdSidenav("mark-order-received").open();
      } else {
        /* orders of users without inventory access can be marked as received immediately */
        this.show_confirm_dialog = true;
      }
    }

    /* Resets inventory update options and expiry dates when the slideout is closed */
    _onCloseSlideout() {
      this.order.UI.receivable_items.forEach(item => {
        item.UI.update_inventory_option = null;
        item.expiry_dates = [];
      })
    }

    closeSlideout() {
      return this.$mdSidenav("mark-order-received").close();
    }

    handleDialogEvent({detail: {accept, hide}}) {
      if (accept) {
        this.markAsReceived().then(() => {
          // Open the slideout with the paywall if the user doesn't have inventory access
          if (!this.has_inventory_access) {
            this.$mdSidenav("mark-order-received").open()
          }
        })
      }
      if (hide) {
        this.show_confirm_dialog = false;
      }
    }

    markAsReceived() {
      this.submitting = true;
      return this.sowOrderService.markAsReceived(this.order)
        .then((updated_order) => {
          for (const item of this.order.UI.receivable_items) {
            item.quantity_received = item.UI.quantity_receivable;
          }
          this.$rootScope.$broadcast('order-marked-as-received', updated_order);
          this.closeSlideout();
        })
        .finally(() => {
          this.submitting = false;
        });
    }

    handleSubmitClick() {
      this.submitted = true;
      if (this.order.UI.receivable_items.every(item => item.UI.update_inventory_option)) {
        this.show_confirm_dialog = true;
      }
    }

    addExpDate(item) {
      item.expiry_dates ??= [];
      item.expiry_dates.push(null);
    }

    removeExpDate(item, index) {
      item.expiry_dates.splice(index, 1);
    }

    parseItems(items) {
      const implants_flag = this.AccessService.getOfficeFeatureFlag("implants");
      const medications_flag = this.AccessService.getOfficeFeatureFlag("medications");
      const inventory_flag = this.AccessService.getOfficeFeatureFlag("inventory_full");

      let implants_count = 0;
      let inventory_count = 0;
      let medications_count = 0;

      for (const item of items) {
        item.UI = {...item.UI, option: null};
        item.expiry_dates ||= [];
        item.item_bundles = [{qty: item.quantity}]

        switch (item.type || item.inventory_item?.type) {
          case "Medication Item":
          case "Marketplace Medication":
            medications_count++;
            if (medications_flag) {
              item.UI.update_inventory_option = "computer";
              item.item_type = "Marketplace Medication"; // fix for PO items
            }
            break;
          case "Implant Item":
          case "Marketplace Implant":
            implants_count++;
            if (implants_flag) {
              item.item_type = "Marketplace Implant"; // fix for PO items
            }
            break;
          default:
          case "General Item":
          case "Marketplace Item":
            inventory_count++;
            if (inventory_flag) {
              item.item_type = "Marketplace Item"; // fix for PO items
            }
        }
      };

      const imp_wall = !implants_flag && implants_count > 0;
      const inv_wall = !inventory_flag && inventory_count > 0;
      const meds_wall = !medications_flag && medications_count > 0;

      if (imp_wall || inv_wall || meds_wall) {
        this.display_paywall = true;
      }
    }

    defineLocks() {
      this.has_inventory_access = this.AccessService.hasGeneralInventoryAccess();
    }

  }

  angular.module("sowOrders")
    .controller(markOrderReceived.controller, markOrderReceivedCtrl)
    .component(markOrderReceived.selector, markOrderReceived);

})();

(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.subscriptions')
    .directive('marketplacePlan', marketplacePlanDirective);

  function marketplacePlanDirective ($log, $rootScope, $state) {
    return {
      'restrict': 'E',
      'replace': true,
      'templateUrl': 'templates/marketplace/subscriptions/plan-style-b.html',
      'scope': {
        'isCurrent': '@',
        'noButton': '@',
        'action': '&',
        'isLarge': '@',
      },
      'link': function ($scope, $element, $attrs) {
        var isLarge;
        var noButton;
        var isCurrent;
        var defaultClasses = 'marketplace-plan';

        $scope.planClass = defaultClasses;
        $scope.planTitle = 'Marketplace';
        $scope.planTagline = 'Browse & Purchase on Sowingo';
        $scope.planPitch = ' ';
        $scope.showAnnualBilling = false;
        $scope.monthlyCost = 0;
        $scope.buttonText = 'Basic';

        function updateState () {
          isLarge = $scope.isLarge ? $scope.$parent.$eval($scope.isLarge) : false;
          noButton = $scope.noButton ? $scope.$parent.$eval($scope.noButton) : false;
          isCurrent = $scope.isCurrent ? $scope.$parent.$eval($scope.isCurrent) : false;

          $scope.disableButton = isCurrent;
          $scope.showButton = !noButton;
          $scope.buttonText = isCurrent ? 'Current Plan' : 'Select Plan';
          $scope.planClass = defaultClasses + (isLarge ? ' plan-large' : '') + (isCurrent ? ' is-current': '');
        }

        $scope.$watch('isLarge', updateState);
        $scope.$watch('isCurrent', updateState);
        $scope.$watch('noButton', updateState);

        $scope.triggerAction = function ($event) {
          if (angular.isDefined($attrs.action)) {
            $scope.action({'$event': $event});
          } else {
            var isManager = _.get($rootScope, 'current_membership.membership_type') === 'manager';
            var isMasterUser = _.get($rootScope, 'current_membership.membership_type') === 'master';

            if (isManager || isMasterUser) {
              $state.go('app.office.profile.subscription.downgrade', {'plan': 'marketplace'});
            } else {
              return;
            }
          }
        };
      }
    };
  }
}());

(function () {
  'use strict';
  
  angular.module('sowMarketplace')
  .controller('sowMktSearchSuggestionsController', sowMktSearchSuggestionsController)
  .directive('sowMktAbSearchSuggestions', sowMarketplaceSearchSuggestionsDirective);
  
  function sowMarketplaceSearchSuggestionsDirective () {
    return {
      restrict: 'E',
      templateUrl: 'sow-mkt/directives/sow-mkt-ab-search-suggestions.html',
      controller: 'sowMktSearchSuggestionsController',
      controllerAs: 'mktssCtrl'
    };
  }
  
  function sowMktSearchSuggestionsController($scope, $window, $rootScope, $state, $timeout) {
    var ctrl = this;
    
    // Methods
    ctrl.reloadDirective = reloadDirective;
    ctrl.clickSearchSuggestion = clickSearchSuggestion;
    ctrl.clickAnywhereButSuggestion = clickAnywhereButSuggestion;

    // Variables
    ctrl.overlay_visible = false;
    ctrl.reload_promise = null;    
    ctrl.results = [];
    ctrl.original_search_query = null;
    ctrl.input_bar_focused = false;
    ctrl.focused_suggestion = -1;
    
    // Subscribe to the 'overlay_visible_changed' $broadcast from `sow-mkt-search.js`
    $scope.$on('overlay_visible_changed', function(event, search_query, search_results, overlay_visible, input_bar_focused, key) {
      // Render everything / reload the directive
      ctrl.input_bar_focused = input_bar_focused;
      var cancelled = $timeout.cancel(ctrl.reload_promise);
      ctrl.reload_promise = $timeout(ctrl.reloadDirective(search_query, search_results, overlay_visible));
    });      
    
    $scope.$on('search_suggestions_focus', handleKey);
    $scope.$on('search_suggestions_select', function(){
     // The -1 is a nomenclature we at Sowingo are using to reference as an index for the original search query
     // For clarity, the `ctrl.results` contains search suggestions & excludes the original search query
     // Therefore the 0 index would be the first search suggestions
     // The -1 is used to represent the current search query
     //
     // index = -1 is <current_search_query> 
     // index = 0 ...n is <search suggestions from Algolia>
     // 
     // Starting the index at -1 allows the UI to not automatically focus on 0
     // to allow for users to type > focus on -1 (original query) > press enter
     // If it was started at 0, the enter focus would search the first suggestion
     //
     //
      if (ctrl.focused_suggestion === -1) {
        clickSearchSuggestion({name: ctrl.original_search_query, type: 'query'});
      } else {
        clickSearchSuggestion(ctrl.results[ctrl.focused_suggestion]);
      }
    });

    return ctrl;

    function handleKey ($event, key) {
      if (key === 'down') {
        moveFocusDown();
      } else if (key === 'up') {
        moveFocusUp();
      }
    }

    function moveFocusUp () {
      if (ctrl.focused_suggestion > -1) {
        ctrl.focused_suggestion -= 1;
      }
    }

    function moveFocusDown () {
      if (ctrl.focused_suggestion < ctrl.results.length - 1) {
        ctrl.focused_suggestion += 1;
      }
    }
    
    function reloadDirective(search_query, search_results, overlay_visible) {
      ctrl.original_search_query = search_query;
      ctrl.overlay_visible = overlay_visible;
      ctrl.results = search_results;
    }
    
    function clickSearchSuggestion(suggestion) {

      ctrl.focused_suggestion = -1;
            
      switch (suggestion.type) {
        
        case "category":
          // Note the `suggestion.name` value will be the category name
          // Need to pass the original search query
          $state.go('app.mkt.search', {
            'query': ctrl.original_search_query, 
            'cat_id': suggestion.id,
            'category': {'id': suggestion.id, 'name': suggestion.name},
            'sub_id': null,
            'mf_id': null
          });
          ctrl.overlay_visible = false;
          ctrl.input_bar_focused = false;
          break;
  
        case "subcategory":
          // Note the `suggestion.name` value will be the category name
          // Need to pass the original search query
          $state.go('app.mkt.search', {
            'query': ctrl.original_search_query, 
            'sub_id': suggestion.id,
            'subcategory': {'id': suggestion.id, 'name': suggestion.name},
            'cat_id': null,
            'mf_id': null
          });
          ctrl.overlay_visible = false;
          ctrl.input_bar_focused = false;
          break;
        
        case "originalQuery":
        case "query":
          // Note the `suggestion.name` value will contain the query 
          // Need to sanitize and remove the HTML tags
          var search_query = suggestion.name.replace(/(<([^>]+)>)/gi, "");

          $state.go('app.mkt.search', {
            'query': search_query, 
            'cat_id': null,
            'mf_id': null,
            'sub_id': null
          });
          ctrl.overlay_visible = false;
          ctrl.input_bar_focused = false;

          // If a query suggestion is selected, update input search bar via
          // the controller
          $rootScope.$broadcast('query_suggestion_clicked', search_query);
          break;   
      }
    }

    function clickAnywhereButSuggestion() {    
      
      ctrl.focused_suggestion = -1;

      if (ctrl.input_bar_focused && ctrl.original_search_query !== null) {
        ctrl.input_bar_focused = false;
        ctrl.overlay_visible = true;
      } else {
        ctrl.overlay_visible = false;
      }
    }
  }

})();

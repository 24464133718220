(function () {
  'use strict';

  angular
    .module('sowSterilization')
    .service('sowSterilizationService', sowSterilizationService);

  function sowSterilizationService (officeService, serverAPI, apiUrl, $q, sgToast, errorService, $translate) {
    /*jshint validthis: true */
    var service = this;

    service.type_paths = {
      'biological': '/biological_sterilization_records',
      'spore': '/biological_sterilization_records',
      'chemical': '/chemical_sterilization_records',
      'ultrasonic_cleaner': '/ultrasonic_cleaner_records',
      'washer_disinfector': '/washer_disinfector_records'
    };

    service.getTestRecords = getTestRecords;
    service.getChemicalTestRecords = getChemicalTestRecords;
    service.getBiologicalTestRecords = getBiologicalTestRecords;
    service.getWasherTestRecords = getWasherTestRecords;
    service.getUltrasonicTestRecords = getUltrasonicTestRecords;
    service.getAllTestRecords = getAllTestRecords;
    service.saveNewRecord = saveNewRecord;
    service.updateRecord = updateRecord;
    service.getLatestRecords = getLatestRecords;
    service.getIndicatorCompanies = getIndicatorCompanies;
    service.exportEmail = exportEmail;

    service.getUploadToken = getUploadToken;
    service.getUploadTokenByInventory = getUploadTokenByInventory;
    service.getFileUploadTokenInventory = getFileUploadTokenInventory;
    // service.attachImageToRecord = attachImageToRecord; // deprecated
    service.uploadImage = uploadImage;

    return service;

    /**
     * A simple convenience wrapper around the call to send the request to the
     * API. It makes the functions simple than having this code block
     * duplicated everywhere.
     */
    function _callAPI (url, params) {
      return serverAPI
        .doAPICall(url, params)
        .then(function (response) {
          return response.data;
        });
    }

    /**
     * Generate a full API url. Since the prefix of these urls requires extra
     * work (pulling in and inserting the office id), it makes sense for us to
     * centralize that bit.
     */
    function _getUrl (path) {
      path = path || '';
      return '{0}/offices/{1}{2}'.format(apiUrl, sow.officeInfo().id, path);
    }

    /**
     * Fetch a listing of biological sterilization test records.
     */
    function getBiologicalTestRecords (filters) {
      var filterNames = ['pending', 'passing', 'failing', 'month'];
      filters = _.pick(filters, filterNames);
      filters.type = 'Biological';

      var url = _getUrl('/sterilization/records');
      var params = {'method': 'GET', 'params': filters};
      return _callAPI(url, params);
    }

    /**
     * Fetch a listing of chemical sterilization test records.
     */
    function getChemicalTestRecords (filters) {
      var filterNames = ['pending', 'passing', 'failing', 'month'];
      filters = _.pick(filters, filterNames);
      filters.type = 'Chemical';

      var url = _getUrl('/sterilization/records');
      var params = {'method': 'GET', 'params': filters};
      return _callAPI(url, params);
    }

    /**
     * Fetch a listing of all (biological _and_ chemical) sterilization test
     * records.
     */
    function getTestRecords (filters) {
      var filterNames = ['pending', 'passing', 'failing', 'month', 'type', 'incubation_complete', 'incubation_cycle', 'page'];
      filters = _.pick(filters, filterNames);
      // filters.type = filters.type || 'Both';

      var url = _getUrl('/sterilization/records');
      var params = {'method': 'GET', 'params': filters};
      return _callAPI(url, params);
    }

    /**
     * Fetch a listing of ultrasonic cleaner test records.
     */
    function getUltrasonicTestRecords (filters) {
      var filterNames = ['pending', 'passing', 'failing', 'month'];
      filters = _.pick(filters, filterNames);

      var url = _getUrl('/ultrasonic_cleaner_records');
      var params = {'method': 'GET', 'params': filters};
      return _callAPI(url, params);
    }

    /**
     * Fetch a listing of washer disinfector test records.
     */
    function getWasherTestRecords (filters) {
      var filterNames = ['pending', 'passing', 'failing', 'month'];
      filters = _.pick(filters, filterNames);

      var url = _getUrl('/washer_disinfector_records');
      var params = {'method': 'GET', 'params': filters};
      return _callAPI(url, params);
    }

    /**
     * Fetch a listing of all test records combined.
     */
    function getAllTestRecords (filters) {
      var promises = [
        getTestRecords(filters),
        getUltrasonicTestRecords(filters),
        getWasherTestRecords(filters)
      ];

      return $q.all(promises);
    }

    function saveNewRecord (data) {
      // temp hacks, not sure why but API requires this
      if(data.sterilizer_mode && !data.sterilizer_mode_id) {
        _.set(data, 'sterilizer_mode_id', data.sterilizer_mode.id);
      }
      data.office_id = sow.officeInfo().id;
      
      var url = _getUrl(service.type_paths[data.type]);
      var params = {'method': 'POST', 'data': data};

      return _callAPI(url, params)
      .catch(function(error){
        errorService.uiErrorHandler(error);
        throw error;
      });

    }

    function updateRecord (id, new_data, type) {
      // for the new patch request, type will not be included in the new_data object,
      // so we use the argument type to get the correct path
      var path = service.type_paths[new_data.type ?? type];
      var url = _getUrl('{0}/{1}'.format(path,id));
      var params = {'method': 'PATCH', 'data': new_data};
      return _callAPI(url, params);
    }

    function getLatestRecords () {
      var url = _getUrl('/sterilization/records/latest');
      var params = {'method': 'GET'};
      return _callAPI(url, params);
    }

    function getIndicatorCompanies () {
      var url = _getUrl('/biological_indicator_companies');
      var params = {'method': 'GET'};
      return _callAPI(url, params);
    }

    function exportEmail () {
      var user = sow.userInfo();
      var data = {
        'email': user.email,
        'name': user.name,
      };
      var url = _getUrl('/sterilization/send_report_email');
      var params = {'method': 'POST', 'data': data};

      return _callAPI(url, params)
      .then(function(email_result){
        $translate('MESSAGES.EMAIL_SENT')
        .then(function(message){
          sgToast.showSimple(message);
        });
        return email_result;
      })
      .catch(function(error){
        errorService.uiErrorHandler(error);
      });
    }

    function getUploadToken (filename) {
      var url = _getUrl('/aws_utils/s3_upload_token/{0}'.format(filename));
      var params = {'method': 'GET'};

      return _callAPI(url, params)
      .catch(function(error){
        errorService.uiErrorHandler(error);
      });
    }

    function getUploadTokenByInventory (filename) {
      var url = _getUrl('/aws_utils/s3_upload_token/inventory/{0}'.format(filename));
      var params = {'method': 'GET'};

      return _callAPI(url, params)
      .catch(function(error){
        errorService.uiErrorHandler(error);
      });
    }

    function getFileUploadTokenInventory (filename) {
      var url = _getUrl('/aws_utils/s3_upload_token/sds/{0}'.format(filename));
      var params = {'method': 'GET'};

      return _callAPI(url, params)
      .catch(function(error){
        errorService.uiErrorHandler(error);
      });
    }

    function uploadImage (file_data) {
      var filename = getDefaultFileName(file_data.name);
      return getUploadToken(filename)
      .then(function(aws_data){
        var post_data = _.extend(_.omit(aws_data, ['aws_url']), {'file': file_data});
        var form_data = new FormData();
        _.map(Object.keys(post_data), function(key){
          form_data.append(key, post_data[key]);
        });
        var params = {
          'method': 'POST', 
          'data': form_data, 
          'headers':{
            'enctype': 'multipart/form-data', 
            'Access-Control-Allow-Origin': '*',
            'Content-Type': undefined
          }
        };

        return _callAPI(aws_data.aws_url, params)
        .then(function(){
          var path = "{0}{1}/{2}".format(aws_data.aws_url, sow.officeInfo().id, filename);
          return {
            'aws_data': aws_data,
            'file_data': file_data,
            'image_path': path,
          };
        })
        .catch(function(error){
          // 403: token expired
          // 400: file larger than 50mb
          errorService.uiErrorHandler(error);
        });
      });
    }

    function getDefaultFileName (original_name) {
      var extension = _.last( _.split(original_name, '.') );
      var date = moment().utc().format('YYYYMMDD_HHmmssms');
      return _.join([date,extension],'.');
    }

    // deprecated
    function attachImageToRecord (record, file_data) {
      return getUploadToken(file_data)
      .then(function(aws_data){
        return uploadImage(aws_data, file_data)
        .then(function(s3_response){

          var path = "{0}{1}/{2}".format(aws_data.aws_url, sow.officeInfo().id, file_data.name);
          
          return updateRecord(record.id, {'image_path': path, 'type': "chemical"});
        });
      });
    }
  }

}());


(function(){

  angular.module('sowSterilization')
  .controller('sterilizationTestListController', sterilizationTestListController);

  function sterilizationTestListController ($scope, $rootScope, $q, $timeout, $filter, $mdDialog, smoothScroll, errorService, sowSterilizationService, stznHelperService, AccessService, sowInteropService ) {
    /*jshint validthis: true*/
    var ctrl = this;
    ctrl.selected_month = moment().startOf('month').format("YYYY-MM-DD");
    ctrl.month_name = moment(ctrl.selected_month).format("MMMM YYYY");
    
    ctrl.records_ui_data = stznHelperService.records_ui_data;
    ctrl.records_type_names = {
      ...stznHelperService.records_type_names,
      // Only here in the list view we display "Biological/Spore Test" for both
      // biological and spore tests, since this name maps to the corresponding filter
      biological: 'STERILIZATION.BIOLOGICAL_SPORE_TEST',
      spore: 'STERILIZATION.BIOLOGICAL_SPORE_TEST',
    };
    ctrl.incubator_options = stznHelperService.incubator_options;
    ctrl.chemical_indicator_options = stznHelperService.chemical_indicator_options;

    ctrl.office_first_date = $rootScope.current_office.sterilization_start_date;
    ctrl.filter = 'all';
    ctrl.display_items = [];
    ctrl.timeout_promises = [];

    ctrl.updateSelectedMonth = updateSelectedMonth;
    ctrl.addNewTest = addOrEditTest;
    ctrl.alertHandler = alertHandler;

    ctrl.detailsDialog = stznHelperService.detailsDialog;
    ctrl.pendingActionsDialog = stznHelperService.pendingActionsDialog;
    ctrl.getTimeLabel = stznHelperService.getTimeLabel;
    ctrl.getDurationLabel = stznHelperService.getDurationLabel;
    ctrl.getChemIndicatorLabel = stznHelperService.getChemIndicatorLabel;
    
    ctrl.updateFilter = updateFilter;
    ctrl.openBundle = openBundle;
    ctrl.closeBundle = closeBundle;
    ctrl.toggleBundle = toggleBundle;
    ctrl.isCurrentBundle = isCurrentBundle;
    ctrl.saveRecord = saveRecord;
    ctrl.cancelSave = cancelSave;
    ctrl.getEndTimeLabel = getEndTimeLabel;
    ctrl.getCycleNumberText = getCycleNumberText;
    ctrl.handleTestsPageEvent = handleTestsPageEvent;

    ctrl.$onInit = init;

    return ctrl;

    function init () {
      _defineLocks()
      // event handlers
      $scope.$on('add-new-stzn-test', addOrEditTest);
    
      if (ctrl.has_sterilization_v2_enabled) {
        $scope.$on('stzn-new-test-saved', onV2RecordUpdated);
        $scope.$on('stzn-test-action', onV2RecordUpdated);
      } else {
        $scope.$on('stzn-incubation-complete', handleIncubationComplete);
        $scope.$on('stzn-test-action', handlePendingRecordAction);
        $scope.$on('stzn-new-test-saved', handleNewRecord);
        $scope.$on('stzn-new-test-edited', _handleUpdatedRecord);
        $scope.$on('$destroy', function(){
          _.forEach(ctrl.timeout_promises, function(promise){
            $timeout.cancel(promise);
          });
        });

        $scope.$on('is-visible-bottom-of-sterilization-list', loadNextPage);
        generateMonthsList();
        loadRecords();
      }
    }

    function onV2RecordUpdated (_$event, new_record) {
      if(_shouldShowFailTipsDialog(new_record)){
        $timeout(function(){
          stznHelperService.failTipsDialog(new_record);
        }, 500);
      }

      sowInteropService.dispatchToWindow('sterilization-new-test-saved', {
        record: new_record
      });
    }

    function loadRecords () {
      ctrl.page = 1;
      return $q.all(
        fetchPeriodRecords({show_loader: true}),
        fetchPendingRecords()
      );
    }

    function fetchPeriodRecords ({show_loader} = {}) {
      var params = {
        month: ctrl.selected_month,
        page: ctrl.page,
      };
      if (ctrl.filter !== 'all') {
        const type = {
          biological: 'Biological',
          chemical: 'Chemical',
          ultrasonic_cleaner: 'Ultrasonic',
          washer_disinfector: 'WasherDisinfector',
        }[ctrl.filter];

        if (type) {
          params.type = type;
        }
      }

      if (show_loader) {
        ctrl.loading = true;
      }

      return sowSterilizationService.getTestRecords(params)
      .then(function(res){
        ctrl.has_next = res.pagination_info.has_next;
        const records = res.results;
        ctrl.display_items = _.filter(_.concat(ctrl.display_items, records), function(item){
          var selected = item.result !== 'Incubation Complete' && item.result !== 'Incubation Cycle';
          if(selected){
            parseRecord(item);
          }
          return selected;
        });
        
        return ctrl.display_items;
      }).finally(() => {  
        ctrl.loading = false;
      });
    }

    function loadNextPage() {
      if (ctrl.has_next)  {
        ctrl.page++;
        fetchPeriodRecords();
      }
    }

    function fetchPendingRecords () {
      var params = {
        'pending': true,
        'type': 'Biological'
      };

      var promises = [];
      return sowSterilizationService.getTestRecords(params)
      .then(function(tests_response){
        ctrl.pending_items = tests_response;
        _.map(ctrl.pending_items, parseRecord);
        return tests_response;
      });
    }

    function preventNavigation () {

    }

    function addOrEditTest (_$event, selected_record) {
      return $mdDialog.show({
          controller: 'stznAddNewTestController',
          controllerAs: 'stznANTCtrl',
          templateUrl: 'sow-sterilization/modals/stzn-add-new-test.html',
          parent: angular.element(document.body),
          clickOutsideToClose: true,
          locals: {
            selected_record: selected_record ? angular.copy(selected_record) : null,
          }
        });
    }

    function handleNewRecord ($event, new_record) {
      stznHelperService.showActionMessage(new_record);
      if(_shouldShowFailTipsDialog(new_record)){
        $timeout(function(){
          stznHelperService.failTipsDialog(new_record);
        }, 500);
      }

      // If the record is an Incubation Cycle, fetch the pending records
      if (new_record.result === 'Incubation Cycle') {
        fetchPendingRecords();
      }

      if (isNewRecordTypeInCurrentView(new_record.tested_at, new_record.type)) {
        _reloadListPage();
      }
    }

    /**
     * Angular event listener when a record is updated.
     * It updates the record in the list if the date is not changed, otherwise it reloads the list.
     * @param {Object} $event - the event object.
     * @param {Object} updated_record - the updated record.
     * @param {Object} old_record - the old record.
     */
    function _handleUpdatedRecord ($event, updated_record, old_record) {
      ctrl.current_bundle = null;
      
      // When date is not changed, update the record in the list
      if (moment(old_record.tested_at).isSame(moment(updated_record.tested_at))) {
        const existing_record_index = _findRecordIndex(updated_record.id);
        _updateExistingRecord(updated_record, existing_record_index);
      } else {
        // Reload the list and open the details view
        _reloadListPage().then(() => {
          const existing_record_index = _findRecordIndex(updated_record.id);
          if (existing_record_index >= 0) {
            // Open the details view
            openBundle(ctrl.display_items[existing_record_index]);
          }
        })
      }
    }

    function _findRecordIndex (record_id) {
      return _.findIndex(ctrl.display_items, {id: record_id})
    }

    /**
     * Update the existing record in the list with the updated record.
     * @param {Object} updated_record - the updated record.
     * @param {number} existing_record_index - the index of the existing record in the list.
     */
    function _updateExistingRecord (updated_record, existing_record_index) {
      // Throw an error if the record is not found, this should never happen
      // but will help us to catch the issue on Sentry
      if (existing_record_index < 0) {
        throw new Error(`Record with id ${updated_record.id} not found in the list`);
      }

      const parsed_record = parseRecord(updated_record);
      ctrl.display_items[existing_record_index] = angular.copy(parsed_record);

      // Open the details view
      openBundle(ctrl.display_items[existing_record_index]);
    }

    function _reloadListPage () {
      // Reset the page to 1 and fetch the records again
      ctrl.display_items = [];
      ctrl.page = 1;
      return fetchPeriodRecords({show_loader: true});
    }


    /**
     * The function checks if a record with a specific type is in the current view based on the
     * selected month and filter.
     * @param tested_at - timestamp representing when a record was tested.
     * @param type - the type of the record.
     * @returns {boolean} - true if the record is in the current view, false otherwise.
     */
    function isNewRecordTypeInCurrentView (tested_at, type) {
      const same_month = moment(ctrl.selected_month).isSame(moment(tested_at), 'month');
      const same_type = ctrl.filter === 'all' || type === ctrl.filter;
      return same_month && same_type;
    }

    function _shouldShowFailTipsDialog (record) {
      return record.machine_name !== 'Control Spore Test' && (record.result === 'Failed' || record.result === 'Control Passed');
    }

    function handlePendingRecordAction ($event, new_record) {
      stznHelperService.showActionMessage(new_record);
      if(_shouldShowFailTipsDialog(new_record)){
        $timeout(function(){
          stznHelperService.failTipsDialog(new_record);
        }, 500);
      }
      var old_record = _.find(ctrl.pending_items, function(item){
        return item.id === new_record.id;
      });
      _.remove(ctrl.pending_items,old_record);
      var same_month = moment(ctrl.selected_month).isSame(moment(new_record.tested_at), 'month');
      var same_type = ctrl.filter === 'all' || new_record.type === ctrl.filter;
      if(same_month && same_type) {
        parseRecord(new_record);
        ctrl.display_items.push(new_record);
      }
    }

    function handleIncubationComplete ($event, ic_record) {
      var existing_record = _.find(ctrl.pending_items, function(item){
        return item.id === ic_record.id;
      });
      if (existing_record) {
        _.set(existing_record, 'result', 'Incubation Complete');
        alertHandler(existing_record, $event);
      }
    }

    function generateMonthsList () {
      ctrl.months_list = [];

      var first_month = moment(angular.copy(ctrl.office_first_date)).startOf('month');
      var current_month = moment().startOf('month');

      for (var i = current_month; i.isSameOrAfter(first_month, 'month'); i.subtract(1,'month') ) {
        var i_month = {
          date: i.format("YYYY-MM-DD"),
          name: i.format("MMMM YYYY"),
        };
        ctrl.months_list.push(i_month);
      }
    }

    function updateSelectedMonth () {
      ctrl.display_items = [];
      loadRecords();
      ctrl.month_name = moment(ctrl.selected_month).format("MMMM YYYY");
    }

    function updateFilter (value) {
      if (ctrl.filter === value) {
        return;
      }
      ctrl.filter = value;
      ctrl.display_items = [];
      ctrl.page = 1;
      fetchPeriodRecords({show_loader: true});
    }

    function parseRecord (record) {
      var ui_data = ctrl.records_ui_data[record.result];
      _.set(record, 'UI', ui_data);
      // notes backup in case of editing & canceling
      _.set(record, 'UI.notes', record.notes);
      if(record.result === 'Incubation Cycle'){
        setIncubationTimeout(record);
      }

      // For the Control Spore Test, set the type to 'spore' 
      // so when the user clicks edit, the correct form is shown
      if (record.machine_name === 'Control Spore Test') {
        record.type = 'spore'
      }

      _.each(record.images, function(image){
        _.set(image, 'UI.has_preview', stznHelperService.isImage(image.path));
        _.set(image, 'UI.thumbnail_url', stznHelperService.getThumbnailUrl(image.path));
      });

      return record;
    }

    function setIncubationTimeout (record) {
      var now = moment();
      var end_date = moment(record.tested_at).add(record.incubator_time, 'minutes');
      var ms_remaining = end_date.diff(now);
      var timeout_promise = $timeout(function(){
        _.set(record, 'result', 'Incubation Complete');
      }, ms_remaining);
      ctrl.timeout_promises.push(timeout_promise);
    }

    
    function openBundle (bundle) {
      ctrl.current_bundle = bundle;

      //Scroll to bundle
      $timeout(function(){
        var bundleId = "record-{0}".format(bundle.id);
        smoothScroll.scrollToElementById(bundleId, {offset : -100, speed: 0, onlyUp: true});
      },280); //280 ms to compensate for transition animation.
    }

    //
    // Close a Bundle
    //
    function closeBundle (bundle) {
      if (ctrl.current_bundle === bundle) {
        ctrl.current_bundle = null;
      }
      
    }

    //
    // Toggle a Bundle Open/Closed
    //
    function toggleBundle (bundle) {
      ctrl.current_bundle = (ctrl.current_bundle === bundle) ? null : bundle;
    }

    //
    // Is bundle the current bundle?
    //
    function isCurrentBundle (bundle) {
      return ctrl.current_bundle === bundle;
    }

    function alertHandler (record, $event) {
      // PASS/FAIL a Test
      if(record.result === 'Incubation Complete') {
        ctrl.pendingActionsDialog(record);
      }else if (record.result === 'Incubation Cycle') {
        // Check Test details
        ctrl.detailsDialog(record);
      }
    }

    function saveRecord (record) {
      addOrEditTest(null, record);
    }

    function cancelSave (record) {
      closeBundle(record);
      _.set(record, 'notes', record.UI.notes);
    }

    function getEndTimeLabel (test) {
      return ctrl.getTimeLabel(test.result_recorded_at);
    }

    /**
     * Retrieves the cycle number from the given record (if any)
     * @param {Object} record - The record object to evaluate
     * @returns {string} - The cycle number (if valid) or "N/A"
     */
    function getCycleNumberText(record) {
      const cycle_number = _getCycleNumber(record);
      if (Number.isNaN(parseInt(cycle_number))) {
        return $filter('translate')('MARKETPLACE.CART.NA');
      }
      return cycle_number + '';
    }

    function _getCycleNumber(record) {
      switch (record.type) {
        case 'biological':
          return record.bio_load_number;
        case 'chemical':
          return record.chem_load_number;
        case 'ultrasonic_cleaner':
        case 'washer_disinfector':
          return record.cycle_number;
        default:
          return null;
      }
    }

    function handleTestsPageEvent ({detail}) {
      switch(detail.action) {
        case 'add_test':
          return addOrEditTest()
        case 'incubation_complete':
          return stznHelperService.pendingActionsDialog(detail.record);
        case 'incubation_cycle':
          return stznHelperService.detailsDialog(detail.record);
      }
    }

    function _defineLocks () {
      ctrl.has_sterilization_v2_enabled = AccessService.hasSterilizationV2Enabled();
    }

  } // end controller

})();
angular.module('app.shared', ['app.config', 'ui.keypress', 'app.shared.ui', 'app.shared.system', 'app.shared.support'])
.config(['$stateProvider', '$urlRouterProvider', function($stateProvider, $urlRouterProvider) {
  
}]);


String.prototype.toProperCase = function () {
    return this.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
};

(function () {
  'use strict';

  angular.module('sowInventory')
  .directive('sowIiOnHand', sowInventoryItemOnHandDirective)
  .controller('sowInventoryItemOnHandController', sowInventoryItemOnHandController);

  function sowInventoryItemOnHandDirective () {
    return {
      restrict: 'E',
      replace: true,
      templateUrl: 'sow-inventory/directives/item-on-hand.html',
      controller: 'sowInventoryItemOnHandController',
      controllerAs: 'iioCtrl',
      scope: {
        'item': '=',
      }
    };
  }

  function sowInventoryItemOnHandController ($scope, $mdDialog, $rootScope, $timeout, $filter, inventoryItemService) {
    var ctrl = this;

    ctrl.addQuantity = addQuantity;
    ctrl.removeQuantity = removeQuantity;
    ctrl.getDisplayQuantity = inventoryItemService.calculateInventoryOnHand;
    ctrl.getTrackingMethod = getTrackingMethod;
    
    return ctrl;

    function addQuantity (ev) {
      if(ev.stopPropagation)
        ev.stopPropagation();
      if($scope.item.UI.is_medication) {
        return addMedicationSlideout(ev);
      } else {
        return openAddModal(ev);
      }
    }

    function removeQuantity (ev) {
      if(ev.stopPropagation)
        ev.stopPropagation();
      if($scope.item.UI.is_medication) {
        return removeMedicationSlideout(ev);
      } else {
        return openRemoveModal(ev);
      }
    }

    function addMedicationSlideout (ev) {
      $rootScope.$broadcast('close-inventory-slideout');
      $timeout(function(){
        // timeout needed bc somehow trying to close one slideout closed both of them
        $rootScope.$broadcast('medication-add-quantity', $scope.item);
      }, 500);
    }

    function removeMedicationSlideout (ev) {
      $rootScope.$broadcast('inventory-slideout-tab', 'medstock');
    }

    function openAddModal (ev) {
      $mdDialog.show({
        controller: 'itemOnHandAddModalController',
        templateUrl: 'templates/marketplace/inventory/modals/item-on-hand-add.html',
        parent: angular.element(document.body),
        clickOutsideToClose:true,
        targetEvent: ev,
        scope : $scope,
        preserveScope: true
      })
      .finally(function(){
        //Clean
        if($scope.item['in']){
          delete $scope.item['in'];
        }
      });
    }
    function openRemoveModal (ev) {
      $mdDialog.show({
        controller: 'itemOnHandRemoveModalController',
        templateUrl: 'templates/marketplace/inventory/modals/item-on-hand-remove.html',
        parent: angular.element(document.body),
        clickOutsideToClose:true,
        targetEvent: ev,
        scope : $scope,
        preserveScope: true
      })
      .finally(function(){
        //Clean
        if($scope.item['out']){
          delete $scope.item['out'];
        }
      });
    }

    function getTrackingMethod(item) {
      return _.get(item, 'tracking_method') === 'Package'
        ? $filter('translate')('COMMON.PACKAGE')
        : $filter('translate')('COMMON.ITEM')
    }

  }
}());

angular.module("app.marketplace.ui.dashboard")
.controller("OfficeDashboardController",
  function($scope, $rootScope, $filter, $state, sessionService, errorService, sowDashboardService, gettingStartedDialog, orderByFilter, sowBudgetService, sowReportsService2, sowApprovalService, membershipService, AccessService){
    //inventory, inventory_log, sterilization_records, all_orders, pendingShipments) {
    /*jshint validthis: true */
    var ctrl = this;
    // ctrl.active_filter = 'all';
    ctrl.active_filter = 'current';

    ctrl.selected_year = moment().format('YYYY');
    ctrl.current_year = moment();
    ctrl.show_by_category = false;

    ctrl.suppliers = [];
    ctrl.rangeOptions = [];
    ctrl.isEmpty = false;

    ctrl.inventory_stats = {
      medications:{
        total:0,
        per_office:0
      },
      inventory:{
        total:0,
        per_office:0
      },
      implants:{
        total:0,
        per_office:0
      }
    };

    ctrl.spending_by_category_stats =  {
      medications:{
        total:0,
        per_office:0
      },
      inventory:{
        total:0,
        per_office:0
      },
      implants:{
        total:0,
        per_office:0
      }
    };

    ctrl.totals = {
      total:  0,
      per_office: 0
    };

    ctrl.chart = {
      'data': [],
      'labels': [],
      'options': {
        'responsive': true,
        'maintainAspectRatio': true,
        'tooltips': {
          'enabled': true,
          'displayColors': false,
          'mode': 'single',
          'titleFontFamily': "'Roboto', 'Helvetica', 'Arial', 'Sans-Serif'",
          'footerFontFamily': "'Roboto', 'Helvetica', 'Arial', 'Sans-Serif'",
          'bodyFontFamily': "'Roboto', 'Helvetica', 'Arial', 'Sans-Serif'",
          'callbacks': {
            'label': function (tooltipItems, data) {
              return ' ' + $filter('currency')(tooltipItems.xLabel, '$', 2);
            },
          },
        },
        'scales': {
          'xAxes': [{
            'interval': 1000,
            'gridLines': {
              'drawTicks': true,
              'color': 'rgba(128,142,155,0.25)',
              'zeroLineColor': 'rgba(128,142,155,0.25)',
            },
            'ticks': {
              'fontFamily': "'Roboto', 'Helvetica', 'Arial', 'Sans-Serif'",
              'beginAtZero': true,
              'callback': function (value) {
                return $filter('currency')(value, '$', 0);
              },
            },
          }],
          'yAxes': [{
            'gridLines': {
              'display': false
            },
            'ticks': {
              'fontFamily': "'Roboto', 'Helvetica', 'Arial', 'Sans-Serif'",
            },
          }]
        },
        dataset: {
          barPercentage: 1,
          barThickness: 25,
          categoryPercentage:1,
        }
      },
      'overrides': {
        'borderWidth': 0,
        'borderColor': 'rgba(51,187,196,1)',
        'backgroundColor': 'rgba(51,187,196,1)',
        'hoverBackgroundColor': 'rgba(51,187,196,0.5)',
      }
    };

    ctrl.first_year = moment($scope.$root.current_office.created_at).year();
    ctrl.last_year = moment().year();
    ctrl.budgetReportData = [];
    ctrl.currentMonthData = {
        month: moment(moment().month() + 1, 'M').format('MMMM'),
        budget:0,
        spending:0,
        balance:{
            value: 0,
            text:'',
            color: '_black'
        }
    };
    ctrl.currentYearData = {
        year: moment().year(),
        budget: 0,
        spending: 0,
        balance:{
            value: 0,
            text: '',
            color: '_black'
        }
    };

    Array.prototype.sum = function (prop) {
        var total = 0
        for ( var i = 0, _len = this.length; i < _len; i++ ) {
          const num = Number(this[i][prop]);
          if (!Number.isNaN(num)) {
            total += num;
          }
        }
        return total
    }

    ctrl.sort = function(array, sortByName, sortOrder){
        var newArray = [];
        if(array.length > 0){
            newArray = orderByFilter(array, sortByName, sortOrder);
        }
        return newArray ;
    }

    ctrl.getBudgetReport = function(yearFrom, yearTo){
        sowBudgetService.getBudgetReport(yearFrom, yearTo).then(function(result){
            ctrl.mapBudgetReportData(result);
        });
    }
    
    ctrl.mapBudgetReportData = function(data){
        var report = data[0].report;
        var budget = report.budgets || [];
        var order = report.orders || [];

      setCurrentYearData(budget, order);

        if(budget.length > 0){
            budget = ctrl.sort(budget,'year', true);
            for(var i = 0 ; i < budget.length; i++){
                var perYear = {};
                perYear.year = budget[i].year;
                perYear.budget = budget[i].monthly_budget.sum('budget');

                if(order.length > 0){
                    order = ctrl.sort(order,'year', true);
                    for(var j = 0; j < order.length ; j++){
                        if(budget[i].year == order[j].year){
                            perYear.spending = order[j].monthly_orders.sum('total');
                        }
                    }
                }else{
                    perYear.spending = 0;
                }
                ctrl.budgetReportData.push(perYear);
            }
        }else{
            ctrl.getReportCurrentYear(ctrl.last_year);
            var perYear = {};
            perYear.year = ctrl.currentYearData.year;
            perYear.budget = ctrl.currentYearData.budget;
            perYear.spending = ctrl.currentYearData.spending;
            ctrl.budgetReportData.push(perYear);
        }

        if(ctrl.budgetReportData.length > 0){
            for(var i =0; i < ctrl.budgetReportData.length; i ++){
                var item = ctrl.budgetReportData[i];
                if(item.budget > item.spending){
                    item.balance ={
                        value: item.budget - item.spending,
                        color: '_balance',
                        text:'BUDGET.BALANCE_LEFT'
                    }
                }else if(item.budget < item.spending){
                    item.balance ={
                        value: item.spending - item.budget,
                        color: '_over',
                        text:'COMMON.OVER_BUDGET'
                    }
                }else{
                    item.balance ={
                        value: item.spending - item.budget,
                        color: '_black'
                    }
                }
            }
        }

    }

    ctrl.getReportCurrentYear = function(currentYear){
        sowReportsService2.getSpeedingOfficeReport(currentYear).then(function(result){
            ctrl.mapCurrentData(result);
        });
    }

    ctrl.mapNextYearData = function(data){
        var report = data[0].report;
        var budget = report.budgets || [];
        var monthlyBudget = [];
        if(budget.length > 0){
            monthlyBudget = budget[0].monthly_budget;
            if(monthlyBudget.length > 0){
                ctrl.budgetNextYear = monthlyBudget.sum('budget');
            }
        }
    }

    ctrl.mapCurrentData = function(data){
        var report = data[0].report;
        var budget = report.budgets || [];
        var monthlyBudget = [];
        var order = report.orders || [];
        var monthlyOrder =[];
        if(budget.length > 0){
            monthlyBudget = budget[0].monthly_budget;
        }

        if(order.length > 0){
            monthlyOrder = order[0].monthly_orders;
        }
        ctrl.setCurrentYearData(monthlyBudget, monthlyOrder);
        ctrl.setCurrentMonthData(monthlyBudget, monthlyOrder);
    }

    ctrl.setCurrentYearData = function(monthlyBudget, monthlyOrder){
        
        if(monthlyBudget.length > 0){
            ctrl.currentYearData.budget = monthlyBudget.sum('budget');
         }

         if(monthlyOrder.length >0){
             ctrl.currentYearData.spending = monthlyOrder.sum('total');
         }

         if(ctrl.currentYearData.budget > ctrl.currentYearData.spending){
             ctrl.currentYearData.balance = {
                 value: ctrl.currentYearData.budget - ctrl.currentYearData.spending,
                 text: 'COMMON.BALANCE',
                 color: '_balance'
             }
         }else if(ctrl.currentYearData.budget < ctrl.currentYearData.spending){
             ctrl.currentYearData.balance = {
                 value: ctrl.currentYearData.spending - ctrl.currentYearData.budget,
                 text: 'COMMON.OVER_BUDGET',
                 color: '_over'
             }
         }
    }

    ctrl.setCurrentMonthData = function(monthlyBudget, monthlyOrder){
        var currentMonth = moment(moment().month() + 1 , 'M').format('MMM');
        if(monthlyBudget.length > 0){
            for(var i =0; i < monthlyBudget.length; i++){
                if(currentMonth == monthlyBudget[i].month){
                    ctrl.currentMonthData.budget = monthlyBudget[i].budget;
                }
            }
        }

        if(monthlyOrder.length > 0){
            for(var i =0; i < monthlyOrder.length; i++){
                if(currentMonth == monthlyOrder[i].month){
                    ctrl.currentMonthData.spending = monthlyOrder[i].total;
                }
            }
        }

        if(ctrl.currentMonthData.budget > ctrl.currentMonthData.spending){
            ctrl.currentMonthData.balance = {
                value: ctrl.currentMonthData.budget - ctrl.currentMonthData.spending,
                color:'_balance',
                text: "COMMON.BALANCE"
            }
        }else  if(ctrl.currentMonthData.budget < ctrl.currentMonthData.spending){
            ctrl.currentMonthData.balance = {
                value: ctrl.currentMonthData.spending - ctrl.currentMonthData.budget,
                color:'_over',
                text: "COMMON.OVER_BUDGET"
            }
        }
    }

    ctrl.goToBudgetManager = function($event){
        $state.go('app.budget-detail', {year: ctrl.selectedYear});
    }

    ctrl.initBudget = function () {
        ctrl.getReportCurrentYear(ctrl.last_year);
        ctrl.getBudgetReport(ctrl.first_year,ctrl.last_year);
    }



    ctrl.update_range = update_range;
    ctrl.gsd = gsd;
    init();
    return ctrl;

    /**
     * @method init
     */
    function init() {
      // redirect to SDS in case user doesn't have dashboard permission
      if(_.isNil(_.get($rootScope, 'user_permissions.dashboard', null)) &&
          _.isNil(_.get($rootScope, 'user_permissions.master_permission', null))
        ){
        $state.go('app.sdslist');
      }
      ctrl.initBudget();

      ctrl.isEmpty = true;
      // wait until session's PUT request is completed, in case of a recent login
      sessionService.onInit()
      .then(function(){
        setup_offices();
        setup_range_options();
        load_current_inventory_value();
        load_suppliers_stats();

        var features = $scope.features;

        ctrl.show_by_category = features.inventory_full ? (features.medications || features.implants) : (features.medications && features.implants);
        ctrl.show_by_category = !ctrl.show_by_category ? false : ctrl.show_by_category;

        load_spending_by_category();
        fetchPendingApprovalCount();
        defineLocks();
        
      });
      $scope.$on('officeService: office-changed', function(){
        init();
      });
    }

    function gsd (ev) {
      return gettingStartedDialog.show(ev);
    }

    function empty_state(empty) {
      switch (empty) {
        case "inventory":
          ctrl.inventory_stats = {
            medications:{
              total:0,
              per_office:0
            },
            inventory:{
              total:0,
              per_office:0
            },
            implants:{
              total:0,
              per_office:0
            }
          };
          break;
        case "categories":
          ctrl.spending_by_category_stats =  {
            medications:{
              total:0,
              per_office:0
            },
            inventory:{
              total:0,
              per_office:0
            },
            implants:{
              total:0,
              per_office:0
            }
          };
          break;
        default:

          ctrl.chart = {
            'data': [],
            'labels': [],
            'options': {
              'responsive': true,
              'maintainAspectRatio': true,
              'tooltips': {
                'enabled': true,
                'displayColors': false,
                'mode': 'single',
                'titleFontFamily': "'Roboto', 'Helvetica', 'Arial', 'Sans-Serif'",
                'footerFontFamily': "'Roboto', 'Helvetica', 'Arial', 'Sans-Serif'",
                'bodyFontFamily': "'Roboto', 'Helvetica', 'Arial', 'Sans-Serif'",
                'callbacks': {
                  'label': function (tooltipItems, data) {
                    return ' ' + $filter('currency')(tooltipItems.xLabel, '$', 2);
                  },
                },
              },
              'scales': {
                'xAxes': [{
                  'interval': 1000,
                  'gridLines': {
                    'drawTicks': true,
                    'color': 'rgba(128,142,155,0.25)',
                    'zeroLineColor': 'rgba(128,142,155,0.25)',
                  },
                  'ticks': {
                    'fontFamily': "'Roboto', 'Helvetica', 'Arial', 'Sans-Serif'",
                    'beginAtZero': true,
                    'callback': function (value) {
                      return $filter('currency')(value, '$', 0);
                    },
                  },
                }],
                'yAxes': [{
                  'gridLines': {
                    'display': false
                  },
                  'ticks': {
                    'fontFamily': "'Roboto', 'Helvetica', 'Arial', 'Sans-Serif'",
                  },
                }]
              },
              dataset: {
                barPercentage: 1,
                barThickness: 25,
                categoryPercentage: 1,
              },
            },
            'overrides': {
              'borderWidth': 0,
              'borderColor': 'rgba(51,187,196,1)',
              'backgroundColor': 'rgba(51,187,196,1)',
              'hoverBackgroundColor': 'rgba(51,187,196,0.5)',
            }
          };
          break;
      }

    }

    /**
     * @method setup_offices
     * @description set the current office, check if has multiple offices
     */
    function setup_offices() {
      
      ctrl.memberships = sessionService.getOfficeMemberships();

      ctrl.is_individual_office =_.some(ctrl.memberships, function(membership) {
        return sow.officeInfo().id === membership.office.id && ['team'].includes(membership.membership_type);
      });

      ctrl.memberships =_.filter(ctrl.memberships, function(membership) {
        return ['manager','master'].includes(membership.membership_type);
      });



      ctrl.total_offices = ctrl.memberships.length;
      ctrl.has_multiple_office = ctrl.total_offices > 1;

      ctrl.current_office = sow.officeInfo();

    }

    /**
     * @method setup_range_options
     * @description set dropdown year
     */
    function setup_range_options () {
      var first_date = moment($scope.$root.current_office.created_at);

      var last_date = moment();
      var years_count = last_date.diff(first_date, 'y');

      ctrl.rangeOptions.push(last_date.format('YYYY'));
      for (var i = 1; i <= years_count; i++) {
        var year_number = moment().subtract(i,'y').format('YYYY');
        ctrl.rangeOptions.push(year_number);
      }
    }

    /**
     * @method update_range
     */
    function update_range() {
      load_suppliers_stats();
      load_current_inventory_value();
      load_spending_by_category();
    }

    /**
     * @method load_current_inventory_value
     */
    function load_current_inventory_value() {
      sowDashboardService.getCurrentInventoryValue()
      .then(function (stats) {
        if (!angular.equals({}, stats)) {
          parse_inventory_data(stats);
        }
      }).catch(function (error) {
        var t_message = $filter('translate')('ERRORS.VALUES_LOAD')
        errorService.uiErrorHandler(error || t_message);
      });
    }

    function gather_inventory_info(offices_ids, stats) {
      var info = [];
      _.each(offices_ids, function (office_id) {
        var office_stats = stats[office_id];
        var office_stat_array = Object.keys(office_stats).map(function (type) {
          return {
            total: office_stats[type].total_value,
            product_count: office_stats[type].product_count,
            inventory_type: type
          };
        });

        info = _.concat(info, office_stat_array);

      });

      var result = _.chain(info)
        .groupBy('inventory_type')
        .map(function(objects, inventory_type) {
            return {
              inventory_type: inventory_type,
              total: _.sumBy(objects, function (obj) {
                return parseFloat(obj.total, 10);
              }),
              product_count:_.sumBy(objects, function (obj) {
                return parseFloat(obj.product_count, 10);
              }),
            };
        })
        .value();

      return result;
    }

    function parse_inventory_data(stats){
      if(ctrl.has_multiple_office && ctrl.active_filter === 'all'){
        offices_ids = Object.keys(stats);
      } else {
        offices_ids = [ctrl.current_office.id];
        if(!stats[ctrl.current_office.id]){
          empty_state("inventory");
          return ;
        }
      }

      var result = gather_inventory_info(offices_ids, stats);

      var medications = _.find(result, {'inventory_type':"Medication Item"}) || {total: 0, product_count: 0};
      var implants = _.find(result, {'inventory_type':"Implant Item"}) || {total: 0, product_count: 0};
      var inventory = _.find(result, {'inventory_type':"General Item"}) || {total: 0, product_count: 0};
      var marketplace = _.find(result, {'inventory_type':"Marketplace Item"}) || {total: 0, product_count: 0};


      var medications_all_offices, implants_all_offices, inventory_all_offices = 0;
      if (ctrl.active_filter === 'current') {
        result_per_office = gather_inventory_info(Object.keys(stats), stats);
        medications_all_offices = _.find(result_per_office, {'inventory_type':"Medication Item"}) || {total: 0, product_count: 0};
        implants_all_offices = _.find(result_per_office, {'inventory_type':"Implant Item"}) || {total: 0, product_count: 0};
        inventory_all_offices = _.find(result_per_office, {'inventory_type':"General Item"}) || {total: 0, product_count: 0};
        marketplace_all_offices = _.find(result_per_office, {'inventory_type':"Marketplace Item"}) || {total: 0, product_count: 0};

      }

      var med_per_office, imp_per_office, inv_per_office, inv_product_count = 0;
      if(ctrl.active_filter === 'all' ){
        med_per_office = medications ? medications.total/ctrl.total_offices : 0;
        imp_per_office = implants ? implants.total/ctrl.total_offices : 0;
        inv_per_office = inventory ? (inventory.total + marketplace.total)/ctrl.total_offices: 0;

        inv_product_count = inventory.product_count + marketplace.product_count;
      } else {
        med_per_office = medications_all_offices ? medications_all_offices.total/ctrl.total_offices : 0;
        imp_per_office = implants_all_offices ? implants_all_offices.total/ctrl.total_offices : 0;
        inv_per_office = inventory_all_offices ? (inventory_all_offices.total + marketplace_all_offices.total)/ctrl.total_offices: 0;
        inv_product_count = inventory.product_count + marketplace.product_count;
      }


      ctrl.inventory_stats = {
        medications:{
          total: medications ? medications.total : 0,
          product_count: medications ? medications.product_count : 0,
          per_office: med_per_office
        },
        implants:{
          total: implants ? implants.total : 0,
          product_count: implants ? implants.product_count : 0,
          per_office: imp_per_office
        },
        inventory:{
          total: inventory ? (inventory.total + marketplace.total)  : 0,
          product_count: inv_product_count,
          per_office: inv_per_office
        }
      };


    }

    /**
    * @method load_spending_by_category
    */
   function load_spending_by_category() {
     sowDashboardService.getSpendingByCategory({year: ctrl.selected_year})
      .then(function (stats) {
        if (!angular.equals({}, stats)) {
          parse_spending_by_category_data(stats);
        }
      }).catch(function (error) {
        var t_message = $filter('translate')('ERRORS.CATEGORY_SPENDING')
        errorService.uiErrorHandler(error || t_message);
      });
   }

   function gather_spent_by_category(offices_ids, stats){
      var info = [];
      _.each(offices_ids, function (office_id) {
        var office_stats = stats[office_id];
        var office_stat_array = Object.keys(office_stats).map(function (type) {
          return {
            total: office_stats[type],
            inventory_type: type
          };
        });

        info = _.concat(info, office_stat_array);

      });

      var result = _.chain(info)
        .groupBy('inventory_type')
        .map(function(objects, inventory_type) {
            return {
              inventory_type: inventory_type,
              total: _.sumBy(objects, function (obj) {
                return parseFloat(obj.total, 10);
              })
            };
        })
        .value();

      return result;
   }

   function parse_spending_by_category_data(stats){
    if(ctrl.has_multiple_office && ctrl.active_filter === 'all'){
      offices_ids = Object.keys(stats);
    } else {
      offices_ids = [ctrl.current_office.id];
      if(!stats[ctrl.current_office.id]){
        empty_state("categories");
        // return ;
      }
    }

    var result = [];

    if(ctrl.active_filter === 'current' && !stats[ctrl.current_office.id]){
      result = {};
    }  else{
      result = gather_spent_by_category(offices_ids, stats);
    }

    var result_per_office = gather_spent_by_category(Object.keys(stats), stats);

    var total_offices = 1;

    total_offices = _.filter(ctrl.memberships, function(o) {
      return moment(o.office.created_at).isSameOrBefore(moment().year(ctrl.selected_year), 'year');
    }).length || 1;


    var medications_per_office = _.find(result_per_office, {'inventory_type':"Medication Item"}) || {total: 0};
    var implants_per_office = _.find(result_per_office, {'inventory_type':"Implant Item"}) || {total: 0} ;
    var inventory_per_office = _.find(result_per_office, {'inventory_type':"General Item"}) || {total: 0};
    var marketplace_per_office = _.find(result_per_office, {'inventory_type':"Marketplace Item"}) || {total: 0};

    var medications = _.find(result, {'inventory_type':"Medication Item"}) || {total: 0};
    var implants = _.find(result, {'inventory_type':"Implant Item"}) || {total: 0};
    var inventory = _.find(result, {'inventory_type':"General Item"}) || {total: 0};
    var marketplace = _.find(result, {'inventory_type':"Marketplace Item"}) || {total: 0};

    var total_spent_per_office =  medications_per_office.total + implants_per_office.total + inventory_per_office.total + marketplace_per_office.total;
    var total_spent =  medications.total + implants.total + inventory.total + marketplace.total;
    ctrl.totals = {
      total:  total_spent || 0,
      per_office: total_spent_per_office/total_offices
    };

    ctrl.spending_by_category_stats = {
      medications:{
        total: medications ? medications.total : 0,
        per_office: medications_per_office ? medications_per_office.total/total_offices : 0
      },
      implants:{
        total: implants ? implants.total : 0,
        per_office: implants_per_office ? implants_per_office.total/total_offices : 0
      },
      inventory:{
        total: inventory ? (inventory.total + marketplace.total) : 0,
        per_office: inventory_per_office ? (inventory_per_office.total + marketplace_per_office.total)/total_offices: 0
      }
    };


   }

    /**
     * @method load_suppliers_stats
     */
    function load_suppliers_stats () {
      var year_start = moment().year(ctrl.selected_year).startOf('y');
      var year_end = moment().year(ctrl.selected_year).endOf('y');

      sowDashboardService.getSupplierSpendingStats({year: ctrl.selected_year})
        .then(function (stats) {

          ctrl.isEmpty = Object.keys(stats).length === 0;
          if(!ctrl.isEmpty){
            build_chart(stats);
          }

        }).catch(function (error) {
          var t_message = $filter('translate')('ERRORS.SUPPLIER_STATS_LOAD')
          errorService.uiErrorHandler(error || t_message);
        });
    }

    function gather_supplier_info(offices_ids, stats){
      var info = [];
      _.each(offices_ids, function (office_id) {
        var office_stats = stats[office_id];
        var office_stat_array = Object.keys(office_stats).map(function (supplier) {
          return {
            total_spent: office_stats[supplier],
            supplier_name: supplier
          };
        });

        info = _.concat(info, office_stat_array);

      });

      var result = _.chain(info)
        .groupBy('supplier_name')
        .map(function(objects, supplier_name) {
            return {
              supplier_name: supplier_name,
              total_spent: _.sumBy(objects, function (obj) {
                return parseFloat(obj.total_spent, 10);
              })
            };
        })
        .value();

      var suppliers = _.chain(result)
        .sortBy(function (item) { return parseFloat(item.total_spent, 10); })
        .reverse()
        .value();


      var total_spent =  _.sumBy(suppliers, function(o) { return o.total_spent; });

      return {
        total_spent: total_spent,
        suppliers: suppliers
      };
    }


    /**
     * @method build_chart
     */
    function build_chart (stats) {
      var offices_ids = [];

      if(ctrl.has_multiple_office && ctrl.active_filter === 'all'){
        offices_ids = Object.keys(stats);
      } else {
        offices_ids = [ctrl.current_office.id];
        empty_state("suppliers");
        if(!stats[ctrl.current_office.id]){
          ctrl.isEmpty = true;
          empty_state("suppliers");
          return ;
        }
      }

      var current_spent = gather_supplier_info(offices_ids, stats);
      // var all_spent = gather_supplier_info(Object.keys(stats), stats);
      ctrl.chart.data = _.map( current_spent.suppliers.slice(0, 6), 'total_spent');
      ctrl.chart.labels = _.map( current_spent.suppliers.slice(0, 6), 'supplier_name');

    }

    function fetchPendingApprovalCount() {
      let pending_approval_count = 0;
      sowApprovalService.getPendingCount()
        .then(res => pending_approval_count = res || 0)
        .catch(err => errorService.uiErrorHandler(err))
        .finally(() => ctrl.pending_approval_count = pending_approval_count);
    }

    function defineLocks() {
      const membership = membershipService.get();

      const mkt_approval_condition = _.get(membership, 'user_properties.orders.approval_req', false);
      const po_approval_condition = _.get(membership, 'user_properties.purchase_orders.approval_req', false);
      ctrl.approvals_enabled = mkt_approval_condition || po_approval_condition;

      ctrl.gl_budget_enabled = AccessService.getOfficeProperty('gl_budget_enabled');
    }

    /** 
     * Parses spending and budget data from the current year for display in the UI. 
     * 
     * @param {Array} budget_years 
     * @param {Array} order_years 
     * 
     * @return {*} 
    */
    function setCurrentYearData(budget_years, order_years) {
      const current_year = new Date().getFullYear();
      const annual_budget = budget_years?.find(({ year }) => year === current_year);
      const annual_orders = order_years?.find(({ year }) => year === current_year);
      const total_budget = annual_budget?.total_budget || 0;
      const total_spent = annual_orders?.total_orders?.total || 0;
      const balance = total_budget - total_spent;
      ctrl.current_year_data = {
        budget: total_budget ? $filter('currency')(total_budget.toString()) : '$ -',
        spent: total_spent ? $filter('currency')(total_spent.toString()) : '$0',
        balance: total_budget ? $filter('currency')(balance?.toString()) : '$ -',
      }
    }

});

angular.module("app.marketplace.ui.inventory")
  .controller("inventoryItemOnHandController",
    function ($scope, $filter, $mdDialog, inventorySharingService) {

      $scope.item.tracking_method = $scope.item.tracking_method || 'Item';
      $scope.inventorySharingService = inventorySharingService;

      $scope.openAddModal = function (ev) {
        $mdDialog.show({
          controller: 'itemOnHandAddModalController',
          templateUrl: 'templates/marketplace/inventory/modals/item-on-hand-add.html',
          parent: angular.element(document.body),
          clickOutsideToClose: true,
          targetEvent: ev,
          scope: $scope,
          preserveScope: true
        })
          .finally(function () {
            //Clean
            if ($scope.item['in']) {
              delete $scope.item['in'];
            }
          });
      };
      $scope.openRemoveModal = function (ev) {
        $mdDialog.show({
          controller: 'itemOnHandRemoveModalController',
          templateUrl: 'templates/marketplace/inventory/modals/item-on-hand-remove.html',
          parent: angular.element(document.body),
          clickOutsideToClose: true,
          targetEvent: ev,
          scope: $scope,
          preserveScope: true
        })
          .finally(function () {
            //Clean
            if ($scope.item['out']) {
              delete $scope.item['out'];
            }
          });
      };

      $scope.expand_on_tracking_tab = function (item, group) {
        $scope.tabs.setActive('tracking');
        $scope.toggleItem(item, group);
      };

      $scope.trackingBy = function (type) {
        return type === $scope.item.tracking_method;
      };

      $scope.getPackagingType = function (item) {
        var t_item = $filter('translate')('COMMON.ITEM');
        var t_pack = $filter('translate')('COMMON.PACK');
        return item.tracking_method === 'Item'
          ? t_item
          : t_pack;
      };
    });
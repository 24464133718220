(function () {
  'use strict';
  angular.module('sowMarketplace')
    .controller('MarketplaceSectionController', MarketplaceSectionController);

  function MarketplaceSectionController() {
    /*jshint validthis: true */
    var ctrl = this;

    return ctrl;
  }

}());

angular.module('app.marketplace.ui.inventory')

.directive('packagingInfo', function() {
  return {
    restrict: 'E',
    controllerAs: 'ctrl',
    templateUrl: 'templates/marketplace/inventory/directives/packaging-info.html',
    scope: true,
    bindToController: {
      inventoryItem: '=',
      case: '=',
      orderPackageQuantity: '=',
    },
    controller: function($filter) {
      var ctrl = this;

      ctrl.getIcon = getIcon;
      ctrl.getText = getText;

      ////////////

      function getIcon(inventoryItem) {
        if (!inventoryItem) {
          return 'styles/img/icons/ic-item-product.svg';
        }

        if (inventoryItem.type === 'Medication Item'){
          return 'styles/img/medications/bottle.png';
        }

        return ctrl.case
          ? 'styles/img/icons/ic-pack.svg'
          : 'styles/img/icons/ic-item-product.svg';
      }

      /**
       * If the item has buy_by_case equals to true, return the locale with the case quantity, otherwise return the individual item locale
       * @param [caseQuantity=0] - The number of items in the case.
       * @returns a string.
       */
      function getText(caseQuantity = 0) {
        if (ctrl.case) {
          return parseInt(caseQuantity) > 0
            ? '{0} {1} {2}'.format(translate('COMMON.PACK_OF'), caseQuantity, translate('COMMON.ITEMS'))
            : translate('COMMON.PACKAGE');
        } else {
          return translate('COMMON.INDIVIDUAL_ITEM');
        }
      }

      function translate (key) {
        return $filter('translate')(key);
      }
    }
  };
});

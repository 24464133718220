angular.module('app.marketplace.elements').service('sterilizationRecordModel', function sterilizationRecordModel(apiUrl, commonApiService) {
  var _this = this;

  _this.model_data = {
    name: 'sterilizationRecord',
    display_name: 'Sterilization Record',
    api: {
      ac_indicator_companies: function (options) {
        if (!options.office_id) {
          throw 'Missing option(s) for path "ac_indicator_companies". Options: ' + JSON.stringify(options);
        }

        return sprintf('%s/offices/%s/biological_indicator_companies', apiUrl, options.office_id);
      },

      'update_record': function (options) {
        var data = {};

        _.forEach(options.fields, function (field) {
          data[field] = options.record[field];
        });

        return {
          'path': '{0}/offices/{1}/{2}_sterilization_records/{3}'.format(apiUrl, options.office.id, options.record.type, options.record.id),
          'params': {
            'method': 'PATCH',
            'data': data,
          }
        };
      },

      mark_failed: function (options) {
        if (!checkNested(options, 'office', 'id')) {
          throw 'Missing option(s) for path "mark_failed". Options: ' + JSON.stringify(options);
        }

        if (!checkNested(options, 'record', 'id')) {
          throw 'Missing option(s) for path "mark_failed". Options: ' + JSON.stringify(options);
        }

        if (options.record.type !== 'biological') {
          throw 'Can only mark biological records as Failed. Options: ' + JSON.stringify(options);
        }

        return {
          path: sprintf(
            '%s/offices/%s/biological_sterilization_records/%s',
            apiUrl, options.office.id, options.record.id
          ),
          params: {
            method: 'PATCH',
            data: {
              result: 'Failed'
            }
          }
        };
      },

      mark_passed: function (options) {
        if (!checkNested(options, 'office', 'id')) {
          throw 'Missing option(s) for path "mark_passed". Options: ' + JSON.stringify(options);
        }

        if (!checkNested(options, 'record', 'id')) {
          throw 'Missing option(s) for path "mark_passed". Options: ' + JSON.stringify(options);
        }

        if (options.record.type !== 'biological') {
          throw 'Can only mark biological records as Passed. Options: ' + JSON.stringify(options);
        }

        return {
          path: sprintf(
            '%s/offices/%s/biological_sterilization_records/%s',
            apiUrl, options.office.id, options.record.id
          ),
          params: {
            method: 'PATCH',
            data: {
              result: 'Passed'
            }
          }
        };
      },

      create: function(record){
        if (!record) {
          throw 'Missing record for sterilizationRecord path "create".';
        }

        if (!checkNested(record, 'office_id')) {
          throw 'Missing record.office_id for sterilizationRecord path "create".';
        }

        if (record.testType !== 'biological' && record.testType !== 'chemical') {
          throw 'Invalid record type "' + record.testType + '" for sterilizationRecord path "create".';
        }

        record = angular.copy(record);
        record.tester_id = record.tester.user_id;
        record.sterilizer_mode_id = record.sterilizer_mode.id;

        return {
          path: sprintf('%s/offices/%s/%s_sterilization_records', apiUrl, record.office_id, record.testType),
          params: {
            method: 'POST',
            data: record
          }
        };
      },

      fetch_all: function (options) {
        if (!checkNested(options, 'office', 'id')) {
          throw 'Missing option(s) for path "multiple". Options: ' + JSON.stringify(options);
        }

        var _params = {};
        var _filters = ['months', 'start_date', 'end_date', 'pending', 'failed', 'passed', 'limit'];

        for (var i = 0; i < _filters.length; ++i) {
          var _filterName = _filters[i];

          if (options.hasOwnProperty(_filterName)) {
            _params[_filterName] = options[_filterName] === true ? 1
                                 : options[_filterName] === false ? 0
                                 : options[_filterName];

          }
        }

        return {
          path: sprintf('%s/offices/%s/sterilization/records', apiUrl, options.office.id),
          params: {
            method: 'GET',
            params: _params
          }
        };
      },

      single: function (options) {
        if (!checkNested(options, 'data', 'id')) {
          throw 'Missing option(s) for path "single". Options: ' + JSON.stringify(options);
        }

        return {
          path: commonApiService.getOfficeUrl() + "/sterilization/records/" + options.data.id,
          params: {
            method: 'GET'
          }
        };
      }
    }
  };

  _this.autofill = function (record) {
    autofillDate(record, 'tested_at');
    autofillDate(record, 'created_at');
    autofillDate(record, 'updated_at');

    record.UI = record.UI || {};

    angular.extend(record.UI, {
          record_icon: record.result === 'Passed' ? 'assignment_turned_in'
                     : record.result === 'Failed' ? 'assignment_turned_in'
                     : 'assignment_late',

          record_icon_class: record.result === 'Passed' ? 'green-icon'
                           : record.result === 'Failed' ? 'red-icon'
                           : 'orange-icon',

          result_name_pretty: record.result === 'Passed' ? 'Passed'
                            : record.result === 'Failed' ? 'Failed'
                            : 'Pending',

          result_name: record.result === 'Passed' ? 'PASSED'
                     : record.result === 'Failed' ? 'FAILED'
                     : 'PENDING',

         result_class: record.result === 'Passed' ? 'sterilization-result-passed'
                     : record.result === 'Failed' ? 'sterilization-result-failed'
                     : 'sterilization-result-pending',

      type_name_short: record.type === 'chemical' ? 'CHEM'
                     : record.type === 'biological' ? 'BIO'
                     : undefined,

            type_name: record.type === 'chemical' ? 'Chemical Test'
                     : record.type === 'biological' ? 'Biological Test'
                     : undefined
    });
  };

  _this.fields = {
    id: {
      display_name: 'id',
      required: true,
      api: {
        submit: true
      },
      type: 'integer'
    },

    testType: {
      display_name: 'Record Type',
      required: true,
      api: {
        submit: true
      },
      type: 'string'
    },

    sterilizer: {
      display_name: 'Sterilizer',
      required: true,
      api: {
        submit: true
      },
      type: 'object'
    },

    sterilizer_mode: {
      display_name: 'Sterilizer Mode',
      required: true,
      api: {
        submit: true
      },
      type: 'object'
    },

    indicator_company: {
      display_name: 'Indicator Company',
      required: true,
      api: {
        submit: true
      },
      type: 'string'
    },

    wraps: {
      display_name: 'Wraps',
      required: true,
      api: {
        submit: true
      },
      type: 'integer'
    },

    pouches: {
      display_name: 'Pouches',
      required: true,
      api: {
        submit: true
      },
      type: 'integer'
    },

    cassettes: {
      display_name: 'Cassettes',
      required: true,
      api: {
        submit: true
      },
      type: 'integer'
    },

    office_id: {
      display_name: null,
      required: true,
      api: {
        submit: true
      },
      type: 'string'
    },

    result: {
      display_name: 'Result',
      required: false,
      api: {
        submit: true
      },
      type: 'string'
    },

    incubator_time: {
      display_name: 'Incubator Time (hrs)',
      required: true,
      api: {
        submit: true
      },
      type: 'integer'
    },

    notes: {
      display_name: 'Notes',
      required: true,
      api: {
        submit: true
      },
      type: 'string'
    },

    tested_at: {
      display_name: 'Tested At',
      required: true,
      api: {
        submit: true
      },
      type: 'DateTime'
    },

    tester: {
      display_name: 'Tester',
      required: true,
      api: {
        submit: true
      },
      type: 'object'
    },

    indicator_id: {
      display_name: 'Packet #',
      required: true,
      api: {
        submit: true
      },
      type: 'string'
    },

    included_general_products: {
      display_name: 'General Products',
      required: true,
      api: {
        submit: true
      },
      type: 'boolean'
    },

    included_other: {
      display_name: 'Other (specify in notes)',
      required: true,
      api: {
        submit: true
      },
      type: 'boolean'
    },

    included_implants: {
      display_name: 'Implants',
      required: true,
      api: {
        submit: true
      },
      type: 'boolean'
    },

    included_tape: {
      display_name: 'Tape',
      required: true,
      api: {
        submit: true
      },
      type: 'boolean'
    },

    included_strips: {
      display_name: 'Strip(s)',
      required: true,
      api: {
        submit: true
      },
      type: 'boolean'
    }
  };

  _this.data = {
    params: {
      model_name: 'sterilizationRecord'
    }
  };
});

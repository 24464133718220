(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.purchaseorders')
    .controller('poDialogController', poDialogController);

  function poDialogController($scope, $filter, $mdDialog, poHelperService, formService) {
    /*jshint validthis: true */
    $scope.openCalendar = poHelperService.openCalendar;

    var ctrl = this;

    ctrl.checkAnswer = checkAnswer;
    ctrl.answer = $scope.answer = answer;
    ctrl.hide = $scope.hide = hide;
    ctrl.cancel = $scope.cancel = cancel;
    ctrl.getUndoTitle = getUndoTitle;

    return ctrl;

    function hide() {
      $mdDialog.hide();
    };

    function cancel() {
      $mdDialog.cancel();
    };

    function answer(answer) {
      $mdDialog.hide(answer);
    };

    function checkAnswer(form, answer) {
      var valid_form = formService.checkRequiredFields(form);
      if (valid_form) {
        $scope.answer(answer);
      }
    }

    function getUndoTitle(action) {
      var map = {
        'Received': 'PO.UNDO_RECEIVED',
        'Cancelled': 'PO.UNDO_CANCELLED'
      };
      return $filter('translate')(map[action]);
    }

  }

}());

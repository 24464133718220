{
  angular.module('sowLanguage', [])
  .config(sowLanguageConfig);

  function sowLanguageConfig ($stateProvider, $urlRouterProvider) {
    // keeping this template with non-existing files in case we ever need 
    // a languages/localization page for some reason

    // $stateProvider
    // .state('app.language', {
    //   url: '/language',
    //   templateUrl: 'sow-language/views/language-page.html',
    //   controller: 'LanguageSectionController',
    //   controllerAs: 'langSecCtrl',
    //   data: {
    //     'title': 'Language',
    //     'action_bar_directive': 'action-bar-language',
    //     'require_log_in' : true,
    //   },
    // });
  }
}

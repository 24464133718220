(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.getting-started')
    .service('gettingStartedDialog', gettingStartedDialog);

  //
  // "Getting Started" Dialog Service
  //
  function gettingStartedDialog($mdDialog, $q) {
    /*jshint validthis: true */
    var service = this;

    service.promise = null;
    service.show = showDialog;
    service.showImplantsDialog = showImplantsDialog;
    service.showInventoryDialog = showInventoryDialog;
    service.showCertificationsDialog = showCertificationsDialog;
    service.showMedicationsDialog = showMedicationsDialog;
    service.showShoppingListDialog = showShoppingListDialog;

    return service;

    //
    // Show the "Getting Started" Dialog
    //
    function showDialog($event) {
      // deprecated on SPD-970, using appcues dialogs instead
      if (!service.promise) {
        service.promise = $mdDialog.show({
          'templateUrl': 'templates/marketplace/getting-started/getting-started-dialog.html',
          'controller': 'gettingStartedDialogCtrl',
          'controllerAs': '$ctrl',
          'targetEvent': $event,
          'escapeToClose': true,
          'parent': angular.element(document.body),
          'fullscreen': true,
        })
          .then(function (result) {
            service.promise = null;
            return result;
          }).catch(function (error) {
            service.promise = null;
            throw error;
          });
      }

      return service.promise;
    }

    function showImplantsDialog($event) {
      return customDialog($event, 'templates/marketplace/getting-started/getting-started-implants.html');
    }

    function showInventoryDialog($event) {
      return customDialog($event, 'templates/marketplace/getting-started/getting-started-inventory.html');
    }

    function showCertificationsDialog($event) {
      return customDialog($event, 'templates/marketplace/getting-started/getting-started-certifications.html');
    }

    function showMedicationsDialog($event) {
      return customDialog($event, 'templates/marketplace/getting-started/getting-started-medications.html');
    }

    function showShoppingListDialog($event) {
      return customDialog($event, 'templates/marketplace/getting-started/getting-started-shopping-list.html');
    }

    function customDialog($event, template_url) {
      return $mdDialog.show({
        'templateUrl': template_url,
        'controller': 'gettingStartedDialogCtrl',
        'controllerAs': '$ctrl',
        'targetEvent': $event,
        'escapeToClose': true,
        'parent': angular.element(document.body),
        'fullscreen': true,
      });
    }

  }

}());

angular.module('app.marketplace.ui.inventory')

.directive('inventorySupplierForm', function() {
  return {
    restrict: 'E',
    controllerAs: 'ctrl',
    templateUrl: 'templates/marketplace/inventory/directives/inventory-supplier-form.html',
    scope: true,
    bindToController: {
      context: '@',
      itemClone: '=',
      form: '=',
      action: '@'
    },
    controller: function(elementService) {
      var ctrl = this;

      ctrl.onSupplierSearch = onSupplierSearch;
      ctrl.supplierNames = [];
      
      ctrl.$onInit = init;

      return ctrl;
      
      //////////////
      
      function init () {
        ctrl.isCreate = ctrl.action === 'create';
        var supplier_name = ctrl.itemClone.supplier_name || _.isString(ctrl.itemClone.supplier) ? ctrl.itemClone.supplier : null;

        if(!supplier_name) {
          _.set(ctrl.itemClone, 'supplier', null);
          _.set(ctrl.itemClone, 'supplier_id', null);
          _.set(ctrl.itemClone, 'supplier_name', null);
        } else {
          var buffer = {
            'id': _.get(ctrl.itemClone, 'supplier_id'),
            'name': null,
          };
          if ( _.isString(ctrl.itemClone.supplier) ){
            buffer.name = _.get(ctrl.itemClone, 'supplier', null);
          } else {
            buffer.name = _.get(ctrl.itemClone, 'supplier_name', _.get(ctrl.itemClone, 'supplier.name', null));
          }
          _.set(ctrl.itemClone, 'supplier', buffer);
          _.set(ctrl.itemClone, 'supplierSearchText', ctrl.itemClone.supplier.name);
        }
      }

      function onSupplierSearch(search) {
        if (!search) {
          return false;
        }

        search = search.toLowerCase();
        return elementService.get('supplier', null, {
          endpoint: 'search',
          name: search
        })
        .then(function (data) {
          var suppliers = getSuppliersArray(data);
          var firstList = suppliers.filter(function(supplier) {
            return _.startsWith(supplier.name.toLowerCase(), search);
          });
          var secondList = suppliers.filter(function(supplier) {
            return _.includes(supplier.name.toLowerCase(), search);
          });

          return _.unionBy(firstList,secondList, "id");
        });
      }

      // The data format being returned by the API is correct, however, the
      // ElementService changes the data strucure from an array to an object
      //  with each supplier ID as an object key. I don't know why.
      function getSuppliersArray(data) {
        var suppliers = [];
        _.each(data, function(supplier) {
          suppliers.push({
            id: supplier.id,
            name: supplier.name
          });
        });

        return suppliers;
      }
    }
  };
});



angular.module('app.marketplace.ui.orders')
.controller('sowingoOrdersPendingController',
    function($scope, $rootScope, errorService, orderService, $mdDialog, sgToast){
  if(!$scope.pendingShipments){
    updateShipments();
  }

  function updateShipments () {
    return orderService.getPendingShipments()
    .then(function(shipments){
      $scope.pendingShipments = shipments;
    });
  }

  $scope.$on('mkt-shipment-updated', updateShipments);
  
  $scope.shipmentReceived = function(order_id, order_hrid, shipment_id, do_import, shipment){
    // sends an event so it can be picked up by sidenav controller.
    $rootScope.$broadcast('order-receive-open', shipment);
    return;
  };
})
.directive('sowingoOrdersPending', function() {
  return {
    restrict: 'E',
    replace: true,
    templateUrl: 'templates/marketplace/orders/directives/sowingo-orders-pending.html',
    controller: 'sowingoOrdersPendingController'
  };
});

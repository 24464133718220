(function () {
  'use strict';
  
  angular
  .module('sowReports')
  .service('sowImplantPlacementService', sowImplantPlacementService);
  
  function sowImplantPlacementService(apiUrl, serverAPI, errorService) {
    /*jshint validthis: true */
    var service = this;
    
    service.getImplantDashboard = getImplantDashboard;
    service.getSurgeonReport = getSurgeonReport;
    service.getPatientReport = getPatientReport;
    service.getPatientReportDetail = getPatientReportDetail;
    service.getSurgeonDetail = getSurgeonDetail;
    service.getReferringDoctorReport = getReferringDoctorReport;
    service.getReferringDoctorDetail = getReferringDoctorDetail;
    service.getPlacedList = getPlacedList;
    
    return service;
    
    function getImplantDashboard(start_date, end_date, previous_start_date, previous_end_date) {
      var office_id = sow.officeInfo().id;
      var url = '{0}/offices/{1}/reports/implants/placement?start_date={2}&end_date={3}&previous_start_date={4}&previous_end_date={5}'.format(apiUrl, office_id, start_date, end_date, previous_start_date, previous_end_date);
      
      var options = {
        'method': 'GET'
      };
      
      return serverAPI
      .doAPICall(url, options)
      .then(function (response) {
        return response.data;
      })
      .catch(errorService.uiErrorHandler);
    }
    
    //surgeon report 
    function getSurgeonReport(start_date, end_date, previous_start_date, previous_end_date) {
      var office_id = sow.officeInfo().id;
      var url = '{0}/offices/{1}/reports/implants/placement/surgeon?start_date={2}&end_date={3}&previous_start_date={4}&previous_end_date={5}'.format(apiUrl, office_id, start_date, end_date, previous_start_date, previous_end_date);
      
      var options = {
        'method': 'GET'
      };
      
      return serverAPI
      .doAPICall(url, options)
      .then(function (response) {
        return response.data;
      })
      .catch(errorService.uiErrorHandler);
    }
    function getSurgeonDetail(id,start_date, end_date) {
      var office_id = sow.officeInfo().id;
      var url = '{0}/offices/{1}/reports/implants/placement/surgeon/{2}?start_date={3}&end_date={4}'.format(apiUrl, office_id, id,start_date, end_date);
      
      var options = {
        'method': 'GET'
      };
      
      return serverAPI
      .doAPICall(url, options)
      .then(function (response) {
        return response.data;
      })
      .catch(errorService.uiErrorHandler);
    }
    //end surgeon report
    
    //patient report
    function getPatientReport(start_date, end_date) {
      var office_id = sow.officeInfo().id;
      var url = '{0}/offices/{1}/reports/implants/placement/patient?start_date={2}&end_date={3}'.format(apiUrl, office_id, start_date, end_date);
      
      var options = {
        'method': 'GET'
      };
      
      return serverAPI
      .doAPICall(url, options)
      .then(function (response) {
        return response.data;
      })
      .catch(errorService.uiErrorHandler);
    }
    
    function getPatientReportDetail(patientID) {
      var office_id = sow.officeInfo().id;
      const URIPatientID = encodeURIComponent(patientID);
      var url = '{0}/offices/{1}/reports/implants/placement/patient/{2}'.format(apiUrl, office_id, URIPatientID);
      var options = {
        'method': 'GET'
      };
      
      return serverAPI
      .doAPICall(url, options)
      .then(function (response) {
        return response.data;
      })
      .catch(errorService.uiErrorHandler);
    }
    //end patient report
    
    function getReferringDoctorReport(start_date, end_date, previous_start_date, previous_end_date) {
      var office_id = sow.officeInfo().id;
      var url = '{0}/offices/{1}/reports/implants/placement/doctor?start_date={2}&end_date={3}&previous_start_date={4}&previous_end_date={5}'.format(apiUrl, office_id, start_date, end_date, previous_start_date, previous_end_date);
      
      var options = {
        'method': 'GET'
      };
      
      return serverAPI
      .doAPICall(url, options)
      .then(function (response) {
        return response.data;
      })
      .catch(errorService.uiErrorHandler);
    }
    function getReferringDoctorDetail(id,start_date, end_date) {
      var office_id = sow.officeInfo().id;
      var url = '{0}/offices/{1}/reports/implants/placement/doctor/{2}?start_date={3}&end_date={4}'.format(apiUrl, office_id, id,start_date, end_date);
      
      var options = {
        'method': 'GET'
      };
      
      return serverAPI
      .doAPICall(url, options)
      .then(function (response) {
        return response.data;
      })
      .catch(errorService.uiErrorHandler);
    }
    
    //Placed list
    function getPlacedList(start_date, end_date) {
      var office_id = sow.officeInfo().id;
      var url = '{0}/offices/{1}/reports/implants/placement/details?start_date={2}&end_date={3}'.format(apiUrl, office_id, start_date, end_date);
      
      var options = {
        'method': 'GET'
      };
      
      return serverAPI
      .doAPICall(url, options)
      .then(function (response) {
        return response.data;
      })
      .catch(errorService.uiErrorHandler);
    }
  }
}());

(function () {
  'use strict';
  angular.module('sowOrders')
    .controller('OrderReceiveController', OrderReceiveController);

  function OrderReceiveController($scope, $filter, $q, $state, $rootScope, $timeout, $mdSidenav, sgToast, errorService,
    sowMedicationService, impHelperService, sowImplantService, sowShipmentService, PurchaseOrderService, sowAnalyticsService, AccessService) {
    /*jshint validthis: true*/
    var ctrl = this;

    ctrl.imp_expiry_years = impHelperService.generateExpiryYears();
    ctrl.imp_expiry_months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
    ctrl.order = null;
    ctrl.display_paywall = false;
    ctrl.update_supplier = false;
    ctrl.pending_auto_receive_items = 0;

    ctrl.mode = 'mkt'; // or PO

    ctrl.close = close;
    ctrl.receiveApp = receiveApp;
    ctrl.receiveComputer = receiveComputer;
    ctrl.addExpDate = addExpDate;
    ctrl.removeExpDate = removeExpDate;
    ctrl.removeBundle = removeBundle;
    ctrl.bundleQtyChange = bundleQtyChange;
    ctrl.goToCalendly = goToCalendly;
    ctrl.shouldHideSupplierCheckbox = shouldHideSupplierCheckbox;
    ctrl.$onInit = init;

    return ctrl;

    function init() {
      defineLocks();
      $scope.$on('order-receive-open', open);
      $scope.$on('po-receive-open', openPO);
      // setCloseEvent();
    }

    function setCloseEvent(callbackFn) {
      callbackFn = callbackFn || closeEvent;
      // $timeout(function(){
      //   $mdSidenav('order-receive')
      //   .then(function(instance) {
      //     instance.onClose(callbackFn);
      //   });
      // }, 3000);
      $mdSidenav('order-receive').onClose(closeEvent);
    }

    function open(event, shipment) {
      ctrl.order = shipment;
      sowAnalyticsService.logReceiveShipmentsClicked({order_hrid: shipment.order_hrid});
      if (ctrl.has_inventory_access) {
        parseItems(ctrl.order);
        setCloseEvent();
        return $mdSidenav('order-receive').open();
      } else {
        _handleNoInventoryAccessReceive(shipment)
      }
    }

    /**
     * Receives the shipment items without updating the inventory and opens the paywall slideout
     * @param {object} shipment
     */
    function _handleNoInventoryAccessReceive(shipment) {
      parseItems(ctrl.order, false);
      const receive_order = {
        'order_hrid': shipment.order_hrid,
        'shipment_id': shipment.shipment_id,
        do_import: false,
        items: ctrl.order.items,
        receiving_date: moment().utc().format(),
      }
      sowShipmentService.receiveItems(receive_order).then(() => {
        // Refresh the awaiting shipment list or the order details page
        if ($state.is('app.orders.awaiting_shipment')) {
          $rootScope.$broadcast('mkt-shipment-updated');
        } else {
          $rootScope.$broadcast('order-marked-as-received');
        }

        // Open the slideout with the paywall message
        ctrl.display_paywall = true
        $mdSidenav('order-receive').open();
      })
    }

    function openPO(event, purchase_order) {
      ctrl.order = purchase_order;
      ctrl.mode = 'po';
      parseItems(ctrl.order);
      if (ctrl.po_auto_receive) {
        return autoReceiveItems(ctrl.order.items);
      } else {
        setCloseEvent();
        return $mdSidenav('order-receive').open();
      }
    }

    function close() {

      // Call close event to update the shipment list, only if the user is on the awaiting shipment page
      // and the slideout content is not the paywall
      if ($state.is('app.orders.awaiting_shipment') && !ctrl.display_paywall) {
        closeEvent();
      }
      return $mdSidenav('order-receive').close();
    }

    function closeEvent() {
      $rootScope.$broadcast('mkt-shipment-updated', ctrl.order);
    }

    function parseItems(order, checkPaywall = true) {
      var implants_flag = sow.officeFeatures().implants;
      var implants_count = 0;
      var medications_flag = sow.officeFeatures().medications;
      var medications_count = 0;
      var inventory_flag = sow.officeFeatures().inventory_full;
      var inventory_count = 0;

      order.receiving_date = moment().utc().format();
      order.shipment_items = [];

      _.forEach(order.items, function (item) {
        var attrs = {
          'quantity_received': (ctrl.mode === 'mkt') ? item.quantity : item.receive_quantity,
          'UI': {
            'option': null,
          },
          'expiry_dates': item.expiry_dates || [],
          'item_bundles': [
            { 'qty': (ctrl.mode === 'mkt') ? item.quantity : item.receive_quantity }
          ],
        };
        _.extend(item, attrs);
        var type = _.get(item, 'item_type', null) || _.get(item, 'inventory_item.type', null);

        switch (type) {
          case "Medication Item":
          case "Marketplace Medication":
            medications_count++;
            if (medications_flag) {
              _.set(item, 'UI.option', 'comp');
              _.set(item, 'item_type', 'Marketplace Medication'); // fix for PO items
              order.shipment_items = _addShipmentItem(order.shipment_items, item);
            } else {
              return;
            }
            break;
          case "Implant Item":
          case "Marketplace Implant":
            implants_count++;
            if (implants_flag) {
              _.set(item, 'item_type', 'Marketplace Implant'); // fix for PO items
              order.shipment_items = _addShipmentItem(order.shipment_items, item);
            } else {
              return;
            }
            break;
          default:
          case "General Item":
          case "Marketplace Item":
            inventory_count++;
            if (inventory_flag) {
              _.set(item, 'item_type', 'Marketplace Item'); // fix for PO items
              order.shipment_items = _addShipmentItem(order.shipment_items, item);
            } else {
              return;
            }
            break;
        }
      });

      // Check if the paywall should be displayed
      if (checkPaywall) {
        const imp_wall = (!implants_flag && implants_count);
        const meds_wall = (!medications_flag && medications_count);
        const inv_wall = (!inventory_flag && inventory_count);
        
        // Validate that any of the items are implants, medications, or inventory items,
        // and the user doesn't have access to the respective feature (implants, medications, inventory)
        if (meds_wall || imp_wall || inv_wall) {
          ctrl.display_paywall = true;
        }
      }
    }

    /**
     * Adds a new shipment item to the shipment_items array, if the quantity is greater than 0
     * @param {object} shipment_items
     * @param {object} new_shipment
     * @returns {array}
     */
    function _addShipmentItem(shipment_items, new_shipment) {
      if(new_shipment.quantity > 0) {
        return [
          ...shipment_items,
          new_shipment
        ]
      }

      return shipment_items;
    }

    function addExpDate(item) {
      item.expiry_dates = item.expiry_dates || [];
      item.expiry_dates.push(null);
    }

    function removeExpDate(item, index) {
      // _.remove(item.expiry_dates, date);
      _.pullAt(item.expiry_dates, index);
    }

    /**
     * Auto-receives the item(s) the user has elected to receive
     * @param items - the items we're receiving
     */
    function autoReceiveItems(items = []) {
      // store the number of pending items we need to auto receive
      ctrl.pending_auto_receive_items = items.length;
      if (ctrl.pending_auto_receive_items === 1) {
        // handle the case where we're only receiving one item
        autoReceiveFinalItem(items[0]);
      } else {
        // handle the case where we're receiving multiple items
        autoReceiveMultipleItems(items);
      }
    }

    /**
     * Takes an array of items and receives all but the final one synchronously,
     * then receives the final one once all other calls have returned. This
     * ensures that the API will update the status of the PO correctly because if
     * all receive calls come in simultaneously it creates a race condition which
     * means that the PO will remain "Active" even if all items have been received
     * @param items - an array of items to be auto-received
     */
    function autoReceiveMultipleItems(items) {
      // make a copy of the items (we'll remove the last item on the next line)
      const all_items_but_one = [...items];
      // remove the last item and store it in its own variable called final_item
      const final_item = all_items_but_one.pop();
      // synchronously call all requests except the final one
      for (const item of all_items_but_one) {
        receiveApp(item)
          .finally(() => {
            // decrement the pending calls once this one returns
            ctrl.pending_auto_receive_items--;
            // if only one item remains, auto-receive it now
            if (ctrl.pending_auto_receive_items === 1) {
              autoReceiveFinalItem(final_item);
            }
          });
      }
    }

    /**
     * Receives an item then decrements the pending calls and shows a toast to the user
     * @param item - the item to be received
     */
    function autoReceiveFinalItem(item) {
      receiveApp(item)
        .finally(() => {
          // decrement the pending calls once this one returns
          ctrl.pending_auto_receive_items--;
          // show a success toast to the user
          const t_items_received = $filter('translate')('TOAST.ITEMS_RECEIVED');
          sgToast.showSimple(t_items_received);
        });
    }

    function receiveApp(item) {
      fixDates(item);
      var method = (ctrl.mode === 'mkt') ? markReceived : markPOReceived;
      _.set(item, 'UI.saving', true);
      return method(item, false, false)
        .finally(function () {
          $timeout(function () {
            _.set(item, 'UI.saving', false);
          }, 2000);
        });
    }

    function receiveComputer(item) {
      fixDates(item);
      var method = (ctrl.mode === 'mkt') ? markReceived : markPOReceived;
      _.set(item, 'UI.saving', true);
      return method(item, true, true)
        .finally(function () {
          $timeout(function () {
            _.set(item, 'UI.saving', false);
          }, 2000);
        });
    }

    function fixDates(item) {
      item.expiry_dates = _.filter(item.expiry_dates, function (this_date) {
        return (this_date instanceof Date && !isNaN(this_date));
      });
    }

    function markReceived(item, do_import, update_inventory) {
      var order = _.extend({}, ctrl.order, { 'items': [item], 'do_import': do_import, 'update_inventory': update_inventory });

      return sowShipmentService.receiveItems(order)
        .then(function (response) {
          _.set(item, 'UI.received', true);
          // _.set(item, 'UI.saving', false);

          if (item.item_type === 'Marketplace Medication') {
            var this_med = _.get(response, 'changes[0].medication_item', null);
            if (this_med) {
              updateMedicationInventory(item, this_med);
            } else {
              var t_message = $filter('translate')('ERRORS.MED_NOT_FOUND')
              errorService.uiErrorHandler(t_message);
            }
          } else if (item.item_type === 'Marketplace Implant') {
            var this_implant = _.get(response, 'changes[0].implant_item', null);
            if (this_implant) {
              updateImplantInventory(item, this_implant);
            } else {
              var t_message = $filter('translate')('ERRORS.IMPLANT_NOT_FOUND')
              errorService.uiErrorHandler(t_message);
            }
          }
          $scope.$broadcast('mkt-order-item-updated', response);
          checkAllReceived();
        })
        .catch(errorService.uiErrorHandler);
    }

    function updateMedicationInventory(med_poi, medication_item) {
      var promises = [];
      var med_bundles = med_poi.item_bundles;
      _.each(med_bundles, function (bulk_stock, index) {
        // var bulk_stock = med_bundles[index];
        var iterations = bulk_stock.qty && bulk_stock.qty !== "" ? parseInt(bulk_stock.qty) : 1;
        if (med_poi.buy_by_case) {
          var pack_size = _.get(medication_item, 'case_quantity', 1);
          iterations = parseInt(pack_size) * iterations;
        }
        var datestring = "{0}/{1}/{2}".format(bulk_stock.expiry_month, '01', bulk_stock.expiry_year);
        var stock_obj = {
          'medication_id': medication_item.id,
          'expiry_date': new Date(datestring),
          'lot_number': bulk_stock.lot_number,
          'quantity': iterations
        };
        promises.push(sowMedicationService.batchCreateMedicationStock(stock_obj));

        // _.each(iterations, function(iterations, j){
        // var stock_obj = {
        //   'medication_id': medication_item.id,
        //   'expiry_date': moment().year(bulk_stock.expiry_year).month(bulk_stock.expiry_month).toDate(),
        //   'lot_number': bulk_stock.lot_number
        // };
        //   promises.push(sowMedicationService.createOrUpdateMedicationStock(stock_obj));
        // });
      });

      return $q.all(promises);
    }

    function updateImplantInventory(order_item, implant_item) {
      var saving_item = { 'price': order_item.unit_cost };
      return sowImplantService.getInventoryGroups({ 'inventory_item_id': implant_item.inventory_item.id })
        .then(function (response) {
          saving_item.inventory_group = response[0].id || null;
          _.forEach(order_item.item_bundles, function (bundle) {
            var individual_implant = _.extend({}, saving_item, bundle);
            for (var i = bundle.qty - 1; i >= 0; i--) {
              sowImplantService.saveInventoryItem(individual_implant);
            }
          });
        });
    }

    function markPOReceived(item, do_import, update_inventory) {
      var type = _.get(item, 'inventory_item.type', null) || _.get(item, 'item_type', null);
      return PurchaseOrderService.receiveItems(ctrl.order, [item], ctrl.order.receiving_date, ctrl.update_supplier, update_inventory, ctrl.disable_supplier_price_update)
        .then(function (po_result) {
          if (update_inventory) {
            switch (type) {
              case 'Implant Item':
                poImplantReceive(item)
                  .then(function () {
                    finish(item);
                  });
                break;
              case 'Medication Item':
                poMedicationReceive(item)
                  .then(function () {
                    finish(item);
                  });
                break;
              default:
                finish(item);
                break;
            }
          } else {
            finish(item);
          }

          function finish(item) {
            _.set(item, 'UI.received', true);
            $timeout(function () {
              _.set(item, 'UI.saving', false);
            }, 2000);
            // broadcast the updated PO value (unless we still have multiple items to auto-receive)
            if (ctrl.pending_auto_receive_items < 2) {
              $rootScope.$broadcast('po_updated', po_result);
            }
            checkAllReceived();
          }

        })
        .catch(err => {
          close();
          const message = _getReceivePOItemErrorMessage(err);
          errorService.uiErrorHandler(message);
          $rootScope.$broadcast('invalid-po-item-request');
        });
    }


    /**
     * > When a PO is received, create an implant inventory item for each bundle in the PO
     * @param received_item - The item that was received.
     * @returns a promise.
     */
    function poImplantReceive(received_item) {
      var savingItem = { 'price': received_item.unit_cost, 'supplier_id': _.get(ctrl, 'order.supplier.id') };
      return sowImplantService.getInventoryGroups({ 'inventory_item_id': received_item.inventory_item.id })
        .then(function (response) {
          savingItem.inventory_group = response[0].id || null;
          _.forEach(received_item.item_bundles, function (bundle) {
            const savingBundle = _.extend({}, savingItem, bundle, {quantity: bundle.qty, disable_supplier_price_update: ctrl.disable_supplier_price_update });
            if(bundle.qty > 1) {
              // for multiple quantities, we use the batch endpoint to create items faster
              // the response will be a list (array) of IDs
              sowImplantService.batchCreateInventoryItems(savingBundle);
            } else {
              // for single quantity, we use the single item endpoint
              sowImplantService.saveInventoryItem(savingBundle);
            }
          });
        });
    }

    function poMedicationReceive(medication_po_item_received) {

      // var promises = [];
      // medication_po_item_received = received_medication_items[0];
      return sowMedicationService.getMedications({ inventory_item_id: [medication_po_item_received.inventory_item_id] })
        .then(function (medications) {
          var medication = medications[0];
          if (medication) {
            return updateMedicationInventory(medication_po_item_received, medication)
              .then(function (result) {
                _.set(medication_po_item_received, 'UI.received', true);
                medication_po_item_received.received_count = result.length;
                medication_po_item_received.medication = medication;
              });
          }
        });
    }

    function bundleQtyChange(item) {
      var total_qty = _.reduce(item.item_bundles, function (sum, bundle) { return sum + parseInt(bundle.qty); }, 0);
      var diff = (item.quantity_received - total_qty);
      if (diff > 0) {
        item.item_bundles.push({ qty: diff });
      }
    }

    function removeBundle(item, bundle) {
      _.remove(item.item_bundles, bundle);
      var last_bundle = _.last(item.item_bundles);
      last_bundle.qty = (last_bundle.qty * 1) + (bundle.qty * 1);
      if (last_bundle.qty > item.quantity_received) {
        last_bundle.qty = item.quantity_received;
      }
    }

    function checkAllReceived() {
      var all_received = true;
      _.forEach(ctrl.order.shipment_items, function (item) {
        if (!item.UI.received) {
          all_received = false;
        }
      });
      if (all_received) {
        $timeout(function () {
          close();
        }, 1000);
      }
    }

    function goToCalendly() {
      var url = "https://calendly.com/sowingosales/consultation";
      var win = window.open(url, '_blank');
      win.focus();
    }

    /** 
     * It returns true if items belong to supplier or the user_property disable_supplier_price_update is false
     */
    function shouldHideSupplierCheckbox() {
      return ctrl.order.itemsBelongToSupplier || ctrl.disable_supplier_price_update;
    }

    /**
     * It returns the error message from the server, or a default message if the server didn't provide one
     * @param error - The error object returned from the server.
     * @returns the error message or the translated message.
     */ 
    function _getReceivePOItemErrorMessage(error) {
      return error.message || $filter('translate')('PO.ITEM_NOT_FOUND');
    }

    function defineLocks () {
      ctrl.po_auto_receive = AccessService.getProperty('purchase_orders.auto_receive');
      ctrl.disable_supplier_price_update = AccessService.getProperty('purchase_orders.disable_supplier_price_update');
      ctrl.has_inventory_access = AccessService.hasGeneralInventoryAccess();
    }

  }

}());

(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.medications')
    .controller('medListController', medListController);

  function medListController ($scope, $rootScope, $state, $stateParams, $anchorScroll,
    $location, $mdSidenav, $timeout, sgToast, medHelperService, $mdDialog,
    $q, sowMedicationService, officeService, inventoryItemService, formService,
    dataSharingService, errorService, $filter, DeleteInventoryActivePODialog, AccessService) {
    /*jshint validthis: true */
    var ctrl = this;

    $scope.medicationsLoaded = false;

    ctrl.route_tags = [
        {
          name:"ORAL",
          active: false,
        },
        {
          name:"IV",
          active: false,
        },
        {
          name:"INTRAMUSCULAR",
          active: false,
        },
        {
          name:"SUBCUTANEOUS",
          active: false,
        },
        {
          name:"TOPICAL",
          active: false,
        },
        {
          name:"INHALATION",
          active: false,
        },
        {
          name:"INTRAMUCOSAL",
          active: false,
        },
      ];

    ctrl.search_text = '';
    ctrl.search_filter = search_filter;
    ctrl.product = {};

    ctrl.isCurrentBundle = isCurrentBundle;
    ctrl.openBundle = openBundle;
    ctrl.closeBundle = closeBundle;
    ctrl.set_active_tab = set_active_tab;
    ctrl.newSlideout = newSlideout;

    ctrl.dialogs = [];
    ctrl.add_quantity_dialog = add_quantity_dialog;
    ctrl.closeDialogs = closeDialogs;

    ctrl.is_all_tag_active = true;
    ctrl.check_if_all_tag_active = check_if_all_tag_active;
    ctrl.initial_tags_state = initial_tags_state;
    ctrl.open_discard_dialog = open_discard_dialog;
    ctrl.save_quantity_dialog = save_quantity_dialog;
    ctrl.add_medication_modal = add_medication_modal;
    ctrl.emptyStateText = emptyStateText;

    ctrl.close_dialogs = close_dialogs;
    ctrl.goto_med_detail = goto_med_detail;

    ctrl.add_to_shopping_list = add_to_shopping_list;
    ctrl.showProductDetails = showProductDetails;

    ctrl.parse_medications = medHelperService.parseMedications;

    ctrl.open_delete_medication_group = open_delete_medication_group;
    ctrl.delete_medication = delete_medication;
    ctrl.update_medication = update_medication;
    ctrl.expand_packaging = expand_packaging;
    ctrl.toggleExtraMenuDropdown = toggleExtraMenuDropdown;
    ctrl.shouldShowCartButton = shouldShowCartButton

    //add to purchase order;
    ctrl.checked_items = [];
    $scope.initial_medications = [];
    ctrl.check_item = check_item;
    ctrl.dataSharingService = {};
    init();

    return ctrl;

    function init () {
      if(!$rootScope.features.medications) {
        return;
      }
      var aid = sow.officeInfo().association_membership.professional_association_id;
      ctrl.show_gsd =
        sow.officeFeatures().aaoms_marketplace ||
        aid ==='aaoms' ||
        aid ==='dentsply' ||
        aid ==='neodent';
      dataSharingService.is_loading = true;
      dataSharingService.toRemoveItems = {};
      // ctrl.checked_items = dataSharingService.checked_items;
      ctrl.dataSharingService = dataSharingService;
      ctrl.dataSharingService.checked_items = [];
      ctrl.type = $stateParams.type;
      if(!$stateParams.type){
        $stateParams.type = "regular";
        ctrl.type = "regular";
      }

      var office = officeService.get();
      officeService.getLocations()
        .then(function(locations){
          ctrl.locations = locations;
        });

      ctrl.countryId = _.get(office, 'address.country.id');

      ctrl.is_emergency = false;
      if($stateParams.type !== "regular"){
        ctrl.is_emergency = true;
      }
      var filters = {
        is_emergency: ctrl.is_emergency
      };

      if($stateParams.focus_by_inventory_id){
        filters = {};
      }

      load_medications(filters);
      ctrl.years = load_years();

      $scope.$on('medication-item-updated', updateLocalMedication);
      $scope.$on('medication-item-deleted', handleMedicationDeleted);
      $scope.$on('medication-add-quantity', function(event, medication){
        add_quantity_dialog(medication, event);
      });
      $scope.$on('medication-stock-removed', handleMedStockRemoved);
      $scope.$on('medication-stock-added', handleMedStockAdded);

      defineLocks();
    }

    function handleMedStockRemoved (ev, stock_removed) {
      var index = _.findIndex(ctrl.medications, {id: stock_removed.medication_id});
      var display_index = _.findIndex(ctrl.display_medications, {id: stock_removed.medication_id});
      var final_qty = null;
      if (index) {
        var qty = _.toNumber(ctrl.medications[index].quantity);
        final_qty = (_.toNumber(qty) - 1);
        ctrl.medications[index].quantity = final_qty;
      }
      if (index && display_index && !!final_qty) {
        ctrl.display_medications[display_index].quantity = final_qty;
      }
    }

    function handleMedStockAdded (ev, medication, qty_added) {
      qty_added = _.toNumber(qty_added);
      var index = _.findIndex(ctrl.medications, {id: medication.id});
      var display_index = _.findIndex(ctrl.display_medications, {id: medication.id});
      var qty_to_add = qty_added >=1 ? qty_added : 1
      var final_qty = null;
      if (index) {
        var qty_now = (_.toNumber(ctrl.medications[index].quantity) || 0);
        final_qty = ( qty_to_add + qty_now );
        ctrl.medications[index].quantity = final_qty;
      }
      if (index && display_index && !!final_qty) {
        ctrl.display_medications[display_index].quantity = final_qty;
      }
    }

    function updateLocalMedication ($event, med) {
      var parsed_med = _.first(ctrl.parse_medications([med]));
      var index = _.findIndex(ctrl.medications, {id: med.id});
      if (index) {
        angular.extend(ctrl.medications[index], parsed_med );
      }
      var d_index = _.findIndex(ctrl.display_medications, {id: med.id});
      if (d_index) {
        angular.extend(ctrl.display_medications[d_index], parsed_med );
      }
      // Reapply filters to ensure they properly account for the updated med
      search_filter();
    }

    function handleMedicationDeleted ($event, med) {
      var med_index = _.findIndex(ctrl.medications, ['id', med.id]);
      var display_index = _.findIndex(ctrl.display_medications, ['id', med.id]);
      var display_item = ctrl.display_medications[display_index];

      if(display_index >= 0 && _.get(display_item, 'checked', false))
        check_item(display_item);
      if (med_index >=0)
        ctrl.medications.splice(med_index, 1);
      if (display_index >= 0)
        ctrl.display_medications.splice(display_index, 1);
      
      var params = $stateParams;
      if( _.get($stateParams, 'focus_product') ) {
        _.set(params, 'focus_product', null);
      }
      var t_message = $filter('translate')('TOAST.MED_DELETED');
      sgToast.showSimple(t_message);
      $state.transitionTo($state.current, params);
    }

    function newSlideout (medication, $event, tab) {
      if($event){
        $event.stopPropagation();
      }
      medication.open_more_menu = false;
      $rootScope.$broadcast('medication-item-slideout', medication, null, tab);
    }

    function add_medication_modal(){
      $mdDialog.show({
        controller: 'addMedicationController as addMedCtrl',
        templateUrl: 'templates/marketplace/medications/modals/add-medication.html',
        parent: angular.element(document.body),
        clickOutsideToClose:true,
        bindToController: true,
        locals:{
          med_name: ctrl.search_text
        }
      });
    }

    function goto_med_detail(id, type){
      $state.go('app.medications.detail', {id: id, type: type});
    }

    function reset(medication){


      var found = _.filter($scope.initial_medications, function(med){
        return med.id === medication.id;
      });

      var pos = _.findIndex(ctrl.display_medications, function(o) { return o.id === medication.id; });

      ctrl.display_medications.splice(pos, 1, found[0]);
    }

    function check_item (medication, $event) {
      if($event && $event.stopPropagation) {
        $event.stopPropagation();
      }
      medication.checked = !medication.checked;
      if(medication.checked){
        ctrl.dataSharingService.checked_items.push(medication);
      }else{
        _.each(ctrl.dataSharingService.checked_items, function(checked_item, i){
          if(checked_item && checked_item.id && checked_item.id === medication.id){
            ctrl.dataSharingService.checked_items.splice(i, 1);
          }
        });
      }
    }

    function load_years(){
      var twenty_years_from_now = [];
      for (var index = 0; index <= 20; index++) {
        twenty_years_from_now.push(moment().year() + index);
      }
      return twenty_years_from_now;
    }

    function toggleExtraMenuDropdown (medication, value){
      medication.open_more_menu = value;
      if(value || value===false){
        $scope.open_more_menu = value;
      }else{
        $scope.open_more_menu = !$scope.open_more_menu;
      }
    }

    function expand_packaging(){
      ctrl.is_bulk = !ctrl.is_bulk;
    }

    function load_medications(filters){

      sowMedicationService.getMedications(filters)
        .then(function(medications){
          var medication = null;
          if($stateParams.focus_by_inventory_id){

            var med = _.find(medications, {"inventory_item_id":$stateParams.focus_by_inventory_id });

            if (med.is_emergency) {
              // $stateParams.type = "emergency";
              ctrl.type = "emergency";
            } else {
              // $stateParams.type = "regular";
              ctrl.type = "regular";
            }

            $state.go('app.medications.list', {focus_product: med.id, type: ctrl.type, focus_by_inventory_id: null});


            //medications = _.filter(medications, { 'is_emergency': medication.is_emergency });
          }

          ctrl.medications = ctrl.parse_medications(medications);

          ctrl.display_medications = ctrl.medications;

          ctrl.dataSharingService.is_loading = false;

          if ($stateParams.focus_product) {
            medication = _.find(ctrl.medications, {"id":$stateParams.focus_product });
            $timeout(function() {
              scroll_to(medication);
              // openBundle(medication);
              newSlideout(medication, null, 'details');
            }, 2000);

          }
        }).catch(function(error){
          var t_message = $filter('translate')('ERRORS.MEDS_LOAD')
          errorService.uiErrorHandler(t_message);
        });
    }
    function scroll_to(med){
      $location.hash("medication-"+ med.id);
      $anchorScroll();
    }

    // deprecated
    function parse_medications(medications){
      _.each(medications, function(medication){

        medication.code = medication.barcode;
        if(medication.supplier_id){
          medication.supplier = {
            id: medication.supplier_id,
            name: medication.supplier_name
          };
        } else {
          medication.supplier = null;
        }

        if(medication.manufacturer_id){
          medication.manufacturer = {
            id: medication.manufacturer_id,
            name: medication.manufacturer_name
          };
        } else{
          medication.manufacturer = null;
        }


        if(! angular.equals({}, medication.po_status)){
          medication.purchase_order = true;
          var tooltip = '';
          var tooltip_text = '';
          var keys = Object.keys(medication.po_status);
          if (keys.includes('Received')) {
            keys.splice(keys.indexOf('Received'),1);
          }
          var po_array = [];
          _.each(keys, function(key){
            po_array = po_array.concat(medication.po_status[key]);
          });

          var t_in = $filter('translate')('COMMON.IN');
          var t_and = $filter('translate')('COMMON.AND');
          var t_from = $filter('translate')('COMMON.FROM');

          var poText = _.map(po_array, function (item) {
            return !item.po_supplier ? item.po_name
              : '{0} {1} {2}'.format(item.po_name, t_from, item.po_supplier);
          });

          tooltip = t_in+' ' + _.compact([
            poText.slice(0, -1).join(',\n'),
            poText.slice(-1),
          ]).join('\n'+t_and+' ');
          medication.tooltip = tooltip;

          if(keys.indexOf("Active") > -1){
            medication.tooltip_text = "ORDERS.ACTIVE";
          } else if(keys.indexOf("Draft") > -1){
            medication.tooltip_text = "ORDERS.DRAFT_ORDER";
          } else {
            medication.purchase_order = false;
          }

        }
      });


      return medications;
    }

    function add_to_shopping_list(inventory_item_id){
      inventoryItemService.addToShoppingList([{id: inventory_item_id}])
        .then(function(res){
          var text = $filter('translate')('COMMON.ADDED_TO_SL');
          sgToast.showSimple(text);
        }).catch(function(error){
          var t_message = $filter('translate')('ERRORS.MEDS_ADD_TO_LIST')
          errorService.uiErrorHandler(t_message);
        });
    }

    function showProductDetails (medication, $event) {
      $event.preventDefault();
      $event.stopPropagation();
      $rootScope.$broadcast('mkt-details-open-fetch', medication.product_id);
    }

    function open_delete_medication_group(med, $event){
      $event.stopPropagation();
      resetFocusProduct();
      med.open_more_menu = false;
      var active_orders = _.get(med, 'po_status.Active');
      if ( _.size(active_orders) ) {
        // prevent deleting
        DeleteInventoryActivePODialog.show([med], $event);
      } else {
        $mdDialog.show({
          controller: 'medListController',
          controllerAs: 'medlCtrl',
          templateUrl: 'templates/marketplace/medications/modals/delete-medication-group.html',
          parent: angular.element(document.body),
          clickOutsideToClose: true,
          bindToController: true,
          locals:{
            medication:med
          }
        });
      }
    }

    // in some cases, leaving the focus product on the URL interferes with other actions such as deleting
    function resetFocusProduct () {
      if ($stateParams.focus_product) {
        $state.transitionTo($state.current, _.extend($stateParams, {'focus_product':null}));
      }
    }

    function delete_medication(medication){
      close_dialogs();
      return sowMedicationService.deleteMedication(medication)
        .then(function(res){
          if( _.get($stateParams, 'focus_product') ) {
            _.set($stateParams, 'focus_product', null);
          }
          $state.reload().then(function(){
            var t_message = $filter('translate')('TOAST.MED_DELETED');
            sgToast.showSimple(t_message);
          });
        }).catch(function(error){
          var t_message = $filter('translate')('ERRORS.MED_DELETE')
          errorService.uiErrorHandler(t_message);
        });
    }

    function update_medication(medication){
      if(!formService.checkRequiredFields(ctrl.editMedicationForm[medication.id])){
        return;
      }
      var manufacturer = medication.manufacturer;
      var supplier = medication.supplier;

      medication.barcode = medication.code;
      medication.manufacturer_id = manufacturer ? manufacturer.id : null;
      medication.manufacturer_name = manufacturer ? manufacturer.name : medication.manufacturerSearchText;
      medication.supplier_id = supplier ? supplier.id : null;
      medication.supplier_name = supplier ? supplier.name : medication.supplierSearchText;

      medication.track_by = "Package";
      medication.minimum_level = medication.minimum_level !== "" ? medication.minimum_level : 0;

      sowMedicationService.createOrUpdateMedication(medication)
        .then(function(med){
          updateLocalMedication(null, med);

          // alertsDataCarrierService.load_alerts();
      var t_message = $filter('translate')('TOAST.MED_UPDATED');
          sgToast.showSimple(t_message);
        }).catch(function(error){
          var t_message = $filter('translate')('ERRORS.MED_UPDATE')
          errorService.uiErrorHandler(t_message);
        });

      closeBundle(medication, true);
    }

    function search_filter(){
      var tags_active_array = _.map(_.filter(ctrl.route_tags, {active: true}), 'name');


      ctrl.display_medications = _.filter(ctrl.medications, function(med){
        var search_text = (ctrl.search_text || '').toLowerCase();
        var name = (med.name || '').toLowerCase();
        var brand_name = (med.brand_name || '').toLowerCase();
        var manufacturer_name = (med.manufacturer_name || '').toLowerCase();
        var supplier_name = (med.supplier_name || '').toLowerCase();

        var search_flag = (
          name.includes(search_text) ||
          brand_name.includes(search_text) ||
          manufacturer_name.includes(search_text) ||
          supplier_name.includes(search_text)
        );

        if(!ctrl.is_all_tag_active){
          var truthy_val = at_least_one_true(med, tags_active_array) ;
          return truthy_val && search_flag;
        } else{
          return search_flag;
        }

      });

      function at_least_one_true(med, tags_active_array){
        var result = false;
        tags_active_array.forEach(function (tag) {
          // Tags are all caps but routes_of_admin are proper case (except IV)
          if (tag !== 'IV') {
            tag = tag.toProperCase();
          }
          if (med.routes_of_admin[tag]) {
            result = true;
          }
        });
        return result;
      }
    }


    function check_if_all_tag_active(){
      ctrl.is_all_tag_active = true;

      _.forEach(ctrl.route_tags, function(tag){
        if(tag.active){
          ctrl.is_all_tag_active = false;
        }
      });
      if( !ctrl.is_all_tag_active ){
        search_filter();
      }else{
        search_filter();
      }
    }

    function initial_tags_state(){
      ctrl.is_all_tag_active = true;
      _.forEach(ctrl.route_tags, function(tag){
        tag.active = false;
      });
      // Reapply filters in case the user has searched for a query
      search_filter();
    }

    function isCurrentBundle (bundle) {
      return ctrl.current_bundle && ctrl.current_bundle.id === bundle.id;
    }

    function openBundle (bundle) {
      bundle.open_more_menu = false;
      ctrl.current_bundle = bundle;
      ctrl.current_bundle.active_tab = bundle.active_tab ? bundle.active_tab : 0;
      ctrl.med_copy = angular.copy(bundle);
      scroll_to(bundle);
    }

    function set_active_tab (medication) {
      openBundle(medication);
    }

    function closeBundle (bundle, keep) {

      bundle.open_more_menu = false;
      bundle.active_tab = 0;
      if(!keep){
        angular.extend(bundle, ctrl.med_copy);
      }

      ctrl.current_bundle = null;
      // reset(bundle);
      // if (ctrl.current_bundle === bundle) {
      //   ctrl.current_bundle = null;
      // }
    }

    function add_quantity_dialog (medication, $event) {
      if($event && $event.stopPropagation){
        $event.stopPropagation();
        $event.preventDefault();
      }
      closeDialogs();
      $rootScope.$broadcast('add-medstock-slideout', medication);
    }

    function save_quantity_dialog(medication, $event){
      if (!formService.checkRequiredFields(ctrl.add_quantity_form)) {
        return 0;
      }

      var options = {
        'medication_id': medication.id,
        'expiry_date': moment().year(medication.adding.year).month(medication.adding.month).toDate(),
        'lot_number': medication.adding.lot,
        'location_id': medication.adding.location,
        'quantity': medication.adding.qty
      };

      ctrl.saving = true;

      return sowMedicationService
        .batchCreateMedicationStock(options)
        .then(function (result) {
          // medication.quantity += result.length;
          ctrl.closeDialogs();
          var t_message = $filter('translate')('INVENTORY.UPDATED_ON_HAND');
            sgToast.showSimple(t_message);
          medication.adding = {};
          $rootScope.$broadcast('medication-stock-added', medication, options.quantity);
          if (ctrl.med_copy && ctrl.med_copy.id === medication.id) {
            ctrl.med_copy = medication;
          }
          // alertsDataCarrierService.load_alerts();
        }).catch(function () {
          var t_message = $filter('translate')('ERRORS.ON_HAND_UPDATE')
          errorService.uiErrorHandler(t_message);
        })
        .finally(function () {
          ctrl.saving = false;
        });
    }

    function closeDialogs () {
      //  angular.copy({},ctrl.add_quantity_form);
      _.forEach(ctrl.dialogs, function(dialog){
        $mdSidenav(dialog).close();
      });
    }

    function close_dialogs(){
      $mdDialog.cancel();
      return;
    }


    ctrl.was_open = false;
    function open_discard_dialog(med, add_quantity_form){

      if(
        ctrl.was_open &&
        med.adding  &&
        !angular.equals(med.adding, {}) &&
        (med.adding.location || med.adding.lot || med.adding.qty || med.adding.month || med.adding.year)

      ){
        $mdDialog.show({
          controller: 'medListController',
          controllerAs: 'medListCtrl',
          templateUrl: 'templates/marketplace/medications/modals/discard-tracking-medication.html',
          parent: angular.element(document.body),
          clickOutsideToClose: true,
          bindToController: true,
          locals:{
            medication:med,
            add_quantity_form: add_quantity_form,
            was_open:  !ctrl.was_open
          }
        });
        ctrl.was_open = false;
      }
      closeDialogs();

    }

    function emptyStateText () {
      var empty_text = "No ";
      var tags = [];
      _.forEach(ctrl.route_tags, function(tag){
        if(tag.active){
          tags.push(tag.name);
        }
      });

      if (!ctrl.is_all_tag_active && tags.length > 0) {
        // var x = _.join(tags, ', ');
        var tags_text = "";
        for (var i = 0; i < tags.length; i++) {
          if (i > 0 && i < tags.length-1) {
            tags_text += ', ';
          }
          if (i > 0 && i === tags.length-1) {
            tags_text += ' or ';
          }
          tags_text += tags[i];

        }
        empty_text = empty_text + tags_text + ' ';
      }

      empty_text += "Medications in your list";
      return empty_text;
    }

    function defineLocks () {
      const add_new_lock_condition = AccessService.getProperty("medications.disable_add_new", false);
      ctrl.disabled_add_new = add_new_lock_condition;

      const delete_lock_condition = AccessService.getProperty("medications.disable_delete", false);
      ctrl.disabled_delete = delete_lock_condition;

      ctrl.hasCartAccess = AccessService.hasCartAccess();
    }

    function shouldShowCartButton(medication) {
      return medication.product_id && ctrl.hasCartAccess;
    }
  }


}());

(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.inventory')
    .service('DeleteLocationDialog', DeleteLocationDialog);

  function DeleteLocationDialog ($mdDialog) {
    /*jshint validthis: true */
    var service = this;

    service.show = showDialog;

    return service;

    function showDialog (location, ev) {
      return $mdDialog.show({
        'controller': ConfirmDeleteController,
        'templateUrl': 'templates/marketplace/inventory/confirm-delete-location.html',
        'targetEvent': ev,
        'parent': angular.element(document.body),
        'locals': {
          'location': location,
        },
        'clickOutsideToClose': true,
      }).then(
        function () { return true; },
        function () { return false; }
      );
    }
  }

  /* @ngInject */
  function ConfirmDeleteController ($scope, $mdDialog, location) {
    $scope.location = location;
    $scope.close = $mdDialog.cancel;
    $scope.cancel = $mdDialog.cancel;
    $scope.confirm = $mdDialog.hide;
  }
}());

(function () {
  'use strict';

  const loadingPage = {
    selector: 'loadingPage',
    controller: 'loadingPageCtrl',
    template: `
      <div style="height: 100%; width: 100%; display: grid; place-items: center;">

        <lottie-player ng-hide="$ctrl.use_fallback" src="https://sowingo-static.s3.amazonaws.com/animation/animation.json"
          background="transparent" speed="1" style="width: 300px; height: 300px;" loop autoplay id="lottie-page">
        </lottie-player>

        <div ng-show="$ctrl.use_fallback">
          <md-progress-circular md-mode="indeterminate" md-diameter="120px"></md-progress-circular>
          <img src="styles/img/branding/sowingo-logo.svg">
        </div>

      </div>
    `,
  }

  /** @ngInject */
  class loadingPageCtrl {
    constructor($scope) {
      this.$scope = $scope;
    }

    $onInit() {
      const elem = document.querySelector('#lottie-page');
      // Watch for changes to lottie's isLoaded property to determine whether to show the fallback spinner
      this.$scope.$watch(() => {
        return _.get(elem, '_lottie.isLoaded', false);
      }, (is_lottie_loaded) => {
        this.use_fallback = !is_lottie_loaded;
      });
    }

  }

  angular.module('app.shared.support')
    .controller(loadingPage.controller, loadingPageCtrl)
    .component(loadingPage.selector, loadingPage);

})();
angular.module('app.marketplace.system').controller('NotAuthorizedModalCtrl',
  function NotAuthorizedModalCtrl ($scope, $rootScope, $mdDialog, RouteService, AccountModalService) {
    $scope.wrongAccountType = true;
    $scope.requiredAccountType = 'Premium';
    $scope.currentAccountType = 'Free';

    $scope.back = function () {
      RouteService.goToDefault();
    };

    $scope.goToProfile = function () {
      $rootScope.$state.go('app.office.profile.subscription');
      $mdDialog.hide();
    };

    $scope.login = function () {
      AccountModalService.show('Login');
    };
  }
);

(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.subscriptions')
    .directive('marketplaceFeatures', marketplaceFeaturesDirective);

  function marketplaceFeaturesDirective ($log) {
    return {
      'restrict': 'E',
      'replace': true,
      'templateUrl': 'templates/marketplace/subscriptions/features-list.html',
      'controller': 'PlanFeaturesCtrl',
      'scope': {
        'isLarge': '@',
        'isCollapsed': '=',
      },
      'link': function ($scope, $element, $attrs, $ctrl) {
        $ctrl.init({
          'heading': 'Marketplace includes',
          'defaultClasses': 'marketplace-plan',
          'subtitle': 'all of these features:',
          'features': [
            'Browse through 1000\'s of products.',
            'All products are from licensed distributors and manufacturers.',
            'Price comparison shopping.',
            'Manage inventory for products available on the Marketplace.',
            'Sterilization record keeping.',
          ]
        });
      }
    };
  }
}());

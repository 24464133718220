angular.module("app.shared.support")
  .directive('clickAnywhereButHere', function (clickAnywhereButHereService) {
    return {
      restrict: 'A',
      link: function (scope, elem, attr, ctrl) {
        var handler = function (e) {
          e.stopPropagation();
        };
        elem.on('click', handler);

        scope.$on('$destroy', function () {
          elem.off('click', handler);
        });

        clickAnywhereButHereService(scope, attr.clickAnywhereButHere);
      }
    };
  });
angular.module('app.marketplace.elements').service('sessionModel',
  function sessionModel(apiUrl) {
    var _this = this;
    this.model_data = {
      name: 'session',
      display_name: 'Session',
      api: {
        // single: function (options) {
        //   return sprintf('%s/me/session', apiUrl);
        // },

        current: function (options) {
          return sprintf('%s/me/sessions/current' + (options && options.extended ? '?extended=true' : '?membership=true'), apiUrl);
        },

        logout: function (options) {
          return {
            path: sprintf('%s/login', apiUrl),
            params: {method: 'DELETE'}
          };
        },

        login: function (options) {
          if (!checkNested(options, 'account') && !checkNested(options, 'token')) {
            throw sprintf('Missing option(s) for account path \'login\'. Options: %s', JSON.stringify(options));
          }

          return {
            path: sprintf('%s/login', apiUrl),
            params: {
              method: 'POST',
              data: {
                email: options.account ? options.account.email : null,
                password: options.account ? options.account.password : null,
                token: options.token,
                invite_token: options.invite_token,
              }
            }
          };
        },

        config: function (options) {
          return sprintf('%s/config', apiUrl);
        },

        //
        // Update account, membership, and office flags
        //
        update_flags: function (options) {
          if (!checkNested(options, 'flags')) {
            throw sprintf('Missing option(s) for account path \'update_flags\'. Options: %s', JSON.stringify(options));
          }
          var flags = [
            'seen_po_video_tutorial',
            'prompted_for_premium',
            'prompted_for_premium_in_inventory_with_modal',
            'prompted_for_premium_in_inventory_with_banner',
          ];

          var flagData = {};
          _.map(flags, function(flag){
            if(_.has(options.flags, flag)){
              flagData[flag] = options.flags[flag];
            }
          });

          return {
            path: apiUrl + '/me',
            params: {
              method: 'PATCH',
              data: {flags: flagData},
            }
          };
        }

      }
    };

    this.autofill = function(element) {
    };

    this.fields = {
      'id': {
        'display_name': "id",
        'required': true,
        'api': {
          'submit': true
        },
        'type': "integer"
      },
      'user_id': {
        'display_name': "user_id",
        'required': true,
        'api': {
          'submit': true
        },
        'type': "integer"
      }
    };

    this.data = {
      'params': {
        'model_name': "session"
      } 
    };

  }
);

(function () {
  'use strict';

  angular.module('sowMarketplace')
    .directive('sowMktSearch', sowMarketplaceSearchDirective);

  function sowMarketplaceSearchDirective() {
    return {
      restrict: 'E',
      templateUrl: 'sow-mkt/directives/sow-mkt-search.html',
      controller: 'sowMktSearchController',
      controllerAs: 'mktsdCtrl',
      scope: {
        'destination': '=',
        'inputMode': '=',
      },
    };
  }


})();

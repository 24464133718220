(function () {
  'use strict';

  angular
    .module('sowTutorialVideos')
    .directive('sowCollapsibleMessage', CollapsibleMessageDirective);

  function CollapsibleMessageDirective ($log) {
    return {
      'restrict': 'C',
      'controller': CollapsibleMessageController,
      'controllerAs': 'cmCtrl',
      'templateUrl': 'sow-tutorial-videos/collapsible-message.html',
      'transclude': true,
      'scope': {
        'browserFlag': '@',
        'collapsedText': '@',
        'collapsedIcon': '@',
      },
      'link': function ($scope, $element, $attrs, $ctrl) {
        $ctrl.init($scope.browserFlag);
      }
    };
  }

  function CollapsibleMessageController ($scope, sowBrowserFlags) {
    /*jshint validthis: true */
    var ctrl = this;

    ctrl.isExpanded = false;
    ctrl.isCollapsed = false;
    ctrl.flagName = null;

    ctrl.init = init;
    ctrl.toggle = toggle;

    return ctrl;

    function init (flagName) {
      ctrl.flagName = flagName;
      refreshState();
    }

    function refreshState () {
      var value = sowBrowserFlags.flag(ctrl.flagName);
      ctrl.isCollapsed = value;
      ctrl.isExpanded = !value;
    }

    function toggle () {
      sowBrowserFlags.toggle(ctrl.flagName);
      refreshState();
    }
  }
}());

(function () {
  'use strict';

  angular
    .module('app.shared.support')
    .service('smoothScroll', SmoothScrollService);

  //
  // This code was adapted from this gist:
  //    https://gist.github.com/justinmc/d72f38339e0c654437a2
  //
  function SmoothScrollService () {
    /*jshint validthis: true */
    var service = this;

    service.getScrollPane = getScrollPane;
    service.setScrollPosition = setScrollPosition;
    service.getElementVPosition = getElementVPosition;
    service.getCurrentScrollPosition = getCurrentScrollPosition;
    service.scrollToElement = scrollToElement;
    service.scrollToElementById = scrollToElementById;
    service.scrollTo = scrollTo;

    return service;

    //
    // Return the scroll pane element
    //
    function getScrollPane () {
      var results = document.getElementsByClassName('main-view-port');
      return results[0];
    }

    //
    // Get the current scroll position of the content pane / scroll pane.
    //
    function getCurrentScrollPosition (scrollPane) {
      if (scrollPane) {
        return scrollPane.scrollTop || 0;
      }

      return (
        // Firefox, Chrome, Opera, Safari:
        window.pageYOffset ||

        // Internet Explorer 6 (standards mode)
        (
          document.documentElement &&
          document.documentElement.scrollTop
        ) ||

        // Internet Explorer 6, 7, 8
        document.body.scrollTop
      ) || 0;
    }

    //
    // Set the scroll position. If an element is passed, then we treat that as
    // the content pane, and set the content scroll position on that item.
    //
    function setScrollPosition (position, element) {
      if (element) {
        element.scrollTop = position;
      } else {
        window.scrollTo(0, position);
      }
    }

    function getElementVPosition (element, topElement) {
      var offset = _.get(element,'offsetTop',0);
      var node = element || {};

      while (node.offsetParent && node.offsetParent !== topElement) {
        node = node.offsetParent;
        offset += node.offsetTop;
      }

      return offset;
    }

    //
    // Scroll to an element by an ID.
    //
    function scrollToElementById (elementId, options) {
      var element = document.getElementById(elementId);
      return scrollToElement(element, options);
    }

    //
    // Scroll to an element. The same options as scrollTo apply.
    //
    function scrollToElement (element, options) {
      options = options || {};

      if (!options || !options.scrollPane) {
        options.scrollPane = getScrollPane();
      }
      var scrollToPosition = getElementVPosition(element, options.scrollPane);
      return scrollTo(scrollToPosition, options);
    }

    /*
     * Options:
     *  - speed: manually set speed of scroll
     *  - offset: offset distance of new scroll position.
     *  - onlyDown: only scroll if element is higher than current scroll position
     *  - onlyUp: only scroll if element is lower than current scroll position
     */
    function scrollTo (position, options) {
      if (!options.scrollPane) {
        options.scrollPane = getScrollPane();
      }

      var startYPosition = getCurrentScrollPosition(options.scrollPane);
      var stopYPosition = position + (options.offset || 0);

      var direction = stopYPosition > startYPosition ? 'down' : 'up';
      var distance = direction === 'down' ? stopYPosition - startYPosition : startYPosition - stopYPosition;

      if (distance < 100) {
        setScrollPosition(stopYPosition, options.scrollPane);
        return;
      }

      // Dont scroll if options direction is specified and direction does not comply
      if((options.onlyUp && direction === 'down') || (options.onlyDown && direction === 'up')){
        return;
      }

      var maxSpeed = 20;
      var speed = options.speed || options.speed===0 ? options.speed : Math.round(Math.min(distance / 100, maxSpeed));
      var step = Math.round(distance / 25);
      var leapY = direction === 'down' ? startYPosition + step : startYPosition - step;
      var timer = 0;
      var cmp = null;

      if (direction === 'up') {
        step = step * -1;
        cmp = function (i) { return i > stopYPosition; };
      } else {
        cmp = function (i) { return i < stopYPosition; };
      }

      function generateScrollStep (step) {
        return function () {
          setScrollPosition(step, options.scrollPane);
        };
      }

      for (var i = startYPosition; cmp(i); i += step) {
        setTimeout(generateScrollStep(leapY), timer * speed);
        leapY += step;
        if (direction === 'down' && leapY > stopYPosition || direction === 'up' && leapY < stopYPosition) {
          leapY = stopYPosition;
        }
        timer++;
      }
    }

  }

}());

angular.module("app.shared.support")
  .directive('inboxStyleItem', function ($log, $timeout) {
    return {
      restrict: 'E',
      transclude: true,
      replace: true,
      scope: {
        isOpen: '@',
        openItem: '&',
        closeItem: '&',
        item: '=model'
      },
      templateUrl: 'templates/shared/support/inbox_style/inboxStyleItem.html',
      link: function ($scope, $element, $attrs) {
        $scope.open = false;
        $scope.$parent.$watch($scope.isOpen, function (newValue) { $scope.open = newValue; });
        $scope.$watch('open', function (newValue) {
          if (newValue) {
            $element.addClass('is-open');
          } else {
            $element.removeClass('is-open');
          }
        });
      },
      controller: function ($scope) {
        this.setHeading = function (element) {
          this.heading = element;
        };
        this.setContent = function (element) {
          this.content = element;
        };
        this.scope = $scope;
      }
    };
  });
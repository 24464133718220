angular.module('app.marketplace.ui.cart').controller('CartDrawerController',
  function CartDrawerController($scope, $log, $timeout, sgToast, errorService, elementService, cartService, textInputUpdateDelay, officeService) {
    $scope.cartIsEmpty = true;
    var _oldQuantities = {};
    var _timers = {};
    var updateDelay = textInputUpdateDelay;

    $scope.updateItem = function (item) {

      var isEmpty = _.includes([null, undefined, ''], item.quantity);
      var oldQuantity = _oldQuantities[item.id];

      item.updating = true;

      if (isEmpty) {
        $log.debug('DEBUG: Quantity field is empty. Reverting to %o', oldQuantity);

        //
        // Note: Not sure why, but we need to force a digest cycle here.
        //
        $scope.$apply(function () {
          item.quantity = oldQuantity;
          item.updating = false;
        });
        return;
      }

      cartService.updateCartItem(item).then(function (response) {
      }).catch(function (error) {
        item.quantity = oldQuantity;
        errorService.uiErrorHandler(error);
      })['finally'](function () {
        item.updating = false;
      });
    };

    $scope.$watch('cart.items.length', function (newValue) {
      $scope.cartIsEmpty = !checkNested($scope, 'cart', 'items', 'length') || $scope.cart.items.length < 1;
    });

    $scope.$on('cartService: set-cart', function (scope, cart) {
      $scope.cart = cart;
    });

    $scope.$on('cartService: clear-cart', function (scope) {
      $scope.cart = null;
    });

    $scope.checkout = function () {
      $scope.toggleShoppingCart();
      $scope.$state.go('app.cart');
    };

    $scope.focusItem = function (item) {
      _oldQuantities[item.id] = item.quantity;
    };

    $scope.removeCartItem = function (item) {
      item.updating = true;

      cartService.removeCartItem(item).then(function (response) {
      }).catch(function (error) {
        errorService.uiErrorHandler(error);
      })['finally'](function () {
        item.updating = false;
      });
    };

    officeService.get(true).then(function () {
      $scope.cart = cartService.get();
    });
  }
);

(function () {
  'use strict';

  /** @ngInject */
  class ExternalVendorService {
    constructor($rootScope, officeService, membershipService, apiUrl, appEvents, serverAPI, sgToast, errorService, cartService, ExternalVendorCP, ExternalVendorInventoryCP, sowExternalVendorAccountService) {
      this.$rootScope = $rootScope;
      this.apiUrl = apiUrl;
      this.appEvents = appEvents;
      this.errorService = errorService;
      this.membershipService = membershipService;
      this.officeService = officeService;
      this.serverAPI = serverAPI;
      this.sgToast = sgToast;
      this.sowExternalVendorAccountService = sowExternalVendorAccountService;
      this.cartService = cartService;
      this.ExternalVendor = ExternalVendorCP.getClass();
      this.ExternalVendorInventory = ExternalVendorInventoryCP.getClass();
    }

    _getMembership () {
      return this.membershipService.onInit();
    }

    /**
     * > Get a list of available vendors for the given office
     * @param forceInvalidate - If true, the cache will be ignored and the API will be called.
     * @param officeId - The office ID to get the vendors for. If not provided, the current office ID
     * will be used.
     * @returns A promise that resolves to an array of ExternalVendor objects.
     */
    async getAvailableVendors (forceInvalidate, officeId) {
      const method = forceInvalidate ? this.serverAPI.callAPI : this.serverAPI.callCacheAPI;

      return this._getMembership()
      .then((membership) => {
        officeId = officeId || membership?.office?.id;
        const url = `${this.apiUrl}/${officeId}/external_vendors?marketplace_id=${membership?.current_marketplace?.id}`;
        const config = {'method': 'GET', 'params': {}};
        
        return method(url, config)
        .then(vendors_dict_json => {
          _.each(vendors_dict_json, (vendor) => {
            vendor = new this.ExternalVendor(vendor);
          });
          return vendors_dict_json;
        });
      });
    }

    /**
     * Get a list of vendors and a list of accounts. For each vendor, find the account that belongs to
     * it and add it to the vendor
     * @param forceInvalidate - This is a boolean value that tells the service to ignore the cache and
     * get the data from the server.
     * @param officeId - The office id of the office you want to get the vendors for. 
     * Will use current office by default.
     * @returns An array of vendors with their accounts.
     */
    async getVendorsWithAccounts (forceInvalidate, officeId) {
      const vendorsDict = this.getAvailableVendors(forceInvalidate, officeId);
      const accountsDict = this.sowExternalVendorAccountService.getAccounts(forceInvalidate, officeId);

      return this.fillVendorsAccounts(await vendorsDict, await accountsDict);
    }

    /**
     * "For each vendor in the vendors list, find the account that matches the vendor's id and add it
     * to the vendor object."
     * 
     * @param vendorsDict - The list of vendors that we want to merge with the accounts list.
     * @param accountsDict - The list of accounts that we want to merge with the vendorsList.
     * @returns An array of vendors with their associated accounts.
     */
    fillVendorsAccounts (vendorsDict, accountsDict) {
      // exit early with an empty object (dictionary) when there's no vendors
      if(!_.size(vendorsDict)){
        return {};
      }
      _.each(vendorsDict, (vendor, id) => {
        // get vendor's account and set as a property in it
        vendor.account = _.get(accountsDict, id);
      });
      return vendorsDict;
    }

    /**
     * It takes a productId and an officeId, and returns a promise that resolves to a list of
     * ExternalVendorInventory objects
     * @param productId - The product id of the product you want to search for
     * @param officeId - The officeId of the office you want to search for the product in.
     * @returns A dictionary of ExternalVendorInventory objects.
     */
    getVendorsForProduct (productId, officeId) {
      return this._getMembership()
      .then(membership => {
        officeId = officeId || membership?.office?.id;
        const url = `${this.apiUrl}/marketplaces/${officeId}/external_vendors/product_search/${productId}`;
        const config = {'method': 'GET'};

        return this.serverAPI.callAPI(url, config)
        .then(inventories_dict_json => {
          _.each(inventories_dict_json, (inventory) => {
            inventory = new this.ExternalVendorInventory(inventory);
          })
          return inventories_dict_json;
        });
      });
    }
    
    /**
     * It takes an item object, makes a POST request to the server, and then updates the cart
     * @param item - {
     * @returns The updated cart, including the item sent to this method.
     */
    addItemToCart (item) {
      const url = `${this.apiUrl}/me/cart/external_vendors/item`;
      const options = {
        method: 'POST',
        data: item
      };
      return this.serverAPI.callAPI(url, options)
      .then(updated_cart => {
        return this.cartService.updateCart(updated_cart);
      });
    }
    
    /**
     * It updates the price of an item in the cart.
     * @param item - The item object that you want to update.
     * @returns The updated cart, including the item sent to this method.
     */
    updateItemPrice (item) {
      const url = `${this.apiUrl}/me/cart/external_vendors/item/${item?.id}`;
      const options = {
        method: 'PATCH',
        data: item
      };
      return this.serverAPI.callAPI(url, options).then(updated_cart => {
        return this.cartService.updateCart(updated_cart);
      });
    }

    /**
     * It takes an Object with a key of cart_item_ids_to_update and a value
     * which is an Object where each key is the ID of a cart item and each
     * value is an Object with that cart item's quantity and updated price
     * (fetched from the microservice). It sends this data to the monolith
     * and gets back the updated cart, which it passes to the cart service.
     * @param items - An Object with the following shape:
     * ```
     * {
     *    cart_item_ids_to_update: {
     *       item_id_1: {
     *          quantity: 5,
     *          price: "25.00"
     *       },
     *       item_id_2: {
     *          quantity: 1,
     *          price: "37.50"
     *       },
     *    }
     * }
     * ```
     */
    batchUpdateItemPrice (items) {
      const url = `${this.apiUrl}/me/cart/external_vendors/item/batch_update`;
      const options = {
        method: 'PATCH',
        data: items
      };
      return this.serverAPI.callAPI(url, options)
        .then(updated_cart => {
          return this.cartService.updateCart(updated_cart);
        })
        .finally(() => {
          // broadcast the moment that the prices were refreshed to allow auto-refresh after
          // 15 minutes and to ensure that the loading state does not persist 
          this.$rootScope.$broadcast('external-vendors-prices-refreshed', moment());
        });
    }

    /**
     * Gets a list of actions that can be performed on a vendor.
     * All parameters are optional, and will only narrow down the list of actions returned.
     *
     * @param {Object} supplier - The supplier object, optional.
     * @param {Object} vendor - The vendor object that you want to get the actions for, optional.
     * @param {string} office_id - The office ID of the office you want to get the actions for. 
     * If not provided, the current office ID will be used.
     * @param {string} marketplace_id - The marketplace ID of the marketplace you want to get the actions for. 
     * If not provided, the current office ID will be used.
     * @returns {Object} A map of actions that can be performed on the vendor.
     */
    getVendorActions (supplier, vendor, office_id, marketplace_id) {
      return this._getMembership()
      .then((membership) => {
        office_id ??= membership.office.id;
        marketplace_id ??= membership.current_marketplace.id;

        const url = `${this.apiUrl}/vendor_integrations/${office_id}/actions`;

        const get_params = {
          'marketplace_id': marketplace_id
        };
        if (supplier?.id) get_params.supplier_id = supplier.id;
        if (vendor?.id) get_params.vendor_id = vendor.id;

        const options = {
          'method': 'GET',
          'params': get_params
        };
        return this.serverAPI.makeConcurrentCall(url, options);
      });
    }
    
  }

  angular.module('sowMarketplace')
    .service("sowExternalVendorService", ExternalVendorService);

})();

(function () {
  'use strict';
  angular.module('sowAlgolia')
  .service('sowAlgoliaService', sowAlgoliaService);

  // currentRefinedValues({
  //   container,
  //   [ attributes: [{name[, label, template, transformData]}] ],
  //   [ onlyListedAttributes = false ],
  //   [ clearAll = 'before' ] // One of ['before', 'after', false]
  //   [ templates.{header,item,clearAll,footer} ],
  //   [ transformData.{item} ],
  //   [ autoHideContainer = true ],
  //   [ cssClasses.{root, header, body, clearAll, list, item, link, count, footer} = {} ],
  //   [ collapsible = false ],
  //   [ clearsQuery = false ],
  //   [ transformItems ]
  // })

  function sowAlgoliaService ($rootScope, $window, $stateParams, sowAnalyticsService, sowMktService, errorService, SessionStorageService, ConfigService, sowLanguageService, $interpolate, AlgoliaHelperService, AccessService) {
    /*jshint validthis: true */
    var service = this;
    var refinementItemCustomTemplate = 
      '<div class="ais-refinement-list--item">'
        +'<label class="ais-refinement-list--label layout-row layout-align-start-center">'
          +'<input type="checkbox" class="ais-refinement-list--checkbox" value="{{label}}" {{#isRefined}}checked{{/isRefined}}>'
          +'<span class="checkbox-custom rectangular"></span>'
          +'<div class="ais-refinement-list--text">{{label}}</div>'
          +'<span class="ais-refinement-list--count flex-none">({{count}})</span>'
        +'</label>'
      +'</div>';

    // Methods
    service.addWidget = addWidget;
    service.addWidgets = addWidgets;
    service.addBasicWidgets = addBasicWidgets;
    service.getAisInstance = getAisInstance;
    service.initAisInstance = initAisInstance;
    service.terminateInstance = terminateInstance;
    service.customSearch = customSearch;
    service.directSuggestionSearch = directSuggestionSearch;
    service.directQuerySuggestionSearch = directQuerySuggestionSearch;
    service.searchIndex = null;
    service.querySearchIndex = null;
    service.initAlgoliaIndex = initAlgoliaIndex;
    service.getFacetsForCurrentLanguage = getFacetsForCurrentLanguage;

    // Variables    
    service.apiKey = ConfigService.get('algoliaAPIKey');
    service.appID = ConfigService.get('algoliaAppId');
    service.algolia_prefix = ConfigService.get('algoliaPrefixIndex');
    service.ALGOLIA_INDEX_NAME_V1 = 'MarketplaceVendorInventory';
    service.ALGOLIA_INDEX_NAME_V2 = 'MarketplaceProductQuery';
    service.ALGOLIA_FACET_FILTER_NAME_V1 = 'marketplace_id'; 
    service.ALGOLIA_FACET_FILTER_NAME_V2 = 'marketplaces.marketplace_id'; 


    // This is used to store the current numeric filters applied on the search
    service.numeric_refinements_backup = null; 

    var SEARCH_SUGGESTION_TYPE = {
      'category': 'category',
      'subcategory': 'subcategory',
      'query': 'query'
    }
    const price_widget_name = 'vendor_inventory.price';
    const category_list_class = 'algolia-widget-category-list';

    initAlgoliaIndex();

    return service;

    function addWidget (widget) {
      var ais = getAisInstance();
      ais.addWidget(widget);
    }

    function initAisInstance (ais_params, remove_basic_widgets, custom_url) {
      SessionStorageService.clear();
      const algolia_index_name = AccessService.hasAlgoliaV2Enabled() ? service.ALGOLIA_INDEX_NAME_V2 : service.ALGOLIA_INDEX_NAME_V1;
      const index_products = service.algolia_prefix+algolia_index_name;

      $rootScope.algolia_loading = false;
      $rootScope.algolia_custom_url = custom_url;

      var serviceSearchClient = {
        'search': customSearch,
      };

      // Note: We are using instantsearch.js v2 and its documentation can be found here: https://web.archive.org/web/20221104150157/https://community.algolia.com/instantsearch.js/v2/widgets.html
      $window.ais = instantsearch({
        indexName: index_products,
        searchClient: serviceSearchClient,
        searchParameters: {
          // Required for the price widget to work. It adds the price facet on the payload.
          facets: [price_widget_name], 
        },
      });

      if(!remove_basic_widgets)
        addBasicWidgets();

      if(_.get(ais_params, 'facets', null)){
        const filteredFacets = getFacetsForCurrentLanguage(ais_params.facets);
        addWidgets(filteredFacets);
      }
      // $window.ais.start();
      return $window.ais;
    }

    function initAlgoliaIndex() {
      // Init AlgoliaSearch from JS v4
      _setAlgoliaSearchIndexes();
      
      // Get Marketplace Categories + SubCategories in One Array from the session storage
      // These marketplaces will be used as a referance to map the
      // `category.id` and the `category.name`
      sowMktService.getCategories(false)
      .then(function(response){
        service.marketplaceCategories = _.get(response, 'categories', []);
      });

      $rootScope.$on('officeService: office-changed', function () {
        // Re-initialize Algolia search indexes because the office has changed
        _setAlgoliaSearchIndexes();
      });
    }

    /**
     * Initializes Algolia search indexes based on the version of Algolia enabled.
     */
    function _setAlgoliaSearchIndexes () {
      const algolia_index_name = AccessService.hasAlgoliaV2Enabled() ? service.ALGOLIA_INDEX_NAME_V2 : service.ALGOLIA_INDEX_NAME_V1;
      
      const client = algoliasearch(service.appID, service.apiKey);
      service.searchIndex = client.initIndex(`${service.algolia_prefix}${algolia_index_name}`);
      service.querySearchIndex = client.initIndex(`${service.algolia_prefix}${algolia_index_name}_query_suggestions`);
    }

    function getAisInstance () {
      if(!$window.ais){
        initAisInstance();
      }
      return $window.ais;
    }

    function customSearch (requests) {
      $rootScope.$broadcast('algolia_loading', true);
      const algolia_params = _.cloneDeep(requests[0].params);
      AlgoliaHelperService.syncUrlWithAlgoliaParams(algolia_params); // this call must be before navigation_params is created

      // pass null as the navigation_params prototype to prevent prototype pollution attacks
      const navigation_params = Object.create(null);

      // uses ui-router parameters for category and others
      navigation_params.category_id = $stateParams.cat_id || null;
      navigation_params.subcategory_id = $stateParams.sub_id || null;
      navigation_params.manufacturer_id = $stateParams.mf_id || null;
      navigation_params.query = $stateParams.query || $stateParams.search || null;
      navigation_params.tag = $stateParams.tag || null;
      navigation_params.vendor_id = $stateParams.vendor_id || null;
      navigation_params.page = $stateParams.page || null;
      navigation_params.on_sale_filter = $stateParams.on_sale || null;

      // SPD-5784: new filters, starting from the favourites page 
      // but could extend to others
      if ($stateParams.product_filters) {
        const product_filters = JSON.parse($stateParams.product_filters);
        _.extend(navigation_params, product_filters);
      }

      // probably going to use something like 
      // 'facets': $stateParams.facets || null

      if (!$rootScope.algolia_custom_url && $stateParams.page_type) {

        // Note for, 8.7.17, we decided to use the sales_tracker url
        // which requires the URL query param of 'sales_all=true'
        if ($stateParams.page_type == 'promos') {
          _.set(navigation_params, 'sales_all', true);
        } else {
          _.set(navigation_params, $stateParams.page_type, true);
        }
      }

      if ($stateParams.sale_filter) {
        _.set(navigation_params, $stateParams.sale_filter, true);
      }


      const categoryListDiv = document.getElementsByClassName(category_list_class)[0];
      // Check if the new filter contains a Category filter and the category list div exists
      const hasParentCategory = $stateParams.facet_filters?.includes('parent_category') && categoryListDiv

      // If it does, disable the category list div
      if (hasParentCategory) {
        categoryListDiv.classList.add('disabled');
      }

      // add restricted fields for localization
      _.set(algolia_params, 'restrictSearchableAttributes', getSearchAttributes());
      return sowMktService.searchQuery(navigation_params, algolia_params, $rootScope.algolia_custom_url)
      .then(function(data){
       
        // Re-enable the category list div if it was disabled
        if (hasParentCategory) {
          categoryListDiv.classList.remove('disabled');
        }

        // XGH hack, shamelessly used to workaround algolia's needs for data structure
        data.results[1] = data.results[2] = data.results[3] = data.results[0];
        $rootScope.$broadcast('algolia_data', data);
        var facets = _.get(data, 'results[0].facets', null);
        if (facets) {
          handleDynamicWidgets(facets);
        }

        // for analytics logging of empty search, first check for no results
        if (data.total_products === 0 ) {
          var all_params = _.extend({}, navigation_params, algolia_params);
          // then exclude the following:
          // related products, favourites, promos
          if(!all_params.related_products && !all_params.favourites && !$rootScope.algolia_custom_url) {
            sowAnalyticsService.logEmptySearch( all_params );
          }
        }
        return data;
      })
      .catch(function(error){
        errorService.uiErrorHandler(error);
      })
      .finally(function(){
        $rootScope.$broadcast('algolia_loading', false);
      });
    }

    function addBasicWidgets () {
      var ais = getAisInstance();
      const userLang = sowLanguageService.getCurrentLanguage();

      const widgetsList = [
        instantsearch.widgets.clearAll({
          container: '#clearAll',
          autoHideContainer: false,
          clearsQuery: false,
          templates: {
            'header': 'Filters',
            'link': localize('ACTIONS.CLEAR_FILTERS'),
          },
          cssClasses: {
            root: 'algolia-widget no-border layout-row layout-align-space-between-center',
            header: 'main'
          }
          // styles overwritten in mkt-search.less
        }),
        instantsearch.widgets.currentRefinedValues({ 
          container: '#currentRefinementsSidenav', 
          clearsQuery: false,
          templates: {
            clearAll: localize('ACTIONS.CLEAR_FILTERS')
          },
          cssClasses:{
            "root": "algolia-widget no-border",
            "body": "flex layout-row",
            "list": "flex layout-row layout-wrap"
          }
        }),
        instantsearch.widgets.refinementList({
          container: '#manufacturer',
          attributeName: 'manufacturer.name',
          operator: 'or',
          showMore: true,
          limit: 9,
          sortBy: ["name:asc"],
          templates: {
            header: localize('COMMON.MANUFACTURER'),
            item: refinementItemCustomTemplate,
          },
          cssClasses: {
            root: 'algolia-widget'
          }
        }),
        instantsearch.widgets.refinementList({
          container: '#supplier',
          attributeName: `vendor_inventory.vendor.name_locale.${userLang}`,
          operator: 'or',
          showMore: true,
          limit: 9,
          sortBy: ["name:asc"],
          templates: {
            header: localize('COMMON.SUPPLIER'),
            item: refinementItemCustomTemplate,
          },
          cssClasses: {
            root: 'algolia-widget',
          }
        }),
        instantsearch.widgets.refinementList({
          container: '#subcategory',
          attributeName: `subcategory.name_locale.${userLang}`,
          operator: 'or',
          showMore: true,
          limit: 9,
          sortBy: ["name:asc"],
          templates: {
            header: localize('COMMON.SUBCATEGORY'),
            item: refinementItemCustomTemplate,
          },
          cssClasses: {
            root: 'algolia-widget',
          }
        }),
        instantsearch.widgets.refinementList({
          container: '#category',
          attributeName: `parent_category.name_locale.${userLang}`,
          operator: 'or',
          showMore: true,
          limit: 9,
          sortBy: ["name:asc"],
          templates: {
            header: localize('COMMON.CATEGORY'),
            item: refinementItemCustomTemplate,
          },
          cssClasses: {
            root: 'algolia-widget',
            list: category_list_class,
          }
        }),
        _createWidgetConfig({
          disjunctiveFacetsRefinements: AlgoliaHelperService.transformFacetFiltersToObject($stateParams.facet_filters),
        })
      ]

      // Prevents losing numericFilters values when resetting ais
      service.numeric_refinements_backup = AlgoliaHelperService.transformNumericFiltersToObject($stateParams.numeric_filters)
      
      const refinementElement = document.querySelector('#currentRefinements');
      if (refinementElement) {
        const refinementWidget = instantsearch.widgets.currentRefinedValues({
          container: '#currentRefinements', 
          clearsQuery: false,
          templates: {
            clearAll: localize('ACTIONS.CLEAR_FILTERS')
          },
          cssClasses:{
            "root": "algolia-widget no-border",
            "body": "flex layout-row",
            "list": "flex layout-row layout-wrap"
          }
        });
        widgetsList.push(refinementWidget);
      }

      const paginationElement = document.querySelector('#currentRefinements');
      if (paginationElement) {
        const paginationWidget = instantsearch.widgets.pagination({ 
          container: '#pagination-widget-bottom',
        });
        widgetsList.push(paginationWidget);

        const url_page = _.toNumber($stateParams.page) || 1;
        const pagination_config = _createWidgetConfig({
          page: AlgoliaHelperService.getZeroIndexValue(url_page),
        })
        widgetsList.push(pagination_config);
      }

      ais.addWidgets(widgetsList);
    }

    /**
     * Creates a price widget and returns it with its config
     * @returns {[price_widget, price_widget_config]}
     */
    function _createPriceWidget () {
      const price_widget_items = [
        instantsearch.widgets.priceRanges({
          container: '#price',
          attributeName: price_widget_name,
          templates: {
            header: localize('COMMON.PRICE'),
          },
          cssClasses: {
            root: 'algolia-widget',
          },
          labels: {
            currency: '$',
          },
        })
      ];

      // If there are numeric refinements, add them to the widget config
      if (!_.isEmpty(service.numeric_refinements_backup)) {
        const price_widget_config = _createWidgetConfig({
          numericRefinements: service.numeric_refinements_backup
        });
        price_widget_items.push(price_widget_config);
        service.numeric_refinements_backup = null;
      }

      return price_widget_items;
    }

    function addWidgets (facets) {
      var ais = getAisInstance();
      var main = document.querySelector('#algolia-widgets');
      var widgets = [];
      
      if(!main)
        return;

      _.forEach(facets, function(facet){
        // Skip price widget since it is not a refinement list
        if(!facet || facet === price_widget_name){
          return;
        }
        const id = getElementName(facet);
        const idSelector = "#"+id;
        var this_node = document.createElement('div');
        this_node.id = id;
        main.appendChild(this_node);
        var widget = instantsearch.widgets.refinementList({
          container: idSelector,
          attributeName: facet,
          operator: 'or',
          showMore: true,
          limit: 9,
          sortBy: ["name:asc"],
          templates: {
            header: getFacetDisplayName(facet),
            item: refinementItemCustomTemplate
          },
          cssClasses: {
            root: 'algolia-widget',
          }
        });
        widgets.push(widget);
      });
      ais.addWidgets(widgets);
      $rootScope.$broadcast('algolia-widgets-added');
    }

    function terminateInstance () {
      var ais = getAisInstance();
      ais.dispose();
    }

    function handleDynamicWidgets (response_facets) {
      var ais = getAisInstance();
      if(!_.size(response_facets)){
        return;
      }
      response_facets = Object.keys(response_facets);
      var current_facets = _.map(ais.widgets, function(widget){
        if(!widget || !widget.getConfiguration){
          // return;
        } else {
          var config = widget.getConfiguration();
          var widget_facets = _.concat(_.get(config, 'facets'),_.get(config, 'disjunctiveFacets'));
          return widget_facets;
        }
      });
      current_facets = _.compact(_.flattenDeep(current_facets));
      var new_facets = _.difference(response_facets, current_facets);
      if(_.size(new_facets)){
        // Include price widget only if new facets list contains 'vendor_inventory.price'
        if (new_facets.includes(price_widget_name)) {
          ais.addWidgets(_createPriceWidget());
        }

        const filteredFacets = getFacetsForCurrentLanguage(new_facets);
        addWidgets(filteredFacets);
      }
    }

    function directSuggestionSearch (params) {  
      /**
       * Step 7a - Perform Algolia call to get product / category data
       */      
      if (service.searchIndex == null) {
        service.initAlgoliaIndex();
      }
      var query = params['query'];

      // Variables: Limit the search suggestions results and denote if
      // searching within subcategories is required (i.e. has a catId to search
      // within )
      var SUB_CATS_RESULTS_LIMIT = 5;
      var results_limit = 10;
      var catId = null;

      if ("results_limit" in params) {
        results_limit = params['results_limit'];
      }

      // Configure Facet Filters

      const marketplace_id = params['marketplace_id'];
      const marketplace_key = AccessService.hasAlgoliaV2Enabled() ? service.ALGOLIA_FACET_FILTER_NAME_V2 : service.ALGOLIA_FACET_FILTER_NAME_V1;
      const facet_filters = [`${marketplace_key}: ${marketplace_id}`]
      if (typeof params['cat_id'] !== 'undefined') {
        catId = params['cat_id']
        facet_filters.push('parent_category.id: ' + catId)

        // increase results limit to include subcategories
        results_limit += SUB_CATS_RESULTS_LIMIT;
      }
      
      var searchPromise = service.searchIndex.search(query, {
          facets: ['parent_category.id', 'parent_category.name', 'subcategory.id',
          'subcategory.name'],
          attributesToRetrieve: [
            'id',
            'name',
            'manufacturer_sku',
            'parent_category',
            'subcategory'
          ],
          facetFilters: [facet_filters],
          restrictSearchableAttributes: getSuggestionsAttributes(),
        })
        .then(function(data) {
          return data;
        })
        .catch(function(error) {
          errorService.uiErrorHandler(error);
        })
        // .finally(function() {
        // });       
      return searchPromise;
    }

    function directQuerySuggestionSearch (params) {
      /**
       * Step 2
       * Initalize the Algolia query indices, if required
       * Return query suggestion data
       */

      if (service.searchIndex == null) {
        service.initAlgoliaIndex();
      }
      var query = params['query'];

      /**
        Need to build the query to facet on the marketplace_id.
        Within Algolia the data is represented as:

        staging_MarketplaceVendorInventory:
          facets: {
            exact_matches: {
              marketplace_id: [
                { value: "mrkt_v3c5H7HDE5S8Z3XwRMQumc", count: "225" }
              ]
              parent_category.name:[ 
                { value: "Infection Control", count: 1616 } 
              ]
              subcategory.name:[ 
                { value: "Gloves Nitrile", count: 954 }, 
                { value: "Gloves Latex", count: 331 } 
              ]
            }

        Key of: "staging_MarketplaceVendorInventory"
        Value of "facets"

        The query suggestion has a referance the index in which query suggestions
        are generated.
        
        Therefore the facetFilter needs be be in the form of:

        staging_MarketplaceVendorInventory.facets.exact_matches.marketplace_id.value:mrkt_v3c5H7HDE5S8Z3XwRMQumc
       */

      const marketplace_id = params["marketplace_id"];
      const exact_matches = '.facets.exact_matches.';
      const facet_filters_v1 = `${service.algolia_prefix}${service.ALGOLIA_INDEX_NAME_V1}${exact_matches}${service.ALGOLIA_FACET_FILTER_NAME_V1}.value: ${marketplace_id}`;
      const facet_filters_v2 = `${service.algolia_prefix}${service.ALGOLIA_INDEX_NAME_V2}${exact_matches}${service.ALGOLIA_FACET_FILTER_NAME_V2}.value: ${marketplace_id}`;
      const facet_filters = AccessService.hasAlgoliaV2Enabled() ? [[facet_filters_v1, facet_filters_v2]] : [facet_filters_v1];
      const searchPromise = service.querySearchIndex.search(query, {
        facetFilters: facet_filters,
      })
      .then(function(data) {
        /**
         * Step 3
         * From returned Algolia data, update the `suggested_search_query`
         * object and resolve the promise
         */
        
        var suggested_search_query = [];

        for (var i=0; i < data['hits'].length; i++) {
          suggested_search_query.push({
            'name': data['hits'][i]["_highlightResult"]["query"]["value"],
            'id': null,
            'type': SEARCH_SUGGESTION_TYPE.query
          })
        }
        return suggested_search_query;
      });
      return searchPromise;
    }

    /**
     * A list (array) of attributes to restrict algolia search to (used for localization).
     * @returns An array of strings.
     */
     function getSearchAttributes () {
      /**
      ** Why this is needed:
      * each product has both the original (english) and localized versions of some attributes.
      * eg.
      *  - name
      *  - name_locale.en
      *  - name_locale.fr
      * (same for description and possibly more fields in the future)
      * 
      * And we don't want a search from one language to 'bleed into' another.
      * So we limit the search to the attributes that are relevant to 
      * the current language if they are localized in the DB.
      */
      const language = sowLanguageService.getCurrentLanguage();

      /**
       * this will generate a list of 
       ** ['name_locale.en', 'description_locale.en', ...]
       * for english users, and
       ** ['name_locale.fr', 'description_locale.fr', ...]
       * for french users
       */
      const localizedAttributes = [
        `name_locale.${language}`,
        `description_locale.${language}`,
      ];

      const generalAttributes = [
        'vendor_inventory.vendor_sku',
        'manufacturer.sku',
        'manufacturer.name',
        'manufacturer.id',
        'parent_category.id',
        'subcategory.id',
        'medical_field_id',
        'marketplace_id',
        'subcategory.name',
        'parent_category.name',
        'id',
        'vendor_inventory.price',
        'vendor_inventory.vendor_inventory_id',
        'item_type',
        'vendor_inventory.tags'
      ];

      return [...localizedAttributes, ...generalAttributes];
    }

    /**
     * A list (array) of attributes to restrict algolia suggestions to (used for localization).
     * @returns An array of strings.
     */
     function getSuggestionsAttributes () {
      // functionality is the same as `getSearchAttributes`, for further explanation read above
      const language = sowLanguageService.getCurrentLanguage();
      const localizedAttributes = [
        `subcategory.name_locale.${language}`,
        `parent_category.name_locale.${language}`,
      ];

      const generalAttributes = [
        'manufacturer.name',
      ];

      if (!AccessService.hasAlgoliaV2Enabled()) {
        localizedAttributes.push(`vendor_inventory.vendor.name_locale.${language}`);
        generalAttributes.push('vendor_inventory.price');
      }

      return [...localizedAttributes, ...generalAttributes];
    }

    /**
     * It takes a facet name, cleans it up, and returns a string that can be used as an element name
     * @param facet - The facet name
     * @returns The element name of the facet.
     */
    function getElementName (facet) {
      // from https://stackoverflow.com/a/9635698
      // removes all illegal characters (symbols, spaces) and makes sure
      // first char is a letter and not a number
      const idRegex = /^[^a-z]+|[^\w:.-]+/gi;
      const cleanFacet = getCleanFacetName(facet);
      const facetMap = {
        'vendor_inventory.price': 'price',
        'manufacturer.name': 'manufacturername',
        'vendor_inventory.vendor.name': 'supplier',
        'subcategory.name': 'subcategory',
        'parent_category.name': 'category',
      };
      
      return facetMap[cleanFacet] || cleanFacet.replace(idRegex, '');
    }

    /**
     * It takes a facet name as a string, removes the locale part of the facet name, and returns the
     * facet name in a more readable format
     * @param facet - the name of the facet
     * @returns The name of the facet.
     */
     function getFacetDisplayName (facet) {
      const cleanFacet = getCleanFacetName(facet);
      const facetMap = {
        // these are all the localize-able facet names known so far,
        // any new values should be added to this list since they all
        // come from algolia as english names
        'vendor_inventory.price': 'COMMON.PRICE',
        'manufacturer.name': 'COMMON.MANUFACTURER',
        'vendor_inventory.vendor.name': 'COMMON.SUPPLIER',
        'subcategory.name': 'COMMON.SUBCATEGORY',
        'parent_category.name': 'COMMON.CATEGORY',
      };
      const existingKey = facetMap[facet];
      return existingKey ? localize(existingKey) : cleanFacet;
    }

    /**
     * Removes the `_locale.en`/`_locale.fr` and `filters.` bits of the facet name
     * @param facet - The name of the facet.
     * @returns The facet name without the locale.
     */
    function getCleanFacetName (facet) {
      // any new rules should be added as a new `.replace()` command
      return facet
      .replace(/([_locale.]+[.])\w+/, '')
      .replace('filters.', '');
    }

    /**
     * It takes a key and an options object, and returns a string that is the result of interpolating
     * the key and options into a translate filter
     * 
     * This is a workaround because the regular $translate and $filter('translate') 
     * did not work in the context of this service, but $interpolate does.
     * @param key - The key to be translated.
     * @param options - any data you need to pass to the locale string (optional)
     * @returns The localized value of `key`
     */
    function localize (key, options) {
      return $interpolate(`{{key | translate:options}}`)({'key': key, 'options': options});
    }

    
    /**
     * It takes a list of facets and returns a list of facets with:
     * - not-localized facets
     * - facets localized for the current language
     * @param facets - an array of facet names
     * @returns An array of facet names that are localized to the user's language.
     */
    function getFacetsForCurrentLanguage (facets, userLanguage = sowLanguageService.getCurrentLanguage()) {
      const localeSuffix = '_locale';
      const myLocale = `${localeSuffix}.${userLanguage}`;
      const filteredFacets = _.filter(facets, (facetName) => {
        // the purpose here is to ignore all other languages if they exist
        if(facetName.includes(localeSuffix)){
          return facetName.includes(myLocale);
        } else {
          // if it's not a localized filter, just return it as is
          return true;
        }
      });
      return filteredFacets;
    }

    /**
     * Applies a default value to a widget, call this function after adding the widget to the instance.
     * @param {object} params - Algolia config params, see the link below for more info.
     * @link https://www.algolia.com/doc/guides/building-search-ui/widgets/customize-an-existing-widget/js/#how-to-provide-search-parameters
     * @link https://www.algolia.com/doc/api-reference/search-api-parameters/
     * @return {object} - The widget object.
     */
    function _createWidgetConfig (params) {
      return instantsearch.widgets.configure(params);
    }
  }

})();

(function () {
  'use strict';
  angular.module('sowOrders')
    .directive('sowOrderReceive', sowOrderReceive);

  function sowOrderReceive() {
    return {
      replace: true,
      restrict: 'E',
      templateUrl: 'sow-orders/slideouts/order-receive-items.html',
      controller: 'OrderReceiveController',
      controllerAs: 'oriCtrl',
    };
  }

}());

(function() {
  'use strict';
  
  angular
  .module('sowReports')
  .controller('SpendingSupplierController', SpendingSupplierController);
  
  function SpendingSupplierController($scope, $window, $filter, $state, sowReportsService2, orderByFilter, AccessService) {
    /*jshint validthis: true */
    var ctrl = this;
    ctrl.labelQuantity = 6;
    ctrl.reverse = false;
    ctrl.yearLocal = $window.localStorage.getItem('yearLocal');
    ctrl.selectedYear = moment().year();
    if(ctrl.yearLocal){
      ctrl.selectedYear = ctrl.yearLocal;
    }
    ctrl.isEmptyChart = false;
    ctrl.rangeOptions = [{
      name: moment().year(),
      value: moment().year()
    }];
    ctrl.officeID = sow.officeInfo().id;
    ctrl.chartSetting = {
      options: {
        responsive: true,
        maintainAspectRatio: true,
        legend: {
          display: false
        },
        tooltips: {
          enabled: true,
          displayColors: false,
          mode: 'single',
          callbacks: {
            label: function(tooltipItems, data) {
              return $filter('currency')(tooltipItems.xLabel, '$', 2);
            },
          },
        },
        scales: {
          xAxes: [{
            interval: 1000,
            gridLines: {
              drawTicks: true,
              color: 'rgba(128,142,155,0.25)',
              zeroLineColor: 'rgba(128,142,155,0.25)',
            },
            ticks: {
              beginAtZero: true,
              callback: function(value, index, values) {
                if(index == 0){
                  return value;
                }else{
                  return $filter('currency')(value, '$', 0);
                }
              }
            }
          }],
          yAxes: [{
            ticks: {
              beginAtZero: true,
              callback: function(value) {
                var txt = _.toString(value);
                if(_.size(txt) > 20){
                  return txt.substring(0, 20) + '...';
                }
                
                return value;
              }
            }
            
          }]
        },
        dataset: {
          barThickness: 20,
        },
        legend: {
          display: false,
          position: 'right',
          labels: {
            boxWidth: 10
          }
        }
      },
      series: ['Spending'],
      type: "horizontalBar",
      colors: [{
        backgroundColor: '#33bbc4',
        pointBackgroundColor: 'rgba(148,159,177,1)',
        pointHoverBackgroundColor: 'rgba(148,159,177,1)',
        borderColor: '#33bbc4',
        pointBorderColor: '#fff',
        pointHoverBorderColor: 'rgba(148,159,177,0.8)'
      }]
    };

    ctrl.changeChartData = changeChartData;
    ctrl.chartClick = chartClick;
    ctrl.getSpendingSupplierData = getSpendingSupplierData;
    ctrl.getSpendingSupplierLabel = getSpendingSupplierLabel;
    ctrl.getSpendingSupplierReport = getSpendingSupplierReport;
    ctrl.getSpendingSupplierShipping = getSpendingSupplierShipping;
    ctrl.getSupplierIdByName = getSupplierIdByName;
    ctrl.goToDetail = goToDetail;
    ctrl.goToSupplierDetail = goToSupplierDetail;
    ctrl.resetCurrentChartShow = resetCurrentChartShow;
    ctrl.sort = sort;
    ctrl.sumArray = sumArray;
    ctrl.updateCurrentYear = updateCurrentYear;
    ctrl.updateYearOptions = updateYearOptions;

    init();

    return ctrl;
    
    function init () {
      defineLocks();
      ctrl.getSpendingSupplierReport(ctrl.selectedYear);
      ctrl.updateYearOptions();
    }

    function defineLocks() {
      ctrl.gl_budget_enabled = AccessService.getOfficeProperty('gl_budget_enabled');
    }

    function updateCurrentYear () {
      $window.localStorage.setItem('yearLocal', ctrl.selectedYear);
      if (ctrl.selectedYear) {
        ctrl.getSpendingSupplierReport(ctrl.selectedYear);
      }
    }
    
    function updateYearOptions () {
      var first_date = moment($scope.$root.current_office.created_at).startOf('year');
      var last_date = moment();
      var years_count = last_date.diff(first_date, 'y');
      
      for (var i = 1; i <= years_count; i++) {
        var year_number = moment().subtract(i, 'y').format('YYYY');
        ctrl.rangeOptions.push({
          name: year_number,
          value: year_number
        });
      }
    }
    
    function getSpendingSupplierReport (selectedYear) {
      sowReportsService2.getSpendingSupplierReport(selectedYear).then(function(data) {
        ctrl.chartData = data;
        ctrl.changeChartData();
      });
    }
    
    function resetCurrentChartShow () {
      ctrl.isEmptyChart = false;
      ctrl.currentChartShow = {
        year: '',
        labels: [],
        data: [],
        totalSpent: '',
        average: '',
        suppliers: [],
        highest_volume: [],
        most_orders_placed_with: [],
        totalShipping: '',
      };
    }

    function changeChartData () {
      ctrl.resetCurrentChartShow();
      
      var report = ctrl.chartData[0].report;
      var highest_volume = report.highest_volume;
      var most_orders_placed_with = report.most_orders_placed_with;
      var suppliers = ctrl.sort(report.suppliers, 'total', true);
      
      if(_.size(suppliers) === 0){
        ctrl.isEmptyChart = true;
      }
      var allSupplierData = ctrl.getSpendingSupplierData(suppliers);
      var spendingSupplierData = _.take(allSupplierData, ctrl.labelQuantity);
      var spendingSupplierLabel = _.take(ctrl.getSpendingSupplierLabel(suppliers), ctrl.labelQuantity);
      var spendingSupplierShipping = ctrl.getSpendingSupplierShipping(suppliers);
      var totalSpent = allSupplierData.reduce(ctrl.sumArray, 0);
      var average = totalSpent / allSupplierData.length;
      var totalShipping = spendingSupplierShipping.reduce(ctrl.sumArray, 0);
      
      ctrl.currentChartShow.year = ctrl.selectedYear;
      spendingSupplierData.length = ctrl.labelQuantity;
      ctrl.currentChartShow.data.push(spendingSupplierData);
      spendingSupplierLabel.length = ctrl.labelQuantity;
      ctrl.currentChartShow.labels = spendingSupplierLabel;
      ctrl.currentChartShow.totalSpent = totalSpent;
      ctrl.currentChartShow.average = average;
      ctrl.currentChartShow.suppliers = suppliers;
      ctrl.currentChartShow.totalShipping = totalShipping;
      ctrl.currentChartShow.highest_volume = highest_volume;
      ctrl.currentChartShow.most_orders_placed_with = most_orders_placed_with;
      
      ctrl.selectedYear = ctrl.currentChartShow.year;
    }
    
    function getSpendingSupplierData (suppliers) {
      return _.map(suppliers, function(item){
        return item.total;
      });
    }
    
    function getSpendingSupplierLabel (suppliers) {
      return _.map(suppliers, function(item){
        return item.supplier;
      });
    }
    
    function getSpendingSupplierShipping (suppliers) {
      return _.map(suppliers, function(item){
        return item.shipping;
      });
    }
    
    function sumArray (a, b) {
      return a + b;
    }
    
    function sort (array, sortByName, sortOrder) {
      var newArray = [];
      if (array.length > 0) {
        newArray = orderByFilter(array, sortByName, sortOrder);
      }
      return newArray;
    }
    
    function goToSupplierDetail (supplierID, year, name) {
      var id = '';
      for(var i = 0; i < supplierID.length; i++){
        if(i == 0){
          id = id + supplierID[i];
        }else{
          id = id + ',' + supplierID[i];
        }
      }
      
      $state.go('app.reports.spending.supplier-detail', { supplier_id: id.trim(), year: year, name:name });
    }
    
    function goToDetail (supplierName){
      var supplier = ctrl.getSupplierIdByName(supplierName);
      ctrl.goToSupplierDetail(supplier.id, ctrl.currentChartShow.year, supplier.name);
    }
    
    function getSupplierIdByName (name){
      var supplier = ctrl.currentChartShow.suppliers;
      var supplierInfo = {};
      if(supplier.length > 0){
        for(var i = 0; i < supplier.length ; i++){
          if(supplier[i].supplier == name){
            supplierInfo.id = supplier[i].supplier_id;
            supplierInfo.name = supplier[i].supplier;
          }
        }
      }
      
      return supplierInfo;
    }
    
    function chartClick (points){
      if(points.length > 0){
        var supplierName =points[0]._model.label;
        ctrl.goToDetail(supplierName);
      }
    }
  }
}());
(function () {
  'use strict';

  /** @ngInject */
  function sowApprovalService (serverAPI, apiUrl, officeService, accountService) {
    let service = this;

    service.acceptToken = acceptToken;
    service.declineToken = declineToken;
    service.cancelToken = cancelToken;
    service.getDashboard = getDashboard;
    service.getList = getList;
    service.create = create;
    service.update = update;
    service.getLogs = getLogs;
    service.getPendingCount = getPendingCount;
    service.getRequest = getRequest;
    service.search = search;
    service.getAuthorization = getAuthorization;
    service.getApprovers = getApprovers;
    service.getAuthorizationAndApprovers = getAuthorizationAndApprovers;

    function _getOfficeId () {
      return officeService.get().id;
    }

    function _getUserId () {
      return accountService.get().id;
    }

    function _tokenAction (action, token) {
      const url = `${apiUrl}/approvals/${action}/${token}`;
      const options = {'method': 'PUT'};

      return serverAPI
        .doAPICall(url, options)
        .then((response) => {
          return response.data;
        });
    }

    /**
     * Accept an approval request using a token
     *
     * @param {*} token
     * @return {*} 
     */
    function acceptToken (token) {
      return _tokenAction('accept', token);
    }

    /**
     * Decline an approval request using a token
     *
     * @param {*} token
     * @return {*} 
     */
    function declineToken (token) {
      return _tokenAction('decline', token);
    }
    
    /**
     * Cancel an approval request using a token
     *
     * @param {*} token
     * @return {*} 
     */
    function cancelToken (token) {
      return _tokenAction('cancel', token);
    }
    
    /**
     * Search Approvals records on the Database for office
     *
     * @param {*} filters (approval_type ["purchase_order","cart_order"], approval_status ["None","pending","approved","cancelled","declined"], paginate, page_num, page_size)
     * @return {*} 
     */
    function getDashboard (filters) {
      const office_id = _getOfficeId();
      const url = `${apiUrl}/approvals/${office_id}/dashboard`;
      const options = {'method': 'GET', 'params': filters};

      return serverAPI
        .doAPICall(url, options)
        .then((response) => {
          return response.data;
        });
    }

    /**
     * Fetch current approvals, which can be filtered by type of approval, status, and mode. 
     * Results are paginated.
     * 
     * @param {*} parameters (user_id, approval_type ["purchase_order","cart_order"], approval_status ["None","pending","approved","cancelled","declined"], mode [approver_only,requestor_only,approver_all], paginate, page_num, page_size, get_all_offices)
     * @return {*} 
     * @memberof sowApprovalService
     */
    function getList (parameters) {
      const office_id = _getOfficeId();
      const url = `${apiUrl}/approvals/${office_id}`;
      const options = {'method': 'GET', 'params': parameters};

      return serverAPI
        .doAPICall(url, options)
        .then((response) => {
          return response.data;
        });
    }

    /**
     * Create an Approval record on the Database.
     *
     * @param {*} approval_request (user_id, approver_id, approval_type, reference_id, notes, day_to_expire)
     * @return {*} 
     * @memberof sowApprovalService
     */
    function create (approval_request) {
      if (!approval_request.user_id) {
        approval_request.user_id = _getUserId();
      }
      const office_id = _getOfficeId();
      const url = `${apiUrl}/approvals/${office_id}`;
      const options = {'method': 'POST', 'data': approval_request};

      return serverAPI
        .doAPICall(url, options)
        .then((response) => {
          return response.data;
        });
    }

    /**
     * Modify the approval status and send notification
     *
     * @param {*} approval_request (approval_id, user_id, action ['approve', 'cancel', 'decline'])
     * @return {*} 
     * @memberof sowApprovalService
     */
    function update (approval_request) {
      if (!approval_request.user_id) {
        approval_request.user_id = _getUserId();
      }
      const office_id = _getOfficeId();
      const url = `${apiUrl}/approvals/${office_id}`;
      const options = {'method': 'PUT', 'data': approval_request};

      return serverAPI
        .doAPICall(url, options)
        .then((response) => {
          return response.data;
        });
    }

    /**
     * Fetch log entries for a specific approval request
     *
     * @param {*} approval_id
     * @return {*} 
     */
    function getLogs (approval_id) {
      const office_id = _getOfficeId();
      const url = `${apiUrl}/approvals/${office_id}/logs/${approval_id}`;
      const options = {'method': 'GET'};

      return serverAPI
        .doAPICall(url, options)
        .then((response) => {
          return response.data;
        });
    }

    /**
     * Fetch number of pending approvals 
     *
     * @param {*} filters ( approval_type ["purchase_order","cart_order"] )
     * @return {*} 
     */
    function getPendingCount (filters) {
      const office_id = _getOfficeId();
      const url = `${apiUrl}/approvals/${office_id}/pending/count`;
      const options = {'method': 'GET', 'params': filters};

      return serverAPI
        .doAPICall(url, options)
        .then((response) => {
          return response.data;
        });
    }

    /**
     * Search Approvals records on the Database
     *
     * @param {*} filters ( user_id, approval_type ["purchase_order","cart_order"], approval_status ["None","pending","approved","cancelled","declined"], mode [approver_only,requestor_only,approver_all], paginate, page_num, page_size, get_all_offices )
     * @return {*} 
     * @memberof sowApprovalService
     */
    function search (filters) {
      const office_id = _getOfficeId();
      const url = `${apiUrl}/approvals/${office_id}`;
      const options = {'method': 'GET', 'params': filters};

      return serverAPI
        .doAPICall(url, options)
        .then((response) => {
          return response.data;
        });
    }

    /**
     * Determine wether an action requires approval or not
     *
     * @param {*} user_id
     * @param {*} approval_type ["purchase_order","cart_order"]
     * @param {*} reference_id [PO ID / cart ID]
     * @return {*} 
     */
    function getAuthorization (user_id, approval_type, reference_id) {
      user_id = user_id || _getUserId();
      const office_id = _getOfficeId();
      const url = `${apiUrl}/approvals/${office_id}/authorization`;
      const options = {'method': 'GET', 'params': {user_id, approval_type, reference_id} };

      return serverAPI
        .doAPICall(url, options)
        .then((response) => {
          return response.data;
        });
    }

    /**
     * Fetch list of available approvers in the office
     *
     * @param {*} user_id
     * @param {*} approval_type ["purchase_order","cart_order"]
     * @param {*} reference_id [PO ID / cart ID]
     * @return {*} 
     */
    function getApprovers (user_id, approval_type, reference_id) {
      user_id = user_id || _getUserId();
      const office_id = _getOfficeId();
      const url = `${apiUrl}/approvals/${office_id}/approvers`;
      const options = {'method': 'GET', 'params':{user_id, approval_type, reference_id} };

      return serverAPI
        .doAPICall(url, options)
        .then((response) => {
          return response.data;
        });
    }

    /**
     * Determine wether an action requires approval 
     * and fetch list of available approvers in the office
     *
     * @param {*} user_id
     * @param {*} approval_type ["purchase_order","cart_order"]
     * @param {*} reference_id [PO ID / cart ID]
     * @return {*} 
     */
    function getAuthorizationAndApprovers (user_id, approval_type, reference_id) {
      user_id = user_id || _getUserId();
      const office_id = _getOfficeId();
      const url = `${apiUrl}/approvals/${office_id}/authorization/approvers`;
      const options = {'method': 'GET', 'params': {user_id, approval_type, reference_id} };

      return serverAPI
        .doAPICall(url, options)
        .then((response) => {
          return response.data;
        });
    }

    /**
     * Fetch one approval request object, complete with its order data
     * such as addresses and values
     * Note: request_id is supposed to be the actual database ID, not the HRID
     *
     * @param {string} request_id
     * @return {*} 
     */
    function getRequest (request_id) {
      const office_id = _getOfficeId();
      const url = `${apiUrl}/approvals/${office_id}/${request_id}`;
      const options = {'method': 'GET'};

      return serverAPI
        .doAPICall(url, options)
        .then((response) => {
          return response.data;
        });
    }
  }

  angular.module('sowApprovals')
  .service('sowApprovalService', sowApprovalService);

})();

'use strict';
{
  /** @ngInject */
  class ProductHelperService {
    constructor($filter, msHelperService) {
      this.$filter = $filter;
      this.msHelperService = msHelperService;

      this.PRODUCT_STATUS = {
        AVAILABLE: 'available',
        BACKORDERED: 'backordered',
        DISCONTINUED: 'discontinued',
        SOLD_OUT: 'sold_out',
        UNKNOWN: 'unknown',
      };
    }

    /**
     * Takes a product status and returns a translated string based on the product status
     * @param {"unknown"|"sold_out"|"discontinued"|"available"} product_status
     * @return {string}
     */
    getStockText(product_status) {
      const locale_key = {
        [this.PRODUCT_STATUS.AVAILABLE]: 'MARKETPLACE.DETAIL.IN_STOCK',
        [this.PRODUCT_STATUS.SOLD_OUT]: 'MARKETPLACE.DETAIL.SOLD_OUT',
        [this.PRODUCT_STATUS.DISCONTINUED]: 'MARKETPLACE.DETAIL.DISCONTINUED',
        [this.PRODUCT_STATUS.BACKORDERED]: 'ORDERS.BACKORDERED',
      };

      return this.$filter('translate')(locale_key[product_status]);
    }

    /**
     * Takes a stock response and a default stock status
     * If stock_response was fulfilled, get the stock status
     * Otherwise, return the default status
     * @param {object} stock_response - The response from the stock API call.
     * @param {'available'|'backordered'|'discontinued'|'sold_out'|'unknown'} default_stock_status - This is the default stock status of the product.
     * @returns {object}
     */
    getStockBasedOnResponse(stock_response, default_stock_status) {
      if (stock_response?.status === 'fulfilled') {
        return this._getStockStatus(stock_response?.value?.data?.Stock);
      }

      if (stock_response?.status === 'rejected') {
        console.error(stock_response.reason);
      }

      return {
        product_status: default_stock_status,
        stock_text: this.getStockText(default_stock_status),
      };
    }

    /**
     * If the vendor has a stock text and is external return true
     * @param {object} vendor
     * @param {object} vendor.UI
     * @param {boolean} vendor.UI.is_external
     * @param {string} vendor.UI.stock_text
     * @return {boolean}
     */
    shouldShowStockStatus (vendor) {
      return vendor.UI.stock_text && vendor.UI.is_external;
    }

    /**
     * Takes a stock object and returns a product status
     * @param {object} stock
     * @param {'In.Stock'|'No.Stock'|'Discontinued'|'Backordered.Stock'} stock.value
     * @return {string}
     */
    getProductStatus({value} = {value: null}) {
      const product_status = {
        'In.Stock': this.PRODUCT_STATUS.AVAILABLE,
        'No.Stock': this.PRODUCT_STATUS.SOLD_OUT,
        'Discontinued': this.PRODUCT_STATUS.DISCONTINUED,
        'Backordered.Stock': this.PRODUCT_STATUS.BACKORDERED,
      };
      return product_status[value] ?? this.PRODUCT_STATUS.UNKNOWN;
    }

    // ----------- Private methods -----------

    /**
     * Gets the stock text and value for the product
     * @param {object} stock
     * @return {object}
     */
    _getStockStatus(stock) {
      const product_status = this.getProductStatus(stock);
      return {
        stock_text: this.msHelperService.getDataValue(stock),
        product_status,
      };
    }
  }

  angular.module('sowMarketplace').service('ProductHelperService', ProductHelperService);
}

angular.module("app.shared")
  .controller("TestingController", function ($scope, $timeout, elementService) {
    var self = this;

    self.simulateQuery = false;
    self.isDisabled = false;

    var searchTextChange = function (text) {
      $log.info('Text changed to ' + text);
    };

    var selectedItemChange = function (item) {
      $log.info('Item changed to ' + JSON.stringify(item));
    };

    $scope.plans = [
      { 'monthly_cost': 100.00, 'billing_cycle': 'per_month' },
      { 'monthly_cost': 75.00, 'billing_cycle': 'per_year' },
    ];

    /**
     * Build `states` list of key/value pairs
     */
    var loadAll = function () {
      var allStates = 'Alabama, Alaska, Arizona, Arkansas, California, Colorado, Connecticut, Delaware, Florida, Georgia, Hawaii, Idaho, Illinois, Indiana, Iowa, Kansas, Kentucky, Louisiana, Maine, Maryland, Massachusetts, Michigan, Minnesota, Mississippi, Missouri, Montana, Nebraska, Nevada, New Hampshire, New Jersey, New Mexico, New York, North Carolina, North Dakota, Ohio, Oklahoma, Oregon, Pennsylvania, Rhode Island, South Carolina, South Dakota, Tennessee, Texas, Utah, Vermont, Virginia, Washington, West Virginia, Wisconsin, Wyoming';

      return allStates.split(/, +/g).map(function (state) {
        return {
          value: state.toLowerCase(),
          display: state
        };
      });
    };

    /**
     * Create filter function for a query string
     */
    var createFilterFor = function (query) {
      var lowercaseQuery = query.toLowerCase();

      return function filterFn(state) {
        return (state.value.indexOf(lowercaseQuery) === 0);
      };

    };

    var querySearch = function (query) {
      var results = query ? self.states.filter(createFilterFor(query)) : self.states,
        deferred;
      if (self.simulateQuery) {
        deferred = $q.defer();
        $timeout(function () { deferred.resolve(results); }, Math.random() * 1000, false);
        return deferred.promise;
      } else {
        return results;
      }
    };

    // list of `state` value/display objects
    self.states = loadAll();
    self.querySearch = querySearch;
    self.selectedItemChange = selectedItemChange;
    self.searchTextChange = searchTextChange;

    $scope.ctrl = self;
  });

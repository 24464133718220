(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.inventory')
    .directive('inventoryOverview', InventoryOverview);

  function InventoryOverview () {
    return {
      restrict: 'E',
      replace: true,
      templateUrl: 'templates/marketplace/inventory/inventory-overview.html',
      scope: {
        'hideSearch': '=',
      }
    };
  }
}());

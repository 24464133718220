angular
.module('app.marketplace.ui.dashboard', [])
.config(function($stateProvider, $urlRouterProvider) {
  var defaultUrl = '/app/dashboard/overview';
  $urlRouterProvider.when('/app/office/dashboard', defaultUrl);
  $urlRouterProvider.when('/app/dashboard', defaultUrl);

  $stateProvider.state('app.dashboard', {
    url: '/dashboard',
    // controller: 'OfficeDashboardController',
    // templateUrl: 'templates/marketplace/dashboard/index.html',
    template: '<ui-view></ui-view>',
    data: {
      'title': 'Dashboard',
      'require_log_in' : true,
      'action_bar_directive': 'dashboard-action-bar',
      // 'requires_feature': 'dashboard',
    },
    redirectTo: 'app.dashboard.overview',
  })

  .state('app.dashboard.requests', {
    url: '/requests',
    controller: 'ApprovalRequestsController',
    controllerAs: 'aRCtrl',
    templateUrl: 'sow-approvals/views/approval-requests.html',
    data: {
      title: 'Approvals',
      require_log_in : true,
      action_bar_directive: 'dashboard-action-bar',
    },
  })

  .state('app.dashboard.overview', {
    url: '/overview',
    controller: 'OfficeDashboardController',
    controllerAs: 'dOffCtrl',
    templateUrl: 'templates/marketplace/dashboard/index.html',
    data: {
      'title': 'Dashboard',
      'require_log_in' : true,
      'action_bar_directive': 'dashboard-action-bar',
      // 'requires_feature': 'dashboard',
    },
  });
});

angular.module('app.marketplace.ui.marketing', [])

.config(function ($stateProvider, $urlRouterProvider) {
  var states = {
    'app.vendors': {
      'url': '/vendors',
      'templateUrl': 'templates/marketplace/marketing/vendors.html'
    },
    'app.about': {
      'url': '/about-us',
      'templateUrl': 'templates/marketplace/marketing/about-us.html',
      'data': {
        'action_bar_directive': 'marketing-action-bar',
      }
    },
    'app.contact': {
      'url': '/contact-us',
      'templateUrl': 'templates/marketplace/marketing/contact-us.html',
      'data': {
        'action_bar_directive': 'marketing-action-bar',
      }
    },
    'app.shipping_rates': {
      'url': '/shipping-rates',
      'controller': 'ShippingRatesCtrl',
      'templateUrl': 'templates/marketplace/marketing/shipping-rates.html',
    },
    'app.mobile_app_info': {
      'url': '/mobile_app',
      'controller': 'MobileAppInfoController',
      'controllerAs': 'maipCtrl',
      'templateUrl': 'templates/marketplace/marketing/mobile-app-info.html',
      'data': {
        'action_bar_directive': 'marketing-action-bar',
      }
    },
  };

  _.forEach(states, function (stateOptions, stateName) {
    $stateProvider.state(stateName, stateOptions);
  });
})

;

(function () {
  'use strict';
  angular
    .module('app.marketplace.ui.purchaseorders')
    .controller('poGettingStartedController', poGettingStartedController);


  function poGettingStartedController($mdDialog) {
    /*jshint validthis: true */
    var ctrl = this;

    ctrl.answer = answer;
    ctrl.cancel = cancel;

    function answer(answer) {
      $mdDialog.hide(answer);
    }

    function cancel() {
      $mdDialog.cancel();
    }

    return ctrl;
  }


}());
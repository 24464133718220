(function () {
  'use strict';

  angular
    .module('sowInventory')
    .service('sowInventoryService', sowInventoryService);

  function sowInventoryService (serverAPI, apiUrl, $rootScope, officeService) {
    /*jshint validthis: true */
    var service = this;

    service.getInventoryCategory = getInventoryCategory;
    service.getInventoryCategories = getInventoryCategories;
    service.getInventoryAlerts = getInventoryAlerts;
    service.getInventoryLocations = getInventoryLocations;
    service.getPricingHistory = getPricingHistory;

    return service;

    //
    // Fetch the list of InventoryCategory objects.
    //
    function getInventoryCategories () {
      var url = '{0}/inventory/categories'.format(apiUrl);
      var options = {'method': 'GET'};

      return serverAPI
        .callCacheAPI(url, options);
    }

    //
    // Fetch a specific InventoryCategory by id.
    //
    function getInventoryCategory (category_id) {
      var url = '{0}/inventory/categories/{1}'.format(apiUrl, category_id);
      var options = {'method': 'GET'};

      return serverAPI
        .doAPICall(url, options)
        .then(function (response) {
          return response.data;
        });
    }

    //
    // Fetch pricing history for a specific inventoryItem.
    //
    function getPricingHistory (item_id) {
      var office_id = sow.officeInfo().id;
      var url = '{0}/offices/{1}/reports/price_history'.format(apiUrl, office_id);
      var options = {'method': 'GET', 'params':{'inventory_item_id': item_id} };

      return serverAPI
        .doAPICall(url, options)
        .then(function (response) {
          return response.data;
        });
    }

    /**
     *
     * @method getInventoryAlerts
     * @description Fetch the list of inventory alerts
     */
    function getInventoryAlerts () {
      var url = '{0}/inventory/alerts'.format(apiUrl);
      var options = {'method': 'GET'};

      return serverAPI
      .doAPICall(url, options)
      .then(function (response) {
        return response.data;
      });
    }

    function getInventoryLocations () {
      var current_office = null;
      return officeService.get(true)
      .then(function(office){
        current_office = office;
        var url = '{0}/offices/{1}/inventory/locations'.format(apiUrl, current_office.id);
        var options = {'method': 'GET'};

        return serverAPI
        .doAPICall(url, options)
        .then(function (response) {
          return response.data;
        });
      });
      
    }
  }
}());

(function() {
  'use strict';

  var budgetComponent = {
    templateUrl: 'sow-budget/views/budget-manager.html',
    bindings: {},
    controller: 'budgetComponentController',
    controllerAs: 'BudgetCtrl',
    selector: 'budget'
  };
  
  angular
  .module('sowBudget')
  .controller('budgetComponentController', budgetComponentController)
  .component(budgetComponent.selector, budgetComponent);
  
  function budgetComponentController($scope, $rootScope, $state, orderByFilter, sowBudgetService, sowReportsService2, membershipService, AccessService) {
    /*jshint validthis: true */
    var ctrl = this;
    
    ctrl.FEATURE_DATA = {
      'section': {
        'name': 'MAIN_MENU.BUDGET',
        'icon': {'svg': "styles/img/icons/budget-calculator.svg", 'css': 'blue', },
        
      },
      'plan': {
        'label': 'PAYWALL.PREMIUM_FEATURE',
        'css': 'premium',
      },
      'selling_points': [
        {
          'header': 'BUDGET.PW_HEADER_ONE',
          'text':'BUDGET.PW_TEXT_ONE',
          'img': "styles/img/paywalls/1-budget"
        },
        {
          'header': 'BUDGET.PW_HEADER_TWO',
          'text':'BUDGET.PW_TEXT_TWO',
          'img': "styles/img/paywalls/2-reports"
        }
      ]
    };
    var office_start_date = _.get($rootScope, 'current_office.created_at', moment());
    ctrl.first_year = moment(office_start_date).year();
    ctrl.last_year = moment().year();
    ctrl.budgetReportData = [];
    ctrl.currentMonthData = {
      month: moment(moment().month() + 1, 'M').format('MMMM'),
      budget:0,
      spending:0,
      balance:{
        value: 0,
        text:'',
        color: 'black'
      }
    };
    ctrl.currentYearData = {
      year: moment().year(),
      budget: 0,
      spending: 0,
      balance:{
        value: 0,
        text: '',
        color: 'black'
      }
    };
    ctrl.budgetNextYear = 0;
    
    Array.prototype.sum = function (prop) {
      var total = 0
      for ( var i = 0, _len = this.length; i < _len; i++ ) {
        const num = Number(this[i][prop]);
        if (!Number.isNaN(num)) {
          total += num;
        }
      }
      return total
    }

    ctrl.sort = sort;
    ctrl.isNotANumber = isNotANumber;
    ctrl.getBudgetReport = getBudgetReport;
    ctrl.mapBudgetReportData = mapBudgetReportData;
    ctrl.getReportCurrentYear = getReportCurrentYear;
    ctrl.getReportNextYear = getReportNextYear;
    ctrl.mapNextYearData = mapNextYearData;
    ctrl.mapCurrentData = mapCurrentData;
    ctrl.setCurrentMonthData = setCurrentMonthData;
    ctrl.setCurrentYearData = setCurrentYearData;
    ctrl.goToBudgetManager = goToBudgetManager;

    init();

    return ctrl;

    
    function sort (array, sortByName, sortOrder){
      var newArray = [];
      if(array.length > 0){
        newArray = orderByFilter(array, sortByName, sortOrder);
      }
      return newArray;
    }
    
    function isNotANumber (value) {
      return isNaN(value);
    }
    
    function init (){
      ctrl.getReportCurrentYear(ctrl.last_year);
      ctrl.getReportNextYear(ctrl.last_year);
      defineLocks();
    }
    
    function getBudgetReport (yearFrom, yearTo){
      sowBudgetService.getBudgetReport(yearFrom, yearTo).then(function(result){
        ctrl.mapBudgetReportData(result);
      });
    }
    
    function mapBudgetReportData (data){
      var report = data[0].report;
      var budget = report.budgets || [];
      var order = report.orders || [];
      
      if(budget.length > 0){
				// Create an array of budget data for a range of years
        // first_year: 2020, last_year: 2022, if api_budget_data only has 2020, 2021,
        // then 2022 will be initialized with empty monthly_budget
        const all_years_budget = sowBudgetService.createBudgetArray({
          api_budget_data: budget,
          first_year: ctrl.first_year,
          last_year: ctrl.last_year,
        });
        budget = ctrl.sort(all_years_budget,'year', true);
        for(var i = 0 ; i < budget.length; i++){
          var perYear = {};
          perYear.year = budget[i].year;
          perYear.budget = budget[i].monthly_budget.sum('budget');
          
          if(order.length > 0){
            order = ctrl.sort(order,'year', true);
            for(var j = 0; j < order.length ; j++){
              if(budget[i].year == order[j].year){
                perYear.spending = order[j].monthly_orders.sum('total');
              }
            }
          }else{
            perYear.spending = 0;
          }
          ctrl.budgetReportData.push(perYear);
        }
      }else{
        var perYear = {};
        perYear.year = ctrl.currentYearData.year;
        perYear.budget = ctrl.currentYearData.budget;
        perYear.spending = ctrl.currentYearData.spending;
        ctrl.budgetReportData.push(perYear);
      }
      var isCurrentYearIncluded = false
      for(var i = 0; i < ctrl.budgetReportData.length; i++) {
        if(ctrl.budgetReportData[i].year === ctrl.last_year) {
          isCurrentYearIncluded = true
        }
      }
      
      if(isCurrentYearIncluded === false) {
        var perYear = {};
        perYear.year = ctrl.currentYearData.year;
        perYear.budget = ctrl.currentYearData.budget;
        perYear.spending = ctrl.currentYearData.spending;
        ctrl.budgetReportData.push(perYear);
        ctrl.budgetReportData = ctrl.sort(ctrl.budgetReportData, 'year', true);
      }
      
      if(ctrl.budgetReportData.length > 0){
        for(var i =0; i < ctrl.budgetReportData.length; i ++){
          var item = ctrl.budgetReportData[i];
          if(item.budget == 0 || item.budget ==''){
            item.balance ={
              value:0
            }
          }else{
            if(item.budget > item.spending){
              item.balance ={
                value: item.budget - item.spending,
                color: 'balance',
                text:'BUDGET.BALANCE_LEFT'
              }
            }else if(item.budget < item.spending){
              item.balance ={
                value: item.spending - item.budget,
                color: 'over',
                text:'COMMON.OVER_BUDGET'
              }
            }else{
              item.balance ={
                value: item.spending - item.budget,
                color: 'black'
              }
            }
          }
        }
      }
      
    }
    
    function getReportCurrentYear (currentYear){
      sowReportsService2.getSpeedingOfficeReport(currentYear).then(function(result){
        ctrl.mapCurrentData(result);
        ctrl.getBudgetReport(ctrl.first_year,ctrl.last_year);
      });
    }
    function getReportNextYear (currentYear){
      sowReportsService2.getSpeedingOfficeReport(currentYear + 1).then(function(result){
        ctrl.mapNextYearData(result);
      });
    }
    
    function mapNextYearData (data){
      var report = data[0].report;
      var budget = report.budgets || [];
      var monthlyBudget = [];
      if(budget.length > 0){
        monthlyBudget = budget[0].monthly_budget;
        if(monthlyBudget.length > 0){
          ctrl.budgetNextYear = monthlyBudget.sum('budget');
        }
      }
    }
    
    function mapCurrentData (data){
      var report = data[0].report;
      var budget = report.budgets || [];
      var monthlyBudget = [];
      var order = report.orders || [];
      var monthlyOrder =[];
      if(budget.length > 0){
        monthlyBudget = budget[0].monthly_budget;
      }
      
      if(order.length > 0){
        monthlyOrder = order[0].monthly_orders;
      }
      ctrl.setCurrentYearData(monthlyBudget, monthlyOrder);
      ctrl.setCurrentMonthData(monthlyBudget, monthlyOrder);
    }
    
    function setCurrentYearData (monthlyBudget, monthlyOrder){
      
      if(monthlyBudget.length > 0){
        ctrl.currentYearData.budget = monthlyBudget.sum('budget');
      }
      
      if(monthlyOrder.length >0){
        ctrl.currentYearData.spending = monthlyOrder.sum('total');
      }
      
      if (ctrl.currentYearData.budget > 0) {
        
        if (ctrl.currentYearData.budget > ctrl.currentYearData.spending) {
          ctrl.currentYearData.balance = {
            value: ctrl.currentYearData.budget - ctrl.currentYearData.spending,
            text: 'COMMON.BALANCE',
            color: 'balance'
          }
        } else if (ctrl.currentYearData.budget < ctrl.currentYearData.spending) {
          ctrl.currentYearData.balance = {
            value: ctrl.currentYearData.spending - ctrl.currentYearData.budget,
            text: 'COMMON.OVER_BUDGET',
            color: 'over'
          }
        }
      } else { 
        ctrl.currentYearData.balance = {
          value: ''
        }
      }
    }
    
    function setCurrentMonthData (monthlyBudget, monthlyOrder){
      var currentMonth = moment(moment().month() + 1 , 'M').format('MMM');
      if(monthlyBudget.length > 0){
        for(var i =0; i < monthlyBudget.length; i++){
          if(currentMonth == monthlyBudget[i].month){
            ctrl.currentMonthData.budget = monthlyBudget[i].budget;
          }
        }
      }
      
      if(monthlyOrder.length > 0){
        for(var i =0; i < monthlyOrder.length; i++){
          if(currentMonth == monthlyOrder[i].month){
            ctrl.currentMonthData.spending = monthlyOrder[i].total;
          }
        }
      }
      
      if (ctrl.currentMonthData.budget > 0) {
        if (ctrl.currentMonthData.budget > ctrl.currentMonthData.spending) {
          ctrl.currentMonthData.balance = {
            value: ctrl.currentMonthData.budget - ctrl.currentMonthData.spending,
            color: 'balance',
            text: "COMMON.BALANCE"
          }
        } else if (ctrl.currentMonthData.budget < ctrl.currentMonthData.spending) {
          ctrl.currentMonthData.balance = {
            value: ctrl.currentMonthData.spending - ctrl.currentMonthData.budget,
            color: 'over',
            text: "COMMON.OVER_BUDGET"
          }
        } 
      } else {
        ctrl.currentMonthData.balance = {
          value: ''
        }
      }
    }
    
    /**
     * Redirects the user to the appropriate budget page based on the office property `gl_budget_enabled`.
     * 
     * @param {number} year - The year for which the budget is being managed.
     * @returns {void}
     */
    function goToBudgetManager (year){
      // When the office property gl_budget_enabled is true, the user is 
      // redirected to the spending report page (the react page with the editable budget table)
      if (ctrl.gl_budget_enabled) {
        $state.go('app.reports.spending.budget', {year});
      } else {
        // Otherwise, the user is redirected to the budget manager page (the angular page with the budget manager table)
        $state.go('app.budget.budget-detail', {year});
      }
    }

    function defineLocks () {
      const membership = membershipService.get();

      const edit_condition = _.get(membership, "user_properties.budgets.disable_edit", false);
      ctrl.disabled_edit = edit_condition;

      ctrl.gl_budget_enabled = AccessService.getOfficeProperty('gl_budget_enabled');
    }
    
  }
}());

angular.module("app.shared.support")
  //Prevent screen (body) from scrolling while expression is true.
  .directive('lockScroll', function ($timeout, $parse) {
    return {
      link: function (scope, element, attrs) {
        var model = $parse(attrs.lockScroll);
        scope.$watch(model, function (value) {
          if (value) {
            angular.element('body').css({ overflow: 'hidden' });
          } else {
            angular.element('body').css({ overflow: 'visible' });
          }
        });
      }
    };
  });

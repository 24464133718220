(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.inventory')
    .controller('ShoppingListController', ShoppingListController);

  function ShoppingListController ($scope, $rootScope, sgToast, inventoryItemService, errorService, sowBrowserFlags, officeService, $filter, gettingStartedDialog, sowMedicationService, inventoryHelperService, sowAnalyticsService, AccessService, RouteService) {
    /*jshint validthis: true */
    var ctrl = this;

    ctrl.checked_all = false;
    ctrl.checked_implant = false;
    ctrl.checked_inventory = false;
    ctrl.checked_medication = false;
    ctrl.current_tab = "inventory";
    ctrl.helpOpen = true;
    ctrl.isLoading = false;
    ctrl.tutorialBoxVisible = true;
    ctrl.inventory_bundles = null;
    ctrl.medication_bundles = null;
    ctrl.implant_bundles = null;
    ctrl.selected_supplier = null;
    
    ctrl.addToCart = addToCart;
    ctrl.changeTab = changeTab;
    ctrl.checkAllItems = checkAllItems;
    ctrl.checkBundleItems = checkBundleItems;
    ctrl.checkItem = checkItem;
    ctrl.checkSectionItems = checkSectionItems;
    ctrl.generateTooltip = inventoryHelperService.generateTooltip;
    ctrl.isCurrentTab = isCurrentTab;
    ctrl.removeItem = removeItem;
    ctrl.set_on_hand = set_on_hand;
    ctrl.toggleHelp = toggleHelp;
    ctrl.tutorialBoxDismissed = tutorialBoxDismissed;
    ctrl.checkSelectDisable = checkSelectDisable;
    ctrl.handleAddToCartClick = handleAddToCartClick;
    ctrl.handleRemoveClick = handleRemoveClick;

    ctrl.gsdShoppingList = gettingStartedDialog.showShoppingListDialog;

    ctrl.sections = [
      {
        tag: 'inventory',
        name: "MAIN_MENU.GENERAL_INVENTORY",
        empty_msg: "SHOPLIST.EMPTY_INV",
        bundles: null,
        qty: 0
      },
      {
        tag: 'medication',
        name: "MAIN_MENU.MEDICATIONS",
        empty_msg: "SHOPLIST.EMPTY_MEDS",
        bundles: null,
        qty: 0
      },
      {
        tag: 'implant',
        name: "SHOPLIST.IMPLANTS",
        empty_msg: "SHOPLIST.EMPTY_IMP",
        bundles: null,
        qty: 0
      }
    ];

    ctrl.$onInit = initialize;

    return ctrl;

    //
    // Run anything that the controller needs to initialize.
    //
    function initialize () {
      defineLocks();
      if (!ctrl.has_shopping_list_access) {
        RouteService.goToDefault();
        return;
      }
      ctrl.checkedItems = inventoryItemService.checkedItems;

      var office = officeService.get();
      ctrl.account_type = _.get(office, 'account_type');

      // disabled in SPD-970
      // welcome_dialog();

      loadShoppingList();

      $scope.$on('shopping-list-updated', loadShoppingList);
      $scope.$on('shopping-list-remove', function(ev, item){
        removeItem(item);
      });
      $scope.$on('shopping-list-remove-product', function(ev, product){
        let item = _.find(ctrl.all_items, function(list_item){
          return list_item.product_id === product.id;
        });
        if(item){
          removeItem(item);
        } else {
          throw 'Cannot remove this product from the shopping list.';
        }
      });
      $scope.$on('mkt-details-close', loadShoppingList);
      $scope.$on('orders-checked-items-cleared', function() {
        checkAllItems(false);
      });


      $scope.$on("$destroy", clearCheckedItems);
    }

    /**
     * @method set_on_hand
     * @param {*} item
     */
    function set_on_hand(item) {
      if(item.tracking_method === 'Package'){
        item.on_hand = Math.floor(item.on_hand / item.order_package_quantity);
      }
    }

    /**
     * @method welcome_dialog
     */
    function welcome_dialog () {
      if (!sowBrowserFlags.flag('shopping-list-tutorial') ) {
        gettingStartedDialog.showShoppingListDialog()
        .then(function(){
          sowBrowserFlags.flag('shopping-list-tutorial', true);
        });
      }
    }

    function need_to_show(tag_name) {
      if(tag_name === "General Inventory"){
        return $scope.features.inventory_full;
      } else if (tag_name === "Medications") {
        return $scope.features.medications;
      } else if (tag_name === "Implants") {
        return $scope.features.implants;
      } else {
        var trueCount =
          ($scope.features.inventory_full ? 1 : 0) +
          ($scope.features.medications ? 1 : 0) +
          ($scope.features.implants ? 1 : 0) ;

        ctrl.show_filter = trueCount > 1;
        return ctrl.show_filter;
      }
    }

    function clearCheckedItems () {
      _.map(ctrl.checkedItems, function(checked_item){
        _.set(checked_item, 'UI.checked', false);
      });
      ctrl.checkedItems = angular.copy([], ctrl.checkedItems);
    }

    //
    // Handle the event where the <tutorial-box> is dismissed.
    //
    function tutorialBoxDismissed () {
      ctrl.tutorialBoxVisible = false;
    }

    //
    // Add to Cart
    //
    function addToCart (item) {
      $rootScope.$broadcast('mkt-details-open-fetch', item.product_id);
      // details slideout instead
      // return AddToCartDialog.show(item.product_id, ev)
      // .then(function (result) {
      //   return removeItem(item);
      // });
    }


    //
    // Load the Shopping List
    //
    function loadShoppingList () {
      ctrl.isLoading = true;

      return inventoryItemService
        .loadShoppingList()
        .then(function (items) {
          items = setSupplierInfo(items);
          items = parseItems(items);
          ctrl.isEmpty = _.get(items, 'length', 0) <= 0;

          ctrl.all_items = _.sortBy(items, ['supplier', 'name']);
          ctrl.general_items = angular.copy(ctrl.all_items);
          ctrl.medication_items = _.remove(ctrl.general_items, ['type','Medication Item']);
          ctrl.implant_items = _.remove(ctrl.general_items, ['type','Implant Item']);
          
          if (sow.officeFeatures().medications) {
            load_medications(ctrl.medication_items)
            .then(function(meds){
              ctrl.medication_bundles = splitSupplierBundles(ctrl.medication_items);
              ctrl.sections[1].bundles = ctrl.medication_bundles;
            });
          }

          // new format divided by suppliers
          ctrl.inventory_bundles = splitSupplierBundles(ctrl.general_items);
          ctrl.sections[0].bundles = ctrl.inventory_bundles;
          ctrl.implant_bundles = splitSupplierBundles(ctrl.implant_items);
          ctrl.sections[2].bundles = ctrl.implant_bundles;

          ctrl.sections[0].qty = ctrl.general_items.length;
          ctrl.sections[1].qty = ctrl.medication_items.length;
          ctrl.sections[2].qty = ctrl.implant_items.length;

          if (ctrl.isEmpty) {
            $rootScope.$broadcast('shopping-list-empty');
          } else {
            $rootScope.$broadcast('shopping-list-not-empty');
          }
        })
        .then(inventoryItemService.getInventoryPOStatus)
        .then(function (itemPoStatus) {
          ctrl.poStatus = itemPoStatus;
        })
        .catch(function (error) {
          var t_message = $filter('translate')('SHOPLIST.FAILED_LOAD');
          errorService.uiErrorHandler(t_message);
        })
        .finally(function () {
          ctrl.isLoading = false;
        });
    }
    /**
     * @method load_medications
     * @param {[]} med_inventory_list
     */
    function load_medications(med_inventory_list) {

      var ids = _.map(med_inventory_list,'id');
      return sowMedicationService.getMedications({inventory_item_id: ids})
        .then(function (meds) {

          meds.forEach(function (med) {
            var inventory_med = _.find(med_inventory_list, { 'id': med.inventory_item_id});
            if(!inventory_med){
              return;
            }
            inventory_med['brand_name'] = med.brand_name;
            inventory_med['pkg_count'] = med.pkg_count;
            inventory_med['pkg_units'] = med.pkg_units;
            inventory_med['pkg_type'] = med.pkg_type;
          });
          return meds;
        }).catch(function(error){
          var t_message = $filter('translate')('ERRORS.MEDS_LOAD')
          errorService.uiErrorHandler(t_message);
        });
    }

    //
    // Toggle the Shopping List Help Box Open/Closed
    //
    function toggleHelp () {
      // TODO store and load this setting on a per user/browser basis.
      ctrl.helpOpen = !sowBrowserFlags.toggle('HideShoppingListHelp');
    }

    //
    // Remove Item From Shopping List
    //
    function removeItem (item, collection ) {
      return inventoryItemService.removeFromShoppingList(item).then(function () {
        var t_message = $filter('translate')('TOAST.ITEM_REMOVED_FROM_LIST');
        sgToast.showSimple(t_message);
        clearCheckedItems();
        loadShoppingList();

      });
    }

    function checkItem (item, override, bundle) {
      if(_.isNil(override)){
        _.set(item, "UI.checked", !_.get(item, "UI.checked", false) );
      } else {
        _.set(item, "UI.checked", override );
      }
      if(item.UI.checked){
        if(ctrl.checkedItems.indexOf(item) < 0){
          ctrl.checkedItems.push(item);
        }
      }else{
        _.map(ctrl.checkedItems, function(checked_item, i){
          if(checked_item && checked_item.id === item.id){
            ctrl.checkedItems.splice(i, 1);
          }
        });
      }
      if(ctrl.use_single_supplier){
        setSelectedSupplier();
      }

      if(bundle){
        bundleCheckUpdate(bundle);
      }
    }

    function checkBundleItems (bundle, override) {
      if(_.isNil(override)){
        _.set(bundle, "checked", !_.get(bundle, "checked", false) );
      } else {
        _.set(bundle, "checked", override );
      }

      _.each(bundle.items, function(item){
        checkItem(item, bundle.checked);
      });
    }

    function checkSectionItems (tag, override) {
      if(_.isNil(override)){
        _.set(ctrl, "checked_"+tag, !_.get(ctrl, "checked_"+tag, false) );
      } else {
        _.set(ctrl, "checked_"+tag, override );
      }

      _.each(ctrl[tag+"_bundles"], function(bundle){
        checkBundleItems(bundle, ctrl["checked_"+tag]);
      });
    }

    function checkAllItems (override) {
      var all_bundles = [ctrl.inventory_bundles, ctrl.medication_bundles, ctrl.implant_bundles];
      var all_tags = ['inventory', 'medication', 'implant'];
      if(_.isNil(override)){
        _.set(ctrl, "checked_all", !_.get(ctrl, "checked_all", false) );
      } else {
        _.set(ctrl, "checked_all", override );
      }

      _.each(all_tags, function(tag){
        checkSectionItems(tag, ctrl.checked_all);
      });
    }

    function bundleCheckUpdate (bundle) {
      var all_checked = true;
      _.each(bundle.items, function(item){
        if(!item.UI.checked){
          all_checked = false;
        }
      });
      _.set(bundle, 'checked', all_checked);
    }

    function splitSupplierBundles (source_list) {
      var group = _.chain(source_list)
      .groupBy(function(item){
        var no_supplier = $filter('translate')('COMMON.NO_SUPPLIER');
        var supplier_name = _.isString(item.supplier) ? item.supplier : no_supplier;
        return supplier_name;
      })
      // .groupBy("supplier")
      .map(function(value, key) { return { "name": key, "items": value, "checked": false }; })
      .value();

      return group;
    }

    function setSupplierInfo (list) {
      return _.map(list, function(item){
        var text = "";
        var seller = item.vendor_name || _.isObject(item.supplier) ? item.supplier.name : item.supplier;
        if(seller){
          text+= "From: "+seller;
        }
        if(item.manufacturer_name){
          if(seller){
            text+=", ";
          }
          text+= "By: "+item.manufacturer_name;
        }

        _.set(item, "UI.supplier_text", text);
        _.set(item, "UI.checked", false);

        return item;
      });
    }

    function isCurrentTab (key) {
      return ctrl.current_tab === key;
    }

    function changeTab (key) {
      ctrl.current_tab = key;
    }

    function defineLocks () {
      ctrl.has_shopping_list_access = AccessService.hasShoppingListAccess();
      const single_condition = AccessService.getProperty("shopping_list.use_single_supplier");
      ctrl.use_single_supplier = single_condition;
      ctrl.hasCartAccess = AccessService.hasCartAccess();
    }

    function setSelectedSupplier () {
      ctrl.selected_supplier = _.get(ctrl, 'checkedItems[0].supplier', null);
    }

    function checkSelectDisable (bundle) {
      if (!ctrl.use_single_supplier) {
        return false;
      } else {
        return (!_.isNil(ctrl.selected_supplier) && (bundle.name !== ctrl.selected_supplier) );
      }
    }

    /** 
     * Adds a UI object to each item with its localized
     * tracking method and updated quantity on hand which
     * factors in the tracking method. 
     * 
     * @param {Array} items 
     * 
     * @return {Array} 
    */
    function parseItems(items = []) {
      for (const item of items) {
        // Use the logical OR operator to ensure that "Item"
        // is default (in case item.tracking_method is null)
        const tracking_method = _.get(item, 'tracking_method') || 'Item';
        const localized_tracking_method = $filter('translate')('COMMON.' + tracking_method.toUpperCase());
        const localized_buying_method = inventoryHelperService.getBuyingLabel(item);
        const qty_on_hand = inventoryItemService.calculateInventoryOnHand(item);

        _.set(item, 'UI.localized_tracking_method', localized_tracking_method);
        _.set(item, 'UI.localized_buying_method', localized_buying_method);
        _.set(item, 'UI.qty_on_hand', qty_on_hand);
        _.set(item, 'UI.is_discontinued', inventoryItemService.isItemDiscontinued(item));
      }
      return items;
    }

    function handleAddToCartClick(item) {
      sowAnalyticsService.shoppingListAddToCartClicked();
      addToCart(item);
    }

    function handleRemoveClick(item) {
      sowAnalyticsService.shoppingListRemoveClicked();
      removeItem(item);
    }

  }
}());

(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.office')
    .service('TrialEndDialog', TrialEndDialog);

  var associationCopy = {
    'oda': {
      'logo': 'styles/img/logos/partners/oda.png',
      'name': 'Ontario Dental Association',
    },
    'oma': {
      'logo': 'styles/img/logos/partners/oma-logo.png',
      'name': 'Ontario Medical Association',
    },
  };

  function TrialEndDialog ($rootScope, $mdDialog, StartupDialogService, sessionService, officeService, subscriptionService) {
    /*jshint validthis: true */
    var service = this;

    service.show = showDialog;
    service.shouldShowDialogOnStartup = shouldShowDialogOnStartup;

    initialize();

    return service;

    //
    // Initialize this service.
    //
    function initialize () {
      // StartupDialogService.registerDialog({
      //   'show': showDialog,
      //   'shouldShowFunc': shouldShowDialogOnStartup,
      // });
    }

    //
    // Open the "Trial End" Dialog
    //
    function showDialog ($event) {
      return $mdDialog.show({
        'templateUrl': 'templates/marketplace/office/trial-end-dialog.html',
        'controller': TrialEndDialogCtrl,
        'controllerAs': '$trialEndDialogCtrl',
        'targetEvent': $event,
        'escapeToClose': true,
        'parent': angular.element(document.body),
        'fullscreen': true,
        'resolve': {
          'associationDiscount': function () {
            return officeService.get(true).then(function (office) {
              var association_id = _.get(office, 'association_membership.professional_association_id');

              if (!association_id) {
                return;
              } else {
                return subscriptionService.getPromotions({'professional_association_id': association_id}).then(function (promotions) {
                  if (promotions && promotions.length === 1) {
                    return promotions[0];
                  }
                });
              }
            });
          },
        }
      })['finally'](function () {
        return sessionService.updateFlag('prompted_for_premium', true);
      });
    }

    //
    // Should we launch this dialog for this user?
    //
    function shouldShowDialogOnStartup () {
      return (
        _.get($rootScope, 'current_office.account_type') == 'Free' &&
        _.get($rootScope, 'current_office.trial_over') &&
        !_.get(sessionService, 'session.current_membership.flags.prompted_for_premium') &&
        !_.get($rootScope, 'current_office.flags.prompted_for_premium')
      );
    }

  }

  /* @ngInject */
  function TrialEndDialogCtrl ($scope, $rootScope, $log, $state, $mdDialog, officeService, associationDiscount, subscriptionService) {
    /*jshint validthis: true */
    var ctrl = this;

    $scope.associationDiscount = associationDiscount;
    $scope.featuresCollapsed = true;

    ctrl.timeIsLeft = officeService.isFreeTrialActive();

    ctrl.close = closeDialog;
    ctrl.upgrade = upgrade;
    ctrl.init = init;

    ctrl.init();

    return ctrl;

    //
    // Initialize controller
    //
    function init () {
      var premiumPlan = null;
      var premiumPlusPlan = null;

      subscriptionService.getPlans().then(function(plans){
        _.forEach(plans, function (plan) {
          if (plan.hrid === 'premium' && plan.billing_cycle === 'per_year') {
            premiumPlan = plan;
          }
          if (plan.hrid === 'premium_plus' && plan.billing_cycle === 'per_year') {
            premiumPlusPlan = plan;
          }
        });
      
        if (associationDiscount && associationDiscount.discount_type === 'PERCENT_OFF') {
          premiumPlan.per_month_cost -= premiumPlan.per_month_cost * (associationDiscount.discount_value / 100.0);
          premiumPlusPlan.per_month_cost -= premiumPlusPlan.per_month_cost * (associationDiscount.discount_value / 100.0);
        }

        $scope.premiumPlan = premiumPlan;
        $scope.premiumPlusPlan = premiumPlusPlan;
      });
    }

    //
    // Close the Dialog
    //
    function closeDialog () {
      return $mdDialog.cancel();
    }

    //
    // Select a Premium Plan
    //
    function upgrade () {
      $state.go('app.office.profile.subscription.upgrade', {'billing_cycle': 'per_year'});
      return $mdDialog.hide();
    }
  }

}());

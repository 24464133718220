(function () {
  'use strict';
  angular.module('sowOrders')
  .controller('OrdersDetailController', OrdersDetailController);

  function OrdersDetailController($q, $rootScope, $scope, $filter, $stateParams, errorService, orderService, sowOrderService, mktHelperService, sowExternalVendorAccountService, EVActionsHelperService) {
    const ctrl = {
      ...this,
      $onInit: init,
      getBillingText,
      getPromoCodes,
      getPromoValue,
      goToOrder,
      handleReceiveShipmentClick,
      handleMarkAsReceivedClick,
      isExternal,
      shouldShowEvInfo,
      shouldShowPromoCodes,
      export_and_email_payload: {}
    };

    return ctrl;

    function init () {
      $scope.$on('mkt-order-item-updated', _updateOrderInfo);
      $scope.$on('order-marked-as-received', () => {
        loadOrderLegacy();
      });

      if($stateParams.search_param) {
        loadMixedCheckout();
      } else {
        loadOrderLegacy();
      }
    }

    /**
     * The function calculates and formats the total promotional value for an order, including both
     * internal and external promo codes.
     * @param {MarketplaceOrder} order - the order for which to calculate the total promotional value
     * @return {string} a string that represents the total promotional value of an order, formatted as a
     * currency with a dollar sign and two decimal places. If the total promotional value is greater
     * than zero, the string is preceded by a minus sign to indicate a discount.
     */
    function getPromoValue (order = ctrl.order) {
      const sowingo_value = _.toNumber(_.get(order, 'promo_code_value', 0));
      const external_value = _.get(order, 'external_promo_codes_saving', 0);
      const total_promo_value = _.add(sowingo_value, external_value);
      let text = $filter('currency')(total_promo_value,'$',2);
      if (total_promo_value > 0) {
        text = "- "+text;
      }
      return text;
    }

    /**
     * This function returns promo codes from an order, separated by commas.
     * @param {MarketplaceOrder} order - The order from which to get the promo codes
     * @return {string} all the promo codes applied to an order, separated by commas. 
     * If there are no promo codes applied, it returns an empty string.
     */
    function getPromoCodes (order = ctrl.order) {
      const has_sowingo_code = _.get(order, 'promo_code', false);
      const has_external_codes = _.get(order, 'external_promo_codes_applied', false);
      let promo_codes = [];
      if (has_sowingo_code) {
        promo_codes.push(has_sowingo_code);
      }
      if (has_external_codes) {
        promo_codes = _.concat(promo_codes, has_external_codes);
      }
      return promo_codes.join(', ');
    }

    /**
     * Determines wether the promo codes section is shown by checking if there are any promo codes applied to the order.
     * @param {MarketplaceOrder} order - The order for which to check for promo codes
     * @return {boolean} true if there are any promo codes applied to the order, false otherwise
     */
    function shouldShowPromoCodes (order = ctrl.order) {
      const sowingo_code = _.get(order, 'sowingo_promo_code.code', _.get(order, 'vendor_promo_code.code', null));
      const external_code = _.get(order, 'external_promo_codes_applied', null);
      return sowingo_code || external_code;
    }

    function loadOrderLegacy () {
      const order_id = $stateParams.order_number || $stateParams.hrid;
      return orderService.getOrder({
        id: order_id,
        hrid: order_id
      })
      .then(handleOrder)
      .catch(function (error) {
        var t_message = $filter('translate')('ERRORS.ORDER_ITEMS_RETRIEVE')
        errorService.uiErrorHandler(error || t_message);
      });
    }

    function handleOrder(order) {
      ctrl.order = mktHelperService.parseOrder(order);
      ctrl.all_orders = [ctrl.order];
      _setOrdersSummary(ctrl.all_orders);
      _checkForExternalOrders(ctrl.all_orders);
      _setExportAndEmailPayload(order);
      return order;
    }


    /**
     * Sets the export and email payload for the given order.
     * The payload will be handled by the wofe-react component export-and-email-button.
     *
     * @param {Object} order - The order object.
     * @param {number} order.id - The ID of the order.
     */
    function _setExportAndEmailPayload(order) {
      ctrl.export_and_email_payload = {
        order_id: order.id,
      };
    }

    function loadMixedCheckout () {
      ctrl.mixed_checkout = true;
      const hrid = $stateParams.order_number || $stateParams.hrid || null;
      const search_param = $stateParams.search_param;
      if(!hrid) return;
      return sowOrderService.fetchSingleOrder(hrid, search_param)
      .then(function(response){
        ctrl.purchase_orders = response.purchase_orders;
        _.map(ctrl.purchase_orders, (po) => { return mktHelperService.parsePO(po); });
        if(_.size(response.orders) > 0){
          ctrl.order = mktHelperService.parseOrder(response.orders[0]);
          ctrl.all_orders = [ctrl.order, ...ctrl.purchase_orders];
        } else {
          ctrl.all_orders = [...ctrl.purchase_orders];
        }

        _setOrdersSummary(ctrl.all_orders);
        _checkForExternalOrders(ctrl.all_orders);
      });
    }

    function _setOrdersSummary(orders) {
      ctrl.summary = mktHelperService.getOrdersSummary(orders);
      ctrl.hasCancelledOrRefunded = ctrl.summary.cancelled || ctrl.summary.refunded;
      ctrl.receivable_items = _getReceivableItems(orders);
      _setSectionsVisibility(ctrl.order);
    }

    /**
     * Gets the items (if any) that have not been received yet.
     * @param {object[]} orders An array of orders to check for unreceived items
     * @returns {object[]} An array of items that have not been received yet
     */
    function _getReceivableItems(orders) {
      const itemMap = {};
      for (const order of orders.filter(({status}) => ['Open', 'Completed'].includes(status))) {
        for (const group of order.order_vendor_groups) {
          for (const item of group.order_items) {
            let quantity_receivable = 0;
            const quantity = Number(item.quantity);
            if (quantity) {
              quantity_receivable += quantity;
            }
            const quantity_free = Number(item.quantity_free);
            if (quantity_free) {
              quantity_receivable += quantity_free;
            }
            const quantity_cancelled = Number(item.quantity_cancelled);
            if (quantity_cancelled) {
              quantity_receivable -= quantity_cancelled;
            }
            const quantity_returned = Number(item.quantity_returned);
            if (quantity_returned) {
              quantity_receivable -= quantity_returned;
            }
            itemMap[item.id] ??= {...item, UI: {...item.UI, quantity_receivable}};
          }
          for (const shipment of group.shipments) {
            for (const item of shipment.items) {
              /* due to API inconsistency the ID may be one of two properties */
              const map_item = itemMap[item.order_item_id] ?? itemMap[item.id];
              const quantity_received = Number(item.quantity_received);
              if (map_item && quantity_received) {
                map_item.UI.quantity_receivable -= quantity_received;
              }
            }
          }
        }
      }
      return Object.values(itemMap).filter(i => i.UI.quantity_receivable > 0);
    }

    function goToOrder () {
      // will only happen on single order checkouts
      let order = ctrl.all_orders[0];
      mktHelperService.goToOrder(order);
    }

    /**
    * Generates a string indicating how a particular vendor billed the user.
    * @param {Object} vendor the vendor under test
    * @returns {string} text indicating the vendor's billing method
    */
    function getBillingText(vendor) {
      switch (vendor.checkout_type) {
        case 'marketplace.creditcart.charge_on_checkout':
        case 'marketplace.creditcart.charge_on_shipping':
        case 'marketplace.creditcart.charge_by_vendor':
          return $filter('translate')('MARKETPLACE.CHECKOUT.CC_CHARGED');
        case 'external_vendor':
          return null;
        case 'marketplace.invoice':
          return $filter('translate')('MARKETPLACE.CHECKOUT.INVOICED');
        case 'external_vendor':
        default:
          return null;
      }
    }

    /**
     * If shipment exists, it will open the receive order slideout.
     * @param {{detail: {shipment: object}}} $event
     */
    function handleReceiveShipmentClick ($event) {
      const {detail: {shipment}} = $event;
      if (shipment) {
        const parsed_shipment = _parseShipmentOrder(shipment);
        $rootScope.$broadcast('order-receive-open', parsed_shipment);
      }
    }

    function handleMarkAsReceivedClick() {
      $rootScope.$broadcast('mark-order-received-open', {
        ...ctrl.order,
        UI: {...ctrl.order.UI, receivable_items: ctrl.receivable_items},
      });
    }

    /**
     * Adds the shipment_id and order_hrid to the shipment object, 
     * and parses the items to include the quantity shipped in this shipment.
     * @param {object} shipment
     * @return {object} 
     */
    function _parseShipmentOrder (shipment) {
      return {
        ...shipment,
        shipment_id: shipment.id,
        order_hrid: ctrl.order.hrid,
        items: _parseItems(shipment.items),
      }
    }

    /**
     * Parses the items to include the quantity shipped in this shipment.
     * @param {object[]} items
     * @return {object[]}
     */
    function _parseItems (items) {
      return _.map(items, (item) => {
        return {
          ...item,
          quantity: _.toNumber(item.quantity_shipped_in_this_shipment) - (_.toNumber(item.quantity_received) || 0),
        }
      })
    }

    /**
     * Updates the order info if the order has been updated.
     * @param {object} _
     * @param {object} data
     * @param {object} data.order
     */
    function _updateOrderInfo (_, data) {
      if (data.order) {
        loadOrderLegacy();
      }
    }

    /**
     * Checks all orders for any external vendors, and if it finds any, it
     * calls _getUpdateFromExternalOrder() to get the latest status from the external vendor
     * then updates it on the monolith.
     * 
     * @param {MarketplaceOrder[]} orders - an array of orders to check for external orders
     */
    function _checkForExternalOrders (orders = ctrl.all_orders) {
      // If the order is already completed or cancelled, we don't need to check for external orders
      if (['completed', 'cancelled'].includes(ctrl.order?.status?.toLowerCase())) return;
      
      _.each(orders, (local_order) => {
        // Need to check at group level for the external checkout type,
        // even though the object we use is the whole order itself
        // (The reason why we have groups is because at some point we introduced multi-vendor checkout
        // along with POs, which won't be a thing 2023-forward, but is here for compatibility)
        // For any orders past 2023, it's safe to assume they will have only 1 vendor group.
        _.each(local_order.order_vendor_groups, (group) => {
          const is_ev = isExternal(group);
          if (is_ev) {
            // wait for EV info to available on $rootScope
            $q.when($rootScope.ev_data_loaded).then(() => {
              const ev_info = _findEvFromVendor(group.vendor);

              _getUpdateFromExternalOrder(local_order, ev_info)
              .then((ev_order) => {
                if (ev_order) {
                  _updateExternalOrderOnMonolith(ev_order)
                  .then((monolith_updated_order) => {
                    local_order = monolith_updated_order;
                  });
                }
              });
            });
          }
        });
      });
    }

    /**
     * Gets the status of an order from the microservice.
     * 
     * @param {MarketplaceOrder} order - the order object from the monolith
     * @param {ExternalVendorInfo} ev_info - the ExternalVendorInfo object from microservice
     * 
     * @return {Promise<ExternalVendorOrder>} - the response from the getOrderStatus function.
     */
    function _getUpdateFromExternalOrder (order, ev_info) {
      const { id: monolith_order_id, reference_number } = order;

      return sowExternalVendorAccountService.getOrderStatus(monolith_order_id, reference_number, ev_info.connected_name);
    }

    /**
     * Takes a microservice up-to-date order, 
     * sends it to the monolith API and returns the monolith updated order.
     * 
     * @param {ExternalVendorOrder} ev_order - the external vendor order object from the microservice.
     * 
     * @return {Promise<MarketplaceOrder>} - The updated sowingo order from monolith.
     */
    function _updateExternalOrderOnMonolith (ev_order) {
      return sowOrderService.updateOrderFromEV(ev_order);
    }

    function _findEvFromVendor (vendor) {
      const ev_info = _.get($rootScope.external_vendors, vendor.id);
      return ev_info;
    }

    function isExternal (group) {
      return EVActionsHelperService.isExternalVendor(group);
    }

    function shouldShowEvInfo (order) {
      return _.some(order?.order_vendor_groups, isExternal);
    }

    function _setSectionsVisibility (order = ctrl.order) {
      ctrl.show = {
        billing_address: !_.isEmpty(order?.billing_address?.address1),
        shipping_info: !_.isEmpty(order?.shipping_info?.address1),
        card_details: !_.isEmpty(order?.card_details?.last4),
        credit_card_address: !_.isEmpty(order?.credit_card_address?.address1),
      };
    }
  }

}());

(function () {
    'use strict';
    angular.module('sowUserAdmin')
    .controller('ImplantPlacementByOfficeCtrl', ImplantPlacementByOfficeCtrl);

    function ImplantPlacementByOfficeCtrl ($scope, orderByFilter , $filter , sowAllOfficeService) {
    /*jshint validthis: true */
    var ctrl = this;
    ctrl.office_count = null;
    ctrl.selectedYear = moment().year();
    ctrl.propertyName = 'name';
    ctrl.reverse = false;
    ctrl.rangeOptions = [{
      name: moment().year(),
      value: moment().year()
    }];

    ctrl.dfYear = {
      name : '',
      jan: 0,
      feb: 0,
      mar: 0,
      apr: 0,
      may: 0,
      jun: 0,
      jul: 0,
      aug: 0,
      sep: 0,
      oct: 0,
      nov: 0,
      dec: 0,
      total: 0
    }

    ctrl.pe = {
      jan: {
        color: '',
        value: 0
      },
      feb: {
        color: '',
        value: 0
      },
      mar: {
        color: '',
        value: 0
      },
      apr: {
        color: '',
        value: 0
      },
      may: {
        color: '',
        value: 0,
      },
      jun: {
        color: '',
        value: 0
      },
      jul: {
        color: '',
        value: 0
      },
      aug: {
        color: '',
        value: 0
      },
      sep: {
        color: '',
        value: 0
      },
      oct: {
        color: '',
        value: 0
      },
      nov: {
        color: '',
        value: 0
      },
      dec: {
        color: '',
        value: 0
      },
      total: {
        color: '',
        value: 0
      }
    }

    ctrl.yearTotal = {
      name: $filter('translate')('REPORTS.PLACEMENT.YEARLY_TOTAL'),
      current: angular.copy(ctrl.dfYear),
      previous: angular.copy(ctrl.dfYear)
    }

    ctrl.init = function(){
      ctrl.updateYearOptions();
      ctrl.getImplantDaboard();
    }

    ctrl.updateYearOptions = function() {
        var first_date = moment($scope.$root.current_office.created_at).startOf('year');
        var last_date = moment();
        var years_count = last_date.diff(first_date, 'y');
  
        for (var i = 1; i <= years_count; i++) {
          var year_number = moment().subtract(i,'y').format('YYYY');
          ctrl.rangeOptions.push({
              name: year_number,
              value: year_number
          });
        }
    }

    ctrl.getImplantDaboard = function(){
      var start_date = moment(ctrl.selectedYear.toString(), 'YYYY').startOf('year').format('YYYY-MM-DD');
      var end_date = moment(ctrl.selectedYear.toString(), 'YYYY').endOf('year').format('YYYY-MM-DD');
      var previous_start_date =  moment((ctrl.selectedYear - 1).toString(), 'YYYY').startOf('year').format('YYYY-MM-DD');
      var previous_end_date = moment((ctrl.selectedYear - 1).toString(), 'YYYY').endOf('year').format('YYYY-MM-DD');

      sowAllOfficeService.getOfficeImplant(start_date, end_date, previous_start_date, previous_end_date).then(function(rs){
        ctrl.data = ctrl.changeData(rs);
        ctrl.sortBy(ctrl.propertyName);
      });
    }

    ctrl.updateCurrentYear = function(){
      ctrl.getImplantDaboard();
    }

    ctrl.changeData = function(rs){
      var data = [];
      var yearTotal = angular.copy(ctrl.yearTotal);
      rs.forEach(function(el){
        var ob = {};
        ob.name = el.office.name;
        ob.id = el.office.id;

        var current = el.report.reports.current;
        var previous = el.report.reports.previous;
        var c = ctrl.checkvalueOfMonth(current);
        var p = ctrl.checkvalueOfMonth(previous);
        ob.current = c;
        ob.previous = p;
        var percent = ctrl.calPercent(c,p);
        ob.percent = percent;
        data.push(ob);


        var k = Object.keys(ctrl.dfYear);
        yearTotal.current.name = c.name;
        yearTotal.previous.name = p.name;
        k.forEach(function(k){
          if(k != 'name'){
            if(c.name == moment().year()){
              var m = moment().month() + 1;
              if(moment(k, 'MMM').format('M') > m){
                yearTotal.current[k] = null;
              }else{
                yearTotal.current[k] += c[k];
              }
            }else{
              yearTotal.current[k] += c[k];
            }
            yearTotal.previous[k] += p[k];
          }
        });
        yearTotal.percent = ctrl.calPercent(yearTotal.current, yearTotal.previous);
      })
      ctrl.totalYear = yearTotal;

      return data;
    }

    ctrl.sortBy = function (propertyName) {
      ctrl.reverse = (ctrl.propertyName === propertyName) ? !ctrl.reverse : false;
      ctrl.propertyName = propertyName;
      var allProduct = ctrl.data;
      if (allProduct.length > 0) {
        ctrl.data = orderByFilter(allProduct, ctrl.propertyName, ctrl.reverse);
      }
    };

    ctrl.calPercent = function(c, p){
      var pe = angular.copy(ctrl.pe);
      var k = Object.keys(ctrl.dfYear);

      k.forEach(function(k){
        if(k !== 'name'){
          if(c[k] == null || p[k] == null){
            pe[k].value = '';
            pe[k].color = 'green-color';
          }else if(p[k] === 0){
            if(c[k] === 0){
              pe[k].value = '';
              pe[k].color = 'df-color';
            }else{
              pe[k].value = null;
              pe[k].color = 'green-color';
            }
          }else{
            var val = (c[k] - p[k]) * 100;
            if(val != 0){
              val = val/p[k];
            }
            pe[k].value = val;

            if (val > 0) {
              pe[k].color = 'green-color';
            } else if (val < 0) {
              pe[k].color = 'red-color';
            } else {
              pe[k].color = 'df-color';
            }
          }
        }
      });
      return pe;
    }

    ctrl.checkvalueOfMonth = function(monthList){
      var dfYear = angular.copy(ctrl.dfYear);
      var year = moment(monthList.end_date, 'YYYY-MM-DD').format('YYYY');
      dfYear.name = year;
      var total = 0;

      if(year == moment().year()){
        var month = moment().month() + 2;
        for(var i = month; i < 13 ; i++){
          var m = moment(i, 'M').format('MMM').toLowerCase();
          dfYear[m] = null;
        }
      }

      if(monthList.implants_placed.length > 0){
        monthList.implants_placed.forEach(function(el){
           var month_name = moment(el.date, 'MMM, YYYY').format('MMM').toLowerCase();
           dfYear[month_name] = el.placement_count;
           total = parseInt(total) + parseInt(el.placement_count);
        });
      }
      dfYear.total = total;
      return dfYear;
    }

    ctrl.init();
   
  }

}());

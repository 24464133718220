angular.module('app.marketplace.elements').service('subscriptionReceiptModel',
  function subscriptionModel(apiUrl, commonApiService) {
    var _this = this;
    this.model_data = {
      name: 'subscriptionReceipt',
      display_name: 'Subscription Receipt',
      api: {
        single: function (options) {
          if (!checkNested(options, 'data', 'id')) {
            throw 'Missing option(s) for Subscription Receipt path "single".';
          }
          return commonApiService.getOfficeUrl() + "/subscriptions/receipts/" + options.data.id;
        },
        multiple: function (options) {
          return commonApiService.getOfficeUrl() + "/subscriptions/receipts";
        }
      }
    };


    this.autofill = function(element) {
      element.event_data = JSON.parse(element.event_data);

      var subscription_data = null;
      if (checkNested(element, 'event_data', 'data', 'object', 'lines', 'data')){
          for (var i = 0; i < element.event_data.data.object.lines.data.length; i++) {
              var line = element.event_data.data.object.lines.data[i];
              if (line.type === 'subscription') {
                  subscription_data = line;
                  break;
              }
          }
      }

      if (checkNested(subscription_data, 'plan', 'name')){
        element.item_name = subscription_data.plan.name;
      } else if (element.total < 0) {
          element.item_name = 'Subscription Refund';
      }

      if (checkNested(subscription_data, 'period', 'end')){
        element.expires_at = new Date(parseInt(subscription_data.period.end) * 1000);
      }
      
      if(element.is_monthly || element.is_monthly===false){
        element.billing_cycle = element.is_monthly ? 'per_month' : 'per_year';
      }else if (checkNested(subscription_data, 'plan', 'interval')){
        element.billing_cycle = subscription_data.plan.interval==='year' ? 'per_year' : 'per_month';
      }
      
      if(element.discount){
        element.discount = parseFloat(element.discount);
      }
    };
  }
);

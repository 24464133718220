(function () {
  
  function ExternalVendorCP (VendorCP, SupplierCP, SalesRepCP) {
    const Vendor = VendorCP.getClass();
    const Supplier = SupplierCP.getClass();
    const SalesRep = SalesRepCP.getClass();
    
    /**
    *
    * @class ExternalVendor
    * @typedef ExternalVendor
    */
    class ExternalVendor extends Vendor {
      constructor(json_data) {
        super(json_data);
        this.account = json_data.account;
        this.description = json_data.description;
        this.supplier = new Supplier(json_data.supplier);
        this.connected_name = json_data.connected_name;
        this.vendor_name = json_data.vendor_name;
        if (json_data.primary_sales_rep?.id) {
          this.primary_sales_rep = new SalesRep(json_data.primary_sales_rep);
        } else {
          this.primary_sales_rep = null;
        }
      }

      account;
      get account () {
        return this.account;
      }
      set account (new_account) {
        this.account = new_account;
      }
      
      connected_name;
      get connected_name () {
        return this.connected_name;
      }
      set connected_name (new_connected_name) {
        this.connected_name = new_connected_name;
      }

      description;
      get description () {
        return this.description;
      }

      supplier;
      get supplier () {
        return this.supplier;
      }

      primary_sales_rep;
      get primary_sales_rep () {
        return this.primary_sales_rep;
      }
      set primary_sales_rep (new_primary_sales_rep) {
        this.primary_sales_rep = new_primary_sales_rep;
      }
      
      vendor_name;
      get vendor_name () {
        return this.vendor_name;
      }
      

    }
    
    this.getClass = () => {
      return ExternalVendor;
    }
  }
  
  angular.module('sowMarketplace')
  .service("ExternalVendorCP", ExternalVendorCP);
})();

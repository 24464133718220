angular.module("app.shared.support")
  //Select element text if expression is true
  .directive('selectMe', function ($timeout, $parse, $window) {
    return {
      link: function (scope, element, attrs) {
        var model = $parse(attrs.selectMe);
        scope.$watch(model, function (value) {
          if (value === true) {
            $timeout(function () {
              element.select();
              if ($window.getSelection && element[0].setSelectionRange &&
                (!$window.getSelection() || !$window.getSelection().toString())) {
                // Required for mobile Safari
                element[0].setSelectionRange(0, element[0].value.length);
              }
            });
          }
        });
      }
    };
  });
angular.module("app.shared.support")
  .directive('onlyDigits',
    function () {
      return {
        restrict: 'A',
        require: '?ngModel',
        link: function (scope, element, attrs, modelCtrl) {
          modelCtrl.$parsers.push(function (inputValue) {
            if (inputValue === undefined || inputValue === null) {
              return '';
            }
            var transformedInput = inputValue.replace ? inputValue.replace(/[^0-9]/g, '') : inputValue;
            if (transformedInput !== inputValue) {
              modelCtrl.$setViewValue(transformedInput);
              modelCtrl.$render();
            }
            return transformedInput;
          });
        }
      };
    });
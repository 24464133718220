(function () {
  'use strict';

  angular
    .module('app.marketplace.elements')
    .config(registerEvents);

  //
  // Register accountService Events
  //
  function registerEvents(appEventsProvider) {
    appEventsProvider.registerEvent('setAccount', 'accountService: set-account');
    appEventsProvider.registerEvent('clearAccount', 'accountService: clear-account');
  }

}());

angular
  .module("app.marketplace.elements")
  .constant('ElementModelMap', {
    'account': 'accountModel',
    'registration': 'registrationModel',
    'product': 'productModel',
    'category': 'categoryModel',
    'cart': 'cartModel',
    'subscription': 'subscriptionModel',
    'subscriptionReceipt': 'subscriptionReceiptModel',
    'membership': 'membershipModel',
    'office': 'officeModel',
    'session': 'sessionModel',
    'productTemplate': 'productTemplateModel',
    'inventoryItem': 'inventoryItemModel',
    'manufacturer': 'manufacturerModel',
    'vendor': 'vendorModel',
    'sterilizationRecord': 'sterilizationRecordModel',
    'sterilizer': 'sterilizerModel',
    'order': 'orderModel',
    'orderItem': 'orderItemModel',
    'purchaseOrder': 'purchaseOrderModel',
    'return': 'returnModel',
    'exchange': 'exchangeModel',
    'contentBlock': 'contentBlockModel',
    'supplier': 'SupplierModel',
    'dailyRecord': 'DailyRecordModel',
    'DailyRecordField': 'DailyRecordFieldModel',
  })
angular.module('app.marketplace.ui.theme')

  //
  // TODO Somehow dynamically generate this, and merge it with SowingoThemeConfig
  //
  .constant('SowingoColors', {
    blue1: { red: 57, green: 142, blue: 227 },
    blue2: { red: 44, green: 110, blue: 176 },
    green1: { red: 51, green: 190, blue: 140 },
    green2: { red: 38, green: 140, blue: 103 },
    red1: { red: 222, green: 71, blue: 69 },
    red2: { red: 171, green: 55, blue: 53 },
    purple1: { red: 142, green: 92, blue: 213 },
    orange1: { red: 250, green: 158, blue: 77 },
    pink1: { red: 219, green: 86, blue: 99 },
    syscolor1: { red: 25, green: 45, blue: 56 },
    syscolor2: { red: 77, green: 88, blue: 107 },
    syscolor3: { red: 171, green: 185, blue: 196 },
    syscolor4: { red: 237, green: 238, blue: 240 },
    syscolor5: { red: 245, green: 245, blue: 245 },
    syscolor6: { red: 255, green: 255, blue: 255 },
    syscolor7: { red: 250, green: 250, blue: 250 },
    syscolor8: { red: 132, green: 134, blue: 142 }
  });

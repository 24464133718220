angular.module('app.marketplace.ui.office')
.controller('officeProfileAbController', officeProfileAbController)
.directive('actionBarOfficeProfile', actionBarOfficeProfile);

function actionBarOfficeProfile () {
  return {
    restrict: 'AC',
    templateUrl: 'templates/marketplace/office/directives/action-bar-office-profile.html',
    // controller: 'officeProfileAbController',
    // super XGH hack
    controller: 'InventoryActionBarController',
    controllerAs: 'offAbCtrl'
  };
}

function officeProfileAbController ($scope, $rootScope, $stateParams, sowReportsService, membershipService) {
  /*jshint validthis: true*/
  var ctrl = this;

  ctrl.addUser = addUser;
  ctrl.addRep = addRep;
  ctrl.addDoctor = addDoctor;
  ctrl.newUser = newUser;
  ctrl.addToOffice = addToOffice;

  ctrl.exportSuppliers = sowReportsService.exportSuppliers;
  ctrl.exportLocations = sowReportsService.exportLocations;

  init();

  return ctrl;

  function init () {
    defineLocks();
  }

  function addDoctor () {
    $rootScope.$broadcast('office-ab-add-doctor');
  }

  function addUser () {
    $rootScope.$broadcast('office-ab-add-user');
  }

  function addRep () {
    $rootScope.$broadcast('office-ab-add-rep');
  }
  
  function newUser () {
    $rootScope.$broadcast('new-user-modal');
  }

  function addToOffice () {
    $rootScope.$broadcast('user-add-to-office-modal');
  }

  function defineLocks () {
    const membership = membershipService.get();
    
    const supplier_lock_condition = _.get(membership, "user_properties.suppliers.disable_page", false);
    ctrl.disabled_suppliers_page = supplier_lock_condition;

    const billing_lock_condition = _.get(membership, "user_properties.billing.disable_page", false);
    ctrl.disabled_billing_page = billing_lock_condition;

    const admin_tools_lock_condition = _.get(membership, "user_properties.admin_tools.disable_page", false);
    ctrl.disabled_admin_tools_page = admin_tools_lock_condition;

    const supplier_add_condition = _.get(membership, "user_properties.suppliers.disable_add_new", false);
    ctrl.disabled_suppliers_add_new = supplier_add_condition;
    const rep_add_condition = _.get(membership, "user_properties.sales_reps.disable_add_new", false);
    ctrl.disabled_sales_reps_add_new = rep_add_condition;
  }
}

angular.module('app.marketplace.ui.inventory')

.directive('inventoryMedicationLineItem', function() {
  return {
    restrict: 'E',
    controllerAs: 'ctrl',
    scope: true,
    bindToController: {
      item: '='
    },
    controller: function() {},
    template: '<div layout="row">'
      + '<img ng-src="{{ ctrl.item.image_url | imageUrl }}" class="md-avatar" flex="none" />'
      + '<div class="md-list-item-text p1" flex>'
      +   '<h3 flex="100">{{ ctrl.item.name }}</h3>'
      +   '<div flex="100" class="manufacturer-name">'
      +     '<span ng-show="ctrl.item.supplier_name">from: {{ ctrl.item.supplier_name }} </span>'
      +     '<span ng-show="ctrl.item.manufacturer_name">by: {{ ctrl.item.manufacturer_name }}</span>'
      +   '</div>'
      + '</div>'
      + '<div class="p1" ng-show="ctrl.item.dosage && ctrl.item.unit_of_measurement" flex="nogrow">'
      +   '<span>Dosage: {{ ctrl.item.dosage }} {{ ctrl.item.unit_of_measurement }}</span>'
      + '</div>'
    + '</div>'
  };
});

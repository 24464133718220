angular.module("app.shared.system")
  //Provides access to end points that are not tied to an element. //TODO reassess name and organization?
  .service("commonApiService", function ($rootScope, serverAPI, apiUrl) {
    return {
      getProvinces: function () {
        var params = {};
        return serverAPI.doAPICall(apiUrl + "/provinces", params);
      },
      getOfficeUrl: function () {
        return apiUrl + "/offices/" + $rootScope.current_office.id;
      }
    };
  });

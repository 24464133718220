angular.module("app.shared.support")
  .filter('orderObjectBy', function () {
    return function (items, field, reverse) {
      var filtered = [];

      angular.forEach(items, function (item) {
        filtered.push(item);
      });

      filtered.sort(function (lhs, rhs) {
        return lhs[field] > rhs[field] ? 1 : -1;
      });

      if (reverse) {
        filtered.reverse();
      }

      return filtered;
    };
  });
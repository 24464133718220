angular.module("app.shared.support")
  .directive('inventoryItemCard', function () {
    return {
      restrict: 'A',
      replace: true,
      templateUrl: 'templates/marketplace/elements/inventory_item/inventory-item-card.html',
      link: function ($scope, $element, $attributes) {
        $scope.options = {};
        $scope.options.hideAdd = $attributes.hideAdd; //Optional to indicate that the add button should be hidden.
        $scope.options.name = $attributes.name; //Optional to change what field is being used as a display name.
      }
    };
  });
angular.module('app.marketplace.ui.registration')
  .directive('supplyHubDashboard', function () {
    return {
      restrict: 'E',
      controllerAs: 'ctrl',
      templateUrl: 'templates/marketplace/inventory/directives/supply-hub-dashboard.html',
      scope: true,
      bindToController: {
        context: '@'
      },
      controller: function ($mdDialog, $state) {
        var ctrl = this;

        ctrl.close = close;
        ctrl.goToMkt = goToMkt;

        function goToMkt() {
          $state.go('app.mkt');
          close();
        }

        function close() {
          $mdDialog.cancel();
        }
      }
    };
  });

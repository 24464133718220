(function () {
  'use strict';
  angular.module('sowSterilization')
  .controller('stznPendingTestActionsController', stznPendingTestActionsController);

  function stznPendingTestActionsController ($rootScope, $mdDialog, errorService,
    sowSterilizationService, $filter, stznHelperService, formService) {
    /*jshint validthis:true*/
    var ctrl = this;
    ctrl.saving_record = false;
    ctrl.dialog_version = '';
    
    ctrl.cancelDialog = cancelDialog;
    ctrl.saveRecord = saveRecord;
    ctrl.setResult = setResult;
    ctrl.getDurationLabel = getDurationLabel;
    ctrl.getIncubationTimeLabel = getIncubationTimeLabel;

    ctrl.confirmFailDialog = stznHelperService.confirmActionDialog;

    init();

    return ctrl;

    function init () {
      ctrl.dialog_version = stznHelperService.getRecordVersion(ctrl.record_data);
      var t_pending = $filter('translate')('STERILIZATION.PENDING');
      var type_name = stznHelperService.records_type_names[ctrl.dialog_version];
      type_name = $filter('translate')(type_name);
      ctrl.dialog_title = "{0} {1}".format(t_pending, type_name);

      // set up the end time and date to display on screen
      const end_date = stznHelperService.setEndDateUsingIncubationData({
        tested_at: ctrl.record_data.tested_at,
        incubator_time: ctrl.record_data.incubator_time,
      })
      _.set(ctrl.record_data, 'result_recorded_at', end_date);
      
    }

    function cancelDialog () {
      return $mdDialog.cancel();
    }

    function setResult(result) {
      if(ctrl.saving_record){
        return 0;
      }
      if(!formService.checkRequiredFields(ctrl.pendingForm)){
        return 0;
      }

      _.set(ctrl.record_data, 'result', result);

      if(result === "Failed" || result === "Control Passed"){
        ctrl.confirmFailDialog(ctrl.record_data).then(function(response){
          if(response === true){
            saveRecord();
          }
        });
      } else {
        saveRecord();
      }
    }

    function saveRecord () {
      ctrl.saving_record = true;

      var new_data = _.pick(ctrl.record_data, ['recorder_id','result','notes', 'type', 'result_recorded_at']);

      return sowSterilizationService.updateRecord(ctrl.record_data.id, new_data)
      .then(function(record_response){
        ctrl.saving_record = false;
        $rootScope.$broadcast('stzn-test-action', record_response);
        return $mdDialog.hide(true);
      })
      .catch(function(error){
        ctrl.saving_record = false;
        var t_message = $filter('translate')('ERRORS.RECORD_UPDATE');
        errorService.uiErrorHandler(t_message);
      });
    }

    function getDurationLabel () {
      return stznHelperService.getDurationLabel(ctrl.record_data);
    }

    function getIncubationTimeLabel () {
      return stznHelperService.getTimeLabel(ctrl.record_data.tested_at);
    }

  }

}());

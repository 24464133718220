{
  'use strict';
  angular.module('sowWorkflows', [])
  .config(workflowsConfig);
  
  function workflowsConfig ($stateProvider, $urlRouterProvider) {
    const defaultUrl = '/app/workflows/referrals';
    $urlRouterProvider.when('/app/workflows', defaultUrl);

    //
    // Patient Workflows top-level state
    //
    $stateProvider
    .state("app.workflows", {
      url: '/workflows?accessToken&region',
      templateUrl: "sow-workflows/views/workflows-section.html",
      controller: "WorkflowsSectionController",
      controllerAs: "wsCtrl",
      params: {
        accessToken: null,
        region: null,
      },
      data: {
        'hideSidenav': true,
      }
    })
    .state("app.workflows.redirect", {
      url: '/LinkRedirect?actionType&link&email&drpInviteToken&officeId&referralId',
      template: '<pwf-redirect-to params={{$stateParams}}></pwf-redirect-to>',
      params: {
        actionType: null, 
        link: null,
        email: null,
        drpInviteToken: null,
        officeId: null,
        referralId: null,
      },
      data: {
        title: 'Patients Redirect',
        action_bar_directive: undefined,
        require_log_in : false,
      }
    })
    .state("app.workflows.redirect-error", {
      url: '/link-redirect-error?errorCode',
      template: '<redirect-error-page params={{$stateParams}}></redirect-error-page>',
      params: {
        errorCode: null,
      },
      data: {
        title: 'Redirect Error',
        action_bar_directive: undefined,
        require_log_in : false,
      }
    })
    .state('app.workflows.patients-list', {
      url: '/patients',
      template: '<patients-list-app params={{$stateParams}}></patients-list-app>',
      data: {
        title: 'Patients List',
        action_bar_directive: undefined,
        require_log_in : true,
      }
    })
    .state('app.workflows.patient-detail', {
      url: '/patient/:patientId?openChat&view',
      template: '<patient-details-app params="{{$stateParams}}"></patient-details-app>',
      params: {
        patientId: null,
        openChat: null,
        view: null,
      },
      data: {
        title: 'Patient Details',
        action_bar_directive: undefined,
        require_log_in : false,
      }
    })
    .state('app.workflows.treatment-detail', {
      url: '/treatment/:carePlanId',
      template: '<care-plan-details-app params="{{$stateParams}}"></care-plan-details-app>',
      params: {
        carePlanId: null,
      },
      data: {
        title: 'Treatment Details',
        action_bar_directive: undefined,
        require_log_in : false,
      }
    })
    .state('app.workflows.message-center', {
      url: '/message-center',
      template: '<message-center-app params="{{$stateParams}}"></message-center-app>',
      data: {
        title: 'Message Center',
        action_bar_directive: undefined,
        require_log_in : false,
      }
    })
    .state('app.workflows.letters', {
      url: '/letters',
      template: '<letters-page params="{{$stateParams}}"></letters-page>',
      data: {
        title: 'Letters',
        action_bar_directive: undefined,
        require_log_in : false,
      }
    })
    .state('app.workflows.download-attachments', {
      url: '/letters/attachments?practiceId&patientLetterId&email',
      template: '<download-attachments-page params="{{$stateParams}}"></download-attachments-page>',
      params: {
        practiceId: null,
        patientLetterId: null,
        email: null,
      },
      data: {
        title: 'Download Attachments',
        action_bar_directive: undefined,
        require_log_in : false,
      }
    })
    .state('app.workflows.create-letter', {
      url: '/letters/create/:patientId?letterType',
      template: '<create-letter-page params="{{$stateParams}}"></create-letter-page>',
      params: {
        patientId: null,
        letterType: null,
      },
      data: {
        title: 'Create Letter',
        action_bar_directive: undefined,
        require_log_in : false,
      }
    })
    .state('app.workflows.referrals', {
      url: '/referrals',
      template: '<referrals-list-page params="{{$stateParams}}"></referrals-list-page>',
      data: {
        title: 'Referrals',
        action_bar_directive: undefined,
        require_log_in : false,
      }
    })
    .state('app.workflows.create-referral', {
      url: '/referrals/create',
      template: '<create-referral-page params="{{$stateParams}}"></create-referral-page>',
      data: {
        title: 'Create Referral',
        action_bar_directive: undefined,
        require_log_in : false,
      }
    })
    .state('app.workflows.link-patient', {
      url: '/referral/:referralId/link-patient',
      template: '<link-patient-page params="{{$stateParams}}"></link-patient-page>',
      data: {
        title: 'Link Patient',
        action_bar_directive: undefined,
        referralId: null,
      }
    })
    .state('app.workflows.referral-details', {
      url: '/referral/:referralId?openChat&showConfirmation',
      template: '<referral-details-page params="{{$stateParams}}"></referral-details-page>',
      params: {
        referralId: null,
        openChat: null,
        showConfirmation: null,
        printableVersionUploadToken: null,
      },
      data: {
        title: 'Referral Details',
        action_bar_directive: undefined,
        require_log_in : false,
      }
    })
    .state('app.workflows.add-practice', {
      url: '/add-practice',
      template: '<add-practice-page params="{{$stateParams}}"></add-practice-page>',
      data: {
        title: 'Add Practice',
        action_bar_directive: undefined,
      }
    })
    .state('app.workflows.referral-network', {
      url: '/referral-network',
      template: '<practice-list-page params="{{$stateParams}}"></practice-list-page>',
      data: {
        title: 'Referral Network',
        action_bar_directive: undefined,
      }
    })
    .state('app.workflows.practice-details', {
      url: '/practice/:referralNetworkLocationId',
      template: '<practice-details-page params="{{$stateParams}}"></practice-details-page>',
      params: {
        referralNetworkLocationId: null,
      },
      data: {
        title: 'Practice Details',
        action_bar_directive: undefined,
        require_log_in : false,
      }
    })
    .state('app.workflows.analytics', {
      url: '/analytics',
      template: '<analytics-page params="{{$stateParams}}"></analytics-page>',
      data: {
        title: 'Analytics Page',
        action_bar_directive: undefined,
      }
    });
  }
  
}

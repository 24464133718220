angular.module('app.marketplace.system.analytics')
//
// Analytics Service
//
.service('AnalyticsService', function ($document, $rootScope, $timeout, $log,
  $q, $state, $analytics, appEvents) {

  // List of white listed api calls and optionally a handling function or custom event name.
  var _whiteListedApiCalls = {
      "/users/reset_password" : true
  };
  // Allows easy parsing of urls.
  var urlparser = document.createElement('a');

  //Track white-listed api calls.
  var _trackAPICalls = function(){
    $rootScope.$on('serverAPI: doAPICall', function(scope, args){
      urlparser.href = args.url;
      if(_whiteListedApiCalls[urlparser.pathname] === true){
        $analytics.eventTrack('serverAPI: APICall', {'pathname' : urlparser.pathname, 'url' : args.url, 'params': args.params});
      }else if(typeof _whiteListedApiCalls[urlparser.pathname] === 'string' || _whiteListedApiCalls[urlparser.pathname] instanceof String){
        $analytics.eventTrack(_whiteListedApiCalls[urlparser.pathname], {'pathname' : urlparser.pathname, 'url' : args.url, 'params': args.params});
      }
    });
  };

  //Track errors
  var errorWhiteList = [
      JSON.stringify({
        status : 401,
        message : "Unauthorized"
      })
  ];
  var warningWhiteList = [
      "Markup '<md-button flex></md-button>' may not work as expected in IE Browsers. Consult 'https://github.com/philipwalton/flexbugs#9-some-html-elements-cant-be-flex-containers' for details."
  ];
  var _trackErrors = function(){
    //Depreciated in favor of watching errors on the log.
//    $rootScope.$on('errorService: errorHandled', function(scope, args){
//      var errorMessage = angular.isObject(args) ? JSON.stringify(args) : args;
//      $analytics.eventTrack('errorService: errorHandled', {'message' : errorMessage, 'type' : 'errorService'});
//    });
    $rootScope.$on('$log: errorTriggered', function(scope, args){
      var errorMessage = "";
      _.map(args.arguments, function(currError){
        //Create track error message depending on error type.
        var message, type, stack;
        if(angular.isObject(currError) && currError.message && currError.stack){
          message = currError.message;
          stack = currError.stack;
          type = 'stack';
        }else if(angular.isObject(currError)){
          message = JSON.stringify(currError);
          type = 'object';
        }else {
          message = currError;
          type = 'string';
        }

        if(errorWhiteList.indexOf(message) === -1){
          $analytics.eventTrack('errorService: errorHandled', {'message' : message , 'type' : type, 'stack' : stack});
        }
      });
    });
    $rootScope.$on('$log: warningTriggered', function(scope, args){
      var warningMessage = "";
      _.map(args.arguments, function(currWarning){
        //Create track warning message depending on warning type.
        var message, type, stack;
        if(angular.isObject(currWarning) && currWarning.message && currWarning.stack){
          message = currWarning.message;
          stack = currWarning.stack;
          type = 'stack';
        }else if(angular.isObject(currWarning)){
          message = JSON.stringify(currWarning);
          type = 'object';
        }else {
          message = currWarning;
          type = 'string';
        }

        if(warningWhiteList.indexOf(message) === -1){
          $analytics.eventTrack('errorService: warningHandled', {'message' : message , 'type' : type, 'stack' : stack});
        }
      });
    });
  };

  // Track clicks to named elements.
  var _trackClicks = function(){
    $document.on('mouseup', clickHandler);
  };

  function clickHandler (ev) {
    var ele = $(ev.target);

    // Ignore forms
    if (ele.is('form')){
      return;
    }

    // If ele does not have a name but has a closest ele with a name (but not a form) use it instead.
    if (!ele.attr('name') && ele.closest('[name]:not(form)').length) {
      ele = ele.closest('[name]:not(form)');
    }else if(!ele.attr('name')){
      //Else if no name then ignore
      return;
    }

    $analytics.eventTrack('click', {'name' : ele.attr('name')});
  }

  //Track state changes and pages
  var _trackStates = function(){
    var last = null;
    $rootScope.$on('$stateChangeSuccess', function(ev, to, toParams, fromState, fromParams) {
//      $analytics.eventTrack('$stateChangeSuccess', {'to': to.name, 'from': (fromState || {}).name});
      $timeout(function(){
        if(last !== window.location.hash){
          $analytics.pageTrack(window.location.hash);
          last = window.location.hash;
        }
      });
    });
  };
  //Track various model service's broadcasts
  var _trackModelServices = function(){
    var previousEmail;

    $rootScope.$on(appEvents.setAccount, function (event, account) {
      // previously handled on w
    });

    $rootScope.$on(appEvents.clearAccount, function (event) {
      // $analytics.setAlias('');
      // $analytics.setUsername('');
      
    });

    $rootScope.$on('registrationService: accountCreated', function(scope, account){
      $analytics.setUsername(account.email);
      $analytics.eventTrack('accountCreated', {
        'email': account.email,
        'name': account.name,
      });
    });
    $rootScope.$on('cartService: itemAddedToCart', function(scope, data){
      $analytics.eventTrack('itemAddedToCart', {'product' : _.get(data, 'product.name',''), 'quantity' : data.quantity});
    });
    $rootScope.$on('creditCards: creditCardAdded', function(scope, new_card){
      $analytics.eventTrack('creditCardAdded');
    });
    $rootScope.$on('cartService: checkoutCompleted', function(scope, cart){
      $analytics.eventTrack('payment', {'cart_count': cart.cart_count, 'amount': cart.total});
    });
    $rootScope.$on('searchService: did-search', function(scope, search){
      if(!search.isAutocomplete){ //Ignore autocomplete searches.
        $analytics.eventTrack('searchedMarketplace', {'query': search.query, 'products': search.response.products.length,  'product_templates': search.response.user_products.length,  'inventory_items': search.response.inventory_items.length });
      }
    });
  };

  var _init = function(){
    
    return null;

    _trackAPICalls();
    _trackErrors();
    _trackClicks();
    _trackStates();
    _trackModelServices();
  };

  return {
    init : _init,
    'clickHandler': clickHandler,
  };
});

(function () {
  'use strict';
  angular.module('sowMarketplace')
  .controller('MarketplaceSearchController', MarketplaceSearchController);

  function MarketplaceSearchController($scope, $rootScope, $filter, $stateParams, $anchorScroll, $location, $window, $state, errorService, appEvents, sowMktService, mktHelperService, sowAlgoliaService, adButlerService, sowAnalyticsService, AccessService) {
    /*jshint validthis: true */
    var ctrl = this;

    ctrl.query = null;
    ctrl.tag = null;
    ctrl.cat_id = null;
    ctrl.sub_id = null;
    ctrl.category = null;
    ctrl.subcategory = null;
    ctrl.loading = {
      'categories': false,
      'products': false,
    };
    ctrl.logged_page_views = new Set();
    ctrl.ADS_BREAKOUT_LIMIT = 12;
    ctrl.PRODUCTS_PER_PAGE = 30;
    ctrl.quick_filter_tags = [];

    ctrl.UI = {
      'see_more': false,
    };
    ctrl.categories = {
      'first_row': null,
      'more': null,
      'display': null,
      'see_more': false,
    };
    ctrl.subcategories = {
      'first_row': null,
      'more': null,
      'display': null,
      'see_more': false,
    };
    ctrl.products = {
      'current_page': 0,
      'display': null,
      'display_page_names': null,
      'last_page': null,
      'page_names': null,
      'pages': null,
    };

    ctrl.sections = {
      'breadcrumbs': false,
      'title': false,
      'cat_list': true,
      'sub_list': false,
      'products_list': true,
      'no_results': false,
      'dynamic_ads': false
    };

    ctrl.ad_layouts = {
      'header':null,
      'middle':null,
      'footer':null
    };

    ctrl.toggleSeeMore = toggleSeeMore;
    ctrl.goToCategory = goToCategory;
    ctrl.goToSubcategory = goToSubcategory;
    ctrl.goToManufacturer = goToManufacturer;
    ctrl.initAlgolia = initAlgolia;
    ctrl.getCurrentProductsLabel = getCurrentProductsLabel;
    ctrl.loadMiddleAds = loadMiddleAds;
    ctrl.setProductVendorInfo = mktHelperService.setProductVendorInfo;
    ctrl.open = open;
    ctrl.localize = mktHelperService.getLocalizedProp;
    ctrl.toggleOnSaleFilter = toggleOnSaleFilter;
    ctrl.toggleTagFilter = toggleTagFilter;
    ctrl.clearTagFilter = clearTagFilter;
    ctrl.handleEvent = handleEvent;

    $scope.$on('dynamic-ads-loaded', function(event, data) {
      ctrl.sections.dynamic_ads = data;
    })

    ctrl.$onInit = init;

    return ctrl;

    function init () {
      $scope.$on('mkt-load-product-page', loadProductPage);
      $scope.$on('$destroy', function(){
        $rootScope.algolia_data = null;
      });
      $scope.$on('algolia_data', handleAlgoliaData);
      $scope.$on('algolia_loading', function(event, val){
        ctrl.loading.products = val;
        if(val){
          ctrl.products.display = null;
          ctrl.display_products = null;
        }
      });
      $scope.$on(appEvents.mktProductUpdate, updateLocalProduct);
      $scope.$on('filters-ready', function () {
        initAlgolia();
      })

      initParams();
      defineVisibleAreas();
      ctrl.loading.products = true;
      _defineLocks();      
    }

    function toggleTagFilter (value) {
      // Remove the tag from the URL if it's already there
      if ($state.params.tag === value) {
        ctrl.clearTagFilter()
      } else {
        $state.transitionTo($state.current, {...$state.params, tag: value, page: 1});
      }
    }

    function clearTagFilter () {
      $state.transitionTo($state.current, _.omit($state.params, 'tag'));
    }

    function handleEvent({detail: {clickedBtn = ''}}) {
      if (clickedBtn === 'filters_btn') open();
      else if (clickedBtn === 'on_sale_btn') toggleOnSaleFilter();
      else if (clickedBtn.startsWith('tag:')) toggleTagFilter(clickedBtn.replace('tag:', ''));
      else if (clickedBtn.startsWith('facet_filter:')) removeFacetFilter({value: clickedBtn.replace('facet_filter:', '')});
      else if (clickedBtn === 'clear_filters_btn') clearFacetFilters();
    }

    /** 
     * Removes a facet filter from the URL
     * @param {Object} filter - The filter to remove
     * @param {String} filter.value
     * @param {String} filter.label
     * 
     * @return {void}
     * */
    function removeFacetFilter (filter) {
      const new_filters = _.filter(ctrl.active_facet_filters, ({value}) => value !== filter.value);

      $state.transitionTo($state.current, {
        ...$state.params,
        facet_filters: new_filters.map(({value}) => value).join('--'),
      });
    }

    function clearFacetFilters() {
      $state.transitionTo($state.current, _.omit($state.params, 'facet_filters'));
    }

    function _getActiveFacetFilters () {
      // Facet filters are stored in the URL as a string of key-value pairs separated by '--'
      // subcategory.name_locale.en:Cotton Tips--vendor.name_locale.en:Benco
      const active_facet_filter = $state.params.facet_filters ?? '';

      // Split the string into an array: ['subcategory.name_locale.en:Cotton Tips', 'vendor.name_locale.en:Benco']
      const filters = active_facet_filter.split('--');

      return _.map(filters, value => {
        // Split each filter into an object: {label: 'Cotton Tips', value: 'subcategory.name_locale.en:Cotton Tips'}
        const [, label] = value.split(':');

        return {
          label,
          value,
        };
      });
    }

    /**
     * Initializes the leaderboards and dynamic ads on the page
     */
    function _loadInitialAds () {
      _loadTopAd();
      _loadBottomAd();
      adButlerService.setDynamicAdAsInnerHTML('branded-product-dynamic');
    }

    /**
     * Loads an adButler ad, automatically appending '-scaled'
     * to the zone name if the viewport is narrow
     * @param {string} zone 
     */
    function _loadScaledAd(zone) {
      adButlerService.autoLoadZone(zone + ($window.innerWidth < 960 ? '-scaled' : ''));
    }

    /**
     * Loads the leaderboard ad at the top of the page
     */
    function _loadTopAd() {
      _loadScaledAd('leaderboard1');
    }

    /**
     * Loads the leaderboard ad at the bottom of the page
     */
    function _loadBottomAd() {
      _loadScaledAd('leaderboard2');
    }

    function loadMiddleAds () {
      adButlerService.autoLoadZone('box1');
      adButlerService.autoLoadZone('box2');
      adButlerService.autoLoadZone('box3');
    }

    function initParams () {
      angular.extend(ctrl, _.pick($stateParams, ['query', 'tag', 'cat_id','sub_id','category','subcategory', 'mf_id', 'manufacturer', 'vendor_id', 'vendor_name', 'mf_name']));
    }

    function defineVisibleAreas () {
      ctrl.sections.breadcrumbs = (ctrl.cat_id || ctrl.sub_id || ctrl.mf_name || ctrl.vendor_name);
      // ctrl.sections.title = (!!ctrl.query || !!ctrl.mf_id);
      ctrl.sections.title = true;
      ctrl.sections.cat_list = (!ctrl.cat_id);
      ctrl.sections.sub_list = (ctrl.cat_id && !ctrl.sub_id);
    }

    function scrollTo (id) {
      var newHash = id;
      if ($location.hash() !== newHash) {
        $location.hash(id);
      } else {
        $anchorScroll();
      }
    }

    function scrollToTop () {
      return scrollTo("#appContainer");
    }

    function getPageTitle () {
      var title = '';
      var base_title = '';
      if (ctrl.query || ctrl.tag) {
        base_title = $filter('translate')('MARKETPLACE.SEARCH.CURRENT', {'x': ctrl.query || ctrl.tag});
        title = angular.copy(base_title);

        // If the tag is in the quick filter tags, don't show it in the title
        if (ctrl.tag && ctrl.quick_filter_tags.map(({tag_value}) => tag_value).includes(ctrl.tag)) {
          title = ''
        }

        if (!!ctrl.mf_id && ctrl.manufacturer) {
          title = base_title + $filter('translate')('MARKETPLACE.SEARCH.BY', {'x': _.get(ctrl, 'manufacturer.name')});
        }
        if (!!ctrl.cat_id) {
          title = base_title + $filter('translate')('MARKETPLACE.SEARCH.UNDER', {'x': _.get(ctrl, 'category.name')});
        }
        if (!!ctrl.sub_id) {
          title = base_title + $filter('translate')('MARKETPLACE.SEARCH.UNDER', {'x': _.get(ctrl, 'subcategory.name')});
        }
      } else {
        if (!!ctrl.mf_id) {
          title = mktHelperService.getLocalizedProp(ctrl.manufacturer, 'name');
        }
        if (!!ctrl.cat_id) {
          title = mktHelperService.getLocalizedProp(ctrl.category, 'name');
        }
        if (!!ctrl.sub_id) {
          title = mktHelperService.getLocalizedProp(ctrl.subcategory, 'name');
        }
        if (ctrl.vendor_name) {
          title = _.get(ctrl, 'vendor_name');
        }
        if (ctrl.mf_name) {
          title = _.get(ctrl, 'mf_name');
        }
      }

      return title;
    }

    /** 
     * Returns `Showing {first}-{last} of {total_products} products`, factoring in pagination, filters, and results per page.
     * For example, "Showing 1-30 of 150 products".
     * 
     * @param {Object} search_data Algolia search response
     * @param {Array} display_products Products currently displayed in UI (after filters applied)
     * 
     * @return {String} 
    */
    function getCurrentProductsLabel () {
      var first = 1;
      var last = 0;
      var label = "";
      var on_display = _.size(ctrl.display_products);

      if ( _.get(ctrl, 'search_data.current_page', 0) > 0 ) {
        first = (ctrl.search_data.current_page * ctrl.PRODUCTS_PER_PAGE) + 1;
      }

      last = first + (on_display - 1);

      if ( on_display ) {
        label = $filter('translate')('MARKETPLACE.SEARCH.SHOWING', {
          'a': first, 
          'b': last, 
          'c': ctrl.search_data.total_products
        });
      }
      return label;
    }

    // deprecated
    function loadData () {
      ctrl.loading.products = true;
      var params = {
        'query': ctrl.query,
        'category_id': ctrl.cat_id,
        'subcategory_id': ctrl.sub_id,
        'manufacturer_id': ctrl.mf_id,
      };
      return sowMktService.searchQuery(params)
      .then(function(search_response){
        ctrl.search_data = search_response;
        parseCategoryList(search_response.parent_categories, ctrl.categories);
        ctrl.loading.categories = false;

        var category_browsing = (ctrl.cat_id && !ctrl.mf_id && !ctrl.query && !ctrl.sub_id);
        var subs_source = category_browsing ? _.get(ctrl, 'category.subcategories', null) : _.get(search_response, 'subcategories', null);

        if(!_.size(subs_source)){
          loadCategoriesSeparately();
        } else {
          parseCategoryList(subs_source, ctrl.subcategories);

        }
        if(!_.size(search_response.results[0]['hits'])){
          setEmptyState();
          return;
        }
        parseProductPages(search_response.results[0]['hits'], ctrl.products, search_response.total_products);
        ctrl.loading.products = false;
        catSubcatFind();
      })
      .catch(function(error){
        errorService.uiErrorHandler(error);
      })
      .finally(function(){
        
      });

    }

    function updateLocalProduct ($event, new_item) {
      var item = _.find(ctrl.display_products, ['id', new_item.id]);
      _.extend(item, _.pick(new_item, ['is_favourite_product']));
    }

    function setEmptyState () {
      ctrl.search_data = {};
      defineVisibleAreas();
      ctrl.sections.products_list = false;
      ctrl.sections.cat_list = false;
      ctrl.sections.sub_list = false;
      ctrl.sections.no_results = true;
      ctrl.active_facet_filters = _getActiveFacetFilters();
      // the empty state includes the banner ad at the top of the page
      _loadTopAd();
    }

    function loadCategoriesSeparately () {
      return sowMktService.getCategories()
      .then(function(cat_response){
        parseCategoryList(cat_response.categories, ctrl.categories);
        if(ctrl.cat_id && (!ctrl.category || !ctrl.category.subcategories)){
          var current_category = _.find(cat_response.categories, ['id', ctrl.cat_id]) || {};
          if(!current_category){
            return;
          }
          parseCategoryList(_.get(current_category, 'subcategories', null), ctrl.subcategories);
        }
        catSubcatFind();
      });
    }

    function setAdvertisements (list) {
      ctrl.ads = list;
      _.each(ctrl.ads, function(ad_layout){
        ctrl.ad_layouts[ad_layout.position] = ad_layout;
      });
    }

    function parseCategoryList (list, dest) {
      list = _.orderBy(list, ['name'], ['asc']);
      dest.first_row = _.slice(list, 0, 4);
      dest.more = _.slice(list, 4);
      dest.display = angular.copy(dest.first_row);
    }

    function toggleSeeMore (list) {
      if (!list.see_more) {
        list.display = _.concat(angular.copy(list.first_row), angular.copy(list.more));
        list.see_more = true;
      } else {
        list.display = angular.copy(list.first_row);
        list.see_more = false;
      }
    }

    function parseProductPages (list, dest, total_products) {
      list = _.map(list, ctrl.setProductVendorInfo);
      var page_count = Math.ceil(total_products / 30);
      // dest.pages = _.fill([], [], 0, page_count);
      dest.pages = new Array(page_count);
      dest.pages[0] = list;
      dest.last_page = (dest.pages.length-1);
      dest.display = angular.copy(dest.pages[0]);

      dest.page_names = [];
      for (var i = 0; i < dest.pages.length; i++ ) {
        var number = {'name':i+1, 'position':i, 'active': false};
        if(i === 0){
          number.active = true;
        }
        dest.page_names.push(number);
      }
    }

    function parseProductsList (list, dest, total_products) {
      list = _.map(list, ctrl.setProductVendorInfo);
    }

    // deprecated
    function loadProductPage ($event, index) {
      var params = {
        'query': ctrl.query,
        'category_id': ctrl.cat_id,
        'subcategory_id': ctrl.sub_id,
        'manufacturer_id': ctrl.mf_id,
        'page': index+1,
      };
      if (ctrl.products.pages[index]) {
        return;
      }
      return sowMktService.searchQuery(params)
      .then(function(search_response){
        ctrl.products.pages[index] = _.map(search_response.products, ctrl.setProductVendorInfo);
        ctrl.products.display = angular.copy(ctrl.products.pages[index]);
      })
      .catch(function(error){
        errorService.uiErrorHandler(error);
      });
    }

    function find (sources, id) {
      var this_item = null;
      _.forEach(sources, function(source_item){
        if(this_item) {
          return;
        }
        _.forEach(source_item, function(item){
          if(item.id === id) {
            this_item = angular.copy(item);
            return;
          }
        });
      });
      return this_item;
    }

    function catSubcatFind () {
      if (ctrl.cat_id && !ctrl.category) {
        ctrl.category = find([ctrl.categories.first_row, ctrl.categories.more], ctrl.cat_id);
      }

      if (ctrl.sub_id && !ctrl.subcategory) {
        ctrl.subcategory = find([ctrl.subcategories.first_row, ctrl.subcategories.more], ctrl.sub_id);
      }
    }

    function goToCategory (category) {
      var params = {
        'query': ctrl.query, 
        'cat_id': category.id, 
        'category': category
      };
      return mktHelperService.goToCategory(params);
    }

    function goToSubcategory (subcategory) {
      var params = {
        'query': ctrl.query, 
        'sub_id': subcategory.id, 
        'subcategory': subcategory, 
        'cat_id': ctrl.cat_id, 
        'category': ctrl.category
      };
      return mktHelperService.goToSubcategory(params);
    }

    function goToManufacturer (manufacturer) {
      var params = {
        'manufacturer_id': manufacturer.id,
        'mf_id': manufacturer.id,
        'mf_name': manufacturer.name,
        'manufacturer': manufacturer
      };
      return mktHelperService.goToManufacturer(params);
    }

    function initAlgolia () {
      var category_browsing = (ctrl.cat_id && !ctrl.mf_id && !ctrl.query && !ctrl.tag && !ctrl.sub_id);
      if(category_browsing){
        if(!ctrl.category){
          loadCategoriesSeparately()
          .then(function(){
            addFilters( _.get(ctrl, 'category.filters', null) );
          });
        } else {
          addFilters( _.get(ctrl, 'category.filters', null) );
        }
      } else {
        addFilters(null);
      }

      function addFilters (filters_array) {
        var options = {};
        if(filters_array){
          var facets = _.map(filters_array, function(filter) {
            return 'filters.'+filter;
          });
          _.set(options, 'facets', facets);
        }
        var ais = sowAlgoliaService.initAisInstance(options);

        // Validate that the pagination widget is present so it can search using the correct page number
        if (document.querySelector('#currentRefinements')) {
          ais.start();
        }
      }
      // ais.addWidgets(widgets);
    }

    function handleAlgoliaData (event, search_response) {
      if(!search_response){
        return;
      }
      var hits = _.get(search_response, 'results[0].hits', []);
      adButlerService.setABKW(hits);

      var total = _.get(search_response, 'results[0].nbHits', 0);
      ctrl.search_data = search_response;
      
      parseCategoryList(search_response.parent_categories, ctrl.categories);
      ctrl.loading.categories = false;

      var category_browsing = (ctrl.cat_id && !ctrl.mf_id && !ctrl.query && !ctrl.sub_id);
      var subs_source = category_browsing ? _.get(ctrl, 'category.subcategories', null) : _.get(search_response, 'subcategories', null);

      scrollToTop();
      if(!_.size(subs_source)){
        loadCategoriesSeparately();
      } else {
        parseCategoryList(subs_source, ctrl.subcategories);
      }
      if(_.size(hits) < 1){
        setEmptyState();
        return;
      }

      // parseProductPages(hits, ctrl.products, total);
      ctrl.display_products = _.map(hits, ctrl.setProductVendorInfo);
      ctrl.page_title = getPageTitle();
      ctrl.loading.products = false;
      catSubcatFind();
      // setAdvertisements(search_response.advertising_banners);

      _loadInitialAds();
      _logPageView();
    }

    /**
     * Logs a page view event to Google Analytics
     */
    function _logPageView() {
      const {
        current_page,
        results: [{hits}],
      } = ctrl.search_data;
      /* exit early if we've already logged this page of results */
      if (ctrl.logged_page_views.has(current_page)) {
        return;
      }
      ctrl.logged_page_views.add(current_page);
      const products_per_page = ctrl.PRODUCTS_PER_PAGE;
      const products = _.map(hits, (product, i) => ({
        ...product,
        categories: [product.parent_category, product.subcategory],
        index: current_page * products_per_page + 1 + i,
        /* add info of the lowest priced available vendor */
        ..._.first(product.sorted_vendors),
      }));
      const params = {
        item_list_id: _getItemListId(),
        item_list_name: ctrl.query || ctrl.tag || ctrl.page_title,
        /* page is 0-based in the data but 1-based in the event */
        page_num: current_page + 1,
        products_per_page,
      };
      sowAnalyticsService.logViewItemList(products, params);
    }

    /**
     * Determines which ID should be sent in the Google analytics
     * event which registers that the current page was viewed
     * @return {string}
     */
    function _getItemListId() {
      if (ctrl.mf_id) {
        return 'manufacturer'
      } else if (ctrl.vendor_id) {
        return 'vendor';
      } else if (ctrl.sub_id) {
        return 'subcategory';
      } else if (ctrl.cat_id) {
        return 'category';
      } else if (ctrl.query) {
        return 'query';
      } else {
        return 'tag';
      }
    }

    function open() {
      $rootScope.$broadcast('algolia-filters-open');
    }

    /**
     * Removes the on_sale filter from the URL if it's already there,
     * or adds it if it's not. Also resets the page to 1.
     */
    function toggleOnSaleFilter() {
      const {on_sale, ...params} = $state.params;
      params.page = 1;
      if (!on_sale) params.on_sale = true;
      $state.transitionTo($state.current, params);
    }

    function _defineLocks() {
      ctrl.disable_on_sale_filter = AccessService.getProperty('disable_on_sale_filter');
      ctrl.quick_filter_tags = AccessService.getProperty('marketplace.quick_filter_tags', [])
    }

}

}());

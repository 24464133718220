(function () {
  'use strict';

  /** @ngInject */
  class poQuantityModalCtrl {
    show_product = false;
    product = null;
    constructor(item, suggested_quantities, $filter, $mdDialog, inventoryItemService, errorService, sowMktService, AccessService) {
      this.item = item;
      this.suggested_quantities = suggested_quantities;

      this.$filter = $filter;
      this.$mdDialog = $mdDialog;

      this.inventoryItemService = inventoryItemService;
      this.errorService = errorService;
      this.sowMktService = sowMktService;
      this.AccessService = AccessService;
    }

    $onInit() {
      this.setAdditionalInfo();
      this.setMarketplaceInfo();
    }

    /**
     * Sets additional information for a purchase order item, including notes, suggested
     * quantities, on-hand quantity, desired level, and fill quantity label.
     */
    setAdditionalInfo () {
      this.notes = this.item.inventory_item.notes || '-';
      const quantities = this.suggested_quantities[this.item.inventory_item.id] || {};
      const fill_qty = this.item.buy_by_case ? quantities.reorder_quantity_by_pack : quantities.reorder_quantity_by_item;
      this.fill_qty = fill_qty || 1;
      const tracked_by_pack = quantities.tracking_method === 'Package';
      const tracking_text = this.$filter('translate')(tracked_by_pack ? 'COMMON.PACK' : 'COMMON.ITEM');
      const on_hand = tracked_by_pack
        ? Math.floor(quantities.on_hand / quantities.order_package_quantity)
        : quantities.on_hand;
      this.on_hand_text = `${on_hand} ${tracking_text}${on_hand === 1 ? '' : 's'}`;
      const desired_level = quantities.desired_level;
      const suffix = desired_level === 1 ? '' : 's';
      this.desired_level_text = desired_level
        ? `${desired_level} ${tracking_text}${suffix}`
        : '-';
      this.fill_qty_label = this.$filter('translate')('PO.FILL_QTY');
      // If there's a mismatch between the product's tracking method and the way the user is
      // choosing to order it in this PO, append explanatory text to the Fill Quantity label.
      if (!tracked_by_pack && this.item.buy_by_case) {
        this.fill_qty_label += ` (${this.$filter('translate')('COMMON.PACK_OF')} ${quantities.order_package_quantity})`;
      } else if (tracked_by_pack && !this.item.buy_by_case) {
        this.fill_qty_label += ` (${this.$filter('translate')('COMMON.ITEMS')})`;
      }
    }

    /**
     * Sets marketplace information by fetching product information if the product ID
     * exists and marketplace product pull is enabled.
     */
    setMarketplaceInfo () {
      const product_id = this.item?.inventory_item?.product_id;
      const can_pull = this.AccessService.getProperty('purchase_orders.enable_mrkt_product_pull');
      if (product_id && can_pull) {
        this.fetchProductInfo(product_id);
      }
    }

    closeModal() {
      this.$mdDialog.hide();
    }

    handleSubmit(e) {
      e.preventDefault();
      this.item.quantity = this.fill_qty;
      this.closeModal();
    }

    /**
     * Fetches product information from a service and handles errors if the product is
     * not available.
     * @param {string} product_id - The product ID is a unique identifier for a specific product in the marketplace.
     * @return {Promise<MarketplaceProduct>} - A promise that resolves to a MarketplaceProduct object.
     */
    fetchProductInfo (product_id) {
      if (!product_id) return Promise.reject();
      const t_error_message = this.$filter('translate')('ERRORS.PRODUCT_NOT_AVAILABLE');

      this.show_product = true;
      return this.sowMktService.fetchProduct(product_id)
      .then((mkt_response) => {
        if (!mkt_response || _.isEmpty(mkt_response?.products)) {
          this.errorService.uiErrorHandler(t_error_message);
          this.show_product = false;
          return mkt_response;
        } else {
          // emptying description to reduce html component size
          this.product = {..._.first(mkt_response.products), description: ''};
        }
      })
      .catch((api_error) => {
        this.errorService.uiErrorHandler(api_error || t_error_message);
        this.show_product = false;
      });
    }

  }

  angular.module('app.marketplace.ui.purchaseorders')
    .controller('poQuantityModalCtrl', poQuantityModalCtrl);

})();

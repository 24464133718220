angular.module("app.shared.support")

  //
  // Internal "ng-currency" implementation.
  //
  // There is a 3rd party ng-currency, but I essentially put my own
  // implementation together, and used their implementation to inform me on how
  // to keep the field up to date (since the $formatters don't auto-run on new
  // input, and I was having issues finding information on how to trigger
  // $formatters and $render). (This 'informing' is basically the
  // `reformatViewValue` and `blur` event handler).
  //
  .directive('ngCurrency', function ($filter, $log) {
    return {
      require: 'ngModel',
      link: function ($scope, $element, $attrs, ngModel) {
        //
        // Re-render the view value.
        //
        // This is needed because aside from the initial render/formatting,
        // changes to the model won't be reflected in the <input> display
        // value.
        var reformatViewValue = function () {
          var formatters = ngModel.$formatters;
          var viewValue = ngModel.$$rawModelValue;

          for (var idx = formatters.length - 1; idx >= 0; --idx) {
            viewValue = formatters[idx](viewValue);
          }

          ngModel.$setViewValue(viewValue);
          ngModel.$render();
        };

        //
        // Return true/false based on whether or not the model value is valid.
        //
        var _validateCurrency = function (modelValue) {
          return !Number.isNaN(_parseCurrency(modelValue));
        };

        //
        // Format the user input before it hits the model. Here we remove the
        // dollar sign, and any preceeding and trailing whitespace.
        //
        var _parseCurrency = function (inputValue) {
          return $filter('currency-to-number')(inputValue);
        };

        //
        // Format the number as a currency
        //
        var _formatCurrency = function (modelValue) {
          return $filter('currency')(modelValue);
        };

        //
        // Initialize all of the ngModel pipelines.
        //
        ngModel.$validators.currency = _validateCurrency;
        ngModel.$parsers.unshift(_parseCurrency);
        ngModel.$formatters.unshift(_formatCurrency);

        //
        // Reformat when the user leave the field.
        //
        $element.on('blur', function () {
          ngModel.$commitViewValue();
          reformatViewValue();
        });
      }
    };
  });
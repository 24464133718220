(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.subscriptions')
    .directive('premiumPlusFeatures', premiumPlusFeaturesDirective);

  function premiumPlusFeaturesDirective ($log) {
    return {
      'restrict': 'E',
      'replace': true,
      'templateUrl': 'templates/marketplace/subscriptions/features-list.html',
      'controller': 'PlanFeaturesCtrl',
      'scope': {
        'isLarge': '@',
        'associationId': '=',
        'isCollapsed': '=',
      },
      'link': function ($scope, $element, $attrs, $ctrl) {
        var features = getFeatures($scope.associationId);

        $ctrl.init({
          'defaultClasses': 'premium-plus-plan',
          'heading': 'Premium Plus includes',
          'subtitle': 'everything in Premium, plus:',
          'features': features,
        });

        function getFeatures(associationId) {
          if (associationId === 'aaoms') {
            return [
              'Implant inventory management',
              'Reserve implants for upcoming patients & procedures.',
              'Medication inventory & expiration management.',
              'Equipment certification monitoring.',
              'License & Permit renewal reminders.',
              'Full office financial reports.',
              'Daily Production email.'
            ];
          }

          return [
            'Front office analytics',
            'Productivity reports',
            'Full office financial reports',
            '*Daily Production Email (Coming Soon!)',
            '*Implant Inventory Management (Coming Soon!)',
          ];
        }
      }
    };
  }
}());

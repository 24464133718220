(function () {
  'use strict';

  angular.module('sowSterilization')
    .directive('sowStznTestFormChem', stznTestFormChemicalDirective);

  function stznTestFormChemicalDirective() {
    return {
      restrict: 'E',
      templateUrl: 'sow-sterilization/directives/stzn-chem-test-form.html'
    };
  }

})();
(function () {
  'use strict';

  angular
    .module("app.marketplace.elements")
    .config(registerSessionEvents);

  //
  // Register Session Events with appEventsProvider
  //
  function registerSessionEvents(appEventsProvider) {
    appEventsProvider.registerEvent('newSession', 'sessionService: new-session');
    appEventsProvider.registerEvent('setSession', 'sessionService: set-session');
    appEventsProvider.registerEvent('clearSession', 'sessionService: clear-session');
    appEventsProvider.registerEvent('loggedOutSession', 'sessionService: logged-out-session');
  }

}());

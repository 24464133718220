(function () {
    'use strict';
    angular.module('sowSds', [])
    .config(sowSdsConfig)

    function sowSdsConfig ($stateProvider) {
      
      $stateProvider.state('app.sdslist', {
        url: '/sds',
        controller: 'SdsListController',
        controllerAs: 'sdslCtrl',
        templateUrl: 'sow-sds/sds-list.html',
        data: {
          'title': 'SDS Documents',
          'require_log_in' : true,
          'action_bar_directive': 'sds-action-bar',
          // 'requires_feature': 'dashboard',
        },
      });
    }

}());

(function () {
  'use strict';

  const pdInventoryInfo = {
    selector: 'pdInventoryInfo',
    templateUrl: 'sow-product-details/directives/pd-inventory-info.html',
    controller: 'pdInventoryInfoCtrl',
    controllerAs: 'pdIICtrl',
    bindings: {
      inventoryStatus: '<',
      subsectionsToDisplay: '<',
      desktopOnly: '<',
    },
  }

  /** @ngInject */
  class pdInventoryInfoCtrl {
    constructor($filter) {
      this.$filter = $filter;
    }

    $onChanges() {
      // spread the inventoryStatus object into `this` to avoid having to
      // access nested properties all over the place
      for (const key in this.inventoryStatus) {
        this[key] = this.inventoryStatus[key];
      }
      this._generateSubsections();
    }

    /**
     * Creates the subsections we will display to the user
     */
    _generateSubsections() {
      const subsections = [];

      this.fill_qty = this._getFillQty();
      const is_min_level_set = (parseInt(this.minimum_level) > -1);
      this.is_below_minimum_level = is_min_level_set && (this.on_hand <= this.minimum_level);
      this.is_tracked_by_item = this.tracking_method === 'Item';
      const t_item = this.$filter('translate')('COMMON.ITEM');
      const t_pack = this.$filter('translate')('COMMON.PACK');
      this.tracking_text = this.is_tracked_by_item ? t_item : t_pack;
      this.buying_text = this.buy_by_case ? t_pack : t_item;

      for (const subsection of this.subsectionsToDisplay) {
        subsections.push(this._createSubsectionContent(subsection))
      }
      this.subsections = subsections;
    }

    /**
     * Generates the content for a specific subsection
     * @param subsection - The name of the subsection we're creating content for.
     * @returns An object with the following properties:
     *   title: The title of the subsection
     *   primary_text: The primary text of the subsection
     *   secondary_text: The secondary text of the subsection
     *   text_classes: The text classes of the subsection
     *   tooltip: The tooltip of the subsection (only if active POs exist)
     */
    _createSubsectionContent(subsection) {
      switch (subsection) {
        case 'minimum_level':
          return {
            title: this.$filter('translate')('COMMON.MIN_LVL'),
            primary_text: this._getPrimaryText(this.minimum_level),
            secondary_text: this._getSecondaryText(this.tracking_text, this.minimum_level),
          }
        case 'on_hand':
          return {
            title: this.$filter('translate')('COMMON.ON_HAND'),
            text_classes: this.is_below_minimum_level ? 'orange' : '',
            primary_text: this._getPrimaryText(this.on_hand),
            secondary_text: this._getSecondaryText(this.tracking_text, this.on_hand),
            tooltip: this.active_order_tooltip,
          }
        case 'desired_level':
          return {
            title: this.$filter('translate')('COMMON.OPTIMAL_LVL'),
            primary_text: this._getPrimaryText(this.desired_level),
            secondary_text: this._getSecondaryText(this.tracking_text, this.desired_level),
          }
        case 'fill_qty':
          return {
            title: this.$filter('translate')('PO.FILL_QTY'),
            primary_text: this._getPrimaryText(this.fill_qty),
            secondary_text: this._getSecondaryText(this.buying_text, this.fill_qty),
          }
      }
    }

    /**
     * If quantity is nullish, return a dash. Otherwise, return the value of quantity
     * @param quantity - The quantity of the item.
     * @returns The quantity or a dash.
     */
    _getPrimaryText(quantity) {
      return quantity ?? '-';
    }

    /**
     * If the quantity is 1, return the text. If it's nullish, return undefined.
     * Otherwise, pluralize th text and return it.
     * @param text - The text to be displayed.
     * @param quantity - The number of items in the cart.
     * @returns the text.
     */
    _getSecondaryText(text, quantity) {
      const numeric_qty = parseInt(quantity);
      if (numeric_qty > -1) {
        return numeric_qty === 1 ? text : `${text}s`;
      }
    }

    /**
     * If the inventory status has a fill_qty, return that. Otherwise, if the inventory item is bought
     * by case, return the reorder_quantity_by_pack. Otherwise, return the reorder_quantity_by_item
     * @returns The fill quantity.
     */
    _getFillQty() {
      if (this.fill_qty) {
        return this.fill_qty;
      }
      return this.buy_by_case ? this.reorder_quantity_by_pack : this.reorder_quantity_by_item;
    }

  }

  angular.module('sowOrders')
    .controller(pdInventoryInfo.controller, pdInventoryInfoCtrl)
    .component(pdInventoryInfo.selector, pdInventoryInfo);

})();

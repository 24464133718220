(function () {
  'use strict';
  
  angular
  .module('sowReports')
  .controller('ImplantsPlacementDashboardController', ImplantsPlacementDashboardController);
  
  function ImplantsPlacementDashboardController($state, $filter, sowImplantPlacementService) {
    var ctrl = this;
    ctrl.patient_count = {};
    ctrl.productActive = 'implants';
    ctrl.currentProduct = {
      category: "REPORTS.PLACEMENT.PLACED_IMPLANT",
      color: "#398ee3",
      count: 2,
      img: {svg: "styles/img/implants/implants-icon-v3.svg"},
      placements: [],
      set_name: "implants"
    };
    ctrl.implant_placement = {
      current:{},
      previous: {}
    };
    ctrl.sets = {
      'Implant': {
        color: "#398ee3",
        category: 'REPORTS.PLACEMENT.PLACED_IMPLANT',
        set_name: 'implants',
        display_name: $filter('translate')('IMPLANTS.IMPLANTS'),
        placements: [],
        count: 0,
        img: {
          svg: "styles/img/implants/implants-icon-v3.svg",
        }
      },
      'Closure Cap': {
        color: "#7a41cb",
        category: 'REPORTS.PLACEMENT.PLACED_CLOSURE',
        set_name: 'caps',
        display_name: $filter('translate')('IMPLANTS.COVER_SCREWS'),
        placements: [],
        count: 0,
        img: {
          svg: "styles/img/implants/caps-icon.svg",
        }
      },
      'Healing Abutment': {
        color: "#2eb479",
        category: 'REPORTS.PLACEMENT.PLACED_HEALING',
        set_name: 'abutments',
        display_name: $filter('translate')('IMPLANTS.HEALING_ABUTMENTS'),
        placements: [],
        count: 0,
        img: {
          svg: "styles/img/implants/healing-icon.svg",
        }
      },
      'Bone Graft': {
        color: "#33bbc4",
        category: 'REPORTS.PLACEMENT.PLACED_GRAFTS',
        set_name: 'bonegraft',
        placements: [],
        count: 0,
        img: {
          material: "grain",
        }
      },
      'Membrane': {
        color: "#ff2566",
        category: 'REPORTS.PLACEMENT.PLACED_MEMBRANES',
        set_name: 'membranes',
        display_name: $filter('translate')('IMPLANTS.MEMBRANES'),
        placements: [],
        count: 0,
        img: {
          material: "healing",
        }
      }
    };
    ctrl.date = {
      startDate: moment().startOf('year'),
      endDate: moment().endOf('day'),
      previousStartDate: moment().subtract(1, 'y').startOf('year'),
      previousEndDate: moment().subtract(1, 'y').endOf('year')
    };
    ctrl.changeProductReport =  function(item){
      ctrl.productActive = item.set_name;
      ctrl.currentProduct = item;
      ctrl.checklabel(item.placements);
    };
    ctrl.yearLabel = {
      current: moment(ctrl.date.startDate).format('YYYY'),
      previous: moment(ctrl.date.previousStartDate).format('YYYY'),
      vs: ''
    };        
    ctrl.daterangeOption = {
      locale: {
        format: 'MMM D, YYYY'
      }
      ,
      eventHandlers: {
        'apply.daterangepicker': function (event, picker) {
          ctrl.timeRangeSelected = '';
          ctrl.changeDate();
        }
      }
    };
    ctrl.timeRange = [
      {
        name: 'YTD',
        value: 'ytd'
      },
      {
        name: 'MTD',
        value: 'mtd'
      },
      {
        name: 'QTD',
        value: 'qtd'
      }
    ];
    ctrl.timeRangeSelected = 'ytd';
    ctrl.series = [moment(ctrl.date.startDate).format('YYYY'), moment(ctrl.date.startDate).subtract(1, 'y').format('YYYY')];
    ctrl.chartSetting = {
      options: {
        responsive: true,
        maintainAspectRatio: false
      },
      colors: [
        {
          backgroundColor: '#398de3',
          pointBackgroundColor: 'rgba(148,159,177,1)',
          pointHoverBackgroundColor: 'rgba(148,159,177,1)',
          borderColor: '#398de3',
          pointBorderColor: '#fff',
          pointHoverBorderColor: 'rgba(148,159,177,0.8)'
        },
        {
          backgroundColor: '#a0cfff',
          pointBackgroundColor: 'rgba(77,83,96,1)',
          pointHoverBackgroundColor: 'rgba(77,83,96,1)',
          borderColor: '#a0cfff',
          pointBorderColor: '#fff',
          pointHoverBorderColor: 'rgba(77,83,96,0.8)'
        }
      ]
    };

    ctrl.addDataPerMonth = addDataPerMonth;
    ctrl.addDataPerMonthByCustom = addDataPerMonthByCustom;
    ctrl.addDataToChart = addDataToChart;
    ctrl.addDataToCustomRange = addDataToCustomRange;
    ctrl.addDataToMTD = addDataToMTD;
    ctrl.addDataToQTD = addDataToQTD;
    ctrl.changeDate = changeDate;
    ctrl.changePlacedData = changePlacedData;
    ctrl.changeProductData = changeProductData;
    ctrl.changeSeletedTime = changeSeletedTime;
    ctrl.chartClick = chartClick;
    ctrl.chartForCustomByDay = chartForCustomByDay;
    ctrl.chartForCustomByMonth = chartForCustomByMonth;
    ctrl.chartIsForCustom = chartIsForCustom;
    ctrl.chartIsForMonth = chartIsForMonth;
    ctrl.chartIsForQuarter = chartIsForQuarter;
    ctrl.chartIsForYear = chartIsForYear;
    ctrl.checklabel = checklabel;
    ctrl.compareDataOfQTD = compareDataOfQTD;
    ctrl.customChartData = customChartData;
    ctrl.customData = customData;
    ctrl.formatDisplayYearLabel = formatDisplayYearLabel;
    ctrl.generateDataForChart = generateDataForChart;
    ctrl.generateLabelForCustom = generateLabelForCustom;
    ctrl.generateLabelForCustomByMonth = generateLabelForCustomByMonth;
    ctrl.generateLabelForMonth = generateLabelForMonth;
    ctrl.generateLabelForQuarter = generateLabelForQuarter;
    ctrl.getImplantDashboard = getImplantDashboard;
    ctrl.getImplantDashboardReport = getImplantDashboardReport;
    ctrl.goToPlacedDetail = goToPlacedDetail;
    ctrl.implantPlacementReport = implantPlacementReport;
    ctrl.normalizeDate = normalizeDate;
    ctrl.productChartIsForCustom = productChartIsForCustom;
    ctrl.productChartIsForMonth = productChartIsForMonth;
    ctrl.productChartIsForQuarter = productChartIsForQuarter;
    ctrl.productChartIsForYear = productChartIsForYear;
    ctrl.productChartSumValueByDay = productChartSumValueByDay;
    ctrl.sumByMonth = sumByMonth;
    ctrl.addDataToCustomDateRangeChart = addDataToCustomDateRangeChart;

    init();

    return ctrl;
    
    function init () {
      ctrl.getImplantDashboard();
      ctrl.getImplantDashboardReport();
    }
    
    function changeDate () {
      var d = angular.copy(ctrl.date);
      d.previousStartDate = moment(d.startDate).subtract(1, 'y');
      d.previousEndDate = moment(d.endDate).subtract(1, 'y');
      
      ctrl.date = d;
      
      ctrl.getImplantDashboard();
      ctrl.getImplantDashboardReport();
    }
    
    function getImplantDashboard () {
      var start_date = moment(ctrl.date.startDate).format('YYYY-MM-DD');
      var end_date = moment(ctrl.date.endDate).format('YYYY-MM-DD');
      var previous_start_date = moment(ctrl.date.previousStartDate).format('YYYY-MM-DD');
      var previous_end_date = moment(ctrl.date.previousEndDate).format('YYYY-MM-DD');
      sowImplantPlacementService.getImplantDashboard(start_date, end_date, previous_start_date, previous_end_date)
      .then(function (data) {
        ctrl.customData(data);
        ctrl.series = [moment(ctrl.date.startDate).format('YYYY'), moment(ctrl.date.startDate).subtract(1, 'y').format('YYYY')];
      });
    }
    
    function getImplantDashboardReport () {
      var start_date = moment(ctrl.date.startDate).format('YYYY-MM-DD');
      var end_date = moment(ctrl.date.endDate).format('YYYY-MM-DD');
      sowImplantPlacementService.getPlacedList(start_date, end_date)
      .then(function (data) {
        ctrl.placedData = Object.values(ctrl.changePlacedData(data));
        ctrl.changeProductData(ctrl.placedData);
        ctrl.series = [moment(ctrl.date.startDate).format('YYYY'), moment(ctrl.date.startDate).subtract(1, 'y').format('YYYY')];
        ctrl.total_placed_items = ctrl.placedData.reduce(function(sum, item){ return item.count + sum;},0);
      });
    }
    
    function changeProductData(data){
      
      var f = ctrl.placedData.filter(function(el){
        return el.set_name == ctrl.productActive;
      });
      
      var d = f[0].placements;
      ctrl.checklabel(d);
    }
    
    function checklabel(d){
      if (d.length == 0) {
        ctrl.productChartData = [];
      } else {
        var new_d = ctrl.productChartSumValueByDay(d);
        if (ctrl.timeRangeSelected == 'ytd') {
          ctrl.productChartData = ctrl.productChartIsForYear(new_d);
        } else if (ctrl.timeRangeSelected == 'mtd') {
          ctrl.productChartData = ctrl.productChartIsForMonth(new_d);
        } else if (ctrl.timeRangeSelected == 'qtd') {
          ctrl.productChartData = ctrl.productChartIsForQuarter(new_d);
        } else {
          ctrl.productChartData = ctrl.productChartIsForCustom(new_d);
        }
      }
    }
    
    function productChartIsForYear (d) {
      ctrl.labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      var pData = ctrl.generateDataForChart(ctrl.labels);
      var data = [];
      var s = ctrl.sumByMonth(d);
      pData = ctrl.addDataPerMonth(s, pData);
      data.push(pData);
      return data;
    }
    
    function productChartIsForMonth (d){
      ctrl.labels = ctrl.generateLabelForMonth();
      var pData = ctrl.generateDataForChart(ctrl.labels);
      var data = [];
      d.forEach(function (el) {
        var dt = moment(el.date, 'DD MM YYYY').format('D') - 1;
        pData[dt] = el.placement_count;
      });
      data.push(pData);
      return data;
    }
    
    function productChartIsForQuarter (d){
      ctrl.labels = ctrl.generateLabelForQuarter();
      var pData = ctrl.generateDataForChart(ctrl.labels);
      var data = [];
      var s = ctrl.sumByMonth(d);
      pData = ctrl.compareDataOfQTD(s, pData);
      data.push(pData);
      return data;
    }
    
    function productChartIsForCustom (d){
      var startDate = ctrl.date.startDate;
      var endDate = ctrl.date.endDate;
      var dayNumber = Math.abs(endDate.diff(startDate, 'days')) + 1;
      ctrl.customDayLength = dayNumber;
      if (dayNumber > 32) {
        ctrl.labels = ctrl.generateLabelForCustomByMonth();
        var pData = ctrl.generateDataForChart(ctrl.labels);
        var data = [];
        var s = ctrl.sumByMonth(d);
        pData = ctrl.addDataPerMonthByCustom(s, pData);
        data.push(pData);
        return data;
      } else {
        ctrl.labels = ctrl.generateLabelForCustom();
        var pData = ctrl.generateDataForChart(ctrl.labels);
        var data = [];
        
        var l = ctrl.labels;
        d.forEach(function (el) {
          var dt = parseInt(moment(el.date, 'DD MM YYYY').format('D'));
          var indexOf = l.indexOf(dt);
          pData[indexOf] = el.placement_count;
        });
        data.push(pData);
        return data;
      }
    }
    
    function productChartSumValueByDay (d){
      d.forEach(function(el){
        el.placement_count = 1;
        // this method was trying to convert dates that had already been converted, resulting in invalid dates which caused the charts to show incorrect data
        // so i moved it to normalizeDate() instead
        // el.date = moment(el.date, 'DD MMM, YYYY').format('DD-MM-YYYY');
        el.date = normalizeDate(el.date);
      });
      
      var result = [];
      d.forEach(function (a) {
        if (!this[a.date]) {
          
          this[a.date] = { date: a.date, placement_count: 0 };
          result.push(this[a.date]);
        }
        this[a.date].placement_count += a.placement_count;
      }, Object.create(null));
      
      return result;
    }
    
    function normalizeDate (date_input) {
      // expect dates in both 'DD MMM, YYYY' and 'DD-MM-YYYY' format, return on the latter format
      var response = null;
      var is_formatted = (date_input.toString().substr(6,1) !== ',');
      if (is_formatted) {
        response = angular.copy(date_input);
      } else {
        response = moment(date_input, 'DD MMM, YYYY').format('DD-MM-YYYY');
      }
      return response;
    }
    
    function changePlacedData (data) {
      var p = data.product_placements;
      var s = angular.copy(ctrl.sets);
      p.forEach(function (el) {
        if(s[el.category]) {
          s[el.category].placements = el.placements;
          s[el.category].count = el.placements.length;
        }
      });
      
      return s;
    }
    
    function customData (data) {
      
      var d = data || {};
      var current = d.current || {};
      var previous = d.previous || {};
      
      ctrl.patient_count = current.patient_count;
      
      ctrl.implantPlacementReport(current, previous);
      ctrl.customChartData(current, previous);
    }
    function customChartData (current, previous) {
      var c = current || {};
      var p = previous || {};
      
      if (!p.implants_placed && !c.implants_placed) {
        ctrl.chartData = [];
      } else {
        if (ctrl.timeRangeSelected == 'ytd') {
          ctrl.chartData = ctrl.chartIsForYear(c, p);
        } else if (ctrl.timeRangeSelected == 'mtd') {
          ctrl.chartData = ctrl.chartIsForMonth(c, p);
        } else if (ctrl.timeRangeSelected == 'qtd') {
          ctrl.chartData = ctrl.chartIsForQuarter(c, p);
        } else {
          ctrl.chartData = ctrl.chartIsForCustom(c, p);
        }
      }
    }
    
    function chartIsForCustom (c, p) {
      var startDate = ctrl.date.startDate;
      var endDate = ctrl.date.endDate;
      var dayNumber = Math.abs(endDate.diff(startDate, 'days')) + 1;
      if (dayNumber > 32) {
        return ctrl.chartForCustomByMonth(c, p);
      } else {
        return ctrl.chartForCustomByDay(c, p);
      }
    }
    
    function chartForCustomByMonth (c, p) {
      ctrl.labels = ctrl.generateLabelForCustomByMonth();
      var pData = ctrl.generateDataForChart(ctrl.labels);
      var cData = ctrl.generateDataForChart(ctrl.labels);
      var data = [];
      
      if (c.implants_placed.length > 0) {
        pData = ctrl.addDataToCustomDateRangeChart(p, pData);
        cData = ctrl.addDataToCustomDateRangeChart(c, cData);
      } else {
        pData = ctrl.addDataToCustomDateRangeChart(p, pData);
      }
      
      data.push(cData, pData);
      return data;
    }
    
    function addDataToCustomDateRangeChart (p, pData) {
      var s = ctrl.sumByMonth(p.implants_placed);
      var d = ctrl.addDataPerMonthByCustom(s, pData);
      return d;
    }
    
    function addDataPerMonthByCustom (s, data) {
      var l = ctrl.labels;
      
      s.forEach(function (el) {
        var d = el.dateName;
        var i = l.indexOf(d);
        data[i] = el.placement_count;
      });
      return data;
    }
    
    function generateLabelForCustomByMonth () {
      var label = [];
      var yearOfStartDate = moment(ctrl.date.startDate).format('YYYY');
      var yearOfEndDate = moment(ctrl.date.endDate).format('YYYY');
      
      if (yearOfEndDate == yearOfStartDate) {
        var startDate = moment(ctrl.date.startDate).format('M');
        var endDate = moment(ctrl.date.endDate).format('M');
        for (var i = parseInt(startDate); i <= parseInt(endDate); i++) {
          label.push(moment(i, 'M').format('MMM'));
        }
      } else {
        var s1 = moment(ctrl.date.startDate).format('M');
        var e2 = moment(ctrl.date.endDate).format('M');
        
        for (var i = parseInt(s1); i <= 12; i++) {
          label.push(moment(i, 'M').format('MMM'));
        }
        
        for (var i = 1; i <= parseInt(e2); i++) {
          label.push(moment(i, 'M').format('MMM'));
        }
      }
      
      return label;
    }
    
    
    function chartForCustomByDay (c, p) {
      ctrl.labels = ctrl.generateLabelForCustom();
      var pData = ctrl.generateDataForChart(ctrl.labels);
      var cData = ctrl.generateDataForChart(ctrl.labels);
      var data = [];
      
      if (c.implants_placed.length > 0) {
        pData = ctrl.addDataToCustomRange(p, pData);
        cData = ctrl.addDataToCustomRange(c, cData);
      } else {
        pData = ctrl.addDataToCustomRange(p, pData);
      }
      
      data.push(cData, pData);
      return data;
    }
    
    function addDataToCustomRange (p, pData) {
      var imArr = p.implants_placed;
      var l = ctrl.labels;
      imArr.forEach(function (el) {
        var d = parseInt(moment(el.date, 'DD MM YYYY').format('D'));
        var indexOf = l.indexOf(d);
        pData[indexOf] = el.placement_count;
      });
      return pData;
    }
    
    function generateLabelForCustom () {
      var label = [];
      var monthOfStartDate = moment(ctrl.date.startDate).format('M');
      var monthOfEndDate = moment(ctrl.date.endDate).format('M');
      
      if (monthOfEndDate == monthOfStartDate) {
        var startDate = moment(ctrl.date.startDate).format('D');
        var endDate = moment(ctrl.date.endDate).format('D');
        for (var i = parseInt(startDate); i <= parseInt(endDate); i++) {
          label.push(i);
        }
      } else {
        var s1 = moment(ctrl.date.startDate).format('D');
        var s2 = moment(ctrl.date.startDate).endOf('month').format('D');
        
        var e2 = moment(ctrl.date.endDate).format('D');
        var e1 = moment(ctrl.date.endDate).startOf('month').format('D');
        
        for (var i = parseInt(s1); i <= parseInt(s2); i++) {
          label.push(i);
        }
        
        for (var i = parseInt(e1); i <= parseInt(e2); i++) {
          label.push(i);
        }
      }
      return label;
    }
    
    function chartIsForMonth (c, p) {
      ctrl.labels = ctrl.generateLabelForMonth();
      var pData = ctrl.generateDataForChart(ctrl.labels);
      var cData = ctrl.generateDataForChart(ctrl.labels);
      var data = [];
      
      if (c.implants_placed.length > 0) {
        pData = ctrl.addDataToMTD(p, pData);
        cData = ctrl.addDataToMTD(c, cData);
      } else {
        pData = ctrl.addDataToMTD(p, pData);
      }
      
      data.push(cData, pData);
      return data;
    }
    
    function chartIsForQuarter (c, p) {
      ctrl.labels = ctrl.generateLabelForQuarter();
      var pData = ctrl.generateDataForChart(ctrl.labels);
      var cData = ctrl.generateDataForChart(ctrl.labels);
      var data = [];
      
      if (c.implants_placed.length > 0) {
        pData = ctrl.addDataToQTD(p, pData);
        cData = ctrl.addDataToQTD(c, cData);
      } else {
        pData = ctrl.addDataToQTD(p, pData);
      }
      
      data.push(cData, pData);
      return data;
    }
    
    
    function chartIsForYear (c, p) {
      ctrl.labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      var pData = ctrl.generateDataForChart(ctrl.labels);
      var cData = ctrl.generateDataForChart(ctrl.labels);
      var data = [];
      
      if (c.implants_placed.length > 0) {
        pData = ctrl.addDataToChart(p, pData);
        cData = ctrl.addDataToChart(c, cData);
      } else {
        pData = ctrl.addDataToChart(p, pData);
      }
      
      data.push(cData, pData);
      return data;
    }
    
    function addDataToQTD (p, pData) {
      var s = ctrl.sumByMonth(p.implants_placed);
      var d = ctrl.compareDataOfQTD(s, pData);
      return d;
    }
    
    function compareDataOfQTD (s, data) {
      var l = ctrl.labels;
      _.forEach(s, function (el) {
        var indexOf = l.indexOf(el.dateName);
        data[indexOf] = el.placement_count;
      });
      return data;
    }
    
    function generateLabelForQuarter () {
      var c_month = moment(ctrl.date.startDate).startOf('quarter').format('M');
      var label = [];
      
      for (var i = 0; i < 3; i++) {
        var n = moment(parseInt(c_month) + i, 'M').format('MMM');
        label.push(n);
      }
      return label;
    }
    function generateLabelForMonth () {
      var c_month = moment(ctrl.date.startDate).daysInMonth();
      var p_month = moment(ctrl.date.previousStartDate).daysInMonth();
      var length_of_label = c_month > p_month ? c_month : p_month || p_month;
      var label = [];
      
      for (var i = 0; i < length_of_label; i++) {
        label.push(i + 1);
      }
      
      return label;
    }
    
    function generateDataForChart (labels) {
      var data = [];
      _.each(labels, function (el, i) {
        data.push(0);
      });
      return data;
    }
    
    function addDataToChart (p, pData) {
      var s = ctrl.sumByMonth(p.implants_placed);
      var d = ctrl.addDataPerMonth(s, pData);
      return d;
    }
    
    function addDataToMTD (p, pData) {
      var imArr = p.implants_placed;
      imArr.forEach(function (el) {
        var d = moment(el.date, 'DD MM YYYY').format('D') - 1;
        pData[d] = el.placement_count;
      });
      return pData;
    }
    
    function addDataPerMonth (s, pData) {
      _.each(s, function (el, i) {
        var monthNumber = moment(el.dateName, 'MMM').format('M') - 1;
        pData[monthNumber] = el.placement_count
      });
      return pData;
    }
    
    function sumByMonth (data) {
      var result = [];
      
      _.each(data, function (el) {
        var m = moment(el.date, 'DD MM YYYY').format('MMM');
        el.dateName = m;
      })
      
      data.forEach(function (a) {
        if (!this[a.dateName]) {
          this[a.dateName] = { dateName: a.dateName, placement_count: 0 };
          result.push(this[a.dateName]);
        }
        this[a.dateName].placement_count += a.placement_count;
      }, Object.create(null));
      
      return result;
    }
    
    
    function implantPlacementReport (current, previous) {
      var perc = '-';
      var color = 'black';
      var products_placed = 0;
      var total_consignment = 0;
      var previous_products_placed = 0;
      var previous_total_consignment = 0;
      
      current.total_products_placed.forEach(function (c) {
        if (c.category === 'Implant') {
          products_placed = c.category_count;
          total_consignment = c.on_consignment;
        }
      });
      
      previous.total_products_placed.forEach(function (p) {
        if (p.category === 'Implant') {
          previous_products_placed = p.category_count;
          previous_total_consignment = p.on_consignment;
        }
      });
      
      if (previous_products_placed != 0) {
        perc = (products_placed - previous_products_placed) * 100 / previous_products_placed;
        color = perc > 0 ? 'green' : 'red'
        
        ctrl.implant_placement = {
          current: {
            products_placed: products_placed,
            total_consignment: total_consignment
          },
          previous: {
            products_placed: previous_products_placed == 0 ? '-' : previous_products_placed,
            total_consignment: previous_total_consignment
          },
          percent: {
            value: perc,
            color: color
          }
        }
      }else if(previous_products_placed == 0){
        ctrl.implant_placement = {
          current: {
            products_placed: products_placed,
            total_consignment: total_consignment
          },
          previous: {
            products_placed: previous_products_placed == 0 ? '-' : previous_products_placed,
            total_consignment: previous_total_consignment
          },
          percent: {
            value: perc,
            color: color
          }
        }
      } else{
        ctrl.implant_placement = {
          current: {
            products_placed: "-",
            total_consignment: 0
          },
          previous: {
            products_placed: "-",
            total_consignment: 0
          },
          percent: {
            value: perc,
            color: color
          }
        }
      }
      
      // debugger;
      ctrl.implant_placement.current.total_products_placed = current.products_placed;
      
      ctrl.formatDisplayYearLabel();
    }
    
    function formatDisplayYearLabel () {
      if (ctrl.timeRangeSelected == 'ytd') {
        var c = moment(ctrl.date.startDate).format('YYYY');
        var p = moment(ctrl.date.startDate).subtract(1, 'y').format('YYYY');
        ctrl.yearLabel = {
          current: c,
          previous: p,
          vs: c + ' vs ' + p
        }
      } else if (ctrl.timeRangeSelected == 'mtd') {
        var c = moment(ctrl.date.startDate).format('MMM, YYYY');
        var p = moment(ctrl.date.startDate).subtract(1, 'y').format('MMM, YYYY');
        ctrl.yearLabel = {
          current: c,
          previous: p,
          vs: c + ' vs ' + p
        }
      } else if (ctrl.timeRangeSelected == 'qtd') {
        var c = moment().startOf('quarter').format('MMM') + ' - ' + moment().endOf('quarter').format('MMM, YYYY');
        var p = moment().subtract(1, 'y').startOf('quarter').format('MMM') + ' - ' + moment().subtract(1, 'y').endOf('quarter').format('MMM, YYYY');
        ctrl.yearLabel = {
          current: c,
          previous: p,
          vs: c + ' vs ' + p
        }
      } else {
        if (moment(ctrl.date.startDate).format('YYYY') == moment(ctrl.date.endDate).format('YYYY')) {
          var c = moment(ctrl.date.startDate).format('MMM DD') + ' - ' + moment(ctrl.date.endDate).format('MMM DD, YYYY');
          var p = moment(ctrl.date.startDate).subtract(1, 'y').format('MMM DD, YYYY') + ' - ' + moment(ctrl.date.endDate).subtract(1, 'y').format('MMM DD, YYYY');
          ctrl.yearLabel = {
            current: c,
            previous: p,
            vs: moment(ctrl.date.startDate).format('YYYY') + ' vs ' + moment(ctrl.date.startDate).subtract(1, 'y').format('YYYY')
          }
        } else {
          var c = moment(ctrl.date.startDate).format('MMM DD, YYYY') + ' - ' + moment(ctrl.date.endDate).format('MMM DD, YYYY');
          var p = moment(ctrl.date.startDate).subtract(1, 'y').format('MMM DD, YYYY') + ' - ' + moment(ctrl.date.endDate).subtract(1, 'y').format('MMM DD, YYYY');
          var tc = moment(ctrl.date.startDate).format('YYYY') + ' - ' + moment(ctrl.date.endDate).format('YYYY');
          var tp = moment(ctrl.date.startDate).subtract(1, 'y').format('YYYY') + ' - ' + moment(ctrl.date.endDate).subtract(1, 'y').format('YYYY')
          ctrl.yearLabel = {
            current: c,
            previous: p,
            vs: tc + ' vs ' + tp
          }
        }
      }
    }

    function changeSeletedTime (val) {
      if (ctrl.timeRangeSelected != val) {
        ctrl.timeRangeSelected = val;
        var dt2 = angular.copy(ctrl.date);
        if (val == 'mtd') {
          dt2.startDate = moment().startOf('month');
          dt2.endDate = moment().endOf('day');
          dt2.previousStartDate = moment().subtract(1, 'y').startOf('month');
          dt2.previousEndDate = moment().subtract(1, 'y').endOf('day');
        } else if (val == 'qtd') {
          dt2.startDate = moment().startOf('quarter');
          dt2.endDate = moment().endOf('day');
          dt2.previousStartDate = moment().subtract(1, 'y').startOf('quarter');
          dt2.previousEndDate = moment().subtract(1, 'y').endOf('day');
        } else if (val == 'ytd') {
          dt2.startDate = moment().startOf('year');
          dt2.endDate = moment().endOf('day');
          dt2.previousStartDate = moment().subtract(1, 'y').startOf('year');
          dt2.previousEndDate = moment().subtract(1, 'y').endOf('year');
        }
        ctrl.date = dt2;
        ctrl.getImplantDashboard();
        ctrl.getImplantDashboardReport();
        
      }
    }
    
    function goToPlacedDetail(year,val){
      var startDate = ctrl.date.startDate;
      var endDate = ctrl.date.endDate;
      var isCustom = true;
      if(year && !ctrl.timeRangeSelected){
        startDate = moment(ctrl.date.startDate).subtract(1, 'y');
        endDate = moment(ctrl.date.endDate).subtract(1, 'y');
        isCustom = true;
      }
      if(ctrl.timeRangeSelected){
        startDate = ctrl.timeRangeSelected;
        endDate = null;
        isCustom = false;
      }
      
      if(val){
        startDate = val.startDate;
        endDate = val.endDate;
        isCustom = true;
      }
      $state.go('app.reports.implants-placed-detail', {startDate: startDate, endDate:endDate, isCustom: isCustom, year: year});
    }
    
    function chartClick (points){
      if(points.length > 0){
        var lb =points[0]._model.label;
        
        var val = {
          startDate: '',
          endDate: ''
        }
        
        if(ctrl.timeRangeSelected){
          if(ctrl.timeRangeSelected == 'mtd'){
            var d = moment(ctrl.date.startDate).format('MM YYYY');
            val.startDate = moment(lb + ' ' + d, 'DD MM YYYY');
            val.endDate = moment(lb + ' ' + d, 'DD MM YYYY');
          }else{
            var d = moment(ctrl.date.startDate).format('YYYY');
            val.startDate = moment(lb + ' ' + d, 'MMM YYYY').startOf('month');
            val.endDate = moment(lb + ' ' + d, 'MMM YYYY').endOf('month');
          }
        }else{
          if(ctrl.customDayLength < 32){
            var d = moment(ctrl.date.startDate).format('MM YYYY');
            val.startDate = moment(lb + ' ' + d, 'DD MM YYYY');
            val.endDate = moment(lb + ' ' + d, 'DD MM YYYY');
          }else{
            var d = moment(ctrl.date.startDate).format('YYYY');
            val.startDate = moment(lb + ' ' + d, 'MMM YYYY').startOf('month');
            val.endDate = moment(lb + ' ' + d, 'MMM YYYY').endOf('month');
          }
        }
        ctrl.goToPlacedDetail(null,val);
      }
    }
    
  }
}());

(function () {
  
  function ExternalVendorCredentialsCP (ExternalVendorCP) {
    const ExternalVendor = ExternalVendorCP.getClass();
    
    /**
    *
    * @class ExternalVendorCredentials
    * @typedef ExternalVendorCredentials
    */
    class ExternalVendorCredentials {
      constructor(json_data) {
        this.office_id = json_data.office_id;
        this.password = json_data.password;
        this.user_id = json_data.user_id;
        this.vendor = new ExternalVendor(json_data.vendor);
      }
            
      office_id;
      get office_id () {
        return this.office_id;
      }
      set office_id (new_office_id) {
        this.office_id = new_office_id;
      }
      
      user_id;
      get user_id () {
        return this.user_id;
      }
      set user_id (new_user_id) {
        this.user_id = new_user_id;
      }
      
      password;
      get password () {
        return this.password;
      }
      set password (new_password) {
        this.password = new_password;
      }

      vendor;
      get vendor () {
        return this.vendor;
      }
      set vendor (new_vendor) {
        this.vendor = new_vendor;
      }
      
    }
    
    this.getClass = () => {
      return ExternalVendorCredentials;
    }
  }
  
  angular.module('sowMarketplace')
  .service("ExternalVendorCredentialsCP", ExternalVendorCredentialsCP);
})();

angular.module("app.shared.support")
  //
  // Extract Image from the Product
  //
  .filter('imageUrl', function (ConfigService) {
    var NoImageUrl = ConfigService.get('NoImageUrl');

    return function (image, key) {
      if (arguments.length < 2) {
        key = 'url_src';
      } else {
        key = 'url_' + key;
      }

      if (!image) {
        return NoImageUrl;
      } else if (angular.isString(image)) {
        return image || NoImageUrl;
      } else if (angular.isObject(image)) {
        return image[key] || NoImageUrl;
      } else {
        return NoImageUrl;
      }
    };
  });

(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.shopping')
    .filter('productGroup', ProductGroupFilter);

  //
  // A "group by" filter that returns a list of products where there is only
  // one product per product group.
  //
  // The first Product in a ProductGroup is used as the Product that makes it
  // into the list, unless we encounter a Product that is set as the "main
  // Product" for the ProductGroup. In that case, we use the ProductGroup's
  // main Product.
  //
  function ProductGroupFilter() {
    return function (products) {
      var groups = {};

      _.forEach(products, function (product) {
        var product_group_id = product.product_group.product_group_id;
        var main_product_id = product.product_group.main_product_id;

        if (!groups[product_group_id]) {
          groups[product_group_id] = product;
        } else {
          if (main_product_id === product.id) {
            groups[product_group_id] = product;
          }
        }
      });

      return _.values(groups);
    };
  }

}());

(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.account')
    .controller('LoginFormController', LoginFormController);

  function LoginFormController ($scope, $state, sessionService, apiTokenService, RouteService, sowAnalyticsService) {
    /*jshint validthis: true */
    var ctrl = this;
    ctrl.destination_state = null;
    ctrl.data = {};
    ctrl.invite_token = _.get($scope, 'mdDialogOptions.invite_token');

    ctrl.setSignInMesg = setSignInMesg;
    ctrl.submit = submit;
    ctrl.goToCreateAccountModal = goToCreateAccountModal;
    ctrl.goToForgotPasswordModal = goToForgotPasswordModal;

    init();

    return ctrl;

    function init () {
      _initializeForm()
      sessionService.refreshSession()
      .then(function(session_data){

        if (apiTokenService.hasValidToken()) {
          // $state.go(ctrl.destination_state);
          RouteService.goToDefault();
        } else {
          // login used to be a modal, now that it's a page just do nothing instead
          // AccountModalService.show('Login');
        }
      });
    }

    /**
     * Fill in the email field if it was passed in the URL
     */
    function _initializeForm() {
      if ($state.params.email) {
        ctrl.data.email = $state.params.email;
      }
    }

    //
    // Set the "Signing In" message to display
    //
    function setSignInMesg (msg) {
      ctrl.signingIn = !!msg;
      ctrl.signingInMesg = msg;
    }

    function goToCreateAccountModal() {
      sowAnalyticsService.beginRegistration();
      $state.go('app.registration.start');
    }

    function goToForgotPasswordModal() {
      sowAnalyticsService.beginPasswordReset();
      $state.go('forgot_password');
    }

    //
    // Submit the Login Form
    //
    function submit ($event) {
      if ($event) {
        $event.preventDefault();
        $event.stopPropagation();
      }

      if (!$scope.LoginForm.$valid) {
        return;
      }

      ctrl.setSignInMesg('Signing into Your Account...');

      var account = {'email': ctrl.data.email, 'password': ctrl.data.password};
      var options = {'invite_token': ctrl.invite_token};

      return sessionService.login(account, options).then(function (session) {
        ctrl.setSignInMesg('Setting Your Current Office...');
      }).catch(function (error) {
//        $log.error('LoginFormController.submit: error = %o', error); Not necessary because service does error handling
        ctrl.setSignInMesg(null);

      });
    }
  }
}());

(function () {
  'use strict';

  angular.module("app")
    .config(mdIcons);

  function mdIcons($mdIconProvider) {
    $mdIconProvider.icon('sowingo-logo-bw-small', 'styles/img/branding/sowingo-logo-bw-sm.svg', 24);
  }

}());

angular.module("app.marketplace.ui.shopping")

  //Return true if element (category or product) should be shown for the current medical field.
  .filter('filterMedicalField', function ($rootScope) {
    return function (elements, medical_field) {
      if (!medical_field) {
        return false;
      }

      var filteredArray = [];
      medical_field = medical_field || $rootScope.current_medical_field;
      _.map(elements, function (element) {
        if (element.UI.mf_product_counts) {
          if (element.UI.mf_product_counts[medical_field.id]) {
            filteredArray.push(element);
          }
        } else if (!medical_field || !element.medical_fields || !element.medical_fields.length) {
          filteredArray.push(element);
        } else {
          _.map(element.medical_fields, function (field) {
            if (field.id === medical_field.id) {
              filteredArray.push(element);
            }
          });
        }
      });
      return filteredArray;
    };
  });

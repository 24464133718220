(function () {
  'use strict';

  angular.module("app")
    .config(initializeStripe);

  /**
   * sets our stripe publishable key (constant) so it can be used later on to generate card tokens
   * used in registration & checkout
   *
   * @param {*} stripePublishableKey
   * @param {*} stripeProvider
   */
  function initializeStripe(stripePublishableKey, stripeProvider) {
    stripeProvider.setPublishableKey(stripePublishableKey);
  }

})();
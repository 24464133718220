(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.inventory')
    .controller('duplicateItemController', duplicateItemController);

  function duplicateItemController ($scope, $filter, elementService, errorService, $mdDialog, sgToast, inventoryItemService, item) {
    /*jshint validthis: true */
    var ctrl = this;

    $scope.errors = {};
    $scope.shouldCopyLocations = true;
    // create item endpoint accepts 'manufacturer' as a string
    // to find or create a new manufacturer,
    // so I had to remove the object here
    $scope.item_duplicate = _.omit(angular.copy(item), [
      'on_hand',
      'added_to_shopping_list_by',
      'added_to_shopping_list_by_id',
      'added_to_shopping_list_on',
      'created_at',
      'expiry_date',
      'expiry_dates',
      'id',
      'image',
      'image_url_src',
      'notes',
      'shopping_list',
      'updated_at',
      'manufacturer',
      ]);

    $scope.close = close;
    $scope.submit = submit;
    $scope.vendorSearch = vendorSearch;
    $scope.manufacturerSearch = manufacturerSearch;

    return ctrl;

    //
    // Search a model by name
    //
    function searchModel (model, name) {
      return elementService.get(model, null, {
        'endpoint': 'search',
        'name': name,
      });
    }

    //
    // Search for a manufacturer by name
    //
    function manufacturerSearch (name) {
      if (!name) {
        return false;
      }

      return searchModel('manufacturer', name).then(function (manufacturers) {
        var names = [];
        _.map(manufacturers, function(manufacturer){
          names.push(manufacturer.name);
        });
        return names;
      });
    }

    //
    // Search for a vendor by name
    //
    function vendorSearch (name) {
      if (!name) {
        return false;
      }

      return elementService.get('supplier', null, {'endpoint': 'search', 'name': name}).then(function (suppliers) {
        var supplier_names = _.map(suppliers, 'name');
        return supplier_names;
      });
    }

    //
    // Close the dialog
    //
    function close () {
      $mdDialog.cancel();
    }

    //
    // Submit the form
    //
    function submit () {
      if ($scope.duplicateItemForm.$invalid) {
        $scope.duplicateItemForm.$setSubmitted();
        return false;
      }

      // Compensate for autocomplete
      $scope.item_duplicate.manufacturer_name = $scope.item_duplicate.manufacturer_name || $scope.item_duplicate.manufacturerSearchText;
      $scope.item_duplicate.vendor_name = $scope.item_duplicate.vendor_name || $scope.item_duplicate.vendorSearchText;


      if ($scope.item_duplicate.name === item.name &&
          $scope.item_duplicate.manufacturer_name === item.manufacturer_name &&
          $scope.item_duplicate.vendor_name === item.supplier) {
        $scope.errors.allfieldsmatch = true;
        return false;
      } else {
        $scope.errors.allfieldsmatch = false;
      }

      $scope.submitting = true;

      var newItem = angular.copy($scope.item_duplicate);

      if ($scope.shouldCopyLocations) {
        newItem.locations = angular.copy(item.locations);
      }

      newItem.user_category_id = item.user_category_id;

      return inventoryItemService.createItem(newItem).then(function (new_inventory_item) {
        var t_message = $filter('translate')('TOAST.INVENTORY_ITEM_DUPLICATED');
        sgToast.showSimple(t_message);
        // alertsDataCarrierService.load_alerts();
        $mdDialog.hide(new_inventory_item);
      }).catch(function (error) {
        var t_message = $filter('translate')('ERRORS.INVENTORY_ITEM_DUPLICATE')
        errorService.uiErrorHandler(error || t_message, 0);
      })['finally'](function () {
        $scope.submitting = false;
      });
    }
  }

}());

(function () {
  'use strict';

  angular
    .module('sowMarketplace')
    .service('mktHelperService', mktHelperService)
    .config(registerEventsMktHelper);

  function registerEventsMktHelper (appEventsProvider) {
    appEventsProvider.registerEvent('mktProductDetailsOpen', 'mkt-details-open');
    appEventsProvider.registerEvent('mktProductDetailsOpenFetch', 'mkt-details-open-fetch');
    appEventsProvider.registerEvent('mktProductDetailsOpenFinished', 'mkt-details-open-finished');
  }

  function mktHelperService ($filter, $state, $mdSidenav, $stateParams, $rootScope, $mdDialog, appEvents, sowAnalyticsService, creditCardService, sowLanguageService, ProductHelperService) {
    /*jshint validthis: true */
    const service = this;

    service.goToCategory = goToCategory;
    service.goToSubcategory = goToSubcategory;
    service.goToManufacturer = goToManufacturer;
    service.goToProductDetailsPage = goToProductDetailsPage;
    service.calculateInventoryOnHand = calculateInventoryOnHand;
    service.addToCartQuantityDialog = addToCartQuantityDialog;
    service.cartPermissionDialog = cartPermissionDialog;
    service.productDetailsOpen = productDetailsOpen;
    service.productDetailsFetch = productDetailsFetch;
    service.setProductVendorInfo = setProductVendorInfo;
    service.setProductInfo = setProductInfo;
    service.getPromoText = getPromoText;
    service.getPromoItemQty = getPromoItemQty;
    service.getProductInventoryStatus = getProductInventoryStatus;
    service.parseItem = parseItem;
    service.parseOrder = parseOrder;
    service.parsePO = parsePO;
    service.parsePending = parsePending;
    service.goToOrder = goToOrder;
    service.getOrderSubtotal = getOrderSubtotal;
    service.getOrdersSummary = getOrdersSummary;
    service.sortVendors = sortVendors;
    service.doesVendorHaveProduct = doesVendorHaveProduct;
    service.getLocalizedErrorFromCode = getLocalizedErrorFromCode;
    service.shouldSkipCurrentStep = shouldSkipCurrentStep;
    service.getLocalizedProp = getLocalizedProp;
    service.getLocalizedPromoNotes = getLocalizedPromoNotes;
    service.getActivePrice = getActivePrice;
    service.addPricePrefix = addPricePrefix;
    service.doesVendorHavePrice = doesVendorHavePrice;
    service.getProductStatus = getProductStatus;
    service.getOrderItemQuantity = getOrderItemQuantity;
    service.parseCodesToValue = parseCodesToValue;

    return service;
    
    /** 
     * Navigates to the marketplace search page with selected query applied as a filter. 
     * 
     * @param {Object} params 
     * 
     * @event redirectTo:"app.mkt.search"
     * @return {*} 
    */
    function goToCategory (params) {
      if($stateParams.query) {
        params.query = $stateParams.query;
      }
      if($stateParams.mf_id) {
        params.mf_id = $stateParams.mf_id;
      }
      if($stateParams.manufacturer){
        params['manufacturer'] = $stateParams.manufacturer;
      }
      $state.go('app.mkt.search', params, {'reload': true, 'inherit': false});
    }

    /** 
     * Navigates to the marketplace search page with selected manufacturer applied as a filter. 
     * 
     * @param {Object} params 
     * 
     * @event redirectTo:"app.mkt.search"
     * @return {*} 
    */
    function goToManufacturer (params) {
      if($stateParams.query) {
        params.query = $stateParams.query;
      }
      $state.go('app.mkt.search', params, {'reload': true, 'inherit': false});
    }

    /** 
     * Navigates to the marketplace search page with selected subcategory applied as a filter. 
     * 
     * @param {Object} params 
     * 
     * @event redirectTo:"app.mkt.search"
     * @return {*} 
    */
    function goToSubcategory (params) {
      if($stateParams.query) {
        params.query = $stateParams.query;
      }
      if($stateParams.mf_id) {
        params.mf_id = $stateParams.mf_id;
      }
      $state.go('app.mkt.search', params, {'reload': true});
    }


    /** 
     * Navigates to the Product Details Page of the clicked product. 
     * 
     * @param {object} product
     * @param {object} $event required in order to stop click propagation 
     * @param {{product: {object}, params: {object}}} extra_log_props added inside the g4 event
     * 
     * @event redirectTo:"app.product-details"
     * @return {*} 
    */
    function goToProductDetailsPage (product, $event, extra_log_props) {
      if($event) $event.stopPropagation();
      if (_.get(product, 'url_name')) {
        // Remove any overlays which are currently open
        $mdSidenav('mkt-details').close();
        $mdDialog.hide();

        $state.go('app.product-details', {'url_name': product.url_name});

        const {product_details, params} = _generateProductLogInfo(product, extra_log_props);
        sowAnalyticsService.logProductDetails(product_details, params);
      }
    }

    /**
     * Returns an object with a product_details property and a params property
     * @param {object} product 
     * @param {{product: {object}, params: {object}}} extra_log_props 
     * @returns {{product_details: {object}, params: {object}}}
     */
    function _generateProductLogInfo (product, extra_log_props) {
      const categories = product.parent_category ? [product.parent_category, product.subcategory]: product.categories
      const product_details = {
        ...product,
        index: 0, // add index of 0 when the user clicks the product name on PDSlideout and Add To Cart
        ...extra_log_props?.product, // add extra product info (marketplace section index)
        ..._.first(product.sorted_vendors), // add info of the lowest priced available vendor
        categories, // add categories
      };
      product_details.coupon = product_details.promotions?.[0]?.promotion_type;

      return {
        product_details,
        params: extra_log_props?.params
      };
    }

    /** 
     * Calculated the total quantity of an item in the user's General Inventory,
     * factoring in the quantity contained within each package (if tracked by package). 
     * 
     * @param {Object} item 
     * 
     * @return {Number} 
    */
    function calculateInventoryOnHand(item) {
      if (item.tracking_method === 'Item') {
        return item.on_hand;
      }
      if (!item.order_package_quantity) {
        return 0;
      }
      return Math.floor(item.on_hand / item.order_package_quantity);
    }

   /**
    * If the error code is CART_STRIPE_BILLING_ERROR or CART_STRIPE_CARD_ERROR, then don't skip the
    * current step. Otherwise, skip the current step
    * @param {string} error_code - The internal code of the error returned from the server.
    * @return {boolean}
    */
    function shouldSkipCurrentStep(error_code) {
      switch (error_code) {
        case 'CART_STRIPE_BILLING_ERROR':
        case 'CART_STRIPE_CARD_ERROR':
          return false;
        default:
          return true;
      }
    }

   /**
    * Takes an error code and returns the corresponding localized error message.
    * @param {string} error_code - The internal code of the error returned from the server.
    * @returns {string} The translated error message.
    */
    function getLocalizedErrorFromCode(error_code) {
      let error_locale_key;
      switch (error_code) {
        case 'CART_EMPTY_ERROR':
          error_locale_key = 'CART_EMPTY';
          break;
        case 'CART_STALE_DATA_ERROR':
        case 'CART_TAX_RATE_ERROR':
          error_locale_key = 'ORDER_NOT_PLACED';
          break;
        case 'CART_BILLING_ERROR':
          error_locale_key = 'INPUT_BILLING_ADDRESS';
          break;
        case 'CART_SHIPPING_ERROR':
          error_locale_key = 'INPUT_SHIPPING_ADDRESS';
          break;
        case 'CART_OFFICE_PROMO_CODE_ERROR':
        case 'CART_SOWINGO_PROMO_CODE_ERROR':
        case 'CART_VENDOR_PROMO_CODE_ERROR':
          error_locale_key = 'ENTER_PROMO_CODE';
          break;
        case 'PO_SUPPLIER_MISSING_EMAIL':
          error_locale_key = 'PO_SUPPLIER_MISSING_EMAIL';
          break;
        case 'CART_PRODUCT_TIME_RESTRICT_ERROR':
          error_locale_key = 'CART_PRODUCT_TIME_RESTRICT';
          break;
        case 'CART_ITEM_SOLD_OUT_ERROR':
        case 'CART_ITEM_NOT_AVAILABLE_ERROR':
          error_locale_key = 'CART_ITEM_NOT_AVAILABLE';
          break;
        case 'CART_PRICE_UPDATED_ERROR':
          error_locale_key = 'CART_PRICE_UPDATED';
          break;
        case 'CART_STRIPE_BILLING_ERROR':
          error_locale_key = 'CART_STRIPE_BILLING';
          break;
        case 'PO_EMAILING_ERROR':
          error_locale_key = 'UNABLE_TO_EMAIL';
          break;
        case 'CART_STRIPE_CARD_ERROR':
          error_locale_key = 'CART_STRIPE_CARD';
          break;
        case 'STRIPE_CHARGE_CREDIT_CARD_ERROR':
        case 'STRIPE_CHARGE_CREDIT_CARD_ERROR_AMOUNT_TOO_LARGE':
        case 'STRIPE_CHARGE_CREDIT_CARD_ERROR_AMOUNT_TOO_SMALL':
        case 'STRIPE_CHARGE_CREDIT_CARD_ERROR_CARD_DECLINE_RATE_LIMIT_EXCEEDED':
          error_locale_key = 'CART_STRIPE_UNABLE_TO_PROCESS';
          break;
        case 'STRIPE_CHARGE_CREDIT_CARD_ERROR_CARD_DECLINED':
          error_locale_key = 'CART_STRIPE_DECLINED';
          break;
        case 'STRIPE_CHARGE_CREDIT_CARD_ERROR_EXPIRED_CARD':
          error_locale_key = 'CART_STRIPE_EXPIRED';
          break;
        case 'CART_VENDOR_PROMO_NOT_VALID':
          error_locale_key = 'INVALID_VENDOR_PROMO';
          break;
        case 'PO_CART_CHECKOUT_ERROR':
        case 'CART_ORDER_VENDOR_GROUP_CREATION_ERROR':
        case 'CART_ORDER_CREATION_ERROR':
        case 'CART_ORDER_ROLLBACK_ERROR':
        case 'CART_SANITY_ERROR':
        case 'CART_CHECKOUT_ERROR':
        default:
          error_locale_key = 'UNEXPECTED';
      }
      const t_checkout_error_text = $filter('translate')(`ERRORS.${error_locale_key}`);
      return t_checkout_error_text;
    }

    /** 
     * Configuration of Add to Cart modal dialog. 
     * 
     * @param {Object} triggeringEvent
     * @param {Object} product
     * @param {Object} vendor_item
     * 
     * @event displayModal
     * @return {*} 
    */
    function addToCartQuantityDialog (triggeringEvent, product, vendor_item) {
      sowAnalyticsService.logCartModalOpened({...product, ...vendor_item});
      return $mdDialog.show({
        'bindToController': true,
        'clickOutsideToClose': true,
        'controller': 'mktAddQtyController' ,
        'controllerAs': 'mktaqCtrl',
        'parent': angular.element(document.body),
        'targetEvent': triggeringEvent,
        'templateUrl': 'sow-mkt/modals/mkt-add-qty.html',
        'locals': {
          'product': product,
          'vendor_item': vendor_item,
          'quantity': 1,
        },
      });
    }

    /** 
     * Configuration of purchase authorization modal. 
     * 
     * @param {Object} triggeringEvent 
     * 
     * @event displayModal
     * @return {*} 
    */
    function cartPermissionDialog (triggeringEvent) {
      return $mdDialog.show({
        'clickOutsideToClose': false,
        'controller': 'mktCartPermissionController',
        'controllerAs': 'mktcpCtrl',
        'parent': angular.element(document.body),
        'targetEvent': triggeringEvent,
        'templateUrl': 'sow-mkt/modals/mkt-cart-permission.html'
      });
    }

    /** 
     * Opens product details slideout modal. 
     * 
     * @param {Object} product 
     * 
     * @event displayModal
     * @return {*} 
    */
    function productDetailsOpen (product) {
      $rootScope.$broadcast(appEvents.mktProductDetailsOpen, product);
    }


    function productDetailsFetch (id, options = {}) {
      $rootScope.$broadcast(appEvents.mktProductDetailsOpenFetch, id, options);
    }

    /** 
     * Parses and sorts the vendors of a product. 
     * 
     * @param {Object} product 
     * 
     * @return {Object} 
    */
    function setProductVendorInfo (product) {
      product.vendor_inventory = parseVendorList(product);
      product.sorted_vendors = sortVendors(product.vendor_inventory);

      return product;
    }

    /**
     * Returns a string indicating the stock status of a vendor
     * @param {object} vendor_inventory
     * @return {"unknown"|"sold_out"|"discontinued"|"available"}
     */
    function getProductStatus({product_status}) {
      // Handle case where we don't have any status info
      if (!_.isObject(product_status)) {
        return ProductHelperService.PRODUCT_STATUS.UNKNOWN;
      }
      // If a product is sold out, we label it as such regardless
      // of whether or not it's also discontinued
      if (product_status.sold_out) {
        return ProductHelperService.PRODUCT_STATUS.SOLD_OUT;
      }
      // If it's not sold out but is discontinued, we label it as discontinued
      if (product_status.is_discontinued) {
        return ProductHelperService.PRODUCT_STATUS.DISCONTINUED;
      }
      // By default, products are labeled as available
      return ProductHelperService.PRODUCT_STATUS.AVAILABLE;
    }

    /** 
     * Sets UI property of each vendor within a product's vendor_inventory. 
     * 
     * @param {Object} product 
     * 
     * @return {Array} 
    */
    function parseVendorList (product) {
      var parsed_vendors = _.map(product.vendor_inventory, function(vendor_item){
        _.set(vendor_item, 'UI.full', true);

        const current_vendor_status = getProductStatus(vendor_item);
        _.set(vendor_item, 'UI.product_status', current_vendor_status);
        _.set(vendor_item, 'UI.stock_text', ProductHelperService.getStockText(current_vendor_status));

        var promo_count = _.size(vendor_item.promotions);
        _.set(vendor_item, 'UI.on_sale', (promo_count > 0) );
        _.set(vendor_item, 'UI.promo_count', promo_count );
        _.set(vendor_item, 'UI.shipping_text', getShippingText(vendor_item));
        _.set(vendor_item, 'UI.image', vendor_item.vendor.image);

        _.each(vendor_item.promotions, function(promo){
          var promo_type = _.get(promo, 'promotion_type', null);
          var is_manufacturer_promo = promo_type && promo_type.includes('MANUFACTURER');
          _.set(vendor_item, 'UI.is_manufacturer_promo', is_manufacturer_promo);
          const localized_notes = getLocalizedPromoNotes(promo);
          _.set(vendor_item, 'UI.promo_notes', localized_notes);
          if (!is_manufacturer_promo) {
            var props = promo.promotion_properties;
            if (props.buy === 1) {
              _.set(vendor_item, 'UI.strike_regular_price', true);
            }
          }
          _.set(vendor_item, 'UI.promo_text', getPromoText(promo));
          _.set(vendor_item, 'UI.promo_price', promo.effective_price);
          _.set(vendor_item, 'UI.end_date', promo.end_date);
        });
        _.set(vendor_item, 'UI.sale_end_text', getSaleEndText(vendor_item));
        _.set(vendor_item, 'UI.date_available_text', getDateAvailableText(vendor_item));
        _.set(vendor_item, 'UI.is_external', Boolean(vendor_item.is_external_vendor_pricing));

        return vendor_item;
      });

      return parsed_vendors;
    }

    /**
     * Sorts vendors from cheapest to most expensive within these categories:
     * 1. Internal vendors with stock
     * 2. External vendors with stock
     * 3. Loading vendors
     * 4. Error vendors
     * 5. Sold out internal vendors
     * 6. Discontinued internal vendors
     * 7. Sold out external vendors
     * 8. Discontinued external vendors
     * @param {object[]} vendors
     * @return {object[]}
     */
    function sortVendors(vendors) {
      if (vendors.length < 2) {
        return vendors;
      }
      const sorted_vendors = _sortVendorsByPrice(vendors);
      const {internal_vendors, external_vendors} = _getInternalAndExternalVendors(sorted_vendors);
      const vendors_with_stock = [
        ..._getVendorsWhoHaveStock(internal_vendors),
        ..._getVendorsWhoHaveStock(external_vendors),
      ];
      const {loading_vendors, error_vendors, unknown_price_vendors} = _getPriceUpdateVendors(vendors);
      const sold_out_and_discontinued_vendors = [
        ..._getSoldOutAndDiscontinuedVendors(internal_vendors),
        ..._getSoldOutAndDiscontinuedVendors(external_vendors),
      ];
      return [
        ...vendors_with_stock,
        ...loading_vendors,
        ...unknown_price_vendors,
        ...error_vendors,
        ...sold_out_and_discontinued_vendors,
      ];
    }

    /**
     * Takes an array of vendors and returns an object which contains those which
     * are loading, those which have errored out, and those without prices
     * @param {object[]} vendors
     * @return {object}
     */
    function _getPriceUpdateVendors(vendors) {
      const loading_vendors = [];
      const error_vendors = [];
      const unknown_price_vendors = [];
      for (const vendor of vendors) {
        const {is_external, actions, is_loading, has_error, product_status} = vendor.UI;
        if (is_external && actions) {          
          if (is_loading) {
            loading_vendors.push(vendor);
          } else if (has_error) {
            error_vendors.push(vendor);
          } else if (!doesVendorHavePrice(vendor) && !['sold_out', 'discontinued'].includes(product_status)) {
            unknown_price_vendors.push(vendor);
          }
        }
      };

      return {
        loading_vendors,
        error_vendors,
        unknown_price_vendors,
      };
    }

    /**
     * Returns true if the vendor has a price or list price and false if not
     * @param {object} vendor
     * @return {boolean}
     */
    function doesVendorHavePrice(vendor) {
      return Boolean(vendor.price || vendor.list_price);
    }

    /**
     * Separates a list of vendors into an object containing internal and external lists
     * @param {object[]} vendors
     * @return {object}
     */
    function _getInternalAndExternalVendors(vendors) {
      const internal_vendors = [];
      const external_vendors = [];
      for (const vendor of vendors) {
        if (vendor.UI?.is_external) {
          external_vendors.push(vendor);
        } else {
          internal_vendors.push(vendor);
        }
      }
      return {internal_vendors, external_vendors};
    }

    /**
     * Sorts the vendors who have stock by price (cheapest to most expensive)
     * @param {object[]} vendors
     * @return {object[]}
     */
    function _getVendorsWhoHaveStock(vendors) {
      return vendors.filter(doesVendorHaveProduct);
    }

    /**
     * Generates an array with the sold out vendors (if any) sorted by price,
     * followed by the discontinued vendors (if any) sorted by price
     * @param {object[]} vendors
     * @return {object[]}
     */
    function _getSoldOutAndDiscontinuedVendors(vendors) {
      const sold_out_vendors = [];
      const discontinued_vendors = [];
      for (const vendor of vendors) {
        switch(vendor.UI?.product_status) {
          case 'sold_out':
            sold_out_vendors.push(vendor);
            break;
          case 'discontinued':
            discontinued_vendors.push(vendor);
            break;
        }
      }
      return [...sold_out_vendors, ...discontinued_vendors];
    }

    /**
     * Sorts vendors from lowest to highest effective price.
     * @param {object[]} vendors
     * @return {object[]}
     */
    function _sortVendorsByPrice(vendors) {
      return _.sortBy(vendors, [function(vendor) {
        const lowest_price = vendor.UI?.promo_price || vendor.price;
        return lowest_price;
      }])
    }

    /**
     * > If the product status is "sold_out" or "discontinued", then the product is not available
     * @param vendor - The vendor object
     * @returns A boolean value.
     */
    function doesVendorHaveProduct(vendor) {
      if (['sold_out', 'discontinued'].includes(vendor.UI?.product_status)) {
        return false;
      }
       
      return !(vendor.UI.is_external && (vendor.UI?.is_loading || !doesVendorHavePrice(vendor)));
    }

     /** 
     * Sets UI property of a product and invokes other functions to parse and sort its vendor_inventory. 
     * 
     * @param {Object} product 
     * 
     * @return {Object} 
    */
    function setProductInfo (product) {
      var vi = _.get(product, 'vendor_inventory[0]');
      if (_.isNil(vi)) {
        return null;
      }
      if (!product.list_price) {
        _.set(product, 'list_price', _.get(vi, 'list_price', 0));
      }
      if (!product.price) {
        _.set(product, 'price', _.get(vi, 'price', 0));
      }

      if(vi.has_promotions) {
        var promo = _.get(vi, 'promotions[0]');
        _.set(product, 'UI.is_promo', true);
        _.set(product, 'UI.promo_price', promo.effective_price);
        _.set(product, 'UI.promo_text', getPromoText(promo));
        _.set(product, 'UI.promo_qty', getPromoItemQty(product, promo));

        // Only display third row of promo price data for items for which an
        // effective price can be determined (ie. Buy X, Get X Free)
        if (!['MANUFACTURER_BUY_GET_OTHER', 'BUY_GET_OTHER'].includes(promo.promotion_type)) {
          _.set(product, 'UI.display_promo_price', true);
        }
        
        // If promotion_properties.buy === 1, the regular price can be styled with strikethrough
        // since the customer will always be able to purchase at the promotional price
        var props = promo.promotion_properties;
        if (props.buy === 1) {
          _.set(product, 'UI.strike_regular_price', true);
        }
      }
      product = setProductVendorInfo(product);
      product = _setProductBadges(product);
      product = _setProductType(product);
      product = _setLocalizedProductFields(product)

      return product;
    }

    /**
     * Sets default value for product type in case there's none fetched from the API.
     *
     * @param {marketplaceProduct} product
     * @return {marketplaceProduct} 
     */
    function _setProductType (product) {
      if (_.isObjectLike(product.product_type)) {
        // do nothing, for now
      } else {
        product.product_type = {
          "type": "Regular",
          "external_link": null
        };
      }

      var product_status = _.get(product, 'sorted_vendors[0].UI.product_status');
      // Flag if product is sold out
      var sold_out_condition = product_status === "sold_out";
      _.set(product, 'UI.is_sold_out', sold_out_condition);
      // Flag if product is discontinued
      var discontinued_condition = product_status === "discontinued";
      _.set(product, 'UI.is_discontinued', discontinued_condition);

      var product_type = _.get(product, 'product_type.type', 'Regular');
      // Flag if product is time restricted
      var time_restricted_condition = product_type.includes('Time-Restricted');
      _.set(product, 'UI.is_time_restricted', time_restricted_condition);
      // Flag if product is external
      var external_condition = product_type.includes('External');
      _.set(product, 'UI.is_external', external_condition);
      // Flag if product is formulary
      var formulary_condition = product_type.includes('Formulary');
      _.set(product, 'UI.is_formulary', formulary_condition);
      // Flag if product is a no price product
      var no_price_condition = product_type.includes('No-Price');
      _.set(product, 'UI.is_no_price_product', no_price_condition);
      // Flag if product can be checked out
      var no_checkout_condition = product_type.includes('No-Checkout');
      const add_to_cart_condition = !external_condition && !no_checkout_condition && !time_restricted_condition;
      _.set(product, 'UI.can_checkout', add_to_cart_condition);

      // Flag if product is already in General Inventory
      var gen_inv_on_hand = _.get(product, 'office_inventory_status.on_hand');
      var in_inv_condition = parseInt(gen_inv_on_hand) > -1;
      _.set(product, 'UI.is_in_gen_inv', in_inv_condition);

      // Flag if product is medication
      var item_type = _.get(product, 'item_type', 'Marketplace Item');
      var medication_condition = item_type === 'Marketplace Medication Item';
      _.set(product, 'UI.is_medication', medication_condition);
      // Flag if medication is already in Medications
      var meds_on_hand = _.get(product, 'medication_inventory_status.on_hand');
      var in_meds_condition = parseInt(meds_on_hand) > -1;
      _.set(product, 'UI.is_in_meds', in_meds_condition);
      

      return product;

    }

    /**
     * Parses the advertising_badges array, separating alerts from actual badges 
     * and setting both resultant arrays as properties of product.UI.
     *
     * @param {marketplaceProduct} product
     * @return {marketplaceProduct} 
     */
    function _setProductBadges (product) {
      _.set(product, 'UI.alerts', []);
      _.set(product, 'UI.badges', []);

      if(!product.advertising_badges) return;

      // Some endpoints have this as an object, some as an array with 1 item
      if (_.isNil(product.advertising_badges.length)) {
        var object = product.advertising_badges;
        product.advertising_badges = [];
        product.advertising_badges.push(object);
      }
      
      _.each(product.advertising_badges, function(advertising_object){
        if(!advertising_object || !advertising_object.badges) return;
        _.each(advertising_object.badges, function(badge){
          // Both alerts and badges are in the same table structure, so we separate them here
          // (if the badge_type includes "product_alert" it's an alert, otherwise it's a badge)
          if(badge.badge_type.includes('product_alert')){
            var ICON_SOURCES = {
              product_alert_critical: 'styles/img/icons/icon_alert-circle.svg',
              product_alert_information: 'styles/img/icons/icon_info.svg',
              product_alert_success: 'styles/img/icons/icon_circle_check.svg',
              product_alert_warning: 'styles/img/icons/icon_alert_triangle.svg',
            };
            badge.icon_src = ICON_SOURCES[badge.badge_type];
            product.UI.alerts.push(badge);
          } else {
            product.UI.badges.push(badge);
          }
        });
      });
      return product;
    }

    /**
     * Adds localized data to a product based on the selected language
     * @param {object} product
     * @return {object}
     */
    function _setLocalizedProductFields(product) {
      _.set(product, 'UI.localized_name', getLocalizedProp(product, 'name'));

      return product
    }

    /** 
     * Returns promo text to be displayed in the UI (if any). 
     * 
     * @param {Object} promo 
     * 
     * @return {String} 
    */
    function getPromoText (promo) {
      if(!promo.id) return '';
      var props = promo.promotion_properties;
      var promo_key = promo.promotion_type;
      // Special keys exist for "PERCENT_OFF" and "VALUE_OFF" promos with a `buy` prop
      // greater than one since we want to display "Buy {buy}, Save {get}%" or
      // "Buy {buy}, Save ${get}" in cases such as these where a threshold must
      // be met in order for the promo to be applied
      if (['PERCENT_OFF', 'VALUE_OFF'].includes(promo_key) && props.buy > 1) {
        promo_key = "BUY_GET_" + promo_key;
      }
      // We use the same key for BUY_GET sales of both vendors and manufacturers
      else if (promo_key === "MANUFACTURER_BUY_GET") {
        promo_key = "BUY_GET"
      }
      // BUY_GET_OTHER sales are labeled "Special Offer"
      else if (promo_key === "MANUFACTURER_BUY_GET_OTHER") {
        promo_key = "SPECIAL_OFFER"
      }
      // By default we simply indicate that there is a promotion of some kind
      else if (!promo_key || promo_key === "DEFAULT") {
        promo_key = "PROMOTION"
      }
      return $filter('translate')("MARKETPLACE.CARD.PROMOTIONS." + promo_key, {'x': props.buy, 'y': props.get});
    }

    /** 
     * Calculates the number of free items the user will receive given the number of times they
     * met the `buy` threshold of a "Buy {buy}, Get {get} Free" promo. 
     * 
     * @param {Object} product 
     * @param {Object} promo 
     * 
     * @return {String}
    */
    function getPromoItemQty (product, promo) {
      var qty_text = null;
      if(!promo.id) return qty_text;

      if(['BUY_GET', 'MANUFACTURER_BUY_GET'].includes(promo.promotion_type) ) {
        var times_promo_was_met = Math.floor(product.quantity / _.toNumber(promo.promotion_properties.buy));
        var extra_qty = ( _.toNumber(promo.promotion_properties.get) * times_promo_was_met);
        qty_text = "{0}+{1}".format(product.quantity, extra_qty);
      }

      return qty_text;
    }

    /** 
     * Generates string indicating shipping fees affiliated with vendor (if any). 
     * 
     * @param {Object} vendor 
     * 
     * @return {String} 
    */
    function getShippingText(vendor) {
      // Default value is shipping cost calculated at checkout
      var t_shipping_text = $filter('translate')('MARKETPLACE.DETAIL.SHIPPING_COST');
      var special_fee_amount = _.get(vendor, 'special_fee_amount', null);
      // Case 1: special fee applied (dangerous/hazardous product)
      if (special_fee_amount) {
        t_shipping_text = $filter('translate')('MARKETPLACE.DETAIL.SPECIAL_FEE', { 'x': special_fee_amount });
      // Case 2: multiple shipping rates - show default value
      } else if (_.size(_.get(vendor, 'vendor_shipping_rates')) > 1) {
        return t_shipping_text;
      } else {
        var shipping_rate = _.get(vendor, 'vendor_shipping_rates[0].rate', null);
        // NOTE: range_end > 999999 signifies free shipping is not possible (arbitrary DB designation)
        var range_end = _.get(vendor, 'vendor_shipping_rates[0].range_end', null);
        // Case 3: free shipping over range_end
        if (range_end > 0 && range_end <= 999999) {
          t_shipping_text = $filter('translate')('MARKETPLACE.DETAIL.FREE_SHIPPING_OVER', { 'x': range_end });
        }
        // Case 4: shipping rate applied
        else if (shipping_rate > 0 && range_end > 999999) {
          t_shipping_text = $filter('translate')('MARKETPLACE.DETAIL.SHIPPING_FEE', {'x': shipping_rate});
        }
        // Case 5: free shipping
        else if (range_end === 0) {
          t_shipping_text = $filter('translate')('MARKETPLACE.DETAIL.FREE_SHIPPING');
        }
        // Case 6: range_end < 0 - arbitrary DB designation to hide text entirely
        else if (range_end < 0) {
          return null;
        }
      }
      // Case 7: unknown shipping cost - show default value
      return t_shipping_text;
    }

    /** 
     * Generates sale end date text for a vendor's current promotion (if any).
     * 
     * @param {Object} vendor 
     * 
     * @return {String} 
    */
    function getSaleEndText(vendor) {
      var on_sale = _.get(vendor, 'UI.on_sale');
      var end_date = _.get(vendor, 'UI.end_date');
      if (on_sale && end_date) {
        // Calculate number of days remaining in sale
        var days = calculateRemainingDays(end_date);
        return days > 10
        // Case 1: sale ends in more than 10 days, text is "Sale ends on {end_date}"
          ? $filter('translate')('MARKETPLACE.DETAIL.SALE_ENDS_ON') + $filter('date')(end_date, 'MMM. dd, yyyy')
          // Case 2: sale ends in 1-10 days, text is "Sale ends in 1 day" or "Sale ends in {x} days"
          : days > 0
            ? $filter('translate')('MARKETPLACE.DETAIL.SALE_ENDS_IN_DAYS', { 'x': days }) + (days > 1 ? 's' : '')
            // Case 3: today is last day of sale, text is "Sale ends today"
            : days === 0
              ? $filter('translate')('MARKETPLACE.DETAIL.SALE_ENDS_TODAY')
              // Case 4: invalid end_date
              : null
      }
      // Case 5: product is not on sale
      return null;
    }

    /** 
     * Generates text indicating when time restricted product can be purchased (if any).
     * 
     * @param {Object} vendor 
     * 
     * @return {String} 
    */
     function getDateAvailableText(vendor) {
       // We only need to generate this text if the item is time resticted and currently unavailable
       var time_restricted_available_to_purchase =  _.get(vendor, 'time_restricted_available_to_purchase');
       var is_currently_restricted = time_restricted_available_to_purchase === false;
       var end_date = _.get(vendor, 'time_restricted_available_to_purchase_date');
      if (is_currently_restricted && end_date) {
        var formatted_date = $filter('date')(end_date, 'MMM. dd');
        return $filter('translate')('ACTIONS.ELIGIBLE_TO_ORDER', {'x': formatted_date});
      } else if (is_currently_restricted) {
        return $filter('translate')('ACTIONS.CHECK_BACK_SOON');
      } else {
        return null;
      }
    }

    /** 
     * Calculates how many days remain from now until a specific date. 
     * 
     * @param {String} end_date 
     * 
     * @return {Number} 
    */
    function calculateRemainingDays(end_date) {
      var current_time_in_ms = new Date().getTime();
      var end_date_in_ms = new Date(end_date).getTime();
      var ms_until_sale_ends = end_date_in_ms - current_time_in_ms;
      var ms_in_a_day = 1000 * 60 * 60 * 24;
      var days = Math.ceil(ms_until_sale_ends / ms_in_a_day);
      return days;
    }

    /**
     * If the medication inventory status is available, return it. Otherwise, if the implant
     * inventory status is available, return it. Otherwise, return the office inventory status
     * @param {object} status - The status object returned from the API
     * @return {object} the inventory status of a product.
     */
    function getProductInventoryStatus(status) {
      const inventory_status = status?.office_inventory_status;
      const medication_status = status?.medication_inventory_status;
      const implant_status = status?.implant_inventory_status;
      // if the user tracks the product in their Medications section, medication_status will
      // have an on_hand value of 0 or more - we need to perform this check because
      // medications can optionally be stored in Medications and/or General Inventory (if
      // the product is in both, we will display the Medications status)
      if (parseInt(medication_status?.on_hand) > -1) {
        return medication_status;
      }
      // perform a similar check for implants as well (future proofing)
      if (parseInt(implant_status?.on_hand) > -1) {
        return implant_status;
      }
      return inventory_status;
    }

    // TODO: moving these last functions into a "ordersHelperService" might be more appropriate
    function parseItem (item){
      const active_price = getActivePrice(item);
      _.set(item, 'UI', {
        'active_price': active_price,
        'promo_text': service.getPromoText(item.vendor_inventory_promotion),
        'promo_qty': service.getPromoItemQty(item, item.vendor_inventory_promotion),
        'subtotal_backordered': (active_price * item.quantity_backordered),
        'subtotal_cancelled': (active_price * item.quantity_cancelled),
        'subtotal_shipped': (active_price * item.quantity_shipped_in_this_shipment),
        'subtotal_unshipped': (active_price * item.quantity_unshipped),
        'subtotal_returned': (active_price * item.quantity_returned),
      });
    }

    /**
     * "If the item has a promotion, use the promotion price, otherwise use the item's unit price."
     * @param item
     * @returns {number} the price of the item.
     */
     function getActivePrice(item) {
      const price =
        item.vendor_inventory_promotion.effective_price ||
        item.vendor_inventory_promotion.original_price ||
        item.unit_price ||
        0;
      return _.toNumber(price);
    }

    function parseOrder (order) {
      order.type = 'marketplace_order';
      order.card_details = creditCardService.parseCard(order.card_details);
      _.each(order.order_vendor_groups, function(group){
        _.each(group.shipments, function(shipment){
          _.each(shipment.items, parseItem);
        });
        _.each(group.backordered_items, parseItem);
        _.each(group.cancelled_items, parseItem);
        _.each(group.unshipped_items, parseItem);
        _.each(group.returned_items, parseItem);
      });
      
      return order;
    }

    function parsePO (order) {
      order.type = 'purchase_order';
      return order;
    }

    function parsePending (order, request) {
      order.type = 'pending';
      order.hrid = request.hrid;
      return order;
    }

    function goToOrder (order) {
      switch(order.type){
        case 'purchase_order':
          return $state.go('app.orders.purchase.active',{poId: order.id});
          break;
        case 'marketplace_order':
          sowAnalyticsService.logMarketplaceOrderDetailClicked({order_hrid: order.hrid});
          return $state.go('app.orders.detail', { order_data: order, hrid: order.hrid });
          break;
        case 'pending':
          // TO-DO: change this into a single request page link once we have it
          return $state.go('app.dashboard.requests');
          break;
      }
    }

    function getOrderSubtotal (order) {
      var subtotal = 0;
      if(!order) return subtotal;

      const groups = _.get(order, 'order_vendor_groups', _.get(order, 'vendor_groups') );

      _.each(groups, function(group){
        let group_subtotal = _.get(group,'subtotal',_.get(group,'items_subtotal',0));
        subtotal += _.toNumber(group_subtotal);
      });

      return subtotal;
    }

    /**
     * If the status is cancelled or returned, then add the prefix
     * @returns {boolean}
     */
    function addPricePrefix(status) {
      const with_prefix = ['cancelled', 'returned'];
      return with_prefix.includes(status.toLowerCase()) ? '-': '';
    }

    function getOrdersSummary (orders) {
      let summary = {
        subtotal: 0,
        tax: 0,
        shipping: 0,
        total: 0,
        cancelled: 0,
        refunded: 0,
        net_total: 0,
      };

      _.each(orders, (order) => {
        switch (order.type) {
          case 'purchase_order':
            summary.subtotal = _.add(summary.subtotal, _.toNumber(order.subtotal));
            summary.shipping = _.add(summary.shipping, _.toNumber(order.shipping_cost));
            summary.tax = _.add(summary.tax, _.toNumber(order.tax));
            summary.total = _.add(summary.total, _.toNumber(order.total));
            break;
          case 'marketplace_order':
          case 'pending':
            const subtotal = getOrderSubtotal(order);
            summary.subtotal = _.add(summary.subtotal, _.toNumber(subtotal));
            summary.shipping = _.add(summary.shipping, _.toNumber(order.shipping_subtotal));
            summary.tax = _.add(summary.tax, _.toNumber(order.tax));
            const order_total = _.get(order, 'total_with_shipping', _.get(order, 'total', 0));
            summary.total = _.add(summary.total, _.toNumber(order_total));
            summary.cancelled = _.add(summary.cancelled, _getCancelledValue(order));
            summary.refunded = _.add(summary.refunded, _getRefundedValue(order));
            summary.net_total = _.add(summary.net_total, _.toNumber(order.net_total));
            break;
        }
      });
      return summary;
    }


    /**
     * It returns the total amount of cancelled items in an order
     * @param order
     * @returns The cancelled subtotal of the order.
     */
    function _getCancelledValue(order) {
      if (order.cancelled_subtotal) {
        return _.toNumber(order.cancelled_subtotal);
      }
      let cancelled = 0;
      order.order_vendor_groups?.forEach((order_group) => {
        order_group.cancelled_items?.forEach((item) => {
          cancelled += _getCancelledSubtotal(item);
        });
      });
      return cancelled;
    }


    /**
     * It returns the subtotal of a cancelled item
     * @param cancelled_item - The item that was cancelled.
     * @returns The subtotal of the cancelled item.
     */
    function _getCancelledSubtotal(cancelled_item) {
      const { cancelled_subtotal } = cancelled_item;
      if (cancelled_subtotal) {
        return _.toNumber(cancelled_subtotal);
      } else {
        const { quantity_cancelled, unit_price } = cancelled_item;
        return _.toNumber(unit_price) * quantity_cancelled;
      }
    }

    /**
     * It returns the total amount of money refunded for an order
     * @param order
     * @returns The total amount of money refunded for an order.
     */
    function _getRefundedValue(order) {
      if (order.returned_subtotal) {
        return _.toNumber(order.returned_subtotal);
      }
      let refunded = 0;
      order.order_vendor_groups?.forEach((order_group) => {
        order_group.returned_items?.forEach((item) => {
          refunded += item.returned_subtotal;
        });
      });
      return refunded;
    }


    /**
     * It returns the localized value of a property if it exists (formatted as 'propName_locale.en'), 
     * otherwise it returns the original value
     * @param model - The model object (product, vendor, etc)
     * @param propName - The name of the property you want to get the localized value for.
     * @returns The localized version of the property if it exists, otherwise the original property.
     */
    function getLocalizedProp (model, propName) {
      // selected lang or 'en' by default
      const currentLanguage = sowLanguageService.getCurrentLanguage();
      const original = _.get(model, propName);
      const localized = _.get(model, `${propName}_locale.${currentLanguage}`);

      return localized || original;
    }

    /**
     * It returns the localized notes of a promotion
     * @param promo - the promo object
     * @returns the value of the notes property of the promo object.
     */
    function getLocalizedPromoNotes (promo) {
      // default value
      let path = 'notes';
      // in some cases because of API inconsistency 
      // the notes attribute might have a different name
      if (_.has(promo, 'promotion_notes')) {
        path = 'promotion_notes';
      }
      return getLocalizedProp(promo, path);
    }

    /**
     * It returns the quantity of an order item based on its status
     * @param {object} item
     * @returns {number}
     */
    function getOrderItemQuantity (item) {
      // Get the status of the order item and make it lower case
      const order_status = _.lowerCase(item.status);

      // Object with the order item quantity by status
      const order_quantity = {
        backordered: item.quantity_backordered,
        cancelled: item.quantity_cancelled,
        processing: item.quantity_processing,
        returned: item.quantity_returned,
        shipped: item.quantity_shipped,
      }

      return order_quantity[order_status];
    }

    /**
     * This function takes a string of codes, removes any whitespace and diacritics, splits them by
     * comma, removes duplicates, and returns a string of unique comma-separated codes.
     * @param {string} codes - The parameter `codes` is a string containing comma-separated codes that need to
     * be parsed and cleaned up.
     * @return {string} a string of parsed codes that have been cleaned up by removing extra spaces,
     * diacritics, and duplicates.
     */
    function parseCodesToValue (codes) {
      const parsed_codes = _.chain(codes)
        .replace(/\s{2,}/g,' ')
        .deburr()
        .split(',')
        .map(_.trim)
        .uniq()
        .join(',')
        .value();
      return parsed_codes;
    }
  }

}());

(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.registration')
    .controller('CreateUserCtrl', CreateUserCtrl);

  function CreateUserCtrl ($scope, $timeout, $stateParams, legalService, registrationService, membershipService, accountService, sessionService, errorService, $state, sowAnalyticsService) {
    /*jshint validthis: true */
    var ctrl = this;
    var emailCheckTimeout = null;

    $scope.account = {
      accept_terms_of_service: true
    };
    $scope.saving = false;

    ctrl.showTerms = legalService.showTermsModal;
    ctrl.showPrivacyPolicy = legalService.showPrivacyPolicyModal;
    ctrl.showSignInModal = showSignInModal;
    ctrl.emailChanged = handleEmailChanges;
    ctrl.createUser = createUser;

    return init();

    //
    // Initialize Controller.
    //
    function init () {
      _initializeForm();
      $scope.$watch('account.email', handleEmailChanges);
    }

    /**
     * Checks if an email and name are provided in the URL
     * parameters, and then updates the form fields accordingly.
     */
    function _initializeForm() {
      if ($stateParams.email) {
        $scope.account.email = $stateParams.email;
        registrationService.checkEmail($stateParams.email).then((is_new_email) => {
          $scope.SignUpForm.email.$setValidity('in_use', is_new_email)
          $scope.SignUpForm.email.$touched = true;
          // boolean flag to enable/disable the email field. Email should be enable if it's already in use 
          // or if selectAccountOverride is true
          ctrl.is_email_disabled = $stateParams.selectAccountOverride ? false : is_new_email;
        });
      }

      if ($stateParams.name) {
        $scope.account.name = $stateParams.name;      
      }
    }

    //
    // Launch the Sign-In Modal.
    //
    function showSignInModal ($event) {
      // return AccountModalService.show('Login', $event);
      $state.go('login');
    }

    //
    // Handle Changes to the Email Field.
    //
    function handleEmailChanges (newEmail) {
      //
      // This sometimes happens during initialization.
      //
      if (!$scope.SignUpForm) {
        return;
      }

      if (!newEmail) {
        $scope.SignUpForm.email.$setValidity('in_use', true);

        if (emailCheckTimeout) {
          $timeout.cancel(emailCheckTimeout);
        }

        return;
      }

      if (emailCheckTimeout) {
        $timeout.cancel(emailCheckTimeout);
      }

      emailCheckTimeout = $timeout(function () {
        registrationService.checkEmail(newEmail).then(function (result) {
          $scope.SignUpForm.email.$setValidity('in_use', result);
        });
      }, 300);
    }

    //
    // Submit the Form / Create the User.
    //
    function createUser () {
      $scope.saving = true;

      if (!$scope.SignUpForm.$valid) {
        $scope.SignUpForm.$setSubmitted();
        $scope.saving = false;
        return;
      }

      var name = _.get($scope.account, 'name', '');
      // var capitalized_user_name = name.split(' ').map(_.capitalize).join(' ');
      var capitalized_user_name = name.toProperCase();
      
      _.set($scope.account, 'name', capitalized_user_name);

      return accountService.create($scope.account).then(function () {
        membershipService.suppressSwitcher(function () {
          return sessionService.login($scope.account).then(function (session) {
            createUserSuccess(session);
          }).catch(function (error) {
            errorService.uiErrorHandler(error);
            $scope.saving = false;
          });
        });
      }).catch(function (error) {
        errorService.uiErrorHandler(error);
        $scope.saving = false;
      });
    }
    
    function createUserSuccess ({user_id}) {
      $scope.$state.go('app.registration.account_created', {'redirection': false});
      $scope.saving = false;
      _trackRegisterUser(user_id, $scope.account);
    }

    /**
     * Logs when the user creates an account 
     * @param {string} user_id
     * @param {object} account
     * @param {boolean} account.opt_in_to_emails
     */
    function _trackRegisterUser(user_id, {opt_in_to_emails}) {
      const params = {
        user_id, 
        newsletter_subscription: opt_in_to_emails ? 'opt in' : 'opt out',
      }
      sowAnalyticsService.regUserInfo(params);
    }
  }

}());

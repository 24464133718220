//
//Video Tutorial Controller
//
var VideoTutorialsController = function ($scope, $sce, $filter, serverAPI, errorService) {

  serverAPI.doAPICall('https://vimeo.com/api/v2/sowingo/videos.json')
    .then(function (response) {
      $scope.videos = response.data;
    }).catch(function (error) {
      var t_message = $filter('translate')('ERRORS.VIDEO_RETRIEVE')
      errorService.uiErrorHandler(error || t_message, 0);
    });

  $scope.stringToDate = function (seconds) {
    return new Date(seconds * 1000);
  };
  $scope.descriptionSplit = function (video, index) {
    var split = video.description.split(new RegExp("<br />\\s+<br />\\s+<br />"));
    return index < split.length ? split[index] : '';
  };
  $scope.getVideoUrl = function (video) {
    video.emb_url = "https://player.vimeo.com/video/" + video.id + "?title=0&byline=0&portrait=0";
    return $sce.trustAsResourceUrl(video.emb_url);
  };
};

angular.module('app.marketplace.ui.marketing')
  .controller('VideoTutorialsController', VideoTutorialsController);


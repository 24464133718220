(function () {
  'use strict';

  const sowMktShipping = {
    selector: 'sowMktShipping',
    templateUrl: 'sow-mkt/directives/mkt-shipping.html',
    controller: 'marketplaceShippingController',
    controllerAs: 'mktshCtrl',
  };

  angular.module('sowMarketplace')
    .controller(sowMktShipping.controller, marketplaceShippingController)
    .component(sowMktShipping.selector, sowMktShipping);

  /** @ngInject */
  function marketplaceShippingController ($scope, $filter, $timeout, $rootScope, errorService, cartService, officeService, sessionService, addressService, formService) {
    /*jshint validthis:true*/
    const ctrl = this;
    ctrl.mode = 'view';
    ctrl.saving = false;
    ctrl.address_data = {};
    ctrl.backup_address_data = null;

    ctrl.editAddress = editAddress;
    ctrl.cancelEdit = cancelEdit;
    ctrl.setMode = setMode;
    ctrl.saveAddress = saveAddress;
    ctrl.useOfficeAddressBtn = useOfficeAddressBtn;
    ctrl.formUpdated = formUpdated;
    ctrl.setShowSuiteField = setShowSuiteField;
    ctrl.isSaveBtnDisabled = isSaveBtnDisabled;

    ctrl.$onInit = init;

    return ctrl;

    function init () {
      officeService.get(true)
      .then(function(response){ 
        ctrl.office = response;
        checkForOfficeCountry();
        ctrl.use_office_address = checkIfAddressesMatch();
      });
      ctrl.cart = cartService.get();
      $scope.address_data = ctrl.address_data = angular.copy(ctrl.cart.shipping_info);
      ctrl.hide_office_address_btn = !isOfficeAddressValid();
      setShowSuiteField();
      
      // wait until template is loaded so that form validation can happen
      $timeout(function(){
        if (!ctrl.address_data || !formService.checkRequiredFields(ctrl.shippingForm)) {
          editAddress();
        }
      }, 2000);
    }

    function checkForOfficeCountry () {
      var office_country = _.get(ctrl.office, 'address.country');
      if(office_country){
        _.set(ctrl.address_data, 'country', _.find($scope.$root.config.countries, {id: office_country.id}) );
        ctrl.disable_country = true;
      }
    }

    function formUpdated () {
      var address_changed = (ctrl.mode === 'edit') && !_.isNil(ctrl.backup_address_data) && changesMade(ctrl.address_data, ctrl.backup_address_data);
      if (address_changed) {
        $rootScope.shipping_form_unsaved = true;
        $rootScope.$broadcast('order-form-changed');
      } else {
        $rootScope.shipping_form_unsaved = false;
      }
    }

    function changesMade (new_data, old_data) {
      return !_.isEqual(angular.copy(new_data), angular.copy(old_data));
    }

    function setBackupData () {
      ctrl.backup_address_data = angular.copy(ctrl.address_data);
      $rootScope.shipping_form_unsaved = false;
    }

    function editAddress () {
      setMode('edit');
      setBackupData();
      $timeout(function(){
        var valid = formService.checkRequiredFields(ctrl.shippingForm);
        if(!valid){
          $rootScope.$broadcast('order-form-invalid');
        }
      }, 1000);
    }

    function cancelEdit () {
      setMode('view');
      ctrl.address_data = angular.copy(ctrl.backup_address_data);
      $rootScope.shipping_form_unsaved = false;
      $rootScope.$broadcast('order-form-changed');
    }

    function setMode (mode) {
      ctrl.mode = mode;
    }

    function isOfficeAddressValid () {
      var office = officeService.get();
      var address = _.get(office, 'address');
      return addressService.validateAddress(address);
    }

    function clearProvince () {
      ctrl.address_data.province = null;
    }

    function useOfficeAddress () {
      ctrl.address_data = angular.extend({'phone_number': ctrl.office.phone_number}, ctrl.office.address);

      if (ctrl.address_data) {
        ctrl.address_data.company_name = ctrl.office.name;
        ctrl.address_data.name = sessionService.session.user.name;

        if (ctrl.address_data.country) {
          ctrl.address_data.country = _.find($scope.$root.config.countries, {id: ctrl.address_data.country.id});
        }
      }
      formService.checkRequiredFields(ctrl.shippingForm);
    }

    function useOfficeAddressBtn () {
      useOfficeAddress();
      // had to use timeout in order to prevent saving with no phone number;
      // the form wasn't populated fast enough for the validation to occur with the 'new' office data
      $timeout(function(){
        updateShippingAddress();
      }, 500);
    }

    function updateShippingAddress () {
      if (ctrl.use_office_address) {
        useOfficeAddress();
      }
      var shipping = ctrl.address_data;
      var form_valid = formService.checkRequiredFields(ctrl.shippingForm);
      if (!form_valid) {
        return;
      }

      $scope.saving = ctrl.saving = true;

      if (shipping.location_id) {
        delete shipping.location_id;
      }

      return cartService.updateShipping(shipping)
      .then(function (response) {
        setBackupData();
        $rootScope.$broadcast('mkt-shipping-address-updated', ctrl.address_data);
        return ctrl.address_data && ctrl.address_data.updateOfficeAddress ? updateOfficeAddress() : finish();
      })
      .catch(function (error) {
        var t_message = $filter('translate')('ERRORS.SHIPPING_INFO')
        errorService.uiErrorHandler(t_message);
      })
      .finally(function () {
        ctrl.use_office_address = checkIfAddressesMatch();
        setShowSuiteField();
        $scope.saving = ctrl.saving = false;
      });

      function finish () {
        if (ctrl.mode === 'edit') {
          setMode('view');
        }
      }

      function updateOfficeAddress () {
        return officeService.updateAddress(shipping).then(function (address) {
          return finish();
        }).catch(function (error) {
          var t_message = $filter('translate')('ERRORS.OFFICE_ADDRESS')
          errorService.uiErrorHandler(t_message);
        });
      }
    }

    function saveAddress () {
      updateShippingAddress();
    }

    /**
     * Displays the "suite number" field of the form if a suite number is included
     * in the address or if the user clicks the "add suite number" button.
     *
     * @param {Boolean} button_clicked passed when the user clicks the button
     *
     * @return {*}
    */
    function setShowSuiteField(button_clicked) {
      if (button_clicked) {
        ctrl.show_suite_field = true;
      } else {
        ctrl.show_suite_field = _.get(ctrl, 'address_data.address2') ? true : false;
      }
    }

    /** 
     * Determines if the relevant fields of current billing and shipping addresses match. 
     * 
     * @return {Boolean} 
    */
     function checkIfAddressesMatch() {
      const props = ['address1', 'address2', 'city', 'country.id', 'postal_code', 'province.id']; // relevant fields
      return addressService.checkIfAddressesMatch(ctrl.address_data, ctrl.office.address, props);
    }

    function isSaveBtnDisabled() {
      return ctrl.saving || (ctrl.shippingForm.$invalid && !ctrl.use_office_address)
    }

  }

})();

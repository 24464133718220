(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.subscriptions')
    .controller('sowPlanController', sowPlanController);

  function sowPlanController ($scope, $log, $filter) {
    /*jshint validthis: true */
    var ctrl = this;

    var watchList = [
      'selectedPlan',
      'isCurrent',
      'isLarge',
      'isTrial',
      'isDowngrade',
      'noButton',
    ];

    var state = ctrl.state = {
      'class': '',
      'selectedPlan': null,
      'noButton': false,
      'isDowngrade': false,
      'isCurrent': false,
      'isLarge': false,
      'isTrial': false,
    };

    ctrl.triggerAction = triggerAction;
    ctrl.init = init;
    ctrl._buttonText = null;

    return ctrl;

    //
    // Initialize the Controller
    //
    function init (config) {
      $scope.planTitle = state.name = config.name;
      $scope.planTagline = config.tagline;
      $scope.planPitch = config.pitch;
      state.class = config.class;
      $scope.triggerAction = ctrl.triggerAction;

      _.forEach(watchList, function (name) {
        $scope.$watch(name, updateState);
      });
    }

    function setButtonText (text) {
      ctrl.buttonText = text;
      $scope.buttonText = text;
    }

    function updateState () {
      _eval('noButton');
      _eval('isDowngrade');
      _eval('isCurrent');
      _eval('isLarge');
      _eval('isTrial');

      state.selectedPlan = $scope.selectedPlan;

      var selectedBillingCycle = _.get($scope.selectedPlan, 'billing_cycle');

      $scope.planClass = state.class; // + (state.isLarge ? ' plan-large md-whiteframe-10dp': '') + (state.isCurrent ? ' is-current' : '');

      $scope.monthlyCost = $filter('number')(_.get($scope.selectedPlan, 'per_month_cost'), 2) || '--';
      if($scope.discountValue && $scope.monthlyCost !== '--' ){
        $scope.monthlyCost = $filter('number')((_.get($scope.selectedPlan, 'per_month_cost') - $scope.discountValue), 2);
      }
      $scope.monthlyCost = $scope.monthlyCost.replace(/\.00$/, '');

      $scope.disableButton = state.isCurrent && !state.isTrial;
      $scope.showButton = !state.noButton;

      if (!ctrl._buttonText) {
        $scope.buttonText = getButtonText();
        ctrl.buttonText = angular.copy($scope.buttonText);
      } else {
        ctrl.buttonText = $scope.buttonText = ctrl._buttonText;
      }
    }

    function getButtonText() {
      if (state.isTrial) {
        return 'Select ' + state.name;
      }

      if (!state.isDowngrade) {
        return 'Upgrade to ' + state.name;
      }

      if (state.isCurrent) {
        return 'Current Plan';
      }

      return 'Select ' + state.name;
    }

    //
    // Evaluate a string in the context of the parent $scope, and put the
    // resulting value into ctrl.state.
    //
    function _eval (name) {
      state[name] = $scope[name] ? $scope.$parent.$eval($scope[name]) : false;
    }

    function triggerAction ($event) {
      if (angular.isDefined($scope.action)) {
        $scope.action({'$event': $event});
      } else {
        $log.error('Button clicked, but no action is defined!');
      }
    }
  }
}());

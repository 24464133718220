(function () {
    'use strict';
    angular.module('sowOrders', [])
    .config(sowOrdersConfig);

    function sowOrdersConfig ($stateProvider, $urlRouterProvider) {
      // var defaultUrl = '/app/mkt/landing/';
      // $urlRouterProvider.when('/app/mkt/', defaultUrl);
      // $urlRouterProvider.when('/app/mkt', defaultUrl);

      $stateProvider
      .state('app.orders.sowingo', {
        url: '/sowingo/',
        controller: 'OrdersListController',
        controllerAs: 'ordlsCtrl',
        templateUrl: 'sow-orders/views/orders-mkt-list.html',
        data: {
          'title': 'Marketplace Orders',
          // 'requires_feature': 'marketplace',
        }
      })
      .state('app.orders.awaiting_shipment', {
        url: '/awaiting-shipment/',
        controller: 'OrdersListController',
        controllerAs: 'ordlsCtrl',
        templateUrl: 'sow-orders/views/pending-shipments.html',
        data: {
          title: 'Awaiting Shipment',
        },
      })
      .state('app.orders.detail', {
        url: '/detail/:hrid',
        controller: 'OrdersDetailController',
        controllerAs: 'orddtCtrl',
        templateUrl: 'sow-orders/views/orders-mkt-detail.html',
        data: {
          'title': 'Order',
          // 'requires_feature': 'marketplace',
        },
        params: {
          'order_data': null
        }
      })
      ;
    }

}());

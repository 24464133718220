(function () {
  'use strict';

  angular.module('sowMarketplace')
  .controller('marketplacePromoInfoController', marketplacePromoInfoController)
  .directive('mktPromoInfo', marketplacePromoInfoDirective);

  function marketplacePromoInfoDirective () {
    return {
      restrict: 'E',
      templateUrl: 'sow-mkt/directives/mkt-promo-info.html',
      controller: 'marketplacePromoInfoController',
      controllerAs: 'mktpriCtrl',
      scope: {
        'item': '=',
      },
    };
  }

  function marketplacePromoInfoController ($scope, mktHelperService) {
    /*jshint validthis:true*/
    var ctrl = this;

    ctrl.isOnSale = isOnSale;
    ctrl.getTotalQty = getTotalQty;

    init();

    return ctrl;

    function init () {
      setItemPromo($scope.item);
      isOnSale($scope.item);
    }

    function isOnSale (item) {
      item = item || $scope.item;
      var is_on_sale = !_.isNil(item.UI.promo);
      _.set(item, 'UI.on_sale', is_on_sale);
      return is_on_sale;
    }

    function setItemPromo (item) {
      item = item || $scope.item;
      var promo = _.isNil(_.get(item, 'vendor_inventory_promotion.id')) ? null : item.vendor_inventory_promotion;
      if(!promo) {
        promo = _.isNil(_.get(item, 'promotions[0].id')) ? null : item.promotions[0];
      }
      _.set(item,'UI.promo', promo);
      _.set(item, 'UI.promo_count', _.size(item.promotions));

      _.each(item.promotions, function(promo){
        var promo_type = _.get(promo, 'promotion_type', null);
        _.set(item, 'UI.is_manufacturer_promo', promo_type && promo_type.includes('MANUFACTURER'), false);
        const localized_notes = mktHelperService.getLocalizedPromoNotes(promo);
        _.set(item, 'UI.promo_notes', localized_notes);
      });
  
      return promo;
    }

    function getTotalQty (item) {
      item = item || $scope.item;
      var total_qty = 0;
      var item_promo = item.UI.promo;
      var qty = _.toNumber(item.quantity || 0);
      var buy = _.toNumber(_.get(item_promo, 'promotion_properties.buy', null));
      var promo_active = item_promo && item_promo.id && item_promo.promotion_type === 'BUY_GET' && qty >= buy;
      if (promo_active) {
        var free_qty = (Math.floor(qty / buy) * _.toNumber(item_promo.promotion_properties.get));
        total_qty = free_qty + qty;
      } else {
        total_qty = _.toNumber(qty);
      }
      return total_qty;
    }
    
  }

})();

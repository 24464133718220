//
// This adds the 'force-scrollbar' class to the correct element on the
// md-virtual-repeat-container that md-autocomplete uses for the suggestions
// menu.
//
// This is super hackey, but there is not "real" way to do this in pure CSS or
// via a nice API from Angular Material.
//
// Explanation:
//
//    md-autocomplete creates a <ul> with an id based on the controller's id,
//    which is sticks in a md-virtual-repeat-container. Unfortunately, the
//    virtual-repeat is moved to the top of the DOM, making it impossible to
//    (e.g.) add a class on md-autocomplete element to easily select into the
//    associated virtual-repeat element. We end up having to use Javascript to
//    find the appropriate <ul> element in the DOM, and walk up the tree to the
//    appropriate virtual-repeat element for adding the scrollbar.
//
angular.module('app.shared.support')
  .directive('sgAcForceScrollbar', function ($timeout) {
    return {
      require: '^mdAutocomplete',
      link: function ($scope, $element, $attributes, $mdAutocompleteCtrl) {
        $timeout(function () {
          var suggestionListId = 'ul-' + $mdAutocompleteCtrl.id;
          var suggestionListElement = document.getElementById(suggestionListId);

          if (suggestionListElement) {
            angular.element(suggestionListElement).parent().parent().addClass('force-scrollbar');
          }
        }, 1000);
      },
    };
  });

angular.module("app.marketplace.elements")
.service("exchangeModel", function(apiUrl, $filter) {
  var _this = this;
  this.model_data = {
    "name" : "exchange",
    "display_name" : "Exchange",
    "api" : {
      "by_hrid" : function(options){ 
        if(!checkNested(options,'data','id')){
          throw "Missing option(s) for exchange model path 'single'. Options: " + JSON.stringify(options);
        }
        return apiUrl + "/exchanges/" + options.data.id;
      },
      "multiple" : function(options){
        return apiUrl + "/me/exchanges";
      }

    }

  };

  this.autofill = function(exchange_order) {
    if(exchange_order.created_at){
      exchange_order.created_at = new Date(exchange_order.created_at);
    }
    if(exchange_order.refund_date){
      exchange_order.refund_date = new Date(exchange_order.refund_date);
    }
    
    if(!exchange_order.vendor_decision){
      exchange_order.status = "Pending";
    }else if(exchange_order.vendor_approved){
      exchange_order.status = "Approved";
    }else{
      exchange_order.status = "Denied";
    }
    
    //Reorganizing dates for easier sorting
    exchange_order.sort_date = exchange_order.refund_date || exchange_order.created_at;
    
    exchange_order.name = exchange_order.status + " Exchange, " + (exchange_order.created_at ? $filter('date')(exchange_order.created_at, "MMM d, yyyy") : null);
  };
  
  this.fields = {
    "id" : {
      "display_name" : "id",
      "required" : true,
      "api" : {
        "submit" : true
      },
      "type" : "integer"
    }
  };

  this.data = {
    "params" : {
      "model_name" : "exchange"
    }
  };

});
(function () {
  'use strict';

  angular.module('sowMarketplace')
    .controller('marketplaceCardController', marketplaceCardController);

  function marketplaceCardController($scope, $window, sowMktService, mktHelperService, sowLanguageService, AccessService, sowAnalyticsService) {
    const ctrl = this;

    ctrl.toggleFavouriteStatus = toggleFavouriteStatus;
    ctrl.handleCardClick = handleCardClick;
    ctrl.getBadgeText = getBadgeText;
    ctrl.getProductImage = getProductImage;
    ctrl.getFavouriteIcon = getFavouriteIcon;

    ctrl.$onInit = init;

    return ctrl;

    function init() {
      defineLocks();
      ctrl.language = sowLanguageService.getCurrentLanguage();
      ctrl.product = mktHelperService.setProductInfo($scope.product);
      ctrl.button = _getCardButton();
      ctrl.should_show_prices = _shouldShowPrices();
    }

    /**
     * Opens the add to cart modal for the product
     * @param {object} $event
     * @param {object} product
     */
    function _openAddToCartDialog($event, product = ctrl.product) {
      if ($event) $event.stopPropagation();
      return mktHelperService.addToCartQuantityDialog($event, product, _.first(product.sorted_vendors));
    }

    /**
     * Toggles the favourite status of the product
     * @param {object} $event
     * @param {object} product
     */
    function toggleFavouriteStatus($event, product = ctrl.product) {
      if ($event) $event.stopPropagation();
      return sowMktService.toggleFavourite({...product, index: _getRank()});
    }

    /**
     * Returns the heart icon which reflects the product's status
     * @param {object} product
     */
    function getFavouriteIcon(product = ctrl.product) {
      return product.is_favourite_product ? 'favorite' : 'favorite_border';
    }

    /**
     * Shows the product in a new tab if it's external, and
     * navigates to the product details page if not
     * @param {object} $event
     * @param {object} product
     */
    function handleCardClick($event, product = ctrl.product) {
      logCardClick(product);
      
      if (product.UI.is_external) {
        $window.open(product.product_type.external_link, '_blank');
      } else {
        const extra_log_props = {
          product: {
            index: _getRank(),
          },
          params: {
            item_list_name: $scope.listName,
          }
        };
        mktHelperService.goToProductDetailsPage(product, $event, extra_log_props);
      }
    }

    /**
     * Returns the button to show, or null if no button should be displayed at all
     * @return {object | null}
     */
    function _getCardButton() {
      if (!ctrl.has_cart_access) {
        return null;
      }
      return _generateButton();
    }

    /**
     * Returns the button which corresponds to the product
     * @param {object} product
     * @return {object}
     */
    function _generateButton(product = ctrl.product) {
      const basic_button = {
        is_disabled: false,
        class_list: 'sow-primary-btn',
      };

      const { can_checkout, is_sold_out, is_discontinued, is_no_price_product } = product.UI;
      
      // If product is unavailable, show disabled button with status text
      if (is_sold_out || is_discontinued) {
        const status_key = is_sold_out ? 'SOLD_OUT' : 'DISCONTINUED';
        return {
          ...basic_button,
          is_disabled: true,
          locale_key: `MARKETPLACE.DETAIL.${status_key}`,
        };
      }

      // If item can be added to cart using modal, show add to cart button which opens modal
      if (can_checkout && !is_no_price_product) {
        return {
          ...basic_button,
          handleClick: _openAddToCartDialog,
          locale_key: 'ACTIONS.ADD_TO_CART',
        };
      }

      // Otherwise, show view details button (clicks propagate to card click handler)
      return {
        ...basic_button,
        class_list: 'sow-secondary-btn',
        locale_key: 'ACTIONS.VIEW_DETAILS',
      };
    }

    /**
     * Determines whether the prices section should be displayed
     * @param {object} product
     * @return {boolean}
     */
    function _shouldShowPrices(product = ctrl.product) {
      const {is_external, is_no_price_product} = product.UI;
      return !is_external && !is_no_price_product;
    }

    /**
     * Returns the text of a badge in the selected language
     * @param {object} badge
     * @param {string} language
     * @return {string}
     */
    function getBadgeText(badge, language = ctrl.language) {
      return _.get(badge, `badge_properties.${language}.title`);
    }

    /**
     * Gets the image of the product to display in the card
     * @param {object} $event
     * @param {object} product
     */
    function getProductImage(product = ctrl.product) {
      return _.get(product, 'images[0].image');
    }

    function defineLocks () {
      ctrl.has_cart_access = AccessService.hasCartAccess();
    }

    /**
     * It logs a click event for a product card on any given page/list,
     * sending the page number, rank, list name and product details
     * @param product - the product object
     */
    function logCardClick (product) {
      const vi = product.vendor_inventory[0];
      const promo_type = _.get(vi, 'promotions[0].promotion_type', undefined);
      // API inconsistency
      const vendor_name = vi.vendor.vendor_name || vi.vendor.name;
      const price = vi.price || product.price;

      const payload = {
        brand: product.manufacturer?.name,
        coupon: promo_type,
        item_list_name: $scope.listName,
        page_num: $scope.pageNumber,
        price,
        product_id: product.id,
        product_name: product.name,
        rank: _getRank(),
        vendor: vendor_name,
      };

      sowAnalyticsService.logSelectListItem(product, payload);
    }

    /**
     * Gets the rank/index of the selected product in the current page
     * @return {number}
     */
    function _getRank () {
      const PAGE_SIZE = 30;
      let rank = $scope.rank || 0;
      const page_number_zero_indexed = _.toInteger($scope.pageNumber) - 1;
      if (!_.isNil($scope.pageNumber)) {
        rank += ( page_number_zero_indexed * PAGE_SIZE);
      }

      return rank;
    }
  }

})();

(function () {
  'use strict';
  angular.module('app.marketplace.ui.office')
    .controller('officeRepModalController', officeRepModalController);

  function officeRepModalController($scope, $mdDialog) {
    /*jshint validthis: true*/
    var ctrl = this;

    ctrl.close = close;
    ctrl.submit = submitForm;

    return;

    function close() {
      $mdDialog.cancel();
    }

    function submitForm() {
      ctrl.repForm.$setSubmitted();
      if (ctrl.repForm.$error) {
        _.forEach(Object.keys(ctrl.repForm.$error), function (key) {
          _.forEach(ctrl.repForm.$error[key], function (form_input) {
            form_input.$setDirty();
            form_input.$setTouched();
          });
        });
      }
      if (ctrl.repForm.$valid) {
        ctrl.submittingForm = true;

        $mdDialog.hide(ctrl.sales_rep);
      }
    }
  }

}());

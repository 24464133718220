(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.office')
    .service('SubscriptionPromoCodeDialog', SubscriptionPromoCodeDialog);

  function SubscriptionPromoCodeDialog ($mdDialog) {
    /*jshint validthis: true */
    var service = this;

    service.show = showDialog;

    return service;

    //
    // Show the Dialog
    //
    function showDialog ($event) {
      return $mdDialog.show({
        'templateUrl': 'templates/marketplace/office/subscription-promo-code-dialog.html',
        'controller': SubscriptionPromoCodeDialogCtrl,
        'controllerAs': '$dialogCtrl',
        'targetEvent': $event,
        'escapeToClose': true,
        'parent': angular.element(document.body),
        'fullscreen': true,
      });
    }
  }

  function SubscriptionPromoCodeDialogCtrl ($scope, $mdDialog, officeService, errorService, subscriptionService) {
    /*jshint validthis: true */
    var ctrl = this;
    var inputContainer = null;
    var ngMessages = null;

    $scope.promoCode = null;

    ctrl.errors = {};
    ctrl.close = closeDialog;
    ctrl.addDiscount = addDiscount;
    ctrl.promoCodeChanged = handleFieldUpdate;

    init();

    return ctrl;

    //
    // Initialize the controller
    //
    function init () {
      updateODAMessageFlag();
    }

    //
    // Update the ODA message flag
    //
    function updateODAMessageFlag () {
      $scope.showODAMessage = officeService.isODAMember();
    }

    //
    // Reset all errors.
    //
    function resetErrors () {
      _.map(ctrl.errors, function(error){
        error = false;
      })
      showErrors(false);
    }

    //
    // Back out of the dialog action
    //
    function closeDialog () {
      return $mdDialog.cancel();
    }

    //
    // Mark the <input> as invalid based on the truthy or falsey-ness of the
    // value passed in.
    //
    function showErrors (value) {
      var methodName;

      inputContainer = inputContainer || $('md-dialog md-input-container');
      methodName = value ? 'addClass' : 'removeClass';
      inputContainer[methodName]('md-input-invalid');

      ngMessages = ngMessages || $('md-dialog ng-messages');
      methodName = value ? 'removeClass' : 'addClass';
      ngMessages[methodName]('md-auto-hide');
    }

    //
    // Handle changes to the promo code field.
    //
    function handleFieldUpdate () {
      resetErrors();
    }

    //
    // Add the Discount / Promotion to the Upgrade
    //
    function addDiscount () {
      $scope.saving = true;

      resetErrors();

      if (!$scope.promoCode && $scope.promoCode !== 0) {
        ctrl.errors.required = true;
        showErrors(true);
        $scope.saving = false;
        return;
      }

      return subscriptionService.getPromotions({'code': $scope.promoCode}).then(function (promotions) {
        $scope.saving = false;

        if (!promotions || promotions.length > 1 || promotions.length < 1) {
          ctrl.errors.invalid_promotion = true;
          showErrors(true);
          return;
        }

        var promotion = promotions[0];

        if (!promotion.discount_type) {
          ctrl.errors.trial_promotion = true;
          showErrors(true);
          return;
        }

        var office = officeService.get();
        var professional_association_id = _.get(office, 'association_membership.professional_association_id');

        var office_has_association = !!professional_association_id;
        var promo_has_association = !!promotion.professional_association_id;
        var associations_dont_match = promotion.professional_association_id !== professional_association_id;

        if (office_has_association && promo_has_association && associations_dont_match) {
          ctrl.errors.invalid_promotion = true;
          showErrors(true);
          return;
        }

        if (promo_has_association && !office_has_association) {
          ctrl.errors.requires_membership = true;
          showErrors(true);
          return;
        }

        return $mdDialog.hide(promotion);
      }).catch(function (error) {
        errorService.uiErrorHandler(error);
        $scope.saving = true;
      });
    }
  }

}());

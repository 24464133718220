angular.module('app.marketplace.ui.cart', [])

  .config(function($stateProvider, $urlRouterProvider) {
    $stateProvider.state('app.cart', {
      url: '/cart/checkout',
      controller : 'checkoutController',
      templateUrl : 'templates/marketplace/cart/views/checkout.html',
      data: {
        'title': 'Checkout',
        'require_log_in' : true,
        'action_bar_directive': 'action-bar-blank',
      }
    });
  })

;

// Web server api service. Single point for api calls.
angular.module("app.shared.system.api").service("elasticUtils", [ "$http", function($http) {
 
  //Gets hits from response and creates array using _source field
  var _convertResponse = function(response) {
    var convertedHits = [];
    
    _.map(response.data.hits.hits, function(item){
      convertedHits.push(item._source);
    });
    
    return convertedHits;
  };

  return {
    convertResponse : _convertResponse
  };
} ]);

(function () {
  'use strict';

  angular.module('sowSds')
  .controller('SdsEditController', SdsEditController);

  function SdsEditController ($filter, $mdDialog, $window, $scope, $rootScope, $timeout, $q, errorService, sgToast, sowSdsService) {
    /*jshint validthis: true */
    var ctrl = this;

    ctrl.FILES_LIMIT = 3;
    ctrl.FILE_SIZE_LIMIT = 50000000;
    ctrl.doc_files = [];
    ctrl.display_file_warning = false;
    ctrl.object_urls = [];
    ctrl.pending_removals = [];

    ctrl.modes = {
      'edit': {
        'dialog_title': 'SDS.EDIT',
        'ok_btn_label': 'ACTIONS.SAVE',
        'showForm': false,
      },
      'add': {
        'dialog_title': 'SDS.ADD_NEW',
        'ok_btn_label': 'ACTIONS.ADD',
        'showForm': true,
      },
      'attach': {
        'dialog_title': 'SDS.ATTACH_SHEET',
        'ok_btn_label': 'ACTIONS.SAVE',
        'showForm': false,
      },
    };

    ctrl.cancel = cancelDialog;
    ctrl.saveRecord = saveRecord;
    ctrl.deleteRecord = deleteRecord;

    $scope.handleNewFile = ctrl.handleNewFile = handleNewFile; 
    $scope.allowDrop = ctrl.allowDrop = allowDrop;
    $scope.handleFileDrop = ctrl.handleFileDrop = handleFileDrop;
    ctrl.handleRemoveClick = handleRemoveClick;
    ctrl.removeFile = removeFile;
    ctrl.triggerFileInput = triggerFileInput;

    init();

    return ctrl;

    function init () {
      ctrl.sds_doc = ctrl.sds_doc || {};
      _.extend(ctrl, ctrl.modes[ctrl.mode]);
      if(ctrl.mode === 'edit' && ctrl.sds_doc.item_type === 'Other'){
        ctrl.showForm = true;
      }

      if (ctrl.sds_doc.id && ctrl.sds_doc.document_path) {
        generateDocfiles();
      }

      $scope.$on('$destroy', function(){
        _.forEach(ctrl.object_urls, $window.URL.revokeObjectURL);
      });
    }

    function handleNewFile (file) {
      ctrl.display_file_warning = false;
      if(!file){
        return;
      }
      if(file.size > ctrl.FILE_SIZE_LIMIT){
        ctrl.display_file_warning = true;
        $timeout(function(){
          $scope.$apply();
        }, 300);
        return;
      }

      var docfile = {
        'binary': file,
        'name': file.name,
        'size': file.size,
        'display_size': getDisplayFileSize(file.size),
        'warning': (file.size > ctrl.FILE_SIZE_LIMIT),
      };

      if (file.type.startsWith('image/')) {
        var thumbnail_url = $window.URL.createObjectURL(file);
        ctrl.object_urls.push(thumbnail_url);

        _.set(docfile, 'preview', 'image');
        _.set(docfile, 'thumbnail_src', thumbnail_url);
      } else {
        _.set(docfile, 'preview', 'icon');
      }

      ctrl.doc_files.push(docfile);

      $timeout(function(){
        $scope.$apply();
        uploadFile(docfile);
      }, 700);
    }

    function handleFileDrop (event) {
      event.stopPropagation();
      event.preventDefault();
  
      var dt = event.dataTransfer;
      var files = dt.files;

      handleNewFile(files[0]);
    }

    function allowDrop ($event) {
      $event.preventDefault();
    }

    function handleRemoveClick(file) {
      ctrl.pending_removals.push(file.path);
    }

    function removeFile (file) {
      var path_list = _.get(ctrl.sds_doc, 'document_path') || [];
      var file_pos = path_list.indexOf(file.path);
      if ( ctrl.sds_doc.id && file.path && (file_pos >= 0) ) {
        // hit API so it's moved to _deleted
        sowSdsService.pathRemove(ctrl.sds_doc, file_pos)
        .then(function(log_response){
          $rootScope.$broadcast('sds-item-updated', log_response);
          _.remove(ctrl.doc_files, file);
          ctrl.sds_doc = log_response;
          generateDocfiles();
        })
        .catch(function(e){
          var t_message = $filter('translate')('ERRORS.FILE_REMOVE')
          errorService.uiErrorHandler(t_message);
        });
        
      } else {
        // actually remove from S3, show toast
        sowSdsService.fileRemove(file.path)
        .then(function(){
          _.remove(ctrl.doc_files, file);
          var t_message = $filter('translate')('TOAST.FILE_REMOVED');
          sgToast.showSimple(t_message);
        })
        .catch(function(err){
          console.error(err);
        });
      }

      // _.remove(ctrl.doc_files, file);
      
      $timeout(function(){
        $scope.$apply();
      }, 700);
    }

    function uploadFile (docfile) {
      if( !docfile.binary ){
        return;
      }

      if(docfile.size < ctrl.FILE_SIZE_LIMIT){
        ctrl.uploading = true;
        return $q.when(
          sowSdsService.docUpload(docfile, ctrl.certification),
          function(s3_response){
            ctrl.uploading = false;
            _.set(docfile, 'path', s3_response.doc_path);
            _.set(docfile, 'progress', '100%');
          },
          function(error){
            var t_message = $filter('translate')('ERRORS.DOCUMENT_UPLOAD')
            errorService.uiErrorHandler(t_message);
          },
          function(event){
          }
          );
      } else {
        var t_message = $filter('translate')('ERRORS.FILE_TOO_LARGE')
        errorService.uiErrorHandler(t_message);
      }
    }

    function generateDocfiles () {
      ctrl.doc_files = [];
      _.each(ctrl.sds_doc.document_path, function(docpath){
        if(docpath){
          ctrl.doc_files.push({
            'preview': 'icon',
            'path': docpath,
            'name': getUrlFilename(docpath),
          });
        }
      });
    }

    function generateDocPaths () {
      ctrl.sds_doc.document_path = ctrl.sds_doc.document_path || [];
      _.each(ctrl.doc_files, function(docfile){
        ctrl.sds_doc.document_path = _.union(ctrl.sds_doc.document_path, [docfile.path]);
      });
    }

    function cancelDialog () {
      return $mdDialog.cancel();
    }

    function saveRecord () {
      // TO-DO: checkRequiredFields
      // prepare doc_paths and return to list controller to actually save
      for (const path of ctrl.pending_removals) {
        const file = ctrl.doc_files.find(file => file.path === path);
        removeFile(file);
      }
      generateDocPaths();
      $mdDialog.hide(ctrl.sds_doc);
    }

    function deleteRecord () {
      _.set(ctrl.sds_doc, 'is_deleted', true);
      $mdDialog.hide(ctrl.sds_doc);
    }

    function triggerFileInput () {
      var input = angular.element('#file-input');
      // doing it immediately caused an infinite digest error
      $timeout(function() {
        input.click();
      }, 300);
      
    }

    function getUrlFilename (url) {
      return _.last(_.split(url, '/'));
    }

    // found this on SO =)
    function getDisplayFileSize(bytes) {
      var thresh = 1000;
      if(Math.abs(bytes) < thresh) {
          return bytes + ' B';
      }
      var units = ['kB','MB','GB','TB','PB','EB','ZB','YB'];
      var u = -1;
      do {
          bytes /= thresh;
          ++u;
      } while(Math.abs(bytes) >= thresh && u < units.length - 1);
      return bytes.toFixed(1)+' '+units[u];
    }
  }

}());

(function () {
  'use strict';

  angular
  .module('sowCertifications')
  .controller('certificationsSectionController', certSectionController);

  function certSectionController ($scope, $state, $rootScope, sowBrowserFlags, gettingStartedDialog, certificationDataCarrierService) {
    /*jshint validthis: true */
    var ctrl = this;

    ctrl.FEATURE_DATA = {
      'section': {
        'name': 'MAIN_MENU.CERTIFICATION',
        'icon': {'material': 'verified_user', 'css': 'green',},
        
      },
      'plan': {
        'label': 'PAYWALL.PREMIUM_FEATURE',
        'css': 'premium',
      },
      'selling_points': [
        {
          'header': 'CERTIFICATIONS.PW_HEADER_ONE',
          'text':'CERTIFICATIONS.PW_TEXT_ONE',
          'img': "styles/img/paywalls/1-glasses-green"
        },
        {
          'header': 'CERTIFICATIONS.PW_HEADER_TWO',
          'text':'CERTIFICATIONS.PW_TEXT_TWO',
          'img': "styles/img/paywalls/2-add"
        },
        {
          'header': 'CERTIFICATIONS.PW_HEADER_THREE',
          'text':'CERTIFICATIONS.PW_TEXT_THREE',
          'img': "styles/img/paywalls/3-clock-red"
        }
      ]
    };

    ctrl.isLoading = isLoading;
    ctrl.doneLoading = doneLoading;
    ctrl.certificationDataCarrierService = certificationDataCarrierService;

    init();

    return ctrl;

    function init () {
      if (shouldRedirect($state)) {
        $state.go('app.certifications.list', {type: 'All'});
        return;
      }
    }

    function shouldRedirect($state) {
      var params = $state.params || {};
      var isParentState = $state.current.name === 'app.certifications.list';

      return isParentState && !params.type;
    }

    function isLoading () {
      ctrl.monState.isLoading = true;
    }

    function doneLoading () {
      ctrl.monState.isLoading = false;
    }
  }
}());

angular.module('app.marketplace.ui.office', [
  'app.marketplace.ui.subscriptions',
  'app.marketplace.ui.medical_field_selector',
  'sowSubscriptionBilling',
])

  .config(function($stateProvider, $urlRouterProvider) {
    $urlRouterProvider.when('/app/office', '/app/dashboard');
    $urlRouterProvider.when('/app/office/profile/subscription', '/app/office/profile/subscription/');

    $stateProvider.state('app.office.create', {
      'url': '/create',
      'title': 'Create a New Office',
      'controller': 'CreateOfficeCtrl',
      'controllerAs': '$ctrl',
      'templateUrl': 'templates/marketplace/registration/views/create-office.html',
      'data': {
        'action_bar_directive': undefined,
        'hideSidenav': true,
      }
    });

    $stateProvider
      .state('app.office', {
        url: '/office',
        controller: 'officeController',
        templateUrl: 'templates/marketplace/office/views/office.html',
        data: {
          'title': 'Office',
          'action_bar_directive': 'action-bar-office',
          'require_log_in' : true
          // 'authorization_required': true,
        }
      })

      //
      // Office Profile States
      //
      .state('app.office.profile', {
        url: '/profile',
        templateUrl: 'templates/marketplace/office/views/profile.html',
        data: {
          action_bar_directive: 'action-bar-office-profile',
          title: 'Office Profile'
        },
        resolve: {
          office: function(errorService, elementService, officeService){
            return officeService.get(true)
            .then(function(office){
              return office;
            });
          }
        }
      })
      .state('app.office.profile.settings', {
        data: {
          action_bar_directive: undefined,
          require_log_in : true,
          title: 'Office Settings',
        },
        template: '<office-profile-app params="{{$stateParams}}"></office-profile-app>',
        url: '/settings',
      })
      .state('app.office.profile.users', {
        url: '/users?new_type',
        controller: 'officeUsersController',
        templateUrl: 'templates/marketplace/office/views/users.html',
        data: {
          'title': 'Office Staff'
        },
        resolve: {
          users: function($filter, errorService, elementService, officeService){
            return officeService.get(true)
            .then(function(office){
              return elementService.get("account", null, {"endpoint" : "office_users", "office" : office})
              .then(function(users){
                return users;
              }).catch(function(error){
                var t_message = $filter('translate')('ERRORS.USER_RETRIEVE')
                errorService.uiErrorHandler(error || t_message);
              });
            });
          },
          membershipRequests: function($filter, errorService, officeService){
            return officeService.get(true)
            .then(function(office){
              return officeService.getMembershipRequests()
              .then(function(membershipRequests){
                return membershipRequests;
              }).catch(function(error){
                var t_message = $filter('translate')('ERRORS.MEMBERSHIP_REQUESTS_RETRIEVE')
                errorService.uiErrorHandler(error || t_message);
              });
            });
          },
          membershipInvites: function($filter, errorService, officeService){
            return officeService.get(true)
            .then(function(office){
              return officeService.getPendingInvitations()
              .then(function(membershipInvites){
                return membershipInvites;
              }).catch(function(error){
                var t_message = $filter('translate')('ERRORS.MEMBERSHIP_REQUESTS_RETRIEVE')
                errorService.uiErrorHandler(error || t_message);
              });
            });
          }
        }
      })
      .state('app.office.profile.reps', {
        url: '/reps',
        controller: 'officeRepsController',
        controllerAs: 'offRepCtrl',
        templateUrl: 'templates/marketplace/office/views/reps.html',
        data: {
          'title': 'Office Sales Reps'
        },
      })
      .state('app.office.profile.suppliers', {
        url: '/suppliers',
        controller: 'dashboardSuppliersController',
        controllerAs: 'dsupCtrl',
        templateUrl: 'templates/marketplace/dashboard/dashboard-suppliers.html',
        data: {
          'title': 'Suppliers',
          'require_log_in' : true,
          // 'action_bar_directive': 'dashboard-action-bar',
          // 'requires_feature': 'dashboard',
        },
      })
      .state('app.office.profile.add-supplier', {
        controller: function($filter, $scope, sgToast) {
          $scope.handleEvent = ({detail: {action}}) => {
            if (action === 'created') {
              sgToast.showSimple($filter('translate')('PO.SUPPLIER_CREATED'));
            }
          };
        },
        data: {require_log_in: true, title: 'Add Supplier'},
        template: '<supplier-app ng-on-event="handleEvent($event)" params="{{$stateParams}}" type="page"></supplier-app>',
        url: '/add-supplier',
      })
      .state('app.office.profile.edit-supplier', {
        controller: function($filter, $scope, sgToast) {
          $scope.handleEvent = ({detail: {action}}) => {
            if (action === 'deleted') {
              sgToast.showSimple($filter('translate')('SUPPLIER.SUPPLIER_DELETED'));
            }
            if (action === 'updated') {
              sgToast.showSimple($filter('translate')('PO.SUPPLIER_UPDATED'));
            }
          };
        },
        data: {require_log_in: true, title: 'Edit Supplier'},
        template: '<supplier-app ng-on-event="handleEvent($event)" supplier-id="{{$stateParams.id}}" type="page"></supplier-app>',
        url: '/edit-supplier/:id',
      })
      .state('app.office.profile.connected_suppliers', {
        url: '/connected-suppliers',
        controller: 'externalVendorsSectionCtrl',
        controllerAs: 'eVCtrl',
        template: `
          <external-vendors-app
            external-vendors="{{eVCtrl.external_vendors}}" ng-on-external-vendors-app-event="eVCtrl.handleExternalVendorsAppEvent($event)">
          </external-vendors-app>
        `,
        data: {
          title: 'Connected Suppliers',
          require_log_in : true,
        },
      })
      .state('app.office.profile.locations', {
        url: '/locations',
        controller: 'dashboardLocationsController',
        controllerAs: 'dlocCtrl',
        templateUrl: 'templates/marketplace/dashboard/dashboard-locations.html',
        data: {
          'title': 'Locations',
          'require_log_in' : true,
          // 'action_bar_directive': 'dashboard-action-bar',
          // 'requires_feature': 'dashboard',
        },
      })

      // Office Subscriptions: Email Subscriptions
      .state('app.office.profile.email_notifications', {
        url: '/email_notifications',
        template: '<office-email-notifications-page params={{$stateParams}}></office-email-notifications-page>',
        data: {
          'title': 'Email Notifications',
          'require_log_in' : true,
        },
      })

      //
      // Office Subscriptions: Master State
      //
      .state('app.office.profile.subscription', {
        url: '/subscription',
        controller: 'OfficeSubscriptionsController',
        template: '<ui-view></ui-view>',
        data: {
          'title': 'Office Subscription',
          'contractSidenav': true,
        }
      })

      //
      // Office Subscriptions: View Subscription State
      //
      .state('app.office.profile.subscription.view', {
        url: '/',
        template: '<upgrade-plan-page params={{$stateParams}}></upgrade-plan-page>',
      })

      //
      // Office Subscriptions: Downgrade
      //
      .state('app.office.profile.subscription.downgrade', {
        'url': '/plan-change/:plan',  // Call this 'plan change' rather than "downgrade" when user-facing.
        'controller': 'OfficeSubscriptionDowngradeCtrl',
        'controllerAs': '$ctrl',
        'templateUrl': 'templates/marketplace/office/downgrade.html',
        'params': {
          'billing_cycle': 'per_year',
        },
        'data': {
          'title': 'Give Us Your Feedback!',
          'hideHeader': true,
          'hideFooter': true,
          'hideSidenav': true,
          'action_bar_directive': null,
        },
      })

      //
      // Office Subscriptions: Upgrade Subscription State
      //
      .state('app.office.profile.subscription.upgrade', {
        url: '/upgrade/:plan',
        controller: 'OfficeSubscriptionUpgradeCtrl',
        controllerAs: '$ctrl',
        templateUrl: 'templates/marketplace/office/upgrade.html',
        params: {
          'billing_cycle': null,
          'yearly_only': false,
          'from_click': false,
          'promotion': null,
        },
        data: {
          'title': 'Upgrade Subscription',
          'hideHeader': true,
          'hideFooter': true,
          'hideSidenav': true,
          'action_bar_directive': null,
        }
      })

      .state('app.office.profile.subscription.upgrade_complete', {
        url: '/upgrade-complete',
        templateUrl: 'templates/marketplace/office/upgrade-complete.html',
        data: {
          'title': 'Upgrade Complete',
          'hideHeader': true,
          'hideFooter': true,
          'hideSidenav': true,
          'action_bar_directive': null,
        }
      })

      .state('app.office.profile.subscription_billing', {
        'url': '/subscription_billing',
        'controller': 'sowSubscriptionBillingController',
        'controllerAs': 'sbCtrl',
        'templateUrl': 'sow-subscription-billing/sow-subscription-billing.html',
        'data': {
          'title': 'Subscription Billing History'
        },
        resolve: {
          current_subscription: function(errorService, $filter, subscriptionService){
            return subscriptionService.currentSubscription().then(function (current_subscription) {
              return current_subscription;
            }).catch(function (error) {
              var t_message = $filter('translate')('ERRORS.CURRENT_SUB_RETRIEVE')
              errorService.uiErrorHandler(error || t_message);
            });
          },
          receipts: function($filter, errorService, elementService, officeService){
            return officeService.get(true).then(function (office) {
              return elementService.getElements('subscriptionReceipt').then(function (receipts) {
                var receiptsArray = $.map(receipts, function (value, index) { return [value]; });
                return $filter('orderBy')(receiptsArray, '-created_at');
              }).catch(function (error) {
                var t_message = $filter('translate')('ERRORS.SUB_RECEIPTS_LOAD')
                errorService.uiErrorHandler(error || t_message, 0);
                return false;
              });
            }).catch(function (error) {
              var t_message = $filter('translate')('ERRORS.USER_RETRIEVE')
              errorService.uiErrorHandler(error || t_message);
            });
          },
          failed_payment: function(errorService, subscriptionService, $filter){
            return subscriptionService.failedPayments().then(function (failed_payments) {
              var filteredFailures = $filter('filter')(failed_payments, {is_resolved: false});
              return filteredFailures.length ? filteredFailures[0] : null;
            }).catch(function (error) {
              var t_message = $filter('translate')('ERRORS.PAYMENTS_RETRIEVE')
              errorService.uiErrorHandler(error || t_message);
            });
          },
          credit_cards: function(errorService, $filter, creditCardService){
            return creditCardService.getCards().then(function (credit_cards) {
              return credit_cards;
            }).catch(function (error) {
              var t_message = $filter('translate')('ERRORS.CREDIT_CARD_RETRIEVE')
              errorService.uiErrorHandler(error || t_message);
            });
          }
        }
      })
      .state('app.office.profile.subscription_receipt', {
        url: '/receipt/:receiptId',
        controller: 'subscriptionReceiptController',
        templateUrl: 'templates/marketplace/office/views/subscription-receipt.html',
        data: {
          'title': 'Subscription Receipt',
          'hideHeader': true,
          'hideFooter': true,
          'hideSidenav': true,
          'action_bar_directive': null,
        },
        resolve: {
          receipt: function($stateParams, $filter, errorService, elementService, officeService){
            return officeService.get(true)
            .then(function(){
              return elementService.get('subscriptionReceipt', $stateParams.receiptId).then(function (receipt) {
                return receipt;
              }).catch(function (error) {
                var t_message = $filter('translate')('ERRORS.RECEIPT_RETRIEVE')
                errorService.uiErrorHandler(error || t_message);
              });
            });
          }
        }
      })

      .state('app.office.profile.details', {
        url: '/details',
        controller: 'OfficeSettingsController',
        templateUrl: 'templates/marketplace/office/views/settings.html',
        data: {
          'title': 'Office Details',
          'confirm_navigation': {'forms': ['officeInfoForm'], 'type' : 'LeavePage'}
        }
      })
  })

;

(function () {
  'use strict';

  // this had to be declared outside angular scope because a config block doesn't have access to stuff like root scope or window
  // although it can access global variables like this one
  const locales = {
    en: "/locales/locale-en.json",
    fr: "/locales/locale-fr.json"
  };
  angular.module("app")
    .service('customTranslationLoader', customTranslationLoader);

  function customTranslationLoader($http, $q, $log, $rootScope, $timeout) {
    return load;

    function load(options) {
      const language_file = locales[options.key] || null;
      const deferred = $q.defer();
      if (!language_file || !Object.keys(locales).includes(options.key)) {
        $log.error('language not found:', options.key);
        deferred.reject(options.key);

        // The default method of error handling did not work with 
        // our custom loader, so I had to call this from here instead.
        // This requires a timeout because it needs to be called after 
        // the promise is rejected
        $timeout(() => {
          $rootScope.$broadcast('$translate: set-language', 'en');
        }, 500);

        return deferred.promise;
      } else {
        return $http({
          'url': language_file,
          'method': 'GET'
        }).then(function (response) {
          return response.data;
        });
      }
    }
  }

}());

angular.module("app.marketplace.ui.inventory")
  .controller("productTemplateCardController",
    function ($scope, $filter, sgToast, $mdDialog, inventoryItemService, errorService) {

      $scope.addToInventory = function (product_template) {
        var t_prefix = $filter('translate')('TOAST.WANT_TO_ADD');
        var t_suffix = $filter('translate')('TOAST.TO_YOUR_INVENTORY');
        var confirm = $mdDialog.confirm()
          .title('Add to inventory?')
          .textContent(t_prefix + product_template.product_name + t_suffix)
          .ok('Yes')
          .cancel('Cancel');
        return $mdDialog.show(confirm).then(function () {
          var data = {
            manufacturer: product_template.manufacturer_name,
            name: product_template.product_name,
            product_template_id: product_template.product_template_id
          };
          return inventoryItemService.createItem(data)
            .then(function (response) {
              var t_message = $filter('translate')('TOAST.ITEM_ADDED_TO_INV');
              sgToast.showSimple(t_message);
            }).catch(function (error) {
              var t_message = $filter('translate')('ERRORS.INVENTORY_ADD_ITEM')
              errorService.uiErrorHandler(error || t_message);
            });
        });

      };

    });

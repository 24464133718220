(function () {
  'use strict';

  angular
    .module("app.marketplace.ui.office")
    .service('referringDoctorsService', referringDoctorsService);


    function referringDoctorsService(serverAPI, apiUrl) {
      /*jshint validthis: true */
      var service = this;

      service.getReferringDoctors = getReferringDoctors;
      service.createOrUpdateReferringDoctors = createOrUpdateReferringDoctors;
      service.deleteReferringDoctor = deleteReferringDoctor;

      return service;

      /**
       * @method getReferringDoctors
       * @description Fetch the office referring doctors
       * @param filters an obj containing a doctor id
       *
       */
      function getReferringDoctors(filters) {

        var url = '{0}/office/referring_doctors'.format(apiUrl);
        var options = {
          'method': 'GET',
          'params': _.pick(filters, ['id']),
        };

        return serverAPI
          .doAPICall(url, options)
          .then(function (response) {
            return response.data;
          });

      }
      /**
       * @method createOrUpdateReferringDoctors
       * 
       * @desc Create or Update a Referring Doctor
       * 
       * @param referring_doctor obj
       */
      function createOrUpdateReferringDoctors (referring_doctor) {
        var attrs = ['id', 'name', 'title', 'notes', 'office_id', 'preferred_implant_company_id'];
        var url = '{0}/office/referring_doctors'.format(apiUrl);
        var options = {
          'method': 'POST',
          'data': _.pick(referring_doctor, attrs)
        };
  
        return serverAPI
          .doAPICall(url, options)
          .then(function (response) {
            return response.data;
          });
      }

      function deleteReferringDoctor (referring_doctor) {
        var url = '{0}/office/referring_doctors/{1}'.format(apiUrl, referring_doctor.id);
        var options = {
          'method': 'DELETE',
          // 'params': _.pick(filters, ['id']),
        };

        return serverAPI
        .doAPICall(url, options)
        .then(function (response) {
          return response.data;
        });
      }
    }

}());
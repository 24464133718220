angular.module("app.shared.support")
  // Use inbox-style-bundle-heading below an inbox-style-bundle to provide a heading containing HTML
  .directive('inboxStyleItemHeading', function () {
    return {
      transclude: true,   // Grab the contents to be used as the heading
      template: '',       // In effect remove this element!
      replace: true,
      require: '^inboxStyleItem',
      link: function (scope, element, attrs, controller, transclude) {
        // Pass the heading to the controller
        // so that it can be transcluded into the right place in the template
        // [The second parameter to transclude causes the elements to be cloned so that they work in ng-repeat]
        controller.setHeading(transclude(scope, angular.noop));
      }
    };
  });
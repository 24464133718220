(function () {
  'use strict';

  angular.module('sowProduct')
    .controller('algoliaFiltersController', algoliaFiltersController)
    .directive('sowAlgoliaFilters', algoliaFiltersSlideout);

  function algoliaFiltersSlideout() {
    return {
      restrict: 'E',
      templateUrl: 'sow-mkt/slideouts/mkt-algolia-filters.html',
      controller: 'algoliaFiltersController',
      controllerAs: 'filtersCtrl',
      scope: true
    };
  }

  function algoliaFiltersController($scope, $mdSidenav, $rootScope, $timeout) {
    var ctrl = this;

    var CID = 'algolia-filters';

    ctrl.close = close;
    ctrl.filtersReady = filtersReady;

    init()

    return ctrl;

    function init() {
      $scope.$on('algolia-filters-open', open);
      $scope.$on('algolia_data', setNumberOfHits);
      $scope.$on('algolia-widgets-added', updateWidgets);
    }

    function open() {
      $mdSidenav(CID).open();
      ctrl.widget_ids = getWidgetIDs();
      addEventListeners();
    }

    function close() {
      $mdSidenav(CID).close();
    }

    function filtersReady() {
      ctrl.widget_ids = getWidgetIDs();
      $rootScope.$broadcast('filters-ready')
    }

    function updateWidgets() {
      ctrl.widget_ids = getWidgetIDs();
      addEventListeners();
    }

    function setNumberOfHits (event, res) {
      ctrl.number_of_hits = _.get(res, 'total_products');
    }

    /** 
     * Returns the IDs of all children of the `algolia-widgets` <div>. 
     * 
     * @return {Array} 
    */
    function getWidgetIDs() {
      var widgets = document.querySelector('#algolia-widgets');
      var widget_ids = _.map(widgets.children, function (child) {
        return child.getAttribute("id");
      })
      return widget_ids;
    }

    /** 
     * Applies or removes the class name "open" to the content of the widget which contains the header that the user clicked on.
     * 
     * @param {Object} event 
     * @param {Array} widget_ids 
     * 
     * @event add".open"/remove".open"
     * @return {*} 
    */
    function toggleSection(event, widget_ids) {
      widget_ids = widget_ids || ctrl.widget_ids;
      const section_name = _.camelCase(event.target.innerHTML);
      const section_id = event.srcElement.parentElement.parentElement.parentElement.id;
      const section = document.querySelector('#' + section_id);
      if (section) {
        const section_content = section.querySelector('[class*="--list"');
        if (section_content.classList.contains("open")) {
          section_content.classList.remove("open");
        } else {
          section_content.classList.add("open");
        }
      } else {
        console.warn(section_name + ' not found');
      }
    }

    /** 
     * Adds a click handler to the header of each widget to allow toggling of that section. 
     * 
     * @param {Array} widget_ids 
     * 
     * @event addEventListener"click"
     * @return {*} 
    */
    function addEventListeners(widget_ids) {
      widget_ids = widget_ids || ctrl.widget_ids;
      _.each(widget_ids, function (widget_id) {
        var element = document.querySelector('#' + widget_id);
        var header = element?.querySelector('.ais-header');
        if (!header) {
          $timeout(function () {
            addEventListeners([widget_id]);
          }, 1000);
        } else {
          header.addEventListener('click', toggleSection);
        }
      })
    }

  }

})();

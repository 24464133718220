{
  'use strict';
  /** @ngInject */
  class PatientHelperService {
    constructor($filter) {
      this.$filter = $filter;
    }

    /**
     * Parses a person's name from FHIR standard data into a single string.
     * 
     * eg given this data:
     * ```
     * person: {
     *   name: {
     *     "family": "McDonald",
     *     "given": [
     *       "Ronald",
     *       "John"
     *     ]
     *   }
     * }
     * ```
     * You would get `Ronald John McDonald`
     * @param {Patient | Doctor} person
     * @return {string}
     */
    _getFullName (person) {
      const namesArray = person?.name;
      const humanName = namesArray?.[0];
      const givenNames = humanName?.given?.filter(Boolean).join?.(' ');
      const lastName = humanName?.family;
      if (!givenNames) return lastName || '';
      if (!lastName) return givenNames;
      return `${givenNames} ${lastName}`;
    }

    _getPatientDob (patient) {
      return moment(patient.birthDate).format('MM/DD/YYYY');
    }

    _getPatientCN (patient) {
      const chart_number = _.find(patient.identifier, ['type.text', 'ChartNumber']);
      const t_no_patient = this.$filter('translate')('WORKFLOWS.PATIENTS.NO_PATIENT_ID');
      return chart_number?.value || t_no_patient;
    }

    _getDatabaseId (person) {
      if (!person?.identifier) return null;
      const database_id = _.find(person.identifier, ['type.text', 'DatabaseID']);
      return database_id?.value || null;
    }

    /**
     * Gets the referring and provider doctors for a patient.
     * 
     * @param {Patient} patient
     * @return {object}
     */
    _getDoctors (patient) {
      return {
        referring_doctor: {
          ...patient.referringDoctor,
          id: patient.referringDoctor?.referringContactId,
          name: this._getFullName(patient.referringDoctor),
        }, 
        provider_doctor: {
          ...patient.providerDoctor,
          id: patient.providerDoctor?.providerId,
          name: this._getFullName(patient.providerDoctor),
        },
      };
    }

    /**
     * Parses a FHIR Patient object into a simpler format to be used with implants.
     * 
     * @param {Patient} patient
     * @return {ParsedPatient}
     */
    parsePatient (patient) {
      return {
        chart_number: this._getPatientCN(patient),
        dob: patient.birthDate && this._getPatientDob(patient.birthDate),
        id: patient.id,
        name: this._getFullName(patient),
        ...this._getDoctors(patient),
      };
    }

  }

  angular.module('sowWorkflows').service('PatientHelperService', PatientHelperService);
}

(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.shopping')
    .filter('supplierProductCount', supplierProductCount);

  //
  // Generate the product count for a Vendor based on the current Country and
  // MedicalField values.
  //
  function supplierProductCount($rootScope) {
    return function (vendor) {
      var count = 0;
      var productGroups = {};
      var medicalFieldId = $rootScope.current_medical_field.id;
      var currentCountryId = $rootScope.current_country.id;

      _.forEach(vendor.product_ids, function (productId) {
        var pg_id = productId.product_group_id;
        var countryMatches = (productId.country_id === currentCountryId);
        var medicalFieldMatches = (!productId.medical_field_id || productId.medical_field_id === medicalFieldId);
        var productGroupSeen = productGroups[pg_id];

        if (countryMatches && medicalFieldMatches && !productGroupSeen) {
          count++;
          productGroups[pg_id] = true;
        }
      });

      return count;
    };
  }
}());

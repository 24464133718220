(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.inventory')
    .directive('productWorkSheetActionBar', ProductWorkSheetActionBarDirective);

  function ProductWorkSheetActionBarDirective () {
    return {
      'restrict': 'AC',
      'controller': ProductWorkSheetActionBarCtrl,
      'controllerAs': '$actionBarCtrl',
      'templateUrl': 'templates/marketplace/inventory/product-work-sheet-action-bar.html',
    };
  }

  function ProductWorkSheetActionBarCtrl ($scope, gettingStartedDialog) {
    /*jshint validthis: true */
    var ctrl = this;

    ctrl.openDialog = openDialog;

    return ctrl;

    function openDialog (ev) {
      return gettingStartedDialog.show(ev);
    }
  }
}());

angular.module("app.shared.support")
  .directive('detectOverflow', function ($compile) {
    return {
      restrict: 'A',
      link: function (scope, element, attrs) {
        scope.$watch(function () {
          return element[0].scrollHeight > element[0].clientHeight || element[0].scrollWidth > element[0].clientWidth;
        }, function (newElementValue, oldVal) {
          if (newElementValue) {
            element.addClass('isOverflow');
            element.removeClass('isNotOverflow');
            if (attrs.detectOverflow) {
              scope[attrs.detectOverflow] = true;
            }
          } else {
            element.addClass('isNotOverflow');
            element.removeClass('isOverflow');
            if (attrs.detectOverflow) {
              scope[attrs.detectOverflow] = false;
            }
          }
        });
      }
    };
  });

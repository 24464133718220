angular.module("app.marketplace.ui.inventory")
.controller("manageInventoryLocationController", manageInventoryLocationController);
function manageInventoryLocationController ($scope, $filter, $mdDialog, errorService, officeService, sowDashboardService) {
  const ctrl = this;

  if(!$scope.locations){
    ctrl.loading = true;
    sowDashboardService.getLocations().then(function(response){
      $scope.locations = response;
    })
    .catch(function(error){
      var t_message = $filter('translate')('ERRORS.LOCATIONS_LOAD')
      errorService.uiErrorHandler(error || t_message, 0);
    })
    .finally(function(){
      ctrl.loading = false;
    });
  }
  
  $scope.addLocation = function(new_location){
    if(!new_location){
      return false;
    }
    
    officeService.createLocation(new_location.name)
    .then(function(newLocation){
      newLocation.is_new = true;
      $scope.locations.push(newLocation);
    }).catch(function(error){
      var t_message = $filter('translate')('ERRORS.LOCATION_CREATE')
      errorService.uiErrorHandler(error || t_message, 0);
    });
    $scope.new_location = {};
   };
   
  $scope.removeLocation = function(location, force){
    if(!location){
      return false;
    }
     
    if(!force && location.num_products>0){
      var confirmResult = confirm('Remove location with '+location.num_products+' products?');
      if (confirmResult === true) {
        return $scope.removeLocation(location,true);
      } else {
        return false;
      }
    }else{
      officeService.removeLocation(location)
      .then(function(message){
        _.map($scope.locations, function(this_location, i){
          if(!this_location){
            return;
          }
          if(this_location.id === location.id){
            $scope.locations.splice(i, 1);
          }
        });
      }).catch(function(error){
        var t_message = $filter('translate')('ERRORS.LOCATION_REMOVE')
        errorService.uiErrorHandler(error || t_message, 0);
      });
    }
  };
  
  //----------
  //Functions to be used by view-inventory-item directive
  $scope.closeModal = function(){
    $mdDialog.cancel();
  };
  
}

(function () {
  'use strict';

  angular.module('app.marketplace.ui.office')
         .controller('officeUserModalController', officeUserModalController);

  function officeUserModalController ($scope, $rootScope, $mdDialog, $filter, officeService, accountModel, errorService, modalType, user) {
    $scope.modalType = modalType;
    $scope.accountModel = accountModel;
    $scope.user = user ? angular.copy(user) : {};

    $scope.close = closeDialog;
    $scope.submit = submitForm;
    $scope.isSameUser = isSameUser;

    return;

    function closeDialog () {
      $mdDialog.cancel();
    }

    function isSameUser (user) {
      var cm = $rootScope.current_membership;
      var same_user = user.id === cm.id;

      // debug same user
      
      return same_user;
    }

    function submitForm () {
      $scope.userForm.$setSubmitted();
      if ($scope.userForm.$error) {
        _.forEach(Object.keys($scope.userForm.$error), function(key){
          _.forEach($scope.userForm.$error[key], function(form_input){
            form_input.$setDirty();
            form_input.$setTouched();
          });
        });
      }
      if ($scope.userForm.$valid) {
        $scope.submittingForm = true;

        if (modalType === 'new') {

          officeService.inviteOfficeUser($scope.user).then(function (response) {
            $mdDialog.hide('User has been invited.');
          }).catch(function(error) {
            var t_message = $filter('translate')('ERRORS.USER_INVITE')
            errorService.uiErrorHandler(error || t_message, 0);
            $mdDialog.cancel();
          })['finally'](function () {
            $scope.submittingForm = false;
          });

        } else if (modalType === 'edit') {
          var updateFn = null;
          if(_.get($scope.user,'invited_on')){
            updateFn = officeService.updateOfficeInvite;
          } else {
            updateFn = officeService.updateOfficeUser;
          }
          updateFn($scope.user).then(function (response) {
            $mdDialog.hide('User Successfully Updated');
          }).catch(function(error) {
            if (error.status === 403) {
              var t_message = $filter('translate')('ERRORS.PERMISSION_DENIED')
              errorService.uiErrorHandler(error || t_message,   0);
            } else {
              var t_message = $filter('translate')('ERRORS.USER_NOT_UPDATED')
              errorService.uiErrorHandler(error || t_message, 0);
            }
            $mdDialog.cancel();
          })['finally'](function () {
            $scope.submittingForm = false;
          });

        }
      }
    }
  }

}());

angular.module("app.marketplace.elements")
.service("productService",
    function($rootScope, $q, $log, elementService, productModel, inventoryItemService, sessionService) {
  var _this = this;

  //
  // Get Products By Vendor
  //
  // Fetch a list of products by Vendor Id.
  //
  var _getProductsByVendor = function (vendorId) {
    var countryId = sessionService.get().country.id;

    return elementService.getElements('product', {
      endpoint: 'vendor_products',
      forceAPI: true,
      country_id: countryId,
      vendor_id: vendorId
    });
  };

  var _getProductsById = function (productIds) {
    //
    // Note: We need this crazy batching setup because ElasticSearch has a hard
    // limit of 1024 clauses in the query.
    //
    var products = [];
    var index = 0;
    var batchSize = 1000;
    var batches = Math.ceil(productIds.length / 1000);

    function fetchBatch (start, size) {
      return elementService.getElements('product', {
        endpoint: 'multiple_by_id',
        forceAPI: true,
        product_ids: productIds.slice(start, size)
      }).then(function (productsMap) {
        return productsMap;
      });
    }

    function batchLoop () {
      return fetchBatch(index, batchSize).then(function (currentBatchProductMap) {
        batches -= 1;
        index += batchSize;

        if (batches > 0) {
          return batchLoop().then(function (nextBatchProductMap) {
            // bubble product map results back to the top
            return angular.extend(currentBatchProductMap, nextBatchProductMap);
          });
        } else {
          return currentBatchProductMap;
        }
      });
    }

    return $q(function (resolve, reject) {
      batchLoop().then(function (productsMap) {
        resolve(_.values(productsMap));
      }).catch(function (error) {
        reject(error);
      });
    });
  };

  //
  // Check if product is in inventory and if so add inventory information.
  //
  var _checkInventory = function(product){
    if (product.inventory_item_id) {
      $q.resolve(product.inventory_item_id);
    }

    return inventoryItemService.findItemViaProduct(product).then(function (item) {
      if (item) {
        product.inventory_item_id = item.id;
        product.inventory_item = item;
      }
      return item;
    });
  };

  //
  // Get Country Properties From product For The Current Session Country
  //
  var _getCountryProperties = function (product) {
    var sessionObject = sessionService.get();
    var currentCountry;

    if (!sessionObject || !sessionObject.country) {
      $log.error('No Session Object or Current Country.');
      return undefined;
    } else {
      currentCountry = sessionObject.country;
    }

    var matchingCountries = (product.country_properties || []).filter(function (cProperties) {
      return cProperties.country_id === currentCountry.id;
    });

    if (matchingCountries.length === 0) {
      $log.error('No country properties for country (%o).', currentCountry);
      return undefined;
    } else if (matchingCountries.length > 1) {
      $log.error('Multiple Country Properties (%o) Found for Country (%o).', matchingCountries, currentCountry);
      return undefined;
    } else {
      $log.debug('Found Country Properties (%o) for Country (%o)', matchingCountries[0], currentCountry);
      return matchingCountries[0];
    }
  };

  //
  // Is this product regulated?
  //
  var _isRegulated = function (product) {
    var countryProperties = _getCountryProperties(product);

    if (!countryProperties) {
      $log.error('No country properties on product:%o. Assuming it\'s regulated.', product);
      return true;
    } else {
      return countryProperties.is_regulated;
    }
  };

  var _getOnSaleProducts = function(medicalField){
    return elementService.getElements('product',
        { 'endpoint' : 'elastic_custom_search', 'forceAPI' :true,
          'generic' : true,
          'custom_filter' : { "bool": {
              "must": [
                  {"query":{
                      "bool": {
                          "must": [
                              {"nested":{
                                  "path":"vendors",
                                  "filter" : {
                                      "exists":{ "field" : "vendors.promotion_price" }       
                                  }      
                              }} 
                          ]
                      }
                  }},
                  {
                      "or": [
                          {"term":{ "medical_fields.id" : medicalField ? medicalField : $rootScope.marketplace_medical_field ? $rootScope.marketplace_medical_field.id : "dentistry" }  },
                          {
                              "bool": {
                                "must_not": [
                                  {
                                    "exists": {
                                      "field": "medical_fields.id"
                                    }
                                  }
                                ]
                              }
                          }
                      ]
                  }
                ]
              }
            }
          
        });
  };
  var _getFeaturedProducts = function(medicalField){
    return elementService.getElements('product',
        { 'endpoint' : 'elastic_custom_search', 'forceAPI' :true,
          'generic' : true,
          'escaped_query' : {"term":{"vendors.is_featured":true}}});
  };
  var _getNewProducts = function(medicalField){
    return elementService.getElements('product',
        { 'endpoint' : 'elastic_custom_search', 'forceAPI' :true,
          'generic' : true,
          'escaped_body' : {
            "from" : 0, "size" : 20,
            "sort" : [{ "created_at" : {"order" : "desc"}}],
            "filter":{
                  "or": [
                      {"term":{ "medical_fields.id" : medicalField ? medicalField :$rootScope.marketplace_medical_field ? $rootScope.marketplace_medical_field.id : "dentistry" }  },
                      {
                          "bool": {
                            "must_not": [
                              {
                                "exists": {
                                  "field": "medical_fields.id"
                                }
                              }
                            ]
                          }
                      }
                  ]
                }           
            }
          }
        
    );
  };

  var _getOrderHistory = function(product){
    return elementService.get('orderItem', null, {'endpoint': 'product_history', 'product': product, 'return_type': 'multiple'})
    .then(function(orders){
      product.history = _.values(orders);
      return orders;
    });
  };

  return {
    checkInventory : _checkInventory,
    isRegulated: _isRegulated,

    getProductsById: _getProductsById,
    getProductsByVendor: _getProductsByVendor,
    getFeaturedProducts : _getFeaturedProducts,
    getOnSaleProducts : _getOnSaleProducts,
    getOrderHistory : _getOrderHistory,
    getNewProducts : _getNewProducts
  };
});

(function () {
  'use strict';

  var sortLabelMap = {
    'name': 'Product Name',
    'manufacturer_name': 'Manufacturer Name',
    undefined: 'Product Name',
  };

  angular
    .module('app.marketplace.ui.inventory')
    .controller('InventoryPrintCtrl', InventoryPrintCtrl);

  function InventoryPrintCtrl ($scope, $window, $timeout, inventoryItemService) {
    /*jshint validthis: true */
    var ctrl = this;

    ctrl.error = null;
    ctrl.searchTerm = '';
    ctrl.sortedBy = '';
    ctrl.filteredBy = [];

    ctrl.updateFilters = updateFilters;
    ctrl.updateTimestamp = updateTimestamp;
    ctrl.formatLocations = formatLocations;
    ctrl.print = printPage;
    ctrl.startLoading = startLoading;
    ctrl.endLoading = endLoading;
    ctrl.setError = setError;
    ctrl.clearError = clearError;
    ctrl.init = init;
    ctrl.getItemQuantity = getItemQuantity;
    $scope.getItemQuantity = getItemQuantity;

    return ctrl;

    //
    // Initialize the Controller
    //
    // This is designed to be called from child controllers in the print
    // section. Since this 'master' print controller is just a place to
    // consolidate common code.
    //
    function init () {
      ctrl.clearError();
      ctrl.updateTimestamp();
      ctrl.updateFilters();
    }

    //
    // Set the current error message
    //
    function setError (error) {
      ctrl.error = error;
    }

    //
    // Clear the error message
    //
    function clearError () {
      ctrl.error = null;
    }

    //
    // Mark state as loading
    //
    function startLoading () {
      ctrl.loading = true;
    }

    //
    // Mark state as not loading
    //
    function endLoading () {
      ctrl.loading = false;
    }

    //
    // Format a list of location objects for display
    //
    function formatLocations (locations) {
      if (locations && locations.length > 0) {
        return _.map(locations, 'name').join(', ');
      } else {
        return '';
      }
    }

    //
    // Trigger the Print Dialog
    //
    function printPage (delay) {
      delay = delay || 1000;
      $timeout(function () { $window.print(); }, delay);
    }

    //
    // Update the "Printed At" Timestamp
    //
    function updateTimestamp () {
      ctrl.printedAt = new Date();
    }

    //
    // Update the "Filter Block" Values
    //
    function updateFilters () {
      var sortField = _.get($scope, 'sorts.sort_field');

      ctrl.sortedBy = sortLabelMap[$scope.sorts.sort_field];
      ctrl.searchTerm = $scope.filters.inventory_search;

      if ($scope.filters.below_min_level) {
        ctrl.filteredBy.push('Below Minimum Level');
      }

      if ($scope.filters.expiring_soon) {
        ctrl.filteredBy.push('Expired and Expiring Soon');
      }
    }

    function getItemQuantity (item) {
      var end_result = 0;
      // var inv_item = item.selectedItem;
      if (item) {
        end_result = inventoryItemService.calculateInventoryOnHand(item);
      }
      return end_result;
    }
  }
}());

(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.inventory')
    .directive('inventoryItemField', InventoryItemField);

  // General directive for modifying the field value of an object.
  // value in the model is a not a linked value until submitted.
  // Extra functionality is included to allow for pattern checking,
  //    passing a function for setting "no value",
  //    passing a function to call after submit,
  //    custom icons,
  //    and automatically setting the focus to the next item after submission
  function InventoryItemField () {
    return {
      'restrict': 'E',
      'replace': true,
      'templateUrl': 'templates/marketplace/inventory/directives/inventory-item/inventory-item-field.html',
      'scope': {
        'item': '=', // Parent object
        'field': '=' /* Object containing:
                      * - name (field name for lookup and modification)
                      * - display_name (Text to describe the field in the placeholder)
                      * - icon (Material icon name to display)
                      * - pattern (Regex string to evaluate against)
                      * - no_value_fn (Function that if passed will display an extra button, allowing the user to set "no" value)
                      * - onsubmit (Function to call after submission)
                      * - focus_next (Indicates if next item's field should be focused after submission)
                      */
      },
      'controller': InventoryItemFieldController,
    };
  }

  function InventoryItemFieldController ($scope, $filter, sgToast, inventoryItemService, errorService) {
    $scope.value = $scope.item[$scope.field.name];
    $scope.submit = function(force){
      if(!force && $scope.itemFieldForm.$invalid){
        return false;
      }
      $scope.submitting = true;
      $scope.item[$scope.field.name] = $scope.value;
      inventoryItemService.updateItem($scope.item)
      .then(function(inventory_item){
        $scope.submitting = false;

        // Call onsubmit function if one exists.
        if($scope.field.onsubmit){
          $scope.field.onsubmit($scope.item);
        }

        var t_prefix = $filter('translate')('TOAST.INVENTORY');
        var t_suffix = $filter('translate')('TOAST.UPDATED');
        sgToast.showSimple(t_prefix + $scope.field.display_name + t_suffix);
      }).catch(function(error){
        $scope.submitting = false;
        var t_message = $filter('translate')('ERRORS.INVENTORY_UPDATE')
        errorService.uiErrorHandler(error || t_message, 0);
      });


      // Heavy/ugly implementation of moving the focus to the next item
      if($scope.field.focus_next &&
          $scope.$parent.bundle){
        var index = $scope.$parent.$index; //Preserve index.
        var items = null;
        if($scope.$parent.bundle.items){
          items = $scope.$parent.bundle.items;
        }else if($scope.$parent.bundle.itemsFn){
          items = $scope.$parent.bundle.itemsFn();
        }
        if(!items[index] || !items[index].id){ //Item at previous index does not exist. Do nothing.
          return false;
        }else if(items[index].id !== $scope.item.id){
          //Assume previous item was removed. Focus this item as "next"
          items[index].UI.focus_field=true;
        }else if((index+1)<items.length){
          //Item at current index still exists and next item exists. Focus next item.
          items[index+1].UI.focus_field=true;
        }
      }
    };

    $scope.patternFn = (function() {
      var regexp = $scope.field.pattern;
      return {
          test: function(value) {
              if(!regexp){
                return true;
              }
              if( $scope.requireTel === false ) {
                  return true;
              }
              return regexp.test(value);
          }
      };
    })();
  }
}());

(function () {
  'use strict';

  angular
    .module('sowSideNav', ['ngMaterial', 'ui.router', 'sowBrowserFlags'])
    .controller('sowSideNavController', SideNavController)
    .directive('sowSidenav', SideNavDirective);

  function SideNavDirective ($mdSticky, $compile) {
    return {
      'replace': true,
      'restrict': 'E',
      'templateUrl': 'sow-sidenav/side-navigation.html',
      'link': function (scope, element) {
        $mdSticky(scope, element);
      }
    };
  }

  // TODO Fix imports for: officeService, elementService, $state, errorService

  function SideNavController ($scope, $log, errorService, elementService, officeService, $state, $mdMedia, $rootScope, sowBrowserFlags) {
    /*jshint validthis: true */
    var ctrl = this;
    var flagName = 'UserContractedSideNav';

    ctrl.contracted = false;
    ctrl.contractedBy = {
      'button': false,
      'route': false,
      'size': false,
    };

    ctrl.hidden = false;
    ctrl.hiddenBy = {
      'guest': false,
      'route': false,
      'size': false,
    };

    ctrl.toggle = toggle;
    ctrl.itemIsActive = itemIsActive;
    ctrl.shouldShowItem = shouldShowItem;

    init();

    return ctrl;

    //
    // Initialize the Controller
    //
    function init () {
      ctrl.contractedBy.button = sowBrowserFlags.flag(flagName);
      ctrl.contractedBy.route = (_.get($state, 'current.data') || {}).contractSidenav;
      ctrl.contractedBy.size = shouldAutoContract();
      ctrl.hiddenBy.guest = !$rootScope.current_account;
      ctrl.hiddenBy.route = (_.get($state, 'current.data') || {}).hideSidenav;
      ctrl.hiddenBy.size = shouldAutoHide();
      updateState();

      $scope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
        var toStateData = toState && toState.data ? toState.data : {};
        ctrl.hiddenBy.route = _.get(toStateData, 'hideSidenav', false);
        ctrl.contractedBy.route = _.get(toStateData, 'contractSidenav', false);
        updateState();
      });

      //
      // Watch for changes that come from outside of the controller's scope.
      //
      $scope.$watch(function () {
        return (
          shouldAutoHide() + '-' +
          shouldAutoContract() + '-' +
          (!$rootScope.current_account)
        );
      }, function (newValue) {
        ctrl.contractedBy.size = shouldAutoContract();
        ctrl.hiddenBy.size = shouldAutoHide();
        ctrl.hiddenBy.guest = !$rootScope.current_account;
        updateState();
      });

    }

    //
    // See if we should automatically contract the sidebar due to browser
    // display width.
    //
    function shouldAutoContract () {
      return !$mdMedia('gt-sm');
    }

    //
    // See if we should automatically hide the sidebar.
    //
    function shouldAutoHide () {
      return $mdMedia('xs');
    }

    //
    // Update the state of the controller.
    //
    function updateState () {
      ctrl.hidden = _.reduce(ctrl.hiddenBy, function (p, c) { return p || c; }, false);
      ctrl.contracted = _.reduce(ctrl.contractedBy, function (p, c) { return p || c; }, false);

      if (ctrl.hidden) {
        setStatus('hidden');
      } else if (ctrl.contracted) {
        setStatus('contracted');
      } else {
        setStatus('full');
      }
    }

    //
    // Toggle if the sidenav is contracted / expanded
    //
    function toggle () {
      ctrl.contractedBy.button = sowBrowserFlags.toggle(flagName);
      updateState();
    };

    //
    // Return true if menuItem.state passes $state.includes, _and_ none of the
    // items in menuItem.exclude pass $state.includes.
    //
    function itemIsActive (menuItem) {
      var excludes = menuItem.exclude || [];
      return (
        //
        // The current state is part of menuItem.state.
        //
        $state.includes(menuItem.state) &&

        //
        // A way to exclude certain states that might pass the above check. The
        // example that spawned this is app.inventory vs.
        // app.inventory.log_sheets. app.inventory.log_sheets is under
        // app.inventory, so the above check would evaluate true for both
        // menuItem.state values at the same time. By adding
        // app.inventory.log_sheets under menuItem.exclude for app.inventory,
        // we now have a way to have this eval true for only one menuItem at a
        // time.
        //
        !_.some(excludes, $state.includes)
      );
    }

    // implemented user permissions for hiding menu items, but performance in 
    // this method is not very good, might need to refactor shortly
    function shouldShowItem (menuItem) {
      var access = true;
      var user_permissions = _.get($rootScope, 'current_membership.user_permissions',[]);
      const permission_overrides = _.get($rootScope, 'current_membership.user_properties.permission_overrides', {});
      if (menuItem.feature) {
        var office_has_flag = $rootScope.features[menuItem.feature] || false;
        access = office_has_flag;
      }
      if (access && menuItem.hide_aaoms) {
        var assoc = _.get($rootScope.current_office, 'association_membership.professional_association_id', null);
        var isnt_member = (assoc !== 'aaoms');
        access = isnt_member;
      }
      if (access && menuItem.permission) {
        var has_permission = ( user_permissions.indexOf(menuItem.permission) >= 0 );
        var is_master = ( user_permissions[0] === 'master_permission' );
        var user_has_permission = ( has_permission || is_master);
        access = user_has_permission;
      }
      if (access && menuItem.permission_OR) {
        var has_permission = false;
        _.each(menuItem.permission_OR, function(key){
          var key_met = ( user_permissions.indexOf(key) >= 0 );
          if(key_met) {
            has_permission = true;
          }
        });
        var is_master = ( user_permissions[0] === 'master_permission' );
        var user_has_permission = ( has_permission || is_master);
        access = user_has_permission;
      }
      if (access && menuItem.overrides?.length) {
        // permission_overrides impact sections of this menuItem's
        // part of the app, so we need to check if all of them are
        // true for the current user and hide the menuItem if so
        let grant_access = false;
        for (const override of menuItem.overrides) {
          if (!permission_overrides[override]) {
            grant_access = true;
            break;
          }
        }
        access = grant_access;
      }
      return access;
    }

    function setStatus (status) {
      $rootScope.desktopSidenavStatus = status;
    }
  }
}());

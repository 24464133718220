(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.marketing')
    .controller('ShippingRatesCtrl', ShippingRatesCtrl);

  function ShippingRatesCtrl ($scope, $filter, vendorService, errorService) {
    /*jshint validthis: true */
    var ctrl = this;

    $scope.rates = [];
    $scope.ratesLoaded = false;
    $scope.ratesLoading = false;
    $scope.ratesError = false;

    ctrl.init = init;

    ctrl.init();

    return ctrl;

    function init () {
      $scope.ratesLoading = true;

      vendorService.getShippingRates().then(function (rates) {
        $scope.ratesLoaded = true;
        updateRates(rates);
      }).catch(function (error) {
        $scope.ratesError = true;
        errorService.uiErrorHandler(error);
      })['finally'](function () {
        $scope.ratesLoading = false;
      });
    }

    function getFeeTypeFmt (fee_type) {
      return {
        'ambient': 'Extra {0} charge for ambient shipping.',
        'dangerous': 'Extra {0} charge for hazardous products.',
      }[fee_type];
    }

    function updateRates (rates) {
      $scope.rates = _.map(rates, function (summary) {
        var _summary = {
          'vendor_name': _.get(summary, 'vendor.name'),
          'vendor_id': _.get(summary, 'vendor.id'),
          'rate_range_min': _.min(_.map(summary.rates, 'rate')),
          'rate_range_max': _.max(_.map(summary.rates, 'rate')),
          'fees': _.map(summary.fees, function (fee) {
            return getFeeTypeFmt(fee.fee_type).format($filter('currency')(fee.fee_amount, '$', 2));
          })
        };

        var max_range = _.max(_.map(summary.rates, 'range_end'));

        if (max_range < 1000000) {
          _summary.free_shipping_at = max_range;
        }

        return _summary;
      });
    }
  }

}());

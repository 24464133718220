angular.module("app.marketplace.elements")
.service("contentBlockService", function($rootScope, $q, elementService, contentBlockModel) { 
  var _this = this;
  _this.content_blocks = null;
  _this.initialized = false;
  _this._initCalled = false;

  var _setContentBlocks = function(content_blocks){
    _this.content_blocks = content_blocks;
    $rootScope.$broadcast('contentBlocksService: set-contentBlocks', content_blocks);
    _this.initialized = true;
  };
  var _clearContentBlocks = function(){
    _this.content_blocks = null;
    $rootScope.$broadcast('contentBlocksService: clear-contentBlocks');
    _this.initialized = true;
  };
  //Convience function for waiting for initialized
  var _waitForInitialized = function(){
    if(_this.initialized){
      return $q.resolve(_this.content_blocks);
    }else{
      if(!_this._initCalled){
        _this._init();
      }
      var deferred = $q.defer();
      var initWatch = $rootScope.$watch(function(){ 
        return _this.initialized; 
      }, function(newInitializedValue, oldInitializedValue){
        if(newInitializedValue){
          deferred.resolve(_this.content_blocks);
          initWatch(); //Stop watch;
        }
      });
      return deferred.promise;
    }
  };
  
  var _getContentBlocks = function(waitForInitialized){
    if(waitForInitialized){
      return _waitForInitialized()
      .then(function(content_blocks){
        if(content_blocks){
          return content_blocks;
        }else{
          return $q.reject("Error getting content blocks");
        }
      });
    }else{
      return  _this.content_blocks;
    }
  };
  
  //~~~~~~~~~~~~~~
  var _refresh = function(){
    elementService.getElements('contentBlock', {'forceAPI' : true})
    .then(function(content_blocks){
      _.map(content_blocks, function(block){
        block.displayed_products = _.values(elementService.createMultiple("product", block.displayed_products));
      });
      _setContentBlocks(elementService.elementMaps.contentBlock); //Hard link to global map
    });
  };
  _this._init = function(){
    _this._initCalled = true;
    _refresh();
  };
  
  return {
    init : _this._init,
    get : _getContentBlocks
    
  };
});

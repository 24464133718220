(function () {
  'use strict';

  angular.module('sowInventory')
  .service('agGridHelperService', agGridHelperService);

  function agGridHelperService ($filter) {
    var service = this;

    service.defaultOptions = defaultOptions;
    service.defaultColumn = defaultColumn;
    service.resizeColumns = resizeColumns;
    service.dateCR = dateCR;
    service.currencyVFCR = currencyCR;

    return service;

    // configuration objects
    function defaultOptions () {
      return {
        columnDefs: [{headerName: "ID", field: "id", minWidth: 40},],
        defaultColDef: defaultColumn(),
        columnTypes: {
          "dateColumn": {minWidth: 130, filter: 'agDateColumnFilter', cellRenderer: dateCR},
          "dateTimeColumn": {minWidth: 130, filter: 'agDateColumnFilter', cellRenderer: dateTimeCR},
          "mediumDateColumn": {minWidth: 130, filter: 'agDateColumnFilter', cellRenderer: mediumDateCR},
          "currencyColumn": {minWidth: 100, filter: 'agNumberColumnFilter', cellRenderer: currencyCR},
          "boolYnColumn": {minWidth: 50, cellRenderer: yesNoCR}
        },
        rowData: [{id:1}],
        enableColResize: true,
        enableSorting: true,
        enableFilter: true,
        pagination: true,
        suppressRowClickSelection: true,
        onGridSizeChanged: gridSizeHandler,
        onGridReady: gridReadyHandler,
        domLayout: 'autoHeight',
        // onColumnResized: gridSizeHandler
      };
    }

    function defaultColumn () {
      return {
        minWidth: 10,
        minHeight: 60,
        editable: false,
        filter: 'agTextColumnFilter',
        supressCellSelection: true,
        comparator: function(a,b) {
          a = a.toLowerCase();
          b = b.toLowerCase();
          if (typeof a === 'string') {
            return a.localeCompare(b);
          } else {
            return (a > b ? 1 : (a < b ? -1 : 0));
          }
        }
      };
    }

    // event handlers
    function gridSizeHandler (params) {
      resizeColumns(params);
    }

    function gridReadyHandler (params) {
      resizeColumns(params);
    }

    function resizeColumns (params) {
      params.api.sizeColumnsToFit();
    }

    // CR: CellRenderer
    // VF: ValueFormatter
    // params.value is the column's property value
    // params.data is the whole row object
    function currencyCR (params) {
      return $filter('currency')(params.value,'$',2);
    }

    function dateCR (params) {
      var a_date = new Date(params.value);
      var this_date = $filter('date')(a_date, 'yyyy-MM-dd');
      return this_date;
    }

    function dateTimeCR (params) {
      var a_date = new Date(params.value);
      var this_date = $filter('date')(a_date, 'yyyy-MM-dd HH:mm');
      return this_date;
    }

    function mediumDateCR (params) {
      var a_date = new Date(params.value);
      var this_date = $filter('date')(a_date, 'MMM dd, yyyy');
      return this_date;
    }

    function urlCR (params) {
      return ('<a href="'+params.value+'" target="_blank">'+params.value+'</a>');
    }

    function phoneUrlCR (params) {
      return ('<a href="tel:'+params.value+'">'+params.value+'</a>');
    }

    function emailUrlCR (params) {
      return ('<a href="mailto:'+params.value+'" target="_blank">'+params.value+'</a>');
    }

    function yesNoCR (params) {
      return params.value ? 'Yes':'No';
    }
  }

}());
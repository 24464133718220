(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.inventory')
    .service('DeleteInventoryActivePODialog', DeleteInventoryActivePODialog);

  function DeleteInventoryActivePODialog ($mdDialog) {
    /*jshint validthis: true */
    var service = this;

    service.show = show;

    return service;

    function show (items, $event) {
      return $mdDialog.show({
        'parent': angular.element(document.body),
        'targetEvent': $event,
        'controller': DialogCtrl,
        'controllerAs': '$dialogCtrl',
        'templateUrl': 'templates/marketplace/inventory/delete-inventory-active-po-dialog.html',
        'clickOutsideToClose': false,
        'escapeToClose': false,
        'locals': {
          'items': items,
        }
      });
    }
  }

  /* @ngInject */
  function DialogCtrl ($scope, $q, $mdDialog, elementService, errorService, items) {
    /*jshint validthis: true */
    var ctrl = this;
    var deleted = 0;

    $scope.items = items;

    ctrl.dismiss = dismiss;

    return ctrl;

    function dismiss () {
      return $mdDialog.hide([]);
    }
  }

}());

(function () {
  'use strict';
  angular.module('sowSterilization')
  .controller('stznAddNewMachineController', stznAddNewMachineController);

  function stznAddNewMachineController ($rootScope, $mdDialog, $filter, errorService, sowMachineService, sgToast, formService, AccessService) {
    /*jshint validthis:true*/
    var ctrl = this;
    ctrl.loading = false;
    ctrl.save_btn_label = 'ACTIONS.ADD';
    var t_sc = $filter('translate')('STERILIZATION.SC');
    var t_uc = $filter('translate')('STERILIZATION.UC');
    var t_wd = $filter('translate')('STERILIZATION.WD');
    ctrl.machine_type_options = [
      { 
        'name': 'Sterilizer Machine', 
        'value': 'sterilizer',
        'display_name': t_sc
      },
      { 
        'name': 'Ultrasonic Cleaner', 
        'value': 'ultrasonic',
        'display_name': t_uc
      },
      { 
        'name': 'Washer-Disinfector', 
        'value': 'washer',
        'display_name': t_wd
      }
    ];
    ctrl.machine_types_data = {
      'sterilizer': {
        'create_fn': sowMachineService.createSterilizer,
        'update_fn': sowMachineService.updateSterilizer,
        'prefab_name': 'Steam Pressure Pulse',
        'display_name': t_sc,
        'form': 'addSterilizerForm',
      },
      'ultrasonic': {
        'create_fn': sowMachineService.createUltrasonicCleaner,
        'update_fn': sowMachineService.updateUltrasonicCleaner,
        'prefab_name': 'Ultrasonic Cleaner',
        'display_name': t_uc,
        'form': 'addUltrasonicForm',
      },
      'washer': {
        'create_fn': sowMachineService.createWasherDisinfector,
        'update_fn': sowMachineService.updateWasherDisinfector,
        'prefab_name': 'Washer Disinfector',
        'display_name': t_wd,
        'form': 'addWasherForm',
      },
    };
    ctrl.create_success_msg = "";
    ctrl.save_success_msg = "";
    
    ctrl.cancelDialog = cancelDialog;
    ctrl.setType = setType;
    ctrl.addMode = addMode;
    ctrl.removeMode = removeMode;
    ctrl.saveMachine = saveMachine;
    ctrl.action = null;
    ctrl.machine_and_serial_limit = 65;
    
    init();

    return ctrl;

    function init () {
      // in case this dialog was invoked with data on it to be edited
      if(_.get(ctrl,'machine_data.id', null)){
        setEditMode();
        ctrl.action = 'edited';
      } else {
        setAddMode();
        ctrl.action = 'added';
      }

      _defineLocks();
    }

    // UI functions

    function cancelDialog () {
      return $mdDialog.cancel();
    }

    function setType (type) {
      if (!type) {
        return;
      }

      ctrl.machine_type = type;
      ctrl.machine_data.machine_type = ctrl.machine_types_data[ctrl.machine_type].prefab_name;
    }

    function setAddMode () {
      ctrl.mode = 'add';
      ctrl.dialog_title = "STERILIZATION.ADD_NEW_MACHINE";
      ctrl.save_btn_label = "STERILIZATION.ADD_MACHINE";
      ctrl.saving_method = 'create_fn';
      ctrl.machine_data = {'modes':[{}]};
      if(ctrl.machine_type){
        setType(ctrl.machine_type);
      } else {
        ctrl.machine_type = '';
      }
    }

    function setEditMode () {
      ctrl.mode = 'edit';
      ctrl.dialog_title = "STERILIZATION.EDIT_MACHINE";
      ctrl.save_btn_label = "ACTIONS.SAVE_CHANGES";
      ctrl.saving_method = 'update_fn';
    }

    function addMode () {
      ctrl.machine_data.modes.push({});
    }

    function removeMode (mode) {
      _.remove(ctrl.machine_data.modes, mode);
    }

    function errorMessage () {
      // TO-DO: improve ui message
    }

    function saveMachine () {
      var form_name = ctrl.machine_types_data[ctrl.machine_type]['form'];
      var this_form = ctrl[form_name];
      if(!formService.checkRequiredFields(this_form)){
        return 0;
      }
      var saveFn = ctrl.machine_types_data[ctrl.machine_type][ctrl.saving_method];
      // var eventName = "stzn-machine-new-{0}".format(ctrl.machine_type);
      ctrl.loading = true;
      return saveFn(ctrl.machine_data)
      .then(function(response_machine){
        $rootScope.$broadcast('stzn-machine-saved', {
          type: ctrl.machine_type,
          data: response_machine,
          action: ctrl.action,
        });
        cancelDialog();
      })
      .catch(function(error){
        errorService.uiErrorHandler(error);
      })
      .finally(function(){
        ctrl.loading = false;
      });
    }

    function _defineLocks () {
      const disable_sterilization_preset_delete = AccessService.getProperty('sterilization.disable_sterilization_preset_delete');
      const disable_sterilization_preset_edit = AccessService.getProperty('sterilization.disable_sterilization_preset_edit');
      ctrl.is_delete_preset_disabled = disable_sterilization_preset_delete && ctrl.action === 'edited';
      ctrl.is_edit_preset_disabled = disable_sterilization_preset_edit && ctrl.action === 'edited';
    }

  }

}());

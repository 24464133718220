angular.module("app.shared.support")
  .directive('productCard', function () {
    return {
      restrict: 'A',
      replace: true,
      templateUrl: 'templates/marketplace/elements/product/product-card.html',
      controller: 'productController',
      controllerAs: '$productCtrl',
      link: function ($scope, $element, $attributes, $ctrl) {
        $scope.options = {};
        $scope.options.hideAdd = $attributes.hideAdd; // Optional to indicate that the add button should be hidden.
        $scope.options.name = $attributes.name; // Optional to change what field is being used as a display name.

        if ($attributes.vendorId) {
          $ctrl.setStickyVendor($attributes.vendorId);
        }
      }
    };
  });
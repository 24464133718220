(function () {
  'use strict';

  angular.module("app")
    .config(initializeTranslation);

  //
  // text translation languages
  //
  function initializeTranslation($translateProvider) {
    $translateProvider.useSanitizeValueStrategy('sce');
    $translateProvider.preferredLanguage('en');
    $translateProvider.fallbackLanguage('en');
    $translateProvider.useLoader('customTranslationLoader');
    // TO-DO: partial loading as in https://angular-translate.github.io/docs/#/guide/12_asynchronous-loading
  }
}());

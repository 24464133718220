(function () {
  'use strict';
  
  angular
  .module('sowReports')
  .controller('ImplantsPlacementpatientController', ImplantsPlacementpatientController);
  
  function ImplantsPlacementpatientController ($scope, $state, orderByFilter, sowImplantPlacementService, $filter) {
    /*jshint validthis:true*/
    var ctrl = this;
    var t_patient = $filter('translate')('REPORTS.PLACEMENT.PATIENT_NAME');
    var t_refdoc = $filter('translate')('COMMON.REF_DOC');
    var t_surgeon = $filter('translate')('REPORTS.PLACEMENT.SURGEON_NAME');
    var t_lot = $filter('translate')('COMMON.LOT_NUMBER');
    var t_all = $filter('translate')('COMMON.ALL');


    ctrl.propertyName = 'patient_name';
    ctrl.reverse = true;
    ctrl.searchType = 'patient_name';
    ctrl.placeHolder = t_patient;
    
    ctrl.endDate = moment().endOf('day');
    ctrl.rangeOptions = [];
    ctrl.typeSearch = [
      {
        type: 'patient_name',
        value: t_patient,
        placeHolder: t_patient
      },
      {
        type: 'referring_doctor_name',
        value: t_refdoc,
        placeHolder: t_refdoc
      },
      {
        type: 'surgeon_name',
        value: t_surgeon,
        placeHolder: t_surgeon
      },
      {
        type: 'lot_number',
        value: t_lot,
        placeHolder: t_lot
      }
    ];
    ctrl.timeRange = [
      {
        name: t_all,
        value: 'all'
      },
      {
        name: 'YTD',
        value: 'ytd'
      },
      {
        name: '1YR',
        value: '1YR'
      }
    ];
    ctrl.timeRangeSelected = 'all';

    ctrl.changePatientData = changePatientData;
    ctrl.changeSearchType = changeSearchType;
    ctrl.changeSeletedTime = changeSeletedTime;
    ctrl.dataReport = dataReport;
    ctrl.getPatientReport = getPatientReport;
    ctrl.gotoDetail = gotoDetail;
    ctrl.search = search;
    ctrl.sortBy = sortBy;

    init();

    return ctrl;
    
    function changeSearchType () {
      ctrl.typeSearch.forEach(function(el){
        if(el.type == ctrl.searchType){
          ctrl.placeHolder = el.placeHolder;
        }
      });
    }
    
    function search (value, index, arr) {
      var keyword =  ctrl.searchBy;
      if(keyword===undefined || keyword===''){
        return true;
      }
      var regex = new RegExp(keyword, 'i');
      var searchValue = value[ctrl.searchType];
      // if(ctrl.searchType == 'referring_doctor' || ctrl.searchType == 'surgeon_name'){
      //   var t = '';
      //   searchValue.forEach(function(el){
      //     t += el.name + ' ';
      //   });
      //   searchValue = t;
      // }else 
      if(ctrl.searchType == 'lot_number'){
        var t = '';
        searchValue.forEach(function(el){
          t += el + ' ';
        });
        searchValue = t;
      }
      
      return regex.test(searchValue);
    }
    
    function init () {
      ctrl.first_date = moment($scope.$root.current_office.created_at);
      ctrl.startDate = angular.copy(ctrl.first_date);
      ctrl.getPatientReport();
    }
    
    function getPatientReport () {
      var start_date = moment(ctrl.startDate).format('YYYY-MM-DD');
      var end_date = moment(ctrl.endDate).format('YYYY-MM-DD');
      sowImplantPlacementService.getPatientReport(start_date, end_date).then(function(result){
        ctrl.patientData = ctrl.changePatientData(result);
        ctrl.patientReport = ctrl.dataReport(result);
        
        ctrl.sortBy(ctrl.propertyName, true);
      })
    }
    
    function dataReport (result) {
      var d = {
        patient_count: result.patient_count,
        previous_patient_count: result.previous_patient_count,
        percent: {}
      }
      
      var c = result.patient_count.count;
      var p = result.previous_patient_count.count;
      
      var t = '';
      var color = '';
      
      if (c == 0 && p == 0) {
        t = '-';
        color = 'df-color';
      } else if (c != 0 && p == 0) {
        t = '+';
        color = 'green-color';
      } else {
        t = (c - p) * 100;
        if (p != 0 || t != 0) {
          t = t / p;
        }
        if (t > 0) {
          color = 'green-color'
        } else if (t < 0) {
          color = 'red-color'
        } else {
          color = 'df-color'
        }
        
      }
      
      d.percent.count = t;
      d.percent.color = color;
      d.percent.year = result.patient_count.year + ' vs ' + result.previous_patient_count.year;
      
      
      return d;
    }
    
    function changePatientData (result){
      var rs = result.patient_report;
      var pArr = [];
      rs.forEach(function(el){
        var referring_doctor_name = '';
        var surgeon_name = '';
        var t_multiple = $filter('translate')('COMMON.MULTIPLE');
        if (!el.patient?.name) {
          return;
        }
        if(el.referring_doctor.length == 1){
          referring_doctor_name = el.referring_doctor[0].name;
        }else if(el.referring_doctor.length > 1){
          referring_doctor_name = t_multiple;
        }
        
        if(el.surgeon_name.length == 1){
          surgeon_name = el.surgeon_name[0].name;
        }else if(el.surgeon_name.length > 1){
          surgeon_name = t_multiple;
        }
        
        var p = {
          patient_name: el.patient.name,
          patient_id: el.patient.id || el.patient.name,
          referring_doctor: el.referring_doctor,
          referring_doctor_name: referring_doctor_name,
          surgeon: el.surgeon_name,
          surgeon_name: surgeon_name,
          product_placement:el.product_placement,
          lot_number: el.lot_number,
          last_placement: moment(el.last_placement, 'YYYY-MM-DD').format('DD MMM, YYYY')
        }
        pArr.push(p);
      });
      
      return pArr;
    }
    
    function changeSeletedTime (val) {
      ctrl.timeRangeSelected = val;
      if(val == 'all'){
        ctrl.startDate = angular.copy(ctrl.first_date);
        ctrl.endDate = moment().endOf('day');
      }else if(val == 'ytd'){
        ctrl.startDate = moment().startOf('year');
        ctrl.endDate = moment().endOf('day');
      }else{
        ctrl.startDate = moment().subtract(1, 'y').endOf('day');
        ctrl.endDate = moment().endOf('day');
      }
      
      ctrl.getPatientReport();
    }
    
    function gotoDetail ({patient_id, patient_name}) {
      $state.go('app.reports.patient-detail',{patient_id, patient_name});
    }
    
    function sortBy (propertyName, dont_reverse) {
      if(dont_reverse) {
        ctrl.reverse = false;
      } else {
        ctrl.reverse = (ctrl.propertyName === propertyName) ? !ctrl.reverse : false;
      }
      ctrl.propertyName = propertyName;
      var allProduct = ctrl.patientData;
      if (allProduct.length > 0) {
        ctrl.patientData = orderByFilter(allProduct, ctrl.propertyName, ctrl.reverse);
      }
    }
    
  }
}());

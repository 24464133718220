angular.module('app.marketplace.ui.registration')

.constant('HowDidYouHearOptions', [
  'Search Engine',
  'Healthcare Association',
  'Social Media',
  'Event/Conference',
  'Word of Mouth',
  'Advertisement / Flyer',
  'Coupon',
  'Other',
])

;

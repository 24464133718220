(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.subscriptions')
    .directive('premiumPlusPlan', premiumPlusPlanDirective);

  function premiumPlusPlanDirective ($log, $filter) {
    return {
      'restrict': 'E',
      'replace': true,
      'controller': 'sowPlanController',
      'controllerAs': 'planCtrl',
      'templateUrl': 'templates/marketplace/subscriptions/plan-style-b.html',
      'scope': {
        'selectedPlan': '=',
        'associationId': '=',
        'isCurrent': '@',
        'isLarge': '@',
        'isTrial': '@',
        'isDowngrade': '@',
        'action': '&',
        'noButton': '@',
        'discountValue': '@',
      },
      'link': function ($scope, $element, $attrs, $ctrl) {
        var isCurrent;
        var tagline = $scope.associationId === 'aaoms'
          ? 'Optimized for Oral Surgery Practices'
          : 'Implant Management & Practice Performance Monitoring';

        $ctrl.init({
          'class': 'premium-plus-plan',
          'name': 'Premium Plus',
          'tagline': tagline,
          'pitch': ' ',
        });


        function updateState () {
          isCurrent = $scope.isCurrent ? $scope.$parent.$eval($scope.isCurrent) : false;
          $scope.buttonText = isCurrent ? 'Current Plan' : 'Upgrade to Premium Plus';
        }

        $scope.$watch('isCurrent', updateState);

      }
    };
  }
}());

(function () {
    'use strict';
    angular.module('sowMarketplace')
    .controller('ProductDetailSlideoutController', ProductDetailSlideoutController)
    .directive('sowSlideoutMktDetails', sowSlideoutMktDetails);

    function sowSlideoutMktDetails () {
      return {
        replace: true,
        restrict: 'E',
        templateUrl: 'sow-mkt/slideouts/mkt-product-detail-slideout.html',
        controller: 'ProductDetailSlideoutController',
        controllerAs: 'mktpdCtrl',
        // link: function ($scope, $element, $attrs) {}
      };
    }

  function ProductDetailSlideoutController($scope, $rootScope, $window, $mdSidenav, $filter, errorService, appEvents, mktHelperService, sowMktService, sowAnalyticsService, AccessService, ProductExternalVendorsService, sgToast, ProductHelperService, productDetailsService, coreUIService) {
      /*jshint validthis: true*/
      const ctrl = this;

      ctrl.selected_vendor = null;
      ctrl.close = close;
      ctrl.setImage = setImage;
      ctrl.getSds = getSds;
      ctrl.toggleFavouriteProduct = toggleFavouriteProduct;
      ctrl.goToProductDetailsPage = goToProductDetailsPage;
      ctrl.localize = mktHelperService.getLocalizedProp;
      ctrl.handleConnectionModalEvents = handleConnectionModalEvents;
      ctrl.shouldShowCta = shouldShowCta;
      ctrl.$onInit = init;

      const PRIMARY_BTN_CLASS = 'sow-primary-btn';
      const SECONDARY_BTN_CLASS = 'sow-secondary-btn';

      const VENDOR_BUTTON = {
        ADD_TO_CART: {
          locale_key: 'ACTIONS.ADD_TO_CART',
          class_list: PRIMARY_BTN_CLASS,
          handleClick: _openAddToCartModal,
        },
        SOLD_OUT: {
          locale_key: 'MARKETPLACE.DETAIL.SOLD_OUT',
          is_disabled: true,
          class_list: PRIMARY_BTN_CLASS,
        },
        DISCONTINUED: {
          locale_key: 'MARKETPLACE.DETAIL.DISCONTINUED',
          is_disabled: true,
          class_list: PRIMARY_BTN_CLASS,
        },
        VIEW_DETAILS: {
          locale_key: 'ACTIONS.VIEW_DETAILS',
          class_list: SECONDARY_BTN_CLASS,
          handleClick: goToProductDetailsPage,
        },
        CONNECT_ACCOUNT: {
          locale_key: 'ACTIONS.CONNECT_ACCOUNT',
          class_list: SECONDARY_BTN_CLASS,
          handleClick: _openConnectionModal,
        },
        ADD_TO_SHOPPING_LIST: {
          locale_key: 'ACTIONS.ADD_TO_LIST',
          class_list: PRIMARY_BTN_CLASS,
          handleClick: _addToShoppingList,
        },
      };

      // Expose Private Methods ONLY FOR TESTING
      ctrl._openConnectionModal = _openConnectionModal;
      ctrl._fetchConnectedVendorPrice = _fetchConnectedVendorPrice;
      ctrl._addVendorsCustomUIProps = _addVendorsCustomUIProps;
      ctrl._generateVendorStatus = _generateVendorStatus;
      ctrl._hideModal = _hideModal;
      ctrl._loadExternalAndOfficialVendors = _loadExternalAndOfficialVendors;
      ctrl._generateExternalVendorButton = _generateExternalVendorButton;
      ctrl._openAddToCartModal = _openAddToCartModal;

      return ctrl;

      function init () {
        $scope.$on(appEvents.mktProductDetailsOpen, open);
        $scope.$on(appEvents.mktProductDetailsOpenFetch, loadProduct);
        $rootScope.$watch('external_vendors', () => _updateExternalVendorsList());
        $rootScope.$watch('external_vendors_actions', () => _updateExternalVendorsList());
        _defineLocks();
      }

      function setImage (img) {
        ctrl.current_image = $filter('imageUrl')(_.get(img, 'image'));
      }

      function open (event, product) {
        _defineLocks();
        ctrl.product = angular.copy(product);

        if (!ctrl.is_add_to_shopping_list_slideout) {
          loadRelatedProducts();
        }

        _loadExternalAndOfficialVendors();
        setImage(_.get(product,'images[0]', null));
        $mdSidenav('mkt-details').onClose(setEmptyState);
        return $mdSidenav('mkt-details').open();
      }

      function close () {
        // erase current product's data before closing, so it doesn't show up for a moment when the next product is opened
        setEmptyState();
        return $mdSidenav('mkt-details').close();
      }

      function setEmptyState () {
        _.set(ctrl, 'product', null);
        _.set(ctrl, 'all_vendors', null);
        _.set(ctrl, 'related_products', null);
        _.set(ctrl, 'current_image', null);
      }

      function goToProductDetailsPage(_event, {product = ctrl.product} = {}) {
        return mktHelperService.goToProductDetailsPage(product);
      }

      function _addToShoppingList (_$event, {vendor_item}) {
        vendor_item.UI.button.is_disabled = true;
        let promise;
        const type = productDetailsService.getProductType(ctrl.product);
        if (type === 'implant') {
          promise = productDetailsService.addProductToShoppingList(ctrl.product, 'implant_inventory_status', vendor_item);
        } else {
          promise = productDetailsService.addToShoppingList(ctrl.inventory_item_id, vendor_item);
        }
        return promise.then(() => {
          // Close the slideout after adding to shopping list
          ctrl.close();
          // Broadcast an event indicating that the product was added to the shopping list
          $rootScope.$broadcast('shopping_list_updated');
        }).finally(() => {
          vendor_item.UI.button.is_disabled = false;
        })
      }

      function _openAddToCartModal ($event, {vendor_item}) {
        const product = angular.copy(ctrl.product);
        return close().then(function(){
          sowAnalyticsService.logMktSlideoutAddToCart(vendor_item);
          return mktHelperService.addToCartQuantityDialog($event, product, vendor_item);
        });
      }

      function getSds () {
        const url = ctrl.product.sds_url;
        $window.open(url, '_blank');
      }

      function loadProduct ($event, id, {action, item} = {}) {
        if (ctrl.loading || !id) {
          return;
        }
        ctrl.loading = true;
        ctrl.inventory_item_id = item?.id;
        ctrl.is_add_to_shopping_list_slideout = action === 'ADD_TO_SHOPPING_LIST';

        return sowMktService.fetchProduct(id)
        .then(function(response){
          ctrl.product = mktHelperService.setProductInfo(response.products?.[0]);
          if (!ctrl.product) {
            showProductNotFoundDialog();
            return response;
          }
          return open($event, ctrl.product);
        })
        .catch(function(err){
          errorService.uiErrorHandler(err);
        })
        .finally(function(){
          ctrl.loading = false;
          $rootScope.$broadcast(appEvents.mktProductDetailsOpenFinished);
        });
      }

      function loadRelatedProducts () {
        ctrl.loading_related = true;
        return sowMktService.getRelatedProducts(ctrl.product.id)
        .then(function(response){
          //return response;
          ctrl.related_products = _.get(response, 'results[0].hits', []);
        })
        .catch(errorService.uiErrorHandler)
        .finally(function(){
          ctrl.loading_related = false;
        });
      }


      /**
       * Displays the information dialog when the product is not found on the marketplace.
       * Note: API returns the algolia search empty response when the product is not found.
       */
      function showProductNotFoundDialog() {
        const title = 'MARKETPLACE.DETAIL.NOT_FOUND';
        const description = 'ERRORS.PRODUCT_NOT_FOUND_ON_MARKETPLACE';
        const actions = [
          coreUIService.primaryButton('ACTIONS.DISMISS'),
        ];
        const hide_close_btn = true;
        coreUIService.showDialog(
          title,
          description,
          actions,
          hide_close_btn
        );
      }

      function toggleFavouriteProduct (product) {
        return sowMktService.toggleFavourite(product);
      }

      /**
       * Checks the UI properties of the vendor object to determine if the CTA should be shown.
       * @param {object} vendor_item
       * @param {{has_error: {boolean}, is_loading: {boolean}, is_disconnected: {boolean}}} vendor_item.UI
       * @returns a boolean value.
       */
      function shouldShowCta (vendor_item) {
        // Shows the cta when the vendor is not loading or there is no error and the vendor is disconnected.
        return !(vendor_item.UI.is_loading || (vendor_item.UI.has_error && !vendor_item.UI.is_disconnected))
      }

      /**
       * If onClose is true (when the user clicks cancel or 'x' icon) -> Hide the modal.
       * If toast message exists -> Display a toast notification.
       * If the externalVendor is connected -> calls the method to fetch the connected vendor pricing.
       * @param {object} $event
       * @param {object} $event.detail
       * @param {boolean} $event.detail.onClose
       * @param {string} $event.detail.toastMessage
       * @param {object} $event.detail.externalVendor
       */
      function handleConnectionModalEvents($event) {
        const {
          detail: {onClose, toastMessage, externalVendor},
        } = $event;
        if (onClose) {
          _hideModal();
        }
        if (toastMessage) {
          sgToast.showSimple(toastMessage);
        }

        if (ProductExternalVendorsService.isVendorConnected(externalVendor?.account)) {
          _hideModal();
          ProductExternalVendorsService.updateRootScopeExternalVendors(externalVendor);
          _fetchConnectedVendorPrice(externalVendor);
        }
      }

      /**
       * Fetches the price of a connected vendor, and updates the list of vendors with the new states
       * @param {object} connected_vendor
       */
      async function _fetchConnectedVendorPrice(connected_vendor) {
        const vendor_to_update = ctrl.all_vendors.find((vendor) => vendor.UI.id === connected_vendor.account.vendor_id);
        if(vendor_to_update) {
          vendor_to_update.UI.is_loading = true;
          const updated_vendors = await ProductExternalVendorsService.getConnectedVendorPricing(
            ctrl.all_vendors,
            connected_vendor,
          );
          _addVendorsCustomUIProps(updated_vendors);
        }
      }

      /**
       * Sets some custom UI properties for each vendor
       * @param {array} vendors - An array of vendor objects.
       */
      function _addVendorsCustomUIProps(vendors, refresh_list = true) {
        // This prop is used on the UI to set a dynamic column width when the list has vendors with promos
        ctrl.has_vendors_with_promo = vendors.filter((vendor) => vendor.UI.on_sale).length > 0;

        // Sets custom UI properties
        vendors.forEach((vendor) => {
          vendor.UI.status = _generateVendorStatus(vendor);
          vendor.UI.button = _generateExternalVendorButton(vendor);
        });

        ctrl.all_vendors = vendors;

        if (refresh_list) {
          // force rerender to ensure updated vendors
          $scope.$apply();
        }
      }

      /**
      * Checks the vendor UI properties to return a status to be used on the UI
      * @param {object} vendor
      */
      function _generateVendorStatus(vendor) {
        // Case 1: return early for government products and official vendors
        if (!vendor.UI.is_external) {
          return null;
        }

        // Case 2: Vendor got an error fetching the pricing
        if (vendor.UI.has_error) {
          return 'EXTERNAL_VENDORS.CONNECTION_FAILED';
        }

        // Case 3: Vendor is without price and disconnected
        if (!mktHelperService.doesVendorHavePrice(vendor) && vendor.UI.is_disconnected) {
          return 'ACTIONS.CONNECT_ACCOUNT';
        }
      }

      /**
       * Returns the appropriate button based on the vendor's status or UI props
       * @param {object} vendor
       * @param {object} product
       */
      function _generateExternalVendorButton(vendor, product = ctrl.product) {
        if (ctrl.is_add_to_shopping_list_slideout) {
          return VENDOR_BUTTON.ADD_TO_SHOPPING_LIST;
        }

        switch (vendor.UI.product_status) {
          case ProductHelperService.PRODUCT_STATUS.SOLD_OUT:
            return {
              ...VENDOR_BUTTON.SOLD_OUT,
              class_list: _getStockStatusClassName(vendor),
            }
          case ProductHelperService.PRODUCT_STATUS.DISCONTINUED:
            return {
              ...VENDOR_BUTTON.DISCONTINUED,
              class_list: _getStockStatusClassName(vendor),
            }
          default:
            if (_shouldShowAddToCartButton(vendor, product)) {
              return VENDOR_BUTTON.ADD_TO_CART;
            }
            return _getExternalVendorButton(vendor)
        }
      }

      /**
       * If the vendor is external and disconnected/with error, return a button to connect the account.
       * @param {object} vendor
       * @returns {VENDOR_BUTTON.CONNECT_ACCOUNT | VENDOR_BUTTON.VIEW_DETAILS}
       */
      function _getExternalVendorButton(vendor) {
        if (vendor.UI.is_external) {
          // Case 1:  API UI flag is disable_add_to_cart or external vendor price equals to 0
          if(vendor.UI.actions?.UI_flags?.disable_add_to_cart && mktHelperService.doesVendorHavePrice(vendor)) {
            return VENDOR_BUTTON.VIEW_DETAILS;
          }

          // Case 2: Vendor is disconnected
          if (vendor.UI.is_disconnected) {
            return {
              ...VENDOR_BUTTON.CONNECT_ACCOUNT,
              is_disabled: ctrl.disable_manage_accounts,
            }
          }
        }

        return VENDOR_BUTTON.VIEW_DETAILS;
      }

      /**
       * If vendor is official check the product, user permissions and vendor price
       * If vendor is external, the same logic as the official, plus the connection and the UI_flags
       * @param {object} vendor
       * @param {object} product
       * @returns {boolean}
       */
      function _shouldShowAddToCartButton(vendor, product) {
        const {is_external, is_disconnected, actions} = vendor.UI;
        const can_checkout_and_has_cart_access =
          product?.UI?.can_checkout && ctrl.has_cart_access && mktHelperService.doesVendorHavePrice(vendor);
    
        if (is_external) {
          const is_connected = !is_disconnected;
          const has_add_to_cart_action = !actions?.UI_flags?.disable_add_to_cart;
          return is_connected && has_add_to_cart_action && can_checkout_and_has_cart_access;
        }
    
        return can_checkout_and_has_cart_access;
      }

      /**
       * Checks the external vendor UI_flags to return a class name for a button
       * @param {object} vendor
       * @returns {SECONDARY_BTN_CLASS|PRIMARY_BTN_CLASS}
       */
      function _getStockStatusClassName(vendor) {
        const is_external_and_cart_disabled = vendor.UI.is_external && vendor.UI.actions?.UI_flags?.disable_add_to_cart;
        return is_external_and_cart_disabled ? SECONDARY_BTN_CLASS : PRIMARY_BTN_CLASS;
      }

      /**
       * It fetches the external vendors for the product, and calls the method to add the custom UI
       */
      async function _loadExternalAndOfficialVendors() {
        const official_vendors_sorted = ctrl.product.sorted_vendors;
        _addVendorsCustomUIProps(official_vendors_sorted, false);

        const { has_external, vendors } = await ProductExternalVendorsService.getAvailableVendorsForProduct(ctrl.product.id, official_vendors_sorted);
        _addVendorsCustomUIProps(vendors);

        if(has_external) {
          // Fetch the pricing data for all the external vendors and updates the external vendors list
          const updated_vendors = await ProductExternalVendorsService.getAllExternalVendorPricing(ctrl.all_vendors);
          _addVendorsCustomUIProps(updated_vendors);
        }
      }

      function _updateExternalVendorsList() {
        // if the external_vendors are updated we need to update the list only if a product exists
        if (ctrl.product) {
          _loadExternalAndOfficialVendors();
        }
      }

      function _hideModal() {
        ctrl.selected_vendor = null;
      }

      function _openConnectionModal(_$event, {vendor_item}) {
        sowAnalyticsService.logOpenDialogConnectVendor({
          vendor_name: vendor_item.vendor.name,
        })
        const selected_vendor_id = vendor_item.UI.id;
        ctrl.selected_vendor = $rootScope.external_vendors[selected_vendor_id];
      }

      function _defineLocks () {
        ctrl.has_cart_access = AccessService.hasCartAccess();
        ctrl.disable_manage_accounts = AccessService.getProperty('external_vendors.disable_manage_accounts');;
      }
  }

}());

(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.orders-aaoms')
    .controller('OrdersAaomsActionBarController', OrdersAaomsActionBarController)
    .directive('actionBarOrdersAaoms', actionBarOrdersAaoms);

  function actionBarOrdersAaoms () {
    return {
      restrict: 'AC',
      replace: true,
      templateUrl: 'templates/marketplace/orders-aaoms/directives/action-bar-orders-aaoms.html',
      controller: 'OrdersAaomsActionBarController',
    };
  }

  // $scope, $mdMenu, categoryService, vendorService, NavDropdownMenuService 
  function OrdersAaomsActionBarController () {
    /*jshint validthis: true */
    var ctrl = this;

    return ctrl;

  }

}());

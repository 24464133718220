(function () {
  'use strict';

  angular.module('sowMarketplace')
  .controller('marketplacePromoDetailsController', marketplacePromoDetailsController)
  .directive('mktPromoDetails', marketplacePromoDetailsDirective);

  function marketplacePromoDetailsDirective () {
    return {
      restrict: 'E',
      templateUrl: 'sow-mkt/directives/mkt-promo-details.html',
      controller: 'marketplacePromoDetailsController',
      controllerAs: 'mktpdCtrl',
      scope: {
        item: '<',
        type: '@',
        text: '@'
      },
    };
  }

  function marketplacePromoDetailsController ($scope, $rootScope) {
    const ctrl = this;

    ctrl.toggleOpen = toggleOpen;
    ctrl.setIsDialogVisible = setIsDialogVisible;
    ctrl.handleInformationDialogEvent = handleInformationDialogEvent;

    ctrl.$onInit = init;

    return ctrl;

    function init () {
      ctrl.open = false;
      setIsDialogVisible(false);
      _setPromoLinkText();
    }

    /**
     * Sets the locale key for the promo info link based on the text binding.
     * @param {'details'|undefined} text
     */
    function _setPromoLinkText(text = $scope.text) {
      ctrl.promo_link_locale_key = text === 'details' ? 'COMMON.DETAILS' : 'MARKETPLACE.DETAIL.PROMO';
    }

    /**
     * Toggles the visibility of the promo tooltip.
     */
    function toggleOpen() {
      ctrl.open = !ctrl.open;
    }

    /**
     * Shows or hides the promo dialog.
     * @param {boolean} is_open
     */
    function setIsDialogVisible(is_open) {
      $rootScope.$broadcast('promo-details-dialog: visibility', {is_dialog_visible: is_open})
      ctrl.is_dialog_visible = Boolean(is_open);
    }

    /**
     * If an `onClose` event is emitted, hides the promo dialog.
     * @param {true|undefined} event.detail.onClose
     */
    function handleInformationDialogEvent({detail: {onClose}}) {
      if (onClose) {
        setIsDialogVisible(false);
      }
    }
  }

})();

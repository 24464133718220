angular.module("app.marketplace.ui.office")

.controller("officeController", function($scope, elementService, $mdDialog) {
  // Used instead of ui-sref so that angular-material's directive and styling
  // can be used. (Only triggers if there is an ng-click attribute)
  $scope.stateNav = function(newState){
    $state.go(newState);
  };
})

;

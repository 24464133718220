(function () {
  'use strict';
  angular.module("app.marketplace.ui.medications")
    .directive('actionBarMedications', medABDirective);

  function medABDirective() {
    return {
      restrict: 'AC',
      templateUrl: 'templates/marketplace/medications/directives/action-bar-medications.html',
      controller: 'medicationsActionBarController',
      controllerAs: 'medAbCtrl'
    };
  }

}());

(function () {
  'use strict';

  angular.module('sowInventory')
    .directive('sowAgItemOptions', sowInventoryAgGridItemOptionsDirective);

  function sowInventoryAgGridItemOptionsDirective() {
    return {
      restrict: 'E',
      replace: true,
      templateUrl: 'sow-inventory/ag-directives/ag-item-options.html',
      controller: 'sowInventoryAgItemOptionsController',
      controllerAs: 'aiopCtrl',
      scope: {
        'item': '=',
      }
    };
  }

}());

(function () {
    'use strict';
  
    angular.module('sowProduct')
    .controller('pdNeedHelpController',pdNeedHelpController)
      .directive('pdNeedHelp', pdNeedHelpDirective);
  
    function pdNeedHelpDirective() {
      return {
        restrict: 'E',
        templateUrl: 'sow-product-details/directives/pd-need-help.html',
        controller: 'pdNeedHelpController',
        controllerAs: 'pdnhCtrl',
      };
    }

    function pdNeedHelpController () {
      var ctrl = this;

      ctrl.openChat = openChat;

      return ctrl;

      /** 
       * Opens the Zopim chat overlay. 
       * 
       * @return {*} 
      */
      function openChat () {
        $zopim.livechat.window.show();
      }
    }
  
  })();

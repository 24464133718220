angular.module('app.marketplace.elements').service('subscriptionModel',
  function subscriptionModel(apiUrl, commonApiService) {
    var _this = this;
    this.model_data = {
      name: 'subscription',
      display_name: 'Subscription',
      api: {
        'current_subscription': function (options) {
          if (!checkNested(options, 'office_id')) {
            throw 'Missing option(s) for subscription path "current_subscription".';
          }
          return {
            'path': '{0}/offices/{1}/subscriptions/current'.format(apiUrl, options.office_id),
            'params': {'method': 'GET'},
          };
        },

        'downgrade_refund': function (options) {
          return {
            'path': '{0}/downgrade_refund'.format(apiUrl),
            'params': {
              'method': 'POST',
              'data': {
                'plan_hrid': options.planHrid,
                'billing_cycle': options.billingCycle,
              }
            }
          };
        },


        'update_subscription': function (options) {
          // var hasPlanAndCard = (options.plan_id && options.card_id);
          // var noPlanAndCard = (!options.plan_id && !options.card_id);

          // if (!(hasPlanAndCard || noPlanAndCard)) {
          //   throw 'Missing option(s) for subscription path "create_subscription".';
          // }

          return {
            'path': '{0}/subscriptions'.format(apiUrl),
            'params': {
              'method': 'POST',
              'data': {
                'feedback': options.feedback,
                'credit_card_id': options.card_id,
                'subscription_plan_id': options.plan_id,
                'subscription_promotion_id': options.promo_code,
              },
            },
          };
        },

        //
        // Query the list of subscription plans.
        //
        subscription_plans: function (options) {
          var params = {};

          if (_.has(options, 'country_id')) {
            params.country_id = options.country_id;
          }

          if (_.has(options, 'province_id')) {
            params.province_id = options.province_id;
          }

          return {
            path: '{0}/subscription_plans'.format(apiUrl),
            params: {
              method: 'GET',
              params: params,
            }
          };
        },

        //
        // Fetch Subscription Promo Codes
        //
        subscription_promotions: function (options) {
          var params = {};

          if (_.has(options, 'professional_association_id')) {
            params.professional_association_id = options.professional_association_id;
          }

          if (_.has(options, 'country_id')) {
            params.country_id = options.country_id;
          }

          if (_.has(options, 'code')) {
            params.code = options.code;
          }

          return {
            path: '{0}/subscription_promotions'.format(apiUrl),
            params: {
              method: 'GET',
              params: params,
            }
          };
        },

        //
        // Validate Subscription Promotional Codes
        //
        validate_subscription_promo_code: function (options) {
          if (!_.has(options, 'professional_association_id')) {
            throw "Missing 'professional_association_id' for subscription path 'validate_subscription_promo_code'. Options: " + JSON.stringify(options);
          }

          if (options.code === undefined) {
            throw "Missing option(s) for subscription path 'validate_subscription_promo_code'. Options: " + JSON.stringify(options);
          }

          if (!checkNested(options, 'country_id')) {
            throw "Missing option(s) for subscription path 'validate_subscription_promo_code'. Options: " + JSON.stringify(options);
          }

          return {
            path: apiUrl + '/subscriptions/validate_promo_code',
            params: {
              method: "POST",
              data: {
                code: options.code,
                country_id: options.country_id,
                professional_association_id: options.professional_association_id
              }
            }
          };
        },
        
        failed_payments: function (options) {
          if (!checkNested(options, 'office_id')) {
            throw 'Missing option(s) for subscription path "failed_payments".';
          }
          return {
            'path': '{0}/offices/{1}/subscriptions/failed_payments'.format(apiUrl, options.office_id),
            'params': {'method': 'GET'},
          };
        },
        retry_payment: function (options) {
          if (!checkNested(options, 'failed_payment_id') || !checkNested(options, 'stripe_card')) {
            throw 'Missing option(s) for subscription path "retry_payment".';
          }
          
          return {
            path: commonApiService.getOfficeUrl() + '/subscriptions/failed_payments/' + options.failed_payment_id + '/retry',
            params: {
              method: "POST",
              data: {
                stripe_card: options.stripe_card
              }
            }
          };
        },
        
        
        upgrade_cost: function (options) {
          return {
            path: apiUrl + '/upgrade_cost',
            params: {
              method: "POST",
              data: {
                stripe_coupon_id: options.stripe_coupon_id
              }
            }
          };
        }
        
        
      }
    };
  }
);

(function () {
  'use strict';

  angular.module('app.marketplace.ui.medical_field_selector')
         .directive('medicalFieldSelector', medicalFieldSelectorDirective);

  function medicalFieldSelectorDirective (ConfigService) {
    return {
      restrict: 'E',
      replace: true,
      template: `
        <div class="medical-field-selector" layout layout-wrap layout-align="center center">
          <div class="field-type" layout layout-wrap layout-align="center center" ng-repeat="field in fields">
            <div flex="100" layout layout-align="center center">
              <div class="field-type-icon" ng-class="iconClass(field, $index)" ng-click="selectField(field)" name="medicalFieldSelector-btn-{{field.id}}">
                <span class="png-icon inactive"><img ng-src="styles/img/medical-fields/{{field.id}}-grey.png"></span>
                <span class="png-icon hover"><img ng-src="styles/img/medical-fields/{{field.id}}-white.png"></span>
              </div>
            </div>
            <div class="centered-text text-nowrap" flex="100">
              {{'MEDICAL_FIELDS.'+field.name | translate}}
            </div>
          </div>
        </div>
      `,
      scope: {
        'select': '&',
        'selectedField': '=', // TODO Re-evaluate the need for this.
      },
      link: function ($scope) {

        $scope.fields = ConfigService.get('medical_fields');
        $scope.iconClass = iconClass;
        $scope.selectField = selectField;

        return;

        function iconClass (field, index) {
          var colour = (index % 5);

          return {
            'blue-icon': colour === 0,
            'green-icon': colour === 1,
            'red-icon': colour === 2,
            'purple-icon': colour === 3,
            'orange-icon': colour === 4
          };
        }

        function selectField (field) {
          $scope.select({'field': field});
        }
      }
    };
  }

}());

(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.registration')
    .service('AssociationService', AssociationService);



  function AssociationService ($filter, $q, AssociationVerificationDialog, $rootScope) {

    var VerificationDialogConfig = {
      'dao': { // includes both DAO and CDO
        label: 'Denturist Association of Ontario (DAO) or Ontario-registered Denturists',
        logo: 'styles/img/logos/partners/dao_logo.full.png',
        id_label_prefix: 'CDO Registration/DAO',
        member_id_label: 'SIGNUP.MEMBER_NUMBER',
        member_id_placeholder: 'XXXXX',
        credential_label: 'SIGNUP.LAST_NAME',
        description: [
          '<span>',
          $filter('translate')('SIGNUP.EXCLUSIVE_BENEFIT', { 'x': 'Ontario-registered Denturist/DAO' }) + $filter('translate')('SIGNUP.QUALIFY_FOR', { 'x': 'Ontario-registered Denturists/DAO' }),
          '</span>',
          '<ul class="feature-list">',
          '  <li>' + $filter('translate')('SIGNUP.MKT_ACCESS_2', { 'x': 'DAO/Ontario-registered Denturists' }) + '</li>',
          // SPD-3816 '  <li>' + $filter('translate')('SIGNUP.FREE_TRIAL', { 'x': '14' }) + '</li>',
          '</ul>',
        ].join("\n"),
        verify: ({ member_id, credential }, $injector) => {
          const { validateDAO } = $injector.get('sowAssociationService'); // we use the same method for DAO or CDO members
          return validateDAO(member_id, credential)
            .then(({ result, data }) => ({ result, data }));
        },
      },

      'adto': {
        label: 'Association of Dental Technologists of Ontario (ADTO)',
        logo: 'styles/img/logos/partners/adto_logo.full.png',
        id_label_prefix: 'CDTO',
        member_id_label: 'SIGNUP.REGISTRATION_#',
        member_id_placeholder: 'XXXX',
        credential_label: 'SIGNUP.LAST_NAME',
        description: [
          '<span>',
          $filter('translate')('SIGNUP.EXCLUSIVE_BENEFIT', { 'x': 'ADTO' }) + $filter('translate')('SIGNUP.QUALIFY_FOR', { 'x': 'ADTO' }),
          '</span>',
          '<ul class="feature-list">',
          '  <li>' + $filter('translate')('SIGNUP.MKT_ACCESS_2', { 'x': 'ADTO' }) + '</li>',
          // SPD-3816 '  <li>' + $filter('translate')('SIGNUP.FREE_TRIAL', { 'x': '14' }) + '</li>',
          '</ul>',
        ].join("\n"),
        verify: ({ member_id, credential }, $injector) => {
          const { validateADTO } = $injector.get('sowAssociationService');
          return validateADTO(member_id, credential)
            .then(({ result, data }) => ({ result, data }));
        },
      },

      'oda': {
        label: 'Ontario Dental Association (ODA)',
        logo: 'styles/img/logos/partners/oda_logo.svg',
        id_label_prefix: 'ODA',
        member_id_label: 'SIGNUP.MEMBER_NUMBER',
        member_id_placeholder: '06XXXXX or 36XXXXX',
        credential_label: 'SIGNUP.DENTIST_NAME',
        description: [
          '<span>',
          $filter('translate')('SIGNUP.EXCLUSIVE_BENEFIT', {'x': 'ODA'}) + $filter('translate')('SIGNUP.QUALIFY_FOR', {'x': 'ODA'}),
          '</span>',
          '<ul class="feature-list">',
          '  <li>' + $filter('translate')('SIGNUP.MKT_ACCESS', {'x': 'ODA'}) + '</li>',
          '  <li>' + $filter('translate')('SIGNUP.PERCENT_OFF', {'x': '35'}) + '</li>',
          // SPD-3816 '  <li>' + $filter('translate')('SIGNUP.FREE_TRIAL', {'x': '14'}) + '</li>',
          '</ul>',
          '<span>',
          $filter('translate')('SIGNUP.LEARN_MORE', {'x': 'ODA'}),
          '  <a href="https://sowingo.com/oda" target="_blank" name="{{btnPrefix}}-link-aboutOda">sowingo.com/oda</a>',
          '</span>',
        ].join("\n"),
        verify: function (data, $injector) {
          var registrationService = $injector.get('registrationService');

          function validate (id, lname) {
            return registrationService
              .verifyODAMembership(id, lname)
              .then(function (response) {
                return response.data;
              });
          }

          return validate(data.member_id, data.credential)
            .then(function (response) {
              return {
                'result': response.result,
                'data': response.data,
              };
            });
        },
      },

      'nbds': {
        label: 'New Brunswick Dental Society (NBDS)',
        logo: 'styles/img/logos/partners/nbds_logo.svg',
        id_label_prefix: 'NBDS',
        member_id_label: 'SIGNUP.MEMBER_NUMBER',
        member_id_placeholder: '04XXXXXX1 or 54XXXXXX1',
        credential_label: 'SIGNUP.DENTIST_NAME',
        description: [
          '<span>',
          $filter('translate')('SIGNUP.EXCLUSIVE_BENEFIT', {'x': 'NBDS'}) + $filter('translate')('SIGNUP.QUALIFY_FOR', {'x': 'NBDS'}),
          '</span>',
          '<ul class="feature-list">',
          '  <li>' + $filter('translate')('SIGNUP.MKT_ACCESS', {'x': 'NBDS'}) + '</li>',
          // SPD-3816 '  <li>' + $filter('translate')('SIGNUP.FREE_TRIAL', {'x': '14'}) + '</li>',
          '</ul>',
          '<span>',
          $filter('translate')('SIGNUP.LEARN_MORE', {'x': 'NBDS'}),
          '  <a href="https://sowingo.com/nbds" target="_blank" name="{{btnPrefix}}-link-aboutNbds">sowingo.com/nbds</a>',
          '</span>',
        ].join("\n"),
        verify: function (data, $injector) {
          var sowAssociationService = $injector.get('sowAssociationService');

          function validate (id, lname) {
            return sowAssociationService
              .validateNBDS(id, lname);
          }

          return validate(data.member_id, data.credential)
            .then(function (response) {
              return _.pick(response, ['result','data']);
            });
        },
      },

      // 
      // BC Dental Association (BCDA)
      // 

      'bcda': {
        label: 'BC Dental Association (BCDA)',
        logo: 'styles/img/logos/partners/bcda_logo.svg',
        id_label_prefix: 'BCDA',
        member_id_label: 'SIGNUP.MEMBER_NUMBER',
        member_id_placeholder: '000XXX or 00XXXX',
        credential_label: 'SIGNUP.DENTIST_NAME',
        description: [
          '<span>',
          $filter('translate')('SIGNUP.EXCLUSIVE_BENEFIT', {'x': 'BCDA'}) + $filter('translate')('SIGNUP.QUALIFY_FOR', {'x': 'BCDA'}),
          '</span>',
          '<ul class="feature-list">',
          '  <li>' + $filter('translate')('SIGNUP.MKT_ACCESS', {'x': 'BCDA'}) + '</li>',
          // SPD-3816 '  <li>' + $filter('translate')('SIGNUP.FREE_TRIAL', {'x': '14'}) + '</li>',
          '</ul>',
          '<span>',
          $filter('translate')('SIGNUP.LEARN_MORE', {'x': 'BCDA'}),
          '  <a href="https://sowingo.com/bcda" target="_blank" name="{{btnPrefix}}-link-aboutBcda">sowingo.com/bcda</a>',
          '</span>',
        ].join("\n"),
        verify: function (data, $injector) {
          var sowAssociationService = $injector.get('sowAssociationService');

          function validate (id, lname) {
            return sowAssociationService
              .validateBCDA(id, lname);
          }

          return validate(data.member_id, data.credential)
            .then(function (response) {
              return _.pick(response, ['result','data']);
            });
        },
      },

      'nsda': {
        label: 'Nova Scotia Dental Association (NSDA)',
        logo: 'styles/img/logos/partners/nsda_logo.svg',
        id_label_prefix: 'NSDA',
        member_id_label: 'SIGNUP.MEMBER_NUMBER',
        member_id_placeholder: '039109000',
        credential_label: 'SIGNUP.DENTIST_NAME',
        description: [
          '<span>',
          $filter('translate')('SIGNUP.EXCLUSIVE_BENEFIT', {'x': 'NSDA'}) + $filter('translate')('SIGNUP.QUALIFY_FOR', {'x': 'NSDA'}),
          '</span>',
          '<ul class="feature-list">',
          '  <li>' + $filter('translate')('SIGNUP.MKT_ACCESS', {'x': 'NSDA'}) + '</li>',
          // SPD-3816 '  <li>' + $filter('translate')('SIGNUP.FREE_TRIAL', {'x': '14'}) + '</li>',
          '</ul>',
        ].join("\n"),
        verify: function (data, $injector) {
          var sowAssociationService = $injector.get('sowAssociationService');

          return sowAssociationService.validateAny('nsda', {'nsda_id': data.member_id, 'last_name': data.credential})
            .then(function (response) {
              return _.pick(response, ['result','data']);
            });
        },
      },

      'adac': {
        label: 'Alberta Dental Association (ADA)',
        id_label_prefix: 'ADA',
        member_id_label: 'SIGNUP.MEMBER_NUMBER',
        member_id_placeholder: '0XXXX',
        credential_label: 'SIGNUP.DENTIST_NAME',
        description: [
          '<span>',
          $filter('translate')('SIGNUP.EXCLUSIVE_BENEFIT', {'x': 'ADA'}) + $filter('translate')('SIGNUP.QUALIFY_FOR', {'x': 'ADA'}),
          '</span>',
          '<ul class="feature-list">',
          '  <li>' + $filter('translate')('SIGNUP.MKT_ACCESS', {'x': 'ADA'}) + '</li>',
          // SPD-3816 '  <li>' + $filter('translate')('SIGNUP.FREE_TRIAL', {'x': '14'}) + '</li>',
          '</ul>',
        ].join("\n"),
        verify: function (data, $injector) {
          var sowAssociationService = $injector.get('sowAssociationService');

          return sowAssociationService.validateAny('adac', {'adac_id': data.member_id, 'last_name': data.credential})
            .then(function (response) {
              return _.pick(response, ['result','data']);
            });
        },
      },

      'dapei': {
        label: 'Dental Association of Prince Edward Island (DAPEI)',
        logo: 'styles/img/logos/partners/dapei_logo.svg',
        id_label_prefix: 'DAPEI',
        member_id_label: 'SIGNUP.MEMBER_NUMBER',
        member_id_placeholder: '2XXXXXXX',
        credential_label: 'SIGNUP.DENTIST_NAME',
        description: [
          '<span>',
          $filter('translate')('SIGNUP.EXCLUSIVE_BENEFIT', {'x': 'DAPEI'}) + $filter('translate')('SIGNUP.QUALIFY_FOR', {'x': 'DAPEI'}),
          '</span>',
          '<ul class="feature-list">',
          '  <li>' + $filter('translate')('SIGNUP.MKT_ACCESS', {'x': 'DAPEI'}) + '</li>',
          // SPD-3816 '  <li>' + $filter('translate')('SIGNUP.FREE_TRIAL', {'x': '14'}) + '</li>',
          '</ul>',
          '<span>',
          $filter('translate')('SIGNUP.LEARN_MORE', {'x': 'DAPEI'}),
          '  <a href="https://sowingo.com/dapei" target="_blank" name="{{btnPrefix}}-link-aboutDapei">sowingo.com/dapei</a>',
          '</span>',
        ].join("\n"),
        verify: function (data, $injector) {
          var sowAssociationService = $injector.get('sowAssociationService');

          function validate (id, lname) {
            return sowAssociationService
              .validateDAPEI(id, lname);
          }

          return validate(data.member_id, data.credential)
            .then(function (response) {
              return _.pick(response, ['result','data']);
            });
        },
      },

      'rcdso': {
        label: 'Royal College of Dental Surgeons of Ontario (RCDSO)',
        logo: 'styles/img/logos/partners/rcdso_logo.svg',
        id_label_prefix: 'RCDSO',
        member_id_label: 'SIGNUP.MEMBER_NUMBER',
        member_id_placeholder: 'XXXXX',
        credential_label: 'SIGNUP.DENTIST_NAME',
        description: [
          '<span>',
          $filter('translate')('SIGNUP.EXCLUSIVE_BENEFIT', { 'x': 'RCDSO' }) + $filter('translate')('SIGNUP.QUALIFY_FOR', { 'x': 'RCDSO' }),
          '</span>',
          '<ul class="feature-list">',
          '  <li>' + $filter('translate')('SIGNUP.MKT_ACCESS', { 'x': 'RCDSO' }) + '</li>',
          // SPD-3816 '  <li>' + $filter('translate')('SIGNUP.FREE_TRIAL', { 'x': '14' }) + '</li>',
          '</ul>',
        ].join("\n"),
        verify: ({ member_id, credential }, $injector) => {
          const { validateRCDSO } = $injector.get('sowAssociationService');
          return validateRCDSO(member_id, credential)
            .then(({ result, data }) => ({ result, data }));
        },
      },

      'acdq': {
        label: 'Association des chirurgiens dentistes du Québec (ACDQ)',
        logo: 'styles/img/logos/partners/acdq_logo.svg',
        id_label_prefix: 'ACDQ',
        member_id_label: 'SIGNUP.MEMBER_NUMBER',
        member_id_placeholder: 'XXXXX',
        credential_label: 'SIGNUP.DENTIST_NAME',
        description: `
          <span>
            ${$filter('translate')('SIGNUP.EXCLUSIVE_BENEFIT', { 'x': 'ACDQ' })} ${$filter('translate')('SIGNUP.QUALIFY_FOR', { 'x': 'ACDQ' })}
          </span>
          <ul class="feature-list">
            <li>${$filter('translate')('SIGNUP.MKT_ACCESS_3', {association: 'ACDQ'})} </li>
            <li>${$filter('translate')('SIGNUP.PERCENT_OFF', {x: '15'})}</li>
          </ul>
          <span>
            ${$filter('translate')('SIGNUP.LEARN_MORE', {x: 'ACDQ'})}
            <a href="https://sowingo.com/supply-hubs/acdq/" target="_blank" name="{{btnPrefix}}-link-aboutAcdq">sowingo.com/acdq</a>
          </span>
        `,
        // SPD-3816 <li>${$filter('translate')('SIGNUP.FREE_TRIAL', { 'x': '14' })}</li> 
        verify: ({ member_id, credential }, $injector) => {
          const { validateACDQ } = $injector.get('sowAssociationService');
          return validateACDQ(member_id, credential)
            .then(({ result, data }) => ({ result, data }));
        },
      },

      //
      // Ontario Medical Association
      //
      'oma': {
        label: 'OMA',
        logo: 'styles/img/logos/partners/oma-logo.png',
        id_label_prefix: 'OMA',
        member_id_label: 'SIGNUP.MEMBER_NUMBER',
        member_id_placeholder: '0XXXXXX or 1XXXXXX',
        credential_label: 'SIGNUP.LAST_NAME',
        footer: [
          '<span class="disclaimer-text-1">',
          $filter('translate')('SIGNUP.FOOTER'),
          '</span>',
        ].join("\n"),
        description: [
          '<span>',
          $filter('translate')('SIGNUP.OMA_ADVANTAGES') + $filter('translate')('SIGNUP.QUALIFY_FOR', {'x': 'OMA'}),
          '</span>',
          '<ul class="feature-list">',
          // SPD-3816 '  <li>' + $filter('translate')('SIGNUP.FREE_TRIAL', {'x': '30'}) + '</li>',
          '  <li>' + $filter('translate')('SIGNUP.PERCENT_OFF', {'x': '35'}) + '</li>',
          '</ul>',
          '<span>',
          $filter('translate')('SIGNUP.LEARN_MORE', {'x': 'OMA'}),
          '  <a href="http://www.oma.org" target="_blank" name="{{btnPrefix}}-link-aboutOMA">www.oma.org</a>.',
          '</span>',
        ].join("\n"),
        verify: function (data, $injector) {
          var $q = $injector.get('$q');
          var errorService = $injector.get('errorService');

          //
          // Validate Member Number Pattern
          //
          if (!data.member_id.match(/^[01]\d{6}/)) {
            var t_error = $filter('translate')('SIGNUP.INVALID_#') + data.member_id;
            errorService.errorHandler(t_error);
            return $q.when({
              'result': false,
              'data': {},
            });
          } else {
            return $q.when({
              'result': true,
              'data': {
                'oma_member_id': data.member_id,
                'oma_lastname': data.credential,
              }
            });
          }
        }
      },
      //
      // AAOMS
      //
      'aaoms': {
        label: 'AAOMS',
        name: 'AAOMS',
        logo: 'styles/img/logos/partners/aaoms-logo.png',
        id_label_prefix: 'AAOMS',
        member_id_label: 'SIGNUP.MEMBER_NUMBER',
        contact_url: 'http://www.aaoms.org/about/contact-us',
        member_id_placeholder: 'XXXXXX',
        credential_label: 'SIGNUP.LAST_NAME',
        description: [
          '<span>',
          $filter('translate')('SIGNUP.PARTNER_PROGRAM', {'x': 'AAOMS'}) + $filter('translate')('SIGNUP.QUALIFY_FOR', {'x': 'AAOMS'}),
          '</span>',
          '<ul class="feature-list">',
          // SPD-3816 '  <li>' + $filter('translate')('SIGNUP.FREE_TRIAL', {'x': '14'}) + '</li>',
          '  <li>' + $filter('translate')('SIGNUP.PERCENT_OFF', {'x': '10'}) + '</li>',
          '</ul>',
          '<span>',
          $filter('translate')('SIGNUP.LEARN_MORE', {'x': 'AAOMS'}),
          '  <a href="http://www.aaoms.org/" target="_blank" name="{{btnPrefix}}-link-aboutAAOMS">www.aaoms.org</a>.',
          '</span>',
        ].join("\n"),
        verify: function (data, $injector) {
          // var $q = $injector.get('$q');
          // var errorService = $injector.get('errorService');
          var sowAssociationService = $injector.get('sowAssociationService');

          function validate (id, lname) {
            return sowAssociationService
              .validateAAOMS(id, lname);
          }

          return validate(data.member_id, data.credential)
            .then(function (response) {
              return _.pick(response, ['result','data']);
            });
        }
      },
      //
      // Neodent
      //
      'neodent': {
        label: 'Neodent',
        name: 'Neodent',
        logo: 'styles/img/logos/partners/neodent-logo.jpg',
        member_id_label: 'SIGNUP.ACCESS_CODE',
        contact_url: 'http://www.straumann.com/neodent/ca/en/professionals.html',
        member_id_placeholder: 'XXXXXX',
        hide_credential_field: true,
        // credential_label: 'SIGNUP.LAST_NAME',
        // footer: [
        //   '<span class="disclaimer-text-1">',
        //   '  Customers will be informed that periodically Sowingo may',
        //   '  provide their name and membership information to AAOMS',
        //   '  for validation purposes.',
        //   '</span>',
        // ].join("\n"),
        description: [
          '<span>',
          $filter('translate')('SIGNUP.OBTAIN_CODE', {'x': 'Neodent'}),
          '</span>',
        ].join("\n"),
        verify: function (data, $injector) {
          // TO-DO: check with brandon for verification endpoint.
          var sowAssociationService = $injector.get('sowAssociationService');

          return sowAssociationService
            .validateNeodent(data.member_id)
            .then(function (response) {
              return _.pick(response, ['result', 'data']);
            });
        }
      },
      //
      // Dentsply
      //
      'dentsply': {
        label: 'Dentsply',
        name: 'Dentsply Sirona',
        logo: 'styles/img/logos/partners/Dentsply_sirona_logo.png',
        member_id_label: 'SIGNUP.ACCESS_CODE',
        contact_url: 'https://www.dentsplysirona.com/en-ca/help/contact_us.html',
        member_id_placeholder: 'XXXXXX',
        hide_credential_field: true,
        description: [
          '<span>',
          $filter('translate')('SIGNUP.OBTAIN_CODE', {'x': 'Dentsply'}),
          '</span>',
        ].join("\n"),
        verify: function (data, $injector) {
          // TO-DO: check with brandon for verification endpoint.
          var sowAssociationService = $injector.get('sowAssociationService');

          return sowAssociationService
            .validateDentsply(data.member_id)
            .then(function (response) {
              return _.pick(response, ['result', 'data']);
            });
        }
      },
      //
      // MNP
      //
      'mnp': {
        label: 'MNP',
        name: 'MNP',
        logo: 'styles/img/logos/partners/mnp_logo.png',
        id_label_prefix: 'MNP',
        member_id_label: 'SIGNUP.MEMBER_NUMBER',
        contact_url: 'http://www.mnp.ca',
        member_id_placeholder: $filter('translate')('SIGNUP.ENTER_#', {'x': 'MNP'}),
        hide_credential_field: true,
        description: [
          '<span>',
          $filter('translate')('SIGNUP.LEARN_MORE', {'x': 'MNP'}),
          '  <a href="http://www.mnp.ca" target="_blank" name="{{btnPrefix}}-link-aboutMnp">www.mnp.ca</a>.',
          '</span>',
        ].join("\n"),
        verify: function (data, $injector) {
          var registrationService = $injector.get('registrationService');

          function validate (id) {
            return registrationService
              .verifyMnpMembership(id)
              .then(function (response) {
                return response.data;
              })
              .catch(function(e){
                console.error(e);
              });
          }

          return validate(data.member_id)
            .then(function (response) {
              if (response && response.result) {
                return response;
              }
            })
            .then(function (response) {
              return {
                'result': response.result,
                'data': response.data,
              };
            });
        }
      },
      //
      // AAP
      //
      'aap': {
        contact_url: 'https://www.perio.org/',
        credential_label: 'SIGNUP.LAST_NAME',
        hide_member_field: true,
        label: 'AAP',
        logo: 'styles/img/landing/aap-logo-aap-page.png',
        id_label_prefix: 'AAP',
        member_id_label: 'SIGNUP.MEMBER_NUMBER',
        member_id_placeholder: $filter('translate')('SIGNUP.ENTER_#', {'x': 'AAP'}),
        name: 'AAP',
        description: [
          '<span>',
          $filter('translate')('SIGNUP.PARTNER_PROGRAM', {'x': 'AAP'}) + $filter('translate')('SIGNUP.QUALIFY_FOR', {'x': 'AAP'}),
          '</span>',
          '<ul class="feature-list">',
          // SPD-3816 '  <li>' + $filter('translate')('SIGNUP.FREE_TRIAL', {'x': '14'}) + '</li>',
          '  <li>' + $filter('translate')('SIGNUP.PERCENT_OFF', {'x': '10'}) + '</li>',
          '</ul>',
          '<span>',
          $filter('translate')('SIGNUP.LEARN_MORE', {'x': 'AAP'}),
          '  <a href="https://www.perio.org/" target="_blank" name="{{btnPrefix}}-link-aboutMnp">www.perio.org/</a>.',
          '</span>',
        ].join("\n"),
        verify: function (data, $injector) {
          // TO-DO: check with brandon for verification endpoint.
          var sowAssociationService = $injector.get('sowAssociationService');

          return sowAssociationService
            .validateAAP(data.credential)
            .then(function (response) {
              return _.pick(response, ['result', 'data']);
            });
        }
      },
      'mds': {
        contact_url: 'https://www.massdental.org/',
        credential_label: 'SIGNUP.LAST_NAME',
        hide_member_field: true,
        label: 'MDS',
        logo: 'styles/img/landing/partners/mds-logo.png',
        id_label_prefix: 'MDS',
        member_id_label: 'SIGNUP.MEMBER_NUMBER',
        member_id_placeholder: $filter('translate')('SIGNUP.ENTER_#', {'x': 'MDS'}),
        name: 'MDS',
        description: [
          '<span>',
          $filter('translate')('SIGNUP.PARTNER_PROGRAM', {'x': 'MDS'}) + $filter('translate')('SIGNUP.QUALIFY_FOR', {'x': 'MDS'}),
          '</span>',
          '<ul class="feature-list">',
          // SPD-3816 '  <li>' + $filter('translate')('SIGNUP.FREE_TRIAL', {'x': '14'}) + '</li>',
          '  <li>' + $filter('translate')('SIGNUP.PERCENT_OFF', {'x': '10'}) + '</li>',
          '</ul>',
          '<span>',
          $filter('translate')('SIGNUP.LEARN_MORE', {'x': 'MDS'}),
          '  <a href="https://www.massdental.org/" target="_blank" name="{{btnPrefix}}-link-aboutMds">https://www.massdental.org/</a>.',
          '</span>',
        ].join("\n"),
        verify: function (data, $injector) {
          // TO-DO: check with brandon for verification endpoint.
          var sowAssociationService = $injector.get('sowAssociationService');

          return sowAssociationService
            .validateMDS(data.credential)
            .then(function (response) {
              return _.pick(response, ['result', 'data']);
            });
        }
      },
      'patterson': {
        label: 'Patterson',
        name: 'Patterson',
        logo: 'styles/img/logos/partners/new-patt-logo-blue.png',
        member_id_label: 'SIGNUP.ACCESS_CODE',
        contact_url: 'https://www.pattersondental.com',
        member_id_placeholder: 'XXXXXXXXXXXX',
        hide_credential_field: true,
        description: [
          '<span>',
          $filter('translate')('SIGNUP.OBTAIN_CODE', {'x': 'Patterson'}),
          '</span>',
        ].join("\n"),
        verify: function (data, $injector) {
          var sowAssociationService = $injector.get('sowAssociationService');
          return sowAssociationService
            .validatePatterson(data.member_id)
            .then(function (response) {
              return _.pick(response, ['result', 'data']);
            });
        }
      },
      'cdss': {
        label: 'The College of Dental Surgeons of Saskatchewan (CDSS)',
        name: 'CDSS',
        id_label_prefix: 'CDSS',
        member_id_label: 'SIGNUP.MEMBER_NUMBER',
        member_id_placeholder: '',
        credential_label: 'SIGNUP.LAST_NAME',
        logo_height: '116px',
        description: [
          '<span>',
          $filter('translate')('SIGNUP.EXCLUSIVE_BENEFIT', {'x': 'CDSS'}) + $filter('translate')('SIGNUP.QUALIFY_FOR', {'x': 'CDSS'}),
          '</span>',
          '<ul class="feature-list">',
          '  <li>' + $filter('translate')('SIGNUP.MKT_ACCESS', {'x': 'CDSS'}) + '</li>',
          '</ul>',
        ].join("\n"),
        verify: function (data, $injector) {
          const sowAssociationService = $injector.get('sowAssociationService');

          return sowAssociationService.validateAny('cdss', {'cdss_id': data.member_id, 'last_name': data.credential})
            .then(function (response) {
              return _.pick(response, ['result','data']);
            });
        }
      },
    };

    /*jshint validthis: true */
    var service = this;

    service.get = get;
    service.getConfig = getConfig;
    service.associationList = associationList;
    service.getRequiredAssociation = getRequiredAssociation;
    service.verifyAssociation = verifyAssociation;

    return service;

    //
    // Fetch an association by id.
    //
    function get (id) {
      const ProfessionalAssociations = getAssociations();
      return _.find(ProfessionalAssociations, {'id': id});
    }


    function getConfig (associationId) {
      var _VerificationDialogConfig = VerificationDialogConfig[associationId];
      if (!_VerificationDialogConfig) {
        return $q.all([])
        .then(function(){
          const ProfessionalAssociations = getAssociations();
          return _.pick(ProfessionalAssociations[associationId], ['id','name']);
        });
      }
      if (associationId == 'aaoms') {
        _VerificationDialogConfig['contact_url']="https://www.sowingo.com/contact";
        _VerificationDialogConfig['name']="Sowingo";
        _VerificationDialogConfig['contact']="at 1.888.997.3133 or";
        _VerificationDialogConfig['email']="support@sowingo.com";
      }
      return _VerificationDialogConfig;
    }

    //
    //
    //
    function verifyAssociation (associationId, $event) {
      var _VerificationDialogConfig = getConfig(associationId);
      
      return AssociationVerificationDialog.show(
        associationId,
        _VerificationDialogConfig,
        $event
      ).then(function (data) {
        //
        // Note: This object will be _.merge()'d into the create office
        //       payload (prior to sending to the API), so make sure you take
        //       that into account. Don't clobber anything important.
        //
        var payloadUpdates = {
          'association_data': data,
        };

        if (associationId === 'oda') {
          data.oda_member_no = data.oda_member_id;
          angular.extend(payloadUpdates, {
            'license': data.license_no,
            'phone_number': data.phone_number,
            'address': {
              'address1': data.address1,
              'address2': data.address2,
              'city': data.city,
              'postal_code': data.postal_code,
            }
          });
        }

        return payloadUpdates;
      });
    }

    //
    // For a (province, medical field, specialty) tuple, return an association
    // with exclusivity (if any exist). Note that if there are multiple
    // associations for this combination, there _SHOULDN'T_ be any exclusive
    // associations.
    //
    function getRequiredAssociation (province_id, medical_field_id, medical_field_subtype) {
      var associations = associationList(
          {'id': province_id},
          {'id': medical_field_id},
          medical_field_subtype);

      var exclusive_assoc = _.find(associations, function(association){
        var exclusive = association.isExclusive;
        if(!exclusive) {
          return false;
        } else {
          var valid_province = association.provinces.indexOf(province_id) >= 0;
          var valid_field = association.fields.length ? (association.fields.indexOf(medical_field_id) >= 0) : true;
          var valid_subtype = association.fields.length ? false:true;
          if(valid_field) {
            _.each(association.fields[medical_field_id].subtypes, function(subtype){
              if(subtype === medical_field_subtype)
                valid_subtype = true;
            });
          }
          return valid_field && valid_subtype && valid_province;
        }
        
      });

      if (associations.length >= 1 && exclusive_assoc) {
        return exclusive_assoc;
      } else {
        return undefined;
      }
    }

    //
    // If the provinces list is empty (or doesn't exist), there are no province
    // restrictions. Otherwise, the province's id _MUST_ be in the list of
    // provinces.
    //
    function checkProvinceRequirements (association, province) {
      var provinces = _.get(association, 'provinces.length') > 0 ? association.provinces : null;
      return !provinces || _.includes(provinces, province.id);
    }

    //
    // If the fields list of empty (or doesn't exist), there are no field
    // requirements. Otherwise, the field _MUST_ match a field in the list. If
    // the field (on the association) contains a non-empty subtypes list, then
    // we also _MUST_ match the subtype.
    //
    function checkMedicalFieldRequirements (association, medical_field, medical_field_subtype) {
      var fieldReqs = _.get(association, 'fields.length') > 0 ? association.fields : null;
      var matchFound = false;

      if (fieldReqs) {
        _.forEach(fieldReqs, function (fieldReq) {
          if (fieldReq.id !== medical_field.id) {
            return false;
          }

          var subtypeReqs = _.get(fieldReq, 'subtypes.length') > 0 ? fieldReq.subtypes : null;

          matchFound = !subtypeReqs || _.includes(subtypeReqs, medical_field_subtype);

          if (matchFound) {
            return false;
          }
        });
      }

      return !fieldReqs || matchFound;
    }

    //
    // Return a list of associations that match the province + medical field combo.
    //
    function associationList (province, medical_field, medical_field_subtype) {
      const ProfessionalAssociations = getAssociations();
      return ProfessionalAssociations.filter(function (association) {
        return checkProvinceRequirements(association, province) &&
          checkMedicalFieldRequirements(association, medical_field, medical_field_subtype);
      });
    }

    function getAssociations () {
      const all_CA_provinces = _.map($rootScope.config.countriesMap['CA'].provinces, function(province){ return province.id; });

      const ProfessionalAssociations = [
        {
          'id': 'oda',
          'name': 'Ontario Dental Association (ODA)',
          'logo': 'styles/img/logos/partners/oda_logo.svg',
          'promo_code': 'ODA2016',
          'provinces': ['Ontario'],
          'isExclusive': false,
          'fields': [
            {
              'id': 'dentistry',
              'subtypes': [
                'General Dentistry',
                'Endodontics',
                'Oral Surgery',
                'Orthodontics',
                'Paediatrics',
                'Periodontics',
                'Prosthodontics',
              ]
            }
          ],
        },
        {
          'id': 'adto',
          'name': 'Association of Dental Technologists of Ontario (ADTO)',
          'logo': 'styles/img/logos/partners/adto_logo.svg',
          'provinces': ['Ontario'],
          'isExclusive': false,
          'fields': [
            {
              'id': 'dentistry',
              'subtypes': [
                'General Dentistry',
                'Endodontics',
                'Oral Surgery',
                'Orthodontics',
                'Paediatrics',
                'Periodontics',
                'Prosthodontics',
              ]
            }
          ],
        },
        {
          'id': 'bcda',
          'name': 'BC Dental Association (BCDA)',
          'logo': 'styles/img/logos/partners/bcda_logo.svg',
          'provinces': all_CA_provinces,
          'isExclusive': false,
          'fields': [
            {
              'id': 'dentistry',
              'subtypes': [
                'General Dentistry',
                'Endodontics',
                'Oral Surgery',
                'Orthodontics',
                'Paediatrics',
                'Periodontics',
                'Prosthodontics',
              ]
            }
          ],
        },
        {
          'id': 'oma',
          'name': 'Ontario Medical Association (OMA)',
          'logo': 'styles/img/logos/partners/oma-logo.png',
          'promo_code': null,
          'provinces': [],
          'fields': [
            {
              'id': 'medical',
              'subtypes': [],
            }
          ],
        },
        {
          'id': 'dao',
          'name': 'The Denturist Association of Ontario (DAO)',
          'logo': 'styles/img/logos/partners/dao-logo.full.png',
          'isExclusive': true,
          'exclusivityMsg': $filter('translate')('SIGNUP.ON_EXCLUSIVE', {'x': 'DAO'}),
          'promo_code': null,
          'provinces': ['Ontario'],
          'fields': [{'id': 'dentistry', 'subtypes': ['Denturism']}],
        },
        {
          'id': 'nbds',
          'name': 'New Brunswick Dental Society (NBDS)',
          'logo': 'styles/img/logos/partners/nbds_logo.svg',
          'provinces': ['New Brunswick'],
          'isExclusive': false,
          'fields': [
            {
              'id': 'dentistry',
              'subtypes': [
                'General Dentistry',
                'Endodontics',
                'Oral Surgery',
                'Orthodontics',
                'Paediatrics',
                'Periodontics',
                'Prosthodontics',
              ]
            }
          ],
        },
        {
          'id': 'dapei',
          'name': 'Dental Association of Prince Edward Island (DAPEI)',
          'logo': 'styles/img/logos/partners/dapei_logo.svg',
          'provinces': ['Prince Edward Island'],
          'isExclusive': false,
          'fields': [
            {
              'id': 'dentistry',
              'subtypes': [
                'General Dentistry',
                'Endodontics',
                'Oral Surgery',
                'Orthodontics',
                'Paediatrics',
                'Periodontics',
                'Prosthodontics',
              ]
            }
          ],
        },
        {
          'id': 'mds',
          'name': 'Massachusetts Dental Society (MDS)',
          'logo': 'styles/img/logos/partners/mds-logo.png',
          'isExclusive': false,
          'provinces': ['MA'],
          'fields': [{'id': 'dentistry', 'subtypes': []}],
        },
        {
          'id': 'aaoms',
          'name': 'AAOMS', // American Association of Oral and Maxillofacial Surgeons ()
          'logo': 'styles/img/logos/partners/aaoms-logo.png',
          'provinces': _.map($rootScope.config.countriesMap['US'].provinces, function(province){ return province.id; }),
          'isExclusive': false,
          'exclusivityMsg': $filter('translate')('SIGNUP.USA_EXCLUSIVE', {'x': 'AAOMS'}),
          'fields': [
            {
              'id': 'dentistry',
              'subtypes': [
                'Oral Surgery',
                'General Dentistry',
              ]
            }
          ],
        },
        {
          'id': 'neodent',
          'name': 'Neodent',
          'logo': 'styles/img/logos/partners/aaoms-logo.png',
          'provinces': all_CA_provinces,
          'isExclusive': false,
          'exclusivityMsg': $filter('translate')('SIGNUP.USA_EXCLUSIVE', {'x': 'Neodent'}),
          'fields': [
            {
              'id': 'dentistry',
              'subtypes': [
                'Oral Surgery',
                'General Dentistry',
              ]
            }
          ],
        },
        {
          'id': 'dentsply',
          'name': 'Dentsply Sirona',
          'logo': 'styles/img/logos/partners/Dentsply_sirona_logo.png',
          'provinces': all_CA_provinces,
          'isExclusive': false,
          'exclusivityMsg': $filter('translate')('SIGNUP.USA_EXCLUSIVE', {'x': 'Dentsply'}),
          'fields': [
            {
              'id': 'dentistry',
              'subtypes': [
                'Oral Surgery',
                'General Dentistry',
              ]
            }
          ],
        },
        {
          'id': 'mnp',
          'name': 'MNP',
          'logo': 'styles/img/logos/partners/mnp_logo.png',
          'provinces': all_CA_provinces,
          'isExclusive': false,
          'exclusivityMsg': $filter('translate')('SIGNUP.USA_EXCLUSIVE', {'x': 'MNP'}),
          'fields': [
            {
              'id': 'dentistry',
              'subtypes': []
            }
          ],
        },
        {
          'id': 'aap',
          'name': 'AAP',
          'logo': 'styles/img/landing/aap-logo-aap-page.png',
          'all_provinces': true,
          'provinces': [],
          'isExclusive': false,
          'exclusivityMsg': '.',
          'fields': [
            {
              'id': 'dentistry',
              'subtypes': [
                'General Dentistry',
                'Endodontics',
                'Oral Surgery',
                'Orthodontics',
                'Paediatrics',
                'Periodontics',
                'Prosthodontics',
              ]
            }
          ],
        },
        {
          'id': 'patterson',
          'name': 'Patterson',
          'logo': 'styles/img/logos/partners/new-patt-logo-blue.png',
          'provinces': [],
          'isExclusive': false,
          'fields': [],
        },

        {
          'id': 'nsda',
          'name': 'Nova Scotia Dental Association (NSDA)',
          'logo': 'styles/img/logos/partners/nsda_logo.svg',
          'isExclusive': false,
          'provinces': all_CA_provinces,
          'fields': [{'id': 'dentistry', 'subtypes': []}],
        },

        {
          'id': 'adac',
          'name': 'Alberta Dental Association (ADA)',
          'logo': 'styles/img/logos/partners/adac_logo.svg',
          'isExclusive': false,
          'provinces': all_CA_provinces,
          'fields': [{'id': 'dentistry', 'subtypes': []}],
        },
      ];

      return ProfessionalAssociations;
    }
  }

}());

//
// Support Page Controller
//
var SupportController = function ($scope, $sce, SupportQuestions) {
  $scope.SupportQuestions = angular.copy(SupportQuestions);

  angular.forEach($scope.SupportQuestions, function (faqs, title) {
    angular.forEach(faqs, function (faq) {
      if (_.isString(faq.answer)) {
        faq.answer = $sce.trustAsHtml(faq.answer);
      }
    });
  });
};

angular.module('app.marketplace.ui.marketing')
  .controller('SupportController', SupportController);
(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.office')
    .controller('WelcomeDialogController', WelcomeDialogController);

  function WelcomeDialogController ($mdDialog, $state) {
    /*jshint validthis: true */
    var ctrl = this;

    ctrl.cancel = cancel;
    ctrl.getstarted = getstarted;

    return ctrl;

    function cancel () {
      $mdDialog.cancel();
    }

    function getstarted () {
      $state.go('app.dashboard');
      cancel();
    }
  }

}());

(function () {
  'use strict';

  angular
    .module('sowLogSheets', [
      'ngMaterial',
      'sowOfficeUsers',
    ])
    .controller('sowLogSheetController', LogSheetController);

  function LogSheetController ($state, $filter, $mdDialog, elementService, officeService, inventoryItemService, sgToast, errorService, searchService, $rootScope, $timeout) {
    /*jshint validthis: true */
    var ctrl = this;

    ctrl.itemCount = 0;
    ctrl.items = [];

    ctrl.productSearch = productSearch;
    ctrl.selectedItemChange = selectedItemChange;
    ctrl.checkItem = checkItem;
    ctrl.removeItem = removeItem;
    ctrl.cancelUpdate = cancelUpdate;
    ctrl.updateInventory = updateInventory;
    ctrl.getItemQuantity = getItemQuantity;

    init();

    return ctrl;

    //
    // initialize the controller's state
    //
    function init () {
      loadUsers();

      var items = ctrl.items = [];
      _.forEach(_.range(20), function () { items.push({}); });
    }

    function getItemQuantity (item) {
      var end_result = 0;
      var inv_item = item.selectedItem;
      if (inv_item) {
        end_result = inventoryItemService.calculateInventoryOnHand(inv_item);
      }
      return end_result;
    }

    function loadUsers () {
      ctrl.loadingUsers = true;

      return officeService
        .get(true)
        .then(function (office) {
          return elementService
            .get('account', null, {'endpoint': 'office_users', 'office': office})
            .then(function (users) {
              ctrl.users = $filter('sowActiveOfficeUsers')(users);
            })
            .catch(function (error) {
              var t_message = $filter('translate')('ERRORS.USER_RETRIEVE_API')
              errorService.uiErrorHandler(error || t_message);
            });
        })
        .finally(function () {
          ctrl.loadingUsers = false;
        });
    }

    //
    // Autocomplete Search for Inventory Items
    //
    function productSearch (item, searchText) {
      if (!searchText) {
        return;
      }

      return searchService
        .inventorySearch(searchText)
        .then(function (searchElements) {
          item.search = {};
          item.search.items = _.chain(searchElements)
            .orderBy('_score', 'desc')
            .filter(function(item){
              var not_implant = !item.item_type.includes('Implant');
              var not_med = !item.item_type.includes('Medication');
              return not_implant && not_med;
            })
            .value()
            .slice(0, 50);
          return item.search.items;
        });
    }

    //
    // Update the State of the Item Based on Autocomplete State
    //
    function selectedItemChange (item) {
      if (!item) {
        return false;
      }

      if (!item.selectedItem) {
        if (item.quantity) {
          ctrl.itemCount -= 1;
        }

        // Clear item
        delete item.quantity;
        delete item.user_id;
      } else {
        if (!item.quantity) {
          ctrl.itemCount += 1;
        }

        item.quantity = 1;
      }
    }

    //
    // Do some error-correction and validation of the item.
    //
    function checkItem (item) {
      if (!item.quantity || parseInt(item.quantity) < 0) {
        item.quantity = 0;
      }

      if (item.searchText && !item.selectedItem) {
        $timeout(function () {
          item.showItemWarning = !!(item.searchText && !item.selectedItem);
        }, 300);
      } else {
        item.showItemWarning = false;
      }
    }

    //
    // Clear Item.
    //
    function removeItem (item) {
      delete item.selectedItem;
      delete item.searchText;
      delete item.search;
    }

    function cancelUpdate (event) {
      $state.go('app.inventory.all');
    }

    function updateInventory (event) {
      var t_title = $filter('translate')('INVENTORY.LOGSHEETS.DIALOG_TITLE');
      var t_text = $filter('translate')('INVENTORY.LOGSHEETS.DIALOG_TEXT');
      var t_update = $filter('translate')('ACTIONS.UPDATE');
      var t_cancel = $filter('translate')('ACTIONS.CANCEL');
      // appending dialog to document.body to cover sidenar in docs app
      var confirm = $mdDialog
        .confirm()
        .title(t_title)
        .textContent(t_text)
        .targetEvent(event)
        .ok(t_update)
        .cancel(t_cancel);

      return $mdDialog
        .show(confirm)
        .then(function () {
          var itemUpdates = [];

          _.forEach(ctrl.items, function (item) {
            if (parseInt(item.quantity, 10) && item.selectedItem && item.selectedItem.id) {
              var itemUpdate = {
                'id': item.selectedItem.id,
                'out': {
                  'out': inventoryItemService.calculateInventoryUnit(item.selectedItem, item.quantity),
                },
                'user_id': item.user_id,
              };
              itemUpdates.push(itemUpdate);
            }
          });

          return inventoryItemService
            .bulkRemoveStock(itemUpdates)
            .then(function (items) {
              var t_message = $filter('translate')('TOAST.INVENTORY_UPDATED_FROM_LOG');
              sgToast.showSimple(t_message);
              ctrl.itemCount = 0;
              $rootScope.ignoreConfirmNavigation = true;
              $state.go('app.inventory.all', null, {'ignoreConfirmNavigation': true});
            })
            .catch(function (error) {
              var t_message = $filter('translate')('ERRORS.INVENTORY_UPDATE')
              errorService.uiErrorHandler(error || t_message, 0);
            });

        });
    }
  }
}());

(function () {
  'use strict';

  angular
    .module('app.marketplace.elements')
    .service('SupplierService', SupplierService);

  function SupplierService (elementService, $rootScope, $mdDialog, serverAPI, apiUrl, officeService, errorService) {
    /*jshint validthis: true */
    var service = this;

    service.getAll = getSuppliers;
    service.get = getSupplier;
    service.remove = deleteSupplier;
    service.create = createSupplier;
    service.update = updateSupplier;
    service.getSupplierDeleteStatus = getSupplierDeleteStatus;
    service.clearCache = clearCache;
    service.getSupplierSalesReps = getSupplierSalesReps;

    service.getSalesReps = getSalesReps;
    service.getSalesRep = getSalesRep;
    service.createOrUpdateSalesRep = createOrUpdateSalesRep;
    service.removeSalesRep = removeSalesRep;

    init();

    return service;

    //
    // Initial setup code for SupplierService.
    //
    function init () {
      // Make sure that we don't have any leftover suppliers when we change offices.
      $rootScope.$on('officeService: office-changed', clearCache);
    }

    //
    // Remove all suppliers from cache.
    //
    function clearCache () {
      elementService.removeAll('supplier');
    }

    function updateSupplier(supplier) {
      return elementService.callEndpoint('supplier', {
        'endpoint': 'update',
        'supplier': supplier,
      }).then(function (response) {
        elementService.create('supplier', response.data);
        return response.data;
      });
    }

    function getSuppliers(noCache, options) {
      options = options || {};
      var defaultOptions = {'forceAPI': !!noCache,};
      angular.extend(options, defaultOptions);
      return elementService.getElements('supplier', options);
    }

    function getSupplier(supplier_id, opts) {
      var options = {
        'forceAPI': _.get(opts, 'force', false),
        'extended': _.get(opts, 'extended', false),
      };
      return elementService.get('supplier', supplier_id, options);
    }

    function _deleteSupplier(supplier) {
      return elementService.remove('supplier', supplier, true);
    }

    function deleteSupplier(supplier, ev) {
      return getSupplier(
        supplier.id,
        {
          'force': true,
          'extended': true,
        }
      ).then(function (supplier) {
        return $mdDialog.show({
          /* @ngInject */
          'controller': function ($scope, $mdDialog, supplier) {
            /*jshint validthis: true */
            var ctrl = this;
            ctrl.close = function (ev) { $mdDialog.cancel(); };
            ctrl.confirm = function (ev) { $mdDialog.hide(ctrl.supplier); };
            ctrl.supplier = $scope.supplier = supplier;
            return ctrl;
          },
          'controllerAs': 'dialogCtrl',
          'templateUrl': 'templates/marketplace/suppliers/delete-dialog.html',
          'parent': angular.element(document.body),
          'clickOutsideToClose': true,
          'targetEvent': ev,
          'locals': {'supplier': supplier},
        }).then(
          // Delete the supplier. If deletion is successful, make sure the
          // promise resolves to true.
          function (supplier) {
            return _deleteSupplier(supplier).then(function () {
              return true;
            });
          },
          function () {
            // Ignore the "error" because we don't want the users of this
            // function to need to differentiate between an "error" that was
            // just the user dismissing the dialog, and a true error while
            // attempting to delete.
            //
            // Instead we'll just return true/false based on if there was a
            // successful deletion or not. If the promise is successful, but
            // returns false, then we know that no deletion happened (but not
            // due to an error).
            return false;
          }
        );
      });
    }

    function createSupplier(supplier) {
      return elementService.callEndpoint('supplier', {
        'endpoint': 'create',
        'supplier': supplier,
      }).then(function (response) {
        elementService.create('supplier', response.data);
        return response.data;
      });
    }

    function getSupplierSalesReps() {
      const url = `${apiUrl}/suppliers`;
      const options = {
        method: 'GET',
        params: {
          list_sales_reps: true,
          po_type_info: true,
        },
      }
      return serverAPI
        .doAPICall(url, options)
        .then(({data}) => data)
        .catch(err => console.error(err));
    }

    // function getSalesReps (filters) {
    //   var options = _.pick(filters);

    //   return elementService.getElements('sales_rep', options);
    // }

    function getSalesReps (filters) {
      filters = filters || {};

      var url = '{0}/suppliers/sales_reps'.format(apiUrl);
      var options = {
        'method': 'GET',
        'params': _.pick(filters, [
          'supplier_id',
          'is_primary'
        ]),
      };

      return serverAPI
        .doAPICall(url, options)
        .then(function (response) {
          return response.data;
        });
    }

    function getSalesRep (filters) {
      filters = filters || {};

      var url = '{0}/suppliers/sales_reps'.format(apiUrl);
      var options = {
        'method': 'GET',
        'params': _.pick(filters, [
          'supplier_id',
          'id',
        ]),
      };

      return serverAPI
        .doAPICall(url, options)
        .then(function (response) {
          return response.data;
        });
    }

    function createOrUpdateSalesRep (rep_item) {
      var url = '{0}/suppliers/sales_reps'.format(apiUrl);
      rep_item.supplier_id = rep_item.supplier.id;
      rep_item.office_id = $rootScope.current_office.id;
      var options = { 'method': 'POST', 'data': rep_item, };

      return serverAPI
        .doAPICall(url, options)
        .then(function (response) {
          return response.data;
        });
    }

    function removeSalesRep (rep_item) {
      var url = '{0}/suppliers/sales_reps/{1}'.format(apiUrl, rep_item.id);
      // rep_item.supplier_id = rep_item.supplier.id;
      // , 'data': rep_item,
      var options = { 'method': 'DELETE' };

      return serverAPI
        .doAPICall(url, options)
        .then(function (response) {
          return response.data;
        });
    }

    /** 
     * Fetches an Object wherein each key is the ID of a supplier and each value
     * is a boolean indicating whether that supplier can be deleted. Will include
     * the status of all suppliers within the current office by default, or can
     * take an optional array of supplier IDs and only return that subset.
     * Note: this array is converted to a CSL string and passed as a URL param.
     * 
     * @param {Array} supplier_ids 
     * 
     * @return {Object} 
    */
    function getSupplierDeleteStatus(supplier_ids = []) {
      const url = `${apiUrl}/suppliers/can_delete`
      const params = { office_id: officeService.get().id };
      const supplier_id_csl = supplier_ids.join(',');
      if (supplier_id_csl) {
        params.supplier_ids = supplier_id_csl;
      }
      return serverAPI
        .doAPICall(url, { method: 'GET', params })
        .then(({ data }) => data)
        .catch(err => errorService.uiErrorHandler(err.message));
    }

  }
}());

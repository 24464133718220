(function () {
  'use strict';
  
  angular.module('app.shared.system.api').service('serverAPI', serverAPI);
  
  // Web server api service. Single point for api calls.
  function serverAPI ($http, $rootScope, $filter, errorService, $q, SessionStorageService) {
    /*jshint validthis: true */
    var service = this;
    var currentGetCalls = {}; // List of current "GET" api calls being made. Important for avoiding duplicate calls.
    service.concurrent_calls = {};
    service.CONCURRENCY_LIMIT = 5;
    
    $rootScope.totalCallCount = 0; // Total number of current calls.
    service.doAPICall = doAPICall;
    service.callAPI = callAPI;
    service.callCacheAPI = callCacheAPI;
    service.makeConcurrentCall = makeConcurrentCall;
    
    return service;
    
    //
    // Functions for managing current calls.
    //
    function addCurrentCall (callKey, api_call) {
      if (callKey && api_call && !currentGetCalls[callKey]) {
        currentGetCalls[callKey] = api_call;
        return api_call;
      } else {
        return false;
      }
    }
    
    //
    // Remove GET From Current Calls List
    //
    function removeCurrentCall (callKey) {
      if (callKey && currentGetCalls[callKey]) {
        delete currentGetCalls[callKey];
        return true;
      } else {
        return false;
      }
    }
    
    //
    // Generate a unique id for this specific "GET" call. We need to include the
    // params because some calls use params as a filter and calls with different
    // params will return different results.
    //
    function getCallKey (fromUrl, options) {
      return options.method === 'GET' ? fromUrl + JSON.stringify(options.params) : null;
    }
    
    //
    // Return the current GET call for url + options
    //
    function getCurrentCall (callKey) {
      return callKey ? currentGetCalls[callKey] : false;
    }
    
    //
    // Process an error response from the API. Make sure that we handle common
    // cases of server / network failure so that the user gets a better error
    // message.
    //
    // Note: This logic dupe'd in errorService. It needs to be slightly
    //       different here, so we can't just make a call out to errorService.
    function processErrorResponse (response) {
      if (response.status === -1) {
        return $filter('translate')('ERRORS.UNABLE_TO_CONNECT');
      }
      if (response.status === 0) {
        return $filter('translate')('ERRORS.UNABLE_COMM');
      }
      else if (response.status >= 502 && response.status <= 504) {
        return $filter('translate')('ERRORS.SERVER_UNAVAILABLE');
      }
      else if (_.isObject(response.data) ) {
        // API Error structure could be inside an error (response.data.error) object 
        // or it could destructured inside data (response.data)
        if (response.data.error?.message !== undefined) {
          return response.data.error;
        } else if (response.data.error_code) {
          return response.data;
        }
      }
      else if (response.status === 500) {
        return $filter('translate')('ERRORS.SERVER_500');
      }
      else {
        return $filter('translate')('ERRORS.API_GENERAL');
      }
    }
    
    /**
    * Single point for http calls
    *
    * @param options =
    *          {isAsync, dataType, checkCache, doCache, dbRef,
    *          elementType, dataContent, POST, noLoadingImg}
    */
    function doAPICall (fromUrl, options) {
      // Assert
      if (!fromUrl) {
        throw "Empty fromUrl passed";
      }
      
      options = options || {};
      options.method = options.method || "GET";
      
      var callKey = getCallKey(fromUrl, options);
      var currentCall = getCurrentCall(callKey);
      
      // If method is GET and existing call is being made then return that call.
      if (currentCall) {
        return currentCall;
      }
      
      $rootScope.totalCallCount++;
      $rootScope.$broadcast('serverAPI: doAPICall', {'url': fromUrl, 'params': options.params});
      
      const defaultHeaders = {};
      const overrideHeaders = {
        'Accept-Language': $rootScope.current_language,
        'User-Email': $rootScope.current_account?.email,
        'Office-Membership-Id': $rootScope.current_membership?.id,
        'Office-Id': $rootScope.current_office?.id,
      }
      const requestHeaders = _.extend(defaultHeaders, options.headers, overrideHeaders);
      
      var api_call = $http({
        url : fromUrl,
        async : options.isAsync || false,
        dataType : options.dataType,
        params : options.params,
        responseType : options.responseType || undefined,
        data : options.data,
        method : options.method,
        headers : requestHeaders
      }).then(function(data, status, headers, config) {
        return data;
      }).catch(function(response) {
        $rootScope.$broadcast('serverAPI: apiError', response);
        throw processErrorResponse(response);
      })['finally'](function () {
        $rootScope.totalCallCount -= 1;
        removeCurrentCall(callKey);
      });
      
      // Track calls being made
      addCurrentCall(callKey, api_call);
      
      return api_call;
    }
    
    /**
     * It makes an API call, and if it fails, it shows a toast
     * @param url - The url of the API endpoint
     * @param params - This is the data that you want to send to the API.
     * @param disableToast - If you want to disable the error toast message, pass true.
     * 
     * This might be used in cases where you want to make multiple calls and 
     * it's acceptable that some of them might fail, so you hide the toast messages
     * to prevent confusion with the users.
     * 
     * @returns A promise.
     */
    function callAPI (url, params, error_options) {
      return doAPICall(url, params)
      .then(function (response) {
        return response.data;
      })
      .catch((error) => {
        const {show_error_toast, throw_error} = _handleErrorOptions(error_options);
        if(show_error_toast) {
          errorService.uiErrorHandler(error);
        }
        if (throw_error) {
          throw error;
        }
      });
    }


  /**
   * Returns an object with the default error options or the custom options
   * @param {object} error_options
   * @param {boolean} error_options.show_error_toast
   * @param {boolean} error_options.throw_error
   */
    function _handleErrorOptions(error_options) {
      const default_error_options = {
        show_error_toast: true,
        throw_error: false,
      }

      if (error_options) {
        const {show_error_toast, throw_error} = error_options
        return {
          show_error_toast: show_error_toast ?? default_error_options.show_error_toast,
          throw_error: throw_error ?? default_error_options.throw_error,
        }
      }

      return default_error_options;
    }
    
    /**
     * It takes a URL and parameters, hashes them, checks if the hash exists in session storage, if it
     * does, it returns the data from session storage, if it doesn't, it calls the API and stores the
     * data in session storage
     * @param url - The API endpoint to call
     * @param params - This is the data that you want to send to the API.
     * @param disableToast - If you want to disable the error toast message, pass true.
     * 
     * This might be used in cases where you want to make multiple calls and 
     * it's acceptable that some of them might fail, so you hide the toast messages
     * to prevent confusion with the users.
     * 
     * @returns A promise.
     */
    function callCacheAPI (url, params, disableToast=false) {
      var request_hash = _hash( JSON.stringify({'url':url,'params':params}) );
      
      var existing_data = SessionStorageService.get(request_hash);
      if(existing_data) {
        var deferred = $q.defer();
        deferred.resolve(existing_data);
        return deferred.promise;
      }
      
      return doAPICall(url, params, disableToast)
      .then(function (response) {
        try {
          SessionStorageService.set(request_hash, response.data);
        } catch (error) {
          SessionStorageService.clear();
        }
        return response.data;
      });
    }

    function _hash (s) {
      /* Simple hash function. */
      var a = 1, c = 0, h, o;
      if (s) {
        a = 0;
        /*jshint plusplus:false bitwise:false*/
        for (h = s.length - 1; h >= 0; h--) {
          o = s.charCodeAt(h);
          a = (a<<6&268435455) + o + (o<<14);
          c = a & 266338304;
          a = c!==0?a^c>>21:a;
        }
      }
      return String(a);
    }

    function _addDomainCall (domain, subdomain = -1) {
      service.concurrent_calls[domain][subdomain]++;
    }

    function _removeDomainCall (domain, subdomain = -1) {
      service.concurrent_calls[domain][subdomain]--;
    }


    function _initDomainCalls (domain, subdomain = 0) {
      const path = `['${domain}'][${subdomain}]`;
      _.set(service.concurrent_calls, path, 0);
    }

    /**
     * It takes a URL, and returns the domain portion of it.
     * eg: for an input of "https://www.sowingo.com/search?q=hello", it returns "www.sowingo.com"
     * @param {string} url - The URL of the page you want to load.
     * @returns {string} domain_name - The domain name of the URL.
     */
    function _getDomain (url) {
      const [protocol, address] = url.split('//');
      const domain_name = address.split('/')[0];
      return domain_name;
    }

    /**
     * It takes a URL and a prefix, and returns a new URL with the prefix inserted as a subdomain.
     * eg: for an input of "https://api.sowingo.com/search?q=hello" and a prefix of "test", it returns "https://test.api.sowingo.com/search?q=hello"
     * @param url - The URL of the page you want to load.
     * @param prefix - The prefix to use for the URL.
     * @returns the url with the prefix added to the beginning of the url.
     */
    function _getPrefixedUrl (url, prefix) {
      const urlParts = url.split('//');
      return `${urlParts[0]}//${prefix}.${urlParts[1]}`;
    }

    /**
     * It takes a url and returns it with a prefix subdomain currently available for concurrent calls.
     * @param url - the url to be called
     * @returns An object with concurrent_url, domain and subdomain.
     */
    function _getConcurrentUrl (url) {
      let subdomain;
      let concurrent_url;
      const domain = _getDomain(url);
      
      if (!service.concurrent_calls[domain]) {
        _initDomainCalls(domain);
      }

      _.each(service.concurrent_calls[domain], (call_count, slot_index) => {
        if (!subdomain && call_count < service.CONCURRENCY_LIMIT) {
          subdomain = slot_index;
          return;
        }
      });
      
      switch(subdomain) {
        // the first position is always the default url, so we don't need to prefix it
        // for all other cases, we use one of the slots or create a new one
        case 0:
          concurrent_url = url;
          break;
        // if we don't have a prefix, we need to create a new slot
        // in this case if we have 4 slots (0-3), the length will be 4 and
        // the next slot will be 4 because it is zero-indexed
        case undefined:
        case null:
          const nextSubdomain = service.concurrent_calls[domain].length;
          subdomain = nextSubdomain;
          _initDomainCalls(domain, subdomain);
          concurrent_url = _getPrefixedUrl(url, subdomain);
          break;
        // for any number 1+ we use the prefix
        default:
          concurrent_url = _getPrefixedUrl(url, subdomain);
          break;
      }

      return {concurrent_url, subdomain, domain};
    }

    /**
     * It makes a call to the server using managed subdomains for concurrency, 
     * registers the call on start and removes it on completion.
     * @param {string} url - The url to call.
     * @param {object} options - This is the same options object that you would pass to the serverAPI.callAPI
     * function.
     * @param {object} error_options - This is the same error_options object that you would pass to the serverAPI.callAPI
     * function (show_error_toast, and throw_error keys)
     * @returns A promise.
     */
    function makeConcurrentCall (url, options, error_options) {
      const {concurrent_url, subdomain, domain} = _getConcurrentUrl(url);
      _addDomainCall(domain, subdomain);
      return callAPI(concurrent_url, options, error_options)
      .catch((error) => {
        throw error;
      })
      .finally(() => {
        _removeDomainCall(domain, subdomain);
      });
    }
  }
    
  }());
  
(function(){
  'use strict';
  angular.module("sowCertifications")
    .controller('certActionBarController', certABController);

  function certABController ($state, $rootScope, $mdDialog, certificationDataCarrierService, $scope) {
    /*jshint validthis:true*/
    var ctrl = this;

    ctrl.newRecordDialog = newRecordDialog;
    ctrl.newCertificationDialog = newCertificationDialog;
    ctrl.deleteCertification = deleteCertification;
    ctrl.back = back;
    ctrl.has_certification_access = false;
    
    init();
    return ctrl;

    function init(){
      _defineLocks();
      ctrl.certificationDataCarrierService = certificationDataCarrierService;
    }

    function deleteCertification () {
      $mdDialog.show({
        controller: 'certificationsDetailController',
        controllerAs: 'certdCtrl',
        templateUrl: 'sow-certifications/modals/cert-delete-certification.html',
        parent: angular.element(document.body),
        clickOutsideToClose: true,
        bindToController: true,
      });

    }

    function newRecordDialog () {
      $rootScope.$broadcast('crt-new-record');
    }


    function back(){
      if(window.history.length > 2){
        window.history.back();
      } else {
        $state.go('app.certifications.list');
      }
    }

    function newCertificationDialog () {
      $mdDialog.show({
          controller: 'certificationsDetailController',
          controllerAs: 'certdCtrl',
          templateUrl: 'sow-certifications/modals/cert-edit-certification.html',
          parent: angular.element(document.body),
          clickOutsideToClose: true,
          locals: {

          },
          bindToController: true,
        });
    }

    function _defineLocks () {
      ctrl.has_certification_access = Boolean($scope.features.certifications);
    }

  }

}());

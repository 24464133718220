(function () {
  'use strict';
  
  angular
  .module('app.marketplace.ui.registration')
  .controller('CreateOfficeCtrl', CreateOfficeCtrl);
  
  var stages = [
    'medical-field',
    'specialty',
    'office-info',
    'verify-association',
    'select-plan',
    'credit-card',
    'office-created'
  ];
  
  var MembershipTypes = [
    {'id': 'master', 'name': 'Master User'},
    {'id': 'manager', 'name': 'Manager'},
    {'id': 'team', 'name': 'Team Member'},
  ];
  
  function CreateOfficeCtrl (
    $log, $mdDialog, $rootScope, $scope, $state, $stateParams, $timeout, $injector, $filter,
    AssociationService,
    creditCardService,
    errorService,
    membershipService,
    officeService,
    registrationService,
    RouteService,
    sessionService,
    sowAssociationService,
    subscriptionService,
    RegistrationHelperService,
    sowAnalyticsService,
    CountryService,
    PwfRedirectService,
    ) {
      /*jshint validthis: true */
      var ctrl = this;
      
      $scope.membership_types = MembershipTypes;
      $scope.data = {};
      $scope.btnPrefix = 'verifyAssociationDialog';
      
      ctrl.errors = {};
      ctrl.use_office_address = true;
      ctrl.stages = stages;
      ctrl.current_stage = 'medical-field';
      
      ctrl.office = {};
      // mockup data for debugging purposes
      // ctrl.office = {
      //   "name": "test",
      //   "phone_number": "asdasdasd",
      //   "address": {
      //     "address1": "asdasdas",
      //     "city": "asdasdasd",
      //     "postal_code": "90210"
      //   }
      // };
      ctrl.plans = RegistrationHelperService.PLANS;
      ctrl.associations = [];
      ctrl.no_validate_association_ids = [
        'caqe',
      ];
      ctrl.defaultPlanByAssociationId = {
        'aaoms': ctrl.plans.premium_plus
      };
      ctrl.invites = [{}];
      ctrl.animation_class = 'ng-switch-slide-left';
      ctrl.province_requirements = {
        'CA':{
          // 'Ontario': {
          //   'association': true,
          // }
        }
      };
      ctrl.billingCycle = 'per_year';
      ctrl.office_address_info = null;
      ctrl.is_office_location_id_filled = false;
      
      ctrl.addAnotherInvite = addAnotherInvite;
      ctrl.canShowFree = canShowFree;
      ctrl.captureBackButton = captureBackButton;
      ctrl.createOffice = createOffice;
      ctrl.disableBackButtonCapture = null;
      ctrl.finish = finish;
      ctrl.getSwitchAnimationClass = getSwitchAnimationClass;
      ctrl.logout = logout;
      ctrl.nextStage = nextStage;
      ctrl.previousStage = previousStage;
      ctrl.handleAddressSelectionEvent = handleAddressSelectionEvent;
      ctrl.should_validate_form = false;
      ctrl.provinceRequiresAssoc = provinceRequiresAssoc;
      ctrl.removeInvite = removeInvite;
      ctrl.saveCreditCard = saveCreditCard;
      ctrl.validateOfficeAddress = validateOfficeAddress;
      ctrl.handleAddressConfirmModalEvent = handleAddressConfirmModalEvent;
      ctrl.selectMedicalField = selectMedicalField;
      ctrl.selectPlan = selectPlan;
      ctrl.selectSpecialty = selectSpecialty;
      ctrl.sendInvites = sendInvites;
      ctrl.validateInvites = validateInvites;
      ctrl.verify = verifyMembership;
      ctrl.trackContactSupportAction = trackContactSupportAction;
      ctrl.showSuiteField = showSuiteField;
      ctrl.handlePlanSelection = handlePlanSelection;
      ctrl.association_discount = null;
      
      ctrl.$onInit = init;
      
      function logout () {
        return sessionService.logout().then(function () {
          // window.location.href = '/';
          $rootScope.goToDefault();
        });
      }
      
      function init () {
        _initializeFormValues();
        _populateProvinceDropdown();
        captureBackButton();
        
        $scope.showLogoutButton = !!$rootScope.current_account && !$rootScope.current_office;
        //
        // Update ctrl.office.country when ctrl.office.province changes.
        //
        $scope.$watch(function () {
          return ctrl.office.province;
        }, function (newProvince) {
          ctrl.office.association = null;
          if (newProvince) {
            ctrl.office.country = CountryService.getCountryById(ctrl.available_provinces, newProvince.country_id);
            refreshAssociationsList();
            getFullPlans();
            // set ODA as default for all dentistry offices in ontario
          }
        });
        
        // 
        // update discount when office association changes
        // 
        $scope.$watch(function () {
          return ctrl.office.association;
        }, function (newAssociation) {
          ctrl.office.association_data = null;
          ctrl.discount = null;
          if (newAssociation) {
            subscriptionService.getPromotions({
              'country_id': ctrl.office.country.id,
              'professional_association_id': newAssociation.id
            }).then(function (promotions) {
              if (promotions && promotions.length === 1) {
                ctrl.discount = promotions[0];
                ctrl.association_discount = promotions[0];
              }
            });
          }
        });
        $scope.$watch(function(){
          return ctrl.office.promo_code;
        }, function(newPromoCode, oldPromoCode){
          if(_.isNil(newPromoCode) || newPromoCode === ''){
            // When the user removes the promo code, the association discount should be applied
            ctrl.discount = ctrl.association_discount;
          }
          getFullPlans();
        })
      }

      /**
       * Initializes form values by setting the office name to the practice name if it exists in
       * the state parameters.
       */
      function _initializeFormValues() {
        if ($stateParams.officeName) {
          ctrl.office.name = $stateParams.officeName;
        }

        if ($stateParams.officeLocationId) {
          ctrl.office.location_id = $stateParams.officeLocationId;
          // This will skip the Confirm Practice Modal
          ctrl.is_office_location_id_filled = true;
        }

        if ($stateParams.email) {
          ctrl.office.email = $stateParams.email;
        }

        if ($stateParams.officePhoneNumber) {
          ctrl.office.phone_number = $stateParams.officePhoneNumber;
        }

        if ($stateParams.officeAddress) {
          ctrl.office.address = {}
          ctrl.office.address.address1 = $stateParams.officeAddress.address1;
          ctrl.office.address.address2 = $stateParams.officeAddress.address2;
          ctrl.office.address.city = $stateParams.officeAddress.city;
          ctrl.office.address.postal_code = $stateParams.officeAddress.postal_code;
        }
      }

      /**
       * Populates a dropdown menu with province options based on the selected country.
       */
      function _populateProvinceDropdown () {
        const country = $stateParams.officeAddress?.country_id ?? $stateParams.country;
        CountryService.getAvailableCountryData(country)
          .then((provinces) => {
            ctrl.available_provinces = provinces;
            _setProvinceDropdownValue(country);
          }).catch(() => {
            ctrl.available_provinces = [];
          })
      }

      /**
       * The function sets the value of the province dropdown based on the stateParams content and checks
       * if the province and country are saved, it disables the province dropdown
       * @param country_id {'CA'|'US'|'UK'}
       */
      function _setProvinceDropdownValue(country_id) {
        if ($stateParams.officeAddress?.province_id) {
          // Fill province dropdown
          _setProvince($stateParams.officeAddress?.province_id, country_id)

          // If the province is saved, set the country to the selected province's country
          if (ctrl.office.province) {
            ctrl.office.country = CountryService.getCountryById(ctrl.available_provinces, ctrl.office.province.country_id)

            // If country is saved, disable the province dropdown 
            if (ctrl.office.country) {
              ctrl.is_filled_province_valid = ctrl.is_office_location_id_filled;
            }
          }
        }
      }
      
      function getFullPlans (options) {
        $scope.disableSubmit = true;
        options = _.extend(options, {
          'country_id': ctrl.office.country?.id,
          'province_id': ctrl.office.province?.id,
        });
        return subscriptionService.getPlans(options)
        .then(function (plans) {
          var plan_map = {};
          _.forEach(plans, function (plan) {
            plan_map[plan.hrid] = plan_map[plan.hrid] || {};
            plan_map[plan.hrid][plan.billing_cycle] = plan;
          });
          ctrl.plan_map = plan_map;
          ctrl.subscription_plans = plans; // redundancy
        })
        .finally(() => {
          $scope.disableSubmit = false;
        });
      }
    
      
      /**
       * Checks for showing the free plan option:
       * - there is a free plan object (comes from API `/subscriptions`)
       * - the plan's country matches the the office's country
       * - if the plan requires an association, assert that office belongs to one elligible for it
       * @returns {boolean}
      */
      function canShowFree (free_plan = ctrl.plan_map?.free?.per_year, office = ctrl.office) {
        const free_plan_members_only = free_plan?.association_members_only;
        const free_plan_country_id = free_plan?.country_id;
        const office_country_id = office?.country?.id;
        const office_association = office?.association?.id;

        const office_is_valid_member = (office_association) && (free_plan.associations?.includes(office_association));
        const member_validation = ( !free_plan_members_only || (free_plan_members_only && office_is_valid_member) );
        const country_validation = (free_plan_country_id == office_country_id);
        
        const show_free_plan_condition = Boolean(free_plan && member_validation && country_validation);
        return show_free_plan_condition;
      }
      
      //
      // Capture the Back Button
      //
      function captureBackButton () {
        ctrl.disableCaptureBackButton = $scope.$on('$locationChangeStart', function ($event) {
          var currentIndex = getCurrentIndex();
          
          //
          // If we are still in the process of creating the office, then the
          // back button should move us back through the process.
          //
          if (currentIndex > 0 && currentIndex < 3) {
            previousStage();
            $event.preventDefault();
            return;
          }
          
          //
          // Registration-Specific Stuff
          //
          if ($state.includes('app.registration')) {
            //
            // If we get back to the start of the process, send then back to the
            // "Create / Join" selection state.
            //
            if (currentIndex === 0 && $state.includes('app.registation')) {
              $event.preventDefault();
              $state.go('app.registration.account_created');
              return;
            }
            
            //
            // In all other circumstances, prevent movement.
            //
            $event.preventDefault();
          }
          
        });
      }
      
      function selectPlan (plan_id, is_cardless) {
        ctrl.office.selected_plan = plan_id;
        ctrl.cardless_plan_selected = is_cardless;
        if (is_cardless) {
          createOffice();
        } else {
          _trackSelectPlanAction();
          nextStage();
        }
      }
      
      //
      // Finish creating office / leave this page.
      //
      function finish () {
        ctrl.disableCaptureBackButton();
        
        // var prod_mkt_id = $rootScope.current_office.marketplace.indexOf("mrkt_Wv8WmX34yGvDg3UgVNcVbi") >= 0;
        // var stgn_mkt_id = $rootScope.current_office.marketplace.indexOf("mrkt_v3c5H7HDE5S8Z3XwRMQumc") >= 0;
        // var by_marketplace = (prod_mkt_id || stgn_mkt_id);
        // var by_association = (_.get(ctrl, 'office.association.id', null) === 'oda');
        // var by_plan = (ctrl.office.selected_plan === 'free');
        // var by_feature = $rootScope.current_office.feature_flags['marketplace'];
        // var by_permission = $rootScope.user_permissions['marketplace_shopping_cart'] && $rootScope.user_permissions['marketplace_orders_purchase_orders'];
        // var oda_conditions = (by_marketplace && by_association && by_plan && by_feature && by_permission);
        
        return RouteService.goToDefault();
      }
      
      function odaDialog () {
        $mdDialog.show({
          template: '<supply-hub-dashboard></supply-hub-dashboard>',
          parent: angular.element(document.body),
          clickOutsideToClose: true
        });
      }
      
      function refreshAssociationsList () {
        if (ctrl.office.province && ctrl.office.province.id && ctrl.office.medical_field) {
          $scope.disableForm = true;
          sowAssociationService.getAssociations({
            'province_id':ctrl.office.province.id,
            'medical_field_id':ctrl.office.medical_field.id
          })
          .then(function(results){
            var all = [];
            var required_index = -1;
            _.each(results, function(field_list){
              all = _.concat(all, _.map(field_list.associations, function(assoc, index){
                var parsed_assoc = _.extend(assoc, {'id':assoc.association_id, 'name': assoc.association_name});
                
                // check if this particular association is required for the office's current province
                if( _.get(parsed_assoc, 'default_for_provinces.length', 0) ){
                  var province_found = _.indexOf(parsed_assoc.default_for_provinces, ctrl.office.province.id);
                  if ( province_found >= 0 ) {
                    required_index = index;
                  }
                }
                
                return parsed_assoc;
              }));
            });
            ctrl.associations = all;
            if(required_index >= 0){
              ctrl.office.association = ctrl.associations[required_index];
            }
          })
          .catch(errorService.uiErrorHandler)
          .finally(function(){
            $scope.disableForm = false;
          });
          // ctrl.associations = AssociationService.associationList(
          //     ctrl.office.province,
          //     ctrl.office.medical_field,
          //     ctrl.office.medical_field_subtype);
        } else {
          ctrl.associations = [];
        }
      }
      
      //
      // Send out membership invitations
      //
      function sendInvites () {
        var invitesToSend = [];
        
        angular.forEach(ctrl.invites, function (invite) {
          if(invite.name || invite.email) {
            invite.membership_type = 'manager';
            invitesToSend.push(invite);
          }
        });
        
        if (invitesToSend.length < 1) {
          var t_message = $filter('translate')('ERRORS.NO_INVITES')
          errorService.uiErrorHandler(t_message);
          return;
        }
        
        officeService.sendInvites(invitesToSend).then(function () {
          nextStage();
          $timeout(function () {
            finish();
          }, 5000);
        });
      }
      
      function validateInvites () {
        var valid_invites = 0;
        var invalid_invites = 0;
        _.each(ctrl.invites, function(invite){
          if(invite.name || invite.email) {
            valid_invites++;
          } else {
            invalid_invites++;
          }
        });
        
        return (valid_invites >= 1);
      }
      
      function removeInvite (invite) {
        _.remove(ctrl.invites, invite);
      }
      
      //
      // Add Another Entry to the Invites List
      //
      function addAnotherInvite () {
        ctrl.invites.push({});
      }
      
      //
      // create the new office
      //
      function createOffice ($event) {
        //
        // Build the payload
        //

        var name = _.get(ctrl.office, 'name', '');
        // var capitalized_office_name = name.split(' ').map(_.capitalize).join(' ');
        var capitalized_office_name = name.toProperCase();

        var payload = {
          'name': capitalized_office_name,
          'association': ctrl.office.association || {},
          'medical_field': ctrl.office.medical_field,
          'medical_field_subtype': ctrl.office.medical_field_subtype,
          'subscription_promo_code': (ctrl.discount ? ctrl.discount.code : ctrl.office.promo_code),
          'address': _.extend(ctrl.office.address, {
            'province': ctrl.office.province,
            'country': ctrl.office.country
          }),
          'phone_number': ctrl.office.phone_number,
          'card_token_id': ctrl.office.card_token_id,
          'skyflow_id': ctrl.office?.skyflow_id,
          'selected_plan': ctrl.office.selected_plan,
          location_id: ctrl.office.location_id,
          email: ctrl.office.email,
        };
        
        if (ctrl.office.association_data) {
          angular.extend(payload, {
            'association_data' : ctrl.office.association_data.data,
          });
          if (ctrl.office.association_data.data.license_no)
            angular.extend(payload, {
              'license' : ctrl.office.association_data.data.license_no,
            });
          if (ctrl.office.association.id.toLowerCase() == 'oda') {
            angular.extend(payload.association_data, {
              'oda_member_no' : payload.association_data.oda_member_id,
            });
          }
        }
        
        return _createOffice(payload);
      }

      function showSuiteField () {
        ctrl.is_suite_field_visible = true;
      }

      /**
       * Validates if the office address input field has a value.
       * @returns {boolean}
       */
      function _validatesOfficeAddress () {
        const address1_input = angular.element(document.querySelector('#address1'));
        const address1_input_value = address1_input.val();
        const is_address_valid = address1_input_value.length > 0

        // if the address is valid, set the office address to the input value
        if (is_address_valid) {
          ctrl.office.address.address1 = address1_input_value;
        }

        return is_address_valid;
      }
      
      function validateOfficeAddress ($event, $form) {
        $scope.disableForm = true;

        ctrl.should_validate_form = true;

        const is_address_valid = _validatesOfficeAddress();

        //
        // Enforce form validity
        //
        if (!$form.$valid || !is_address_valid) {
          $form.$setSubmitted();
          $scope.disableForm = false;
          return;
        }
        
        //
        // Enforce Association Exclusivity Requirements
        //
        var medicalFieldId = _.get(ctrl.office, 'medical_field.id');
        var medicalFieldSubtype = _.get(ctrl.office, 'medical_field_subtype');
        var associationId = _.get(ctrl.office, 'association.id');
        var provinceId = _.get(ctrl.office, 'province.id');
        var exclusiveAssoc = AssociationService.getRequiredAssociation(provinceId, medicalFieldId, medicalFieldSubtype);
        
        if (exclusiveAssoc && associationId !== exclusiveAssoc.id) {
          errorService.uiErrorHandler(exclusiveAssoc.exclusivityMsg);
          $scope.disableForm = false;
          return;
        }
        _trackCreateOfficeAction();
        
        if (associationId) {
          $scope.association = _.extend({}, AssociationService.getConfig(associationId), ctrl.office.association);
        } else {
          $scope.association = {};
        }

        if (ctrl.is_office_location_id_filled) {
          return _goToNextStage();
        } else {
          // Open the confirm practice modal with the office address data
          ctrl.office_address_info = {
            address1: ctrl.office.address.address1,
            address2: ctrl.office.address.address2,
            city: ctrl.office.address.city,
            country_id: ctrl.office.country.id,
            name: ctrl.office.name,
            postal_code: ctrl.office.address.postal_code,
            province_id: ctrl.office.province.abbreviation,
          };
        }
      }

      /**
       * If the user confirms the office address, the office location ID is set to the practice ID
       * and the user is taken to the next stage.
       * @param {object} $event
       * @param {object} $event.detail
       */
      function handleAddressConfirmModalEvent($event) {
        const {detail} = $event;
        if (detail) {
          if (detail.practice?.id) {
            ctrl.office.location_id = detail.practice.id;
          }
        }

        // Reset the office address info, so the modal can be opened again
        ctrl.office_address_info = null;

        _goToNextStage();
      }

      function verifyMembership () {

        if (!ctrl.VerificationForm.$valid) {
          ctrl.VerificationForm.$setSubmitted();
          $timeout(function () {
            var scrollPane = $('[name="VerificationForm"]');
            var scrollElem = $('[name="VerificationForm"] md-input-container.md-input-invalid').first();
            var topOfElement = scrollElem.offset().top - parseInt(scrollElem.css('margin-top')) - parseInt('16px');
            var topOfScrollPane = scrollPane.scrollTop() - scrollPane.offset().top;
            scrollPane.animate({'scrollTop': topOfScrollPane + topOfElement});
          }, 100);
          return;
        }
        resetErrors();
        _trackVerifyAssociationAction($scope.data);
        return $scope.association.verify($scope.data, $injector)
        .then(function (payload) {
          if (payload.result) {
            afterVerify(payload, $scope.data.organization);
          } else {
            ctrl.errors.bad_credentials = true;
          }
        })
        .catch(function (error) {
          errorService.uiErrorHandler(error);
          ctrl.errors.server_error = true;
        })
        .finally(function(){
          $scope.disableForm = false;
          var scrollPane = $('[name="VerificationForm"]');

          if (scrollPane.length > 0) {
            scrollPane.animate({scrollTop: scrollPane[0].scrollHeight});
          }
        });
      }

      function resetErrors () {
        _.each(ctrl.errors, function(error, name){
          ctrl.errors[name] = false;
        });
      }

      function afterVerify (data, associationId) {
        var payloadUpdates = {
          'association_data': data,
        };

        if (associationId === 'oda') {
          data.oda_member_no = data.oda_member_id;
          angular.extend(payloadUpdates, {
            'license': data.license_no,
            'phone_number': data.phone_number,
            'address': {
              'address1': data.address1,
              'address2': data.address2,
              'city': data.city,
              'postal_code': data.postal_code,
            }
          });
        }

        _.merge(ctrl.office, payloadUpdates);
        $scope.disableForm = false;
        _goToNextStage();
      }

      /**
       * Applies the promo code (if any) the user has entered. If it's invalid,
       * shows an error toast. If no code has been entered or if the code is
       * valid, advances to the next stage of the registration process.
       */
      async function _goToNextStage() {
        const code = ctrl.office.promo_code;
        /* ensure we've loaded the discount associated with the promo code (if any) */
        // When the user enter an event code, it has more priority than the association discount
        // So we need to remove the association discount
        if (code) {
          try {
            const promos = await subscriptionService.getPromotions({code, country_id: ctrl.office.country.id});
            const discount = _.first(promos);
            if (discount) {
              ctrl.discount = discount;
              // Reset the state of the form as the user moves to the next stage
              $timeout(function(){
                $scope.$apply();
              }, 0);
            } else {
              throw Error('Invalid promo code, no discount found');
            }
          } catch (error) {
            errorService.uiErrorHandler($filter('translate')('MESSAGES.INVALID_CODE'));
            /* exit early since code is invalid */
            return;
          } finally {
            $scope.disableForm = false;
          }
        }
        nextStage();
      }

      function saveCreditCard ($event, $form) {
        $scope.disableForm = true;
        
        //
        // Enforce form validity
        //
        if (!$form.$valid) {
          $form.$setSubmitted();
          $scope.disableForm = false;
          return;
        }
        // if user has selected 'use office address', it will be sent to stripe
        var address_data = ctrl.use_office_address ? _.pick(ctrl.office,['address','province','country']) : {};
        var cc_data = angular.extend({}, ctrl.credit_card, address_data);
        
        return creditCardService
        .createCardToken(cc_data)
        .then(function(token){
          _.set(ctrl.office, 'card_token_id', token.id);
          const billing_address = _.extend({
            country: cc_data.country,
            province: cc_data.province,
          }, cc_data.address);
          creditCardService.addCardToQueue(cc_data, token, billing_address);

          return createOffice();

        })
        .catch(function(error){
          errorService.uiErrorHandler(error);
        })
        .finally(function(){
          $scope.disableForm = false;
        });
        
      }

      /**
       * Plan selection event emitted by the react component
       * and calls the selectPlan function with the selected plan data.
       */
      function handlePlanSelection ({detail: {selected_plan}}) {
        if (selected_plan) {
          const {
            hrid, 
            cardlessRegistration, 
            perMonthCostWithDiscount, 
            perMonthCost
          } = selected_plan;
          ctrl.selected_plan_cost = perMonthCostWithDiscount || perMonthCost;
          ctrl.selectPlan(hrid, cardlessRegistration)
        }
      }
      
      function isCardless () {
        return _.get(ctrl.office, 'selected_plan.cardless_registration', false);
      }
      
      //
      // Returns the animation class to apply to ngSwitch
      //
      function getSwitchAnimationClass () {
        return ctrl.animation_class;
      }
      
      //
      // Where the createOffice magic happens, this allows cleaner code in
      // createOffice by abstracting out the common code.
      //
      function _createOffice (payload) {
        $rootScope.showLoadingOverlay = true;

        return registrationService.createOffice(payload).then(function (office) {
          const pwf_params = {
            email: sessionService.get().user.email, 
            country: payload.address.country.id,
            officeId: office.id,
          }
          return PwfRedirectService.registerNewPwfOffice(pwf_params).then(() => {
            return sessionService.refreshSession().then(function (session) {
              const officeMemberships = session.memberships.office || [];
              
              const membership = _.find(officeMemberships, function (m) {
                return _.get(m, 'office.id') === office.id;
              });
              
              if (!membership) {
                throw 'Unable to find membership for new office.';
              }
  
              const office_id =  office.id;
              if(ctrl.cardless_plan_selected) {
                _trackSelectPlanAction(office_id);
              } else {
                _trackPaymentAction(office_id);
              }
              
              return membershipService.setCurrent(membership, {shouldRedirect: false}).then(function () {
                return sessionService.refreshSession().then(function(){
                  creditCardService.processQueue();
                  nextStage();
                });
              });
            });
          })
        }).catch(function (error) {
          errorService.uiErrorHandler(error);
        })['finally'](function () {
          $scope.disableForm = false;
          $rootScope.showLoadingOverlay = false;
        });
      }
      
      //
      // callback for selecting medical field subtype (aka specialty)
      //
      function selectSpecialty (specialty) {
        ctrl.office.medical_field_subtype = specialty;
        _trackSpecialtyAction(specialty);
        nextStage();
      }
      
      //
      // callback for selecting the medical field
      //
      function selectMedicalField (medicalField) {
        ctrl.office.medical_field = medicalField;
        ctrl.office.medical_field_subtype = null;
        ctrl.office.association = null;
        _trackSelectFieldAction(medicalField.name);
        nextStage();
      }
      
      //
      // shortcut to the current stage index
      //
      function getCurrentIndex () {
        return _.findIndex(stages, function (o) {
          return o === ctrl.current_stage;
        });
      }
      
      //
      // Should we skip this stage?
      //
      function shouldSkipStage () {
        var result = false;
        //
        // if the medical field has no subtypes (aka specialties), then there is
        // nothing for the user to select. we just skip that step.
        //
        if(ctrl.current_stage === 'specialty'){
          var subtype_count = _.get(ctrl, 'office.medical_field.subtypes.length');
          var no_subtypes =  (ctrl.current_stage === 'specialty' && (!subtype_count || subtype_count < 1));
          var cardless_sign_up = (ctrl.current_stage === 'credit-card' && ctrl.cardless_plan_selected);
          
          result = (no_subtypes || cardless_sign_up);
        }

        if(ctrl.current_stage === 'verify-association'){
          
          result = !_.get(ctrl, "office.association.id", false);

          // skip the step if the ID is in the list of association IDs which don't require verification
          if (ctrl.no_validate_association_ids.includes(ctrl.office?.association?.id)) {
            result = true;
          }

        }

        if (ctrl.current_stage === 'select-plan') {
          const associationId = ctrl.office?.association?.id
          if (_.has(ctrl.defaultPlanByAssociationId, associationId)) {
            ctrl.office.selected_plan = ctrl.defaultPlanByAssociationId[associationId];
            result = true;
          }
        }

        if(ctrl.current_stage === 'credit-card'){
          result = ctrl.cardless_plan_selected;
        }

        return result;
      }
      
      //
      // got to the previous stage
      //
      function previousStage () {
        var currentIndex = getCurrentIndex();
        var newIndex = currentIndex - 1;
        ctrl.animation_class = 'ng-switch-slide-right';
        ctrl.current_stage = stages[currentIndex - 1];
        
        if (shouldSkipStage()) {
          newIndex = currentIndex - 2;
          ctrl.current_stage = stages[currentIndex - 2];
        }
        
        $scope.showLogoutButton = (newIndex === 0) && !!$rootScope.current_account && !$rootScope.current_office;
      }
      
      //
      // go to the next stage
      //
      function nextStage () {
        var currentIndex = getCurrentIndex();
        var newIndex = currentIndex + 1;
        $scope.disableForm = false;
        $scope.showLogoutButton = (newIndex === 0) && !!$rootScope.current_account && !$rootScope.current_office;

        
        
        if (currentIndex + 1 < stages.length && currentIndex + 1 >= 0) {
          ctrl.current_stage = stages[currentIndex + 1];
          ctrl.animation_class = 'ng-switch-slide-left';
          if(ctrl.current_stage === 'office-created'){
              finish();
          }
          
          // Check if accountType is in the URL and select the plan accordingly
          if (ctrl.current_stage === 'select-plan' && $stateParams.accountType) {
            const plan = ctrl.plans[$stateParams.accountType.toLowerCase()];
            if (plan) {
              ctrl.selectPlan(plan, ctrl.plan_map[plan][ctrl.billingCycle].cardless_registration);
            }
          }
        } else {
          $log.error(
            'Bad currentIndex Value :: currentIndex:%o ctrl.current_stage:%o stages:%o',
            currentIndex, ctrl.current_stage, stages
            );
        }
          
        if (shouldSkipStage()) {
          nextStage();
        }
        
        if (ctrl.current_stage === 'office-info') {
          refreshAssociationsList();
        }
      }
        
      function provinceRequiresAssoc (province) {
        return _.get(ctrl.province_requirements, province.country_id+'.'+province.id+'.association', false);
      }

      // Called when the user clicks sowingo support email or phone number (Payment section)
      function trackContactSupportAction() {
        sowAnalyticsService.regRequestSupport();
      }

      /**
       * Logs Create office button was clicked (Create office step)
       * @param {object} office
       * @param {object} office.address
       * @param {string} office.address.city
       * @param {object} office.province
       * @param {string} office.province.name
       * @param {object} office.association
       * @param {string|undefined} office.association.name
       * @param {string|undefined} office.promo_code
       */
      function _trackCreateOfficeAction(office = ctrl.office) {
        const params = {
          city: _.get(office, 'address.city'),
          state_province: _.get(office, 'province.name'),
          association: _.get(office, 'association.name', ''),
          event_code: _.get(office, 'promo_code', ''),
        };
        sowAnalyticsService.regOfficeInfo(params);
      }

      /**
       * Logs verify association button was clicked (Verify Association step)
       * @param {object} data
       * @param {string} data.member_id
       * @param {string} data.credential
       */
      function _trackVerifyAssociationAction({member_id, credential}) {
        const params = {
          member_num: member_id,
          last_name: credential,
        };
        sowAnalyticsService.regVerifyAssociation(params);
      }

      /**
       * Logs Select plan button (Start Shopping or Get Started) was clicked (Select plan step)
       * @param {string} office_id
       * @param {string} subscription_plan
       */
      function _trackSelectPlanAction(office_id, subscription_plan = ctrl.office.selected_plan) {
        const params = {
          subscription_plan,
          office_id: office_id ?? '',
        };
        sowAnalyticsService.regSelectPlan(params);
      }

      /**
       * Logs Get started button (premium and premium plus) was clicked (Select payment step)
       * @param {string} office_id
       * @param {boolean} use_office_address - radio button value
       */
      function _trackPaymentAction(office_id, use_office_address = ctrl.use_office_address) {
        const params = {
          address_option: use_office_address ? 'office address' : 'other',
          office_id: office_id,
        };
        sowAnalyticsService.regInputPayment(params);
      }

      /**
       * Logs the selected field (Select Field step)
       * @param {string} field
       */
      function _trackSelectFieldAction(field) {
        const params = {
          field,
        };
        sowAnalyticsService.regSelectField(params);
      }

      /**
       * Logs the selected specialty (Select Specialty step)
       * @param {string} specialty
       */
      function _trackSpecialtyAction(specialty) {
        const params = {
          specialty,
        };
        sowAnalyticsService.regSelectSpecialty(params);
      }

      /**
       * Handles the React address selection event and updates the office address accordingly
       * @param {object} $event
       * @param {object} $event.detail
       * @param {string} $event.detail.city
       * @param {string} $event.detail.postalCode
       * @param {string} $event.detail.state
       * @param {string} $event.detail.suite
       * @param {string} $event.detail.country
       * @param {string} $event.detail.name - office address line 1
       */
      function handleAddressSelectionEvent($event) {
        const {detail} = $event;
        if (detail) {
          const {city, postalCode, state, suite, country, name} = detail;
          ctrl.office.address = ctrl.office.address || {};
          ctrl.office.address.address1 = name;
          ctrl.office.address.city = city ?? ctrl.office.address.city;
          ctrl.office.address.postal_code = postalCode ?? ctrl.office.address.postal_code;
          ctrl.office.address.address2 = suite ?? ctrl.office.address.address2;
          
          if (state) {
            _setProvince(state.shortName, country);
          }
        }
      }

      /**
       * Sets the province of an office based on the provided state and country
       * @param {string} state 
       * @param {string} country
       */
      function _setProvince(state, country) {
        const provinces = CountryService.getCountryById(ctrl.available_provinces, country)?.provinces;
        if (provinces && provinces.length > 0) {
          const province_info = provinces.find(province => province.abbreviation === state);
          if (province_info) {
            ctrl.office.province = province_info;
          }
        }
      }
    }
      
      /* @ngInject */
      function odaDialogCtrl ($scope, $q, $mdDialog, registrationService, errorService) {
        /*jshint validthis: true */
        var ctrl = this;
        
        $scope.data = {};
        $scope.btnPrefix = 'verifyOdaDialog';
        
        ctrl.errors = {};
        
        ctrl.cancel = cancelDialog;
        ctrl.verify = verifyMembership;
        
        return ctrl;
        
        function resetErrors () {
          _.each(ctrl.errors, function(error, name){
            ctrl.errors[name] = false;
          });
        }
        
        function cancelDialog () {
          $mdDialog.cancel();
        }
        
        function saving (func) {
          var deferred = $q.defer();
          $scope.saving = true;
          
          func()
          .then(deferred.resolve, deferred.reject)
          ['finally'](function () { $scope.saving = false; });
          
          return deferred.promise;
        }
        
        function verifyMembership () {
          if (!ctrl.VerificationForm.$valid) {
            return;
          }
          
          resetErrors();
          
          saving(function () {
            return registrationService.verifyODAMembership(
              $scope.data.oda_member_no,
              $scope.data.last_name
              ).then(function (response) {
                var payload = response.data;
                var scrollPane = $('[name="VerificationForm"] > md-dialog-content');
                
                if (payload.result) {
                  $mdDialog.hide(payload.data);
                } else {
                  ctrl.errors.bad_credentials = true;
                  scrollPane.animate({scrollTop: scrollPane[0].scrollHeight});
                }
              }).catch(function (error) {
                errorService.uiErrorHandler(error);
                ctrl.errors.server_error = true;
                scrollPane.animate({scrollTop: scrollPane[0].scrollHeight});
              });
            });
          }
        }
      }());
      
(function () {
  'use strict';

  angular
    .module('app.marketplace.ui')
    .directive('siteFooter', SiteFooterDirective);

  function SiteFooterDirective () {
    return {
      restrict: 'E',
      replace: true,
      controller: SiteFooterCtrl,
      controllerAs: '$footerCtrl',
      templateUrl: 'templates/marketplace/footer/footer.html'
    };
  }

  /* @ngInject */
  function SiteFooterCtrl ($scope, $rootScope, $state, appEvents, sowLanguageService) {
    /*jshint validthis: true */
    var ctrl = this;

    ctrl.hidden = !!_.get($state, 'current.data.hideFooter');
    ctrl.isLarge = true;
    ctrl.isSmall = false;

    ctrl.toggleLanguages = toggleLanguages;
    ctrl.closeLanguages = closeLanguages;
    ctrl.current_year = $rootScope.current_year;

    ctrl.show_languages = false;
    ctrl.handleLanguageAppEvent = handleLanguageAppEvent;

    ctrl.templates = {
      'small': 'templates/marketplace/footer/footer-small.html',
      'small-mobile': 'templates/marketplace/footer/footer-small-mobile.html',
    };

    return init();

    function init () {
      updateFooterSize();
      $scope.$on('$stateChangeSuccess', handleStateChange);
      $scope.$on(appEvents.setAccount, updateFooterSize);
      $scope.$on(appEvents.clearAccount, updateFooterSize);
    }

    //
    // Event handler for the $stateChangeSuccess event.
    //
    function handleStateChange (event, toState, toParams, fromState, fromParams) {
      ctrl.hidden = !!_.get(toState, 'data.hideFooter');
    }

    //
    // Return true/false depending on if the large footer should be shown.
    //
    function shouldShowLargeFooter () {
      return false;
    }

    //
    // Update the isLarge and isSmall flags.
    //
    function updateFooterSize () {
      ctrl.isLarge = shouldShowLargeFooter();
      ctrl.isSmall = !shouldShowLargeFooter();
    }

    function handleLanguageAppEvent(e) {
      const payload = e.detail;
      if (payload.language) {
        setLanguage(payload.language);
      }
    }

    function setLanguage (tag) {
      sowLanguageService.setLanguage(tag);
    }

    function toggleLanguages () {
      ctrl.show_languages = !ctrl.show_languages;
    }

    function closeLanguages () {
      ctrl.show_languages = false;
    }
  }

}());

angular.module("app.marketplace.ui.inventory")
 .controller("hiddenInventoryController",
  function ($scope, $filter, errorService, $mdDialog, sessionService, $state) {

    //
    // Close the dialog
    //
    $scope.close = function () {
      $mdDialog.cancel();
    };

    //
    // Submit the form
    //
    $scope.upgrade = function () {
      $scope.submitting = true;
      return sessionService.updateFlag('prompted_for_premium_in_inventory_with_modal', true).then(function () {
        $state.go('app.office.profile.subscription.view');
        $mdDialog.hide();
      }).catch(function (error) {
        var t_message = $filter('translate')('ERRORS.ACCOUNT_UPDATE')
        errorService.uiErrorHandler(error || t_message, 0);
      })['finally'](function () {
        $scope.submitting = false;
      });
    };
});


(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.cart')
    .directive('cartAddress', cartAddressDirective);

  function cartAddressDirective () {
    return {
      'restrict': 'C',
      'templateUrl': 'templates/marketplace/cart/directives/address.html',
      'scope': {
        'address': '=ngModel',
        'isOpen': '=isOpen',
      },
      'link': cartAddressLinkFunc,
      'controller': cartAddressController,
    };
  }

  /* @ngInject */
  function cartAddressController ($scope, $filter, addressService, cartService, errorService, officeService, sessionService) {
    /*jshint validthis: true */
    var ctrl = this;

    $scope.state = 'initializing';

    ctrl.office = null;

    ctrl.initialize = initialize;
    ctrl.toggleState = toggleState;
    ctrl.useOfficeAddress = useOfficeAddress;
    ctrl.useOfficeAddressBtn = useOfficeAddressBtn;
    ctrl.updateAddressText = updateAddressText;
    ctrl.updateShippingAddress = updateShippingAddress;

    return ctrl;

    //
    // The public interface to controller initialization
    //
    function initialize () {
      return officeService.get(true).then(_initialize); // wait until the office is initialized
    }

    //
    // The "real" initialization function (private)
    //
    function _initialize (office) {
      ctrl.office = office;

      $scope.state = 'show';
      $scope.saving = false;
      $scope.toggleState = ctrl.toggleState;
      $scope.useOfficeAddressBtn = ctrl.useOfficeAddressBtn;
      $scope.hideUseOfficeAddressBtn = !isOfficeAddressValid();

      // used to clear the province when the country is changed.
      $scope.clearProvince = function () {
        $scope._addr.province = null;
      };

      $scope.save = ctrl.updateShippingAddress;
      $scope.$watch('address', ctrl.updateAddressText);
      $scope.$watch('isOpen', handleIsOpen);

      //
      // If there is no existing shipping info, then fill it in with the office
      // address info.
      //
      if (!$scope.address || !$scope.address.country) {
        //
        // The 'Btn' version sends the address to the API. We don't want that
        // to happen if the office address is incomplete.
        //
        if (isOfficeAddressValid()) {
          useOfficeAddressBtn();
        } else {
          useOfficeAddress();
        }
      }

      if (!isShippingAddressValid()) {
        toggleState('edit');
      }

    }

    //
    // Handle changes to $scope.isOpen
    //
    function handleIsOpen (isOpen) {
      if (isOpen) {
        $scope.ShippingAddressForm.$setPristine();
      }
    }

    //
    // Toggle between "show" and "edit" states.
    //
    function toggleState (state) {
      if (arguments.length > 0) {
        $scope.state = state;
      } else {
        $scope.state = ($scope.state === 'edit' ? 'show' : 'edit');
      }

      if ($scope.state === 'show') {
        $scope._addr = null;
      }

      if ($scope.state === 'edit') {
        $scope._addr = angular.copy($scope.address);
      }

      $scope.isOpen = ($scope.state === 'edit');
    }

    //
    // Run address through addressServices's generateAddressText
    //
    function updateAddressText (value) {
      $scope.addressText = addressService.generateAddressText(value);
    }

    //
    // Is this shipping address ($scope.address) valid?
    //
    function isShippingAddressValid () {
      return addressService.validateAddress($scope.address);
    }

    //
    // Submit Changes to Shipping Address
    //
    function updateShippingAddress () {
      var shipping = $scope._addr;

      if (!$scope.ShippingAddressForm.$valid) {
        return;
      }

      $scope.saving = true;

      if (shipping.location_id) {
        delete shipping.location_id;
      }

      return cartService.updateShipping(shipping).then(function (response) {
        return $scope.address && $scope.address.updateOfficeAddress ? updateOfficeAddress() : finish();
      }).catch(function (error) {
        var t_message = $filter('translate')('ERRORS.SHIPPING_INFO')
        errorService.uiErrorHandler(t_message);
      })['finally'](function () {
        $scope.saving = false;
      });

      function finish () {
        if ($scope.state === 'edit') {
          ctrl.toggleState();
        }
      }

      function updateOfficeAddress () {
        return officeService.updateAddress(shipping).then(function (address) {
          return finish();
        }).catch(function (error) {
          var t_message = $filter('translate')('ERRORS.OFFICE_ADDRESS')
          errorService.uiErrorHandler(t_message);
        });
      }
    }

    //
    // Check if the office address is properly filled out.
    //
    function isOfficeAddressValid () {
      var office = officeService.get();
      var address = _.get(office, 'address');
      return addressService.validateAddress(address);
    }

    //
    // Copy Office Address into $scope._addr
    //
    function useOfficeAddress () {
      $scope._addr = angular.copy(ctrl.office.address);

      if ($scope._addr) {
        $scope._addr.company_name = ctrl.office.name;
        $scope._addr.name = sessionService.session.user.name;

        if ($scope._addr.country) {
          $scope._addr.country = _.find($scope.$root.config.countries, {id: $scope._addr.country.id});
        }
      }

    }

    //
    // When the button is clicked, we want to also update the shipping address,
    // and close the form.
    //
    function useOfficeAddressBtn () {
      useOfficeAddress();
      return ctrl.updateShippingAddress();
    }
  }

  /* @ngInject */
  function cartAddressLinkFunc ($scope, $element, $attrs, ctrl) {
    ctrl.initialize();
  }

}());

(function () {
  'use strict';

  angular.module('sowProduct')
  .controller('pdNotFoundController',pdNotFoundController)
    .directive('pdNotFound', pdNotFoundDirective);

  function pdNotFoundDirective() {
    return {
      restrict: 'E',
      templateUrl: 'sow-product-details/directives/pd-not-found.html',
      controller: 'pdNotFoundController',
      controllerAs: 'pdNFCtrl',
    };
  }

  function pdNotFoundController () {
    var ctrl = this;

    ctrl.openChat = openChat;

    return ctrl;

    /** 
     * Opens the Zopim chat overlay. 
     * 
     * @return {*} 
    */
    function openChat () {
      $zopim.livechat.window.show();
    }
  }

})();

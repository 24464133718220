(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.registration')
    .directive('gradientPage', gradientPage);

  function gradientPage () {
    return {
      'restrict': 'C',
      'replace': true,
      'transclude': true,
      'template': [
        '<div layout="column" layout-align="center center">',
        '  <div ng-transclude class="panel md-whiteframe-z2" layout="column" layout-align="start center">',
        '  </div>',
        '</div>',
      ].join("\n")
    };
  }
}());

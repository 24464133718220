angular.module('app.marketplace.elements').service('categoryService', function ($log, $q, elementService) {
  var _this = this;

  //
  // Filter out categories with no products in any medical field. TODO consider refactor.
  //
  function _hasProductsFilter(categories) {
    var filtered = [];

    for (var i = 0, len = categories.length; i < len; ++i) {
      _.map(categories[i].UI.mf_product_counts, function(mf){
        if (mf) {
          filtered.push(mf);
        }
      });
    }

    return filtered;
  }

  //
  // Return only categories that have products in them.
  //
  _this.getCategoriesWithProducts = function () {
    return _this.getCategories().then(function (categories) {
      return _hasProductsFilter(categories);
    });
  };

  //
  // Return a map of all categories.
  //
  _this.getCategories = function () {
    return elementService.getElements('category').then(function (categories) {
      return _.values(categories);
    });
  };

  return _this;
});


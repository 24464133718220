(function() {
  "use strict";

  angular
    .module("app.marketplace.ui.inventory")
    .controller(
      "inventoryCreateProductController",
      inventoryCreateProductController
    );

  function inventoryCreateProductController(
    $rootScope,
    Upload,
    sowSterilizationService,
    $timeout,
    $window,
    $filter,
    elementService,
    $mdDialog,
    $state,
    officeModel,
    officeService,
    alertsDataCarrierService,
    errorService,
    sowSdsService,
    inventoryItemService,
    AddLocationsDialog,
    sgToast,
    EditLocationDialog,
    DeleteLocationDialog,
    serverAPI,
    membershipService,
    $http
  ) {
    var ctrl = this;
    var locations;
    ctrl.saving = false;
    ctrl.img_file = {};
    ctrl.file_info = {};
    ctrl.product = {
      name: "",
      manufacturer: "",
      category_id: "",
      expiry_dates: [],
      locations: [],
      buy_by_case: "false",
      order_package_quantity: "",
      supplier: "",
      sku: "",
      price: null,
      tracking_method: "Item",
      minimum_level: "",
      desired_level: "",
      notes: "",
      on_hand: "",
      product_id: "",
      image_src: "",
      sds_url: "",
      product_id : "",
      barcodes: ""
    };
    ctrl.categories = [];
    ctrl.subcategories = [];
    ctrl.subcategories_for_category = [];

    ctrl.handleCategoryChange = handleCategoryChange;

    ctrl.getDefaultFileName = function(original_name) {
      var extension = _.last(_.split(original_name, "."));
      var format = "YYYYMMDD_HHmmss";
      var date = moment()
        .utc()
        .format(format);
      return _.join([date, extension], ".");
    };

    ctrl.changeBoughtIndividual  = function(){
      ctrl.product.tracking_method = 'Item';
    }

    ctrl.uploadFile = function(file, errFiles) {
      ctrl.file_progress = -1;
      ctrl.file_info = file;
      if (file) {
        ctrl.file_size = parseFloat((file.size / 1048576).toFixed(2)) + " MB";
        var fileName = file.name;
        sowSterilizationService
          .getFileUploadTokenInventory(ctrl.getDefaultFileName(fileName))
          .then(function(el) {
            ctrl.uploadFileToAWS(el, file);
          });
      }
    };

    ctrl.removeFile = function(file_path){
      sowSdsService.fileRemove(file_path)
        .then(function(){
          var t_message = $filter('translate')('TOAST.FILE_REMOVED');
          sgToast.showSimple(t_message);
        })
        .catch(function(err){
          return true;
        });
    }

    ctrl.resetFileUpload = function(file_path){
      // ctrl.removeFile(file_path);
      ctrl.file_progress = -1;
      ctrl.file_src = null;
      ctrl.product.sds_url = null;
      var t_message = $filter('translate')('TOAST.FILE_REMOVED');
      sgToast.showSimple(t_message);
    }

    ctrl.uploadFileToAWS = function(aws_data, file) {
      Upload.upload({
        url: aws_data.aws_url,
        method: "POST",
        transformRequest: function (data, headersGetter) {
          var headers = headersGetter();
          delete headers.Authorization;
          return data;
        },
        fields: {
          key: aws_data.key,
          AWSAccessKeyId: aws_data.AWSAccessKeyId,
          acl: aws_data.acl,
          policy: aws_data.policy,
          signature: aws_data.signature,
          "Content-Type": aws_data['Content-Type'],
          success_action_status: aws_data.success_action_status
        },
        file: file,
        headers: {
          enctype: "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
          "Content-Type": undefined
        }
      }).progress(function(evt) {
          ctrl.file_progress = parseInt(100.0 * evt.loaded / evt.total);
      }).success(function(response, status, headers, config) {
        ctrl.product.sds_url = aws_data.aws_url + aws_data.key;
      }).error(function(response, status, headers, config) {
      }).xhr(function(xhr) {
          ctrl.abortFile = function() {
            xhr.abort();
            setTimeout(function(){
              ctrl.file_progress = -1;
              })
          };
      });
    };

    ctrl.init = function() {
      $(document).ready(function() {
        $(window).keydown(function(event){
          if(event.keyCode == 13) {
            event.preventDefault();
            return false;
          }
        });
      });
      ctrl.checkFi();
      ctrl.getCategories();
      defineLocks();
    };

    ctrl.checkFi = function() {
      if (!ctrl.locations) {
        ctrl.getLocations();
      } else {
        locations = angular.extend([], ctrl.locations);
      }
    };

    ctrl.getInventory = function() {
      officeService.getInventory().then(
        function(inventory) {
          locations = angular.extend([], inventory.locations);
        },
        function(error) {
          var t_message = $filter('translate')('ERRORS.INVENTORY_LOCATIONS_LOAD')
          errorService.uiErrorHandler(
            error || t_message,
            0
          );
        }
      );
    };

    ctrl.getLocations = function() {
      officeService.getLocations().then(
        function(locations_response) {
          locations = locations_response;
        },
        function(error) {
          var t_message = $filter('translate')('ERRORS.INVENTORY_LOCATIONS_LOAD')
          errorService.uiErrorHandler(
            error || t_message,
            0
          );
        }
      );
    };

    ctrl.manufacturerSearch = function(name) {
      if (!name) {
        return false;
      }

      return elementService
        .get("manufacturer", null, {
          endpoint: "search",
          name: name
        })
        .then(function(manufacturers) {
          return _.map(manufacturers, "name");
        });
    };

    ctrl.getCategories = function() {
      inventoryItemService.getInventoryCategories().then(function(response) {
        var categories = response.data;
        var removed_cat = _.remove(categories, function(obj) {
          return obj.name === "Other";
        });
        categories = _.sortBy(categories, ["name"]);
        categories.push(removed_cat[0]);
        const subcategories = _.remove(categories, function(obj) {
          return !_.isNil(obj?.parent_id);
        } );
        ctrl.categories = categories;
        ctrl.subcategories = subcategories;
        ctrl.subcategories_for_category = [];

        var params = $state.params;
      if(params){
        ctrl.product.product_id  = params.id;
        ctrl.product.name = params.name;
        ctrl.product.manufacturer = params.manu;
        ctrl.product.manufacturer_id = params.manu_id;
        ctrl.product.vendor_name = params.vendor_name;
        ctrl.product.category_id = params.category;
        ctrl.product.barcodes = params.barcodes;
        if(params.img){
          ctrl.product.image_src = params.img;
          ctrl.image_src = params.img;
          ctrl.img_progress = 100;
          var i = ctrl.image_src.split('/');
          ctrl.img_file.name = i[i.length -1];
        }

        if(params.sds){
          ctrl.product.sds_url = params.sds;
          ctrl.file_src = params.img;
          ctrl.file_progress = 100;
          var i = ctrl.file_src.split('/');
          ctrl.file_info.name = i[i.length -1];
        }
      }
      });
    };

    ctrl.addExpiryDate = function() {
      ctrl.product.expiry_dates = ctrl.product.expiry_dates || [];

      if (ctrl.itemClone.new_expiry_date) {
        var newDate = true;
        _.map(ctrl.product.expiry_dates, function(expiry_date, di) {
          if (
            moment(new Date(expiry_date.date))
              .startOf("day")
              .isSame(moment(ctrl.itemClone.new_expiry_date).startOf("day"))
          ) {
            newDate = false;
          }
        });
        if (newDate) {
          ctrl.product.expiry_dates = ctrl.formatExpiryDates(
            ctrl.product.expiry_dates,
            ctrl.itemClone.new_expiry_date
          );
        }
      }
      delete ctrl.itemClone.new_expiry_date; //clear
    };

    ctrl.formatExpiryDates = function(expiry_dates, new_expiry_date) {
      var dates = angular.copy(expiry_dates);

      var item =
        ctrl.action === "update"
          ? {
              date: new_expiry_date
            }
          : new_expiry_date;

      dates.push(item);
      return dates;
    };

    ctrl.displayExpiryDate = function(expiry_date) {
      return ctrl.action === "update" ? expiry_date.date : expiry_date;
    };

    ctrl.locationSearch = function(query) {
      if (!query) {
        return locations;
      }

      var results = [];

      _.map(locations, function(location, l) {
        if (location.name.toLowerCase().indexOf(query.toLowerCase()) > -1) {
          results.push(location);
        }
      });

      return results;
    };

    ctrl.addLocation = function(location, locationName) {
      if (!ctrl.itemClone || (!locationName && (!location || !location.name))) {
        return false;
      }

      if (!ctrl.itemClone.locations) {
        ctrl.itemClone.locations = [];
      }

      location = location || {
        name: locationName
      };

      if (!location.id) {
        _.forEach(locations, function(loc) {
          if (loc.name.toLowerCase() === location.name.toLowerCase()) {
            location = loc;
            return false;
          }
        });
      }

      var existsOnItem = false;

      _.forEach(ctrl.itemClone.locations, function(loc) {
        if (location.id && loc.id === location.id) {
          existsOnItem = true;
          return false;
        }

        if (loc.name.toLowerCase() === location.name.toLowerCase()) {
          existsOnItem = true;
          return false;
        }
      });

      if (!existsOnItem) {
        ctrl.itemClone.locations.push(location);
      }

      //
      // TODO This could get merged into the loop over ctrl.locations
      // above.
      //
      var newLoc = true;
      _.map(locations, function(this_location, li) {
        if (this_location.name.toLowerCase() === location.name.toLowerCase()) {
          newLoc = false;
        }
      });

      if (newLoc) {
        locations.push(location);
      }

      // DOM Manipulatiion like this should be done in a directive link
      // method as opposed to a controller.
      var inputEle = angular.element(
        document.getElementById("location-add-input")
      );
      if (inputEle) {
        inputEle.val("");
        inputEle.scope().$mdAutocompleteCtrl.hidden = true;
        inputEle.blur();
      }
    };

    ctrl.onSupplierSearch = function(search) {
      if (!search) {
        return false;
      }

      search = search.toLowerCase();
      return elementService
        .get("supplier", null, {
          endpoint: "search",
          name: search
        })
        .then(function(data) {
          var suppliers = getSuppliersArray(data);
          var firstList = suppliers.filter(function(supplier) {
            return _.startsWith(supplier.name.toLowerCase(), search);
          });
          var secondList = suppliers.filter(function(supplier) {
            return _.includes(supplier.name.toLowerCase(), search);
          });

          return _.unionBy(firstList, secondList, "id");
        });
    };

    function getSuppliersArray(data) {
      var suppliers = [];
      _.each(data, function(supplier) {
        suppliers.push({
          id: supplier.id,
          name: supplier.name
        });
      });

      return suppliers;
    }

    ctrl.submit = function($form) {
      if ($form.$invalid || ctrl.saving) {
        return false;
      }
      ctrl.saving = true;
      ctrl.product.locations = ctrl.itemClone.locations;
      ctrl.product.buy_by_case == "false" ? false : true;
      ctrl.product.manufacturer =
        ctrl.product.manufacturer || ctrl.product.manufacturerSearchText;
      ctrl.product.on_hand = _.isNil(ctrl.on_hand) ? 0 : ctrl.on_hand;
      ctrl.product.supplier = ctrl.product.supplier
        ? ctrl.product.supplier.name
        : ctrl.itemClone.supplierSearchText;
      return inventoryItemService
        .createItemInventory(ctrl.product)
        .then(function(inventory_item) {
          var t_message = $filter('translate')('TOAST.INVENTORY_ITEM_CREATED');
          sgToast.showSimple(t_message);
          // alertsDataCarrierService.load_alerts();
          if(ctrl.product.product_id && ctrl.product.barcodes){
            showMessages(inventory_item.id);
          }else{
            showScanAndLink(inventory_item.id);
          }
        })
        .catch(function(error) {
          var t_message = $filter('translate')('ERRORS.INVENTORY_UPDATE')
          errorService.uiErrorHandler(
            error || t_message,
            0
          );
        })
        .finally(function(){
          ctrl.saving = false;
        });
    };

    function showScanAndLink(id) {
      $mdDialog
        .show({
          template:
            '<inventory-create-success context="product"></inventory-create-success>',
          parent: angular.element(document.body),
          clickOutsideToClose: true
        })
        .finally(function() {
          $state.go('app.inventory.all', {itemId: id}, {reload: true});
        });
    }
    function showMessages(id) {
      $mdDialog
        .show({
          template:
            '<inventory-create-success-product context="product"></inventory-create-success-product>',
          parent: angular.element(document.body),
          clickOutsideToClose: true
        })
        .finally(function() {
          $state.go('app.inventory.all', {itemId: id}, {reload: true});
        });
    }

    function newSlideout(item) {
      $rootScope.$broadcast("inventory-item-slideout", null, item.id);
    }

    ctrl.cancel = function($form){
        var confirm = $mdDialog.confirm({
          template:
            '<inventory-create-product-confirmation></inventory-create-product-confirmation>',
          parent: angular.element(document.body),
        })
        $mdDialog.show(confirm).then(function() {
          $form.$submitted = true;
          ctrl.submit($form);
        }).catch(function() {
          $state.go('app.inventory.add-new-product');
        });
    }

    ctrl.addLocationByEnter = function(e, location, locationSearchText){
      if(e.which == 13){
        e.preventDefault();
        ctrl.addLocation(location, locationSearchText);
      }
    }

    ctrl.init();

    function defineLocks () {
      const membership = membershipService.get();
      
      const add_new_lock_condition = _.get(membership, "user_properties.gen_inv.disable_add_new", false);
      ctrl.disabled_add_new = add_new_lock_condition;

      const edit_min_level_condition = _.get(membership, "user_properties.gen_inv.disable_min_level", false);
      ctrl.disabled_edit_minimum_level = edit_min_level_condition;

      const edit_desired_level_condition = _.get(membership, "user_properties.gen_inv.disable_desired_level", false);
      ctrl.disabled_edit_desired_level = edit_desired_level_condition;
    }

    /**
     * Handles the change event when the user selects a new category.
     * Resets the subcategory_id property of the product and updates
     * the subcategories_for_category array to only include
     * subcategories that belong to the selected category (if any).
     */
    function handleCategoryChange() {
      ctrl.product.subcategory_id = null;
      const {category_id} = ctrl.product;
      ctrl.subcategories_for_category = category_id
        ? ctrl.subcategories.filter(s => s.parent_id === category_id)
        : [];
    }
  }
})();

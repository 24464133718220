(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.office')
    .controller('OfficeSettingsController', OfficeSettingsController);

  function OfficeSettingsController ($scope, $filter, $timeout, $mdDialog, sgToast, officeModel, officeService, errorService, AccessService, localStorageService) {
    /*jshint validthis: true */
    var ctrl = this;

    $scope.office = null;
    $scope.officeModel = officeModel;
    $scope.updateOffice = updateOffice;
    $scope.clearInventory = clearInventory;
    // $scope.removeOffice = removeOffice;
    $scope.handleManageLogoClick = handleManageLogoClick;
    $scope.handleManageLogoEvent = handleManageLogoEvent;

    init();

    return ctrl;

    //
    // Initialize the Controller
    //
    function init () {
      $scope.practice_info = localStorageService.get('practice-info');

      $timeout(function () {
        $scope.officeInfoForm.$setPristine();
      });

      $scope.$on('officeService: set-office', function (scope, office) {
        setOffice(office);
      });

      $scope.$on('officeService: clear-office', function () {
        setOffice();
      });

      setOffice(officeService.get());
      defineLocks();
    }

    //
    // Change the Office
    //
    function setOffice (office) {
      $scope.office = office;
      $scope.edit_office = angular.copy(office);

      if ($scope.officeInfoForm) {
        $scope.officeInfoForm.$setPristine();
      }
    }

    //
    // Save Changes to the Office
    //
    function updateOffice () {
      if (!$scope.officeInfoForm.$valid) {
        $scope.officeInfoForm.$setSubmitted();
        return;
      }

      $scope.updatingOffice = true;

      officeService.updateOffice($scope.edit_office).then(function success (office) {
        $scope.updatingOffice = false;
        var t_message = $filter('translate')('TOAST.OFFICE_UPDATED');
        sgToast.showSimple(t_message);
        init(office);
      }).catch(function error (response) {
        $scope.updatingOffice = false;

        if (checkNested(response, 'data', 'error', 'message')) {
          errorService.uiErrorHandler(response.data.error.message);
        } else {
          errorService.uiErrorHandler('Office Update Failed');
        }
      });
    }

    //
    // Delete the Office
    //
    // function removeOffice () {
    //   if (!$scope.updatingOffice) {
    //     $scope.updatingOffice = true;
    //     officeService.removeOffice($scope.office).then(function success(office) {
    //       $scope.updatingOffice = false;
    //       sgToast.showSimple('The office has been removed');
    //       _init($scope.$root.current_office);
    //     }).catch(function error(response) {
    //       $scope.updatingOffice = false;
    //       if (checkNested(response, 'data', 'error', 'message')) {
    //         errorService.uiErrorHandler(response.data.error.message);
    //       } else {
    //         errorService.uiErrorHandler(response);
    //       }
    //     });
    //   }
    // }

    //
    // Remove all items from inventory
    //
    function clearInventory (event) {
      $mdDialog.show({
        'controller': ClearInventoryDialogCtrl,
        'controllerAs': '$ctrl',
        'templateUrl': 'templates/marketplace/office/clear-inventory-dialog.html',
        'parent': angular.element(document.body),
        'targetEvent': event,
        'clickOutsideToClose': true,
      }).then(function () {
        $scope.showLoadingBackdrop();

        officeService.clearInventory().then(function () {
          $scope.hideLoadingBackdrop();
          var t_message = $filter('translate')('TOAST.OFFICE_INVENTORY_CLEARED');
          sgToast.showSimple(t_message);
        }).catch(function () {
          $scope.hideLoadingBackdrop();
          errorService.showErrorToast('Failed to clear inventory.');
        });
      });
    }

    function handleManageLogoClick() {
      $scope.is_managing_logo = true;
    }

    function handleManageLogoEvent({detail: {hide, url}}) {
      if (hide) {
        $scope.is_managing_logo = false;
      }
      if (url) {
        $scope.practice_info.logoUrl = url;
        localStorageService.set('practice-info', $scope.practice_info);
      }
    }

    function defineLocks () {
      $scope.clear_inventory_locked = AccessService.getProperty('office.disable_clear_inventory');
      $scope.disable_edit_office_details = AccessService.getProperty('office.disable_edit_office_details');
    }

  }

  /* @ngInject */
  function ClearInventoryDialogCtrl ($scope, $mdDialog) {
    /*jshint validthis: true */
    var ctrl = this;
    var confirmationValues = ['DELETE', 'SUPPRIMER'];

    $scope.confirmationText = '';

    ctrl.cancel = cancel;
    ctrl.confirm = confirm;
    ctrl.canConfirm = canConfirm;

    return ctrl;

    function canConfirm () {
      return confirmationValues.includes($scope.confirmationText);
    }

    function cancel () {
      $mdDialog.cancel();
    }

    function confirm () {
      $mdDialog.hide();
    }
  }
}());

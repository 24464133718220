(function () {
  'use strict';

  angular.module('app.marketplace.elements')
         .service('membershipService', membershipService);

  // TODO Maybe move this to a more central location so that initialization can
  //      be better planned / coordinated.

  function membershipService ($q, $log, $rootScope, $state, $filter, sessionService, elementService, errorService, AccountModalService, RouteService, appEvents, zendeskChatService) {
    /*jshint validthis: true */
    var service = this;

    service.membership = null;
    service.initialized = false; // Set to true after the service has been initialized
    service._shouldSuppressSwitcher = false;

    service.init = init;
    service.onInit = _waitForInitialized;
    service.get = getMembership;
    service.getAll = getAllMemberships;
    service.setCurrent = setCurrent;
    service.changeCurrentMembership = _changeCurrentMembership;
    service.canPurchaseRegulated = canPurchaseRegulated;
    service.suppressSwitcher = suppressSwitcher;
    service.setMembershipFromOfficeId = setMembershipFromOfficeId;

    return service;

    //
    // While function 'func' is running, don't allow the membership selection
    // pop-up to be triggered.
    //
    function suppressSwitcher (func) {
      service._shouldSuppressSwitcher = true;

      return $q.when(func())['finally'](function () {
        service._shouldSuppressSwitcher = false;
      });
    }

    //
    // Set Membership From Office ID
    //
    function setMembershipFromOfficeId (office_id) {
      var officeMemberships = sessionService.getOfficeMemberships();
      var officeMembership = _.find(officeMemberships, function (m) { return _.get(m, 'office.id') === office_id; });

      if (officeMembership) {
        return setCurrent(officeMembership);
      } else {
        var t_message = $filter('translate')('ERRORS.OFFICE_SELECT')
        errorService.uiErrorHandler(t_message);
        return $q.reject();
      }
    }

    //
    // Set the current Membership
    //
    function updateLocalMembership (membership) {
      service.membership = membership;
      sessionService.updateLocalMembership(membership);
      service.initialized = true;
    }

    //
    // Clear the current membership within the app.
    //
    function clearMembership () {
      service.membership = null;
      $rootScope.current_membership = null;
      $rootScope.$broadcast('membershipService: clear-membership');
      service.initialized = true;
    }

    //
    // Convience function for waiting for initialized
    //
    function _waitForInitialized () {
      if (service.initialized) {
        return $q.when(service.membership);
      } else {
        var deferred = $q.defer();
        var getInitialized = function () {
          return service.initialized;
        };
        var initWatch = $rootScope.$watch(getInitialized, function(newValue, oldValue){
          if (newValue) {
            deferred.resolve(service.membership);
            initWatch(); // Stop watch;
          }
        });
        return deferred.promise;
      }
    }

    //
    // Return the current membership
    //
    function getMembership (waitForInitialized) {
      if (waitForInitialized) {
        return _waitForInitialized().then(function (membership) {
          return membership ? membership : $q.reject('No membership');
        });
      } else {
        return service.membership;
      }
    }

    //
    // Fetch the current membership for this session from the API.
    //
    function fetchCurrentMembership () {
      return elementService.get('membership', null, {
        endpoint: 'current',
        return_type: 'single',
      }).then(function (membership) {
        if (membership) {
          updateLocalMembership(membership);
        }else{
          clearMembership();
        }
        return !!membership;
      }).catch(function(error){
        clearMembership();
        return error;
      });
    }

    //
    // Set Current Membership
    //
    function setCurrent (membership, options) {
      if (!membership || !membership.id) {
        errorService.uiErrorHandler('Unable to Select Office.', 0);
        sessionService.logout();
        return $q.reject('No membership ID.');
      }

      var shouldRedirect = _.get(options, 'redirect', false);

      // Awkward endpoint TODO reassess .get does not feel right
      return elementService.callEndpoint('membership', {
        endpoint: 'set_current',
        membership_id: membership.id,
        return_type: 'single',
        doUpdate: true
      }).then(function (membership) {
        updateLocalMembership(membership);
        const session = sessionService.get();
        zendeskChatService.updateZendeskFormWithUserInfo(session)

        if (shouldRedirect) {
          RouteService.goToDefault();
        }
      }).catch(function (error) {
        errorService.uiErrorHandler(error || 'Unable to Select Office.', 0);
        if (!service.membership) {
          sessionService.logout();
        }
        throw error;
      });
    }

    //
    // Used to grab the current membership from a session object. This way we
    // don't need to make redundant API calls.
    //
    function getCurrentMembershipFromSession (session) {
      if (!session.current_membership || !session.current_membership.id) {
        return false;
      }

      var membership = elementService.create('membership', session.current_membership);
      membership.cart = session.cart ? session.cart : membership.cart;
      updateLocalMembership(membership);
      return true;
    }

    //
    // Set Initial "current" membership on application load / login.
    //
    function initCurrentMembership (session) {
      var deferred = $q.defer();
      if (service._shouldSuppressSwitcher) {
        deferred.resolve(true);
      }else if (!_.has(session, 'memberships.office') || session.memberships.office.length < 1) {
        //
        // session.memberships.office should be an array of all active office
        // memberships that this user has. If there are none, then this user
        // really can't do anything on the Marketplace.
        //
        $log.debug('membershipService: User session has no office memberships. Bailing out.');
        AccountModalService.hide();
        clearMembership();
        deferred.resolve(false);
      }else if (getCurrentMembershipFromSession(session)) {
        //
        // If the current session already has a current membership set on it,
        // then let's use that (and save ourselves from some round-trips to the
        // API).
        //
        AccountModalService.hide();
        // RouteService.goToDefault(true);
        deferred.resolve(true);
      }else{
        return fetchCurrentMembership().then(function (membershipExists) {
          if (membershipExists) {
            AccountModalService.hide();
            return;
          }

          // Check if route comes from redirect lambda response and set current membership using url params
          if (
           ['required_login', 'practice_login'].includes($state.current.name) && 
            $state.params.toState?.includes('app.workflows') &&
            $state.params.officeMembershipId
          ) {
            // Set current membership from url params
            return setCurrent({id: $state.params.officeMembershipId}, {redirect: true})
          }

          //
          // If there is only one office, then we can just set that office
          // without needing to prompt the user.
          //
          if (session.memberships.office.length === 1) {
            var options = {
              'redirect': ($state.current.name === 'app.registration.start'),
            };

            return setCurrent(session.memberships.office[0], options)
              .then(function (response) {
                AccountModalService.hide();
                return response;
              });
          }

          //
          // If there is more then one office, we need the user to make a
          // selection.
          //
          if (session.memberships.office.length > 1) {
            // Ask user for current office/membership.
            return AccountModalService.show('SelectMembership').then(function (membership) {
              var options = {
                'redirect': ($state.current.name === 'app.registration.start'),
              };

              return setCurrent(membership, options);
            });
          }
        }).catch(function () {
          clearMembership();
          window.location.href = '/';
        });
      }

      return deferred.promise;
    }

    //
    // Trigger the Change Membership Modal
    //
    function _changeCurrentMembership (ev) {
      AccountModalService.show('ChangeMembership').then(function (membership) {
        setCurrent(membership).then(function(){
          sessionService.refreshSession();
        });
      });
    }

    //
    // Return List of All Memberships
    //
    function getAllMemberships () {
      return service.memberships;
    }

    //
    // Does this Membership Give User Ability to Purchase Regulated Products?
    //
    function canPurchaseRegulated () {
      return service.membership.can_purchase_regulated;
    }

    //
    // Handle the event when a new session is set.
    //
    function handleNewSession (event, session) {
      if (session && session.user_id) {
        service.memberships = session.memberships;
        initCurrentMembership(session)['finally'](function(){
          if(!service.initialized){
            clearMembership();
          }
        });
      } else {
        clearMembership();
      }
    }

    //
    // Initialize membershipService
    //
    function init () {
      //
      // Make sure that the data isn't stale if the user updates office
      // information.
      //
      $rootScope.$on('office-model-updated', function ($event, office) {
        // $log.debug('DEBUG: Caught office-model-updated office:%o', office);
        _.forEach(service.memberships.office, function (officeMembership) {
          if (office.id === officeMembership.office.id) {
            officeMembership.office = office;
          }
        });
      });

      $rootScope.$on(appEvents.setSession, handleNewSession);
      $rootScope.$on(appEvents.clearSession, clearMembership);
    }
  }

}());

(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.medications')
    .controller('medCountListController', medCountListController);

  function medCountListController ($scope, $state, $stateParams) {
    /*jshint validthis: true */
    var ctrl = this;

    ctrl.closeOffice = closeOffice;
    ctrl.openOffice = openOffice;
    ctrl.editEntry = editEntry;

    init();

    return ctrl;

    function init () {
      mockupData();
      if ($stateParams.close_date) {
        var day = _.find(ctrl.bundles[0].entries, ["date",$stateParams.close_date]);
        if (!day) {
          day = ctrl.today;
        }
        closeOffice(day);
      }
    }

    function closeOffice (day) {
      day.UI.state = "closed";
      day.skipped = true;
    }

    function openOffice (day) {
      day.UI.state = "empty";
      day.skipped = false;
    }

    function editEntry (day) {
      if(!day.skipped){
        $state.go("app.medications.count.detail",{date:day.date});
      }
    }

    function mockupData () {
      ctrl.today = {
        UI: {
          state: "empty",
          date_label: moment().format("ddd, MMM D YYYY"),
          empty_data: true,
          incomplete_data: false,
        },
        date: moment().format("YYYY-MM-DD"),
        skipped: false,
      };

      ctrl.bundles = [
        {
          "date": moment().format("YYYY-MM"),
          "entries": [
            {
              UI: {
                state: "complete",
                date_label: moment().subtract(1,'d').format("ddd, MMM D YYYY"),
                empty_data: false,
                incomplete_data: false,
              },
              id: 1,
              date: moment().subtract(1,'d').format("YYYY-MM-DD"),
              skipped: false,
            },
            {
              UI: {
                state: "complete",
                date_label: moment().subtract(2,'d').format("ddd, MMM D YYYY"),
                empty_data: false,
                incomplete_data: false,
              },
              id: 2,
              date: moment().subtract(2,'d').format("YYYY-MM-DD"),
              skipped: false,
            },
            {
              UI: {
                state: "closed",
                date_label: moment().subtract(3,'d').format("ddd, MMM D YYYY"),
                empty_data: true,
                incomplete_data: false,
              },
              id: 3,
              date: moment().subtract(3,'d').format("YYYY-MM-DD"),
              skipped: true,
            },
            {
              UI: {
                state: "closed",
                date_label: moment().subtract(4,'d').format("ddd, MMM D YYYY"),
                empty_data: true,
                incomplete_data: false,
              },
              id: 4,
              date: moment().subtract(4,'d').format("YYYY-MM-DD"),
              skipped: true,
            },
            {
              UI: {
                state: "empty",
                date_label: moment().subtract(5,'d').format("ddd, MMM D YYYY"),
                empty_data: true,
                incomplete_data: false,
              },
              id: 5,
              date: moment().subtract(5,'d').format("YYYY-MM-DD"),
              skipped: false,
            },
          ],
          "description": moment().format("MMMM YYYY"),
        }
      ];
    }
  }
}());

(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.subscriptions')
    .directive('premiumFeatures', premiumFeaturesDirective);

  function premiumFeaturesDirective ($log) {
    return {
      'restrict': 'E',
      'replace': true,
      'templateUrl': 'templates/marketplace/subscriptions/features-list.html',
      'controller': 'PlanFeaturesCtrl',
      'scope': {
        'isLarge': '@',
        'associationId': '=',
        'isCollapsed': '=',
      },
      // to do: link method should be used when modifying the DOM. It would be
      // good to move the initialization values to the controller itself.
      'link': function ($scope, $element, $attrs, $ctrl) {
        var features = getFeatures($scope.associationId);

        $ctrl.init({
          'defaultClasses': 'premium-plan',
          'heading': 'Premium includes',
          'subtitle': 'Marketplace features, plus:',
          'features': features,
        });

        function getFeatures(associationId) {
          if (associationId === 'aaoms') {
            return [
              'Universal inventory management.',
              'Supply level indicators.',
              'Expiration date warnings.',
              'Purchase Orders for any supplier.',
              'Products & supplier reports.',
            ];
          }

          return [
            'Universal inventory management.',
            'Supply level indicators.',
            'Expiry date warnings.',
            'Comprehensive order tracking.',
            'Custom office analytics.',
            'Reports based on usage and value of inventory.',
          ];
        }
      }
    };
  }
}());

(function () {
  'use strict';

  angular
    .module('app.shared.support')
    .run(setupScrolling);

  //
  // Setup Scrolling
  //
  // jQuery's default easing algorithm:
  // https://github.com/jquery/jquery/blob/master/src/effects/Tween.js#L112-L114
  //
  function setupScrolling($rootScope, $location, $timeout, smoothScroll) {
    var getHash = function () {
      return $location.hash();
    };

    var hashScroll = function (hash, clearHash, delay) {
      if (delay) {
        $timeout(function () {
          hashScroll(hash, clearHash);
        }, 300);
      }

      smoothScroll.scrollToElementById(hash);

      if (clearHash) {
        $location.hash(null);
      }
    };

    //
    // Watch $location.hash()
    //
    $rootScope.$watch(getHash, function (value) {
      if (value) {
        hashScroll(value, true);
      }
    });

    //
    // Scroll on $stateChangeSuccess
    //
    // When the ui-router has a successful state change, we should either
    // scroll to $location.hash() or scroll to the top (<body> has #sowingo on
    // it).
    //
    $rootScope.$on('$stateChangeSuccess', function () {
      hashScroll($location.hash() || 'sowingo', false, true);
    });
  }

}());


(function () {
  'use strict';
  
  angular
  .module('sowReports', ['chart.js', 'agGrid', 'daterangepicker'])
  .config(ConfigureState)
  
  function ConfigureState ($stateProvider, $urlRouterProvider) {
    $urlRouterProvider.when('/app/office/reports', '/app/reports/supplier');
    $urlRouterProvider.when('/app/reports/', '/app/reports/spending');
    $urlRouterProvider.when('/app/reports', '/app/reports/spending');
    $urlRouterProvider.when('/app/reports/supplier/', '/app/reports/supplier');
    $urlRouterProvider.when('/app/reports/product/', '/app/reports/product');
    $urlRouterProvider.when('/app/reports/implants-placement', '/app/reports/implants-placement/dashboard');
    $urlRouterProvider.when('/app/reports/implants-placement/', '/app/reports/implants-placement/dashboard');
    
    $stateProvider
    .state('app.reports', {
      'url': '/reports',
      'controller': 'ReportSectionController',
      'controllerAs': 'rsCtrl',
      'templateUrl': 'sow-reports/views/reports-index.html',
      'data': {
        'require_log_in' : true,
        'action_bar_directive': 'reports-action-bar',
      },
    })
    .state('app.reports.supplier', {
      'url': '/supplier',
      'controller': 'SupplierReportController',
      'controllerAs': 'srCtrl',
      'templateUrl': 'sow-reports/views/supplier.html',
      'data': {
        'title': 'Supplier Report'
      }
    })
    .state('app.reports.product', {
      'url': '/product',
      'controller': 'ProductReportController',
      'controllerAs': 'prCtrl',
      'templateUrl': 'sow-reports/views/product.html',
      'data': {
        'title': 'Product Report'
      }
    })
    .state('app.reports.from-product', {
      'url': '/from-product',
      'controller': 'ProductReportController',
      'controllerAs': 'prCtrl',
      'templateUrl': 'sow-reports/views/product.html',
      'data': {
        'action_bar_directive': 'action-bar-inventory',
        'title': 'General Inventory Report'
      }
    })
    .state('app.reports.product-detail', {
      'url': '/product/:productId',
      'controller': 'ProductDetailReportController',
      'controllerAs': 'pdrCtrl',
      'templateUrl': 'sow-reports/views/product-detail.html',
      'data': {
        'title': 'General Inventory Detail Report',
        'super_title': 'Inventory Report',
        'super_state': 'app.reports.product'
      },
    })
    .state('app.reports.medication', {
      'url': '/medication',
      'controller': 'MedicationListReportController',
      'controllerAs': 'medListReportCtrl',
      'templateUrl': 'sow-reports/views/medication.html',
      'data': {
        'title': 'Medication Report',
        'require_feature': 'medications'
      }
    })
    .state('app.reports.from-medication', {
      'url': '/from-medication',
      'controller': 'MedicationListReportController',
      'controllerAs': 'medListReportCtrl',
      'templateUrl': 'sow-reports/views/medication.html',
      'data': {
        'title': 'Medication Report',
        'action_bar_directive': 'action-bar-medications',
        'require_feature': 'medications'
      }
    })
    .state('app.reports.medication-detail', {
      'url': '/medication/:medicationId',
      'controller': 'MedicationDetailReportController',
      'controllerAs': 'mrdCtrl',
      'templateUrl': 'sow-reports/views/medication-detail.html',
      'data': {
        'title': 'Medication Detail Report',
        'require_feature': 'medications',
        'action_bar_directive': 'action-bar-report-medication',
        'super_title': 'Medication Report',
        'super_state': 'app.reports.medication'
      },
    })
    .state('app.reports.implants', {
      'url': '/implants',
      'controller': 'ImplantsReportController',
      'controllerAs': 'imprCtrl',
      'templateUrl': 'sow-reports/views/implants.html',
      'data': {
        'title': 'Implants Usage Report'
      }
    })
    .state('app.reports.from-implants', {
      'url': '/from-implants',
      'controller': 'ImplantsReportController',
      'controllerAs': 'imprCtrl',
      'templateUrl': 'sow-reports/views/implants.html',
      'data': {
        'title': 'Implants Usage Report',
        'action_bar_directive': 'action-bar-implants'
      }
    })
    .state('app.reports.implants-detail', {
      'url': '/implants/:month_filter/?type',
      'controller': 'ImplantsDetailReportController',
      'controllerAs': 'imprdCtrl',
      'templateUrl': 'sow-reports/views/implants-detail.html',
      'data': {
        'title': 'Implants Usage Report Detail',
        'super_title': 'Implant Usage Report',
        'super_state': 'app.reports.implants'
      }
    })
    // .state('app.reports', {
    //     url: '/reports',
    //     template: '<ui-view></ui-view>',
    //     data: {
    //         'require_log_in': true,
    //         'action_bar_directive': 'reports-action-bar',
    //     },
    //     redirectTo: 'app.reports.spending.office',
    // })
    .state('app.reports.spending', {
      url: '/spending',
      controller: 'SpendingController',
      controllerAs: 'SpendingCtrl',
      templateUrl: 'sow-reports/views/spending.html',
      data: {
        'title': 'Spending'
      },
      redirectTo: 'app.reports.spending.office'
    })
    .state('app.reports.spending.office', {
      url: '/office',
      controller: 'SpendingOfficeController',
      controllerAs: 'OfficeCtrl',
      templateUrl: 'sow-reports/views/spending-office.html',
      data: {
        'title': 'Spending by Office'
      },
    })
    .state('app.reports.spending.month-detail', {
      url: '/month-detail?month?year',
      params: {
        month: null,
        year: null
      },
      controller: 'SpendingMonthDetailController',
      controllerAs: 'MonthCtrl',
      templateUrl: 'sow-reports/views/spending-month-detail.html',
      data: {
        'title': 'Spending By Office - Month Detail',
        'super_title': 'Spending By Office',
        'super_state': 'app.reports.spending'
      },
    })
    .state('app.reports.spending.supplier', {
      url: '/supplier',
      controller: 'SpendingSupplierController',
      controllerAs: 'SupplierCtrl',
      templateUrl: 'sow-reports/views/spending-supplier.html',
      data: {
        'title': 'Spending By Supplier'
      },
    })
    .state('app.reports.spending.supplier-detail', {
      url: '/supplier-detail?supplier_id?year?name',
      params: {
        supplier_id: null,
        year: null
      },
      controller: 'SpendingSupplierDetailController',
      controllerAs: 'SupDetailCtrl',
      templateUrl: 'sow-reports/views/spending-supplier-detail.html',
      data: {
        'title': 'Spending By Supplier - Detail',
        'super_title': 'Spending By Supplier',
        'super_state': 'app.reports.spending.supplier'
      },
    })
    .state('app.reports.spending.supplier-month', {
      url: '/supplier-month?supplier_id?month?year?supplier_name',
      params: {
        supplier_id: null,
        month: null,
        year: null,
        supplier_name: null
      },
      controller: 'SpendingSupplierMonthController',
      controllerAs: 'SupMonthCtrl',
      templateUrl: 'sow-reports/views/spending-supplier-month.html',
      data: {
        'title': 'Spending By Supplier - Month Detail',
        'super_title': 'Spending By Supplier',
        'super_state': 'app.reports.spending.supplier'
      },
    })
    .state('app.reports.spending.category', {
      url: '/category',
      controller: 'SpendingCategoryController',
      controllerAs: 'CategoryCtrl',
      templateUrl: 'sow-reports/views/spending-category.html',
      data: {
        'title': 'Spending By Category'
      },
    })
    .state('app.reports.spending.category-detail', {
      url: '/category-detail?categoryID?year?categoryName',
      controller: 'SpendingCategoryDetailController',
      controllerAs: 'CategoryDetailCtrl',
      templateUrl: 'sow-reports/views/spending-category-detail.html',
      data: {
        'title': 'Spending By Category - Detail',
        'super_title': 'Spending By Category',
        'super_state': 'app.reports.spending.category'
      },
    })
    .state('app.reports.spending.category-month', {
      url: '/category-month?categoryID?year?month?categoryName',
      controller: 'SpendingCategoryMonthDetailController',
      controllerAs: 'CategoryMonthDetail',
      templateUrl: 'sow-reports/views/spending-category-month-detail.html',
      data: {
        'title': 'Spending By Category - Month Detail',
        'super_title': 'Spending By Category',
        'super_state': 'app.reports.spending.category'
      },
    })
    .state('app.reports.spending.budget', {
      controller: 'BudgetSpendingController',
      controllerAs: 'bsCtrl',
      data: {title: 'Budget Spending'},
      templateUrl: 'sow-reports/views/budget-spending.html',
      url: '/budget-spending?year',
      params: {
        year: null
      },
    })
    .state('app.reports.implants-placement', {
      'url': '/implants-placement',
      'controller': 'ImplantsPlacementController',
      'controllerAs': 'imprCtrl',
      'templateUrl': 'sow-reports/views/implants-placement.html',
      'data': {
        'title': 'Implants Placement Report'
      }
    })
    .state('app.reports.implants-placement.dashboard', {
      'url': '/dashboard',
      'controller': 'ImplantsPlacementDashboardController',
      'controllerAs': 'imprDashCtrl',
      'templateUrl': 'sow-reports/views/implants-placement-dashboard.html',
      'data': {
        'title': 'Implants Dashboard Report'
      }
    })
    .state('app.reports.implants-placement.patient', {
      'url': '/patient',
      'controller': 'ImplantsPlacementpatientController',
      'controllerAs': 'imprPatientCtrl',
      'templateUrl': 'sow-reports/views/implants-placement-patient.html',
      'data': {
        'title': 'Implants Patient Report'
      }
    })
    .state('app.reports.patient-detail', {
      'url': '/patient-detail?patient_name&patient_id',
      'controller': 'ImplantsPlacementPatientDetailController',
      'controllerAs': 'imprPatientCtrl',
      'templateUrl': 'sow-reports/views/implants-placement-patient-detail.html',
      'data': {
        'title': 'Implants Patient Detail Report',
        'super_title': 'Patient Report',
        'super_state': 'app.reports.implants-placement.patient'
      }
    })
    .state('app.reports.implants-placement.referring-doctor', {
      'url': '/referring-doctor',
      'controller': 'ImplantsPlacementDoctorController',
      'controllerAs': 'imprDoctorCtrl',
      'templateUrl': 'sow-reports/views/implants-placement-referring-doctor.html',
      'data': {
        'title': 'Implants Referring Doctor Report'
      }
    }).state('app.reports.referring-doctor-detail', {
      'url': '/referring-doctor-detail?id?name?year',
      'controller': 'ImplantsPlacementDoctorDetailController',
      'controllerAs': 'imprDoctorCtrl',
      'templateUrl': 'sow-reports/views/implants-placement-referring-doctor-detail.html',
      'data': {
        'title': 'Implants Referring Doctor Report detail',
        'super_title': 'Referring Doctor Report',
        'super_state': 'app.reports.implants-placement.referring-doctor'
      }
    }).state('app.reports.implants-placement.surgeon', {
      'url': '/surgeon',
      'controller': 'ImplantsPlacementSurgeonController',
      'controllerAs': 'imprSurgeonCtrl',
      'templateUrl': 'sow-reports/views/implants-placement-surgeon.html',
      'data': {
        'title': 'Implants Referring Surgeon Report'
      }
    }).state('app.reports.surgeon-detail', {
      'url': '/surgeon-detail?id?name?year',
      'controller': 'ImplantsPlacementSurgeonDetailController',
      'controllerAs': 'imprSurgeonCtrl',
      'templateUrl': 'sow-reports/views/implants-placement-surgeon-detail.html',
      'data': {
        'title': 'Implants Referring Surgeon Report',
        'super_title': 'Surgeon Report',
        'super_state': 'app.reports.implants-placement.surgeon'
      }
    })
    .state('app.reports.implants-placed-detail', {
      'url': '/implants-placed-detail?startDate?endDate?isCustom?year',
      'controller': 'ImplantsPlacedListController',
      'controllerAs': 'imprPlaCtrl',
      'templateUrl': 'sow-reports/views/implants-placed-list-detail.html',
      'data': {
        'title': 'Implants Placed List',
        'super_title': 'Implants Placed Report',
        'super_state': 'app.reports.implants-placement.dashboard',
        'contractSidenav': true
      }
    })
    .state('app.all_office_dashboard', {
      url: '/all_office_dashboard',
      template: '<ui-view></ui-view>',
      data: {
        'title': 'Multi Practice Dashboard',
        'require_log_in' : true,
        'action_bar_directive': 'reports-action-bar'
      },
    })
    .state('app.reports.office_dashboard_spending', {
      url: '/office_dashboard_spending',
      controller: 'AllOfficeDashboardSpending',
      controllerAs: 'aodsCtrl',
      templateUrl: 'sow-user-admin/office-dashboard/spending-by-office.html',
      data: {
        'title': 'Spending By Office',
        'require_log_in' : true
      },
    })
    .state('app.reports.office_dashboard_implant', {
      url: '/office_dashboard_implant',
      controller: 'ImplantPlacementByOfficeCtrl',
      controllerAs: 'aodiCtrl',
      templateUrl: 'sow-user-admin/office-dashboard/implant-placement-by-office.html',
      data: {
        'title': 'Implant Placement By Office',
        'require_log_in' : true
      },
    });
  }
}());
  
angular.module("app.shared.support")
  //
  // Extract Image from the Product
  //
  .filter('productImage', function ($log) {
    return function (product, imageIndex) {
      if (!product || !product.images || !product.images.length) {

        // Fallback to main image if one exists
        if (product && product.main_image) {
          return product.main_image;
        }

        return undefined;
      }

      if (isEmpty(imageIndex) || imageIndex < 0 || imageIndex >= product.images.length) {

        // Resort to the main image if we have one, otherwise just default to the first image.
        if (product.main_image) {
          return product.main_image;
        } else {
          imageIndex = 0;
        }
      }

      return product.images[imageIndex];
    };
  });
angular.module("app.marketplace.elements")

//$rootScope is only included to utilize root variables for autofill. TODO reconsider approach that does not use $rootScope 
.service("productModel", function($rootScope, searchUrl, apiUrl) {
  var _this = this;
  this.model_data = {
    name: "product",
    display_name: "Product",
    watch_vars: {"country" : true, "marketplace_medical_field" : true},
    api: {
      //Different path types which correspond to different endpoints.
      multiple: function(options){
        return {
           "path" : searchUrl+"/products/Product/_search",//"/products";
           "type" : "elastic",
           "params" : {
             "method" : "POST",
             "data" : {"size": 250}
           }
        };
      },

      //
      //
      //
      "multiple_by_id": function (options) {
        if (!checkNested(options, 'product_ids')) {
          throw 'Missing "product_ids" value.';
        }

        return {
          'path': '{0}/products/Product/_search'.format(searchUrl),
          'type': 'elastic',
          'params': {
            'method': 'POST',
            'data': {
              'size': 10000,
              'query': {
                'terms': {
                  'id': options.product_ids
                },
              }
            }
          },
        };
      },

      //
      // Return all products that offered by the Vendor matching `options.data.vendor_id`
      //
      vendor_products: function (options) {
        if (!checkNested(options, 'vendor_id') || !checkNested(options, 'country_id')) {
          throw 'Missing option(s) for product model path \'vendor_products\'. Options: ' + JSON.stringify(options);
        }

        return {
          path: sprintf('%s/products/Product/_search', searchUrl),
          type: 'elastic',
          params: {
            method: 'POST',
            data: {
              "size": 1000,
              "query": {
                "nested": {
                  "path": "vendors",
                  "query": {
                    "bool": {"must": [
                      {"term": {"vendors.id": options.vendor_id}},
                      {"term": {"vendors.country_id": options.country_id}}
                    ]}
                  }
                }
              }
            }
          }
        };
      },

      "single" : function(options){
        if(!checkNested(options,'data','id')){
          throw "Missing option(s) for product model path 'single'. Options: " + JSON.stringify(options);
        }
        return {
          "path" : searchUrl+"/products/Product/_search",
          "type" : "elastic",
          "params" : {
            "method" : "POST",
            "data" : {
              "size": 1000,
              "query": {
                "term": {"id" : options.data.id}
              }
            }
          }
       };
      },

      "subcategory_products": function(options){
        if(!checkNested(options,'category')){
          throw "Missing option(s) for product model path 'subcategory_products'. Options: " + JSON.stringify(options);
        }
        var data = {
            "size": 1000,
            "query":{
              "term":{
                "categories.url_name" : options.category.url_name
              }
            },
            "filter":{
              "term":{
                "display_in_categories":true
              }
            }
        };
        return {
          "path" : searchUrl+"/products/Product/_search",
          "type" : "elastic",
          "params" : {
            "method" : "POST",
            "data" : data
          }
       };
      },

      "search" : function(options){
        if(!checkNested(options,'query')){
          throw "Missing option(s) for product model path 'search'. Options: " + JSON.stringify(options);
        }
        var escapedQuery = !options.alreadyEncoded ? encodeURIComponent(options.query) : options.query;
        var path = apiUrl+"/inventory/es-search?search="+escapedQuery;
        if(options.office_id){
          path += "&office_id=" + options.office_id;
        }
        if(options.medical_field_id){
          path += "&medical_field_id=" + options.medical_field_id;
        }
        return {
           "path" : path,
           "type" : "custom",
           "params" : {
             "method" : "GET"
           }
        };
      },

      "elastic_custom_search" : function(options){
        if(!checkNested(options,'escaped_query') && !checkNested(options,'custom_filter') && !checkNested(options,'escaped_body')){
          throw "Missing option(s) for product model path 'elastic_custom_search'. Options: " + JSON.stringify(options);
        }


        var body = {
            "size": 250
          };

        if(!options.generic){
          body.query = {
              "multi_match": {
                "query": options.escaped_query,
                "multi_match" : {'fields' : [ "product_group.name^2", "description", "vendors.name", "manufacturer.name", "vendors.vendor_sku", "category.name", "subcategory.name" ]}
              }
            };
          body.filter = {
            "term": {
              "display_in_categories" : true
            }
          };
        }else{
          if(options.escaped_body){
            body = options.escaped_body;
          }
          if(options.escaped_query){
            body.query = options.escaped_query;
          }
          if(options.custom_filter){
            body.filter = options.custom_filter;
          }
        }

        return {
          "path" : searchUrl+"/products/Product/_search",
          "type" : "elastic",
          "params" : {
            "method" : "POST",
            "data" : body
          }
       };
      }
    }
  };

  this.autofill = function(element) {
    if(!element){
      return;
    }
    // Format product properties
    element.country_properties_map = {};
    if(element.country_properties){
      element.country_properties_map = {};
      _.map(element.country_properties, function(property){
        element.country_properties_map[property.country_id] = property;
        if(property.properties && (typeof property.properties === 'string' || property.properties instanceof String)){
          property.properties = JSON.parse(property.properties);
        }
      });
    }
    

    if($rootScope.current_country){
      // Determine Active Vendor
      element.UI.active_vendor = null;
      angular.forEach(element.active_vendor, function (vendor) {
        if (vendor.country_id === $rootScope.current_country.id) {
          element.UI.active_vendor = vendor;
        }
      });
      
      // Gather up country-specific vendors
      element.UI.vendors = element.vendors.filter(function (vendor) {
        return vendor.country_id === $rootScope.current_country.id;
      });

      // Gather up country-specific "Related Products"
      element.UI.related = [];
      angular.forEach(element.related, function (related_products) {
        if (related_products.country_id === $rootScope.current_country.id) {
          element.UI.related = related_products.products;
        }
      });
      
    }

    //
    // Lookup (current) country properties
    //
    if (!element.country_properties || !$rootScope.current_country) {
      element.UI.is_published = false;
      element.UI.is_purchasable = false;
      if(!element.country_properties){
        $log.error('No country properties on product: %o', element);
      }
      return;
    } else {
      var countryProperties;
      var i;
      var len = element.country_properties.length;
      for (i = 0; i < len; ++i) {
        if (element.country_properties[i].country_id === $rootScope.current_country.id) {
          countryProperties = element.country_properties[i];
          break;
        }
      }

      if (countryProperties) {
        element.UI.properties = countryProperties;
        element.UI.is_in_country = true;
        element.UI.is_published = !!countryProperties.is_published;
        element.UI.is_purchasable = !!(countryProperties.is_published && element.UI.active_vendor);
      } else {
        element.UI.properties = null;
        element.UI.is_in_country = false;
        element.UI.is_published = false;
        element.UI.is_purchasable = false;
      }
    }

    //
    // Assumes active vendor has the lowest price.
    //
    if (element.UI.active_vendor) {
      element.price = parseFloat(element.UI.active_vendor.regular_price);
      element.promotion_price = parseFloat(element.UI.active_vendor.promotion_price);
      element.lowest_price = element.promotion_price;
    }
    


    //
    // Determine if product should be displayed from is_published and current medical field.
    //
    if (!element.medical_fields || !element.medical_fields.length || !$rootScope.marketplace_medical_field || !element.UI.is_published) {
      element.UI.marketplace_display = element.UI.is_published;
    }else{
      element.UI.marketplace_display = false;
      _.map(element.medical_fields, function(medical_field){
        if(medical_field.id === $rootScope.marketplace_medical_field.id){
          element.UI.marketplace_display = true;
        }
      });
    }
    
    return element;
  };

  this.fields = {
    "id" : {
      "display_name" : "id",
      "required" : true,
      "api" : {
        "submit" : true
      },
      "type" : "integer"
    },
    "name" : {
      "display_name" : "name",
      "required" : true,
      "api" : {
        "submit" : true
      },
      "type" : "text"
    }
  };

  this.data = {
    "params" : {
      "model_name" : "product"
    },
    "getStatus" : function() { //Example function
      return this.last_state;
    }
  };

});

(function () {
  'use strict';

  angular.module('sowReports')
    .filter('tbValue', ['$filter', function () {
      return function (input) {
        if (input == 0) {
          return input;
        }
        if (input == "" || input == undefined || input == null) {
          return '-';
        } else {
          return input;
        }
      };
    }]);
})();
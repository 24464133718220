(function () {
  'use strict';

  angular
    .module('sowSterilization')
    .service('stznHelperService', stznHelperService);

  function stznHelperService (sowSterilizationService, $mdDialog, sgToast, $translate, sterilizationImagesUrl, $filter) {
    /*jshint validthis: true */
    var service = this;

    service.records_ui_data = {
      'Passed': {
        'icon': 'assignment_turned_in',
        'icon_class': 'green-icon',
        'display_name': 'STERILIZATION.PASSED',
        'result_class': 'sterilization-result-passed',
      },
      'Failed': {
        'icon': 'assignment_late',
        'icon_class': 'red-icon',
        'display_name': 'STERILIZATION.FAILED',
        'result_class': 'sterilization-result-failed',
      },
      'Incubation Cycle': {
        'icon': 'assignment_late',
        'icon_class': 'orange-icon',
        'display_name': 'STERILIZATION.INCUBATION_CYCLE',
        'result_class': 'sterilization-result-incubating',
      },
      'Incubation Complete': {
        'icon': 'assignment_late',
        'icon_class': 'orange-icon',
        'display_name': 'STERILIZATION.INCUBATION_COMPLETE',
        'result_class': 'sterilization-result-incubated',
      },
      'Control Passed': {
        'icon': 'assignment_late',
        'icon_class': 'red-icon',
        'display_name': 'STERILIZATION.PASSED_CONTROL',
        'result_class': 'sterilization-result-failed',
        'is_control': true,
      },
      'Control Failed': {
        'icon': 'assignment_turned_in',
        'icon_class': 'green-icon',
        'display_name': 'STERILIZATION.FAILED_CONTROL',
        'result_class': 'sterilization-result-passed',
        'is_control': true,
      },
    };
    service.records_type_names = {
      'biological': 'STERILIZATION.BS_TEST',
      'chemical': 'STERILIZATION.SC_TEST',
      'ultrasonic_cleaner': 'STERILIZATION.UC_TEST',
      'ultrasonic': 'STERILIZATION.UC_TEST',
      'washer_disinfector': 'STERILIZATION.WD_TEST',
      'washer': 'STERILIZATION.WD_TEST',
      'control': 'STERILIZATION.CS_TEST',
    };
    service.incubator_options = {
      "10":'10 minutes',
      "24":'24 minutes',
      "60":'1 hour',
      "180":'3 hours',
      "600":'10 hours',
      "1440":'24 hours',
    };
    service.chemical_indicator_options = [
      { 'value':"class1", 'display_name': 'Class 1 - Process Indicator' },
      { 'value':"class2", 'display_name': 'Class 2 - Bowie Dick' },
      { 'value':"class3", 'display_name': 'Class 3 - Single variable' },
      { 'value':"class4", 'display_name': 'Class 4 - Multi-variable' },
      { 'value':"class5", 'display_name': 'Class 5 - Indicator Strip' },
      { 'value':"class6", 'display_name': 'Class 6' },
    ];

    service.getTimeLabel = getTimeLabel;
    service.getDurationLabel = getDurationLabel;
    service.acIndicatorCompanyNames = acIndicatorCompanyNames;
    service.confirmActionDialog = confirmActionDialog;
    service.pendingActionsDialog = pendingActionsDialog;
    service.detailsDialog = detailsDialog;
    service.failTipsDialog = failTipsDialog;
    service.getChemIndicatorLabel = getChemIndicatorLabel;
    service.getRecordVersion = getRecordVersion;
    service.showActionMessage = showActionMessage;
    service.isImage = isImage;
    service.setEndDateUsingIncubationData = setEndDateUsingIncubationData;
    service.getThumbnailUrl = getThumbnailUrl;
    service.waitUntilImageIsLoaded = waitUntilImageIsLoaded;

    return service;

    function getTimeLabel (date) {
      return moment(date).format('MMMM DD, YYYY h:mm A');
    }

    function getDurationLabel (record) {
      return _.get(service.incubator_options, record.incubator_time, 'N/A');
    }

    function acIndicatorCompanyNames (query = '') {
      var keywords = query.split(/\s+/);
      var matcher = new RegExp('.*' + keywords.join('.*') + '.*', 'i');

      return sowSterilizationService.getIndicatorCompanies().then(function (companyNames) {
        var _ranking = {};
        var _tmp = [];

        for (var i = 0; i < companyNames.length; ++i) {
          var match = companyNames[i].match(matcher);
          if (match) {
            _ranking[companyNames[i]] = match.length;
          }
        }

        return _.sortBy(
          _.filter(companyNames, function (name) {
            return _ranking[name];
          }),
          function (name) {
            return _ranking[name] * -1;
          }
        );
      });
    }

    function confirmActionDialog (record) {
      return $mdDialog.show({
          controller: 'stznConfirmActionController',
          controllerAs: 'stznCFACtrl',
          templateUrl: 'sow-sterilization/modals/stzn-confirm-fail-action.html',
          parent: angular.element(document.body),
          clickOutsideToClose: true,
          locals: {
            'record_data': angular.copy(record),
          },
          bindToController: true,
        });
    }

    function pendingActionsDialog(record) {
      return $mdDialog.show({
          controller: 'stznPendingTestActionsController',
          controllerAs: 'stznPTACtrl',
          templateUrl: 'sow-sterilization/modals/stzn-pending-test-actions.html',
          parent: angular.element(document.body),
          clickOutsideToClose: true,
          locals: {
            'record_data': angular.copy(record),
          },
          bindToController: true,
        });
    }

    function detailsDialog (record) {
      return $mdDialog.show({
          controller: 'stznPendingTestDetailController',
          controllerAs: 'stznPTDCtrl',
          templateUrl: 'sow-sterilization/modals/stzn-pending-test-detail.html',
          parent: angular.element(document.body),
          clickOutsideToClose: true,
          locals: {
            'record_data': angular.copy(record),
          },
          bindToController: true,
        });
    }

    function failTipsDialog (record) {
      var dialog_version = null;
      switch(record.type){
        case "ultrasonic_cleaner":
        case "washer_disinfector":
          dialog_version = "ultra_washer";
          break;
        case "biological":
          if(!record.sterilizer_mode_id){
            dialog_version = 'sterilizer_control';
          } else {
            dialog_version = 'sterilizer_regular';
          }
          break;
        default:
          dialog_version = 'sterilizer_regular';
      }

      return $mdDialog.show({
          controller: 'stznDialogController',
          controllerAs: 'stznFTCtrl',
          templateUrl: 'sow-sterilization/modals/stzn-fail-tips.html',
          parent: angular.element(document.body),
          clickOutsideToClose: true,
          locals: {
            'record_data': angular.copy(record),
            'version': dialog_version,
          },
          bindToController: true,
        });
    }

    function emailSentDialog () {
      return $mdDialog.show({
          controller: 'stznDialogController',
          controllerAs: 'stznESCtrl',
          templateUrl: 'sow-sterilization/modals/stzn-email-sent.html',
          parent: angular.element(document.body),
          clickOutsideToClose: true,
        });
    }

    function getChemIndicatorLabel (record) {
      var label = record.chemical_indicator;
      var found_label = _.find(service.chemical_indicator_options, function(item){
        return item.value === record.chemical_indicator;
      });
      label = _.get(found_label, 'display_name' ,label); // returns base class name if by any chance it's not on the list
      return label;
    }

    function getRecordVersion (record) {
      switch(record.type){
        case "biological":
          if(!record.sterilizer_mode_id){
            return "control";
          } else {
            return "biological";
          }
        case "chemical":
          return "chemical";
        case "ultrasonic_cleaner":
          return "ultrasonic";
        case "washer_disinfector":
          return "washer";
        case "spore":
          return "control";
        default:
          return "biological";
      }
    }

    function showActionMessage (record) {
      var version = getRecordVersion(record);
      var messages = {
        'biological':{
          'Passed': "BS_TEST_PASSED",
          'Failed': "BS_TEST_FAILED",
          'Incubation Cycle': "BS_TEST_STARTED"
        },
        'control': {
          'Passed': "CS_TEST_PASSED",
          'Failed': "CS_TEST_FAILED",
          'Incubation Cycle': "CS_TEST_STARTED",
          'Control Passed': "BS_TEST_PASSED",
          'Control Failed': "CS_TEST_FAILED",
        },
        'chemical': {
          'Passed': "SC_TEST_PASSED",
          'Failed': "SC_TEST_FAILED",
        },
        'ultrasonic': {
          'Passed': "UC_TEST_PASSED",
          'Failed': "UC_TEST_FAILED",
        },
        'washer': {
          'Passed': "WD_TEST_PASSED",
          'Failed': "WD_TEST_FAILED",
        },
      };
      const message = messages[version][record.result];
      const translation = $filter('translate')("STERILIZATION."+ message)
      sgToast.showSimple(translation)
    }

    function isImage (url) {
      if (!url) {
        return false;
      }

      // valid image extensions
      const valid_extensions = ['jpg', 'png', 'gif', 'jpeg', 'svg'];

      // get the file extension from the url 
      const extension = url.split('.').slice(1).at(-1)?.trim().toLowerCase();

      // check if the extension is in the valid extensions list
      const is_image = valid_extensions.includes(extension);
      return is_image;
    }

    /**
     * Returns a modified URL for a thumbnail image with a width of 60 and height of 60.
     * @param url sample.com/office/image.png
     * @param {{width: number, height: number}} options - width and height of the thumbnail
     * @returns {string} - newUrl.com/office/image.png?width=60&height=60
     */
    function getThumbnailUrl (url, {width = 60, height = 60} = {}) {
      if (!url) {
        return null;
      }

      const url_obj = new URL(url);
      return sterilizationImagesUrl + url_obj.pathname + `?width=${width}&height=${height}`;
    }

    /**
     * Calculates the end date adding the incubator time to the tested_at date.
     * @param {{tested_at: string, incubator_time: number}} data
     * @returns {Date} end_date
     */
    function setEndDateUsingIncubationData ({tested_at, incubator_time}) {
      const end_date = moment(tested_at).add(incubator_time, 'minutes').seconds(0).milliseconds(0).toDate();
      return end_date;
    }


    /**
     * Returns a Promise that resolves once the image is decoded and it is safe to append it to the DOM
     * @see https://developer.mozilla.org/en-US/docs/Web/API/HTMLImageElement/decode
     * @param {string} url - image url
     * @returns {Promise} - resolves when the image is loaded
     */
    function waitUntilImageIsLoaded (url) {
      const image_obj = new Image();
      image_obj.src = url;
      return image_obj.decode();
    }
  }

}());

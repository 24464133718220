(function () {
  'use strict';

  angular
    .module('sowSds')
    .controller('sdsActionBarController', sdsActionBarController)
    .directive('sdsActionBar', SdsActionBar);

  function SdsActionBar () {
    return {
      'restrict': 'AC',
      'templateUrl': 'sow-sds/directives/sds-action-bar.html',
      'controller': 'sdsActionBarController',
      'controllerAs': 'sdsAbCtrl',
    };
  }

  function sdsActionBarController ($rootScope) {
    /*jshint validthis: true */
    var ctrl = this;

    ctrl.newSdsDoc = newSdsDoc;

    init();

    return ctrl;

    function init () {

    }

    function newSdsDoc () {
      $rootScope.$broadcast('sds-new-doc');
    }
  }
}());

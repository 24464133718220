(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.registration')
    .controller('AccountCreatedCtrl', AccountCreatedCtrl);

  function AccountCreatedCtrl ($scope, $state, $stateParams, pending_invites, sessionService, RouteService) {
    /*jshint validthis: true */
    var ctrl = this;

    $scope.from_registration = !$stateParams.redirection;
    $scope.logout = logout;

    pendingInvitesRedirect();

    return ctrl;

    function pendingInvitesRedirect () {
      if (pending_invites && pending_invites.length > 0) {
        $scope.$state.go('app.registration.pending_invites', {'pending_invites': pending_invites});
      } else {
        // SOW-4794 removing user's ability to join office, if they're not invited by any office they will automatically create a new one
        $scope.$state.go('app.registration.create_office');
      }
    }

    function logout () {
      return sessionService.logout().then(function () {
        RouteService.goToDefault();
      });
    }
  }
}());

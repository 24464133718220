(function () {
  'use strict';

  angular.module('sowOrders')
    .controller('orderPromoPriceController', orderPromoPriceController)
    .directive('orderPromoPrice', ordersPromoPriceDirective);

  function ordersPromoPriceDirective() {
    return {
      restrict: 'E',
      templateUrl: 'sow-orders/directives/order-promo-price.html',
      controller: 'orderPromoPriceController',
      controllerAs: 'orppCtrl',
      scope: {
        'item': '=',
      },
    };
  }

  function orderPromoPriceController() {
    /*jshint validthis:true*/
    var ctrl = this;

    init();

    return ctrl;

    function init() {

    }

  }

})();
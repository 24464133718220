angular.module('app.marketplace.ui.inventory')

.directive('inventoryQuantityOnHand', function() {
  return {
    restrict: 'E',
    controllerAs: 'ctrl',
    scope: true,
    bindToController: {
      item: '='
    },
    controller: function($scope) {
      var ctrl = this;

      ctrl.getQuantity = getQuantity;

      /////////

      function getQuantity(item) {
        if (!item || !item.inventory_item) {
          return;
        }

        var caseSize = item.inventory_item.order_package_quantity;
        var itemsOnHand = item.inventory_item.on_hand;
        var casesOnHand = (itemsOnHand / caseSize);

        if ( item.inventory_item.tracking_method === 'Item' || !item.inventory_item.tracking_method ) {
          return itemsOnHand;
        } else {
          return casesOnHand;
        }

      }
    },
    template: '<span ng-show="ctrl.item && ctrl.item.inventory_item">'
      + '{{ ctrl.getQuantity(ctrl.item) }}'
      + '</span>'
  };
});

(function() {
  'use strict';
  
  angular
  .module('sowBudget')
  .controller('BudgetDetailController', BudgetDetailController);
  
  function BudgetDetailController($state, sowBudgetService, sgToast, $filter, membershipService, AccessService) {
    Array.prototype.sum = function (prop) {
      var total = 0
      for ( var i = 0, _len = this.length; i < _len; i++ ) {
        const num = Number(this[i][prop]);
        if (!Number.isNaN(num)) {
          total += num;
        }
      }
      return total;
    };
    var ctrl = this;

    ctrl.budgetData = [];
    ctrl.currentBudget = [];
    ctrl.isBudget = false;
    ctrl.totalCurrentBudget = 0;
    ctrl.totalCurrentSpent = 0;
    ctrl.totalPreSpent = 0;

    ctrl.budgetReport = budgetReport;
    ctrl.setData = setData;
    ctrl.checkIsBudget = checkIsBudget;
    ctrl.setBudgetData = setBudgetData;
    ctrl.setBudgetDataForYear = setBudgetDataForYear;
    ctrl.setSpendingData = setSpendingData;
    ctrl.setSpentDataForYear = setSpentDataForYear;
    ctrl.getMonth = getMonth;
    ctrl.updateBudget = updateBudget;
    ctrl.onSave = onSave;
    ctrl.goBack = goBack;

    ctrl.$onInit = init;

    // define locks initial values
    ctrl.disabled_edit = false;
    ctrl.gl_budget_enabled = false;

    return ctrl;
    
    function init (){
      defineLocks();

      _redirectToBudgetReport();

      if($state.params.year){
        ctrl.year = $state.params.year;
      }else{
        ctrl.year = moment().year();
      }
      ctrl.getMonth();
      return ctrl.budgetReport(ctrl.year-1, ctrl.year);

    }

    /**
     * Redirects to the budget report page if the GL budget is enabled.
     * The budget report page is a Budget Table with Editable Fields.
     */
    function _redirectToBudgetReport() {
      // If the office property is enabled, redirect to the budget report page (Budget Table with Editable Fields)
      // This cover the case when the user manually navigates to the budget detail page changing the URL
      if (ctrl.gl_budget_enabled) {
        $state.go('app.reports.spending.budget', { year: ctrl.year });
      }
    }
    
    function budgetReport (yearFrom, yearTo){
      return sowBudgetService.getBudgetReport(yearFrom, yearTo)
      .then(function(result){
        ctrl.setData(result);
      });
    }
    
    function setData (data){
      var report = data[0].report;
      var budget = report.budgets;
      var order = report.orders;
      ctrl.checkIsBudget(budget);
      
      
      if(order.length > 0){
        ctrl.setSpendingData(order);
      }
      
      if(budget.length > 0){
        ctrl.setBudgetData(budget);
      }
    }
    
    function checkIsBudget (budget){
      for(var i = 0; i < budget.length; i++){
        if(budget[i].year == ctrl.year){
          ctrl.currentBudget = budget[i].monthly_budget;
          ctrl.isBudget = true;
        }
      }
    }
    
    function setBudgetData (budget){
      var monthlyBudget = {
        monthly_budget: [],
        year: ctrl.year
      };
      
      for(var i = 0 ; i < budget.length; i++){
        if(budget[i].year == ctrl.year){
          monthlyBudget = budget[i];
        }
      }
      
      ctrl.setBudgetDataForYear(monthlyBudget);
      ctrl.totalCurrentBudget = ctrl.budgetData.sum('currentBudget');
      
    }
    
    function setBudgetDataForYear (budget) {
      var monthlyBudget = budget.monthly_budget;
      for (var i = 0; i < ctrl.budgetData.length; i++) {
        var perMonth = ctrl.budgetData[i];
        for (var j = 0; j < monthlyBudget.length; j++) {
          if (perMonth.month == monthlyBudget[j].month) {
            perMonth.currentBudget = monthlyBudget[j].budget;
          }
        }
      }
    }
    
    function setSpendingData (order){
      var currentOrder = {
        monthly_orders:[],
        year: ctrl.year
      };
      var preOrder = {
        monthly_orders:[],
        year: ctrl.year -1
      };
      
      for(var i = 0 ; i < order.length; i++){
        if(order[i].year == ctrl.year){
          currentOrder = order[i];
        }else if(order[i].year == ctrl.year-1){
          preOrder = order[i];
        }
      }
      
      ctrl.setSpentDataForYear(currentOrder);
      ctrl.setSpentDataForYear(preOrder);
      
      ctrl.totalCurrentSpent = ctrl.budgetData.sum('currentSpent');
      ctrl.totalPreSpent = ctrl.budgetData.sum('preSpent');
      
    }
    
    function setSpentDataForYear (order){
      var monthlyOrder = order.monthly_orders;
      var year = order.year;
      if(order){
        for(var i = 0; i < ctrl.budgetData.length; i++){
          var perMonth = ctrl.budgetData[i];
          for(var j =0; j < monthlyOrder.length; j++){
            if(year == parseInt(ctrl.year)){
              if(perMonth.month == monthlyOrder[j].month){
                perMonth.currentSpent = monthlyOrder[j].total;
              }
            }else if(year == parseInt(ctrl.year)-1){
              if(perMonth.month == monthlyOrder[j].month){
                perMonth.preSpent = monthlyOrder[j].total;
              }
            }
          }
        }
      }
    }
    
    function getMonth (){
      for(var i = 1; i < 13 ; i++){
        var perMonth = {
          preSpent:0,
          currentSpent:0,
          currentBudget:0,
          monthYear: moment(i, 'M').format('MMMM') + ' ' +ctrl.year,
          month: moment(i, 'M').format('MMM')
        }
        ctrl.budgetData.push(perMonth);
      }
    }
    
    function updateBudget (data, val){
      var action = val ? sowBudgetService.updateBudget : sowBudgetService.createBudget;
      action(data)
      .then(function(datas){
        ctrl.budgetReport(ctrl.year-1, ctrl.year);
        var t_message = $filter('translate')('COMMON.CHANGES_SAVED');
        sgToast.showSimple(t_message);
      });
    }
    
    function onSave (){
      var data = {
        "budgets" : []
      } 
      
      var dataForUpdate = {
        "budgets" : []
      } 
      var dataForAdd = {
        "budgets" : []
      } 
      
      for(var i = 0 ; i < ctrl.budgetData.length; i++){
        var budget = ctrl.budgetData[i].currentBudget;
        if(!ctrl.budgetData[i].currentBudget){
          budget = 0;
        }
        var budgetPerMonth = {
          "start_date": moment(ctrl.budgetData[i].monthYear, 'MMMM YYYY').format('YYYY-M'),
          "budget": budget
        }
        data.budgets.push(budgetPerMonth);
      }
      
      var k = [];
      ctrl.currentBudget.forEach(function(el){
        k.push(el.month);
      });
      
      data.budgets.forEach(function(el){
        var monthName = moment(el.start_date, 'YYYY-M').format('MMM');
        var indexOf = k.indexOf(monthName);
        if(indexOf > -1){
          dataForUpdate.budgets.push(el);
        }else{
          dataForAdd.budgets.push(el);
        }
      });
      if(dataForUpdate.budgets.length > 0){
        ctrl.updateBudget(dataForUpdate, true);
      }
      
      if(dataForAdd.budgets.length > 0){
        ctrl.updateBudget(dataForAdd, false);
      }
    }
    
    function goBack (){
      $state.go('app.budget');
    }


    function defineLocks () {
      const membership = membershipService.get();

      const edit_condition = _.get(membership, "user_properties.budgets.disable_edit", false);
      ctrl.disabled_edit = edit_condition;
      ctrl.gl_budget_enabled = AccessService.getOfficeProperty('gl_budget_enabled');
    }
    
  }
}());

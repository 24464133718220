angular.module("app.marketplace.ui.shopping", [])

.config(function($stateProvider, $urlRouterProvider) {

  $urlRouterProvider.when("/app/shopping/:medicalField/product", "/app/shopping/:medicalField/home")
                    .when("/app/shopping/:medicalField/product/", "/app/shopping/:medicalField/home");

  $stateProvider
    .state("app.shopping", {
      url: '/shopping/:medicalField',
      templateUrl: "templates/marketplace/shopping/shopping.html",
      controller: "shoppingController" ,
      data: {
        title: 'Marketplace',
        action_bar_directive: 'action-bar-shopping'
      },
      redirectTo: 'app.shopping.home' // Redirecting via $urlRouterProvider can cause template scope digest issues.
    })
    .state('app.shopping.vendors', {
      url: '/supplier',
      controller: 'ShopByVendorCtrl',
      controllerAs: '$ctrl',
      templateUrl: 'templates/marketplace/shopping/views/shop-by-vendor.html'
    })

    .state('app.shopping.vendor', {
      url: '/supplier/:vendorId',
      controller: 'ShopByVendorCtrl',
      controllerAs: '$ctrl',
      templateUrl: 'templates/marketplace/shopping/views/shop-by-vendor.html'
    })

    .state("app.shopping.home", {
      url: '/home',
      templateUrl : "templates/marketplace/shopping/home.html"
    })

    .state("app.shopping.categories", {
      url: '/categories',
      controller: "categoriesController",
      templateUrl: "templates/marketplace/shopping/categories.html",
      data: {
        'title': 'All Categories » Marketplace'
      }
    })

    .state("app.shopping.subcategories", {
      url: '/categories/:categoryId',
      controller: "categoriesController",
      templateUrl: "templates/marketplace/shopping/categories.html"
    })

    .state("app.shopping.product", {
      url: '/product/:productId',
      controller: "productController",
      templateUrl: "templates/marketplace/shopping/product.html",
      params: {
        'vendor_id': null,
      }
    })

    .state("app.shopping.featured", {
      url: '/featured',
      templateUrl : "templates/marketplace/content-block/content-block-page.html",
      controller : "contentBlockPageController",
      resolve: {
        block: function ($stateParams, productService) {
          return productService.getFeaturedProducts($stateParams.medicalField).then(function (products) {
            return {
              title: "Featured Products",
              displayed_products: _.values(products)
            };
          });
        }
      }
    })
    
    .state("app.shopping.on_sale", {
      url: '/on_sale',
      templateUrl : "templates/marketplace/content-block/content-block-page.html",
      controller : "contentBlockPageController",
      resolve: {
        block: function ($stateParams, productService) {
          return productService.getOnSaleProducts($stateParams.medicalField).then(function (products) {
            return {
              title: "On Sale Products",
              displayed_products: _.values(products)
            };
          });
        }
      }
    })

    .state("app.shopping.new_items", {
      url: '/new_items',
      templateUrl : "templates/marketplace/content-block/content-block-page.html",
      controller : "contentBlockPageController",
      resolve: {
        block: function ($stateParams, $filter, productService) {
          return productService.getNewProducts($stateParams.medicalField).then(function (products) {
            return {
              'title': "New Products",
              'sort': 'none',
              'element_type': 'product',
              'displayed_products': $filter('orderBy')(_.values(products), function (product) {
                return (new Date(product.created_at)).getTime() / 1000;
              }, true),
            };
          });
        }
      }
    });
});



(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.purchaseorders')
    .service('poHelperService', poHelperService);

  function poHelperService (PurchaseOrderService, $mdDialog, $mdToast, $timeout, $filter, $rootScope, sowAnalyticsService, accountService, AccessService, inventoryItemService) {
    const EVENT_ACTION_KEYS = {
      'approvals.email.purchase_order.approved.approved': 'EMAILED_APPROVAL_CONFIRMATION',
      'approvals.email.purchase_order.cancelled.cancelled_by_user': 'EMAILED_CONFIRMATION',
      'approvals.email.purchase_order.declined.declined': 'EMAILED_CONFIRMATION',
      'approvals.email.purchase_order.pending.approver_copy': 'EMAILED_REVIEWER',
      'approvals.email.purchase_order.pending.requestor_copy': 'EMAILED_REQUESTOR',
      'approvals.purchase_order.approve': 'APPROVED_REQUEST',
      'approvals.purchase_order.cancel': 'CANCELLED_BY_REQUESTOR',
      'approvals.purchase_order.created': 'CREATED_REQUEST',
      'approvals.purchase_order.decline': 'DENIED_REQUEST',
    };
    var service = this;

    service.makeDuplicate = makeDuplicate;
    service.deletePoDialog = deletePoDialog;
    service.emailPoDialog = emailPoDialog;
    service.emailPurchaseOrder = emailPurchaseOrder;
    service.openCalendar = openCalendar;
    service.getUnitCost = getUnitCost;
    service.getActualUnitCost = getActualUnitCost;
    service.parseEventsData = parseEventsData;
    service.getRecipients = getRecipients;
    service.shouldShowDelete = shouldShowDelete;
    service.getTotalUnits = getTotalUnits;
    service.parsePurchaseOrder = parsePurchaseOrder;
    service.findItemShipment = findItemShipment;
    service.getBackorderText = getBackorderText;
    service.getItemDate = getItemDate;
    service.getItemType = getItemType;
    service.makeBundles = makeBundles;
    service.getEvInfoFromSupplier = getEvInfoFromSupplier;
    service.getInitialPoData = getInitialPoData;
    service.getInitialPoDataFromImplants = getInitialPoDataFromImplants;

    service.status_data = {
      Pending: {
        color:"pending",
        text: "PO.TOOLTIPS.PENDING"
      },
      Active: {
        color:"information",
        text: "PO.TOOLTIPS.ACTIVE"
      },
      Draft: {
        color: "neutral",
        text: "PO.TOOLTIPS.DRAFT"
      },
      Received: {
        color: "success",
        text: "PO.TOOLTIPS.RECEIVED"
      },
      Cancelled: {
        color: "critical",
        text: "PO.TOOLTIPS.CANCELLED"
      }
    };
    service.received_status_data = {
      All:{
        icon: "lens",
        description: "PO.TOOLTIPS.RECEIVED_ALL"
      },
      None:{
        icon: "panorama_fish_eye",
        description: "PO.TOOLTIPS.RECEIVED_NONE"
      },
      Partial:{
        icon: "tonality",
        description: "PO.TOOLTIPS.RECEIVED_SOME"
      }
    };

    return service;

    function openCalendar($event){

      $event.stopPropagation();
      $timeout(function() {
        var thisElem = $($event.currentTarget)[0];
        var datepicker = $(thisElem).siblings('md-datepicker')[0];
        var datePickerButton = $(datepicker).find('.md-datepicker-triangle-button')[0];

        if (datePickerButton) {
          $(datePickerButton).click();
        }
      }, 150);

    }

    function makeDuplicate(po){
      var duplicate = {
        po_date: new Date(),
        supplier: po.supplier,
        created_by: po.created_by,
        notes: po.notes,
        shipping_cost: po.shipping_cost,
        office_vendor_membership: po.office_vendor_membership,
      };
      var dup_items = {};
      _.map(po.items, function(item){
        if(!item.inventory_item){
          return;
        }
        if(dup_items[item.id]){
          dup_items[item.id].quantity += item.quantity;
        }else{
          dup_items[item.id] = item;
          dup_items[item.id].last_action = null;
          dup_items[item.id].last_action_date = null;
          dup_items[item.id].last_action_by = null;
        }
      });
      duplicate.items = Object.keys(dup_items).map(function(key){ return dup_items[key]; });

      return duplicate;
    }

    function deletePoDialog(po, hasReceived){
      hasReceived = !!hasReceived;
      var receivedItems = 0;
      if(po.items){
        receivedItems = _.reduce(po.items, function(sum, item){
          return sum + (item.last_action === 'Received');
        },0);
      }
      if(hasReceived | receivedItems){
        // received dialog
        return choiceDialog(po)
        .then(function(answer) {
          const logFn = answer ? 'purchaseOrderRevertClicked' : 'purchaseOrderKeepProductsClicked';
          sowAnalyticsService[logFn]();
          return simpleDialog(po).then(function(){
            sowAnalyticsService.purchaseOrderDeleteConfirmed();
            return deletePO(po, answer);
          });

        });
      }else{
        // notreceived dialog
        return simpleDialog(po)
        .then(function(answer) {
          //on dialog answered
          sowAnalyticsService.purchaseOrderDeleteConfirmed();
          return deletePO(po);
        });
      }
    }

    function simpleDialog(po){
      return $mdDialog.show({
          controller: 'poDialogController',
          controllerAs: 'poDelCtrl',
          templateUrl: 'templates/marketplace/purchase-orders/modals/po-delete-regular.html',
          parent: angular.element(document.body),
          // targetEvent: $event,
          clickOutsideToClose:true,
          locals: {
            po: po
          },
          bindToController: true
        });
    }

    function choiceDialog(po){
      return $mdDialog.show({
          controller: 'poDialogController',
          controllerAs: 'poDelCtrl',
          templateUrl: 'templates/marketplace/purchase-orders/modals/po-delete-received.html',
          parent: angular.element(document.body),
          // targetEvent: $event,
          clickOutsideToClose:true,
          locals: {
            po: po
          },
          bindToController: true
        });
    }

    function deletePO(po,revertOption){
      revertOption = !!revertOption;
      return PurchaseOrderService.remove(po, revertOption);
    }

    function emailPoDialog(po, rep){
      const recipients = getRecipients(po, rep)
      return $mdDialog.show({
        controller: 'poDialogController',
        controllerAs: 'poEmCtrl',
        templateUrl: 'templates/marketplace/purchase-orders/modals/po-email.html',
        parent: angular.element(document.body),
        // targetEvent: $event,
        clickOutsideToClose:true,
        locals: {
          po: po,
          email:{
            getcopy: true,
            recipients,
          }
        },
        bindToController: true
      })
      .then(function(answer) {
        //on dialog answered
        //alert(JSON.stringify(answer));
        sowAnalyticsService.purchaseOrderEmailConfirmed();
        emailPurchaseOrder(po, answer.recipients, answer.getcopy)
      });
    }

    function emailPurchaseOrder(po, recipients, get_copy, show_confirmation) {
      PurchaseOrderService.email(po, recipients, get_copy)
        .then(() => {
          if (show_confirmation) {
            const t_your_email = $filter('translate')('COMMON.YOUR_EMAIL');
            const t_rep_email = $filter('translate')('COMMON.SALES_REP_EMAIL');
            const confirmation_dialog = $mdDialog.confirm({
              clickOutsideToClose: true,
              templateUrl: 'templates/marketplace/purchase-orders/modals/po-email-confirmation.html',
              /** @ngInject */
              controller: ($scope, $mdDialog) => {
                const user_email = `${t_your_email}: ${accountService.get().email}`;
                if (!_.isEmpty(recipients)) {
                  const rep_emails = recipients
                    .split(', ')
                    // filter out empty strings and ensure that
                    // the user's email is not duplicated
                    .filter(email => email && email !== user_email)
                    .map(email => `${t_rep_email}: ${email}`);
                  $scope.email_addresses = [...rep_emails, user_email];
                } else {
                  $scope.email_addresses = [user_email];
                }
                $scope.closeModal = () => $mdDialog.hide();
              },
            });
            $mdDialog.show(confirmation_dialog);
          } else {
            const t_email_sent = $filter('translate')('ORDERS.EMAIL_SENT');
            $mdToast.show(
              $mdToast.simple()
                .textContent(t_email_sent)
                .position("bottom right")
                .hideDelay(2000)
            );
          }
          $rootScope.$broadcast('po-emailed', po.id);
        })
        .catch(() => {
          const t_email_error = $filter('translate')('MESSAGES.EMAIL_ERROR');
          $mdToast.show(
            $mdToast.simple()
              .textContent(t_email_error)
              .position("bottom right")
              .hideDelay(2000)
          );
        });
    }

    /* This gets the price cost from the existing inventory item */
    function getUnitCost (po_item) {
      const inventory_item = po_item.inventory_item || {};
      if (po_item.buy_by_case === inventory_item.buy_by_case) {
        return inventory_item.price || 0;
      }

      return po_item.unit_cost || 0;
    }

    /* This gets the unit cost from the purchase order item*/
    function getActualUnitCost(poItem) {
      return poItem.unit_cost;
    }

    /** 
     * Generates a string of a comma-separated list of unique sales rep
     * email addresses which will autopopulate the po-email dialog. 
     * 
     * @param {Object} po 
     * @param {Object} rep 
     * 
     * @return {String} 
    */
    function getRecipients(po, rep) {
      const emails = new Set();
      if (_.get(rep, 'email')) {
        emails.add(rep.email);
      }
      if (_.get(po, 'supplier.sales_rep_email')) {
        emails.add(po.supplier.sales_rep_email);
      }
      return [...emails].join(', ');
    }

     /** 
     * Parses each of the Activity Log events of a particular 
     * purchase order to ready them for display in the UI. 
     * 
     * @param {Array} events 
     * 
     * @return {Array} 
    */
    function parseEventsData(events) {
      const parsed_events = _.values(events);
      for (const event of parsed_events) {
        const parsed_data = JSON.parse(event.data);

        const date_string = new Date(event.date).toString();
        const time = date_string.split(' ')[4];

        const type_array = event.type.split('.');

        const t_sowingo_support = $filter('translate')('OFFICE.ROLES.SOWINGO_SUPPORT');
        let user_name = event.user_name || t_sowingo_support;

        let action_locale_key, action_secondary_text, po_status;

        switch (type_array[0]) {
          case 'po':
            // Currently these are always email events
            action_locale_key = 'PO.EMAILED';
            action_secondary_text = parsed_data.object.data;
            po_status = type_array[2];
            break;
            
          case 'approvals':
            action_locale_key = `APPROVALS.${EVENT_ACTION_KEYS[event.type]}`;
            const { requested_by_email, requested_by_name, requested_to_email, requested_to_name } = parsed_data.object;

            switch (event.type) {
              case 'approvals.email.purchase_order.approved.approved':
                action_secondary_text = requested_by_email;
                po_status = 'Active';
                break;
              case 'approvals.email.purchase_order.cancelled.cancelled_by_user':
                action_secondary_text = requested_to_email;
                po_status = 'Cancelled';
                break;
              case 'approvals.email.purchase_order.declined.declined':
                action_secondary_text = requested_by_email;
                po_status = 'Cancelled';
                break;
              case 'approvals.email.purchase_order.pending.approver_copy':
                action_secondary_text = requested_to_email;
                po_status = 'Pending';
                break;
              case 'approvals.email.purchase_order.pending.requestor_copy':
                action_secondary_text = requested_by_email;
                po_status = 'Pending';
                break;
              case 'approvals.purchase_order.approve':
                user_name = requested_to_name;
                po_status = 'Active';
                break;
              case 'approvals.purchase_order.cancel':
                user_name = requested_by_name;
                po_status = 'Cancelled';
                break;
              case 'approvals.purchase_order.created':
                user_name = requested_by_name;
                po_status = 'Pending';
                break;
              case 'approvals.purchase_order.decline':
                user_name = requested_to_name;
                po_status = 'Cancelled';
                break;
            }

          case 'edi':
            const code = parsed_data.object.po_edi_code;
            po_status = parsed_data.object.po_status;
            action_locale_key = `PO.EDI_ACTIONS.ACTION_${code}`;
            action_secondary_text = `EDI ${code}`;
            break;
        }

        event.UI = { action_locale_key, action_secondary_text, po_status, user_name, time };

      }
      return parsed_events;
    }

    /**
     * Determines if the delete button needs to be shown based on the: PO status, PO type,
     * and user_properties(disable_delete and disable_delete_active).
     * 
     * Conditions:
     * 1. Check the po.status: If Draft return the negative value of 'purchase_orders.disable_delete' 
     * 2. Check the po.type:
     *  2.1 Is not EDI return the negative value of 'purchase_orders.disable_delete_active'
     *  2.2 Is a EDI return false 
     * @param {object} po - The purchase order object
     * @param {'Draft'|'Active'|'Cancelled'|'Received'} po.status
     * @param {'edi'|'regular'} po.type
     * @return {boolean}
     */
     function shouldShowDelete (po) {
      if(!po) {
        return false;
      }
      const disable_delete = AccessService.getProperty('purchase_orders.disable_delete');
      const disable_delete_active = AccessService.getProperty('purchase_orders.disable_delete_active');
      
      const is_draft = po.status.toLowerCase() === 'draft';
      const is_edi = po.type.toLowerCase() === 'edi';
      const is_allow_to_delete_all = !disable_delete;
      const is_allow_to_delete_active = !disable_delete_active;

      // Condition 1: Check PO status (Draft PO)
      if (is_draft) {
        return is_allow_to_delete_all;
      }

      // Condition 2: Check PO type (not EDI)
      if(!is_edi) {
        return is_allow_to_delete_active;
      }

      // Condition 3: Check PO type (is EDI) -> Do not show delete
      return false;
    }

    // total units

    /**
     * > It takes a PO and returns the total number of units ordered on that PO (disconsiders substituted items)
     * @param po - The purchase order object
     * @returns The total number of units in the PO.
     */
    function getTotalUnits(po){
      const total = _.reduce(po.items, function(sum, item){
        let qty = 0;
        if(item.inventory_item && item?.status?.toLowerCase() !== 'substituted'){
          qty = item.quantity;
        }
        return sum + qty;
      }, 0);
      return total;
    }

    /**
     * > Parses API data, adding any necessary data for UI purposes for PO pages
     * @param po - The purchase order object
     * @returns the po object.
     */
    function parsePurchaseOrder (po) {
      const totalUnits = getTotalUnits(po);
      _.set(po, 'UI.valid_units', totalUnits);
      return po;
    }

    function _getBundleIndex(bundle, bundle_list){
      let index = -1;
      _.forEach(bundle_list, function(b, i){
        if( bundle.type === b.type && bundle.last_action_date === b.last_action_date){
          index = i;
        }
      });
      return index;
    }

    /**
     * > Loops through each of the PO's items and groups them in bundles by type (shipped, cancelled, received, etc)
     * in order to display them separated on the template.
     * @param items - an array of objects, each object representing an item in the order
     * @returns An array of objects.
     */
    function makeBundles (po){
      const response = {
        groups: {},
        bundles: [],
        qtyOrdered: {},
      };

      _.forEach(po.items, function (item) {
        const type = getItemType(item);
        let group = response.groups[type];
        const shipment = findItemShipment(item, po?.shipments);
        const last_action_date = getItemDate(item, type, shipment);

        if (!group) {
          group = response.groups[type] = [];
        }

        // in order to find other similar items or to create a new section, this bundle has to be created
        const bundle_for_this_item = {
          items: [item],
          last_action_date,
          last_action: type,
          show: true,
        };
        switch (type) {
          case 'shipped':
            bundle_for_this_item.shipment = shipment;
            break;
          case 'backordered':
            bundle_for_this_item.backordered_on = item.backordered_on;
            _.set(bundle_for_this_item, 'UI.backorder_text', getBackorderText(item));
            break;
          case 'substituted':
            bundle_for_this_item.substituted_new_sku = item.substituted_new_sku;
            break;
        }

        const bundle = _.find(group, function (g) {
          // For shipped items, they should be bundled by shipment instead of date
          if (type === 'shipped') {
            return g.shipment?.id === shipment?.id;
          }
          return g.last_action_date === last_action_date;
        });

        /* avoid grouping substituted items since SKU is only for a single item */
        if (bundle && type !== 'substituted') {
          bundle.items.push(item);
        } else {
          group.push(bundle_for_this_item);
        }

        response.qtyOrdered[item.inventory_item_id] = (
          (response.qtyOrdered[item.inventory_item_id] || 0)
            + (item.quantity || 0)
            - (item.extra_quantity || 0)
        );
      });

      // no idea why this is here a second time
      _.forEach(po.items, function(item){
        const item_type = getItemType(item);
        const item_date = getItemDate(item, item_type);
        const bundle = {
          last_action_date: item_date,
          last_action: item_type,
          type: response?.itemTypeOrder?.[item_type],
        };
        const bundle_index = _getBundleIndex(bundle, response.bundles);
        if (bundle_index >= 0) {
          response.bundles[bundle_index].items.push(item);
        } else {
          bundle.items = [item];
          response.bundles.push(bundle);
        }
      });

      return response;
    }

    /**
     * It takes an item, a type, and a shipment, and returns the date of the item's last action, or the
     * date of the shipment, or the date of the item's backorder, or null
     * @param item - the item object
     * @param type - the type of item we're looking at (backordered, shipped, substituted, or
     * regular item)
     * @param shipment - the shipment object for type=shipped items
     */
    function getItemDate (item, type, shipment) {
      let item_date;
      switch(type) {
        case 'backordered':
          item_date = item.backordered_on;
          break;
        case 'shipped':
          item_date = shipment?.shipped_on;
          break;
        case 'substituted':
          item_date = item?.substituted_on;
          break;
        default:
          item_date = item.last_action_date || null;
      }

      // Some of these date properties might be in different formats, 
      // hence the substring to get just the "YYYY-MM-DD" part of it.
      // If itemDate is null, `?.substr()` will return undefined which is fine for this case
      return item_date?.substr(0,10);
    }

    /**
     * Find the shipment that contains the item
     * @param item - the item that we're looking for
     * @returns The itemShipment object
     */
    function findItemShipment (item, shipments_list) {
      // for some cases, items may contain a shipment object
      if (item.shipment) {
        return item.shipment;
      }
      // For items with `status=shipped`, we need information on the shipment 
      // which is located outside the item, in the `shipments` array of the PO itself.
      const item_shipment = _.find(shipments_list, function(shipment){
        let shipment_contains_item = false;
        // A single shipment might have multiple items, so here we look into 
        // all of them to find if the current item is present on the current shipment.
        _.each(shipment.items, function(shipmentItem) {
          if (shipmentItem.order_item_id === item.id) {
            shipment_contains_item = true;
          }
        });
        return shipment_contains_item;
      });
      return item_shipment;
    }

    /**
     * It takes an item and returns the item's type
     * @param item - the item object
     * @returns The type of the item.
     */
    function getItemType (item) {
      // EDI PO's items will have a `status` property, whereas regular items will just use `last_action`
      // this is a failsafe to keep old/regular PO functionality working properly
      // Items not yet received have neither of those properties filled in, so we apply 'notreceived' as their type.
      const type = (item.status || item.last_action || 'notreceived');
      return type.toLowerCase();
    }

    /**
     * Generate text to display on the template regarding a backordered item's estimated ship date
     * 
     * @param {POItem} item - The item object
     */
    function getBackorderText (item) {
      const estimated_shipping_text = $filter('translate')('PO.ESTIMATED_SHIPPING');
      const na_text = $filter('translate')('MARKETPLACE.CART.NA');
      const estimated_ship_date = _.get(item, 'estimated_ship_date');
      const estimated_ship_note = _.get(item, 'estimated_ship_note');
      const ship_date_formatted = $filter('date')(estimated_ship_date);

      // Dates take first priority
      if (estimated_ship_date) {
        return `${estimated_shipping_text}: ${ship_date_formatted}`;
      }

      // When there is no date, check for a note - likely to be a string 
      // eg. "Estimated Ship Date 2/13/23 - 2/22/23"
      if (estimated_ship_note) {
        return estimated_ship_note;
      }

      // If there is no date or note, return "N/A"
      return `${estimated_shipping_text}: ${na_text}`;
    }

    /**
     * > Find the external vendor object that matches the id of
     * the supplier in this draft
     * @param {Supplier} supplier - the supplier object that was selected
     * @param {ExternalVendor[]} external_vendors - the list of external vendors
     * @return {ExternalVendor} - the external vendor object that matches the supplier
     */
    function getEvInfoFromSupplier (supplier, external_vendors) {
      return _.find(external_vendors, (ev) => {
        return ev?.supplier?.id === supplier?.id;
      });
    }

    /**
     * Takes a list of inventory items and returns an object containing initial PO items
     * and a supplier if all items belong to the same one.
     * @param {InventoryItem[]} inventory_items_list - a list of inventory items
     * @return {object} an object with two properties: 
     * `initial_items`: an array of PO items for each of the inventory items in the `inventory_items_list` array
     * `supplier`: the supplier object if all items belong to the same one, otherwise null
     */
    function getInitialPoData (inventory_items_list) {
      const item_suppliers = [];
      // remove falsy values from the list in case there are any
      // (not a known issue but just in case)
      const clean_list = _.compact(inventory_items_list);
      const initial_items = inventoryItemService.getNonDiscontinuedItems(clean_list, (inventory_item) => {
        item_suppliers.push({
          id: inventory_item.supplier_id,
          name: inventory_item.supplier,
        });

        const inventory_item_id = inventory_item.inventory_item_id ?? inventory_item.id;

        // PO item
        return {
          buy_by_case: inventory_item.buy_by_case,
          inventory_item_id,
          inventory_item: _.extend(inventory_item, {
            id: inventory_item_id,
            manufacturer: inventory_item.manufacturer || {
              name: inventory_item.manufacturer_name,
              id: inventory_item.manufacturer_id,
            },
            price: inventory_item.price || inventory_item.default_price || 0.00,
          }),
          name: inventory_item.name,
          quantity: 1,
          searchText: inventory_item.name,
          searchSku: inventory_item.sku,
          sku: inventory_item.sku,
          unit_cost: inventory_item.price || inventory_item.default_price || 0.00,
          tracking_method: inventory_item.tracking_method,
        };
      });

      // Only send a supplier if all items belong to the same one
      let supplier = null;
      const suppliers_found = _.uniqBy(item_suppliers, 'id');
      if (suppliers_found.length === 1 && suppliers_found[0].id) {
        supplier = suppliers_found[0];
      }

      // Initial PO data
      return {
        initial_items,
        supplier,
      };
    }

    
    /**
     * This function maps an array of implants and returns an array of objects with specific properties
     * extracted from each implant that map to its inventory item counterpart.
     * @param {Implant[]} implants_list - a list of implants.
     * @return {InventoryItem[]} an array of objects with specific properties extracted from each implant.
     */
    function _getItemsFromImplants (implants_list) {
      // in case we get any falsy values within the list
      const clean_list = _.compact(implants_list);
      return _.map(clean_list, function (implant) {
        return {
          id: implant.inventory_item_id,
          image: implant.catalog_item.product_image_url,
          price: implant.default_price,
          manufacturer_id: implant.catalog_item.manufacturer_id,
          manufacturer_name: implant.catalog_item.manufacturer_name,
          name: implant.catalog_item.name,
          sku: implant.reference_no,
        };
      });
    }

    /**
     * This function retrieves initial purchase order data from a list of implants.
     * @param {Implant[]} implants_list - an array of implants.
     * @return {object} the initial po data needed for creating new drafts
     */
    function getInitialPoDataFromImplants (implants_list) {
      const items_from_checked_implants = _getItemsFromImplants(implants_list);
      const initial_po_data = getInitialPoData(items_from_checked_implants);
      return initial_po_data;
    }
    
  }
 }());

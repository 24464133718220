(function () {
  'use strict';

  angular
    .module('sowUserAdmin')
    .controller('admActionBarController', admActionBarController)
    .directive('admActionBar', UserAdmActionBar);

  function UserAdmActionBar () {
    return {
      'restrict': 'AC',
      'templateUrl': 'sow-user-admin/directives/user-adm-action-bar.html',
      'controller': 'admActionBarController',
      'controllerAs': 'admAbCtrl',
    };
  }

  function admActionBarController ($rootScope) {
    /*jshint validthis: true */
    var ctrl = this;

    ctrl.newUser = newUser;
    ctrl.addToOffice = addToOffice;

    init();

    return ctrl;

    function init () {

    }

    function newUser () {
      $rootScope.$broadcast('new-user-modal');
    }

    function addToOffice () {
      $rootScope.$broadcast('user-add-to-office-modal');
    }
  }
}());

angular.module('app.marketplace.ui.theme', ['ngMaterial'])

//
// Set Angular-Material Colour Theme
//
.config(function(SowingoThemeConfig, $mdThemingProvider) {
  var palettes = SowingoThemeConfig.palettes;
  var paletteConfig = SowingoThemeConfig.paletteConfig;

  //
  // Define Custom Palettes
  //
  $mdThemingProvider.definePalette('SowingoBlue', palettes.SowingoBlue);
  $mdThemingProvider.definePalette('SowingoGreen', palettes.SowingoGreen);
  $mdThemingProvider.definePalette('SowingoRed', palettes.SowingoRed);


  //
  // Create the Sowingo Theme
  //
  var SowingoTheme = $mdThemingProvider.theme('sowingo');

  SowingoTheme.primaryPalette('SowingoBlue', paletteConfig.primary);
  SowingoTheme.accentPalette('SowingoGreen', paletteConfig.accent);
  SowingoTheme.warnPalette('SowingoRed', paletteConfig.warn);

  //
  // Set Things Up for Internal App Usage
  //
  // angular.forEach(SowingoThemeConfig.aliases, function (aliases, paletteName) {
  //   angular.forEach(aliases, function (aliasValue, aliasName) {
  //     SowingoThemeConfig[paletteName][aliasName] = SowingoThemeConfig[paletteName][aliasValue];
  //   });

  //   // Setup (e.g.) SowingoBlue as an $inject-able constant
  //   $provider.decorator(paletteName, function () {
  //     return SowingoThemeConfig[paletteName];
  //   });
  // });

  //
  // Enable Sowingo Theme
  //
  $mdThemingProvider.setDefaultTheme('sowingo');
});

angular.module('app.marketplace.ui')
  .directive('headerHamburgerMenu', function (gettingStartedDialog) {
    return {
      restrict: 'E',
      replace: true,
      templateUrl: 'templates/marketplace/header/directives/header-hamburger-menu.html',
      link: function ($scope, $element, $attrs) {
        $scope.isMarketing = !!$scope.$eval($attrs.marketing);

        $scope.getStarted = function ($event) {
          gettingStartedDialog.show($event);
        };
      }
    };
  });
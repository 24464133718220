(function () {
  'use strict';

  var vendorModelFields = {
    'id': {
      'display_name': 'id',
      'required': true,
      'api': {
        'submit': true
      },
      'type' : 'integer'
    }
  };

  var vendorModelData = {
    'params': {
      'model_name': 'vendor'
    }
  };

  angular
    .module('app.marketplace.elements')
    .service('vendorModel', vendorModel);

  function vendorModel (apiUrl, searchUrl) {
    /*jshint validthis: true */
    var model = this;

    model.autofill = autofill;
    model.fields = vendorModelFields;
    model.data = vendorModelData;

    model.model_data = {
      'name': 'vendor',
      'display_name': 'Vendor',
      'api': {
        'get_shipping_rates': getShippingRates,
        'get_all_vendors': getAllVendors,
        'lookup_vendor': lookupVendor,
        'search': search,
      }
    };

    return model;

    //
    // Fetch all of the vendor shipping rates
    //
    function getShippingRates (options) {
      return {
        'path': '{0}/shipping_rates/summary'.format(apiUrl),
        'params': {'method': 'GET'}
      };
    }

    //
    // Fetch the list of _all_ vendors
    //
    function getAllVendors (options) {
      return {
        path: sprintf('%s/products/Vendor/_search', searchUrl),
        type: 'elastic',
        params: {
          method: 'POST',
          data: {
            "size": 1000,
            "query": {
              "constant_score": {
                "filter": {
                  "exists": {
                    "field": "id",
                  }
                }
              }
            }
          },
        }
      };
    }

    //
    // Fetch a single Vendor by ID
    //
    function lookupVendor (options) {
      if (!checkNested(options, 'vendor_id')) {
        throw 'Missing option(s) for account path \'lookup_vendor\'. Options: ' + JSON.stringify(options);
      }

      return {
          path: sprintf('%s/products/Vendor/_search', searchUrl),
          type: 'elastic',
          params: {
            method: 'POST',
            data: {
              "size": 1000,
              "query": {
                "bool": {
                  "should": [
                    {"term": {"id": options.vendor_id}},
                    {"term": {"url_name": options.vendor_id}},
                  ]
                }
              }
            }
        }
      };
    }

    function search (options) {
      if (!checkNested(options, 'name')) {
        throw "Missing option(s) for vendor path 'search'. Options: " + JSON.stringify(options);
      }

      return {
        "path": searchUrl + "/products/Vendor/_search",
        "type": "elastic",
        "params": {
          "method": "POST",
          "data": {
            "size": 1000,
            "query": {
              "query_string": {
                  "query": "*" + options.name + "*",
                  "fields": ["name"],
                  "default_operator": "and"
              }
            }
          }
        }
      };
    }

    function autofill (vendor) {
    }
  }
}());

(function () {
  'use strict';

  angular
    .module('sowMedications')
    .service('sowMedicationService', sowMedicationService);

  function sowMedicationService (serverAPI, apiUrl, officeService) {
    /*jshint validthis: true */
    var service = this;

    service.getMedicationStockHistory = getMedicationStockHistory;
    service.getMedications = getMedications;
    service.getMedication = getMedication;
    service.getMedicationFromInventory = getMedicationFromInventory;
    service.createOrUpdateMedication = createOrUpdateMedication;
    service.deleteMedication = deleteMedication;

    service.getMedicationStock = getMedicationStock;
    service.createOrUpdateMedicationStock = createOrUpdateMedicationStock;
    service.removeMedicationStock = removeMedicationStock;
    service.batchRemoveMedicationStock = batchRemoveMedicationStock;
    service.batchCreateMedicationStock = batchCreateMedicationStock;

    service.getMedicationAlerts = getMedicationAlerts;
    service.getMedicationSummary = getMedicationSummary;
    service.getMedicationSuggestions = getMedicationSuggestions;

    return service;

    /**
     * Create a bunch of medication stock by template.
     *
     */
    function batchCreateMedicationStock (options) {
      var url = '{0}/medications/rpc/batch_create_stock'.format(apiUrl);
      var params = {
        'method': 'POST',
        'data': _.pick(options, ['medication_id', 'expiry_date', 'lot_number', 'location_id', 'quantity'])
      };

      return serverAPI
        .doAPICall(url, params)
        .then(function (response) {
          return response.data;
        });
    }

    /**
     *
     * Fetch a summary of a Medication
     *
     */
    function getMedicationSummary (medication) {
      var url = '{0}/medications/{1}/summary'.format(apiUrl, medication.id);
      var params = {'methods': 'GET'};

      return serverAPI
        .doAPICall(url, params)
        .then(function (response) {
          return response.data;
        });
    }

    /**
     *
     * Fetch the stock history of a medication. Returns a list of datapoints. Each datapoint looks like:
     *
     * {
     *   medication_id: "rx_dfgdsg",
     *   date: "2011-01-01",
     *   previous: 0,
     *   on_hand: 12,
     *   added: 20,
     *   removed: 8,
     * }
     *
     * Attributes:
     *
     * - date -- The date that the date is describing.
     * - previous -- The "on hand" value at the start of the day.
     * - on_hand -- The "on hand" value at the end of the day.
     * - added -- The quantity that was added on this date.
     * - removed -- The quantity that was removed on this date.
     *
     * Arguments:
     *
     * - medication -- The Medication that you want to fetch stock history for.
     *
     * Filters:
     *
     * - start_date -- The start of the date range. (Note: This is _inclusive_
     *   meaning that this date _will_ be in the results).
     *
     * - end_date -- The end of the date range (Note: This is _exclusive_
     *   meaning that this date won't be in the results).
     *
     */
    function getMedicationStockHistory (medication, filters) {
      var _filters = _.pick(filters, ['start_date', 'end_date']);
      _filters.medication_id = medication.id;

      var url = '{0}/medications/history'.format(apiUrl);
      var params = {'methods': 'GET', 'params': _filters};

      return serverAPI
        .doAPICall(url, params)
        .then(function (response) {
          return response.data;
        });
    }

    /*
     * Get a list of alerts on Medications in stock.
     *
     * The response JSON is of the form:
     *
     * {
     *  "minimum_level": [],
     *  "expiring": [],
     * }
     *
     * The "expiring" list is comprised of MedicationStock instances that have
     * expired or will be expiring in the next 18 months. The "minimum_level"
     * list is comprised of Medication instances that have a quantity at or
     * below the minimum level.
     *
     */
    function getMedicationAlerts () {
      var url = '{0}/medications/alerts'.format(apiUrl);
      var params = {'method': 'GET'};

      return serverAPI
        .doAPICall(url, params)
        .then(function (response) {
          return response.data;
        });
    }

    /*
     * Get a list of Medication Suggestions for a query string.
     *
     * The suggestions are of the form:
     *
     *  {
     *    "name": "Name",
     *    "brand_name": "Brand Name",
     *    "supplier_id": "sup_dfgkjslfg",
     *    "supplier_name": "Supplier Name",
     *    "manufacturer_id": "mfr_dfgssdfg",
     *    "manufacturer_name": "Manufacturer Name",
     *    "dosage_amount": 10.5,
     *    "dosage_units": "mg/ml",
     *  }
     *
     */
    function getMedicationSuggestions (query) {
      var office = officeService.get();
      var url = '{0}/medications/{1}/suggestions'.format(apiUrl, office.id);
      var params = {
        'method': 'GET',
        'params': {'name': query, 'marketplace_id': office.marketplace[0]}
      };

      return serverAPI
        .doAPICall(url, params)
        .then(function (response) {
          return response.data;
        });
    }

    /**
     * Is an object
     * @param {*} filters
     *  - id could be just one id or an array of ids
     * @return an array of medications based of the request filters
     */

    function getMedications (filters){
      filters = filters || {};
      var url = '{0}/medications'.format(apiUrl);
      var params = {
        'method': 'GET',
        'params': _.pick(filters, ['id', 'office_id', 'expiring', 'supplier_id', 'manufacturer_id', 'barcode', 'is_emergency', 'inventory_item_id','has_actions'])
 };

      return serverAPI
        .doAPICall(url, params)
        .then(function (response) {
          return response.data;
        });
    }

    function getMedication (id) {
      return getMedications({id: id})
      .then(function(response){
        return _.first(response);
      });
    }

    function getMedicationFromInventory (inventory_item_id) {
      return getMedications({inventory_item_id: inventory_item_id})
      .then(function(response){
        return _.first(response);
      });
    }

    function createOrUpdateMedication (medication, selected_vendor) {

      // If passed a selected_vendor, medication is a marketplace product and must be standardized
      if (selected_vendor) {
        medication = standardizeMedicationProduct(medication, selected_vendor);
      }
      var url = '{0}/medications'.format(apiUrl);
      var params = {
        'method': 'POST',
        'data': _.pick(medication, [
          'id', 'office_id', 'name', 'brand_name', 'unit_of_measurement',
          'notes', 'dosage', 'manufacturer_id','buy_by_case','routes_of_admin',
          'supplier_id', 'price', 'track_by', 'pkg_type', 'pkg_sku',
          'pkg_count', 'pkg_units', 'case_type', 'case_quantity', 'case_price',
          'case_sku', 'barcode', 'minimum_level', 'desired_level', 'DIN', 'is_emergency',
          'manufacturer_name', 'supplier_name', 'sku', 'product_id', 'image_url',
        ])
      };

      return serverAPI
        .doAPICall(url, params)
        .then(function (response) {
          return response.data;
        });
    }

    /** 
     * Transforms a Marketplace Medication Item to match the shape of all medications. 
     * 
     * @param {Object} medication 
     * @param {Object} vendor 
     * 
     * @return {Object} 
    */
    function standardizeMedicationProduct(medication, vendor) {

      // Vendor (AKA supplier) properties
      _.set(medication, 'sku', vendor.vendor_sku);
      _.set(medication, 'price', vendor.price);
      var supplier_id = _.get(vendor, 'vendor.id');
      _.set(medication, 'supplier_id', supplier_id);
      var supplier_name = _.get(vendor, 'vendor.name');
      _.set(medication, 'supplier_name', supplier_name);

      // Manufacturer properties
      var manufacturer_name = _.get(medication, 'manufacturer.name');
      _.set(medication, 'manufacturer_name', manufacturer_name);
      var manufacturer_id = _.get(medication, 'manufacturer.id');
      _.set(medication, 'manufacturer_id', manufacturer_id);
      
      // Additional properties
      _.set(medication, 'is_emergency', false);
      _.set(medication, 'track_by', 'Package');
      _.set(medication, 'brand_name', medication.name);
      _.set(medication, 'pkg_type', medication.unit_name);
      _.set(medication, 'product_id', medication.id);
      _.set(medication, 'image_url', medication.main_image);

      // NOTE: we have to send a null ID or the API will think this is an update to an existing medication
      _.set(medication, 'id', null);

      return medication;

    }

    function deleteMedication (medication) {
      var url = '{0}/medications/{1}'.format(apiUrl, medication.id);
      var params = {'method': 'DELETE'};

      return serverAPI
        .doAPICall(url, params)
        .then(function (response) {
          return response.data;
        });
    }

    function getMedicationStock (filters) {
      filters = filters || {};
      var url = '{0}/medications/stock'.format(apiUrl);
      var params = {
        'method': 'GET',
        'params': _.pick(filters, ['id', 'office_id', 'medication_id', 'is_emergency']),
      };

      return serverAPI
        .doAPICall(url, params)
        .then(function (response) {
          return response.data;
        });
    }

    function createOrUpdateMedicationStock (medicationStock) {
      var url = '{0}/medications/stock'.format(apiUrl);
      var params = {
        'method': 'POST',
        'data': _.pick(medicationStock, ['id', 'medication_id', 'expiry_date', 'lot_number', 'location_id'])
      };

      return serverAPI
        .doAPICall(url, params)
        .then(function (response) {
          return response.data;
        });
    }

    function removeMedicationStock (medicationStock) {
      var url = '{0}/medications/stock/{1}'.format(apiUrl, medicationStock.id);
      var params = {'method': 'DELETE'};

      return serverAPI
        .doAPICall(url, params)
        .then(function (response) {
          return response.data;
        });
    }

    function batchRemoveMedicationStock (stock_array) {
      var url = '{0}/medications/stock'.format(apiUrl);
      var id_array = _.map(stock_array, function(stock_item){
        return stock_item.id;
      });
      var params = {'method': 'DELETE', 'headers':{ 'content-type':'application/json'}, 'data':{ 'id': id_array } };

      return serverAPI
        .doAPICall(url, params)
        .then(function (response) {
          return response.data;
        });
    }
  }
}());

'use strict';
{
  /** @ngInject */
  class AlgoliaHelperService {
    constructor($state, $stateParams) {
      this.$state = $state;
      this.$stateParams = $stateParams;
      this.url_filter_separator = '--';
    }

    /**
     * Updates the url with the current search parameters.
     * @param {object} algolia_params
     * @return {void}
     */
    syncUrlWithAlgoliaParams(algolia_params) {
      const url_params = this._createUrlParams(algolia_params);

      this.$state.transitionTo(this.$state.current, _.extend(this.$stateParams, url_params), {
        notify: false,
      });
    }

    /**
     * It takes a string of comma-separated filters and returns an object with the filters as keys and
     * the value as list of strings.
     * @param {string} facet_filters 'manufacturer.name:Dentsply--vendor_inventory.vendor.name_locale.en:Patterson'
     * @return {object} ```
     * {
     *    manufacturer.name: ['Dentsply'],
     *    vendor_inventory.vendor.name_locale.en: ['Patterson']
     * }
     * ```
     */
    transformFacetFiltersToObject(facet_filters) {
      const filter_by_category = {};

      // Split the string -> ['manufacturer.name:Dentsply', 'vendor_inventory.vendor.name_locale.en:Patterson']
      const facet_filters_list = facet_filters?.split(this.url_filter_separator);

      facet_filters_list?.forEach((filter) => {
        // Split the filter into an array of category and value -> ['manufacturer.name', 'Dentsply']
        const [category, value] = filter.split(':');

        if (category && value) {
          // Add the new filter to the object -> {manufacturer.name: ['Dentsply']}
          filter_by_category[category] = [value];
        }
      });
      return filter_by_category;
    }

    /**
     * It takes a string of comma separated numeric filters and returns an object of key value pairs
     * where the key is the category and the value is an object of operators and values
     * @param {string} numeric_filters 'vendor_inventory.price<=100--vendor_inventory.price>=100'
     * @return {object} ```
     * {
     *   vendor_inventory.price: {
     *     '<=': [ '100' ],
     *     '>=': [ '100' ]
     *   }
     * }
     * ```
     */
    transformNumericFiltersToObject(numeric_filters) {
      // Split the string into an array of filters -> ['vendor_inventory.price<=100', 'vendor_inventory.price>=100']
      const numeric_filters_list = numeric_filters?.split(this.url_filter_separator);
      const filter_by_category = {};

      numeric_filters_list?.forEach((numeric_filter) => {
        // Regex to match the operator and the value: "<", ">", "<=", or ">=" followed by a number
        const regex = /([><]=?)(\d+)/;

        // Match the regex and return an array of the operator and the value -> ['<=100', '<=', '100']
        const result = numeric_filter.match(regex);
        if (result) {
          // Destructure the array to get the operator and the value -> '<=' and '100'
          const [, operator, value] = result;

          // Split the numeric filter by the operator to get the category -> ['vendor_inventory.price']
          const [category] = numeric_filter.split(operator);

          // Add the new filter to the object -> {vendor_inventory.price: { '<=': [ '100' ], '>=': [ '100' ] }}
          filter_by_category[category] = {
            ...filter_by_category[category],
            [operator]: [value],
          };
        }
      });

      return filter_by_category;
    }

    /**
     * It takes a page number and returns the pagination index (page - 1)
     * @param {number} page
     * @return {number}
     */
    getZeroIndexValue(page) {
      return page - 1;
    }

    // Private functions

    /**
     * It takes a page number and returns the url page number (page + 1)
     * @param {number} page
     * @return {number}
     */
    _getUrlPageNumber(page = 0) {
      return page + 1;
    }

    /**
     * Takes the Algolia params and returns a URL params object
     * @param {object} algolia_params
     * @return {{page: number, facet_filters: string, numeric_filters: string}}}
     */
    _createUrlParams(algolia_params) {
      const url_params = {};

      // Page
      url_params.page = this._getUrlPageNumber(algolia_params.page);

      // Facet filters
      const facet_filters = _.get(algolia_params, 'facetFilters', []);
      url_params.facet_filters = facet_filters.length > 0 ? facet_filters.join(this.url_filter_separator) : null;

      // Numeric filters
      const numeric_filters = _.get(algolia_params, 'numericFilters', []);
      url_params.numeric_filters = numeric_filters.length > 0 ? numeric_filters.join(this.url_filter_separator) : null;

      return url_params;
    }
  }

  angular.module('sowAlgolia').service('AlgoliaHelperService', AlgoliaHelperService);
}

angular.module('app.shared.ui')
  .directive('videoModalDisplay', function () {
    return {
      restrict: 'E',
      require: '?^videoModal',
      replace: true,
      transclude: true,
      controllerAs: 'vmCtrl',
      template:
        '<div class="video-modal-wrapper" ng-show="vmCtrl.isOpen()">' +
        '  <div class="js-video" ng-if="vmCtrl.isOpen()">' +
        '    <a class="js-video-close" ng-click="vmCtrl.toggle(false)"> &times; CLOSE </a>' +
        '    <ng-transclude></ng-transclude>' +
        '  </div>' +
        '  <div class="js-video-backdrop"></div>' +
        '</div>',
      link: function ($scope, $element, $attrs, ctrl) {
        if (!ctrl) {
          return;
        }

        var jsVideo = angular.element($element.children()[0]);
        var iframe = angular.element(jsVideo.children()[1]);

        ctrl.videoElement = iframe;
      }
    };
  });
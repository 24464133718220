angular.module('app.marketplace.ui.inventory')

.directive('inventoryProductLineItem', function() {
  return {
    restrict: 'E',
    controllerAs: 'ctrl',
    scope: true,
    bindToController: {
      item: '='
    },
    controller: function() {},
    template: '<div layout="row">'
      + '<img ng-src="{{ ctrl.item.image || ctrl.item.img_src | imageUrl }}" class="md-avatar" />'
      + '<div class="md-list-item-text p1">'
      +   '<h3 flex="100">{{ ctrl.item.name }}</h3>'
      +   '<div flex="100" class="manufacturer-name">'
      +     '<span ng-show="ctrl.item.supplier_name">from: {{ ctrl.item.supplier_name }}</span>'
      +     '<span ng-show="ctrl.item.manufacturer.name">, by: {{ ctrl.item.manufacturer.name }}</span>'
      +   '</div>'
      + '</div>'
    + '</div>'
  };
});

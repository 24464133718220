(function () {
  'use strict';
  angular.module("sowCertifications")
    .directive('actionBarCertifications', certActionBarDirective);

  function certActionBarDirective() {
    return {
      restrict: 'AC',
      templateUrl: 'sow-certifications/directives/action-bar-certifications.html',
      controller: 'certActionBarController',
      controllerAs: 'certAbCtrl'
    };
  }

}());

angular.module("app.shared.system")
  // TODO reassess. Provides service for accessing global variables.
  .service("globalVariablesSrv", ["$timeout", function ($timeout) {
    var globalVariables = {
      "currTimeVal": new Date().getTime(),
      "currTimeValUTC": moment().utc().valueOf()

    };

    $timeout(function () {
      globalVariables["currTimeVal"] = new Date().getTime();
      globalVariables["currTimeValUTC"] = moment().utc().valueOf();
    }, 1000);

    return {
      getVar: function (name) {
        return globalVariables[name];
      }
    };
  }]);
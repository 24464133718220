(function () {
  'use strict';

  angular.module('sowMarketplace')
  .controller('marketplaceActionBarController', marketplaceActionBarController)
  .directive('actionBarMkt', marketplaceActionBarDirective);

  function marketplaceActionBarDirective () {
    return {
      restrict: 'AC',
      templateUrl: 'sow-mkt/directives/mkt-action-bar.html',
      controller: 'marketplaceActionBarController',
      controllerAs: 'mktAbCtrl'
    };
  }

  function marketplaceActionBarController ($rootScope, $state, appEvents, sowMktService,  errorService, sessionService, AccessService, mktHelperService) {
    /*jshint validthis:true*/
    var ctrl = this;
    ctrl.categories = [];
    ctrl.manufacturers = [];

    ctrl.setCurrentCategory = setCurrentCategory;
    ctrl.goToCategory = goToCategory;
    ctrl.goToSubcategory = goToSubcategory;
    ctrl.goToMainList = goToMainList;
    ctrl.openNewCart = openNewCart;
    ctrl.localize = mktHelperService.getLocalizedProp;
    ctrl.focusSearchBar = focusSearchBar;

    init();

    return ctrl;

    function init () {
      sessionService.onInit()
      .then(function(session_data){
        loadCategories().then(function(){
          setCurrentCategory(ctrl.categories[0]);
        });
      });
      $rootScope.$on(appEvents.setSession, function (event, newSession) {
        ctrl.mkt_tagline = _.get(newSession, 'current_marketplace.tagline');
        ctrl.mkt_img_src = _.get(newSession, 'current_marketplace.image_src');
      });
      defineLocks();
    }

    function setCurrentCategory (category) {
      ctrl.current_category = category;
    }

    function goToCategory (category) {
      $state.go('app.mkt.search',{'cat_id':category.id, 'category':category}, {'reload': true, 'inherit': false});
    }

    function goToSubcategory (category, subcategory) {
      $state.go('app.mkt.search',{'cat_id':category.id, 'sub_id': subcategory.id, 'category':category, 'subcategory':subcategory}, {'reload': true, 'inherit': false});
    }

    function goToMainList () {
      $state.go('app.mkt.categories');
    }

    function loadCategories () {
      return sowMktService.getCategories()
      .then(function(cat_response){
        ctrl.categories = cat_response.categories;
      })
      .catch(function(error){
        errorService.uiErrorHandler(error);
      });
    }

    function loadManufacturers () {
      sowMktService.getManufacturers()
      .then(function(mf_response){
        ctrl.manufacturers = mf_response.manufacturers;
      })
      .catch(function(error){
        errorService.uiErrorHandler(error);
      });
    }

    function openNewCart () {
      $rootScope.$broadcast('sow-cart-open');
    }

    function focusSearchBar () {
      angular.element('#mkt-landing-search-input').trigger('focus');
    }

    function defineLocks () {
      ctrl.disabled_orders_page = AccessService.getProperty('orders.disable_page');
      ctrl.hideCartButton = !AccessService.hasCartAccess();
    }
  }

})();

angular.module("app.shared.support")
  //Alternative clickAnywhereButHere directive
  .directive('jfBlur', ["$document", function ($document) {
    return {
      restrict: 'A',
      link: function (scope, element, attr, ctrl) {

        var handler = function (event) {
          var isClickedElementChildOfPopup = element
            .find(event.target)
            .length > 0;

          if (isClickedElementChildOfPopup) {
            return;
          }

          scope.$apply(function () {
            //        scope[attr.jfBlur] = false;
            scope.$eval(attr.jfBlur);
          });
        };

        $(document).on('click', handler);

        scope.$on('$destroy', function () {
          $(document).off('click', handler);
        });

      }
    };
  }]);

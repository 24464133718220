(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.implants')
    .filter('leftpad', leftpadFilter);

  // TO-DO: move this to somewhere like app.js

  //
  // fill a number with Zeroes to its left, for a `length` amount
  // usage: `<span>{{23 | leftpad:4}}</span>` should display `0023`
  //
  function leftpadFilter() {
    return function (input, length) {
      var number = parseInt(input, 10);
      length = parseInt(length, 10);
      if (isNaN(number) || isNaN(length)) {
        return input;
      }
      number = '' + number;
      while (number.length < length) {
        number = '0' + number;
      }
      return number;
    };
  }

}());

angular.module("app.marketplace.ui.inventory")
  .directive('inventoryAlertsBlock', [function () {
    return {
      restrict: 'E',
      replace: true,
      templateUrl: 'templates/marketplace/inventory/directives/inventory-alerts-block.html',
      link: function ($scope, $element, $attributes) {
      },
      controller: 'inventoryActivityController'
    };
  }]);

(function () {
  'use strict';

  angular.module('sowInventory')
  .directive('sowIiPricingHistory', sowInventoryItemPricingHistoryDirective)
  .controller('sowInventoryItemPricingHistoryController', sowInventoryItemPricingHistoryController);

  function sowInventoryItemPricingHistoryDirective () {
    return {
      restrict: 'E',
      replace: true,
      templateUrl: 'sow-inventory/directives/item-pricing-history.html',
      controller: 'sowInventoryItemPricingHistoryController',
      controllerAs: 'iiphCtrl',
      scope: {
        'item': '=',
      }
    };
  }

  function sowInventoryItemPricingHistoryController ($filter, $state, $rootScope, membershipService) {
    var ctrl = this;
    // styles/img/branding/sowingo-logo.png
    // assignment

    ctrl.orders_info = {
      'Purchase Order': {
        'icon': {
          'material':'assignment',
          'color': '',
        },
        'Active': 'blue-icon',
        'Draft': 'grey-icon',
        'Received': 'green-icon',
        'Cancelled': 'red-icon',
      },
      'Marketplace Order': {
        'icon': {
          'img': 'styles/img/branding/sowingo-logo.png',
        },
        'Open': 'blue-icon',
        'Closed': 'red-icon',
        'Received': 'green-icon',
        'Cancelled': 'red-icon',
      },
      'icons':{
        'Item': 'styles/img/icons/ic-item-product.svg',
        'Medication': 'styles/img/icons/ic-item-medication.svg',
        'pack': 'styles/img/icons/ic-pack.svg',
      }
    };

    ctrl.sortOrder = 'order_date';

    ctrl.goToOrder = goToOrder;
    ctrl.getUnitLabel = getUnitLabel;

    ctrl.$onInit = () => {
      defineLocks();
    }

    return ctrl;
    
    function goToOrder (order) {
      if (!ctrl.user_has_access_to_orders) {
        return;
      }
      if(order.order_type === 'Purchase Order' && !ctrl.disable_purchase_orders){
        var route = '';
        if(order.order_status === 'Draft'){
          route = 'app.orders.purchase.draft';
        } else {
          route = 'app.orders.purchase.active';
        }
        var url = $state.href(route, {'poId': order.id}, {'absolute': false});
        openInNewTab(url);
      } else if(order.order_type === 'Marketplace Order' && !ctrl.disable_marketplace_orders) {
        var url = $state.href('app.orders.detail', {'hrid': order.order_id}, {'absolute': false});
        openInNewTab(url);
      }
      // closeSlideout();
    }

    function getUnitLabel (unit, quantity) {
      if (!unit) {
        return "";
      }

      var capitalized_unit = unit.toUpperCase();
      var localization_key =  "COMMON." + capitalized_unit;
      var t_label = $filter('translate')(localization_key) + "";
      t_label = t_label.toUpperCase();

      // If we get back the localization key instead of localized text,
      // it means the unit of this item is not in our JSON...
      if (t_label === localization_key) {
        // ...so we should just use the unit type we were passed
        t_label = capitalized_unit;
      }

      // The following logic prevents us displaying text like "1 PACKAGES", "3 EACHS", or "2 BOXS":
      var plural_modifier = "S";
      if (quantity === 1 || capitalized_unit === "EACH") {
        plural_modifier = "";
      } else if (t_label === "BOX") {
        plural_modifier = "ES"
      }

      return t_label + plural_modifier;
    }

    function openInNewTab (url) {
      // $("<a>").attr("href", url).attr("target", "_blank")[0].click();
      window.open(url, '_blank');
    }

    function closeSlideout () {
      $rootScope.$broadcast('close-inventory-slideout');
    }

    function defineLocks() {
      const membership = membershipService.get();
  
      const master_permission_condition = _.values(membership?.user_permissions).includes('master_permission');

      const orders_permission_condition = _.values(membership?.user_permissions).includes('marketplace_orders_purchase_orders');
      ctrl.user_has_access_to_orders = orders_permission_condition || master_permission_condition;

      const disable_po_condition = _.get(membership, 'user_properties.permission_overrides.disable_purchase_orders', false);
      ctrl.disable_purchase_orders = disable_po_condition;
  
      const disable_mkt_orders_condition = _.get(membership, 'user_properties.permission_overrides.disable_marketplace_orders', false);
      ctrl.disable_marketplace_orders = disable_mkt_orders_condition;

    }
  }
}());

(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.implants')
    .controller('impDrillsController', impDrillsController);

  function impDrillsController ($scope, $filter, $stateParams, $mdSidenav, $timeout, sgToast) {
    /*jshint validthis: true */
    var ctrl = this;
    ctrl.types = [];

    ctrl.setType = setType;
    ctrl.currentTypeIs = currentTypeIs;
    ctrl.shopToast = shopToast;
    
    init();

    return ctrl;

    function init () {
      mockUpData();
      setFirstType();
    }

    function setFirstType () {
      ctrl.current_type = ctrl.types[0];
    }

    function setType (type_abbrev) {
      var type = _.find(ctrl.types, {"abbrev": type_abbrev});
      if (type) {
        ctrl.current_type = type;
      }
    }

    function currentTypeIs (abbrev) {
      return ctrl.current_type.abbrev === abbrev;
    }

    function shopToast () {
      var text = $filter('translate')('COMMON.ADDED_TO_SL');
      sgToast.showSimple(text);
    }
    
    function getRandomId () {
    
    }

    function mockUpData () {
      ctrl.icon = {
        svg: "styles/img/implants/drills-icon.svg"
      };
      ctrl.types = [
        {
          name: "Straumann® Round Bur",
          tab: "ROUND BUR",
          abbrev: "RB",
          groups: [
            {
              name: "Straumann® Round Bur",
              icon: "screw-icon.svg",
              color: "none",
              img: "styles/img/implants/drills/round-bur-group.png",
              items: [
                {
                  img: "styles/img/implants/drills/round-bur-14-mm.png",
                  description: "Round bur Ø 1.4 mm",
                  id: "044.022",
                },
                {
                  img: "styles/img/implants/drills/round-bur-23-mm.png",
                  description: "Round bur Ø 2.3 mm",
                  id: "044.003",
                },
                {
                  img: "styles/img/implants/drills/round-bur-31-mm.png",
                  description: "Round bur Ø 3.1 mm",
                  id: "044.004",
                },
              ]
            }
          ]
        },
        {
          name: "Straumann® Pilot Drill",
          tab: "PILOT DRILL",
          abbrev: "PD",
          img: "styles/img/implants/drills/pilot-group.png",
          groups: [
            {
              name: "Straumann® Pilot Drill",
              icon: "screw-icon.svg",
              img: "styles/img/implants/drills/pilot-group.png",
              color: "none",
              items: [
                {
                  img: "styles/img/implants/drills/pilot-drill-long-guided-22-mm-l-414-mm.png",
                  description: "BLT Pilot Drill, long, guided, Ø 2.2mm, L 41.4mm",
                  id: "034.259",
                },
                {
                  img: "styles/img/implants/drills/b-l-t-pilot-drill-medium-guided-22-mm-l-374-mm.png",
                  description: "BLT Pilot Drill, medium, guided, Ø 2.2mm, L 37.4mm",
                  id: "044.003",
                },
                {
                  img: "styles/img/implants/drills/b-l-t-pilot-drill-short-guided-22-mm-l-334-mm.png",
                  description: "BLT Pilot Drill, short, guided, Ø 2.2mm, L 33.4mm",
                  id: "044.004",
                },
                {
                  img: "styles/img/implants/drills/b-l-t-pilot-drill-long-22-mm-l-41-mm.png",
                  description: "BLT Pilot Drill, long, Ø 2.2mm, L 41mm",
                  id: "044.003",
                },
                {
                  img: "styles/img/implants/drills/b-l-t-pilot-drill-short-22-mm-l-33-mm.png",
                  description: "BLT Pilot Drill. short, Ø 2.2mm, L 33mm",
                  id: "044.003",
                },
                {
                  img: "styles/img/implants/drills/pilot-drill-1-long-22-mm-l-41-mm.png",
                  description: "Pilot drill 1, long, Ø 2,2 mm, L 41 mm",
                  id: "044.003",
                },
                {
                  img: "styles/img/implants/drills/pilot-drill-1-short-22-mm-l-33-mm.png",
                  description: "Pilot drill 1, short, Ø 2,2 mm, L 33 mm",
                  id: "044.003",
                },
                {
                  img: "styles/img/implants/drills/pilot-drill-2-long-28-mm-l-41-mm.png",
                  description: "Pilot drill 2, long, Ø 2,8 mm, L 41 mm",
                  id: "044.003",
                },
                {
                  img: "styles/img/implants/drills/pilot-drill-2-short-28-mm-l-33-mm.png",
                  description: "Pilot drill 2, short, Ø 2,8 mm, L 33 mm",
                  id: "044.003",
                },
                {
                  img: "styles/img/implants/drills/pilot-drill-extra-long-guided-22-mm-l-40-mm.png",
                  description: "Pilot drill, extra-long, guided, , Ø 2,2 mm, L 40 mm",
                  id: "044.003",
                },
                {
                  img: "styles/img/implants/drills/pilot-drill-long-guided-22-mm-l-36-mm.png",
                  description: "Pilot drill, long, guided, Ø 2,2 mm, L 36 mm",
                  id: "044.003",
                },
                {
                  img: "styles/img/implants/drills/pilot-drill-short-guided-22-mm-l-32-mm.png",
                  description: "Pilot drill, short, guided, Ø 2,2 mm, L 32 mm",
                  id: "044.003",
                },
              ]
            }
          ]
        },
        {
          name: "Straumann® Twist Drill",
          tab: "TWIST DRILLS",
          abbrev: "TD",
          groups: [
            {
              name: "Straumann® Ø2.8mm Twist Drill",
              icon: "screw-icon.svg",
              color: "none",
              img: "styles/img/implants/drills/twist-group-yellow.png",
              items: [
                {
                  img: "styles/img/implants/drills/b-l-t-drill-long-guided-28-mm-l-414-mm.png",
                  description: "BLT Drill Long, guided Ø 2.8mm, L 41.4mm ",
                  id: "044.022",
                },
                {
                  img: "styles/img/implants/drills/b-l-t-drill-medium-guided-28-mm-l-374-mm.png",
                  description: "BLT Drill medium, guided Ø 2.8mm, L 37.4mm",
                  id: "044.003",
                },
                {
                  img: "styles/img/implants/drills/b-l-t-drill-short-guided-28-mm-l-334-mm.png",
                  description: "BLT Drill short, guided Ø 2.8mm, L 33.4mm",
                  id: "044.004",
                },
                {
                  img: "styles/img/implants/drills/b-l-t-drill-short-28-mm-l-33-mm.png",
                  description: "BLT Drill, short, Ø 2.8mm, L 33mm ",
                  id: "044.004",
                },
                {
                  img: "styles/img/implants/drills/twist-drill-p-r-o-extra-long-guided-28-mm-l-40-mm.png",
                  description: "Twist drill PRO, extra-long, guided, Ø 2.8 mm, L 40 mm ",
                  id: "044.004",
                },
                {
                  img: "styles/img/implants/drills/twist-drill-p-r-o-long-guided-28-mm.png",
                  description: "Twist drill PRO long, guided, Ø 2,8 mm, L 36 mm",
                  id: "044.004",
                },
                {
                  img: "styles/img/implants/drills/twist-drill-p-r-o-short-guided-l-32-mm-28-mm.png",
                  description: "Twist drill PRO, short, guided, L 32 mm, Ø 2.8 mm",
                  id: "044.004",
                },
              ]
            },
            {
              name: "Straumann® Ø3.5mm Twist Drill",
              icon: "screw-icon.svg",
              color: "none",
              img: "styles/img/implants/drills/twist-group-red.png",
              items: [
                {
                  img: "styles/img/implants/drills/b-l-t-drill-long-guided-35-mm-length-414-mm.png",
                  description: "BLT Drill Long, guided Ø 3.5mm, Length: 41.4mm ",
                  id: "044.004",
                },
                {
                  img: "styles/img/implants/drills/b-l-t-drill-medium-guided-35-mm-length-374-mm.png",
                  description: "BLT Drill medium, guided Ø 3.5mm, Length 37.4mm",
                  id: "044.004",
                },
                {
                  img: "styles/img/implants/drills/b-l-t-drill-long-35-mm-l-41-mm.png",
                  description: "BLT Drill, long, Ø 3.5mm, L 41mm",
                  id: "044.004",
                },
                {
                  img: "styles/img/implants/drills/b-l-t-drill-short-guided-35-mm-l-334-mm.png",
                  description: "BLT Drill, short, guided, Ø 3.5mm, L 33.4mm",
                  id: "044.004",
                },
                {
                  img: "styles/img/implants/drills/b-l-t-drill-short-35-mm-l-33-mm.png",
                  description: "BLT Drill, short, Ø 3.5mm, L 33mm",
                  id: "044.004",
                },
                {
                  img: "styles/img/implants/drills/twist-drill-p-r-o-long-35-mm-l-41-mm.png",
                  description: "Twist drill PRO, long, Ø 3.5 mm, L 41 mm",
                  id: "044.004",
                },
                {
                  img: "styles/img/implants/drills/twist-drill-p-r-o-short-35-mm-l-33-mm.png",
                  description: "Twist drill PRO, short, Ø 3.5 mm, L 33 mm",
                  id: "044.004",
                },
                {
                  img: "styles/img/implants/drills/twist-drill-p-r-o-extra-long-guided-35-mm-l-40-mm.png",
                  description: "Twist drill PRO, extra-long, guided, Ø 3.5 mm, L 40 mm",
                  id: "044.004",
                },
                {
                  img: "styles/img/implants/drills/twist-drill-p-r-o-long-guided-35-mm-l-36-mm.png",
                  description: "Twist drill PRO, long, guided, Ø 3.5mm, L 36 mm",
                  id: "044.004",
                },
                {
                  img: "styles/img/implants/drills/twist-drill-p-r-o-short-guided-35-mm-l-32-mm.png",
                  description: "Twist drill PRO, short, guided, Ø 3.5 mm, L 32 mm",
                  id: "044.004",
                },
              ]
            },
            {
              name: "Straumann® Ø3.5mm Twist Drill",
              icon: "screw-icon.svg",
              color: "none",
              img: "styles/img/implants/drills/twist-group-green.png",
              items: [
                {
                  img: "styles/img/implants/drills/b-l-t-drill-long-guided-42-mm-length-414-mm.png",
                  description: "BLT Drill Long, guided Ø 4.2mm, Length: 41.4mm ",
                  id: "044.022",
                },
                {
                  img: "styles/img/implants/drills/b-l-t-drill-medium-guided-42-mm-length-374-mm.png",
                  description: "BLT Drill medium, guided Ø 4.2mm, Length 37.4mm",
                  id: "044.003",
                },
                {
                  img: "styles/img/implants/drills/b-l-t-drill-long-42-mm-l-41-mm.png",
                  description: "BLT Drill, long, Ø 4.2mm, L 41mm",
                  id: "044.004",
                },
                {
                  img: "styles/img/implants/drills/b-l-t-drill-short-guided-42-mm-l-334-mm.png",
                  description: "BLT Drill, short, guided, Ø 4.2mm, L 33.4mm",
                  id: "044.004",
                },
                {
                  img: "styles/img/implants/drills/b-l-t-drill-short-42-mm-l-33-mm.png",
                  description: "BLT Drill, short, Ø 4.2mm, L 33mm",
                  id: "044.004",
                },
                {
                  img: "styles/img/implants/drills/twist-drill-p-r-o-long-42-mm-l-41-mm.png",
                  description: "Twist drill PRO, long, Ø 4.2 mm, L 41mm",
                  id: "044.004",
                },
                {
                  img: "styles/img/implants/drills/twist-drill-p-r-o-short-42-mm-l-33-mm.png",
                  description: "Twist drill PRO, short, Ø 4.2 mm, L 33mm",
                  id: "044.004",
                },
                {
                  img: "styles/img/implants/drills/twist-drill-p-r-o-extra-long-guided-42-mm-l-40-mm.png",
                  description: "Twist drill PRO, extra-long, guided, Ø 4.2 mm, L 40mm",
                  id: "044.004",
                },
                {
                  img: "styles/img/implants/drills/twist-drill-p-r-o-long-guided-42-mm-l-36-mm.png",
                  description: "Twist drill PRO, long, guided, Ø 4.2mm, L 36mm",
                  id: "044.004",
                },
                {
                  img: "styles/img/implants/drills/twist-drill-p-r-o-short-guided-42-mm-l-32-mm.png",
                  description: "Twist drill PRO, short, guided, Ø 4.2 mm, L 32mm",
                  id: "044.004",
                },
              ]
            }
          ]
        },
      ];

    }
    
  }
}());

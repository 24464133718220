(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.medications')
    .controller('medCountController', medCountController);

  function medCountController ($scope, $state, $stateParams) {
    /*jshint validthis: true */
    var ctrl = this;

    init();

    return ctrl;

    function init () {
      
    }

  }
}());

angular.module("app.shared.support")
  .directive('inboxStyleBundle', function ($log, $timeout) {
    return {
      restrict: 'E',
      transclude: true,
      replace: true,
      scope: {
        isOpen: '@',
        openBundle: '&',
        closeBundle: '&',
        bundle: '=model',
        simple: '=' //Required for "simple" use of the directive due to errors and conflicts of assuming simple and then allowing fancy transcluding.
      },
      templateUrl: 'templates/shared/support/inbox_style/inboxStyleBundle.html',
      link: function ($scope, $element, $attrs) {
        $scope.open = false;
        $scope.$attrs = $attrs;
        $scope.$parent.$watch($scope.isOpen, function (newValue) { $scope.open = newValue; });
        $scope.$watch('open', function (newValue) {
          if (newValue) {
            $element.addClass('is-open');
          } else {
            $element.removeClass('is-open');
          }
        });
        if ($scope.simple) {
          $scope.dont_fancy_transclude = true;
        }
      },
      controller: function ($scope) {
        this.setHeading = function (element) {
          this.heading = element;
        };
        this.setInternalHeading = function (element) {
          this.internal_heading = element;
        };
        this.setInternalHeadingActions = function (element) {
          this.internal_heading_actions = element;
        };
        this.setContent = function (element) {
          this.content = element;
        };

        $scope.toggleBundle = function () {
          if ($scope.open && $scope.$attrs.openBundle && $scope.$attrs.closeBundle) { //Fn check is made to avoid double toggle.
            $scope.closeBundle();
          } else if (!$scope.open && $scope.$attrs.openBundle) {
            $scope.openBundle();
          }
        };

        this.scope = $scope;
      }
    };
  });
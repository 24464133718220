(function () {
  'use strict';

  const sowCardlessBilling = {
    selector: 'sowCardlessBilling',
    templateUrl: 'sow-mkt/directives/cardless-billing.html',
    controller: 'cardlessBillingController',
    controllerAs: 'mktcbCtrl',
  };

  angular.module('sowMarketplace')
    .controller(sowCardlessBilling.controller, cardlessBillingController)
    .component(sowCardlessBilling.selector, sowCardlessBilling);
  
  /** @ngInject */
  function cardlessBillingController ($scope, $rootScope, $state, $timeout, stripe, errorService, cartService, officeService, addressService, elementService, formService) {
    /*jshint validthis:true*/
    var ctrl = this;
    ctrl.edit_address = false;
    ctrl.saving = false;
    ctrl.office_name = $rootScope.current_office.name;

    ctrl.editAddress = editAddress;
    ctrl.cancelEdit = cancelEdit;
    ctrl.saveAddress = saveAddress;
    ctrl.setShowSuiteField = setShowSuiteField;

    init();

    return ctrl;

    function init () {
      getUpdatedCartData();
      $scope.$on('mkt-shipping-address-updated', () => handleShippingAddressUpdate());
      $scope.$on('cartService: set-cart', () => getUpdatedCartData());
      $scope.$on('cartService: clear-cart', () => getUpdatedCartData());
      $scope.$on('cartService: itemAddedToCart', () => getUpdatedCartData());
      $scope.$on('$destroy', savingForm);
    }

    function getUpdatedCartData (cart_data) {
      ctrl.cart = cart_data || cartService.get();
      ctrl.address_data = ctrl.cart.cardless_billing_address;
      ctrl.billing_address = ctrl.billing_address || angular.copy(ctrl.address_data);
      setShowSuiteField();
      ctrl.vendors = _.map(ctrl.cart.vendor_groups, function(vendor){
        return _.pick(vendor, ['vendor_name', 'vendor_image', 'vendor_customer_number', 'vendor_id', 'id']);
      });
      ctrl.shipping_address = angular.copy(addressService.extractAddress(ctrl.cart.shipping_info));
      ctrl.use_shipping_address = checkIfAddressesMatch(); // boolean bound to checkbox
    }

    function handleShippingAddressUpdate() {
      if (ctrl.use_shipping_address) {
        // The user has selected to use the shipping address as the billing
        // address, and has now updated the shipping address. We need to ensure
        // that the updated shipping address will be used here as well.

        // Getting the updated cart data resets ctrl.use_shipping_address
        getUpdatedCartData();
        // so we manually set it back to true
        ctrl.use_shipping_address = true;
        // before saving the address using the new shipping address.
        saveAddress();
      } else {
        getUpdatedCartData();
      }
    }

    function isBillingAddressValid (address_data) {
      return addressService.validateAddress(address_data);
    }

    function editAddress () {
      ctrl.edit_address = true;
      editingForm();
    }

    function editingForm () {
      $rootScope.billing_form_unsaved = true;
      $rootScope.$broadcast('billing-address-edit');
    }

    function savingForm () {
      ctrl.billing_address = angular.copy(ctrl.address_data);
      ctrl.use_shipping_address = checkIfAddressesMatch();
      setShowSuiteField();
      $rootScope.billing_form_unsaved = false;
      $rootScope.$broadcast('billing-address-saved');
    }

    function saveAddress () {
      if(ctrl.use_shipping_address){
        ctrl.billing_address = ctrl.shipping_address;
      } else {
        var valid_form = formService.checkRequiredFields(ctrl.address_form);
        if(!valid_form)
          return;
      }
      var valid_address = isBillingAddressValid(ctrl.billing_address);
      if (!valid_address) {
        return;
      }
      ctrl.saving = true;
      return cartService.updateBillingAddress(ctrl.billing_address)
      .then(function(cart_response){
        ctrl.edit_address = false;
        getUpdatedCartData(cart_response);
        savingForm();
      })
      .catch(errorService.uiErrorHandler)
      .finally(function(){
        ctrl.saving = false;
      });
    }

    function cancelEdit () {
      ctrl.edit_address = false;
      savingForm();
    }

    /** 
     * Determines if the relevant fields of current billing and shipping addresses match. 
     * 
     * @return {Boolean} 
    */
    function checkIfAddressesMatch() {
      const props = ['address1', 'address2', 'city', 'country.id', 'postal_code', 'province.id']; // relevant fields
      return addressService.checkIfAddressesMatch(ctrl.address_data, ctrl.shipping_address, props)
    }

    
    /**
     * Displays the "suite number" field of the form if a suite number is included
     * in the address or if the user clicks the "add suite number" button.
     *
     * @param {Boolean} button_clicked passed when the user clicks the button
     *
     * @return {*}
    */
    function setShowSuiteField(button_clicked) {
      if (button_clicked) {
        ctrl.show_suite_field = true;
      } else {
        ctrl.show_suite_field = _.get(ctrl, 'billing_address.address2') ? true : false;
      }
    }

  }

})();

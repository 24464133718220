angular.module("app.shared.support")

  //Simple template that has a header which opens and closes a transcluded content area. (Can be used to build an accordian)
  .directive('expandable', function ($timeout, $parse) {
    return {
      restrict: 'AE',
      transclude: true,
      scope: {
        'title': '@',
        'open': '=isOpen'
      },
      templateUrl: 'templates/shared/support/expandable.html',
      link: function (scope, element, attrs) {
        var model = $parse(attrs.open);
      }

    };
  });
angular
  .module('app.marketplace.ui.inventory')
  .controller('addInventoryItemController', addInventoryItemController);

function addInventoryItemController($scope, $rootScope, $filter, sgToast, $mdDialog,
  $timeout, $state, inventoryItemService, errorService, StepsService, productName) {

  $scope.itemClone = {name: productName};
  $scope.steps = new StepsService({
    current: productName ? 2 : 1,
    total: 4
  });

  ////////

  $scope.createNew = function (item) {
    var new_item = item;

    $scope.itemClone = {
      name: item.name,
      upc: item.upc,
      image: item.image,
      user_category_id: _.get(new_item, 'category.id'),
    };

    if (isNotBlankManufacturer(item)) {
      $scope.itemClone.manufacturer = angular.copy(item.manufacturer);
    }

    $scope.steps.next();
  };

  $scope.closeModal = function(){
    $mdDialog.cancel();
  };

  function newSlideout (item) {
    $rootScope.$broadcast('inventory-item-slideout', null, item.id);
  }

  $scope.saveItem = function(){
    $scope.submitting = true;

    $scope.itemClone.manufacturer_name = $scope.itemClone.manufacturer_name || $scope.itemClone.manufacturerSearchText;
    $scope.itemClone.supplier = $scope.itemClone.supplier ? $scope.itemClone.supplier.name : $scope.itemClone.supplierSearchText;

    return inventoryItemService.createItem($scope.itemClone)
      .then(function(inventory_item){
        $scope.submitting = false;
        var t_message = $filter('translate')('TOAST.INVENTORY_ITEM_CREATED');
        sgToast.showSimple(t_message);
        // alertsDataCarrierService.load_alerts();
        showScanAndLink(inventory_item.id);
        newSlideout(inventory_item);
      })
      .catch(function(error) {
        $scope.submitting = false;
        var t_message = $filter('translate')('ERRORS.INVENTORY_UPDATE')
        errorService.uiErrorHandler(error || t_message, 0);
      });
  };

  // This method is to supprt a hack in our system where there was a 'blank'
  // manufacturer in production.
  function isNotBlankManufacturer(item) {
    var blankManufacuterId = 'ab4aff28-53be-4409-a096-5bd9c2540ba2';
    var manufacturerId = _.get(item, 'manufacturer.id');
    var manufacturerName = _.get(item, 'manufacturer.name');

    return manufacturerId !== blankManufacuterId && manufacturerName;
  }

  function showScanAndLink(id) {
    $mdDialog.show({
      template: '<inventory-scan-link context="product"></inventory-scan-link>',
      parent: angular.element(document.body),
      clickOutsideToClose:true
    }).finally(function() {
      $state.go('app.inventory.all', {itemId: id});
    });
  }
}

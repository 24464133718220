(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.inventory')
    .directive('inventoryItemOnHandLocs', InventoryItemOnHandLocs);

  function InventoryItemOnHandLocs () {
    return {
      restrict: 'E',
      replace: true,
      templateUrl: 'templates/marketplace/inventory/inventory-item-on-hand-locs.html'
    };
  }

}());


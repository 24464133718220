angular.module("app.marketplace.elements")

.service("cartService",
  function cartService($rootScope, $log, $filter, $q, sgToast, sessionService, elementService, inventoryItemService, errorService, appEvents, serverAPI, apiUrl, sowAnalyticsService) {
    var _this = this;
    this.cart = null;

    //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    // Service variable functions
    var _setCart = function(cart){
      _this.cart = cart;
      $rootScope.current_cart = cart;
      // _this._checkCart(); Removed to avoid call at beginning
      $rootScope.$broadcast('cartService: set-cart', cart);

    };

    var _clearCart = function(){
      _this.cart = null;
      $rootScope.current_cart = null;
      $rootScope.$broadcast('cartService: clear-cart');
    };

    var _getCart = function() {
      return  _this.cart;
    };



    //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    //Cart update/check/refresh functions

    // Calculate Free Shipping Remainder for a Vendor Group
    var _calcFreeShippingRemainder = function (group) {

    };
    //Update the current cart with the new cart data.
    var _updateCart = function(cart){
      if(!cart){
        throw "No cart to update";
      }
      if(!cart.params){
        cart = elementService.create('cart', cart);
      }

      _setCart(cart);
      _this._checkCart();
    };

    //Retrieves full cart from backend
    var _refreshCart = function(){
      if ($rootScope.current_account) {
        elementService.get('cart', null, {
          endpoint: 'current',
          return_type: 'single'
        }).then(function(cart){
          _updateCart(cart);
        });
      } else {
        _clearCart();
      }
    };

    // Check cart for missing fields. This fn is required due to possible
    // circular dependencies
    _this._checkCart = function(){
      _setShippingAlerts();
      // Required for final checkout. Ugly but best here because this is run for
      // every cart retrieval.
    };
    //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    // Update functions

    var _submitCartItem = function(item){
      var errorMsg = !sessionService.isActive() ? 'Please create an account or login first.'
            : !item ? 'No item specified.'
            : null;
      if (errorMsg) {
        return $q.reject(errorMsg);
      }

      var endpoint = item.id ? 'update_cart_item' : 'create_cart_item';

      $rootScope.$broadcast('cartService:begin-action:item-change');
      return elementService.callEndpoint('cart', {'endpoint': endpoint, 'item':item, 'doUpdate' : true})
      .then(function(response) {
        // _this._checkCart();
        _refreshCart();
        return _.first(_.values(response));
      })
      .catch(function(error) {
        _refreshCart();
        throw error;
      })
      .finally(function(){
        $rootScope.$broadcast('cartService:end-action:item-change');
      });
    };
    var _addCartItem = function (product_id, quantity, vendor_id) {
      if (!sessionService.isActive()) {
        return $q.reject('Please create an account or sign in first.');
      } else if (!quantity) {
        return $q.reject('No quantity specified.');
      }

      var item = {
        product_id: product_id,
        quantity  : quantity,
        vendor_id : vendor_id
      };

      const cart = _getCart();
      const cart_items = cart.items ?? [];
      const current_item_in_cart = cart_items.find(item => item.product_id === product_id);
      if (current_item_in_cart) {
        const max_quantity_limit = 999;
        const total_quantity = current_item_in_cart.quantity + quantity;
        if (total_quantity > max_quantity_limit) {
          const message =  $filter('translate')('ERRORS.VALUE_LESS_THAN', {value: max_quantity_limit, field_name: 'Quantity'});
          return $q.reject(message);
        }
      }

      return _submitCartItem(item)
      .then(function (item_response) {
        $rootScope.$emit('cartService: itemAddedToCart', {'product': item_response, 'quantity': quantity});
        const t_added_item = getItemAddedText(quantity);
        return $q.when(t_added_item);
      })
      .catch(function (error) {
        return $q.reject(error);
      });
    };
    //Convenience method
    var _updateCartItem = function(item){
      return _submitCartItem(item);
    };

    //Convenience function for removing all items.
    var _removeCartItem = function(item){
      var errorMsg = !sessionService.isActive() ? 'Please create an account or login first.'
          : !item ? 'No item specified.'
          : null;
      if (errorMsg) {
        return $q.reject(errorMsg);
      }
      $rootScope.$broadcast('cartService:begin-action:item-change');
      return elementService.callEndpoint('cart', {'endpoint' : 'remove_cart_item', 'item': item, 'doUpdate' : true})
      .then(function(result) {
          sowAnalyticsService.logRemoveFromCart([{...item, ...item.product}]);
          return _this._checkCart();
      })
      .catch(function(error) {
          _refreshCart();
          throw "Failed to update item(s)";
      })
      .finally(function(){
        $rootScope.$broadcast('cartService:end-action:item-change');
      });
    };
    //Convenience function for removing all items.
    var _removeAllItems = function(items){
      return _.map(items, _removeCartItem);
    };

    var _updateShipping = function(shipping_info){
      return elementService.callEndpoint('cart', {'endpoint' : 'update_shipping', 'shipping_info' : shipping_info, 'doUpdate' : true})
      .then(function(){
        _this._checkCart();
      });
    };

    // hits the API endpoint for /me/cart/billing, 
    // with a POST sending the existing card data (provided that card exists and has an id property)
    // or with a DELETE method if there is no card
    var _updateBilling = function(card){
      const endpoint = card?.id ? 'update_billing' : 'remove_billing';
      return elementService.callEndpoint('cart', {'endpoint' : endpoint, 'card' : card, 'doUpdate' : true})
      .then(function(){
        _this._checkCart();
      });
    };

    function _callAPI (url, params) {
      return serverAPI
        .doAPICall(url, params)
        .then(function (response) {
          return response.data;
        });
    }

    var _updateBillingAddress = function(address_data){
      var url = '{0}/me/cart/cardless_billing_address'.format(apiUrl);
      var params = {'method': 'post', 'data':{'cardless_billing_address': address_data}};
      return _callAPI(url, params);

      // return elementService.callEndpoint('cart', {'endpoint' : 'cardless_billing_address', 'doUpdate' : true})
      // .then(function(){
      //   _this._checkCart();
      // });
    };
    var _updatePromoCode = function(promo_code){
      return elementService.callEndpoint('cart', {'endpoint' : 'update_promo_code', 'promo_code' : promo_code, 'doUpdate' : true})
      .then(function(response){
        _this._checkCart();
        return response;
      });
    };
    var _removePromoCode = function(promo_code){
      return elementService.callEndpoint('cart', {'endpoint' : 'remove_promo_code', 'doUpdate' : true})
      .then(function(response){
        _this._checkCart();
        return response;
      });
    };
    var _setShippingAlerts = function setShippingAlerts () {
      cart=_getCart();
      _.forEach(cart.vendor_groups, function(vendor){
        // set alerts
        _.set(vendor, 'shipping_alerts', {});
        var free_tier = ((_.get(vendor, 'free_shipping_possible', false)).toLowerCase().trim() === 'true');
        var free_shipping = _.get(vendor, 'shipping_rate.free_shipping', 0);
        free_shipping = free_shipping ? free_shipping : 0;
        if (free_tier) {
          if ((parseFloat(vendor.items_subtotal) > parseFloat(free_shipping)) && (vendor.shipping_subtotal|0 === 0) ) {
            _.set(vendor, 'shipping_alerts.free', true);
          } else {
            var remaining = (free_shipping - vendor.items_subtotal);
            if (remaining > 0) {
              _.set(vendor, 'shipping_alerts.spend_more', true);
              _.set(vendor, 'shipping_alerts.remaining', remaining);
            } else {
              _.set(vendor, 'shipping_alerts.free', true);
            }
          }
        } else if ((vendor.shipping_subtotal*1) === 0) {
          _.set(vendor, 'shipping_alerts.free', true);
        }
      });
    };

    //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    // Checkout function

    // Complete and checkout cart
    var _completeCart = function(cart){
      var productIds = _.map(cart.items, function (item) {
        return _.get(item, 'product.id') || _.get(item, 'product_id');
      });

      return elementService.callEndpoint('cart', {
        endpoint: 'complete_cart',
        cart: cart,
        doUpdate : true,
        return_type : 'single'
      }).then(function(cart) {
        try {
          _.forEach(elementService.elementMaps.inventoryItem, function (inventoryItem) {
            if (inventoryItem.product_id && _.includes(productIds, inventoryItem.product_id)) {
              inventoryItemService.getItem(inventoryItem.id, {'force': true}).then(null, function (error) {
                $log.error('Error Updating Inventory Item: %o', error);
              });
            }
          });
        }
        catch (error) {
          // We want to ignore the error here because the user doesn't need to see this.
          $log.error('Error Updating Inventory Item: %o', error);
        }

        $rootScope.$emit('cartService: checkoutCompleted', cart);
        _setCart(cart);
      }).catch(function (error) {
        throw error;
      });
    };

    /**
     * Calls the endpoint to complete the vendor group checkout
     * @param {object} group
     * @return {Promise<object>} The cart object
     */
    function _completeGroup (group){
      const product_ids = _.map(group.items, function (item) {
        return _.get(item, 'product.id') || _.get(item, 'product_id');
      });

      return elementService.callEndpoint('cart', {
        endpoint: 'complete_vendor_group',
        group,
        doUpdate: true,
        return_type: 'single'
      }).then(function(cart) {
        _.forEach(elementService.elementMaps.inventoryItem, function (inventoryItem) {
          if (inventoryItem.product_id && _.includes(product_ids, inventoryItem.product_id)) {
            inventoryItemService.getItem(inventoryItem.id, {'force': true}).then(null, function (error) {
              $log.error('Error Updating Inventory Item: %o', error);
            });
          }
        });
        $rootScope.$emit('cartService: checkoutCompleted', cart);
        _setCart(cart);
        return cart;
      }).catch(function (error) {
        throw error;
      });
    };

    //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    // Initialization function
    var _init = function(){
      $rootScope.$on('membershipService: set-membership', function(event, membership){
        if (membership.cart) {
          _updateCart(membership.cart);
        } else {
          _refreshCart();
        }
      });

      $rootScope.$on(appEvents.clearSession, function () {
        _clearCart();
      });

      //
      // Catch Events From Elsewhere to Add to Cart.
      //
      $rootScope.$on('cartService: add-to-cart', function ($event, product, vendor, quantity, successMessageOverride) {
        _addCartItem(product.id, quantity, vendor.id).then(function (message) {
          sgToast.showSimple(successMessageOverride || message);
        }).catch(function (errorMessage) {
          errorService.uiErrorHandler(errorMessage, 0);
        });
      });
    };

    /**
     * Generates localized text which reflects the quantity added to the cart
     * @param {number} quantity
     * @return {string}
     */
    function getItemAddedText(quantity) {
      const t_added_item = $filter('translate')('MARKETPLACE.CART.ADDED_ITEM', {quantity});
      return t_added_item;
    }

    /**
     * Loads information about the cart or cart vendor group
     * which matches the ID it receives
     * @param {object} params
     * @return {Promise<object>} {
     *    total_savings: `${number}`,
     *  }
     */
    async function getCartStats({cart_id, cart_vendor_group_id} = {}) {
      const {data} = await elementService.callEndpoint('cart', {
        data: {
          cart_id,
          cart_vendor_group_id,
        },
        endpoint: 'cart_stats',
      });
      return data;
    }

    return {
      _init : _init,

      updateCart : _updateCart,
      refreshCart : _refreshCart,
      checkCart: _this._checkCart,

      addToCart : _addCartItem,
      updateCartItem : _updateCartItem,
      removeCartItem : _removeCartItem,
      removeAllItems : _removeAllItems,

      completeCart : _completeCart,
      completeGroup : _completeGroup,

      updateShipping : _updateShipping,
      updateBilling : _updateBilling,
      updateBillingAddress : _updateBillingAddress,
      updatePromoCode : _updatePromoCode,
      removePromoCode : _removePromoCode,
      setShippingAlerts: _setShippingAlerts,

      getItemAddedText,
      getCartStats,

      get : _getCart

    };
  }
);

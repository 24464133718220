(function () {
  'use strict';

  angular.module('sowOrders')
  .controller('orderItemController', orderItemController)
  .directive('sowOrderItem', orderItemDirective);

  function orderItemDirective () {
    return {
      restrict: 'E',
      templateUrl: 'sow-orders/directives/sow-order-item.html',
      controller: 'orderItemController',
      controllerAs: 'oiCtrl',
      scope: {
        'item': '=',
        'status': '@'
      },
    };
  }

  function orderItemController ($filter, mktHelperService, sowAnalyticsService, AccessService) {
    /*jshint validthis:true*/
    var ctrl = this;

    ctrl.buyAgain = buyAgain;
    ctrl.getFormattedPrice = getFormattedPrice;
  
    init();

    return ctrl;

    function init () {
      defineLocks();
    }

    /**
     * It takes an item, checks if it has a promotion price or an original price
     * @param {object} item
     * @param {string} status status from parent
     * @returns {string}
     */
    function getFormattedPrice(item, status) {
      const price = mktHelperService.getActivePrice(item);
      const formatted_price = $filter('currency')(price, '$', 2);
      const prefix = mktHelperService.addPricePrefix(status);
      return `${prefix}${formatted_price}`;
    }

    function buyAgain (item, $event) {
      if($event) $event.stopPropagation();
      if (ctrl.hideCartButton) return;
      sowAnalyticsService.logBuyAgainBtnClicked(item);
      mktHelperService.productDetailsFetch(item.product.id);
    }

    function defineLocks () {
      ctrl.hideCartButton = !AccessService.hasCartAccess();
    }
    
  }

})();

(function(){
  'use strict';

  angular
    .module('app.marketplace.ui.purchaseorders')
    .controller('poReceiveDialogController', poReceiveDialogController)
    .directive('sowSlideoutPoReceive', sowSlideoutPoReceive);

  function poReceiveDialogController ($scope, $mdDialog, poHelperService) {
    $scope.openCalendar = poHelperService.openCalendar;

    $scope.hide = function() {
      $mdDialog.hide();
    };

    $scope.cancel = function() {
      $mdDialog.cancel();
    };

    $scope.answer = function(answer) {
      $mdDialog.hide(answer);
    };
  }

  function sowSlideoutPoReceive () {
    return {
      replace: true,
      restrict: 'E',
      templateUrl: 'templates/marketplace/purchase-orders/directives/po-receive-slideout.html',
      link: function ($scope, $element, $attrs) {
        
      }
    };
  }

}());
angular.module('app.marketplace.ui.office').controller('NewOfficeFormCtrl',
  function NewOfficeFormCtrl ($scope, $filter, $mdDialog, AccountModalService, errorService, elementService) {
    var _this = this;

    //
    // Initialize $scope and controller
    //
    _this.init = function () {
      $scope.new_office = {address: {}};

      // FIXME This needs to come from MedicalField now
      // $scope.officeTypes = OfficeTypes;

      $scope.flags = {
        submitting: false
      };

      $scope.actions = {
        submit: _this.submit,
        cancel: _this.cancel
      };
    };

    //
    // Cancel the Form
    //
    // If we cancel here, we just want to revert back to the 
    _this.cancel = function () {
      AccountModalService.show('SelectMembership');
    };

    //
    // Submit the Form
    //
    _this.submit = function ($event) {
      if ($event) {
        $event.preventDefault();
        $event.stopPropagation();
      }

      if (!$scope.NewOfficeForm.$valid) {
        return;
      }

      $scope.flags.submitting = true;
      
      elementService.submit('office', {
        endpoint: 'create',
        element: $scope.new_office
      }).then(function (office) {
        // First (and only) membership should be this user's membership. :^)
        $mdDialog.hide(office.memberships ? office.memberships[0] : true);
      }).catch(function (error) {
        var t_message = $filter('translate')('ERRORS.OFFICE_CREATE');
        errorService.uiErrorHandler(error || t_message, 0);
      })['finally'](function () {
        $scope.flags.submitting = false;
      });
    };

    _this.init();
  }
);

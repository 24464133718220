angular.module('app.marketplace.ui.orders').directive('recentOrdersBlock', function() {
  return {
    restrict: 'E',
    controller: 'recentOrdersController',
    controllerAs: 'ctrl',
    templateUrl: 'templates/marketplace/orders/directives/recent-orders-block.html',
    scope: {
      'allOrders': '=',
      'loading': '=',
      'title': '@',
      'viewAllState': '@',
    }
  };
});

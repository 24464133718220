(function () {
  'use strict';

  const sowMktVendorCheckout = {
    selector: 'sowMktVendorCheckout',
    templateUrl: 'sow-mkt/directives/mkt-checkout-2023.html',
    controller: 'marketplaceVendorCheckoutController',
    controllerAs: 'mktvcCtrl',
    bindings: {
      confirmationRequired: '=',
      showConfirmationForm: '=',
      currentStep: '<',
      stepCount: '<',
    },
  };

  angular.module('sowMarketplace')
    .controller(sowMktVendorCheckout.controller, marketplaceVendorCheckoutController)
    .component(sowMktVendorCheckout.selector, sowMktVendorCheckout);

  /** @ngInject */
  function marketplaceVendorCheckoutController ($scope, $rootScope, errorService, cartService, sgToast, $filter, EVActionsHelperService, sowExternalVendorAccountService, mktHelperService, msHelperService) {
    /*jshint validthis:true*/
    const ctrl = this;

    ctrl.action_disabled = false;
    ctrl.cart_limit = null;
    ctrl.is_fetching_external_vendor_prices = false;

    const MOBILE_BREAKPOINT = 600;

    const width = angular.element(document).find('appContainer').offsetWidth;
    // By default, hide promo code section on mobile (toggleable)
    ctrl.hide_promo_code_section = width < MOBILE_BREAKPOINT;
    ctrl.is_promocode_declined = false;
    ctrl.is_promocode_accepted = false;
    ctrl.ev_total_savings = null;
    ctrl.alert = {};

    $scope.applyPromoCode = ctrl.applyPromoCode = applyPromoCode;
    $scope.removePromoCode = ctrl.removePromoCode = removePromoCode;
    $scope.resetPromoCodeValidation = ctrl.resetPromoCodeValidation = resetPromoCodeValidation;
    ctrl.completeOrder = completeOrder;
    ctrl.getAppliedPromoValue = getAppliedPromoValue;
    ctrl.hidePromoCodeAlert = hidePromoCodeAlert;
    ctrl.showPromoCodeSection = showPromoCodeSection;
    ctrl.shouldShowPromoForm = shouldShowPromoForm;
    
    ctrl.$onInit = init;

    return ctrl;

    function init () {
      _setInitialState();
      $scope.$on('cartService:begin-action:item-change', disableAction);
      $scope.$on('cartService:end-action:item-change', evaluateActionPermission);
      $scope.$on('order-form-changed', evaluateActionPermission);
      $scope.$on('mkt-shipping-address-updated', evaluateActionPermission);
      $scope.$on('billing-address-edit', evaluateActionPermission);
      $scope.$on('billing-address-saved', evaluateActionPermission);
      $scope.$on('cartService: set-cart', evaluateActionPermission);
      $scope.$on('cartService: clear-cart', evaluateActionPermission);
      $scope.$on('cartService: itemAddedToCart', evaluateActionPermission);
      $scope.$on('width-updated', function(event, props) {
        if (props.new >= MOBILE_BREAKPOINT) { // If the new window width is wider than a mobile screen...
          ctrl.hide_promo_code_section = false; // ...we need to display the promo code section
        }
      });
    }

    /**
     * Shows the promo code section if the vendor_actions UI_flags has the promo_code flag set to true or if the vendor
     * is not an external vendor (official/internal vendor)
     * @return {boolean}
     */
    function shouldShowPromoForm () {
      // Case 1: If the current step does not exist or is not an external vendor, show the promo code form
      if (!ctrl.currentStep || !ctrl.currentStep.is_external) return true;

      // Case 2: Check if the vendor has promo code enabled
      return Boolean(ctrl.currentStep.vendor_actions?.UI_flags?.promo_code);
    }

    /**
     * Disable checkout until we know all items are valid.
     */
    function _setInitialState() {
      // disable order completion until cart can be evaluated
      disableAction();
      evaluateActionPermission();
    }

    /**
     * Disables checkout if we're loading external vendor prices or if the cart contains
     * one or more items for whom we were unable to fetch an updated price. Otherwise,
     * enables checkout.
     */
    function evaluateActionPermission () {
      updateCart();
      enableAction();
    }

    function enableAction () {
      $rootScope.checkout_action_disabled = false;
      ctrl.action_disabled = false;
    }

    function disableAction () {
      $rootScope.checkout_action_disabled = true;
      ctrl.action_disabled = true;
    }

    function updateCart() {
      ctrl.cart = cartService.get();
      ctrl.cartIsEmpty = !checkNested(ctrl, 'cart', 'items', 'length') || ctrl.cart.items.length < 1;
      if (_.get(ctrl, 'cart.promo_code.id')) { // If a code has been entered...
        const code_from_session = _getCode(ctrl.cart?.promo_code);
        ctrl.promo_code_input = code_from_session;
        if (_.get(ctrl, 'cart.promo_code_applied')) { // ...check if it's been applied...
          ctrl.hide_promo_code_section = false; // ...and toggle open the promo code section if so.
          // Then set an alert with the success message
          _promoCodeApplied([code_from_session]);
        } else {
          // otherwise show the error alert
          _promoCodeError();
        }
      }
    }

    function removePromoCode () {
      if (!ctrl.cart.promo_code) {
        return;
      }
      ctrl.saving = true;
      disableAction();

      cartService.removePromoCode()
      .then(function (response) {
        updateCart();
        const t_message = $filter('translate')('TOAST.PROMO_CODE_REMOVED');
        sgToast.showSimple(t_message);
        resetPromoCodeValidation();
        $scope.PromoCodeForm.$setPristine();
        // removes any alert we may have set
        _setAlert([],[]);
      })
      .catch(function (error) {
        const t_message = $filter('translate')('ERRORS.PROMO_REMOVE')
        errorService.uiErrorHandler(t_message, 0);
      })
      .finally(function(){
        ctrl.saving = false;
        enableAction();
      });
    }

    function applyPromoCode () {
      if (!ctrl.promo_code_input) {
        return false;
      }
      ctrl.is_promocode_accepted = false;
      ctrl.is_promocode_declined = false;
      $scope.PromoCodeForm?.promo_code.$setValidity('api_error', true);
      ctrl.promo_code_loading = true;

      if (ctrl.currentStep?.is_external) {
        _applyExternalVendorPromoCode();
      } else {
        cartService
          .updatePromoCode(ctrl.promo_code_input)
          .then((cart_response) => {
            _promoCodeApplied([ctrl.promo_code_input]);
          })
          .catch((error) => {
            _promoCodeError(error);
          })
          .finally(_enablePromoCodeBtn);
      }
    }
    
    function _promoCodeApplied(accepted_codes_list) {
      const codes_as_text = accepted_codes_list.join(', ');
      
      $scope.PromoCodeForm?.$setPristine();
      ctrl.is_promocode_accepted = true;
      ctrl.promo_codes_applied = codes_as_text;
      _setAlert(accepted_codes_list, []);
    }
    
    function _promoCodeError(error) {
      // show validation error only if ALL codes were declined
      if (!ctrl.is_promocode_accepted && ctrl.is_promocode_declined) {
        $scope.PromoCodeForm.promo_code.$setValidity('api_error', false);
      }

      $scope.promoCodeErrorMsg = error?.message;
      ctrl.is_promocode_declined = true;
      _setAlert([], [ctrl.promo_code_input], error?.message);
    }

    /**
     * The function sets an alert message based on the accepted and declined promo codes.
     * @param {string[]} accepted_codes_list - A list of promo codes that were successfully accepted.
     * @param {string[]} declined_codes_list - A list of promo codes that were declined during checkout.
     * @param {string} error_message - An optional error message to display in the alert body.
     */
    function _setAlert (accepted_codes_list, declined_codes_list, error_message = null) {
      if (_.size(declined_codes_list)) {
        // show error alert for declined codes, accepted codes on summary
        const locale = _getPluralizedLocale('MARKETPLACE.CHECKOUT.PROMO_CODE_DECLINED', declined_codes_list.length);
        const codes_as_text = declined_codes_list.join(', ');
        const t_message_declined = $filter('translate')(locale, {codes: codes_as_text});
        ctrl.alert = {
          show: true,
          type: 'critical',
          header: t_message_declined.toString(),
          body: error_message,
        };
        ctrl.codes_declined = declined_codes_list.join(',');
        ctrl.is_promocode_declined = true;
      } else if (_.size(accepted_codes_list)) {
        // show success alert for accepted codes, as well as on summary
        const locale = _getPluralizedLocale('MARKETPLACE.CHECKOUT.PROMO_CODE_APPLIED', accepted_codes_list.length);
        const codes_as_text = accepted_codes_list.join(', ');
        const t_message_applied = $filter('translate')(locale, {codes: codes_as_text});
        ctrl.alert = {
          show: true,
          type: 'success',
          header: t_message_applied.toString(),
          body: null,
        };
      } else {
        ctrl.alert = {
          show: false,
        };
        ctrl.is_promocode_accepted = false;
        ctrl.is_promocode_declined = false;
      }
      resetPromoCodeValidation();
      _clearPromoCodeInput();
    }
    
    function _enablePromoCodeBtn() {
      ctrl.promo_code_loading = false;
    }

    function _applyExternalVendorPromoCode () {
      $scope.$emit('Marketplace: EV promo code', {
        isLoading: true,
      });
      ctrl.is_promocode_declined = false;
      ctrl.is_promocode_accepted = false;
      ctrl.ev_total_savings = null;
      
      const vendor_actions = ctrl.currentStep.vendor_actions;
      const promo_code_action = EVActionsHelperService.getActionInfo(vendor_actions, EVActionsHelperService.ACTIONS.ORDER_REVIEW);
      if (promo_code_action) {
        const payload = {
          promo_code: mktHelperService.parseCodesToValue(ctrl.promo_code_input),
          monolith_cart_id: ctrl.cart.id,
          po_number: _.find(ctrl.currentStep.additional_details, {name: 'po_number'})?.value,
          special_instructions: _.find(ctrl.currentStep.additional_details, {name: 'special_instructions'})?.value,
          shipping_method: ctrl.currentStep.ShippingMethods_selected,
          billing_method: ctrl.currentStep.BillingMethods_selected,
        };

        const codes_sent = _.split(payload.promo_code, ',');
        
        let codes_received, codes_declined;
        sowExternalVendorAccountService
          .callAction(promo_code_action, payload)
          .then((order_review_response) => {
            const ev_data = _.first(order_review_response)?.data;

            codes_received = msHelperService.getDataValue(ev_data?.CartPromoCodes);
            
            const promo_savings = msHelperService.getDataValue(ev_data?.CartTotalSaving);
            ctrl.ev_total_savings = promo_savings || 0;

            codes_declined = _.difference(codes_sent, codes_received);
            if (codes_declined.length > 0) {
              ctrl.codes_declined = codes_declined.join(',');
              ctrl.is_promocode_declined = true;
            }

            $scope.$emit('Marketplace: EV promo code', {
              isLoading: false,
              response: order_review_response,
            });
            _promoCodeApplied(codes_received);
          })
          .catch((error) => {
            errorService.uiErrorHandler(error);
            resetPromoCodeValidation();
            _clearPromoCodeInput();
          })
          .finally(() => {
            _setAlert(codes_received, codes_declined);
            // reset promo code field
            resetPromoCodeValidation();
            _enablePromoCodeBtn();
            $scope.$emit('Marketplace: EV promo code', {
              isLoading: false,
            });
          });
      } else {
        $scope.$emit('Marketplace: EV promo code', {
          isLoading: false,
        });
        _enablePromoCodeBtn();
      }
    }

    function resetPromoCodeValidation () {
      $scope.PromoCodeForm?.promo_code.$setValidity('api_error', true);
      $scope.promoCodeErrorMsg = null;
    }

    function _clearPromoCodeInput () {
      ctrl.promo_code_input = null;
    }

    function completeOrder ($event) {
      // In order to keep the logic consistent in all controllers,
      // I've chosen to delegate this to the parent "MarketplaceOrderController" instead
      // Note: this will only work as long as the parent-child relation is kept.
      // That is: if <sow-mkt-checkout> is nested inside the order page
      $scope.$emit('Marketplace:complete-order');
    }
    
    function getAppliedPromoValue () {
      let value; 
      if (ctrl.currentStep?.is_external) {
        value = ctrl.ev_total_savings;
      } else {
        value = _.get(ctrl.cart, 'promo_code_value_applied', 0);
      }
      let text = $filter('currency')(value,'$',2);
      if (value > 0) {
        text = "- "+text;
      }
      return text;
    }

    function hidePromoCodeAlert() {
      ctrl.alert.show = false;
    }

    function showPromoCodeSection() {
      ctrl.hide_promo_code_section = false;
    }

    /**
     * Returns a promotional code from a given object, or null if none is found.
     * @param {object} promo_object - An object that contains information about a promotional code. 
     * It may have a `code` property or a nested `sowingo_promo_code` object with a `code` property.
     * @return {string} the code property of the promo_object if it exists, otherwise it returns the code
     * property of the sowingo_promo_code object if it exists, and if neither exist, it returns null.
     */
    function _getCode (promo_object) {
      return promo_object?.code || promo_object?.sowingo_promo_code?.code || null;
    }


    /**
     * The function returns a pluralized locale based on a count.
     * @param {string} locale - The locale you want to pluralize.
     * @param count - A number used to determine whether the locale should be pluralized. 
     * If the count is equal to 1, the locale will be suffixed with "_one",
     * otherwise it will be suffixed with "_other".
     * @returns a string that is the concatenation of the `locale` parameter and either `_one` or
     * `_other` depending on whether the `count` parameter is equal to 1 or not.
     */
    function _getPluralizedLocale (locale, count) {
      return locale + (count === 1 ? '_one' : '_other');
    }

  }

})();

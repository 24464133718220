(function () {
  'use strict';

  angular
    .module('sowAssociations')
    .service('sowAssociationService', sowAssociationService);

  function sowAssociationService (serverAPI, apiUrl) {
    /*jshint validthis: true */
    var service = this;

    service.getAssociations = getAssociations;
    service.validateAAOMS = validateAAOMS;
    service.validateAAP = validateAAP;
    service.validateBCDA = validateBCDA;
    service.validateNBDS = validateNBDS;
    service.validateDAPEI = validateDAPEI;
    service.validateRCDSO = validateRCDSO;
    service.validateDAO = validateDAO;
    service.validateADTO = validateADTO;
    service.validateACDQ = validateACDQ;
    service.validateDentsply = validateDentsply;
    service.validateMDS = validateMDS;
    service.validateNeodent = validateNeodent;
    service.validatePatterson = validatePatterson;
    service.validateAny = validateAny;

    return service;

    function getAssociations (options) {
      var url = '{0}/associations/data'.format(apiUrl);
      var params = {
        'method': 'GET',
        'params': _.pick(options, ['medical_field_id','association_id', 'province_id'])
      };

      return serverAPI
        .doAPICall(url, params)
        .then(function (response) {
          return response.data;
        });
    }

    function validateAny (id, data) {
      var url = '{0}/associations/verify'.format(apiUrl);
      var params = {
        'method': 'POST',
        'data': {
          'association_id': id,
          'args': data
        }
      };

      return serverAPI
        .doAPICall(url, params)
        .then(function (response) {
          return response.data;
        });
    }

    function validateAAOMS (aaomsID, lastName) {
      var url = '{0}/associations/verify'.format(apiUrl);
      var params = {
        'method': 'POST',
        'data': {
          'association_id': 'aaoms',
          'args': {
            'aaoms_id': aaomsID,
            'last_name': lastName,
          }
        }
      };

      return serverAPI
        .doAPICall(url, params)
        .then(function (response) {
          return response.data;
        });
    }

    function validateNeodent (accessCode) {
      var url = '{0}/associations/verify'.format(apiUrl);
      var params = {
        'method': 'POST',
        'data': {
          'association_id': 'neodent',
          'args': {'access_code': accessCode}
        }
      };

      return serverAPI
        .doAPICall(url, params)
        .then(function (response) {
          return response.data;
        });
    }

    function validateDentsply (accessCode) {
      var url = '{0}/associations/verify'.format(apiUrl);
      var params = {
        'method': 'POST',
        'data': {
          'association_id': 'dentsply',
          'args': {'access_code': accessCode}
        }
      };

      return serverAPI
        .doAPICall(url, params)
        .then(function (response) {
          return response.data;
        });
    }

    function validatePatterson (accessCode) {
      var url = '{0}/associations/verify'.format(apiUrl);
      var params = {
        'method': 'POST',
        'data': {
          'association_id': 'patterson',
          'args': {'access_code': accessCode}
        }
      };

      return serverAPI
        .doAPICall(url, params)
        .then(function (response) {
          return response.data;
        });
    }

    function validateAAP (last_name) {
      var url = '{0}/associations/verify'.format(apiUrl);
      var params = {
        'method': 'POST',
        'data': {
          'association_id': 'aap',
          'args': {'last_name': last_name}
        }
      };

      return serverAPI
        .doAPICall(url, params)
        .then(function (response) {
          return response.data;
        });
    }

    function validateMDS (last_name) {
      var url = '{0}/associations/verify'.format(apiUrl);
      var params = {
        'method': 'POST',
        'data': {
          'association_id': 'mds',
          'args': {'last_name': last_name}
        }
      };

      return serverAPI
        .doAPICall(url, params)
        .then(function (response) {
          return response.data;
        });
    }

    function validateBCDA (bcdaID, lastName) {
      var url = '{0}/associations/verify'.format(apiUrl);
      var params = {
        'method': 'POST',
        'data': {
          'association_id': 'bcda',
          'args': {
            'bcda_id': bcdaID,
            'last_name': lastName,
          }
        }
      };

      return serverAPI
        .doAPICall(url, params)
        .then(function (response) {
          return response.data;
        });
    }

    function validateNBDS (nbdsID, lastName) {
      var url = '{0}/associations/verify'.format(apiUrl);
      var params = {
        'method': 'POST',
        'data': {
          'association_id': 'nbds',
          'args': {
            'nbds_id': nbdsID,
            'last_name': lastName,
          }
        }
      };

      return serverAPI
        .doAPICall(url, params)
        .then(function (response) {
          return response.data;
        });
    }

    function validateDAPEI (dapeiID, lastName) {
      var url = '{0}/associations/verify'.format(apiUrl);
      var params = {
        'method': 'POST',
        'data': {
          'association_id': 'dapei',
          'args': {
            'dapei_id': dapeiID,
            'last_name': lastName,
          }
        }
      };

      return serverAPI
        .doAPICall(url, params)
        .then(function (response) {
          return response.data;
        });
    }

    function validateRCDSO(rcdso_id, last_name) {
      const url = `${apiUrl}/associations/verify`;
      const params = {
        method: 'POST',
        data: {
          association_id: 'rcdso',
          args: {
            rcdso_id,
            last_name,
          }
        }
      };
      return serverAPI.doAPICall(url, params)
        .then(({ data }) => data);
    }

    function validateDAO(dao_id, last_name) {
      const url = `${apiUrl}/associations/verify`;
      const params = {
        method: 'POST',
        data: {
          association_id: 'dao',
          args: {
            dao_id,
            last_name,
          }
        }
      };
      return serverAPI.doAPICall(url, params)
        .then(({ data }) => data);
    }

    function validateADTO(adto_id, last_name) {
      const url = `${apiUrl}/associations/verify`;
      const params = {
        method: 'POST',
        data: {
          association_id: 'adto',
          args: {
            adto_id,
            last_name,
          }
        }
      };
      return serverAPI.doAPICall(url, params)
        .then(({ data }) => data);
    }

    function validateACDQ(acdq_id, last_name) {
      const url = `${apiUrl}/associations/verify`;
      const params = {
        method: 'POST',
        data: {
          association_id: 'acdq',
          args: {
            acdq_id,
            last_name,
          }
        }
      };
      return serverAPI.doAPICall(url, params)
        .then(({ data }) => data);
    }

  }
}());

(function () {
  'use strict';

  angular.module('sowOrders')
  .service('sowOrderService', sowOrderService);

  function sowOrderService (membershipService, serverAPI, apiUrl) {
    /*jshint validthis: true */
    var service = this;

    service.searchOrders = searchOrders;
    service.listItems = listItems;
    service.listOrders = listOrders;
    service.fetchSingleOrder = fetchSingleOrder;
    service.updateOrderFromEV = updateOrderFromEV;
    service.markAsReceived = markAsReceived;
    
    return service;

    function searchOrders (parameters) {
      var headers = {
        'page': parameters.page || 1,
        'page_size': parameters.page_size || 20
      };
      var body = {
        'query': parameters.query,
        'query_type': parameters.query_type || 'orders'
      };
      
      var options = {'method': 'POST', 'params': headers, 'data': body};

      return _getUrl('/search')
      .then(function(url){
        return _callAPI(url, options);
      });
    }

    function listItems (parameters) {
      var headers = {
        'page': parameters.page || 1,
        'page_size': parameters.page_size || 20,
        'order_item_status': parameters.order_item_status || null
        // sort_by (default: 'created_at', available: ['created_at', 'hrid', 'product_name'])
        // asc (default: false, available: [true, false])
        // order_item_status (default: none, available: ['backordered', 'processing', 'shipped', 'cancelled'])
      };
      
      var options = {'method': 'GET', 'params': headers};

      return _getUrl('/order_items')
      .then(function(url){
        return _callAPI(url, options);
      });
    }

    function listOrders (parameters) {
      var headers = {
        'page': parameters.page || 1,
        'page_size': parameters.page_size || 20,
        'order_status': parameters.order_status || null
        // sort_by (default: 'created_at', available: ['created_at', 'hrid', 'product_name'])
        // asc (default: false, available: [true, false])
        // order_status (default: none, available: ['open', 'completed'])
      };
      
      var options = {'method': 'GET', 'params': headers};
      var url = '{0}/me/orders'.format(apiUrl);
      return _callAPI(url, options);
      
    }

    function fetchSingleOrder (hrid, search_param) {
      var parameters = {
        // 'hrid': hrid
      };
      if(search_param) {
        parameters.search_param = search_param;
        // hrid = checkout_id;
      }
      var options = {'method': 'GET', 'params': parameters};
      var url = '{0}/orders/{1}'.format(apiUrl, hrid);
      return _callAPI(url, options);
    }


    /**
     * Takes an order response from the EV MicroService and updates the order items
     * in the Monolith API.
     * 
     * @param {ExternalVendorOrder} ms_order_response - The response from the EV MicroService API call to get order status.
     * @return {Promise<MarketplaceOrder>} The response from the Monolith API call.
     */
    function updateOrderFromEV (ms_order_response) {
      const order_body = {
        order_id: ms_order_response.id,
        order_items: ms_order_response?.data?.OrderItems,
      };
      const options = {'method': 'POST', 'data': order_body};
      return membershipService.onInit()
      .then(function(membership_data){
        const url = '{0}/vendor_integrations/{1}/order_item_update'.format(apiUrl, membership_data.office.id);

        return _callAPI(url, options);
      });
    }

    function _callAPI (url, params) {
      return serverAPI
        .doAPICall(url, params)
        .then(function (response) {
          return response.data;
        });
    }

    function _getUrl (path) {
      path = path || '';
      return membershipService.onInit()
      .then(function(membership_data){
        return '{0}/offices/{1}/orders{2}'.format(apiUrl, membership_data.office.id, path);
      });
    }

    function markAsReceived(order) {
      return _callAPI(
        `${apiUrl}/orders/manualReceive/${order.hrid}`,
        {
          data: {
            inventory_updates: order.UI.receivable_items.map(item => {
              const update_inventory = item.UI.update_inventory_option === 'computer';
              const parsed_item = {
                item_id: item.id,
                quantity_received: item.UI.quantity_receivable,
                update_inventory,
              };
              if (update_inventory && item.expiry_dates?.length) {
                parsed_item.expiry_dates = item.expiry_dates;
              }
              return parsed_item;
            }),
            received_on: order.UI.receiving_date,
          },
          method: 'POST',
        }
      );
    }
  }

}());

angular.module('app.marketplace.ui.office')

  .directive('actionBarOffice', function() {
    return {
      restrict: 'AC',
      templateUrl: 'templates/marketplace/office/directives/action-bar-office.html'
    };
  })

;

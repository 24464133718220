angular.module("app.marketplace.ui.inventory")
  .controller("itemOnHandRemoveModalController",
    function ($scope, $filter, $rootScope, $timeout, sgToast, $mdDialog, elementService, errorService, inventoryItemService) {
      $scope.item['out'] = {
        'out': 1
      };
      $timeout(function () {
        $scope.item.UI.focus_remove = true;
      });

      $scope.cancel = function () {
        $mdDialog.cancel();
      };
      $scope.submit = function () {
        if ($scope.itemQuantityRemove.$invalid) {
          return false;
        }

        $scope.submitting = true;
        $scope.item.UI.do_save = true;
        $timeout(function () { //Force digest so do_save and do its stuff (save other changes)
          inventoryItemService.removeStock($scope.item)
            .then(function (inventory_item) {
              $scope.submitting = false;
              var t_message = $filter('translate')('INVENTORY.UPDATED_ON_HAND');
              sgToast.showSimple(t_message);
              $mdDialog.hide();
              // the only relevant properties which the API updates in this call are on_hand
              // and expiry_dates, so we'll include these in the broadcast to ensure that any
              // unsaved changes the user has made in the PD slideout are not overridden
              const { on_hand, expiry_dates } = inventory_item;
              const updated_properties = { on_hand, expiry_dates };
              $rootScope.$broadcast('inventory-item-updated', inventory_item, updated_properties);
            }).catch(function (error) {
              $scope.submitting = false;
              var t_message = $filter('translate')('ERRORS.INVENTORY_REMOVE')
              errorService.uiErrorHandler(error || t_message, 0);
            });
        });
      };
    });

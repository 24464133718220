(function () {
  'use strict';

  /** @ngInject */
  class ShoppingCartPageCtrl {
    constructor($state, AccessService, cartService) {
      this.$state = $state;

      this.AccessService = AccessService;
      this.cartService = cartService;
    }

    $onInit() {
      this.defineLocks();

      /* redirect to mkt landing if full page cart not enabled */
      if (!this.full_page_cart) {
        this.$state.go('app.mkt');
      }
    }

    handleEvent({detail: {cart}}) {
      if (cart) {
        this.cartService.updateCart(cart);
      }
    }

    defineLocks() {
      this.full_page_cart = this.AccessService.hasOfficeProperty('shopping_cart', 'full_page_cart');
    }
  }

  angular.module('sowMarketplace')
    .controller('ShoppingCartPageController', ShoppingCartPageCtrl);

})();

angular.module("app.marketplace.elements")
.service("orderModel", function(apiUrl, $filter, commonApiService) {
  var _this = this;
  this.model_data = {
    "name": "order",
    "display_name": "Order",
    "api": {
      "by_hrid": function(options){
        if (!checkNested(options,'data', 'id')) {
          throw "Missing option(s) for order model path 'single'. Options: " + JSON.stringify(options);
        }
        return apiUrl + "/orders/" + options.data.id;
      },
      "multiple": function (options) {
        return apiUrl + "/me/orders";
      },
      "get_pending_orders": function(options){
        return commonApiService.getOfficeUrl() + "/shipments/pending";
      },
      "update_shipment_received" : function(options){
        if(!checkNested(options, "order", 'hrid') || !checkNested(options, "shipment", 'id') ){
          throw "Missing option(s) for order model path 'update_shipment_received'. Options: " + JSON.stringify(options);
        }
        
        return {
          path : apiUrl + "/orders/" + options.order.hrid + "/shipments/" + options.shipment.id + "/received",
          params : {
            method : "PUT",
            data : options.shipment
          }
        };
      }

    }

  };

  this.autofill = function (order) {
    if (order.created_at) {
      order.created_at = new Date(order.created_at);
    }

    order.UI = order.UI || {};

    // Reorganizing dates for easier sorting
    order.sort_date = order.created_at;

    order.name = order.status + " Order, " + (order.created_at ? $filter('date')(order.created_at, "MMM d, yyyy") : null);

    if (order.office_promo_code && order.office_promo_code.value) {
      order.office_promo_code.value = parseFloat(order.office_promo_code.value); //Correcting type
    }
    

    //Set order item instances in shipments.items
    // (Dealing with awkward endpoint return.)
    if(order.order_vendor_groups){
      
      _.each(order.order_vendor_groups, function(group, c){
        group.backordered_items = [];
        group.cancelled_items = [];
        group.unshipped_items = [];
        group.returned_items = []
        
        var itemMap = {};
        _.each(group.order_items, function(item){
          itemMap[item.id] = item;
          if (item.quantity_backordered) {
            group.backordered_items.push(item);
          }
          if (item.quantity_cancelled) {
            group.cancelled_items.push(item);
          }
          if (item.quantity_unshipped) {
            group.unshipped_items.push(item);
          }
          if (item.quantity_returned) {
            group.returned_items.push(item);
          }
        });

        _.each(group.shipments, function(shipment){
          shipment.items = _.flatMap(shipment.items, item => {
            if (!item) {
              return [];
            }
            /* if there's no corresponding item in this vendor's map, this item
            belongs to another vendor and should be filtered out of this shipment
            since we display shipments grouped by vendor (this can happen when the
            user manually receives the order since in that case the API creates a
            sort of pseudo shipment which may contain items from various vendors) */
            const itemMap_item = itemMap[item.order_item_id || item.id];
            if (!itemMap_item) {
              return [];
            }
            const {item_id, quantity, quantity_received} = item;              
            const item_info = {item_id, quantity, quantity_received};
            /* add additional info from the corresponding item in order_items */
            const updated_item = _.extend(item_info, itemMap_item);
            updated_item['quantity_shipped_in_this_shipment'] = parseInt(quantity);
            return [updated_item];
          });
        });
      });
    }
  };

  this.fields = {
    "id": {
      "display_name": "id",
      "required": true,
      "api": {
        "submit": true
      },
      "type": "integer"
    }
  };

  this.data = {
    "params": {
      "model_name": "order"
    }
  };

});

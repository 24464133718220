(function () {
  'use strict';
  angular.module('sowSterilization')
  .controller('stznDialogController', stznDialogController);

  function stznDialogController ($mdDialog) {
    /*jshint validthis:true*/
    var ctrl = this;
    
    ctrl.cancelDialog = cancelDialog;
    ctrl.hide = hide;
    ctrl.answer = answer;

    init();

    return ctrl;

    function init () {
      
    }

    function cancelDialog () {
      return $mdDialog.cancel();
    }

    function answer (content) {
      return $mdDialog.hide(content);
    }

    function hide () {
      return $mdDialog.hide();
    }

  }

}());

angular.module("app.marketplace.elements")
.service("manufacturerModel", ["apiUrl", function(apiUrl) {
  var _this = this;
  this.model_data = {
    "name" : "manufacturer",
    "display_name" : "Manufacturer",
    "api" : {
      "single" : function(options){ 
      },
      "search" : function(options){ 
        if(!checkNested(options,'name')){
          throw "Missing option(s) for manufacturer path 'search'. Options: " + JSON.stringify(options);
        }
        return {
          path : apiUrl + "/manufacturers?name=" + options.name,
          params : {
            method : "GET"
          }
        };
      }

    }

  };

  this.autofill = function(manufacturer) {
    
  };
  
  this.fields = {
    "id" : {
      "display_name" : "id",
      "required" : true,
      "api" : {
        "submit" : true
      },
      "type" : "integer"
    }
  };

  this.data = {
    "params" : {
      "model_name" : "manufacturer"
    }
  };

} ]);
(function () {
  'use strict';

  angular.module('sowMarketplace')
    .controller('marketplaceBannerAdController', marketplaceBannerAdController);

  function marketplaceBannerAdController($window, mktHelperService) {
    /*jshint validthis:true*/
    var ctrl = this;

    ctrl.goToAd = goToAd

    init();

    return ctrl;

    function init() {

    }

    function goToAd(banner) {
      switch (banner.banner_type) {
        case "category_id":
          mktHelperService.goToCategory({ 'cat_id': banner.banner_target });
          break;
        case "subcategory_id":
          mktHelperService.goToSubcategory({ 'sub_id': banner.banner_target });
          break;
        case "manufacturer_id":
          mktHelperService.goToManufacturer({ 'id': banner.banner_target });
          break;
        case "external_url":
          $window.open(banner.banner_target, '_blank');
          break;
        case "product_id":
          mktHelperService.productDetailsFetch(banner.banner_target);
          break;
      }
    }
  }


})();
(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.implants')
    .controller('impGetStartedController', impGetStartedController);

  function impGetStartedController ($scope, $state, $stateParams, $timeout, sowImplantService, $rootScope, impHelperService, sgToast) {
    /*jshint validthis: true */
    var ctrl = this;

    ctrl.current_step = 1;
    ctrl.manufacturers = [];
    ctrl.selected_manufacturer = {};

    ctrl.tables = [
      {name:"Implant Inventory", status:"", icon:"styles/img/implants/implants-icon.svg", type:'implants'},
      {name:"Closure Cap Inventory", status:"", icon:"styles/img/implants/caps-icon.svg", type:'caps'},
      {name:"Healing Abutment", status:"", icon:"styles/img/implants/healing-icon.svg", type:'abutments'},
    ];
    ctrl.disable_btn = false;

    ctrl.goToStep = goToStep;
    ctrl.buildTables = buildTables;
    // ctrl.checkAll = checkAll;
    ctrl.noneSelected =  noneSelected;
    ctrl.pristineSettings = pristineSettings;
    ctrl.resetChanges = resetChanges;
    _.extend(ctrl, impHelperService.table_settings_functions);

    init();

    return ctrl;

    function init () {
      // captureBackButton();
      loadData()
      .then(function(){
        getParams();
      });

      $scope.$on('implants-step2-clear-selection', resetChanges);
    }

    function loadData () { 
      return sowImplantService.getManufacturers()
        .then(function(response){
        if ($stateParams.adding) {
          response = _.filter(response, ['show_in_list', false]);
        }
        ctrl.manufacturers = _.sortBy(response, ['name']);
      });
    }

    function getParams () {
      if ($stateParams.mfr_id) {
        ctrl.selected_manufacturer = _.find(ctrl.manufacturers, ["id", $stateParams.mfr_id]);
      }
      if($stateParams.step){
        goToStep($stateParams.step);
      }
    }

    function goToStep (number) {
      if(number === ctrl.current_step){
        return;
      }
      
      if (number*1 === 2) {
        // load sizes & lengths for manufacturer
        return loadTableSettings().then(function(){
          transit({mfr_id: ctrl.selected_manufacturer.id});
        });
      }

      if (ctrl.current_step === 2 && number*1 === 3) {
        if(pristineSettings()){
          return;
        }
        return saveSettings()
        .then(function(response){
          transit();
        });
      }

      transit();

      function transit (input_params) {
        ctrl.current_step = number;
        var new_params = _.extend($stateParams, {'step': number}, input_params);
        $state.transitionTo(
          $state.current,
          new_params,
          {
            notify: false,
            location: 'replace',
          }
        );
      }
      
    }

    function loadTableSettings () {
      var product_type = $stateParams.type || "Implant";
      return sowImplantService.getTableSettings(ctrl.selected_manufacturer.id, product_type)
      .then(function(response){
        ctrl.table_settings = response;
        convertLocalSettings();
      });
    }

    function convertLocalSettings () {
      ctrl.local_settings = ctrl.convertToUiFormat(ctrl.table_settings);
      ctrl.initial_settings = angular.copy(ctrl.local_settings);
    }

    function saveSettings () {
      if(!ctrl.changes_made){
        return;
      }
      ctrl.saving = true;
      var converted_settings = ctrl.convertToApiFormat(ctrl.local_settings);
      _.set(ctrl.table_settings, "settings", converted_settings);
      return sowImplantService.saveTableSettings(ctrl.table_settings)
      .then(function(response){
        // sgToast.showSimple("Changes to table have been saved");
      }).catch(function(error){
        // sgToast.showSimple("404");
      });
    }


    //
    // Capture the Back Button
    //
    function captureBackButton () {
      ctrl.disableCaptureBackButton = $scope.$on('$locationChangeStart', function ($event) {
        if (ctrl.current_step === 2) {
          $event.preventDefault();
          goToStep(1);
          return;
        }
      });
      $scope.$on('$destroy', ctrl.disableCaptureBackButton);
    }

    function buildTables () {
      ctrl.disable_btn = true;
      ctrl.tables[0].status = "building";
      buildAllTables();

      $timeout(function() {
        ctrl.tables[0].status = "built";
        ctrl.tables[1].status = "building";
        // buildAllTables();
        
        $timeout(function() {
          ctrl.tables[1].status = "built";
          ctrl.tables[2].status = "building";

          $timeout(function() {
            ctrl.tables[2].status = "built";
            
            $timeout(function() {
              $state.go('app.implants.manufacturers');
            }, 1500);
            
          }, 1500);
        }, 1500);
      }, 1500);
    }

    function buildAllTables () {
      var categories = Object.keys( _.omit(ctrl.selected_manufacturer.categories, 'Implant') );
      var first_promise = {};
      _.forEach(categories, function(category, index){
        if(index === 0){
          first_promise = this_promise();
        } else {
          first_promise.then(this_promise);
        }

        function this_promise () {
          return sowImplantService.getTableSettings(ctrl.selected_manufacturer.id, category)
          .then(function(table_settings){
            deepTrue(table_settings.settings);
            return sowImplantService.saveTableSettings(table_settings);
          }); 
        }
      });
    }

    function deepTrue (object) {
      var props = Object.keys(object);
      if (props.length > 0) {
        _.forEach(props, function(prop){
          var second_lvl_props = Object.keys(object[prop]);
          if (second_lvl_props.length > 0) {
            deepTrue(object[prop]);
          } else {
            object[prop] = true;
          }
        });
      }
    }

    function noneSelected () {
      var none_selected = true;
      if(!ctrl.table_builder){
        return true;
      }
      _.forEach(ctrl.table_builder.settings, function(group){
        _.forEach(group, function(type){
          _.forEach(type, function(diameter){
            if(diameter === true){
              none_selected = false;
            }
          });
        });
      });
      return none_selected;
    }

    function pristineSettings () {
      var current_settings = angular.copy(ctrl.local_settings);
      return _.isEqual(ctrl.initial_settings, current_settings);
    }

    function resetChanges () {
      ctrl.local_settings = angular.copy(ctrl.initial_local_settings);
    }

  }
}());

(function () {
  'use strict';

  angular.module('sowInventory')
  .directive('sowIiHeaderAlert', sowInventoryItemHeaderAlertDirective)
  .controller('sowInventoryItemHeaderAlertController', sowInventoryItemHeaderAlertController);

  function sowInventoryItemHeaderAlertDirective () {
    return {
      restrict: 'E',
      replace: true,
      templateUrl: 'sow-inventory/directives/item-header-alert.html',
      controller: 'sowInventoryItemHeaderAlertController',
      controllerAs: 'iihaCtrl',
      scope: {
        'item': '=',
      }
    };
  }

  function sowInventoryItemHeaderAlertController ($scope, $state, $rootScope) {
    var ctrl = this;

    ctrl.goToAlertsPage = goToAlertsPage;
    ctrl.getExpiryDate = getExpiryDate;

    init();
    return ctrl;

    function init () {
      $scope.$on('slideout-item-set', setExpiryDate);
      $scope.$on('item-errors-set', setExpiryDate);
    }

    function setExpiryDate (event, item) {
      ctrl.expiry_date_label = getExpiryDate(item);
    }

    function getExpiryDate (item) {
      var label_format = _.get(item, 'UI.is_medication', null) ? 'MMM YYYY' : 'MMM DD, YYYY';
      if(!item){
        return '';
      }
      if(item.expiry_date){
        return item.expiry_date.format(label_format);
      } else {
        if(!_.get(item, 'expiry_dates.length', null)){
          return '';
        }
        var expiry_date_obj = _.first( _.sortBy(item.expiry_dates, function(date_obj){ 
          return moment(date_obj.date).format('x');
        }) );
        return moment(expiry_date_obj.date).format(label_format);
      }
    }

    function goToAlertsPage (item) {
      // TO-DO: handle goto click
      var destination = _.get(item, 'UI.is_medication', null) ? 'app.medications.alerts' : 'app.inventory.alerts';
      $rootScope.$broadcast('close-inventory-slideout');
      $state.go(destination);
    }
  }
}());

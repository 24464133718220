(function () {
  'use strict';

  angular.module('app.marketplace.ui.registration')
         .controller('RegistrationController', RegistrationController);

  function RegistrationController ($scope) {
  }

}());

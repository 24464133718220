angular.module('app.marketplace.ui.inventory')
  .service('inventorySharingService', inventorySharingService);

function inventorySharingService() {
  /*jshint validthis: true */
  var service = this;

  service.total_alerts = 0;

  service.tabs = {
    active: 'details',
    setActive: function (tab) {
      this.active = tab;
    },
    isActive: function (tab) {
      return this.active === tab ? 'active' : '';
    }
  };

  return service;
}

angular.module("app.shared.support")

  //Compile time defining directive from a variable.
  //Perfect for recursive, conditional, or var-defined directives.
  //Mostly related stackoverflow link: http://stackoverflow.com/questions/15279244/dynamically-add-directive-in-angularjs
  .directive('embdirective', ["$timeout", "$compile", function ($timeout, $compile) {
    var embCompile = function (scope, element, attrs) {
      var dirClass = attrs.embdirective;
      var htmlText = '<div class="embdirective ' + dirClass + '">' +
        '</div>';
      element.html(htmlText);
      $compile(element.contents())(scope);


      scope.$watch(function () {
        return attrs.embdirective;
      }, function (newValue, oldValue) {
        if (newValue && newValue !== oldValue) {
          dirClass = attrs.embdirective;
          htmlText = '<div class="' + dirClass + '">' +
            '</div>';
          element.html(htmlText);
          $compile(element.contents())(scope);
        }
      });
    };
    return {
      restrict: 'A',
      compile: function (scope, element, attrs) {
        return embCompile;
      }
    };
  }]);
(function () {
  'use strict';

  var PremiumPreviews = {
    'app.implants': {
      'RequiredFeature': 'implants',
    },
    'app.inventory.shopping_list': {
      'RequiredFeature': 'shopping_list',
      'SectionHeading': 'Shopping List',
      'SectionSubheading': "You've discovered a Premium Feature!",
      'Copy': [
        "Add products you need to re-order to this list until you're ready " +
        "to start creating Purchase Orders or ordering on the Sowingo Marketplace."
      ],
      'PreviewImageURL': 'styles/img/previews/shopping-list-image@2x.png',
    },
    'app.orders.purchase': {
      'RequiredFeature': 'purchase_orders',
      'SectionHeading': 'Purchase Orders',
      'SectionSubheading': "You've discovered a Premium Feature!",
      'Copy': [
        'Create Purchase Orders and send them directly to your suppliers',
        'When your order arrives, you can easily update your inventory in just a few clicks. ' +
        'You\'ll also have a permanent record that you can reference at any time.'
      ],
      'PreviewImageURL': 'styles/img/previews/purchaseorders-upgrade-image.jpg',
    },
    'app.monitors': {
      'RequiredFeature': 'monitors',
      'SectionHeading': 'Monitors',
      'SectionSubheading': '',
      'Copy': [
      ],
      'PreviewImageURL': '',
    },
    'app.reports': {
      'RequiredFeature': 'reports',
      'SectionHeading': 'Reports',
      'SectionSubheading': "You've discovered a Premium feature!",
      'Copy': [
        'Sowingo’s new Reports will now show you how much you are spending on ' +
        'supplies as well as how much quantity is flowing in and out of your office ' +
        'over a given time period.'
      ],
      'PreviewImageURL': 'styles/img/previews/reports-upgrade-image.jpg',
    },
    'app.dashboard': {
      'RequiredFeature': 'dashboard',
      'SectionHeading': 'Dashboard',
      'SectionSubheading': "You've discovered a Premium feature!",
      'Copy': [
        'The Sowingo Dashboard provides a quick overview of your office activity.',
        (
          'You will be able to identify all the numbers that matter most in one place. ' +
          'Know the exact value of your supplies, track your inventory usage and easily ' +
          'review recent marketplace orders.'

        ),
      ],
      'PreviewImageURL': 'styles/img/previews/dashboard-upgrade-image.jpg',
    },
    'app.inventory.log_sheets': {
      'RequiredFeature': 'inventory_log_sheets',
      'SectionHeading': 'Log Sheets',
      'SectionSubheading': "You've Discovered a Premium Feature and a Real Time Saver!",
      'Copy': [
          (
            '5-10 min/week to keep your inventory fully up to date and avoid wasting ' +
            'hours manually counting products.'
          )
      ],
      'PreviewImageURL': 'styles/img/previews/logsheets-upgrade-image.png',
    },
  };

  angular
    .module('app.marketplace.ui.subscriptions')
    .directive('premiumPreview', PremiumPreview);

  function PremiumPreview ($state) {
    return {
      restrict: 'EA',
      replace: true,
      scope: {},
      templateUrl: 'templates/marketplace/subscriptions/premium-preview.html',
      link: function ($scope, $element, $attrs) {
        $scope.go = $scope.$root.$state.go;

        // Load Parameters for this $state.
        _.forEach(PremiumPreviews, function (Config, StateName) {
          if ($state.includes(StateName)) {
            angular.extend($scope, Config);
            return false;
          }
        });
      }
    };
  }
}());

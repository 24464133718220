(function () {
  'use strict';
  
  angular
  .module('sowReports')
  .controller('SpendingSupplierMonthController', SpendingSupplierMonthController);
  
  function SpendingSupplierMonthController($scope, $window, $rootScope, $filter, $state, sowReportsService2, orderByFilter, AccessService, sowAnalyticsService) {
    var ctrl = this;
    ctrl.orderPropertyName = 'dayNumber';
    ctrl.orderReverse = false;
    ctrl.productPropertyName = 'product_name';
    ctrl.productReverse = false;
    var t_spending = $filter('translate')('COMMON.SPENDING');
    var t_manufacturer = $filter('translate')('COMMON.MANUFACTURER');
    var t_product_name = $filter('translate')('COMMON.PRODUCT_NAME');
    Array.prototype.sum = function (prop) {
      var total = 0;
      for ( var i = 0, _len = this.length; i < _len; i++ ) {
        const num = Number(this[i][prop]);
        if (!Number.isNaN(num)) {
          total += num;
        }
      }
      return total;
    };
    ctrl.chartSetting = {
      options: {
        scales: {
          xAxes: [{
            stacked: true,
            ticks: {
              beginAtZero: true
            }
          }],
          yAxes: [{
            stacked: false,
            ticks: {
              beginAtZero: true,
              callback: function(value, index, values) {
                if(index == values.length-1){
                  return value;
                }else{
                  return $filter('currency')(value, '$', 0);
                }
              }
            }
          }]
        }
      },
      series: [t_spending],
      type: "StackedBar",
      colors: [
        {
          backgroundColor: '#33bbc4',
          pointBackgroundColor: 'rgba(148,159,177,1)',
          pointHoverBackgroundColor: 'rgba(148,159,177,1)',
          borderColor: '#33bbc4',
          pointBorderColor: '#fff',
          pointHoverBorderColor: 'rgba(148,159,177,0.8)'
        },
        {
          backgroundColor: '#dddddd',
          pointBackgroundColor: 'rgba(77,83,96,1)',
          pointHoverBackgroundColor: 'rgba(77,83,96,1)',
          borderColor: '#dddddd',
          pointBorderColor: '#fff',
          pointHoverBorderColor: 'rgba(77,83,96,0.8)'
        },
        {
          backgroundColor: '#fa9e4d',
          pointBackgroundColor: 'rgba(77,83,96,1)',
          pointHoverBackgroundColor: 'rgba(77,83,96,1)',
          borderColor: '#fa9e4d',
          pointBorderColor: '#fff',
          pointHoverBorderColor: 'rgba(77,83,96,0.8)'
        }
      ]
    };
    ctrl.rangeOptions = [
      {
        name:moment().format('MMMM, YYYY'),
        value:moment().format('YYYY-M')
      }
    ];
    ctrl.searchProductType = 'product_name';
    ctrl.productTypeSearch = [
      {
        type: 'product_name',
        value: t_product_name
      },
      {
        type: 'manufacturer',
        value: t_manufacturer
      }
    ];

    ctrl.addDataForChart = addDataForChart;
    ctrl.changeChartData = changeChartData;
    ctrl.changesearchProductType = changesearchProductType;
    ctrl.clearSearchOrder = clearSearchOrder;
    ctrl.getLabel = getLabel;
    ctrl.getOrders = getOrders;
    ctrl.getParamsFromURL = getParamsFromURL;
    ctrl.getSupplierByMonth = getSupplierByMonth;
    ctrl.goBack = goBack;
    ctrl.goToOrder = goToOrder;
    ctrl.goToProductReport = goToProductReport;
    ctrl.openItemDetails = openItemDetails;
    ctrl.openPriceHistory = openPriceHistory;
    ctrl.openProductDetails = openProductDetails;
    ctrl.orderSort = orderSort;
    ctrl.productSort = productSort;
    ctrl.resetChart = resetChart;
    ctrl.setDataForDay = setDataForDay;
    ctrl.sumArray = sumArray;
    ctrl.takeMarketLink = takeMarketLink;
    ctrl.updateCurrentYear = updateCurrentYear;
    ctrl.updateMonthYearOptions = updateMonthYearOptions;
    ctrl.isOrderClickable = isOrderClickable;
    ctrl.isAddToCartEnabled = isAddToCartEnabled;

    init();

    return ctrl;
    
    function init  () {
      defineLocks();
      ctrl.updateMonthYearOptions();
      ctrl.params = ctrl.getParamsFromURL();
      ctrl.label = ctrl.getLabel(ctrl.params.month, ctrl.params.year);
      if(ctrl.params.year){
        ctrl.selectedYear = ctrl.params.year + '-' + moment(ctrl.params.month, 'MMMM').format('M');
      }else{
        ctrl.selectedYear = moment().format('YYYY-M');
      }
      ctrl.resetChart();
      ctrl.getSupplierByMonth(ctrl.params.id, ctrl.selectedYear);
    }
    
    function updateCurrentYear (){
      $window.localStorage.setItem('yearLocal', moment(ctrl.selectedYear,'YYYY-M').format('YYYY'));
      ctrl.resetChart();
      ctrl.getSupplierByMonth(ctrl.params.id, ctrl.selectedYear);
    }
    
    function resetChart (){
      ctrl.currentChartShow = {
        labels: ctrl.label,
        datas: [],
        supplierName: ctrl.params.supplierName,
        month: moment(ctrl.selectedYear, 'YYYY-M').format("MMMM"),
        year: moment(ctrl.selectedYear, 'YYYY-M').format("YYYY"),
        totalSpent: 0,
        orderPlace: 0,
        totalShipping: 0,
        orders:[],
        products:[]
      }
    }
    
    function updateMonthYearOptions () {
      var first_date = moment($scope.$root.current_office.created_at);
      var last_date = moment();
      var month_count = last_date.diff(first_date, 'M');
      for (var i = 1; i <= month_count; i++) {
        ctrl.rangeOptions.push({
          name: moment().subtract(i,'M').format('MMMM, YYYY'),
          value: moment().subtract(i,'M').format('YYYY-M')
        });
      }
    }
    
    function getSupplierByMonth  (supplierID, yearMonth) {
      sowReportsService2.getSupplierDetailByMonth(supplierID, yearMonth).then(function (result) {
        ctrl.changeChartData(result);
      });
    }
    
    function changeChartData  (data) {
      var report = data[0].report;
      var orders = report.orders;
      var products = report.products;
      
      if (orders.length > 0) {
        ctrl.addDataForChart(ctrl.label, orders);
      } else {
        // integrate empty chart later
      }
      
      if(products.length > 0){
        ctrl.currentChartShow.products = products;
      }else{
        // integrate empty table 
      }
    }
    
    function addDataForChart  (labels, orders) {
      var data = ctrl.setDataForDay(labels, orders);
      var totalSpent = data.reduce(ctrl.sumArray, 0);
      var newOrders = ctrl.getOrders(orders);
      var orderPlace = orders.length;
      var totalShipping = newOrders.sum('shipping_fees')
      
      ctrl.currentChartShow.totalSpent = totalSpent;
      ctrl.currentChartShow.datas.push(data);
      ctrl.currentChartShow.orders = newOrders;
      ctrl.currentChartShow.orderPlace = orderPlace;
      ctrl.currentChartShow.totalShipping = totalShipping;
    }
    
    function getOrders (orders){
      for(var i = 0; i< orders.length; i++){
        orders[i].dayNumber = parseInt(moment(orders[i].order_date, 'D MMM, YYYY').format('D'));
      }
      return orders;
    }

    function changesearchProductType (){
      ctrl.searchBy = {};
    }
    
    function setDataForDay  (labels, orders) {
      var data = [];
      for (var i = 0; i < labels.length; i++) {
        data.push(0);
      }
      for (var i = 1; i <= labels.length; i++) {
        for (var j = 0; j < orders.length; j++) {
          var date = moment(orders[j].order_date, 'D MMM, YYYY').format('D');
          if (i == date) {
            const total = Number(orders[j].total_order);
            if (total) {
              data[i - 1] += total;
            }
          }
        }
      }
      // data contains dollar amounts, we need to round each number to two
      // decimal places due to floating point arithmetic issues in javascript
      return data.map(num => Number(num.toFixed(2)));
    }
    
    function getLabel  (month, year) {
      var monthYearNumber = year + '-' + moment(month, 'MMMM').format('M');
      var countMonth = moment(monthYearNumber, "YYYY-M").daysInMonth();
      var month = moment(monthYearNumber, "YYYY-M").format('MMM');
      var daysList = [];
      for (var i = 1; i <= countMonth; i++) {
        daysList.push(month + ' ' + i);
      }
      return daysList;
    }
    
    function getParamsFromURL  () {
      var params = $state.params;
      var supplier = {
        id: '',
        month: moment().month() + 1,
        year: moment().year(),
        supplierName: ''
        
      };
      if (params) {
        supplier.id = params.supplier_id;
        supplier.month = params.month;
        supplier.year = params.year;
        supplier.supplierName = params.supplier_name
      }
      return supplier;
    }
    
    function sumArray  (a, b) {
      return a + b;
    }
    
    function goBack (){
      window.history.back();
    }
    
    function takeMarketLink (type){
      if(type == 'Marketplace Order'){
        $state.go('app.mkt');
      }else if(type == 'Purchase Order'){
        $state.go('app.purchaseorders');
      }
    }
    
    function orderSort  (propertyName) {
      ctrl.orderReverse = (ctrl.orderPropertyName === propertyName) ? !ctrl.orderReverse : false;
      ctrl.orderPropertyName = propertyName;
      var allProduct = ctrl.currentChartShow.orders;
      if (allProduct.length > 0) {
        ctrl.currentChartShow.orders = orderByFilter(allProduct, ctrl.orderPropertyName, ctrl.orderReverse);
      }
    }
    
    function productSort  (propertyName) {
      ctrl.productReverse = (ctrl.productPropertyName === propertyName) ? !ctrl.productReverse : false;
      ctrl.productPropertyName = propertyName;
      var allProduct = ctrl.currentChartShow.products;
      if (allProduct.length > 0) {
        ctrl.currentChartShow.products = orderByFilter(allProduct, ctrl.productPropertyName, ctrl.productReverse);
      }
    }
    
    function clearSearchOrder (){
      ctrl.orderSearch = {};
    }
        
    function goToOrder (item) {
      if (!isOrderClickable(item)) {
        return;
      }
      if (item.type === 'Marketplace Order') {
        sowAnalyticsService.logMarketplaceOrderDetailClicked({order_hrid: item.order_id});
        $state.go('app.orders.detail', {'hrid': item.order_id});
      } else if (item.type === 'Purchase Order') {
        $state.go('app.orders.purchase.active', {'poId':item.id});
      }
    }
    
    function openPriceHistory (item) {
      var type = _.get(item, 'item_type', '');
      if(!item.inventory_id || type.includes('Implant')){
        return;
      }
      if ( type.includes('Medication') ) {
        $rootScope.$broadcast('medication-item-slideout', null, null, 'price', item.inventory_id);
      } else {
        $rootScope.$broadcast('inventory-item-slideout', null, item.inventory_id, 'price');
      }
    }
    
    function openItemDetails (item) {
      var type = _.get(item, 'item_type', '');
      if(!item.inventory_id || type.includes('Implant')){
        return;
      }
      if ( type.includes('Medication') ) {
        $rootScope.$broadcast('medication-item-slideout', null, null, 'details', item.inventory_id);
      } else if (!type.includes('Implant') ) {
        $rootScope.$broadcast('inventory-item-slideout', null, item.inventory_id, 'details');
      }
    }
    
    function openProductDetails (item) {
      $rootScope.$broadcast('mkt-details-open-fetch', item.product_id);
    }
    
    function goToProductReport (item){
      var type = _.get(item, 'item_type', '');
      if(type.includes('Implant')){
        $state.go('app.reports.implants');
      } else {
        $state.go('app.reports.product-detail', {productId: item.inventory_id});
      }
    }

    /**
     * If the order type is a purchase order, return true if purchase orders are enabled, otherwise
     * return false. If the order type is a marketplace order, return true if marketplace orders are
     * enabled, otherwise return false. Return true by default.
     * @param order - The order object
     * @returns A boolean value indicating whether the link is enabled.
     */
    function isOrderClickable(order) {
      switch (order?.type) {
        case 'Purchase Order':
          return !ctrl.disable_purchase_orders;
        case 'Marketplace Order':
          return !ctrl.disable_marketplace_orders;
      }
      return true;
    }

    /**
     * If the disable_add_to_cart variable is true, return false. Otherwise, return true if the item
     * has a product_id and false if not.
     * @param item - The item object
     * @returns Boolean indicating if the item can be added to cart.
     */
     function isAddToCartEnabled(item) {
      if (ctrl.disable_add_to_cart) {
        return false;
      }
      return Boolean(item?.product_id);
    }

    function defineLocks() {
      const master_permission_condition = AccessService.getPermission('master_permission');

      const orders_permission_condition = AccessService.getPermission('marketplace_orders_purchase_orders');
      const orders_access_condition = orders_permission_condition || master_permission_condition;

      const disable_po_condition = AccessService.getOverride('disable_purchase_orders', false);
      ctrl.disable_purchase_orders = !orders_access_condition || disable_po_condition;

      const disable_mkt_orders_condition = AccessService.getOverride('disable_marketplace_orders', false);
      ctrl.disable_marketplace_orders = !orders_access_condition || disable_mkt_orders_condition;
      
      const add_to_cart_condition = AccessService.getPermission('marketplace_shopping_cart');
      ctrl.disable_add_to_cart = !add_to_cart_condition && !master_permission_condition;

      ctrl.hideCartButton = !AccessService.hasCartAccess();
    }

  }
}());

(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.medications')
    .controller('medSectionController', medSectionController);

  function medSectionController ($scope, $state, $stateParams, $timeout, dataSharingService, gettingStartedDialog, $rootScope, sowBrowserFlags) {
    /*jshint validthis: true */
    var ctrl = this;

    ctrl.FEATURE_DATA = {
      'section': {
        'name': 'MAIN_MENU.MEDICATIONS',
        'icon': {'material': "local_pharmacy", 'css': 'red' },
      },
      'plan': {
        'label': 'PAYWALL.PREMIUM_FEATURE',
        'css': 'premium',
      },
      'selling_points': [
        {
          'header': 'MEDICATIONS.PW_HEADER_ONE',
          'text':'MEDICATIONS.PW_TEXT_ONE',
          'img': "styles/img/paywalls/1-meds",
        },
        {
          'header': 'MEDICATIONS.PW_HEADER_TWO',
          'text':'MEDICATIONS.PW_TEXT_TWO',
          'img': "styles/img/paywalls/2-add",
        },
        {
          'header': 'MEDICATIONS.PW_HEADER_THREE',
          'text':'MEDICATIONS.PW_TEXT_THREE',
          'img': "styles/img/paywalls/3-qr-code",
        },
      ],
    };

    ctrl.isLoading = isLoading;
    ctrl.doneLoading = doneLoading;

    ctrl.showLoader = false;
    var loaderTimeout = $timeout(function () {
      ctrl.showLoader = true;
    }, 400);

    init();

    return ctrl;

    function init () {
      ctrl.showLoader = false;
      ctrl.dataSharingService = dataSharingService;

      // Hide the loader if we're on the medication log page (it's a React page)
      if ($state.is('app.medications.log')) {
        ctrl.dataSharingService.is_loading = false;
      }
      
      if(!$rootScope.features.medications) {
        return;
      }
      // aaomsWelcomeDialog();
    }

    function aaomsWelcomeDialog () {
      $scope.gsdMedications = gettingStartedDialog.showMedicationsDialog;
      if ($rootScope.features.aaoms_marketplace && !sowBrowserFlags.flag('medications-tutorial') ) {
        gettingStartedDialog.showMedicationsDialog()
        .then(function(){
          sowBrowserFlags.flag('medications-tutorial', true);
        });
      }
    }

    function isLoading () {
      ctrl.medState.isLoading = true;
    }

    function doneLoading () {
      ctrl.medState.isLoading = false;
    }
  }
}());

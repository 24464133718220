(function () {
  'use strict';

  const FOOTER_ID = 'approval-requests-id';
  const PAGE_SIZE = 20;

  /** @ngInject */
  class ApprovalRequestsController {
    constructor($scope, sowApprovalService, errorService) {
      this.$scope = $scope;

      this.sowApprovalService = sowApprovalService;
      this.errorService = errorService;
    }

    $onInit() {
      this.FOOTER_ID = FOOTER_ID;

      this.order_statuses = [
        {
          name: 'all',
          locale_key: 'ORDERS.ALL',
          badge_color: null,
        },
        {
          name: 'pending',
          locale_key: 'PO.PENDING',
          badge_color: 'pending',
        },
        {
          name: 'approved',
          locale_key: 'ORDERS.APPROVED',
          badge_color: 'success',
        },
        {
          name: 'declined',
          locale_key: 'ORDERS.DENIED',
          badge_color: 'neutral'
        },
        {
          name: 'cancelled',
          locale_key: 'ORDERS.CANCELLED',
          badge_color: 'critical'
        },
      ]

      this.available_views = [
        {
          name: 'orders',
          locale_key: 'COMMON.ORDERS',
          filters: this.order_statuses,
        },
      ]

      this.selectView(this.available_views[0]);

      this.$scope.$on(`is-visible-${FOOTER_ID}`, () => this.loadNextPage());
    }

    selectView(view) {
      this.displayed_view = view || this.available_views[0];
      this.available_filters = view.filters;
      this.applied_filter = view.filters[0];
      this.resetPage();
    }

    resetPage() {
      this.approval_requests = [];
      this.all_pages_fetched = false;
      this.current_page = 0;
      this.loadNextPage();
    }

    loadNextPage() {
      if (this.all_pages_fetched || this.loading_next_page) {
        return null;
      }
      this.loading_next_page = true;

      // request params:
      const paginate = true;
      const page_size = PAGE_SIZE;
      // omit approval_status if fetching all orders
      const approval_status = this.applied_filter.name === this.available_filters[0].name ? null : this.applied_filter.name;
      const page_num = this.current_page + 1;
      const query_term = this.search_query || null;

      this.sowApprovalService.getDashboard({ paginate, page_size, approval_status, page_num, query_term })
        .then(res => this.pageLoadHandler(res, page_num, query_term, page_size))
        .catch(err => this.errorService.uiErrorHandler(err))
        .finally(() => this.loading_next_page = false);
    }

    pageLoadHandler(api_response, page_num, query_term, page_size) {
      const new_approval_requests = this.parseRequests(api_response);
      this.current_page = page_num;
      this.applied_query = query_term;
      this.is_filter_applied = this.isFilterApplied();
      if (new_approval_requests.length < page_size) {
        this.all_pages_fetched = true;
      }
      this.approval_requests = [...this.approval_requests, ...new_approval_requests];
      this.office_has_no_approvals = !this.is_filter_applied && !this.approval_requests.length;
    }

    parseRequests(requests = []) {
      for (const request of requests) {
        const order_status = this.order_statuses.find(({ name }) => name === request.approval_status) || {};
        _.set(request, 'UI.badge_color', order_status.badge_color);
        _.set(request, 'UI.locale_key', order_status.locale_key);
      }
      return requests;
    }

    isFilterApplied() {
      return this.applied_query || (this.applied_filter.name !== this.available_filters[0].name);
    }

    handleViewClick(clicked_view) {
      const view_updated = this.displayed_view.name !== clicked_view.name;
      if (view_updated) {
        this.selectView(clicked_view);
      }
    }

    handleFilterClick(clicked_filter) {
      const filter_updated = this.applied_filter.name !== clicked_filter.name;
      if (filter_updated) {
        this.applied_filter = clicked_filter;
        this.resetPage();
      }
    }

    handleSearchQuery(e = {}) {
      if (e.preventDefault) {
        e.preventDefault();
      }
      // we don't need to reset the page if applied_query and
      // search_query are both falsy (since only a truthy query
      // will filter the page), or if they're identical
      const query_exists = this.applied_query || this.search_query;
      const query_updated = this.applied_query !== this.search_query;
      if (query_exists && query_updated) {
        this.resetPage();
      }
    }

  }

  angular.module('sowOrders')
    .controller('ApprovalRequestsController', ApprovalRequestsController);

}());

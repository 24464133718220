(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.implants')
    .controller('impEditTableController', impEditTableController);

  function impEditTableController ($scope, $state, $stateParams, $filter, sowImplantService, sgToast, $mdDialog, impHelperService, SessionStorageService) {
    /*jshint validthis: true */
    var ctrl = this;

    ctrl.icons = {
      "Implant": "styles/img/implants/implants-icon-v3.svg",
      "Closure Cap": "styles/img/implants/caps-icon.svg",
      "Healing Abutment": "styles/img/implants/healing-icon.svg",
    };
    ctrl.TAGS = {
      "Implant": "IMPLANTS.IMPLANT",
      "Closure Cap": "IMPLANTS.COVER_SCREW",
      "Healing Abutment": "IMPLANTS.HEALING_ABUTMENT",
      "Bone Graft": "IMPLANTS.BONE_GRAFT",
      "Membrane": "IMPLANTS.MEMBRANE"
    };

    ctrl.goBack = goBack;
    ctrl.saveSettings = saveSettings;
    ctrl.translateProduct = translateProduct;
    _.extend(ctrl, impHelperService.table_settings_functions);

    ctrl.manufacturer = null;
    ctrl.table_settings = null;
    ctrl.local_settings = null;
    ctrl.changes_made = false;
    ctrl.saving = false;
    ctrl.discarding = false;

    init();

    return ctrl;

    function init () {
      loadSettings()
      .then(function(){
        convertLocalSettings();
      });

      $scope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
        if (ctrl.changes_made && !ctrl.discarding && !ctrl.saving) {
          event.preventDefault();
          showUnsavedDialog()
          .then(function (answer) {
            // Allow state change
            if (answer) {
              // save and go
              saveSettings();
              // $state.go(toState.name, toParams);
            } else {
              ctrl.discarding = true;
              ctrl.changes_made = false;
              $state.go(toState.name, toParams);
            }
          });
        }
      });
    }

    // 
    // Data functions
    // 

    function showUnsavedDialog () {
      return $mdDialog.show({
        controller: 'poDialogController',
        controllerAs: 'dialogCtrl',
        templateUrl: 'templates/marketplace/implants/modals/imp-unsaved-warning.html',
        parent: angular.element(document.body),
        clickOutsideToClose: true,
      });
    }

    function convertLocalSettings () {
      ctrl.local_settings = ctrl.convertToUiFormat(ctrl.table_settings);
    }

    function loadSettings () {
      return sowImplantService.getTableSettings($stateParams.mfr_id, $stateParams.type)
      .then(function(response){
        ctrl.table_settings = response;

        return sowImplantService.getManufacturer($stateParams.mfr_id)
        .then(function(response){
          ctrl.manufacturer = response;
        });

      });
      
    }

    function saveSettings () {
      if(!ctrl.changes_made){
        return;
      }
      ctrl.saving = true;
      var converted_settings = ctrl.convertToApiFormat(ctrl.local_settings);
      _.set(ctrl.table_settings, "settings", converted_settings);
      return sowImplantService.saveTableSettings(ctrl.table_settings)
      .then(function(response){
        var t_message = $filter('translate')('TOAST.TABLE_CHANGES_SAVED');
        sgToast.showSimple(t_message);
        SessionStorageService.clear();
        goBack();
      }).catch(function(error){
        sgToast.showSimple("404");
      });
    }

    function goBack () {
      $state.go("app.implants.table", $stateParams);
    }

    function translateProduct (product) {
      return $filter('translate')(ctrl.TAGS[product]);
    }

    // 
    // UI functions moved to impHelperService and extended at the top
    // 
    
  }
}());

angular.module('app.marketplace.ui.inventory')

.directive('inventoryMedicationDetailsForm', function() {
  return {
    restrict: 'E',
    controllerAs: 'ctrl',
    templateUrl: 'templates/marketplace/inventory/directives/inventory-medication-details-form.html',
    scope: true,
    bindToController: {
      itemClone: '=',
      form: '=?',
      action: '@'
    },
    controller: function(officeService, elementService) {
      var ctrl = this;
      var office = officeService.get();

      ctrl.countryId = _.get(office, 'address.country.id');
      ctrl.manufacturerSearch = manufacturerSearch;

      //////////

      function manufacturerSearch (name) {
        if (!name) {
          return false;
        }

        return elementService.get('manufacturer', null, {
          endpoint: 'search',
          name: name
        })
        .then(function (manufacturers_results) {
          return _.map(manufacturers_results, function(item) {
            return {name: item.name, id: item.id};
          });
        });
      }
    }
  };
});

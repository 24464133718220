(function () {
  'use strict';

  angular
    .module('app.marketplace.elements')
    .service('sterilizationRecordService', SterilizationRecordService);

  function SterilizationRecordService($rootScope, $filter, $log, $mdDialog, sgToast, errorService, elementService, localStorageService, officeService) {
    /*jshint validthis: true */
    var service = this;
    var defaultsKey = 'sterilization-defaults';
    var _oldestRecordDate = null;
    var _oldestRecordDateSeen = null;

    service.getRecordsByMonthRange = _getRecordsByMonthRange;
    service.getRecords = _getRecords;
    service.getPending = _getPendingRecords;
    service.markPassed = _markPassed;
    service.markFailed = _markFailed;
    service.updateRecord = updateRecord;
    service.createRecord = _createRecord;
    service.getIndicatorCompanies = _getIndicatorCompanies;
    service.editNotes = _editNotes;
    service.reachedOldestRecord = _reachedOldestRecord;
    service.getOldestRecordDateSeen = _getOldestRecordDateSeen;
    service.saveDefaults = saveDefaults;
    service.getBlankRecord = getBlankRecord;

    init();

    return service;

    function init () {
      $rootScope.$on('officeService: office-changed', function () {
        _oldestRecordDate = null;
        _oldestRecordDateSeen = null;
      });
    }

    //
    // A helper function to get a Date object with now milliseconds.
    //
    function getNoMilliTime () {
      var d = new Date();
      d.setMilliseconds(0);
      return d;
    }

    //
    // Return a new 'blank' record, populated with defaults if any are set.
    //
    function getBlankRecord () {
      var record = {
        testType: 'biological',
        tested_at: getNoMilliTime(),
        pouches: 0,
        wraps: 0,
        cassettes: 0,
        included_tape: false,
        included_other: false,
        included_strips: false,
        included_implants: false,
        included_general_products: false
      };

      if (localStorageService.isSupported()) {
        var officeId = officeService.get().id;
        var defaults = localStorageService.get(defaultsKey);
        if (defaults && defaults[officeId]) {
          angular.extend(record, defaults[officeId]);
        }
      }

      return record;
    }

    //
    // From a record, extra values that we will use as defaults when creating a
    // new "blank" record.
    //
    function saveDefaults (record) {
      if (localStorageService.isSupported()) {
        var officeId = officeService.get().id;
        var defaults = localStorageService.get(defaultsKey);

        if (!defaults) {
          defaults = {};
        }

        defaults[officeId] = {
          'tester': angular.copy(record.tester),
          'indicator_company': record.indicator_company,
          'incubator_time': record.incubator_time,
          'sterilizer': angular.copy(record.sterilizer),
          'sterilizer_mode': angular.copy(record.sterilizer_mode),
        };

        //
        // Clean out temporary values that AngularJS / AngularMaterial assign.
        // We don't want these to be stored.
        //
        _.forEach(['tester', 'sterilizer', 'sterilizer_mode'], function (key) {
          delete defaults[officeId][key]['$$hashKey'];
          delete defaults[officeId][key]['$$mdSelectId'];
        });

        localStorageService.set(defaultsKey, defaults);
      }
    }

    //
    // Convenience Debug Logger
    //
    function _debug () {
      var args = Array.prototype.slice.call(arguments, 1);
      args[0] = sprintf('sterilizationRecordModel: %s: %s', arguments[0], args[0]);
      return $log.debug.apply(undefined, args);
    }

    //
    // Process New Records
    //
    function _processNewRecord (record) {
      if (!_oldestRecordDate || _oldestRecordDate > record.tested_at) {
        _oldestRecordDate = record.tested_at;
      }

      if (!_oldestRecordDateSeen || _oldestRecordDateSeen > record.tested_at) {
        _oldestRecordDateSeen = record.tested_at;
      }

      return record;
    }

    //
    // Process The Incoming List of Records
    //
    function _processRecordsResponse (response, options) {
      options = angular.extend(
        {
          'ignoreDates': false,
        },
        options
      );

      var records = elementService.createMultiple('sterilizationRecord', response.data.records);
      _oldestRecordDate = new Date(response.data.oldest_test_date);

      if (!options.ignoreDates) {
        var _oldestSeen = _oldestRecordDateSeen ? moment.utc(_oldestRecordDateSeen) : null;

        angular.forEach(records, function (record) {
          var testDate = moment.utc(record.tested_at);

          if (!_oldestSeen || _oldestSeen.isAfter(testDate)) {
            _oldestRecordDateSeen = record.tested_at;
          }
        });
      }

      return records;
    }

    //
    // Have we reached the oldest record?
    //
    function _reachedOldestRecord () {
      return (
        _oldestRecordDate &&
        _oldestRecordDateSeen &&
        _oldestRecordDateSeen <= _oldestRecordDate
      );
    }

    function _getOldestRecordDateSeen () {
      return _oldestRecordDateSeen;
    }

    function _editNotes (record) {
      return $mdDialog.show({
        templateUrl: 'templates/marketplace/sterilization/modals/edit-sterilization-record-notes.html',
        controller: 'EditSterilizationRecordNotesController',
        locals: {record: angular.copy(record)}
      });
    }

    function _getIndicatorCompanies () {
      return officeService.get(true).then(function (office) {
        return elementService.callEndpoint('sterilizationRecord', {
          endpoint: 'ac_indicator_companies',
          office_id: office.id
        }).then(function (response) {
          var records = response.data;

          // _debug('_getIndicatorCompanies', 'response = %o', response);
          // _debug('_getIndicatorCompanies', 'records = %o', records);

          return records;
        });
      });
    }


    function _getPendingRecords (limit) {
      return officeService.get(true).then(function (office) {
        return elementService.callEndpoint('sterilizationRecord', {
          endpoint: 'fetch_all',
          forceAPI: true,
          office: office,
          pending: true,
          limit: limit
        }).then(function (response) {
          return _processRecordsResponse(response, {'ignoreDates': true});
        });
      });
    }

    function _getRecordsByMonthRange (start, months) {
      return officeService.get(true).then(function (office) {
        return elementService.callEndpoint('sterilizationRecord', {
          endpoint: 'fetch_all',
          forceAPI: true,
          office: office,
          start_date: start,
          months: months
        }).then(function (response) {
          return _processRecordsResponse(response);
        }).catch(function (response) {
          if (response === 'Range filter is older than the oldest records.') {
            throw 'Oldest Record Reached';
          } else {
            throw response;
          }
        });
      });
    }

    function _getRecords (limit, endDate) {
      return officeService.get(true).then(function (office) {
        return elementService.callEndpoint('sterilizationRecord', {
          endpoint: 'fetch_all',
          forceAPI: true,
          office: office,
          end_date: endDate,
          limit: limit,
        }).then(function (response) {
          return _processRecordsResponse(response);
        });
      });
    }

    function _postRecord (data) {
      data.office_id = officeService.get().id;
      return elementService.submit('sterilizationRecord', {
        endpoint: 'create',
        element: data,
        doUpdate: true
      }).then(function (record) {
        var t_message = $filter('translate')('TOAST.RECORD_CREATED');
        sgToast.showSimple(record.type.toProperCase() + t_message);
        return _processNewRecord(record);
      });
    }

    function _createRecord () {
      return $mdDialog.show({
        templateUrl: 'templates/marketplace/sterilization/modals/create-sterilization-record.html',
        controller: 'CreateSterilizationRecordController',
        locals: {
          postRecord: _postRecord
        }
      });
    }

    function _markPassed (record) {
      return officeService.get(true).then(function (office) {
        return elementService.callEndpoint('sterilizationRecord', {
          endpoint: 'mark_passed',
          office: officeService.get(),
          record: record,
          doUpdate: true
        }).then(function (response) {
          var t_message = $filter('translate')('TOAST.BIO_TEST_PASSED');
          sgToast.showSimple(t_message);
          return response.data;
        }, function (error) {
          var t_message = $filter('translate')('ERRORS.MARK_PASSED')
          errorService.uiErrorHandler(t_message);
        });
      });
    }

    function _markFailed (record) {
      return officeService.get(true).then(function (office) {
        return elementService.callEndpoint('sterilizationRecord', {
          endpoint: 'mark_failed',
          office: office,
          record: record,
          doUpdate: true
        }).then(function (response) {
          var t_message = $filter('translate')('TOAST.BIO_TEST_FAILED');
          sgToast.showSimple(t_message);
          return response.data;
        }).catch(function (error) {
          var t_message = $filter('translate')('ERRORS.MARK_FAILED')
          errorService.uiErrorHandler(t_message);
        });
      });
    }

    function updateRecord (record, fields) {
      return officeService
        .get(true)
        .then(function (office) {
          return elementService
            .callEndpoint('sterilizationRecord', {
              'endpoint': 'update_record',
              'office': office,
              'record': record,
              'fields': fields,
              'doUpdate': true,
            });
        })
        .then(function (record) {
          var t_message = $filter('translate')('TOAST.RECORD_UPDATED');
          sgToast.showSimple(t_message);
          return record;
        })
        .then(null, function (error) {
          errorService.uiErrorHandler(error);
          throw error;
        });
    }
  }
}());

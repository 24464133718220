(function () {
  'use strict';

  /** @ngInject */
  class BudgetSpendingController {
    gl_budget_enabled = false;
    selected_year = new Date().getFullYear();
    year_options = [];

    constructor($state, AccessService, officeService) {
      this.$state = $state;
      this.AccessService = AccessService;
      this.officeService = officeService;
    }

    $onInit() {
      this._setYearFromParams();

      this.defineLocks();
      this.redirectIfNotAuthorized();
      this.initYearOptions();
    }

    /**
     * Sets the selected year from the URL parameters if a year is specified.
     * If no year is specified in the URL, the default year will be used (current year).
     */
    _setYearFromParams() {
      // Check if a year is specified in the URL before setting the default year
      if (this.$state.params.year) {
        this.selected_year = this.$state.params.year;
      } else {
        // Use the current year as the default year
        this.refreshUrlParams(this.selected_year)
      }
    }

    defineLocks() {
      this.gl_budget_enabled = this.AccessService.getOfficeProperty('gl_budget_enabled');
    }

    redirectIfNotAuthorized() {
      if (!this.gl_budget_enabled) {
        this.$state.go('app.reports.spending.office');
      }
    }

    /**
     * Updates the URL parameters to reflect the selected year without reloading the page.
     *
     * @param {number} selected_year - The year to set in the URL parameters.
     */
    refreshUrlParams(selected_year) {
      // Update state params to reflect the selected year
      // and notify:false to prevent reloading the page
      this.$state.go('app.reports.spending.budget', { year: selected_year }, {notify: false});
    }

    initYearOptions() {
      /* eligible years are the current one and all previous ones in which the office existed (if any) */
      for (let i = 0; i <= moment().diff(moment(this.officeService.get().created_at).startOf('year'), 'y'); i++) {
        this.year_options.push(moment().subtract(i, 'y').format('YYYY'));
      }

      // Sort the year options in descending order
      this._ensureSelectedYearInOptions();
    }


    /**
     * Ensures that the selected year is included in the list of year options.
     * 
     * If the selected year is not already in the list of year options, it adds the selected year
     * to the list and sorts the list in descending order.
     */
    _ensureSelectedYearInOptions() {
      // Return early if year is already in the list
      if (this.year_options.includes(this.selected_year.toString())) {
        return;
      }


      // The selected year is now included on the url params, which means that if the
      // url param date is not in the list of year_options, it should be added to the list
      // of year_options, and it should be sorted in descending order
      this.year_options.push(this.selected_year);

      // Sort the year_options in descending order
      this.year_options.sort((a, b) => b - a);
    }
  }

  angular.module('sowReports').controller('BudgetSpendingController', BudgetSpendingController);

})();

(function () {
  'use strict';

  const manufacturerCard = {
    bindings: {
      manufacturer: '<',
    },
    controller: 'manufacturerCardCtrl',
    controllerAs: 'manCardCtrl',
    selector: 'mktManufacturerCard',
    templateUrl: 'sow-mkt/directives/mkt-card-manufacturer.html',
  };

  /** @ngInject */
  class manufacturerCardCtrl {
    constructor(mktHelperService) {
      this.mktHelperService = mktHelperService;
    }

    $onInit() {
      this.localized_manufacturer = this.mktHelperService.getLocalizedProp(this.manufacturer, 'name');
    }

    /**
     * Navigates to a page of products sold by the selected manufacturer
     * @param {object} manufacturer
     */
    goToManufacturer(manufacturer = this.manufacturer) {
      const params = {
        manufacturer,
        manufacturer_id: manufacturer.id,
        mf_id: manufacturer.id,
        mf_name: manufacturer.name,
      };
      return this.mktHelperService.goToManufacturer(params);
    }
  }

  angular.module('sowMarketplace')
    .controller(manufacturerCard.controller, manufacturerCardCtrl)
    .component(manufacturerCard.selector, manufacturerCard);

})();

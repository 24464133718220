(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.medications')
    .controller('medDetailController', medDetailController);

    function medDetailController($scope, $filter, $timeout, $state, $stateParams, $q, $mdToast, $mdSidenav, $mdDialog, officeService, sowMedicationService, inventoryItemService, dataSharingService, errorService){
      /*jshint validthis: true */
      var ctrl = this;
      var undoTimeout = 5000;
      var undoHideTimeouts = {};


      ctrl.filters = {
        medication_id: null
      };

      init();
      ctrl.delete_single_medication = delete_single_medication;
      ctrl.open_edit_medication_sidenav = open_edit_medication_sidenav;
      ctrl.update_medication_stock = update_medication_stock;
      ctrl.open_discard_edition_dialog = open_discard_edition_dialog;
      ctrl.open_delete_medication_stock_dialog = open_delete_medication_stock_dialog;
      ctrl.add_to_shopping_list = add_to_shopping_list;
      ctrl.toggleExtraMenuDropdown = toggleExtraMenuDropdown;
      ctrl.openMenu = openMenu;

      ctrl.clickRemove = clickRemove;
      ctrl.sortOrder = 'expiry_date';

      ctrl.close_dialogs = close_dialogs;
      ctrl.close_side_nav = close_side_nav;
      ctrl.dialogs = ["med-edit-sidenav"];
      return ctrl;

      function init () {
        var office = officeService.get();
        ctrl.countryId = _.get(office, 'address.country.id');

        officeService.getLocations()
          .then(function(locations){
            ctrl.locations = locations;
          });

        ctrl.years = load_years();

        get_medication_and_stock()
          .then(function(result){
            dataSharingService.is_loading = false;
            ctrl.medication = po_status (result.medication[0]);
            dataSharingService.inventory_item_id = ctrl.medication.inventory_item_id;
            ctrl.dataSharingService = dataSharingService;
            ctrl.medication_stock = result.medication_stock;
          }).catch(function(err){
            var t_message = $filter('translate')('ERRORS.MEDS_LOAD')
            errorService.uiErrorHandler(t_message);
          });

        $scope.$on('$stateChangeStart', function (event, toState, toParams) {
          var isTruthy = function (value) { return !!value; };
          var itemIds = _
            .chain(ctrl.dataSharingService.toRemoveItems)
            .pickBy(isTruthy)
            .keys()
            .value();

          if (itemIds && itemIds.length > 0) {
            event.preventDefault();
            $q.all(_.map(itemIds, removeMedicationStockItem))
              .finally(function () {
                $state.transitionTo(toState, toParams);
              });
          }
        });
      }

      function openMenu ($mdOpenMenu, ev) {
        var originatorEv = ev;
        $mdOpenMenu(ev);
      }

      function po_status(medication){
        if(! angular.equals({}, medication.po_status)){
          medication.purchase_order = true;
          var tooltip='';
          var tooltip_text ='';
          _.each(Object.keys( medication.po_status), function(key){
            if(key==="Active"){
              tooltip_text = "Active";
            }
            tooltip+=key +":"+medication.po_status[key]+" ";
          });
          medication.tooltip = tooltip;
          medication.tooltip_text = tooltip_text === "Active" ? "Active": "Draft";
        }
        return medication;
      }

      function toggleExtraMenuDropdown (medication, value){

        _.each(ctrl.medication_stock, function(item){
          item.open_more_menu = false;
        });
        medication.open_more_menu = value;
        // if(value || value===false){
        //   $scope.open_more_menu = value;
        // }else{
        //   $scope.open_more_menu = !$scope.open_more_menu;
        // }
      }

      function load_years(){
        var twenty_years_from_now = [];
        for (var index = 0; index <= 20; index++) {
          twenty_years_from_now.push(moment().year() + index);
        }
        return twenty_years_from_now;
      }

      function update_medication_stock(stock_item){
        stock_item.expiry_date = moment().years(stock_item.year).month(stock_item.month).toDate();
        sowMedicationService.createOrUpdateMedicationStock(stock_item)
          .then(function(result){
            $state.reload()
              .then(function(){
                var t_message = $filter('translate')('TOAST.CHANGES_SAVED');
                $mdToast.show(
                  $mdToast.simple()
                    .textContent(t_message)
                    .position("bottom right")
                    .hideDelay(2000)
                );
              });

          }).catch(function (err) {
            var t_message = $filter('translate')('ERRORS.MED_UPDATE')
            errorService.uiErrorHandler(t_message);
          });
      }

      function add_to_shopping_list(item){

        inventoryItemService.addToShoppingList([{id: item.inventory_item_id}])
          .then(function(res){
            var message = $filter('translate')('COMMON.ADDED_TO_SL');
            $mdToast.show(
              $mdToast.simple()
                .textContent(message)
                .position("bottom right")
                .hideDelay(2000)
            );

          }).catch(function (err) {
            var t_message = $filter('translate')('ERRORS.SHOPPING_LIST_ADD')
            errorService.uiErrorHandler(t_message);
          });
      }



      function close_side_nav () {
        _.forEach(ctrl.dialogs, function(dialog){
          $mdSidenav(dialog).close();
        });
      }
      function close_dialogs(){
        close_side_nav();
        $mdDialog.cancel();
      }

      function open_discard_edition_dialog(medication_stock_item){

        $mdDialog.show({
          controller: 'medDetailController',
          controllerAs: 'medDetailCtrl',
          templateUrl: 'templates/marketplace/medications/modals/discard-edition-changes.html',
          parent: angular.element(document.body),
          clickOutsideToClose: true,
          bindToController: true,
          locals:{
            medication_stock_item: medication_stock_item
          }
        });
      }



      function open_edit_medication_sidenav (item, $event) {
        $event.stopPropagation();
        close_side_nav();
        var expiry = moment(item.expiry_date);
        ctrl.medication_stock_item = {
          lot_number : item.lot_number,
          id:item.id,
          location_id: item.location_id,
          medication_id: item.medication_id,
          year: expiry.year(),
          month: expiry.format('MMMM')
        };

        return $mdSidenav("med-edit-sidenav").toggle();
      }

      function get_medication_and_stock(){

        var deferred = $q.defer();

        var medication_promise = get_medication({id: $stateParams.id});
        var stock_promise = load_medication_stock({medication_id: $stateParams.id});

        $q.all([medication_promise, stock_promise])
          .then(function(values){
            deferred.resolve({
              medication: values[0],
              medication_stock: values[1]
            });
          }).catch(function(reason){
            deferred.reject(reason);
          });

        return deferred.promise;
      }

      //
      // Process the Removal of a MedicationStockItem from Inventory
      //
      function removeMedicationStockItem (id) {
        if (!ctrl.dataSharingService.toRemoveItems[id]) {
          return $q.when(null);
        }

        delete ctrl.dataSharingService.toRemoveItems[id];
        delete undoHideTimeouts[id];
        _.remove(ctrl.medication_stock, {'id': id});
        // alertsDataCarrierService.load_alerts();
        return sowMedicationService.removeMedicationStock({'id': id});
      }

      //
      // User clicks the "Remove" button on a MedicationStockItem
      //
      function clickRemove (medicationStockItem) {
        var id = medicationStockItem.id;
        medicationStockItem.removing = true;


        sowMedicationService.removeMedicationStock({id: id})
          .then(function() {
            $timeout(function () {
              ctrl.medication_stock =  ctrl.medication_stock.filter(function(med){
                return med.id !== id;
              });
              medicationStockItem.removed = false;
            }, 3000);
          });
      }

      function open_delete_medication_stock_dialog(medication_stock_item){
        $mdDialog.show({
          controller: 'medDetailController',
          controllerAs: 'medDetailCtrl',
          templateUrl: 'templates/marketplace/medications/modals/delete-medication-stock.html',
          parent: angular.element(document.body),
          clickOutsideToClose: true,
          bindToController: true,
          locals:{
            medication_stock_item: medication_stock_item
          }
        });
      }

      function delete_single_medication(stock_item){
        sowMedicationService.removeMedicationStock(stock_item)
          .then(function(result){
            var t_message = $filter('translate')('TOAST.ITEM_DELETED');
            $state.reload()
              .then(function(){
                $mdToast.show(
                  $mdToast.simple()
                    .textContent('Item Deleted')
                    .position("bottom right")
                    .hideDelay(2000)
                );
              });

          }).catch(function (err) {
            var t_message = $filter('translate')('ERRORS.MED_DELETE')
            errorService.uiErrorHandler(t_message);
          });

      }

      function get_medication(filters){
        return sowMedicationService.getMedications(filters);
      }

      function load_medication_stock(filters){
        return sowMedicationService.getMedicationStock(filters);
      }


    }

}());

(function () {
  'use strict';
  angular.module("sowCertifications")
    .directive('actionBarCertDetail', certDetailActionBarDirective);

  function certDetailActionBarDirective() {
    return {
      restrict: 'AC',
      templateUrl: 'sow-certifications/directives/action-bar-cert-detail.html',
      controller: 'certActionBarController',
      controllerAs: 'certAbCtrl'
    };
  }

}());

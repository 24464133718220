(function () {
angular.module('app.marketplace.ui.inventory', [
  'sowLogSheets',
  'sowInventory',
  'ngFileUpload'
])
.config(function($stateProvider, $urlRouterProvider) {
  $urlRouterProvider.when('/app/inventory', '/app/inventory/');
  $urlRouterProvider.when('/app/inventory/', '/app/inventory/all/'); // Makes app.inventory.index the default state for app.inventory
  $urlRouterProvider.when('/app/inventory/activity_pages', '/app/inventory/activity_pages/');
  $urlRouterProvider.when('/app/inventory/log_sheets', '/app/inventory/log_sheets/intro');
  $urlRouterProvider.when('/app/inventory/create-product/', '/app/inventory/create-product');

  $stateProvider
    .state('app.inventory', {
      url: '/inventory',
      controller: 'inventoryController',
      controllerAs: 'invSecCtrl',
      templateUrl: 'templates/marketplace/inventory/inventory.html',
      data: {
        'title': 'Inventory',
        'action_bar_directive': 'action-bar-inventory',
        'require_log_in' : true,
        'requires_feature': 'inventory_full',
      },
    })
    /**
     * General Inventory Alerts
     */
    .state('app.inventory.alerts', {
      url: '/alerts',
      controller: 'InventoryAlertsController',
      controllerAs: 'invAlertsCtrl',
      templateUrl: 'templates/marketplace/inventory/inventory-alerts.html',
      data: {
        'title': 'Inventory Alerts',
        'action_bar_directive': 'action-bar-inventory',
        'require_log_in' : true
      },
      params:{
        filter:''
      }
    })
    //
    // Inventory "Master" Print State
    //
    .state('app.inventory.print', {
      url: '/print',
      abstract: true,
      template: '<ui-view/>',
      controller: 'InventoryPrintCtrl',
      controllerAs: '$printCtrl',
      data: {
        'action_bar_directive': 'inventory-print-action-bar',
        'title': 'Print Inventory',
      }
    })
    //
    // Inventory "Print All" Page
    //
    .state('app.inventory.print.all', {
      url: '/all',
      controller: 'InventoryPrintAllCtrl',
      templateUrl: 'templates/marketplace/inventory/views/inventory-print-all.html',
    })
    //
    // Inventory "Print Suppliers" Page
    //
    .state('app.inventory.print.suppliers', {
      url: '/suppliers',
      controller: 'InventoryPrintSuppliersCtrl',
      templateUrl: 'templates/marketplace/inventory/views/inventory-print-bundles.html',
      data: {
        'title': 'Print Inventory Suppliers',
      }
    })
    //
    // Inventory "Print Locations" Page
    //
    .state('app.inventory.print.locations', {
      url: '/locations',
      controller: 'InventoryPrintLocationsCtrl',
      templateUrl: 'templates/marketplace/inventory/views/inventory-print-bundles.html',
      data: {
        'title': 'Print Inventory Locations',
      }
    })
    .state('app.inventory.all', {
      url: '/all/:itemId?filterType', //Cant have just '/:itemId' because it breaks all sibling states.
      controller: 'sowInventoryListController',
      controllerAs: 'silCtrl',
      templateUrl: 'templates/marketplace/inventory/views/inventory-all.html',
      data: {
        'confirm_navigation': {'forms': ['inventoryItemForm'], 'type' : 'LeavePage'}
      }
    })
    .state('app.inventory.grid', {
      url: '/grid/:itemId?filterType', //Cant have just '/:itemId' because it breaks all sibling states.
      controller: 'sowInventoryGridController',
      controllerAs: 'gigCtrl',
      templateUrl: 'sow-inventory/views/inventory-grid.html',
      data: {
        // 'confirm_navigation': {'forms': ['inventoryItemForm'], 'type' : 'LeavePage'}
      }
    })
    .state('app.inventory.locations', {
      url: '/locations',
      controller: 'InventoryLocationsController',
      templateUrl: 'templates/marketplace/inventory/views/inventory-locations.html',
      data: {
        'title': 'Inventory By Location'
      }
    })
    .state('app.inventory.suppliers', {
      url: '/suppliers',
      controller: 'inventorySuppliersController',
      templateUrl: 'templates/marketplace/inventory/views/inventory-suppliers.html',
      data: {
        'title': 'Inventory By Supplier',
        'confirm_navigation': {'forms': ['inventoryItemForm'], 'type' : 'LeavePage'}
      }
    })
    .state('app.inventory.log', {
      url: '/log',
      controller: 'inventoryLogController',
      templateUrl: 'templates/marketplace/inventory/views/inventory-log.html',
      data: {
        'title': 'Inventory Log'
      },
      resolve: {
        inventoryActivityItems: function(errorService, officeService, $filter){
          return officeService.getInventoryActivity(true).then(function(activityItems) {
            return activityItems;
          }).catch(function(error) {
            var t_message = $filter('translate')('ERRORS.INVENTORY_LOGS_LOAD')
            return errorService.uiErrorHandler(error || t_message, 0);
          });
        }
      }
    })
    .state('app.inventory.product_work_sheet', {
      url: '/product_work_sheet',
      controller: 'ProductWorkSheetCtrl',
      controllerAs: 'pwsCtrl',
      templateUrl: 'templates/marketplace/inventory/product_work_sheet.html',
      data: {
        'title': 'Inventory Product Work Sheet',
        'confirm_navigation': {'element_exists': ['.row-dirty'], 'type': 'LeavePage'},
        'action_bar_directive': 'product-work-sheet-action-bar',
      }
    })
    .state('app.inventory.log_sheets', {
      'url': '/logsheets',
      'controller': 'sowLogSheetController',
      'controllerAs': 'lsCtrl',
      'templateUrl': 'sow-logsheets/logsheets.html',
      'data': {
        'title': 'Inventory Log Sheets',
        'confirm_navigation': {
          'element_exists': ['.row-image'],
          'type' : 'CancelUpdate',
          'requires_feature': 'inventory_log_sheets',
        },
      },
    })
    .state('app.inventory.activity_pages', {
      url: '/activity_pages/:filterType',
      controller: 'inventoryActivityController',
      templateUrl: 'templates/marketplace/inventory/views/inventory-activity-pages.html',
      data: {
        'title': 'Inventory Activity'
      }
    })
    .state('app.inventory.add-new-product', {
      url: '/add-new-product',
      controller: 'inventoryAddNewProductController',
      controllerAs: 'inAddCtrl',
      templateUrl: 'templates/marketplace/inventory/views/inventory-add-new-product-pages.html',
      data: {
        'title': 'Inventory Add New Product'
      }
    })
    .state('app.inventory.create-product', {
      url: '/create-product?id?name?category?manu?img?sds?barcodes?manu_id?vendor_name',
      controller: 'inventoryCreateProductController',
      controllerAs: 'inCreateCtrl',
      templateUrl: 'templates/marketplace/inventory/views/inventory-create-product-pages.html',
      data: {
        'title': 'Inventory Create New Product'
      }
    });
});
})();

(function () {
  'use strict';

  angular.module('sowMarketplace')
    .directive('mktProductCard', marketplaceProductCardDirective);

  function marketplaceProductCardDirective() {
    return {
      restrict: 'E',
      templateUrl: 'sow-mkt/directives/mkt-product-card.html',
      controller: 'marketplaceCardController',
      controllerAs: 'mktcCtrl',
      scope: {
        listName: '<',
        pageNumber: '<',
        product: '<',
        rank: '<',
      },
    };
  }

})();

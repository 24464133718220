(function () {
  'use strict';

  angular.module('sowInventory')
  .controller('sowInventoryListController', sowInventoryListController);

  function sowInventoryListController ($scope, $rootScope, $q, $timeout, $location, $anchorScroll, $stateParams, $filter, inventoryItemService, inventoryHelperService, sowFilterService, AccessService) {
    var ctrl = this;

    $scope.filtered_items = [];
    ctrl.displayItems = [];
    ctrl.current_item_opened = false;
    ctrl.current_item_scrolled = false;
    ctrl.show_scroll_btn = false;
    
    var generateTooltip = inventoryHelperService.generateTooltip;
    ctrl.checkAllItems = checkAllItems;
    ctrl.checkedItems = inventoryItemService.checkedItems;
    ctrl.openFiltersSidenav = openFiltersSidenav;
    ctrl.scrollToTop = scrollToTop;
    ctrl.deselectOption = sowFilterService.deselectOption;
    ctrl.clearAll = sowFilterService.clearAll;
    ctrl.applyFilters = applyFilters;

    ctrl.VS_OPTIONS = {
      'scrollParent': '#appContainer',
      'size': 72,
      'scroll-margin': 72*15,
    };

    $scope.group = {
      "displayName": "All General Inventory",
      "type": "all",
      "name": "all",
    };

    init();

    return ctrl;

    function init () {
      $scope.$on('$destroy', clearCheckedItems);
      $scope.$on('inventory-item-updated', updateLocalItem);
      $scope.$on('inventory-upload-end', updateLocalItem);
      $scope.$on('inventory-item-deleted', deleteLocalItem);
      $scope.$on('inventory-checked-items-cleared', function(){
        ctrl.check_all_items = false;
      });
      $scope.$on('gen-inv-apply-filters', applyFilters);
      loadData();
      $scope.$on('system-scrolled', function(event, props) {
        var height = document.getElementById('appContainer').offsetHeight;
        ctrl.show_scroll_btn = props.new > height * 2;
      })
      initParams();
      defineLocks();
    }

    function initParams () {
      if($stateParams.itemId) {
        if(!ctrl.current_item_opened){
          $rootScope.$broadcast('inventory-item-slideout', null, $stateParams.itemId, 'details');
          ctrl.current_item_opened = true;
        }
        if(!ctrl.current_item_scrolled){
          $timeout(function(){
            scrollTo({'id':$stateParams.itemId});
          }, 3000);
        }
      }
    }

    function setupFilters () {
      var t_manufacturer = $filter('translate')('COMMON.MANUFACTURER');
      var t_category = $filter('translate')('COMMON.CATEGORY');
      var t_subcategory = $filter('translate')('COMMON.SUBCATEGORY');
      var t_supplier = $filter('translate')('COMMON.SUPPLIER');
      var t_location = $filter('translate')('COMMON.LOCATION');
      var inventory_filter_groups = [
        {
          'label': 'Search',
          'type': 'search',
          'search_properties': ['name', 'sku', 'manufacturer_name', 'supplier'],
          'search_query': ''
        },
        {
          'label': t_manufacturer,
          'key': 'manufacturer_name',
          'open': false
        },
        {
          'label': t_category,
          'key': 'category_name',
          'open': false
        },
        {
          'label': t_subcategory,
          'key': 'subcategory_name',
          'open': false
        },
        {
          'label': t_supplier,
          'key': 'supplier',
          'open': false
        },
        {
          'label': t_location,
          'key': 'locations',
          'list_key': 'name',
          'open': false
        },
      ];
      $scope.filter_state = sowFilterService.getFilterStateFromData($scope.itemsMap, inventory_filter_groups);
      // $rootScope.$broadcast('gen-inv-filters-data', $scope.filter_state);
    }

    function applyFilters () {
      // ctrl.search_input = '';
      $scope.filtered_items = sowFilterService.getFilteredItems($scope.itemsMap, $scope.filter_state);
      var applied_filters = findAppliedFilters();
      $scope.applied_filters = applied_filters;
      $scope.number_of_applied_filters = Object.keys(applied_filters).length;
    }

    function findAppliedFilters() {
      var applied_filters = {};
      _.each($scope.filter_state, function(item) {
        _.each(item.options, function(option, key) {
          if (option.active) {
            applied_filters[key] = option;
          }
        })
      })
      return applied_filters;
    }

    /** 
     * Opens the filters slideout. 
     * 
     * @event openSlideout"inventory-filter-slideout"
     * @return {*} 
    */ 
    function openFiltersSidenav() {
      $rootScope.$broadcast('gen-inv-filters-open');
    }

    /** 
     * Scrolls to the top of the list. 
     * 
     * @return {*} 
    */ 
    function scrollToTop() {
      scrollTo({id: 'top-of-gen-inv'});
    }

    function loadData () {
      ctrl.loading = true;
      $q.all([
        fetchPoData(),
      ])
      .then(function(responses){
        updateDisplayItems();
        setupFilters();
        applyFilters();
      })
      .finally(function(){
        ctrl.loading = false;
        initParams();
      });
    }

    function fetchPoData () {
      return inventoryItemService.getInventoryPOStatus().then(function (poStatusList) {
        ctrl.itemPoStatus = poStatusList;
      });
    }

    function updateDisplayItems () {
      // var items = $scope.getItems($scope.group, true);

      var items = _.values($scope.itemsMap);

      ctrl.displayItems = _.chain(items)
      .filter(function (item) {
        var filter_condition = (item.type === 'General Item' || item.type === 'Marketplace Item');
        if (filter_condition) {
          _.set(item, 'po_status', _.get(ctrl, 'itemPoStatus['+item.id+']', null));
          setPoInfo(item);
        }
        _.set(item, 'UI.is_discontinued', inventoryItemService.isItemDiscontinued(item));
        return filter_condition;
      })
      .value();

      $scope.itemsMap = _.keyBy(ctrl.displayItems, 'id');
      
      // adds current item to the list
      if ($scope.currentItem) {
        addCurrentItem(items, ctrl.displayItems);
      }
      // applySearch();
    }

    function scrollTo (item){
      $location.hash(item.id);
      $anchorScroll();
    }

    function setPoInfo (item) {
      var tooltip_text = null;
      if(!item.po_status)
        return;

      var active_qty = _.get(item, 'po_status.Active.length', 0);
      var draft_qty = _.get(item, 'po_status.Draft.length', 0);

      if (active_qty > 0) {
        tooltip_text = generateTooltip(item.po_status.Active);
      } else if (draft_qty > 0) {
        tooltip_text = generateTooltip(item.po_status.Draft);
      }
      _.set(item, 'UI.po_tooltip', tooltip_text);
      return item;
    }

    function addCurrentItem (all_items, display_items) {
      var current_item_id = $scope.currentItem;
      var current_item = _.find(all_items, current_item_id);
      var is_in_display_items = _.find(display_items, current_item_id);
      if (!is_in_display_items) {
        // adds item to the top of the list, to prevent issues with scrolling to bottom 
        // (it will reorder once you scroll down to its place).
        display_items.unshift(current_item);
      }
      var is_in_filtered_items = _.find($scope.filtered_items, current_item_id);
      if (!is_in_filtered_items) {
        $scope.filtered_items.unshift(current_item);
      }
      $scope.itemsMap[current_item_id] = current_item;
      reloadData();
    }

    function updateLocalItem (event, item) {
      if (!item) return;
      var display_item_index = _.findIndex(ctrl.displayItems, ['id', item.id]);
      item = setAlertInfo(item);
      if (display_item_index >= 0) {
        // ctrl.displayItems[display_item_index] = item;
        _.extend(ctrl.displayItems[display_item_index], item);
      } else {
        ctrl.displayItems.push(item);
      }
      var filtered_items_index = _.findIndex($scope.filtered_items, ['id', item.id]);
      if (filtered_items_index >= 0) {
        // $scope.filtered_items[filtered_items_index] = item;
        _.extend($scope.filtered_items[filtered_items_index], item);
      } else {
        $scope.filtered_items.push(item);
      }
      $scope.itemsMap[item.id] = item;
      reloadData();
    }

    function reloadData () {
      updateDisplayItems();
      applyFilters();
    }

    function setAlertInfo (item) {
      item = item || $scope.item;
      var actual_qty = inventoryItemService.calculateInventoryOnHand(item);
      if (item.minimum_level > 0 && actual_qty <= item.minimum_level) {
        _.set(item, 'errors.below_minimum_level', true);
      }
      if ( item.expiry_date ) {
        var expiry_limit = moment().add(30, 'days');
        var is_expired = moment().isSameOrAfter(moment(item.expiry_date));
        var is_soon = moment(item.expiry_date).diff(expiry_limit, 'days') <= 30;
        _.set(item, 'errors.expired', is_expired);
        _.set(item, 'errors.expiring_soon', is_soon);
      }
      return item;
    }

    function deleteLocalItem (event, item) {
      var display_index = _.findIndex(ctrl.displayItems, ['id', item.id]);
      if (display_index >= 0) {
        ctrl.displayItems.splice(display_index,1);
      }
      var filtered_index = _.findIndex($scope.filtered_items, ['id', item.id]);
      if (filtered_index >= 0) {
        $scope.filtered_items.splice(filtered_index,1);
      }
      delete $scope.itemsMap[item.id];
      reloadData();
    }

    function checkAllItems ($event) {
      if($event.stopPropagation)
        $event.stopPropagation();

      ctrl.check_all_items = !ctrl.check_all_items;

      var list = $scope.filtered_items;

      _.each(list, function(item){
        if(item.UI.checked !== ctrl.check_all_items)
          inventoryItemService.checkItem(item);
      });
    }

    function clearCheckedItems () {
      return inventoryItemService.clearCheckedItems();
    }

    function defineLocks () {
      const add_new_lock_condition = AccessService.getProperty("gen_inv.disable_add_new", false);
      ctrl.disabled_add_new = add_new_lock_condition;

      ctrl.has_multi_supplier_selection = AccessService.getProperty('shopping_list.multi_supplier_selection');
      ctrl.hasCartAccess = AccessService.hasCartAccess();
    }
  }
}());

(function() {
  "use strict";

  angular
    .module("app.marketplace.ui.inventory")
    .controller(
      "inventoryAddNewProductController",
      inventoryAddNewProductController
    );

  function inventoryAddNewProductController(
    $state,
    inventoryItemService,
    mktHelperService
  ) {
    var ctrl = this;

    ctrl.searchBy = {
      product_name: ""
    };
    ctrl.localize = mktHelperService.getLocalizedProp;

    ctrl.init = function() {};

    ctrl.searchingProduct = function() {
      inventoryItemService
        .searchingProductModel(ctrl.searchBy.product_name)
        .then(function(rs) {
          ctrl.data = rs.hits;
        });
    };

    ctrl.createProduct = function(item) {
      let parameters = {
        id: '',
        name: '',
        img: '',
        manu: '',
        manu_id: '',
        category: '',
        sds: '',
        vendor_name: ''
      };

      if (item) {
        parameters = {
          id: item.id,
          name: item.name,
          img: item.main_image,
          manu: item.manufacturer.name,
          manu_id: item.manufacturer.id,
          category: item.parent_category.id,
          sds: item.sds_url,
          barcodes: item.barcodes,
          vendor_name: _.get(item, 'vendor_inventory.vendor.name')
        };
      } else {
        parameters.name = ctrl.searchBy.product_name;
      }
      $state.go("app.inventory.create-product", parameters);
    };

    ctrl.init();
  }
})();

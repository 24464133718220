(function () {
  'use strict';

  /** @ngInject */
  function sowApprovalHelperService ($mdDialog, sowApprovalService, accountService) {
    let service = this;

    service.openDialog = openDialog;
    service.checkApprovalAndDialog = checkApprovalAndDialog;

    /**
     * Show Approval Request dialog
     *
     * @param {*} ev Event (can be null)
     * @param {*} reviewers List of request reviewers
     * @param {*} user_id ID of the user making the request (ideally should be current logged in user)
     * @param {*} approval_type Accepted values: ['purchase_order', 'cart_order']
     * @param {*} reference_id ID of the PO / mkt order
     * @return {*} 
     */
    function openDialog (ev, reviewers, user_id, approval_type, reference_id) {
      return $mdDialog.show({
        'controller': 'sowApprovalDialogCtrl',
        'controllerAs': 'ardCtrl',
        'templateUrl': 'sow-approvals/components/approval-dialog.html',
        'targetEvent': ev,
        'parent': angular.element(document.body),
        'clickOutsideToClose': true,
        'bindToController': true,
        'locals': {
          'reviewers': reviewers,
          'request': {
            'user_id': user_id,
            'approval_type': approval_type,
            'reference_id': reference_id,
          }
        },
      });
    }

    // optional convenience method for checking & showing dialog in 1 call

    /**
     * Checks if an order needs approval using current user's ID, then automatically returns the dialog Fn if it does
     *
     * @param {*} approval_type Accepted values: ['purchase_order', 'cart_order']
     * @param {*} reference_id ID of the PO / mkt order
     * @return {*} 
     */
    function checkApprovalAndDialog (approval_type, reference_id) {
      const current_user = accountService.get();
      return sowApprovalService.getAuthorizationAndApprovers(current_user.id, approval_type, reference_id)
      .then((auth_response) => {
        if (auth_response.need_approval) {
          return openDialog(null, auth_response.approvers_list, current_user.id, approval_type, reference_id);
        } else {
          // TO-DO: what should return from this? truthy value or promise resolve?
          return auth_response;
        }
      });
    }
  }

  angular.module('sowApprovals')
  .service('sowApprovalHelperService', sowApprovalHelperService);

})();

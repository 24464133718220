(function () {
  'use strict';
  angular.module('sowSterilization')
  .controller('stznPendingTestDetailController', stznPendingTestDetailController);

  function stznPendingTestDetailController ($scope, $filter, $mdDialog, $interval, $rootScope, stznHelperService) {
    /*jshint validthis:true*/
    var ctrl = this;
    ctrl.saving_record = false;
    ctrl.countdown_label = '';
    ctrl.timer_promise = null;

    ctrl.cancelDialog = cancelDialog;
    ctrl.closeDialog = closeDialog;
    ctrl.getDurationLabel = getDurationLabel;
    ctrl.getIncubationTimeLabel = getIncubationTimeLabel;
    ctrl.getCountdownLabel = getCountdownLabel;

    init();

    return ctrl;

    function init () {
      setCountdownLabel();
      // set up the end time and date to display on screen
      const end_date = stznHelperService.setEndDateUsingIncubationData({
        tested_at: ctrl.record_data.tested_at,
        incubator_time: ctrl.record_data.incubator_time,
      })
      _.set(ctrl.record_data, 'result_recorded_at', end_date);

      ctrl.timer_promise = $interval(setCountdownLabel, 1000);
      $scope.$on('$destroy', function(){
        $interval.cancel(ctrl.timer_promise);
      });
    }

    function setCountdownLabel () {
      ctrl.countdown_label = getCountdownLabel();
    }

    function cancelDialog () {
      return $mdDialog.cancel();
    }

    function closeDialog (result) {
      return $mdDialog.hide(result);
    }

    function getDurationLabel () {
      var time = ctrl.record_data.incubator_time;
      var t_minutes = $filter('translate')('TIME.MINUTES');
      var t_hour = $filter('translate')('TIME.HOUR');
      var t_hours = $filter('translate')('TIME.HOURS');
      var options = {
        "10":'10 {0}'.format(t_minutes),
        "24":'24 {0}'.format(t_minutes),
        "60":'1 {0}'.format(t_hour),
        "180":'3 {0}'.format(t_hours),
        "600":'10 {0}'.format(t_hours),
        "1440":'24 {0}'.format(t_hours),
      };

      return _.get(options, time, 'N/A');
    }

    function getIncubationTimeLabel () {
      return moment(ctrl.record_data.tested_at).format('MMMM DD, YYYY  h:mm A');
    }

    function getCountdownLabel () {
      var now = moment();
      var fromDate = moment(ctrl.record_data.tested_at);
      var duration = moment.duration({'minutes': ctrl.record_data.incubator_time});
      var toDate = moment(fromDate).add(duration);

      if(now.isSameOrAfter(toDate)){
        finishIncubation();
      }

      var hourDiff = toDate.diff(now,'hours');
      var minutesDiff = toDate.diff(now,'minutes');
      var secondsDiff = toDate.diff(now,'seconds');
      var hourDuration = (Math.floor(minutesDiff/60)+"").padStart(2,'0');
      var minuteDuration = ((minutesDiff % 60)+"").padStart(2,'0');
      var secondsDuration = ((secondsDiff % 60)+"").padStart(2,'0');

      return "{0}:{1}:{2}".format(hourDuration, minuteDuration, secondsDuration);
    }

    function finishIncubation () {
      ctrl.record_data.result = 'Incubation Complete';
      $interval.cancel(ctrl.timer_promise);
      $rootScope.$broadcast('stzn-incubation-complete', ctrl.record_data);
      cancelDialog();
    }

  }

}());

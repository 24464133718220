(function () {
  'use strict';

  angular
    .module('app.marketplace.elements')
    .service('PurchaseOrderService', PurchaseOrderService);

  function PurchaseOrderService ($window, $document, $timeout, $filter, $state, elementService, officeService, inventoryItemService, membershipService, errorService) {
    /*jshint validthis: true */
    var service = this;
    var lastStatus = null;

    service.get = getPurchaseOrder;
    service.getAll = getAll;
    service.getPoName = getPoName;
    service.getAllByStatus = getAllByStatus;
    service.getPageByStatus = getPageByStatus;
    service.getPageByQuery = getPageByQuery;
    service.getAllByPage = getAllByPage;
    service.create = createNewOrder;
    service.remove = deletePurchaseOrder;
    service.count = getPurchaseOrderCount;
    service.saveDraft = saveDraft;
    service.makeActive = makeActive;
    service.receiveItems = receiveItems;
    service.cancelItems = cancelItems;
    service.getMonths = getMonths;
    service.editItemActionDate = editItemActionDate;
    service.undoLastAction = undoLastAction;
    service.editActive = editActive;
    service.email = emailPurchaseOrder;
    service.deletePOItem = deletePOItem;
    service.printList = printList;
    service.print = print;
    service.fetchEvents = fetchEvents;
    service.getHRID = getHRID;

    service.updateLastStatusFilter = updateLastStatusFilter;
    service.getLastStatusFilter = getLastStatusFilter;

    return service;

    function getLastStatusFilter () {
      return lastStatus;
    }

    function updateLastStatusFilter (status) {
      lastStatus = status;
    }

    //
    // Delete a Purchase Order Item
    //
    function deletePOItem (poItem) {
      var options = {
        'endpoint': 'delete_po_item',
        'purchase_order_item_id': poItem.id,
      };

      return elementService.callEndpoint('purchaseOrder', options).then(function (response) {
        var po = _.get(response, 'data');
        elementService.create('purchaseOrder', po);
        return po;
      });
    }

    function emailPurchaseOrder (po, recipient, send_copy) {
      var options = {
        'endpoint': 'email_purchase_order',
        'payload': {
          'purchase_order_id': po.id,
          'recipient': recipient,
          'send_copy': !!(send_copy),
        }
      };

      return elementService.callEndpoint('purchaseOrder', options);
    }

    //
    // Edit an Active Purchase Order
    //
    function editActive (po) {
      var options = {
        'endpoint': 'edit_active',
        'payload': {
          'purchase_order_id': po.id,
          'name': po.name,
          'po_date': po.po_date,
          'created_by_id': po.created_by.id,
          'notes': po.notes,
          'shipping_cost': po.shipping_cost,
          'items': po.items,
          'supplier': po.supplier,
          'additional_fees': po.additional_fees,
          'discounts': po.discounts,
        },
      };
      return elementService.callEndpoint('purchaseOrder', options).then(function (response) {
        var po = response.data;
        elementService.create('purchaseOrder', po);
        return po;
      });
    }

    //
    // Undo the last action (Cancel/Receive) of a Purchase Order Item.
    //
    function undoLastAction (po_item) {
      var options = {
        'endpoint': 'undo_last_action',
        'purchase_order_item_id': po_item.id,
      };
      var shouldUpdateInventory = (po_item.last_action === 'Received');

      return elementService.callEndpoint('purchaseOrder', options).then(function (response) {
        var po = response.data;
        elementService.create('purchaseOrder', po);

        if (shouldUpdateInventory) {
          inventoryItemService.getItem(po_item.inventory_item_id, {'force': true});
        }

        return po;
      });
    }

    //
    // Change the date of the last action (Received / Cancelled) of a Purchase
    // Order Item.
    //
    function editItemActionDate (po_item, date) {
      var options = {
        'endpoint': 'change_date',
        'purchase_order_item_id': po_item.id,
        'date': date,
      };
      return elementService.callEndpoint('purchaseOrder', options).then(function (response) {
        var po = response.data;
        elementService.create('purchaseOrder', po);
        return po;
      });
    }

    function print (po) {
      var options = {
        'endpoint': 'print_po',
        'purchase_order_id': po.id,
      };
      return elementService.callEndpoint('purchaseOrder', options).then(function (response) {
        var filename = '';
        var disposition = response.headers('Content-Disposition');

        if (disposition && disposition.indexOf('attachment') !== -1) {
          var filenameRE = /filename=[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          var matches = filenameRE.exec(disposition);
          if (matches !== null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '');
          }
        } else {
          filename = 'Purchase Order Report.pdf';
        }

        var type = response.headers('Content-Type');
        var blob = new Blob([response.data], {type: type});

        if (typeof $window.navigator.msSaveBlob !== 'undefined') {
          $window.navigator.msSaveBlob(blob, filename);
        } else {
          var URL = $window.URL || $window.webkitURL;
          var downloadUrl = URL.createObjectURL(blob);

          if (filename) {
            // use HTML5 a[download] attribute to specify a filename
            var a = angular.element('<a>');
            var downloadSupported = (typeof document.createElement('a').download) !== 'undefined';

            // safari doesn't support this yet
            if (!downloadSupported) {
              //
              // Attempt to use <a target="_blank"> to open it in a new tab.
              // Result: FAILURE. Chrome pop-up blocker captures this.
              //
              // a.attr('href', downloadUrl);
              // a.attr('target', '_blank');
              // angular.element(document.body).after(a);
              // $timeout(function () { a[0].click(); });

              //
              // Attempt to use $window.open to open in a new tab.
              // Result: FAILURE. Chrome pop-up blocker captures this.
              //
              // $window.open(downloadUrl);

              $window.location = downloadUrl;
            } else {
              a.attr('href', downloadUrl);
              a.attr('download', filename);
              angular.element(document.body).after(a);

              // For some reason AngularJS's .triggerHandler() doesn't actually
              // download the contents like the DOM's .click() function does.
              // The only drawback is that AngularJS complains loudly when we
              // use .click() because it's in the middle of a $digest cycle, so
              // we need to wrap a $timeout() around it to appease the
              // AngularJS gods.
              $timeout(function () { a[0].click(); });
            }
          } else {
            $window.location = downloadUrl;
          }
        }
        return response.data;
      });
    }

    function printList (filters) {
      var office = officeService.get();
      var options = {
        'endpoint': 'print_po_list',
        'office_id': office.id,
        'po_month': filters.po_month === 'all' ? '' : filters.po_month,
        'created_by': filters.created_by === 'all' ? '' : filters.created_by,
        'status': filters.status,
        'order_id': filters.order_id,
        'supplier': filters.supplier,
      };
      return elementService.callEndpoint('purchaseOrder', options).then(function (response) {
        var filename = '';
        var disposition = response.headers('Content-Disposition');

        if (disposition && disposition.indexOf('attachment') !== -1) {
          var filenameRE = /filename=[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          var matches = filenameRE.exec(disposition);
          if (matches !== null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '');
          }
        } else {
          filename = 'Report.pdf';
        }

        var type = response.headers('Content-Type');
        var blob = new Blob([response.data], {type: type});

        if (typeof $window.navigator.msSaveBlob !== 'undefined') {
          $window.navigator.msSaveBlob(blob, filename);
        } else {
          var URL = $window.URL || $window.webkitURL;
          var downloadUrl = URL.createObjectURL(blob);

          if (filename) {
            // use HTML5 a[download] attribute to specify a filename
            var a = angular.element('<a>');
            var downloadSupported = (typeof document.createElement('a').download) !== 'undefined';

            // safari doesn't support this yet
            if (!downloadSupported) {
              //
              // Attempt to use <a target="_blank"> to open it in a new tab.
              // Result: FAILURE. Chrome pop-up blocker captures this.
              //
              // a.attr('href', downloadUrl);
              // a.attr('target', '_blank');
              // angular.element(document.body).after(a);
              // $timeout(function () { a[0].click(); });

              //
              // Attempt to use $window.open to open in a new tab.
              // Result: FAILURE. Chrome pop-up blocker captures this.
              //
              // $window.open(downloadUrl);

              $window.location = downloadUrl;
            } else {
              a.attr('href', downloadUrl);
              a.attr('download', filename);
              angular.element(document.body).after(a);

              // For some reason AngularJS's .triggerHandler() doesn't actually
              // download the contents like the DOM's .click() function does.
              // The only drawback is that AngularJS complains loudly when we
              // use .click() because it's in the middle of a $digest cycle, so
              // we need to wrap a $timeout() around it to appease the
              // AngularJS gods.
              $timeout(function () { a[0].click(); });
            }
          } else {
            $window.location = downloadUrl;
          }
        }
        return response.data;
      });
    }

    //
    // Return a list of month-year pairs to be used in the list filter.
    //
    function getMonths () {
      var office = officeService.get();
      var options = {
        'endpoint': 'filter_months',
        'office_id': office.id,
      };
      return elementService.callEndpoint('purchaseOrder', options).then(function (response) {
        return response.data;
      });
    }

    //
    // Receive a Batch of Items
    //
    function receiveItems (purchaseOrder, items, date, updateSupplier, updateInventory, disable_supplier_price_update) {
      function buildItemUpdate (item) {
        return {
          id: item.id,
          quantity: item.receive_quantity,
          // If the user changes the buying method and tries to receive it, 
          // we have the history of what he ordered inside order_package_quantity(po item level)
          order_package_quantity: item.order_package_quantity,
        };
      }

      var options = {
        'endpoint': 'receive_items',
        'forceAPI': true,
        'payload': {
          'purchase_order_id': purchaseOrder.id,
          'date_received': date,
          'update_supplier': !!updateSupplier,
          'update_inventory': updateInventory,
          'items': _.map(items, buildItemUpdate),
          disable_supplier_price_update,
        }
      };

      return elementService.callEndpoint('purchaseOrder', options)
      .then(function (response) {
        var po = response.data;
        elementService.create('purchaseOrder', po);

        _.forEach(items, function (item) {
          inventoryItemService.getItem(item.inventory_item_id, {'force': true})
          .then(function(loaded_item){

            if ( _.size(item.expiry_dates) ) {
              var new_dates = _.map(item.expiry_dates, function(date){ 
                return {
                  'date': moment.utc(date).format()
                };
              });
              var all_dates = _.uniq( _.concat(loaded_item.expiry_dates, new_dates) );
              _.extend(loaded_item, {'expiry_dates': all_dates});

              // image_src is required when updating a non-marketplace product; if we don't send it, the product image will be removed
              loaded_item.image_src ??= loaded_item.image_url_src;
              inventoryItemService.updateItem(loaded_item);
            }
          });

        });

        return po;
      });
    }

    //
    // Cancel a Batch of Items
    //
    function cancelItems (purchaseOrder, items, date) {
      var buildItemUpdate = function (item) {
        return {
          'id': item.id,
          'quantity': item.quantity,
        };
      };

      var options = {
        'endpoint': 'cancel_items',
        'payload': {
          'purchase_order_id': purchaseOrder.id,
          'date': date,
          'items': _.map(items, buildItemUpdate),
        }
      };

      return elementService.callEndpoint('purchaseOrder', options).then(function (response) {
        var po = response.data;
        elementService.create('purchaseOrder', po);
        return po;
      });
    }

    //
    // Fetch a single PurchaseOrder by ID.
    //
    function getPurchaseOrder (purchaseOrderId) {
      var options = {
        'forceAPI': true
      };
      return elementService.get('purchaseOrder', purchaseOrderId, options);
    }

    //
    // Move a PurchaseOrder from Draft to Active state.
    //
    function makeActive (po) {
      return elementService.callEndpoint('purchaseOrder', {
        'endpoint': 'make_active',
        'purchase_order_id': po.id,
      }).then(function (response) {
        var po = _.get(response, 'data');
        elementService.create('purchaseOrder', po);
        return po;
      });
    }

    //
    // Get a list of all PurchaseOrders
    //
    function getAll () {
      var options = {'forceAPI': true};
      return elementService.getElements('purchaseOrder', options).then(function (result) {
        return result;
      });
    }

    //
    // Save a PurchaseOrder as a Draft
    //
    // Parameters:
    // - po.purchase_order_id
    // - po.office_id
    // - po.name
    // - po.shipping_cost
    // - po.supplier_id
    // - po.supplier_name
    // - po.po_date
    // - po.created_by_id
    // - po.notes
    // - po.items
    //
    function saveDraft (po, tax_exempt) {
      // If we weren't passed a boolean tax_exempt prop, we'll set that
      // prop to the value of the corresponding user property. Pass a bool
      // if you'd like to manually set the tax_exempt status of this PO.
      if (!_.isBoolean(tax_exempt)) {
        var membership = membershipService.get();
        var tax_exempt_condition = _.get(membership, 'user_properties.office.tax_exempt', false);
        tax_exempt = tax_exempt_condition;
      }
      return elementService.callEndpoint('purchaseOrder', {
        'endpoint': 'save_draft',
        'data': {
          'purchase_order_id': _.get(po, 'id'),
          'office_id': _.get(po, 'office_id'),
          'name': _.get(po, 'name'),
          'supplier_id': _.get(po, 'supplier.id'),
          'supplier_name': _.get(po, 'supplier.name'),
          'po_date': _.get(po, 'po_date'),
          'created_by_id': _.get(po, 'created_by.id'),
          'notes': _.get(po, 'notes'),
          'shipping_cost': _.get(po, 'shipping_cost'),
          'items': _.map(_.get(po, 'items'), function(item){ return _.omit(item, ['UI','searchText']);}) || [],
          'sales_rep_id': _.get(po, 'supplier.sales_rep_id'),
          'tax_exempt': tax_exempt,
          'hrid': _.get(po, 'hrid'),
          'additional_fees': _.get(po, 'additional_fees'),
          'discounts': _.get(po, 'discounts'),
          'shipping_option_selected': _.get(po, 'shipping_option_selected'),
          office_vendor_membership_id: _.get(po, 'account_number.id', null),
        }
      }).then(function (response) {
        var po = _.get(response, 'data');
        elementService.create('purchaseOrder', po);
        return po;
      }).catch(error => {
        /* if the API rejected because of a duplicate HRID, show an error toast and
        redirect the user to the corresponding existing draft purchase order */
        if (error?.internal_code === 'PO_HRID_DUPLICATE' && error.extra_data?.po_id) {
          errorService.showErrorToast($filter('translate')('ORDERS.THERE_WAS_A_PROBLEM'));
          $state.go('app.orders.purchase.draft', {poId: error.extra_data.po_id});
        }
        throw error;
      });
    }

    //
    // Get the count of all PurchaseOrders for this Office.
    //
    function getPurchaseOrderCount () {
      return elementService.callEndpoint('purchaseOrder', {
        'endpoint': 'count',
      }).then(function (response) {
        return response.data;
      });
    }

    function getHRID () {
      return elementService.callEndpoint('purchaseOrder', {
        'endpoint': 'hrid',
      }).then(function (response) {
        return response.data;
      });
    }

    //
    // Get a list of all PurchaseOrders with the given status.
    //
    function getAllByStatus (status) {
      var options = {
        'forceAPI': true,
        'filters': {'status': status},
      };
      return elementService.getElements('purchaseOrder', options).then(function (result) {
        return result;
      });
    }

    function getPageByStatus(status, page_num = 1) {
      const office_id = officeService.get().id;
      const options = {
        endpoint: 'multiple_page',
        forceAPI: true,
        filters: { office_id, status, page_num },
      };
      return elementService.getElements('purchaseOrder', options)
        .then(result => result);
    }

    function getPageByQuery(status, page_num, query_term, search_param) {
      const office_id = officeService.get().id;
      const options = {
        endpoint: 'get_page_by_query',
        forceAPI: true,
        filters: { office_id, status, page_num, query_term, search_param }
      }
      return elementService.getElements('purchaseOrder', options)
        .then(result => result);
    }

    //
    // Get List of PurchaseOrders paginated by page_size and page_number
    //
    function getAllByPage (page_num, page_size) {
      var options = {
        'endpoint': 'multiple_page',
        'filters': {'office_id': officeService.get().id, 'page_num': page_num, 'page_size': page_size},
        'forceAPI': true,
      };
      return elementService.getElements('purchaseOrder', options).then(function (result) {
        return result;
      });
    }

    //
    // Create a new PurchaseOrder.
    //
    function createNewOrder (order) {
      order.created_by_id = _.get(order, 'created_by.id');
      order.supplier_name = _.get(order, 'supplier.name');
      order.office_id = officeService.get().id;

      return elementService.callEndpoint('purchaseOrder', {
        'endpoint': 'new_po',
        'purchaseOrder': order,
      }).then(function (response) {
        var po = _.get(response, 'data');
        elementService.create('purchaseOrder', po);
        return po;
      });
    }

    //
    // Delete a PurchaseOrder.
    //
    function deletePurchaseOrder (order, revertInventory) {
      revertInventory = !!revertInventory;
      order.UI.revertInventory = revertInventory;
      return elementService.remove('purchaseOrder', order, true);
    }

    /** 
     * Generates a string of `PO-{count or HRID}`
     * in the case of a count number with less then 3 digits, we pad it with zeros
     * 
     * @param {string | number} number
     * 
     * @return {string} 
    */
    function getPoName(number, po_type = 'general') {
      const prefix = 'PO-';
      switch(po_type) {
        case 'edi':
          return number;
        default:
        case 'general':
          number = _.padStart(number, 3, '0');
          return `${prefix}${number}`;
      }
    }

    /** 
     * Fetches events associated with the purchase order
     * whose ID was passed as an argument. 
     * 
     * @param {Type} po_id 
     * 
     * @return {Type} 
    */
    function fetchEvents(po_id) {
      const office_id = officeService.get().id;

      if (!po_id || !office_id) {
        return null;
      }

      return elementService.callEndpoint('purchaseOrder', {
        endpoint: 'fetch_events',
        data: {
          office_id,
          po_id,
        },
      })
        .then(({ data }) => (data))
        .catch(err => {
          console.error(err);
          return err.message;
        });
    }

  }

}());

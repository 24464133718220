angular.module("app.marketplace.ui.inventory")


  .controller("itemOnHandAddModalController",
    function ($scope, $filter, $rootScope, $timeout, elementService, sgToast, $mdDialog, inventoryItemService, errorService) {

      $scope.max_quantity_limit = 99999999;
      $scope.item['in'] = {
        'in': 1
      };

      $scope.cancel = function () {
        $mdDialog.cancel();
      };

      $scope.submit = function () {
        if ($scope.itemQuantityAdd.$invalid) {
          return false;
        }

        if ($scope.item['in'].vendor && $scope.item['in'].vendor.name) {
          $scope.item['in'].vendor_name = $scope.item['in'].vendor.name;
        } else if (!$scope.item['in'].vendor && $scope.item.vendorSearchText) {
          $scope.item['in'].vendor_name = $scope.item.vendorSearchText;
        }

        $scope.submitting = true;
        $scope.item.UI.do_save = true;
        $timeout(function () { //Force digest so do_save and do its stuff (save other changes)
          inventoryItemService.addStock($scope.item, $scope.locations)
            .then(function (inventory_item) {
              $scope.submitting = false;
              var t_message = $filter('translate')('INVENTORY.UPDATED_ON_HAND');
              sgToast.showSimple(t_message);
              $mdDialog.hide();
              // the only relevant properties which the API updates in this call are on_hand
              // and expiry_dates, so we'll include these in the broadcast to ensure that any
              // unsaved changes the user has made in the PD slideout are not overridden
              const { on_hand, expiry_dates } = inventory_item;
              const updated_properties = { on_hand, expiry_dates };
              $rootScope.$broadcast('inventory-item-updated', inventory_item, updated_properties);
            }).catch(function (error) {
              $scope.submitting = false;
              var t_message = $filter('translate')('ERRORS.INVENTORY_REMOVE')
              errorService.uiErrorHandler(error || t_message, 0);
            });
        });
      };

      $scope.onQuantityChange = function () {
        /* 
          Quantity value should be less than than 8 digits (99999999) and 
          we use the selected item on hand value to calculate the max value
         */
        if (_.toNumber($scope.item['in']?.in) + $scope.item.on_hand > $scope.max_quantity_limit) {
          $scope.itemQuantityAdd.in.$setValidity('max', false);
          $scope.itemQuantityAdd.in.$setTouched();
        } else {
          $scope.itemQuantityAdd.in.$setValidity('max', true)
        }
      }

      $scope.locationSearch = function (query) {
        if (!query) {
          return $scope.locations;
        }
        var results = [];

        _.map($scope.locations, function (location, l) {
          if (location.name.toLowerCase().indexOf(query.toLowerCase()) > -1) {
            results.push(location);
          }
        });
        return results;
      };
      $scope.vendorSearch = function (name) {
        if (!name) {
          $scope.vendors = [];
          return $scope.vendors;
        }
        return elementService.get("vendor", null, { "endpoint": "search", "name": name })
          .then(function (vendors) {
            $scope.vendors = [];
            _.map(vendors, function (vendor) {
              $scope.vendors.push(vendor);
            });
            return $scope.vendors;
          });
      };
    });

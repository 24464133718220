(function () {
  'use strict';

  angular
    .module('app.marketplace.system')
    .controller('SystemController', SystemController);

  //
  // Controls and manages top level task app
  //
  function SystemController($scope, $rootScope, $q, $state, $stateParams, $window, $timeout, membershipService, sessionService, $mdSidenav, TrialEndDialog, StartupDialogService, sowLanguageService) {
    var ctrl = this;
    var membershipWatch;

    $scope.$state = $state;
    $scope.$window = $window;

    $scope.isIE11 = $window.isIE11;
    $scope.isIE10 = $window.isIE10;

    $scope.logout = logout;
    $scope.changeOffice = changeOffice;
    $scope.toggleShoppingCart = toggleShoppingCart;

    $scope.showLoadingBackdrop = showLoadingBackdrop;
    $scope.hideLoadingBackdrop = hideLoadingBackdrop;

    //TODO reassess could not think of a better controller/service to have this in.
    $scope.$watch('current_medical_field.id', function(newMedicalFieldValue, oldMedicalFieldValue){
      if(newMedicalFieldValue && (!$scope.$root.marketplace_medical_field || newMedicalFieldValue!==oldMedicalFieldValue)){
        $scope.$root.marketplace_medical_field = $scope.config.medical_fields_map[newMedicalFieldValue];
      }
    });
    $scope.$watch(function () {
      var container = document.getElementById('appContainer');
      return container.scrollTop;
    }, function (newScrollValue, oldScrollValue) {
      $rootScope.$broadcast('system-scrolled', { from: oldScrollValue, new: newScrollValue })
    });
    $scope.$watch(() => {
      const container = document.getElementById('appContainer');
      return container.offsetWidth;
    }, (newContainerWidth, oldContainerWidth) => {
      if(newContainerWidth && newContainerWidth !== oldContainerWidth){
        $rootScope.$broadcast('width-updated', { from: oldContainerWidth, new: newContainerWidth })
      }
    });
    $rootScope.$on('membershipService: set-membership', StartupDialogService.showDialogs);

    init();

    return ctrl;

    function init () {
      initializeServices();
      initiateTrialExpiryLogic();

      if ($stateParams.language) {
        sowLanguageService.setLanguage($stateParams.language);
      }
    }

    //
    // Trial Expiry Logic
    //
    function initiateTrialExpiryLogic () {
      membershipWatch = $scope.$watch('current_membership.id', function (newMembershipValue, oldMembershipValue) {
        if (newMembershipValue) {
          membershipWatch();
          var SessionUI = $scope.current_session.UI || {};

          if (!SessionUI.prompted_for_premium_shown && TrialEndDialog.shouldShowDialogOnStartup()) {
            TrialEndDialog.show()['finally'](function () {
              // It's _possible_ that $scope.current_session changed since this
              // code is async from the parent scope, so don't use the variable.
              $scope.current_session.UI.prompted_for_premium_was_shown = true;
            });
          } else if (!SessionUI.prompted_for_premium_shown) {
            SessionUI.prompted_for_premium_was_shown = true;
          }

          SessionUI.prompted_for_premium_shown = true;
        }
      });
    }
    
    // Wait for all core element services (ie. coreElementServices) to be initialized 
    //    then set global flag indicating that services have been initialized
    function initializeServices () {
    }

    function logout () {
      sessionService.logout();
      window.location.href = '/';
    }

    function changeOffice ($event) {
      membershipService.changeCurrentMembership($event);
    }

    function toggleShoppingCart () {
      $mdSidenav('right').toggle();
      $scope.cartNav = $mdSidenav('right');
    }
    
    

    //Functions to hide/show global backdrop
    var timer = false;
    var loadingBackdropShownAt = false;

    function showLoadingBackdrop(){
      timer = $timeout(function () {
        loadingBackdropShownAt = new Date();
        $scope.loadingBackdrop = true;
      }, 300);
    }

    function hideLoadingBackdrop(){
      if (timer) {
        $scope.loadingBackdrop = false;
        $timeout.cancel(timer);
      } else {
        var timeDiff = (new Date()).getTime() - loadingBackdropShownAt.getTime();

        if (timeDiff >= 2000) {
          $scope.loadingBackdrop = false;
        } else {
          return $timeout(function () {
            $scope.loadingBackdrop = false;
          }, 2000 - timeDiff);
        }
      }

      return $q.when(null);
    }

  }
}());

(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.implants')
    .controller('impTableController', impTableController);

  function impTableController ($q, $scope, $rootScope, $state, $stateParams, $mdSidenav, $timeout, $mdDialog,
    sgToast, sowImplantService, $filter, impHelperService, AddLocationsDialog, errorService, referringDoctorsService, membershipService, formService, mktHelperService,
    appEvents, officeService, AccessService, inventoryItemService, PatientService, PatientHelperService, RedirectModalService) {
    /*jshint validthis: true */
    var ctrl = this;
    ctrl.current_table = null;
    ctrl.current_tab = null;
    ctrl.current_child_tab = null;
    ctrl.manufacturer = null;
    ctrl.redirecting = false;
    ctrl.dialogs = ["add-implant", "list-implants", "remove-implant", "confirm-implant-removal", "search-implants"];
    ctrl.table_types = {
      "Implant": {
        product_name: "Implant", title: translate('IMPLANTS.IMPLANTS'), short_name: 'implants', display_name: translate('IMPLANTS.IMPLANT'),
        icon: { svg: "styles/img/implants/implants-icon-v3.svg", color: "blue", },
      },
      "Closure Cap": {
        product_name: "Closure Cap", title: translate('IMPLANTS.COVER_SCREWS'), short_name: 'caps', display_name: translate('IMPLANTS.COVER_SCREW'),
        icon: { svg: "styles/img/implants/caps-icon.svg", color: "purple", },
      },
      "Healing Abutment": {
        product_name: "Healing Abutment", title: translate('IMPLANTS.HEALING_ABUTMENTS'), short_name: 'abutments', display_name: translate('IMPLANTS.HEALING_ABUTMENT'),
        icon: { svg: "styles/img/implants/healing-icon.svg", color: "green", },
      },
      "Bone Graft": {
        product_name: "Bone Graft", title: translate('IMPLANTS.BONE_GRAFTS'), short_name: 'grafts', display_name: translate('IMPLANTS.BONE_GRAFT'),
        icon: { material: "grain", color: "green3", },
      },
      "Membrane": {
        product_name: "Membrane", title: translate('IMPLANTS.MEMBRANES'), short_name: 'membranes', display_name: translate('IMPLANTS.MEMBRANE'),
        icon: { material: "healing", color: "red-pink", },
      },
      "Final Abutment": {
        product_name: "Final Abutment", title: translate('IMPLANTS.FINAL_ABUTMENTS'), short_name: 'final_abutments', display_name: translate('IMPLANTS.FINAL_ABUTMENT'),
        icon: { svg: "styles/img/implants/final-abutment-icon-orange.svg", color: "orange", },
      },
      "Coping": {
        product_name: "Coping", title: translate('IMPLANTS.COPINGS'), short_name: 'coping', display_name: translate('IMPLANTS.COPING'),
        icon: { svg: "styles/img/implants/coping-icon-purple.svg", color: "purple", },
      },
    };
    ctrl.inventory_items = [];
    ctrl.catalog_items = [];
    ctrl.inventory_groups = [];
    ctrl.async_fetch_items = [];
    ctrl.loaded_tabs = {};
    ctrl.initial_tracking_levels_backup = {};
    ctrl.search_option = "reference_no";
    ctrl.search_query = '';
    ctrl.isLoading = false;
    ctrl.whatIsLoading = {
      'tableData': false,
      'search': false,
      'deleteRequest': false,
      'productDetails': false,
    };
    ctrl.expiration_limit = 18;
    ctrl.getExpiryOptions = getExpiryOptions;
    ctrl.imp_expiry_years = impHelperService.generateExpiryYears();
    ctrl.today = moment().toDate();
    ctrl.implant_max_quantity_limit = 50;
    ctrl.implant_min_quantity_limit = 0;
    ctrl.implant_default_quantity = 1;
    ctrl.implant_quantity_field_name = $filter('translate')('COMMON.QUANTITY');
    ctrl.disable_procedure_selection = true;

    ctrl.impSecCtrl = $scope.impSecCtrl;

    ctrl.displayed_view = 'imp_inventory';
    ctrl.selectView = selectView;

    ctrl.setTab = setTab;
    ctrl.currentTabIs = currentTabIs;
    ctrl.setChildTab = setChildTab;
    ctrl.currentChildTabIs = currentChildTabIs;
    ctrl.setHover = setHover;
    ctrl.clearHover = clearHover;
    ctrl.hoverText = hoverText;
    ctrl.addDialog = addDialog;
    ctrl.editDialog = editDialog;
    ctrl.addDialogDelayed = addDialogDelayed;
    ctrl.getImplantText = getImplantText;
    ctrl.listDialog = listDialog;
    ctrl.handleCellClick = handleCellClick;
    ctrl.searchDialog = searchDialog;
    ctrl.removeDialog = removeDialog;
    ctrl.confirmationDialog = confirmationDialog;
    ctrl.closeDialogs = closeDialogs;
    ctrl.shopToast = shopToast;
    ctrl.goToEditTable = goToEditTable;
    ctrl.fetchItems = fetchItems;
    ctrl.asyncFetchItems = asyncFetchItems;
    ctrl.confirmItemRemove = confirmItemRemove;
    ctrl.deleteCurrentItem = deleteCurrentItem;
    ctrl.deleteItem = deleteItem;
    ctrl.saveCurrentItem = saveCurrentItem;
    ctrl.updateImplantGroup = updateImplantGroup;
    ctrl.getLocationName = getLocationName;
    ctrl.selectTooth = selectTooth;
    ctrl.openCalendar = openCalendar;
    ctrl.discardCurrentItem = discardCurrentItem;
    ctrl.itemIsExpiring = itemIsExpiring;
    ctrl.updateSearch = updateSearch;
    ctrl.expirySort = expirySort;
    ctrl.removalDateFilter = removalDateFilter;
    ctrl.handleAddToCartClick = handleAddToCartClick;
    ctrl.handleAddToShoppingListClick = handleAddToShoppingListClick;
    ctrl.shouldShowAddToCartButton = shouldShowAddToCartButton;
    ctrl.shouldHideShoppingListButton = shouldHideShoppingListButton;
    ctrl.isImplantDiscontinued = isImplantDiscontinued;
    ctrl.onCancelClick = onCancelClick;
    ctrl.should_show_implant_discard_modal = true;
    ctrl.should_trigger_set_hover = true;
    ctrl.searchPatients = searchPatients;
    ctrl.selectedPatientRemovalChanged = selectedPatientRemovalChanged;
    ctrl.selectedProcedureChanged = selectedProcedureChanged;
    ctrl.selectedPatientReserveChanged = selectedPatientReserveChanged;
    ctrl.getReservedName = getReservedName;

    // Following two functions are exported only for testing purposes
    ctrl.showEditImplantFormConfirmation = showEditImplantFormConfirmation;
    ctrl.showTrackingLevelFormConfirmation = showTrackingLevelFormConfirmation;

    // This is a flag to prevent the hover from being set when the user clicks on a cell
    ctrl.is_cell_clicked = false;

    ctrl.loading = loading;
    ctrl.doneLoading = doneLoading;

    ctrl.addLocations = AddLocationsDialog.show;

    ctrl.$onInit = init;

    return ctrl;

    function init () {
      _defineLocks();
      ctrl.current_table_type = ctrl.table_types[$stateParams.type];

      $scope.$on('$stateChangeStart', function (event, toState, toParams) {
        // when changing between product types, reset path tabs
        if(toState.name === $state.current.name && toParams.type !== $stateParams.type && !ctrl.redirecting){
          ctrl.redirecting = true;
          event.preventDefault();
          $state.transitionTo(toState.name, _.extend(toParams, {'tab': null, 'c_tab': null}), {location: 'replace'});
        }

      });

      $scope.$on(appEvents.mktProductDetailsOpenFinished, () => {
        ctrl.whatIsLoading.productDetails = false;
      });

      $rootScope.$on('inventory_locations_updated', function($event){
        loadLocations();
      });

      // Close all dialogs when the shopping list is updated
      $scope.$on('shopping_list_updated', () => {
        closeDialogs('add-implant');
        closeDialogs('list-implants');
      });

      loadData();
    }

    function getParams () {
      if($stateParams.tab){
        setTab($stateParams.tab);
      } else {
        setFirstTab();
      }
      if($stateParams.c_tab){
        setChildTab($stateParams.c_tab);
      }
      if($stateParams.group_list){
        // TODO: code for list dialog from route path
      }
    }

    /**
     * Returns an array of expiry years, with the saved year included if it
     * is not already included in the generated years.
     * @returns {Array}
     */
    function getExpiryOptions () {
      /* if we are editing an existing implant, and the saved expiry year is not included in the generated years, include it.
        In case that the API returns a expiry_year that is not included in the generated years, it will have
        an empty input field for the expiry year, and that's not what we want.
      */

      if (ctrl.current_inventory_item && isEditImplantForm()) {
        const saved_year = ctrl.current_inventory_item.expiry_year
        const year_is_not_included = !impHelperService.generateExpiryYears().includes(saved_year)
        if (saved_year && year_is_not_included) {
          return [saved_year, ...impHelperService.generateExpiryYears()]
        }
      }

      return impHelperService.generateExpiryYears();
    }

    function translate(key) {
      return $filter('translate')(key);
    }

    //
    // UI functions
    //

    function setFirstTab () {
      // ctrl.current_tab = ctrl.tabs[0];
      setTab(ctrl.tabs[0].id);
      // setFirstChildTab();
    }

    function setTab (tab_id) {
      var tab = _.find(ctrl.tabs, {"id": tab_id});
      if (tab) {
        loadTabData( {implant_tab_id: tab.id, require_table_id: true} );

        ctrl.current_tab = tab;

        if(tab.child_tabs.length > 0) {

          if($stateParams.c_tab){
            var child = _.find(tab.child_tabs, ['id',$stateParams.c_tab]);
            if(!child){
              setFirstChildTab();
            } else {
              setChildTab(child.id);
            }
          } else {
            setFirstChildTab();
          }

          // setTable(ctrl.current_child_tab.implant_table_id);
        } else {
          ctrl.current_child_tab = null;
          // setTable(ctrl.current_tab.implant_table_id);
          setCurrentTable(tab.implant_table);
        }

      }

      $state.transitionTo(
        $state.current,
        _.extend($stateParams, {'tab': tab_id}),
        {
          notify: false,
          location: 'replace',
        }
      );
    }

    function currentTabIs (id) {
      return ctrl.current_tab && ctrl.current_tab.id === id;
    }

    function setFirstChildTab () {
      // ctrl.current_child_tab = ctrl.current_tab.child_tabs[0];
      setChildTab(ctrl.current_tab.child_tabs[0].id);
    }

    function setChildTab (tab_id) {
      var child_tab = _.find(ctrl.current_tab.child_tabs, {"id": tab_id});
      if (child_tab) {

        ctrl.current_child_tab = child_tab;

        if(child_tab.implant_table_id) {
          // setTable(child_tab.implant_table_id);
          setCurrentTable(child_tab.implant_table);
        }
      }

      $state.transitionTo(
        $state.current,
        _.extend($stateParams, {'c_tab': tab_id}),
        {
          notify: false,
          location: 'replace',
        }
      );
    }

    function currentChildTabIs (id) {
      return ctrl.current_child_tab && ctrl.current_child_tab.id === id;
    }

    function setTable (id) {
      ctrl.current_table = _.find(ctrl.tables, ["id", id]);
    }

    function setCurrentTable (table) {
      ctrl.current_table = table;
    }

    function setHover (column_index, row_index, options, $event) {
      var cell_name = "setHover on {0}-{1}".format(row_index, column_index);
      // console.time(cell_name);
      if($event){
        $event.stopPropagation();
      }

      /**
       * Prevent the hover from being set when the user clicks on a cell,
       * avoiding ctrl.hover_item_options being set to incorrect values
       */
      if (ctrl.is_cell_clicked) {
        return;
      }

      ctrl.hover_column = column_index;
      ctrl.hover_row = row_index;

      if(options){
        ctrl.hover_inventory = _.get(options,'inventory_items.length', null);
        ctrl.hover_item_options = options;
        ctrl.current_catalog_item = options.catalog_item;
      } else {
        ctrl.hover_inventory = [];
        ctrl.hover_item_options = {};
        ctrl.current_catalog_item = {};
      }
      // console.timeEnd(cell_name);
    }

    function clearHover () {
      // Prevent the hover from being cleared when the user clicks on save button after editing an implant
      if(ctrl.should_trigger_set_hover) {
        setHover(-1,-1,null,null);
      }
    }

    function hoverText () {
      var text = "";
      if( ctrl.hover_column >= 0 && ctrl.hover_row >= 0 ){
        text = _.join([ctrl.current_table.columns[ctrl.hover_column].label,
              "x",
              ctrl.current_table.rows[ctrl.hover_row].label], " ");
      }
      return text;
    }

    function goToEditTable () {
      var params = {
        mfr_id: $stateParams.mfr_id,
        step: 2,
        type: ctrl.current_table_type.product_name,
      };
      $state.go('app.implants.edit-table',params);
    }

    function shopToast () {
      var text = $filter('translate')('COMMON.ADDED_TO_SL');
      sgToast.showSimple(text);
    }

    function deletedToast () {
      var t_message = $filter('translate')('TOAST.DELETED');
      sgToast.showSimple(ctrl.current_catalog_item.category+t_message);
    }

    function removedToast () {
      var t_message = $filter('translate')('TOAST.REMOVED');
      sgToast.showSimple(ctrl.current_catalog_item.category+t_message);
    }

    function savedToast (is_new) {
      var msg = '';
      if(is_new){
        var t_message = $filter('translate')('TOAST.ADDED_TO_INV');
        msg = ctrl.current_catalog_item.category+t_message;
      } else {
        var t_message = $filter('translate')('TOAST.CHANGES_SAVED');
        msg = t_message;
      }
      sgToast.showSimple(msg);
    }

    function errorToast () {
      var t_message = $filter('translate')('ERRORS.SERVER_TRY_AGAIN');
      sgToast.showSimple(t_message);
    }

    function getLocationName (location_id) {
      var location = _.find(ctrl.inventory_locations, ["id", location_id]) || {name:""};
      return location.name || "";
    }

    function getImplantText () {
      var text = "";
      if( _.isNumber(ctrl.hover_column) && _.isNumber(ctrl.hover_row)){
        text = _.join([ctrl.current_table.columns[ctrl.hover_column].label,
              "x", ctrl.current_table.rows[ctrl.hover_row].label ], " ");
      }

      return text;
    }

    function asyncFetchItems(row, column, options, tab_id) {
      if(ctrl.loaded_tabs[tab_id]) {
        fetchItems(row, column, options);
      } else {
        ctrl.async_fetch_items.push({
          'row':row,
          'column':column,
          'options':options,
          'tab_id': tab_id
        });
      }
    }

    function fetchItems (row, column, options) {
      var table_filters = _.extend({},row.filter, column.filter);
      var search_filters = _.extend({}, _.omit(ctrl.current_table.filters, ['manufacturer_id', 'manufacturer', 'category']), table_filters);

      // leaving comments as proof of concept, this technique may be useful elsewhere in loops related to implant objects
      // var timestamp = moment().format('x');
      // console.time('whole loop_'+timestamp);
      // console.time('until found_'+timestamp);
      
      _.each(ctrl.inventory_groups, function(group){
        var props = _.pick(group.catalog_item.properties, Object.keys(search_filters));
        var match = _.isEqual(search_filters, props);

        if ( match ) {
          options.inventory_group = angular.copy(group);
          // console.timeEnd('until found_'+timestamp);
          return false;
        }
      });
      // console.timeEnd('whole loop_'+timestamp);

      options.inventory_items = [];

      if (options.inventory_group) {
        options.catalog_item = options.inventory_group.catalog_item;
        // options.inventory_items = [];
        // _.forEach(options.inventory_group.item_ids, function(id){
        //   var item = _.find(ctrl.inventory_items, function(this_item){
        //     return this_item.id === id && !this_item.was_placed;
        //   });
        //   if (item) {
        //     options.inventory_items.push(item);
        //   }
        // });
      }
    }

    function selectTooth (number, section_v, section_h) {
      if (_.isEqual(ctrl.current_inventory_item.tooth_placement_value, number)) {
        ctrl.current_inventory_item.tooth_placement_value = null;
        ctrl.teeth_section_v = null;
        ctrl.teeth_section_h = null;
        return;
      }
      ctrl.current_inventory_item.tooth_placement_value = number;
      ctrl.teeth_section_v = section_v;
      ctrl.teeth_section_h = section_h;
    }

    function openCalendar($event){

      $event.stopPropagation();
      $timeout(function() {
        var thisElem = $($event.currentTarget)[0];
        var datepicker = $(thisElem).siblings('md-datepicker')[0];
        $(datepicker)[0].children[0].click();
      }, 400);

    }

    function itemIsExpiring (item) {
      if(!item){
        return false;
      }
      var expiry_date = moment([item.expiry_year, (item.expiry_month*1 - 1)]);
      var diff = expiry_date.diff(moment(), 'months');
      return diff < ctrl.expiration_limit;
    }

    //
    // Update the loading status of the controller. If we are transitioning
    // from !isLoading => isLoading, then we give it 400ms. If the loading is
    // done by the time the $timeout executes, then the update to isLoading
    // should just have no effect. This allows us to only show the loading
    // indicator when we _really_ need to (i.e. things are taking too long from
    // the user perspective).
    //
    function updateLoadingStatus () {
      if (!ctrl.isLoading) {
        $timeout(function () {
          ctrl.isLoading = _.some(ctrl.whatIsLoading);
        }, 400);
      } else {
        ctrl.isLoading = _.some(ctrl.whatIsLoading);
      }
    }

    function loading (name) {
      ctrl.whatIsLoading[name] = true;
      $scope.$broadcast('loading', name);
      updateLoadingStatus();
    }

    function doneLoading (name) {
      ctrl.whatIsLoading[name] = false;
      $scope.$broadcast('doneLoading', name);
      updateLoadingStatus();
    }

    function expirySort (item) {
      if(!item || !item.expiry_month || !item.expiry_year){
        return 0;
      }
      var expiry_date = moment([item.expiry_year, (item.expiry_month*1 - 1)]);
      var ms = expiry_date.format('x');
      return ms;
    }

    function removalDateFilter (input) {
      var now = moment();
      var this_date = moment(input);
      return this_date.isSameOrBefore(now, 'day');
    }

    function setCellData (row, row_index, column, column_index) {
      // TO-DO: for future reference, this is where the cell options object is going to be built
    }

    //
    // Dialogs
    //

    function addDialog (origin_dialog) {
      closeDialogs(origin_dialog);

      var group = ctrl.hover_item_options.inventory_group;

      // SPD-2106
      // for officeGroups, only master office will set prices for implants
      // in which case, if the office/user gets a default price along with an inventoryGroup,
      // this is going to be set in the (now disabled) price input. Otherwise you'd get the most recent price
      // for that product or zero if there's none.

      
      // SPD-3165
      // ImplantGroups with multi-item packs will have a `unit_price` property for individual items,
      // while regular ones will have just `default_price` in the case of a OfficeGroup policy
      // and lastly, a `most_recent_price` in all offices

      const initial_price = group?.unit_price || group?.default_price || group?.most_recent_price || 0;

      ctrl.current_inventory_item = {
        location_id: _.get(group, 'most_recent_location.id', null),
        minimum_level: group?.minimum_level,
        price: initial_price,
        qty: ctrl.implant_default_quantity,
      };

      return $mdSidenav("add-implant").open().then(function(){
        setupInitialItem();
        setOriginDialog(origin_dialog);
      });
    }

    /**
     * Opens a dialog to edit implant item, sets up initial values, 
     * and retrieves patient information if available.
     *
     * @param {InventoryItem} inventory_item - The `inventory_item` parameter is an object that represents an item in
     * the inventory. It contains various properties such as `minimum_level`, `reservation_id`, etc.
     * @param {string} origin_dialog - The `origin_dialog` parameter is the dialog that triggered the edit
     * dialog. It is used to close all other dialogs before opening the edit dialog.
     * @param {object} options - The implant item's additional data (stock items, expiry dates, etc).
     * @return {Promise<MdDialog>}
     */
    function editDialog (inventory_item, origin_dialog, options) {
      closeDialogs(origin_dialog);

      if(options){
        setHover(-1,-1,options);
      }

      // this is the autocomplete's selected item, it has to be stored in different variables because 
      // if we use a single one, it would populate both (they are both present in the DOM already)
      ctrl.selected_patient_reservation = null;
      ctrl.patient_search_text = null;
      ctrl.current_inventory_item = inventory_item;
      ctrl.current_inventory_item.minimum_level = ctrl.hover_item_options.inventory_group.minimum_level;
      // at the edit state, a patient might be set at `reservation_id`, so we need to check for that
      const patient_id = ctrl.current_inventory_item.reservation_id;
      if (ctrl.has_pwf_access && patient_id) {
        _getPatient(patient_id).then((patient) => {
          ctrl.patient_search_text = patient.name;
          ctrl.selected_patient_reservation = patient;
          selectedPatientReserveChanged(patient);
        })
      }

      $mdSidenav("add-implant").onClose(showEditImplantFormConfirmation)

      return $mdSidenav("add-implant").open().then(function(){
        setupInitialItem();
        setOriginDialog(origin_dialog);
      });
    }

    function setupInitialItem () {
      ctrl.initial_inventory_item = angular.copy(ctrl.current_inventory_item);
    }

    function addDialogDelayed () {
      return $timeout(function() {
        return addDialog();
      }, 500);
    }

    function closeAddEditDialog () {
      // check wether the item has been saved, and warn the user accordingly
      var item_pristine = _.isEqual(ctrl.current_inventory_item, ctrl.initial_inventory_item);
      var form_pristine = ctrl.editImplantForm?.$pristine;

      if (form_pristine) {
        discardCurrentItem();
      } else {
        // modal
        // alert('item has changed, save first');

        var template_url = ctrl.current_inventory_item.id ? 'templates/marketplace/implants/modals/imp-unsaved-warning.html' : 'templates/marketplace/implants/modals/imp-new-warning.html';

        $mdDialog.show({
          controller: 'poDialogController',
          controllerAs: 'dialogCtrl',
          templateUrl: template_url,
          parent: angular.element(document.body),
          clickOutsideToClose: true,
          locals: {
            product_name: ctrl.current_catalog_item.category,
          },
          bindToController: true,
        })
        .then(function (answer) {
        // Allow state change
          if (answer) {
            saveCurrentItem();
          } else {
            discardCurrentItem();
          }
        }).finally(() => {
          ctrl.should_show_implant_discard_modal = true;
        })
      }
    }

    function resetEditForm () {
      ctrl.editImplantForm?.$setPristine();
      ctrl.editImplantForm?.$setUntouched();
    }

    function discardCurrentItem () {
      closeDialogs();
      resetCurrentItem();
      resetForms();
    }

    function handleCellClick (options) {
      // Backup used when the user closes the dialog without saving

      _setCellActive(options);
      ctrl.options_backup = angular.copy(options);
      return listDialog(options);
    }

    function _setCellActive(options) {
      /**
       * Check if options are defined before setting the flag
       * This prevents side effects with the function: listDialog.
       * listDialog verifies the presence of options
       * and calls the setHover method
       */ 
      if (options) {
        ctrl.is_cell_clicked = true;

      /**
       * Reset the flag after 500ms, to allow the user to click on the cell again
       * or a different cell
       */ 
        $timeout(() => {
          ctrl.is_cell_clicked = false;
        }, 500);
      }
    }

    async function listDialog (item_options, origin_dialog, options) {
      closeDialogs(origin_dialog);

      if(options){
        setHover(-1,-1,options);
      }
      ctrl.product_id = null;
      
      if (item_options.inventory_group) {
        const on_hand = _.get(item_options, 'inventory_group.on_hand');
        const min_level = _.get(item_options, 'inventory_group.minimum_level');
        const is_stock_low = _.isNumber(min_level) && min_level >= on_hand;
        ctrl.show_low_stock_badge = is_stock_low;

        // fetch the product_id of the implant (if one exists)
        const membership = membershipService.get();
        const marketplace_id = membership.current_marketplace.id;
        const office_id = membership.office.id;
        const { manufacturer_id, reference_no } = item_options.inventory_group;
        try {
          const { data } = await sowImplantService.getMktProductID(marketplace_id, office_id, manufacturer_id, reference_no); 
          ctrl.product_id = data;
        } catch (error) {
          console.error(error);
        }
      }

      ctrl.current_catalog_item = item_options.catalog_item;

      //
      // trying to speed up cell fetch
      //
      if (item_options.inventory_group && (!item_options.inventory_items || item_options.inventory_items.length === 0)) {
        item_options.inventory_items = [];
        _.forEach(item_options.inventory_group.item_ids, function(id){
          // var item = _.find(ctrl.inventory_items, function (item){ return item.id === id && !item.was_placed; });
          var item = _.get(ctrl.inventory_map, id, null);
          if (item && !item.was_placed) {
            item_options.inventory_items.push(item);
          }
        });
      }

      _fetchReservedItems(item_options.inventory_items);

      
      // Attach an onClose handler when the slide-out is closed.
      // Normally, our loadProduct() promise's finally block resets the loading flag and broadcasts
      // the finish event. (mktProductDetailsOpenFinished event)
      //
      // However, if the user manually dismisses the slide-out (by clicking outside),
      // the promise from $mdSidenav('mkt-details').open() may never resolve, and the finally block is skipped.
      $mdSidenav('list-implants').onClose(() => {
        _resetLoadingState();
        showTrackingLevelFormConfirmation();
      });

      // ctrl.current_items_list = item_options.inventory_items;
      return $mdSidenav("list-implants").open().then(function(){
        setOriginDialog(origin_dialog);
      });
    }

    function _resetLoadingState() {
      ctrl.whatIsLoading.productDetails = false;
    }

    /**
     * Opens a dialog to remove implants, sets up initial values,
     * and retrieves patient information if available.
     *
     * @param {InventoryItem} inventory_item - The `inventory_item` parameter is an object that represents an item in
     * the inventory. It contains various properties such as `minimum_level`, `reservation_id`, etc.
     * @param {string} origin_dialog - The `origin_dialog` parameter is the dialog that triggered the edit
     * dialog. It is used to close all other dialogs before opening the edit dialog.
     * @param {object} options - The implant item's additional data (stock items, expiry dates, etc).
     * @return {Promise<MdDialog>}
     */
    function removeDialog (inventory_item, origin_dialog, options) {
      closeDialogs(origin_dialog);

      if(options){
        setHover(-1,-1,options);
      }

      // this is the autocomplete's selected item, it has to be stored in different variables because
      // if we use a single one, it would populate both (they are both present in the DOM already)
      ctrl.selected_patient_removal = null;
      ctrl.current_inventory_item = inventory_item;
      ctrl.current_inventory_item.was_placed_on = new Date();
      ctrl.current_inventory_item.placement_id = ctrl.current_inventory_item.reservation_id;

      const countryId = sow.officeInfo().address.country.id;
      ctrl.current_tooth_format = impHelperService.getToothPlacementFormats(countryId);
      ctrl.current_inventory_item.tooth_placement_format = ctrl.current_tooth_format.format_name;
      // at the removal state, a patient might be set already at `placement_id`, so we need to check for that,
      // and if it's not there, then we need to check for `reservation_id` in case it was previously reserved for that patient
      const patient_id = ctrl.current_inventory_item.placement_id || ctrl.current_inventory_item.reservation_id;
      if (ctrl.has_pwf_access && patient_id) {
        _getPatient(patient_id).then((patient) => {
          ctrl.selected_patient_removal = patient;
          selectedPatientRemovalChanged(patient);
        })
        .finally(() => {
        });
      }
      return $mdSidenav("remove-implant").open().then(function(){
        setOriginDialog(origin_dialog);
        setupInitialItem();
      });
    }

    function confirmationDialog (inventory_item) {
      closeDialogs('remove-implant');
      if(inventory_item){
        ctrl.current_inventory_item = inventory_item;
      }
      return $mdSidenav("confirm-implant-removal").open();
    }

    function searchDialog () {
      closeDialogs();
      updateSearch();

      return $mdSidenav("search-implants").open()
      .then(function(){
        angular.element('#search-slideout-input').focus();
        updateSearch();
      });
    }

    function closeDialogs (dialog_name) {
      ctrl.search_query = '';

      const is_tracking_level_form_dirty = isTrackingLevelFormActiveAndDirty();

      if (is_tracking_level_form_dirty) {
        ctrl.tracking_edited_values_backup = cloneInventoryGroupFields();
      }

      if (dialog_name) {
        if (dialog_name === 'add-implant') {
          closeAddEditDialog();
        } else {
          $mdSidenav(dialog_name).close();
        }

      } else {
        _.forEach(ctrl.dialogs, function(dialog){
          $mdSidenav(dialog).close();
        });
        if(ctrl.origin_dialog) {

          if (ctrl.origin_dialog === 'list-implants') {
            _fetchReservedItems(ctrl.hover_item_options.inventory_items)
          }
          $mdSidenav(ctrl.origin_dialog).open();
          setOriginDialog(null);
        }
      }

      // Reset the list view only if the tracking level form is not dirty
      // to avoid having two dialogs open at the same time
      if (!is_tracking_level_form_dirty) {
        ctrl.selectView('imp_inventory');
      }
    }

    function setOriginDialog (name) {
      ctrl.origin_dialog = name || null;
      resetForms();
    }

    function resetForms () {
      ctrl.editImplantForm?.$setPristine();
      ctrl.editImplantForm?.$setUntouched();

      ctrl.removeImplantForm?.$setPristine();
      ctrl.removeImplantForm?.$setUntouched();

      ctrl.editImplantGroupForm?.$setPristine();
      ctrl.editImplantGroupForm?.$setUntouched();
    }

    //
    // Data functions
    //

    function resetCurrentItem () {
      _.forEach(Object.keys(ctrl.current_inventory_item), function(key){
        if(!_.isEqual(ctrl.current_inventory_item[key], _.get(ctrl.initial_inventory_item,key,null) ) ) {
          ctrl.current_inventory_item[key] = _.get(ctrl.initial_inventory_item,key,null);
        }
      });
      ctrl.selected_patient_removal = null;
      // ctrl.current_inventory_item = angular.copy(ctrl.initial_inventory_item);
      resetEditForm();
    }

    function loadData () {
      var params = {
        manufacturer: {
          id: $stateParams.mfr_id
        },
        manufacturer_id: $stateParams.mfr_id,
        category: ctrl.table_types[$stateParams.type].product_name
      };
      ctrl.params = params;

      ctrl.impSecCtrl.isLoading();
      sowImplantService.getTabs(params)
      .then(function(tabs_response){
        _.forEach(tabs_response, function(tab){
          if(tab.child_tabs){
            tab.child_tabs = _.sortBy(tab.child_tabs, function(ctab){return ctab.sort_rank;});
          }
        });
        ctrl.tabs = _.sortBy(tabs_response, function(tab){return tab.sort_rank;});

        if(ctrl.tabs.length >= 1){
          // this sets the current tab id to either the one on route params or the first one available
          getParams();

          $scope.$on('doneLoading', function(ev, name){
            if(name === ctrl.current_tab.id){
              ctrl.impSecCtrl.doneLoading();
            }
          });
        } else {
          ctrl.impSecCtrl.doneLoading();
        }
      })
      .catch(function(error){
        var t_message = $filter('translate')('ERRORS.TABS_LOAD')
        errorService.uiErrorHandler(error.message || t_message);
      });

      sowImplantService.getManufacturer($stateParams.mfr_id)
      .then(function(response){
        ctrl.manufacturer = response;
      })
      .catch(function(error){
        var t_message = $filter('translate')('ERRORS.MANUF_LOAD')
        errorService.uiErrorHandler(error.message || t_message);
      });

      officeService.getOfficeUsers().then((users) => {
        ctrl.officeUsers = impHelperService.getMedicUsers(users);

        officeService.getPendingInvitations().then((invites) => {
          if (_.size(invites)) {
            ctrl.officeUsersInvites = impHelperService.getMedicUserInvites(invites);
          }
        });
      })
      .catch(function(error){
        var t_message = $filter('translate')('ERRORS.OFFICE_USERS_LOAD')
        errorService.uiErrorHandler(error.message || t_message);
      });

      referringDoctorsService.getReferringDoctors()
      .then(function (doctors) {
        ctrl.referring_doctors = doctors;
      }).catch(function (error) {
        var t_message = $filter('translate')('ERRORS.DOCTORS_RETRIEVE')
        errorService.uiErrorHandler(error || t_message, 3000);
      });

      loadLocations();
    }

    // In officeGroups, one master office will be able
    // to set pricing on inventory, meds & implants, while all other offices in the group will not
    // this is defined on the membership level though, because there might be some overrides 
    // such as a C-level user being able to update pricing in a limited office
    function _defineLocks () {
      const orders_access_condition = AccessService.hasOrdersPermission();

      ctrl.price_locked = AccessService.getProperty('implants.disable_price');
      ctrl.delete_locked = AccessService.getProperty('implants.disable_delete');
      ctrl.min_lvl_locked = AccessService.getProperty('implants.disable_min_level');
      ctrl.desired_lvl_locked = AccessService.getProperty('implants.disable_desired_level');
      ctrl.edit_table_locked = AccessService.getProperty('implants.disable_edit_table');
      ctrl.disabled_admin_tools_page = AccessService.getProperty('admin_tools.disable_page');

      ctrl.disable_shopping_list = !AccessService.hasShoppingListAccess();

      const disable_mkt_orders_condition = AccessService.getOverride('disable_marketplace_orders');
      ctrl.disable_marketplace_orders = !orders_access_condition || disable_mkt_orders_condition;

      ctrl.hideCartButton = !AccessService.hasCartAccess();

      ctrl.has_pwf_access = AccessService.hasPmsIntegration();

      ctrl.has_multi_supplier_selection = AccessService.getProperty('shopping_list.multi_supplier_selection');
    }

    function loadTabData (params) {
      params = _.extend({}, ctrl.params, params);

      var tab_is_loaded = _.get(ctrl.loaded_tabs, params.implant_tab_id);

        if(!tab_is_loaded){
          ctrl.loading(params.implant_tab_id);

        return loadInventoryItems(params)
        .then(function(){
          sowImplantService.getInventoryGroups(params)
            .then(function(groups_response){
              ctrl.inventory_groups = _.unionBy(ctrl.inventory_groups, groups_response, 'id');
              
              _.set(ctrl.loaded_tabs, params.implant_tab_id, true);

              // after all item data has been loaded, fetch inventory & catalog items data for implant cells
              _.forEach(ctrl.async_fetch_items, function(item){
                asyncFetchItems(item.row, item.column, item.options, item.tab_id);
              });
              ctrl.async_fetch_items = [];

              setChildTabQuantities();

              ctrl.doneLoading(params.implant_tab_id);


              // TO-DO: change where this is called
              // updateCatalogMap();

            })
            .catch(function(error){
              var t_message = $filter('translate')('ERRORS.IMPLANTS_DATA_LOAD')
              errorService.uiErrorHandler(error.message || t_message);
            });
        });
      }
    }

    /** 
     * Sets total_qty of each child tab to the sum of its groups' on_hand quantities.
     * 
     * @return {*} 
    */
    function setChildTabQuantities() {
      for (const tab of ctrl.tabs) {
        for (const child_tab of tab.child_tabs) {
          child_tab.total_qty = 0;
          for (const group of ctrl.inventory_groups) {
            if (group.imp_table_id === child_tab.implant_table_id) {
              child_tab.total_qty += _.size(group.item_ids);
            }
          }
        }
      }
    }

    function updateCatalogMap () {
      ctrl.catalog_map = {};

      _.forEach(ctrl.inventory_groups, function(group, index){
        

      });
    }

    function loadInventoryItems (params) {
      return sowImplantService.getInventoryItems( _.extend({},params, {was_placed: 0}))
      .then(function(inventory_response){
        ctrl.inventory_items = _.unionBy(ctrl.inventory_items, inventory_response, 'id');
        ctrl.inventory_map = {};
        _.each(ctrl.inventory_items, function(item){
          _.set(ctrl.inventory_map, item.id, item);
        });
      })
      .catch(function(error){
        var t_message = $filter('translate')('ERRORS.INVENTORY_ITEMS_LOAD')
        errorService.uiErrorHandler(error.message || t_message);
      });
    }


    function updateReferringDoc () {
      var doc = _.get(ctrl, 'current_inventory_item.UI.referring_doctor', null);

      if(doc && doc.id){
        _.set(ctrl, 'current_inventory_item.referring_doctor_id', doc.id);
        _.set(ctrl, 'current_inventory_item.referring_doctor_name', doc.name);
      }
    }

    function _generateOfficeInventoryItem () {
      return {
        id: ctrl.hover_item_options.inventory_group.inventory_item_id,
        image: ctrl.current_catalog_item.product_image_url,
        manufacturer: ctrl.manufacturer.name,
        name: ctrl.current_catalog_item.name,
        price: ctrl.current_inventory_item.price,
        product_id: ctrl.product_id ?? '',
        sku: ctrl.current_catalog_item.reference_no,
        tracking_method: 'Item',
        type: 'Implant Item',
        implant: {
          category: ctrl.current_catalog_item.category,
          expiry_month: ctrl.current_inventory_item.expiry_month,
          expiry_year: ctrl.current_inventory_item.expiry_year,
          imp_inventory_group_id: ctrl.hover_item_options.inventory_group.id,
          lot_number: ctrl.current_inventory_item.lot_number,
          properties: ctrl.current_catalog_item.properties,
          serial_number: ctrl.current_inventory_item.serial ?? '',
          sow_implant_inventory_item_id: ctrl.current_inventory_item.id,
          tooth_placement_format: ctrl.current_inventory_item.tooth_placement_format,
          tooth_placement_value: _.toString(ctrl.current_inventory_item.tooth_placement_value),
        },
      };
    }

    function confirmItemRemove () {
      if(!formService.checkRequiredFields(ctrl.removeImplantForm)){
        return 0;
      }

      updateReferringDoc();
      // failproof for saving empty reservation IDs
      ctrl.current_inventory_item.reservation_id = _.trim(ctrl.current_inventory_item.reservation_id);
      ctrl.current_inventory_item.was_placed = true;
      ctrl.current_inventory_item.tooth_placement_value = ctrl.current_inventory_item.tooth_placement_value || '';

      ctrl.removing = true; 
      return sowImplantService.saveInventoryItem(ctrl.current_inventory_item)
      .then(async function (response){

        if (ctrl.has_pwf_access && ctrl.selected_procedure_id) {
          const payload = {
            office_inventory_item: _generateOfficeInventoryItem(),
            procedure_id: ctrl.selected_procedure_id,
            date: ctrl.current_inventory_item.was_placed_on,
            quantity: 1,
          };
          await PatientService.linkProduct(payload);
        }

        const saved_item = impHelperService.extractItemID(response);
        deleteLocalItem(saved_item, true);
        confirmationDialog();
      })
      .catch(function(error){
        var t_message = $filter('translate')('ERRORS.IMPLANT_SAVE')
        errorService.uiErrorHandler(error.message || t_message);
      })
      .finally(function(){
        ctrl.removing = false;
      });
    }

    function deleteCurrentItem () {
      deleteItem(ctrl.current_inventory_item);
    }

    function deleteItem (inventory_item, options) {
      if(options){
        setHover(null,null,options);
      }

      $mdDialog.show({
        controller: 'poDialogController',
        controllerAs: 'dialogCtrl',
        templateUrl: 'templates/marketplace/implants/modals/imp-delete-warning.html',
        parent: angular.element(document.body),
        clickOutsideToClose: false,
        locals: {
          product_name: ctrl.current_catalog_item.category,
          manufacturer_name: ctrl.manufacturer.name,
          item: {
            lot_number: inventory_item.lot_number,
            ref_number: ctrl.current_catalog_item.reference_no,
            expiry: inventory_item.expiry_year+'-'+$filter('leftpad')(inventory_item.expiry_month,2),
          },
        },
        bindToController: true,
      })
      .then(function (answer) {
        if (answer) {
          ctrl.whatIsLoading.deleteRequest = true;
          sowImplantService.removeInventoryItem(inventory_item.id)
          .then(function(response){
            deleteLocalItem(inventory_item);
            // keeps list dialog open
            var should_close_dialogs = $mdSidenav('add-implant').isOpen() ||
              $mdSidenav('list-implants').isOpen() && ctrl.hover_item_options.inventory_items.length === 0 ||
              $mdSidenav('remove-implant').isOpen() ;

            if(should_close_dialogs){
              closeDialogs();
              if($mdSidenav('list-implants').isOpen() && ctrl.hover_item_options.inventory_items.length === 0){
                closeDialogs('list-implants');
              }
            }
            // alertsDataCarrierService.load_alerts();
          }).catch(function(error){
            errorToast();
          }).finally(function(response) {
            ctrl.whatIsLoading.deleteRequest = false;
          })
        }
      });
    }

    /** 
     * Updates price, desired_level, minimum_level, and/or notes of an implant group.
     * 
     * @return {*}
    */
    function updateImplantGroup(saved_group = ctrl.hover_item_options.inventory_group, tracking_tab_closed = false) {
      if (!formService.checkRequiredFields(ctrl.editImplantGroupForm)) {
        // Re-open the tracking levels tab if the form was invalid and the dialog was closed
        if (!$mdSidenav('list-implants').isOpen()) {
          return handleCellClick(ctrl.options_backup).then(() => {
            selectView('tracking_levels')
            formService.checkRequiredFields(ctrl.editImplantGroupForm)
          })
        }

        return 0;
      }

      ctrl.disable_tracking_save_btn = true;
      sowImplantService.saveImplantGroup(saved_group).then(() => {
        impHelperService.updateInventoryGroupOnSave(ctrl.inventory_groups, saved_group);
        ctrl.editImplantGroupForm?.$setPristine();
        ctrl.editImplantGroupForm?.$setUntouched();

        if (tracking_tab_closed) {
          changeTabToImplantInventory();
        } else {
          closeDialogs();
        }
      }).finally(() => {
        ctrl.disable_tracking_save_btn = false;
      });
    }

    function saveCurrentItem (addAnother) {
      if(!formService.checkRequiredFields(ctrl.editImplantForm)){
        if (!$mdSidenav('add-implant').isOpen()) {
          return editDialog(ctrl.current_inventory_edited_item_backup,'list-implants').then(() => {
            formService.checkRequiredFields(ctrl.editImplantForm)
          })
        }

        return 0;
      }

      // Set the default quantity to one if input is empty
      if(ctrl.current_inventory_item.qty === null) {
        ctrl.current_inventory_item.qty = ctrl.implant_default_quantity;
      }
      
      var new_props = {};
      var new_group = null;
      var quantity = ctrl.current_inventory_item.qty;
      var method = null;

      if (!ctrl.current_inventory_item.id) {
        var group_id = _.get(ctrl.hover_item_options, "inventory_group.id", null);
        if (!group_id) {
          new_group = {
            manufacturer_id: ctrl.manufacturer.id,
            reference_no: ctrl.hover_item_options.catalog_item.reference_no,
            office_id: $rootScope.current_office.id,
          };
        }

        new_props = {
          'inventory_group': group_id || new_group,
          'quantity': quantity
        };
      }
      if (!ctrl.current_inventory_item.id) {
        method = sowImplantService.batchCreateInventoryItems;
      } else {
        // when it's an edit operation, have to use the save method
        method = sowImplantService.saveInventoryItem;
      }

      var savingItem = _.extend({}, ctrl.current_inventory_item, new_props);
      // failproof for saving empty reservation IDs
      savingItem.reservation_id = _.trim(savingItem.reservation_id) || null;
      // if the user has not inputted a quantity, default to the `quantity` value which is 1 by default
      savingItem.quantity = savingItem.qty || quantity;

      if (isEditImplantForm()) {
        // We need to remove these properties from the object because they are not part of the API payload
        savingItem = _.omit(savingItem, ['was_placed_on', 'placement_id', 'tooth_placement_format', 'tooth_placement_value']);
      }

      function saved (response){
        // prevents sending new group info a second time
        savingItem.inventory_group = response.inventory_group;
        if(!savingItem.id) {
          addLocalItem(response);
        } else {
          updateLocalGroup(response.inventory_group);
        }
        new_group = null;
      }

      ctrl.impSecCtrl.isLoading();

      method(savingItem)
      .then(function(response){
        var all_ids = [];
        var item_props = null;

        if (method === sowImplantService.batchCreateInventoryItems) {
          // accounting for two different API formats
          if (response.batch_ids) {
            all_ids = response.batch_ids;
            item_props = _.omit(savingItem, ["qty", "quantity"]);
          } else {
            all_ids = response.ids;
            item_props = _.omit(response, ["ids", "batch_ids"]);
          }
          
          _.forEach(all_ids, function(this_id){
            var this_item = _.extend({}, item_props, {'id': this_id});
            saved(this_item);
          });
        } else {
          // new object with all the previous properties but the quantity, along with the newly created id
          const saved_item = impHelperService.extractItemID(response);
          saved(_.extend({}, _.omit(savingItem, ['quantity', 'qty']), saved_item));
        }

        var is_new = savingItem.id ? false:true;
        savedToast(is_new);
        setupInitialItem();
        if(addAnother) {
          var keep = {
            price: savingItem.price,
            minimum_level: savingItem.minimum_level,
            location_id: savingItem.location_id
          };
          ctrl.current_inventory_item = _.extend({}, keep);
          setupInitialItem();
        } else {
          setOriginDialog('list-implants');
          closeDialogs();
        }
        resetEditForm();

      })
      .catch(function(error){
        // TO-DO: treat errors
        var t_message = $filter('translate')('ERRORS.IMPLANTS_SAVE')
        errorService.uiErrorHandler(t_message);
        // If the API returns an error, the item is not saved, so we need to reset the form
        resetEditForm()
      })
      .finally(function(){
        ctrl.impSecCtrl.doneLoading();
      });
    }


    function deleteLocalItem (inventory_item, removing) {
      var item_in_main_list = _.find(ctrl.inventory_items, ["id",inventory_item.id]);
      var item_in_hover_list = _.find(ctrl.hover_item_options.inventory_items, ["id",inventory_item.id]);

      updateLocalGroup(inventory_item.inventory_group);

      _.remove(ctrl.hover_item_options.inventory_items, item_in_hover_list);
      _.remove(ctrl.inventory_items, item_in_main_list);
      if(removing){
        removedToast();
      }else{
        deletedToast();
      }

    }

    function addLocalItem (inventory_item, inventory_group) {
      ctrl.inventory_items.push(inventory_item);
      ctrl.hover_item_options.inventory_items.push(inventory_item);

      sowImplantService.getInventoryGroup(inventory_item.inventory_group)
      .then(function(group_response){
        var local = _.find(ctrl.inventory_groups, ["id",group_response.id]);

        if(local){
          _.remove(ctrl.inventory_groups, local);
          group_response.imp_table_id = local.imp_table_id;
        }

        ctrl.show_low_stock_badge = _.get(group_response, 'summary.is_below_minimum_level', false);
        
        ctrl.inventory_groups.push(group_response);

        _.extend(ctrl.hover_item_options, {'inventory_group': group_response});

        if(!_.get(ctrl.hover_item_options, "inventory_group.item_ids", null) ){
          _.set(ctrl.hover_item_options, "inventory_group.item_ids", [inventory_item.id]);
        } else {
          if(ctrl.hover_item_options.inventory_group.item_ids.indexOf(inventory_item.id) < 0){
            ctrl.hover_item_options.inventory_group.item_ids.push(inventory_item.id);
          }
        }
        setChildTabQuantities();   
      })
      .catch(function(error){
        var t_message = $filter('translate')('ERRORS.IMPLANT_SAVE')
        errorService.uiErrorHandler(error.message || t_message);
      });

    }

    function updateLocalGroup (id) {
      const local = ctrl.inventory_groups.find(group => group.id === id);
      // account for case where user has searched for item
      // and as a result is viewing a different section
      // than the one which contains the item - in this case
      // that section's groups will be fetched when the user
      // navigates to it, so there's no update required here
      if (!local) return;
      sowImplantService.getInventoryGroup(id)
      .then(function(response){
        _.remove(ctrl.inventory_groups, local);
        response.imp_table_id = local.imp_table_id;
        ctrl.inventory_groups.push(response);
        ctrl.show_low_stock_badge = _.get(response, 'summary.is_below_minimum_level', false);
        _.extend(ctrl.hover_item_options,{inventory_group: response});
        setChildTabQuantities();
        // local = response;
      })
      .catch(function(error){
        var t_message = $filter('translate')('ERRORS.IMPLANT_DATA_LOAD')
        errorService.uiErrorHandler(error.message || t_message);
      });
    }

    function updateSearch () {

      loading('search');

      ctrl.search_groups = [];
      ctrl.search_results = [];
      ctrl.search_result_count = 0;
      var missing_groups_ids = [];

      // in case nothing was typed in the search input, quit loading
      if(ctrl.search_query.length === 0) {
        doneLoading('search');
        return;
      }

      var filters = {
        manufacturer_id: $stateParams.mfr_id,
      };
      filters[ctrl.search_option] = ctrl.search_query;

      return sowImplantService.searchImplantInventory(filters)
      .then(function(response){
        // search_inventory_items has to be an Array, so if the API returned
        // something else it means there were no matches
        ctrl.search_inventory_items = response.length ? response : [];
      })
      .catch(function(error){
        var t_message = $filter('translate')('ERRORS.SEARCH_DATA_LOAD')
        errorService.uiErrorHandler(error.message || 'Failed to load search data, please try again.');
      })
      .finally(function(){
        // ctrl.search_inventory_items = _.filter(ctrl.search_inventory_items, function(s_item){
        //   return (s_item.was_placed !== false); // changed this to include groups with 0 items where it's null instead of false
        // });

        // in case there are no results (empty list), just quit trying to filter the list
        if(!ctrl.search_inventory_items || ctrl.search_inventory_items.length === 0) {
          doneLoading('search');
          return;
        }

        // load offline-found inventoryGroups and make a list of the ones missing
        _.forEach(ctrl.search_inventory_items, function(inventory_item){
          if(missing_groups_ids.indexOf(inventory_item.inventory_group) >= 0 || _.find(ctrl.search_groups, ['id',inventory_item.inventory_group])){
            return;
          }

          var local_group = _.find(ctrl.inventory_groups, ['id',inventory_item.inventory_group]);

          if(local_group){
            ctrl.search_groups.push(local_group);
          } else {
            missing_groups_ids.push(inventory_item.inventory_group);
          }
        });

        // fetch missing inventoryGroups and add them to the list
        if(missing_groups_ids.length > 0){
          var group_filters = {
            id: missing_groups_ids,
            manufacturer_id: $stateParams.mfr_id,
            office_id: $rootScope.current_office.id,
          };

          sowImplantService.getInventoryGroups(group_filters)
          .then(function(response){
            ctrl.search_groups = _.concat(ctrl.search_groups, response);
          })
          .finally(function(){
            generateResults();
          });
        } else {
          generateResults();
        }

        function generateResults(){

          _.forEach(ctrl.search_groups, function(group){

            var group_items = [];

            _.forEach(group.item_ids, function(id){
              // var item = _.find(ctrl.search_inventory_items, ["id", id]);
              var item = _.get(ctrl.inventory_map, id, null);
              if (item) {
                group_items.push(item);
              }
            });

            ctrl.search_results.push({
              catalog_item: group.catalog_item,
              inventory_items: group_items,
              inventory_group: group,
            });

          });

          if(ctrl.search_option === 'reference_no') {
            ctrl.search_result_count = ctrl.search_groups.length;
          } else {
            ctrl.search_result_count = ctrl.search_inventory_items.length;
          }
          doneLoading('search');
        }
      });
    }

    function loadLocations () {
      var locals = null;
      if (ctrl.inventory_locations) {
        locals = angular.copy(ctrl.inventory_locations);
      }
      sowImplantService.getInventoryLocations()
      .then(function(response){
        ctrl.inventory_locations = _.sortBy(response,['name']);
        if (locals) {
          var new_location = _.first(_.differenceBy(angular.copy(ctrl.inventory_locations), locals, 'id'));
          if (new_location) {
            _.set(ctrl.current_inventory_item, "location_id", new_location.id);
          }
        }
      })
      .catch(function(error){
        var t_message = $filter('translate')('ERRORS.INVENTORY_LOCATIONS_LOAD_TRY_AGAIN')
        errorService.uiErrorHandler(error.message || t_message);
      });
    }

    function selectView(clicked_view) {
      if (clicked_view === 'tracking_levels') {
        ctrl.initial_tracking_levels_backup = cloneInventoryGroupFields();
      }

      // Checks if the user is trying to change the tab while the tracking level form is dirty
      if (clicked_view === 'imp_inventory' && isTrackingLevelFormActiveAndDirty()) {
        openConfirmDialogTrackingLevel(true)
      } else {
        ctrl.displayed_view = clicked_view;
      }
    }

    function changeTabToImplantInventory () {
      ctrl.displayed_view = 'imp_inventory';
    }

    function isTrackingLevelTabActive () {
      return ctrl.displayed_view === 'tracking_levels';
    }

    function isTrackingLevelFormActiveAndDirty () {
      return isTrackingLevelTabActive() && ctrl.editImplantGroupForm?.$dirty
    }
  
    function resetTrackingLevelForm () {
      ctrl.editImplantGroupForm?.$setPristine();
      ctrl.editImplantGroupForm?.$setUntouched();
      ctrl.hover_item_options.inventory_group = {
        ...ctrl.hover_item_options.inventory_group,
        ...ctrl.initial_tracking_levels_backup,
      }
    }

    function showTrackingLevelFormConfirmation() {
      // Reset variable to default state
      ctrl.should_trigger_set_hover = true;

      if (isTrackingLevelFormActiveAndDirty()) {
        openConfirmDialogTrackingLevel()
      }
    }

    function openConfirmDialogTrackingLevel (tracking_tab_closed = false) {
      return RedirectModalService.show(null, 'SaveChanges')
        .then(() => {
          updateImplantGroup(ctrl.tracking_edited_values_backup, tracking_tab_closed);
        }).catch(() => {
          resetTrackingLevelForm();
          if (tracking_tab_closed) {
            changeTabToImplantInventory()
          } else {
            closeDialogs();
          }
        }).finally(() => {
          // Reset to undefined to use default values
          ctrl.tracking_edited_values_backup = undefined
          ctrl.initial_tracking_levels_backup = undefined
        })
    }

    function onCancelClick () {
      closeDialogs('add-implant')
      // Prevents having two modals when the user clicks on the cancel button
      ctrl.should_show_implant_discard_modal = false;
    }

    function openConfirmDialogEditImplant () {
      ctrl.current_inventory_edited_item_backup = angular.copy(ctrl.current_inventory_item)
      return RedirectModalService.show(null, 'SaveChanges')
        .then(() => {
          // Prevents the hover event from being triggered, after saving
          ctrl.should_trigger_set_hover = false;
          saveCurrentItem()
        }).catch(() => {
          resetEditForm();
          closeDialogs('add-implant')
        });
    }

    function showEditImplantFormConfirmation() {
      // If the form is dirty, current form is the edit form and close events don't come from the cancel button
      if (ctrl.editImplantForm?.$dirty && isEditImplantForm() && ctrl.should_show_implant_discard_modal) {
        openConfirmDialogEditImplant()
      }
    }

    function isEditImplantForm () {
      return Boolean(ctrl.current_inventory_item.id)
    }

    function cloneInventoryGroupFields() {
      return angular.copy(ctrl.hover_item_options.inventory_group);
    }

    /** 
     * Opens the Product Detail slideout of the marketplace
     * product which corresponds to the implant. 
     * 
     * @param {String} product_id
    */
    function handleAddToCartClick(product_id = ctrl.product_id) {
      ctrl.whatIsLoading.productDetails = true;
      return mktHelperService.productDetailsFetch(product_id);
    }

    /**
     * Ensure the inventory group has a category, then add it to the shopping list.
     * @param {Object} inventory_group - The clicked inventory group.
     * @returns The result of the impHelperService.addToShoppingList function.
     */
    function handleAddToShoppingListClick(inventory_group) {
      // the success toast message we show after adding the item requires that the
      // inventory group has a category string in its catalog_item property
      _ensureGroupHasCategory(inventory_group);

      // Show the Product Details Slideout if the product_id is set and the user has multi-supplier selection
      if (ctrl.product_id && ctrl.has_multi_supplier_selection) {
        ctrl.whatIsLoading.productDetails = true;
        return mktHelperService.productDetailsFetch(ctrl.product_id, {
          action: 'ADD_TO_SHOPPING_LIST',
          item: {
            id: inventory_group.inventory_item_id,
          }
        });
      }

      // If the user does not have multi-supplier selection, add the item to the shopping list
      return impHelperService.addToShoppingList(inventory_group);
    }

    /**
     * If the inventory group does not have a category, set the category to the current table's name.
     * @param {Object} inventory_group - The inventory group that is being processed.
     * @return {Object} - The inventory group (updated if required).
     */
    function _ensureGroupHasCategory(inventory_group) {
      if (!_doesGroupHaveCategory(inventory_group)) {
        const category = _getCategoryName();
        _.set(inventory_group, 'catalog_item.category', category);
      }
      return inventory_group;
    }

    /**
     * If the catalog item of the inventory group has a valid category, then return true.
     * Otherwise, return false.
     * @param {Object} inventory_group - The inventory group that is being processed.
     * @returns {boolean} 
     */
    function _doesGroupHaveCategory(inventory_group) {
      const category = inventory_group?.catalog_item?.category;
      const is_category_string = typeof category === 'string';
      const is_category_populated = category?.length > 0;
      return Boolean(is_category_string && is_category_populated);
    }

    /**
     * Gets the display name of the current catalog item.
     * @returns {string}
     */
    function _getCategoryName() {
      return ctrl.current_catalog_item?.category;
    }

    /**
     * Determines if the `Add to Cart` button should be available
     * 
     */
    function shouldShowAddToCartButton() {
      return Boolean(ctrl.product_id && !ctrl.disable_marketplace_orders && !ctrl.hideCartButton);
    }

    /**
     * Determines if shopping list button should be hidden based on:
     * 1. If the office has disabled shopping list
     * 2. If the implant is discontinued
     * @returns {boolean}
     */
    function shouldHideShoppingListButton() {
      return ctrl.disable_shopping_list || isImplantDiscontinued();
    }

    /**
     * Determines if the implant is discontinued
     * @returns {boolean}
     */
    function isImplantDiscontinued () {
      return ctrl.hover_item_options?.inventory_group && inventoryItemService.isItemDiscontinued(ctrl.hover_item_options.inventory_group)
    }

    /**
     * Searches for patients in the API and returns an array of parsed patient objects.
     * Triggered by the autocomplete element when typing patient names or IDs into it.
     * 
     * @param {string} search_text
     * @return {Promise<ParsedPatient[]>}
     */
    function searchPatients (search_text) {
      if (search_text === '' || _.isNil(search_text)) return $q.resolve([]);
      return PatientService.searchPatients(search_text).then((response) => {
        return _.map(response.patients, (patient) => PatientHelperService.parsePatient(patient));
      });
    }

    function _setPatientEmptyState () {
      ctrl.current_inventory_item = {
        ...ctrl.current_inventory_item,
        placement_id: null,
        referring_doctor_name: null,
        referring_doctor_id: null,
        was_placed_by: null,
        was_placed_by_id: null,
        UI: {
          ...ctrl.current_inventory_item?.UI,
          referring_doctor: null,
          provider_doctor: null,
        }
      };
      ctrl.patient_procedures = [];
      ctrl.disable_procedure_selection = true;
      ctrl.procedure_required = false;
    }

    /**
     * Updates the Implant and patient procedures based on the selected patient.
     * Triggered by selecting an option from the autocomplete in a 'remove implant' dialog.
     * 
     * @param {ParsedPatient} patient - contains "id", "cn", "name", "referring_doctor", and "provider_doctor".
     */
    function selectedPatientRemovalChanged (patient) {
      _setPatientEmptyState();
      if (!patient) {
        return;
      }
      const {referring_doctor, provider_doctor} = patient;

      ctrl.current_inventory_item = {
        ...ctrl.current_inventory_item,
        placement_id: patient.id,
        is_external_id: true,
        referring_doctor_name: referring_doctor.name ?? '',
        referring_doctor_id: referring_doctor.id,
        was_placed_by: provider_doctor.name ?? '',
        was_placed_by_id: provider_doctor.id,
        UI: {
          ...ctrl.current_inventory_item.UI,
          referring_doctor,
          provider_doctor,
        }
      };
      _updatePatientProcedures(patient);
    }


    /**
     * Retrieves the care plans for a patient, extracts the
     * first procedure from each care plan, formats the procedure date, and updates the patient's
     * procedures list.
     * 
     * @param {ParsedPatient} patient 
     */
    function _updatePatientProcedures (patient) {
      return PatientService.getPatientCarePlans(patient.id).then((response) => {
        ctrl.patient_procedures = _.map(response, (care_plan) => {
          const procedure = care_plan.procedures[0];
          return {
            ...procedure,
            formatted_date: moment(procedure.performedAt).format('MM/DD/YYYY'),
          };
        });
        ctrl.disable_procedure_selection = false;
        ctrl.procedure_required = _.size(ctrl.patient_procedures) > 0;
      });
    }

    /**
     * When a procedure is selected, update the implant's placement date to match the procedure's date.
     * 
     * @param {PatientProcedure} procedure
     */
    function selectedProcedureChanged (procedure) {
      const formatted_date = new Date(procedure.performedAt);
      ctrl.current_inventory_item.was_placed_on = formatted_date;
      ctrl.selected_procedure_id = procedure.id;
    }

    /**
     * Retrieves a list of patients based on the reservation IDs
     * provided in the `item_options` object.
     * @param {object} item_options
     */
    function _fetchReservedItems (inventory_items) {
      if (ctrl.has_pwf_access) {
        const ids = _.filter(
          _.map(inventory_items, 'reservation_id'), 
          (item) => !_.isNil(item)
        );
        ctrl.loading_patients_list = true;
        if (_.size(ids) > 0) {
          PatientService.getPatientsList(ids)
            .then((list_response) => {
              ctrl.patients_map = list_response;
            })
            .finally(() => {
              ctrl.loading_patients_list = false;
            });
        }
      }
    }

    /**
     * Returns the name associated with a reservation ID if it exists in
     * the `ctrl.patients_map`, otherwise it returns the reservation ID itself.
     * @param {string} reservation_id
     * @returns {string}
     */
    function getReservedName (reservation_id) {
      if (!ctrl.patients_map) {
        return reservation_id;
      }

      return ctrl.patients_map[reservation_id]?.name || reservation_id
    }

    /**
     * Updates the implant's reservation ID based on the selected patient.
     * Triggered by selecting an option from the autocomplete in a 'edit implant' dialog.
     * 
     * @param {ParsedPatient} patient - contains "id", "cn", "name", "referring_doctor", and "provider_doctor".
     */
    function selectedPatientReserveChanged (patient) {
      ctrl.current_inventory_item.reservation_id = patient?.id;
    }

    function _getPatient (patient_id) {
      ctrl.loading_patient = true;
      return PatientService.getPatient(patient_id)
      .then((response) => {
        return PatientHelperService.parsePatient(response);
      })
      .finally(() => {
        ctrl.loading_patient = false;
      });
    }
  }
}());

angular.module('app.shared.ui')
  .directive('videoModal', function () {
    return {
      controller: 'VideoModalController',
      controllerAs: 'vmCtrl',
      scope: true,
      link: function (scope, element, attrs, videoModalCtrl) {
        videoModalCtrl.init();
      }
    };
  });

(function () {
  'use strict';
  angular.module('sowMarketplace')
  .controller('MarketplaceVendorOrderController', MarketplaceVendorOrderController);

  function MarketplaceVendorOrderController ($scope, $rootScope, $state, $location, sessionService, errorService, cartService, accountService, sowApprovalService, sowApprovalHelperService, mktHelperService, AccessService, EVActionsHelperService, sowExternalVendorAccountService, $q, externalVendorApiUrl, sowAnalyticsService, msHelperService) {
    const EStatus = Object.freeze({
      ACTIVE: 'active',
      COMPLETED: 'completed',
      ERROR: 'error',
      INCOMPLETE: 'incomplete',
      SKIPPED: 'skipped',
    });
    
    const ctrl = {
      ...this,
      confirmation_form: null,
      confirmation_required: false,
      has_auto_card_submission_occurred: false,
      logged_begin_checkout_event_group_ids: [],
      is_checked: false,
      parent_cart_id: null,
      show_confirmation_form: false,
      skip_order: false,
      completeOrder,
      handleOrderDetailsUpdate,
      handleSkipOrderEvent,
      hideCheckoutError,
      hideForm,
      isOrderAvailable,
      scrollToTop,
      shouldShowCheckoutError,
      $onInit: init,
      isCardRequired,
      shouldShowInvoiceBilling,
    };
    
    // -------------------- private methods (exposed for testing only) --------------------
    ctrl._handleCheckoutError = _handleCheckoutError;

    return ctrl;

    function init () {
      _defineLocks();
      _checkForRedirect();
      _refreshSession();

      $scope.$on('cartService:end-action:item-change', _handleItemChange);
      $scope.$on('cartService: set-cart', _updateCart);
      $scope.$on('Marketplace:complete-order', completeOrder);
      $scope.$on('credit-card-auto-upload-failed', _handleCreditCardAutoUploadFailure);
      $scope.$on('Marketplace: EV promo code', _applyEVPromoCode);

      $q.when($rootScope.ev_data_loaded).then(() => {
        if (!_.isEmpty($rootScope.external_vendors_actions) && !_.isEmpty($rootScope.external_vendors)) {
          _updateCart();
          _generateSteps();
        }
      });
    }

    /**
     * Fires from the react form component, updates any selected options or additional data changed
     * 
     * @param {object} event
     * @param {object} event.detail
     * @param {object} event.detail.currentValues
     */
    function handleOrderDetailsUpdate ({detail: {currentValues}}) {
      if (currentValues) {
        _updateOrderInfo(currentValues);
      }
    }

    /**
     * Fires from the react dialog, triggers an order skip
     * 
     * @param {object} event
     * @param {object} event.detail
     * @param {boolean} event.detail.onClose
     */
    function handleSkipOrderEvent ({detail: {onClose}}) {
      if (onClose) {
        _skipOrder();
      }
    }

    /**
     * Shows the status dialog.
     * @param {'placing'|'preparing'|'success'|'updating'} status_name
     */
    function _showStatusDialog (status_name = 'preparing') {
      ctrl.dialog_action = status_name;
    }

    function _hideStatusDialog () {
      ctrl.dialog_action = null;
    }

    function _showSkipOrderDialog () {
      _hideStatusDialog();
      ctrl.skip_order = true;
    }

    /**
     * Turns the session cart's vendor groups into steps used for local state.
     * Should run only once on page visit.
     *  
     * @param {CartVendorGroup[]} groups - This is the array of vendor groups that we are going to iterate through.
     */
    function _generateSteps (groups = ctrl.cart?.vendor_groups) {
      ctrl.steps = _.map(groups, (group, index) => {
        const vendor = _findVendorForGroup(group);
        const vendor_actions = EVActionsHelperService.getCurrentVendorActions(vendor);

        return {
          status: index === 0 ? EStatus.ACTIVE : EStatus.INCOMPLETE,
          text: group.vendor_name,
          is_external: EVActionsHelperService.isExternalVendor(group),
          group,
          vendor,
          vendor_actions,
          summary: {
            // these group properties come from the session cart,
            // on EV groups they will be overwritten with order review data
            CartSubTotal: group.items_subtotal,
            CartTaxTotal: group.tax,
            CartShippingTotal: group.shipping_subtotal,
            CartTotal: group.total,
            CartStatus: null,
          },
          promo_code: null,
          additional_details: null,
          radio_groups: null,
          // Do not rename the variables below as they are using the same backend format (PascalCase)
          BillingMethods_selected: null,
          ShippingMethods_selected: null,
        };
      });
      _goToStep(0);
    }

    /**
     * Update the steps in the checkout process based on the new cart
     * @param {Cart} new_cart - the new cart object
     */
    function _updateSteps (new_cart) {
      _.each(ctrl.steps, (step) => {
        const new_group = _.find(new_cart.vendor_groups, {vendor_id: step.group?.vendor_id});
        step.group = new_group;
        if (!step.is_external) {
          step.summary = {
            CartSubTotal: new_group?.items_subtotal,
            CartTaxTotal: new_group?.tax,
            CartShippingTotal: new_group?.shipping_subtotal,
            CartTotal: new_group?.total,
            CartStatus: null,
          };
        }

        if (_.isEmpty(step.group?.items) && _isCurrentStep(step)) {
          _changeCurrentStepStatus(EStatus.SKIPPED);
        }
      });
    }

    function _isCurrentStep (step) {
      return step?.text === ctrl.current_step?.text;
    }

    /**
     * Sets current state to the step at the given index.
     * For external vendors, this will also trigger _setupVendorCart.
     * @param {number} [index=0] - The index of the step you want to go to.
     */
    function _goToStep (index = 0) {
      scrollToTop();
      ctrl.current_step = ctrl.steps[index];
      ctrl.current_step_number = index + 1;
      ctrl.step_count = ctrl.steps.length > 1 ? `(${ctrl.current_step_number}/${ctrl.steps.length})` : '';
      // handling cart updates on future steps
      // eg. when a user removes items from the slideout, while still in this page
      if (ctrl.current_step.status === EStatus.SKIPPED) {
        _skipOrder();
      }
      _.set(ctrl, 'current_step.status', 'active');

      _getCartGroupStats();

      /* if the vendor is not external we're ready to log the
      begin_checkout event, but if it is external the event
      can't be logged until additional info has been fetched */
      if (!ctrl.current_step.is_external) {
        _logBeginCheckout();
        return;
      }

      // hook for external vendors loading promise
      $q.when($rootScope.ev_data_loaded).then(() => {
        _setupVendorCart(ctrl.current_step);
      });
    }

    /**
     * Loads information about the cart and updates the current step accordingly
     */
    async function _getCartGroupStats() {
      const group_stats = await cartService.getCartStats({cart_vendor_group_id: ctrl.current_step.group.id});
      ctrl.current_step.total_savings = group_stats.total_savings;
      $scope.$apply();
    }

    /**
     * Callback for when the user updates a cart item
     */
    function _handleItemChange() {
      _updateCart();
      _getCartGroupStats();
    }

    /**
     * Navigates to the next step within ctrl.steps, 
     * or to the confirmation page if it's the last step.
     */
    function _goToNextStep () {
      const index = ctrl.steps.indexOf(ctrl.current_step);
      const is_not_last_step = index < ctrl.steps.length - 1;
      if (is_not_last_step) {
        hideCheckoutError();
        _goToStep(index + 1);
      } else {        
        setTimeout(() => {
          _navigateToConfirmationOrMarketplacePage();
        }, 500);
        _updateCart();
      }
    }

    /**
     * Sets the status of the current step to the value of the current_step_status parameter
     * @param {'active'|'completed'|'error'|'incomplete'|'skipped'} current_step_status
     */
    function _changeCurrentStepStatus (current_step_status) {
      _.set(ctrl, 'current_step.status', current_step_status);
    }

    /**
    * Runs once the user navigates to each step and does:
    * - load billing/shipping options
    * - set additional info (special instructions, selected options, etc)
    * - add items to external cart (clear & add from scratch)
    * - update order review (gets updated summary)
    * During this process, a dialog is shown to the user
    * @param {object} step
    */
    async function _setupVendorCart (step) {
      if (step.setting_up || !step.is_external) return;
      step.setting_up = true;
      _showStatusDialog('preparing');
      
      const order_review_action = EVActionsHelperService.getActionInfo(step.vendor_actions, EVActionsHelperService.ACTIONS.ORDER_REVIEW);
      const place_order_action = EVActionsHelperService.getActionInfo(step.vendor_actions, EVActionsHelperService.ACTIONS.PLACE_ORDER);
      const add_to_cart_action = EVActionsHelperService.getActionInfo(step.vendor_actions, EVActionsHelperService.ACTIONS.ADD_TO_CART);
      
      _getBillingShippingOptions(step);
      _setAdditionalInfo(step, order_review_action, place_order_action);
      const add_item_data_list = await _addItemsToExternalCart(step, add_to_cart_action);
      await _addAllItems(add_item_data_list, add_to_cart_action);
      await _updateOrderReview(step);
      
      step.setting_up = false;
    }

    /**
    * fetch external vendor's billing/shipping options for users to select from
    * @param {object} step
    */
    function _getBillingShippingOptions (step) {
      const billing_action = EVActionsHelperService.getActionInfo(step.vendor_actions, EVActionsHelperService.ACTIONS.BILLING_AND_SHIPPING);

      // hit billing/shipping endpoint (could be preemptive & async on _generateSteps)
      if (billing_action) {
        return sowExternalVendorAccountService.callAction(billing_action)
        .then(({data}) => {
          step.radio_groups = Object.entries(data).map(_parseBillingFormData);
        })
        .catch(() => {
          _showSkipOrderDialog();
        });
      }
    }

    /**
    * Clears the external vendor's cart and adds each item from scratch.
    *
    * the reason why we need this is to be in and out of the external vendor's cart as fast as possible,
    * reducing risk of errors and timeouts
    * @param {object} step
    */
    async function _addItemsToExternalCart (step, add_to_cart_action) {
      const clear_action = EVActionsHelperService.getActionInfo(step.vendor_actions, EVActionsHelperService.ACTIONS.CLEAR_CART);
      // clear EV Cart AND add each item to EV Cart
      if (clear_action && add_to_cart_action) {
        await sowExternalVendorAccountService.callAction(clear_action, {monolith_cart_id: ctrl.cart.id})
        
        // add each item to cart
        const add_item_data_list = _.map(step?.group?.items, ({uom, vendor_sku, quantity}) => {
          const add_item_data = {
            monolith_cart_id: ctrl.cart.id,
            quantity,
            uom,
            vendor_sku,
          };

          return add_item_data;
        });
        
        return add_item_data_list;
        
      }
    }

    /**
     * Takes a list of items to add to external vendor cart, by using the provided action.
     * 
     * @param {object[]} add_item_data_list
     * @param {object} add_to_cart_action
     */
    async function _addAllItems (add_item_data_list, add_to_cart_action) {
      for (const add_item_data of add_item_data_list) {
        try {
          await sowExternalVendorAccountService.callAction(add_to_cart_action, add_item_data);
        } catch (error) {
          // _checkForEmptyStep(step);
          console.error('failed to add item to cart', error);
          _showSkipOrderDialog();
        }
      }
    }

    /**
    * Hits external vendor's order review endpoint to get updated summary
    * @param {object} step
    */
    async function _updateOrderReview (step) {
      const order_review_action = EVActionsHelperService.getActionInfo(step.vendor_actions, EVActionsHelperService.ACTIONS.ORDER_REVIEW);
      // hit order review endpoint after all items are done
      if (order_review_action) {
        const review_data = {
          shipping_method: step.ShippingMethods_selected,
          billing_method: step.BillingMethods_selected,
          po_number: _.find(step.additional_details, {name: 'po_number'})?.value,
          monolith_cart_id: ctrl.cart.id,
          special_instructions: _.find(step.additional_details, {name: 'special_instructions'})?.value,
          promo_code: step.promo_code
        }

        try {
          const response = await sowExternalVendorAccountService.callAction(order_review_action, review_data);
          _handleOrderReviewResponse(response, step, true);
        } catch (error_response) {
          console.error(error_response);
          _showSkipOrderDialog();
        }
      }
    }

    /**
    * Sets additional info for the step object (special instructions, selected options, etc)
    * @param {object} step
    * @param {object} order_review_action
    * @param {object} place_order_action
    */
    function _setAdditionalInfo (step, order_review_action, place_order_action) {
      if (step.vendor_actions) {
        if (place_order_action) {
          // this one is not actually used on frontend code, we just pass it to the monolith
          step.place_order_url = `${externalVendorApiUrl}${place_order_action.endpoint}`;
        }
        if (order_review_action) {
          step.ShippingMethods_selected = order_review_action?.json?.shipping_method?.default; // TODO: update to ShippingMethod as soon as Backend does
          step.BillingMethods_selected = order_review_action?.json?.billing_method?.default; // TODO: update to BillingMethod as soon as Backend does
        }

        // find special instructions stuff
        const {UI_flags: {po_number, special_instructions}} = step.vendor_actions;
        const text_fields = [];
        if (po_number) {
          text_fields.push({
            name: 'po_number',
            value: ''
          });
        }

        if (special_instructions) {
          text_fields.push({
            name: 'special_instructions',
            value: '',
            isTextArea: true,
          })
        }

        step.additional_details = text_fields;
      }
    }

    /**
     * Updates the summary of the order review step based on the response from the server
     * 
     * @param {object} review_response
     * @param {object} step
     * @param {boolean} should_refresh
     */
    function _handleOrderReviewResponse (review_response, step, should_refresh) {
      // update summary based on response
      const review_data = review_response[0].data;
      _.each(review_data, (content, key) => {
        // CartSubTotal: 8.27
        // ...
        step.summary[key] = content.value;
      });

      // now that we've fetched updated info we can log the GA event
      _logBeginCheckout();

      _hideStatusDialog();
      _syncExternalCart(step.group, review_data.CartStatus);

      if (should_refresh) {
        $scope.$digest();
      }
    }

    /**
     * If any items are removed from EV cart, remove from sowingo cart as well
     * (if all items are removed, skip to next order)
     * @param {object} vendor_group
     * @param {object[]} CartStatus
     */
    function _syncExternalCart (vendor_group, CartStatus) {
      // check if CartStatus -> CartItemId.value matches each item.vendor_sku (just check if they exist)
      // if any items are removed from EV cart, remove from sowingo cart
      // if all items are removed, skip order

      const remove_promises = [];
      _.each(vendor_group.items, (cvg_item) => {
        const evg_item = _.find(CartStatus, (item) => (cvg_item.vendor_sku === item.data.CartItemId.value));
        if (!evg_item) {
          remove_promises.push(_removeItemFromCart(cvg_item));
        }
      });
      $q.all(remove_promises).then(() => {
        _checkForEmptyStep();
      });
    }

    /**
     * Removes item from monolith cart. 
     * Used when an item is removed from EV cart and we need to sync with monolith.
     * 
     * @param {object} item
     * @return {Promise<object>}
     */
    function _removeItemFromCart (item) {
      return cartService.removeCartItem(item);
    }

    /**
     * Logs information about the current step's vendor group prior to checkout
     */
    function _logBeginCheckout() {
      const {
        checkout_type,
        id,
        products,
        subtotal,
        value,
        vendor,
        vendor_type,
      } = _getGroupInfo();
      if (products.length && !ctrl.logged_begin_checkout_event_group_ids.includes(id)) {
        ctrl.logged_begin_checkout_event_group_ids.push(id);
        sowAnalyticsService.logBeginCheckout(products, {
          checkout_type,
          subtotal,
          value,
          vendor,
          vendor_type,
        });
      }
    }

    /**
     * Logs information about the current step's vendor group following checkout
     * @param {object} order
     */
    function _logCheckout(order) {
      const {
        checkout_type,
        parent_id,
        products,
        promo_code,
        shipping,
        subtotal,
        tax,
        value,
        vendor,
        vendor_type,
      } = _getGroupInfo();
      if (products.length) {
        sowAnalyticsService.logCheckout(products, {
          checkout_type,
          coupon: promo_code,
          order_hrid: order.order_id,
          parent_id,
          shipping,
          subtotal,
          tax,
          value,
          vendor,
          vendor_type,
        });
      }
    }

    /**
     * Returns information about the current vendor group
     * @return {object}
     */
    function _getGroupInfo() {
      const {group, summary} = ctrl.current_step ?? {}
      const {
        checkout_type,
        id,
        is_external_vendor,
        items,
        items_subtotal,
        promo_code,
        shipping_subtotal,
        tax,
        total,
        vendor_name,
      } = group ?? {};
      const {CartShippingTotal, CartSubTotal, CartTaxTotal} = summary ?? {}
      const shipping = CartShippingTotal ?? shipping_subtotal;
      const subtotal = CartSubTotal ?? items_subtotal;
      return {
        checkout_type,
        id,
        parent_id: ctrl.parent_cart_id,
        products: _createEventProducts(items),
        promo_code,
        shipping,
        subtotal,
        tax: CartTaxTotal ?? tax,
        /* value is total because during single checkout
        we consider this vendor group to be the order (whereas
        in the multi-vendor flow it's the whole cart) */
        value: total,
        vendor: vendor_name,
        vendor_type: is_external_vendor ? 'external' : 'internal',
      }
    }

    /**
     * Adds vendor and inventory information to each of the items it receives,
     * preparing them to be logged as the `products` list of a GA event
     * @param {object[]} items
     * @return {object[]}
     */
    function _createEventProducts(items) {
      return _.map(items, item => _.extend({}, item.vendor_inventory, item));
    }

    /**
     * If there are no items left, show the skip order dialog
     * @param {object} step
     */
    function _checkForEmptyStep (step = ctrl.current_step) {
      if (!step) return;
      if (_.isEmpty(step.group?.items)) {
        // for external vendors, the empty cart might be due to items being removed from the EV cart
        // for internals, it might be just the user clicking "remove"
        if (step.is_external) {
          // clicking the button from the dialog will already handle the "last step" case
          _showSkipOrderDialog();
        } else {
          const is_last_step = step.text === _.last(ctrl.steps)?.text;
          // for the last step, we'll have the following states:
          // 1. empty step, but at least 1 order was finished -> go to confirmation page
          // 2. empty step, and no orders were finished -> go to empty state
          if (is_last_step) {
            if (_hasSuccessfulOrders()) {
              return _navigateToConfirmationOrMarketplacePage();
            }
            // shows empty state
            return;
          }
          // on all other steps, skip to next vendor
          return _skipOrder();
        }
      }
    }

    function _hasSuccessfulOrders (steps = ctrl.steps) {
      return _.some(steps, (step) => step.status === EStatus.COMPLETED);
    }
    
    /**
    * Helper function to determine if a vendor is external or not
    * @param {object} vendor_group
    * @return {boolean}
    */
    function _isEV (vendor_group) {
      return EVActionsHelperService.isExternalVendor(vendor_group);
    }

    /**
    * Find the vendor for a given cart vendor group
    * @param {object} vendor_group
    * @return {object|undefined}
    */
    function _findVendorForGroup (vendor_group) {
      const ev_info = _.get($rootScope.external_vendors, vendor_group.vendor_id);
      return ev_info?.vendor;
    }

    /**
    * The cart service uses the current session; we need to refresh the session to update
    * the cart items every time the user visits the cart section.
    */
    function _refreshSession () {
      sessionService
      .refreshSession()
      .then(() => _updateCart())
      .catch(errorService.uiErrorHandler);
    }

    /**
    * Looks for HTML text which is a form that the user needs to read and accept
    * in order to purchase an item.
    *
    * @return {*}
    */
    function _checkForConfirmationForm () {
      ctrl.confirmation_form = null;
      ctrl.confirmation_required = false;
      let need_consent = false;

      if (!ctrl.current_step.group?.items) return;

      const items = ctrl.current_step.group.items;
      _.each(items, function (item) {
        need_consent = _.get(item, 'vendor_inventory.properties.need_consent', false);
        if (need_consent) {
          ctrl.confirmation_form = need_consent;
          ctrl.confirmation_required = true;
        }
      });
      if (!ctrl.confirmation_required) {
        ctrl.show_confirmation_form = false;
      }
    }

    function hideForm () {
      ctrl.show_confirmation_form = false;
    }

    /**
     * Updates cart data in local state. 
     * Generates steps if they don't exist. Updates if they do.
     * Checks for confirmation form.
     */
    function _updateCart () {
      if (ctrl.cart_is_updating) return;
      ctrl.cart_is_updating = true;
      ctrl.cart = cartService.get();
      ctrl.cart_is_empty = _.size(_.get(ctrl, 'cart.items')) === 0;      

      _setParentId();
      if (ctrl.current_step) {
        _checkForConfirmationForm();
        
        if (!ctrl.dialog_action) {
          _updateSteps(ctrl.cart);
          _checkForEmptyStep();
        }
      }

      if(_.isEmpty(ctrl.steps)) {
        _generateSteps();
      }
      ctrl.cart_is_updating = false;
    }

    /**
     * Updates external vendor's cart with the current values of the form.
     * Triggered when users click "save changes" in the form.
     * 
     * @param {object} current_values
     */
    function _updateOrderInfo (current_values) {
      const actions = ctrl.current_step.vendor_actions;
      const order_review = EVActionsHelperService.getActionInfo(actions, EVActionsHelperService.ACTIONS.ORDER_REVIEW)

      if(order_review) {
        _updateControllerValues(current_values);
        _showStatusDialog('updating');
        
        const payload = {
          billing_method: current_values.BillingMethods,
          shipping_method: current_values.ShippingMethods,
          monolith_cart_id: ctrl.cart.id,
          po_number: current_values.po_number,
          special_instructions: current_values.special_instructions,
        }

        sowExternalVendorAccountService.callAction(order_review, payload).then((data) => {
          _handleOrderReviewResponse(data, ctrl.current_step)
        }).catch(() => {
          _showSkipOrderDialog();
        })
      }
    }

    /**
     * On the first call, will receive isLoading: true and show the updating dialog.
     * On the second call, will receive isLoading: false and the promo code data.
     * 
     * @param {object} _ev - The event object
     * @param {object} payload
     * @param {boolean} payload.isLoading
     * @param {Array<object>} payload.response
     */
    function _applyEVPromoCode(_ev, {isLoading, response}) {
      ctrl.dialog_action = isLoading ? 'updating' : null;

      if (response) {
        const codes = msHelperService.getDataValue(response[0].data.CartPromoCodes);
        _.set(ctrl.current_step, 'promo_code', codes);
        _handleOrderReviewResponse(response, ctrl.current_step)
      }
    }

    /**
     * Gets data from order review response and updates the current step.
     * @param {object} response
     * @param {string|undefined} response.po_number
     * @param {string|undefined} response.special_instructions
     * @param {string|undefined} response.BillingMethods
     * @param {string|undefined} response.ShippingMethods
     */
    function _updateControllerValues ({po_number, special_instructions, BillingMethods, ShippingMethods }) {
      _.set(_.find(ctrl.current_step.additional_details, {name: 'po_number'}), 'value', po_number);
      _.set(_.find(ctrl.current_step.additional_details, {name: 'special_instructions'}), 'value', special_instructions);
      _.set(_.find(ctrl.current_step.radio_groups, {title: 'BillingMethods'}), 'selectedOptionId', BillingMethods);
      _.set(_.find(ctrl.current_step.radio_groups, {title: 'ShippingMethods'}), 'selectedOptionId', ShippingMethods);
      _.set(ctrl.current_step, 'BillingMethods_selected', BillingMethods);
      _.set(ctrl.current_step, 'ShippingMethods_selected', ShippingMethods);
    }

    /**
     * Parses billing_shipping_options data into a format that the radio group component can use.
     * @param {object} formData - keys are titles, values are options array
     * @return {object}
     */
    function _parseBillingFormData ([title, options]) {
      const selectedOptionId = _.get(ctrl.current_step, title+"_selected") || options[0].id.value;
      return {
        title,
        selectedOptionId,
        options: options.map(({id, name}) => ({id: id.value, text: name.value}))
      }
    }

    function _refreshCart () {
      return cartService.refreshCart();
    }

    /**
     * If the user is required to confirm the order, and they haven't checked the confirmation
     * checkbox, then show the confirmation form. 
     * If the user is required to ask for approval, show the approval dialog.
     * Otherwise, finish the order.
     * 
     * @return {Promise<object>}
     */
    function completeOrder () {
      if (ctrl.confirmation_required && !ctrl.is_checked) {
        ctrl.show_confirmation_form = true;
        return;
      }
      
      // TODO: apply approval logic to each step instead of the whole cart
      if (ctrl.approval_required) {
        // dialog
        return sowApprovalService.getAuthorizationAndApprovers(null, 'cart_order', ctrl.cart.id)
        .then((auth_response) => {
          if (auth_response.need_approval) {
            // open dialog
            const user_id = accountService.get().id;
            return sowApprovalHelperService.openDialog(null, auth_response.approvers_list, user_id, 'cart_order', ctrl.cart.id)
            .then((dialog_response) => {
              // go to pending order page
              _refreshCart();
              _updateCart();
              _goToPendingOrderPage(dialog_response);
            });
          } else {
            // finish
            return _finishOrder();
          }
        });
      } else {
        return _finishOrder();
      }
    }

    /**
     * Last step of the checkout process. Calls the cartService.completeGroup() function.
     * 
     * @param {object} step
     * @return {Promise<object>}
     */
    function _finishOrder (step = ctrl.current_step) {
      $scope.apiErrors = null;
      ctrl.saving = true;
      _showStatusDialog('placing');
      if (_checkIfCardShouldBeAdded()) {
        return _attemptToSaveCardBeforeCheckingOut();
      }

      const group_data = _createGroupData(step);

      return cartService.completeGroup(group_data)
      .then(order => {
        _logCheckout(order);
        _showStatusDialog('success');
        _changeCurrentStepStatus(EStatus.COMPLETED);
        _goToNextStep();

        setTimeout(() => {
          /* hide only the success dialog since the external vendor
          loading dialog may already be visible after the timeout */
          if (ctrl.dialog_action === 'success') {
            _hideStatusDialog();
          }
        }, 2000)
      })
      .catch(function(error) {
        _hideStatusDialog();
        _handleExternalOrInternalError(error)
      })
      .finally(function(){
        // reset auto card submission
        ctrl.has_auto_card_submission_occurred = false;
        ctrl.saving = false;
        _refreshCart();
        // _closeProcessingDialog();
        ctrl.show_confirmation_form = false;
      });
    }

    /**
     * Creates a group data object that is used to update the cart
     * @param {object} step
     * @return {object} an object with the following properties:
     * - cart_id
     * - cart_vendor_group_id
     * - external_checkout_information
     */
    function _createGroupData (step = ctrl.current_step) {
      const group_data = {
        cart_id: ctrl.cart.id,
        cart_vendor_group_id: step?.group?.id,
      };

      if (_isEV(step?.group)) {
        const title_case_props = step.summary;
        // needed because API is messed up
        const snake_case_props = {
          cart_subtotal: title_case_props.CartSubTotal,
          cart_taxtotal: title_case_props.CartTaxTotal,
          cart_shippingtotal: title_case_props.CartShippingTotal,
          cart_total: title_case_props.CartTotal,
        };

        group_data.external_checkout_information = {
          place_order_url: step.place_order_url,
          ...title_case_props,
          ...snake_case_props,
          dry_run: ctrl.dry_run
        }
      }

      return group_data;
    }

    function _handleExternalOrInternalError(error) {
      if (ctrl.current_step.is_external || mktHelperService.shouldSkipCurrentStep(error.internal_code)) {
        _showSkipOrderDialog();
      } else {
        _handleCheckoutError(error);
      }
    }

    /**
    * Closes the skip order dialog, changes the status of the current step to skipped, and
    * navigates to the next order or the next page (confirmation or marketplace)
    */
    function _skipOrder () {
      ctrl.skip_order = false;
      _changeCurrentStepStatus(EStatus.SKIPPED);
      _goToNextStep();
    }

    function isOrderAvailable () {
      return !ctrl.show_confirmation_form && !ctrl.cart_is_empty
    }

    /**
    * If a credit card is required, and no card is on file, and we have not
    * yet attempted to auto upload a credit card before checking out, then
    * the card should be added before we attempt to submit the order
    * @return {boolean}
    */
    function _checkIfCardShouldBeAdded() {
      // only try to upload the card if this is the first attempt to auto upload
      // a card (since otherwise we could create an infinite loop)
      if (ctrl.has_auto_card_submission_occurred) {
        return false;
      }
      return isCardRequired();
    }

    function isCardRequired (step = ctrl.current_step) {
      return step?.group?.checkout_type.includes('creditcart');
    }

    function shouldShowInvoiceBilling (step = ctrl.current_step) {
      return step?.group?.checkout_type.includes('invoice');
    }

    /**
    * Broadcasts an event so that the billing controller attempts to upload a new credit card
    * using the data the user has entered into its form. The billing controller will then
    * restart the checkout process, and ideally we will now be able to complete the order.
    */
    function _attemptToSaveCardBeforeCheckingOut() {
      // prevent an infinite loop in the event that card upload fails
      ctrl.has_auto_card_submission_occurred = true;
      $scope.$broadcast('save-card-and-complete-order');
    }

    function scrollToTop () {
      $location.hash('top-of-confirmation-form');
    }

    /**
    * Ensures the appropriate error is displayed, updates the cart to account for the error,
    * and scrolls to the top of the page where the error is displayed.
    * @param {object} error - The error object returned from the API.
    */
    function _handleCheckoutError(error) {
      _handleCheckoutErrorCode(error.internal_code);
      _updateCart();
      scrollToTop();
    }

    /**
    * Gets the localized text for an error code and displays that text in an alert.
    * @param {string} error_code - The internal_code property of the error
    */
    function _handleCheckoutErrorCode(error_code) {
      const t_checkout_error_text = mktHelperService.getLocalizedErrorFromCode(error_code);
      ctrl.checkout_error_text = t_checkout_error_text;
      ctrl.show_checkout_error = true;
    }

    /**
    * Returns true if we should display an error and we have text to display, and false if not. 
    * @returns {boolean}
    */
    function shouldShowCheckoutError() {
      const checkout_error_condition = ctrl.checkout_error_text && ctrl.show_checkout_error;
      return Boolean(checkout_error_condition);
    }

    /**
    * Hides the checkout error banner.
    */
    function hideCheckoutError() {
      ctrl.show_checkout_error = false;
    }

    /**
    * Hide the processing dialog, reset the credit card auto-submit state, and display
    * the error we get from the API when the billing method is invalid.
    */
    function _handleCreditCardAutoUploadFailure() {
      _hideStatusDialog();
      ctrl.show_confirmation_form = false;
      ctrl.has_auto_card_submission_occurred = false;
      // simulate the error we get back from the API when the credit card is invalid
      _handleCheckoutError({internal_code: 'CART_STRIPE_BILLING_ERROR'});
    }


      function _defineLocks () {
        ctrl.approval_required = AccessService.getProperty('purchase_orders.approval_req');

        /* we only want to set the `dry_run` property if we received a boolean
        from the API, otherwise it should remain undefined so it's not sent
        in the payload of the request to complete checkout - this is because
        if no value is sent, backend will evaluate the current environment
        and set dry run to true if it's dev/staging, and false if it's prod */
        const is_dry_run = AccessService.getProperty('external_vendors.enable_dry_run', null);
        if (typeof is_dry_run === 'boolean') {
          ctrl.dry_run = is_dry_run;
        }
      }

      function _goToPendingOrderPage (approval_request) {
        $state.go('app.mkt.pending', {approval_request, 'request_id': approval_request.id, 'request_hrid': approval_request.hrid});
      }

      /**
      * If the order has multiple vendors and all the orders were skipped, 
      * navigates the user to the marketplace page. Otherwise, navigates the user
      * to the order confirmation page.
      * @returns {void}
      */
      function _navigateToConfirmationOrMarketplacePage () {
        // Check if all vendors were skipped and if so, go to the marketplace page
        const skipped_vendors = _.filter(ctrl.steps, {status: EStatus.SKIPPED});
        const all_vendors_skipped = _.size(skipped_vendors) === _.size(ctrl.steps);
        // If all vendors were skipped, go to the marketplace page
        if (all_vendors_skipped) {
          $state.go('app.mkt');
        } else {
          _goToOrderConfirmationPage();
        }
      }

      /**
       * Keeps the original parent cart id in a separate variable, 
       * so that we can still use it while new IDs are being sent on every vendor checkout
       */
      function _setParentId () {
        if (ctrl.parent_cart_id === null) {
          ctrl.parent_cart_id = ctrl.cart.id;
        }
      }

      /**
      * Gets order parameters from the ctrl, and then navigates to the "Order Confirmation" page
      */
      function _goToOrderConfirmationPage () {
        const is_mixed_checkout = _.size(_.get(ctrl, 'cart.po_hrids')) > 0;
        // For Mixed checkout, search by checkout_id instead of parent_cart_id
        const search_param = is_mixed_checkout ? 'checkout_id' : 'parent_cart_id';

        const parameters = {
          order_number: ctrl.parent_cart_id,
          search_param,
        }

        $state.go('app.mkt.finished', parameters);
      }

      /**
     * While we still have both pages in prod, check if a redirect is necessary
     */
    function _checkForRedirect () {
      const flag = AccessService.getOfficeFeatureFlag('external_vendors');
      if (!flag) {
        $state.go('app.mkt.order');
      }
    }
    }
  })();
  
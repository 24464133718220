(function() {
  'use strict';
  
  angular
  .module('sowReports')
  .service('sowReportsService2', sowReportsService2);
  
  function sowReportsService2(apiUrl, serverAPI, errorService, officeService, $q) {
    /*jshint validthis: true */
    var service = this;
    
    service.getSpeedingOfficeReport = getSpeedingOfficeReport;
    service.getSpeedingOfficeMonthDetail = getSpeedingOfficeMonthDetail;
    service.getSpendingSupplierReport = getSpendingSupplierReport;
    service.getSpendingSupplierDetail = getSpendingSupplierDetail;
    service.getSpeedingCategoryReport = getSpeedingCategoryReport;
    service.getCategoryDetail = getCategoryDetail;
    service.getSupplierDetailByMonth = getSupplierDetailByMonth;
    service.getCategoryMonthDetail = getCategoryMonthDetail;
    
    return service;
    
    function getSpeedingOfficeReport(year) {
      var office = officeService.get();
      var office_id = _.get(office, 'id', null);
      if(!office_id){
        return $q.reject();
      }
      var url = '{0}/offices/{1}/reports/yearly?start_date={2}'.format(apiUrl, office_id, year);
      
      var options = {
        'method': 'GET'
      };
      
      return serverAPI
      .doAPICall(url, options)
      .then(function(response) {
        return response.data;
      })
      .catch(errorService.uiErrorHandler);
    }
    
    function getSpeedingOfficeMonthDetail(yearMonth) {
      var office = officeService.get();
      var office_id = _.get(office, 'id', null);
      if(!office_id){
        return $q.reject();
      }
      var url = '{0}/offices/{1}/reports/monthly?start_date={2}'.format(apiUrl, office_id, yearMonth);
      
      var options = {
        'method': 'GET'
      };
      
      return serverAPI
      .doAPICall(url, options)
      .then(function(response) {
        return response.data;
      })
      .catch(errorService.uiErrorHandler);
    }
    
    function getSpendingSupplierReport(year) {
      var office = officeService.get();
      var office_id = _.get(office, 'id', null);
      if(!office_id){
        return $q.reject();
      }
      var url = '{0}/offices/{1}/reports/supplier?start_date={2}'.format(apiUrl, office_id, year);
      
      var options = {
        'method': 'GET'
      };
      
      return serverAPI
      .doAPICall(url, options)
      .then(function(response) {
        return response.data;
      })
      .catch(errorService.uiErrorHandler);
    }
    
    function getSpendingSupplierDetail(supplier_id, year) {
      var office = officeService.get();
      var office_id = _.get(office, 'id', null);
      if(!office_id){
        return $q.reject();
      }
      var url = '{0}/offices/{1}/reports/supplier/{2}/?start_date={3}'.format(apiUrl, office_id, supplier_id, year);
      var options = {
        'method': 'GET'
      };
      
      return serverAPI
      .doAPICall(url, options)
      .then(function(response) {
        return response.data;
      })
      .catch(errorService.uiErrorHandler);
    }
    
    function getSupplierDetailByMonth(supplier_id, yearMonth) {
      var office = officeService.get();
      var office_id = _.get(office, 'id', null);
      if(!office_id){
        return $q.reject();
      }
      var url = '{0}/offices/{1}/reports/supplier/{2}/monthly?start_date={3}'.format(apiUrl, office_id, supplier_id, yearMonth);
      var options = {
        'method': 'GET'
      };
      
      return serverAPI
      .doAPICall(url, options)
      .then(function(response) {
        return response.data;
      })
      .catch(errorService.uiErrorHandler);
    }
    
    
    function getSpeedingCategoryReport(year) {
      var office = officeService.get();
      var office_id = _.get(office, 'id', null);
      if(!office_id){
        return $q.reject();
      }
      var url = '{0}/offices/{1}/reports/category?start_date={2}'.format(apiUrl, office_id, year);
      
      var options = {
        'method': 'GET'
      };
      
      return serverAPI
      .doAPICall(url, options)
      .then(function(response) {
        return response.data;
      })
      .catch(errorService.uiErrorHandler);
    }
    
    function getCategoryDetail(year, categoryID) {
      var office = officeService.get();
      var office_id = _.get(office, 'id', null);
      if(!office_id){
        return $q.reject();
      }
      var url = '{0}/offices/{1}/reports/category/{2}?start_date={3}'.format(apiUrl, office_id, categoryID, year);
      
      var options = {
        'method': 'GET'
      };
      
      return serverAPI
      .doAPICall(url, options)
      .then(function(response) {
        return response.data;
      })
      .catch(errorService.uiErrorHandler);
    }
    
    function getCategoryMonthDetail(yearMonth, categoryID) {
      var office = officeService.get();
      var office_id = _.get(office, 'id', null);
      if(!office_id){
        return $q.reject();
      }
      var url = '{0}/offices/{1}/reports/category/{2}/monthly?start_date={3}'.format(apiUrl, office_id, categoryID, yearMonth);
      
      var options = {
        'method': 'GET'
      };
      
      return serverAPI
      .doAPICall(url, options)
      .then(function(response) {
        return response.data;
      })
      .catch(errorService.uiErrorHandler);
    }
  }
}());

angular.module('app.marketplace.ui.contentBlock', [])
.config(function($stateProvider, $urlRouterProvider) {

  $stateProvider
    .state('app.shopping.content_block', {
      url: '/content_block/:blockId',
      templateUrl: 'templates/marketplace/content-block/content-block-page.html',
      controller: 'contentBlockPageController',
      resolve: {
        block: function(){}
      }
    })
  ;

});


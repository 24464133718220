(function () {
  'use strict';
  
  angular
  .module('sowReports')
  .controller('SpendingMonthDetailController', SpendingMonthDetailController);
  
  function SpendingMonthDetailController($scope, $window, $rootScope, $state, $filter, sowReportsService2, orderByFilter, AccessService) {
    var ctrl = this;
    ctrl.propertyName = 'product_name';
    ctrl.reverse = false;
    var t_spending = $filter('translate')('COMMON.SPENDING');
    var t_manufacturer = $filter('translate')('COMMON.MANUFACTURER');
    var t_product_name = $filter('translate')('COMMON.PRODUCT_NAME');
    
    ctrl.rangeOptions = [
      {
        name: moment().format('MMMM, YYYY'),
        value: moment().format('YYYY-M')
      }
    ];
    ctrl.currentChartShow = {
      year: '',
      labels: [],
      data: [],
      totalSpent: '',
      balance: {
        value: 0,
        color: "green"
      },
      products: [],
      totalBudget: '',
      month: ''
    };
    ctrl.searchType = 'product_name';
    ctrl.typeSearch = [
      {
        type: 'product_name',
        value: t_product_name
      },
      {
        type: 'manufacturer',
        value: t_manufacturer
      }
    ];
    ctrl.chartSetting = {
      options: {
        scales: {
          xAxes: [{
            stacked: true,
            ticks: {
              beginAtZero: true
            }
          }],
          yAxes: [{
            stacked: false,
            ticks: {
              beginAtZero: true
            }
          }]
        }
      },
      series: [t_spending],
      type: "StackedBar",
      colors: [
        {
          backgroundColor: '#33be8c',
          pointBackgroundColor: 'rgba(148,159,177,1)',
          pointHoverBackgroundColor: 'rgba(148,159,177,1)',
          borderColor: '#33be8c',
          pointBorderColor: '#fff',
          pointHoverBorderColor: 'rgba(148,159,177,0.8)'
        },
        {
          backgroundColor: '#dddddd',
          pointBackgroundColor: 'rgba(77,83,96,1)',
          pointHoverBackgroundColor: 'rgba(77,83,96,1)',
          borderColor: '#dddddd',
          pointBorderColor: '#fff',
          pointHoverBorderColor: 'rgba(77,83,96,0.8)'
        },
        {
          backgroundColor: '#fa9e4d',
          pointBackgroundColor: 'rgba(77,83,96,1)',
          pointHoverBackgroundColor: 'rgba(77,83,96,1)',
          borderColor: '#fa9e4d',
          pointBorderColor: '#fff',
          pointHoverBorderColor: 'rgba(77,83,96,0.8)'
        }
      ]
    };

    ctrl.changeDataChart = changeDataChart;
    ctrl.changeSearchType = changeSearchType;
    ctrl.getBalance = getBalance;
    ctrl.getDataChart = getDataChart;
    ctrl.getDataFormURL = getDataFormURL;
    ctrl.getLabel = getLabel;
    ctrl.getMonthDetailReport = getMonthDetailReport;
    ctrl.goBack = goBack;
    ctrl.goToBudgetManager = goToBudgetManager;
    ctrl.goToProductReport = goToProductReport;
    ctrl.openItemDetails = openItemDetails;
    ctrl.openPriceHistory = openPriceHistory;
    ctrl.openProductDetails = openProductDetails;
    ctrl.sort = sort;
    ctrl.sortBy = sortBy;
    ctrl.sumArray = sumArray;
    ctrl.updateCurrentYear = updateCurrentYear;
    ctrl.updateMonthYearOptions = updateMonthYearOptions;

    init();

    return ctrl;
    
    function init () {
      ctrl.currentMonthYear = ctrl.getDataFormURL();
      ctrl.updateMonthYearOptions();
      ctrl.getMonthDetailReport(ctrl.currentMonthYear);
      defineLocks();
    }
    
    function updateCurrentYear () {
      $window.localStorage.setItem('yearLocal', moment(ctrl.currentMonthYear,'YYYY-M').format('YYYY'));
      ctrl.currentChartShow = {
        year: '',
        labels: [],
        data: [],
        totalSpent: '',
        balance: {
          value: 0,
          color: "green"
        },
        products: [],
        totalBudget: '',
        month: ''
      };
      ctrl.getMonthDetailReport(ctrl.currentMonthYear);
    }
    
    function getDataFormURL () {
      var params = $state.params;
      var monthYearNumber = moment().format('YYYY') + '-' + moment().format('M');
      if (params.month && params.year) {
        monthYearNumber = params.year + '-' + moment(params.month, 'MMM').format('M');
      }
      return monthYearNumber;
    }
    
    function updateMonthYearOptions () {
      var first_date = moment($scope.$root.current_office.created_at);
      var last_date = moment();
      var month_count = last_date.diff(first_date, 'M');
      for (var i = 1; i <= month_count; i++) {
        ctrl.rangeOptions.push({
          name: moment().subtract(i, 'M').format('MMMM, YYYY'),
          value: moment().subtract(i, 'M').format('YYYY-M')
        });
      }
    }
    
    function getMonthDetailReport (yearMonth) {
      sowReportsService2.getSpeedingOfficeMonthDetail(yearMonth).then(function (data) {
        ctrl.chartData = data;
        ctrl.changeDataChart();
      });
    }
    
    function changeDataChart () {
      
      var report = ctrl.chartData[0].report;
      var spendingPerDay = report.spending_per_day;
      var products = report.product_details;
      var labels = ctrl.getLabel(ctrl.currentMonthYear);
      var datas = ctrl.getDataChart(labels, spendingPerDay);
      var balance = {"color": "black", "value":0.00};
      var budget = report.budgets.length > 0 ? report.budgets : null;
      
      ctrl.currentChartShow.labels = labels;
      
      if (datas.length > 0) {
        var totalSpent = datas.reduce(ctrl.sumArray, 0);
        if (budget !== null) {
          balance = ctrl.getBalance(totalSpent, budget[0].monthly_budget[0].budget);
          ctrl.currentChartShow.totalBudget = budget[0].monthly_budget[0].budget;
        }
        else {
          balance = ctrl.getBalance(totalSpent,budget);
          ctrl.currentChartShow.totalBudget = null;
        }
        ctrl.currentChartShow.data.push(datas);
        ctrl.currentChartShow.totalSpent = totalSpent;
      }
      else {
        ctrl.currentChartShow.totalBudget = budget ? budget[0].monthly_budget[0].budget : null;
      }
      ctrl.currentChartShow.balance = balance;
      ctrl.currentChartShow.month = moment(ctrl.currentMonthYear, 'YYYY-M').format('MMMM');
      ctrl.currentChartShow.year = moment(ctrl.currentMonthYear, 'YYYY-M').format('YYYY');
      ctrl.currentChartShow.products = ctrl.sort(products, 'product_name', ctrl.reverse);
    }
    
    function getLabel (monthYearNumber) {
      var countMonth = moment(monthYearNumber, "YYYY-M").daysInMonth();
      var month = moment(monthYearNumber, "YYYY-M").format('MMM');
      var daysList = [];
      for (var i = 1; i <= countMonth; i++) {
        daysList.push(month + ' ' + i);
      }
      return daysList;
    }
    
    function sumArray (a, b) {
      return a + b;
    }
    
    function getBalance (totalSpent, totalBudget) {
      var balance = {};
      if (totalSpent > totalBudget) {
        balance.value = totalSpent - totalBudget;
        balance.color = "red"
      } else if (totalSpent < totalBudget) {
        balance.value = totalBudget - totalSpent;
        balance.color = "green"
      } else {
        balance.value = totalBudget - totalSpent;
        balance.color = "black"
      }
      return balance;
    }
    
    function getDataChart (labelList, spendingPerDay) {
      var data = []
      
      if (spendingPerDay.length > 0) {
        for (var i = 1; i <= labelList.length; i++) {
          data.push(0);
        }
        for (var i = 1; i <= labelList.length; i++) {
          for (var j = 0; j < spendingPerDay.length; j++) {
            var item = spendingPerDay[j];
            if (item.date == i) {
              data[i - 1] = item.total_spent;
            }
          }
        }
      }
      return data;
    }
    
    function changeSearchType () {
      ctrl.searchBy = {};
      // $location.hash('search-by');
      // $anchorScroll();
    }
    
    function goBack () {
      $state.go('app.reports.spending.office');
    }
    
    function sortBy (propertyName) {
      ctrl.reverse = (ctrl.propertyName === propertyName) ? !ctrl.reverse : false;
      ctrl.propertyName = propertyName;
      var allProduct = ctrl.currentChartShow.products;
      if (allProduct.length > 0) {
        ctrl.currentChartShow.products = orderByFilter(allProduct, ctrl.propertyName, ctrl.reverse);
      }
    }

    function sort (array, sortByName, sortOrder) {
      var newArray = [];
      if (array.length > 0) {
        newArray = orderByFilter(array, sortByName, sortOrder);
      }
      return newArray;
    }
    
    function goToProductReport (item){
      var type = _.get(item, 'item_type', '');
      if(type.includes('Implant')){
        $state.go('app.reports.implants-placement');
      } else {
        $state.go('app.reports.product-detail', {productId: item.inventory_id});
      }
    }
    
    function goToBudgetManager (){
      $state.go('app.budget-detail', {year: $state.params.year});
    }
    
    function openPriceHistory (item) {
      var type = _.get(item, 'item_type', '');
      if(!item.inventory_id || type.includes('Implant')){
        return;
      }
      if ( type.includes('Medication') ) {
        $rootScope.$broadcast('medication-item-slideout', null, null, 'price', item.inventory_id);
      } else {
        $rootScope.$broadcast('inventory-item-slideout', null, item.inventory_id, 'price');
      }
    }
    
    function openItemDetails (item) {
      var type = _.get(item, 'item_type', '');
      if(!item.inventory_id || type.includes('Implant')){
        return;
      }
      if ( type.includes('Medication') ) {
        $rootScope.$broadcast('medication-item-slideout', null, null, 'details', item.inventory_id);
      } else if (!type.includes('Implant')) {
        $rootScope.$broadcast('inventory-item-slideout', null, item.inventory_id, 'details');
      }
    }
    
    function openProductDetails (item) {
      $rootScope.$broadcast('mkt-details-open-fetch', item.product_id);
    }

    function defineLocks () {
      ctrl.hideCartButton = !AccessService.hasCartAccess();
    }
  }
}());

(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.inventory')
    .controller('InventoryPrintAllCtrl', InventoryPrintAllCtrl);

  function InventoryPrintAllCtrl ($scope, $rootScope, $filter) {
    /*jshint validthis: true */
    var ctrl = this;
    var $printCtrl = $scope.$printCtrl;

    init();

    return ctrl;

    function init () {
      $printCtrl.init();
      var t_title = $filter('translate')('INVENTORY.PRINT_TITLE');
      $printCtrl.title = '{0} {1}'.format(t_title, $rootScope.current_office.name);
      $scope.getItems();
      $printCtrl.items = _.chain($scope.items)
                           .filter(function (i) {
                             return i.type === 'General Item' || i.type === 'Marketplace Item';
                           })
                           .sortBy('name')
                           .value();
      $printCtrl.print();
    }
  }
}());

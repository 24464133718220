(function () {
  'use strict';

  angular
    .module('sowOfficeUsers')
    .filter('sowActiveOfficeUsers', sowActiveOfficeUsersFilter)
  ;

  function sowActiveOfficeUsersFilter ($log) {
    return function (input) {
      return _.filter(input, function (officeUser) {
        // $log.debug('DEBUG: sowActiveOfficeUsersFilter: officeUser = %o', officeUser);
        return officeUser.is_active;
      });
    };
  }

}());

(function(){
  'use strict';
  angular.module('app.marketplace.ui.office')
  .controller('officeRepsController', officeRepsController);

  function officeRepsController (SupplierService, $mdDialog, $filter, sgToast, $scope, membershipService, $q) {
    /*jshint validthis: true*/
    var ctrl = this;

    ctrl.addRep = addRep;
    ctrl.editRep = editRep;
    ctrl.removeRep = removeRep;
    ctrl.repSorting = repSorting;

    ctrl.isLoading = false;

    $scope.$on('office-ab-add-rep', ctrl.addRep);

    init();

    return ctrl;

    function init () {
      loadReps();
      defineLocks();
    }

    function loadReps () {
      ctrl.isLoading = true;

      // SPD-2245
      // we used to get the inventory items and then 
      // filter the suppliers for which we have items for
      // this caused a slow-down in offices with huge inventories
      // so it was removed
      $q.all([
        SupplierService.getAll()
        .then(function(suppliers_response){
          ctrl.suppliers = _.sortBy(suppliers_response, ["name"]);
        }),
        SupplierService.getSalesReps()
        .then(function(reps_response){
          ctrl.sales_reps = reps_response;
        })
      ])
      .then(function(){
        ctrl.isLoading = false;
      });
    }


    function fillInSuppliers (suppliers_list) {
      _.forEach(suppliers_list, fillDummy);
      
      function fillDummy (supplier) {
        var existing_rep = _.find(ctrl.sales_reps, function(rep){ return rep.supplier && rep.supplier.id === supplier.id && rep.id; });
        var supplierFiller = _.find(ctrl.sales_reps, function(rep){ return rep.supplier && rep.supplier.id === supplier.id && !rep.id; });

        if (!existing_rep && !supplierFiller) {
          ctrl.sales_reps.push({
            supplier: supplier,
          });
        }

        if (supplierFiller && existing_rep) {
          _.remove(ctrl.sales_reps, supplierFiller);
        }
      }
    }

    function editRep (sales_rep) {
      sales_rep.supplier = sales_rep.supplier ? _.find(ctrl.suppliers, ['id', sales_rep.supplier.id]) : null;
      $mdDialog.show({
          controller: 'officeRepModalController',
          controllerAs: 'dialogCtrl',
          templateUrl: 'templates/marketplace/office/modals/office-rep.html',
          parent: angular.element(document.body),
          clickOutsideToClose:true,
          locals : {
            modalType : "edit",
            sales_rep : angular.copy(sales_rep),
            suppliers_list: ctrl.suppliers,
          },
          bindToController: true,
        }).then(function(rep_data){
          _.extend(sales_rep, rep_data);
          saveRep(rep_data)
          .then(function(response){
            fixPrimary(response);
            _.extend(sales_rep, response);
            var t_message = $filter('translate')('TOAST.REP_DETAILS_SAVED');
            sgToast.showSimple(t_message);
          });
        });
    }

    function addRep (supplier) {
      var sales_rep = {
        can_access_inventory: false,
      };
      if(_.get(supplier, "id")) {
        // sales_rep.supplier = supplier;
        sales_rep.supplier = _.find(ctrl.suppliers, ['id', supplier.id]);
      }
      $mdDialog.show({
          controller: 'officeRepModalController',
          controllerAs: 'dialogCtrl',
          templateUrl: 'templates/marketplace/office/modals/office-rep.html',
          parent: angular.element(document.body),
          clickOutsideToClose:true,
          locals : {
            modalType : "new",
            sales_rep : sales_rep,
            suppliers_list: ctrl.suppliers,
          },
          bindToController: true,
        }).then(function(rep_data){
          if(rep_data.name || rep_data.email || rep_data.phone_number){
            var existing_primary = _.find(ctrl.sales_reps, function(rep){
              return rep.id && rep.is_primary && rep.supplier && rep.supplier.id === rep_data.supplier.id;
            });
            if (!existing_primary) {
              rep_data.is_primary = true;
            }
            saveRep(rep_data)
            .then(function(response){
              addLocalRep(response);
              var t_message = $filter('translate')('TOAST.REP_DETAILS_SAVED');
              sgToast.showSimple(t_message);
            });
          }
        });
    }

    function removeRep (sales_rep) {
      var was_primary = sales_rep.is_primary;

      SupplierService.removeSalesRep(sales_rep)
      .then(function(response){
        var t_message = $filter('translate')('OFFICE.REP.REMOVED');
        sgToast.showSimple(t_message);
        _.remove(ctrl.sales_reps, sales_rep);
        fillInSuppliers([sales_rep.supplier]);
        if(was_primary){
          replacePrimary(sales_rep.supplier);
        }

      });
    }

    function replacePrimary (supplier) {
      var secondary_rep = _.find(ctrl.sales_reps, function(rep){
        return rep.id && rep.supplier && rep.supplier.id === supplier.id && !rep.is_primary;
      });
      if (secondary_rep) {
        _.set(secondary_rep, 'is_primary', true);

      }
    }

    function fixPrimary (sales_rep) {
      if (sales_rep.is_primary) {
        var existing_primary = _.find(ctrl.sales_reps, function(rep){
          return rep.supplier && rep.supplier.id === sales_rep.supplier.id && rep.is_primary;
        });
        if (existing_primary) {
          _.set(existing_primary, 'is_primary', false);
        }
      }
    }

    function repSorting (item) {
      return _.get(item, "supplier.name", "");
    }

    function saveRep (sales_rep) {
      return SupplierService.createOrUpdateSalesRep(sales_rep);
    }

    function addLocalRep (sales_rep) {
      var supplier_filler = _.find(ctrl.sales_reps, function(rep){ return rep.supplier && rep.supplier.id === sales_rep.supplier.id && !rep.id; });
      if(supplier_filler) {
        _.remove(ctrl.sales_reps, supplier_filler);
      }
      if(sales_rep.is_primary){
        var primary_rep = _.find(ctrl.sales_reps, function(rep){ return rep.supplier && rep.supplier.id === sales_rep.supplier.id && rep.is_primary; });
        if (primary_rep) {
          _.set(primary_rep, 'is_primary', false);
        }
      }

      ctrl.sales_reps.push(sales_rep);
    }

    function defineLocks () {
      const membership = membershipService.get();

      const supplier_edit_condition = _.get(membership, "user_properties.suppliers.disable_edit", false);
      ctrl.disabled_supplier_edit = supplier_edit_condition;

      const add_condition = _.get(membership, "user_properties.sales_reps.disable_add_new", false);
      ctrl.disabled_sales_rep_add_new = add_condition;
      const edit_condition = _.get(membership, "user_properties.sales_reps.disable_edit", false);
      ctrl.disabled_sales_rep_edit = edit_condition;
      const delete_condition = _.get(membership, "user_properties.sales_reps.disable_delete", false);
      ctrl.disabled_sales_rep_delete = delete_condition;
    }

  }

}());

(function () {
  'use strict';

  angular.module('sowSterilization')
    .directive('sowStznTestFormSpore', stznTestFormSporeDirective);

  function stznTestFormSporeDirective() {
    return {
      restrict: 'E',
      templateUrl: 'sow-sterilization/directives/stzn-spore-test-form.html'
    };
  }

})();

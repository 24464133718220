angular.module('app.marketplace.ui.inventory')
  .directive('inventoryCreateProductConfirmation', function () {
    return {
      restrict: 'E',
      controllerAs: 'ctrl',
      bindToController: true,
      templateUrl: 'templates/marketplace/inventory/directives/inventory-create-product-confirmation.html',
      controller: function ($mdDialog, membershipService) {
        var ctrl = this;

        ctrl.close = close;
        ctrl.dismiss = dismiss;

        init();

        return ctrl;

        function init () {
          defineLocks();
        }

        function close() {
          $mdDialog.hide();
        }
        function dismiss() {
          $mdDialog.cancel();
        }
        function defineLocks () {
          const membership = membershipService.get();
      
          const add_new_lock_condition = _.get(membership, "user_properties.gen_inv.disable_add_new", false);
          ctrl.disabled_add_new = add_new_lock_condition;
        }
      }
    };
  });

//Directive for an input field that is a drop down for values less than 10.
//Value cant be letters or negative
angular.module("app.shared.support")
.directive('safeIntegerInput',function() {
return {
  restrict: 'E',
  replace: true,
  templateUrl: 'templates/shared/support/directives/safeIntegerInput.html',
  scope: {
    containerClass : '=',
    layout : '@',
    label : '=',
    ngModel : '=',
    name : '@',
    required : '=',
    ngRequired : '=',
    ngMinlength : '=',
    ngMaxlength : '=',
    ngPattern : '=',
    ngChange : '@',
    ngTrim : '=',
    ngFocus : '=',
    ngDisabled : '=',
    values : '@'
  },
  controller: function($scope, $timeout){
    $scope.layout = $scope.layout || 'column';
    $scope.ngModel = $scope.ngModel || 0;
    $scope.inputValue = $scope.ngModel;
    $scope.showInput = $scope.ngModel > 10;
    $scope.values = $scope.values || [0,1,2,3,4,5,6,7,8,9];
    
    $scope.update = function(newValue){
      if(_.isNil(newValue)){
        return;
      }
      $scope.ngModel = parseInt(newValue);
      $scope.showInput = $scope.ngModel > 10;
      if($scope.ngChange){
        //Timeout needed for ngModel linking to digest new value.
        $timeout(function(){
          $scope.$parent.$eval($scope.ngChange);
        });
      }
    };
    $scope.valueSelected = function(selectValue){
      if(parseInt(selectValue)===10){
        $scope.inputValue = $scope.ngModel;
        $scope.showInput = true;
        $scope.focusInput = false;
        $timeout(function(){
          $scope.focusInput = true;
        });
      }else{
        $scope.update(selectValue);
      }
    };
    
//    $scope.$watch('showInput', function(newVal, oldVal){
//      $scope.focusInput = newVal;
//    });
  }
};
});

(function () {
  'use strict';

  angular.module("app")
    .directive('ngModel', ngModelDirectiveOverride);

  // From: https://mark.zealey.org/2015/01/08/formatting-time-inputs-nicely-with-angularjs
  function ngModelDirectiveOverride() {
    return {
      require: '?ngModel',
      link: function (attr, ngModel) {
        if (!ngModel) {
          return;
        }
        if (attr.type !== 'time') {
          return;
        }

        ngModel.$formatters.unshift(function (value) {
          return value.replace(/[\.\,]\d{3}$/, '');
        });
      }
    };
  }

}());

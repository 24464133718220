angular.module("app.marketplace.elements")
.service("externalOrderItemModel", function(apiUrl, commonApiService) {
  var _this = this;
  this.model_data = {
    "name" : "externalOrderItem",
    "display_name" : "Purchase Order Item",
    "api" : {
      "single" : function(options){
      },
      "multiple" : function(options){
        if(!checkNested(options,'external_order','id')){
          throw "Missing option(s) for Purchase order item model path 'multiple'. Options: " + JSON.stringify(options);
        }
        return commonApiService.getOfficeUrl() + "/external_orders/" + options.external_order.id + "/items";
      }

    }

  };

  this.autofill = function(item) {
    if(item.inventory_item){
      item.images = item.inventory_item.image;
      item.manufacturer = {
          id : item.inventory_item.manufacturer_id,
          name : item.inventory_item.manufacturer_name
      };
    }
    //Clear edit state if saved
    if(item.id && item.UI && item.UI.edit){
      item.UI.edit = false;
    }
  };

  this.fields = {
    "id" : {
      "display_name" : "id",
      "required" : true,
      "api" : {
        "submit" : true
      },
      "type" : "integer"
    },
    "manufacturer" : {
      "display_name" : "id",
      "required" : true,
      "api" : {
        "submit" : true
      },
      "type" : "object"
    },
    "sku" : {
      "display_name" : "sku",
      "required" : true,
      "api" : {
        "submit" : true
      },
      "type" : "string"
    }
  };

  this.data = {
    "params" : {
      "model_name" : "externalOrderItem"
    }
  };

});

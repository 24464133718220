(function () {
  'use strict';

  var modelData = {
    "params": {
      "model_name": "purchaseOrder"
    }
  };

  var modelFields = {
    "id" : {
      "display_name" : "id",
      "required" : true,
      "api" : {
        "submit" : true
      },
      "type" : "integer"
    },
  };

  angular
    .module('app.marketplace.elements')
    .service('purchaseOrderModel', PurchaseOrderModel);

  function PurchaseOrderModel (apiUrl) {
    /*jshint validthis: true */
    var model = this;

    model.data = modelData;
    model.fields = modelFields;
    model.autofill = autofill;
    model.model_data = {
      'name': 'purchaseOrder',
      'display_name': 'Purchase Order',
      'api': {
        'single': getPurchaseOrder,
        'multiple': purchaseOrderList,
        'multiple_page': purchaseOrderListPaginate,
        'new_po': newPurchaseOrder,
        'remove': deletePurchaseOrder,
        'count': purchaseOrderCount,
        'hrid': getHRID,
        'save_draft': saveDraftPurchaseOrder,
        'make_active': makePurchaseOrderActive,
        'cancel_items': cancelItems,
        'receive_items': receiveItems,
        'filter_months': getFilterMonths,
        'change_date': changeDate,
        'undo_last_action': undoLastAction,
        'edit_active': editActive,
        'email_purchase_order': emailPurchaseOrder,
        'delete_po_item': deletePurchaseOrderItem,
        'print_po_list': printPOList,
        'print_po': printPO,
        'fetch_events': fetchEvents,
        'get_page_by_query': getPageByQuery,
      }
    };

    return model;

    function autofill (purchase_order) {
      purchase_order.total_units = _.reduce(purchase_order.items, function (previous, current) {
        return previous + (current.quantity || 0) - (current.extra_quantity || 0);
      }, 0);

      // purchase_order.UI = purchase_order.UI || {};
      purchase_order.item_count = _.get(purchase_order, 'items.length', 0);
      purchase_order.sort_date = _.get(purchase_order, 'updated_at', null);
      purchase_order.month = _.get(purchase_order,'po_date','').substr(0, 7);
    }

    function printPO (options) {
      if (!_.has(options, 'purchase_order_id')) {
        throw 'Missing purchase_order_id. Cannot fetch PDF.';
      }

      return {
        'path': '{0}/purchase_orders/{1}/print'.format(apiUrl, options.purchase_order_id),
        'params': {
          'method': 'GET',
          'responseType': 'arraybuffer',
          'headers': {'Accept': 'application/pdf'},
        }
      };
    }

    function printPOList (options) {
      return {
        'path': '{0}/purchase_orders/print_list'.format(apiUrl),
        'params': {
          'method': 'POST',
          'responseType': 'arraybuffer',
          'headers': {
            'Accept': 'application/pdf',
          },
          'data': {
            'office_id': _.get(options, 'office_id'),
            'po_month': _.get(options, 'po_month'),
            'created_by_email': _.get(options, 'created_by'),
            'created_by_id': _.get(options, 'created_by.id'),
            'status': _.get(options, 'status'),
            'order_id': _.get(options, 'order_id'),
            'supplier': _.get(options, 'supplier'),
          }
        }
      };
    }

    function deletePurchaseOrderItem (options) {
      if (!_.has(options, 'purchase_order_item_id')) {
        throw 'Missing option(s) for deletePurchaseOrderItem';
      }

      return {
        'path': '{0}/purchase_orders/delete_purchase_order_item'.format(apiUrl),
        'params': {
          'method': 'POST',
          'data': {'purchase_order_item_id': options.purchase_order_item_id},
        }
      };
    }

    //
    // Send an email copy of the purchase order to a recipient.
    //
    function emailPurchaseOrder (options) {
      if (!_.has(options, 'payload')) {
        throw 'Missing option(s) for emailPurchaseOrder';
      }

      return {
        'path': '{0}/purchase_orders/email'.format(apiUrl),
        'params': {
          'method': 'POST',
          'data': options.payload,
        }
      };
    }

    function editActive (options) {
      if (!_.has(options, 'payload')) {
        throw 'Missing option(s) for editActive';
      }

      return {
        'path': '{0}/purchase_orders/edit_active'.format(apiUrl),
        'params': {
          'method': 'POST',
          'data': options.payload,
        }
      };
    }

    function undoLastAction (options) {
      if (!_.has(options, 'purchase_order_item_id')) {
        throw 'Missing option(s) for undoLastAction';
      }

      return {
        'path': '{0}/purchase_orders/undo_last_action'.format(apiUrl),
        'params': {
          'method': 'POST',
          'data': {
            'purchase_order_item_id': options.purchase_order_item_id,
          }
        }
      };
    }

    function changeDate (options) {
      if  (!_.has(options, 'date') || !_.has(options, 'purchase_order_item_id')) {
        throw 'Missing option(s) for changeDate';
      }

      return {
        'path': '{0}/purchase_orders/edit_last_action_date'.format(apiUrl),
        'params': {
          'method': 'POST',
          'data': {
            'purchase_order_item_id': options.purchase_order_item_id,
            'date': options.date,
          }
        }
      };
    }

    function getFilterMonths (options) {
      if (!_.has(options, 'office_id')) {
        throw 'Cannot fetch months without an office_id.';
      }

      return {
        'path': '{0}/purchase_orders/dates'.format(apiUrl),
        'params': {
          'method': 'GET',
          'params': {
            'office_id': options.office_id
          },
        },
      };
    }

    function getPurchaseOrder (options) {
      if (!_.has(options, 'data.id')) {
        throw 'Cannot fetch purchase order without an id.';
      }

      return {
        'path': '{0}/purchase_orders/{1}'.format(apiUrl, options.data.id),
        'params': {'method': 'GET'},
      };
    }

    function purchaseOrderCount (options) {
      return {
        'path': '{0}/purchase_orders/count'.format(apiUrl),
        'params': {
          'method': 'GET',
        }
      };
    }

    function getHRID () {
      return {
        'path': '{0}/purchase_orders/hrid'.format(apiUrl),
        'params': {
          'method': 'GET',
        }
      };
    }

    function purchaseOrderList (options) {
      var get_params = options.filters || {};

      return {
        'path': '{0}/purchase_orders'.format(apiUrl),
        'params': {
          'method': 'GET',
          'params': get_params,
        }
      };
    }

    function purchaseOrderListPaginate (options) {
      var get_params = options.filters || {};

      return {
        'path': '{0}/purchase_orders_paginate'.format(apiUrl),
        'params': {
          'method': 'GET',
          'params': get_params,
        }
      };
    }

    function newPurchaseOrder (options) {
      if (!_.has(options, 'purchaseOrder')) {
        throw 'Missing new purchase order data.';
      }

      return {
        'path': '{0}/purchase_orders'.format(apiUrl),
        'params': {
          'method': 'POST',
          'data': options.purchaseOrder,
        }
      };
    }

    function receiveItems (options) {
      return {
        'path': '{0}/purchase_orders/receive_items'.format(apiUrl),
        'params': {
          'method': 'POST',
          'data': options.payload,
        },
      };
    }

    function cancelItems (options) {
      return {
        'path': '{0}/purchase_orders/cancel_items'.format(apiUrl),
        'params': {
          'method': 'POST',
          'data': options.payload,
        },
      };
    }

    function makePurchaseOrderActive (options) {
      if (!_.has(options, 'purchase_order_id')) {
        throw 'Missing required field: purchase_order_id.';
      }

      return {
        'path': '{0}/purchase_orders/make_active'.format(apiUrl),
        'params': {
          'method': 'POST',
          'data': {'purchase_order_id': options.purchase_order_id},
        }
      };
    }

    function saveDraftPurchaseOrder (options) {
      if (!_.has(options, 'data')) {
        throw 'Missing purchase order data. Cannot complete request.';
      }

      return {
        'path': '{0}/purchase_orders/save_draft'.format(apiUrl),
        'params': {
          'method': 'POST',
          'data': options.data,
          'params': options.params,
        }
      };
    }

    function deletePurchaseOrder (po) {
      if (!_.has(po, 'id')) {
        throw 'Cannot delete purchaseOrder with no id.';
      }

      var revert = !!_.get(po, 'UI.revertInventory');

      return {
        'path': '{0}/purchase_orders/{1}'.format(apiUrl, po.id),
        'params': {
          'method': 'DELETE',
          'params': {
            'revert_received_quantities': revert,
          },
        }
      };
    }

    /** 
     * Fetches events associated with a particular purchase order. 
     * 
     * @param {Object} options 
     * 
     * @return {Object} 
    */
    function fetchEvents(options) {
      const { office_id, po_id } = options.data;
      return {
        method: 'GET',
        path: `${apiUrl}/purchase_orders/${office_id}/events/${po_id}`,
      }
    }

    function getPageByQuery(options) {
      return {
        method: 'GET',
        path: `${apiUrl}/purchase_orders/search`,
        params: { params: options.filters },
      }
    }
  }

}());

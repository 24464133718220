(function () {
  'use strict';

  angular
    .module('sowSubscriptionPricing')
    .directive('sowSubscriptionPlanFeatures', sowSubscriptionPlanFeatures);

  var featuresMap = {
    'enterprise': [
      { 'text':'SIGNUP.FEATURES.SUPPORT_SPECIALIST', },
      { 'text':'SIGNUP.FEATURES.TRAINING', },
      { 'text':'SIGNUP.FEATURES.MKT', },
      { 'text':'SIGNUP.FEATURES.PERSONALIZED', },
      { 'text':'SIGNUP.FEATURES.SUPPORT_ENTERPRISE', },
    ],
    'premium': [
      { 'text':'SIGNUP.FEATURES.INVENTORY_MEDS', },
      { 'text':'SIGNUP.FEATURES.ALERTS', },
      { 'text':'SIGNUP.FEATURES.SALES', },
      { 'text':'SIGNUP.FEATURES.SPENDING_REPORTS', },
      { 'text':'SIGNUP.FEATURES.STERILIZATION', },
    ],
    'premium-plus': [
      { 'text':'SIGNUP.FEATURES.PLACEMENT_REPORTS', },
      { 'text':'SIGNUP.FEATURES.MONITOR_REPORTS', },
      { 'text':'SIGNUP.FEATURES.REFERRAL', },
      { 'text':'SIGNUP.FEATURES.SUPPORT_PRIORITY', },
    ],
    'free': [
      { 'text': 'SIGNUP.FEATURES.MKT_EXCLUSIVE' },
      { 'text': 'SIGNUP.FEATURES.PRICING' },
      { 'text': 'SIGNUP.FEATURES.UNLIMITED_ACC' },
    ]


  };

  var classes = {
    'premium': ['premium-plan'],
    'premium-plus': ['premium-plus-plan'],
  };

  function sowSubscriptionPlanFeatures ($log) {
    return {
      'restrict': 'E',
      'replace': true,
      'templateUrl': 'sow-subscription-pricing/sow-subscription-plan-features.html',
      'scope': {
        'plan': '@',
        'heading': '@',
        'subtitle': '@',
        'overrideFeaturesMap': '=',
      },
      'link': function ($scope) {
        $scope.classes = [];
        init();
        return;

        function init () {
          updateClasses();
          updateFeatures();
          $scope.$watch('plan', updateFeatures);
          $scope.$watch('plan', updateClasses);
          $scope.$watch('overrideFeaturesMap', updateFeatures);
        }

        function updateClasses () {
          $scope.classes = classes[$scope.plan];
        }

        function updateFeatures () {
          var map = $scope.overrideFeaturesMap || featuresMap;
          $scope.features = map[$scope.plan];
        }
      }
    };
  }

}());

(function () {
  'use strict';

  angular
    .module('sowCertifications')
    .controller('certificationsDetailController', certDetailController);

  function certDetailController($scope, $state, $stateParams, $mdDialog, $mdToast, $timeout, $filter, sowCertificationService, certificationDataCarrierService, formService){
    /*jshint validthis: true */
    var ctrl = this;
    ctrl.records = [];

    init();

    ctrl.reminder = {
      "Biennial": 24,
      "Annual": 12,
      "Semi-Annual": 6,
      "Monthly": 1,
      "Tri-Monthly": 3,
      "Triennial": 36,
      "Quadrennial": 48
    };

    var t_company = $filter('translate')('CERTIFICATIONS.COMPANY_NAME');
    var t_cert = $filter('translate')('CERTIFICATIONS.CERTIFYING_BODY');
    ctrl.placeholder_by_category = {
      "Equipment": t_company,
      "Permit": t_cert,
      "License": t_cert
    };

    ctrl.create_or_update_certification = create_or_update_certification;
    ctrl.create_certification = create_certification;
    ctrl.update_certification = update_certification;
    ctrl.delete_certification = delete_certification;

    ctrl.create_or_update_record = create_or_update_record;

    ctrl.open_edit_record_modal = open_edit_record_modal;
    ctrl.open_create_record_modal = open_create_record_modal;
    ctrl.create_record = create_record;
    ctrl.update_record = update_record;
    ctrl.delete_record = delete_record;

    ctrl.open_calendar = open_calendar;

    ctrl.TODAY = new Date();
    ctrl.DATE_LIMIT = moment(ctrl.TODAY).add(12, 'months').toDate();
    ctrl.THREE_MONTHS_AGO = moment(ctrl.TODAY).subtract(3, 'months').toDate();
    ctrl.ten_years_ago = moment().subtract(10, 'years').toDate();

    ctrl.hide = hide;
    ctrl.cancel = cancel;
    ctrl.certificationDataCarrierService = certificationDataCarrierService;
    ctrl.initial_state = false;



    return ctrl;

    function init () {
      $scope.$on('crt-new-record', open_create_record_modal);

      if($stateParams.id){

        sowCertificationService.getCertification($stateParams.id)
          .then(function(certification){
            ctrl.certification = parse_certification(certification);


            sowCertificationService.getCertificationLogs({certification_id: ctrl.certification.id})
              .then(function(records){
                ctrl.certificationDataCarrierService.is_loading = false;
                watch(true);
                ctrl.records = parse_records(records);
              });
          });
      } else {
        ctrl.certification = { 'certification_date': ctrl.TODAY };
      }
    }

    function watch(first_time){

      $scope.$watch('updateCertificationForm.$valid', function(newFormValidValue, oldVal){
        ctrl.valid_state = true;
        if(first_time){
          ctrl.initial_state = newFormValidValue;
          first_time = false;
        }

        if(!ctrl.initial_state){
          if(!newFormValidValue && !$scope.updateCertificationForm){
            ctrl.valid_state = true;
          } else{
            ctrl.valid_state = newFormValidValue && $scope.updateCertificationForm.$submitted;
          }
        } else {
            ctrl.valid_state = newFormValidValue;
        }
        ctrl.certificationDataCarrierService.allow_records = ctrl.valid_state;

      });
    }

    function reset_form(){
      if (ctrl.certCertificationForm) {
        ctrl.certCertificationForm.$setPristine();
        ctrl.certCertificationForm.$setUntouched();
      }
    }

    function parse_certification(certification){
      var today = moment().startOf('day');
      var expiry = moment(certification.last_certified_on).add(ctrl.reminder[certification.renewal_type], 'M');
      var diff = expiry.diff(today, 'days');

      if ( diff <= 30 ){
        certification.alert = true;
        certification.diff = diff;
        certification.time_left = moment(certification.last_certified_on).add(ctrl.reminder[certification.renewal_type], 'M').toNow(true);
        certification.last_certified_on_date = moment(certification.last_certified_on).toDate();
      }

      certification.expiry_date = moment(certification.last_certified_on).add(ctrl.reminder[certification.renewal_type], 'M').toDate();

      return certification;
    }

    function parse_records(records){
      _.forEach(records, function(record){
        record.certification_date = moment(record.certification_date).toDate();
        record.document_names = [];
        _.forEach(record.document_path, function(url, index){
          var filename = _.last(_.split(url, '/'));
          record.document_names[index] = filename;
        });
      });
      return records;
    }

    function create_or_update_certification () {
      return sowCertificationService.createOrUpdateCertification(ctrl.certification);
    }

    function hide () {
      $mdDialog.hide();
    }

    function cancel () {
      $mdDialog.cancel();
    }

    function delete_certification(){
      $mdDialog.hide();
      sowCertificationService.deleteCertification({id: $stateParams.id})
        .then(function(){
          $state.go('app.certifications.list');
        });
    }

    function update_certification (certification_obj, form) {
      form.$setSubmitted();
      $mdDialog.hide();
      ctrl.create_or_update_certification()
        .then(function(certification){

          ctrl.certification = parse_certification(certification);
          // alertsDataCarrierService.load_alerts();
          watch();
          var text = $filter('translate')('COMMON.CHANGES_SAVED');
          $mdToast.show(
            $mdToast.simple()
              .textContent((text))
              .position("bottom right")
              .hideDelay(2000)
          );

        });
    }


    function create_certification () {
      if(!formService.checkRequiredFields(ctrl.certCertificationForm)){
         return 0;
      }
      $mdDialog.hide();
      ctrl.create_or_update_certification()
        .then(function(certification){

          ctrl.record = {};
          ctrl.record.certification_id = certification.id;
          ctrl.record.certification_date = ctrl.certification.certification_date ? moment(ctrl.certification.certification_date) : moment();
          ctrl.certification = certification;
          ctrl.create_or_update_record(ctrl.record)
            .then(function(){
              $state.go('app.certifications.list', {},{reload: true}).then(function () {
                var text = $filter('translate')('CERTIFICATIONS.NEW_CERT_ADDED');
                $mdToast.show(
                  $mdToast.simple()
                    .textContent((text))
                    .position("bottom right")
                    .hideDelay(2000)
                );
              });
          });
        });
    }

    function create_or_update_record () {
      ctrl.record.certification_id = ctrl.certification.id;
      return sowCertificationService.createOrUpdateCertificationLog(ctrl.record);
    }

    function create_record () {
      $mdDialog.hide();
      // ctrl.record.certification_date = moment(ctrl.certification.certification_date);

      ctrl.create_or_update_record()
        .then(function(record){
          $state.go('app.certifications.detail',{id:record.certification_id},{reload: true})
            .then(function () {
              var text = $filter('translate')('CERTIFICATIONS.RECORD_ADDED');
                $mdToast.show(
                  $mdToast.simple()
                    .textContent((text))
                    .position("bottom right")
                    .hideDelay(2000)
                );
              });
        });
    }

    function update_record (record) {
      $mdDialog.hide();
      ctrl.record = record;
      // ctrl.record.certification_date = moment(record.certification_date).add(ctrl.reminder[ctrl.certification.renewal_type], 'M');
      ctrl.create_or_update_record()
        .then(function(record_response){
          // ctrl.records.push(record_response);
          $state.go('app.certifications.detail',{id:record_response.certification_id},{reload: true})
            .then(function(){
              var text = $filter('translate')('COMMON.CHANGES_SAVED');
              $mdToast.show(
                $mdToast.simple()
                  .textContent((text))
                  .position("bottom right")
                  .hideDelay(2000)
              );
            });

        });
    }

    function open_edit_record_modal (record) {
      $mdDialog.show({
        controller: 'certificationRecordController',
        controllerAs: 'certrCtrl',
        templateUrl: 'sow-certifications/modals/cert-edit-record.html',
        parent: angular.element(document.body),
        clickOutsideToClose: true,
        locals: {
          certification: angular.copy(ctrl.certification),
          record: angular.copy(record),
          valid_state: ctrl.certificationDataCarrierService.allow_records
        },
        bindToController: true,
      })
      .then(function(answer){
        if(answer){
          update_record(answer);
        }
      });
    }

    function open_create_record_modal(){
      reset_form();
      $mdDialog.show({
        controller: 'certificationRecordController',
        controllerAs: 'certrCtrl',
        templateUrl: 'sow-certifications/modals/cert-edit-record.html',
        parent: angular.element(document.body),
        clickOutsideToClose: true,
        locals: {
          certification: angular.copy(ctrl.certification),
          record: {certification_date: new Date()}
        },
        bindToController: true,
      })
      .then(function(answer){
        if(answer){
          ctrl.record = answer;
          create_record();
        }
      });
    }

    function delete_record (id) {

      sowCertificationService.deleteCertificationLog({id: id})
        .then(function(){

          $state.go('app.certifications.detail',{id:ctrl.certification.id},{reload: true})
            .then(function(){
              var text = $filter('translate')('CERTIFICATIONS.RECORD_DELETED');
              $mdToast.show(
                $mdToast.simple()
                  .textContent((text))
                  .position("bottom right")
                  .hideDelay(2000)
              );
            });
        });
    }

    function open_calendar($event){

      $event.stopPropagation();
      $timeout(function() {
        var thisElem = $($event.currentTarget)[0];
        var datepicker = $(thisElem).siblings('md-datepicker')[0];
        $(datepicker)[0].children[0].click();
      }, 300);

    }


  }


}());

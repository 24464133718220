(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.purchaseorders')
    .controller('poSectionController', poSectionController);

  function poSectionController ($scope, $state, $stateParams, PurchaseOrderService) {
    /*jshint validthis: true */
    var ctrl = this;

    ctrl.FEATURE_DATA = {
      'section': {
        'name': 'Purchase Orders',
        'icon': {'material': "assignment", 'css': 'tea' },
        
      },
      'plan': {
        'label': 'PAYWALL.PREMIUM_FEATURE',
        'css': 'premium',
      },
      'selling_points': [
        {
          'header': 'PO.PW_HEADER_ONE',
          'text':'PO.PW_TEXT_ONE',
          'img': "styles/img/paywalls/1-new"
        },
        {
          'header': 'PO.PW_HEADER_TWO',
          'text':'PO.PW_TEXT_TWO',
          'img': "styles/img/paywalls/2-email"
        },
        {
          'header': 'PO.PW_HEADER_THREE',
          'text':'PO.PW_TEXT_THREE',
          'img': "styles/img/paywalls/3-check"
        },
        {
          'header': 'PO.PW_HEADER_FOUR',
          'text':'PO.PW_TEXT_FOUR',
          'img': "styles/img/paywalls/4-reports"
        }
      ]
    };

    ctrl.poState = $scope.poState = {};
    ctrl.isLoading = isLoading;
    ctrl.doneLoading = doneLoading;

    init();

    return ctrl;

    function init () {
      updateLastStatusFilter(null, $state.current, $stateParams);
      $scope.$on('$stateChangeSuccess', updateLastStatusFilter);
    }

    function isLoading () {
      ctrl.poState.isLoading = true;
    }

    function doneLoading () {
      ctrl.poState.isLoading = false;
    }

    function updateLastStatusFilter (event, toState, toParams) {
      console.debug('Updating Last Status Filter: toState=%o toParams=%o', toState, toParams);

      if (toState.name === 'app.orders.purchase.list') {
        PurchaseOrderService.updateLastStatusFilter(toParams.statusFilter);
      }
    }
  }
}());

(function(){
  'use strict';

  angular
    .module('app.marketplace.ui.purchaseorders', ['app.marketplace.elements'])
    .config(poConfig);

  function poConfig($stateProvider, $urlRouterProvider) {
    $urlRouterProvider.when('/app/orders/purchase', '/app/orders/purchase/');
    $urlRouterProvider.when('/app/orders/purchase/', '/app/orders/purchase/list/');
    $urlRouterProvider.when('/app/orders/purchase/list', '/app/orders/purchase/list/');
    $urlRouterProvider.when('/app/orders/purchase/draft', '/app/orders/purchase/draft/');

    $stateProvider.state('app.orders.purchase',{
      url: '/purchase',
      controller: 'poSectionController',
      controllerAs: 'poSecCtrl',
      templateUrl: 'templates/marketplace/purchase-orders/index.html',
      data: {
        'title': 'Purchase Orders',
        'require_log_in': true,
        // this was preventing the purchase orders paywall from showing up. (let this be a reminder to you all...)
        // 'requires_feature': 'purchase_orders',
      }
    });

    $stateProvider.state('app.orders.purchase.list', {
      url: '/list/:statusFilter',
      controller: 'poListController',
      controllerAs: 'polCtrl',
      templateUrl: 'templates/marketplace/purchase-orders/list-view.html',
      data: {
        'title': 'Purchase Orders',
        'require_log_in' : true,
        'action_bar_directive': 'action-bar-orders'
      },
      params: {
        'filter': null,
        'active_search_tag': null,
      }
    });

    $stateProvider.state('app.orders.purchase.draft', {
      url: '/draft/:poId/?:editingActive',
      controller: 'poDraftController',
      controllerAs: 'podCtrl',
      templateUrl: 'templates/marketplace/purchase-orders/draft-view.html',
      data: {
        'title': 'Purchase Order Draft',
        'require_log_in' : true,
        'action_bar_directive': 'action-bar-po-draft'
      },
      params: {
        initial_items: null,
        supplier: null,
        shopping_list_po: false,
      }
    });

    $stateProvider.state('app.orders.purchase.active', {
      url: '/active/:poId',
      controller: 'poActiveController',
      controllerAs: 'poaCtrl',
      templateUrl: 'templates/marketplace/purchase-orders/active-view.html',
      data: {
        'title': 'Purchase Order',
        'require_log_in' : true,
        'action_bar_directive': 'action-bar-po-active'
      }
    });

  }

}());

(function () {
  'use strict';
  angular.module('sowMarketplace')
  .controller('mktAddQtyController', mktAddQtyController);

  function mktAddQtyController($scope, $filter, $rootScope, $mdDialog, sgToast, errorService, sowAnalyticsService, sowMktService, productDetailsService, mktHelperService, sowLanguageService, ProductHelperService) {
    /*jshint validthis: true */
    var ctrl = this;
    ctrl.working = false;

    ctrl.data = {'quantity': 1};
    ctrl.inventory_status = {};
    ctrl.subsections_to_display = [
      'on_hand',
      'desired_level',
      'fill_qty',
    ]

    ctrl.addToCart = addToCart;
    ctrl.cancel = $mdDialog.cancel;
    ctrl.getTotalQty = getTotalQty;
    ctrl.getUnitPrice = getUnitPrice;
    ctrl.selectImage = selectImage;
    ctrl.goToManufacturer = goToManufacturer;
    ctrl.goToVendor = goToVendor;
    ctrl.goToPDP = goToPDP;
    ctrl.isImageSelected = isImageSelected;
    ctrl.shouldShowInventoryInfo = shouldShowInventoryInfo;
    ctrl.toggleFavourite = toggleFavourite;
    ctrl.localize = mktHelperService.getLocalizedProp;
    ctrl.is_modal_open = true;
    ctrl.shouldShowStockStatus = ProductHelperService.shouldShowStockStatus;


    init();

    return ctrl;

    function init () {
      ctrl.language = sowLanguageService.getCurrentLanguage();
      ctrl.quantity_options = productDetailsService.generateQuantityOptions(ctrl.vendor_item);
      _fetchAsyncData();
      checkForPromoQty();
      selectImage();
      const details_dialog_listener = $rootScope.$on('promo-details-dialog: visibility', (_, value) => _toggleModal(value))
      $scope.$on("$destroy", details_dialog_listener);
    }
   
    /**
     * Fetches data from two different endpoints and updates the inventory
     * status with the newly available data.
     */
    function _fetchAsyncData(product = ctrl.product) {
      const product_id = product?.id;

      const promise_requests = [
        sowMktService.fetchInventoryInfo({ product_id }),
        productDetailsService.generateActiveOrderTooltip(product_id),
      ];

      Promise.allSettled(promise_requests)
        .then(([inventory_status_res, tooltip_res]) => {
          const inventory_status = _getInventoryStatus(inventory_status_res.value);
          const active_order_tooltip = tooltip_res.value;
          ctrl.inventory_status = _.extend(
            { active_order_tooltip },
            inventory_status,
          );
          // force rerender to ensure updated inventory status accounted for
          $scope.$apply();
        });
    }

    function _getInventoryStatus(product = ctrl.product) {
      return mktHelperService.getProductInventoryStatus(product);
    }

    /**
     * Sets an image in the gallery as the main image
     * @param img - The image to select. If not provided, the first image in the product's images array
     * will be selected.
     */
    function selectImage(img) {
      ctrl.selected_img = img || ctrl.product.images?.[0];
    }

    function addToCart () {
      if(!ctrl.data.quantity || ctrl.data.quantity < 1){
        var t_message = $filter('translate')('ERRORS.SET_QUANTITY')
        return errorService.uiErrorHandler(t_message);
      }
      ctrl.working = true;

      return productDetailsService.addToCart(ctrl.product, ctrl.vendor_item, ctrl.data.quantity).then(function (mesg) {
        $rootScope.$broadcast('mkt-cart-add-product', ctrl.product);
        $rootScope.$broadcast('shopping-list-remove-product', ctrl.product);
        const product = _.cloneDeep(ctrl.product);
        const item_compiled = _.extend(product, ctrl.vendor_item, {'quantity': ctrl.data.quantity});        
        sowAnalyticsService.logAddToCart([item_compiled]);
        $mdDialog.hide();
        $rootScope.$broadcast('added-to-cart', ctrl.product, ctrl.data.quantity, ctrl.vendor_item);
      }).catch(function (error) {
        errorService.uiErrorHandler(error);
      }).finally(function () {
        ctrl.working = false;
      });
    }

    function toggleFavourite(product = ctrl.product) {
      return productDetailsService.toggleFavourite(product)
    }

    function getTotalQty () {
      var total_qty = 0;
      var qty = _.toNumber(ctrl.data.quantity || 0);
      var buy = _.toNumber(_.get($scope.promo, 'promotion_properties.buy', null));
      var promo_active = $scope.promo && $scope.promo.id && $scope.promo.promotion_type === 'BUY_GET' && qty >= buy;
      if (promo_active) {
        var free_qty = (Math.floor(qty / buy) * _.toNumber($scope.promo.promotion_properties.get));
        total_qty = free_qty + qty;
      } else {
        total_qty = _.toNumber(qty);
      }
      return total_qty;
    }

    function checkForPromoQty () {
      var promo_id = _.get(ctrl.vendor_item, 'vendor_inventory_promotion.id', null);
      var promo = (promo_id ? ctrl.vendor_item.vendor_inventory_promotion : _.get(ctrl.vendor_item, 'promotions[0]', null));
      if(promo){
        _.set(ctrl, 'data.quantity', promo.promotion_properties.buy || 1);
      }
    }

    // sum up the cost of promo items at promo price and regular items at regular price, 
    // making a new live effective price for this purchase
    function getUnitPrice (product, qty_ordered, promo) {
      var unit_price = _.toNumber(product.price || product.unit_price);
      qty_ordered = _.toNumber(qty_ordered);
      if(promo.id) {
        var qty_not_promo = qty_ordered % promo.promotion_properties.buy;
        var not_promo_cost = (qty_not_promo * unit_price);

        switch (promo.promotion_type) {
          case "BUY_GET":
            var qty_free = Math.floor(qty_ordered / promo.promotion_properties.buy) * promo.promotion_properties.get;
            var promo_cost = ( (qty_ordered - qty_not_promo) + qty_free) * _.toNumber(promo.effective_price);
            unit_price = (not_promo_cost + promo_cost) / (qty_ordered+qty_free);
            break;
          case "MANUFACTURER_BUY_GET":
            // MANUFACTURER_BUY_GET has an effective price which takes into
            // account the "free" quantity, thereby lowering the `product.price`
            // However, we are deciding the show the non-effective price
            // in the add-to-cart modal, as this is reflective of the true
            // price which will be paid by the customer.
            break;
          case "PERCENT_OFF":
            var promo_cost = (qty_ordered - qty_not_promo) * _.toNumber(promo.effective_price);
            unit_price = (not_promo_cost + promo_cost) / (qty_ordered);
            break;
          case "VALUE_OFF":
            var promo_cost = (qty_ordered - qty_not_promo) * _.toNumber(promo.effective_price);
            unit_price = (not_promo_cost + promo_cost) / (qty_ordered);
            break;
          // "MANUFACTURER_BUY_GET_OTHER" has a unit_price of effective_price, handled by default
          default:
            unit_price = promo.effective_price;
        }
          
          // for debugging purposes
          // console.table({
          //   'qty_ordered': qty_ordered,
          //   'qty_free': qty_free,
          //   'qty_not_promo': qty_not_promo,
          //   'not_promo_cost': not_promo_cost,
          //   'promo_cost': promo_cost,
          //   'unit_price': unit_price
          // });
      }
      
      return unit_price;
    }

    function shouldShowInventoryInfo() {
      return parseInt(ctrl.inventory_status?.on_hand) > -1;
    }

    function isImageSelected(img) {
      return img.image === ctrl.selected_img.image;
    }

    function goToManufacturer() {
      ctrl.cancel();
      return productDetailsService.goToManufacturer(ctrl.product.manufacturer);
    }

    function goToVendor() {
      ctrl.cancel();
      return productDetailsService.goToVendor(ctrl.vendor_item);
    }

    function goToPDP() {
      ctrl.cancel();
      return mktHelperService.goToProductDetailsPage(ctrl.product);
    }
  
    /** Toggles the AddToCart modal with the Details Modal
     * @param {object} props
     * @param {boolean} props.is_dialog_visible
     */
    function _toggleModal({is_dialog_visible}) {
      ctrl.is_modal_open = !is_dialog_visible;
    }
  }
}());

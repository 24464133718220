(function(){
  'use strict';
  angular.module("app.marketplace.ui.purchaseorders")
    .directive('actionBarPurchaseOrders',poActionBarDirective);

  function poActionBarDirective() {
    return {
      restrict: 'AC',
      templateUrl: 'templates/marketplace/purchase-orders/directives/action-bar-purchase-orders.html',
      controller: 'poActionBarController',
      controllerAs: 'poAbCtrl'
    };
  }

}());
angular.module("app.marketplace.elements")
.service("contentBlockModel", function($rootScope, $filter, apiUrl, searchUrl, commonApiService) {
  var _this = this;
  this.model_data = {
    "name" : "contentBlock",
    "display_name" : "Content Block",
    "watch_vars": {"country" : true},
    "api" : {
      "single" : function(options){
        if(!checkNested(options,'data','id')){
          throw "Missing option(s) for external contentBlock model path 'single'. Options: " + JSON.stringify(options);
        }
        return apiUrl + "/marketplace-content-blocks/" + options.data.id;
      },
      "multiple" : function(options){

        return {
           "path" : searchUrl+"/products/MarketplaceContentBlock/_search",//"/products";
           "type" : "elastic",
           "params" : {
             "method" : "POST",
             "data" : {"size": 100}
           }
        };
//        return apiUrl + "/marketplace-content-blocks?is_published=true";
      }
    }
  };

  this.autofill = function(element) {
    if(!element){
      return;
    }
    if(element.countries){
      element.countries_map = {};
      _.map(element.countries, function(country){
        element.countries_map[country.id] = country;
      });
    }

    element.UI.display = true;
    if($rootScope.current_country && $rootScope.current_country.id && element.countries && element.countries.length){
      element.UI.display = !!element.countries_map[$rootScope.current_country.id];
    }

    // Sort displayed products.
    if(element && element.displayed_products && element.displayed_products.length){
      if(element.displayed_products[0].name){ //Ensure that the sortable field exists.
        element.displayed_products = $filter('orderBy')(element.displayed_products, '+name');
      }
    }
  };

  this.fields = {
    "id" : {
      "display_name" : "id",
      "required" : true,
      "api" : {
        "submit" : true
      },
      "type" : "integer"
    },
    "title" : {
      "display_name" : "id",
      "required" : true,
      "api" : {
        "submit" : true
      },
      "type" : "string"
    }
  };

  this.data = {
    "params" : {
      "model_name" : "contentBlock"
    }
  };

});

var _setDefaultAttr = function (element, name, value) {
  if (!element.hasAttribute(name)) {
    element.setAttribute(name, value);
  }
};

var _setDefaultAttrJQLite = function (element, name, value) {
  _setDefaultAttr(element[0], name, value);
};


angular.module('app.shared.support')

.directive('oneColumnRow', function ($log) {
  return {
    restrict: 'A',
    link: function (scope, element, attrs) {
      element.addClass('row');
      element.addClass('flex');

      if (!element.hasClass('layout-column') && !attrs['layout']) {
        element.addClass('layout-row');
      }

      element.children().addClass('flex');
    }
  };
})

.directive('twoColumnRow', function ($log) {
  return {
    restrict: 'A',
    link: function (scope, element, attrs) {
      element.addClass('row');
      element.addClass('flex');

      if (!element.hasClass('layout-column') && !attrs['layout']) {
        element.addClass('layout-row');
      }

      element.addClass('layout-sm-column');
      element.children().addClass('flex-50');
      element.children().addClass('flex-sm-100');
    }
  };
})

;

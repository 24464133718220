(function () {
  'use strict';

  angular.module('sowSterilization')
    .directive('sowStznTestFormWasher', stznTestFormWasherDirective);

  function stznTestFormWasherDirective() {
    return {
      restrict: 'E',
      templateUrl: 'sow-sterilization/directives/stzn-washer-test-form.html'
    };
  }

})();
(function () {
  'use strict';
  
  angular.module('sowProduct')
  .controller('pdSelectedVendorController', pdSelectedVendorController)
  .directive('pdSelectedVendor', pdSelectedVendorDirective);
  
  function pdSelectedVendorDirective() {
    return {
      restrict: 'E',
      templateUrl: 'sow-product-details/directives/pd-selected-vendor.html',
      controller: 'pdSelectedVendorController',
      controllerAs: 'pdSVCtrl',
      scope: {
        product: '<',
        vendor: '<'
      },
    };
  }
  
  function pdSelectedVendorController($filter, $scope, $rootScope, productDetailsService, AccessService, ProductHelperService) {
    const ctrl = this;

    const PRIMARY_BTN_CLASS = 'sow-primary-btn';
    const SECONDARY_BTN_CLASS = 'sow-secondary-btn';

    const BASIC_BUTTON = Object.freeze({
      class_list: PRIMARY_BTN_CLASS,
      is_disabled: false,
      is_loading: false,
    });

    ctrl.goToVendor = goToVendor;
    ctrl.isAddToCartDisabled = isAddToCartDisabled;
    ctrl.isVariantOptionDisabled = isVariantOptionDisabled;
    ctrl.isVariantOptionSelected = isVariantOptionSelected;
    ctrl.shouldShowStockStatus = ProductHelperService.shouldShowStockStatus;
    
    $scope.$on('vendor-updated', _updateVendor);
    
    ctrl.$onInit = init;
    
    return ctrl;
    
    function init() {
      _defineLocks();

      ctrl.product = ctrl.product || $scope.product;
      ctrl.vendor = ctrl.vendor || $scope.vendor;

      ctrl.quantity_options = _generateQuantityOptions();
      ctrl.variants = _setVariants();

      ctrl.can_add_to_gen_inv = _canAddToGenInv();
      ctrl.can_add_to_meds = _canAddToMeds();

      _resetQuantity();
      _generateButtons();
    }

    /**
     * Sets the quantity to 1
     */
    function _resetQuantity() {
      ctrl.quantity = 1;
    }

    /**
     * Generates the buttons (if any) we will display to the user
     */
    function _generateButtons() {
      ctrl.buttons = {};
      _generateCartButton();
      _generateShoppingListButton();
      _generateGeneralInventoryButton();
      _generateMedicationsButton();
    }

    /**
     * Generates the "Add to Cart" button
     */
    function _generateCartButton() {
      ctrl.buttons.cart = {
        ...BASIC_BUTTON,
        is_disabled: isAddToCartDisabled(),
        locale_key: _getCartButtonLocaleKey(),
        handleClick: _addToCart,
        is_visible: ctrl.has_cart_access,
      }
    }

    /**
     * Determines if adding the product to the shopping cart is disabled
     * @param {object} product
     * @param {object} vendor
     * @return {boolean}
     */
    function isAddToCartDisabled(product = ctrl.product, vendor = ctrl.vendor) {
      return !ctrl.has_cart_access
        || !_doesVendorHaveProduct() 
        || product.UI.is_formulary
        || vendor.UI.is_disconnected
        || vendor.UI.actions?.UI_flags?.disable_add_to_cart
        || Boolean(vendor.UI.date_available_text);
    }

    /**
     * Generates the text for the cart button
     * @param {object} vendor
     */
    function _getCartButtonLocaleKey(vendor = ctrl.vendor) {
      // handle time restricted products
      if (vendor.UI.date_available_text) {
        return vendor.UI.date_available_text;
      }
      // handle available products
      if (_doesVendorHaveProduct() || _.isNil(vendor.price)) {
        return 'ACTIONS.ADD_TO_CART';
      }
      // handle unavailable products
      const {DISCONTINUED, SOLD_OUT} = ProductHelperService.PRODUCT_STATUS;
      switch (vendor.UI.product_status) {
        case DISCONTINUED:
          return 'MARKETPLACE.DETAIL.DISCONTINUED';
        case SOLD_OUT:
        default:
          return 'MARKETPLACE.DETAIL.SOLD_OUT';
      }
    }

    /**
     * Sets and resets the cart button loading state
     * @param {boolean} is_adding
     */
    function _setIsAddingToCart(is_adding) {
      ctrl.buttons.cart.is_loading = is_adding;
      ctrl.buttons.cart.locale_key = is_adding ? 'MARKETPLACE.DETAIL.ADDING_TO_CART' : 'ACTIONS.ADD_TO_CART';
    }

    /**
     * Generates the "Add to Shopping List" button
     * @param {object} product
     */
    function _generateShoppingListButton(product = ctrl.product) {
      ctrl.buttons.list = {
        ...BASIC_BUTTON,
        class_list: product.UI.is_formulary ? PRIMARY_BTN_CLASS : SECONDARY_BTN_CLASS,
        locale_key: 'ACTIONS.ADD_TO_LIST',
        handleClick: _addToShoppingList,
        is_visible: ctrl.has_gen_inv_access && ctrl.has_shopping_list_access,
      }
    }

    /**
     * Sets and resets the shopping cart button loading state
     * @param {boolean} is_adding
     */
    function _setIsAddingToShoppingList(is_adding) {
      ctrl.buttons.list.is_loading = is_adding;
      ctrl.buttons.list.locale_key = is_adding ? 'ACTIONS.ADDING_TO_LIST' : 'ACTIONS.ADD_TO_LIST';
    }

    /**
     * Generates the "Add to General Inventory" button
     * @param {object} product
     */
    function _generateGeneralInventoryButton(product = ctrl.product) {
      ctrl.buttons.inv = {
        ...BASIC_BUTTON,
        class_list: product.UI.is_formulary ? PRIMARY_BTN_CLASS : SECONDARY_BTN_CLASS,
        locale_key: _getInventoryButtonLocaleKey(),
        is_disabled: !ctrl.can_add_to_gen_inv,
        handleClick: _addToGenInv,
        is_visible: ctrl.has_gen_inv_access && !_.includes(product.item_type, 'Implant'),
      }
    }

    /**
     * Gets the locale key to display in the inventory button
     * @return {string}
     */
    function _getInventoryButtonLocaleKey() {
      return ctrl.can_add_to_gen_inv ? 'ACTIONS.ADD_TO_GEN_INV' : 'MARKETPLACE.DETAIL.ADDED_TO_GEN_INV';
    }

    /**
     * Sets and resets the inventory button loading state
     * @param {boolean} is_adding
     */
    function _setIsAddingToGenInv(is_adding) {
      ctrl.buttons.inv.is_loading = is_adding;
      ctrl.buttons.inv.locale_key = is_adding ? 'MARKETPLACE.DETAIL.ADDING_TO_GEN_INV' : _getInventoryButtonLocaleKey();
    }

    /**
     * Generates the "Add to Medications" button
     * @param {object} product
     */
    function _generateMedicationsButton(product = ctrl.product) {
      ctrl.buttons.meds = {
        ...BASIC_BUTTON,
        class_list: product.UI.is_formulary ? PRIMARY_BTN_CLASS : SECONDARY_BTN_CLASS,
        locale_key:  _getMedicationsButtonLocaleKey(),
        is_disabled: !ctrl.can_add_to_meds,
        handleClick: _addToMeds,
        is_visible: product.UI.is_medication && ctrl.has_meds_access,
      }
    }

    /**
     * Gets the locale key to display in the medications button
     * @return {string}
     */
    function _getMedicationsButtonLocaleKey() {
      return  ctrl.can_add_to_meds ? 'ACTIONS.ADD_TO_MEDS' : 'MARKETPLACE.DETAIL.ADDED_TO_MEDS';
    }

    /**
     * Sets and resets the medications button loading state
     * @param {boolean} is_adding
     */
    function _setIsAddingToMeds(is_adding) {
      ctrl.buttons.meds.is_loading = is_adding;
      ctrl.buttons.meds.locale_key = is_adding ? 'MARKETPLACE.DETAIL.ADDING_TO_MEDS' : _getMedicationsButtonLocaleKey();
    }

    /**
     * Generates the quantity options the user can select in the dropdown
     * @param {object} vendor
     * @return {object[]}
     */
    function _generateQuantityOptions(vendor = ctrl.vendor) {
      return productDetailsService.generateQuantityOptions(vendor);
    }

    /**
    * Adds product(s) to user's cart, factoring in the quantity and vendor they have selected.
    * Only adds product(s) if we are not already in the process of adding to the cart.
    * @param {string} product_id
    * @param {string} vendor_id
    * @param {number} quantity
    */
    function _addToCart (product = ctrl.product, vendor = ctrl.vendor, quantity = ctrl.quantity) {
      if (ctrl.buttons.cart.is_loading || ctrl.buttons.cart.is_disabled) {
        return;
      }
      _setIsAddingToCart(true);
      
      productDetailsService.addToCart(product, vendor, quantity)
        .then(() => {
          $rootScope.$broadcast('added-to-cart', product, quantity, vendor);
          productDetailsService.logItemAddedToCart(product, vendor, quantity);
        })
        .finally(() => {
          _setIsAddingToCart(false);
          _resetQuantity();
        });
    }

    /** 
    * Adds the currently selected product to the office's General Inventory. 
    * @param {object} product
    */
    function _addToGenInv (product = ctrl.product) {
      if (ctrl.buttons.inv.is_loading || ctrl.buttons.inv.is_disabled) {
        return;
      }
      _setIsAddingToGenInv(true);
      return productDetailsService.addToGenInv(product, ctrl.vendor)
        .then(general_item => {
          if (general_item.id) {
            ctrl.can_add_to_gen_inv = false;
            _updateGenInvData(general_item);
            _generateGeneralInventoryButton();
          }
          return general_item;
        }).finally(() => {
          _setIsAddingToGenInv(false);
        });
    }

    /** 
    * Adds the currently selected product to the office's Medications (only an option for medications). 
    * @param {object} product
    * @param {object} vendor
    */
    function _addToMeds (product = ctrl.product, vendor = ctrl.vendor) {
      if (ctrl.buttons.meds.is_loading || ctrl.buttons.meds.is_disabled) {
        return;
      }
      _setIsAddingToMeds(true);
      return productDetailsService.addToMeds(product, vendor)
        .then(medication_item => {
          if (medication_item.id) {
            ctrl.can_add_to_meds = false;
            _updateMedsData(medication_item);
            _generateMedicationsButton();
          }
          return medication_item;
        })
        .finally(() => {
          _setIsAddingToMeds(false);
        });
    }

    /** 
    * Determines if the product can be added to General Inventory (true if user has access 
    * and item has not already been added to General Inventory).
    * @param {object} product
    * @return {boolean} 
    */
    function _canAddToGenInv(product = ctrl.product) {
      const { is_in_gen_inv } = product.UI;
      return ctrl.has_gen_inv_access && !is_in_gen_inv;
    }

    /** 
    * Determines if the product can be added to Medications (true if user has access, 
    * item is a medication, and item has not already been added to Medications).
    * @param {object} product
    * @return {boolean} 
    */
    function _canAddToMeds(product = ctrl.product) {
      const { is_medication, is_in_meds } = product.UI;
      return ctrl.has_meds_access && is_medication && !is_in_meds;
    }

    /** 
    * Navigates to the marketplace with the currently selected vendor applied as a filter
    * @param {object} vendor
    */
    function goToVendor(vendor = ctrl.vendor) {
      return productDetailsService.goToVendor(vendor);
    }

    /** 
    * Updates the selected vendor, generates the corresponding quantity
    * options, and resets the quantity of the <select> to 1.
    * Invoked when a new vendor is selected, triggering a $broadcast.
    * @param {object} _event unused event from broadcast
    * @param {object} new_vendor
    */
    function _updateVendor(_event, new_vendor) {
      ctrl.vendor = new_vendor;
      _generateButtons();
      ctrl.quantity_options = _generateQuantityOptions();
      _resetQuantity();
    }

    // TODO: add variant logic (once data is updated)
    function isVariantOptionDisabled(vendor = ctrl.vendor) {
      return !_doesVendorHaveProduct() || vendor.UI.date_available_text;
    }
    function isVariantOptionSelected() {
      return !isVariantOptionDisabled();
    }
    /** 
    * Parses the product to return an array of variants which will be displayed in the UI.
    * @param {object} product
    * @return {object[]}
    */
    function _setVariants(product = ctrl.product) {
      const { content_per_unit: content, unit_name: unit } = product;
      const t_content = $filter('translate')('COMMON.CONTENTS');
      const variants = [{ name: t_content, options: [{ content, unit }] }];
      return variants;
    }

    /** 
    * Adds a product to the Shopping List (which section it's added to is determined by its type).
    * @param {object} product 
    * @param {object} vendor used for adding implants since vendor SKU matches implant group ref #
    * @return {*} 
    */
    function _addToShoppingList (product = ctrl.product, vendor = ctrl.vendor) {
      if (ctrl.buttons.list.is_loading) {
        return;
      }
      _setIsAddingToShoppingList(true);
      let promise;
      const type = productDetailsService.getProductType(product);
      switch (type) {
        case "implant":
          promise = productDetailsService.addProductToShoppingList(product, 'implant_inventory_status', vendor);
          break;
        case "medication":
          if (ctrl.can_add_to_meds) {
            promise = _addToMeds(product, vendor)
              .then(medication => {
                return productDetailsService.addToShoppingList(medication.inventory_item_id, vendor);
              });
          } else {
            promise = productDetailsService.addProductToShoppingList(product, 'medication_inventory_status', vendor);
          }
          break;
        case "general":
        default:
          if (ctrl.can_add_to_gen_inv) {
            promise = _addToGenInv(product)
              .then(general_item => {
                return productDetailsService.addToShoppingList(general_item.id, vendor);
              });
          } else {
            promise = productDetailsService.addProductToShoppingList(product, 'office_inventory_status', vendor);
          }
      }
      return promise
        .finally(() => {
          _setIsAddingToShoppingList(false);
        });
    }

    /**
     * Checks if the vendor has the product currently available
     * @param {object} vendor
     * @return {boolean}
     */
    function _doesVendorHaveProduct(vendor = ctrl.vendor) {
      return productDetailsService.doesVendorHaveProduct(vendor);
    }

    /**
     * Updates the product after it's successfully added to medications
     * @param {object} medication the medication object returned from the API
     * @param {object} product
     */
    function _updateMedsData (medication, product = ctrl.product) {
      _.set(product, 'medication_inventory_status', {
        ...product.medication_inventory_status,
        office_inventory_id: medication.inventory_item_id,
        medication_id: medication.id,
      });
    }

    /**
     * Updates the product after it's successfully added to general inventory
     * @param {object} general_item the inventory object returned from the API
     * @param {object} product
     */
    function _updateGenInvData (general_item, product = ctrl.product) {
      _.set(product, 'office_inventory_status', {
        ...product.office_inventory_status,
        office_inventory_id: general_item.id,
      });
    }

    function _defineLocks() {
      ctrl.has_cart_access = AccessService.hasCartAccess();
      ctrl.has_gen_inv_access = AccessService.hasGeneralInventoryAccess();
      ctrl.has_meds_access = AccessService.hasMedicationsAccess();

      // enable_mrkt_to_shopping_list is a special property used only here to override the override,
      // so better to evaluate the conditions locally instead of in the access service
      const enable_shopping_list = AccessService.getProperty('enable_mrkt_to_shopping_list');
      const disable_shopping_list = AccessService.getOverride('disable_shopping_list');
      const shopping_list_access = enable_shopping_list || !disable_shopping_list;
      ctrl.has_shopping_list_access = AccessService.hasOrdersPermission() && shopping_list_access;
    }
  }

})();

angular.module("app.marketplace.elements")
.service("orderItemModel", function(apiUrl, commonApiService) {
  var _this = this;
  this.model_data = {
    "name" : "orderItem",
    "display_name" : "Order Item",
    "api" : {
      "single" : function(options){ 
      },
      "multiple" : function(options){
        if(!checkNested(options,'order','hrid')){
          throw "Missing option(s) for order item model path 'multiple'. Options: " + JSON.stringify(options);
        }
        return {
          "path" : apiUrl + "/orders/" + options.order.hrid + "/items"
        };
      },
      "product_history" : function(options){
        if(!checkNested(options,'product','id')){
          throw "Missing option(s) for order item model path 'product_history'. Options: " + JSON.stringify(options);
        }
        return {
          "path" : commonApiService.getOfficeUrl()  + "/order_items?product_id=" + options.product.id
        };
      }
    }
  };

  this.autofill = function(order) {
    
  };
  
  this.fields = {
    "id" : {
      "display_name" : "id",
      "required" : true,
      "api" : {
        "submit" : true
      },
      "type" : "integer"
    }
  };

  this.data = {
    "params" : {
      "model_name" : "orderItem"
    }
  };

});
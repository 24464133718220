(function () {
  'use strict';

  angular
    .module('sowBrowserFlags', [])
    .service('sowBrowserFlags', BrowserFlagsService);

  // TODO Use a better way of getting Office / User data than $rootScope

  function BrowserFlagsService (localStorageService, $rootScope) {
    /*jshint validthis: true */
    var service = this;

    service.flag = flag;
    service.toggle = toggleFlag;

    return service;

    //
    // Generate a storage key for browser flags that is unique to an Office and
    // User. This allows people to switch between multiple offices, and/or
    // users while having their flags separate.
    //
    function _getKey () {
      return 'browser-flags-{0}-{1}'.format(
        _.get($rootScope, 'current_office.id'),
        _.get($rootScope, 'current_account.id')
      );
    }

    //
    // The main getter/setter function for reading and writing to a particular
    // flag by name.
    //
    function flag (flagName, flagValue) {
      var data = localStorageService.get(_getKey()) || {};

      if (arguments.length === 2) {
        data[flagName] = flagValue;
        localStorageService.set(_getKey(), data);
      }

      return data[flagName];
    }

    //
    // A convenience flag for toggling a flag on/off (by name).
    //
    function toggleFlag (flagName) {
      var flagValue = flag(flagName);
      return flag(flagName, !flagValue);
    }
  }
}());

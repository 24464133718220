(function () {
  'use strict';

  angular
    .module('app.marketplace.elements')
    .service('inventoryItemService', InventoryItemService);

  function InventoryItemService ($rootScope, $q, apiUrl, serverAPI, $filter, sgToast, elementService, errorService, sessionService, officeService, SupplierService, sowDownloadFile) {
    /*jshint validthis: true */
    var service = this;

    service.calculateInventoryValue = calculateInventoryValue;
    service.calculateInventoryOnHand = calculateInventoryOnHand;
    service.calculateInventoryUnit = calculateInventoryUnit;
    service.checkedItems = [];
    service.checkItem = checkItem;
    service.uncheckItem = uncheckItem;
    service.clearCheckedItems = clearCheckedItems;


    service.printLabelsForItems = printLabelsForItems;
    service.printLabelsForLocation = printLabelsForLocation;

    service.batchRemoveFromShoppingList = batchRemoveFromShoppingList;
    service.removeFromShoppingList = removeFromShoppingList;
    service.loadShoppingList = loadShoppingList;
    service.addToShoppingList = addToShoppingList;

    service.getInventoryCategories = getInventoryCategories;
    service.getInventoryPOStatus = getInventoryPOStatus;
    service.getItem = getInventoryItem;
    service.addStock = addStock;
    service.removeStock = removeStock;
    service.bulkRemoveStock = bulkRemoveStock;
    service.updateItem = updateItem;
    service.createItem = createItem;
    service.createItemInventory = createItemInventory;
    service.createItemFromProduct = createItemFromProduct;
    service.searchGenericProducts = searchGenericProducts;
    service.searchInventory = searchInventory;
    service.findItemViaProduct = findItemViaProduct;
    service.batchLocationUpload = batchLocationUpload;

    service.buildLocationBundles = buildLocationBundles;
    service.buildSupplierBundles = buildSupplierBundles;
    service.searchingProductModel = searchingProductModel;
    service.getSuggestedQuantities = getSuggestedQuantities;
    service.isItemDiscontinued = isItemDiscontinued;
    service.allItemsDiscontinued = allItemsDiscontinued;
    service.getNonDiscontinuedItems = getNonDiscontinuedItems;

    init();

    return service;

    function init () {
      $rootScope.$on('officeService: office-changed', function () {
        service.checkedItems.splice(0);
      });
    }

    //
    // Generate QR Code PDF for a selected set of inventory items.
    //
    function printLabelsForItems (items) {
      return elementService
        .callEndpoint('inventoryItem', {
          'endpoint': 'qrcode_label_pdf',
          'item_ids': _.map(items, 'id'),
          async: true,
        })
        .catch((error) => {
          const t_message = $filter('translate')('ERRORS.PDF_GENERATE')
          errorService.uiErrorHandler(t_message);
          throw error;
        });
    }

    //
    // Generate QR Code PDF for selected inventory location.
    //
    function printLabelsForLocation (location) {
      return elementService
        .callEndpoint('inventoryItem', {
          'endpoint': 'qrcode_label_pdf_location',
          'location_id': location ? location.id : '',
        })
        .then(function (response) {
          var name = location ? location.name : '(No Location)';

          sowDownloadFile.triggerDownload(response, '{0}.pdf'.format(name));
        })
        .then(null, function (error) {
          var t_error = $filter('translate')('ERRORS.PDF_GENERATE')
          errorService.uiErrorHandler(t_error);
          throw error;
        });
    }

    //
    // Generate a mapping of (Inventory Item, PO Status) => Purchase Order Info
    //
    function getInventoryPOStatus () {
      return elementService
        .callEndpoint('inventoryItem', {'endpoint': 'get_inventory_po_status'})
        .then(function (response) {
          var data = response.data;
          var map = {};

          _.forEach(data, function (row) {
            var itemId = row.inventory_item_id;
            var poStatus = row.po_status;
            var itemMap = map[itemId] = map[itemId] || {};

            if (!itemMap[poStatus]) {
              itemMap[poStatus] = [];
            }

            itemMap[poStatus].push(row);
          });

          return map;
        });
    }

    //
    // Fetch an OfficeInventoryItem by ID.
    //
    function getInventoryItem (inventory_item_id, opts) {
      opts = opts || {};

      var options = {
        'forceAPI': opts.force,
      };

      return elementService.get('inventoryItem', inventory_item_id, options);
    }

    //
    // Fetch inventory categories
    //
    function getInventoryCategories () {
      return elementService.callEndpoint('inventoryItem', {
        'endpoint': 'get_categories'
      });
    }

    //
    // Batch Location Upload
    //
    function batchLocationUpload (rows, location) {
      var options = {
        'endpoint': 'batch_location_upload',
        'items': rows,
        'location': location,
      };

      return elementService.callEndpoint('inventoryItem', options).then(function (response) {
        var items = response.data;

        _.forEach(items, function (item) {
          elementService.create('inventoryItem', item);
        });

        return items;
      });
    }

    //
    //
    //
    function buildSupplierBundles (options) {
      options = options || {};

      var emptyBundleClass = options.emptyBundleClass || 'no-supplier';
      var emptyBundleName = options.emptyBundleName || '(No Supplier)';
      var supplierFilter = options.supplierFilter || defaultSupplierFilter;

      return SupplierService.getAll(true, options).then(function (suppliers) {
        var bundles = [];

        bundles.push({
          'class': emptyBundleClass,
          'name': emptyBundleName,
          'filter': supplierFilter(),
        });

        _.forEach(suppliers, function (supplier) {
          bundles.push({
            'id': supplier.id,
            'name': supplier.name,
            'supplier': supplier,
            'filter': supplierFilter(supplier),
          });
        });

        bundles = $filter('orderBy')(bundles, 'name');
        bundles.push(bundles.shift());

        return {
          'suppliers': suppliers,
          'bundles': bundles,
        };
      });

      //
      // The default supplier bundle filter function. Returns a function that
      // is used to filter the full list of inventory items into inventory
      // items under that belong in this supplier's bundle.
      //
      function defaultSupplierFilter (supplier) {
        return function (item) {
          if (!supplier) {
            return !item.vendor_name;
          } else {
            return (
              (item.supplier_id && item.supplier_id === supplier.id) ||
              (item.vendor_name && item.vendor_name.toLowerCase() === supplier.name.toLowerCase())
            );
          }
        };
      }

      var itemsWithSupplier = _.filter(items, 'vendor_name');
      var suppliers = _.map(itemsWithSupplier, makeSupplier);
      var uniqueSuppliers = _.uniq(suppliers, getSupplierKey);

      var noSupplierBundle = $.extend(options.no_supplier_bundle || {}, {
        'class': 'no-supplier',
        'name': '(No Supplier)',
        'items': [],
        'filter': supplierFilter(),
      });

      var bundles = _.map(uniqueSuppliers, function (s) {
        return buildSupplierBundle(s, options.force_bundle_refresh);
      });

      bundles = $filter('orderBy')(bundles, 'name');
      bundles.push(noSupplierBundle);

      _.forEach(bundles, fetchBundleItems);

      return bundles;

      function makeSupplier (inventoryItem) {
        return {
          'id': inventoryItem.supplier_id,
          'name': inventoryItem.vendor_name,
        };
      }

      function getSupplierKey (supplier) {
        return supplier.name ? supplier.name.toLowerCase() : null;
      }

      function buildSupplierBundle (supplier, forceRefresh) {
        if (options.existing_bundles) {
          var existingBundle = _.find(options.existing_bundles, function (bundle) {
            return (
              (bundle.id && bundle.id === supplier.id) ||
              (bundle.name && bundle.name.toLowerCase() === supplier.name.toLowerCase())
            );
          });

          if (existingBundle) {
            angular.extend(existingBundle, {
              'id': supplier.id,
              'name': supplier.name,
              'items': [],
              'force_refresh': forceRefresh,
              'filter': supplierFilter(supplier),
            });
            return existingBundle;
          }
        }

        return {
          'id': supplier.id,
          'name': supplier.name,
          'items': [],
          'force_refresh': forceRefresh,
          'filter': supplierFilter(supplier),
        };
      }
    }


    //
    // Build Location Bundles
    //
    // Returns a promise that resolves to an object with the attributes
    // 'bundles' (an array of bundles) and 'locations' (an array of locations)
    //
    function buildLocationBundles (options) {
      options = options || {};
      var emptyBundleClass = options.emptyBundleClass || 'no-supplier';
      var emptyBundleName = options.emptyBundleName || '(No Location)';
      var locationFilter = options.locationFilter || defaultLocationFilter;

      return officeService.getInventory().then(function (inventory) {
        var locations = inventory.locations;
        var bundles = [];

        bundles.push({
          'class': emptyBundleClass,
          'name': emptyBundleName,
          'filter': locationFilter(),
        });

        _.forEach(locations, function (location) {
          bundles.push({
            'id': location.id,
            'name': location.name,
            'location': location,
            'filter': locationFilter(location),
            'is_new': location.is_new
          });

        });

        bundles = $filter('orderBy')(bundles, 'name');
        bundles.push(bundles.shift());

        return {
          'locations': locations,
          'bundles': bundles,
        };

      });

      //
      // Just an explicit way of casting to a boolean
      //
      function toBool (value) {
        return !!value;
      }

      //
      // Generate a function that can be used to filter a list of items for
      // matching locations.
      //
      function defaultLocationFilter (location) {
        return function (item) {
          if (!location) {
            return toBool(!item.locations || !item.locations.length);
          } else {
            var matchingIndex = _.findIndex(item.locations, {'id': location.id});
            return matchingIndex !== -1;
          }
        };
      }
    }

    //
    // Look-up a location by name.
    //
    function _findLocationByName (locations, name) {
      return _.findIndex(locations, function (location) {
        return location.name.toLowerCase() === name.toLowerCase();
      });
    }

    //
    // Create a new Inventory Location
    //
    function _addLocation (item, location_name, index) {
      if (location_name) {
        location_name = location_name.trim();
      }

      if (!location_name) {
        return $q.reject(false);
      }

      if (item && item.id) {
        return elementService.callEndpoint('inventoryItem', {
          endpoint: 'add_new_location',
          location_name: location_name,
          item_id: item.id
        }).then(function (response) {
          var newLocation = response.data;
          officeService.addLocation(newLocation);
          if(index || index===0){
            item.locations[index] = newLocation;
          }else{
            item.location = newLocation;
          }
          return newLocation;
        }).catch(function(error){
          throw error || "API Error: Failed to create new location.";
        });
      }else{
        return officeService.createLocation(location_name, item.id).then(function (location) {
          if(index || index===0){
            item.locations[index] = location;
          }else{
            item.location = location;
          }
          return location;
        }).catch(function(error){
          throw error || "API Error: Failed to create new location.";
        });
      }
    }

    //
    // Create an Inventory Item
    //
    function createItem (item) {
      // if (!sessionService.isActive()) {
      //   return $q.reject('Please create an account or sign in first.');
      // }

      //Check if there is a new location on the element that has not been added to the list.
      if(!_.isArray(item.locations)){
        item.locations = [];
      }

      if (item.locationSearchText) {
        var newLoc = _.find(item.locations, function (obj) {
          return obj.name.toLowerCase() === item.locationSearchText.toLowerCase();
        });

        if (!newLoc) {
          item.locations.push({'name': item.locationSearchText});
        }
      }

      // Check locations for new locations that need to be created.
      if (item.locations && item.locations.length) {
        var doCreateLoc = function (item, name, index) { // Function created to avoid reference issues of having a function created within a loop
          return _addLocation(item, name, index).then(function (response) {
            return createItem(item);
          }).catch(function (error) {
            errorService.uiErrorHandler(error, 0);
            throw error;
          });
        };
        _.map(item.locations, function(location,location_name){
          if (!_.get(location, 'id', null)) {
            return doCreateLoc(item, location.name, location_name);
          }
        });
      }
      item.office_id = officeService.get().id;

      // fix for a change in property name
      item.inventory_category_id = item.category_id;

      // TODO reassess, add item to inventory.items list or change how inventory items are stored;
      if (item.product_id) {
        return elementService.submit('inventoryItem', {'endpoint': 'create_from_product', 'element': item });
      } else {
        return elementService.submit('inventoryItem', {'endpoint': 'create_from_user_product', 'element': item });
      }

    }
    function createItemInventory (item) {
      if (!sessionService.isActive()) {
        return $q.reject('Please create an account or sign in first.');
      }

      //Check if there is a new location on the element that has not been added to the list.
      item.locations = item.locations || [];

      if (item.locationSearchText) {
        var newLoc = _.find(item.locations, function (obj) {
          return obj.name.toLowerCase() === item.locationSearchText.toLowerCase();
        });

        if (!newLoc) {
          item.locations.push({'name': item.locationSearchText});
        }
      }

      // Check locations for new locations that need to be created.
      if (item.locations && item.locations.length) {
        var doCreateLoc = function (item, name, index) { // Function created to avoid reference issues of having a function created within a loop
          return _addLocation(item, name, index).then(function (response) {
            
          }).catch(function (error) {
            errorService.uiErrorHandler(error, 0);
            throw error;
          });
        };
        var location_promises = [];
        // return createItem(item);

        _.map(item.locations, function(location,location_name){
          if (!location.id) {
            location_promises.push( doCreateLoc(item, location.name, location_name) );
          }
        });

        return $q.all(location_promises).then(function(responses){
          return createItem(item);
        });
      }
      item.office_id = officeService.get().id;
      return elementService.submit('inventoryItem', {'endpoint': 'create_from_user_product', 'element': item });
      

    }

    /** Creates the params object for the createItem function using product and selected_vendor objects
     * @param {object} product
     * @param {number} quantity
     * @param {object} selected_vendor
    */
    function createItemFromProduct (product, quantity, selected_vendor) {

      // product.categories is an array of Objects wherein the first category is always the parent_category of the second,
      // which in turn is always the parent_category of the third, and so on.
      // We need the 'id' property of the last item in this array, as this is the most deeply nested subcategory and consequently
      // the only one which is not the parent_category of any other subcategory:
      const category_id = _.get(_.last(product.categories), 'id');

      const vendor_properties = _getSelectedVendorProps(selected_vendor || product.vendor_inventory[0]);
      const params = {
        category_id,
        manufacturer_id: _.get(product, 'manufacturer.id'),
        manufacturer_name: _.get(product, 'manufacturer.name'),
        name: product.name, 
        product_id: product.id || product.product_id, 
        quantity: quantity, 
        ...vendor_properties
      };

      return createItem(params);
    }

    /**
     * This function returns an object containing the vendor information of the selected vendor.
     * @param {object} selected_vendor
     * @returns {{price: number, sku: string, vendor_name: string, vendor_id: string}}}
     */
    function _getSelectedVendorProps (selected_vendor) {
      return {
        price: selected_vendor.price,
        sku: selected_vendor.vendor_sku,
        vendor_name: _.get(selected_vendor, 'vendor.name'),
        vendor_id: _.get(selected_vendor, 'vendor.id'),
      }
    }

    //
    //
    //
    function findItemViaProduct (product) {
      return officeService.get(true).then(function (office) {
        return elementService.get('inventoryItem', null, {
          endpoint: "search_via_product" ,
          office: office,
          product_id: product.id
        }).then(function (items) {
          if (!items || Object.keys(items).length === 0) {
            return null;
          } else {
            return items[Object.keys(items)[0]]; // TODO clean up endpoint to not return an array?
          }
        });
      });
    }

    function addStock (item, locations) {

      //TODO reassess. NOTE: I do not like how locations are delt with. This is ugly and should be cleaner.
      if(!item.location && item.locationSearchText){

        var locIndex = _findLocationByName(locations, item.locationSearchText);
        if(locIndex>-1){
          item["in"].location = locations[locIndex];
        } else {
          //Location not found.Create new location;
          return _addLocation(item, item.locationSearchText).then(function(response){
            _addStock(item);
          }).catch(function (error) {
            errorService.uiErrorHandler(error, 0);
            throw error;
          }); // Delay submission to after location is created.
        }
      }
      return elementService.submit("inventoryItem", {"endpoint" : "add_stock", "element" : item });
    }

    function removeStock (item) {
      return elementService.submit("inventoryItem", {"endpoint" : "remove_stock", "element" : item });
    }

    function bulkRemoveStock (items) {
      return elementService.callEndpoint("inventoryItem", {"endpoint" : "update_items", "items" : items })
      .then(function(response){
        return elementService.createMultiple("inventoryItem", response.data);
      });
    }

    function updateItem (item) {

      // Check locations for new locations that need to be created.
      if(item.locations && item.locations.length){
        var doCreateLoc = function(item, name, index){ //Function created to avoid reference issues of having a function created within a loop
          return _addLocation(item, name, index)
          .then(function(response){
            return updateItem(item);
          }).catch(function (error) {
            // errorService.uiErrorHandler(error, 0); Need to avoid duplicate error toasts. Assume controller handles error toast.
            throw error;
          });
        };
        _.map(item.locations, function(location, location_name){
          if(!location.id){
            doCreateLoc(item, location.name, location_name);
          }
        });
      }
      return elementService.submit("inventoryItem", {"endpoint" : "update", "element" : item });
    }

    function searchGenericProducts (name) {
      return elementService.callEndpoint("inventoryItem", {
        "endpoint" : "search_generic_products",
        "office" : officeService.get(),
        "medical_field_id" : officeService.get().medical_field_id,
        "medical_field_subtype" : officeService.get().medical_field_subtype,
        "name" : name
      }).then(function(response){
        return {
          inventory_items : response.data.inventory,
          generic_products : response.data.generic_products
        };
      });
    }

    function uncheckItem (item) {
      _.forEach(service.checkedItems, function (checkedItem, checkedIndex) {
        if (checkedItem.id === item.id) {
          service.checkedItems.splice(checkedIndex, 1);
          _.set(checkedItem, 'UI.checked', false);
          return false;
        }
      });
    }

    function checkItem (item) {
      _.set(item, "UI.checked", !_.get(item, "UI.checked", false) );
      if(item.UI.checked){
        service.checkedItems.push(item);
        // $scope.$apply();
        $rootScope.$broadcast('added-checked-item', item);
      }else{
        _.each(service.checkedItems, function(checked_item, i){
          if(!checked_item)
            return;
          if(checked_item.id === item.id){
            service.checkedItems.splice(i, 1);
            // $scope.$apply();
            $rootScope.$broadcast('removed-checked-item', item);
          }
        });
      }
    }

    function clearCheckedItems () {
      _.each(service.checkedItems, function(checked_item){
        if(!checked_item)
          return;
        _.set(checked_item, 'UI.checked', false);
      });
      // service.checkedItems = angular.copy([], service.checkedItems);
      service.checkedItems.splice(0);
      // $scope.$apply();
      $rootScope.$broadcast('inventory-checked-items-cleared');
    }

    /**
     * Removes a list of inventory items from the shopping list, then
     * broadcasts an event if at least one was removed successfully and
     * shows a toast indicating the status of the request (if the
     * options dictate that one should be shown)
     * @param {Array<object>} items
     * @param {object|undefined} options
     * @return {Array<object>}
     */
    function batchRemoveFromShoppingList(items, options = {}) {
      let removed_items = [];
      return elementService.callEndpoint('inventoryItem', {
        endpoint: 'batch_remove_from_shopping_list',
        items,
      })
      .then(res => {
        removed_items = _.values(res?.data);
      })
      .catch(error => {
        console.error(error);
      })
      .finally(() => {
        if (removed_items.length > 0) {
          $rootScope.$broadcast('shopping-list-updated');
        }
        if (options.showToast) {
          _showBatchRemoveFromShoppingListToast(removed_items.length);
        }
        return removed_items;
      });
    }

    /**
     * Shows a toast which reflects the response from a request
     * to batch remove items from the shopping list.
     * @param {number} count
     */
    function _showBatchRemoveFromShoppingListToast(count) {
      if (count > 0) {
        const t_message = $filter('translate')('TOAST.REMOVED_FROM_SHOPPING_LIST', {x: count});
        sgToast.showSimple(t_message);
      } else {
        const t_message = $filter('translate')('ERRORS.SHOPPING_LIST_BATCH_REMOVE', {count});
        errorService.uiErrorHandler(t_message);
      }
    }

    function removeFromShoppingList (item, options) {
      options = angular.extend({
        'broadcast': true,
      }, options);

      return elementService.callEndpoint('inventoryItem', {
        'endpoint': 'remove_from_shopping_list',
        'item': item,
      }).then(function (response) {
        var item = response.data;
        elementService.create('inventoryItem', item);
        uncheckItem(item);
        $rootScope.$broadcast('shopping-list-updated');
        return item;
      });
    }

    function loadShoppingList () {
      var deferred = $q.defer();
      elementService.callEndpoint('inventoryItem', {
        'endpoint': 'get_shopping_list_items'
      })
      .then(function(response) {
        deferred.resolve(response.data);
      })
      .catch(function(response) {
        deferred.reject(response);
      });

      return deferred.promise;
    }

    function addToShoppingList (items, vendor_info) {
      return elementService.callEndpoint('inventoryItem', {
        'endpoint': 'add_to_shopping_list',
        'items': items,
        vendor_info,
      }).then(function (response) {
        var items = response.data;
        elementService.createMultiple('inventoryItem', items);
        $rootScope.$broadcast('shopping-list-updated');
        return items;
      });
    }

    function searchInventory (search, supplier_id) {
      return elementService.callEndpoint("inventoryItem", {
        "endpoint" : "office_inventory_elastic_search",
        "search" : search,
        "supplier_id" : supplier_id,
      }).then(function(response){
        return response.data;
      });
    }

    function calculateInventoryValue(item) {
      var item_qty = _.get(item, 'on_hand', _.get(item, 'quantity', 0));
      if (!item.buy_by_case) {
        return (item_qty * item.price);
      }

      var casesOnHand = (item_qty / item.order_package_quantity) || 0;
      return (casesOnHand * item.price);
    }

    function calculateInventoryOnHand(item) {
      var item_qty = _.get(item, 'on_hand', _.get(item, 'quantity', 0));
      if (item.tracking_method === 'Item') {
        return item_qty;
      }

      if (!item.order_package_quantity) {
        return 0;
      }

      return Math.floor(item_qty / item.order_package_quantity);
    }

    function calculateInventoryUnit (item, quantity) {
      if (item.tracking_method === 'Item') {
        return quantity;
      }

      if (!item.order_package_quantity) {
        return 0;
      }

      return quantity * item.order_package_quantity; 
    }

    function searchingProductModel(key) {
      var office_info = sow.officeInfo();
      var office_id = office_info.id;
      var marketplace_id = office_info.marketplace[0];
      var url = '{0}/offices/{1}/search/generic-inventory-item?query={2}&medical_field_id=""&medical_field_subtype=""&marketplace_id={3}'.format(apiUrl, office_id, key, marketplace_id);
 
      var options = {
          'method': 'GET'
      };

      return serverAPI
          .doAPICall(url, options)
          .then(function(response) {
              return response.data;
          })
          .catch(errorService.uiErrorHandler);
    }

    /** 
     * Fetches suggested quantity data for each of the inventory items in an array based on
     * the item's on_hand quantity and desired_level (eg. 8 when on_hand is 4 and desired_level
     * is 12). The response is an Object where each key is the ID of an inventory item and each
     * value is the suggested quantity data for that particular item (which is itself an Object
     * containing item info and suggested quantities for buying by item or buying by pack). 
     * 
     * @param {Array} po_items 
     * 
     * @return {Object} 
    */
    function getSuggestedQuantities(po_items) {
      const office_id = officeService.get().id;
      const url = `${apiUrl}/offices/${office_id}/inventory/items/reorder-quantity`;
      const unique_ids = new Set();
      for (const po_item of _.values(po_items)) {
        const inventory_item_id = _.get(po_item, 'inventory_item.id');
        if (inventory_item_id) {
          unique_ids.add(inventory_item_id);
        }
      }
      const data = { office_inventory_list: [...unique_ids] };
      // This is more of a GET request but we made it a PUT so that we could include a payload
      return serverAPI
        .doAPICall(url, { method: 'PUT', data })
        .then(({ data }) => data)
        .catch(err => {
          return new Promise((resolve, reject) => reject(err));
        });
    }

    /**
     * Returns true if item cannot be purchased
     * @param {object} item 
     * @returns {boolean}
     */
    function isItemDiscontinued (item) {
      // check if item has can_purchase property and if it is false
      return typeof item.can_purchase === 'boolean' && !item.can_purchase;
    }

    /**
     * Returns true if all items in a given array have been discontinued based on their UI
     * property.
     * @param {object[]} items 
     * @returns {boolean}
     */
    function allItemsDiscontinued (items) {
      return _.every(items, (item) => item.UI?.is_discontinued);
    }

    /**
     * Returns an array of objects containing the inventory item ID for non-discontinued items in a given array.
     * @param {Array} items [ { id: 1, UI: { is_discontinued: false } }, { id: 2, UI: { is_discontinued: true } }]
     * @param {Function} map_callback_fn - Optional callback function to map over the non-discontinued items.
     * @returns {Array} - [ { id: 1 }, { id: 2 }]
     */
    function getNonDiscontinuedItems (items, map_callback_fn) {
      return _.flatMap(items, item => {
        if (!isItemDiscontinued(item)) {
          return map_callback_fn ? map_callback_fn(item) : item;
        }
        return [];
      });
    }
  }
}());

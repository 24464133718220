angular.module('app.marketplace.ui.marketing')
  .constant('SupportQuestions', {
    'General Questions': [
      {
        question: 'I have other questions, who do I contact?',
        answer: 'Feel free to contact Sowingo\'s Customer Service at <a href="mailto:support@sowingo.com">support@sowingo.com</a>.'
      },
      {
        question: 'What are the accepted methods of payment?',
        answer: 'Sowingo accepts payment by Visa, MasterCard and American Express.'
      },
      {
        question: 'Does Sowingo ship outside of Canada?',
        answer: 'Currently we only offer shipping to addresses within Canada.'
      },
      {
        question: 'Can I pay in USD?',
        answer: 'Unfortunately, we do not accept USD for payments at this time.'
      },
      {
        question: 'Why does Sowingo ask for a license number?',
        answer:
          'Health Canada mandates that healthcare professionals (dentists) provide license numbers to ' +
          'purchase regulated health products. All other products may be purchased by users without a license.'
      },
      {
        question: 'How can I receive discounts or promotions?',
        answer:
          '<p>There are two ways to receive discounts and promotions on the Sowingo Marketplace: </p>' +
          '<ol class="numbers"><li>Visit the Marketplace to check out all of the current promotions that are individually set by our suppliers. </li>' +
          '<li>Subscribe to Sowingo News and Promotions under your ‘Email Preferences’ in your ‘Profile’ to receive special offers.</li></ol>'
      },
      {
        question: 'What browsers are supported?',
        answer:
          'Sowingo is optimized for Google Chrome and Firefox. We also support Safari & Internet Explorer 11 and above.'
      },
      {
        question: 'Does Sowingo work on my mobile device?',
        answer:
          'Yes! Sowingo is optimized for mobile devices including tablets and phones. Just open your web browser and navigate to <a href="https://www.sowingo.com">www.sowingo.com</a>.'
      },
      {
        question: 'What does Sowingo cost?',
        answer:
          'Browse our <a href="../pricing.html">Account Types</a> for pricing.'
      },
      {
        question: 'Do I need to download Sowingo on my computer?',
        answer:
          'Sowingo is a cloud-based web application and does not require downloading. You can use Sowingo anywhere that you can access the Internet and you can be signed in on multiple devices at once.'
      },
      {
        question: 'Do I need to be a large office to take advantage of Sowingo?',
        answer:
          'Not at all. Sowingo is a dynamic management tool that can be utilized by all types of practices. Even if you are a small team, you likely have over 150 different supplies on hand – all of which need to be accounted for. Sowingo makes it easy to manage and update your inventory lists, and is a great way to record sterilization logs.'
      }
    ],
    'Account Management': [
      {
        question: 'How do I add users to my account?',
        answer:
          '<ol class="numbers">' +
          '<li>Sign into your Sowingo account.</li> ' +
          '<li>Navigate to the ‘My Office’ tab on the top of the page. Select ‘Office Profile’ from the dropdown menu.</li>' +
          '<li>Select the ‘Staff’ tab and then click the ‘Add New User’ button.</li>' +
          '<li>Complete all fields and click ‘Send Invite’.</li>' +
          '</ol>' +
          '<p>The new user will receive an email where they will be instructed to confirm their registration. The new user will then be linked to your account.</p>'
      },
      {
        question: 'What are the different User Types and Permissions?',
        answer:
          "<strong>Master User</strong>" +
          "<p>A Master has all permissions throughout the site including the ability to add & delete any User. Masters will have the option to receive monthly and weekly analytics reports sent to their email.</p>" +
          "<strong>Manager</strong>" +
          "<p>A Manager has the same permissions as a Master, minus the ability to delete the Master account. They also have the option to receive monthly and weekly analytics reports sent to their email.</p>" +
          "<strong>Team Member</strong>" +
          "<p>A Team Member has the ability to view all aspects of the site but cannot add or delete any Users. They do not have the option to receive monthly and weekly analytics reports sent to their email.</p>"
      },
      {
        question: 'Forgot your password?',
        answer:
          '<p>If you forgot your password you can reset it from the Sign In page. Click ‘Forgot your Password?’ and enter the email address associated with your account. Shortly after you will receive an email with steps to change your password.</p>'
      },
      {
        question: 'How do I change my email and password?',
        answer: (
          '<ol class="numbers">' +
          '<li>Sign into your Sowingo account.</li>' +
          '<li>On the top right of the page, click your name and select ‘Profile’ from the dropdown menu.</li>' +
          '</ol>' +
          '<p>You can change your email address in the ‘Personal Info’ tab. You can change your password in the ‘Password’ tab.</p>'
        )
      },
      {
        question: 'How do I change my email preferences?',
        answer: (
          '<ol class="numbers">' +
          '<li>Sign into your Sowingo account.</li>' +
          '<li>On the top right of the page, click your name and select ‘Profile’ from the dropdown menu.</li>' +
          '</ol>' +
          '<p>Navigate to the ‘Email Preferences’ tab and use the checkboxes to alter your email preferences.</p>'
        )
      },
      {
        question: 'What security measures are in place to protect my financial and business information?',
        answer: (
          "<p>Sowingo uses SSL certificates with the strongest level of security available. 2048-bit and SHA2 encryption enforce a secure connection between your data and our servers. Sowingo uses Digicert as its certificate authority.</p>" +
          "<p>Sowingo does not store any credit card information. We use Stripe as our payment processor and all communication is encrypted.</p>" +
          "<p>For additional details, please see Sowingo's <a href='/#/app/legal/privacy'>Privacy Policy</a>.</p>"
        )
      },
      {
        question: 'How do I close my account?',
        answer: (
          'Even though we will miss you, you may close your account at any time. ' +
          'To do so, please send an email to a Sowingo Team Member at ' +
          '<a href="mailto:support@sowingo.com">support@sowingo.com</a>.'
        )
      }
    ],
    'Ordering': [
      {
        question: 'How do I check my previous Orders?',
        answer: (
          "<ol class='numbers'>" +
          "<li>Sign into your Sowingo account.</li>" +
          "<li>Navigate to the ‘My Office’ tab on the top of the page. Select ‘Orders’ from the dropdown menu.</li>" +
          "<li>Click on a specific Order to view all details.</li>" +
          "</ol>" +
          "<p>Sowingo Orders are updated automatically by the Suppliers that are associated with the Order. Purchase Orders are manually updated by users (you) as the products are delivered.</p>"
        )
      },
      {
        question: 'How do I know the Supplier(s) have received my Order?',
        answer: (
          '<p>Once the Order has been placed through Sowingo, we will email you an Order Confirmation containing the details of the Order. The Supplier(s) will also receive the details immediately and will soon begin processing the Order.</p>'
        )
      },
      {
        question: 'How do I track the status of my Order?',
        answer: (
          "<ol class='numbers'>" +
          "<li>Sign into your Sowingo account.</li>" +
          "<li>Navigate to the ‘My Office’ tab on the top of the page. Select ‘Orders’ from the dropdown menu.</li>" +
          "<li>Click on a specific Order to view all details. You can click on a tracking number, where applicable, to see the most updated status and estimated delivery date.</li>" +
          "</ol>"
        )
      },
      {
        question: 'What if an item is unavailable or backordered?',
        answer: (
          'If a particular item in your Order is backordered or unavailable from the Supplier, you will receive notification by email indicating the estimated delivery date. You can also view the status of your Order (see above).'
        )
      },
      {
        question: 'What if I no longer want the backordered item(s)',
        answer: (
          "Once you are notified of a backordered item(s), you have 72 hours to decide if you'd like to wait until the Supplier is able to ship it to you, or you can cancel the backordered quantity. If you would like to wait, no further action is required on your end - we will let you know when the product is on its way. If you'd like to cancel the backordered quantity, contact a Sowingo Team Member at <a href='mailto:support@sowingo.com'>support@sowingo.com</a> to initiate the process. Refunds will be processed within 30 days to the original method of payment."
        )
      },
      {
        question: 'What sales tax am I responsible for?',
        answer: (
          'Sowingo charges the applicable GST/HST of your province. You are responsible for the PST portion of your order (if applicable). To determine your tax liability, please consult your provincial tax revenue agency.'
        )
      },
      {
        question: 'How do I change an order once it has been placed?',
        answer: (
          'Once an Order Confirmation is received, an Order cannot be changed or modified. Please refer to our <a href="/#/app/legal/terms">Terms and Conditions</a> for more information.'
        )
      },
      {
        question: 'How can I cancel my order?',
        answer: (
          'Once an Order Confirmation has been received, it is not possible to cancel an Order in part or in full. Please refer to our <a href="/#/app/legal/terms">Terms and Conditions</a> for more information.'
        )
      }
    ],
    'Shipping': [
      {
        question: 'How can I receive free shipping?',
        answer: (
          '<p>Our Suppliers individually set their own shipping fees. Most will offer free shipping ' +
          'over a certain dollar value spent, however, there is no guaranteed free shipping. Upon ' +
          'checkout you will see if you qualify for free shipping per Supplier. If you do not qualify,' +
          ' they will display the amount needed to reach the free shipping threshold.</p>' +
          '<p> <a href="#app/shipping-rates">See each supplier\'s minimum order for free shipping</a> </p>'
        ),
      },
      {
        question: 'Who ships the order?',
        answer:
          '<p>Items in your Order will be shipped directly to you by the Supplier(s) that are indicated during the checkout process. Sowingo does not warehouse, ship or distribute any products.</p>' +
          '<p>If you have any questions about your Order, contact a Sowingo Team Member at ' +
          '<a href="mailto:support@sowingo.com">support@sowingo.com</a>.</p>'
      },
      {
        question: 'What is Standard Shipping',
        answer:
          'Standard shipping refers to the general time frame that items will be delivered to you. Standard shipping takes approximately 3-5 business days, depending upon location. Please note that shipping costs and carriers are individually determined by the Suppliers.'
      },
      {
        question: 'Are there any other shipping and handling fees?',
        answer:
          'Some products that require special shipping requirements (ie. hazardous materials, ambient temperature, heavy products, etc) may be subject to an additional shipping and handling fee. This fee is determined by the Supplier, and will be indicated on the product description page as well as on checkout.'
      },
      {
        question: 'Can I ship different portions of the same Order to different offices?',
        answer:
          '<p>In order to ensure that you receive your Order as soon as possible, we are only able to ship each Order to one location. Should you require goods to be shipped to multiple locations, please place different orders for each location.</p>'
      },
      {
        question: 'If an Order cannot be completed, do I receive credit or a refund?',
        answer:
          'Should there be any reason that all or part your Order cannot be completed by Sowingo and/or its Suppliers, we will refund the original method of payment.'
      },
      {
        question: 'Products are missing from my order, what should I do?',
        answer:
          'Please note that some Orders may arrive as partial deliveries from the Supplier(s) due to product availability. If the final quantity of goods delivered is less than the quantity indicated in the Order Confirmation and the item has not been marked as ‘Backordered’ in your account, please contact us at ' +
          '<a ng-href="mailto:support@sowingo.com">support@sowingo.com</a>. A Sowingo Team Member will work directly with the Supplier(s) to resolve your issue.'
      }
    ],
    'Refunds & Returns': [
      {
        question: 'What qualifies for a refund?',
        answer: 'Products can only be returned for a refund if they qualify according to the <a href="/#/app/legal/terms#ReturnsPolicy">Returns Policy</a>.'
      },
      {
        question: 'When will I get my refund?',
        answer: 'You will receive your refund within 30 days of the products being received by the Supplier(s).'
      },
      {
        question: 'How do I return products?',
        answer:
          '<p>If your products qualify for a return or exchange (within 5 business days from date of delivery):</p>' +
          '<ol class="numbers">' +
          '  <li>Contact a Sowingo Team Member at <a href="mailto:support@sowingo.com">support@sowingo.com</a></li>' +
          '  <li>Sowingo will send you a shipping label to return the products to the Supplier(s).</li>' +
          '  <li>Sowingo will refund your money for a return, or have the Supplier ship a replacement product for an exchange, as indicated.</li>' +
          '</ol>' +
          '<p>' +
          'For full details, please review the <a href="/#/app/legal/terms#ReturnsPolicy">Returns Policy</a>.</p>'
      }
    ]
  });

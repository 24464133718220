(function () {
  'use strict';
  angular
    .module('app.marketplace.ui.purchaseorders')
    .controller('poListController', PoListController);

  function PoListController ($scope, $timeout, $filter, PurchaseOrderService, officeService, $state, sessionService, $rootScope, poHelperService, errorService, $mdDialog, sowVimeoService, $sce, AccessService, sowAnalyticsService, sowApprovalService) {
    /*jshint validthis: true */
    var ctrl = this;
    var poSecCtrl = $scope.poSecCtrl;

    ctrl.FOOTER_ID = 'po-list-footer-id';

    ctrl.search_tags = [
        {
          name:"PO.FILTERS.ALL",
          value: 'all',
          active: true,
        },
        {
          name:"PO.FILTERS.ACTIVE",
          value: 'Active',
          active: false,
        },
        {
          name:"PO.FILTERS.DRAFTS",
          value: 'Draft',
          active: false,
        },
        {
          name:"PO.FILTERS.RECEIVED",
          value: 'Received',
          active: false,
        },
        {
          name:"PO.FILTERS.CANCELLED",
          value: 'Cancelled',
          active: false,
        },
      ];
    ctrl.list_limit = null;

    ctrl.poData = [];
    ctrl.statusFilter = $state.params.statusFilter || null;

    ctrl.filter = $state.params.filter || {'searchType': "supplier"};
    ctrl.queryOptions = {};
    ctrl.queryOptions.periods = [];

    ctrl.current_page = 0;

    ctrl.queryOptions.users = [];
    officeService.getOfficeUsers().then(function(users){
      ctrl.queryOptions.users = _.filter(users, 'is_active');
    });

    ctrl.receivedStatusData = poHelperService.received_status_data;
    ctrl.statusData = poHelperService.status_data;

    ctrl.deleteDialog = deleteDialog;
    ctrl.getTotalUnits = poHelperService.getTotalUnits;
    ctrl.navigateTo = navigateTo;
    ctrl.getFilters = getFilters;
    ctrl.dismissTutorial = dismissTutorial;
    ctrl.emailDialog = emailDialog;
    ctrl.updateFilter = updateFilter;
    ctrl.setActiveTag = setActiveTag;
    ctrl.gsdPurchaseOrder = gsdPurchaseOrder;
    ctrl.poHasBeenEmailed = poHasBeenEmailed;
    ctrl.handleTagClick = handleTagClick;
    ctrl.handleSearchQuery = handleSearchQuery;
    ctrl.handleDeleteClick = handleDeleteClick;
    ctrl.handleEmailClick = handleEmailClick;
    ctrl.shouldShowDelete = poHelperService.shouldShowDelete;

    init();

    return ctrl;

    function init () {
      if (!$rootScope.features.purchase_orders) {
        return;
      }

      const selected_tag = $state.params.active_search_tag;
      setActiveTag(selected_tag ? { value: selected_tag } : ctrl.search_tags[0]);

      updateTimePeriods();

      $scope.$on('purchase_order_print', function () {
        PurchaseOrderService.printList({
          'status': ctrl.statusFilter,
          'po_month': ctrl.filter.period,
          'created_by': ctrl.filter.user,
          'order_id': ctrl.filter.searchType === 'name' ? ctrl.filter.search : '',
          'supplier': ctrl.filter.searchType === 'supplier' ? ctrl.filter.search : '',
        }).catch(function (error) {
          errorService.uiErrorHandler(error);
        });
      });

      $scope.$on(`is-visible-${ctrl.FOOTER_ID}`, loadNextPage);

      $scope.$watch('listOrder', renderList);
      defineLocks();
      initApprovalState();

    }

    /**
     * @method gsdPurchaseOrder
     * @description open modal for help with the section
     */
    function gsdPurchaseOrder() {
      $mdDialog.show({
        controller: 'poGettingStartedController',
        controllerAs: 'poGsdCtrl',
        templateUrl: 'templates/marketplace/purchase-orders/modals/po-getting-started.html',
        parent: angular.element(document.body),
        clickOutsideToClose: true,
        bindToController: true
      })
      .then(function (answer) {

        load_video_info(answer);
      });
    }

    /**
     * @method load_video_info
     * @param videoId
     */
    function load_video_info(videoId) {
      sowVimeoService
        .getVideo(videoId)
        .then(function (video) {
          ctrl.video = {
            'id': video.id,
            'title': $scope.videoTitle || video.title,
            'thumbnail': video.thumbnail_large,
            'url': "https://player.vimeo.com/video/{0}?title=0&byline=0&portrait=0".format(video.id),
          };
          ctrl.video['src'] = $sce.trustAsResourceUrl(ctrl.video['url']);
          ctrl.state = "loaded";
          // angular.element('#video_toggle').triggerHandler('click');

        })
        .catch(function (error) {
          ctrl.state = "not loaded";
          errorService.uiErrorHandler(error);
        });
    }


    function renderList () {
      // Terminate any currently running renderers
      if (ctrl.currentRenderer) {
        $timeout.cancel(ctrl.currentRenderer);
      }

      var rawData = ctrl._poData;
      var processedData = rawData.map(poHelperService.parsePurchaseOrder);
      processedData = $filter('orderBy')(processedData, $scope.listOrder);
      if (ctrl.list_limit) {
        processedData = _.slice(processedData, 0, ctrl.list_limit);
      }
      ctrl.poData = processedData;
      // var offset = 0;
      // ctrl.poData = [];
      // stepRenderer();

      // function stepRenderer () {
      //   var step = (offset === 0 ? 20 : 3);
      //   Array.prototype.push.apply(ctrl.poData, $filter('limitTo')(processedData, step, offset));
      //   offset += step;

      //   if (processedData && offset < processedData.length) {
      //     ctrl.currentRenderer = $timeout(stepRenderer, 300);
      //   }
      // }
    }

    function updateFilter () {
      renderList();
    }

    function updateFilterFromAPI () {
      poSecCtrl.isLoading();

      PurchaseOrderService.getAllByStatus(ctrl.statusFilter).then(function(data) {
        ctrl._poData = _.values(data); // convert to an array
        renderList();
      }).catch(function (error) {
        errorService.uiErrorHandler(error, 0);
      })['finally'](function () {
        poSecCtrl.doneLoading();
      });
    }

    function fetchFromPaginateAPI () {
        poSecCtrl.isLoading();

        PurchaseOrderService.getAllByPage(1, ctrl.list_limit).then(function(data) {
          ctrl._poData = _.values(data); // convert to an array
          renderList();
        }).catch(function (error) {
          errorService.uiErrorHandler(error, 0);
        })['finally'](function () {
          poSecCtrl.doneLoading();
        });
    }

    function deleteDialog(po){
      return poHelperService.deletePoDialog(po).then(function(result){
        var position = ctrl.poData.indexOf(po);
        if(position > -1){
          ctrl.poData.splice(position, 1);
        }
        updateTimePeriods();
      });
    }

    function navigateTo(po){
      PurchaseOrderService.updateLastStatusFilter({'filter':ctrl.filter, 'active_search_tag': ctrl.active_search_tag});
      if(po.status === "Draft"){
        $state.go('app.orders.purchase.draft',{poId: po.id});
      }else{
        $state.go('app.orders.purchase.active',{poId: po.id});
      }
    }

    function getFilters(){
      var filterObj = {};
      ctrl.list_limit = null;

      if(ctrl.filter.period && ctrl.filter.period !== "all"){
        filterObj.month = ctrl.filter.period;
      }
      if(ctrl.filter.user && ctrl.filter.user !== "all"){
        filterObj.created_by = {email: ctrl.filter.user};
      }
      if(ctrl.filter.search){
        if(ctrl.filter.searchType === "supplier"){
          filterObj.supplier = {name : ctrl.filter.search};
        }else{
          filterObj[ctrl.filter.searchType] = ctrl.filter.search;
        }
      }
      if (ctrl.active_search_tag && ctrl.active_search_tag !== 'all') {
        if ( ctrl.active_search_tag === 'twenty' ) {
          ctrl.list_limit = 20;
        } else {
          ctrl.list_limit = null;
          filterObj.status = ctrl.active_search_tag;
        }
      }

      return filterObj;
    }

    function setActiveTag(tag) {
      const tag_updated = ctrl.active_search_tag !== tag.value;
      if (tag_updated) {
        poSecCtrl.isLoading();
        ctrl._poData = [];
        ctrl.current_page = 0;
        ctrl.all_orders_fetched = false;
        ctrl.active_search_tag = tag.value;
        loadNextPage();
      }
    }

    function loadNextPage() {
      if (ctrl.all_orders_fetched || ctrl.loading_next_page) {
        return null;
      }
      ctrl.loading_next_page = true;
      const status = ctrl.active_search_tag;
      const page_num = ctrl.current_page + 1;
      const query_term = ctrl.filter.search;
      const search_param = 'all';
      const method = query_term
        ? PurchaseOrderService.getPageByQuery(status, page_num, query_term, search_param)
        : PurchaseOrderService.getPageByStatus(status, page_num);
      method
        .then(data => {
          ctrl.applied_query = query_term;
          ctrl.applied_param = ctrl.filter.searchType;
          ctrl.current_page = page_num;
          const new_orders = _.values(data);
          if (new_orders.length < 20) {
            ctrl.all_orders_fetched = true;
          }
          ctrl._poData = [...ctrl._poData, ...new_orders];
          renderList();
        }).catch(error => {
          errorService.uiErrorHandler(error, 0);
        }).finally(() => {
          ctrl.loading_next_page = false;
          poSecCtrl.doneLoading();
        });
    }

    function fetchPendingApprovalCount() {
      let pending_approval_count = 0;
      sowApprovalService.getPendingCount()
        .then(res => pending_approval_count = res || 0)
        .catch(err => errorService.uiErrorHandler(err))
        .finally(() => ctrl.pending_approval_count = pending_approval_count);
    }

    function generatePeriodData(period){
      var newDate = new Date(period.month+"-01");
      var dateValues = newDate.toUTCString().split(" ");
      var newLabel = dateValues[2] + " " + dateValues[3];

      period.date = period.date || newDate;
      period.label = period.label || newLabel;
      return period;
    }

    function dismissTutorial(){
      sessionService.updateFlag('seen_po_video_tutorial', true);
    }

    function updateTimePeriods(){
      PurchaseOrderService.getMonths().then(function(months){
        ctrl.queryOptions.periods = [];
        if(ctrl.statusFilter){
          _.forEach(months, function(period){

            var filter = ctrl.statusFilter.substr(0,1).toUpperCase() + ctrl.statusFilter.substr(1);
            if(period.counts[filter] > 0){
              ctrl.queryOptions.periods.push(generatePeriodData(period));
            }

          });
        }else{
          ctrl.queryOptions.periods = _.map(months, function(period){
            return generatePeriodData(period);
          });
        }
      });
    }

    function emailDialog(po){
      poHelperService.emailPoDialog(po);
    }

    function defineLocks () {
      ctrl.disabled_add_new = AccessService.getProperty("purchase_orders.disable_add_new");
      ctrl.disabled_edit = AccessService.getProperty("purchase_orders.disable_edit");
      ctrl.disabled_delete = AccessService.getProperty("purchase_orders.disable_delete");
      ctrl.disabled_edit_active = AccessService.getProperty("purchase_orders.disable_edit_active");
      ctrl.disabled_delete_active = AccessService.getProperty("purchase_orders.disable_delete_active");
      ctrl.po_approvals_enabled = AccessService.getProperty("purchase_orders.approval_req");
    }

    function initApprovalState() {
      if (ctrl.po_approvals_enabled) {
        fetchPendingApprovalCount();
        const pending_filter = {
          name: 'PO.FILTERS.PENDING',
          value: 'Pending',
          active: false,
        }
        ctrl.search_tags.splice(1, 0, pending_filter);
      }
    }

    /** 
     * Determines whether to show the mark_email_read icon
     * next to a particular purchase order in the list.
     * The icon should only be displayed next to orders
     * which have already been emailed at least once.
     * 
     * @param {Object} po 
     * 
     * @return {Boolean} 
    */
    function poHasBeenEmailed(po) {
      return _.get(po, 'last_emailed_event_id') ? true : false;
    }

    function handleTagClick(tag) {
      sowAnalyticsService.purchaseOrderFilterClicked(tag);
      setActiveTag(tag);
    }

    function handleSearchQuery(e = {}) {
      if (e.preventDefault) {
        e.preventDefault();
      }
      const query_updated = ctrl.applied_query !== ctrl.filter.search;
      const param_updated = ctrl.applied_param !== ctrl.filter.searchType;
      if (query_updated || param_updated) {
        sowAnalyticsService.purchaseOrderSearchQuery(ctrl.filter.search);
        ctrl._poData = [];
        ctrl.current_page = 0;
        ctrl.all_orders_fetched = false;
        loadNextPage();
      }
    }

    function handleDeleteClick(po, $event) {
      $event.stopPropagation();
      sowAnalyticsService.purchaseOrderDeleteClicked();
      deleteDialog(po);
    }

    function handleEmailClick(po, $event) {
      $event.stopPropagation();
      sowAnalyticsService.purchaseOrderEmailClicked();
      poHelperService.emailPoDialog(po);
    }

  }
}());

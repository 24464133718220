angular.module('app.marketplace.system')
  .service('RouteService', function ($rootScope, $log, $q, $state, $stateParams, $mdDialog, $window, sessionService) {
    'use strict';

    var _this = this;
    var membershipTypes = ['free', 'premium'];

    //
    // Return a boolean describing whether or not the state is a registration
    // state.
    //
    _this.isInRegistration = function (state) {
      state = state || $state.current;
      return state.name.match(/^app\.registration/);
    };

    //
    // Returns true if the current state is a state that should be redirected
    // _away_ from when a user signs in.
    //
    function shouldRedirectOnSignIn(state) {
      if (arguments.length < 1) {
        state = $state.current;
      }

      return state.name === 'app.password_reset';
    }
    _this.shouldRedirectOnSignIn = shouldRedirectOnSignIn;

    //
    // A quick way to determine if membershipType is at or above the 'level' of
    // targetType. E.g. 'free' is below 'premium'.
    //
    var _membershipAtOrAbove = function (membershipType, targetType) {
      return membershipTypes.indexOf(membershipType) >= membershipTypes.indexOf(targetType);
    };

    //
    // Return a boolean based on a flag, or a value comparison to the value of
    // the flag.
    //
    var _hasFlag = function (state, flag, value) {
      if (arguments.length < 3) {
        return !!(state && state.data && state.data[flag]);
      } else {
        return !!(state && state.data && state.data[flag] === value);
      }
    };

    //
    // Can we use this state as a fallback? Will the terminating the current
    // state transition leave us in a good state?
    //
    var _canFallback = function (state) {
      return state && !state.abstract;
    };

    //
    // Convenience function to check if the user is logged in.
    //
    var _isLoggedIn = function () {
      return !!$rootScope.current_account;
    };

    //
    // Redirect to Default Route if on the Landing Page
    //
    _this.redirectToDefault = function () {
      if ($state.current.name === 'app.landing') {
        _this.goToDefault();
      }
    };

    //
    // Transition to the default route.
    //
    _this.goToDefault = $rootScope.goToDefault = function (isLoggedIn) {
      if (arguments.length < 1) {
        isLoggedIn = _isLoggedIn();
      }

      if (isLoggedIn) {
        sessionService.onInit()
          .then(function (session_data) {
            // for some reason, session data is not complete when this is used on login, 
            // so I've changed it to use rootScope instead
            var has_mkt_access = _.get($rootScope, 'features.marketplace');
            var has_dashboard_access = _.get($rootScope, 'features.dashboard');
            var account_type = _.get($rootScope, "current_membership.office.account_type");

            // New(est) redirection rule: free account offices get sent to the marketplace,
            // paid offices go to dashboard and all others go to SDS 
            if ($stateParams.toState && !$stateParams.toState.includes('app.dashboard')) {
              const officeId = _.get($rootScope, 'current_membership.office.id');

              // When lambda redirects to registration and user creates an office.
              // we need to append the officeId to the toParams so that we can redirect to the correct page.
              if (officeId && $state.current.name === 'app.registration.create_office') {
                // toParams is a stringified object so we need to parse it first
                $stateParams.toParams = JSON.stringify({
                  ...JSON.parse($stateParams.toParams),
                  officeId,
                });
              }
              $state.go($stateParams.toState, $stateParams.toParams ? JSON.parse($stateParams.toParams) : null);
            } else if (account_type === "Free" && has_mkt_access) {
              return $state.go('app.mkt');
            } else if (has_dashboard_access) {
              return $state.go('app.dashboard');
            } else {
              return $state.go('app.sdslist');
            }

          });
      } else {
        // not logged in
        $state.go('login');
      }
    };

    _this.exitApp = $rootScope.exitApp = function exitApp() {
      $log.debug('RouteService: Sending to Landing Page.');
      // prevent redirects on new subdomain
      var destination = '/';
      var current_url = window.location.toString();
      if (current_url.includes('app.sowingo.com')) {
        $log.info('re-routing from app.sowingo to sowingo.com');
        destination = 'https://sowingo.com';
      }
      window.location.href = destination;
    };

    //
    // Pop up the "Not Authorized for this Route" modal.
    //
    _this.showNotAuthorizedModal = function () {
      return $mdDialog.show({
        parent: document.body,
        clickOutsideToClose: false,
        escapeToClose: false,
        controller: 'NotAuthorizedModalCtrl',
        templateUrl: 'templates/marketplace/system/modals/not-authorized.html'
      });
    };

    //
    // Handle the 'restricted_to_membership_type' flag.
    //
    _this.handleMembershipTypeRestriction = function (event, toState, fromState, membership) {
      var flag;
      var flagKey = 'restricted_to_membership_type';
      var membershipType;

      if (arguments.length < 4) {
        membership = $rootScope.current_membership;
      }

      if (!_hasFlag(toState, flagKey)) {
        return $q.when(null);
      }

      $log.debug('RouteService.handleMembershipTypeRestriction: _hasFlag(%o, %o) = %o', toState, flagKey, _hasFlag(toState, flagKey));

      if (checkNested(membership, 'office', 'account_type')) {
        flag = toState.data[flagKey];
        membershipType = membership.office.account_type.toLowerCase();
      } else {
        return _this.showNotAuthorizedModal();
      }

      $log.debug('RouteService.handleMembershipTypeRestriction: _membershipAtOrAbove(%o, %o) = %o', membershipType, flag, _membershipAtOrAbove(membershipType, flag));

      return _membershipAtOrAbove(membershipType, flag) ? $q.when(null) : _this.showNotAuthorizedModal();
    };

    //
    // If a route has 'redirect_logged_in' set to true in the data, then we
    // redirect away from that route to the default route when the user is logged
    // in.
    //
    // If a route has 'redirect_logged_out' set to true in the data, then we
    // redirect away from that route to the default route when the user is logged
    // out.
    //
    _this.handleLoggedInRedirect = function (event, toState, fromState, isLoggedIn) {
      if (arguments.length < 4) {
        isLoggedIn = _isLoggedIn();
      }

      // if (toState.name === 'app.registration.start' && isLoggedIn) {
      //   _this.goToDefault();
      // }

      if (_this.isInRegistration(toState)) {
        // TODO We need a better solution here.
        return;
      }

      if (isLoggedIn && _hasFlag(toState, 'redirect_logged_in')) {
        if (_canFallback(fromState) && !_hasFlag(fromState, 'redirect_logged_in')) {
          event.preventDefault();
        } else {
          event.preventDefault();
          _this.goToDefault();
        }
      }

      if (!isLoggedIn && _hasFlag(toState, 'redirect_logged_out')) {
        $log.debug('RouteService.handleLoggedInRedirect: fromState: %o', fromState);
        $log.debug('RouteService.handleLoggedInRedirect: State Has Flag: %o', _hasFlag(fromState, 'redirect_logged_out') ? 'yes' : 'no');
        $log.debug('RouteService.handleLoggedInRedirect: Can Fallback to fromState: %o', _canFallback(fromState) ? 'yes' : 'no');

        if (_canFallback(fromState) && !_hasFlag(fromState, 'redirect_logged_out')) {
          event.preventDefault();
        } else {
          event.preventDefault();
          _this.goToDefault();
        }
      }
    };

    _this.back = back;
    _this.reloadPage = reloadPage;
    _this.goTo = goTo;
    _this.redirectTo = redirectTo;

    return _this;

    // navigate to the previous state while keeping all the previous params (includes params that don't show up on URL)
    function back() {
      $state.go($rootScope.previousState, $rootScope.previousStateParams);
    }

    /**
     * Reloads the page, regardless of where the user has navigated
     */
    function reloadPage() {
      $state.reload();
    }

    /**
     * Updates the router state without reloading and/or triggering a route change
     * @param toParams - The parameters to update.
     */
    function updateState (toParams) {
      $state.transitionTo(
        $state.current,
        _.extend($stateParams, toParams),
        {
          notify: false,
          location: 'replace',
        }
      );
    }

    /**
     * Redirects the user to the specified URL
     * @param {string} url - The URL to redirect to.
     */
    function redirectTo (url) {
      $window.location.href = url;
      $window.location.reload();
    }

    /**
     * It takes two parameters, the first is the state you want to go to, the second is the parameters
     * you want to pass to that state
     * @param {string} toState - The name of the state to go to.
     * @param {object} toParams - Parameters to be sent to the state, if any.
     * @param {boolean} extendParams - Determines whether to extend the current params (true) or replace them (false).
     */
    function goTo (toState, toParams, extendParams = false) {
      const destination_params = extendParams ? _.extend($state.params, toParams) : toParams;
      switch (toState) {
        // needed because it takes into account the previous params as well
        case 'back':
          back();
          break;
        // reloads state, not the whole document
        case 'reload':
          reloadPage();
          break;
        // go to sowingo home page: dashboard/marketplace/login
        case 'home':
          this.goToDefault();
          break;
        // used for updating params only, while staying in the same route
        // eg. navigating between tabs
        case '':
        case null:
        case undefined:
        case $state.current:
          if (destination_params) {
            updateState(destination_params);
          }
          break;
        // default navigation with valid route & optional params
        default:
          if (extendParams) {
            $state.transitionTo(toState, destination_params);
          } else {
            $state.go(toState, destination_params);
          };
          break;
      }
    }

  });

(function () {
  'use strict';

  angular
    .module('sowSubscriptionBilling', [])
    .controller('sowSubscriptionBillingController', sowSubscriptionBillingController);

  function sowSubscriptionBillingController ($scope, $window, $timeout, $filter, $state, elementService, errorService, sgToast, minTimeout, receipts, current_subscription, failed_payment, credit_cards, sowAnalyticsService) {
    /*jshint validthis: true */
    var ctrl = this;

    ctrl.openReceipt = openReceipt;
    ctrl.retryPayment = retryPayment;
    ctrl.saveDefaultSource = saveDefaultSource;
    ctrl.init = init;
    ctrl.handleOrdersClick = handleOrdersClick;

    $scope.goToPlans = goToPlans;

    ctrl.init();

    return ctrl;

    function init () {
      $scope.all_receipts = receipts;
      $scope.failed_payment = failed_payment;
      $scope.credit_cards = credit_cards;
      $scope.current_subscription = current_subscription;
      setCurrentCardToDefault();
      updateCycle();
      updateUpgradeFlags();

      $scope.$watch('currentCreditCard', currentCreditCardWatcher);
      $scope.$watch('current_office.trial_over', updateSubscriptionName);
      $scope.$watch('current_office.trial_ends_on', updateSubscriptionName);
      $scope.$watch('current_office.account_type', updateSubscriptionName);
      $scope.$watch('current_subscription.plan.name', updateSubscriptionName);
    }

    function goToPlans () {
      return $state.go('app.office.profile.subscription.view');
    }

    function updateUpgradeFlags () {
      var office = $scope.current_office;
      $scope.showUpgradeBtn = office.account_type === 'Free';
      $scope.showUpgradeText = office.account_type === 'Free';
    }

    function updateCycle () {
      var billingCycle = _.get(current_subscription, 'plan.billing_cycle');
      var isYearly = (billingCycle === 'per_year');
      var isMonthly = (billingCycle === 'per_month');
      var isComplementary = _.get($scope, 'current_office.is_complementary_account', false);

      $scope.subscriptionCycle = (
        isYearly ? 'TIME.YEARLY'
        : isMonthly ? 'TIME.MONTHLY'
        : billingCycle ? 'Not Applicable'
        : isComplementary ? 'Complementary'
        : 'None'
      );
      $scope.planName = _.get(current_subscription, 'plan.name', '').toLowerCase().replace(' ', '_');
      $scope.showYearlyUpgradePrompt = isMonthly;
      $scope.showUpgradePrompt = !billingCycle && !isComplementary;
    }

    function updateSubscriptionName () {
      var office = $scope.current_office;
      var accountType = office.account_type;
      var t_free = $filter('translate')('COMMON.PLAN_FREE');
      var t_premium = $filter('translate')('COMMON.PLAN_PREMIUM');
      var t_premium_plus = $filter('translate')('COMMON.PLAN_PREMIUM_PLUS');
      var t_professional = $filter('translate')('COMMON.PLAN_PROFESSIONAL');
      // SPD-3816 var t_trial = $filter('translate')('COMMON.TRIAL');
      var baseNameMap = {
        'Premium Plus': t_premium_plus,
        'Premium': t_premium,
        'Free': t_free,
        'Professional': t_professional
      };

      $scope.subscriptionName = baseNameMap[accountType] || accountType;

      /* SPD-3816
      if (!office.UI.no_trial) {
        $scope.subscriptionCondition = ' ('+t_trial+')';
      } else
      */
      if (office.is_complementary_account) {
        $scope.subscriptionCondition = ' (Complementary)';
      }
    }

    function setCurrentCardToDefault (new_default) {
      if (!$scope.current_subscription || !$scope.current_subscription.default_source) {
        return false;
      }

      new_default = new_default || $scope.current_subscription.default_source;

      if(new_default.id === _.get($scope, 'currentCreditCard.id')){
        return;
      }
      
      _.forEach($scope.credit_cards, function (credit_card) {
        if (credit_card.id === new_default.id) {
          $scope.currentCreditCard = credit_card;
        }
      });
    }

    function saveDefaultSource () {
      if ($scope.currentCreditCard.id !== $scope.current_subscription.default_source) {
        $scope.updatingDefaultSource = minTimeout.start(1500);
      }

      return elementService
        .callEndpoint('office', {
          'endpoint': 'set_default_credit_card',
          'credit_card': $scope.currentCreditCard,
        })
        .then(function (response) {
          if (!response) {
            throw '';
          } else {
            $scope.current_subscription.default_source = $scope.currentCreditCard.id;
            var t_message = $filter('translate')('OFFICE.BILLING.CARD_UPDATED');
            sgToast.showSimple(t_message);
          }
        })
        .catch(function (error) {
          var t_message = $filter('translate')('ERRORS.DEFAULT_CARD_UPDATE');
          errorService.uiErrorHandler(error || t_message, 0);
        })
        .finally(function () {
          $scope.updatingDefaultSource
            .end()
            .then(function () {
              $scope.updatingDefaultSource = false;
            });
        });
    }

    function currentCreditCardWatcher (newValue, oldValue) {
      const old_id = _.get(oldValue, 'id');
      const new_id = _.get(newValue, 'id');
      if (!_.isNil(oldValue) && (new_id !== old_id)) {
        saveDefaultSource();
      }
    }

    function openReceipt (receipt) {
      var url = $state.href('app.office.profile.subscription_receipt', {receiptId: receipt.id});
      $window.open(url, '_blank');
    }

    function retryPayment (payment) {
      if (!$scope.failed_payment || $scope.retryingPayment) {
        return false;
      }

      $scope.retryingPayment = true;

      return elementService.callEndpoint('subscription', {
        'endpoint': 'retry_payment',
        'failed_payment_id': payment.id,
        'stripe_card': $scope.currentCreditCard.id,
      })
      .then(function (response) {
        $scope.retrySucceeded = true;
        $timeout(function () {
          $scope.failed_payment = null;
          $scope.retrySucceeded = false;
        }, 2000);
      })
      .catch(function () {
        $scope.retryFailed = true;
        $timeout(function () {
          $scope.retryFailed = false;
        }, 2000);
      })
      .finally(function () {
        $scope.retryingPayment = false;
      });
    }

    function handleOrdersClick() {
      sowAnalyticsService.billingOrdersLinkClicked();
    }

  }
}());

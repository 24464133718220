angular.module("app.shared.support")
  .filter('highlightWord', function () {
    return function (text, selectedWord) {
      if (selectedWord) {
        var pattern = new RegExp(selectedWord, "g");
        return text.replace(pattern, '<span class="highlighted">' + selectedWord + '</span>');
      }
      else {
        return text;
      }
    };
  });
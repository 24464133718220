(function () {
  'use strict';

  angular.module('app.marketplace.elements')
    .run(membershipServiceRunner);

  function membershipServiceRunner(membershipService) {
    membershipService.init();
  }

}());

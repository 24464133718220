(function () {
  'use strict';

  angular.module('sowInventory')
  .directive('sowIiTrackingForm', sowInventoryItemTrackingFormDirective)
  .controller('sowInventoryItemTrackingFormController', sowInventoryItemTrackingFormController);

  function sowInventoryItemTrackingFormDirective () {
    return {
      restrict: 'E',
      replace: true,
      templateUrl: 'sow-inventory/directives/item-tracking-form.html',
      controller: 'sowInventoryItemTrackingFormController',
      controllerAs: 'iitfCtrl',
      scope: {
        'item': '=',
      },
    };
  }

  function sowInventoryItemTrackingFormController ($scope, $mdDialog, membershipService) {
    var ctrl = this;

    ctrl.allowTrackingByCase = allowTrackingByCase;
    ctrl.clearBarCode = clearBarCode;
    ctrl.showScanAndLink = showScanAndLink;
    ctrl.$onInit = () => {
      defineLocks();
    }
    
    return ctrl;

    function clearBarCode() {
      $scope.item.code = null;
    }

    function showScanAndLink() {
      $mdDialog.show({
        template: '<inventory-scan-link context="' + $scope.item.UI.context + '"></inventory-scan-link>',
        parent: angular.element(document.body),
        clickOutsideToClose:true
      });
    }

    function allowTrackingByCase() {
      return !$scope.item.UI.is_medication && $scope.item.buy_by_case;
    }

    function defineLocks() {
      const membership = membershipService.get();

      const path = $scope.item.UI.is_medication ? 'medications' : 'gen_inv';

      const edit_min_level_condition = _.get(membership, `user_properties.${path}.disable_min_level`, false);
      ctrl.disabled_edit_minimum_level = edit_min_level_condition;

      const edit_desired_level_condition = _.get(membership, `user_properties.${path}.disable_desired_level`, false);
      ctrl.disabled_edit_desired_level = edit_desired_level_condition;
    }
  }
}());

(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.implants')
    .controller('impManufacturersController', impManufacturersController);

  function impManufacturersController ($q, $scope, $rootScope, $state, $filter, sowImplantService, $mdDialog, sgToast, SupplierService, errorService, AccessService) {
    /*jshint validthis: true */
    var ctrl = this;
    ctrl.on_hand_values = {implants:0,caps:0,healing:0,money:0};
    ctrl.manufacturers = [];
    ctrl.impSecCtrl = $scope.impSecCtrl;

    ctrl.filter_by = filter_by;
    ctrl.removeManufacturer = removeManufacturer;
    ctrl.editSalesRep = editSalesRep;
    ctrl.addSalesRep = addSalesRep;
    ctrl.goTo = goTo;
    ctrl.addNewManufacturer = addNewManufacturer;

    ctrl.isCurrentBundle = isCurrentBundle;
    ctrl.openBundle = openBundle;
    ctrl.closeBundle = closeBundle;

    ctrl.openModal = openModal;
    ctrl.applySearchQuery = applySearchQuery;
    ctrl.clearSearchQuery = clearSearchQuery;

    const t_implant_companies = $filter('translate')('IMPLANTS.COMPANIES');
    const t_existing_implants = $filter('translate')('IMPLANTS.EXISTING_IMPLANTS');
    const t_available_companies = $filter('translate')('IMPLANTS.AVAILABLE_COMPANIES');

    init();

    return ctrl;

    function init () {
      if (!$rootScope.features.implants) {
        return;
      }
      ctrl.show_gsd =
        sow.officeFeatures().aaoms_marketplace ||
        sow.officeInfo().association_membership.professional_association_id ==='dentsply' ||
        sow.officeInfo().association_membership.professional_association_id ==='neodent';

      defaults();
      loadData();
      defineLocks();
    }

    function filter_by(item) {
      var search = ctrl.search ? ctrl.search.toLowerCase() : '';

      // We need to call .toLowerCase() on the name or sales_rep_name
      // so it's important to use the logical OR operator to reassign
      // these values to an empty string if they're falsy (since calling
      // it on null, undefined, etc. will throw an error)
      var item_name = _.get(item, 'name') || '';
      var sales_rep_name = _.get(item, 'sales_rep.name') || '';

      // Then we return items which include the search query in either field
      return item_name.toLowerCase().indexOf(search) > -1 ||
        sales_rep_name.toLowerCase().indexOf(search)  > -1;
    }

    function loadData () {
      ctrl.impSecCtrl.isLoading();
      sowImplantService.getManufacturers()
      .then(function(manuf_response){
        _.forEach(manuf_response, function(manufacturer){
          if(manufacturer.show_in_list){
            var sales_reps = _.get(manufacturer, 'sales_reps', []);
            var primary_rep = _.find(sales_reps, function(rep) {
              return rep.is_primary;
            });
            manufacturer.sales_rep = primary_rep || _.get(manufacturer, 'sales_reps[0]', null);
            ctrl.manufacturers.push(manufacturer);
          }
        });
        if (ctrl.manufacturers.length === 0) {
          $state.go("app.implants.getstarted",{step:1});
        } else {
          parseManufacturersData();
        }
        ctrl.impSecCtrl.doneLoading();
      })
      .catch(function(error){
        var t_message = $filter('translate')('ERRORS.MANUF_DATA_LOAD')
        errorService.uiErrorHandler(error.message || t_message);
      });
      // SupplierService.getAll()
      // .then(function(suppliers_response){
      //   ctrl.suppliers_list = suppliers_response;
      // })
      // .catch(function(error){
      //   var t_message = $filter('translate')('ERRORS.SUPPLIER_DATA_LOAD')
      //   errorService.uiErrorHandler(error.message || t_message);
      // });
    }
    function openModal(manufacturer, $event) {
      $event.preventDefault();
      $event.stopPropagation();
      $mdDialog.show({
        controller: DialogController,
        templateUrl: 'sow-inventory/slideouts/product-detail-pic.html',
        parent: angular.element(document.body),
        targetEvent: $event,
        clickOutsideToClose:true,
        bindToController:true,
        locals: {
          items: manufacturer
        }
      }).then(function(answer) {
        // $scope.status = 'You said the information was "' + answer + '".';
      }).catch(function() {
        // $scope.status = 'You cancelled the dialog.';
      });
    }
    function editSalesRep (manufacturer, $event) {
      $event.preventDefault();
      $event.stopPropagation();

      return getSupplierCounterpart(manufacturer)
      .then(function(equal_supplier){
        var sales_rep = _.extend({},manufacturer.sales_rep, {supplier: equal_supplier });

        return $mdDialog.show({
          controller: 'officeRepModalController',
          controllerAs: 'dialogCtrl',
          templateUrl: 'templates/marketplace/office/modals/office-rep.html',
          parent: angular.element(document.body),
          clickOutsideToClose:true,
          locals : {
            modalType : "edit",
            sales_rep : sales_rep,
            suppliers_list: [],
            hide_primary_checkbox: true,
          },
          bindToController: true,
        }).then(function(rep_data){
          SupplierService.createOrUpdateSalesRep(rep_data)
          .then(function(rep_response){
            manufacturer.sales_rep = rep_response;
            var t_message = $filter('translate')('TOAST.REP_DETAILS_SAVED');
            sgToast.showSimple(t_message);
          })
          .catch(function(error){
            var t_message = $filter('translate')('ERRORS.SALES_REP_EDIT')
            errorService.uiErrorHandler(error.message || t_message);
          });
        });
      });
    }

    function getSupplierCounterpart (manufacturer) {
      var equal_supplier = _.get(manufacturer, 'supplier[0]');
      if (equal_supplier) {
        var def = $q.defer();
        def.resolve(equal_supplier);
        return def.promise;
      } else {
        return SupplierService.create({'name':manufacturer.name});
      }
    }

    function addSalesRep (manufacturer, $event) {
      $event.preventDefault();
      $event.stopPropagation();

      return getSupplierCounterpart(manufacturer)
      .then(function(equal_supplier){
        var sales_rep = _.extend({},manufacturer.sales_rep, {supplier: equal_supplier });
        return $mdDialog.show({
          controller: 'officeRepModalController',
          controllerAs: 'dialogCtrl',
          templateUrl: 'templates/marketplace/office/modals/office-rep.html',
          parent: angular.element(document.body),
          clickOutsideToClose:true,
          locals : {
            modalType : "edit",
            sales_rep : sales_rep,
            suppliers_list: [],
            hide_primary_checkbox: true,
          },
          bindToController: true,
        }).then(function(rep_data){
          if(rep_data.name || rep_data.email || rep_data.phone_number){

            if (equal_supplier) {
              rep_data.supplier = equal_supplier;
              SupplierService.createOrUpdateSalesRep(rep_data)
              .then(function(rep_response){
                manufacturer.sales_rep = rep_response;
                var t_message = $filter('translate')('TOAST.REP_DETAILS_SAVED');
                sgToast.showSimple(t_message);
              })
              .catch(function(error){
                var t_message = $filter('translate')('ERRORS.SALES_REP_SAVE')
                errorService.uiErrorHandler(error.message || t_message);
              });
            } else {
              console.warn('error finding supplier');
            }
          }
        });
      });
    }


    function isCurrentBundle (bundle) {
      return ctrl.current_bundle === bundle;
    }

    function openBundle (bundle) {
      ctrl.current_bundle = bundle;
    }

    function closeBundle (bundle) {
      if (ctrl.current_bundle === bundle) {
        ctrl.current_bundle = null;
      }
    }

    function goTo (manufacturer, cat_key) {
      var params = {mfr_id: manufacturer.id};
      if(cat_key){
        params.type = cat_key;
        if(cat_key === "Drill"){
          $state.go("app.implants.drills", params);
        } else {
          $state.go("app.implants.table", params);
        }
      }
    }

    function addNewManufacturer () {
      $state.go('app.implants.getstarted',{step:1,adding:true});
    }

    function removeManufacturer (manufacturer, $event) {
      $event.preventDefault();
      $event.stopPropagation();
      $mdDialog.show({
        controller: 'poDialogController',
        controllerAs: 'dialogCtrl',
        templateUrl: 'templates/marketplace/implants/modals/imp-mfr-delete-warning.html',
        parent: angular.element(document.body),
        clickOutsideToClose: false,
        locals: {
          manufacturer_name: manufacturer.name,
        },
        bindToController: true,
      })
      .then(function (answer) {
        if (answer) {
          sowImplantService.removeManufacturer(manufacturer)
          .then(function(response){
            var i = ctrl.manufacturers.indexOf(manufacturer);
            ctrl.manufacturers.splice(i,1);
            if(ctrl.manufacturers.length > 0){
              // success toast message
          var t_message = $filter('translate')('TOAST.MANUF_REMOVED');
              sgToast.showSimple(t_message);
              applySearchQuery();
            } else {
              // return to getstarted page
              $state.go('app.implants.getstarted',{step:1});
            }
          })
          .catch(function(error){
            var t_message = $filter('translate')('ERRORS.MANUF_DELETE')
            errorService.uiErrorHandler(error.message || t_message);
          });
        }
      });
    }

    function defaults () {
      ctrl.manufacturers = [];
      ctrl.search = '';
      ctrl.categories = {
        "Implant": {
          img: "styles/img/implants/implant-1.png",
          name: "Implants",
          tag: 'IMPLANTS.IMPLANTS',
          type: "implants"
        },
        "Closure Cap": {
          img: "styles/img/implants/cap-1.png",
          name: "Cover Screws",
          tag: 'IMPLANTS.COVER_SCREWS',
          type: "caps"
        },
        "Healing Abutment": {
          img: "styles/img/implants/healing-1.png",
          name: "Healing Abutments",
          tag: 'IMPLANTS.HEALING_ABUTMENTS',
          type: "healing"
        },
        "Bone Graft": {
          img: "styles/img/implants/bonegraft-1.png",
          name: "Bone Graft",
          tag: 'IMPLANTS.BONE_GRAFTS',
          type: "bonegraft"
        },
        "Membrane": {
          img: "styles/img/implants/membrane-1.png",
          name: "Membrane",
          tag: 'IMPLANTS.MEMBRANES',
          type: "membrane"
        },
        "Drills": {
          img: "styles/img/implants/drills-1.png",
          name: "Drills",
          tag: 'IMPLANTS.DRILLS',
          type: "drill"
        },
        "Final Abutment": {
          img: "styles/img/implants/final-abutment-icon.svg",
          name: "Final Abutments",
          tag: 'IMPLANTS.FINAL_ABUTMENTS',
          type: "final"
        },
        "Coping": {
          img: "styles/img/implants/coping-icon.svg",
          name: "Copings",
          tag: 'IMPLANTS.COPINGS',
          type: "coping"
        },
      };
    }

    function parseManufacturersData () {
      var products_order = Object.keys(ctrl.categories);

      _.forEach(ctrl.manufacturers, function(manufacturer){
        var fixed_category_array = [];
        _.forEach(Object.keys(manufacturer.categories), function(key){
          var new_object = _.extend({},manufacturer.categories[key], {sort_order: products_order.indexOf(key), name: key} );
          fixed_category_array.push( new_object );
          switch(key){
            case "Healing Abutment":
              ctrl.on_hand_values.healing += manufacturer.categories[key].item_count;
              break;
            case "Implant":
              ctrl.on_hand_values.implants += manufacturer.categories[key].item_count;
              break;
            case "Closure Cap":
              ctrl.on_hand_values.caps += manufacturer.categories[key].item_count;
              break;
          }
        });
        ctrl.on_hand_values.money += parseFloat(manufacturer.summary.total_value);
        manufacturer.categories = angular.copy(fixed_category_array);
      });
      applySearchQuery();
    }

    /**
     * Filters the manufacturers by a search query and sorts them by name, then
     * sets the controller property which will be used to display the sections
     * @param {object[]} manufacturers
     */
    function applySearchQuery(manufacturers = ctrl.manufacturers) {
      const filtered_manufacturers = manufacturers.filter(filter_by);
      const alphabetized_manufacturers = $filter('orderBy')(filtered_manufacturers, 'name');
      ctrl.display_sections = _generateDisplaySections(alphabetized_manufacturers);
    }

    /**
     * Creates the sections which will be displayed in the UI (if any)
     * @param {object[]} manufacturers
     * @return {object[]}
     */
    function _generateDisplaySections(manufacturers) {
      if (manufacturers.length === 0) {
        return [];
      }

      if (ctrl.disable_sort) {
        return [
          {
            manufacturers,
            title: t_implant_companies,
          },
        ];
      }

      return _generateSortedDisplaySections(manufacturers);
    }

    /**
     * Calls a method to sort the filtered manufacturers and generates the
     * display sections which reflect the resultant sorted lists
     * @param {object[]} manufacturers
     * @return {object[]}
     */
    function _generateSortedDisplaySections(manufacturers) {
      const {existing_implants, available_companies} = _sortManufacturersByOnHandValue(manufacturers);

      const display_sections = [];
      if (existing_implants.length > 0) {
        display_sections.push({
          manufacturers: existing_implants,
          title: t_existing_implants,
        });
      }
      if (available_companies.length > 0) {
        display_sections.push({
          manufacturers: available_companies,
          title: t_available_companies,
        });
      }
      return display_sections;
    }

    /**
     * Sorts the manufacturers into two groups: those with implants on hand and those without
     * @param {object[]} manufacturers
     * @return {object}
     */
    function _sortManufacturersByOnHandValue(manufacturers) {
      const existing_implants = [];
      const available_companies = [];
      for (const manufacturer of manufacturers) {
        if (manufacturer.summary?.product_count > 0) {
          existing_implants.push(manufacturer);
        } else {
          available_companies.push(manufacturer);
        }
      }
      return {existing_implants, available_companies};
    }

    /**
     * Clears the search query and then applies it (showing all manufacturers)
     */
    function clearSearchQuery() {
      ctrl.search = '';
      applySearchQuery();
    }

    function defineLocks () {
      ctrl.disabled_sales_rep_add_new = AccessService.getProperty("sales_reps.disable_add_new");
      ctrl.disabled_sales_rep_edit = AccessService.getProperty("sales_reps.disable_edit");
      ctrl.disabled_sales_rep_delete = AccessService.getProperty("sales_reps.disable_delete");

      ctrl.disable_sort = AccessService.getProperty("implants.disable_implant_manufacturer_filter");
    }
  }
}());

(function () {
  'use strict';

  angular
    .module('app.marketplace.elements')
    .service('accountService', accountService);

  /* @ngInject */
  function accountService ($rootScope, $q, $filter, apiTokenService, errorService, elementService, appEvents) {
    /*jshint validthis: true */
    var service = this;

    service.account = null;

    service.initialize = initialize;
    service.onInit = waitForAccount;
    service.get = get;
    service.create = create;
    service.setAccount = setAccount;
    service.isGuestUser = isGuestUser;
    service.waitForAccount = waitForAccount;
    service.resetPasswordWithToken = resetPasswordWithToken;
    service.validatePasswordResetToken = validatePasswordResetToken;
    service._resetPassword = resetPassword;
    service.getMembershipInvites = getMembershipInvites;
    service.acceptMembershipInvite = acceptMembershipInvite;
    service.declineMembershipInvite = declineMembershipInvite;

    return service;

    //
    // Return the current account
    //
    function get (async) {
      if(async){
        return waitForAccount();
      }else{
        return service.account;
      }
    }

    //
    //
    //
    function waitForAccount () {
      if (service.initialized) {
        return $q.when(service.account);
      } else {
        var deferred = $q.defer();

        var stopWatching = $rootScope.$watch(function () {
          return service.initialized;
        }, function (initialized) {
          if (initialized) {
            deferred.resolve(service.account);
            stopWatching();
          }
        });

        return deferred.promise;
      }
    }

    //
    // Is this a guest user?
    //
    function isGuestUser (account) {
      return account && account.email === 'guest_user@sowingo.com';
    }

    //
    // Set the Account
    //
    function setAccount (account, options) {
      options = options || {};

      service.account = account;
      $rootScope.current_account = account;

      if (!options.suppressEvent) {
        $rootScope.$broadcast(appEvents.setAccount, account);
      }
    }

    //
    // Clear The Session
    //
    function clearAccount () {
      service.account = null;
      $rootScope.welcomeBackFlag = false;
      $rootScope.current_account = null;
      $rootScope.$broadcast(appEvents.clearAccount);
    }

    //
    // Use a password reset token to reset the password
    //
    function resetPasswordWithToken (token, password, confirm_password) {
      return elementService.callEndpoint('account', {
        token: token,
        password: password,
        confirm_password: confirm_password,
        endpoint: 'reset_password_with_token'
      }).then(function (response) {
        return response.data;
      }).catch(function (error) {
        if (error.key === 'password_strength') {
          errorService.uiErrorHandler(error.message);
          throw error.message;
        } else {
          throw 'Server Error';
        }
      });
    }

    //
    // Validate a Password Reset Token
    //
    function validatePasswordResetToken (token) {
      return elementService.callEndpoint('account', {
        token: token,
        endpoint: 'validate_password_reset_token'
      })
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        if (error.status === 404 && error.message === 'Invalid token.') {
          throw 'Invalid Token';
        } else {
          var t_message = $filter('translate')('ERRORS.SERVER');
          errorService.uiErrorHandler(t_message);
          throw 'Server Error';
        }
      });
    }

    function create (account) {
      return elementService.callEndpoint('account', {
        'endpoint': 'create_user',
        'account': account,
      });
    }

    //
    // Reset User's Password
    //
    function resetPassword (account) {
      return elementService.callEndpoint('account', {
        account: account,
        endpoint: 'reset_password'
      });
    }

    //
    // Setup Account from a Session
    //
    function setFromSession (session) {
      var user = _.get(session, 'user');

      //
      // If this is a guest, clear values
      //
      if (user && isGuestUser(user)) {
        // Note: This is a hack for now. In reality, guest users shouldn't end up with sessions.
        apiTokenService.clearToken();
        clearAccount();
      }
      //
      // If this is a user, set it up
      //
      else if (user && user.id) {
        var account = elementService.create('account', user);
        setAccount(account);
      }
    }

    //
    // Handle a New Session
    //
    function handleNewSession (event, session) {
      setFromSession(session);
    }

    //
    // Accept / Redeem a MembershipInvite
    //
    function acceptMembershipInvite (invite_id) {
      return elementService.callEndpoint('account', {
        'endpoint': 'accept_membership_invite',
        'user_id': service.account.id,
        'invite_id': invite_id,
      }).then(function (response) {
        return response.data;
      });
    }

    //
    // Decline a MembershipInvite
    //
    function declineMembershipInvite (invite_id) {
      return elementService.callEndpoint('account', {
        'endpoint': 'decline_membership_invite',
        'user_id': service.account.id,
        'invite_id': invite_id
      }).then(function (response) {
        return response.data;
      });
    }

    //
    // Get a list of Pending MembershipInvites
    //
    function getMembershipInvites () {
      var accountId = _.get(service, 'account.id');

      if (!accountId) {
        return $q.reject('no-account');
      }

      return elementService.callEndpoint('account', {
        'endpoint': 'membership_invites',
        'user_id': service.account.id,
      }).then(function (response) {
        return response.data;
      });
    }

    //
    // Initialize the Service
    //
    function initialize (session) {
      $rootScope.$on(appEvents.setSession, handleNewSession);
      $rootScope.$on(appEvents.clearSession, clearAccount);

      if (session) {
        setFromSession(session);
      }

      service.initialized = true;
    }
  }

}());

(function () {
  'use strict';
  angular.module('sowMarketplace')
  .controller('MarketplaceListController', MarketplaceListController);

  function MarketplaceListController(
    $scope, $filter, $rootScope, $state, $stateParams, $anchorScroll, $location, $window, 
    appEvents, mktHelperService, sowAlgoliaService, adButlerService, sowAnalyticsService, AccessService
    ) {
    /*jshint validthis: true */
    var ctrl = this;
    ctrl.PRODUCTS_PER_PAGE = 30;

    ctrl.page_number = 0;
    ctrl.loading = {
      'products': false,
      'next_page': false
    };
    ctrl.types = {
      'favourites': {
        'title': 'MARKETPLACE.TITLES.FAVOURITES',
        'custom_url': false,
        'extra_filters': true,
        'on_sale_filter': true,
        'pagination_widget': true,
        'pagination_scroll': false,
      },
      'frequently_purchased': {
        'title': 'MARKETPLACE.TITLES.FREQUENTLY_PURCHASED',
        'custom_url': "/marketplace_landing/frequently_purchased",
        'extra_filters': false,
        'on_sale_filter': true,
        'pagination_widget': true,
        'pagination_scroll': false,
      },
      'most_popular': {
        'title': 'MARKETPLACE.TITLES.MOST_POPULAR',
        'custom_url': "/marketplace_landing/most_popular_products",
        'extra_filters': false,
        'on_sale_filter': true,
        'pagination_widget': true,
        'pagination_scroll': false,
      },
      'promos': {
        'title': 'MARKETPLACE.TITLES.PROMOS',
        // 'custom_url': "/marketplace_landing/vendor_promotions",
        'custom_url': false,
        'extra_filters': false,
        'on_sale_filter': false,
        'pagination_widget': true,
        'pagination_scroll': false,
      }
    };
    ctrl.product_filters = {
      on_sale_filter: false,
      below_min_level_filter: false,
      in_inventory_filter: false,
    };
    ctrl.page_title = 'List';
    ctrl.pagination_widget = false;
    ctrl.filter_widgets = false;
    ctrl.pagination_scroll = false;
    ctrl.current_page = 0;
    ctrl.display_products = [];
    ctrl.first_page_index = 0;
    ctrl.first_page_loaded = false;
    ctrl.SRC_EMPTY_FAVS = $filter('translate')('IMAGES.MKT_EMPTY_FAVOURITES_CARDS');
    ctrl.SRC_EMPTY_BUY_AGAIN = $filter('translate')('IMAGES.MKT_EMPTY_BUY_AGAIN_CARDS');

    ctrl.getPageTitle = getPageTitle;
    ctrl.initAlgolia = initAlgolia;
    ctrl.getCurrentProductsLabel = getCurrentProductsLabel;

    ctrl.setProductVendorInfo = mktHelperService.setProductVendorInfo;
    
    ctrl.open = open;
    ctrl.toggleFilter = toggleFilter;
    ctrl.handleEvent = handleEvent;
    ctrl.handleSearchSubmit = handleSearchSubmit;
    ctrl.loadAd = loadAd;

    init();

    return ctrl;

    function init () {
      $scope.$on('$destroy', exit);
      $scope.$on('algolia_data', handleAlgoliaData);
      $scope.$on('algolia_loading', _handleAlgoliaLoading);
      $scope.$on(appEvents.mktProductUpdate, updateLocalProduct);
      $scope.$on('filters-ready', function () {
        initAlgolia();
      })

      initParams();
      getPageTitle();
      ctrl.loading.products = true;
      _setEmptyStates();
      _defineLocks();
    }


    /**
     * Loads an ad from AdButler, using the scaled version if the screen is less than 960px wide.
     * @param {string} ad_name - The name of the ad to be loaded.
     */
    function loadAd (ad_name) {
      if($window.innerWidth < 960) {
        ad_name+= '-scaled';
      }
      adButlerService.autoLoadZone(ad_name);
    }

    function exit () {
      $rootScope.algolia_data = null;
      if (ctrl.pagination_scroll) {
        scrollWatch(true);
      }
    }

    function scrollTo (id) {
      var newHash = id;
      if ($location.hash() !== newHash) {
        $location.hash(id);
      } else {
        $anchorScroll();
      }
    }

    function scrollToTop () {
      return scrollTo("#appContainer");
    }

    function initParams () {
      angular.extend(ctrl, _.pick($stateParams, ['page_type', 'search']));
      if ($stateParams.product_filters) {
        ctrl.product_filters = JSON.parse($stateParams.product_filters);
      }

      var current_type = _.get(ctrl, 'types['+ctrl.page_type+']', null);
      if (!current_type) return;
      // sets widget options
      angular.extend(ctrl, current_type);
    }

    function getPageTitle () {
      var key = _.get(ctrl, 'types['+ctrl.page_type+'].title', 'List');
      
      var title = $filter('translate')(key);
      ctrl.page_title = title.toString();

      return title;
    }

    function updateLocalProduct ($event, new_item) {
      const existing_item = _.find(ctrl.display_products, ['id',new_item.id]);
      _.extend(existing_item, _.pick(new_item, ['is_favourite_product']));

      // if the item is not favorited anymore, and we are on the favorites page, remove it
      if (ctrl.page_type === 'favourites' && !existing_item.is_favourite_product) {
        _.remove(ctrl.display_products, ['id',existing_item.id]);
        _setEmptyStates();
      }
    }

    function loadNextPage () {
      var next_btn = document.querySelector(".ais-pagination--item.ais-pagination--item__next .ais-pagination--link");
      if(next_btn && !ctrl.loading.next_page){
        next_btn.click();
      }
    }

    function scrollWatch (disable) {
      var container = document.getElementById("appContainer");
      container.onscroll = disable ? null : handleScroll;

      function handleScroll (event){
        var footer = document.querySelector("footer.site-footer");
        if(isInViewport(footer)){
          loadNextPage();
        }
      }
    }

    function isInViewport (elem) {
      var bounding = elem.getBoundingClientRect();
      return (
          bounding.top >= 0 &&
          bounding.left >= 0 &&
          bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
          bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
      );
    }

    function initAlgolia () {
      const options = {};

      if (ctrl.page_type === 'promos') {
        _.set($stateParams, 'sale_filter', 'sales_all');
      }
      const custom_url = _.get(ctrl, "types["+ctrl.page_type+"].custom_url", null);
      const ais = sowAlgoliaService.initAisInstance(options, false, custom_url);
      ais.start();
      if (ctrl.pagination_scroll) {
        scrollWatch();
      }
    }

    /**
     * Updates the state when Algolia starts or finishes loading
     * @param {object} _event
     * @param {boolean} is_loading
     */
    function _handleAlgoliaLoading (_event, is_loading){
      if (_.size(ctrl.display_products) > 0) {
        ctrl.loading.next_page = is_loading;
      } else {
        ctrl.loading.products = is_loading;
      }
      if(ctrl.loading.products && !ctrl.pagination_scroll){
        ctrl.display_products = [];
      }
      if (!is_loading) {
        _logPageView();
      }
    }

    /**
     * Logs a page view event to Google Analytics
     * @param {object[]} display_products
     * @param {number} current_page
     * @param {number} products_per_page
     * @param {boolean} pagination_scroll
     * @param {string} item_list_id
     * @param {string} item_list_name
     */
    function _logPageView(
      display_products = ctrl.display_products,
      current_page = _.get(ctrl, 'search_data.current_page', 0),
      products_per_page = ctrl.PRODUCTS_PER_PAGE,
      uses_pagination_scroll = ctrl.pagination_scroll,
      item_list_id = ctrl.page_type,
      item_list_name = ctrl.title,
    ) {
      let products = [...display_products];
      const start_of_page = products_per_page * current_page;
      // if this page uses infinite scroll pagination, it will have products
      // from all pages, so we need to slice off any previously logged products
      if (uses_pagination_scroll) {
        products = products.slice(start_of_page);
      }
      products = products.map((product, i) => {
        return {
          ...product,
          // add info of the lowest priced available vendor
          ..._.first(product.sorted_vendors),
          // add categories
          categories: [product.parent_category, product.subcategory],
          // add index which reflects its position on the current page (1-based, not 0-based)
          index: start_of_page + 1 + i,
        };
      });
      // current_page is a zero-based index, page_num starts at 1
      const page_num = current_page + 1;
      const params = { item_list_id, item_list_name, page_num };
      sowAnalyticsService.logViewItemList(products, params);
    }

    function handleAlgoliaData (event, search_response) {
      var hits = _.get(search_response, 'results[0].hits', []);
      if(!search_response || _.size(hits) === 0){
        _unsetLoadingStates();
        _setEmptyStates();
        return;
      }
      ctrl.search_data = search_response;

      if (!ctrl.first_page_loaded) {
        ctrl.first_page_index = search_response.current_page;
        ctrl.first_page_loaded = true;
      }

      var search_results = _.map(hits, ctrl.setProductVendorInfo);

      if(ctrl.pagination_scroll && search_response.current_page !== ctrl.first_page_index) {
        ctrl.display_products = _.concat(ctrl.display_products, search_results);
      } else {
        scrollToTop();
        // current_page is a zero-based index, page_number starts at 1
        ctrl.page_number = search_response.current_page + 1;
        ctrl.display_products = angular.copy(search_results);
      }
      _unsetLoadingStates();
      _setEmptyStates();
    }

    function _unsetLoadingStates () {
      ctrl.loading.products = false;
      ctrl.loading.next_page = false;
    }

    function open() {
      $rootScope.$broadcast('algolia-filters-open');
    }

    /** 
     * Returns `Showing {first}-{last} of {total_products} products`, factoring in pagination, filters, and results per page.
     * For example, "Showing 1-30 of 150 products".
     * 
     * @param {Object} search_data Algolia search response
     * @param {Array} display_products Products currently displayed in UI (after filters applied)
     * 
     * @return {String} 
    */
    function getCurrentProductsLabel (display_products, search_data) {
      display_products = display_products || ctrl.display_products;
      search_data = search_data || ctrl.search_data;
      var first = 1;
      var last = 0;
      var label = "";
      var on_display = _.size(display_products);

      if ( _.get(ctrl, 'search_data.current_page', 0) > 0 ) {
        first = (search_data.current_page * ctrl.PRODUCTS_PER_PAGE) + 1;
      }

      last = first + (on_display - 1);

      // when we have enough products for more than one page:
      if ( on_display && search_data.total_products > ctrl.PRODUCTS_PER_PAGE ) {
        label = $filter('translate')('MARKETPLACE.SEARCH.SHOWING', {
          'a': first, 
          'b': last, 
          'c': search_data.total_products
        });
        // when there are only enough products for one page:
      } else if (on_display) {
        label = $filter('translate')('MARKETPLACE.SEARCH.SHOWING', {
          'a': 1, 
          'b': search_data.total_products, 
          'c': search_data.total_products
        });
      } else {
        const t_products = $filter('translate')('COMMON.PRODUCTS');
        label = `0 ${t_products}`;
      }

      return label;
    }

    function getEnabledProductFilters (all_filters = ctrl.product_filters) {
      let enabled_filters = {};
      _.each(all_filters, (value, key) => {
        // send only the ones that are set to `true`
        if (value) {
          enabled_filters[key] = value;
        }
      });
      return enabled_filters;
    }

    /**
     * Toggles a filter on/off and triggers a data refresh from the API
     * @param {string} filter_name - The name of the filter that you want to toggle.
     */
    function toggleFilter (filter_name) {
      ctrl.product_filters[filter_name] = !ctrl.product_filters[filter_name];
      let enabled_filters = getEnabledProductFilters();
      $state.transitionTo($state.current, {
        ...$state.params,
        page: 1,
        product_filters: JSON.stringify(enabled_filters),
      });
    }

    function handleEvent({detail: {clickedBtn = '', searchTerm}}) {
      if (clickedBtn === 'filters_btn') open();
      else if (clickedBtn === 'on_sale_btn') toggleFilter('on_sale_filter');
      else if (clickedBtn === 'below_min_btn') toggleFilter('below_min_level_filter');
      else if (clickedBtn === 'in_inv_btn') toggleFilter('in_inventory_filter');
      else if (clickedBtn.startsWith('facet_filter:')) removeFacetFilter(clickedBtn.replace('facet_filter:', ''));
      else if (clickedBtn === 'clear_filters_btn') clearFacetFilters();
      else if (typeof searchTerm === 'string') handleSearchSubmit({detail: {searchTerm}});
    }


    /**
     * The function handles the submission of a search term and updates the state with the new search
     * term.
     */
    function handleSearchSubmit ({detail: {searchTerm}}) {
      $state.transitionTo($state.current, {...$state.params, search: searchTerm});
    }

    function removeFacetFilter(clicked_filter) {
      const facet_filters = $state.params.facet_filters?.split('--') ?? [];
      if (facet_filters.length < 2) {
        clearFacetFilters();
        return;
      }
      $state.transitionTo($state.current, {
        ...$state.params,
        facet_filters: facet_filters.filter(f => f !== clicked_filter).join('--'),
        page: 1,
      });
    }

    function clearFacetFilters() {
      $state.transitionTo($state.current, {..._.omit($state.params, 'facet_filters'), page: 1});
    }

    function _isEmptyFavourites () {
      const is_empty_list = _isEmptyList();
      const is_favourites = ctrl.page_type === 'favourites';
      const no_facets = _.isEmpty($stateParams.facet_filters);
      const no_filters = _.isEmpty(getEnabledProductFilters());
      const no_search = _.isEmpty(ctrl.search);

      return (no_filters && no_search && no_facets && is_empty_list && is_favourites);
    }

    function _isEmptyQuery () {
      const has_facets = !_.isEmpty($stateParams.facet_filters);
      const has_filters = !_.isEmpty(getEnabledProductFilters());
      const has_search = !_.isEmpty(ctrl.search);
      const is_empty_list = _isEmptyList();

      return (has_search || has_filters || has_facets) && is_empty_list;
    }

    function _isEmptyList () {
      const no_items = _.size(ctrl.display_products) === 0;
      const not_loading = !ctrl.loading.next_page && !ctrl.loading.products;
      const page_is_first = $stateParams.page === '1';

      return (no_items && not_loading && page_is_first);
    }

    function _setEmptyStates () {
      ctrl.show_empty_favourites = _isEmptyFavourites();
      ctrl.show_empty_query = _isEmptyQuery();
      ctrl.show_empty_list = _isEmptyList();
    }

    function _defineLocks () {
      ctrl.show_inventory_filters = AccessService.getOfficeFeatureFlag('inventory_full');
      ctrl.hide_on_sale_filter = AccessService.getProperty('disable_on_sale_filter');
    }

  }

}());

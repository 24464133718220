angular.module("app.shared.support")

  //Add jf-peak class after scroll reaches specified distance.
  .directive('jfPeak', function ($rootScope, $window) {
    var mainPage = angular.element(document);
    //Get document scroll position and compensate for angular material's messing with the body element
    var _getDocTop = function () {
      var docTop = mainPage.scrollTop();

      var bodyEle = angular.element('body');
      var bodyTop = bodyEle.css('top');
      var bodyPosition = bodyEle.css('position');

      if (bodyPosition === 'fixed') {
        docTop = -1 * parseInt(bodyTop, 10);
      }

      return docTop;
    };

    return {
      restrict: 'A',
      link: function (scope, elem, attrs, ctrl) {
        mainPage.on("scroll", function () {
          if (_getDocTop() >= parseInt(attrs.jfPeak, 10)) {
            elem.addClass('jf-peak');
          } else {
            elem.removeClass('jf-peak');
          }
        });
      }
    };

  });

(function() {
  'use strict';
  
  angular
  .module('sowBudget')
  .service('sowBudgetService', sowBudgetService);
  
  function sowBudgetService(apiUrl, serverAPI, errorService, officeService, $q) {
    /*jshint validthis: true */
    var service = this;
    
    service.getBudgetReport = getBudgetReport;
    service.updateBudget = updateBudget;
    service.createBudget = createBudget;
    service.createBudgetArray = createBudgetArray;
    service.generateYearRange = generateYearRange;
    
    return service;
    
    function getBudgetReport(yearFrom, yearTo) {
      var office = officeService.get();
      var office_id = _.get(office, 'id', null);
      if(!office_id){
        return $q.reject();
      }
      var url = '{0}/offices/{1}/reports/yearly?start_date={2}&end_date={3}'.format(apiUrl, office_id, yearFrom, yearTo);
      
      var options = {
        'method': 'GET'
      };
      
      return serverAPI
      .doAPICall(url, options)
      .then(function(response) {
        return response.data;
      })
      .catch(errorService.uiErrorHandler);
    }
    
    function updateBudget(data) {
      var office = officeService.get();
      var office_id = _.get(office, 'id', null);
      if(!office_id){
        return $q.reject();
      }
      var url = '{0}/offices/{1}/budget'.format(apiUrl, office_id);
      
      var options = {
        'method': 'PUT',
        'data':data
      };
      
      return serverAPI
      .doAPICall(url, options)
      .then(function(response) {
        return response.data;
      })
      .catch(errorService.uiErrorHandler);
    }
    function createBudget(data) {
      var office = officeService.get();
      var office_id = _.get(office, 'id', null);
      if(!office_id){
        return $q.reject();
      }
      var url = '{0}/offices/{1}/budget'.format(apiUrl, office_id);
      
      var options = {
        'method': 'POST',
        'data':data
      };
      
      return serverAPI
      .doAPICall(url, options)
      .then(function(response) {
        return response.data;
      })
      .catch(errorService.uiErrorHandler);
    }

    /**
     * Creates an array of budget data for a range of years.
     * api_budget_data Structure:
     * [
     *   {
     *     total_budget: number, // Total budget for the year
     *     monthly_budget: [
     *       { 
     *          budget: number, 
     *          month: string, // Month abbreviation (e.g. "Jan", "Feb", etc.)
     *       }, // Budget allocation per month
     *     ],
     *     year: number // The year for the budget
     *   }
     * ]
     *
     * Example:
     * [
     *   {
     *     total_budget: 500,
     *     monthly_budget: [
     *       { budget: 500, month: "Jan" },
     *       { budget: 0, month: "Feb" },
     *       { budget: 0, month: "Mar" },
     *       { budget: 0, month: "Apr" },
     *       { budget: 0, month: "May" },
     *       { budget: 0, month: "Jun" },
     *       { budget: 0, month: "Jul" },
     *       { budget: 0, month: "Aug" },
     *       { budget: 0, month: "Sep" },
     *       { budget: 0, month: "Oct" },
     *       { budget: 0, month: "Nov" },
     *       { budget: 0, month: "Dec" }
     *     ],
     *     year: 2018
     *   }
     * ]
     * 
     * 
     * Returns an array of budget data initialized for each year in the range.
     * first_year: 2020, last_year: 2022, if api_budget_data only has 2020, 2021,
     * then 2022 will be initialized with empty monthly_budget
     * [
     *  {
     *   year: 2020,
     *    monthly_budget: [
     *    { budget: 100, month: "Jan" },
     *      ...
     *    { budget: 200, month: "Dec" },
     *   ]
     *  },
     *  {
     *   year: 2021,
     *    monthly_budget: [
     *    { budget: 100, month: "Jan" },
     *      ...
     *    { budget: 200, month: "Dec" },
     *   ]
     *  },
     *  {
     *   year: 2022,
     *   monthly_budget: []
     *  },
     * ]
     * @param {Object} api_budget_data - The budget data retrieved from the API.
     * @param {number} first_year - The first year in the range.
     * @param {number} last_year - The last year in the range.
     * @returns {Array} An array of budget data initialized for each year in the range.
     */
    function createBudgetArray ({
      api_budget_data,
      first_year,
      last_year,
    }) {
      // first_year: 2020, last_year: 2022
      // years -> [2020, 2021, 2022]
      const years = generateYearRange({
        first_year,
        last_year,
      });

      return _mergeBudgetDataWithYears(years, api_budget_data);
    }

    /**
     * Initializes budget data for the given years.
     *
     * This function takes an array of years and returns an array of budget objects.
     * For each year, it checks if there is an existing budget in the `api_budget_data`.
     * If a budget is found, it returns the existing budget. Otherwise, it creates
     * and returns an empty budget object for that year.
     *
     * @param {number[]} years - An array of years for which to initialize budget data.
     * @param {Object[]} api_budget_data - An array of budget objects from the API.
     * @returns {Object[]} An array of budget objects, each containing a `year` and `monthly_budget`.
     */
    function _mergeBudgetDataWithYears(years, api_budget_data) {
      return years.map(year => {
        const budget = findBudgetByYear(api_budget_data, year);

        // If the budget for the year is defined, return it
        if (budget) {
          return budget;
        }

        // If no budget exists for this year, return a default budget entry to maintain continuity
        // this is required to ensure the UI can display the year even if there is no budget data
        return {
          year,
          monthly_budget: []
        };
      });
    }

    /**
     * Finds a budget item by year from the provided API budget data.
     *
     * @param {Array} api_budget_data - The array of budget data objects.
     * @param {number} year - The year to find the budget item for.
     * @returns {Object|undefined} The budget item for the specified year, or undefined if not found.
     */
    function findBudgetByYear(api_budget_data, year) {
      return api_budget_data?.find(item => item.year === year);
    }

    /**
     * Generates an array of years from first_year to last_year, inclusive.
     * _generateYearRange({first_year: 2019, last_year: 2021}) => [2019, 2020, 2021]
     *
     * @param {number} param.first_year - The starting year of the range.
     * @param {number} param.last_year - The ending year of the range.
     * @returns {number[]} An array of years from first_year to last_year, inclusive.
     */
    function generateYearRange({first_year, last_year}) {
      // Validate the input parameters
      if (typeof first_year !== 'number' || typeof last_year !== 'number') {
        throw new Error('Invalid input: first_year and last_year must be numbers.');
      }

      // Ensure that first_year is less than or equal to last_year
      if (first_year > last_year) {
        throw new Error('Invalid input: first_year cannot be greater than last_year.');
      }

      // Create an array of years from first_year to last_year, inclusive
      return Array.from({ length: last_year - first_year + 1 }, (v, k) => k + first_year);
    }
  }
}());

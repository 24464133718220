(function () {
  'use strict';

  angular.module('app.marketplace.ui.creditCards')
         .directive('creditCardForm', creditCardForm);

  function creditCardForm () {
    return {
      restrict: 'E',
      replace: true,
      controller: creditCardFormCtrl,
      controllerAs: '$ctrl',
      templateUrl: 'templates/marketplace/credit-cards/credit-card-form.html',
      scope: {
        'submit': '=',
        'btnPrefix': '@',
        'creditCardFormMode': '@',
        'defaultCountry': '=',
        'ngDisabled': '@',
        'prefilledAddress': '=address',
        'addressLabel': '@',
      }
    };
  }

  /* @ngInject */
  function creditCardFormCtrl ($scope, $q, $log, $rootScope, officeService, ConfigService, creditCardService) {
    /*jshint validthis: true */
    var ctrl = this;
    var countriesMap = ConfigService.get('countriesMap');
    var currentOfficeAddress = _.get($rootScope, 'current_office.address');
    var prefilledAddress = $scope.prefilledAddress || _.get($rootScope, 'current_office.address');

    $scope.countries = ConfigService.get('countries');

    $scope.lockAddress = false;
    $scope.lockForm = false;
    $scope.creditCard = {};
    $scope.billingAddress = {};
    $scope.usePrefilledAddress = true;
    $scope.submit = submitForm;

    ctrl.addressLabel = $scope.addressLabel || 'Office Address';
    ctrl.ngDisabled = null;
    ctrl.isSaving = false;
    ctrl.btnPrefix = $scope.btnPrefix || 'creditCardForm';
    ctrl.creditCardFormMode = null;

    return init();

    //
    // Initialize the controller.
    //
    function init () {
      //
      // Deal with the "creditCardFormMode" of the credit-card-form. Should we use an
      // existing office, or treat this as a new office? Default to
      // existing_office is there is a current_office on $rootScope, otherwise
      // we default to new_office.
      //
      $scope.$watch('creditCardFormMode', function (newValue) {
        if (newValue === 'new_office') {
          ctrl.creditCardFormMode = $scope.creditCardFormMode;
        } else if (newValue === 'existing_office') {
          ctrl.creditCardFormMode = $scope.creditCardFormMode;
        } else {
          $log.warn('WARNING: credit-card-form: invalid "creditCardFormMode" value: %o', newValue);

          if ($rootScope.current_office) {
            ctrl.creditCardFormMode = 'existing_office';
          } else {
            ctrl.creditCardFormMode = 'new_office';
          }
        }
      });

      //
      // Update the provinces list of the country changes.
      //
      $scope.$watch('billingAddress.country.id', updateProvincesList);

      //
      // If the provided prefilledAddress changes, we need to update things
      // related to it.
      //
      $scope.$watch('prefilledAddress', function (newAddress) {
        prefilledAddress = $scope.prefilledAddress || _.get($rootScope, 'current_office.address');

        if ($scope.usePrefilledAddress && prefilledAddress) {
          angular.extend($scope.billingAddress, blankAddress());
          angular.extend($scope.billingAddress, prefilledAddress);
        }
      }, true);

      //
      // If the usePrefilledAddress boolean changes, we need to change
      // $scope.billingAddress to correspond to the change.
      //
      $scope.$watch('usePrefilledAddress', function (newValue, oldValue) {
        angular.extend($scope.billingAddress, blankAddress());

        if (newValue) {
          angular.extend($scope.billingAddress, prefilledAddress);
        }

        $scope.lockAddress = shouldLockAddress();
        $scope.lockForm = shouldLockForm();
      });

      //
      // Allow ng-disabled from the parent scope
      //
      $scope.$watch(evalNgDisabled, updateNgDisabled);
    }

    //
    // Generate a blank address object
    //
    function blankAddress () {
      return {
        address1: null,
        address2: null,
        city: null,
        postal_code: null,
        province: null,
        country: $scope.defaultCountry || $rootScope.current_country || countriesMap['CA'],
      };
    }

    //
    // Eval the ng-disabled value in the context of the parent scope.
    //
    function evalNgDisabled () {
      return $scope.$parent.$eval($scope.ngDisabled);
    }

    //
    // Update local state to reflect a change in ng-disabled.
    //
    function updateNgDisabled (newValue) {
      ctrl.ngDisabled = newValue;
      $scope.lockForm = shouldLockForm();
      $scope.lockAddress = shouldLockAddress();
    }

    //
    // Update the provinces list from the currently selected billing address
    // country.
    //
    // Unfortunately, country objects that come from the outside (i.e.  the
    // provided address) may only have an id and a name attribute, which means
    // that we can't rely on $scope.billingAddress.country.provinces to contain
    // a list of provinces.
    //
    function updateProvincesList () {
      var currentCountryId = _.get($scope, 'billingAddress.country.id');
      var country = _.get(countriesMap, currentCountryId);

      if (country) {
        $scope.provinces = country.provinces;
      }
    }

    //
    // Control the value of ctrl.isSaving
    //
    function toggleSaving (value) {
      ctrl.isSaving = arguments.length < 1 ? !ctrl.isSaving : !!value;
      $scope.lockAddress = shouldLockAddress();
      $scope.lockForm = shouldLockForm();
    }

    //
    // When should we lock (disable) the entire form.
    //
    function shouldLockForm () {
      return ctrl.ngDisabled || ctrl.isSaving;
    }

    //
    // When should we lock (disable) the address portion of the form.
    //
    function shouldLockAddress () {
      return $scope.usePrefilledAddress || shouldLockForm();
    }

    //
    // Take a promise that returns a $http response object, and forward _just_
    // the response's payload to the next promise in the chain.
    //
    function unwrapResponse (promise) {
      return promise.then(function (response) {
        return response.data;
      }).catch(function (error) {
        throw error ? error.message : error;
      });
    }

    //
    // A wrapper that ensures that the "saving" flag is toggled correctly. Just
    // pass it a function that returns a promise, and the "saving" flag will be
    // toggled "on" until the promise resolves.
    //
    function savingWrapper (func) {
      var deferred = $q.defer();
      toggleSaving(true);

      func().then(
        deferred.resolve,
        deferred.reject
      )['finally'](function () {
        toggleSaving(false);
      });

      return deferred.promise;
    }

    //
    // Merge creditCard and billingAddress together to for... cardData! It's super-effective!
    //
    function getCardData () {
      return angular.extend({}, $scope.creditCard, $scope.billingAddress);
    }

    //
    // Create the Credit Card
    //
    function submitForm () {
      if (!$scope.CreditCardForm.$valid) {
        $scope.CreditCardForm.$setSubmitted();
        return $q.reject('Please check your credit card details');
      }

      return savingWrapper(function () {
        if (ctrl.creditCardFormMode === 'new_office') {
          //
          // Just generate the Stripe card token, and return that object.
          //
          return creditCardService.createCardToken(getCardData());
        } else {
          //
          // If we have an office, then we go through the process of adding the
          // card to the office, and then returning the card.
          //
          return creditCardService.createCardToken(getCardData()).then(function (token) {
            return unwrapResponse(officeService.storeCreditCard(token, $scope.billingAddress));
          });
        }
      });
    }
  }

}());

angular.module('app.marketplace.ui.account', [])

  .config(function($stateProvider, $urlRouterProvider) {
    $stateProvider
      .state('app.password_reset', {
        url: '/password_reset/:token',
        controller: 'PasswordResetCtrl',
        templateUrl: 'templates/marketplace/account/views/password-reset.html',
        data: {
          'title': 'Password Reset',
        }
      })

      .state('app.account', {
        url: '/profile',
        controller: 'AccountController',
        templateUrl: 'templates/marketplace/account/account.html',
        data: {
          'title': 'Account',
          'action_bar_directive': 'action-bar-account',
          'require_log_in' : true
        }
      })

      .state('app.account.settings', {
        url: '/settings',
        controller: 'accountSettingsController',
        templateUrl: 'templates/marketplace/account/views/settings.html',
        data: {
          'title': 'Account Settings'
        }
      })

      .state('app.account.password', {
        url: '/password',
        controller: 'accountPasswordController',
        templateUrl: 'templates/marketplace/account/views/password.html',
        data: {
          'title': 'Change Password » Account'
        }
      })

      .state('app.account.email_preferences', {
        url: '/email_preferences',
        controller : 'accountSettingsController',
        templateUrl : 'templates/marketplace/account/views/email-preferences.html',
        data: {
          'title': 'Email Preferences » Account'
        }
      });

    $urlRouterProvider
      .when('/app/password_reset', '/app/password_reset/')
      .when('/app/account', '/app/profile')
      .when('/app/account/', '/app/profile')
      .when('/app/profile', '/app/profile/settings');
  })
;

angular.module("app.shared.support")
  .directive('ngNotMatch', ['$parse',
    function ($parse) {
      return {
        link: function (scope, elem, attrs, ctrl) {
          if (!ctrl) {
            return;
          }
          var firstField = $parse(attrs["ngNotMatch"]);

          var validator = function (value) {
            var temp = firstField(scope),
              v = value !== temp;
            ctrl.$setValidity('notmatch', v);
            return value;
          };

          ctrl.$parsers.unshift(validator);
          ctrl.$formatters.push(validator);
          attrs.$observe("ngNotMatch", function () {
            validator(ctrl.$viewValue);
          });
        },
        restrict: 'A',
        require: '?ngModel'
      };
    }
  ]);
{

  angular.module('app').config(catchAllEvents);
  
  // based on a angularjs contributor suggestion at
  // https://github.com/angular/angular.js/issues/6043#issuecomment-33610406
  function catchAllEvents ($provide) {
    const WOFE_PREFIX = 'wofe:';

    /**
     * Creates a new event with the name prefixed by WOFE_PREFIX, and then dispatches
     * that event to the window, where it can be listened to by react code.
     * 
     * @param eventName - The name of the event you want to dispatch.
     * @param eventData - The data you want to send with the event.
     */
    function dispatchToWindow (eventName, eventData) {
      // had to be duplicated from sowInteropService because 
      // config blocks don't have access to services
      const newEvent = new CustomEvent(`${WOFE_PREFIX} ${eventName}`, {
        detail: {
          eventName: eventName,
          eventData: eventData
        }
      });
      window.dispatchEvent(newEvent);
    }

    /** 
     * A decorator listening to all angular events and 
     * dispatching them to the window. 
     */
    $provide.decorator("$rootScope", function($delegate) {
      const Scope = $delegate.constructor;
      const origBroadcast = Scope.prototype.$broadcast;
      const origEmit = Scope.prototype.$emit;
      Scope.prototype.$broadcast = function (eventName, eventData) {
        dispatchToWindow(eventName, eventData);
        return origBroadcast.apply(this, arguments);
      };
      Scope.prototype.$emit = function (eventName, eventData) {
        dispatchToWindow(eventName, eventData);
        return origEmit.apply(this, arguments);
      };
      return $delegate;
    });
  }
}

(function(){

  angular.module('sowSterilization')
  .controller('sterilizationMachineListController', sterilizationMachineListController);

  function sterilizationMachineListController ($scope, $rootScope, $mdDialog, $stateParams, stznHelperService, sowInteropService,
    ) {
    /*jshint validthis: true*/
    var ctrl = this;
    
    ctrl.records_type_names = stznHelperService.records_type_names;
    ctrl.office_first_date = $rootScope.current_office.sterilization_start_date;
    ctrl.machines = {};

    ctrl.addNewMachine = addNewMachine;
    ctrl.editMachine = editMachine;
    ctrl.handleMachinesPageEvent = handleMachinesPageEvent
        
    init();

    return ctrl;

    // UI functions

    function init () {
      if($stateParams.add_new) {
        addNewMachine(null, $stateParams.add_new);
      }
      // event handlers
      $scope.$on('stzn-machine-saved', handleMachineSaved);
    }

    /**
     * Listens for react events and dispatches them to the appropriate functions.
     * add_machine: opens the add new machine modal
     * edit_machine: opens the edit machine modal
     *
     * @param {{
     *  detail: {
     *    action: 'add_machine' | 'edit_machine';
     *    type: 'sterilizers' | 'ultrasonic_cleaners' | 'washer_disinfectors';
     * }}} react event structure
     * @return {Promise}
     */
    function handleMachinesPageEvent({detail}) {
      switch(detail.action) {
        case 'add_machine':
          return addNewMachine(null, detail.type);
        case 'edit_machine': {
          const machine_type = {
            'sterilizers': 'sterilizer',
            'ultrasonic_cleaners': 'ultrasonic',
            'washer_disinfectors': 'washer',
          }[detail.type];
          
          return editMachine(detail.machine, machine_type);
        }
      }
    }


    function addNewMachine ($event, machine_type) {
      return $mdDialog.show({
          controller: 'stznAddNewMachineController',
          controllerAs: 'stznANMCtrl',
          templateUrl: 'sow-sterilization/modals/stzn-add-new-machine.html',
          parent: angular.element(document.body),
          clickOutsideToClose: true,
          locals: {
            'machine_type': machine_type,
          },
          bindToController: true,
        });
    }

    function editMachine (machine, type) {
      return $mdDialog.show({
          controller: 'stznAddNewMachineController',
          controllerAs: 'stznANMCtrl',
          templateUrl: 'sow-sterilization/modals/stzn-add-new-machine.html',
          parent: angular.element(document.body),
          clickOutsideToClose: true,
          locals: {
            'machine_type': type,
            'machine_data': angular.copy(machine),
          },
          bindToController: true,
        });
    }


    /**
     * Dispatches a wofe event to react so we can update the machine list with the new machine.
     */
    function handleMachineSaved (_$event, params) {
      const machine_type = {
        'sterilizer': 'sterilizers',
        'ultrasonic': 'ultrasonic_cleaners',
        'washer': 'washer_disinfectors',
      }[params.type];

      sowInteropService.dispatchToWindow('sterilization-machine-saved', {
        machineType: machine_type,
        action: params.action,
      });
    }

  } // end controller

})();

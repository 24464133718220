(function () {
  'use strict';

  angular.module('sowSterilization')
  .controller('sterilizationActionBarController', sterilizationActionBarController)
  .directive('actionBarStzn', sterilizationActionBarDirective);

  function sterilizationActionBarDirective () {
    return {
      restrict: 'AC',
      templateUrl: 'sow-sterilization/directives/action-bar-stzn.html',
      controller: 'sterilizationActionBarController',
      controllerAs: 'stznAbCtrl'
    };
  }

  function sterilizationActionBarController ($rootScope, sowReportsService, AccessService, $scope) {
    /*jshint validthis:true*/
    var ctrl = this;

    ctrl.is_add_test_modal_visible = false;

    ctrl.addNewTest = addNewTest;
    ctrl.handleAddTestModalEvent = handleAddTestModalEvent;
    ctrl.exportAndMail = sowReportsService.exportSterilization;
    ctrl.has_sterilization_access = false;

    init();

    return ctrl;

    function init () {
      _defineLocks()
    }

    function addNewTest () {
      /*
        We want to maintain the old behavior when V1 is enabled, so in case we need to roll back,
        we can do so easily without pushing a new release.
      */
      if (AccessService.hasOfficeProperty('sterilization_add_test_version', 'V1')) {
        // Use the old angular modal
        $rootScope.$broadcast('add-new-stzn-test');
      } else {
        // Use the new react add test page
        ctrl.is_add_test_modal_visible = true;
      }
    }

    function handleAddTestModalEvent({detail: {action}}) {
      if (action === 'close') {
        ctrl.is_add_test_modal_visible = false;
      }
    }

    function _defineLocks() {
      ctrl.has_sterilization_v2_enabled = AccessService.hasSterilizationV2Enabled();
      ctrl.show_pouch_content_page = AccessService.hasOfficeProperty('sterilization_show_pouch_content', true);
      ctrl.has_sterilization_access = Boolean($scope.features.sterilization);
    }
  }

})();

(function () {
  'use strict';
  
  angular
  .module('sowReports')
  .controller('SpendingOfficeController', SpendingOfficeController);
  
  function SpendingOfficeController($scope, $window, $rootScope, $state, $filter, sowReportsService2, orderByFilter, AccessService) {
    var ctrl = this;
    
    ctrl.reportPermission = !_.isNil(_.get($rootScope, 'user_permissions.spending_reports_budget', null));
    ctrl.implantPermission = !_.isNil(_.get($rootScope, 'user_permissions.implants_implant_reports', null));
    ctrl.chartEmpty = false;
    ctrl.isSpending = false;
    ctrl.yearLocal = $window.localStorage.getItem('yearLocal');
    ctrl.selectedYear = moment().year();
    if(ctrl.yearLocal){
      ctrl.selectedYear = ctrl.yearLocal;
    }
    ctrl.rangeOptions = [{
      name: moment().year(),
      value: moment().year()
    }];
    ctrl.officeID = sow.officeInfo().id;
    ctrl.currentChartShow = {
      year: ctrl.selectedYear,
      labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      data:[],
      totalSpent:0,
      average: 0,
      balance:{
        value:0,
        color: 'black'
      },
      month:[]
    };
    var t_spending = $filter('translate')('COMMON.SPENDING');
    var t_budget = $filter('translate')('COMMON.BUDGET');
    var t_overage = $filter('translate')('BUDGET.OVERAGE');
    ctrl.chartSetting = {
      options :{
        tooltips:{
          callbacks: {
            label: function(tooltipItem, data) {
              var datasetIndex = tooltipItem.datasetIndex;
              var index = tooltipItem.index;
              
              var value = data.datasets[datasetIndex].data[index];
              var label =  data.datasets[datasetIndex].label;
              
              var data_sources = {};
              data_sources[t_overage] = ctrl.currentChartShow.real_overage_data;
              data_sources[t_budget] = ctrl.currentChartShow.real_budget_data;
              data_sources[t_spending] = ctrl.currentChartShow.real_spending_data;
              
              var real_value = data_sources[label]?.[index];
              
              return label + ': ' + $filter('currency')(real_value, '$', 2);
            }
          }
        },
        scales: {
          xAxes: [{
            stacked: true,
            ticks: {
              beginAtZero: true
            }
          }],
          yAxes: [{
            stacked: false,
            ticks: {
              beginAtZero: true,
              callback: function(value, index) {
                if(index == 0){
                  return $filter('currency')(value, '$', 0);
                }else{
                  return $filter('currency')(value, '', 0);
                }
              }
            }
          }]
        },
        legend: {
          display: true,
          position: 'right',
          labels:{
            boxWidth:10
          },
          onClick:function(e){
            e.stopPropagation();
          }
        }
      },
      series :[t_spending, t_budget, t_overage],
      type : "StackedBar",
      colors : [
        {
          backgroundColor: '#33be8c',
          pointBackgroundColor: 'rgba(148,159,177,1)',
          pointHoverBackgroundColor: 'rgba(148,159,177,1)',
          borderColor: '#33be8c',
          pointBorderColor: '#fff',
          pointHoverBorderColor: 'rgba(148,159,177,0.8)'
        },
        {
          backgroundColor: '#dddddd',
          pointBackgroundColor: 'rgba(77,83,96,1)',
          pointHoverBackgroundColor: 'rgba(77,83,96,1)',
          borderColor: '#dddddd',
          pointBorderColor: '#fff',
          pointHoverBorderColor: 'rgba(77,83,96,0.8)'
        },
        {
          backgroundColor: '#fa9e4d',
          pointBackgroundColor: 'rgba(77,83,96,1)',
          pointHoverBackgroundColor: 'rgba(77,83,96,1)',
          borderColor: '#fa9e4d',
          pointBorderColor: '#fff',
          pointHoverBorderColor: 'rgba(77,83,96,0.8)'
        }
      ]
    };

    ctrl.changeChartData = changeChartData;
    ctrl.chartClick = chartClick;
    ctrl.getBalance = getBalance;
    ctrl.getBudgetData = getBudgetData;
    ctrl.getMonthOfYear = getMonthOfYear;
    ctrl.getOverage = getOverage;
    ctrl.getSpeedingOfficeReport = getSpeedingOfficeReport;
    ctrl.getSpendingData = getSpendingData;
    ctrl.goBack = goBack;
    ctrl.goToBudgetManager = goToBudgetManager;
    ctrl.goToMonthDetail = goToMonthDetail;
    ctrl.resetChart = resetChart;
    ctrl.setDataSpendingOnly = setDataSpendingOnly;
    ctrl.setDataWithBudget = setDataWithBudget;
    ctrl.setSpendingPerMonth = setSpendingPerMonth;
    ctrl.setTable = setTable;
    ctrl.sort = sort;
    ctrl.sumArray = sumArray;
    ctrl.updateCurrentYear = updateCurrentYear;
    ctrl.updateYearOptions = updateYearOptions;
    
    init();

    return ctrl;

    function init () {
      defineLocks();
      ctrl.getSpeedingOfficeReport(ctrl.selectedYear);
      ctrl.updateYearOptions();
      
      if(!ctrl.reportPermission){
        if(ctrl.implantPermission){
          $state.go('app.reports.implants-placement.dashboard');
        } else {
          $state.go('');
        }
      }
    }

    function defineLocks() {
      ctrl.gl_budget_enabled = AccessService.getOfficeProperty('gl_budget_enabled');
    }
    
    function sort (array, sortByName, sortOrder) {
      var newArray = [];
      if(array.length > 0){
        newArray = orderByFilter(array, sortByName, sortOrder);
      }
      return newArray ;
    }
    
    function updateCurrentYear  () {
      $window.localStorage.setItem('yearLocal', ctrl.selectedYear);
      ctrl.resetChart();
      if (ctrl.selectedYear) {
        ctrl.getSpeedingOfficeReport(ctrl.selectedYear);
      }
    }
    
    function updateYearOptions () {
      var first_date = moment($scope.$root.current_office.created_at).startOf('year');
      var last_date = moment();
      var years_count = last_date.diff(first_date, 'y');
      
      for (var i = 1; i <= years_count; i++) {
        var year_number = moment().subtract(i,'y').format('YYYY');
        ctrl.rangeOptions.push({
          name: year_number,
          value: year_number
        });
      }
    }
    
    function getSpeedingOfficeReport (selectedYear) {
      sowReportsService2.getSpeedingOfficeReport(selectedYear).then(function(data){
        ctrl.chartData = data;
        ctrl.changeChartData();
      });
    }
    
    function resetChart () {
      ctrl.chartEmpty = false;
      ctrl.isSpending = false;
      ctrl.currentChartShow = {
        year: ctrl.selectedYear,
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        data:[],
        totalSpent:0,
        average: 0,
        balance:{
          value:0,
          color: 'black'
        },
        month:[]
      };
    }
    
    function changeChartData () {
      var report = ctrl.chartData[0].report;
      var budgets = report.budgets;
      var orders = report.orders;
      
      if(budgets.length == 0 && orders.length == 0){
        ctrl.chartEmpty = true;
        ctrl.currentChartShow.month = ctrl.sort(ctrl.setTable(),'monthNumber', true);
      }else if(budgets.length == 0 && orders.length > 0){
        ctrl.isSpending = true;
        ctrl.chartSetting.options.legend.display = false;
        ctrl.setDataSpendingOnly(orders);
      }else{
        ctrl.setDataWithBudget(budgets, orders);
      }
      
    }
    
    function setTable () {
      var arrayMonth =[];
      
      for(var i = 1; i < 13 ; i ++){
        arrayMonth.push({
          orders_placed: 0,
          total: 0,
          month: moment(i, 'M').format('MMMM'),
          monthNumber: i
        });
      }
        
      if(ctrl.selectedYear == moment().year()){
        var currentMonth = moment().month()+1;
        arrayMonth.length = currentMonth;
      }
      return arrayMonth;
    }

    function setDataSpendingOnly (orders){
      ctrl.currentChartShow.data = [];
      var monthlyOrder = orders[0].monthly_orders;
      var spendingData = ctrl.getSpendingData(monthlyOrder);
      var totalSpent = spendingData.reduce(ctrl.sumArray, 0);
      var average = totalSpent / spendingData.length;

      ctrl.currentChartShow.data.push(spendingData);
      ctrl.currentChartShow.totalSpent = totalSpent;
      ctrl.currentChartShow.average = average;

      for(var i =0; i < monthlyOrder.length; i++){
        var item = monthlyOrder[i];
        item.monthNumber = parseInt(moment(item.month, 'MMM').format("M"));
      }
      ctrl.currentChartShow.month = ctrl.sort(ctrl.setSpendingPerMonth(monthlyOrder),'monthNumber', true);
    }

    function setSpendingPerMonth (monthlyOrder){
      var arrayMonth =[];

      for(var i = 1; i < 13 ; i ++){
        arrayMonth.push(
          {
            orders_placed: 0,
            total: 0,
            month: moment(i, 'M').format('MMM')
          }
          )
        }
        for(var i=0; i < arrayMonth.length; i++){
          for(var j=0; j < monthlyOrder.length; j++){
            if(arrayMonth[i].month == monthlyOrder[j].month){
              arrayMonth[i].orders_placed = monthlyOrder[j].orders_placed;
              arrayMonth[i].total = monthlyOrder[j].total;
            }
          }
          arrayMonth[i].monthNumber = parseInt(moment(arrayMonth[i].month, 'MMM').format("M"));
          arrayMonth[i].month = moment(arrayMonth[i].month, 'MMM').format("MMMM");
        }
        
        if(ctrl.selectedYear == moment().year()){
          var currentMonth = moment().month()+1;
          arrayMonth.length = currentMonth;
        }
        return arrayMonth;
    }

    function setDataWithBudget (budgets, orders){
      var monthlyOrder = [];
      var monthlyBudget = [];
      ctrl.currentChartShow.data = [];
      if(orders.length > 0){
        monthlyOrder = orders[0].monthly_orders ;
      }
      
      if(budgets.length > 0){
        monthlyBudget = budgets[0].monthly_budget;
      }
      
      var budgetData = ctrl.getBudgetData(monthlyBudget);
      var totalBudget = budgetData.reduce(ctrl.sumArray, 0);
      
      var spendingData = ctrl.getSpendingData(monthlyOrder);
      var overageData = ctrl.getOverage(budgetData,spendingData);
      var totalSpent = spendingData.reduce(ctrl.sumArray, 0);
      
      
      // this ensures chart data is changed so that chart bars are displayed correctly despite the actual values
      var spendingDataForChart = [];
      var overageDataForChart = [];
      for (var i = 11; i >= 0; i--) {
        var this_month_budget = parseFloat(budgetData[i])
        var this_month_spending = parseFloat(spendingData[i]);
        var this_month_overage = parseFloat(overageData[i]);
        
        if (this_month_budget === 0) {
          
          // when no budget was set
          overageDataForChart[i] = 0;
          spendingDataForChart[i] = this_month_spending;
          
        } else if(this_month_spending > this_month_budget){
          
          // when user spent more than expected
          overageDataForChart[i] = this_month_spending;
          spendingDataForChart[i] = this_month_budget;
          
        } else {
          
          // when user spent less than expected
          overageDataForChart[i] = this_month_overage;
          spendingDataForChart[i] = this_month_spending;
          
        }
      }
      
      var average = totalSpent / budgetData.length;
      var monthOfYear = ctrl.sort(ctrl.getMonthOfYear(monthlyOrder, budgetData),'monthNumber', true);
      
      // added this for better reading, since chart data needs to be set differently
      ctrl.currentChartShow.real_spending_data = spendingData;
      ctrl.currentChartShow.real_overage_data = overageData;
      ctrl.currentChartShow.real_budget_data = budgetData;
      
      
      ctrl.currentChartShow.data.push(spendingDataForChart, budgetData, overageDataForChart);
      ctrl.currentChartShow.month = monthOfYear;
      ctrl.currentChartShow.totalSpent = totalSpent;
      ctrl.currentChartShow.totalBudget = totalBudget;
      ctrl.currentChartShow.balance = ctrl.getBalance(parseFloat(totalSpent) , parseFloat(totalBudget));
      ctrl.currentChartShow.average = average ;
    }

    function sumArray (a, b){
      return Number(a) + Number(b);
    }

    function getBalance (totalSpent, totalBudget){
      var balance = {};
      if(totalSpent > totalBudget){
        balance.value = totalSpent - totalBudget;
        balance.color = "red"
      }else{
        balance.value = totalBudget - totalSpent;
        balance.color = "green"
      }
      
      return balance;
    }

    function getMonthOfYear (monthlyOrder, budget){
      var arrayMonth =[];
      
      for(var i = 1; i < 13 ; i ++){
        arrayMonth.push({
          orders_placed: 0,
          total: 0,
          month: moment(i, 'M').format('MMM')
        });
      }
      for(var i=0; i < arrayMonth.length; i++){
        for(var j=0; j < monthlyOrder.length; j++){
          if(arrayMonth[i].month == monthlyOrder[j].month){
            arrayMonth[i].orders_placed = monthlyOrder[j].orders_placed;
            arrayMonth[i].total = monthlyOrder[j].total;
          }
        }
        arrayMonth[i].monthNumber = parseInt(moment(arrayMonth[i].month, 'MMM').format("M"));
      }
        
      for(var i = 0; i < arrayMonth.length ; i++){
        var item = arrayMonth[i];
        item.budget = budget[i];
        
        var spendingValue = parseFloat(item.total);
        var budgetValue = parseFloat(budget[i]);
        if(spendingValue > budgetValue){
          item.balance = {
            value: spendingValue - budgetValue,
            color: 'red',
            title: 'COMMON.OVER_BUDGET'
          }
        }else if(spendingValue <  budgetValue){
          item.balance ={
            value: budgetValue - spendingValue,
            color: 'green',
            title: 'COMMON.BALANCE'
          }
        }else if(spendingValue == budgetValue){
          item.balance = {
            value: budgetValue - spendingValue,
            color: 'black',
            title: ''
          }
        }
      }
      
      if(ctrl.selectedYear == moment().year()){
        var currentMonth = moment().month()+1;
        arrayMonth.length = currentMonth;
      }
      return arrayMonth;
    }
  
    function getBudgetData (monthlyBudget){
      var budgetData = [];
      var arrayMonth =[];
      
      for(var i = 1; i < 13 ; i ++){
        arrayMonth.push({
          budget: 0,
          month: moment(i, 'M').format('MMM')
        });
      }
      
      for(var i = 0 ; i < arrayMonth.length ; i++){
        for(var j = 0; j < monthlyBudget.length; j++){
          if(arrayMonth[i].month == monthlyBudget[j].month){
            arrayMonth[i].budget = monthlyBudget[j].budget;
          }
        }
      }
      for(var i = 0 ; i < arrayMonth.length ; i++){
        var item = arrayMonth[i];
        budgetData.push(item.budget);
      }
      
      return budgetData;
    }
    
    function getSpendingData (monthlyOrder){
      var spendingData = [];
      var arrayMonth = [];
      for(var i = 1; i < 13 ; i++){
        arrayMonth.push({
          orders_placed: 0,
          total: 0,
          month: moment(i, 'M').format('MMM')
        });
      }
      if(monthlyOrder.length > 0){
        for(var i=0; i < arrayMonth.length; i++){
          for(var j=0; j < monthlyOrder.length; j++){
            if(arrayMonth[i].month == monthlyOrder[j].month){
              arrayMonth[i].orders_placed = monthlyOrder[j].orders_placed;
              arrayMonth[i].total = monthlyOrder[j].total;
            }
          }
        }
        for(var i = 0 ; i < arrayMonth.length ; i++){
          var item = arrayMonth[i];
          spendingData.push(item.total);
        }
      }else{
        spendingData = [0,0,0,0,0,0,0,0,0,0,0,0];
      }
      return spendingData;
    }
    
    function getOverage (budget, spending){
      var overage = [];
      
      for(var i = 0; i < budget.length; i++){
        if(budget[i] > 0 && budget[i] < spending[i]){
          var month_spending = parseFloat(spending[i]);
          var month_budget = parseFloat(budget[i]);
          var month_overage = Math.abs(month_budget - month_spending);
          var cal = (month_spending - month_budget) + month_spending;
          overage.push(month_overage);
        }else{
          overage.push('0');
        }
      }
      
      return overage;
    }
    
    function goToBudgetManager ($event){
      if($event && $event.stopPropagation)
      $event.stopPropagation();
      $state.go('app.budget.budget-detail', {year: ctrl.selectedYear});
    }
    
    function goToMonthDetail (month, year){
      $state.go('app.reports.spending.month-detail', {month: month, year: year});
    }
    
    function goBack (){
      $state.go('app.reports.spending.category');
    }
    
    function chartClick (points){
      if(points.length > 0){
        var month =points[0]._model.label;
        ctrl.goToMonthDetail(month, ctrl.selectedYear);
      }
    }
    
  }
}());
        
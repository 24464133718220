class InventoryCreateProductControllerData {
  constructor() {
    this.CATEGORIES = [
      { name: 'Other', parent_id: null },
      { name: 'Mock Category', parent_id: null },
    ];
    this.CATEGORY_ID = 'mock-category-id';
    this.MATCHING_SUBCATEGORIES = [
      { name: 'Mock Subcategory 1', parent_id: this.CATEGORY_ID },
      { name: 'Mock Subcategory 2', parent_id: this.CATEGORY_ID },
    ];
    this.NON_MATCHING_SUBCATEGORIES = [
      { name: 'Mock Other Subcategory', parent_id: 'mock-other-category-id' },
    ];
    this.SUBCATEGORIES = [
      ...this.MATCHING_SUBCATEGORIES,
      ...this.NON_MATCHING_SUBCATEGORIES,
    ];
    this.CATEGORIES_RESPONSE = [
      ...this.CATEGORIES,
      ...this.SUBCATEGORIES,
    ];
  }
}

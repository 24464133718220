angular.module("app.shared.support")
  //Replaces content with custom html
  .directive('inboxStyleItemContentTransclude', function ($log) {
    return {
      require: ['?^inboxStyleItem'],
      link: function (scope, element, attrs, controller) {
        scope.$watch(function () { return controller.length && controller[0] ? controller[0].content : null; }, function (content) {
          if (content) {
            element.html('');
            element.append(controller[0].content);
          }
        });
      }
    };
  });
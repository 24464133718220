(function () {
  'use strict';

  angular.module('sowSterilization')
    .directive('sowStznTestFormUltra', stznTestFormUltrasonicDirective);

  function stznTestFormUltrasonicDirective() {
    return {
      restrict: 'E',
      templateUrl: 'sow-sterilization/directives/stzn-ultra-test-form.html'
    };
  }

})();
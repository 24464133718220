angular.module('app.shared.support')

.directive('productValueInInventory', function() {
  return {
    restrict: 'E',
    controllerAs: 'ctrl',
    scope: true,
    bindToController: {
      product: '='
    },
    controller: function(inventoryItemService) {
      var ctrl = this;
      ctrl.getPrice = inventoryItemService.calculateInventoryValue;
    },
    template: '<span>{{ ctrl.getPrice(ctrl.product) | currency }}</span>'
  };
});

(function () {
  'use strict';

  angular
    .module('sowImplants')
    .service('sowImplantService', sowImplantService);

  //
  // Objects:
  //
  //  * ImplantTab -- an object that represents a top-level tab on an implant
  //    manufacturer's page. It shoud contain all the necessary information
  //    (titles, content, etc) to render the tab to the user.
  //
  //    - It's possible for there to be child tabs under an ImplantTab. Each
  //      child tab follows the same format as the top-level ImplantTab object.
  //
  //    - If an ImplantTab has child tabs, there should be no ImplantTable
  //      associated directly with it (only indirectly through the child tabs).
  //
  // * ImplantTable -- an object that represents a table of implant products to
  //   the user.
  //
  //   - Each ImplantTable is meant to be paired with an ImplantTab in a
  //     one-to-one relationship (this ImplantTab is not necessarily a
  //     top-level ImplantTab).
  //
  //   - Each ImplantTable should contain everything necessary to render/style
  //     the _entire_ implant products table to the user.
  //
  //   - Since each ImplantTable contains the information for the entire,
  //     unfiltered table, it must be paired with a TableSettings object to
  //     filter the rows/columns down to what the user has configured to be
  //     shown.
  //
  // * ImplantTableSettings -- an object that contains the settings for which
  //   products in an ImplantTable a user wants to see.
  //
  //   - Each ImplantTableSettings object is keyed on manufacturer_id and
  //     category (and is also specific to an office).
  //
  //   - Each setting of of the format:
  //
  //       [["Tissue Level Implants", "Standard Implant", "4.1 mm"], true]
  //
  //     where the first item is a list of strings that specifies the setting,
  //     and the second item in the array is a boolean indicating if that item
  //     is set to be visible or not.
  //
  // * ImplantInventoryGroup -- A grouping of ImplantInventoryItem records
  //   under the ImplantCatalogItem that they are linked to.
  //
  //   - This also contains the inventory_item_id reference that points to the
  //     OfficeInventoryItem stub in this office's inventory for the
  //     ImplantCatalogItem.
  //
  // * ImplantInventoryItem -- A record representing an individual product in
  //   the office's implants inventory.
  //

  function sowImplantService (serverAPI, apiUrl, $rootScope, $log, appEvents, accountService, errorService) {
    /*jshint validthis: true */
    var service = this;

    service.getManufacturer = getManufacturer;
    service.getManufacturers = getManufacturers;
    service.removeManufacturer = removeManufacturer
    service.getCatalogItems = getCatalogItems;
    service.getTabs = getTabs;
    service.getTables = getTables;
    service.getTable = getTable;
    service.getTableSettings = getTableSettings;
    service.saveTableSettings = saveTableSettings;
    service.getInventoryGroup = getInventoryGroup;
    service.getInventoryGroups = getInventoryGroups;
    service.getInventoryItem = getInventoryItem;
    service.getInventoryItems = getInventoryItems;
    service.saveInventoryItem = saveInventoryItem;
    service.batchCreateInventoryItems = batchCreateInventoryItems;
    service.removeInventoryItem = removeInventoryItem;
    service.getInventoryLocations = getInventoryLocations;
    service.searchImplantInventory = searchImplantInventory;
    service.getImplantAlerts = getImplantAlerts;
    service.getImplantUsers = getImplantUsers;
    service.saveImplantGroup = saveImplantGroup;

    service.yearlyReportData = yearlyReportData;
    service.monthlyReportData = monthlyReportData;
    service.getMktProductID = getMktProductID;

    return service;

    function getImplantUsers () {
      var filters = {'implants':true};
      var url = _getUrl("/users");
      var params = {'method': 'GET', 'params': filters};
      return _callAPI(url, params);
    }

    function _getUrl (path) {
      path = path || '';
      return '{0}/offices/{1}{2}'.format(apiUrl, sow.officeInfo().id, path);
    }

    function _callAPI (url, params) {
      return serverAPI
        .doAPICall(url, params)
        .then(function (response) {
          return response.data;
        });
    }

    /**
     * @method getImplantAlerts
     * @description Fetch the list of implants alerts
     * 
     */
    function getImplantAlerts () {
      
      var url = '{0}/implants/alerts'.format(apiUrl);
      var options = {'method': 'GET'};

      return serverAPI
        .doAPICall(url, options)
        .then(function (response) {
          return response.data;
        });
    
    }

    function saveImplantGroup(updated_group) {
      const url = `${apiUrl}/implants/inventory/groups/${updated_group.id}`
      const data = _.pick(updated_group, ['price', 'desired_level', 'minimum_level', 'notes']);
      return serverAPI
        .doAPICall(url, { method: 'PUT', data })
        .then(({ data }) => data)
        .catch(err => errorService.uiErrorHandler(err.message));
    }

    //
    // Return a filtered list of ImplantInventoryItem(s)
    //
    // Filters:
    //
    // * manufacturer_id -- Filters on an exact match for a Manufacturer.
    //
    // * lot_number -- Filters on all ImplantInventoryItem(s) that have a
    //   lot_number value that starts with (or is equal to) the provided string
    //   (case-insensitive).
    //
    // * reference_no -- Filters on all ImplantInventoryItem(s) that have a
    //   reference_no value that starts with (or is equal to) the provided
    //   string (case-insensitive).
    //
    // * reservation_id -- Filters on all ImplantInventoryItem(s) that have a
    //   reservation_id value that starts with (or is equal to) the provided
    //   string (case-insensitive).
    //
    function searchImplantInventory (filters) {
      filters = filters || {};

      var url = '{0}/implants/inventory/search'.format(apiUrl);
      var options = {
        'method': 'GET',
        'params': _.pick(filters, [
          'manufacturer_id',
          'reference_no',
          'reservation_id',
          'lot_number',
        ]),
      };

      return serverAPI
        .doAPICall(url, options)
        .then(function (response) {
          return response.data;
        });
    }

    //
    // Clear a manufacturer from having tables and showing up in the implant
    // manufacturer list.
    //
    // Note: This returns back the manufacturer, because it's not _really_
    // deleted. The client still needs access to the implant manufacturer for
    // other purposes (like displaying the list of manufacturers on the setup
    // panel).
    //
    function removeManufacturer (manufacturer) {
      var url = '{0}/implants/manufacturers/{1}'.format(apiUrl, manufacturer.id);
      var options = {'method': 'DELETE'};

      return serverAPI
        .doAPICall(url, options)
        .then(function (response) {
          angular.extend(manufacturer, response.data);
          return manufacturer;
        });
    }

    //
    // Create/Update an ImplantInventoryItem object
    //
    // This endpoint will create a new ImplantInventoryItem or save an existing
    // ImplantInventoryItem (note: to save an existing ImplantInventoryItem,
    // the submitted object _must_ contain the id of the item to update).
    //
    // The following are possible attributes for the submitted object:
    //
    // * `id` -- The id of the item. This is only for saving an existing item.
    //   You cannot choose the id for a new item.
    //
    // * `lot_number` -- The lot number of the inventory item. (Required)
    //
    // * `expiry_year` -- The expiry year. (Required)
    //
    // * `expiry_month` -- The expiry month. (Required)
    //
    // * `price` -- The price of the item.
    //
    // * `location_id` -- The id of the inventory location where this item is
    //   located.
    //
    // * `on_consignment` -- A boolean describing whether or not the item is on
    //   consignment.
    //
    // * `reservation_id` -- A string.
    //
    // * `placement_id` -- A string. This cannot be set if `was_placed_on` and
    //   `was_placed_by_id` are not set.
    //
    // * `was_placed_on` -- A ISO-8601 formatted datetime. This cannot be set
    //   if `was_placed_by_id` is not set.
    //
    // * `was_placed_on_id` -- The id of the user that placed this item. This
    //   cannot be set of `was_placed_on` is not set.
    //
    // * `inventory_group` -- The ImplantInventoryGroup for this item. This can
    //   either be an object representing the ImplantInventoryGroup or the string
    //   ID of an existing ImplantInventoryGroup. The format of an object looks like:
    //
    //    {
    //      "id": "",
    //      "manufacturer_id": "",
    //      "office_id": "",
    //      "reference_no": "",
    //    }
    //
    //    `id` is optional. If it exists, it must be the id of an existing
    //    `ImplantInventoryGroup`. If `id` is missing, then an
    //    `ImplantInventoryGroup` is looked up using the natural key
    //    (manufacturer_id, office_id, reference_no). If no
    //    ImplantInventoryGroup is found this way, a new one is created.
    //
    //    Note: You cannot change the ImplantInventoryGroup of an existing
    //    ImplantInventoryItem. If you submit bad data or a mismatched
    //    Group-Item pair the API will fail with an error.
    //
    // 
    function saveInventoryItem (implantInventoryItem) {
      var url = '{0}/implants/inventory/items'.format(apiUrl);
      var options = {'method': 'POST', 'data': implantInventoryItem};

      return serverAPI
        .doAPICall(url, options)
        .then(function (response) { 
          $rootScope.$broadcast(appEvents.implantItemSaved, response.data);
          return response.data; 
        });
    }

    /**
     * same as saveInventoryItem, but accepting `qty` in order to create multiple items in a single call
     *
     * @param {*} implantInventoryItem
     * @return {*} 
     */
    function batchCreateInventoryItems (implantInventoryItem) {
      var url = '{0}/implants/rpc/batch_create_inventory_items'.format(apiUrl);
      var options = {'method': 'POST', 'data': implantInventoryItem};

      return serverAPI
        .doAPICall(url, options)
        .then(function (response) { 
          $rootScope.$broadcast(appEvents.implantItemSaved, response.data);
          return response.data; 
        });
    }

    //
    // Fetch a ImplantInventoryItem object by id.
    //
    function getInventoryItem (id) {
      var url = '{0}/implants/inventory/items/{1}'.format(apiUrl, id);
      var options = {'method': 'GET'};

      return serverAPI
        .doAPICall(url, options)
        .then(function (response) { return response.data; });
    }

    //
    // Fetch a list of ImplantInventoryItem objects.
    //
    function getInventoryItems (filters) {
      var url = '{0}/implants/inventory/items'.format(apiUrl);
      var options = {
        'method': 'GET', 
        'params': _.pick(filters, [ 'id', 'manufacturer_id', 'inventory_group', 'inventory_group_id', 'was_placed', 'was_placed_on', 'implant_table_id', 'implant_tab_id' ])
      };

      // if (filter) {
      //   if (filter.manufacturerId) { options.params.manufacturer_id = filter.manufacturerId; }
      //   if (filter.inventoryGroupId) { options.params.inventory_group_id = filter.inventoryGroupId; }
      // }

      return serverAPI
        .doAPICall(url, options)
        .then(function (response) { return response.data; });
    }

    // 
    // Remove one specific ImplantInventoryItem
    // 
    function removeInventoryItem (id) {
      var url = '{0}/implants/inventory/items/{1}'.format(apiUrl, id);
      var options = {'method': 'DELETE'};

      return serverAPI
        .doAPICall(url, options)
        .then(function (response) { return response.data; });
    }

    //
    // Fetch a ImplantInventoryGroup object by id
    //
    function getInventoryGroup (id) {
      var url = '{0}/implants/inventory/groups/{1}'.format(apiUrl, id);
      var options = {'method': 'GET'};

      return serverAPI
        .doAPICall(url, options)
        .then(function (response) { return response.data; });
    }

    //
    // Fetch a list of ImplantInventoryGroup objects.
    //
    function getInventoryGroups (filters) {
      var url = '{0}/implants/inventory/groups'.format(apiUrl);
      var options = {
        'method': 'GET',
        'params': _.pick(filters, [
          'id', 'office_id', 'manufacturer_id', 'reference_no', 'category',
          'product_line', 'length', 'diameter', 'type', 'inventory_item_id', 
          'implant_table_id', 'implant_tab_id', 'require_table_id',
        ])
      };

      return serverAPI
        .doAPICall(url, options)
        .then(function (response) { return response.data; });
    }

    //
    // Fetch an ImplantTableSettings object.
    //
    function getTableSettings (manufacturer_id, category) {
      var url = '{0}/implants/catalog/settings'.format(apiUrl);
      var options = {
        'method': 'GET',
        'params': {
          'manufacturer_id': manufacturer_id,
          'category': category,
        }
      };

      return serverAPI
        .doAPICall(url, options)
        .then(function (response) { return response.data; });
    }

    //
    // Save an ImplantTableSettings object.
    //
    function saveTableSettings (tableSettings) {
      var url = '{0}/implants/catalog/settings'.format(apiUrl);
      var options = {
        'method': 'POST',
        'data': {
          'manufacturer_id': tableSettings.manufacturer_id,
          'category': tableSettings.category,
          'settings': tableSettings.settings,
        }
      };

      return serverAPI
        .doAPICall(url, options)
        .then(function (response) {
          return response.data;
        });
    }

    //
    // Fetch a list of (top-level) ImplantTab objects
    //
    function getTabs (filters) {
      var url = '{0}/implants/tabs'.format(apiUrl);
      var options = {'method': 'GET', 'params': {}};

      if (filters) {
        if (filters.manufacturer) { options.params.manufacturer_id = filters.manufacturer.id; }
        if (filters.manufacturer_id) { options.params.manufacturer_id = filters.manufacturer_id; }
        if (filters.category) { options.params.category = filters.category; }
      }

      // changed to cache because it doesnt change very often
      return serverAPI.callCacheAPI(url, options);
    }

    //
    // Fetch a list of ImplantTable objects.
    //
    function getTables (filters) {
      var url = '{0}/implants/tables'.format(apiUrl);
      var options = {'method': 'GET', 'params': {}};

      if (filters) {
        if (filters.manufacturer) { options.params.manufacturer_id = filters.manufacturer.id; }
        if (filters.manufacturer_id) { options.params.manufacturer_id = filters.manufacturer_id; }
        if (filters.category) { options.params.category = filters.category; }
      }

      return serverAPI
        .doAPICall(url, options)
        .then(function (response) {
          return response.data;
        });
    }

    //
    // Fetch an ImplantTable object by id
    //
    function getTable (id) {
      var url = '{0}/implants/tables/{1}'.format(apiUrl, id);
      var options = {'method': 'GET'};

      return serverAPI
        .doAPICall(url, options)
        .then(function (response) {
          return response.data;
        });
    }

    //
    // Fetch the list of implant manufacturers
    //
    function getManufacturers () {
      var url = '{0}/implants/manufacturers'.format(apiUrl);
      var options = {'method': 'GET'};

      return serverAPI.callAPI(url, options);
    }

    // Fetch one implant manufacturer
    //
    function getManufacturer (manufacturer_id) {
      var url = '{0}/implants/manufacturers/{1}'.format(apiUrl, manufacturer_id);
      var options = {'method': 'GET'};

      return serverAPI.callAPI(url, options);
    }

    //
    // Fetch the list of catalog items for a manufacturer.
    //
    function getCatalogItems (filters) {
      var url = '{0}/implants/catalog/items'.format(apiUrl);
      var options = {
        'method': 'GET',
        'params': _.pick(filters, [
          'manufacturer_id',
          'category',
          'implant_table_id',
          'implant_tab_id',
        ])
      };

      if (filters && filters.manufacturer) {
        options.params.manufacturer_id = filters.manufacturer.id;
      }

      return serverAPI
        .doAPICall(url, options)
        .then(function (response) {
          return response.data;
        });
    }

    // 
    // fetch a list of inventory locations
    // 
    function getInventoryLocations () {
      var url = '{0}/offices/{1}/inventory/locations'.format(apiUrl, $rootScope.current_office.id);
      var options = {'method': 'GET'};

      return serverAPI
        .doAPICall(url, options)
        .then(function (response) {
          return response.data;
        });
    }

    //
    // Fetch Implant Usage report data for a year
    // available params:  
    // category (Eg Implant or Bone Graft)
    // office_id (optional)
    // year (2017, 2018, ...)
    //
    function yearlyReportData (params) {
      var url = '{0}/implants/reports/placement/yearly'.format(apiUrl);
      var options = {
        'method': 'GET',
        'params': params,
      };

      return serverAPI
        .doAPICall(url, options)
        .then(function (response) {
          return response.data;
        });
    }

    //
    // Fetch Implant Usage report data for a year
    // available params:  
    // category (Eg 'Implant' or 'Bone Graft')
    // office_id (optional)
    // month - ISO-8660 timestamp ("2017-01-01")
    //
    function monthlyReportData (params) {
      var url = '{0}/implants/reports/placement/monthly'.format(apiUrl);
      var options = {
        'method': 'GET',
        'params': params,
      };

      return serverAPI
        .doAPICall(url, options)
        .then(function (response) {
          return response.data;
        });
    }

    /** 
     * Fetches the ID of the marketplace product which
     * corresponds to an implant (if one exists).
     * 
     * @param {String} marketplace_id 
     * @param {String} office_id 
     * @param {String} manufacturer_id 
     * @param {String} reference_no 
     * 
     * @return {String?} 
    */
    async function getMktProductID(marketplace_id, office_id, manufacturer_id, reference_no) {
      const params = { marketplace_id, manufacturer_id, reference_no };
      const url = `${apiUrl}/implants/${office_id}/product_id`;
      const options = { method: 'GET', params };
      try {
        return await serverAPI.doAPICall(url, options);
      } catch (error) {
        $log.error(error);
      }
    }

  }
}());

angular.module("app.shared.system")
  .service("minTimeout", function ($timeout, $q) {
    return {
      start: function (minMS) {
        var startMS = (new Date()).getTime();

        //Returns a promise for when the min timeout has resolved
        var _end = function () {
          var deferred = $q.defer();
          var currMS = (new Date()).getTime();
          if ((currMS - startMS) < minMS) {
            $timeout(function () {
              deferred.resolve(true);
            }, minMS - (currMS - startMS));
          } else {
            deferred.resolve(true);
          }

          return deferred.promise;
        };

        return {
          end: _end
        };
      }
    };
  });

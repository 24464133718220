(function () {
    'use strict';
    angular.module('sowSds')
    .controller('SdsListController', SdsListController);

    function SdsListController ($scope, errorService, sowSdsService, $mdDialog, $filter, sgToast, SDSHelperService) {
    /*jshint validthis: true */
    var ctrl = this;

    ctrl.display_items = [];
    ctrl.filter = 'all:all';
    ctrl.filter_params = {
      'search_text': null,
      'type': 'all',
    };
    ctrl.listOrder = 'product_name'
    ctrl.timeout_promises = [];
    ctrl.TYPE_TAGS = {
      "General Item": "SDS.GENERAL_ITEM",
      "Marketplace Item": "SDS.MARKETPLACE_ITEM",
      "Medication Item": "SDS.MEDICATION_ITEM",
      "Other": "COMMON.OTHER"
    };

    ctrl.addNewItem = addNewItem;
    ctrl.updateFilter = updateFilter;
    ctrl.attachFile = attachFile;
    ctrl.editItem = editItem;
    ctrl.applyFilters = applyFilters;
    ctrl.clearSearch = clearSearch;
    ctrl.getUrlFilename = SDSHelperService.getUrlFilename;

    init();

    return ctrl;

    function init () {
      ctrl.loading = true;
      loadItems();

      $scope.$on('sds-new-doc', addNewItem);
      $scope.$on('sds-item-updated', (e, updated_item) => updateLocalItem(updated_item));
      $scope.$on('$destroy', exit);
    }

    function exit () {

    }

    // UI functions

    function addNewItem () {
      openModal('add', null, sowSdsService.createItem);
    }

    function attachFile (item) {
      openModal('attach', item, sowSdsService.updateItem);
    }

    function editItem (item) {
      openModal('edit', item, sowSdsService.updateItem);
    }

    function docSavedToast () {
      var t_message = $filter('translate')('SDS.DOC_SAVED');
      sgToast.showSimple(t_message);
    }

    function docRemovedToast () {
      var t_message = $filter('translate')('SDS.DOC_REMOVED');
      sgToast.showSimple(t_message);
    }

    function openModal (mode, item, callback) {
      return $mdDialog.show({
        'bindToController': true,
        'clickOutsideToClose': true,
        'controller': 'SdsEditController',
        'controllerAs': 'sdseCtrl',
        'locals': { 'sds_doc': item, 'mode': mode },
        'parent': angular.element(document.body),
        'templateUrl': 'sow-sds/modals/sds-edit-doc.html',
      })
      .then(function(actioned_item){
        if (actioned_item.is_deleted) {
          return sowSdsService.deleteItem(actioned_item)
          .then(function(response){
            removeLocalItem(actioned_item);
            docRemovedToast();
          });
        } else {
          return callback(actioned_item)
          .then(function(result_item){
            updateLocalItem(result_item);
            docSavedToast();
          });
        }
      });
    }

    function updateLocalItem (new_item) {
      var list_index = _.findIndex(ctrl.items, function(item){
        return item.id === new_item.id;
      });
      if (parseInt(list_index) >= 0) {
        ctrl.items[list_index] = _.extend(ctrl.items[list_index], _.pick(new_item, ['document_path','document_path_deleted']));
      } else {
        ctrl.items.push(new_item);
      }
      applyFilters();
    }

    function removeLocalItem (old_item) {
      var list_index = _.findIndex(ctrl.items, function(item){
        return item.id === old_item.id;
      });
      if (parseInt(list_index) >= 0) {
        ctrl.items.splice(list_index, 1);
        applyFilters();
      }
    }

    function applyFilters () {
      ctrl.display_items = _.filter(ctrl.items, filterRecordsBy);
    }

    function clearSearch () {
      ctrl.filter_params.search_text = null;
      applyFilters();
    }
    
    function filterRecordsBy(item){
      
      if(ctrl.filter_params.search_text){
        if(_.startsWith(_.lowerCase(item.product_name),_.lowerCase(ctrl.filter_params.search_text))){

        } else {
          return false;
        }
      } 
      if (ctrl.filter_params.type === 'meds') {
        return item.item_type === 'Medication Item';
      }

      if (ctrl.filter_params.type === 'general') {
        return item.item_type === 'General Item' || item.item_type === 'Marketplace Item';
      }

      if (ctrl.filter_params.type === 'other') {
        return item.item_type === 'Other';
      }

      return true;
    }

    function updateFilter (value) {
      ctrl.filter_params.type = value;
      applyFilters();
    }


    // data functions

    function loadItems () {
      sowSdsService.getAllItems()
      .then(function(doc_response){
        const sanitized_items = removeDuplicateDocs(doc_response);
        ctrl.items = sanitized_items;
        applyFilters();
        ctrl.loading = false;
      })
      .catch(function(e){
        errorService.uiErrorHandler(e);
      })
    }

    /**
     * Removes duplicate documents from the given list of items.
     * 
     * items example
     * ```
     * [{
     *  "office_name": "office_name",
     *  "document_path_deleted": null,
     *  "office_id": "off_123",
     *  "created_at": "2025-01-06T09:24:41+00:00",
     *  "inventory_type": "General Item",
     *  "product_name": "Product Name",
     *  "inventory_id": "123",
     *  "item_type": "General Item",
     *  "inventory_name": "Product Name",
     *  "inventory_manufacturer_name": "Dentsply Canada Ltd",
     *  "product_manufacturer": "Dentsply Canada Ltd",
     *  "is_deleted": false,
     *  "deleted_on": null,
     *  "id": "sds_9Xuef6VnjEX9BNJVP6eWXC",
     *  "document_path": [
     *    "https://s3.com/sds_documents/072806529.pdf",
     *    "[u'https://s3.com/sds_documents/072806529.pdf', u'https://s3.com/sds_documents/072806529.pdf']",
     *    ]
     *  }]
     * ```
     * @param {Array} items - The list of items to process.
     * @param {Object} items[].document_path - The document path of each item.
     * @returns {Array} The list of items with unique document paths.
     */
    function removeDuplicateDocs(items) {
      return items.map(item => {
        item.document_path = SDSHelperService.filterUniqueSdsUrls(item.document_path);
        return item;
      });
    }
  }

}());

(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.inventory')
    .service('ExpiryDatesDialog', ExpiryDatesDialog);

  function ExpiryDatesDialog ($mdDialog) {
    /*jshint validthis: true */
    var service = this;

    service.show = showDialog;

    return service;

    function showDialog (expiryDates, ev) {
      return $mdDialog.show({
        'controller': ExpiryDatesDialogController,
        'controllerAs': '$ctrl',
        'templateUrl': 'templates/marketplace/inventory/expiry-dates-dialog.html',
        'parent': angular.element(document.body),
        'targetEvent': ev,
        'clickOutsideToClose': false,
        'locals': {'expiry_dates': angular.copy(expiryDates) || []},
      });
    }
  }

  /* @ngInject */
  function ExpiryDatesDialogController ($scope, $mdDialog, expiry_dates) {
    /*jshint validthis: true */
    var ctrl = this;

    ctrl.expiry_dates = expiry_dates || [];
    ctrl.done = done;
    ctrl.cancel = cancel;
    ctrl.addExpiryDate = addExpiryDate;
    ctrl.removeExpiryDate = removeExpiryDate;

    return ctrl;

    function cancel () {
      return $mdDialog.cancel();
    }

    function done () {
      return $mdDialog.hide(ctrl.expiry_dates);
    }

    function addExpiryDate () {
      if ($scope.newExpiryDate) {
        ctrl.expiry_dates.push($scope.newExpiryDate);
        delete $scope.newExpiryDate;
      }
    }

    function removeExpiryDate (index) {
      if (index >= 0 && index < ctrl.expiry_dates.length) {
        ctrl.expiry_dates.splice(index, 1);
      }
    }
  }
}());

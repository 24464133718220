(function () {
  'use strict';

  angular
  .module('sowAlerts')
  .controller('alertsController', alertsController);

    function alertsController($scope, $filter, alertsDataCarrierService, sowAlertService, errorService, sowImplantService) {
      /*jshint validthis: true */
      var ctrl = this;

      init();
      return ctrl;

      function init() {
        ctrl.alertsDataCarrierService = alertsDataCarrierService;
        ctrl.alertsDataCarrierService.is_loading = false;
        var officeInfo = sow.officeInfo();
        ctrl.account_type = officeInfo.account_type;

        if (ctrl.account_type === 'Premium Plus' && officeInfo.address.country.id === 'CA' && officeInfo.flags.premium_plus_trial) {
          ctrl.account_type = "";
        }

        load_alerts();
      }

      function load_alerts() {

        sowAlertService.getDashboardAlerts()
          .then(function (alerts) {

            Object.keys(alerts).forEach(function (key) {

              switch (key) {
                case 'sterilization':
                  ctrl.sterilization = [
                    {
                      title: "ALERTS.BIOTEST_PENDING",
                      total: alerts[key].pending_biological_records,
                      type:"biological"
                    },
                    {
                      title:"ALERTS.FAILED_TESTS",
                      total: alerts[key].number_failed_tests_last_30_days,
                      type:"chemical"
                    }
                  ];
                  break;
                case 'certifications':
                  ctrl.certifications = [
                    {
                      title: "ALERTS.UPCOMING_RENEWALS",
                      total: alerts[key].upcoming_renewals,
                      icon:"alarm",
                      color:'orange'
                    },
                    {
                      title:"ALERTS.EXPIRED_CERT",
                      total: alerts[key].expired_certifications,
                      icon:"alarm",
                      color:'red'
                    }
                  ];
                  break;
                case 'inventory':
                  ctrl.inventory = [
                    {
                      title: "COMMON.MIN_LVL",
                      total: alerts[key].below_minimum_level,
                      icon:"opacity",
                      type:"minimum",
                      color:'orange'
                    },
                    {
                      title:"ALERTS.EXPIRED",
                      total: alerts[key].expiring_soon,
                      icon:"hourglass",
                      type:"expired",
                      color:'red'
                    }
                  ];
                  break;
                case 'medications':
                  ctrl.medications = [
                    {
                      title: "COMMON.MIN_LVL",
                      total: alerts[key].below_minimum_level.medications,
                      icon:"opacity",
                      color:'orange',
                      type:"minimum",
                    },
                    {
                      title:"ALERTS.EXPIRED",
                      total: alerts[key].expiring_soon.medications,
                      icon:"hourglass",
                      color:'red',
                      type:"expired",
                    }
                  ];
                  ctrl.emergency_medications = [
                    {
                      title: "COMMON.MIN_LVL",
                      total: alerts[key].below_minimum_level.emergency_medications,
                      icon:"opacity",
                      color:'orange',
                      type:"minimum"
                    },
                    {
                      title:"ALERTS.EXPIRED",
                      total: alerts[key].expiring_soon.emergency_medications,
                      icon:"hourglass",
                      color:'red',
                      type:"expired",
                    }
                  ];
                  break;
                case 'implants':
                  if ($scope.features.implants) {
                    parse_implants(alerts[key]);
                  }

                  break;

                default:

                  break;
              }

            });
          }).catch(function (error) {
            var t_message = $filter('translate')('ERRORS.SERVER_TRY_AGAIN')
            errorService.uiErrorHandler(t_message);
          });
      }

      /**
       * @method parse_implants
       * @param {*} alerts
       */
      function parse_implants(alerts) {

        load_imp_manufacturers()
          .then(function (manufacturers) {

            var minimum, expiring_soon = [];

            var parsed_info = [];

            manufacturers.forEach(function (manufacturer) {

              var has_below_minimum_level = alerts.below_minimum_level[manufacturer.id];
              var has_expiring_soon = alerts.expiring_soon[manufacturer.id];

              if(has_below_minimum_level || has_expiring_soon){
                var info = {
                  manufacturer_name: manufacturer.name,
                  manufacturer_id: manufacturer.id,
                  manufacturer_url_name: manufacturer.url_name,
                  below_minimum_level: has_below_minimum_level,
                  expiring_soon: has_expiring_soon
                };

                parsed_info.push(info);
              }
            });

            ctrl.implants = parsed_info;

          });
      }

      /**
      * @method load_imp_manufacturers
      * @description load implants manufacturers
      */
      function load_imp_manufacturers() {
      return sowImplantService.getManufacturers()
        .then(function (manufacturers) {

          return Promise.resolve(manufacturers);
        }).catch(function (err) {
          var t_message = $filter('translate')('ERRORS.IMPLANT_MANUF')
          errorService.uiErrorHandler(t_message);
          return Promise.reject(t_message);
        });
      }
  }

}());

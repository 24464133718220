angular.module("app.marketplace.ui.contentBlock")
  //Next event content block to be shown in the landing page
  .directive('contentBlockNextEvent', function () {
    return {
      restrict: 'E',
      replace: true,
      templateUrl: 'templates/marketplace/content-block/directives/content-block-custom.html',
      controller: function ($scope, $sce, contentBlockService) {
        //Get all content blocks and find block with specific block class.
        contentBlockService.get(true).then(function (contentBlocks) {
          _.map(contentBlocks, function (block, i) {
            if (block.classes_block === "cb-type-event") {
              $scope.block = block;
            }
          });
        });
      }
    };
  });
(function () {
  'use strict';

  angular
    .module('sowDownloadFile', [])
    .service('sowDownloadFile', sowDownloadFileService);

  function sowDownloadFileService ($log, $window, $timeout) {
    /*jshint validthis: true */
    var service = this;

    service.triggerDownload = triggerDownload;
    service.downloadBlob = downloadBlob;
    service.responseToBlob = responseToBlob;
    service.extractFilenameFromResponse = extractFilenameFromResponse;

    return service;

    //
    // Trigger the browser to download the contents of a response to a file.
    //
    function triggerDownload (response, filename, options) {
      var blob;
      options = angular.extend({'defaultFilename': null}, options);

      if (!filename) {
        filename = extractFilenameFromResponse(response, options.defaultFilename);
      }

      blob = responseToBlob(response);
      return downloadBlob(blob, filename);
    }

    //
    // Trigger a download of blob to filename.
    //
    function downloadBlob (blob, filename) {

      if (typeof $window.navigator.msSaveBlob !== 'undefined') {
        $window.navigator.msSaveBlob(blob, filename);
        return;
      }

      var URL = $window.URL || $window.webkitURL;
      var downloadUrl = URL.createObjectURL(blob);

      if (!filename) {
        $window.location = downloadUrl;
        return;
      }

      // use HTML5 a[download] attribute to specify a filename
      var a = angular.element('<a>');
      var downloadSupported = (typeof document.createElement('a').download) !== 'undefined';

      // safari doesn't support this yet
      if (!downloadSupported) {
        //
        // Attempt to use <a target="_blank"> to open it in a new tab.
        // Result: FAILURE. Chrome pop-up blocker captures this.
        //
        // a.attr('href', downloadUrl);
        // a.attr('target', '_blank');
        // angular.element(document.body).after(a);
        // $timeout(function () { a[0].click(); });

        //
        // Attempt to use $window.open to open in a new tab.
        // Result: FAILURE. Chrome pop-up blocker captures this.
        //
        // $window.open(downloadUrl);

        $window.location = downloadUrl;
      } else {
        a.attr('href', downloadUrl);
        a.attr('download', filename);
        angular.element(document.body).after(a);

        // For some reason AngularJS's .triggerHandler() doesn't actually
        // download the contents like the DOM's .click() function does.
        // The only drawback is that AngularJS complains loudly when we
        // use .click() because it's in the middle of a $digest cycle, so
        // we need to wrap a $timeout() around it to appease the
        // AngularJS gods.
        $timeout(function () { a[0].click(); });
      }
    }

    //
    // Create a new Blob instance from a response object.
    //
    function responseToBlob (response) {
      var content_type = response.headers('Content-Type');
      var blob = new Blob([response.data], {'type': content_type});
      return blob;
    }

    //
    // Extract a filename from a response object (or fall back on
    // defaultFilename).
    //
    function extractFilenameFromResponse (response, defaultFilename) {
      var filename = '';
      var disposition = response.headers('Content-Disposition');

      if (disposition && disposition.indexOf('attachment') !== -1) {
        var filenameRE = /filename=[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var matches = filenameRE.exec(disposition);
        if (matches !== null && matches[1]) {
          filename = matches[1].replace(/['"]/g, '');
        }
      }

      return filename || defaultFilename;
    }
  }
}());

(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.registration')
    .controller('InviteRegisterCtrl', InviteRegisterCtrl);

  function InviteRegisterCtrl ($scope, $state, $timeout, $stateParams, accountService, sessionService, RouteService, errorService, membershipService) {
    /*jshint validthis: true */
    var ctrl = this;

    $scope.account = {};
    $scope.invite = {};

    $scope.createAccount = createAccount;

    initialize();

    return ctrl;

    function initialize () {
      $scope.invite = $stateParams.invite;
      $scope.account.email = $scope.invite.email;
    }

    function createAccount () {
      $scope.savingForm = true;

      if (!$scope.CreateAccountForm.$valid) {
        $scope.CreateAccountForm.$setSubmitted();
        $scope.savingForm = false;
        return;
      }

      return accountService.create({
        'name': $scope.account.name,
        'email': $scope.invite.email,
        'password': $scope.account.password,
        'invite_token': $scope.invite.token,
        'opt_in_to_emails': $scope.account.agree_to_emails,
        'accept_terms_of_service': true,
      }).then(function (account) {
        return sessionService.login({
          'email': $scope.invite.email,
          'password': $scope.account.password,
        }).then(function (result) {
          // membershipService.init()
          // .then(function(){
            $timeout(function(){
              if($state.current.name !== 'app.registration.account_created'){
                RouteService.goToDefault();
              }
            }, 1000);
          // });
          return result;
        });
      }).catch(function (error) {
        errorService.uiErrorHandler(error);
      })
      .finally(function () {
        $scope.savingForm = false;
      });
    }
  }
}());

(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.inventory')
    .controller('InventoryAlertsController', InventoryAlertsController)
    .directive('ngPrint', function printDirective() {
      var printSection = document.getElementById('printSection');
      // if there is no printing section, create one
      if (!printSection) {
          printSection = document.createElement('div');
          printSection.id = 'printSection';
          document.body.appendChild(printSection);
      }
      function link(scope, element, attrs) {
          element.on('click', function (event) {
            window.print()
            event.stopPropagation()
            event.preventDefault();
              var elemToPrint = document.getElementById(attrs.printElementId);
              // if (elemToPrint) {
              //     printElement(elemToPrint);
              // }
          });
          window.onbeforeprint = function () {
            // clean the print section before adding new content

            var elemToPrint = document.getElementById(attrs.printElementId);
            if (elemToPrint) {
                printElement(elemToPrint);
            }
        };
          window.onafterprint = function () {
              // clean the print section before adding new content
              printSection.innerHTML = '';
          };
          if (window.matchMedia) {
            var mediaQueryList = window.matchMedia('print');
            mediaQueryList.addListener(function(mql) {
              if (mql.matches) {
                //window.onbeforeprint();
              }
              else {
                window.onafterprint();

              }
            });
          }
      }
      function printElement(elem) {
          // clones the element you want to print
          var domClone = elem.cloneNode(true);
          printSection.appendChild(domClone);
          // window.print();
      }
      return {
          link: link,
          restrict: 'A'
      };
  });


  function InventoryAlertsController($rootScope, $filter, $stateParams, $mdDialog, sowInventoryService, inventoryItemService, sgToast, errorService, inventorySharingService, alertsDataCarrierService, AccessService, mktHelperService) {
    /*jshint validthis: true */
    var ctrl = this;

    ctrl.add_to_shopping_list = add_to_shopping_list;
    ctrl.remove_expiry_dates = remove_expiry_dates;
    ctrl.close_dialog = close_dialog;
    ctrl.remove_quantity = remove_quantity;
    ctrl.dateSort = dateSort;
    ctrl.set_expired_dates = set_expired_dates;
    ctrl.sort_expiry_dates = sort_expiry_dates;
    ctrl.set_po_info = set_po_info;
    ctrl.now = moment().toDate();
    ctrl.check_selected = check_selected;
    ctrl.answer = answer;
    ctrl.inventorySharingService = inventorySharingService;
    ctrl.getOnHand = getOnHand;
    ctrl.showProductDetails = showProductDetails;
    ctrl.setTab = setTab;
    ctrl.isAddToCartEnabled = isAddToCartEnabled;
    ctrl.shouldHideShoppingList = shouldHideShoppingList;
    ctrl.shouldHideShoppingListColumn = shouldHideShoppingListColumn;

    ctrl.$onInit = init;

    return ctrl;

    /**
     * @method init
     * @description Initializae the controller, intended for calling default services etc.
     */
    function init () {
      defineLocks();
      ctrl.active_tab = $stateParams.filter === "minimum" ? "minimum": "expired";
      ctrl.inventorySharingService.show_print = ctrl.active_tab === "minimum" ? true : false;
      load_alerts();
    }

    function setTab (key) {
      ctrl.active_tab = key;
      inventoryItemService.clearCheckedItems();
    }

    function showProductDetails (item) {
      $rootScope.$broadcast('mkt-details-open-fetch', item.product_id);
    }

    /**
     * @method load_alerts
     * @description Load inventory alerts services
     */
    function load_alerts() {
      sowInventoryService.getInventoryAlerts()
        .then(function (alerts) {
          ctrl.minimum = alerts.below_minimum_level.map((alert) => {
            _.set(alert, 'UI.is_discontinued', inventoryItemService.isItemDiscontinued(alert));
            return alert;
          })
          ctrl.expiring = _.map(alerts.expiring_soon, function(item){
            item.expired_dates = set_expired_dates(item);
            _.set(item, 'UI.is_discontinued', inventoryItemService.isItemDiscontinued(item));
            return item;
          });
          ctrl.inventorySharingService.total_alerts = ctrl.minimum.length + ctrl.expiring.length;

        }).catch(function (err) {
          var t_message = $filter('translate')('ERRORS.INVENTORY_ALERTS_LOAD')
          errorService.uiErrorHandler(t_message);
        });
    }

    /**
     * @method set_po_info
     * @description set the info of POs
     * @param {*} item
     */
    function set_po_info(item) {
      if(item.po_info && !angular.equals({}, item.po_info)){
        item.purchase_order = true;
        var tooltip = '';
        var tooltip_text = '';
        var t_in = $filter('translate')('COMMON.IN');
        var t_from = $filter('translate')('COMMON.FROM');
        var t_and = $filter('translate')('COMMON.AND');
        var keys = Object.keys(item.po_info);
        if (keys.includes('Received')) {
          keys.splice(keys.indexOf('Received'),1);
        }
        var po_array = [];
        _.each(keys, function(key){
          po_array = po_array.concat(item.po_info[key]);
        });

        var poText = _.map(po_array, function (item) {
          return !item.po_supplier ? item.po_name
            : '{0} {1} {2}'.format(item.po_name, t_from, item.po_supplier);
        });

        tooltip = '{0} {1}'.format(t_in, _.compact([
          poText.slice(0, -1).join(',\n'),
          poText.slice(-1),
        ]).join('\n{0} '.format(t_and)));
        item.tooltip = tooltip;

        if(keys.indexOf("Active") > -1){
          item.tooltip_text = $filter('translate')('ORDERS.ACTIVE');
          item.class = 'active';
        } else if(keys.indexOf("Draft") > -1){
          item.tooltip_text = $filter('translate')('ORDERS.DRAFT_ORDER');
          item.class = 'draft';
        } else {
          item.purchase_order = false;
        }

      } else {
        item.purchase_order = false;
      }
    }

    /**
     * @method  add_to_shopping_list
     * @description add the item to the shopping list
     * @param {*} item
     */

    function add_to_shopping_list(item){
      if (item.product_id && ctrl.has_multi_supplier_selection) {
        return mktHelperService.productDetailsFetch(item.product_id, {
          action: 'ADD_TO_SHOPPING_LIST',
          item,
        });
      }

      inventoryItemService.addToShoppingList([{id: item.id}])
        .then(function(){
          var text = $filter('translate')('COMMON.ADDED_TO_SL');
          sgToast.showSimple(text);
        }).catch(function (err) {
          var t_message = $filter('translate')('ERRORS.SHOPPING_LIST_ADD')
          errorService.uiErrorHandler(t_message);
        });
    }

    /**
     * @method remove_expiry_dates
     * @description open delete expiry dates modal
     * @param {*} item
     */
    function remove_expiry_dates(item) {
      ctrl.item = item;
      $mdDialog.show({
        controller: 'InventoryAlertsController',
        controllerAs: 'invAlertCtrl',
        templateUrl: 'templates/marketplace/inventory/modals/remove-expiration-dates.html',
        parent: angular.element(document.body),
        clickOutsideToClose: false,
        bindToController: true,
        locals:{
          item:item,
          quantity_to_remove: 0,
        }
      })
      .then(function (answer) {
        if (answer) {
          remove_quantity(answer);
        }
      });
    }

    /**
     * @method dateSort
     * @description order from expired to expiring
     */
    function dateSort(item) {
      // sort_expiry_dates(item);
      return moment(item.expired_dates[0].date).format('x');
    }

    /**
     * @method sort_expiry_dates
     */
    function sort_expiry_dates(item) {
      item.expiry_dates.sort(function(o1,o2){
        return moment(o1.date).isSameOrBefore(o2.date) ? -1 : moment(o1.date).isSameOrAfter(o2.date) ? 1 : 0;
      });

      return item.expiry_dates;
    }

    /**
     * @method set_expired_dates
     * @description obtain list of expired dates
     * @param {*} item
     */
    function set_expired_dates(item) {
      var expired = [];
      var limit_date = moment().add(60, 'd');

      item.expiry_dates.forEach(function(obj) {
        if (moment(obj.date).isSameOrBefore(limit_date)) {
          obj.date_instance = new Date(obj.date);
          obj.remove = true;
          expired.push(obj);
        }
      });

      expired = _.sortBy(expired, function(item){
        return moment(item.date).format('x');
      });
      return expired;
    }

    /**
     * @method check_selected
     */
    function check_selected(item) {
      var res = item.expiry_dates.some(function(dateObj) {
        return dateObj.remove === true;
      });

      return res;
    }


    /**
     * @method remove_quantity
     * @description update the on_hand property of the item based on the quantity to remove and update the item expiry_dates
     */
    function remove_quantity(quantity) {


      var end_quantity = inventoryItemService.calculateInventoryUnit(ctrl.item, quantity);
      var now = moment();
      remove_from_list(ctrl.item);
      ctrl.quantity_to_remove = quantity*1 || 0;
      ctrl.quantity_to_remove = end_quantity*1 || 0;
      ctrl.item.on_hand = (ctrl.item.on_hand - ctrl.quantity_to_remove);
      ctrl.item.expiry_dates = ctrl.item.expiry_dates.filter(function(obj) {
        if(!obj.remove){
          return obj;
        }
      });

      inventoryItemService.updateItem(ctrl.item)
        .then(function (inventory_item) {
          alertsDataCarrierService.load_alerts();
          var t_message = $filter('translate')('INVENTORY.ITEM_DELETED');
          sgToast.showSimple(t_message);
        }).catch(function(error){
          var t_message = $filter('translate')('ERRORS.INVENTORY_REMOVE')
          errorService.uiErrorHandler(error || t_message, 0);
        });

    }

    /**
     * close modal dialog
     */
    function close_dialog() {
      $mdDialog.cancel();
    }

    /***Dialog Operations ***/

    /**
     * @method hide
     * @description hide the dialog
     */
    function hide() {
      $mdDialog.hide();
    }

    function cancel() {
      $mdDialog.cancel();
    }

    function answer(answer) {
      if(validate_form()){
        $mdDialog.hide(answer);
      }
    }

    /***End dialog operations ***/

    function validate_form() {
      ctrl.removeQtyDates.$setSubmitted();
      if(ctrl.removeQtyDates.$invalid || !check_selected(ctrl.item)){
        return false;
      } else {
        return true;
      }
    }

    /**
     * @method remove_from_list
     * @param {*} item the element to remove
     */
    function remove_from_list(item) {

      var removed = _.remove(ctrl.expiring, function (al) {
        return al.id === item.id;
      });
    }

    function getOnHand (item) {
      return inventoryItemService.calculateInventoryOnHand(item);
    }

    /**
     * If the disable_add_to_cart variable is true, return false. Otherwise, return true if the item
     * has a product_id.
     * @param item - The item object
     * @returns Boolean indicating if the item can be added to cart.
     */
    function isAddToCartEnabled(item) {
      if (ctrl.disable_add_to_cart) {
        return false;
      }
      return Boolean(item?.product_id);
    }

    /**
     * Determines if shopping list button should be hidden based on:
     * 1. If the office has disabled shopping list
     * 2. If the implant is discontinued (item.UI.is_discontinued)
     * @returns {boolean}
     */
    function shouldHideShoppingList (item) {
      return ctrl.disable_shopping_list || item.UI.is_discontinued || !item.can_purchase;
    }
    
    /**
     * Determines if shopping list column should be hidden based on:
     * 1. If the office has disabled shopping list
     * 2. If all items are discontinued
     * @returns {boolean}
     */
    function shouldHideShoppingListColumn () {
      return ctrl.disable_shopping_list || inventoryItemService.allItemsDiscontinued(ctrl.expiring);
    }

    function defineLocks() {
      const master_permission_condition = AccessService.getPermission('master_permission');
      
      const add_to_cart_condition = AccessService.getPermission('marketplace_shopping_cart');
      ctrl.disable_add_to_cart = !add_to_cart_condition && !master_permission_condition;

      ctrl.disable_shopping_list = !AccessService.hasShoppingListAccess();

      ctrl.hideCartButton = !AccessService.hasCartAccess();
      ctrl.has_multi_supplier_selection = AccessService.getProperty('shopping_list.multi_supplier_selection');
    }

  }
}());

(function () {
  'use strict';

  angular.module('sowAlerts')
    .service('alertsDataCarrierService', alertsDataCarrierService)

  function alertsDataCarrierService($filter, sowAlertService, errorService) {
    /*jshint validthis: true */
    var service = this;
    service.is_loading = true;
    service.total_alerts = 0;
    service.load_alerts = load_alerts;
    service.implants = {
      below_minimum_level: 0,
      expiring_soon: 0
    };
    service.certifications = {
      expired_certifications: 0,
      upcoming_renewals: 0
    };
    service.inventory = {
      below_minimum_level: 0,
      expiring_soon: 0
    };

    function load_alerts() {
      if (sow.userInfo() && sow.officeInfo()) {
        sowAlertService.getDashboardAlerts()
          .then(function (alerts) {
            var total = 0;


            if (sow.officeFeatures().implants && alerts.implants) {
              var manufacturers_minimum_ids = Object.keys(alerts.implants.below_minimum_level);
              var manufacturers_expiring_ids = Object.keys(alerts.implants.expiring_soon);

              service.implants = {
                below_minimum_level: implant_total(manufacturers_minimum_ids, alerts.implants.below_minimum_level),
                expiring_soon: implant_total(manufacturers_expiring_ids, alerts.implants.expiring_soon)
              };

              total = service.implants.below_minimum_level;
              total += service.implants.expiring_soon;
            }

            if (sow.officeFeatures().certifications && alerts.certifications) {
              service.certifications = {
                expired_certifications: alerts.certifications.expired_certifications,
                upcoming_renewals: alerts.certifications.upcoming_renewals
              };
              total += service.certifications.expired_certifications + service.certifications.upcoming_renewals;
            }

            if (alerts.inventory) {
              service.inventory = {
                below_minimum_level: alerts.inventory.below_minimum_level,
                expiring_soon: alerts.inventory.expiring_soon
              };
              total += service.inventory.below_minimum_level + service.inventory.expiring_soon;
            }

            if (sow.officeFeatures().medications && alerts.medications) {
              total += alerts.medications.below_minimum_level.emergency_medications
                + alerts.medications.below_minimum_level.medications
                + alerts.medications.expiring_soon.emergency_medications
                + alerts.medications.expiring_soon.medications;
            }
            if (sow.officeFeatures().sterilization && alerts.sterilization) {
              total += alerts.sterilization.days_since_last_chemical_test + alerts.sterilization.pending_biological_records;
            }

            service.total_alerts = total;


          }).catch(function (error) {
            var t_message = $filter('translate')('ERRORS.SERVER_TRY_AGAIN')
            errorService.uiErrorHandler(error || t_message);
          });
      }
    }

    function implant_total(ids, alerts) {
      var total = 0;
      ids.forEach(function (id) {
        var categories = Object.keys(alerts[id]);
        categories.forEach(function (category) {
          total += alerts[id][category];
        });
      });

      return total;
    }

    return service;
  }

}());

angular.module("app.marketplace.elements")

.service("inventoryItemModel", function ($injector, $rootScope, apiUrl, commonApiService) {
  var _this = this;
  this.model_data = {
    "name" : "inventoryItem",
    "display_name" : "Inventory Item",
    "api" : {
      "single" : function(options){
        if(!checkNested(options,'data', 'id')){
          throw "Missing option(s) for inventory_items path 'single'. Options: " + JSON.stringify(options);
        }
        return {
          path : commonApiService.getOfficeUrl() + "/inventory/items/" + options.data.id,
          params : {
            method : "GET"
          }
        };
      },

      'qrcode_label_pdf': function (options) {
        return {
          path: '{0}/inventory/qrcodes/label-sheet'.format(apiUrl),
          params: {
            method: 'POST',
            data: {
              async: options.async,
              item_ids: _.get(options, 'item_ids'),
              office_id: $rootScope.current_office.id,
            },
          }
        };
      },

      'qrcode_label_pdf_location': function (options) {
        options.location_id = options.location_id || ' ';

        return {
          'path': '{0}/inventory/locations/{1}/qrcode-label-sheet'.format(apiUrl, options.location_id),
          'params': {
            'method': 'GET',
            'responseType': 'arraybuffer',
            'headers': {'Accept': 'application/pdf, application/json; q=0.8'},
          },
        };
      },

      'remove_from_shopping_list': function (options) {
        // this is supposed to be used with inventoryItems only
        // when calling from the marketplace, first we need to get
        // the correct inventory item to be passed here
        let id = _.get(options, 'item.id');
        if (!id) {
          throw 'Cannot remove an item from the shopping list without an id.';
        }

        return {
          path: `${apiUrl}/inventory/shopping-list/${id}`,
          params: { method: 'DELETE' },
        };

      },

      /**
       * Takes an array of inventory items and removes them from the shopping list
       * @param {object} options
       * @param {Array<object>} options.items
       * @return {object}
       */
      batch_remove_from_shopping_list: options => {
        const item_ids = _.map(options?.items, ({id}) => id);
        if (item_ids.length === 0) {
          throw 'Invalid items argument';
        }

        return {
          params: {
            data: {
              item_ids,
              office_id: $rootScope.current_membership.office.id,
            },
            headers: {
              'content-type': 'application/json',
            },
            method: 'DELETE',
          },
          path: `${apiUrl}/inventory/shopping-list`,
        };
      },

      'get_inventory_po_status': function (options) {
        return '{0}/inventory/po-status'.format(apiUrl);
      },

      'get_categories': function (options) {
        return '{0}/inventory/categories'.format(apiUrl);
      },

      'get_shopping_list_items': function (options) {
        return '{0}/inventory/shopping-list'.format(apiUrl);
      },

      "add_to_shopping_list": function (options) {
        if (!_.has(options, 'items')) {
          throw 'Missing option(s) for add_to_shopping_list.';
        }

        return {
          'path': '{0}/inventory/shopping-list'.format(apiUrl),
          'params': {
            'method': 'POST',
            'data': {
              'items': options.items || [],
              ...options.vendor_info,
            }
          }
        };
      },

      "batch_location_upload": function (options) {
        if (!_.has(options, 'items') || !_.has(options, 'location')) {
          throw 'Missing option(s) for batch_location_upload.';
        }

        return {
          'path': '{0}/inventory/batch_location_upload'.format(apiUrl),
          'params': {
            'method': 'POST',
            'data': {
              'items': options.items,
              'location': options.location
            }
          }
        };
      },

      "office_inventory" : function(options){
        var params = angular.extend({method: "GET"}, options.params);
        options = options || {};
        _.set(options, 'mode', 'wofe-list');
        let url = new URL(commonApiService.getOfficeUrl() + "/inventory/items");
        url.searchParams.append("mode", options.mode);
        url.searchParams.append("all", options.all);
        
        const http_object = {
          path: url.toString(),
          params: params
        };

        return http_object;
      },

      "office_inventory_elastic" : function(options){
        return {
          path : commonApiService.getOfficeUrl() + "/inventory/elastic?all=true",
          type : "elastic",
          params : {
            method : "POST",
            data : {}
          }
        };
      },

      "office_inventory_elastic_search" : function(options){
        if(!checkNested(options,'search')){
          throw "Missing option(s) for inventory_items path 'office_inventory_elastic_search'. Options: " + JSON.stringify(options);
        }
        return {
          path : commonApiService.getOfficeUrl() + "/inventory/search",
          type : "elastic",
          params : {
            method: "POST",
            data: {
              search: options.search,
              supplier_id: options.supplier_id,
            }
          }
        };
      },

      "office_inventory_log" : function(options){
        if(!checkNested(options,'office', 'id')){
          throw "Missing option(s) for inventory_items path 'office_inventory_log'. Options: " + JSON.stringify(options);
        }
        return {
          path : apiUrl + '/offices/' + options.office.id + '/inventory/logs',
          params : {
            method : "GET"
          }
        };
      },

      "office_inventory_activity" : function(options){
        if(!checkNested(options,'office', 'id')){
          throw "Missing option(s) for inventory_items path 'office_inventory_activity'. Options: " + JSON.stringify(options);
        }

        var path = apiUrl + '/offices/' + options.office.id + '/inventory/events';
        if(options.page || options.per_page){
          path += '?';
          if(options.page){
            path += 'page='+options.page;
          }
          if(options.per_page){
            path += 'per_page='+options.per_page;
          }
        }

        return {
          path : path,
          params : {
            method : "GET"
          }
        };
      },

      "search_templates_and_products" : function(options){
        if(!checkNested(options,'name') && !checkNested(options,'manufacturer_name')){
          throw "Missing option(s) for inventory_items path 'search_templates_and_products'. Options: " + JSON.stringify(options);
        }
        return {
          path : commonApiService.getOfficeUrl() + "/search/add-inventory-item?" + (options.name ? "query=" + options.name : "") + (options.manufacturer_name ? "&manufacturer=" + options.manufacturer_name : ""),
          params : {
            method : "GET"
          }
        };
      },

      'search_generic_products': function (options) {

        if (!checkNested(options,'name') || !checkNested(options,'medical_field_id')) {
          throw "Missing option(s) for inventory_items path 'search_generic_products'. Options: " + JSON.stringify(options);
        }

        var url = '{0}/search/generic-inventory-item?query={1}&medical_field_id={2}'.format(
          commonApiService.getOfficeUrl(),
          options.name,
          options.medical_field_id
        );

        if (options.medical_field_subtype) {
          url += '&medical_field_subtype=' + options.medical_field_subtype;
        }

        return {
          path: url,
          params: {method: 'GET'},
        };
      },

      "search_via_product" : function(options){
        if(!checkNested(options,'product_id')){
          throw "Missing option(s) for inventory_items path 'search_via_product'. Options: " + JSON.stringify(options);
        }
        return {
          path : commonApiService.getOfficeUrl() + "/inventory/products?product_id=" + options.product_id,
          params : {
            method : "GET"
          }
        };
      },

      "create_from_product" : function(item){
        if(!checkNested(item,'office_id')){
          throw "Missing option(s) for inventory_items path 'create'. Options: " + JSON.stringify(item);
        }
        //TODO reassess, migrate to pre-parsing area?
        if(item.expiry_date){
          if(!item.expiry_date.toISOString){
            item.expiry_date = new Date(item.expiry_date);
          }
          item.expiry_date = item.expiry_date.toISOString();
        }
        //TODO clean endpoint to be standardized.
        if (!item.manufacturer_id) {
          item.manufacturer_id = _.get(item, 'manufacturer.id', _.get(item, 'manufacturer_object.id', null));
        }
        if (!item.manufacturer_name) {
          item.manufacturer_name =  _.get(item, 'manufacturer.name', _.isString(item.manufacturer) ? item.manufacturer : null);
        }
        
        if(!item.on_hand){
          item.on_hand = 0;
        }

        return {
          path : apiUrl + "/offices/" + item.office_id + "/inventory/products",
          params : {
            method : "POST",
            data : item,
            update : 'full'
          }
        };
      },

      "create_from_user_product" : function(item){
        if(!checkNested(item,'office_id')){
          throw "Missing option(s) for inventory_items path 'create'. Options: " + JSON.stringify(item);
        }
        //TODO reassess, migrate to pre-parsing area?
        if(item.expiry_date){
          if(!item.expiry_date.toISOString){
            item.expiry_date = new Date(item.expiry_date);
          }
          item.expiry_date = item.expiry_date.toISOString();
        }
        //TODO clean endpoint to be standardized.
        if (!item.manufacturer_id) {
          item.manufacturer_id = _.get(item, 'manufacturer.id', _.get(item, 'manufacturer_object.id', null));
        }
        if (!item.manufacturer_name) {
          item.manufacturer_name =  _.get(item, 'manufacturer.name', _.isString(item.manufacturer) ? item.manufacturer : null);
        }
        if(!item.on_hand){
          item.on_hand = 0;
        }
        item.inventory_category_id = getCategoryId(item);
        item.upc = item.code;

        return {
          path : apiUrl + "/offices/" + item.office_id + "/inventory/user_products",
          params : {
            method : "POST",
            data : item,
            update : 'full'
          }
        };
      },

      "update" : function(item){
        if(!checkNested(item,'office_id') || !checkNested(item,'id')){
          throw "Missing option(s) for inventory_items path 'update'. Options: " + JSON.stringify(item);
        }
        item = $.extend({}, item);
        if(item.expiry_date){
          delete item.expiry_date;
        }

        return {
          path : apiUrl + "/offices/" + item.office_id + "/inventory/items/" + item.id,
          params : {
            method : "PUT",
            data : item,
            update : 'full'
          }
        };
      },
      "add_stock" : function(item){
        if(!checkNested(item,'office_id') || !checkNested(item,'id') || !checkNested(item,'in')){
          throw "Missing option(s) for inventory_items path 'add_stock'. Options: " + JSON.stringify(item);
        }
        var data = {
          in: getInOuts(item, item.in.in),
          expiry_date: item.in.expiry_date,
        };
        //TODO reassess, migrate to pre-parsing area?
        if(data.expiry_date){
          if(!data.expiry_date.toISOString){
            data.expiry_date = new Date(data.expiry_date);
          }
          data.expiry_date = data.expiry_date.toISOString();
        }

        return {
          path : apiUrl + "/offices/" + item.office_id + "/inventory/items/" + item.id + "/in",
          params : {
            method : "PUT",
            data : data,
            update : 'full'
          }
        };
      },
      "remove_stock" : function(item){
        if(!checkNested(item,'office_id') || !checkNested(item,'id') || !checkNested(item,'out')){
          throw "Missing option(s) for inventory_items path 'remove_stock'. Options: " + JSON.stringify(item);
        }
        var data = {
          out: getInOuts(item,  item.out.out)
        };
        return {
          path : apiUrl + "/offices/" + item.office_id + "/inventory/items/" + item.id + "/out",
          params : {
            method : "PUT",
            data : data,
            update : 'full'
          }
        };
      },
      'update_items' : function(options){
        if(!checkNested(options,'items')){
          throw "Missing option(s) for inventory_items path 'update_items'. Options: " + JSON.stringify(item);
        }
        return {
          path : commonApiService.getOfficeUrl() + "/inventory/items",
          params : {
            method : "PUT",
            data : {'items': options.items}
          }
        };
      },

      remove: function (item) {
        if (!checkNested(item, 'office_id') || !checkNested(item, 'id')) {
          throw 'Missing option(s) for inventory_items path "remove". Options: ' + JSON.stringify(item);
        }
        return {
          path: sprintf('%s/inventory/items/%s', commonApiService.getOfficeUrl(), item.id),
          params: {
            method: 'DELETE'
          }
        };
      },

      "add_new_location" : function(options){
        if(!checkNested(options,'item_id') || !checkNested(options,'location_name')){
          throw "Missing option(s) for office path 'new_location'. Options: " + JSON.stringify(options);
        }
        return {
          path : commonApiService.getOfficeUrl() + "/inventory/locations",
          params : {
            method : "POST",
            data : {
                name: options.location_name,
                item_id: options.item_id
            }
          }
        };
      }
    }
  };

  this.autofill = function(item) {
    //Combine the two string arrays into a proper array of objects. (So that 'PUT' can be called on the object)
    if(item.location_names && item.location_names.length){
      item.locations = [];
      _.map(item.location_names, function(x,l){
        item.locations.push({
          'id' : item.location_ids[l],
          'name' : item.location_names[l]
        });
      });
    }

    //
    // The MSDS Url is in a mapping under `officeService.inventory.msds`. Look
    // it up by our ID to see if we have anything there.
    //
    // TODO We might want to create an endpoint just for getting an MSDS <=>
    // product_id mapping, and use that rather than sticking the msds map onto
    // the inventory items response.
    //
    var officeService = $injector.get('officeService');
    item.msds_url = _.get(officeService.getMsdsMap(), item.id);

    if (item.inventory_locations && item.inventory_locations.length) {
      item.locations = item.inventory_locations;
      delete item.inventory_locations; // Avoid update conflicts;
    }

    if (!item.image_url_src && item.image) {
      item.image_url_src = item.image.length ? item.image[0].url_240_box : item.image.url_240_box;

    }

    // Note: category_images has been removed from the backend, so this will
    // get removed soon.
    if (item.category_images && item.category_images.length) {
      item.category_image_url_240_box = item.category_images[0].url_240_box;
    } else {
      item.category_image_url_240_box = item.image_url_src;
    }

    //
    // Calculate Most Recent Expiry Date
    //
    item.expiry_date = null;

    if (_.size(item.expiry_dates)) {
      for (var i = 0; i < item.expiry_dates.length; ++i) {
        if (!item.expiry_date || moment(item.expiry_dates[i].date) < item.expiry_date) {
          item.expiry_date = moment(item.expiry_dates[i].date);
        }
      }
    }

    item.has_expiry_date = !!item.expiry_date;

    item.code = item.upc && item.upc.code ? item.upc.code : item.code;

    //
    // Calculate Errors
    //
    item.errors = {};

    if (item.minimum_level && item.on_hand <= item.minimum_level) {
      item.errors['below_minimum_level'] = true;
    }

    if (item.expiry_date && moment(item.expiry_date) < moment()) {
      item.errors['expired'] = true;
    } else if (item.expiry_date && moment().add(60, 'd') > moment(item.expiry_date) && moment(item.expiry_date) > moment()) {
      item.errors['expiring_soon'] = true;
    }

    item.error_count = Object.keys(item.errors).length;

    if (!item.manufacturer) {
      item.manufacturer = {
          name : item.manufacturer_name,
          id : item.manufacturer_id
      };
    }
  };

  this.fields = {
    "id" : {
      "display_name" : "id",
      "required" : true,
      "api" : {
        "submit" : true
      },
      "type" : "integer"
    },
    "office_id" : {
      "display_name" : "office_id",
      "required" : true,
      "api" : {
        "submit" : true
      },
      "type" : "integer"
    },
    "product_id" : {
      "display_name" : "product_id",
      "required" : true,
      "api" : {
        "submit" : true
      },
      "type" : "integer"
    },
    "product_template_id" : {
      "display_name" : "product_template_id",
      "api" : {
        "submit" : true
      },
      "type" : "integer"
    },
    "user_category_id" : {
      "display_name" : "user_category_id",
      "api" : {
        "submit" : true
      },
      "type" : "string"
    },

    "inventory_category_id" : {
      "display_name" : "inventory_category_id",
      "api" : {
        "submit" : true
      },
      "type" : "string"
    },

    "category_id" : {
      "display_name" : "category_id",
      "api" : {
        "submit" : true
      },
      "type" : "string"
    },

    "subcategory_id" : {
      "display_name" : "subcategory_id",
      "api" : {
        "submit" : true
      },
      "type" : "string"
    },

    "on_hand" : {
      "display_name" : "on_hand",
      "required" : true,
      "api" : {
        "submit" : true
      },
      "type" : "integer"
    },

    "in" : {
      "display_name" : "in",
      "required" : true,
      "api" : {
        "submit" : true
      },
      "type" : "object"
    },

    "out" : {
      "display_name" : "out",
      "required" : true,
      "api" : {
        "submit" : true
      },
      "type" : "integer"
    },

    "expiry_date" : {
      "display_name" : "expiry_date",
      "required" : true,
      "api" : {
        "submit" : true
      },
      "type" : "date"
    },
    "expiry_dates" : {
      "display_name" : "expiry_dates",
      "required" : true,
      "api" : {
        "submit" : true
      },
      "type" : "array"
    },
    "location" : {
      "display_name" : "location",
      "required" : true,
      "api" : {
        "submit" : true
      },
      "type" : "date"
    },
    "locations" : {
      "display_name" : "locations",
      "required" : true,
      "api" : {
        "submit" : true
      },
      "type" : "array"
    },
    "minimum_level" : {
      "display_name" : "minimum_level",
      "required" : true,
      "api" : {
        "submit" : true
      },
      "type" : "integer"
    },
    "desired_level" : {
      "display_name" : "desired_level",
      "required" : true,
      "api" : {
        "submit" : true
      },
      "type" : "integer"
    },
    "code" : {
      "display_name" : "code",
      "required" : true,
      "api" : {
        "submit" : true
      },
      "type" : "string"
    },
    "no_upc" : {
      "display_name" : "no_upc",
      "required" : true,
      "api" : {
        "submit" : true
      },
      "type" : "boolean"
    },
    "price" : {
      "display_name" : "price",
      "required" : true,
      "api" : {
        "submit" : true
      },
      "type" : "number"
    },
    "name" : {
      "display_name" : "name",
      "required" : true,
      "api" : {
        "submit" : true
      },
      "type" : "string"
    },
    "manufacturer_name" : {
      "display_name" : "manufacturer_name",
      "required" : true,
      "api" : {
        "submit" : true
      },
      "type" : "string"
    },
    "manufacturer_id" : {
      "display_name" : "manufacturer_id",
      "required" : true,
      "api" : {
        "submit" : true
      },
      "type" : "string"
    },
    "vendor_name" : {
      "display_name" : "vendor_name",
      "required" : true,
      "api" : {
        "submit" : true
      },
      "type" : "string"
    },
    "vendor_id" : {
      "display_name" : "vendor_id",
      "required" : true,
      "api" : {
        "submit" : true
      },
      "type" : "string"
    },
    "manufacturer" : {
      "display_name" : "manufacturer",
      "api" : {
        "submit" : true
      },
      "type" : "text"
    },
    "notes": {
      "display_name": "notes",
      "api": {"submit": true},
      "type": "text"
    },
    "sku": {
      "display_name": "sku",
      "api": {"submit": true},
      "type": "text"
    },
    "tracking_method": {
      "display_name": "tracking_method",
      "api": {"submit": true},
      "type": "text"
    },
    "item_package_quantity": {
      "display_name": "item_package_quantity",
      "api": {"submit": true},
      "type": "number"
    },
    "order_package_quantity": {
      "display_name": "order_package_quantity",
      "api": {"submit": true},
      "type": "number"
    },
    "supplier_id": {
      "display_name": "supplier_id",
      "api": {"submit": true},
      "type": "string"
    },
    "supplier": {
      "display_name": "supplier",
      "api": {"submit": true},
      "type": "string"
    },
    "buy_by_case": {
      "display_name": "buy_by_case",
      "api": {"submit": true},
      "type": "boolean"
    },
    "sds_url" : {
      "display_name" : "SDS",
      "required" : true,
      "api" : {
        "submit" : true
      },
      "type" : "array"
    }
  };

  this.data = {
    "params" : {
      "model_name" : "inventoryItem"
    }
  };


  function getInOuts(item, quantity) {
    if (item.tracking_method === 'Package') {
      return quantity * item.order_package_quantity || 1;
    }

    return quantity;
  }


  /**
   * Returns the category ID of a given item
   * If the item has a subcategory ID, it returns that.
   * Otherwise, it returns the category ID. If the item has neither, it returns null.
   * @param {object} item
   * @return {string | null}
   */
  function getCategoryId(item) {
    return item.subcategory_id ?? item.category_id ?? null;
  }


});

angular.module("app.marketplace.ui.inventory")
  .controller("inventoryItemRowController",
    function ($scope, $rootScope, mktHelperService) {
      $scope.newSlideout = function (item) {
        if ($scope.supress_product_slideout) {
          return;
        }
        $rootScope.$broadcast('inventory-item-slideout', item);
      };
      $scope.toggleExtraMenuDropdown = function (value) {
        if (value || value === false) {
          $scope.open_more_menu = value;
        } else {
          $scope.open_more_menu = !$scope.open_more_menu;
        }
      };

      $scope.getVendorText = function (item) {
        var str = "";
        if (item.vendor_name) {
          str += item.vendor_name;
          if (item.manufacturer_name && (item.manufacturer_name !== item.vendor_name)) {
            str = "From: " + str + ", By: " + item.manufacturer_name;
          }
        } else {
          str = item.manufacturer_name;
        }
        return str;
      };

      $scope.showImageDetail = function (_src) {
        $rootScope.$broadcast('inventory-detail-image', _src);
      };

      $scope.showProductDetails = showProductDetails;

      function showProductDetails(item) {
        mktHelperService.productDetailsFetch(item.product_id);
      }
    });

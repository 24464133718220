var AccountModalService = function ($rootScope, $mdDialog, accountModalTemplates, $filter) {
  var _this = this;

  var _forms = {
    Login: {
      title: $filter('translate')('ACTIONS.SIGN_IN_ACC'),
      template: accountModalTemplates.Login || 'templates/marketplace/account/modals/account-modal-login-form.html'
    },
    PasswordReset: {
      title: $filter('translate')('OFFICE.PROFILE.RESET_PW'),
      template: accountModalTemplates.PasswordReset || 'templates/marketplace/account/modals/account-modal-reset-password.html'
    },
    SelectMembership: {
      title: $filter('translate')('ACTIONS.SELECT_OFFICE'),
      template: accountModalTemplates.SelectMembership || 'templates/marketplace/account/modals/membership-select.html'
    },
    ChangeMembership: {
      title: $filter('translate')('ACTIONS.SELECT_OFFICE'),
      template: accountModalTemplates.ChangeMembership || 'templates/marketplace/account/modals/membership-select.html'
    },
    CreateAnOffice: {
      title: $filter('translate')('ACTIONS.CREATE_OFFICE'),
      template: accountModalTemplates.CreateAnOffice || 'templates/marketplace/office/forms/new-office.html'
    }
  };

  var _show = function (form, $event, options) {
    if (_this.$modal) {
      _this.$modal.setForm(form);
      return _this.$modal.promise;
    } else {
      _this.$modal = _newModal(form, $event, options);
      return _this.$modal.promise;
    }
  };

  var _hide = function () {
    if (!_this.$modal) {
      return;
    } else {
      _this.$modal.hide();
    }
  };

  var _newModal = function (form, $event, options) {
    console.debug('DEBUG: options %o', options);
    var $modal = {
      promise: null,
      config: {
        parent: angular.element(document.body),
        templateUrl: accountModalTemplates.AccountModal || 'templates/marketplace/account/modals/account-modal.html',
        targetEvent: $event,
        controllerAs: 'ctrl',
        clickOutsideToClose: false,
        escapeToClose: false,
        locals: {'options': options},
      }
    };

    /* @ngInject */
    $modal.config.controller = function($scope, $rootScope, $log, $mdDialog, options) {
      var _this = this;

      $scope.mdDialogOptions = options;

      $scope.close = function () {
        $mdDialog.cancel();
      };

      $scope.hide = function () {
        $mdDialog.hide();
      };

      $scope.setForm = function (formName) {
        if (!_forms[formName]) {
          $log.error('No Such Account Modal Form: ' + formName);
        } else {
          _this.form = _forms[formName];
        }
      };

      angular.extend($modal, {
        close: $scope.close,
        setForm: $scope.setForm,
        hide: $scope.hide
      });

      $scope.setForm(form);
    };

    $modal.promise = $mdDialog.show($modal.config);
    $modal.promise['finally'](function () {
      _this.$modal = null;
    });

    return $modal;
  };

  return {
    $modal: null,
    show: _show,
    hide: _hide
  };
};

angular.module('app.marketplace.elements').service('AccountModalService', AccountModalService);


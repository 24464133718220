angular.module('app.marketplace.system')

.service('StepsService', StepsService);

function StepsService() {
  return function (options) {
    options = options || {};

    this.current = options.current || 1;

    this.total = options.total || 4;

    this.isCurrent = function(step) {
      return step === this.current;
    };

    this.next = function() {
      if (!options.validator) {
        this.current = this.current + 1;
        return;
      }

      if (options.validator(this.current)) {
        this.current = this.current + 1;
      }
    };

    this.previous = function() {
      this.current = this.current - 1;
    };
  };
}

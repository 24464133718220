(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.orders')
    .controller('recentOrdersController', RecentOrdersController);

  function RecentOrdersController ($scope, $log, $state) {
    /*jshint validthis: true */
    var ctrl = this;

    var poStatusIcons = {
      'Received' : {
        'name': 'check',
        'class': 'green-icon'
      },
      'Draft' : {
        'name': 'mode_edit',
        'class': 'blue-icon'
      },
      'Active' : {
        'name': 'local_shipping',
        'class': 'blue-icon'
      },
      'Cancelled' : {
        'name': 'not_interested',
        'class': 'red-icon'
      },
    };

    ctrl.orders = [];
    ctrl.openOrder = openOrder;

    init();

    return ctrl;

    function init () {
      $scope.$watchCollection('allOrders', updateOrders);
    }

    function updateOrders (orders) {
      ctrl.orders = [];

      _.forEach(orders, function (order) {
        var type = order.params.model_name;
        var icon = _.get(poStatusIcons, order.status || order.status_name);

        ctrl.orders.push({
          'id': order.id,
          'type': type,
          'icon': icon,
          'name': (
            type === 'purchaseOrder' ? order.name
            : type === 'return' ? 'Return'
            : type === 'exchange' ? 'Exchange'
            : 'Order'
          ),
          'status': order.status || order.status_name,
          'sort_date': order.sort_date,
          // 'sort_date': type === 'purchaseOrder' ? order.updated_at : order.sort_date,
          'item_count': (
            type === 'order' ? order.item_count
            : type === 'return' ? 1
            : type === 'exchange' ? 1
            : type === 'purchaseOrder' ? order.item_count
            : 0
          ),
          // Note: This is here for the sowingo-order-icon directive to access it.
          'params': {'model_name': type},
        });
      });
    }

    function openOrder (order) {
      if (order.type === 'purchaseOrder') {
        var stateName = order.status === 'Draft' ? 'app.orders.purchase.draft' : 'app.orders.purchase.active';
        $state.go(stateName, {'poId': order.id});
      }
      else if (_.includes(['order', 'exchange', 'return'], order.type)) {
        $state.go('app.orders.sowingo', {orderId: order.id});
      }
      else {
        $log.error('Unknown Model Type: %o', order.type);
      }
    }
  }
}());

(function () {
  'use strict';
  
  angular
  .module('sowReports')
  .service('sowReportsService',sowReportsService);
  
  function sowReportsService ($mdDialog, apiUrl, serverAPI, accountService, errorService, $filter, coreUIService) {
    /*jshint validthis: true */
    var service = this;
    
    service.exportImplantInventory = exportImplantInventory;
    service.exportImplants = exportImplants;
    service.exportLocations = exportLocations;
    service.exportShoppingList = exportShoppingList;
    service.exportSuppliers = exportSuppliers;
    service.exportSuccessDialog = exportSuccessDialog;
    service.exportSterilization = exportSterilization;
    
    return service;

    function exportImplantInventory () {
      return exportReport('implant_inventories_report', 'REPORTS.EMAIL.TYPE_IMPLANT_INVENTORY');
    }
    
    function exportShoppingList () {
      return exportReport('shopping_list_report', 'COMMON.SHOPPING_LIST');
    }
    
    function exportImplants () {
      return exportReport('implants_report', 'REPORTS.EMAIL.TYPE_IMPLANT_USAGE');
    }
    
    function exportLocations () {
      return exportReport('inventory_locations_report', 'COMMON.STORAGE_LOCATIONS');
    }
    
    function exportSuppliers () {
      return exportReport('inventory_suppliers_report', 'COMMON.SUPPLIERS');
    }

    function exportSterilization () {
      return exportReport('sterilization/send_report_email', 'STERILIZATION.RECORDS', null, {
        // API requires a timezone so they can convert the date to the correct timezone
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
      });
    }
    
    function exportReport (report_path, report_type, filetype, extra_payload) {
      const office_id = sow.officeInfo().id;
      const user = accountService.get();
      const url = `${apiUrl}/offices/${office_id}/${report_path}`;
      
      const options = {
        'method': 'POST', 
        'data': {
          'name': user.name,
          'email': user.email,
          ...extra_payload
        }
      };
      
      return serverAPI
      .doAPICall(url, options)
      .then(function (response) { 
        exportSuccessDialog(report_type, user.email, filetype);
        return response.data;
      })
      .catch(errorService.uiErrorHandler);
    }
    
    function exportSuccessDialog (report_type, email, filetype) {
      const user = accountService.get();
      email = email || user.email;
      let headline_key = 'DIALOG_TITLE';
      if (report_type === 'REPORTS.EMAIL.TYPE_CODES') {
        headline_key = 'TYPE_CODES_TITLE'
      }
      const headline = $filter('translate')(`REPORTS.EMAIL.${headline_key}`);
      const t_type = $filter('translate')(report_type);
      const tagline = $filter('translate')('REPORTS.EMAIL.DIALOG_TEXT', {
        email,
        type: t_type, 
        pdf: (filetype === 'pdf')
      });

      const actions = [coreUIService.primaryButton('PO.GOT_IT')];

      return coreUIService.showDialog(headline, tagline, actions);
    }
  }
}());

(function () {
    'use strict';
  
    angular.module('sowProduct')
      .controller('pdAdButlerController', pdAdButlerController)
      .directive('pdAdButler', pdAdButlerDirective);
  
    function pdAdButlerDirective() {
      return {
        restrict: 'E',
        templateUrl: 'sow-product-details/directives/pd-ad-butler.html',
        controller: 'pdAdButlerController',
        controllerAs: 'pdAdButlerCtrl',
        scope: {
          product: '='
        }
      };
    }
  
    function pdAdButlerController ($scope, adButlerService) {
      var ctrl = this;

      ctrl.butlers = [
        'box1',
        'box2',
        'box3',
        'leaderboard2',
      ];
      ctrl.dynamic_ads = false;

      $scope.$on('dynamic-ads-loaded', function(event, data) {
        ctrl.dynamic_ads = data;
      })
  
      init();
  
      return ctrl;
  
      function init() {
        ctrl.product = ctrl.product || $scope.product;
        adButlerService.setABKW([ctrl.product]);
        _.each(ctrl.butlers, function(key){
          adButlerService.autoLoadZone(key);
        });
        adButlerService.setDynamicAdAsInnerHTML('branded-product-dynamic');
      }
  
    }
  
  })();

(function () {
    'use strict';
    angular.module('sowMarketplace', [])
    .config(sowMarketplaceConfig);

    function sowMarketplaceConfig ($stateProvider, $urlRouterProvider) {
      var defaultUrl = '/app/mkt/landing/';
      $urlRouterProvider.when('/app/mkt/', defaultUrl);
      $urlRouterProvider.when('/app/mkt', defaultUrl);

      $stateProvider
        .state('app.mkt', {
          url: '/mkt',
          templateUrl: 'sow-mkt/views/mkt-index.html',
          controller: 'MarketplaceSectionController',
          controllerAs: 'mktSecCtrl',
          data: {
            'title': 'Marketplace',
            'action_bar_directive': 'action-bar-mkt',
            'require_log_in' : true,
          },
          redirectTo: 'app.mkt.landing'
        })
        .state('app.mkt.landing', {
          url: '/landing/?cat_focus',
          templateUrl: 'sow-mkt/views/mkt-main.html',
          controller: 'MarketplaceLandingController',
          controllerAs: 'mktlCtrl',
          params:{
            filter:''
          },
          data: {
            'title': 'Marketplace Landing',
          }
        })
        .state('app.mkt.list', {
          url: '/list/:page_type?facet_filters&numeric_filters&page&search&product_filters',
          templateUrl: 'sow-mkt/views/mkt-list.html',
          controller: 'MarketplaceListController',
          controllerAs: 'mktlsCtrl',
          params:{
            filter:'',
            facet_filters: null,
            numeric_filters: null,
            page: '1',
            search: null,
          },
          data: {
            'title': 'Product List',
          }
        })
        .state('app.mkt.search', {
          url: '/search/?cat_id&sub_id&mf_id&query&tag&vendor_id&vendor_name&mf_name&facet_filters&numeric_filters&page&on_sale',
          templateUrl: 'sow-mkt/views/mkt-search-results.html',
          controller: 'MarketplaceSearchController',
          controllerAs: 'mktsCtrl',
          params:{
            category: null,
            subcategory: null,
            manufacturer: null,
            vendor_id: null,
            vendor_name: null,
            mf_name: null,
            facet_filters: null,
            numeric_filters: null,
            page: '1',
            on_sale: null,
          },
          data: {
            'contractSidenav': true,
            'title': 'Search Results'
          }
        })
        .state('app.mkt.manufacturers', {
          url: '/manufacturers',
          templateUrl: 'sow-mkt/views/mkt-manufacturers.html',
          controller: 'MarketplaceManufacturersController',
          controllerAs: 'mktmCtrl',
          params:{},
          data: {
            'contractSidenav': true,
            'title': 'Manufacturers'
          }
        })
        .state('app.mkt.categories', {
          url: '/categories',
          templateUrl: 'sow-mkt/views/mkt-categories.html',
          controller: 'MarketplaceCategoriesController',
          controllerAs: 'mktctCtrl',
          params:{},
          data: {
            'contractSidenav': true,
            'title': 'Categories'
          }
        })
        .state('app.mkt.cart', {
          controller: 'ShoppingCartPageController',
          controllerAs: 'scpCtrl',
          data: {
            contractSidenav: true,
            title: 'Shopping Cart'
          },
          params: {},
          /* the params attribute is unused but prevents the issue where
          Angular renders the React component in multiple digest cycles */
          template: '<shopping-cart-page params="{{$stateParams}}" ng-on-event="scpCtrl.handleEvent($event)"></shopping-cart-page>',
          url: '/cart',
        })
        .state('app.mkt.order', {
          url: '/order',
          templateUrl: 'sow-mkt/views/mkt-order.html',
          controller: 'MarketplaceOrderController',
          controllerAs: 'mktoCtrl',
          params:{},
          data: {
            'contractSidenav': true,
            'title': 'Order'
          }
        })
        .state('app.mkt.vendor-order', {
          url: '/vendor-order',
          templateUrl: 'sow-mkt/views/mkt-order-2023.html',
          controller: 'MarketplaceVendorOrderController',
          controllerAs: 'mktvoCtrl',
          params:{},
          data: {
            contractSidenav: true,
            title: 'Order',
            action_bar_directive: undefined,
          }
        })
        .state('app.mkt.finished', {
          url: '/finished-order?order_number&search_param',
          template: '<order-confirmation-app params={{$stateParams}}></order-confirmation-app>',
          params:{
          },
          data: {
            action_bar_directive: undefined,
            'title': 'Finished Order'
          }
        })
        .state('app.mkt.pending', {
          url: '/pending-order?request_id&request_hrid',
          templateUrl: 'sow-mkt/views/mkt-pending-order.html',
          controller: 'PendingOrderController',
          controllerAs: 'pdoCtrl',
          params:{
            approval_request: null,
          },
          data: {
            'title': 'Pending Order'
          }
        })
        ;
    }

}());

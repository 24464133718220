(function () {
  'use strict';

  const sowMktCheckout = {
    selector: 'sowMktCheckout',
    templateUrl: 'sow-mkt/directives/mkt-checkout.html',
    controller: 'marketplaceCheckoutController',
    controllerAs: 'mktcoCtrl',
    bindings: {
      confirmationRequired: '=',
      showConfirmationForm: '=',
    },
  };

  angular.module('sowMarketplace')
    .controller(sowMktCheckout.controller, marketplaceCheckoutController)
    .component(sowMktCheckout.selector, sowMktCheckout);

  /** @ngInject */
  function marketplaceCheckoutController ($scope, $rootScope, $state, errorService, cartService, sgToast, $mdDialog, $filter) {
    /*jshint validthis:true*/
    var ctrl = this;

    ctrl.action_disabled = false;
    ctrl.cart_limit = null;
    ctrl.is_fetching_external_vendor_prices = false;

    const MOBILE_BREAKPOINT = 600;

    const width = angular.element(document).find('appContainer').offsetWidth;
    // By default, hide promo code section on mobile (toggleable)
    ctrl.hide_promo_code_section = width < MOBILE_BREAKPOINT;

    $scope.applyPromoCode = ctrl.applyPromoCode = applyPromoCode;
    $scope.removePromoCode = ctrl.removePromoCode = removePromoCode;
    $scope.resetPromoCodeField = ctrl.resetPromoCodeField = resetPromoCodeField;
    ctrl.completeOrder = completeOrder;
    ctrl.getAppliedPromoValue = getAppliedPromoValue;
    ctrl.hidePromoCodeAlert = hidePromoCodeAlert;
    ctrl.showPromoCodeSection = showPromoCodeSection;
    ctrl.getShippingTotal = getShippingTotal;
    
    ctrl.$onInit = init;

    return ctrl;

    function init () {
      _setInitialState();
      $scope.$on('cartService:begin-action:item-change', disableAction);
      $scope.$on('cartService:end-action:item-change', _handleItemChange);
      $scope.$on('order-form-changed', evaluateActionPermission);
      $scope.$on('mkt-shipping-address-updated', evaluateActionPermission);
      $scope.$on('billing-address-edit', evaluateActionPermission);
      $scope.$on('billing-address-saved', evaluateActionPermission);
      $scope.$on('cartService: set-cart', evaluateActionPermission);
      $scope.$on('cartService: clear-cart', evaluateActionPermission);
      $scope.$on('cartService: itemAddedToCart', evaluateActionPermission);
      $scope.$on('width-updated', function(event, props) {
        if (props.new >= MOBILE_BREAKPOINT) { // If the new window width is wider than a mobile screen...
          ctrl.hide_promo_code_section = false; // ...we need to display the promo code section
        }
      });
      $scope.$on('refresh-external-vendor-prices', () => {
        ctrl.is_fetching_external_vendor_prices = true;
        evaluateActionPermission();
      });
      $scope.$on('external-vendors-prices-refreshed', () => {
        ctrl.is_fetching_external_vendor_prices = false;
        evaluateActionPermission();
      });
      $scope.$on('prices-fetched-for-all-external-vendor-items', () => {
        ctrl.cart_has_external_vendor_item_without_price = false;
        evaluateActionPermission();
      });
      $scope.$on('price-fetch-failed-for-external-vendor-item', () => {
        ctrl.cart_has_external_vendor_item_without_price = true;
        evaluateActionPermission();
      });
    }

    /**
     * If the order contains one or more external vendors, the prices of their products must be
     * fetched from the API and refreshed. Disable checkout until we know.
     */
    function _setInitialState() {
      // disable order completion until cart can be evaluated
      disableAction();
      updateCart();
      // if this is an order which contains one or more external vendors, the prices
      // of their products must be fetched from the API and refreshed
      const is_external_vendor_order = ctrl.cart.vendor_groups.some(vendor => vendor.is_external_vendor);
      if (is_external_vendor_order) {
        ctrl.is_fetching_external_vendor_prices = true;
      }
      evaluateActionPermission();
      _getCartStats();
    }

    /**
     * Disables checkout if we're loading external vendor prices or if the cart contains
     * one or more items for whom we were unable to fetch an updated price. Otherwise,
     * enables checkout.
     */
    function evaluateActionPermission () {
      updateCart();
      // disable checkout if we're loading external vendor prices or if the cart contains
      // one or more items for whom we were unable to fetch an updated price
      if (ctrl.is_fetching_external_vendor_prices || ctrl.cart_has_external_vendor_item_without_price) {
        disableAction();
      } else {
        enableAction();
      }
    }

    function enableAction () {
      $rootScope.checkout_action_disabled = false;
      ctrl.action_disabled = false;
    }

    function disableAction () {
      $rootScope.checkout_action_disabled = true;
      ctrl.action_disabled = true;
    }

    function updateCart() {
      ctrl.cart = cartService.get();
      ctrl.cartIsEmpty = !checkNested(ctrl, 'cart', 'items', 'length') || ctrl.cart.items.length < 1;
      if (_.get(ctrl, 'cart.promo_code.id')) { // If a code has been entered...
        if (_.get(ctrl, 'cart.promo_code_applied')) { // ...check if it's been applied...
          ctrl.hide_promo_code_section = false; // ...and toggle open the promo code section if so.
          // Then set an alert with the appropriate type.
          ctrl.promo_code_alert = 'success'
        } else {
          ctrl.promo_code_alert = 'critical'
        }
      }
    }

    /**
     * Loads information about the cart
     */
    async function _getCartStats() {
      const cart_stats = await cartService.getCartStats({cart_id: ctrl.cart.id});
      ctrl.cart_stats = cart_stats;
      $scope.$apply();
    }

    /**
     * Callback for when the user updates a cart item
     */
    function _handleItemChange() {
      evaluateActionPermission();
      _getCartStats();
    }

    /**
     * The function returns the shipping total for a cart, taking into account whether any vendors
     * have a purchase order or if the shipping subtotal is zero.
     * @param {object} cart
     * @return {string}
     */
    function getShippingTotal (cart) {
      const {shipping_subtotal} = cart
      const subtotal = $filter('currency')(shipping_subtotal, '$', 2);
      
      // Check if cart vendor_groups contains at least on vendor with purchase_order
      if (cart.vendor_groups.some(vendor => vendor.checkout_type === 'purchase_order')) {
        return subtotal;
      }

      // Check if shipping_subtotal is equal to 0
      if (_.toNumber(shipping_subtotal) === 0) {
        return $filter('translate')('MARKETPLACE.DETAIL.FREE');
      }

      // Otherwise, return subtotal
      return subtotal;
    }

    function removePromoCode () {
      if (!ctrl.cart.promo_code) {
        return;
      }
      ctrl.saving = true;
      disableAction();

      cartService.removePromoCode()
      .then(function (response) {
        updateCart();
        var t_message = $filter('translate')('TOAST.PROMO_CODE_REMOVED');
        sgToast.showSimple(t_message);
        resetPromoCodeField();
        $scope.PromoCodeForm.$setPristine();
      })
      .catch(function (error) {
        var t_message = $filter('translate')('ERRORS.PROMO_REMOVE')
        errorService.uiErrorHandler(t_message, 0);
      })
      .finally(function(){
        ctrl.saving = false;
        ctrl.promo_code_alert = null;
        enableAction();
      });
    }

    function applyPromoCode () {
      if (!ctrl.cart.promo_code.code) {
        return false;
      }
      $scope.PromoCodeForm.promo_code.$setValidity('api_error', true);
      ctrl.promo_code_loading = true;

      cartService.updatePromoCode(ctrl.cart.promo_code.code)
      .then(function (response) {
        var t_message = $filter('translate')('TOAST.PROMO_CODE_APPLIED');
        sgToast.showSimple(t_message);
        $scope.PromoCodeForm.$setPristine();
        $scope.promoCodeErrorMsg = null;
        ctrl.promo_code_alert = 'success';
      })
      .catch(function (error) {
        $scope.PromoCodeForm.promo_code.$setValidity('api_error', false);
        $scope.promoCodeErrorMsg = error.message;
        ctrl.promo_code_alert = 'critical';
      })
      .finally(function(){
        ctrl.promo_code_loading = false;
      });
    }

    function resetPromoCodeField () {
      $scope.PromoCodeForm.promo_code.$setValidity('api_error', true);
      $scope.promoCodeErrorMsg = null;
      ctrl.promo_code_alert = null;
    }

    function completeOrder ($event) {
      // In order to keep the logic consistent in all controllers,
      // I've chosen to delegate this to the parent "MarketplaceOrderController" instead
      // Note: this will only work as long as the parent-child relation is kept.
      // That is: if <sow-mkt-checkout> is nested inside the order page
      $scope.$emit('Marketplace:complete-order');
    }
    
    function getAppliedPromoValue () {
      var value = _.get(ctrl.cart, 'promo_code_value_applied', 0);
      var text = $filter('currency')(value,'$',2);
      if (value > 0) {
        text = "- "+text;
      }
      return text;
    }

    function hidePromoCodeAlert() {
      ctrl.promo_code_alert = null;
    }

    function showPromoCodeSection() {
      ctrl.hide_promo_code_section = false;
    }

  }

})();

(function () {
  'use strict';

  angular
    .module('app.shared.ui')
    .directive('capitalize', CapitalizeDirective);

  function CapitalizeDirective () {
    return {
      'require': 'ngModel',
      'link': function (scope, element, attrs, modelCtrl) {
        var capitalize = CapitalizeParserFactory(modelCtrl);
        modelCtrl.$parsers.push(capitalize);
        capitalize(scope[attrs.ngModel]); // capitalize initial value
      },
    };
  }

  //
  // Factory For generating 'capitalize' $parsers function.
  //
  // We need to use a factory here rather than just passing a reference to the
  // function because the function needs access to a runtime reference to
  // modelCtrl, which is _not_ an argument to the $parser function.
  //
  function CapitalizeParserFactory (modelCtrl) {
    return function (inputValue) {
      if (inputValue === undefined) {
        inputValue = '';
      }

      var capitalized = inputValue.toUpperCase();

      if (capitalized !== inputValue) {
        modelCtrl.$setViewValue(capitalized);
        modelCtrl.$render();
      }

      return capitalized;
    };
  }
}());

//
// Required Login Controller
//
angular.module('app.marketplace.ui.account').controller('RequiredLoginController',
  function RequiredLoginController($scope, $state, $stateParams, AccountModalService, sessionService, officeService, RouteService) {
    if(!$stateParams.toState){
      RouteService.goToDefault();
    }else if(officeService.get()){
      //Changing office causes a state re-load so this is necessary in case the promise is short circuited
      proceedToState();
    }else{
      AccountModalService.show('Login');
    }

    sessionService.isSignedIn(true)
    .then(function(){
      officeService.get(true)
      .then(function(office){
        proceedToState();
      });
    });
    
    function proceedToState(){
      $state.go($stateParams.toState, $stateParams.toParams ? JSON.parse($stateParams.toParams) : null);
    }
  }
);

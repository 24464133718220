{
  'use strict';
  /** @ngInject */
  class WorkflowsService {
    constructor(CountryService, workflowApiUrl, localStorageService) {
      this.workflowApiUrl = workflowApiUrl;
      this.CountryService = CountryService;
      this.localStorageService = localStorageService;
    }

    getBaseUrl() {
      return this.CountryService.createLocalizedUrl(this.workflowApiUrl);
    }

    clearPermissions() {
      this.localStorageService.remove('permissions');
    }
  }

  angular.module('sowWorkflows').service('WorkflowsService', WorkflowsService);
}

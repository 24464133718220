(function () {
  'use strict';

  /** @ngInject */
  class sowTokenActionCtrl {
    constructor($scope, sowApprovalService, $stateParams) {
      this.$scope = $scope;
      this.sowApprovalService = sowApprovalService;
      this.$stateParams = $stateParams;
    }

    $onInit() {
      this.doAction();
    }

    // either accept, cancel or decline
    doAction () {
      this.loading = true;
      this.mode = this.getMode();
      this.token = this.getToken();
      const actions = {
        'accept': this.sowApprovalService.acceptToken,
        'cancel': this.sowApprovalService.cancelToken,
        'decline': this.sowApprovalService.declineToken
      };
      const action = actions[this.mode];
      return action(this.token)
      .then((action_response) => {
        this.success = true;
        this.approved_action = action_response;
      })
      .finally(() => {
        this.loading = false;
      });
      // no catch block because the default state is the fail state
    }

    getMode () {
      return this.$stateParams.mode;
    }

    getToken () {
      return this.$stateParams.token;
    }
    
  }

  const sowTokenAccept = {
    selector: 'sowTokenAccept',
    controller: 'sowTokenActionCtrl',
    controllerAs: 'ataCtrl',
    templateUrl: 'sow-approvals/views/token-accept.html',
    bindToController: true,
    scope: {
      mode: 'accept'
    },
  };

  const sowTokenCancel = {
    selector: 'sowTokenCancel',
    controller: 'sowTokenActionCtrl',
    controllerAs: 'ataCtrl',
    templateUrl: 'sow-approvals/views/token-cancel.html',
    bindToController: true,
    scope: {
      mode: 'cancel'
    },
  };

  const sowTokenDecline = {
    selector: 'sowTokenDecline',
    controller: 'sowTokenActionCtrl',
    controllerAs: 'ataCtrl',
    templateUrl: 'sow-approvals/views/token-decline.html',
    bindToController: true,
    scope: {
      mode: 'decline'
    },
  };

  angular.module('sowApprovals')
    .controller(sowTokenAccept.controller, sowTokenActionCtrl)
    .component(sowTokenAccept.selector, sowTokenAccept)
    .component(sowTokenCancel.selector, sowTokenCancel)
    .component(sowTokenDecline.selector, sowTokenDecline);

})();

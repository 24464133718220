angular.module('app.shared.system.title')
.service('TitleService', function TitleService ($window, titleConfig) {
  var _this = this;

  //
  // Set the Window Title
  //
  _this.setTitle = function (title) {
    if (!title) {
      $window.document.title = titleConfig['default'];
    } else {
      $window.document.title = title + titleConfig['suffix'];
    }
  };

  return _this;
});

(function () {
  'use strict';
  angular.module('sowProduct')
  .controller('productDetailsController', productDetailsController)
  
  function productDetailsController($scope, $stateParams, productDetailsService) {
    var ctrl = this;
    
    ctrl.product = null;
    ctrl.related_products = null;
    ctrl.selected_vendor = null;
    ctrl.loading = true;

    ctrl.setProductData = setProductData;
    ctrl.setRelatedProductsData = setRelatedProductsData;
    ctrl.selectVendor = selectVendor;
    
    init();
    
    return ctrl;
    
    function init () {
      bindEvents();
      checkParams();
    }

    function exit () {
      // designated for 'garbage collection'
    }

    function bindEvents () {
      if ($scope.$on) {
        $scope.$on('$destroy', exit);
        $scope.$on('pd-select-vendor', selectVendor);
      }
    }

    /**
     * Checks for parameters in the URL to set the state without previous interaction.
     * 
     * @return {*}
     */
    function checkParams () {
      if ($stateParams.product) {
        setProductData($stateParams.product);
      } else {
        loadProductData().then(function(){
          loadRelatedProducts();
        });
      }
      if ($stateParams.vendor) {
        selectVendor(null, $stateParams.vendor);
      }
      if ($stateParams.image) {
        selectImage(null, $stateParams.image);
      }
      if ($stateParams.section) {
        // TODO: scroll down to that section, make sure sections have IDs
      }
    }

    /**
     * Uses "url_name" param of URL to fetch corresponding product from the API.
     *
     * @return {*} 
     */
    function loadProductData() {
      return productDetailsService.fetchProductDetailData($stateParams.url_name).then(function (product) {
        setProductData(product);
      }).catch(function (err) {
        console.error(err);
      }).finally(function () {
        ctrl.loading = false;
      });
    }

    /**
     * Fetches array or related products which corresponds to the product's ID.
     * 
     * @param {String} product_id
     *
     * @return {*} 
     */
    function loadRelatedProducts (product_id) {
      product_id = product_id || _.get(ctrl, 'product.id');
      if (product_id) {
        return productDetailsService.getRelatedProducts(product_id).then(function (related_products) {
          setRelatedProductsData(related_products);
        }).catch(function (err) {
          console.error(err);
        });
      }
    }
    
    /**
     * Assigns product data from API to its controller variable.
     *
     * @param {Object} product
     * 
     * @return {*}
     */
    function setProductData(product) {
      if (_.get(product, 'sorted_vendors')) {
        ctrl.product = product;
        selectVendor(null, _.get(product, 'sorted_vendors[0]'));
      }
    }
    
    /**
     * Assigns related products array to its controller variable.
     *
     * @param {Array} list Algolia hits
     */
    function setRelatedProductsData(list) {
      ctrl.related_products = list;
    }

    /**
     * > When a user clicks on a vendor, that vendor is passed to the parent controller, which then
     * updates the state of its children accordingly
     * @param _$event - the event that triggered the function
     * @param clicked_vendor - the vendor object that was clicked on
     */
    function selectVendor (_$event, clicked_vendor) {
      ctrl.selected_vendor = clicked_vendor;
      ctrl.id = clicked_vendor.vendor.id;
      $scope.$broadcast('vendor-updated', ctrl.selected_vendor)
    }
    
  }
})();

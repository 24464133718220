(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.registration')
    .controller('InviteRequestResponseCtrl', InviteRequestResponseCtrl);

  function InviteRequestResponseCtrl ($scope, $filter, $stateParams, $timeout, RouteService, officeService, errorService) {
    /*jshint validthis: true */
    var ctrl = this;

    $scope.state = 'loading';
    $scope.errorMsg = null;

    ctrl.request_id = $stateParams.request_id;
    ctrl.response = $stateParams.response;

    return initialize();

    //
    //
    //
    function initialize () {
      if (ctrl.response !== 'accept' && ctrl.response !== 'reject') {
        setError('Bad Url...');
        $timeout(RouteService.goToDefault, 4000);
      }
      else if (!ctrl.request_id) {
        setError('Invalid request...');
        $timeout(RouteService.goToDefault, 4000);
      }
      else {
        return processRequest();
      }
    }

    function setError (msg) {
      $scope.state = 'error';
      $scope.errorMsg = msg;
    }

    function processRequest () {
      if (ctrl.response === 'accept') {
        return officeService.acceptMembershipRequest(ctrl.request_id).then(function () {
          $scope.state = 'request_processed';
        }).catch(function (error) {
          errorService.uiErrorHandler(error);
          var t_message = $filter('translate')('ERRORS.REQUEST_FAIL')
          setError(t_message);
        });
      }

      else if (ctrl.response === 'reject') {
        return officeService.rejectMembershipRequest(ctrl.request_id).then(function () {
          $scope.state = 'request_processed';
        }).catch(function (error) {
          errorService.uiErrorHandler(error);
          var t_message = $filter('translate')('ERRORS.REQUEST_FAIL')
          setError(t_message);
        });
      }

      else {
        // ERROR!
      }


    }

  }
}());

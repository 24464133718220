angular.module('app.marketplace.ui.inventory')
  .directive('inventoryCreateSuccess', function () {
    return {
      restrict: 'E',
      controllerAs: 'ctrl',
      templateUrl: 'templates/marketplace/inventory/directives/inventory-create-success.html',
      scope: true,
      bindToController: {
        context: '@'
      },
      controller: function ($mdDialog) {
        var ctrl = this;

        ctrl.close = close;
        function close() {
          $mdDialog.cancel();
        }
      }
    }
  });

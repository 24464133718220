(function () {
    'use strict'; 

    angular
        .module('sowUserAdmin')
        .service('sowAllOfficeService', sowAllOfficeService); 

    function sowAllOfficeService(apiUrl, serverAPI, errorService) {
        /*jshint validthis: true */
        var service = this; 

        service.getOfficeSpending = getOfficeSpending; 
        service.getOfficeImplant = getOfficeImplant; 

        return service; 

        function getOfficeSpending(start_date, end_date) {
            var url = '{0}/offices/reports/yearly?start_date={1}&end_date={2}'.format(apiUrl, start_date, end_date); 

            var options =  {
                'method':'GET'
            }; 

            return serverAPI
                .doAPICall(url, options)
                .then(function (response) {
                    return response.data; 
                })
                .catch(errorService.uiErrorHandler); 
        }
        function getOfficeImplant(start_date, end_date, previous_start_date, previous_end_date) {
            var url = '{0}/offices/reports/implants/placement?start_date={1}&end_date={2}&previous_start_date={3}&previous_end_date={4}'.format(apiUrl, start_date, end_date, previous_start_date, previous_end_date); 

            var options =  {
                'method':'GET'
            }; 

            return serverAPI
                .doAPICall(url, options)
                .then(function (response) {
                    return response.data; 
                })
                .catch(errorService.uiErrorHandler); 
        }
    }
}()); 
angular.module('app.shared.ui')
  .directive('videoModalToggle', function () {
    return {
      require: '?^videoModal',
      link: function (scope, element, attrs, ctrl) {
        if (!ctrl) {
          return;
        }

        ctrl.addToggleElement(element);

        var toggleModal = function (event) {
          event.preventDefault();
          scope.$apply(function () {
            ctrl.toggle();
          });
        };

        element.on('click', toggleModal);

        //
        // ARIA (of Sorrow)
        //
        element.attr({ 'aria-haspopup': true, 'aria-expanded': false });
        scope.$watch(ctrl.isOpen, function (isOpen) {
          element.attr('aria-expanded', !!isOpen);
        });

        //
        // $destroy
        //
        scope.$on('$destroy', function () {
          element.off('click', toggleModal);
        });
      }
    };
  });

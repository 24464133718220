(function () {
  'use strict';

  angular.module('sowInventory')
  .directive('sowIiBuyingForm', sowInventoryItemBuyingFormDirective)
  .controller('sowInventoryItemBuyingFormController', sowInventoryItemBuyingFormController);

  function sowInventoryItemBuyingFormDirective () {
    return {
      restrict: 'E',
      replace: true,
      templateUrl: 'sow-inventory/directives/item-buying-form.html',
      controller: 'sowInventoryItemBuyingFormController',
      controllerAs: 'iibfCtrl',
      scope: {
        'item': '=',
      },
    };
  }

  function sowInventoryItemBuyingFormController ($scope) {
    var ctrl = this;
    ctrl.changeBoughtInPackage = changeBoughtInPackage;
    return ctrl;
    function changeBoughtInPackage(value) {
      if (!value) {
        $scope.item.tracking_method = 'Item';
        $scope.item[$scope.item.UI.case_quantity] = null;
      }
    }

  }
}());

angular.module("app.marketplace.ui.shopping")
  .controller("categoriesController",
    function ($scope, $filter, $stateParams, elementService, TitleService) {
      $scope.categoryId = $stateParams.categoryId || null;

      //
      // The parent controller (shoppingController) initializes / loads
      // $scope.categories, but this controller makes changes to
      // $scope.categories.filtered. When navigating within the 'app.shopping'
      // namespace, the parent controller does *not* reinitialize itself on
      // each $state transition, only this controller does. So we need a way to
      // wait if the parent controller is still loading categories, but not
      // wait if that has already happened.
      //
      // Note: This could be simplified if the parent controller didn't need to
      // load categories. Possible future refactor?
      //
      $scope.categories.promise.then(function (categories) {
        $scope.categories.filtered = $filter('filter')(categories.array, { 'parent_id': $scope.categoryId });
        $scope.categories.filtered = $filter('filterMedicalField')($scope.categories.filtered, $scope.marketplace_medical_field);
      });

      if ($scope.categoryId) {
        elementService.get('category', $scope.categoryId).then(function (category) {
          $scope.category = category;

          if ($stateParams.categoryId === category.id) {
            if (category.parent_id) {
              elementService.get('category', category.parent_id).then(function (parentCategory) {
                TitleService.setTitle(category.name + ' » ' + parentCategory.name + ' » Marketplace');
              }).catch(function () {
                TitleService.setTitle(category.name + ' » Marketplace');
              });
            } else {
              TitleService.setTitle(category.name + ' » Marketplace');
            }
          }

          if (category.products) {
            $scope.products = $filter('filterMedicalField')(category.products, $scope.marketplace_medical_field);
          } else {
            var options = {
              endpoint: 'subcategory_products',
              category: category,
              forceAPI: true
            };
            elementService.getElements('product', options).then(function (products) {
              category.products = _.values(products);
              $scope.products = $filter('filterMedicalField')(category.products, $scope.marketplace_medical_field);
            });
          }
        });
      }
    });

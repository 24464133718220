/*
 * Front end model of an account.
 * Singleton service used for reference by the element manager and generic controllers/directives.
 *
 */
angular.module("app.marketplace.elements")

.service("accountModel", function(serverAPI, apiUrl, MembershipTypes) {
  var _this = this;

  // High level model information for use in API calls, local storage
  // reference, generic HTML generation, etc.
  this.model_data = {
    // Model name and API reference name
    name: "account",
    display_name: "Account",
    api: {
      // Different path types which correspond to different endpoints.
      multiple: function (options) {
        return 'accounts';
      },

      single: function (options) {
        if (!checkNested(options, 'data', 'id')) {
          throw 'Missing option(s) for account path \'single\'. Options: ' + JSON.stringify(options);
        }
        return sprintf('%s/users/%s', apiUrl, options.data.id);
      },

      me: function (options) {
        return apiUrl + '/me';
      },

      //
      // Reset a password using a reset token
      //
      reset_password_with_token: function (options) {
        if (!checkNested(options, 'token') || !checkNested(options, 'password') || !checkNested(options, 'confirm_password')) {
          throw 'Missing option(s) for account path \'reset_password_with_token\'. Options: ' + JSON.stringify(options);
        }

        return {
          path: apiUrl + '/password/token/' + options.token,
          params: {
            method: 'POST',
            data: {
              password: options.password,
              confirm_password: options.confirm_password
            }
          }
        };
      },

      //
      // Decline a MembershipInvite
      //
      'decline_membership_invite': function (options) {
        if (!checkNested(options, 'user_id') || !checkNested(options, 'invite_id')) {
          throw 'Missing option(s) for account path \'decline_membership_invite\'. Options: ' + JSON.stringify(options);
        }

        return {
          path: '{0}/users/{1}/invites/{2}'.format(apiUrl, options.user_id, options.invite_id),
          params: {
            method: 'DELETE'
          }
        };
      },

      //
      // Accept a MembershipInvite
      //
      'accept_membership_invite': function (options) {
        if (!checkNested(options, 'user_id') || !checkNested(options, 'invite_id')) {
          throw 'Missing option(s) for account path \'accept_membership_invite\'. Options: ' + JSON.stringify(options);
        }

        return {
          path: '{0}/users/{1}/invites/{2}'.format(apiUrl, options.user_id, options.invite_id),
          params: {
            method: 'POST'
          }
        };
      },

      //
      // List Unredeemed MembershipInvites
      //
      'membership_invites': function (options) {
        if (!checkNested(options, 'user_id')) {
          throw 'Missing option(s) for account path \'membership_invites\'. Options: ' + JSON.stringify(options);
        }

        return {
          path: '{0}/users/{1}/invites'.format(apiUrl, options.user_id),
          params: {
            method: 'GET',
            params: {
              'redeemed': 0,
              'type': 'office',
            }
          }
        };
      },

      validate_password_reset_token: function (options) {
        if (!checkNested(options, 'token')) {
          throw 'Missing option(s) for account path \'validate_password_reset_token\'. Options: ' + JSON.stringify(options);
        }

        return {
          path: apiUrl + '/password/token/' + options.token
        };
      },

      reset_password: function (options) {
        if (!checkNested(options,'account', 'email')) {
          throw 'Missing option(s) for account path \'reset_password\'. Options: ' + JSON.stringify(options);
        }
        return {
          path: apiUrl + "/users/reset_password?email="+options.account.email
        };
      },

      update_password: function (options) {
        if (!checkNested(options, 'edit_password')) {
          throw 'Missing option(s) for account path \'update_password\'. Options: ' + JSON.stringify(options);
        }

        return {
          path: apiUrl + '/me/password',
          params: {
            method: 'PUT',
            data: {
              passwords: options.edit_password
            }
          }
        };
      },

      register: function (options) {
        if (!checkNested(options, 'account')) {
          throw 'Missing option(s) for account path \'register\'. Options: ' + JSON.stringify(options);
        }
        return {
          path: apiUrl + '/users/register',
          params: {
            data: options.account,
            method: 'POST'
          }
        };
      },

      update: function (account) {
        if (!account) {
          throw 'Missing account for account path \'update\'. ';
        }
        return {
          path: apiUrl + '/me',
          params: {
            method: 'PUT',
            data: account
          }
        };
      },

      // In this model and not office because it allows easy instance creation
      // of the returned users via elementService.get
      office_users: function (options) {
        if (!checkNested(options, 'office', 'id')) {
          throw 'Missing option(s) for account path \'office_users\'. Options: ' + JSON.stringify(options);
        }
        return {
          path: sprintf('%s/offices/%s/users', apiUrl, options.office.id),
          params: {
            method: 'GET'
          }
        };
      },

      update_office_user: function (options) {
        if (!checkNested(options, 'office', 'id') || !checkNested(options, 'user', 'id')) {
          throw 'Missing option(s) for account path \'update_office_user\'. Options: ' + JSON.stringify(options);
        }
        return {
          path: sprintf('%s/offices/%s/users/%s', apiUrl, options.office.id, options.user.id),
          params: {
            method: 'PUT',
            // data: {
            //     membership_type: options.user.membership_type,
            //     title: options.user.title
            // }
            // changed business rule in SOW-4673
            data: _.pick(options.user, ['name', 'type', 'membership_type', 'title', 'email']),
          }
        };
      },

      remove_office_user: function (options) {
        if (!checkNested(options, 'office', 'id') || !checkNested(options, 'user', 'id')) {
          throw 'Missing option(s) for account path \'update_office_user\'. Options: ' + JSON.stringify(options);
        }
        return {
          path: sprintf('%s/offices/%s/users/%s', apiUrl, options.office.id, options.user.id),
          params: {
            method: 'DELETE'
          }
        };
      },

      //
      // Create User Endpoint
      //
      create_user: function (data) {
        if (!_.has(data, 'account')) { throw 'Cannot create an empty account.'; }
        if (!_.has(data, 'account.email')) { throw 'Missing email. Cannot create an account.'; }
        if (!_.has(data, 'account.password')) { throw 'Missing password. Cannot create an account.'; }

        return {
          path: '{0}/users'.format(apiUrl),
          params: {
            method: 'POST',
            data: data.account,
          }
        };
      }
    },

    // Local storage reference
    STORAGE_ID: 'account',
    STORAGE_ID_LAST: 'account-LastLogin',

    // Display details for generating a table of accounts
    table_display: {
    }
  };

  this.autofill = function (account) {
    if (account.membership_type) {
      account.membership_display_name = MembershipTypes[account.membership_type];
    }
    if(!account.flags){
      account.flags = {'mobile_tutorial_completed': false};
    }
  };

  //Field information
  this.fields = {
    id: {
      display_name: "id",
      required: true,
      api: {
        submit: true
      },
      type: "string"
    },
    name: {
      display_name: "Name",
      required: true,
      api: {
        submit: true
      },
      type: "string"
    },
    email: {
      display_name: "Email",
      required: true,
      api: {
        submit: true
      },
      type: "string"
    },
    password: {
      display_name: "Login password",
      required: true,
      api: {
        submit: true
      },
      type: "password"
    },
    monthly_email: {
      display_name: "monthly_email",
      api: {
        submit: true
      },
      type: "boolean"
    },
    weekly_email: {
      display_name: "weekly_email",
      api: {
        submit: true
      },
      type: "boolean"
    },
    opt_in_to_emails: {
      display_name: "opt_in_to_emails",
      api: {
        submit: true
      },
      type: "boolean"
    },
    stock_alert_email: {
      display_name: "stock_alert_email",
      api: {
        submit: true
      },
      type: "boolean"
    },
    flags: {
      display_name: "flags",
      api: {
        submit: true
      },
      type: "object"
    },
    type: {
      display_name: "Login type",
      required: false,
      type: "code",
      values: [ {
        name: "admin",
        display_name: "Admin"
      } ],
      api: {
        submit: true
      },
      all_values: [
         {name: 'Master User', value: 'master', levels: {'master':true}},
         {name: 'Manager', value: 'manager', levels: {'manager':true, 'master':true}},
         {name: 'Team Member', value: 'team', levels: {'manager':true, 'master':true}}
       ]
    }
  };

  //Fields and functions replicated for each instance.
  this.data = {
    params: {
      model_name: "account"
    }
  };

});

(function () {
    'use strict';
    angular.module('sowSterilization', [])
    .config(sowSterilizationConfig);

    function sowSterilizationConfig ($stateProvider, $urlRouterProvider) {
      var defaultUrl = '/app/stzn/tests';
      $urlRouterProvider.when('/app/stzn/', defaultUrl);
      $urlRouterProvider.when('/app/stzn', defaultUrl);

      $stateProvider
        .state('app.stzn', {
          url: '/stzn',
          templateUrl: 'sow-sterilization/stzn-index.html',
          controller: 'SterilizationIndexController',
          controllerAs: 'stznIndexCtrl',
          data: {
            'title': 'Sterilization',
            'action_bar_directive': 'action-bar-stzn',
            'require_log_in' : true,
            // 'requires_feature': 'sterilization',
          },
          redirectTo: 'app.stzn.testlist'
        })
        .state('app.stzn.testlist', {
          url: '/tests?filter',
          templateUrl: 'sow-sterilization/stzn-test-list.html',
          controller: 'sterilizationTestListController',
          controllerAs: 'stzlCtrl',
          params: {
            filter: null,
          }
        })
        .state('app.stzn.machinelist', {
          url: '/machines/?add_new',
          template: '<sterilization-machine-page params="{{$stateParams}}" ng-on-event="stzmlCtrl.handleMachinesPageEvent($event)"></sterilization-machine-page>',
          controller: 'sterilizationMachineListController',
          controllerAs: 'stzmlCtrl',
          params:{
            filter:''
          }
        })
        .state('app.stzn.details', {
          data: { action_bar_directive: null },
          template: '<sterilization-details params={{$stateParams}}></sterilization-details>',
          url: '/details/:type/:id?filter',
          params: {
            filter: null
          }
        })
        .state('app.stzn.create', {
          data: { action_bar_directive: null },
          params: { filter: null },
          template: '<sterilization-details params={{$stateParams}}></sterilization-details>',
          url: '/create/:type?filter',
        })
        .state('app.stzn.pouch-contents', {
          template: '<pouch-contents-page params="{{$stateParams}}"></pouch-contents-page>',
          url: '/pouch-contents/',
        });
    }

}());

(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.medications')
    .controller('medCountDetailController', medCountDetailController);

  function medCountDetailController ($scope, $state, $stateParams, medHelperService) {
    /*jshint validthis: true */
    var ctrl = this;

    ctrl.rxCount = rxCount;

    init();

    return ctrl;

    function init () {
      if($stateParams.date){
        ctrl.date_label = moment($stateParams.date).format("MMM D, YYYY");
      }
      mockupData();
    }

    function rxCount (product) {
      var result = 0;
      
      result = product.rx.opening + product.rx.added - product.rx.used - product.rx.wasted;
      
      return result;
    }

    function mockupData () {
      ctrl.products = _.filter(medHelperService.getMockupProducts(), ["type","regular"]);
    }

  }
}());

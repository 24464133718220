(function () {
  'use strict';
  
  angular
  .module('sowReports')
  .controller('reportsActionBarController', reportsActionBarController)
  .directive('reportsActionBar', ReportsActionBar);
  
  function ReportsActionBar () {
    return {
      'restrict': 'AC',
      'templateUrl': 'sow-reports/directives/action-bar.html',
      'controller': 'reportsActionBarController',
      'controllerAs': 'repAbCtrl'
    };
  }
  
  function reportsActionBarController (impHelperService, sowReportsService) {
    /*jshint validthis: true */
    var ctrl = this;
    
    ctrl.exportImplants = sowReportsService.exportImplants;
    ctrl.exportSuppliers = sowReportsService.exportSuppliers;
    
    init();
    
    return ctrl;
    
    function init () {
      
    }
    
  }
}());

angular.module('app.marketplace.ui.orders')
.controller('ordersActionBarController', function ($scope, $state, $mdDialog, $filter, sgToast, sessionService, elementRefreshService, errorService, officeService, orderService, PurchaseOrderService, $rootScope, poHelperService, inventoryItemService, sowReportsService, AccessService, sowAnalyticsService) {
  /*jshint validthis: true */
  var ctrl = this;
  // ctrl.poId = $state.params.poId;
  ctrl.status = $state.current.name.split('.')[2];
  ctrl.editingActive = $state.params.editingActive;
  ctrl.editEnabled = true;
  ctrl.features = $rootScope.features;

  ctrl.goBack = goBack;
  ctrl.deleteButton = deleteButton;
  ctrl.duplicateButton = duplicateButton;
  ctrl.editButton = editButton;
  ctrl.emailButton = emailButton;
  ctrl.getPoId = getPoId;
  ctrl.print = print;

  ctrl.updateShoppingListCount = updateShoppingListCount;
  ctrl.clearShoppingList = clearShoppingList;
  ctrl.removeFromShoppingList = removeFromShoppingList;
  ctrl.clearCheckedItems = clearCheckedItems;
  ctrl.createPurchaseOrder = createPurchaseOrder;
  ctrl.addToPurchaseOrder = addToPurchaseOrder;
  ctrl.listPurchaseOrders = listPurchaseOrders;

  ctrl.handleExportAndEmailClick = handleExportAndEmailClick;
  ctrl.handleRemoveSelectedClick = handleRemoveSelectedClick;
  ctrl.handleCancelClick = handleCancelClick;
  ctrl.handleCreateOrderClick = handleCreateOrderClick;
  ctrl.handleNewPurchaseOrderClick = handleNewPurchaseOrderClick;
  ctrl.shouldHideCreateOrderButton = shouldHideCreateOrderButton;

  init();

  return ctrl;

  function init () {
    ctrl.checkedItems = inventoryItemService.checkedItems;
    elementRefreshService.getCurrentVar('country', true);
    $scope.$on('shopping-list-empty', function () { ctrl.shoppingListEmpty = true; });
    $scope.$on('shopping-list-not-empty', function () { ctrl.shoppingListEmpty = false; });
    // defineLinkPlacement();
    updateShoppingListCount();
    $scope.$on('shopping-list-updated', updateShoppingListCount);

    initSowingoScope();
    handleStateChange($state.current, $state.params);
    $scope.$on('$stateChangeSuccess', handleStateChange);
    $scope.$on("$destroy", clearCheckedItems);
    _defineLocks();
  }

  function handleStateChange (state, params) {
    if (state.name === 'app.orders.purchase.active') {
      ctrl.editEnabled = true;

      PurchaseOrderService.get(params.poId).then(function (po) {
        if (po.status === 'Received' || po.status === 'Cancelled') {
          ctrl.editEnabled = false;
        }
      });
    }
    if (state.name === 'app.orders.sowingo') {
      initSowingoScope();
    }
    _defineLocks();
  }

  function getPoId(){
    return $state.params.poId;
  }

  function goBack(){
    $state.go('app.orders.purchase.list', {
      'statusFilter': PurchaseOrderService.getLastStatusFilter()
    });
  }

  function deleteButton ($event) {
    var po = {'id': getPoId(), 'UI':{} };
    //this is an extreme workaround for not making another network request.
    var hasReceivedItems = $('.po-items-header.received').length > 0;
    poHelperService.deletePoDialog(po, hasReceivedItems).then(function(result){
      ctrl.goBack();
    });
  }

  function print () {
    $rootScope.$broadcast('purchase_order_print');
  }

  function duplicateButton($event, page){
    $rootScope.$broadcast("duplicate_po",page);
  }

  function editButton($event, page){
    $rootScope.$broadcast("edit_po",page);
  }

  function emailButton(){
    var po = {id: getPoId(), UI:{} };
    poHelperService.emailPoDialog(po);
  }

  function initSowingoScope () {
    $scope.order_filter = {};
    orderService.UI.filter = $scope.order_filter;

    officeService.getOfficeUsers()
    .then(function(users){
      $scope.all_users = users;
      $scope.all_users_map = {};
      _.map(users, function(user){
        $scope.all_users_map[user.user_id] = user;
      })
    }).catch(function(error){
      var t_message = $filter('translate')('ERRORS.USERS_RETRIEVE')
      errorService.uiErrorHandler(error || t_message);
    });

    $scope.toggleFilterMenuDropdown = function(val){
      $scope.open_filter_menu = val;
    };
  }

  // Shopping List Functions

  function updateShoppingListCount () {
    inventoryItemService.loadShoppingList().then(function (items) {
      ctrl.slCount = _.get(items, 'length', 0);
    });
  }

  //
  // Remove All Items From the Shopping List
  //
  function clearShoppingList (ev) {
    $filter('translate')('TOAST.REMOVE_ALL_PRODUCTS');
    return sgDialog.showConfirm({
      'title': 'Clear Shopping List?',
      'hideTitle': true,
      'ok': 'Clear List',
      'okRed': true,
      'cancel': 'Cancel',
      'textContent': t_message,
    }).then(function () {
      inventoryItemService.loadShoppingList().then(function (items) {
        return removeFromShoppingList(items);
      });
    });
  }

  //
  // Remove all selected items from the shopping list.
  //
  function removeFromShoppingList (items, options) {
    items = items || ctrl.checkedItems;
    options = angular.extend({
      'suppressPopups': false,
    }, options);

    return inventoryItemService.batchRemoveFromShoppingList(items, {showToast: !options.suppressPopups}).then(() => {
      clearCheckedItems();
    });
  }

  function createPurchaseOrder () {
    const {initial_items, supplier} = poHelperService.getInitialPoData(ctrl.checkedItems);
    clearCheckedItems();
    $state.go('app.orders.purchase.draft', {
      initial_items,
      supplier,
      shopping_list_po: true,
    });
  }

  function listPurchaseOrders () {
    let single_supplier;
    if (ctrl.use_single_supplier) {
      single_supplier = _.get(ctrl, 'checkedItems[0].supplier', null);
    }
    
    var po_list = [];
    PurchaseOrderService.getAllByStatus('Draft')
    .then(function(response){
      po_list = _.values(response);

      // for offices with single supplier setting, we'll filter these POs
      // so they can only add items to POs of the same supplier.
      if (single_supplier) {
        po_list = _.filter(po_list, function(po){
          return _.get(po, 'supplier.name') === single_supplier;
        });
      }
      if(!po_list.length){
        createPurchaseOrder();
        return;
      }
      po_list = _.sortBy(po_list, function(po){ return po.supplier.name; });
      // $scope.po_list = po_list;
      // display dialog
      $mdDialog.show({
        controller: 'poDialogController as dialogCtrl',
        templateUrl: 'templates/marketplace/inventory/modals/add-to-purchase-order.html',
        parent: angular.element(document.body),
        clickOutsideToClose:true,
        // scope: $scope,
        locals: {
          po_list: po_list,
          // cancel: $mdDialog.close,
        },
        bindToController: true,
      })
      .then(function(po){
        if(po){
          sowAnalyticsService.shoppingListAddToOrderConfirmed();
          addToPurchaseOrder(po);
        } else {
          sowAnalyticsService.shoppingListCreateOrderConfirmed();
          createPurchaseOrder();
        }
      });
    });

  }

  function addToPurchaseOrder (order) {
    const including_items = inventoryItemService.getNonDiscontinuedItems(ctrl.checkedItems, (inventoryItem) => {
      return {
        'inventory_item_id': inventoryItem.id,
        'sku': inventoryItem.sku,
        'unit_cost': inventoryItem.price || 0.00,
        'quantity': 1,
      };
    });

    order.items = _.concat(order.items, including_items);

    return PurchaseOrderService.saveDraft(order).then(function (po) {
      removeFromShoppingList(ctrl.checkedItems, {'suppressPopups': true}).then(function () {
        clearCheckedItems();
        $state.go('app.orders.purchase.draft',{poId: po.id});
      });
    }).catch(function (error) {
      /* the service shows an error toast in this case so we won't also do so here */
      if (error?.internal_code === 'PO_HRID_DUPLICATE') {
        return;
      }
      errorService.uiErrorHandler(error);
    });
  }

  function clearCheckedItems () {
    _.map(ctrl.checkedItems, function(checked_item){
      _.set(checked_item, 'UI.checked', false);
    });
    ctrl.checkedItems = angular.copy([], ctrl.checkedItems);
    $rootScope.$broadcast('orders-checked-items-cleared');
  }

  function defineLinkPlacement () {
    sessionService.get(true)
    .then(function(session){
      var is_premium = ["Premium", "Premium Plus"].includes(session.current_membership.office.account_type);
      if (is_premium) {
        ctrl.po_placement = -20;
        ctrl.mkt_placement = -19;
      } else {
        ctrl.mkt_placement = -20;
        ctrl.po_placement = -19;
      }
    });
  }

  /**
   * Determines if create order button should be hidden based on:
   * 1. If disable_purchase_orders is true
   * 2. If all checked items are discontinued
   * @returns {boolean}
   */
  function shouldHideCreateOrderButton () {
    return ctrl.disable_purchase_orders || inventoryItemService.allItemsDiscontinued(ctrl.checkedItems);
  }

  function _defineLocks () {
    ctrl.mkt_orders_locked = AccessService.getProperty('orders.disable_page');
    ctrl.use_single_supplier = AccessService.getProperty('shopping_list.use_single_supplier');
    ctrl.disable_purchase_orders = AccessService.getOverride('disable_purchase_orders');
    ctrl.disable_marketplace_orders = AccessService.getOverride('disable_marketplace_orders');
    ctrl.has_po_flag = AccessService.getOfficeFeatureFlag('purchase_orders');
    ctrl.disable_new_po = AccessService.getProperty('purchase_orders.disable_add_new');
    ctrl.show_new_po = shouldShowNewPO();
  }

  function handleExportAndEmailClick() {
    sowAnalyticsService.shoppingListExportAndEmailClicked();
    sowReportsService.exportShoppingList();
  }

  function handleRemoveSelectedClick() {
    sowAnalyticsService.shoppingListRemoveSelectedClicked();
    removeFromShoppingList();
  }

  function handleCancelClick() {
    sowAnalyticsService.shoppingListCancelClicked();
    clearCheckedItems();
  }

  function handleCreateOrderClick() {
    sowAnalyticsService.shoppingListCreateOrderClicked();
    listPurchaseOrders();
  }

  function handleNewPurchaseOrderClick() {
    sowAnalyticsService.newPurchaseOrderClicked();
  }

  function shouldShowNewPO () {
    return (
      ctrl.has_po_flag 
      && !ctrl.disable_new_po 
      && !ctrl.disable_purchase_orders 
      && $state.includes('app.orders.purchase')
    );
  }

});

(function () {
  'use strict';
  
  angular
  .module('sowReports')
  .controller('ImplantsPlacementSurgeonDetailController', ImplantsPlacementSurgeonController);
  
  function ImplantsPlacementSurgeonController($scope, $filter, sgToast, $state,sowImplantService , sowImplantPlacementService, $mdDialog, errorService , orderByFilter, appEvents, $rootScope, membershipService) {
    var ctrl = this;
    ctrl.selectedYear = ($state.params.year) ? $state.params.year : moment().year();
    ctrl.rangeOptions = [{
      name: moment().year(),
      value: moment().year()
    }];
    
    ctrl.indexSort = 0;
    ctrl.propertyName = 'date';
    ctrl.reverse = false;
    
    ctrl.surgeon_name = $state.params.name;
    ctrl.searchProductType = 'placement_id';
    ctrl.placehodlderText = $filter('translate')('REPORTS.PLACEMENT.PATIENT_NAME');
    ctrl.productTypeSearch = [
      {
        type: 'referring_doctor',
        value: 'Referring Doctor',
        placeHolder: $filter('translate')('COMMON.REF_DOC')
      },
      {
        type: 'placement_id',
        value: 'Patient',
        placeHolder: $filter('translate')('REPORTS.PLACEMENT.PATIENT_NAME')
      }
    ];
    ctrl.sets = {
      'Implant': {
        color: "#398ee3",
        set_name: 'implants',
        display_name: $filter('translate')('IMPLANTS.IMPLANTS'),
        category: 'Implant',
        placements: [],
        img:{
          svg: "styles/img/implants/implants-icon-v3.svg",
        }
      },
      'Closure Cap': {
        color: "#7a41cb",
        set_name: 'caps',
        display_name: $filter('translate')('IMPLANTS.COVER_SCREWS'),
        category: 'Closure Cap',
        placements: [],
        img:{
          svg: "styles/img/implants/caps-icon.svg",
        }
      },
      'Healing Abutment': {
        color: "#2eb479",
        set_name: 'abutments',
        display_name: $filter('translate')('IMPLANTS.HEALING_ABUTMENTS'),
        category: 'Healing Abutment',
        placements: [],
        img:{
          svg: "styles/img/implants/healing-icon.svg",
        }
      },
      'Bone Graft': {
        color: "#33bbc4",
        set_name: 'bonegraft',
        category: 'Bone Graft',
        placements: [],
        img:{
          material: "grain",
        }
      },
      'Membrane': {
        color: "#ff2566",
        set_name: 'membranes',
        display_name: $filter('translate')('IMPLANTS.MEMBRANES'),
        category: 'Membrane',
        placements: [],
        img:{
          material: "healing",
        }
      }
    };
    
    ctrl.changedKeySearch = changedKeySearch;
    ctrl.editRemoval = editRemoval;
    ctrl.getSurgeonDetail = getSurgeonDetail;
    ctrl.onChangeYear = onChangeYear;
    ctrl.search = search;
    ctrl.setColorAndImg = setColorAndImg;
    ctrl.sortBy = sortBy;
    ctrl.undo = undo;
    ctrl.updateYearOptions = updateYearOptions;

    init();

    return ctrl;
    
    function init () {
      defineLocks();
      ctrl.updateYearOptions();
      ctrl.getSurgeonDetail();
      $rootScope.$on(appEvents.implantItemSaved, function($event, saved_item){
        ctrl.getSurgeonDetail();
      });
    }
    
    function editRemoval (item) {
      $rootScope.$broadcast(appEvents.impEditRemovalFetch, item.imp_inventory_item_id, item);
    }
    
    function changedKeySearch (){
      ctrl.productTypeSearch.forEach(function(el){
        if(el.type == ctrl.searchProductType){
          ctrl.placehodlderText = el.placeHolder;
        }
      });
    }
    
    function search (value, index, arr) {
      var keyword =  ctrl.searchBy;
      if(keyword===undefined || keyword===''){
        return true;
      }
      var regex = new RegExp(keyword, 'i');
      var searchValue = value[ctrl.searchProductType];
      return regex.test(searchValue);
    }
    
    function updateYearOptions () {
      var first_date = moment($scope.$root.current_office.created_at);
      var last_date = moment();
      var years_count = last_date.diff(first_date, 'y');
      
      for (var i = 1; i <= years_count; i++) {
        var year_number = moment().subtract(i, 'y').format('YYYY');
        ctrl.rangeOptions.push({
          name: year_number,
          value: year_number
        });
      }
    }
    
    function getSurgeonDetail () {
      var id = $state.params.id;
      var start_date = moment(ctrl.selectedYear, 'YYYY').startOf('y').format('YYYY-MM-DD');
      var end_date = moment(ctrl.selectedYear, 'YYYY').endOf('y').format('YYYY-MM-DD');
      sowImplantPlacementService.getSurgeonDetail(id, start_date, end_date).then(function (result) {
        ctrl.data = Object.values(ctrl.setColorAndImg(result));
        ctrl.sortBy(ctrl.propertyName, ctrl.indexSort);
      })
    }
    
    function onChangeYear () {
      ctrl.getSurgeonDetail();
    }
    
    function setColorAndImg (result) {
      var p = result.product_placements;
      var s = angular.copy(ctrl.sets);
      p.forEach(function(element){
        if(element.placements.length > 0){
          element.placements.forEach(function(el){
            el.date = moment(el.date, 'DD MMM, YYYY HH:mm:ss').format('DD MMM, YYYY');
          })
        }
        if (s[element.category]) {
          s[element.category].placements = element.placements;
          s[element.category].all_placements_are_external = element.placements.every(el => el.is_external_id);
        }
      });
      
      return s;
    }
    
    function undo (item) {
      item.implant_inventory_item_id = item.imp_inventory_item_id;
      return $mdDialog.show({
        controller: 'poDialogController as dialogCtrl',
        templateUrl: 'sow-reports/modals/implant-return-to-inventory.html',
        parent: angular.element(document.body),
        clickOutsideToClose: true,
        // scope: $scope,
        locals: {
          item: item,
          // cancel: $mdDialog.close,
        },
        bindToController: true,
      })
      .then(function (answer) {
        item.id = item.implant_inventory_item_id;
        return sowImplantService.getInventoryItem(item.implant_inventory_item_id)
        .then(function (inventory_item) {
          inventory_item.was_placed = false;
          return sowImplantService.saveInventoryItem(inventory_item)
          .then(function (saved_item) {
            _.remove(ctrl.report_data, ['implant_inventory_item_id', item.id]);
            // var type = $stateParams.type !== 'Closure Cap' ? $stateParams.type : 'Cover Screw' || 'Implant';
        var t_message = $filter('translate')('TOAST.IMPLANT_HAS_BEEN_RETURNED');
            sgToast.showSimple(t_message);
            _.set(item, 'UI.hidden', true);
          }).catch(function (error) {
            var t_message = $filter('translate')('ERRORS.INVENTORY_RETURN')
            errorService.uiErrorHandler(t_message);
          });
        }).catch(function (error) {
          var t_message = $filter('translate')('ERRORS.IMPLANT_LOAD')
          errorService.uiErrorHandler(t_message);
        });
      });
    }
    
    function sortBy (propertyName, index) {
      ctrl.indexSort = index;
      ctrl.reverse = (ctrl.propertyName === propertyName) ? !ctrl.reverse : false;
      ctrl.propertyName = propertyName;
      var allProduct = ctrl.data[index].placements;
      if (allProduct.length > 0) {
        ctrl.data[index].placements = orderByFilter(allProduct, ctrl.propertyName, ctrl.reverse);
      }
    }

    function defineLocks () {
      const membership = membershipService.get();

      const hide_report_prices_condition = _.get(membership, 'user_properties.reports.hide_report_prices', false);
      ctrl.hide_report_prices = hide_report_prices_condition;
    }
  }
  
  
}());

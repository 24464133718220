(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.inventory')
    .controller('EditLocationController', EditLocationController);

  function EditLocationController ($scope, $filter, $mdDialog, location, officeService, sgToast, errorService) {
    /*jshint validthis: true */
    var ctrl = this;

    $scope.lockForm = false;

    $scope.location = {
      'id': location.id,
      'name': location.name,
    };
    ctrl.original_location = angular.copy($scope.location);

    ctrl.close = closeModal;
    ctrl.save = saveLocation;

    return ctrl;

    function closeModal () {
      if ($scope.lockForm) {
        return;
      }

      $mdDialog.cancel();
    }

    function saveLocation (event) {
      if ($scope.lockForm) {
        return;
      }
      if (!$scope.EditLocationForm.$valid) {
        $scope.EditLocationForm.$setSubmitted();
        return;
      }
      if ($scope.location.name === ctrl.original_location.name) {
        $mdDialog.hide(location);
        // sgToast.showSimple('No changes made.');
        return;
      }
      $scope.lockForm = true;
      return officeService.renameLocation($scope.location.id, $scope.location.name)
      .then(function (location) {
        $mdDialog.hide(location);
        var t_message = $filter('translate')('TOAST.LOCATION_RENAMED')
        sgToast.showSimple(t_message);
      })
      .catch(function (error) {
        errorService.uiErrorHandler(error);
      })
      .finally(function(){
        $scope.lockForm = false;
      });
    }
  }
}());

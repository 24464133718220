angular.module('app.shared.ui').directive('ngMinInt', function () {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function ($scope, $element, $attrs, ngModel) {
      ngModel.$validators.minint = function (value) {
        return isEmpty(value) || parseInt(value, 10) >= parseInt($attrs.ngMinInt, 10);
      };
    }
  };
});

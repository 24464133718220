angular.module("app.marketplace.elements")
.service("returnModel", function(apiUrl, $filter) {
  var _this = this;
  this.model_data = {
    "name" : "return",
    "display_name" : "Return",
    "api" : {
      "by_hrid" : function(options){ 
        if(!checkNested(options,'data','id')){
          throw "Missing option(s) for return model path 'single'. Options: " + JSON.stringify(options);
        }
        return apiUrl + "/returns/" + options.data.id;
      },
      "multiple" : function(options){
        return apiUrl + "/me/returns";
      }

    }

  };

  this.autofill = function(return_order) {
    if(return_order.created_at){
      return_order.created_at = new Date(return_order.created_at);
    }
    if(return_order.refund_date){
      return_order.refund_date = new Date(return_order.refund_date);
    }
    
    if(!return_order.vendor_decision){
      return_order.status = "Pending";
    }else if(return_order.vendor_approved){
      return_order.status = "Approved";
    }else{
      return_order.status = "Denied";
    }
    
    //Reorganizing dates for easier sorting
    return_order.sort_date = return_order.refund_date || return_order.created_at;
    
    return_order.name = return_order.status + " Return, " + (return_order.created_at ? $filter('date')(return_order.created_at, "MMM d, yyyy") : null);
  };
  
  this.fields = {
    "id" : {
      "display_name" : "id",
      "required" : true,
      "api" : {
        "submit" : true
      },
      "type" : "integer"
    }
  };

  this.data = {
    "params" : {
      "model_name" : "return"
    }
  };

});
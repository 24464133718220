angular.module("app.marketplace.elements")

//$rootScope is only included to utilize root variables for autofill. TODO reconsider approach that does not use $rootScope 
.service("categoryModel", function($rootScope, $log, searchUrl) {
  var _this = this;
  this.model_data = {
    "name" : "category",
    "display_name" : "Category",
    "watch_vars": {"country" : true},
    "api" : {
      //Different path types which correspond to different endpoints.
      "multiple" : function(options){
        return {
           "path" : searchUrl+"/products/Category/_search",
           "type" : "elastic",
           "params" : {
             "method" : "GET",
             "params" : {
               "size":1000,
               "sort": "name.raw:asc"
             }
           }
        };
      },
      "single" : function(options){
        if(!checkNested(options,'data','id')){
          throw "Missing option(s) for path 'single'. Options: " + JSON.stringify(options);
        }
        return {
          "path" : searchUrl+"/products/Category/_search",
          "type" : "elastic",
          "params" : {
            "method" : "POST",
            "data" : {
              "size":1000,
              "query": {
                "term": {"id" : options.data.id}
              }
            }
          }
       };
      }

    }

  };

  this.autofill = function(element) {
    if(!element){
      return;
    }
    element.UI = element.UI || {};

    // Easier for filtering... cant remember how to filter deep properties.
    element.parent_id = element.parent ? element.parent.id : null;

    // Easier for filtering... cant remember how to filter deep properties.
    element.parent_id = element.parent ? element.parent.id : null;
    
    
    if($rootScope.current_country){
      var counts = element.product_counts;
      if (!counts) {
        element.UI.mf_product_counts = null;
        $log.error('No `product_counts` list on Category: %o', element);
        return;
      }

      element.UI.mf_product_counts = {};
      var allMedicalFields = !element.medical_fields.length;
      _.map(element.medical_fields, function(field){
        element.UI.mf_product_counts[field.id] = 0;
      });
      
      var i, len = counts.length;
      for (i = 0; i < len; ++i) {
        if (counts[i].country_id === $rootScope.current_country.id) {
          _.map(counts[i].medical_fields, function(field){
            if(allMedicalFields || element.UI.mf_product_counts[field.medical_field_id] === 0){
              element.UI.mf_product_counts[field.medical_field_id] = field.product_count;
            }
          });
        }
      }
    }
  };

  this.fields = {
    "id" : {
      "display_name" : "id",
      "required" : true,
      "api" : {
        "submit" : true
      },
      "type" : "integer"
    },
    "name" : {
      "display_name" : "name",
      "required" : true,
      "api" : {
        "submit" : true
      },
      "type" : "text"
    }
  };

  this.data = {
    "params" : {
      "model_name" : "category"
    }
  };

});

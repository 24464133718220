(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.creditCards')
    .directive('creditCardList', creditCardListDirective);

  function creditCardListDirective () {
    return {
      restrict: 'E',
      templateUrl: 'templates/marketplace/credit-cards/credit-card-list.html',
      controller: CreditCardListCtrl,
      controllerAs: '$ccListCtrl',
      scope: {
        'btnPrefix': '@',
        'currentCreditCard': '=model',
        'updatingDefaultSource': '@',
        'allowEdit': '@',
        'useRadioGroup': '@',
      },
      link: function ($scope, $element, $attrs, $ctrl) {
        //
        // We call this here because the controller is instantiated before this,
        // so we want to make sure that the controller has access to
        // $scope.currentCreditCard (and it doesn't clobber it). We can't call
        // the init code from the controller's constructor.
        //
        $ctrl.init();

        if ($attrs.isDisabled) {
          $scope.$watch(function () { return $scope.$parent.$eval($attrs.isDisabled); },
                        function (newValue, oldValue) { $ctrl.isDisabled = newValue; });
        }
        if ($attrs.updatingDefaultSource !== undefined) {
          $scope.$watch(function () { return !!$scope.$parent.$eval($attrs.updatingDefaultSource); },
                        function (newValue, oldValue) { $ctrl.updatingDefaultSource = newValue; });
        }

      }
    };
  }

  /* @ngInject */
  function CreditCardListCtrl ($scope, $filter, $location, CreditCardDialog, creditCardService, errorService, officeService, sgToast, sowAnalyticsService, coreUIService) {
    /*jshint validthis: true */
    var ctrl = this;

    $scope.credit_cards = [];
    $scope.CardListState = null;
    // $scope.loadingCardList = false;

    ctrl.first_load = true;
    ctrl.openAddCreditCardDialog = openAddCreditCardDialog;
    ctrl.openEditCreditCardDialog = openEditCreditCardDialog;
    ctrl.handleRemoveClick = handleRemoveClick;
    ctrl.toggleMenu = toggleMenu;
    ctrl.updateDefaultCard = updateDefaultCard;
    ctrl.shouldShowMenuIcon = shouldShowMenuIcon;
    ctrl.cardsRemoving = {};
    ctrl.init = init;

    return ctrl;

    //
    // Initialize the controller
    //
    function init () {
      updateCreditCardList();
    }

    //
    // Update the card list state.
    //
    function updateState (newState) {
      var card_count = _.get($scope, 'credit_cards.length') || 0;

      $scope.CardListState = newState !== undefined ? newState
                           : card_count > 0 ? 'has-cards'
                           : 'no-cards';

      ctrl.is_edit_disabled = isEditDisabled(card_count);
    }

    //
    // Fetch the list of credit cards, and update the list.
    //
    function updateCreditCardList () {

      if (ctrl.first_load) {
        ctrl.first_load = false;
        updateState('loading');
      }

      var selectedCardId = _.get($scope, 'currentCreditCard.id');

      return creditCardService.getCards().then(function (cards) {
        $scope.credit_cards = cards || [];

        if (selectedCardId) {
          _.forEach(cards, function (creditCard) {
            if (creditCard.id === selectedCardId) {
              $scope.currentCreditCard = creditCard;
              return false;
            }
          });
        } else {
          $scope.currentCreditCard = $scope.credit_cards[0];
        }

        updateState();
      }).catch(function (error) {
        updateState('list-load-failed');
        var t_message = $filter('translate')('ERRORS.LOAD_CARD_LIST')
        errorService.uiErrorHandler(error || t_message);
      });

    }

    async function updateDefaultCard(selected_card) {
      if ($scope.currentCreditCard.id === selected_card.id) {
        return;
      }

      sowAnalyticsService.billingCardMenuMakeDefault();

      ctrl.updatingDefaultSource = true;
      toggleMenu(null);
      try {
        await creditCardService.setDefaultCard(selected_card);
        $scope.currentCreditCard = selected_card;
        updateCreditCardList();
      } catch (error) {
        errorService.uiErrorHandler(error);
      } finally {
        ctrl.updatingDefaultSource = false;
      }
    }

    //
    // Open the "Add Credit Card" dialog.
    //
    function openAddCreditCardDialog ($event) {
      if (ctrl.isDisabled) {
        return;
      }

      const office = officeService.get();
      const plan_name = office.account_type;
      const path = $location.path();
      const page_name = path.includes('subscription_billing')
        ? 'Subscription Billing History'
        : 'Upgrade Subscription';
      const params = { plan_name, page_name };
      sowAnalyticsService.billingAddPaymentMethodClicked(params);

      return CreditCardDialog.show($event, "add").then(function (card) {
        const t_card_added = $filter('translate')('OFFICE.BILLING.SUCCESSFULLY_ADDED');
        sgToast.showSimple(t_card_added);
        $scope.currentCreditCard = card;
        updateCreditCardList();
      });
    }
    //
    // Open the "Edit Credit Card" dialog.
    //
    function openEditCreditCardDialog ($event, card) {
      if (ctrl.isDisabled) {
        return;
      }

      return CreditCardDialog.show($event, "edit", card).then(function (card) {
        updateCreditCardList();
      });
    }

    //
    // Remove a credit card from the list.
    //
    function removeCreditCard (creditCard) {
      if (ctrl.isDisabled) {
        return;
      }

      ctrl.cardsRemoving[creditCard.id] = true;

      return creditCardService.removeCard(creditCard).then(function (response) {
        var removedCardIsSelected = ($scope.currentCreditCard && $scope.currentCreditCard.id === creditCard.id);

        const t_card_deleted = $filter('translate')('OFFICE.BILLING.SUCCESSFULLY_DELETED');
        sgToast.showSimple(t_card_deleted);

        return updateCreditCardList().then(function () {
          var cardCount = _.get($scope, 'credit_cards.length');
          var noCards = !cardCount || cardCount < 1;
          $scope.currentCreditCard = noCards ? null : $scope.credit_cards[0];
        });
      }).catch(function (error) {
        ctrl.cardsRemoving[creditCard.id] = false;
        var t_message = $filter('translate')('ERRORS.CARD_REMOVE')
        errorService.uiErrorHandler(t_message);
      });
    }

    function handleRemoveClick($event, credit_card) {
      $event.preventDefault();
      $event.stopPropagation();

      const expiry_year = credit_card.exp_year;
      const default_status = $scope.currentCreditCard.id === credit_card.id;
      const params = { expiry_year, default_status };
      sowAnalyticsService.billingCardMenuDeleteClicked(params);

      const headline = 'OFFICE.BILLING.DELETE_PAYMENT_METHOD';
      const tagline = 'OFFICE.BILLING.ARE_YOU_SURE';
      const actions = [
        coreUIService.deleteButton(headline),
        coreUIService.cancelButton()
      ];

      return coreUIService.showDialog(headline, tagline, actions)
      .then((deletion_confirmed) => {
        if (deletion_confirmed) {
          sowAnalyticsService.billingCardMenuDeleteConfirmed(params);
          removeCreditCard(credit_card);
        }
      });
    }

    function toggleMenu(id) {
      switch (id) {
        case ctrl.displayed_menu_id:

          // the id arg is that of the menu which was already open,
          // so we reset the displayed menu ID to close all menus
          ctrl.displayed_menu_id = null;

          // the truthiness of the id arg matters in both switch cases because
          // toggleMenu may also be invoked with an argument of null in order to
          // reset the list state, but we only want to log these actions if the
          // user clicked the menu icon of one of the credit cards
          if (id) {
            sowAnalyticsService.billingCardMenuClickedClosed();
          }
          break;

        default:
          ctrl.displayed_menu_id = id;
          if (id) {
            sowAnalyticsService.billingCardMenuClickedOpen();
          }
      }
    }

    /** 
     * Prevents the user from deleting the only credit card
     * on file if their account is not free (since we need
     * a way to bill them for their membership). 
     * 
     * @return {Boolean} 
    */
    function isEditDisabled(card_count) {
      const office = officeService.get();
      return office.account_type !== 'Free' && card_count < 2;
    }

    /**
     * If the user is not editing, the card is not being removed, and the default source is not being
     * updated, then show the menu icon.
     * @return {boolean}
     */
    function shouldShowMenuIcon() {
      return !ctrl.is_edit_disabled && !_isCardBeingRemoved() && !ctrl.updatingDefaultSource;
    }

    /**
     * Checks if any of the credit cards are currently being removed
     * @return {boolean}
     */
    function _isCardBeingRemoved(credit_cards = $scope.credit_cards, cardsRemoving = ctrl.cardsRemoving) {
      for (const card of credit_cards) {
        if (cardsRemoving[card.id]) {
          return true;
        }
      }
      return false;
    }

  }

}());

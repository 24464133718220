angular.module("app.shared.support")

  //Modified from http://stackoverflow.com/questions/12931369/click-everywhere-but-here-event
  .service('clickAnywhereButHereService', function ($document, $timeout) {
    var tracker = [];

    return function ($scope, expr) {
      var i, t, len;
      for (i = 0, len = tracker.length; i < len; i++) {
        t = tracker[i];
        if (t.expr === expr && t.scope === $scope) {
          return t;
        }
      }
      var first = true;
      $timeout(function () {
        first = false;
      });

      var handler = function () {
        if (first) {
          first = false;
          return;
        }
        $scope.$apply(expr);
      };

      $document.on('click', handler);

      // IMPORTANT! Tear down this event handler when the scope is destroyed.
      $scope.$on('$destroy', function () {
        $document.off('click', handler);
      });

      t = { scope: $scope, expr: expr };
      tracker.push(t);
      return t;
    };
  });

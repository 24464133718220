angular.module('app.marketplace.elements')

.service('registrationService', function ($filter, $http, $rootScope, apiUrl, errorService, elementService) {
  var _this = this;

  function requestOfficeInvite (office_id) {
    return elementService.callEndpoint('registration', {
      endpoint: 'request_office_invite',
      office_id: office_id,
    }).then(function (response) {
      return response.data;
    });
  }

  function searchOfficeNames (searchText) {
    return elementService.callEndpoint('registration', {
      endpoint: 'office_search',
      searchText: searchText,
    }).then(function (response) {
      return response.data;
    });
  }

  var _createAccount = function (data) {
    return elementService.callEndpoint('registration', {
      endpoint: 'register',
      data: data
    })
    .then(function(response){
      $rootScope.$emit('registrationService: accountCreated', data);
      return response;
    });
  };

  var _verifyODAMembership = function(odaMemberNo, dentistLastName) {
    return elementService.callEndpoint('registration', {
      endpoint: 'verify_organization',
      organization: 'oda',
      identifier: odaMemberNo,
      credentials: dentistLastName
    });
  };

  function _verifyNeodentMembership (neodentAccessCode) {
    return elementService.callEndpoint('registration', {
      endpoint: 'verify_organization',
      organization: 'neodent',
      identifier: neodentAccessCode,
      credentials: 'test',
    });
  }

  function _verifyDentsplyMembership (dentsplyAccessCode) {
    return elementService.callEndpoint('registration', {
      endpoint: 'verify_organization',
      organization: 'dentsply',
      identifier: dentsplyAccessCode,
      credentials: 'test',
    });
  }

  function _verifyMnpMembership (mnp_client_number) {
    return elementService.callEndpoint('registration', {
      endpoint: 'verify_organization',
      organization: 'mnp',
      identifier: mnp_client_number,
      credentials: 'test',
    });
  }

  function _verifyAapMembership (aap_last_name) {
    return elementService.callEndpoint('registration', {
      endpoint: 'verify_organization',
      organization: 'aap',
      identifier: aap_last_name,
      credentials: aap_last_name,
    });
  }

  var _confirmEmail = function (token, email) {
    return elementService.callEndpoint('registration', {
      endpoint: 'confirm_email',
      token: token,
      email: email
    });
  };

  var _getUserInvitation = function (token) {
    return elementService.callEndpoint('registration', {
      endpoint: 'get_user_invite',
      token: token
    }).then(function (response) {
      return response.data;
    });
  };

  var _respondToInvite = function (token, payload) {
    return elementService.callEndpoint('registration', {
      endpoint: 'respond_to_invite',
      token: token,
      payload: payload
    }).then(function (response) {
      return response.data;
    });
  };

  //
  // Create Office
  //
  function createOffice (office) {
    return elementService.callEndpoint('registration', {
      endpoint: 'create_office',
      office: office
    }).then(function (response) {
      return response.data;
    });
  }

  //
  // Check if an email address is already in use.
  //
  function checkEmail (email) {
    return $http({
      method: 'HEAD',
      url: apiUrl + '/users',
      params: {email: email}
    }).then(function (response) {
      return response.status === 204;
    }).catch(function (errorResponse) {
      if (errorResponse.status === 302) {
        return false;
      } else {
        var t_message = $filter('translate')('ERRORS.EMAIL_AVAIL')
        errorService.uiErrorHandler(t_message);
        throw errorResponse;
      }
    });
  }

  return {
    verifyODAMembership: _verifyODAMembership,
    verifyNeodentMembership: _verifyNeodentMembership,
    verifyDentsplyMembership: _verifyDentsplyMembership,
    verifyAapMembership: _verifyAapMembership,
    verifyMnpMembership: _verifyMnpMembership,
    createAccount: _createAccount,
    confirmEmail: _confirmEmail,
    getUserInvitation: _getUserInvitation,
    respondToInvite: _respondToInvite,
    checkEmail: checkEmail,
    createOffice: createOffice,
    searchOfficeNames: searchOfficeNames,
    requestOfficeInvite: requestOfficeInvite,
  };
});

(function () {
  'use strict';

  angular.module('sowInventory')
  .controller('sowInventoryGridController', sowInventoryGridController);

  function sowInventoryGridController ($scope, $rootScope, $q, $filter, $timeout, officeService, errorService, inventoryItemService, agGridHelperService, mktHelperService, AccessService) {
    var ctrl = this;

    ctrl.ag_grid_options = {};
    ctrl.checkedItems = inventoryItemService.checkedItems;
    ctrl.applySearch = applySearch;

    init();

    return ctrl;

    function init () {
      defineLocks();
      setupAgGrid();
      loadData();
      $scope.$on('inventory-checked-items-cleared', clearGridCheckedItems);
      $scope.$on('added-checked-item', function(){$scope.$apply();});
      $scope.$on('removed-checked-item', function(){$scope.$apply();});
      $scope.$on('$destroy', clearCheckedItems);
      $scope.$on('inventory-item-updated', updateLocalItem);
      $scope.$on('inventory-item-deleted', deleteLocalItem);
    }

    function loadData () {
      ctrl.loading = true;
      $q.all([
        loadInventory(),
        loadPOData(),
        ])
      .then(function(responses){
        setupPoData();
      })
      .catch(errorService.uiErrorHandler)
      .finally(function(){
        updateGridData( _.get(ctrl, 'inventory.items',[]) );
        ctrl.loading = false;
      });
    }

    function loadInventory () {
      return officeService.get(true).then(function (office) {
        return officeService.getInventory(true, false).then(function (payload) {
          ctrl.inventory = payload;
        });
      }).catch(function (error) {
        var t_message = $filter('translate')('ERRORS.INVENTORY_LOAD')
        errorService.uiErrorHandler(error || "API Error: Failed to load inventory.", 0);
      });
    }

    function updateLocalItem (event, item) {
      var index = _.findIndex(ctrl.inventory.items, ['id', item.id]);
      if (index >= 0) {
        ctrl.inventory.items[index] = item;
      } else {
        ctrl.inventory.items.push(item);
      }
      updateGridData(ctrl.inventory.items);
    }

    function deleteLocalItem (event, item) {
      var index = _.findIndex(ctrl.inventory.items, ['id', item.id]);
      if (index >= 0) {
        ctrl.inventory.items.splice(index,1);
      }
      updateGridData(ctrl.inventory.items);
    }

    function applySearch () {
      if(!ctrl.search_input){
        updateGridData(ctrl.inventory.items);
      } else {
        var list = _.filter(ctrl.inventory.items, function(item){
          return _.includes(item.name.toLowerCase(), ctrl.search_input.toLowerCase());
        });
        updateGridData(list);
      }
    }

    function loadPOData () {
      return inventoryItemService.getInventoryPOStatus()
      .then(function (poStatusList) {
        ctrl.po_data = poStatusList;
      });
    }

    function setupPoData () {
      _.each(ctrl.inventory.items, function(item){
        var po_data = _.get(ctrl.po_data, item.id, null);
        _.set(item, 'po_status', po_data);
        // if(_.size(_.get(po_data, 'Active',[]))){
        //   _.set(item, 'UI.po_status', 'active');
        // } else if (_.size(_.get(po_data, 'Draft',[]))) {
        //   _.set(item, 'UI.po_status', 'draft');
        // }
      });
    }

    function setupAgGrid () {
      var columns = [
        // {headerName: "", cellRenderer: productCheckCR, minWidth: 50 },
        {headerName: '', field: 'UI.checked', cellClass: 'ag-check-cell', headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true, minWidth: 40, maxWidth: 40, },
        {headerName: "", cellRenderer: productImageCR, minWidth: 70, maxWidth: 70 },
        {headerName: "PRODUCT NAME", field: "name", sort:'asc', cellRenderer: productNameCR, comparator: lowercaseComparator },
        {headerName: "", cellRenderer: cartCR, minWidth: 50, maxWidth: 50 },
        {headerName: "SUPPLIER", field: "supplier", cellRenderer: centerTextCR, maxWidth: 110},
        {headerName: "STATUS", maxWidth: 160, cellRenderer: poStatusCR},
        {headerName: "PRICE", field: "price", minWidth:100, maxWidth: 100, cellRenderer: productPriceCR},
        {headerName: "QTY ON HAND", field: "on_hand", minWidth:130, maxWidth: 140, cellRenderer: onhandCR, cellClass: 'ag-cell-bordered'},
        {headerName: "ALERTS", minWidth: 50, maxWidth: 90, cellRenderer: alertsCR},
      ];
      _.each(columns, function(column){
        _.set(column, 'filter', false);
      });

      if(!ctrl.hasCartAccess){
        // remove 'add to cart column if user has no permission to use shopping cart' 
        columns.splice(3,1);
      }

      ctrl.ag_grid_options = angular.extend({}, agGridHelperService.defaultOptions(), {
        'angularCompileRows': true,
        'animateRows': false,
        'columnDefs': columns,
        'defaultColDef': {
          'filter': false,
          'resizable': true,
        },
        'onCellClicked': handleClick,
        'onRowSelected': onRowSelected,
        'onSelectionChanged': onSelectionChanged,
        'pagination': true,
        'paginationPageSize': 300,
        'rowData': [],
        'rowHeight': 62,
        'rowSelection': 'multiple',
        'suppressRowClickSelection': true,
      });
    }

    function lowercaseComparator (a,b) {
      a = a.toLowerCase();
      b = b.toLowerCase();
      if (typeof a === 'string') {
        return a.localeCompare(b);
      } else {
        return (a > b ? 1 : (a < b ? -1 : 0));
      }
    }

    function onRowSelected(event) {
      checkItem(event.node.data, event);
    }

    function onSelectionChanged(event) {
      var nodes = event.api.getSelectedRows();
      if(nodes.length === 0)
        inventoryItemService.clearCheckedItems();
      // resetCheckedItems(selected_items);
    }

    function updateGridData (items) {
      ctrl.ag_grid_options.api.setRowData(items);
    }

    function handleClick (params) {
      // itemSlideout(null, params.data.id);
      $rootScope.$broadcast('inventory-item-slideout', null, params.data.id);
    }

    function productCheckCR (params) {
      _.set(params.$scope, 'item', params.data);
      _.set(params.$scope, 'checkItem', checkItem);
      return ('<div style="height: 100%;" layout="row" layout-align="center center"> <md-checkbox ng-checked="item.UI.checked"'
               +'class=""'
               +'name="inventoryItem-chk-checkItem"'
               +'aria-label="{{item.name}}"'
               +'ng-click="checkItem(item, $event)">'
            +'</md-checkbox></div>');
    }

    function productImageCR (params) {
      // _.set(params.$scope, 'item', params.data);
      var item = params.data;
      var img_source = $filter('imageUrl')(item.image.url_src || item.image.url_240_box || item.image_url);
      return ('<div style="height: 100%;" layout="row" layout-align="center center"><img class="ag-item-pic" src="'+img_source+'" /></div>');
    }

    function productNameCR (params) {
      var product_name = params.value || '';
      var manufacturer = params.data.manufacturer_name || params.data.manufacturer.name || '';
      return ('<div style="height: 100%;" layout="column" layout-align="center start">'
              +'<span class="ag-item-name">'+product_name+'</span>'
              +'<span class="ag-item-manufacturer">'+manufacturer+'</span>'
              +'</div>');
    }

    function centerTextCR (params) {
      var data = params.value || '';
      return ('<div style="height: 100%;" layout="row" layout-align="start center">'
              +'<span class="ag-item-name">'+data+'</span>'
              +'</div>');
    }

    function cartCR (params) {
      _.set(params.$scope, 'item', params.data);
      _.set(params.$scope, 'addToCart', addToCart);
      return ('<div layout="column" layout-align="center center" class="ag-item-options">'
              +'<div class="option-button" aria-label="Add item to shopping cart" ng-click="addToCart($event, item)" ng-if="item.product_id">'
                +'<md-icon>add_shopping_cart</md-icon>'
                +'<md-tooltip>add to shopping cart</md-tooltip>'
              +'</div>'
              // promo badge goes here
            +'</div>');
    }

    function poStatusCR (params) {
      var active = ('<div class="ag-po-status layout-row layout-align-start-center">'
                +'<md-icon class="material-icons blue-icon">lens</md-icon>'
                +'<span>ACTIVE ORDER</span>'
              +'</div>');
      var draft = ('<div class="ag-po-status layout-row layout-align-start-center">'
                +'<md-icon class="material-icons syscolor3-text">lens</md-icon>'
                +'<span>DRAFT ORDER</span>'
              +'</div>');
      var item_status = params.data.po_status;
      if (_.get(item_status, 'Active.length') > 0) {
        return active;
      } else if (_.get(item_status, 'Draft.length') > 0) {
        return draft;
      } else {
        return '';
      }
    }

    function productPriceCR (params) {
      var price = params.value || 0;
      var formatted_price = $filter('currency')(price,'$',2);
      var package_name = params.data.tracking_method;
      return (
        '<div class="ag-item-price layout-column layout-align-center-center">'
          +'<span class="price-label">'+formatted_price+'</span>'
          +'<span class="package-label">PER '+package_name.toUpperCase()+'</span>'
        +'</div>'
        );
    }

    function onhandCR (params) {
      // return params.value;
      // return ('{0} {1}s').format(params.data.on_hand, params.data.tracking_method);
      _.set(params.$scope, 'item', params.data);
      return '<sow-ag-item-on-hand item="item" flex="none"></sow-ag-item-on-hand>';
    }

    function slimOnHandCR (params) {
      var package_name = params.data.tracking_method;
      var icon = '';
      if(package_name.toLowerCase() === 'item'){
        icon = '<md-icon md-svg-icon="styles/img/icons/ic-item-product.svg" alt="Item icon"></md-icon>';
      } else {
        icon = '<md-icon md-svg-icon="styles/img/icons/ic-pack.svg" alt="Box icon"></md-icon>';
      }
      return (
        '<div class="ag-item-qty layout-column layout-align-center-center">'
          +'<span class="qty-label">'+inventoryItemService.calculateInventoryOnHand(params.data)+'</span>'
          +'<span class="package-label">'+ icon + package_name.toUpperCase()+'(S)</span>'
        +'</div>'
        );
    }

    function alertsCR (params) {
      var render_icons = '<div class="layout-row layout-align-space-around-center" style="height: 100%">';
      var icons = {
        'minimum': '<md-icon class="material-icons ag-alert-icon orange-icon" alt="below minimum level" aria-label="below minimum level">opacity</md-icon>',
        'expiring': '<img class="ag-alert-icon" src="styles/img/implants/hourglass-red.svg" alt="expiring soon" aria-label="expiring soon" />',
      };
      // changed in order to account for package sizes on minimum level
      var on_hand = inventoryItemService.calculateInventoryOnHand(params.data);
      var minimum = _.get(params, 'data.minimum_level', 0);
      if (minimum > 0 && on_hand <= minimum) {
        render_icons += icons.minimum;
      }
      if (params.data.expiry_date && moment(params.data.expiry_date).isSameOrBefore(moment().add(30,'days'))) {
        render_icons += icons.expiring;
      }
      return render_icons+'</div>';
    }

    function optionsCR (params) {
      _.set(params.$scope, 'item', params.data);
      return '<sow-ag-item-options item="item" flex="none"></sow-ag-item-options>';
    }

    function checkItem (item, $event) {
      if($event.stopPropagation)
        $event.stopPropagation();
      if(!item)
        return;

      return inventoryItemService.checkItem(item);
    }

    function clearGridCheckedItems () {
      ctrl.ag_grid_options.api.deselectAll();
    }

    function clearCheckedItems () {
      clearGridCheckedItems();
      return inventoryItemService.clearCheckedItems();
    }

    function resetCheckedItems (new_items) {
      clearCheckedItems();
      _.each(new_items, function(this_item){
        checkItem(this_item);
      });
    }

    function addToCart (ev, item) {
      ev.stopPropagation();
      return mktHelperService.productDetailsFetch(item.product_id);
    }


    function defineLocks () {
      ctrl.hasCartAccess = AccessService.hasCartAccess();
    }

  }
}());

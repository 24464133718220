(function() {
  'use strict';
  
  angular
  .module('sowReports')
  .controller('SpendingSupplierDetailController', SpendingSupplierDetailController);
  
  function SpendingSupplierDetailController($scope, $window, $filter, $state, sowReportsService2, orderByFilter) {
    var ctrl = this;
    ctrl.propertyName = 'monthNumber';
    ctrl.reverse = false;
    ctrl.selectedYear = moment().year();
    ctrl.isEmptyChart = false;
    ctrl.rangeOptions = [{
      name: 'This Year',
      value: moment().year().toString()
    }];
    ctrl.currentChartShow = {
      year: ctrl.selectedYear,
      labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      data: [],
      totalSpent: 0,
      average: 0,
      month: [],
      totalShipping: 0,
    };
    Array.prototype.sum = function (prop) {
      var total = 0;
      for ( var i = 0, _len = this.length; i < _len; i++ ) {
        const num = Number(this[i][prop]);
        if (!Number.isNaN(num)) {
          total += num;
        }
      }
      return total;
    };

    ctrl.changeChartData = changeChartData;
    ctrl.chartClick = chartClick;
    ctrl.getFullMonth = getFullMonth;
    ctrl.getSpendingSupplierData = getSpendingSupplierData;
    ctrl.getSpendingSupplierDetail = getSpendingSupplierDetail;
    ctrl.getSpendingSupplierLabel = getSpendingSupplierLabel;
    ctrl.getSpendingSupplierShipping = getSpendingSupplierShipping;
    ctrl.getSupplierIdFromURL = getSupplierIdFromURL;
    ctrl.goBack = goBack;
    ctrl.goToMonthDetail = goToMonthDetail;
    ctrl.resetCurrentChartShow = resetCurrentChartShow;
    ctrl.setDataChart = setDataChart;
    ctrl.setDataToArray = setDataToArray;
    ctrl.setValueFromChart = setValueFromChart;
    ctrl.sort = sort;
    ctrl.sortBy = sortBy;
    ctrl.sumArray = sumArray;
    ctrl.updateCurrentYear = updateCurrentYear;
    ctrl.updateYearOptions = updateYearOptions;

    init();

    return ctrl;
    
    function init () {
      ctrl.supplierParams = ctrl.getSupplierIdFromURL();
      ctrl.selectedYear = ctrl.supplierParams.year.toString();
      ctrl.getSpendingSupplierDetail(ctrl.supplierParams.id, ctrl.supplierParams.year);
      ctrl.updateYearOptions();
    }
    
    function getSupplierIdFromURL () {
      var params = $state.params;
      var paramsFromURL = {
        id: params.supplier_id,
        year:params.year,
        name: params.name
      }
      return paramsFromURL;
    }
    
    function updateCurrentYear () {
      $window.localStorage.setItem('yearLocal', ctrl.selectedYear);
      if (ctrl.selectedYear) {
        ctrl.getSpendingSupplierDetail(ctrl.supplierParams.id, ctrl.selectedYear);
      }
    }
    
    function updateYearOptions () {
      var first_date = moment($scope.$root.current_office.created_at).startOf('year');
      var last_date = moment();
      var years_count = last_date.diff(first_date, 'y');
      
      for (var i = 1; i <= years_count; i++) {
        var year_number = moment().subtract(i, 'y').format('YYYY');
        ctrl.rangeOptions.push({
          name: year_number,
          value: year_number
        });
      }
    }
    
    function getSpendingSupplierDetail (supplierID, selectedYear) {
      sowReportsService2.getSpendingSupplierDetail(supplierID, selectedYear).then(function(data) {
        ctrl.chartData = data;
        ctrl.changeChartData();
      });
    }
    
    ctrl.officeID = sow.officeInfo().id;
    
    function resetCurrentChartShow () {
      ctrl.isEmptyChart = false;
      ctrl.currentChartShow = {
        year: ctrl.selectedYear,
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        data: [],
        totalSpent: 0,
        average: 0,
        month: [],
        totalShipping: 0,
      };
    }
    
    function changeChartData () {
      //Reset current chart show
      ctrl.resetCurrentChartShow();
      
      var report = ctrl.chartData[0].report;
      var orders = report.orders;
      
      if(orders.length > 0){
        ctrl.setDataChart(orders);
      }else{
        ctrl.isEmptyChart = true;
      }
    }
    
    function setDataChart (orders){
      var dataAfterChange = ctrl.setValueFromChart(orders);
      var newData = ctrl.setDataToArray(dataAfterChange);
      var totalSpending = newData.reduce(ctrl.sumArray, 0);
      var average = totalSpending / newData.length;
      var totalShipping = dataAfterChange.sum('shipping');
      
      //set data to chart
      ctrl.currentChartShow.data.push(newData);
      ctrl.currentChartShow.month = ctrl.sort(dataAfterChange, 'monthNumber', true);
      ctrl.currentChartShow.totalSpent = totalSpending;
      ctrl.currentChartShow.average = average;
      ctrl.currentChartShow.totalShipping = totalShipping;
    }
    function setDataToArray (dataAfterChange){
      var data = [];
      for(var i = 0; i < dataAfterChange.length ; i++){
        data.push(dataAfterChange[i].total);
      }
      return data;
    }
    
    function getFullMonth (){
      var fullMonth = [];
      for(var i = 1; i < 13; i++){
        fullMonth.push({
          month: moment(i, 'M').format('MMM'),
          orders: 0,
          shipping: 0,
          total: 0,
        });
      }
      return fullMonth;
    }
    
    function setValueFromChart (order){
      var fullMonth = ctrl.getFullMonth();
      for(var i = 0; i< fullMonth.length; i++){
        for(var j = 0; j < order.length ; j++){
          if(fullMonth[i].month == order[j].month){
            fullMonth[i].orders = order[j].orders;
            fullMonth[i].shipping = order[j].shipping;
            fullMonth[i].total = order[j].total;
          }
        }
        fullMonth[i].monthNumber = parseInt(moment(fullMonth[i].month, 'MMM').format('M'));
        fullMonth[i].month = moment(fullMonth[i].month, 'MMM').format('MMMM');
      }
      if(ctrl.selectedYear == moment().year()){
        var month = moment().month() + 1;
        fullMonth.length = month;
      }
      
      return fullMonth;
    }
    
    function getSpendingSupplierData (suppliers) {
      var spendingData = [];
      if (suppliers.length > 0) {
        for (var i = 0; i < suppliers.length; i++) {
          var item = suppliers[i];
          if (item.total > 0) {
            spendingData.push(item.total);
          }
        }
      }
      return spendingData;
    }
    
    function getSpendingSupplierLabel (suppliers) {
      var spendingLabel = [];
      if (suppliers.length > 0) {
        for (var i = 0; i < suppliers.length; i++) {
          var item = suppliers[i];
          if (item.total > 0) {
            spendingLabel.push(item.supplier);
          }
        }
      }
      return spendingLabel;
    }
    
    function getSpendingSupplierShipping (suppliers) {
      var spendingData = [];
      if (suppliers.length > 0) {
        for (var i = 0; i < suppliers.length; i++) {
          var item = suppliers[i];
          if (item.shipping > 0) {
            spendingData.push(item.shipping);
          }
        }
      }
      return spendingData;
    }
    
    function sumArray (a, b) {
      return a + b;
    }
    
    ctrl.chartSetting = {
      options: {
        scales: {
          xAxes: [{
            stacked: true,
            ticks: {
              beginAtZero: true
            }
          }],
          yAxes: [{
            stacked: false,
            ticks: {
              beginAtZero: true,
              callback: function(value, index, values) {
                if(index == values.length-1){
                  return value;
                }else{
                  return $filter('currency')(value, '$', 0);
                }
              }
            }
          }]
        }
      },
      series: ['Spending'],
      type: "StackedBar",
      colors: [{
        backgroundColor: '#33bbc4',
        pointBackgroundColor: 'rgba(148,159,177,1)',
        pointHoverBackgroundColor: 'rgba(148,159,177,1)',
        borderColor: '#33bbc4',
        pointBorderColor: '#fff',
        pointHoverBorderColor: 'rgba(148,159,177,0.8)'
      }]
    }
    
    function sort (array, sortByName, sortOrder) {
      var newArray = [];
      if (array.length > 0) {
        newArray = orderByFilter(array, sortByName, sortOrder);
      }
      return newArray;
    }
    
    function sortBy (propertyName) {
      ctrl.reverse = (ctrl.propertyName === propertyName) ? !ctrl.reverse : false;
      ctrl.propertyName = propertyName;
      var month = ctrl.currentChartShow.month;
      if(month.length > 0){
        ctrl.currentChartShow.month = orderByFilter(month, ctrl.propertyName, ctrl.reverse);
      }
    };
    
    //go to month detail
    function goToMonthDetail (supplier_id, month, year) {
      var name = (ctrl.supplierParams.name).trim();
      $state.go('app.reports.spending.supplier-month', { supplier_id: supplier_id, month: month, year: year, supplier_name: name  });
    }
    
    function goBack () {
      $state.go('app.reports.spending.supplier');
    }
    
    function chartClick (points){
      if(points.length > 0){
        var month =points[0]._model.label;
        ctrl.goToMonthDetail(ctrl.supplierParams.id, moment(month, 'MMM').format('MMMM') ,ctrl.selectedYear);
      }
    }
    
    ctrl.init();
  }
}());
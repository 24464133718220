(function () {
  'use strict';

  angular.module('app.marketplace.ui.inventory').directive('inventoryItemContent', InventoryItemContent);

  function InventoryItemContent () {
    return {
      restrict: 'E',
      replace: true,
      templateUrl: 'templates/marketplace/inventory/inventory-item-content.html',
      controller: 'editInventoryItemController'
    };
  }
}());

(function () {
  'use strict';

  angular.module('sowMarketplace')
    .controller('sowMktSearchController', sowMktSearchController);

  function sowMktSearchController($q, $scope, $rootScope, $timeout, $filter, $state, $stateParams, $location, sowMktService, sowAlgoliaService, errorService, $compile, membershipService) {
    /*jshint validthis:true*/
    var ctrl = this;
    ctrl.search_query = null;
    ctrl.search_category = null;
    ctrl.name = 'All Categories';
    ctrl.categories = [];
    ctrl.category_dict = {};
    ctrl.subcategory_dict = {};
    ctrl.search_suggestions = [];
    ctrl.search_promise = null;
    ctrl.dropdownActive = false;
    ctrl.length_search = 8;
    ctrl.overlay_visible = false;

    ctrl.goToSearchPage = goToSearchPage;
    ctrl.handleKey = handleKey;
    ctrl.handleKeyPress = handleKeyPress;
    ctrl.clickSuggestion = clickSuggestion;
    ctrl.clickCategorySuggestion = clickCategorySuggestion;
    ctrl.updateSearch = updateSearch;
    ctrl.directSuggestionSearch = directSuggestionSearch;

    ctrl.selectedCategory = selectedCategory;
    ctrl.scrollBottom = scrollBottom;
    ctrl.toggleDropdown = toggleDropdown;
    ctrl.toggleOverlay = toggleOverlay;
    ctrl.getMainImage = getMainImage;
    ctrl.rankCategoryData = rankCategoryData

    ctrl.active_algolia_query_search = null;
    ctrl.active_algolia_category_search = null;
    ctrl.input_bar_focused = false;

    var SEARCH_SUGGESTION_TYPE = {
      'category': 'category',
      'subcategory': 'subcategory',
      'query': 'query'
    }

    ctrl.force_category_search = false;

    var everywhere = angular.element(window.document);
    everywhere.on('click', function (event) {
      var className = event.target.className;
      var n = className?.indexOf?.("not-closed");
      if (n < 0) {
        $timeout(function () {
          ctrl.dropdownActive = false;
        });
      }
    });

    ctrl.seeFullSearch = function (number) {
      // ctrl.length_search = number;
      goToSearchPage();
    }

    init();

    return ctrl;

    function init() {
      ctrl.inputMode = $scope.inputMode || 'full';
      ctrl.destination = $scope.destination || 'search';
      $scope.$on('mkt-search-clean-input', cleanInputs);
      loadCategories().then(initParams);

      $scope.$on('query_suggestion_clicked', function (event, search_query) {
        ctrl.search_query = search_query;
      });

    }

    function toggleDropdown() {
      ctrl.dropdownActive = !ctrl.dropdownActive;
    }

    function toggleOverlay(value, search_query, search_results) {

      /**
       * Note the, `ctrl.input_bar_focused` is to prevent the 
       * `clickAnywhereButSuggestion` method in the file 
       * `sow-mkt-ab-search-suggestions-ctrl.js` to hide the
       * suggestion results.
       * 
       * The `sow-mkt-ab-search.html` input focus handles the display of the results
       * when the input bar is focused.  This dismissal of the results bar
       * is handled in the `sow-mkt-ab-search-suggestions.html` for any
       * clicks "anywhere" and clicks within the results which perform the
       * search required by the user.
       * 
       */

      if (_.isNil(value)) {
        ctrl.overlay_visible = !ctrl.overlay_visible;
        if (typeof search_results !== 'undefined') {
          $scope.$broadcast('overlay_visible_changed', search_query, search_results, value, ctrl.input_bar_focused);
        }

      } else {
        ctrl.overlay_visible = value;

        if (typeof search_results !== 'undefined') {

          // If the search results are empty, add the original query
          if (Array.isArray(search_results) && search_results.length === 0) {
            search_results.push({
              name: search_query,
              type: 'originalQuery',
            });
          }
          $rootScope.$broadcast('overlay_visible_changed', search_query, search_results, value, ctrl.input_bar_focused);
        }
      }

      // Reset the `input_bar_focused`
      ctrl.input_bar_focused = false;

    }

    function setSuggestionOrder() {

      //TODO: Move the original query to above the first term before categories
      // Only! if there are categories

      var deferred = $q.defer();

      if (ctrl.search_suggestions.length > 0) {

        // Given the original query may appear within the suggestion results
        // from algolia, we do the following:
        //
        // - If the suggestion queries contain the suggestion result, remove
        // - If the search suggestions contain categories or subcategories, move
        // the original search query to the row above the first category or
        // subcategory.


        // Slice data to the maximum of `length_search`
        ctrl.search_suggestions = ctrl.search_suggestions.slice(0, ctrl.length_search);

        // Check of the search suggestion queries equal the original query:
        var original_query_inserted = false;
        var original_query = { 'name': ctrl.search_query, 'id': null, 'type': 'originalQuery' };
        for (var i = 0; i < ctrl.search_suggestions.length; i++) {
          var search_query = ctrl.search_suggestions[i].name.replace(/(<([^>]+)>)/gi, "");

          // If the search query and search query suggestions match -- remove from array          
          if (search_query === ctrl.search_query) {
            ctrl.search_suggestions.splice(i, 1);
          }

          // Since the array is ordered by:
          // - query
          // - categories or subcategory
          // The first category or subcategory should insert the original
          // query above the category or subcategory row.

          if (ctrl.search_suggestions[i].type == SEARCH_SUGGESTION_TYPE.category
            || ctrl.search_suggestions[i].type == SEARCH_SUGGESTION_TYPE.subcategory) {

            // Insert in index position `i`
            ctrl.search_suggestions.splice(i, 0, original_query);
            original_query_inserted = true;
            break;
          }
        }

        // Append the original search term at the top (e.g. ... in All Categories)
        if (!original_query_inserted) {
          ctrl.search_suggestions.unshift(original_query);
        }
      }
      deferred.resolve(ctrl.search_suggestions);
      return deferred.promise;
    }

    function scrollBottom(e) {
      $timeout(function () {
        var objDiv = $(e.currentTarget).parent().find('ul').get(0);
        objDiv.scrollTop = objDiv.scrollHeight;
      }, 100)
    }

    function selectedCategory(name, id) {
      ctrl.name = name;
      ctrl.search_category = id;
      ctrl.dropdownActive = false;
      ctrl.updateSearch();
    }

    function initParams() {
      // ctrl.search_category = $stateParams['cat_id'] || null;
      ctrl.search_query = $stateParams['query'] || null;
    }

    function cleanInputs() {
      ctrl.search_query = null;
      ctrl.search_category = null;
      ctrl.search_promise = null;
    }

    function goToSearchPage() {
      // Oct 2020: we had lots of empty search results due to this auto-filtering
      // eg: people would enter PPE and then search for something outside of it like gloves
      // and get basically nothing bc there's no gloves in ppe
      // I'm un-setting them all except for the search query
      // so it's a universal search now
      var params = {
        'query': ctrl.search_query,
        // 'cat_id': ctrl.search_category
        'cat_id': null,
        'sub_id': null,
        'mf_id': null
      };
      if (!ctrl.search_category && !ctrl.search_query) {
        return;
      }
      if (ctrl.search_category && !ctrl.search_query) {
        params.category = _.find(ctrl.categories, ['id', ctrl.search_category]);
      }
      var url = null;
      if ($state.current.name.includes('sales')) {
        // in case user is already on sales tracker page, keep current selected pill, else go to all sales
        _.set(params, 'sale_filter', _.get($stateParams, 'sale_filter', 'sales_all'));
        url = 'app.sales.landing';
      } else {
        url = 'app.mkt.search';
      }
      params = _.extend({}, $stateParams, params);
      $state.go(url, params)
        .then(function () {
          cleanInputs();
          cleanSuggestions();
        });
    }

    function updateSearch() {
      if (!ctrl.search_query) {
        ctrl.search_suggestions = [];
        ctrl.overlay_visible = false;
        $scope.overlay_visible = false;
        return;
      }

      var params = {
        'category_id': ctrl.search_category,
        'query': ctrl.search_query
      };

      ctrl.search_promise = sowMktService.searchQuery(params)
        .then(getSuggestionsFromSearch)
        .catch(function (error) {
          console.warn(error);
        });
      return ctrl.search_promise;
    }

    function directSuggestionSearch() {

      // $rootScope.$broadcast('algolia_loading', true);

      if (!ctrl.search_query) {
        // Triggers when the search input-bar is cleared.
        ctrl.search_suggestions = [];
        ctrl.overlay_visible = false;

        // If the search input-bar is cleared, the next search should be
        // "global" in nature, ie. not show the subcategories
        ctrl.force_category_search = true;
        // If cleared, removed search results overlay.
        toggleOverlay(false, ctrl.search_query, ctrl.search_suggestions);
        return;
      } else if ($stateParams.facet_filters || $stateParams.tag || $stateParams.on_sale) {
        /* when the user types a query, we clear any filters or tag so
        that the search results will be global instead of a subset of
        results which also match the tag and/or facet filters */
        $location.search('facet_filters', null);
        $location.search('tag', null);
        $location.search('on_sale', null);
      }

      // Length search defines the number of results (search results length)
      ctrl.length_search = 10;
      var params = {
        'query': ctrl.search_query,
        'results_limit': ctrl.length_search,
        'cat_id': $stateParams.cat_id,
        'marketplace_id': membershipService['membership']['current_marketplace']['id']
      };

      // First search for query suggestions, if less than 3, then perform
      // a product search and their resulting categories.
      // `search_suggestions` should be comprised of:
      // 1. Search Query Suggestions
      // 2. Product (relevant) categories
      //
      // For example:
      // [{ name: round <em> bur</em>, id: null, type: "query" ... at least 3x
      //  {name: "Burs", id: "65009991-4e6c-4177-b0b1-4fdad026b34f", type: "subcategory"}
      //  }]
      //
      //
      cleanSuggestions();
      return $timeout(performSearch(params), 50);

      // Execute search with a 50ms delay -- acts as the debounce
      // note the `sow-mkt-ab-search.html` `ng-model-options`
      //  debounce is set to 50ms
      // var cancelled = $timeout.cancel(ctrl.search_promise);
      // ctrl.search_promise = $timeout(performSearch(params), 50);
      // return ctrl.search_promise;
    }

    function performSearch(params) {

      /**
      * Step 1
      * Perform a direct to Algolia the query suggestion search
      * 
      */

      return sowAlgoliaService.directQuerySuggestionSearch(params)
        .then(function (suggested_search_query) {

          /**
           * Step 4
           * If `suggested_search_query` < `ctrl.length_search`, make second Algolia call
           * to obtain categories relevant to the ctrl.`search_query`
           * 
           * If `suggested_search_query` > `ctrl.length_search`, process results
           * and display in directive 
           */
          if (suggested_search_query.length < ctrl.length_search) {
            /**
             * Step 5a
             * Build `search_suggestions` object for display
             */        
            return getQuerySuggestionsFromSearch(suggested_search_query)
              .then(function () {
                /**
                 * Step 6a
                 * Perform second Algolia call to get category data
                 */
                var direct_search_promise = sowAlgoliaService.directSuggestionSearch(params)
                  .then(function (result) {
                    /**
                     * Step 9
                     * Append Algolia category data `search_suggestions`
                     */
                    var get_suggestions_promise = getCategorySuggestionsFromSearch(result);
                    return get_suggestions_promise;
                  })
                  .then(function (result) {
                    /**
                     * Step 10
                     * Re-Order and set the data order within `search_suggestions`
                     */
                    var set_suggestion_promise = setSuggestionOrder();
                    return set_suggestion_promise;
                  })
                  .catch(function (error) {
                    console.warn(error);
                  })
                  .finally(function () {
                    /**
                     * Step 11
                     * Set `toggleOverlay` and broadcast to update directive
                     */
                    toggleOverlay(true, ctrl.search_query, ctrl.search_suggestions);
                    // $rootScope.$broadcast('algolia_loading', false);
                  });
                return direct_search_promise;
              })
          } else {
            /**
             * Step 5b
             * Build `search_suggestions` object for display
             */
            return getQuerySuggestionsFromSearch(suggested_search_query)
              .then(setSuggestionOrder)
              .finally(function () {
                toggleOverlay(true, ctrl.search_query, ctrl.search_suggestions);
                // $rootScope.$broadcast('algolia_loading', false);
              });
          }
        })
        .catch(function (error) {
          console.warn(error);
        })
    }

    function getSuggestionsFromCategories() {
      cleanSuggestions();
      var deferred = $q.defer();

      var size = _.size(ctrl.search_query);
      var query = _.lowerCase(ctrl.search_query);

      var category_search = _.slice(_.filter(ctrl.categories, function (category) {
        var category_name = _.lowerCase(category.name);
        return _.startsWith(category_name, query);
      }), 0, 5);
      var results = _.map(category_search, function (item) {
        return _.extend({ 'type': 'category' }, item);
      });

      deferred.resolve(results);
      return deferred.promise;
    }

    function getSuggestionsFromSearch(search_data) {
      cleanSuggestions();
      var deferred = $q.defer();
      var some_items = _.slice(_.get(search_data, 'results[0].hits'));
      var _listDom = angular.element('md-virtual-repeat-container');
      var _qr_str = ctrl.search_query || '';
      var _disabled = _.size(ctrl.search_query) === 0;
      var cls_name = _disabled ? '_readMore _disabled' : '_readMore';
      if (ctrl.search_query && ctrl.search_query.length > 0) {
        _listDom.append($compile('<a class="' + cls_name + '" ng-click=\"mktsdCtrl.goToSearchPage()\">See all results for “' + _qr_str + '”</a>')($scope));
      } else {
        $('._readMore').remove();
      }
      _.each(some_items, function (item) {
        var suggestion = _.extend({ 'type': 'product' }, item);
        ctrl.search_suggestions.push(suggestion);
      });
      // displays results on screen
      toggleOverlay(true);
      deferred.resolve(ctrl.search_suggestions);
      return deferred.promise;
    }

    function getCategorySuggestionsFromSearch(search_data) {
      var deferred = $q.defer();
      /**
       * Build and return Algolia data and resolve `searchPromise`
       * 
       * Build `suggested_category_data` object:
        
          [
            [
              'name': String, 
            'id': String, 
              is_subcategory: Bool
              ]
          ]
        
        For example:
          [
            [
              'name': Articulating And Occlusal Indicators, 
              'id': HsfsVTxfgejzDsVpSBwWi6, 
              is_subcategory: false
              ]
          ]
          and exclude any categories not found in the `ctrl.categories`
          such as categories from other marketplaces        
      */


      /**
       * Force Category Search Always On
       * The Subcategory search has logic gaps, ie. what happens
       * when a user highlights the text and searches again while within
       * a category?
       */

      ctrl.force_category_search = true;

      // SUBCATEGORIES
      if (typeof $stateParams.cat_id !== 'undefined' && ctrl.force_category_search == false) {
        var category_type = SEARCH_SUGGESTION_TYPE.subcategory;
        var cat_id = $stateParams.cat_id;
        var is_subcategory = true;

      } else {
        // CATEGORIES
        var category_type = SEARCH_SUGGESTION_TYPE.category;
        var is_subcategory = false;

      }

      // Rank category data on number of results and product relevancy
      var suggested_category_data = [];
      var category_results = rankCategoryData(search_data, is_subcategory);
      var limited_ranked_categories = category_results.slice(0, ctrl.length_search);

      _.forEach(limited_ranked_categories, function (category) {

        if (is_subcategory) {
          var subcategory_id = category['id'];

          // Append Subcategory
          var subcategory_name = ctrl.subcategory_dict[subcategory_id];

          // Algolia has more subcategories data than may be available for the 
          // marketplace, if undefined, skip
          if (typeof subcategory_name !== 'undefined') {
            suggested_category_data.push({
              'name': subcategory_name,
              'id': subcategory_id,
              'type': category_type
            });
          }

        } else {

          // Find category object from the cached marketplace categories
          var cat_id = category.id;
          var parentCategoryIndexPosition = _.findIndex(
            ctrl.categories, function (marketplaceCategory) {
              return marketplaceCategory.id == cat_id;
            });

          // IndexPosition may return -1 if not included in the cached 
          /// marketplace categories.  This may occur as the Algolia index
          // may contain categories from other languages, such as french
          //
          if (parentCategoryIndexPosition > -1) {

            // Append Category
            var category_name = ctrl.category_dict[cat_id];

            // Algolia has more categories data than may be available for the 
            // marketplace, if undefined, skip            
            if (typeof category_name !== 'undefined') {
              suggested_category_data.push({
                'name': category_name,
                'id': cat_id,
                'type': category_type
              });
            }
          }
        }
      })


      // Append to `search_suggestions` Category or Subcategory data to 
      ctrl.search_suggestions.push.apply(
        ctrl.search_suggestions,
        suggested_category_data
      )

      deferred.resolve(ctrl.search_suggestions);
      return deferred.promise;
    }

    function rankCategoryData(data, isSubcategory) {
      // 1. Get all the categroies from the `parent_category.id` facet.
      // For each of the facet types: 
      // 0: "subcategory.id"
      // 1: "subcategory.name"
      // 2: "parent_category.id"
      // 3: "parent_category.name"
      // 
      // The object arrays are ordered to match from Algolia.
      // For example, position 0 matches the `id` with the `name`
      //
      // The value for each the objects contain the number of results.
      // For example, 
      // `parent_category.id` = [          
      //          '5nRK3eHNffuy4oHoKQewQ3: 5
      //          55QVyiP2UEkuws5oMxANqb: 10
      //          ...
      //  ]
      //
      var categories = [];
      var facets = data['facets'];

      // 1. Rank (level 1) the categories based on the number of results
      var categories = [];

      if (isSubcategory) {
        var categoryFacets = facets['subcategory.id'];
      } else {
        var categoryFacets = facets['parent_category.id'];
      }
      for (var categoryId in categoryFacets) {
        categories.push({
          'id': categoryId,
          'value': categoryFacets[categoryId]
        });
      }

      var rankedCategories = [];
      // By default, sorted ascending.
      var rankedCategories = _.sortBy(categories, [function (category) {
        return category.value;
      }
      ]).reverse();

      // 2. Rank adjust categories based on product relevancy
      //    Get the categories from the returned results (default is 30 results)
      //    with products at top of `hits` array are more relevant
      var relevantCategories = [];

      if (isSubcategory) {
        var hit_type = 'subcategory';
      } else {
        var hit_type = 'parent_category';
      }

      _.forEach(data['hits'], function (hits) {
        var categoryObject = hits[hit_type];

        // only append if not duplicate category
        if (!relevantCategories.some(function (category) {
          return category.id === categoryObject['id']
        })) {
          relevantCategories.push(categoryObject);
        }
      })

      // 3. Merge Ranked & Relevant Categories        
      // Find indexOf relevant categories and re-rank the `RankedCategories`
      _.forEach(relevantCategories.reverse(), function (relevantCategory) {
        var indexPosition = _.findIndex(rankedCategories, function (category) {
          var categoryId = category.id;
          return categoryId == relevantCategory.id
        });
        var categoryToShift = rankedCategories.splice(indexPosition, 1)[0];
        rankedCategories.unshift(categoryToShift);
      })

      return rankedCategories;
    }

    function getQuerySuggestionsFromSearch(search_data) {
      var deferred = $q.defer();
      ctrl.search_suggestions.push.apply(ctrl.search_suggestions, search_data)
      deferred.resolve(ctrl.search_suggestions);
      return deferred.promise;
    }


    function cleanSuggestions() {
      ctrl.search_suggestions = [];
      ctrl.search_category = null;
      ctrl.search_promise = null;
    }

    function clickSuggestion(item) {
      if (!item) {
        return;
      }
      switch (item.type) {
        case SEARCH_SUGGESTION_TYPE.category:
          $state.go('app.mkt.search', { 'category': item, 'cat_id': item.id });
          cleanSuggestions();
          break;
        case 'product':
          ctrl.search_query = item.name;
          goToSearchPage();
          cleanSuggestions();
          break;
        default:
          break;
      }
    }

    function clickCategorySuggestion(category) {
      if (!category) {
        return;
      }
      $state.go('app.mkt.search', { 'query': ctrl.search_query, 'cat_id': category.id });
      toggleOverlay(false);
      cleanSuggestions();
    }

    function loadCategories() {
      return sowMktService.getCategories()
        .then(function (cat_response) {
          ctrl.categories = cat_response.categories;

          // TODO: Consider moving to cache within the `sowMktService`
          // Build Category Dict & Build Subcateory Dict
          _.forEach(ctrl.categories, function (category) {
            ctrl.category_dict[category['id']] = category['name'];
            // Loop each category to build subcategory dictionary
            _.forEach(category['subcategories'], function (subcategory) {
              ctrl.subcategory_dict[subcategory['id']] = subcategory['name'];
            })
          })
        })
        .catch(errorService.uiErrorHandler);
    }

    function handleKey($event) {
      if ($event.which === 13) {
        // timeout to wait for model debounce
        $timeout(function () {
          goToSearchPage();
          ctrl.isDisabled = false;
        }, 50);
      }
    }

    function handleKeyPress($event) {
      ctrl.input_bar_focused = false;
      var key = null;
      switch ($event.which) {
        case 13:
          key = 'enter';
          break;
        case 37:
          key = 'left';
          break;
        case 38:
          key = 'up';
          $event.preventDefault();
          break;
        case 39:
          key = 'right';
          break;
        case 40:
          key = 'down';
          break;
      };

      if (key === 'down' || key === 'up') {
        $rootScope.$broadcast('search_suggestions_focus', key);
      } else {
        toggleOverlay(false, ctrl.search_query, ctrl.search_suggestions);
        if (key === 'enter') {
          $rootScope.$broadcast('search_suggestions_select', key);
        }
      }
    }


    function getMainImage(item) {
      var img = _.get(item, 'main_image_240_box', null);
      if (!img) {
        img = _.get(item, 'main_image', null);
      }
      if (!img) {
        var main_object = _.filter(item.images, function (obj) {
          return obj.main_image_bool;
        });
        if (main_object) {
          img = _.get(main_object, 'image_240_box', _.get(main_object, 'image', null));
        }
      }
      return $filter('imageUrl')(img);
    }

  }

})();

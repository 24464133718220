angular.module("app.shared.support")

  .directive('isVisible', $rootScope => {
    return {
      restrict: 'A',
      link: (scope, element, attrs) => {
        const id = attrs.isVisible;
        const observer = new IntersectionObserver(entries => {
          if (entries[0].isIntersecting === true) {
            $rootScope.$broadcast('is-visible-' + id)
          }
        }, { threshold: [1] });
        observer.observe(element[0]);
      }
    };
  });


{

  const externalVendorsSection = {
    controller: 'externalVendorsSectionCtrl',
  }

  /** @ngInject */
  class externalVendorsSectionCtrl {
    constructor($rootScope, membershipService, RouteService, sgToast) {
      this.$rootScope = $rootScope;
      this.membershipService = membershipService;
      this.RouteService = RouteService;
      this.sgToast = sgToast;
    }

    $onInit() {
      this.defineLocks();
      this.checkForAccess();
      this.setExternalVendors();
      // ensure that the external vendors are passed to the template
      this.$rootScope.$watch('external_vendors', () => {
        this.setExternalVendors();
      });
    }

    checkForAccess() {
      if (this.lock_external_vendors) {
        this.RouteService.goToDefault();
      }
    }

    setExternalVendors() {
      this.external_vendors = this.$rootScope.external_vendors;
    }

    defineLocks() {
      const membership = this.membershipService.get();

      const enable_external_vendors_condition = _.get(membership, 'office.feature_flags.external_vendors', false);
      const disable_account_management_condition = _.get(membership, 'user_properties.external_vendors.disable_manage_accounts', false);
      this.lock_external_vendors = !enable_external_vendors_condition || disable_account_management_condition;
    }

    handleExternalVendorsAppEvent ($event) {
      const payload = $event.detail;
      if (payload?.externalVendor) {
        this.handleEVUpdated(payload.externalVendor);
      }
      if (payload?.toastMessage) {
        this.sgToast.showSimple(payload.toastMessage);
      }
    }

    handleEVUpdated (updatedExternalVendor) {
      this.$rootScope.external_vendors[updatedExternalVendor.vendor.id] = updatedExternalVendor;
    }
  }

  angular.module('app.marketplace.ui.dashboard')
    .controller(externalVendorsSection.controller, externalVendorsSectionCtrl);

}

(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.medications')
    .service('medHelperService', medHelperService);

  function medHelperService ($filter) {
    /*jshint validthis: true */
    var service = this;

    service.parseSingleMedication = parseSingleMedication;
    service.parseMedications = parseMedications;
    service.setExpiryErrors = setExpiryErrors;

    return service;

    function parseSingleMedication (med) {
      var medication = angular.copy(med);
      medication.code = medication.barcode;
      if(medication.supplier_id){
        medication.supplier = {
          id: medication.supplier_id,
          name: medication.supplier_name
        };
      } else {
        medication.supplier = null;
      }

      if(medication.manufacturer_id){
        medication.manufacturer = {
          id: medication.manufacturer_id,
          name: medication.manufacturer_name
        };
      } else{
        medication.manufacturer = null;
      }

      if(!_.isNil(medication.po_status) && !_.isEmpty(medication.po_status)){
        medication.purchase_order = true;
        var tooltip = '';
        var tooltip_text = '';
        var keys = Object.keys(medication.po_status);
        if (keys.includes('Received')) {
          keys.splice(keys.indexOf('Received'),1);
        }
        var po_array = [];
        _.each(keys, function(key){
          po_array = po_array.concat(medication.po_status[key]);
        });

        var t_in = $filter('translate')('COMMON.IN');
        var t_and = $filter('translate')('COMMON.AND');
        var t_from = $filter('translate')('COMMON.FROM');

        var poText = _.map(po_array, function (item) {
          return !item.po_supplier ? item.po_name
            : '{0} {1} {2}'.format(item.po_name, t_from, item.po_supplier);
        });

        tooltip = t_in+' ' + _.compact([
          poText.slice(0, -1).join(',\n'),
          poText.slice(-1),
        ]).join('\n'+t_and+' ');
        medication.tooltip = tooltip;

        if(keys.indexOf("Active") > -1){
          medication.tooltip_text = "ORDERS.ACTIVE";
        } else if(keys.indexOf("Draft") > -1){
          medication.tooltip_text = "ORDERS.DRAFT_ORDER";
        } else {
          medication.purchase_order = false;
        }
      }

      // Calculate Errors
      medication.errors = {};

      if (medication.minimum_level && medication.quantity <= medication.minimum_level) {
        medication.errors['below_minimum_level'] = true;
      }

      if (medication.stock) {
        setExpiryErrors(medication, medication.stock);
      }

      medication.error_count = Object.keys(medication.errors).length;
      return medication;
    }

    function generateTooltip (poStatusList) {
      if (!poStatusList) {
        return '';
      }
      var t_in = $filter('translate')('COMMON.IN');
      var t_and = $filter('translate')('COMMON.AND');
      var t_from = $filter('translate')('COMMON.FROM');

      var poText = _.map(poStatusList, function (item) {
        return !item.po_supplier ? item.po_name
          : '{0} {1} {2}'.format(item.po_name, t_from, item.po_supplier);
      });

      return t_in+' ' + _.compact([
        poText.slice(0, -1).join(',\n'),
        poText.slice(-1),
      ]).join(',\n'+t_in+' ');
    }

    function parseMedications (medications_list) {
      return _.map(medications_list, parseSingleMedication);
    }

    function setExpiryErrors (medication, stock_list) {
      var earliest_date = null;
      var now = moment();
      var limit_date = moment().add(60, 'd');
      _.map(stock_list, function(stock_item) {
        var stock_date = moment(stock_item.expiry_date);
        if(!earliest_date || stock_date.isSameOrBefore(earliest_date))
          earliest_date = stock_date;
      });
      _.set(medication, 'expiry_date', earliest_date);
      if(!earliest_date){
        return;
      }
      if (earliest_date.isSameOrBefore(now)) {
        _.set(medication, 'errors.expired', true);
      } else if (earliest_date.isSameOrBefore(limit_date)) {
        _.set(medication, 'errors.expiring_soon', true);
      }
    }

  }
 }());

(function () {
  'use strict';

  angular
    .module('app.marketplace.system')
    .provider('appEvents', appEventsProvider);

  //
  // The appEvents Provider
  //
  // This is a way to have a config()-able object as a central place to
  // store/access internal app events.
  //
  function appEventsProvider () {
    /*jshint validthis: true */
    var provider = this;

    provider.events = {};
    provider.registerEvent = registerEvent;
    provider.$get = instantiate;

    return provider;

    function registerEvent (name, value) {
      provider.events[name] = value;
    }

    function instantiate () {
      return provider.events;
    }
  }

}());

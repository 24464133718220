angular.module('app.marketplace.ui.office').controller('OfficeSubscriptionsController',
  function OfficeSubscriptionsController($scope, $log, $state, elementService, errorService, accountService, membershipService) {
    $scope.changePlan = function (plan) {
      $state.go('app.office.profile.subscription.upgrade', {'plan': plan});
    };

    //
    // Initialize that shit, yo!
    //
    var init = function (account) {
      $scope.account = account;
      $scope.edit_account = angular.copy(account);

      if ($scope.accountInfoForm) {
        $scope.accountInfoForm.$setPristine();
      }

      $scope.$on('accountService: set-account', function (scope, account) {
        init(account);
      });

      $scope.$on('accountService: clear-account', function() {
        init();
      });
    };

    init(accountService.get());
  }
);

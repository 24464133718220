angular.module("app.shared.support")
  .directive('ngMatch', ['$parse',
    function ($parse) {
      return {
        require: '?ngModel',
        restrict: 'A',
        scope: {
          ngMatch: '='
        },
        link: function (scope, elem, attrs, ctrl) {
          scope.$watch(function () {
            return (ctrl.$pristine && angular.isUndefined(ctrl.$modelValue)) || scope.ngMatch === ctrl.$modelValue;
          }, function (currentValue) {
            ctrl.$setValidity('match', currentValue);
          });
          if (!ctrl) {
            return;
          }
          var firstField = $parse(attrs["ngMatch"]);

          var validator = function (value) {
            var temp = firstField(scope),
              v = value === temp;
            ctrl.$setValidity('match', v);
            return value;
          };

          ctrl.$parsers.unshift(validator);
          ctrl.$formatters.push(validator);
          attrs.$observe("ngMatch", function () {
            validator(ctrl.$viewValue);
          });
        },
      };
    }
  ]);
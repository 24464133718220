/** @ngInject */
class coreUIService {
  constructor($mdDialog, $filter) {
    this.$mdDialog = $mdDialog;
    this.$filter = $filter;
  }

  /**
   * Here's an example of what might be sent as actions
   * ```
   * const actions = [
   *   {
   *     cta: 'PO.GOT_IT',
   *     classes: 'sow-primary-btn sow-lg-btn',
   *     name: 'dialog-confirm',
   *     return_value: true
   *   },
   *   {
   *     cta: 'COMMON.YES',
   *     classes: 'sow-green-btn sow-lg-btn',
   *     name: 'dialog-yes'
   *     callback: () => { doSomething(); }
   *   },
   *   {
   *     cta: 'COMMON.NO',
   *     classes: 'sow-delete-btn sow-lg-btn',
   *     name: 'dialog-no',
   *     return_value: false
   *   }
   * ];
   * ```
  */

  // this is a basic dialog with headline, tagline and actions. 
  // However, we can do something more complex in the future by adding
  // an HTML template through `$templateCache.get(templateUrl);`
  // eg. a form, an alert, or any other html-based information
  // and use the `scrollable-dialog` class as a base for it

  /**
   * Shows a standard style dialog 
   *
   * @param {string} headline
   * @param {string} tagline
   * @param {Array} actions - expects objects like this one: 
   * ```
   * {
   *  cta: 'ACTIONS.SAVE', (localization string)
   *  name: 'dialog-save-btn', 
   *  classes: 'sow-primary-btn sow-lg-btn' (as many as needed)
   * }
   * ```
   * @param {boolean} hideCloseBtn pass true if you want to hide the `x` in the top right corner
   * 
   * @memberof coreUIService
   */
  showDialog (headline, tagline, actions, hideCloseBtn) {
    headline = this.$filter('translate')(headline);
    tagline = this.$filter('translate')(tagline);
    return this.$mdDialog.show({
      controller: 'sowDialogController',
      controllerAs: 'dialogCtrl',
      templateUrl: 'sow-core-ui/components/sow-dialog.html',
      parent: angular.element(document.body),
      clickOutsideToClose: false,
      locals:{
        headline,
        tagline,
        actions,
        hideCloseBtn,
      },
      bindToController: true,
    });
  }

  /* Some default buttons provided as a shortcut */

  cancelButton (cta = 'ACTIONS.CANCEL') {
    return {
      cta,
      classes: 'sow-cancel-btn sow-lg-btn',
      name: 'dialog-cancel-btn',
      return_value: false
    };
  }

  primaryButton (cta) {
    return {
      cta,
      classes: 'sow-primary-btn sow-lg-btn',
      name: 'dialog-confirm-btn',
      return_value: true
    };
  }

  deleteButton (cta) {
    return {
      cta,
      classes: 'sow-delete-btn sow-lg-btn',
      name: 'dialog-delete-btn',
      return_value: true
    };
  }
}

angular.module('sowCoreUI')
  .service('coreUIService', coreUIService);

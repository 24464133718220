angular.module('app.marketplace.ui.inventory')
  .service('searchInventoryService', function () {
    this.map = {
      product: {
        name: 'COMMON.PRODUCT_NAME',
        example: 'Eg: Velocity Nitrile Powder Free Gloves',
        cta: 'Add New Product'
      },
      medication: {
        name: 'MEDICATIONS.NAME',
        example: 'Eg: Propofol',
        cta: 'MEDICATIONS.ADD_GENERAL'
      },
      emergencyMedication: {
        name: 'MEDICATIONS.EMERGENCY_NAME',
        example: 'Eg: Propofol',
        cta: 'MEDICATIONS.ADD_EMERGENCY'
      }
    };
  });

(function () {
  'use strict';
  angular.module('sowOrders')
    .directive('sowOrderPaywall', sowOrderPaywall);

  function sowOrderPaywall() {
    return {
      replace: true,
      restrict: 'E',
      templateUrl: 'sow-orders/slideouts/order-receive-paywall.html',
      scope: {
        onDoneClicked: '&',
      },
      link: function ($scope) {
        $scope.goToCalendly = goToCalendly;

        function goToCalendly () {
          const url = "https://calendly.com/sowingosales/consultation";
          const win = window.open(url, '_blank');
          win.focus();   
        }
      }
    };
  }

}());

angular.module("app.marketplace.ui.implants")
  .controller("referringDoctorsController",
  function($q, $scope, $filter, sgToast, $mdDialog, errorService, referringDoctorsService, sowImplantService, $stateParams) {
    var ctrl = this;
    ctrl.done_loading = false;
    ctrl.doctors = [];
    ctrl.manufacturers = [];

    ctrl.edit_doctor = edit_doctor;
    ctrl.add_doctor = add_doctor;
    ctrl.remove_doctor = remove_doctor;
    ctrl.getCompanyName = getCompanyName;

    ctrl.$onInit = init;

    return ctrl;

    /**
     *
     * @method init
     * @desc initialize the controller
     *
     */
    function init() {
      $scope.$on('ab-add-doctor', ctrl.add_doctor);

      $q.all([
        loadDoctors(),
        loadCompanies()
      ]).finally(() => {
        ctrl.done_loading = true;
        initParams();
      });
    }

    function loadDoctors () {
      return referringDoctorsService.getReferringDoctors()
      .then(function (doctors) {
        ctrl.doctors = doctors;
      }).catch(function (error) {
        const t_message = $filter('translate')('ERRORS.DOCTORS_RETRIEVE')
        errorService.uiErrorHandler(error || t_message, 3000);
      });
    }

    function loadCompanies () {
      return sowImplantService.getManufacturers()
      .then(function(mf_response){
        ctrl.manufacturers = mf_response;
      })
      .catch(function(error){
        const t_message = $filter('translate')('ERRORS.MANUFS_LOAD');
        uiErrorHandler(error || t_message);
      }); 
    }

    function initParams () {
      if (!!$stateParams.add) {
        add_doctor();
      }
    }

    function edit_doctor(ev, doctor) {
      var initial_doc = angular.copy(doctor);
      ev.stopPropagation();
      ev.preventDefault();
      return $mdDialog.show({
        controller: 'poDialogController as dialogCtrl',
        templateUrl: 'templates/marketplace/office/modals/office-doctor.html',
        parent: angular.element(document.body),
        clickOutsideToClose:true,
        locals: {
          doctor: angular.copy(doctor),
          modalType: 'edit',
          manufacturers: ctrl.manufacturers,
        },
        bindToController: true,
      })
      .then(function (answer) {
        referringDoctorsService.createOrUpdateReferringDoctors(answer)
        .then(function (response) {
          updateLocalDoctor(response);
          var t_message = $filter('translate')('TOAST.REFERRING_DOCTOR_UPDATED');
          sgToast.showSimple(t_message);
        }).catch(function (error) {
          doctor = initial_doc;
          var t_message = $filter('translate')('ERRORS.DOCTOR_EDIT')
          errorService.uiErrorHandler(error || t_message, 3000);
        });
      });
    }

    function updateLocalDoctor (new_doc) {
      var index = _.findIndex(ctrl.doctors, ['id', new_doc.id]);
      if (index >= 0) {
        ctrl.doctors[index] = new_doc;
      } else {
        ctrl.doctors.push(new_doc);
      }
    }

    function add_doctor() {
      if (!ctrl.done_loading) return;
      $mdDialog.show({
        controller: 'poDialogController as dialogCtrl',
        templateUrl: 'templates/marketplace/office/modals/office-doctor.html',
        parent: angular.element(document.body),
        clickOutsideToClose:true,
        locals: {
          doctor: {},
          modalType: 'new',
          manufacturers: ctrl.manufacturers,
        },
        bindToController: true,
      })
      .then(function (answer) {
        referringDoctorsService.createOrUpdateReferringDoctors(answer)
        .then(function (doctor) {
          sgToast.showSimple($filter('translate')('COMMON.REF_DOC_CREATED'));
          updateLocalDoctor(doctor);
        }).catch(function (error) {
          var t_message = $filter('translate')('ERRORS.DOCTOR_CREATE')
          errorService.uiErrorHandler(error || t_message, 3000);
        });
      });
    }

    /**
     * @method remove_doctor
     * @desc open modal to confirm doctor removal
     * @param {*} doctor
     */
    function remove_doctor(ev, doctor) {
      ev.stopPropagation();
      ev.preventDefault();
      $mdDialog.show({
        controller: 'poDialogController as dialogCtrl',
        templateUrl: 'templates/marketplace/office/modals/remove-office-doctor.html',
        parent: angular.element(document.body),
        clickOutsideToClose:true,
        locals: {
          doctor: doctor
        },
        bindToController: true,
      })
      .then(function (answer) {
        if (answer) {
          referringDoctorsService.deleteReferringDoctor(doctor).then(function(response){
            sgToast.showSimple($filter('translate')('COMMON.REF_DOC_REMOVED'));
            _.remove(ctrl.doctors, doctor);
          });
        }
        
      });
    }

    function getCompanyName (mf_id) {
      var manufacturer = _.find(ctrl.manufacturers, ['id', mf_id]);
      return manufacturer ? manufacturer.name : '';
    }

  });

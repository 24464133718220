(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.shopping-aaoms')
    .controller('ShoppingAaomsActionBarController', ShoppingAaomsActionBarController)
    .directive('actionBarShoppingAaoms', actionBarShoppingAaoms);

  function actionBarShoppingAaoms () {
    return {
      restrict: 'AC',
      replace: true,
      templateUrl: 'templates/marketplace/shopping-aaoms/directives/action-bar-shopping-aaoms.html',
      controller: 'ShoppingAaomsActionBarController',
    };
  }

  // $scope, $mdMenu, categoryService, vendorService, NavDropdownMenuService 
  function ShoppingAaomsActionBarController () {
    /*jshint validthis: true */
    var ctrl = this;

    return ctrl;

  }

}());

(function () {
  'use strict';

  angular.module('sowMarketplace')
  .controller('marketplaceAdressFormController', marketplaceAdressFormController)
  .directive('mktAdressForm', marketplaceAdressFormDirective);

  function marketplaceAdressFormDirective () {
    return {
      restrict: 'E',
      templateUrl: 'sow-mkt/directives/address-form.html',
      controller: 'marketplaceAdressFormController',
      controllerAs: 'mktafCtrl',
      scope: true,
    };
  }

  function marketplaceAdressFormController ($scope, $rootScope) {
    /*jshint validthis:true*/
    var ctrl = this;

    init();

    return ctrl;

    function init () {
      
    }
    
  }

})();
(function () {
  'use strict';
  
  angular
  .module('sowReports')
  .controller('SpendingCategoryMonthDetailController', SpendingCategoryMonthDetailController);
  
  function SpendingCategoryMonthDetailController($scope, $window, $rootScope, $state, $filter, sowReportsService2, orderByFilter) {
    var ctrl = this;
    ctrl.isChartEmpty = false;
    ctrl.productPropertyName = 'product_name';
    ctrl.productReverse = false;
    var t_spending = $filter('translate')('COMMON.SPENDING');
    var t_manufacturer = $filter('translate')('COMMON.MANUFACTURER');
    var t_product_name = $filter('translate')('COMMON.PRODUCT_NAME');
    
    ctrl.rangeOptions = [
      {
        name:moment().format('MMMM, YYYY'),
        value:moment().format('YYYY-M')
      }
    ];
    ctrl.searchProductType = 'product_name';
    ctrl.productTypeSearch = [
      {
        type: 'product_name',
        value: t_product_name
      },
      {
        type: 'manufacturer',
        value: t_manufacturer
      }
    ];
    ctrl.chartSetting = {
      options :{
        scales: {
          xAxes: [{
            stacked: true,
            ticks: {
              beginAtZero: true
            }
          }],
          yAxes: [{
            stacked: false,
            ticks: {
              beginAtZero: true,
              callback: function(value, index, values) {
                if(index == values.length-1){
                  return value;
                }else{
                  return $filter('currency')(value, '$', 0);
                }
              }
            }
          }]
        }
      },
      series :[t_spending],
      type : "StackedBar",
      colors : [
        { 
          backgroundColor: '#9667d8',
          pointBackgroundColor: 'rgba(148,159,177,1)',
          pointHoverBackgroundColor: 'rgba(148,159,177,1)',
          borderColor: '#9667d8',
          pointBorderColor: '#fff',
          pointHoverBorderColor: 'rgba(148,159,177,0.8)'
        },
        { 
          backgroundColor: '#dddddd',
          pointBackgroundColor: 'rgba(77,83,96,1)',
          pointHoverBackgroundColor: 'rgba(77,83,96,1)',
          borderColor: '#dddddd',
          pointBorderColor: '#fff',
          pointHoverBorderColor: 'rgba(77,83,96,0.8)'
        },
        { 
          backgroundColor: '#fa9e4d',
          pointBackgroundColor: 'rgba(77,83,96,1)',
          pointHoverBackgroundColor: 'rgba(77,83,96,1)',
          borderColor: '#fa9e4d',
          pointBorderColor: '#fff',
          pointHoverBorderColor: 'rgba(77,83,96,0.8)'
        }
      ]
    };
    
    Array.prototype.sum  = function (prop) {
      var total = 0;
      for ( var i = 0, _len = this.length; i < _len; i++ ) {
        const num = Number(this[i][prop]);
        if (!Number.isNaN(num)) {
          total += num;
        }
      }
      return total;
    };

    ctrl.getDataFormParams = getDataFormParams;
    ctrl.resetChart = resetChart;
    ctrl.updateCurrentYear = updateCurrentYear;
    ctrl.getCategoryDetail = getCategoryDetail;
    ctrl.changeDataChart = changeDataChart;
    ctrl.setDataToChart = setDataToChart;
    ctrl.getLabel = getLabel;
    ctrl.setDataForDay = setDataForDay;
    ctrl.productSort = productSort;
    ctrl.changesearchProductType = changesearchProductType;
    ctrl.goBack = goBack;
    ctrl.sumArray = sumArray;
    ctrl.sort = sort;
    ctrl.updateMonthYearOptions = updateMonthYearOptions;
    ctrl.goToProductReport = goToProductReport;
    ctrl.goToMonthDetail = goToMonthDetail;
    
    ctrl.openPriceHistory = openPriceHistory;
    ctrl.openItemDetails = openItemDetails;
    ctrl.openProductDetails = openProductDetails;
    
    init();
    
    return ctrl;
    
    function init  () {
      ctrl.getDataFormParams();
      ctrl.resetChart();
      ctrl.updateMonthYearOptions();
      ctrl.getCategoryDetail(ctrl.selectedYear, ctrl.categoryID);
    }
    
    function getDataFormParams (){
      ctrl.categoryID = $state.params.categoryID;
      ctrl.selectedYear = $state.params.year + '-' + $state.params.month;
      ctrl.categoryName = $state.params.categoryName;
      ctrl.month = $state.params.month;
      ctrl.year = $state.params.year;
    }
    
    function resetChart (){
      ctrl.isChartEmpty = false;
      ctrl.currentChartShow = {
        labels: [],
        datas: [],
        year: moment(ctrl.selectedYear, 'YYYY-M').format('MMMM, YYYY'),
        categoryName: ctrl.categoryName,
        totalSpent: 0,
        average: 0,
        orderPlaced: 0,
        products: []
      };
    }
    
    function updateCurrentYear (){
      $window.localStorage.setItem('yearLocal', moment(ctrl.selectedYear,'YYYY-M').format('YYYY'));
      ctrl.resetChart();
      ctrl.getCategoryDetail(ctrl.selectedYear, ctrl.categoryID);
    }
    
    function getCategoryDetail (yearMonth, categoryID){
      sowReportsService2.getCategoryMonthDetail(yearMonth, categoryID).then(function(data){
        ctrl.changeDataChart(data);
      });
    }
    
    function changeDataChart (data){
      var report = data[0].report;
      var orders = report.orders;
      var products = report.products;
      
      if(orders.length > 0){
        ctrl.setDataToChart(orders);
      }else{
        ctrl.isChartEmpty = true;
      }
      
      if(products.length > 0){
        ctrl.currentChartShow.products = products;
      }
    }
    
    function setDataToChart (orders){
      var labels = ctrl.getLabel(ctrl.month, ctrl.year);
      var data = ctrl.setDataForDay(labels, orders);
      var totalSpent = data.reduce(ctrl.sumArray,0);
      var average = totalSpent/labels.length;
      
      ctrl.currentChartShow.datas.push(data);
      ctrl.currentChartShow.labels = labels;
      ctrl.currentChartShow.totalSpent = totalSpent;
      ctrl.currentChartShow.average = average;
      ctrl.currentChartShow.orderPlaced = orders.length;
    }
    
    function getLabel  (month, year) {
      var monthYearNumber = year + '-' + month;
      var countMonth = moment(monthYearNumber, "YYYY-M").daysInMonth();
      var monthDay = moment(monthYearNumber, "YYYY-M").format('MMM');
      var daysList = [];
      for (var i = 1; i <= countMonth; i++) {
        daysList.push(monthDay + ' ' + i);
      }
      return daysList;
    }
    
    function setDataForDay  (labels, orders) {
      var data = [];
      for (var i = 0; i < labels.length; i++) {
        data.push(0);
      }
      
      var result = [];
      orders.forEach(function (a) {
        if (!this[a.date]) {
          this[a.date] = { date: a.date, total_spent: 0 };
          result.push(this[a.date]);
        }
        this[a.date].total_spent += parseFloat(a.total_spent);
      }, Object.create(null));
      
      for (var i = 1; i <= labels.length; i++) {
        for (var j = 0; j < result.length; j++) {
          var date = moment(result[j].date, 'MMM, D').format('D');
          if (i == date) {
            data[i - 1] = parseFloat(result[j].total_spent);
          }
        }
      }
      
      return data;
    }
    
    
    function productSort  (propertyName) {
      ctrl.productReverse = (ctrl.productPropertyName === propertyName) ? !ctrl.productReverse : false;
      ctrl.productPropertyName = propertyName;
      var allProduct = ctrl.currentChartShow.products;
      if (allProduct.length > 0) {
        ctrl.currentChartShow.products = orderByFilter(allProduct, ctrl.productPropertyName, ctrl.productReverse);
      }
    };
    
    function changesearchProductType (){
      ctrl.searchBy = {};
    }
    
    function goBack (){
      $state.go('app.reports.spending.category');
    }
    
    function sumArray (a, b){
      return a + b;
    }
    
    function sort (array, sortByName, sortOrder){
      var newArray = [];
      if(array.length > 0){
        newArray = orderByFilter(array, sortByName, sortOrder);
      }
      return newArray ;
    }
    
    function updateMonthYearOptions () {
      var first_date = moment($scope.$root.current_office.created_at);
      var last_date = moment();
      var month_count = last_date.diff(first_date, 'M');
      for (var i = 1; i <= month_count; i++) {
        ctrl.rangeOptions.push({
          name: moment().subtract(i,'M').format('MMMM, YYYY'),
          value: moment().subtract(i,'M').format('YYYY-M')
        });
      }
    }
    
    function goToProductReport (item){
      var type = _.get(item, 'item_type', '');
      if(type.includes('Implant')){
        $state.go('app.reports.implants');
      } else {
        $state.go('app.reports.product-detail', {productId: item.inventory_id});
      }
    }
    
    function goToMonthDetail (monthNumber){
      $state.go('app.reports.spending.category-month', {categoryID: ctrl.categoryID, year: ctrl.year, month: monthNumber, categoryName: ctrl.categoryName})
    }
    
    function openPriceHistory (item) {
      var type = _.get(item, 'item_type', '');
      if(!item.inventory_id || type.includes('Implant')){
        return;
      }
      if ( type.includes('Medication') ) {
        $rootScope.$broadcast('medication-item-slideout', null, null, 'price', item.inventory_id);
      } else {
        $rootScope.$broadcast('inventory-item-slideout', null, item.inventory_id, 'price');
      }
    }
    
    function openItemDetails (item) {
      var type = _.get(item, 'item_type', '');
      if(!item.inventory_id || type.includes('Implant')){
        return;
      }
      if ( type.includes('Medication') ) {
        $rootScope.$broadcast('medication-item-slideout', null, null, 'details', item.inventory_id);
      } else if (!type.includes('Implant')) {
        $rootScope.$broadcast('inventory-item-slideout', null, item.inventory_id, 'details');
      }
    }
    
    function openProductDetails (item) {
      $rootScope.$broadcast('mkt-details-open-fetch', item.product_id);
    }
  }
}());

(function () {
  'use strict';

  angular.module('sowSales')
  .controller('SalesActionBarController', SalesActionBarController)
  .directive('actionBarSales', SalesActionBarDirective);

  function SalesActionBarDirective () {
    return {
      restrict: 'AC',
      templateUrl: 'sow-sales/directives/action-bar-sales.html',
      controller: 'SalesActionBarController',
      controllerAs: 'SalesAbCtrl'
    };
  }

  function SalesActionBarController ($rootScope, $state, sowMktService, $timeout, errorService, smoothScroll) {
    /*jshint validthis:true*/
    var ctrl = this;

    init();

    return ctrl;

    function init () {
      
    }
  }

})();
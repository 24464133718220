(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.legal')
    .directive('legalActionBar', LegalActionBar);

  function LegalActionBar () {
    return {
      'restrict': 'AC',
      'templateUrl': 'templates/marketplace/legal/action-bar.html',
    };
  }
}());

angular.module("app.shared.support")

  //Directive that calls a passed function when the element goes in or our of view.
  //
  .directive('inView', function ($rootScope, $timeout, $parse, $window) {
    var mainPage = angular.element($window); //Must use this element and not the window because it does the scolling.
    var theWindow = angular.element($window);

    return {
      link: function (scope, element, attrs) {
        if (!attrs.inView) {
          return;
        }
        var inViewFunc = $parse(attrs.inView);

        var inViewVal = false;
        var updateInView = function (newInViewValue, dontApply) {
          if (newInViewValue !== inViewVal) {
            inViewVal = newInViewValue;
            inViewFunc(scope, { '$inview': newInViewValue });
            if (!dontApply) {
              scope.$apply();
            }
          }
        };

        var checkInView = function (dontApply) {
          var top = angular.element(element).offset().top;
          var eleHeight = angular.element(element).height();
          var wHeight = theWindow.height();
          var wScroll = theWindow.scrollTop();
          if ((top + eleHeight) > wScroll && (top) < (wHeight + wScroll)) {
            updateInView(true, dontApply);
          } else {
            updateInView(false, dontApply);
          }
        };

        mainPage.on("scroll", function () {
          checkInView();
        });

        checkInView(true);
        scope.$root.$on('inView: render', function () {
          checkInView(true);
        });
      }
    };
  });


angular.module("app.marketplace.elements")
.service("officeModel", function($rootScope, searchUrl, apiUrl, commonApiService) {
  var _this = this;
  this.model_data = {
    'name': 'office',
    'display_name' : 'Office',
    'api': {
        'product_stats': function (options) {
          if (!_.has(options, 'product_id')) {
            throw 'Cannot fetch product stats without a product id.';
          }

          return {
            'path': '{0}/reports/products/{1}'.format(apiUrl, options.product_id),
            'params': {
              'method': 'GET',
              'params': {
                'start_date': _.get(options, 'start_date'),
                'end_date': _.get(options, 'end_date'),
              }
            }
          };
        },

        'product_stats_summary': function (options) {
          return {
            'path': '{0}/reports/products'.format(apiUrl),
            'params': {
              'method': 'GET',
              'params': {
                'start_date': _.get(options, 'start_date'),
                'end_date': _.get(options, 'end_date'),
              }
            }
          };
        },

        'supplier_stats': function (options) {
          return {
            'path': '{0}/reports/supplier'.format(apiUrl),
            'params': {
              'method': 'GET',
              'params': {
                'start_date': _.get(options, 'start_date'),
                'end_date': _.get(options, 'end_date'),
              }
            }
          };
        },

        'tax_rate': function (options) {
          if (!_.has(options, 'office_id')) {
            throw 'Missing options for Tax Rate API.';
          }

          const params = { method: 'GET' };
          if (typeof options.tax_exempt === 'boolean') {
            _.set(params, 'params.tax_exempt', options.tax_exempt);
          }

          return {
            'path': '{0}/offices/{1}/po_tax_rate'.format(apiUrl, options.office_id),
            'params': params,
          };
        },

        'clear_inventory': function (options) {
          if (!_.has(options, 'office_id')) {
            throw 'Cannot clear inventory without an office_id.';
          }

          return {
            'path': '{0}/inventory/clear'.format(apiUrl),
            'params': {
              'method': 'POST',
              'data': {'office_id': options.office_id}
            },
          };
        },

        'get_unused_subscription_discounts': function (options) {
          if (!checkNested(options, 'office_id')) {
            throw 'Missing option(s) for path "get_unused_subscription_discounts". Options: ' + JSON.stringify(options);
          }
  
          return {
            'path': '{0}/offices/{1}/unused_discounts'.format(apiUrl, options.office_id),
            'params': {'method': 'GET'},
          };
        },

        //
        // Get pending membership requests
        //
        'get_membership_request': function (options) { 
  
          return {
            'path': commonApiService.getOfficeUrl() + '/invitation_requests',
            'params': {
              'method': 'GET'
            }
          };
        },
        
        //
        // Accept a Membership Request
        //
        'accept_membership_request': function (options) {
          if (!checkNested(options, 'request_id')) {
            throw 'Missing option(s) for path "accept_membership_request". Options: ' + JSON.stringify(options);
          }
  
          return {
            'path': '{0}/offices/invitation_requests/{1}'.format(apiUrl, options.request_id),
            'params': {
              'method': 'POST',
              'data': {},
            }
          };
        },
  
        //
        // Reject a Membership Request
        //
        'reject_membership_request': function (options) {
          if (!checkNested(options, 'request_id')) {
            throw 'Missing option(s) for path "reject_membership_request". Options: ' + JSON.stringify(options);
          }
  
          return {
            'path': '{0}/offices/invitation_requests/{1}'.format(apiUrl, options.request_id),
            'params': {
              'method': 'DELETE',
              'data': {},
            }
          };
        },
  
        get_users: function (options) {
          if (!checkNested(options, 'office', 'id')) {
            throw 'Missing option(s) for path "get_users". Options: ' + JSON.stringify(options);
          }
          return sprintf('%s/offices/%s/users', apiUrl, options.office.id);
        },
  
        // TODO reassess and possibly migrate credit card endpoints to better suited model.
        "me_credit_cards" : function(){
          return apiUrl + "/me/credit_cards";
        },
        //TODO clean api. Error response is insufficient and not clean.
        "store_credit_card" : function(options){
          if(!checkNested(options,'credit_card')){
            throw "Missing option(s) for path 'store_credit_card'. Options: " + JSON.stringify(options);
          }
          return {
            path : apiUrl + "/me/credit_cards",
            params : {
              method : "POST",
              data : {credit_card : options.credit_card}
            }
          };
        },
        "remove_credit_card" : function(options){
          if(!checkNested(options,'credit_card','id')){
            throw "Missing option(s) for path 'remove_credit_card'. Options: " + JSON.stringify(options);
          }
          return {
            path : apiUrl + "/me/credit_cards?credit_card_id="+options.credit_card.id,
            params : {
              method : "DELETE"
            }
          };
        },
        "set_default_credit_card" : function(options){
          if(!checkNested(options,'credit_card','id')){
            throw "Missing option(s) for path 'set_default_credit_card'. Options: " + JSON.stringify(options);
          }
          return {
            path : commonApiService.getOfficeUrl() + "/stripe/update_default_source",
            params : {
              method : "POST",
              data: {credit_card_id : options.credit_card.id}
            }
          };
        },
        "update_credit_card" : function(options){
          if(!checkNested(options,'credit_card','id') || !checkNested(options,'credit_card')){
            throw "Missing option(s) for path 'update_credit_card'. Options: " + JSON.stringify(options);
          }
          return {
            path : apiUrl + "/me/credit_cards?credit_card_id="+options.credit_card.id,
            params : {
              method : "PUT",
              data: {
                credit_card : options.credit_card
              }
            }
          };
        },

        'new_office': function (options) {
          if (!checkNested(options, 'office')) {
            throw 'Missing option(s) for path "new_office". Options: ' + JSON.stringify(options);
          }
          return {
            path: apiUrl + "/offices",
            params: {
              method: "POST",
              data: options.office,
            }
          };
        },

        "create": function (office) {
          if (!office) {
            throw "Missing office for office path 'create'. ";
          }

          office = angular.copy(office);

          return {
            path: apiUrl + "/offices",
            params: {
              method: "POST",
              data: office,
            }
          };
        },

        "update" : function(office){
          if (!office) {
            throw "Missing office for office path 'update'. ";
          }

          return {
            path: '{0}/offices/{1}'.format(apiUrl, office.id),
            params: {
              method: 'PUT',
              data: office
            }
          };
        },

        "patch" : function(options){
          if(!checkNested(options,'office','id')){
            throw "Missing office for office path 'patch'. " + JSON.stringify(options);
          }
          return {
            path : apiUrl + "/offices/"+ options.office.id,
            params : {
              method : "PATCH",
              data : options.office
            }
          };
        },
        "remove" : function(office){
          if(!office){
            throw "Missing office for office path 'remove'. ";
          }
          return {
            path : apiUrl + "/offices/"+ office.id,
            params : {
              method : "DELETE",
              data : office
            }
          };
        },


        "invite_user" : function(options){
          if(!checkNested(options,'office','id') || !checkNested(options,'user')){
            throw "Missing option(s) for office path 'invite_user'. Options: " + JSON.stringify(options);
          }

          return {
            path : commonApiService.getOfficeUrl() + "/invites",
            params : {
              method : "POST",
              data : options.user,
              // {
              //     name: options.user.name,
              //     membership_type: options.user.membership_type,
              //     email: options.user.email,
              //     title: options.user.title
              // }
            }
          };
        },
        "cancel_user_invite" : function(options){
          if(!checkNested(options,'invite', 'id')){
            throw "Missing option(s) for office path 'cancel_user_invite'. Options: " + JSON.stringify(options);
          }

          return {
            path : commonApiService.getOfficeUrl() + "/invites/" + options.invite.id,
            params : {
              method : "DELETE"
            }
          };
        },
        "resend_user_invite" : function(options){
          if(!checkNested(options,'invite', 'id')){
            throw "Missing option(s) for office path 'cancel_user_invite'. Options: " + JSON.stringify(options);
          }

          return {
            path : commonApiService.getOfficeUrl() + "/invites/" + options.invite.id + "/resend",
            params : {
              method : "POST"
            }
          };
        },

        'rename_location': function (options) {
          return {
            'path': commonApiService.getOfficeUrl() + '/inventory/locations/' + options.location_id,
            'params': {
              'method': 'PUT',
              'data': {'name': options.location_name},
            }
          };
        },

        "get_locations" : function(options){
          return {
            path : commonApiService.getOfficeUrl() + "/inventory/locations",
            params : {
              method : "GET",
            }
          };
        },
        //Location related endpoints
        "create_location" : function(options){
          if(!checkNested(options, 'location', 'name')){
            throw "Missing option(s) for inventory_items path 'create_location'. Options: " + JSON.stringify(options);
          }
          return {
            path : commonApiService.getOfficeUrl() + "/inventory/locations",
            params : {
              method : "POST",
              data : options.location
            }
          };
        },
        "remove_location" : function(options){
          if(!checkNested(options, 'location', 'id')){
            throw "Missing option(s) for inventory_items path 'remove_location'. Options: " + JSON.stringify(options);
          }
          return {
            path : commonApiService.getOfficeUrl() + '/inventory/locations/' + options.location.id,
            params : {
              method : "DELETE"
            }
          };
        },

        //Calls custom endpoint that gets all necessary information for the dashboard.
        //Best place for this endpoint?
        "dashboard_data" : function(options){
          return apiUrl + '/me/dashboard' + (options.data_field ? '?data_field=' + options.data_field : '');
        },

        // pending invites
        "invites" : function(options){
          return commonApiService.getOfficeUrl() + '/invites';
        }

      }

    };

  this.autofill = function(element) {
    element.is_free_account = element.account_type === "Free";
    element.UI.show_expires_info_bar = false;

    if (element.trial_ends_on) {
      var now = moment();
      var expiry_date = moment(new Date(element.trial_ends_on));

      element.trial_over = expiry_date.isBefore(now);
      element.trial_days_left = expiry_date.diff(now, 'days');
      // SPD-3816 element.UI.show_expires_info_bar = element.trial_days_left > 0;
 
      // if (!element.trial_days_left) {
      //   element.trial_over = true;
      // }
    }

    element.UI.no_trial = !element.trial_ends_on || element.trial_over;
  };

  this.fields = {
    "id" : {
      "display_name" : "id",
      "required" : true,
      "api" : {
        "submit" : true
      },
      "type" : "string"
    },
    "name" : {
      "display_name" : "name",
      "required" : true,
      "api" : {
        "submit" : true
      },
      "type" : "string"
    },
    "email" : {
      "display_name" : "email",
      "required" : true,
      "api" : {
        "submit" : true
      },
      "type" : "string"
    },
    "phone_number" : {
      "display_name" : "phone_number",
      "required" : true,
      "api" : {
        "submit" : true
      },
      "type" : "string"
    },
    "license" : {
      "display_name" : "license",
      "required" : true,
      "api" : {
        "submit" : true
      },
      "type" : "string"
    },
    "oda_member_id" : {
      "display_name" : "oda_member_id",
      "required" : false,
      "api" : {
        "submit" : true
      },
      "type" : "string"
    },
    "address" : {
      "required" : true,
      "api" : {
        "submit" : true
      },
      "type" : "object",
      "fields" : {
        "address1" : {
          "display_name" : "address1",
          "required" : true,
          "api" : {
            "submit" : true
          },
          "type" : "string"

        },
        "address2" : {
          "display_name" : "address2",
          "api" : {
            "submit" : true
          },
          "type" : "string"

        },
        "city" : {
          "display_name" : "city",
          "required" : true,
          "api" : {
            "submit" : true
          },
          "type" : "string"
        },
        "postal_code" : {
          "display_name" : "postal_code",
          "required" : true,
          "api" : {
            "submit" : true
          },
          "type" : "string"
        },
        "province" : {
          "display_name" : "province",
          "required" : true,
          "api" : {
            "submit" : true
          },
          "type" : "string"

        },
        "country" : {
          "display_name" : "country",
          "api" : {
            "submit" : true
          },
          "type" : "string"

        }
      }
    },
    "medical_field_id" : {
      "display_name" : "medical_field_id",
      "required" : true,
      "api" : {
        "submit" : true
      },
      "type" : "string"
    },
    "medical_field_subtype" : {
      "display_name" : "medical_field_subtype",
      "required" : true,
      "api" : {
        "submit" : true
      },
      "type" : "string"
    },
    "invites" : {
      "display_name" : "invites",
      "required" : true,
      "api" : {
        "submit" : true
      },
      "type" : "array"
    }
  };

  this.data = {
    "params" : {
      "model_name" : "office"
    }
  };

  var configWatch = $rootScope.$watch('config', function(newConfigValue, oldConfigValue){
    if(newConfigValue){
      _this.fields.address.fields.province.all_provinces = newConfigValue.current_provinces;
      _this.fields.address.fields.province.provinces_map = newConfigValue.current_provinces_map;
      configWatch();
    }
  });
});

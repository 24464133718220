//
// AccountNavController
//
angular.module('app.marketplace.ui.account').controller('AccountNavController',
  function AccountNavController($scope, $rootScope, $state, $mdDialog, sessionService, membershipService, AccountModalService) {
    var _this = this;
    var originatorEv;
    _this.menuIsOpen = false;

    _this.isLoggedIn  = function () {
      return !!$rootScope.current_account;
    };

    _this.login = function ($event) {
      AccountModalService.show('Login', $event);
    };

    _this.openMenu = function($mdOpenMenu, ev) {
      originatorEv = ev;
      $mdOpenMenu(ev);
    };

    _this.toggleMenu = function ($event, state) {
      if ($event) {
        $event.preventDefault();
        $event.stopPropagation();
      }

      if (arguments.length < 2) {
        _this.menuIsOpen = !_this.menuIsOpen;
      } else {
        _this.menuIsOpen = !!state;
      }
    };
  }
);

angular.module('app.marketplace.ui.cart')

  .directive('creditCards', function ($log) {
    return {
      restrict: 'C',
      scope: {
        isOpen: '=',
        creditCardId: '=ngModel',
        creditCardObj: '=',
        shippingAddress: '=',
        isSaving: '='
      },
      templateUrl: 'templates/marketplace/cart/directives/credit-cards.html',
      controllerAs: 'ctrl',

      //
      // Credit Cards Controller
      //
      controller: function CreditCardsController($scope, $filter, officeService, stripe, errorService, elementService, addressService) {
        var _this = this;

        _this.useShippingAddress = true;

        //
        // Toggle between displaying the credit card list and the "add credit
        // card" form.
        //
        _this.toggleState = function () {
          if ($scope.state === 'show') {
            $scope.new_card = {};
            $scope.state = 'add';
          } else {
            $scope.state = 'show';
            $scope.new_card = null;
          }

          $scope.isOpen = ($scope.state === 'add');

          if ($scope.isOpen) {
            checkShippingAddress();
            $scope.NewCreditCardForm.$setPristine();
          }
        };

        //
        // Check if the shipping address is valid. If it isn't valid, then we
        // need to update the UI to disallow the user from attempting to use it
        // as a billing address.
        //
        function checkShippingAddress () {
          var shippingAddressIsValid = addressService.validateAddress($scope.shippingAddress);

          if (shippingAddressIsValid) {
            _this.useShippingAddress = true;
            _this.hideRadioSelect = false;
          } else {
            _this.useShippingAddress = false;
            _this.hideRadioSelect = true;
          }
        }

        //
        // Load the list of credit cards on this account.
        //
        _this.loadStoredCards = function () {
          $scope.loading_cards = true;

          officeService.getCreditCards().then(function (stored_cards) {
            $scope.credit_cards = stored_cards;
            _updateCreditCardObj();
          }).catch(function (error) {
            var t_message = $filter('translate')('ERRORS.API_FAIL')
            errorService.uiErrorHandler(t_message, 0);
          })['finally'](function () {
            $scope.loading_cards = false;
          });
        };

        //
        // Remove a Credit Card
        //
        _this.removeCreditCard = function (credit_card, index) {
          if (_.includes(_this.removingCards, credit_card.id)) {
            return;
          } else {
            _this.removingCards.push(credit_card.id);
          }
          var t_message = $filter('translate')('ERRORS.CARD_REMOVAL_ERROR')

          try {
            elementService.callEndpoint('office', {
              endpoint: 'remove_credit_card',
              credit_card: credit_card
            }).then(function(response) {
              $scope.credit_cards.splice(index, 1);

              // if you delete the selected card
              if ($scope.creditCardId === credit_card.id) {
                //Wait until all cards currently being removed have finished.
                var removingCardsWatch = $scope.$watch(function(){
                  return _this.removingCards.length;
                }, function(newCardValue, oldCardValue){
                  if(!newCardValue){
                    removingCardsWatch();
                    if ($scope.credit_cards && $scope.credit_cards.length > 0) {
                      $scope.creditCardId = $scope.credit_cards[0].id;
                      _updateCreditCardObj();
                    } else {
                      $scope.creditCardId = null;
                      _updateCreditCardObj();
                    }
                  }
                });
              }
            }).catch(function(response) {
                errorService.uiErrorHandler(t_message + (response.data ? response.data.error.message : response.message ? response.message : 'Unkown Error'), 0);
            })['finally'](function () {
              _this.removingCards = _.without(_this.removingCards, credit_card.id);
            });
          } catch (error) {
            errorService.uiErrorHandler(t_message + error.message, 0);
            _this.removingCards = _.without(_this.removingCards, credit_card.id);
          }
        };

        _this.addCreditCard = function ($event) {
          if (!$scope.NewCreditCardForm.$valid) {
            return;
          }

          $scope.isSaving = true;

          var stripe_card = {
            name: $scope.new_card.name,
            number: $scope.new_card.number,
            cvc: $scope.new_card.cvc,
            exp_month: $scope.new_card.expiry.month,
            exp_year: $scope.new_card.expiry.year
          };

          //
          // Pull in the address information. addressService should validate it
          // and give us a sgToast if there is an issue.
          //
          var address = addressService.extractAddress(
            _this.useShippingAddress ? $scope.shippingAddress : $scope.new_card,
            _this.useShippingAddress ? {errorPrefix: 'Invalid Shipping Address'} : undefined
          );

          if (address) {
            angular.extend(stripe_card, addressService.convertToStripeAddress(address));
          } else {
            return;
          }

          try {
            stripe.card.createToken(stripe_card, function (status, response) {
              if (response.error) {
                $scope.$apply(function () {
                  $scope.isSaving = false;
                  var t_message = $filter('translate')('ERRORS.CARD_CREATION')
                  errorService.uiErrorHandler('Card creation error: ' + response.error.message, 0);
                });
              } else {
                var new_card = {
                  id: response.id,
                  billing: address
                };

                elementService.callEndpoint('office', {
                  endpoint: 'store_credit_card',
                  credit_card: new_card
                }).then(function (response) {

                  $scope.$emit('creditCards: creditCardAdded', new_card);
                  $scope.credit_cards.push(response.data);
                  $scope.creditCardId = response.data.id;
                  _updateCreditCardObj();
                  $scope.isSaving = false;
                  _this.toggleState();

                }).catch(function (error) {

                  var error_message = null;

                  if (error.message.error_body && error.message.error_body.code==="incorrect_zip") {
                    error_message = 'The billing address does not match the one associated with the card';
                  }

                  if (error.message.error_body && error.message.error_body.code==="card_declined") {
                    error_message = error.message.error_body.message;
                  }

                  // Important for stripe's error messages to be displayed.
                  if (angular.isString(error.message) && !error_message) {
                    error_message = error.message;
                  }

                  if (!error_message) {
                    error_message = 'Card Creation Error';
                  }

                  $scope.isSaving = false;
                  errorService.uiErrorHandler(error_message, 3000); 

                });
              }
            });
          } catch (error) {
            $scope.isSaving = false;
            errorService.uiErrorHandler('Card creation error: ' + error.message, 0);
          }

        };


        //
        // Update $scope.creditCardObj to the credit card that matches card_id.
        //
        var _updateCreditCardObj = function (card_id) {
          if (arguments.length < 1) {
            card_id = $scope.creditCardId;
          }

          $scope.creditCardObj = null;

          if (!card_id || !$scope.credit_cards || !$scope.credit_cards.length) {
            return;
          }

          angular.forEach($scope.credit_cards, function (credit_card) {
            if (credit_card.id ===  card_id) {
              $scope.creditCardObj = credit_card;
            }
          });
        };

        _this.init = function () {
          _this.removingCards = [];

          checkShippingAddress();

          angular.extend($scope, {
            state: 'show',
            isSaving: false,
            loading_cards: false,
            credit_cards: [],
            toggleState: _this.toggleState,
            save: _this.addCreditCard,
            removeCard: function ($event, card, index) {
              $event.stopPropagation();
              return _this.removeCreditCard(card, index);
            },
            clearProvince: function(){
              $scope.new_card.province=null;
            }
          });

          //
          // Watch For Changes to creditCardId and update creditCardObj to
          // match. It's basically just a tag-along binding because we have a
          // requirement of updating two places with the info.
          //
          $scope.$watch('creditCardId', _updateCreditCardObj);
          
          $scope.$watch(function(){
            return _this.removingCards.length;
          }, function(newCardValue, oldCardValue){
            if(newCardValue!==oldCardValue){
              if(newCardValue){
                $scope.isSaving=true;
              }else{
                $scope.isSaving=false;
              }
            }
          });

          _this.loadStoredCards();
        };
      },

      link: function ($scope, $element, $attr, ctrl) {
        ctrl.init();
      }
    };
  })

;

{
  'use strict';
  /** @ngInject */
  class PatientService {
    constructor(serverAPI, sessionService, localStorageService, WorkflowsService) {
      this.serverAPI = serverAPI;
      this.sessionService = sessionService;
      this.localStorageService = localStorageService;
      this.WorkflowsService = WorkflowsService;
    }

    /**
     * Retrieves practice information based on an office ID and stores it in local storage.
     * The API call is only made if the office has workflows enabled.
     * @param {{office_id: string|undefined, has_workflows: boolean}} office_params
     */
    async savePracticeInfoInLocalStorage({office_id, has_workflows}) {
      if (office_id && has_workflows) {
        const practice_info = await this.getPracticeInfo(office_id);
        if (practice_info) {
          return this.localStorageService.set('practice-info', practice_info);
        }
      }

      return this.localStorageService.set('practice-info', null);
    }

    /**
     * Makes a GET request to the server API to retrieve information about a practice based on the provided office_id
     * @param {string} office_id
     * @returns {Promise<{id: string, hasPmsIntegration: string, officeId: string, name: string, timezoneId: string}>}
     */
    getPracticeInfo(office_id) {
      const url = `${this.WorkflowsService.getBaseUrl()}/Practice/${office_id}`;
      const config = {
        headers: this._getHeaders(),
        method: 'GET',
      };
      return this.serverAPI.callAPI(url, config, {show_error_toast: false});
    }

    /**
     * Searches for patients from the API using the specified query term.
     * @param {string} query_term Either the patient's name or ChartNumber.
     * @return {Promise<Patient[]>} A promise that resolves to an array of patients.
     */
    searchPatients(query_term) {
      const url = `${this.WorkflowsService.getBaseUrl()}/Patient`;
      const headers = this._getHeaders();
      const config = {
        method: 'GET',
        headers,
        params: {
          query_term,
          page: 1,
          local_time: moment().format(),
        },
      };
      return this.serverAPI.callAPI(url, config);
    }

    getPatient(patient_id) {
      const url = `${this.WorkflowsService.getBaseUrl()}/Patient/${patient_id}`;
      const headers = this._getHeaders();
      const config = {
        method: 'GET',
        headers,
      };
      return this.serverAPI.callAPI(url, config);
    }

    getPatientsList(ids) {
      const url = `${this.WorkflowsService.getBaseUrl()}/Internal/Patients/Info`;
      const headers = this._getHeaders();
      const config = {
        method: 'POST',
        headers,
        data: { ids: ids.join(',') },
      };
      return this.serverAPI.callAPI(url, config);
    }

    /**
     * Fetches all Care Plans for a specific patient from the API.
     * @param {string} patient_id
     * @return {Promise<CarePlan[]>} A promise that resolves to an array of Care Plans.
     */
    getPatientCarePlans(patient_id) {
      const url = `${this.WorkflowsService.getBaseUrl()}/CarePlan`;
      const headers = this._getHeaders();
      const config = {
        method: 'GET',
        headers,
        params: {
          patient: patient_id,
          local_time: moment().format(),
        },
      };
      return this.serverAPI.callAPI(url, config);
    }

    linkProduct(data) {
      const url = `${this.WorkflowsService.getBaseUrl()}/CarePlan/Product`;
      const headers = this._getHeaders();
      const config = {
        method: 'POST',
        headers,
        data,
      };
      return this.serverAPI.callAPI(url, config);
    }

    /**
     * Fetches the Care Team for a specific patient from the API.
     * @param {string} patient_id
     * @return {Promise<CareTeam>} A promise that resolves to a Care Team.
     */
    getCareTeam(patient_id) {
      const url = `${this.WorkflowsService.getBaseUrl()}/CareTeam/${patient_id}`;
      const headers = this._getHeaders();
      const config = {
        method: 'GET',
        headers,
      };
      return this.serverAPI.callAPI(url, config);
    }

    _getHeaders() {
      const token = this._getToken();
      return { 'User-Token': token };
    }

    _getToken() {
      const session = this.sessionService.get();
      return session.token;
    }
  }

  angular.module('sowWorkflows').service('PatientService', PatientService);
}

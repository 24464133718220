(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.inventory')
    .directive('inventoryPrintActionBar', inventoryPrintActionBar);

  function inventoryPrintActionBar () {
    return {
      'restrict': 'AC',
      'templateUrl': 'templates/marketplace/inventory/inventory-print-action-bar.html',
    };
  }
}());

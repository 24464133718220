(function () {
  'use strict';

  angular.module('sowInventory')
  .controller('sowInventoryItemSlideoutController', sowInventoryItemSlideoutController)
  .directive('sowInventoryItem', function() {
    return {
      replace: true,
      restrict: 'E',
      controller: 'sowInventoryItemSlideoutController',
      controllerAs: 'iisCtrl',
      templateUrl: 'sow-inventory/slideouts/inventory-item-slideout.html',
      link: function ($scope, $element, $attrs) {

      }
    };
  });

  function sowInventoryItemSlideoutController ($scope, $q, $rootScope, $mdSidenav, $mdDialog, $filter,
    inventoryItemService, errorService, sowInventoryService, sgToast, mktHelperService, sowMedicationService, formService,
    RedirectModalService, elementService, alertsDataCarrierService, DeleteInventoryDraftPODialog, DeleteInventoryActivePODialog, membershipService, AccessService) {
    /* jshint validthis:true*/
    var ctrl = this;

    ctrl.base_item = null;
    ctrl.is_open = false;
    ctrl.is_open_image = false;
    ctrl.promises = [];
    ctrl.saving_item = false;
    ctrl.unsaved_changes = false;

    ctrl.addToShoppingList = addToShoppingList;
    ctrl.closeSlideout = closeSlideout; //exported to use in child controller
    ctrl.confirmCancel = confirmCancel;
    ctrl.deleteItem = deleteItem;
    ctrl.open = open;
    ctrl.openInventory = openInventory;
    ctrl.openMedication = openMedication;
    ctrl.saveItem = saveItem;
    ctrl.setTab = setTab;
    ctrl.showProductDetails = showProductDetails;

    ctrl.bulkRemoveMedStock = bulkRemoveMedStock;
    ctrl.bulkDeleteMedStock = bulkDeleteMedStock;

    ctrl.openDetailPic = openDetailPic;
    ctrl.shouldHideShoppingList = shouldHideShoppingList;
    ctrl.shouldShowAddToCartIcon = shouldShowAddToCartIcon;

    ctrl.$onInit = init;

    return ctrl;

    function init () {
      defineLocks();
      $scope.$on('close-inventory-slideout', closeSlideout);
      $scope.$on('inventory-detail-image', openDetailPic);
      $scope.$on('inventory-item-slideout', openInventory);
      $scope.$on('inventory-item-updated', handleItemUpdated);
      $scope.$on('inventory-slideout-tab', setTab);
      $scope.$on('inventory-upload-end', function(){ ctrl.uploading = false; });
      $scope.$on('inventory-upload-start', function(){ ctrl.uploading = true; });
      $scope.$on('medication-item-slideout', openMedication);
      $scope.$on('medication-item-updated', handleItemUpdated);
      $scope.$on('medication-stock-added', handleMedStockAdded);
      $scope.$on('medication-stock-removed', handleMedStockRemoved);
      $scope.$on('open-inventory-slideout', open);
      $scope.$on('med-stock-checkbox-clicked', handleCheckboxClicked);

      $scope.$on('update-file', ($event, item) => {
        ctrl.rootForm.$setDirty();
        ctrl.base_item = _.extend(item || $event.targetScope.item);
      });
      setTab(null, 'details');
      
    }

    function confirmCancel () {
      ctrl.is_open = false;
      // var is_inventory = ['Inventory','General', 'Marketplace'].includes(_.get($scope.item, "type", "").split(" ")[0]);
      // var changed = itemHasChanged() && is_inventory;
      var changed = itemHasChanged();
      if(!changed) {
        return;
      }
      $mdDialog.show({
        controller: DialogController,
        controllerAs: 'dialogCtrl',
        templateUrl: 'sow-inventory/dialogs/inventory-unsaved-changes.html',
        parent: angular.element(document.body),
        clickOutsideToClose: true,
      })
      .then(function (answer) {
        if (answer) {
          saveItem();
        } else {
          return;
        }
      });
    }

    function openDetailPic(ev) {
      ctrl.is_open_image = true;
      $mdDialog.show({
        controller: DialogController,
        templateUrl: 'sow-inventory/slideouts/product-detail-pic.html',
        parent: angular.element(document.body),
        targetEvent: ev,
        clickOutsideToClose:true,
        locals: {
          items: $scope.base_item
        }
      }).then(function(answer) {
          // $scope.status = 'You said the information was "' + answer + '".';
        }).catch(function() {
          // $scope.status = 'You cancelled the dialog.';
        });
    }

    function setTab (event, tab) {
      tab = tab || 'details';
      ctrl.tab = tab;

      // XXGH, we need to keep the medstock checkboxes from triggering the unsaved changes dialog
      if (tab === 'medstock') {
        ctrl.form_was_pristine = angular.copy(ctrl.rootForm.$pristine);
      }
    }

    function bulkDeleteMedStock () {
      $scope.$broadcast('med-stock-delete');
    }

    function bulkRemoveMedStock () {
      $scope.$broadcast('med-stock-remove');
    }

    function openInventory (ev, item, item_id, tab) {
      // Check the locks for the office before opening the slideout
      defineLocks();
      setTab(ev, tab);
      if(!item && item_id){
        // as of SPD-2109, we're using a trimmed items list and fetching the 
        // full inventoryItem from API when you open the slideout
        return inventoryItemService.getItem(item_id, {'force': true})
        .then(function(item_response){
          triggerItemUpdated(item_response);
          return openInventory(ev, item_response, null, tab);
        });
      } else {
        // XGH warning: preventing implants from showing up here
        var type = item.type || '';
        if(type.includes('Implant')){
          return $q.all([]);
        }
        
        setCurrentItem(item);
        if (item && !ctrl.is_open) {
          return open()
          .then(function(){
            $scope.$broadcast('inventory-slideout-opened');
          });
        }
      }
    }

    function openMedication (ev, medication, medication_id, tab, inventory_id) {
      // Check the locks for the office before opening the slideout
      defineLocks();
      setTab(ev, tab);
      if(!medication){
        if (medication_id) {
          return sowMedicationService.getMedication(medication_id)
          .then(function(med_response){
            return openMedication(ev, med_response, null, tab, null);
          });
        }
        if (inventory_id) {
          return sowMedicationService.getMedicationFromInventory(inventory_id)
          .then(function(med_response){
            return openMedication(ev, med_response, null, tab, null);
          });
        }

      } else {
        setCurrentItem(medication);
        if (medication && !ctrl.is_open) {
          return open()
          .then(function(){
            $scope.$broadcast('medication-slideout-opened');
          });
        }
      }
    }

    function setCurrentItem (item) {
      $scope.item = angular.copy(item);
      $scope.item.tracking_method = $scope.item.tracking_method || 'Item';
      var item_type = _.get($scope.item, 'type', '');
      var is_medication = item_type.includes('Medication') || _.isObject($scope.item.routes_of_admin);
      _.set($scope.item, 'UI.is_medication', is_medication );
      _.set($scope.item, 'UI.context', (is_medication ? 'medication':'product') );
      _.set($scope.item, 'UI.allow_tracking_by_case', (!is_medication && $scope.item.buy_by_case) );
      _.set($scope.item, 'UI.case_quantity', (is_medication ? 'case_quantity':'order_package_quantity') );

      if ( _.isEqual($scope.manufacturer, {'name':null,'id':null}) ) {
        _.set($scope.item, 'manufacturer', null);
        _.set($scope.item, 'manufacturerSearchText', null);
        _.set($scope.item, 'manufacturer_name', null);
        _.set($scope.item, 'manufacturer_id', null);
      }
      setVendorInfo();
      setPOInfo();
      setAlertInfo();
      // resetScope();
      setPricingHistory().then(resetBaseItem);
    }

    /**
     * If the updated item is the one the user is editing in the slideout, updates the item
     * in the UI with the changed properties we got back from the API while ensuring that
     * the user's unsaved changes to the item are not overridden.
     * @param _event - the event object that was passed to the event handler (unused)
     * @param updated_item - the entire item object that was updated
     * @param updated_properties - an optional object containing only the properties that were updated
     */
    function handleItemUpdated (_event, updated_item, updated_properties) {
      if (updated_item.id === $scope.item?.id) {
        // If we were passed an updated_properties object, those are the only properties
        // we need to extend. This prevents us from overriding unsaved changes the user
        // has made to the item in the slideout.
        // Otherwise we extend all properties of the updated item itself.
        const object_to_extend = updated_properties || updated_item;
        const current_item = _.extend({}, $scope.item, object_to_extend);
        setCurrentItem(current_item);
      }
    }

    function handleMedStockAdded (ev, medication, stock_array) {
      if($scope.item && medication.id === $scope.item.id) {
        $scope.item.quantity += stock_array.length;
      }
    }

    function handleMedStockRemoved (ev, stock_removed) {
      if($scope.item && stock_removed.medication_id === $scope.item.id) {
        --$scope.item.quantity;
      }
    }

    function handleCheckboxClicked () {
      if(ctrl.form_was_pristine) {
        ctrl.rootForm.$setPristine();
      }
    }

    function resetBaseItem () {
      $scope.$broadcast('slideout-item-set', $scope.item);
      ctrl.base_item = angular.copy($scope.item);
    }

    function itemHasChanged(){
      return ctrl.rootForm.$dirty;

      // var statement = false;
      // var new_item = _.omit($scope.item, _.isNull);
      // var old_item = _.omit(ctrl.base_item, _.isNull);
      // statement = angular.toJson(new_item) !== angular.toJson(old_item);
      // return statement;
    }

    function open () {
      $mdSidenav('inventory-product').onClose(confirmCancel);
      ctrl.is_open = true;
      ctrl.rootForm.$setPristine();
      return $mdSidenav('inventory-product').open();
    }

    function closeSlideout () {
      // TO-DO: include check for unsaved changes
      if ( !formService.checkRequiredFields(ctrl.rootForm) ){
        return checkUnsavedChanges()
        .then(function(){
          return $mdSidenav('inventory-product').close();
        });
      }
      
      return $mdSidenav('inventory-product').close();
    }

    function setAlertInfo (item) {
      item = item || $scope.item;
      var valid_minimum = _.isNumber(item.minimum_level) && item.minimum_level >= 0;
      if (valid_minimum) {
        var actual_qty = inventoryItemService.calculateInventoryOnHand(item);
        var is_below = _.isNumber(actual_qty) && actual_qty <= item.minimum_level;
        var minlvl_condition = valid_minimum && is_below;

        _.set(item, 'errors.below_minimum_level', minlvl_condition);
      }
      if ( item.expiry_date ) {
        var expiry_limit = moment().add(30, 'days');
        var is_expired = moment().isSameOrAfter(moment(item.expiry_date));
        var is_soon = moment(item.expiry_date).diff(expiry_limit, 'days') <= 30;
        _.set(item, 'errors.expired', is_expired);
        _.set(item, 'errors.expiring_soon', is_soon);
      }
    }

    function setVendorInfo (item) {
      item = item || $scope.item;
      var str = "";
      if(item.vendor_name) {
        str+= item.vendor_name;
        if(item.manufacturer_name && (item.manufacturer_name !== item.vendor_name)){
          str= "From: "+str+", By: "+item.manufacturer_name;
        }
      } else {
        str = item.manufacturer_name;
      }
      _.set(item, 'UI.vendor_text',str);
      return str;
    }

    function setPOInfo () {
      // should load PO status from endpoint first
      if($scope.item.po_status){
        $scope.item.disable_buy_options = ( _.size($scope.item.po_status['Active']) || _.size($scope.item.po_status['Draft']) );
      } else {
        $scope.item.disable_buy_options = false;
      }

    }

    function setPricingHistory () {
      ctrl.hide_history = true;
      var id = $scope.item.inventory_item_id || $scope.item.id || null;
      if(!id){
        ctrl.hide_history = true;
        return $q.all([]);
      }
      var this_promise = sowInventoryService.getPricingHistory(id);
      ctrl.promises.push(this_promise);
      return this_promise
      .then(function(history_response){
        history_response = _.map(history_response, function(order){
          _.set(order, 'UI.date_label', moment.utc(order.order_date).format('MMM DD, YYYY') );
          return order;
        });
        _.set($scope.item, 'UI.pricing_history', history_response);
        ctrl.hide_history = false;
      })
      .catch(function(error){
        errorService.uiErrorHandler(error);
        ctrl.hide_history = true;
      });
    }

    function resetScope () {
      // ctrl.tab = 'details';
      // $scope.$apply();
    }

    function saveItem () {
      if ( !formService.checkRequiredFields(ctrl.rootForm) || ctrl.saving_item){
        return;
      }
      ctrl.saving_item = true;
      var is_med = $scope.item.UI.is_medication;
      var saveFn = is_med ? saveMedicationItem : saveInventoryItem;
      return saveFn()
      .then(function(response_item){
        ctrl.base_item = angular.copy(response_item);
        $scope.item = angular.copy(response_item);
        resetBaseItem();
        ctrl.rootForm.$setPristine();
        closeSlideout();
        var t_item_updated = $filter('translate')('TOAST.ITEM_UPDATED');
        sgToast.showSimple(t_item_updated);
      })
      .finally(function(){
        ctrl.saving_item = false;
      });
    }

    function saveInventoryItem () {
      var inventory_item = angular.copy($scope.item);
      var manufacturer = angular.copy($scope.item.manufacturer);
      var manufacturer_exists = (!!manufacturer.id && !!manufacturer.name);
      var supplier = angular.copy($scope.item.supplier);

      inventory_item.supplier_id = supplier ? supplier.id : null;
      inventory_item.supplier_name = supplier ? supplier.name : inventory_item.supplierSearchText;
      inventory_item.supplier = inventory_item.supplierSearchText;
      inventory_item.image_src = inventory_item.image_src ? inventory_item.image_src : null;
      inventory_item.sds_url = inventory_item.sds_url ? inventory_item.sds_url : null;

      if(!inventory_item.supplier){
        inventory_item.vendor_name = inventory_item.supplier;
      }

      // inventory_item.manufacturer_id = manufacturer.id || null;
      // inventory_item.manufacturer_name = manufacturer_exists ? manufacturer.name : inventory_item.manufacturerSearchText;
      // inventory_item.manufacturer = manufacturer_exists ? manufacturer.name : inventory_item.manufacturerSearchText;

      inventory_item.manufacturer_id = null;
      inventory_item.manufacturer_name = inventory_item.manufacturerSearchText;
      inventory_item.manufacturer = inventory_item.manufacturerSearchText;

      return inventoryItemService.updateItem(inventory_item)
      .then(triggerItemUpdated)
      .catch(errorService.uiErrorHandler);
    }

    function triggerItemUpdated (item_response) {
      $rootScope.$broadcast('inventory-item-updated', item_response);
    }

    function addToShoppingList () {
      if(!$scope.item){
        return;
      }

      const item = {id: $scope.item.inventory_item_id ? $scope.item.inventory_item_id : $scope.item.id};

      if ($scope.item.product_id && ctrl.has_multi_supplier_selection) {
        closeSlideout();
        return mktHelperService.productDetailsFetch($scope.item.product_id, {
          action: 'ADD_TO_SHOPPING_LIST',
          item,
        });
      }

      return inventoryItemService.addToShoppingList([item])
        .then(function(res){
          var text = $filter('translate')('COMMON.ADDED_TO_SL');
        sgToast.showSimple(text);
        })
        .catch(function(error){
          var t_message = $filter('translate')('ERRORS.ADD_ITEM_TO_LIST')
          errorService.uiErrorHandler(t_message);
        });
    }

    function showProductDetails () {
      closeSlideout();
      mktHelperService.productDetailsFetch($scope.item.product_id);
    }

    function checkUnsavedChanges () {
      // var new_item = angular.copy($scope.item);
      // var unsaved_changes = !(_.isEqual(ctrl.base_item, new_item));
      var unsaved_changes = itemHasChanged();
      if (unsaved_changes) {
        return RedirectModalService.show(null, 'SaveChanges')
        .then(function(){
          ctrl.saveFn();
        }).catch(function(){
          return $q.resolve();
        });
      } else {
        return $q.resolve();
      }
      
    }

    function deleteItem ($event) {
      if (ctrl.saving_item) {
        return;
      }
      ctrl.saving_item = true;
      var deleteFn = $scope.item.UI.is_medication ? deleteMedication : deleteInventoryItem;
      // wait until price history is loaded
      return $q.all(ctrl.promises)
      .then(function(){
        return deleteFn($scope.item, $event)
        .then(function(){
          closeSlideout();
        })
        .catch(function(error){
          if(error){
            return errorService.uiErrorHandler(error);
          }
        })
        .finally(function(){
          $mdDialog.hide();
          ctrl.saving_item = false;
        });
      });
    }

    function deleteInventoryItem (item) {

      function _success () {
        // check for open bundle and close it
        var t_message = $filter('translate')('TOAST.INVENTORY_ITEM_REMOVED');
        sgToast.showSimple(t_message);
        $rootScope.$broadcast('inventory-item-deleted', item);
      }

      function _error (error) {
        var t_message = $filter('translate')('ERRORS.ITEM_REMOVE')
        errorService.uiErrorHandler(error || t_message, 0);
      }

      return elementService
      .remove('inventoryItem', item, true)
      .then(function (inventory_item) {
        _success();
      })
      .catch(function (error) {
        var poError = _.get(error, 'purchase_order_error');

        if (poError) {
          var drafts = _.filter(poError.purchase_orders, {'status': 'Draft'});
          var candidate = angular.extend(angular.copy(item), poError);

          if (drafts.length === poError.purchase_orders.length) {
            return DeleteInventoryDraftPODialog
              .show([candidate])
              .then(_success)
              .catch(_error);
          } else {
            return DeleteInventoryActivePODialog
              .show([candidate])
              .then(function () {
                $mdDialog.hide();
              });
          }
        } else {
          _error(error);
        }
      });
    }

    function deleteMedication (med, $event) {
      med = med || $scope.item;
      med.open_more_menu = false;
      var active_orders = _.get(med, 'po_status.Active');
      if ( _.size(active_orders) ) {
        return DeleteInventoryActivePODialog.show([med], $event);
      } else {
        return $mdDialog.show({
          controller: DialogController,
          controllerAs: 'medlCtrl',
          templateUrl: 'templates/marketplace/medications/modals/delete-medication-group.html',
          parent: angular.element(document.body),
          clickOutsideToClose: true,
          bindToController: true,
          locals:{
            medication:med
          }
        })
        .then(function(answer){
          if (answer) {
            return sowMedicationService.deleteMedication(med)
            .then(function(res){
              $rootScope.$broadcast('medication-item-deleted', med);
            })
            .catch(function(error){
              var t_message = $filter('translate')('ERRORS.MED_DELETE');
              errorService.uiErrorHandler(t_message);
            });
          }
        });
      }
    }

    function saveMedicationItem (medication) {
      medication = medication || $scope.item;
      if(!formService.checkRequiredFields(ctrl.rootForm)){
        return;
      }
      var manufacturer = medication.manufacturer;
      var supplier = medication.supplier;

      medication.barcode = medication.code;
      medication.manufacturer_id = manufacturer ? manufacturer.id : null;
      medication.manufacturer_name = manufacturer ? manufacturer.name : medication.manufacturerSearchText;
      medication.supplier_id = supplier ? supplier.id : null;
      medication.supplier_name = supplier ? supplier.name : medication.supplierSearchText;

      medication.track_by = "Package";
      medication.minimum_level = medication.minimum_level !== "" ? medication.minimum_level : 0;
      medication.desired_level = medication.desired_level !== "" ? medication.desired_level : 0;

      return sowMedicationService.createOrUpdateMedication(medication)
      .then(function(med){
        $rootScope.$broadcast('medication-item-updated', med);
        var t_message = $filter('translate')('TOAST.MED_UPDATED');
        sgToast.showSimple(t_message);
      }).catch(function(error){
        var t_message = $filter('translate')('ERRORS.MED_DELETE');
        errorService.uiErrorHandler(t_message);
      });
    }

    /**
     * Determines if shopping list column should be hidden based on:
     * 1. If the office has disabled shopping list
     * 2. If the item is discontinued (item.UI.is_discontinued)
     * @returns {boolean}
     */
    function shouldHideShoppingList (item) {
      return ctrl.disable_shopping_list || item.UI.is_discontinued;
    }

    /**
     * Determines if add to cart icon should be shown based on:
     * 1. If the item has a product_id
     * 2. If the item is not discontinued (item.UI.is_discontinued)
     * 3. If the user has cart access
     * @returns {boolean}
     */
    function shouldShowAddToCartIcon (item) {
      return item.product_id && !item.UI.is_discontinued && ctrl.hasCartAccess;
    }

    function defineLocks () {
      const membership = membershipService.get();

      const property_path = _.get($scope, 'item.UI.is_medication', false) ? 'user_properties.medications' : 'user_properties.gen_inv';

      const delete_lock_condition = _.get(membership, property_path+".disable_delete", false);
      ctrl.disabled_delete = delete_lock_condition;

      ctrl.disable_shopping_list = !AccessService.hasShoppingListAccess();

      ctrl.hasCartAccess = AccessService.hasCartAccess();
      ctrl.has_multi_supplier_selection = AccessService.getProperty('shopping_list.multi_supplier_selection');
    }

  }

  function DialogController($scope, $mdDialog) {
    var ctrl = this;

    $scope.product = $scope.$parent.item;
    ctrl.hide = $scope.hide = function() {
      $mdDialog.hide();
    };

    ctrl.cancel = $scope.cancel = function() {
      $mdDialog.cancel();
    };

    ctrl.answer = $scope.answer = function(answer) {
      $mdDialog.hide(answer);
    };

    // XGH warning
    ctrl.delete_medication = ctrl.answer;
    ctrl.close_dialogs = ctrl.cancel;
    return ctrl;
  }

}());

angular.module("app.marketplace.ui.inventory")
.controller("inventoryAllController",
  function($scope, $stateParams) {

  $scope.VS_OPTIONS = {
    'scrollParent': '#appContainer',
    'size': 72
  };

  $scope.vi_index = 20;

  if($scope.clearSearch){
    $scope.clearSearch(true);
  }

  var itemsMapWatch = $scope.$watch('itemsMap', function(itemsMap){
    if(itemsMap && $stateParams.itemId && $scope.itemsMap[$stateParams.itemId]){
      $scope.viewItem(null, $scope.itemsMap[$stateParams.itemId]);
      itemsMapWatch();
    }
  });

  if($stateParams.filterType){
    if($stateParams.filterType === "below_minimum_level"){
      $scope.filters.below_min_level = true;
    }else if($stateParams.filterType === "expiring_soon"){
      $scope.filters.expiring_soon = true;
    }
  }

  $scope.group = {
    "displayName": "All General Inventory",
    "type": "all",
    "name": "all",
  };

  $scope.$watch('vi_index', updateDisplayItems);
  $scope.$watch('filters.below_min_level', updateDisplayItems);
  $scope.$watch('filters.expiring_soon', updateDisplayItems);
  $scope.$watchCollection('group._items', updateDisplayItems);

  $scope.displayItems = [];

  function updateDisplayItems () {
    var items = $scope.getBundleItems($scope.group, true);
    $scope.displayItems = _.chain(items)
                           .filter(function (i) {
                             return i.type === 'General Item' || i.type === 'Marketplace Item';
                           })
                           // .slice(0, $scope.vi_index - 1)
                           .value();

    // adds current item to the list
    if ($scope.currentItem) {
      addCurrentItem(items, $scope.displayItems);
    }
  }

  function addCurrentItem (all_items, display_items) {
    var _current_item_in_list = _.find(display_items, $scope.currentItem);
    if (!_current_item_in_list) {
      var _current_item = _.find(all_items, $scope.currentItem);
      
      // adds item to the top of the list, to prevent issues with scrolling to bottom 
      // (it will reorder once you scroll down to its place).
      display_items.unshift(_current_item);
    }
  }

  // Note that the DOM needs to be loaded first,
  // or else document.body will be undefined
  function getScrollTopElement() {
    var results = document.getElementsByClassName('main-view-port');
    return results[0];
  }

  //
  // Get the top-level scrolling element. Apparently, Firefox defaults this to
  // <html>, while WebKit defaults it to <body>. We could change this in CSS
  // too, but this JS fix works too.
  //
  // Reference: http://stackoverflow.com/questions/8149155/animate-scrolltop-not-working-in-firefox
  //
  var topScrollElement = getScrollTopElement();

  // $interval(function(){
  //   var scrollPane = angular.element(topScrollElement);
  //   var newScrollTop = scrollPane.scrollTop();

  //   // Note: I haven't bothered to figure out what these calculations that John
  //   // put in here mean yet so these debug statements are useful whenever I
  //   // need to poke at an issue here. I really should decipher all of this and
  //   // make it more readable.
  //   // $log.debug('scrollPane.length = %o', scrollPane.length);
  //   // $log.debug('(window.innerHeight + newScrollTop) >= (scrollPane[0].scrollHeight - 400) = %o', (window.innerHeight + newScrollTop) >= (scrollPane[0].scrollHeight - 400));
  //   // $log.debug('newScrollTop > ($scope.vi_index * 64 - window.innerHeight) = %o', newScrollTop > ($scope.vi_index * 64 - window.innerHeight));

  //   if (scrollPane.length &&
  //       (window.innerHeight + newScrollTop) >= (scrollPane[0].scrollHeight - 400) &&
  //       newScrollTop > ($scope.vi_index * 64 - window.innerHeight)) {
  //     $scope.showListLoad = true;
  //     $timeout(function(){
  //       $scope.showListLoad = false;
  //       $scope.vi_index += 20;
  //     });
  //   }
  // }, 300);
});

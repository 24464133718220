(function () {
  'use strict';

  angular
    .module('app.shared.support')
    .service('addressService', addressService);

  function addressService (sgToast, ConfigService) {
    /*jshint validthis: true */
    var service = this;

    service.extractAddress = extractAddress;
    service.validateAddress = validateAddress;
    service.findAddressErrors = findAddressErrors;
    service.generateAddressText = generateAddressText;
    service.convertToStripeAddress = convertToStripeAddress;
    service.convertFromStripeAddress = convertFromStripeAddress;
    service.checkIfAddressesMatch = checkIfAddressesMatch;

    return service;

    //
    // Generate address text
    //
    function generateAddressText (address) {
      var lines = [];

      lines.push(address.company_name);
      lines.push((address.company_name ? 'c/o ' : '') + address.name);
      lines.push(address.address1);
      lines.push(address.address2);
      lines.push(_.compact([
        address.city,
        address.province && (address.province.abbreviation || address.province.name)
      ]).join(', '));
      lines.push(address.postal_code);

      return _.compact(lines).join("\n");
    }

    //
    // Is address valid? Returns true/false
    //
    function validateAddress (address) {
      return !findAddressErrors(address);
    }

    //
    // Return a string error indicating why address has errors. Otherwise
    // returns false.
    //
    function findAddressErrors (address) {
      return !address ? 'Blank Address'
           : !address.address1 ? 'Missing Street Address'
           : !address.city ? 'Missing City'
           : !address.postal_code ? 'Missing Postal Code'
           : !address.country ? 'Missing Country'
           : !address.province ? 'Missing Province'
           : false;
    }

    //
    // Return an object with address attributes filled in.
    //
    function extractAddress (address, options) {
      var addressError = findAddressErrors(address);

      //
      // Build the errorConfig with incoming errorConfig overriding defaults.
      //
      options = angular.extend({}, {
        suppressErrors: false,
        errorPrefix: 'Invalid Billing Address'
      }, options || {});

      if (addressError) {
        if (!options.suppressErrors) {
          sgToast.showSimple(options.errorPrefix + ': ' + addressError);
        }
        return false;
      } else {
        return {
          address1: address.address1,
          address2: address.address2,
          city: address.city,
          postal_code: address.postal_code,
          province: address.province,
          country: address.country
        };
      }
    }

    //
    // Convert the data format that Stripe expects to address fields.
    //
    function convertToStripeAddress (address) {
      return {
        address_line1: address.address1,
        address_line2: address.address2,
        address_city: address.city,
        address_zip: address.postal_code,
        address_state: address.province.name,
        address_country: _.get(address,'country.id', address.province.country_id),
        country: _.get(address,'country.id', address.province.country_id)
      };
    }
    //
    // Convert the data format that Stripe expects to address fields.
    //
    function convertFromStripeAddress (address) {
      var countriesMap = ConfigService.get('countriesMap');
      var addressData = {
        address1: address.address_line1,
        address2: address.address_line2,
        city: address.address_city,
        postal_code: address.address_zip,
        province: {name : address.address_state},
        country: {id: (address.address_country || address.country)}
      };
      if(addressData.country.id){
        var provinces = _.get(countriesMap, addressData.country.id).provinces;
        _.map(provinces, function(province){
          if(province.name === addressData.province.name){
            addressData.province = province;
          }
        });
      }
      
      return addressData;
      
    }

    /** 
     * Determines if the relevant fields of two addresses match.
     * 
     * @param {Object} addr_1
     * @param {Object} addr_2
     * @param {Array} props the properties we will check for deep equality
     * 
     * @return {Boolean} 
    */
    function checkIfAddressesMatch(addr_1, addr_2, props) {
      for (const prop of props) {
        if (addr_1[prop] !== addr_2[prop]) {
          return false;
        }
      }
      return true;
    }

  }
}());

(function () {
  'use strict';

  var plans = {
    'marketplace': {
      'name': 'Marketplace',
    },
    'premium': {
      'name': 'Premium',
    }
  };

  angular
    .module('app.marketplace.ui.office')
    .controller('OfficeSubscriptionDowngradeCtrl', OfficeSubscriptionDowngradeCtrl);

  function OfficeSubscriptionDowngradeCtrl ($scope, $rootScope, $log, $state, $filter, $stateParams, subscriptionService, errorService, sessionService) {
    /*jshint validthis: true */
    var ctrl = this;
    var office = $rootScope.current_office;
    var plan_hrid = $stateParams.plan;

    $scope.feedback = {
      'reasons': {
        'I don\'t need the plan\'s features.': false,
        'I think that the price is too expensive.': false,
        'I have some other reason. (Please describe below)': false,
      },
      'comments': '',
    };

    $scope.hasPartialRefund = false;
    $scope.loadingPartialRefund = false;
    $scope.errorLoadingPartialRefund = false;
    $scope.saving = false;
    $scope.refundAmount = 0;
    $scope.isSelected = isReasonSelected;
    $scope.select = selectReason;
    $scope.cancel = cancelMarketplace;
    $scope.submit = switchToMarketplace;

    ctrl.init = init;

    ctrl.init();

    return ctrl;

    //
    // Initialize the controller
    //
    function init () {
      var youShouldNeverHaveComeHere = (
        !office ||  // no office
        office.account_type === 'Free' || // cannot downgrade
        !plans[plan_hrid] || // not a valid plan
        plans[plan_hrid].name === office.account_type // cannot downgrade to the plan you're already on
      );

      if (youShouldNeverHaveComeHere) {  // Be gone!
        return toPlansPage();
      }

      $scope.planName = plans[plan_hrid].name;
      $scope.currPlanName = office.account_type;

      var youMayQualifyForMoneyback = (!office.trial_ends_on || !office.trial_over);

      if (youMayQualifyForMoneyback) {
        checkForPartialRefund();
      }
    }

    //
    // Check for Partial Refund
    //
    function checkForPartialRefund () {
      $scope.errorLoadingPartialRefund = false;
      $scope.loadingPartialRefund = true;

      return subscriptionService
        .downgradeRefund(plan_hrid, $stateParams.billing_cycle)
        .then(function (refund) {
          $scope.refundAmount = refund;
          $scope.hasPartialRefund = refund > 0;
        })
        .catch(function (error) {
          $scope.errorLoadingPartialRefund = true;
          $log.error('There was an error calculating your refund amount.');
        })
        .finally(function () {
          $scope.loadingPartialRefund = false;
        });
    }

    //
    // Redirect to the "Subscription View" Page
    //
    function toPlansPage () {
      return $state.go('app.office.profile.subscription.view');
    }

    //
    // Check if a Choice is currently selected (by index).
    //
    function isReasonSelected (reason) {
      return $scope.feedback.reasons[reason];
    }

    //
    // Select a Choice by index.
    //
    function selectReason (reason) {
      if (!$scope.saving) {
        $scope.feedback.reasons[reason] = !$scope.feedback.reasons[reason];
      }
    }

    //
    // Cancel Selection of Marketplace Plan
    //
    function cancelMarketplace () {
      if (!$scope.saving) {
        toPlansPage();
      }
    }

    //
    // Switch to Marketplace
    //
    function switchToMarketplace () {
      $scope.saving = true;

      if (!plan_hrid || plan_hrid === 'marketplace') {
        return subscriptionService.switchToMarketplace($scope.feedback).then(function () {
          return sessionService.refreshSession().then(function () {
            return $state.go('app.office.profile.subscription.view');
          }).catch(function (error) {
            var t_message = $filter('translate')('ERRORS.SUB_SUCCESS_UNRELATED_FAIL')
            var t_message2 = $filter('translate')('ERRORS.UPDATING_PLEASE_REFRESH')
            errorService.uiErrorHandler(t_message + t_message2);
          });
        }).catch(function (error) {
          errorService.uiErrorHandler(error);
        })['finally'](function () {
          $scope.saving = false;
        });
      } else {
        return subscriptionService
          .getPlans()
          .then(function (plans) {
            var selectedPlan;

            _.forEach(plans, function (plan) {
              if (plan.hrid === plan_hrid && plan.billing_cycle === $stateParams.billing_cycle) {
                selectedPlan = plan;
              }
            });

            return subscriptionService.downgradeTo(selectedPlan.id, $scope.feedback);
          })
          .then(function () {
            return sessionService
              .refreshSession()
              .then(function () {
                return $state.go('app.office.profile.subscription.view');
              })
              .catch(function () {
                window.reload();
              });
          })
          .catch(function (error) {
            errorService.uiErrorHandler(error);
          })
          .finally(function () {
            $scope.saving = false;
          });
      }

    }
  }
}());

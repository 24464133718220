(function () {
  'use strict';
  angular.module('sowProduct')
  .service('productDetailsService', productDetailsService);
  
  function productDetailsService($state, $rootScope, sowMktService, mktHelperService, $mdDialog, $location, $anchorScroll, $filter, cartService, sgToast, errorService, inventoryItemService, sowMedicationService, membershipService, sowAnalyticsService, sowExternalVendorService, sowExternalVendorAccountService, inventoryHelperService) {
    var service = this;
    
    service.fetchProductDetailData = fetchProductDetailData;
    service.getRelatedProducts = getRelatedProducts;
    service.toggleFavourite = toggleFavourite;
    service.goToManufacturer = goToManufacturer;
    service.goToVendor = goToVendor;
    service.addToCart = addToCart;
    service.addExternalVendorItemToCart = addExternalVendorItemToCart;
    service.logItemAddedToCart = logItemAddedToCart;
    service.addToGenInv = addToGenInv;
    service.addToMeds = addToMeds;
    service.openGalleryDialog = openGalleryDialog;
    service.scrollToRelatedProducts = scrollToRelatedProducts;
    service.getCurrentMembership = getCurrentMembership;
    service.addProductToShoppingList = addProductToShoppingList;
    service.addImplantToShoppingList = addImplantToShoppingList;
    service.addToShoppingList = addToShoppingList;
    service.updateGenInvData = updateGenInvData;
    service.updateMedsData = updateMedsData;
    service.getProductType = getProductType;
    service.getProductInventoryStatus = getProductInventoryStatus;
    service.doesVendorHaveProduct = doesVendorHaveProduct;
    service.getPriceText = getPriceText;
    service.sortVendors = sortVendors;
    service.getVendorsForProduct = getVendorsForProduct;
    service.getProductPriceForAccount = getProductPriceForAccount;
    service.generateQuantityOptions = generateQuantityOptions;
    service.generateActiveOrderTooltip = generateActiveOrderTooltip;
    
    return service;
    
    /**
     * Fetches the product which matches the "url_name" param of the URL.
     *
     * @param {String} product_id
     * 
     * @return {Object} 
     */
    function fetchProductDetailData(url_name) {
      const include_inventory_info = true;
      return sowMktService.fetchUrlProduct(url_name, include_inventory_info)
        .then(response => {
          const product = response.products?.[0];
          if (product) {
            mktHelperService.setProductInfo(product);
            return product;
          }
      });
    }

    /**
     * Fetches all products related to the one specified by the current product's ID.
     *
     * @param {String} product_id
     * 
     * @return {Array} Algolia hits
     */
    function getRelatedProducts (product_id) {
      return sowMktService.getRelatedProducts(product_id).then(function (related_products) {
        var hits = _.get(related_products, 'results[0].hits', null);
        return hits;
      });
    }

    /**
     * Toggles a product's favourite status (boolean).
     *
     * @param {Object} product
     * 
     * @return {*}
     */
    function toggleFavourite(product) {
      return sowMktService.toggleFavourite(product);
    }

    /**
     * Navigates to product search results page with selected manufacturer applied as a filter.
     *
     * @param {Object} manufacturer
     * 
     * @return {*}
     */
    function goToManufacturer(manufacturer) {
      var params = {
        'manufacturer_id': manufacturer.id,
        'mf_id': manufacturer.id,
        'mf_name': manufacturer.name,
        'manufacturer': manufacturer
      };
      mktHelperService.goToManufacturer(params);
    }

    /** 
     * Navigates to product search results page with selected vendor applied as a filter.
     * Will only be executed if selected vendor is actively selling the product (not sold out and/or discontinued).
     * 
     * @param {Object} vendor 
     * 
     * @return {*} 
    */
    function goToVendor(vendor) {
      var vendor_id = _.get(vendor, 'vendor.id');
      var vendor_name = _.get(vendor, 'vendor.name');
      return vendor_id ? $state.go("app.mkt.search", {vendor_id: vendor_id, vendor_name: vendor_name}) : null;
    }

    /**
     * Opens the Image Gallery dialog for a given product.
     *
     * @param {Object} product
     * 
     * @return {mdDialog} 
     */
    function openGalleryDialog (product) {
      return $mdDialog.show({
        bindToController: true,
        clickOutsideToClose: true,
        controller: 'pdImagesController',
        controllerAs: 'pdGalleryCtrl',
        escapeToClose: false,
        parent: angular.element(document.body),
        templateUrl: 'sow-product-details/directives/pd-image-gallery.html',
        locals: {
          "product": product,
          "gallery": true
        }
      });
    }
    
    /**
     * Adds a quantity of a product to the user's shopping cart, factoring in the vendor they've selected.
     *
     * @param {String} product_id
     * @param {Number} quantity
     * @param {String} vendor_id
     * 
     * @return {*}
     */
    function addOfficialVendorItemToCart (product_id, quantity, vendor_id) {
      return cartService.addToCart(product_id, quantity, vendor_id)
      .catch(function (error) {
        errorService.uiErrorHandler(error);
        throw error;
      });
    }

    /**
     * Adds an  external vendor item to the cart
     * @param item - Object with vendor_inventory_id, price, product_id, and quantity
     * @returns The promise object.
     */
    function addExternalVendorItemToCart(item) {
      return sowExternalVendorService.addItemToCart(item)
        .catch((error) => {
          errorService.uiErrorHandler(error);
        });
    }

    /**
     * Logs that an item has been added to the cart.
     * @param {object} product
     * @param {object} vendor
     * @param {number} quantity
     */
    function logItemAddedToCart(product, vendor, quantity) {
      const item = {...product, ...vendor, quantity};
      // the analytics method takes an array of items
      sowAnalyticsService.logAddToCart([item]);
    }

    /**
     * Adds a product to the office's general inventory.
     *
     * @param {Object} product
     * @param {Object} selected_vendor
     * @return {*}
     */
    function addToGenInv (product, selected_vendor) {
      return inventoryItemService.createItemFromProduct(product, 0, selected_vendor)
      .then(function (response) {
        var t_message = $filter('translate')('MARKETPLACE.DETAIL.ADDED_TO_GEN_INV');
        sgToast.showSimple(t_message);
        updateGenInvData(product, response);
        return response;
      }).catch(function (error) {
        errorService.uiErrorHandler(error);
        return error.status;
      });
    }

    /**
     * Adds a medication product to the office's Medications.
     *
     * @param {Object} product
     * 
     * @return {*}
     */
    function addToMeds(product, selected_vendor) {
      return sowMedicationService.createOrUpdateMedication(product, selected_vendor)
      .then((response) => {
        const t_message = $filter('translate')('MARKETPLACE.DETAIL.ADDED_TO_MEDS');
        sgToast.showSimple(t_message);
        updateMedsData(product, response);
        return response;
      })
      .catch(error => errorService.uiErrorHandler(error));
    }

    /** 
    * Scrolls to the related products component.
    * 
    * @return {*} 
    */
    function scrollToRelatedProducts () {
      $location.hash('related-products');
      $anchorScroll();
    }

    /** 
     * Fetches the memebership status of current user and office`. 
     * 
     * @return {Object} 
    */
    function getCurrentMembership () {
      return membershipService.get()
    }
    
    /** 
     * Adds an inventory item to the Shopping List. 
     * 
     * @param {MarketplaceProduct} product 
     * @param {string} id_path
     * @param {object} selected_vendor
     * @return {*} 
    */
    function addProductToShoppingList(product, id_path, selected_vendor) {
      // for products with existing Office Inventory IDs
      // (All Implants, plus meds and general items that have been added already)
      // we use the existing id to add them to shopping list
      const existing_id = _.get(product, id_path+'.office_inventory_id', null);
      if (existing_id) {
        // for products with their inventory data already defined, we go straight to adding to SL
        return addToShoppingList(existing_id, selected_vendor);
      } else {
        // for all others, we first create the general/meds item, then add it to SL
        if (product.item_type.includes('Medication')){
          return addToMeds(product, selected_vendor).then(function(medication_item){
            return addToShoppingList(medication_item.inventory_item_id);
          });
        } else {
          return addToGenInv(product, selected_vendor).then(function(general_item){
            return addToShoppingList(general_item.id, selected_vendor);
          });
        }
      }
      
    }

    /** 
     * Adds an implant to the Shopping List. 
     * 
     * @param {MarketplaceProduct} product object fetched from PDP
     * 
     * @return {*} 
    */
    function addImplantToShoppingList(product) {
      const item_id = _.get(product, 'implant_inventory_status.office_inventory_id', null);
      return addToShoppingList(item_id);
    }

    /** 
     * Adds an item of any kind to the Shopping List using its ID. 
     * 
     * @param {String} id 
     * @param {object} selected_vendor
     * @return {*} 
    */
    function addToShoppingList(id, selected_vendor) {
      const vendor_info = _getSelectedVendorInfo(selected_vendor);
      return inventoryItemService.addToShoppingList([{id}], vendor_info) // expects an array of objects
        .then((items_added_to_list) => {
          const t_success = $filter('translate')('COMMON.ADDED_TO_SL');
          sgToast.showSimple(t_success);
          return items_added_to_list;
        }).catch((error) => {
          const error_message = _localizeShoppingListError(error);
          const t_error = $filter('translate')(error_message);
          errorService.uiErrorHandler(t_error);
        });
    }

    /**
     * Returns an error message based on the type of error passed as an argument.
     * @param {unknown} error
     * @returns {'ERRORS.NO_LINKED_SUPPLIER'|'ERRORS.ADD_ITEM_TO_LIST'}
     */
    function _localizeShoppingListError (error) {
      if (error?.internal_code === 'NO_LINKED_SUPPLIER') {
        return 'ERRORS.NO_LINKED_SUPPLIER';
      }

      return 'ERRORS.ADD_ITEM_TO_LIST';
    }

    /**
     * Returns an object with vendor_inventory_id from the selected vendor
     * @param selected_vendor
     * @returns {{vendor_inventory_id}}
    */
    function _getSelectedVendorInfo (selected_vendor) {
      if (selected_vendor) {
        return {
          vendor_inventory_id: selected_vendor.vendor_inventory_id,
        }
      }
    }

    // updates product data to include newly created medication item based on it
    function updateMedsData (product, medication) {
      _.set(product, 'medication_inventory_status', {
        "on_hand": null,
        "tracking_method": null,
        "office_inventory_id": medication.inventory_item_id,
        "minimum_level": null,
        "medication_id": medication.id
      });
    }
    
    // updates product data to include newly created inventory item based on it
    function updateGenInvData (product, general_item) {
      _.set(product, 'office_inventory_status', {
        "on_hand": null,
        "tracking_method": null,
        "office_inventory_id": general_item.id,
        "minimum_level": null,
      });
    }

    // helps simplify the process of identifying item_type
    function getProductType (product) {
      let type;

      if (product.item_type.includes('Medication')) {
        type = 'medication';
      } else if (product.item_type.includes('Implant')) {
        type = 'implant';
      } else {
        type = 'general';
      }

      return type;
    }

    /**
    * Determines if the quantity in question is a factor of the buy prop of the promo and returns the appropriate promo text if so.
    * 
    * @param {number} quantity The value of the quantity option currently invoking the function
    * @param {object} props The buy and get properties of the sale
    * 
    * @return {string | null} The promo text to display next to the quantity in the corresponding <option> of the quantity <select> (if any)
    */
     function _getValueOffText(quantity, props) {
      if (quantity % props.buy === 0) {
        const value_off = quantity / props.buy * props.get;
        const promo_key = 'MARKETPLACE.CARD.PROMOTIONS.BUY_GET_VALUE_OFF!';
        return $filter('translate')(promo_key, { x: quantity, y: value_off });
      }
      return null;
    }
    
    /**
    * Determines if the quantity in question is a factor of the buy prop of the promo and returns the appropriate promo text if so.
    * 
    * @param {number} quantity The value of the quantity option currently invoking the function
    * @param {object} props The buy and get properties of the sale
    * 
    * @return {string | null} The promo text to display next to the quantity in the corresponding <option> of the quantity <select> (if any)
    */
    function _getPercentOffText(quantity, props) {
      if (quantity === props.buy) {
        return $filter('translate')(
          'MARKETPLACE.CARD.PROMOTIONS.BUY_X_OR_MORE_GET_PERCENT_OFF!',
          { x: quantity, y: props.get },
        );
      }
      return null;
    }

    /**
     * Returns the maximum free quantity for a given quantity based on available promos
     * 
     * @param {number} quantity The value of the quantity option currently invoking the function
     * @param {Array<number>} free_quantities The number of free items the user will receive for each quantity
     * 
     * @return {string | null} The localized promo text to display next to the quantity in the
     * corresponding <option> of the quantity <select> (if any)
    */
    function _getBuyGetText(quantity, free_quantities) {
      const free_quantity = free_quantities[quantity];
      if (!free_quantity) return null;
      return $filter('translate')(
        'MARKETPLACE.CARD.PROMOTIONS.BUY_GET!',
        { x: quantity, y: free_quantity },
      );
    }
    
    /**
    * Generates a range of options from which the user may select a quantity.
    * @param {object} vendor The currently selected vendor
    * @param {number} min_qty The lowest quantity the user may select
    * @param {number} max_qty The highest quantity the user may select
    * @return {object[]} The range of Objects which will populate the quantity <select>'s <option>s
    */
    function generateQuantityOptions(vendor, min_qty = 1, max_qty = 99) {
      const quantity_options = [];
      const { limit_per_order } = vendor;
      const promo_type = _.get(vendor, 'promotions[0].promotion_type', null);
      const props = _.get(vendor, 'promotions[0].promotion_properties', null);
      // account for multiple tiers of BUY_GET and MANUFACTURER_BUY_GET promos
      const promo_tiers = _.filter(
        _.get(vendor, 'promotions[0].available_promo_tiers'),
        // only include tiers that match the promo type (there should never
        // be a mix of vendor and manufacturer promos but just being safe)
        { promotion_type: promo_type },
      );
      const buy = props?.buy || 0;

      // find which method and args will allow us to get the promo text (if any)
      let method, args;
      if (promo_type === 'VALUE_OFF' && buy > 1) {
        method = _getValueOffText;
        args = [props];
      } else if (promo_type === 'PERCENT_OFF' && buy > 1) {
        method = _getPercentOffText;
        args = [props];
      } else if (['BUY_GET', 'MANUFACTURER_BUY_GET'].includes(promo_type) && promo_tiers.length) {
        method = _getBuyGetText;
        args = [_getFreeQuantities(max_qty, promo_tiers)];
      }

      for (let i = min_qty; i <= max_qty; i++) {
        // add promo text (if any)
        const buy_get_info = method?.(i, ...args) || null;
        // handle quantity restriction (if any)
        const is_disabled = limit_per_order ? i > limit_per_order : false;
        quantity_options.push({ number: i, buy_get_info, is_disabled });
      }

      return quantity_options;
    }

    /**
     * Calculates the number of free items the user will receive (if any) for each
     * available quantity based on the promos of the vendor and/or manufacturer
     *
     * @param {number} max_qty The highest quantity the user may select
     * @param {Array<object>} promo_tiers Available tiers of a buy/get promo
     * @returns {Array<number>} The free quantity for each available quantity
     */
    function _getFreeQuantities(max_qty, promo_tiers) {
      const props = promo_tiers.map(p => p.promotion_properties);
      // initialize a zero-indexed array of free quantities where the index
      // is the quantity and every value is 0 until we perform calculations
      const free_quantities = Array(max_qty + 1).fill(0);
      // find the minimum quantity at which a free item is possible
      const lowest_buy_prop = Math.min(...props.map(p => p.buy));
      // iterate over all quantities up to the maximum quantity
      for (let i = lowest_buy_prop; i <= max_qty; i++) {
        // apply each promo in turn to determine the maximum free quantity
        for (const { buy, get } of props) {
          // calculate the free quantity by adding the `get` value to the
          // max free quantity of the quantity that is `buy` items less
          // e.g. if quantity is 16 and the promo is buy 5 get 2 and the
          // max free quantity for 11 is 4, then the free quantity is 6
          const new_quantity = free_quantities[i - buy] + get;
          // update the free quantity at this index if the new quantity
          // is greater than the current value at this index
          if (new_quantity > free_quantities[i]) {
            free_quantities[i] = new_quantity;
          }
        }
      }
      return free_quantities;
    }

    /**
     * Generates a string with information about open marketplace orders and active POs
     * which include the marketplace product in question, for display in a tooltip
     * @param {string} product_id - The id of the marketplace product
     * @return {string} - A CSL of orders which include said product
     */
    function generateActiveOrderTooltip(product_id) {
      return sowMktService.fetchProductOrderInfo({ product_id })
        .then(res => {
          const mkt_orders = res?.['Marketplace Orders'] || [];
          const open_orders = mkt_orders.filter(order => order.order_status === 'Open');
          const purchase_orders = res?.['Purchase Orders'] || [];
          const active_orders = purchase_orders.filter(order => order.po_status === 'Active');
          const all_orders = [...open_orders, ...active_orders];
          return inventoryHelperService.generateTooltip(all_orders);
        });
    }

    function getVendorsForProduct(product_id) {
      return sowExternalVendorService.getVendorsForProduct(product_id);
    }

    function getProductPriceForAccount(vendor_info) {
      return sowExternalVendorAccountService.getProductPriceForAccount(vendor_info);
    }

    function sortVendors(vendors) {
      return mktHelperService.sortVendors(vendors);
    }

    function doesVendorHaveProduct(vendors) {
      return mktHelperService.doesVendorHaveProduct(vendors);
    }

    /**
     * If the vendor is disconnected and has no price, prompt the user to
     * connect their account. Otherwise, show the formatted price.
     * @param {object} vendor
     * @param {number|undefined} price
     */
    function getPriceText(vendor, price = vendor.price) {
      const { has_error, is_disconnected } = vendor.UI || {};
      const has_price = mktHelperService.doesVendorHavePrice(vendor);
      // case 1: vendor has error - show error text
      if (has_error) {
        return $filter('translate')('EXTERNAL_VENDORS.CONNECTION_ERROR');
      }
      // case 2: disconnected vendor w/o price - show account connection prompt
      if (is_disconnected && !has_price) {
        return $filter('translate')('EXTERNAL_VENDORS.CONNECT_TO_VIEW_PRICE');
      }
      // default: show formatted price
      return $filter('currency')(price, '$', 2);
    }

    function getProductInventoryStatus(info) {
      return mktHelperService.getProductInventoryStatus(info);
    }

    /**
    * Adds product(s) to user's cart, factoring in the quantity and vendor they have selected.
    * Only adds product(s) if we are not already in the process of adding to the cart.
    * @param {string} product_id
    * @param {string} vendor_id
    * @param {number} quantity
    */
    function addToCart (product, vendor, quantity) {
      // Due to API naming inconsistency, the product's ID may be called id or product_id
      const product_id = product.id || product.product_id;

      // there are two methods for adding to cart, one for external vendors and one for internal ones
      if (vendor.UI?.is_external) {
        const { vendor_inventory_id, price } = vendor;
        const item = { vendor_inventory_id, price, product_id, quantity };
        return addExternalVendorItemToCart(item);
      } else {
        const vendor_id = vendor.vendor.id;
        return addOfficialVendorItemToCart(product_id, quantity, vendor_id);
      }
    }
    
  }
})();

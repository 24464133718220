(function () {
  'use strict';

  angular
    .module("app.marketplace.elements")
    .service('sessionService', sessionService);

  //
  //
  //
  function sessionService ($rootScope, $q, $filter, $log, $state, elementService, errorService, appEvents, accountService, apiTokenService, useExtendedSession, sowAnalyticsService, localStorageService, sowInteropService, generalUtils, sowUserAdminDataService, zendeskChatService) {
    /*jshint validthis: true */
    var service = this;

    service.session = null;

    service.initialize = initialize;
    service.onInit = _waitForInitialized;
    service.get = get;
    service.login = login;
    service.logout = logout;
    service.isActive = isActive;
    service.isSignedIn = isSignedIn;
    service.setSession = setSession;
    service.clearSession = clearSession;
    service.refreshSession = refreshSession;
    service.hasOfficeMemberships = hasOfficeMemberships;
    service.getOfficeMemberships = getOfficeMemberships;
    service.updateFlag = updateFlag;
    service.updateLocalMembership = updateLocalMembership;

    return service;

    //
    // Return a boolean describing whether or not this session has a signed in
    // user.
    //
    function _isSignedIn () {
      return (
        service.session &&
        service.session.user &&
        service.session.user.id &&
        service.session.user.email !== 'guest_user@sowingo.com' // TODO get rid of this guest user mumbo jumbo
      );
    }
    function isSignedIn (async) {
      if(async){
        return _waitForInitialized().then(function (session) {
          return _isSignedIn();
        });
      }else{
        return _isSignedIn();
      }
    }

    //
    // Return a boolean describing whether or not the user has any office
    // memberships.
    //
    function hasOfficeMemberships () {
      var count = _.get(service, 'session.memberships.office.length') || 0;
      return count > 0;
    }

    //
    // Return the list of office memberships
    //
    function getOfficeMemberships () {
      return _.get(service.session, 'memberships.office');
    }

    //
    // A wrapper around $broadcast to simply calls.
    //
    function _broadcast (eventName, data, dontSend) {
      if (!dontSend) {
        $rootScope.$broadcast(eventName, data);
        sowInteropService.broadcastToTabs(eventName, data);
      }
    }

    //
    // Set Session (private)
    //
    function _setSession (session, options) {
      options = options || {};
      if(session === service.session){
        return;
      }

      service.session = session;
      $rootScope.current_session = session;
      localStorageService.set('session', session);

      //
      // TODO push this processing to the endpoint.
      //
      removeInactiveOfficeMemberships();

      //
      // Update the token & expiry
      //
      apiTokenService.setToken(session.token, session.token_expiry);

      //
      // If the service wasn't initialized before, it is now.
      //
      service.initialized = true;

      sowAnalyticsService.setSession(session);
    }

    //
    // Set Session (public)
    //
    function setSession (session, options) {
      options = options || {};

      var sessionId = _.get(service, 'session.id');
      var isNewSession = (service.session && sessionId !== session.id);
      if (_isSignedIn() === false) {
        _broadcast(appEvents.loggedOutSession, service.session, options.noEvent);
      }

      _setSession(session, options);
      _broadcast(appEvents.setSession, service.session, options.noEvent);
      _broadcast(appEvents.newSession, service.session, options.noEvent || !isNewSession);
    }

    //
    // Clear the session
    //
    function clearSession () {
      service.session = null;
      $rootScope.current_session = null;
      apiTokenService.clearToken();
      _broadcast(appEvents.clearSession);
      service.initialized = true;
    }

    //
    // Cleanup the Session Object
    //
    // This just remove inactive offices from the office memberships list.
    //
    function removeInactiveOfficeMemberships () {
      var session = service.session;

      if (_.get(session, 'memberships.office.length') < 1) {
        return;
      }

      for (var i = session.memberships.office.length - 1; i >= 0; i--) {
        if (!session.memberships.office[i].office.is_active) {
          // Splicing out inactive offices. Easiest way to do this, short of
          // modifying the endpoint.
          session.memberships.office.splice(i, 1);
        }
      }
    }
    
    //
    // Update account, membership, and office flags
    //
    function updateFlag (flag, value) {
      var flags = {};
      flags[flag] = value;
      return elementService.callEndpoint('session', {
        'endpoint': 'update_flags',
        'flags' : flags
      }).then(function(response){
        return refreshSession();
      }).catch(function (error) {
        throw 'There was an error updating your session. Please try again later.';
      });
    }

    //
    // Convience function for waiting for initialized
    //
    function _waitForInitialized () {
      if (service.initialized) {
        return $q.resolve(service.session);
      } else {
        var deferred = $q.defer();
        var stopWatching = $rootScope.$watch(function() {
          return service.initialized;
        }, function (newSessionInitializedValue, oldSessionInitializedValue) {
          if (newSessionInitializedValue) {
            deferred.resolve(service.session);
            stopWatching();
          }
        });

        return deferred.promise;
      }
    }

    //
    // async option waits for the service to be initialized and returns a promise.
    //
    function get (async) {
      if (async) {
        return _waitForInitialized().then(function (session) {
          return session;
        });
      } else {
        return service.session;
      }
    }

    //
    // Fetch the Current Session (private)
    //
    function _fetchSession () {
      return elementService.get('session', null, {
        'endpoint': 'current',
        'return_type': 'single',
        'extended': useExtendedSession
      })
      .then(null, function (error) {
        throw 'There was an error loading your current session. Please try again later.';
      });
    }

    //
    // Migrated from authentication service. I think it makes more sense here atm
    // but could be revised. TODO re-evaluate.
    //
    function login (account, options) {
      options = options || {};

      var token = options.token;
      var invite_token = options.invite_token;

      return elementService.get('session', null, {
        token: token,
        invite_token: invite_token,
        account: account,
        endpoint: 'login',
        return_type: 'single'
      }).then(function (session) {
        setSession(session, {noEvent: options.noEvent});

        //
        // Handle the "no office memberships" case. We need something to
        // trigger during the login action, because the other code only
        // triggers on state changes (and login doesn't necessarily imply a
        // state change).
        //
        // Only do this if:
        //
        // - We did _not_ pass the 'noEvent' option.
        //
        // - We're not on app.registration.start. When a login is triggered
        //   from there, we handle redirects manually.
        //
        // - This session contains no active office memberships.
        //
        // - There is no current $state transition in progress.
        //
        // - The current state doesn't have the 'no_office_whitelisted' flag set to true.
        //
        if (!options.noEvent &&
            $state.current.name !== 'app.registration.start' &&
            !hasOfficeMemberships() &&
            !$state.transition &&
            !_.get($state.current, 'data.no_office_whitelisted')) {

          $log.warn('DEBUG: No Office Memberships. Redirecting to state "app.registration.account_created".');
          $state.go('app.registration.account_created');

        }

        return session;
      }).catch(function (error) {
        const error_message = $filter('translate')('ERRORS.INCORRECT_EMAIL_OR_PASSWORD');
        if (_.get(error, 'status') === 401) {
          errorService.uiWarningHandler(error_message, null, "high");
        }
        else if (error === 'Unauthorized') {
          errorService.uiWarningHandler(error_message, null, "high");
        }
        else if (angular.isString(error)) {
          errorService.uiWarningHandler(error, null, "high");
        }
        else {
          errorService.showErrorToast('Server Error. (╯°□°）╯︵ ┻━┻');
        }

        if (error.status !== 0 && checkNested(error, 'data')) {
          throw error.data;
        } else {
          throw error;
        }
      });
    }

    //
    // Refreshes the global session and user. Used mostly for auto-login.
    //
    function refreshSession () {
      return _fetchSession().then(function (session) {
        if (session && session.id && session.user_id) {
          setSession(session);
        } else {
          clearSession();
        }

        return session;
      });
    }

    //
    // Sign Out Session
    //
    function logout () {
      return elementService.callEndpoint('session', {
        endpoint: 'logout'
      }).then(null, function (error) {
        errorService.errorHandler(error || "Error attempting logout");
      })['finally'](function () {
        clearSession();
      });
    }

    //
    // Convenience function to check if the session is signed in.
    // TODO migrate to only have 1 isActive isLoggedIn function.
    //
    function isActive () {
      return !!$rootScope.current_account;
    }

    /**
     * Updates the current session's membership ID and handles any errors that occur.
     * @param membership_id 
     * @returns {Promise<void>}
     */
    function updateCurrentSessionMembershipId (membership_id) {
      return elementService.callEndpoint('membership', {
        endpoint: 'set_current',
        membership_id,
        return_type: 'single',
        doUpdate: true
      }).then(function (membership) {
        updateLocalMembership(membership);
      }).catch(function (error) {
        errorService.uiErrorHandler(error || 'Unable to Select Office.', 0);
        throw error;
      });
    }

    /**
     * Updates the local membership data and user permissions, and broadcasts an event to
     * notify other components of the change.
     * @param {object} membership
     */
    function updateLocalMembership (membership) {
      $rootScope.current_membership = membership;
      $rootScope.user_permissions = {};
      _.each(membership.user_permissions, function(permission_key){
        $rootScope.user_permissions[permission_key] = true;
      });
      if(_.get($rootScope, 'user_permissions.master_permission', false)){
        _.each(sowUserAdminDataService.USER_PERMISSIONS.master, function(permission_key){
          $rootScope.user_permissions[permission_key] = true;
        });
      }
      const currentSession = get();
      const session_country = currentSession.country;
      const membership_country = _getCountryFromMembership(membership);

      // Only update the session country if it is different from the membership
      if (_hasDifferentCountry(session_country, membership_country)) {
        currentSession.country = membership_country;
      }

      currentSession.current_membership = membership;
      localStorageService.set('session', currentSession);
      $rootScope.$broadcast('membershipService: set-membership', membership);
    }

    function _getCountryFromMembership (membership) {
      return membership.office.address.country;
    }

    /**
     * Checks if the session country and membership country have different IDs.
     * @param {{id: string, name: string}} session_country
     * @param {{id: string, name: string}} membership_country 
     * @return {boolean}
     */
    function _hasDifferentCountry (session_country, membership_country) {
      return session_country.id !== membership_country.id;
    }

    //
    // Initialize sessionService
    //
    function initialize () {
      const urlHasToken = Boolean(generalUtils.getValueFromUrlParams('userToken'));

      return _fetchSession().then(function (session) {
        _setSession(session);
        accountService.initialize(session);

        //
        // Check if the user has any office memberships. If not, then we
        // redirect them away.
        //
        var officeMemberships = _.get(session, 'memberships.office');

        if (isSignedIn()){
          if(!officeMemberships || officeMemberships.length < 1) {
            $state.go('app.registration.account_created');
          }
        }
        _broadcast(appEvents.setSession, service.session);
        _broadcast(appEvents.newSession, service.session);

        if (urlHasToken) {
          updateCurrentSessionMembershipId(session.current_membership.id)
        }

        zendeskChatService.updateZendeskFormWithUserInfo(service.session);
        return service.session;
      }).catch(function(){
        accountService.initialize();
        return clearSession();
      });
    }
  }

}());

(function () {
  'use strict';

  const mktOrderBody = {
    restrict: 'E',
    selector: 'mktOrderBody',
    controller: 'mktOrderBodyCtrl',
    controllerAs: 'obCtrl',
    templateUrl: 'sow-mkt/directives/mkt-order-body.html',
    bindToController: true,
    scope: {
      allOrders: '='
    },
  }

  function mktOrderBodyDirective () {
    return mktOrderBody;
  }

  /** @ngInject */
  class mktOrderBodyCtrl {
    constructor(mktHelperService, membershipService) {
      this.mktHelperService = mktHelperService;
      this.membershipService = membershipService;
    }

    $onInit() {
      this.defineLocks();
    }

    $doCheck() {
      this.generateSummary(this.allOrders);
    }
    
    generateSummary (orders_list) {
      this.summary = this.mktHelperService.getOrdersSummary(orders_list);
    }

    goToOrder (order) {
      if (this.isOrderLinkDisabled(order)) {
        return;
      }
      return this.mktHelperService.goToOrder(order);
    }

    /**
     * If the order type is a purchase order, return the value of the disable_purchase_orders variable.
     * If the order type is a marketplace order, return the value of the disable_marketplace_orders
     * variable. Otherwise, return false.
     * @param order - The clicked order object
     * @returns A boolean value indicating whether the link is disabled.
     */
    isOrderLinkDisabled(order) {
      switch (order?.type) {
        case 'purchase_order':
          return this.disable_purchase_orders;
        case 'marketplace_order':
          return this.disable_marketplace_orders;
      }
      return false;
    }

    defineLocks() {
      const membership = this.membershipService.get();

      const master_permission_condition = _.values(membership?.user_permissions).includes('master_permission');

      const orders_permission_condition = _.values(membership?.user_permissions).includes('marketplace_orders_purchase_orders');
      const orders_access_condition = orders_permission_condition || master_permission_condition;

      const disable_po_condition = _.get(membership, 'user_properties.permission_overrides.disable_purchase_orders', false);
      this.disable_purchase_orders = !orders_access_condition || disable_po_condition;

      const disable_mkt_orders_condition = _.get(membership, 'user_properties.permission_overrides.disable_marketplace_orders', false);
      this.disable_marketplace_orders = !orders_access_condition || disable_mkt_orders_condition;
    }
  }

  angular.module('sowMarketplace')
    .controller(mktOrderBody.controller, mktOrderBodyCtrl)
    .directive(mktOrderBody.selector, mktOrderBodyDirective);

})();

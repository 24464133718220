(function () {
    'use strict';
    angular.module('sowAnalytics', [])
    .service('sowAnalyticsService', sowAnalyticsService);

    function sowAnalyticsService ($window, AnalyticsHelperService) {
      /*jshint validthis: true */
      var service = this;

      service.dataLayer = $window.dataLayer = $window.dataLayer || [];

      service.logEvent = logEvent;
      service.logPageView = logPageView;
      service.logAction = logAction;

      // Marketplace functions
      service.logMktProductClicked = logMktProductClicked;
      service.logMktListAddToCart = logMktListAddToCart;
      service.logMktSlideoutAddToCart = logMktSlideoutAddToCart;
      service.logAddToCartQtyChange = logAddToCartQtyChange;
      service.logAddToCartDone = logAddToCartDone;
      service.logAddToCartCancel = logAddToCartCancel;
      service.logEmptySearch = logEmptySearch;

      // GTM standards
      service.setOffice = setOffice;
      service.setSession = setSession;
      service.logAddToCart = logAddToCart;
      service.logBeginCheckout = logBeginCheckout;
      service.logCheckout = logCheckout;
      service.logRemoveFromCart = logRemoveFromCart;
      service.logProductDetails = logProductDetails;
      service.logViewItemList = logViewItemList;

      // Custom eCommerce
      service.logCartModalOpened = logCartModalOpened;
      service.logCartItemQuantityUpdate = logCartItemQuantityUpdate;
      service.logChangeSelectedVendor = logChangeSelectedVendor;
      service.logSelectListItem = logSelectListItem;
      service.logToggleFavourite = logToggleFavourite;

      // Shopping List
      service.shoppingListExportAndEmailClicked = shoppingListExportAndEmailClicked;
      service.shoppingListRemoveClicked = shoppingListRemoveClicked;
      service.shoppingListAddToCartClicked = shoppingListAddToCartClicked;
      service.shoppingListCancelClicked = shoppingListCancelClicked;
      service.shoppingListRemoveSelectedClicked = shoppingListRemoveSelectedClicked;
      service.shoppingListCreateOrderClicked = shoppingListCreateOrderClicked;
      service.shoppingListCreateOrderConfirmed = shoppingListCreateOrderConfirmed;
      service.shoppingListAddToOrderConfirmed = shoppingListAddToOrderConfirmed;

      // Purchase Order List View
      service.purchaseOrderFilterClicked = purchaseOrderFilterClicked;
      service.purchaseOrderSearchQuery = purchaseOrderSearchQuery;
      service.newPurchaseOrderClicked = newPurchaseOrderClicked;
      service.purchaseOrderDeleteClicked = purchaseOrderDeleteClicked;
      service.purchaseOrderDeleteConfirmed = purchaseOrderDeleteConfirmed;
      service.purchaseOrderRevertClicked = purchaseOrderRevertClicked;
      service.purchaseOrderKeepProductsClicked = purchaseOrderKeepProductsClicked;
      service.purchaseOrderEmailClicked = purchaseOrderEmailClicked;
      service.purchaseOrderEmailConfirmed = purchaseOrderEmailConfirmed;

      // Purchase Order Individual View
      service.purchaseOrderDuplicateClicked = purchaseOrderDuplicateClicked;
      service.purchaseOrderActionBarEmailClicked = purchaseOrderActionBarEmailClicked;
      service.purchaseOrderActionBarPrintClicked = purchaseOrderActionBarPrintClicked;
      service.purchaseOrderEditClicked = purchaseOrderEditClicked;
      service.purchaseOrderActionBarDeleteClicked = purchaseOrderActionBarDeleteClicked;

      // Purchase Order Table
      service.purchaseOrderUndoClicked = purchaseOrderUndoClicked;
      service.purchaseOrderUndoConfirmed = purchaseOrderUndoConfirmed;
      service.purchaseOrderCancelProductClicked = purchaseOrderCancelProductClicked;
      service.purchaseOrderCancelProductConfirmed = purchaseOrderCancelProductConfirmed;
      service.purchaseOrderEditDateClicked = purchaseOrderEditDateClicked;
      service.purchaseOrderEditDateConfirmed = purchaseOrderEditDateConfirmed;

      // Purchase Order Sales Rep
      service.purchaseOrderAddNameClicked = purchaseOrderAddNameClicked;
      service.purchaseOrderAddEmailClicked = purchaseOrderAddEmailClicked;
      service.purchaseOrderAddNumberClicked = purchaseOrderAddNumberClicked;

      // Billing Page
      service.billingAddPaymentMethodClicked = billingAddPaymentMethodClicked;
      service.billingAddressUnchecked = billingAddressUnchecked;
      service.billingAddressChecked = billingAddressChecked;
      service.billingAddPaymentMethodCancel = billingAddPaymentMethodCancel;
      service.billingSavePaymentMethodConfirmed = billingSavePaymentMethodConfirmed;
      service.billingCardMenuClickedOpen = billingCardMenuClickedOpen;
      service.billingCardMenuClickedClosed = billingCardMenuClickedClosed;
      service.billingCardMenuDeleteClicked = billingCardMenuDeleteClicked;
      service.billingCardMenuDeleteConfirmed = billingCardMenuDeleteConfirmed;
      service.billingCardMenuMakeDefault = billingCardMenuMakeDefault;
      service.billingOrdersLinkClicked = billingOrdersLinkClicked;
      
      // Order Review
      service.orderReviewAddPaymentMethodClicked = orderReviewAddPaymentMethodClicked;
      service.orderReviewCcAddressCheck = orderReviewCcAddressCheck;
      service.orderReviewCcAddressUncheck = orderReviewCcAddressUncheck;
      service.orderReviewPaymentMethodSelectorClicked = orderReviewPaymentMethodSelectorClicked;
      service.orderReviewAddPaymentMethodCancel = orderReviewAddPaymentMethodCancel;
      service.orderReviewSavePaymentMethodConfirmed = orderReviewSavePaymentMethodConfirmed;

      // Marketplace Orders
      service.logMarketplaceOrderDetailClicked = logMarketplaceOrderDetailClicked;
      service.logOrderRowClicked = logOrderRowClicked;
      service.logOrderItemRowClicked = logOrderItemRowClicked;
      service.logOrderFilterClicked = logOrderFilterClicked;
      service.logOrderItemFilterClicked = logOrderItemFilterClicked;
      service.logBuyAgainBtnClicked = logBuyAgainBtnClicked;
      service.logOrderSearchQuery = logOrderSearchQuery;
      service.logAwaitingShipmentsClicked = logAwaitingShipmentsClicked;
      service.logReceiveShipmentsClicked = logReceiveShipmentsClicked;

      // Registration 
      service.beginRegistration = beginRegistration;
      service.beginPasswordReset = beginPasswordReset;
      service.confirmPasswordReset = confirmPasswordReset;
      service.regUserInfo = regUserInfo;
      service.regSelectField = regSelectField;
      service.regSelectSpecialty = regSelectSpecialty;
      service.regOfficeInfo = regOfficeInfo;
      service.regVerifyAssociation = regVerifyAssociation;
      service.regSelectPlan = regSelectPlan;
      service.regInputPayment = regInputPayment;
      service.regRequestSupport = regRequestSupport;

      // Connected Suppliers
      service.logOpenDialogConnectVendor = logOpenDialogConnectVendor;

      return service;

      // first we set up GTAG with office identification only
      function setOffice(office) {
        if (office) {
          service.dataLayer.push({
            event: 'login',
            officeId: office.id,
          });
        }
      }


      // tip: when using `object.property.id`, always use lodash's get method, as in _.get(object, 'property.id', null) (falls back to null if any of the objects in the path do not exist)


      // then we got appcues and set it up with user-based identification
      // TO-DO: unify analytics identification process
      function setSession (session) {
        if(!$window.Appcues){
          return;
        }
        var office = _.get(session, "current_membership.office", null);
        var user = _.get(session, "user", null);
        var membership = _.get(session, 'current_membership', null);

        var trial_days_left = 0;
        var trial_end_date = _.get(office, "trial_ends_on",null);
        if ( moment.isDate(trial_end_date) ) {
          trial_days_left = _.toNumber(moment(trial_end_date).diff(moment(), "days"));
        }

        var user_data = {
          associationId: _.get(office, 'association_membership.professional_association_id', null),
          currentMarketplace: _.get(membership, 'current_marketplace.name', null),
          currentMarketplaceId: _.get(membership, 'current_marketplace.id', null),
          name: _.get(user, 'name', null),
          email: _.get(user, 'email', null),
          createdAt: (office && office.created_at) ? moment(office.created_at).format('x') : null,
          officeName: _.get(office, 'name', null),
          planType: _.get(office, 'account_type', null),
          role: _.get(membership, 'membership_type', null),
          title: _.get(membership, 'title', null),
          officeId: _.get(office, 'id', null),
          feature_flags: _.get(office, 'feature_flags', null),
          properties: _.get(office, 'properties', null),
          user_properties: _.get(membership, 'user_properties', null),
          userPermissions: _.get(membership, 'user_permissions', null),
          // note userId is a default Appcues property
          sowingoUserId: _.get(user, 'id', null),
          location: _.get(office, 'address.postal_code', null),
          premium_trial: _.get(office, 'flags.premium_trial', false),
          premium_plus_trial: _.get(office, 'flags.premium_plus_trial', false),
          trial_end_date: trial_end_date,
          trial_days_left: trial_days_left,
          province_id: _.get(office, 'address.province.id', null),
          country_id: _.get(office, 'address.country.id', null),
          office_group_id: _.get(office, 'office_group_id', null),

          // additional recommended user properties:
          // team: "Marketing" // or any other way you segment your users
          // version: "2.0" // users on different versions may need to see different content
          // featureFlag: "beta” // to communicate with users who have different features
          // language: "spanish" // for multi-language applications
          // renewalDate: "06-16-2019" // to remind users to renew
        };

        // userId will be defined as office membership Id
        // so that each user of each office can have triggers on Appcues
        var id = _.get(membership, 'id', null);
        $window.Appcues.identify(id, user_data);
      }

      // TO-DO: find a way to either clean the whole datalayer object
      // or have an interface for setting it up that fills all unused properties with null
      // because when we don't, data gets carried over to all subsequent events
      // eg. an 'empty-search' event data will still be present on all route-changes that happen after it on the same session

      function logEvent (event_name, event_properties) {
        service.dataLayer.push({
          event: 'app-event',
          attributes: {
            'event_name': event_name,
            'properties': event_properties
          }
        });
        
        $window.Appcues.track(event_name);
      }

      function logPageView () {
        service.dataLayer.push({
          event: 'app-route-change',
          attributes: {
            'parameters': toParams,
            'route': toState.name,
            'title': toState.data.title,
            'url': toState.url,
          }
        });

        $window.Appcues.page();
      }

      function logAction (action_name, action_params) {
        service.dataLayer.push({
          event: 'app-user-action',
          attributes: {
            'action_name': action_name,
            'parameters': action_params
          }
        });
      }

      // GA4 ecommerce events

      function logAddToCart (products) {
        AnalyticsHelperService.addEcommerceEvent('add_to_cart', products);
      }

      function logBeginCheckout(products, params) {
        AnalyticsHelperService.addEcommerceEvent('begin_checkout', products, params);
      }

      function logCheckout(products, params) {
        AnalyticsHelperService.addEcommerceEvent('purchase', products, params);
      }

      function logRemoveFromCart (products) {
        AnalyticsHelperService.addEcommerceEvent('remove_from_cart', products);
      }

      function logProductDetails(product, params) {
        AnalyticsHelperService.addEcommerceEvent('view_item', [product], params);
      }

      function logViewItemList(products, params) {
        AnalyticsHelperService.addEcommerceEvent('view_item_list', products, params);
      }

      // Custom ecommerce events

      function logCartModalOpened(product) {
        AnalyticsHelperService.addEcommerceEvent('cart_modal_opened', [product]);
      }

      function logCartItemQuantityUpdate(product) {
        AnalyticsHelperService.addEcommerceEvent('item_quantity_update', [product]);
      }

      function logChangeSelectedVendor (product, payload) {
        AnalyticsHelperService.addEcommerceEvent('change_selected_vendor', [product], payload);
      }

      function logSelectListItem (product, payload) {
        AnalyticsHelperService.addEcommerceEvent('select_list_item', [product], payload);
      }

      
      /**
       * Logs a toggle favourite event
       * @param {'add_to_wishlist'|'remove_from_wishlist'} event_name ecommerce event being tracked.
       * @param {object} product
       * @returns {void}
       */
      function logToggleFavourite(event_name, product) {
        // AnalyticsHelperService requires a categories array
        if (!product.categories) {
          product.categories = [product.parent_category, product.subcategory].filter(Boolean)
        }

        return AnalyticsHelperService.addProductInfoEcommerceEvent(event_name, [product]);
      }

      // Marketplace

      function logMktProductClicked (product) {
        return logAction('mkt-product-clicked', _.pick(product, ['name', 'id']));
      }

      function logMktListAddToCart (product) {
        return logAction('mkt-list-add', _.pick(product, ['name', 'id']));
      }

      function logMktSlideoutAddToCart (product) {
        return logAction('mkt-slideout-add', _.pick(product, ['name', 'id']));
      }

      function logAddToCartQtyChange (product) {
        return logAction('mkt-add-to-cart-qty-change', _.pick(product, ['name', 'id', 'qty', 'quantity']));
      }

      function logAddToCartDone (product) {
        return logAction('mkt-add-to-cart-done', _.pick(product, ['name', 'id', 'qty', 'quantity']));
      }

      function logAddToCartCancel (product) {
        return logAction('mkt-add-to-cart-cancel', _.pick(product, ['name', 'id', 'qty', 'quantity']));
      }

      function logEmptySearch (params) {
        return logEvent('empty-search-results', params);
      }

      // Shopping List

      function shoppingListExportAndEmailClicked() {
        return logAction('shopping-list-export-and-email-clicked');
      }

      function shoppingListRemoveClicked() {
        return logAction('shopping-list-remove-clicked');
      }

      function shoppingListAddToCartClicked() {
        return logAction('shopping-list-add-to-cart-clicked');
      }

      function shoppingListCancelClicked() {
        return logAction('shopping-list-cancel-clicked');
      }

      function shoppingListRemoveSelectedClicked() {
        return logAction('shopping-list-remove-selected-clicked');
      }

      function shoppingListCreateOrderClicked() {
        return logAction('shopping-list-create-order-clicked');
      }

      function shoppingListCreateOrderConfirmed() {
        return logAction('shopping-list-create-order-confirmed');
      }

      function shoppingListAddToOrderConfirmed() {
        return logAction('shopping-list-add-to-order-confirmed');
      }

      // Purchase Order List View

      function purchaseOrderFilterClicked(tag) {
        return logAction('purchase-order-filter-clicked', tag.value);
      }

      function purchaseOrderSearchQuery(query) {
        return logAction('purchase-order-search-query', query);
      }

      function newPurchaseOrderClicked() {
        return logAction('new-purchase-order-clicked');
      }

      function purchaseOrderDeleteClicked() {
        return logAction('purchase-order-delete-clicked');
      }

      function purchaseOrderDeleteConfirmed() {
        return logAction('purchase-order-delete-confirmed');
      }

      function purchaseOrderRevertClicked() {
        return logAction('purchase-order-revert-clicked');
      }

      function purchaseOrderKeepProductsClicked() {
        return logAction('purchase-order-keep-products-clicked');
      }

      function purchaseOrderEmailClicked() {
        return logAction('purchase-order-email-clicked');
      }

      function purchaseOrderEmailConfirmed() {
        return logAction('purchase-order-email-confirmed');
      }

      // Purchase Order Individual View

      function purchaseOrderDuplicateClicked() {
        return logAction('purchase-order-duplicate-clicked');
      }

      function purchaseOrderActionBarEmailClicked() {
        return logAction('purchase-order-action-bar-email-clicked');
      }

      function purchaseOrderActionBarPrintClicked() {
        return logAction('purchase-order-action-bar-print-clicked');
      }

      function purchaseOrderEditClicked(page) {
        return logAction('purchase-order-edit-clicked', page);
      }

      function purchaseOrderActionBarDeleteClicked() {
        return logAction('purchase-order-action-bar-delete-clicked');
      }

      // Purchase Order Table
      
      function purchaseOrderUndoClicked() {
        return logAction('purchase-order-undo-clicked');
      }

      function purchaseOrderUndoConfirmed() {
        return logAction('purchase-order-undo-confirmed');
      }

      function purchaseOrderCancelProductClicked() {
        return logAction('purchase-order-cancel-product-clicked');
      }

      function purchaseOrderCancelProductConfirmed() {
        return logAction('purchase-order-cancel-product-confirmed');
      }

      function purchaseOrderEditDateClicked() {
        return logAction('purchase-order-edit-date-clicked');
      }

      function purchaseOrderEditDateConfirmed() {
        return logAction('purchase-order-edit-date-confirmed');
      }

      // Purchase Order Sales Rep

      function purchaseOrderAddNameClicked() {
        return logAction('purchase-order-add-name-clicked');
      }

      function purchaseOrderAddEmailClicked() {
        return logAction('purchase-order-add-email-clicked');
      }

      function purchaseOrderAddNumberClicked() {
        return logAction('purchase-order-add-number-clicked');
      }

      // Billing Page

      function billingAddPaymentMethodClicked(params) {
        return logAction('billing-add-payment-method-clicked', params);
      }

      function billingAddressUnchecked() {
        return logAction('billing-address-unchecked');
      }

      function billingAddressChecked() {
        return logAction('billing-address-checked');
      }

      function billingAddPaymentMethodCancel() {
        return logAction('billing-add-payment-method-cancel');
      }

      function billingSavePaymentMethodConfirmed(plan_name) {
        return logAction('billing-save-payment-method-confirmed', plan_name);
      }

      function billingCardMenuClickedOpen() {
        return logAction('billing-card-menu-clicked-open');
      }

      function billingCardMenuClickedClosed() {
        return logAction('billing-card-menu-clicked-closed');
      }

      function billingCardMenuDeleteClicked(params) {
        return logAction('billing-card-menu-delete-clicked', params);
      }

      function billingCardMenuDeleteConfirmed(params) {
        return logAction('billing-card-menu-delete-confirmed', params);
      }

      function billingCardMenuMakeDefault() {
        return logAction('billing-card-menu-make-default');
      }

      function billingOrdersLinkClicked() {
        return logAction('billing-orders-link-clicked');
      }

      // Marketplace Orders 
      function logMarketplaceOrderDetailClicked (params) {
        return AnalyticsHelperService.addCustomEvent('view_mrkt_order', params);
      }

      function logOrderRowClicked (params) {
        return AnalyticsHelperService.addCustomEvent('select_mrkt_order', params);
      }

      function logOrderItemRowClicked (params) {
        return AnalyticsHelperService.addCustomEvent('select_mrkt_order_item', params);
      }

      function logOrderFilterClicked (params) {
        return AnalyticsHelperService.addCustomEvent('filter_mrkt_order_status', params);
      }

      function logOrderItemFilterClicked (params) {
        return AnalyticsHelperService.addCustomEvent('filter_mrkt_order_item_status', params);
      }

      function logBuyAgainBtnClicked (params) {
        const product_info = _parseBuyAgainProductInfo(params)
        return AnalyticsHelperService.addCustomEvent('click_buy_it_again', product_info);
      }

      function logOrderSearchQuery (params) {
        return AnalyticsHelperService.addCustomEvent('search_mrkt_order', params);
      }

      function logAwaitingShipmentsClicked () {
        return AnalyticsHelperService.addCustomEvent('view_awaiting_shipments');
      }

      function logReceiveShipmentsClicked (params) {
        return AnalyticsHelperService.addCustomEvent('receive_mrkt_order_shipment', params);
      }

      // Order Review

      function orderReviewAddPaymentMethodClicked(plan_name) {
        return logAction('order-review-add-payment-method-clicked', plan_name);
      }

      function orderReviewCcAddressCheck() {
        return logAction('order-review-cc-address-check');
      }

      function orderReviewCcAddressUncheck() {
        return logAction('order-review-cc-address-uncheck');
      }

      function orderReviewPaymentMethodSelectorClicked(params) {
        return logAction('order-review-payment-method-selector-clicked', params);
      }

      function orderReviewAddPaymentMethodCancel() {
        return logAction('order-review-add-payment-method-cancel');
      }

      function orderReviewSavePaymentMethodConfirmed(plan_name) {
        return logAction('order-review-save-payment-method-confirmed', plan_name);
      }

      // Registration 
      function beginRegistration() {
        return logAction('begin_registration', {step: '0'});
      }
      function beginPasswordReset() {
        return logAction('begin_password_reset');
      }
      function confirmPasswordReset() {
        return logAction('confirm_password_reset');
      }
      function regUserInfo(params) {
        return logAction('reg_user_info', { ...params, step: '1'});
      }
      function regSelectField(params) {
        return logAction('reg_select_field', {...params, step: '2'});
      }
      function regSelectSpecialty(params) {
        return logAction('reg_select_specialty', {...params, step: '3'});
      }
      function regOfficeInfo(params) {
        return logAction('reg_office_info', {...params, step: '4'});
      }
      function regVerifyAssociation(params) {
        return logAction('reg_verify_association', {...params, step: '5'});
      }
      function regSelectPlan(params) {
        return logAction('reg_select_plan',  {...params, step: '6'});
      }
      function regInputPayment(params) {
        return logAction('reg_input_payment',  {...params, step: '7'});
      }
      function regRequestSupport() {
        return logAction('reg_request_support');
      }

      // Connected Suppliers
      function logOpenDialogConnectVendor(params) {
        return AnalyticsHelperService.addCustomEvent('open_form_connect_vendor', params);
      }

      /**
       * Parses product information and returns an object with the item name, ID, brand, and price.
       * @param {object} params
       * @returns {{item_name: string, item_id: string, item_brand: string, price: number}}
       */
      function _parseBuyAgainProductInfo(params) {
        const {
          product: { name, id, manufacturer },
          effective_price,
          unit_price,
        } = params;
        const price = _.toNumber(effective_price || unit_price);

        return {
          item_name: name,
          item_id: id,
          item_brand: manufacturer.name,
          price,
        };
      }
    }

}());

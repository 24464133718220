(function () {
  'use strict';
  angular.module('sowOrders')
  .controller('OrdersListController', OrdersListController);

  function OrdersListController($scope, $rootScope, $state, $filter, orderService, sowOrderService, mktHelperService, sowApprovalService, AccessService, sowAnalyticsService) {
    /*jshint validthis: true */
    var ctrl = this;

    ctrl.current_tab = 'orders';
    ctrl.current_items_filter = 'all';
    ctrl.current_items_pages_loaded = 0;

    ctrl.current_orders_filter = 'all';
    ctrl.current_orders_pages_loaded = 0;

    ctrl.empty_state_messages = {
      "all": "ORDERS.EMPTY_MSG_ALL",
      "completed": "ORDERS.EMPTY_MSG_COMPLETED",
      "open": "ORDERS.EMPTY_MSG_OPEN",
      "backordered": "ORDERS.EMPTY_MSG_BACKORDERED",
      "processing": "ORDERS.EMPTY_MSG_PROCESSING",
      "shipped": "ORDERS.EMPTY_MSG_SHIPPED",
      "cancelled": "ORDERS.EMPTY_MSG_CANCELLED",
      "returned": "ORDERS.EMPTY_MSG_RETURNED"
    };

    ctrl.filter_pills = {
      "all": "ORDERS.ALL",
      "open": "COMMON.OPEN",
      "completed": "ORDERS.COMPLETED",
      "cancelled": "ORDERS.CANCELLED",
    };

    ctrl.isCurrentTab = isCurrentTab;
    ctrl.changeTab = changeTab;
    ctrl.isCurrentOrdersFilter = isCurrentOrdersFilter;
    ctrl.isCurrentItemsFilter = isCurrentItemsFilter;
    ctrl.changeOrdersFilter = changeOrdersFilter;
    ctrl.changeItemsFilter = changeItemsFilter;
    ctrl.applyFilters = applyFilters;
    ctrl.goToDetail = goToDetail;
    ctrl.updateSearch = updateSearch;
    ctrl.buyAgain = buyAgain;
    ctrl.receiveShipment = receiveShipment;
    ctrl.getEmptyTextOrders = getEmptyTextOrders;
    ctrl.getEmptyTextItems = getEmptyTextItems;
    ctrl.translateStatus = translateStatus
    ctrl.getFormattedTotalPrice = getFormattedTotalPrice
    ctrl.handleOrderIdClick = handleOrderIdClick;
    ctrl.handleShipmentClick = handleShipmentClick;
    ctrl.getStatusBadge = getStatusBadge;
    ctrl.getOrderItemQuantity = getOrderItemQuantity;
    ctrl.goToAwaitingShipmentPage = goToAwaitingShipmentPage;
    ctrl.$onInit = init;
    const MOBILE_BREAKPOINT = 600;

    return ctrl;

    function init () {
      defineLocks();
      $scope.$on('$destroy', exit);
      $scope.$on('mkt-shipment-updated', fetchPendingShipments);
      changeTab('orders');
      fetchOrdersList();
      fetchItemsList();
      fetchPendingShipments();
      initApprovalState();
      scrollWatch();
    }

    function exit () {
      scrollWatch(true);
      // $interval.cancel(ctrl.scroll_interval);
    }

    function scrollWatch (disable) {
      var container = document.getElementById("appContainer");
      if (!container) return;
      container.onscroll = disable ? null : handleScroll;
      // ctrl.scroll_interval = $interval(function(){
      //   handleScroll();
      // }, 800);
    }

    function handleScroll (event){
      // var footer = document.querySelector("footer.site-footer");
      var footer = document.getElementById("end-of-page");

      if(isInViewport(footer)){
        loadNextPage();
      }
    }


    function handleOrderIdClick(e, hrid) {
      e.stopPropagation();
      sowAnalyticsService.logMarketplaceOrderDetailClicked({order_hrid: hrid});
      $state.go('app.orders.detail', { hrid })
    }

    function handleShipmentClick(shipment) {
      const width = document.getElementById('appContainer').offsetWidth;
      // only open the slideout if viewed on mobile
      if (width <= MOBILE_BREAKPOINT) {
        receiveShipment(shipment);
      }
    }

    function isInViewport (elem) {
      var bounding = elem?.getBoundingClientRect();
      return (
          bounding &&
          bounding.top >= 0 &&
          bounding.left >= 0 &&
          bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
          bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
      );
    }

    function isCurrentTab (key) {
      return ctrl.current_tab === key;
    }

    function changeTab (key) {
      ctrl.current_tab = key;
      ctrl.search_query = null;
      updateSearch();
      // fetchOrdersList();
    }

    function isCurrentOrdersFilter (key) {
      return ctrl.current_orders_filter === key;
    }

    function isCurrentItemsFilter (key) {
      return ctrl.current_items_filter === key;
    }

    function changeOrdersFilter (key) {
      ctrl.current_orders_filter = key;
      // applyOrdersFilters();
      sowAnalyticsService.logOrderFilterClicked({filter_value: key});
      updateSearch();
    }

    function changeItemsFilter (key) {
      ctrl.current_items_filter = key;
      // applyItemsFilters();
      sowAnalyticsService.logOrderItemFilterClicked({filter_value: key});
      updateSearch();
    }

    function fetchPendingShipments () {
      return orderService.getPendingShipments()
      .then(function(response){
        ctrl.pending_shipments = response;

        // Set loading to false only when the page is 'Awaiting Shipment', 
        // the Orders tabs use the same controller, and we don't want to hide the loading indicator here.
        if (_isAwaitingShipmentPage()) {
          ctrl.loading = false;
        }
      });
    }

    function initApprovalState() {
      if (ctrl.mkt_approvals_enabled) {
        fetchPendingApprovalCount();
      }
    }

    function fetchPendingApprovalCount() {
      let pending_approval_count = 0;
      sowApprovalService.getPendingCount()
        .then(res => pending_approval_count = res || 0)
        .catch(err => errorService.uiErrorHandler(err))
        .finally(() => ctrl.pending_approval_count = pending_approval_count);
    }

    /**
     * This function navigates to the "awaiting shipment" page and logs an analytics event.
     */
    function goToAwaitingShipmentPage () {
      $state.go('app.orders.awaiting_shipment');
      sowAnalyticsService.logAwaitingShipmentsClicked();
    }

    function receiveShipment (shipment, $event) {
      $rootScope.$broadcast('order-receive-open', shipment);
    }

    function fetchOrdersList (params) {
      // temporary solution while list endpoint is not available
      // return searchOrders({'query': 'ODA', 'page': 1});
      params = params || {'page':1};
      if (ctrl.current_orders_filter !== 'all') {
        params.order_status = ctrl.current_orders_filter;
      }
      ctrl.current_items_parameters = params;
      ctrl.loading = true;
      return sowOrderService.listOrders(params)
      .then(updateLocalOrders);
    }

    function fetchItemsList (params) {
      params = params || {'page': 1};
      if (ctrl.current_items_filter !== 'all') {
        params.order_item_status = ctrl.current_items_filter;
      }
      ctrl.current_items_parameters = params;
      ctrl.loading = true;
      return sowOrderService.listItems(params)
      .then(updateLocalItems);
    }

    function updateSearch () {
      if(ctrl.current_tab === 'orders'){
        if(!ctrl.search_query){
          return fetchOrdersList();
        }
        ctrl.current_orders_filter = 'all';
        _logSearchEvent();
        return searchOrders({'query': ctrl.search_query, 'page': 1});
      }
      if(ctrl.current_tab === 'order_items'){
        if(!ctrl.search_query){
          return fetchItemsList();
        }
        ctrl.current_items_filter = 'all';
        _logSearchEvent();
        return searchItems({'query': ctrl.search_query, 'page': 1});
      }
    }

    function searchOrders (params) {
      // set the params for reuse on next page fetch
      _.set(params, 'query', ctrl.search_query);
      ctrl.current_orders_parameters = angular.copy(params);
      ctrl.loading = true;
      return sowOrderService.searchOrders(params)
      .then(updateLocalOrders);
    }

    function searchItems (params) {
      _.set(params, 'query_type', 'order_items');
      _.set(params, 'query', ctrl.search_query);
      ctrl.current_items_parameters = angular.copy(params);
      ctrl.loading = true;
      return sowOrderService.searchOrders(params)
      .then(updateLocalItems);
    }

    function loadNextPage () {
      if(ctrl.loading) return;

      if(ctrl.current_tab === 'orders' && ctrl.orders_metadata) {
        if(ctrl.orders_metadata.current_page >= ctrl.orders_metadata.total_pages ) return;
        var params = {
          'page': ctrl.orders_metadata.current_page + 1
        };
        if(!ctrl.search_query){
          return fetchOrdersList(params);
        } else {
          return searchOrders(params);
        }
      }

      if(ctrl.current_tab === 'order_items' && ctrl.items_metadata) {
        if(ctrl.items_metadata.current_page >= ctrl.items_metadata.total_pages ) return;

        var params = {
          'page': ctrl.items_metadata.current_page + 1,
          'query_type': 'order_items'
        };
        if(!ctrl.search_query){
          return fetchItemsList(params);
        } else {
          return searchItems(params);
        }
      }
    }

    function updateLocalOrders (response) {
      ctrl.orders_metadata = _.omit(response, ['results']);
      // handling multiple pages
      if(response.current_page === 1) {
        ctrl.orders_results = response.results;
      } else {
        ctrl.orders_results = _.concat(ctrl.orders_results, response.results);
      }

      applyOrdersFilters();
      _hideLoadingWhenTabSelected();
    }

    function updateLocalItems (response) {
      ctrl.items_metadata = _.omit(response, ['results']);
      // handling multiple pages
      if(response.current_page === 1) {
        ctrl.items_results = response.results;
      } else {
        ctrl.items_results = _.concat(ctrl.items_results, response.results);
      }
      
      applyItemsFilters();
      _hideLoadingWhenTabSelected();
    }

    /**
     * This function hides the loading spinner on the orders tab if the current page
     * is not the awaiting shipment page.
     */
    function _hideLoadingWhenTabSelected () {
      if (!_isAwaitingShipmentPage()) {
        ctrl.loading = false;
      }
    }

    function _isAwaitingShipmentPage () {
      return $state.is('app.orders.awaiting_shipment')
    }

    function applyFilters () {
      applyOrdersFilters();
      applyItemsFilters();
    }

    function applyOrdersFilters () {
      if(ctrl.current_orders_filter === 'all'){
        ctrl.display_orders = ctrl.orders_results;
        return;
      } else {
        ctrl.display_orders = _.filter(ctrl.orders_results, function(object){
          return _.lowerCase(object.status) === ctrl.current_orders_filter;
        });
      }
    }

    function applyItemsFilters () {
      if(ctrl.current_items_filter === 'all'){
        ctrl.display_items = ctrl.items_results;
        return;
      } else {
        ctrl.display_items = _.filter(ctrl.items_results, function(object){
          return _.lowerCase(object.status) === ctrl.current_items_filter;
        });
      }
    }

    function goToDetail (order) {
      _logOrderRowClick(order);
      return $state.go('app.orders.detail', {'order_data': order, 'hrid':order.hrid});
    }

    /**
     * Logs a row click event and sends relevant data to an analytics service based on the current tab.
     * @param {object} order
     * @returns {void}
     */
    function _logOrderRowClick (order) {
      const params = {
        order_hrid: order.hrid,
      }

      switch (ctrl.current_tab) {
        case 'orders':
          sowAnalyticsService.logOrderRowClicked(params);
          break;
        case 'order_items':
          sowAnalyticsService.logOrderItemRowClicked(params);
          break;
      }
    }

    function buyAgain (item, $event) {
      if($event) $event.stopPropagation();
      sowAnalyticsService.logBuyAgainBtnClicked(item);
      mktHelperService.productDetailsFetch(item.product.id);
    }

    function getEmptyTextOrders () {
      var msg = _.get(ctrl.empty_state_messages, ctrl.current_orders_filter, ctrl.empty_state_messages.all);
      msg = $filter('translate')(msg);
      return msg;
    }

    function getEmptyTextItems () {
      var msg = _.get(ctrl.empty_state_messages, ctrl.current_items_filter, ctrl.empty_state_messages.all);
      msg = $filter('translate')(msg);
      return msg;
    }

    /**
     * It takes an item object, and returns a formatted price string
     * @param item - The item object
     * @returns {string}
     */
    function getFormattedTotalPrice(item) {
      const price = item.effective_price || item.unit_price;
      const formatted_price = $filter('currency')(price, '$', 2);
      const prefix = mktHelperService.addPricePrefix(item.status);
      return `${prefix}${formatted_price}`;
    }

    function translateStatus (status) {
      if(!status){
        return '';
      }
      var key = "ORDERS."+_.toString(status).toUpperCase();
      return $filter('translate')(key);
    }

    /**
     * If the current filter is not 'all' or the search query is not empty, return the order quantity
     * by the order status (Backordered, Processing, Shipped, Returned, Cancelled). 
     * Otherwise, return the item quantity
     * @param {object} item
     * @return {number}
     */
    function getOrderItemQuantity (item) {
      if (ctrl.current_items_filter !== 'all' || ctrl.search_query) {
        return mktHelperService.getOrderItemQuantity(item);
      }
      
      return item.quantity;
    }

    /**
     * This function logs a search query for either orders or order items.
     */
    function _logSearchEvent () {
      const log_params = {
        query: ctrl.search_query,
        search_type: ctrl.current_tab === 'orders' ? 'order' : 'order item',
      }
      sowAnalyticsService.logOrderSearchQuery(log_params);
    }
    

    function defineLocks () {
      const mkt_approval_condition = AccessService.getProperty('orders.approval_req');
      ctrl.mkt_approvals_enabled = mkt_approval_condition;
      ctrl.hideCartButton = !AccessService.hasCartAccess();
      ctrl.has_inventory_access = AccessService.hasGeneralInventoryAccess();
    }

    /**
     * Returns a badge variation name mapped to the order's status.
     * @param status - The status of the order.
     * @returns The badge variation name.
     */
    function getStatusBadge (status) {
      const default_status = 'neutral';
      if (!status) return default_status;
      const status_key = _.lowerCase(_.first(_.words(status)));

      const badges = {
        open: 'information',
        completed: 'success',
        cancelled: 'critical',
      };

      return badges[status_key] || default_status;
    }

  }

}());

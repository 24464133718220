(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.inventory')
    .directive('editNewInventoryItem', EditNewInventoryItem);

  function EditNewInventoryItem () {
    return {
      'restrict': 'E',
      'replace': true,
      'templateUrl': 'templates/marketplace/inventory/modals/inventory-item-modal-content.html',
      'controller': 'editInventoryItemController'
    };
  }
}());

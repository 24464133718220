angular.module('app.marketplace.ui').directive('infoBar', function () {
  return {
    restrict: 'E',
    replace: true,
    transclude: true,
    scope: {},
    templateUrl: 'templates/marketplace/header/directives/info-bar.html',
    link: function ($scope, $element, $attrs) {
    },
    controller: function($scope, localStorageService){
      if(!$scope.$root.UI){
        $scope.$root.UI = {}; 
      }
      $scope.$root.UI.hideInfoBar = localStorageService.get('hideInfoBar');
      $scope.closeInfoBar = function(){
        localStorageService.set('hideInfoBar', true);
        $scope.$root.UI.hideInfoBar = true;
      };
    }
  };
});

(function () {
  'use strict';

  angular.module('sowAlerts')
    .directive('actionBarAlerts', alertsActionBarDirective);

  function alertsActionBarDirective() {
    return {
      restrict: 'AC',
      templateUrl: 'sow-alerts/directives/action-bar-alerts.html'
    };
  }

}());

(function () {
  'use strict';

  angular
    .module('app.shared.support')
    .config(disableAutoScrolling);

  //
  // Disable Auto-Scrolling via $anchorScroll
  //
  // We will auto-scroll with our own function.
  function disableAutoScrolling ($anchorScrollProvider) {
    $anchorScrollProvider.disableAutoScrolling();
  }

}());

(function () {
  'use strict';

  var modelData = {
    "params": {
      "model_name": "dailyRecord"
    }
  };

  var modelFields = {
    "id": {
      "display_name": "id",
      "required": true,
      "api": {
        "submit": true
      },
      "type": "integer"
    },
  };

  angular
    .module('app.marketplace.elements')
    .service('DailyRecordModel', DailyRecordModel);

  function DailyRecordModel(apiUrl) {
    /*jshint validthis: true */
    var model = this;

    model.data = modelData;
    model.fields = modelFields;
    model.autofill = autofill;
    model.model_data = {
      'name': 'dailyRecord',
      'display_name': 'Daily Record',
      'api': {
        'single': getDailyRecord,
        'create_record': createNewDailyRecord,
        'get_records': getDailyRecordList,
        'get_daily_status_report': getDailyStatusReport,
        'clear_data': clearDailyRecordData,
        'update_record': updateRecord,
        'collections_report': getCollectionsReport,
        'wellbeing_report': getWellBeingReport,
        'procedure_report': getProcedureReport,
        'production_report': getProductionReport,
        'financial_report': getFinancialReport,
      }
    };

    return model;

    //
    //
    //
    function autofill (purchase_order) {
    }

    //
    //
    //
    function getDailyStatusReport (options) {
      return '{0}/monitor/reports/daily-status'.format(apiUrl);
    }

    //
    // Create a new dailyRecord instance
    //
    function createNewDailyRecord (options) {
      if (!_.has(options, 'record')) {
        throw 'Missing record data. Cannot create a new record.';
      }

      return {
        'path': '{0}/monitor/records'.format(apiUrl),
        'params': {
          'method': 'POST',
          'data': options.record,
        }
      };
    }

    //
    //
    //
    function updateRecord (options) {
      if (!_.has(options, 'record.id')) {
        throw 'Cannot update a record with no ID.';
      }

      return {
        'path': '{0}/monitor/records/{1}'.format(apiUrl, options.record.id),
        'params': {
          'method': 'PUT',
          'data': options.record,
        }
      };
    }

    //
    // Fetch a single DailyRecord model by ID.
    //
    function getDailyRecord (options) {
      if (!_.has(options, 'data.id')) {
        throw 'Cannot fetch daily record without an ID.';
      }

      return {
        'path': '{0}/monitor/records/{1}'.format(apiUrl, options.data.id),
        'params': {'method': 'GET'},
      };
    }

    //
    // Clear data for a dailyRecord by ID.
    //
    function clearDailyRecordData (options) {
      if (!_.get(options, 'recordId')) {
        throw 'Cannot clear record data without a record ID.';
      }

      return {
        'path': '{0}/monitor/records/{1}'.format(apiUrl, options.recordId),
        'params': {'method': 'DELETE'},
      };
    }

    //
    //
    //
    function getDailyRecordList (options) {
      var get_params = options.filters || {};

      return {
        'path': '{0}/monitor/records'.format(apiUrl),
        'params': {
          'method': 'GET',
          'params': get_params,
        }
      };
    }

    //
    // Fetch the Production Report
    //
    function getProductionReport (options) {
      var fields = ['month', 'office_id'];
      var filters = _.pick(options.filters, fields);
      var requiredFields = ['month'];

      _.forEach(requiredFields, function (requiredField) {
        if (!_.has(filters, requiredField)) {
          throw 'Missing required field: {0}'.format(requiredField);
        }
      });

      return {
        'path': '{0}/monitor/reports/production'.format(apiUrl),
        'params': {
          'method': 'GET',
          'params': filters,
        },
      };
    }

    //
    // Fetch the Procedure Report
    //
    function getProcedureReport (options) {
      var fields = ['filter', 'date', 'office_id'];
      var filters = _.pick(options.filters, fields);
      var requiredFields = ['filter', 'date'];
      var filterValues = ['day', 'month', 'year'];

      _.forEach(requiredFields, function (requiredField) {
        if (!_.has(filters, requiredField)) {
          throw 'Missing required field: {0}'.format(requiredField);
        }
      });

      if (!_.includes(filterValues, filters.filter)) {
        throw 'Unknown Filter Value: {0}'.format(filters.filter);
      }

      return {
        'path': '{0}/monitor/reports/procedure'.format(apiUrl),
        'params': {
          'method': 'GET',
          'params': filters,
        },
      };
    }

    //
    // Fetch the Financial Report
    //
    function getFinancialReport (options) {
      var fields = ['filter', 'date', 'office_id'];
      var filters = _.pick(options.filters, fields);
      var requiredFields = ['filter', 'date'];
      var filterValues = ['day', 'month', 'year'];

      _.forEach(requiredFields, function (requiredField) {
        if (!_.has(filters, requiredField)) {
          throw 'Missing required field: {0}'.format(requiredField);
        }
      });

      if (!_.includes(filterValues, filters.filter)) {
        throw 'Unknown Filter Value: {0}'.format(filters.filter);
      }

      return {
        'path': '{0}/monitor/reports/financial'.format(apiUrl),
        'params': {
          'method': 'GET',
          'params': filters,
        },
      };
    }

    //
    // Fetch the Well-Being Report
    //
    function getWellBeingReport (options) {
      var fields = ['filter', 'date', 'office_id'];
      var filters = _.pick(options.filters, fields);
      var requiredFields = ['filter', 'date'];
      var filterValues = ['day', 'month', 'year'];

      _.forEach(requiredFields, function (requiredField) {
        if (!_.has(filters, requiredField)) {
          throw 'Missing required field: {0}'.format(requiredField);
        }
      });

      if (!_.includes(filterValues, filters.filter)) {
        throw 'Unknown Filter Value: {0}'.format(filters.filter);
      }

      return {
        'path': '{0}/monitor/reports/wellbeing'.format(apiUrl),
        'params': {
          'method': 'GET',
          'params': filters,
        },
      };
    }

    //
    // Fetch the Collections Report
    //
    function getCollectionsReport (options) {
      var fields = ['filter', 'date', 'office_id'];
      var filters = _.pick(options.filters, fields);
      var requiredFields = ['filter', 'date'];
      var filterValues = ['day', 'month', 'year'];

      _.forEach(requiredFields, function (requiredField) {
        if (!_.has(filters, requiredField)) {
          throw 'Missing required field: {0}'.format(requiredField);
        }
      });

      if (!_.includes(filterValues, filters.filter)) {
        throw 'Unknown Filter Value: {0}'.format(filters.filter);
      }

      return {
        'path': '{0}/monitor/reports/collections'.format(apiUrl),
        'params': {
          'method': 'GET',
          'params': filters,
        },
      };
    }
  }
}());

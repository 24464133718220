{
  /** @ngInject */
  class WorkflowsSectionCtrl {
    constructor($scope) {
      this.$scope = $scope;
    }

    $onInit() {
      // any initializer code can be set here
    }
    
  }

  angular.module('sowWorkflows')
    .controller('WorkflowsSectionController', WorkflowsSectionCtrl);
}

(function () {
  'use strict';

  angular
    .module('sowVimeo', ['app.shared.system.api'])
    .service('sowVimeoService', VimeoService);

  function VimeoService (serverAPI) {
    /*jshint validthis: true */
    var service = this;

    service._videos = null;
    service.getVideos = _getVideos;
    service.getVideo = _getVideo;

    return service;

    //
    // Fetch the list of public Sowingo videos on Vimeo
    //
    function _getVideos () {
      return serverAPI
        .doAPICall('https://vimeo.com/api/v2/sowingo/videos.json')
        .then(function (response) { return response.data; });
    }

    //
    // Fetch the metadata for a (public) Vimeo video by ID.
    //
    function _getVideo (videoId) {
      return serverAPI
        .doAPICall('https://vimeo.com/api/v2/video/{0}.json'.format(videoId))
        .then(function (response) {
          return response.data.length === 0 ? null : response.data[0];
        });
    }
  }
}());

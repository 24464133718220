(function () {
  'use strict';
  angular.module('sowMarketplace')
  .controller('MarketplaceManufacturersController', MarketplaceManufacturersController);

  function MarketplaceManufacturersController($state, errorService, sowMktService, $anchorScroll, $location, $window, adButlerService) {
    /*jshint validthis: true */
    var ctrl = this;
    ctrl.scrollTo = scrollTo;
    ctrl.goToSearch = goToSearch;

    init();

    return ctrl;
    function init () {
      loadData();

      _loadAds();
    }

    function _loadAds () {
      const is_mobile = $window.innerWidth < 960;
      const top_ad = is_mobile ? 'leaderboard1-scaled' : 'leaderboard1';
      const bottom_ad = is_mobile ? 'leaderboard2-scaled' : 'leaderboard2';
      adButlerService.autoLoadZone(top_ad);
      adButlerService.autoLoadZone(bottom_ad);
    }

    function scrollTo (id) {
      var newHash = id;
      if ($location.hash() !== newHash) {
        $location.hash(id);
      } else {
        $anchorScroll();
      }
    }

    function loadData () {
      ctrl.loading = true;
      return sowMktService.getManufacturers(true)
      .then(function(response){
        var list = _.get(response, 'manufacturers', []);
        ctrl.featured_items = _.filter( list, function(item){ return item.featured; } );

        var grouped_list = _.groupBy(list, function(manu){
          return manu.name.substr(0,1).toUpperCase();
        });
        grouped_list = _.map(grouped_list, function(list, letter){
          var chunk_size = Math.round(_.size(list) / 4) || 1;

          return {
            'letter': letter,
            'item_lists': _.chunk(list, chunk_size)
          };
        });
        ctrl.grouped_list = _.sortBy(grouped_list, ["letter"]);
      })
      .catch(function(error){
        errorService.uiErrorHandler(error);
      })
      .finally(function(){
        ctrl.loading = false;
      });
    }

    function goToSearch (manufacturer) {
      $state.go('app.mkt.search',{'mf_id':manufacturer.id, 'manufacturer': manufacturer, 'mf_name': manufacturer.name});
    }

  }

}());

(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.inventory')
    .service('AddLocationsDialog', AddLocationsDialog);

  function AddLocationsDialog ($mdDialog, $rootScope) {
    /*jshint validthis: true */
    var service = this;

    service.show = showDialog;

    return service;

    function showDialog (ev, locations) {
      $mdDialog.show({
        'controller': 'manageInventoryLocationController',
        'templateUrl': 'templates/marketplace/inventory/modals/manage-inventory-location.html',
        'targetEvent': ev,
        'parent': angular.element(document.body),
        'clickOutsideToClose': true,
        'locals': {
          'locations': locations,
        }
      })['finally'](function () {
        $rootScope.$broadcast('inventory_locations_updated');
      });
    }
  }
}());

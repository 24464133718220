(function () {
  'use strict';

  angular
    .module('app.marketplace.system')
    .directive('actionBarBlank', ActionBarBlank);

  function ActionBarBlank () {
    return {
      'restrict': 'AC',
      'template': '<div class="action-bar-content" layout layout-align="start center"></div>',
    };
  }
}());

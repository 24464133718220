(function(){
  'use strict';
  angular.module("app.marketplace.ui.implants")
    .directive('actionBarEditImplantTable',impEditTableActionBarDirective);

  function impEditTableActionBarDirective() {
    return {
      restrict: 'AC',
      templateUrl: 'templates/marketplace/implants/directives/action-bar-edit-implant-table.html',
      controller: 'impActionBarController',
      controllerAs: 'impAbCtrl'
    };
  }

}());

angular.module('app.marketplace.system')
  .run(initializeRedirectHandlers);

function initializeRedirectHandlers($rootScope, $log, $timeout, $state, RouteService, sessionService, RedirectModalService) {
  var lastDelay = null;
  var ignoreConfirmNavigation = null;


  $rootScope.$on('$stateChangeStart', function ($event, toState, toParams, fromState, fromParams, options) {
    var isSignedIn = sessionService.isSignedIn();
    var hasOfficeMemberships = sessionService.hasOfficeMemberships();
    var authorizationRequired = _.get(toState, 'data.authorization_required');

    ignoreConfirmNavigation = options && (options.ignoreConfirmNavigation === true || options.ignoreConfirmNavigation === false) ?
      options.ignoreConfirmNavigation : ignoreConfirmNavigation;

    if (!isSignedIn && toState.name === 'login') {
      ;
    }
    // Confirm navigation rules first----------------------------
    // Check for confirm_navigation data, check for unsaved forms, if so prevent default and show modal.
    else if (!ignoreConfirmNavigation && _.get(fromState, 'data.confirm_navigation')) {
      var confirm_navigation = _.get(fromState, 'data.confirm_navigation');
      var handleForm = function (confirm_navigation) {
        $event.preventDefault();
        RedirectModalService.show($event, confirm_navigation.type, confirm_navigation)
          .then(function (result) {
            ignoreConfirmNavigation = true;
            $state.go(toState.name, toParams, { 'reload': true });
          });
      };
      //Check for dirty forms. If dirty then show modal.
      if (confirm_navigation.forms) {
        _.map(confirm_navigation.forms, function (form_name) {
          if (!_.isString(form_name)) {
            return;
          }
          var form = angular.element("form[name=" + form_name + "]");
          if (form && form.length && form.scope()[form_name] && form.scope()[form_name].$dirty) {
            handleForm(confirm_navigation);
          }
        });
        //Check for elements to exist. If they do then show modal.
      } else if (confirm_navigation.element_exists) {
        _.map(confirm_navigation.element_exists, function (item) {
          var element = angular.element(item);
          if (element && element.length) {
            handleForm(confirm_navigation);
          }
        });
      }
    }

    // ----------------------------------------------------------

    //
    // Process any 'redirectTo' settings on the state.
    //
    else if (toState.redirectTo) {
      $log.debug('RouteService: Found "redirectTo" setting. Redirecting to %o', toState.redirectTo);
      $event.preventDefault();
      $state.go(toState.redirectTo, toParams);
    }

    //
    // If the user is signed in and has no office memberships, we only allow
    // them to go to routes specifically flagged as whitelisted. All other
    // routes are redirected to the "Account Created" registration page where
    // the user can select "Create Office" or "Join Office" to rectify the
    // situation.
    //
    else if (isSignedIn && !hasOfficeMemberships && !_.get(toState, 'data.no_office_whitelisted')) {
      $log.debug('RouteService: User has no office memberships. Redirecting to: app.registration.account_created');
      $event.preventDefault();

      if (fromState.name !== 'app.registration.account_created') {
        $state.go('app.registration.account_created');
      }
    }

    //
    // If the route says that signed in users should be redirected away, then
    // we process that here.
    //
    else if (isSignedIn && _.get(toState, 'data.redirect_logged_in')) {
      $log.debug('RouteService: Found "redirect_signed_in" setting. User is signed in. Redirecting to default.');
      $event.preventDefault();
      RouteService.goToDefault();
    }

    //
    // If the route says that it requires users to be logged in, check status then redirect if necessary.
    //
    else if (_.get(toState, 'data.require_log_in') && !isSignedIn) {
      $event.preventDefault();
      sessionService.isSignedIn(true)
        .then(function (newIsSignedIn) {
          isSignedIn = newIsSignedIn;
          if (isSignedIn) {
            $state.go(toState.name, toParams);
          } else {
            $log.debug('RouteService: Found "require_log_in" setting. User is not signed in. Redirecting to login required state.');
            $state.go('required_login', { 'toState': toState.name, 'toParams': JSON.stringify(toParams) });
          }
        });
    }
  });

  //Reset variables
  $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
    ignoreConfirmNavigation = false;
  });
  $rootScope.$on('$stateChangeError', function (event, toState, toParams, fromState, fromParams) {
    ignoreConfirmNavigation = false;
  });

}
(function () {
  'use strict';

  const sowIconBtn = {
    selector: 'sowIconBtn',
    controller: 'sowIconBtnCtrl',
    controllerAs: 'iconBtnCtrl',
    templateUrl: 'sow-core-ui/components/sow-icon-btn.html',
    bindings: {
      tooltipText: '@',
      iconSrc: '@',
      isLoading: '<',
      clickHandler: '&',
    },
  }

  /** @ngInject */
  class sowIconBtnCtrl {
    constructor() {

    }

    $onInit() {

    }

  }

  angular.module('sowCoreUI')
    .controller(sowIconBtn.controller, sowIconBtnCtrl)
    .component(sowIconBtn.selector, sowIconBtn);

})();

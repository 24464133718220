(function () {
  'use strict';

  angular.module('sowCertifications')
    .service('certificationDataCarrierService', certificationDataCarrierService)

  function certificationDataCarrierService() {
    /*jshint validthis: true */
    var service = this;

    service.has_category = has_category;
    service.set_category = set_category;
    service.is_loading = true;
    service.allow_records = false;
    service.category = {
      license: false,
      equipment: false,
      permit: false
    };

    return service;

    function has_category(type) {
      return service.category[type];
    }
    function set_category(type, val) {
      service.category[type] = val;
    }
  }

}());

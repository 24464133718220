(function () {
  'use strict';
  angular.module('sowMarketplace')
  .controller('mktCartPermissionController', mktCartPermissionController);

  function mktCartPermissionController ($scope, $rootScope, $mdDialog, sgToast, errorService, cartService, sowMktService) {
    /*jshint validthis: true */
    var ctrl = this;

    ctrl.current_step = 1;
    ctrl.auth_type = null;
    ctrl.is_authorized = false;
    ctrl.busy = false;

    ctrl.nextStep = nextStep;
    ctrl.previousStep = previousStep;
    ctrl.authorize = authorize;
    ctrl.cancel = $mdDialog.cancel;

    init();

    return ctrl;

    function init () {
      loadUsers();
    }

    function loadUsers () {
      return sowMktService.getAuthUsers()
      .then(function(users_response){
        ctrl.users_list = users_response;
      });
    }

    function nextStep () {
      if (ctrl.current_step === 1) {
        switch (ctrl.auth_data.auth_type) {
          case "email":
            ctrl.is_authorized ? showEmailSent() : authorize();
            break;
          case "password":
            ctrl.current_step = 2;
            break;
          case "pin":
            // TO-DO
            break;
          default:
            return;
        }
        return;
      }
      if (ctrl.current_step === 2) {
        // showEmailSent();
        if(ctrl.is_authorized) {
          return respondAuthPassed();
        }

        return;
      }
    }

    function respondAuthPassed () {
      $mdDialog.hide(true);
    }

    function showEmailSent () {
      ctrl.current_step = 3;
    }

    function authorize () {
      if (ctrl.authForm.$invalid) {
        return;
      } 
      ctrl.busy = true;
      return sowMktService.sendAuthData(ctrl.auth_data)
      .then(function(response){
        // 403 erros wind up here, somehow
        var right_password = (_.isBoolean(response) && !!response);
        var email_sent = (response.success);
        var no_errors = (!response.error);
        var conditions = (right_password || email_sent) && no_errors;
        if (conditions){
          ctrl.is_authorized = true;
          ctrl.nextStep();
        } else {
          // error toast, maybe?
        }
      })
      .catch(function(error){
        // for some reason, this method never catches an error
      })
      .finally(function(){
        ctrl.busy = false;
      });
    }

    function previousStep () {
      if(ctrl.current_step > 1){
        ctrl.current_step--;
      }
    }

  }
}());
(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.subscriptions')
    .controller('PlanFeaturesCtrl', PlanFeaturesCtrl);

  function PlanFeaturesCtrl ($scope, $timeout) {
    /*jshint validthis: true */
    var ctrl = this;

    $scope.expand = expandFeaturesList;

    ctrl.defaultClasses = '';
    ctrl.init = init;

    return ctrl;

    //
    // Initialize controller
    //
    function init (options) {
      $scope.heading = options.heading || '';
      $scope.subtitle = options.subtitle || '';
      $scope.features = options.features || [];
      $scope.listClasses = ctrl.defaultClasses = options.defaultClasses;
      $scope.$watch('isLarge', updateState);
    }

    //
    // Update the current state
    //
    function updateState () {
      var isLarge = $scope.isLarge ? $scope.$parent.$eval($scope.isLarge) : false;
      $scope.listClasses = ctrl.defaultClasses + (isLarge ? ' plan-large' : '');
    }

    //
    // Expand the Features List
    //
    function expandFeaturesList ($event) {
      $scope.isCollapsed = false;
      scrollToBottom($event);
    }

    //
    // XXX This is hard-coded for a dialog, but we need to make this more
    //     robust for other areas.
    //
    function scrollToBottom ($event) {
      var clickedElement = $($event.target);
      var scrollPane = $('md-dialog-content');
      var topOfClickedElement = clickedElement.offset().top - parseInt(clickedElement.css('margin-top'));
      var topOfScrollPane = scrollPane.scrollTop() - scrollPane.offset().top;
      var offset = topOfScrollPane + topOfClickedElement;

      $timeout(function () {
        scrollPane.animate({'scrollTop': offset});
      }, 50);
    }
  }
}());

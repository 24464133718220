(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.legal')
    .directive('legalDisclaimer', legalDisclaimer);

  function legalDisclaimer (legalService) {
    return {
      'restrict': 'E',
      'replace': true,

      'template': [
        '<div class="legal-disclaimer">',
        '  {{\'SIGNUP.ACC.CONTINUE\' | translate}}',
        '  <a name="legalDisplaimer-link-showTermsModal" ng-click="showTerms($event)">{{\'COMMON.TERMS\' | translate}}</a> {{\'COMMON.AND\' | translate}}',
        '  <a name="legalDisclaimer-link-showPrivacyPolicyModal" ng-click="showPrivacyPolicy($event)">{{\'COMMON.PRIVACY_POLICY\' | translate}}</a>.',
        '</div>',
      ].join("\n"),

      'link': function ($scope) {
        $scope.showTerms = legalService.showTermsModal;
        $scope.showPrivacyPolicy = legalService.showPrivacyPolicyModal;
      }
    };
  }

}());

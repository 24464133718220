(function () {
    'use strict';
    angular.module('sowSales', [])
    .config(sowSalesConfig);

    function sowSalesConfig ($stateProvider, $urlRouterProvider) {
      var defaultUrl = '/app/sales/landing/';
      $urlRouterProvider.when('/app/sales/', defaultUrl);
      $urlRouterProvider.when('/app/sales', defaultUrl);

      $stateProvider
        .state('app.sales', {
          url: '/sales',
          templateUrl: 'sow-sales/views/sales-index.html',
          controller: 'SalesSectionController',
          controllerAs: 'salesSecCtrl',
          data: {
            'title': 'Sales',
            'action_bar_directive': 'action-bar-mkt',
            'require_log_in' : true,
          },
          redirectTo: 'app.sales.landing'
        })
        .state('app.sales.landing', {
          url: '/landing/:sale_filter/?query&cat_id',
          templateUrl: 'sow-sales/views/sales-landing.html',
          controller: 'SalesLandingController',
          controllerAs: 'saleslCtrl',
          params:{
            sale_filter:'sales_all',
            query: null,
            cat_id: null,
          },
          data: {
            'contractSidenav': false,
          }
        })
        ;
    }

}());

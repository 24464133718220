angular.module("app.shared.support")
  .directive('selectOnClick', function ($window, $timeout) {
    return {
      restrict: 'A',
      link: function (scope, element, attrs) {
        element.on('focus', function ($event) {
          $timeout(function () {
            if (scope.$root.isChrome) {
              element[0].select();
            } else {
              element[0].setSelectionRange(0, 9999);
            }
          });
        });
      }
    };
  });
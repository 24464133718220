(function () {
  'use strict';

  angular.module('sowProduct')
    .controller('pdImagesController', pdImagesController)
    .directive('pdImages', pdImagesDirective);

  function pdImagesDirective() {
    return {
      restrict: 'E',
      templateUrl: 'sow-product-details/directives/pd-images.html',
      controller: 'pdImagesController',
      controllerAs: 'pdImagesCtrl',
      scope: {
        product: '='
      }
    };
  }

  /**
   * This is being used for both the product images inline directive and the image gallery dialog (pop-up)
   *
   */
  function pdImagesController(productDetailsService, $scope, $mdDialog, $timeout, NoImageUrl, sowLanguageService) {
    var ctrl = this;

    ctrl.ZOOM_ELEMENT = "gallery-img-container";
    ctrl.ZOOM_OPTIONS = {
      "offset": {vertical: 0, horizontal: 10},
      // "scale": "2.0",
      // "width": 400,
      "zoomPosition": "original",
      // "zoomWidth": 700
    };
    ctrl.current_index = 0;

    ctrl.openGalleryDialog = productDetailsService.openGalleryDialog;
    ctrl.toggleFavourite = productDetailsService.toggleFavourite;
    ctrl.closeGallery = closeGallery;
    ctrl.isFirstImageSelected = isFirstImageSelected;
    ctrl.isLastImageSelected = isLastImageSelected;
    ctrl.nextPic = nextPic;
    ctrl.previousPic = previousPic;
    ctrl.selectImage = selectImage;
    ctrl.checkForMainImage = checkForMainImage;

    init();

    return ctrl;

    function init() {
      // assign scope variable to controller in order to use on both directives
      ctrl.product = ctrl.product || $scope.product;
      ctrl.language = sowLanguageService.getCurrentLanguage();
      checkForEmptyImages();
      checkForMainImage();
      // setupZoom();
    }

    /**
     * check for the gallery boolean and activate the image-zoom library
     *
     */
    function setupZoom () {
      if(ctrl.gallery){
        $timeout(function(){
          new ImageZoom(document.getElementById(ctrl.ZOOM_ELEMENT), ctrl.ZOOM_OPTIONS);
        }, 1000);
      }
    }

    function isFirstImageSelected () {
      return ctrl.current_index === 0;
    }


    function isLastImageSelected () {
      return ctrl.current_index === ctrl.product.images.length-1;
    }


    function previousPic () {
      selectImage(ctrl.current_index-1);
    }

    function nextPic () {
      selectImage(ctrl.current_index+1);
    }

    function selectImage (new_index) {
      var previous_index = angular.copy(ctrl.current_index);
      // setAnimationState(new_index, previous_index);
      horizontalScroll(new_index, previous_index);
      var index_image = _.get(ctrl.product, 'images['+new_index+']', null);
      ctrl.current_index = new_index;
      ctrl.current_image = _.get(index_image, 'image', null);
    }

    function horizontalScroll (new_index, previous_index) {
      var scroll_element = document.querySelector('#pd-image-scroll');
      scroll_element.scrollLeft = (scroll_element.clientWidth*new_index);
    }
    
    /**
     * every image inside `product.images` has a `main_image_bool` which if true should be displayed as selected every time
     *
     */
    function checkForMainImage () {
      var main_found = false;
      _.each(ctrl.product.images, function(item, index){
        if (item.main_image_bool) {
          main_found = true;
          $timeout(function(){
            selectImage(index);
          }, 1000);

        }
      });
      if (!main_found) {
        selectImage(0);
      }
    }

    function checkForEmptyImages () {
      if (_.isEmpty(ctrl.product.images)){
        _.set(ctrl.product, 'images', [{'image': NoImageUrl}])
      }
    }

    function closeGallery () {
      return $mdDialog.hide();
    }

  }

})();

(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.subscriptions')
    .directive('subscriptionPlanStyleA', subscriptionPlanStyleA);

  function subscriptionPlanStyleA () {
    return {
      'restrict': 'E',
      'replace': true,
      'templateUrl': 'templates/marketplace/subscriptions/plan-style-a.html',
      'scope': {
        'plan': '=',
      }
    };
  }

}());

angular.module("app.shared.system")
  .service("generalUtils", [function () {
    var fixDate = function (d, check) { // force d to be on check's YMD,
      // for daylight savings purposes
      if (+d) { // prevent infinite looping on invalid dates
        while (d.getDate() !== check.getDate()) {
          d.setTime(+d + (d < check ? 1 : -1) * 3600000);
        }
      }
    };

    /**
     * Get the current window's hash params and converts them into an object with key-value pairs.
     * @returns {URLSearchParams}
     */
    function _urlParamsToObject () {
      const searchString = window.location.hash.split('?')?.[1] ?? ''; // 'userToken=123'
      return new URLSearchParams(searchString);
    }

    /**
     * Get the value of a key from the current window's hash params.
     * @param {string} key
     * @returns {string}
     */
    function getValueFromUrlParams (key) {
      return _urlParamsToObject().get(key);
    }

    return {
      parseISO8601: function (s, ignoreTimezone) { // ignoreTimezone
        // defaults to false
        // derived from http://delete.me.uk/2005/03/iso8601.html
        // TODO: for a known glitch/feature, read
        // tests/issue_206_parseDate_dst.html
        var m = s.match(/^([0-9]{4})(-([0-9]{2})(-([0-9]{2})([T ]([0-9]{2}):([0-9]{2})(:([0-9]{2})(\.([0-9]+))?)?(Z|(([-+])([0-9]{2})(:?([0-9]{2}))?))?)?)?)?$/);
        if (!m) {
          return null;
        }
        var date = new Date(m[1], 0, 1);
        if (ignoreTimezone || !m[13]) {
          var check = new Date(m[1], 0, 1, 9, 0);
          if (m[3]) {
            date.setMonth(m[3] - 1);
            check.setMonth(m[3] - 1);
          }
          if (m[5]) {
            date.setDate(m[5]);
            check.setDate(m[5]);
          }
          fixDate(date, check);
          if (m[7]) {
            date.setHours(m[7]);
          }
          if (m[8]) {
            date.setMinutes(m[8]);
          }
          if (m[10]) {
            date.setSeconds(m[10]);
          }
          if (m[12]) {
            date.setMilliseconds(Number("0." + m[12]) * 1000);
          }
          fixDate(date, check);
        } else {
          date.setUTCFullYear(m[1], m[3] ? m[3] - 1 : 0, m[5] || 1);
          date.setUTCHours(m[7] || 0, m[8] || 0, m[10] || 0, m[12] ? Number("0." + m[12]) * 1000 : 0);
          if (m[14]) {
            var offset = Number(m[16]) * 60 + (m[18] ? Number(m[18]) : 0);
            offset *= m[15] === '-' ? 1 : -1;
            date = new Date(+date + (offset * 60 * 1000));
          }
        }
        return date;
      },
      one_day: (24 * 60 * 60 * 1000),
      one_hour: (60 * 60 * 1000),
      shortISODate: function (date) {
        return date.toISOString().substr(0, 19);
      },
      getValueFromUrlParams
    };
  }]);

'use strict';
{
  /** @ngInject */
  class ProductExternalVendorsService {
    constructor(
      $rootScope,
      productDetailsService,
      ProductHelperService,
      AccessService,
      sowExternalVendorAccountService,
      msHelperService,
      mktHelperService,
    ) {
      this.$rootScope = $rootScope;
      this.productDetailsService = productDetailsService;
      this.AccessService = AccessService;
      this.sowExternalVendorAccountService = sowExternalVendorAccountService;
      this.msHelperService = msHelperService;
      this.mktHelperService = mktHelperService;
      this.ProductHelperService = ProductHelperService;

      this.VENDOR_STATUS = Object.freeze({
        CONNECTED: 'connected',
        DISCONNECTED: 'disconnected',
      });

      this.ERROR_CODE = Object.freeze({
        LOGIN_REQUIRED: 'ERRORCODES.LOGIN_REQUIRED',
      })
    }

    /**
     * Gets the available vendors for a product, and sorts the list with the official vendors
     * @param {string} product_id
     * @param {array} official_vendors
     * @return {null|object[]}
     */
    async getAvailableVendorsForProduct(product_id, official_vendors = []) {
      const default_vendors = {
        has_external: false,
        vendors: official_vendors,
      };

      // return early if no external vendors exist
      if (_.isEmpty(this.$rootScope.external_vendors)) {
        return default_vendors;
      }

      let vendors_for_product = {};
      vendors_for_product = await this.productDetailsService.getVendorsForProduct(product_id);

      // If the office doesn't have external vendors or the API throws an error, display only the official vendors
      if (_.isEmpty(vendors_for_product)) {
        return default_vendors;
      }

      const external_vendors = Object.entries(vendors_for_product).map((vendor) =>
        this._addExternalVendorUIProps(vendor),
      );
      return {
        has_external: true,
        vendors: this.productDetailsService.sortVendors([...official_vendors, ...external_vendors]),
      };
    }

    /**
     * Takes an array of vendors and fetches stock and price info for each of
     * the external vendors with available actions
     * @param {object[]} all_vendors
     * @return {Promise<object[]>}
     */
    async getAllExternalVendorPricing(all_vendors) {
      const price_and_stock_calls = all_vendors.map(async (vendor) => {
        if (vendor.UI.is_external) {
          const vendor_with_price = await this._fetchExternalPricing(vendor);
          return vendor_with_price;
        }
        return vendor;
      });

      const vendors = await Promise.all(price_and_stock_calls);
      return this.productDetailsService.sortVendors(vendors);
    }

    /**
     * Takes an array of vendors and a connected vendor, and returns an array of
     * vendors with updated pricing and stock information for the connected vendor
     * @param {object[]} all_vendors
     * @param {object} connected_vendor
     * @return {Promise<object[]>}
     */
    async getConnectedVendorPricing(all_vendors, connected_vendor) {
      const price_and_stock_calls = all_vendors.map(async (vendor) => {
        if (vendor.UI.id === connected_vendor.account.vendor_id) {
          const vendor_with_price = await this._fetchExternalPricing(vendor);
          vendor_with_price.UI.is_disconnected = false;
          return vendor_with_price;
        }
        return vendor;
      });
      const vendors = await Promise.all(price_and_stock_calls);
      return this.productDetailsService.sortVendors(vendors);
    }

    /**
     * Adds an updated connected vendor object to the root scope
     * @param {object} connected_vendor
     */
    updateRootScopeExternalVendors(connected_vendor) {
      const connected_vendor_id = connected_vendor.vendor.id;
      this.$rootScope.external_vendors[connected_vendor_id] = connected_vendor;
    }

    /**
     * Returns true if the external vendor account is connected and false if not
     * @param {object} external_vendor_account
     * @return {boolean}
     */
    isVendorConnected(external_vendor_account) {
      return external_vendor_account?.connected_status === this.VENDOR_STATUS.CONNECTED;
    }

    // ----------- Private methods -----------

    /**
     * Returns the account object if vendor_id exists
     * @param {string} vendor_id
     * @returns {object}
     */
    _getExternalVendorAccount(vendor_id) {
      return this.$rootScope.external_vendors[vendor_id]?.account;
    }

    /**
     * Returns the vendor actions object if vendor_id exists
     * @param {string} vendor_id
     * @returns {object}
     */
    _getExternalVendorActions(vendor_id) {
      return this.$rootScope.external_vendors_actions[vendor_id];
    }

    /**
     * It takes a vendor id and a set of properties, and returns a new object with the properties merged
     * @param {[string, object]} vendor
     */
    _addExternalVendorUIProps([vendor_id, properties]) {
      const is_disconnected = !this.isVendorConnected(this._getExternalVendorAccount(vendor_id));
      const actions = this._getExternalVendorActions(vendor_id);
      const {vendor_inventory} = properties;

      const vendor_info = {
        ...properties,
        ...vendor_inventory,
        UI: {
          ...properties.UI,
          ...this._generateDefaultVendorProps(),
          is_disconnected,
          id: vendor_inventory.vendor.id,
          image: vendor_inventory.vendor.image,
          name: vendor_inventory.vendor.name,
          sku: properties?.vendor_sku,
          actions,
          is_loading: Boolean(actions),
        },
        promotions: this._parsePromo(vendor_inventory.promotions),
        // internal price
        price: vendor_inventory.price,
      };

      return vendor_info;
    }

    /**
     * Returns the actions which enable fetching updated price and/or stock info
     * for a particular external vendor (if any)
     * @param {object} data_sources
     * @param {object} external_vendor
     * @return {Promise[]}
     */
    _getActions(data_sources, external_vendor) {
      const {price_status, stock_status} = data_sources;
      const {
        vendor_sku,
        uom,
        vendor: {id: account_number},
      } = external_vendor;
      const params = {
        vendor_sku,
        uom,
        account_number,
      };
      if (price_status && stock_status) {
        const price = this.sowExternalVendorAccountService.callAction(price_status, params);
        const stock = this.sowExternalVendorAccountService.callAction(stock_status, params);
        return [price, stock];
      } else if (price_status) {
        const price = this.sowExternalVendorAccountService.callAction(price_status, params);
        return [price];
      } else if (stock_status && this.mktHelperService.doesVendorHavePrice(external_vendor)) {
        const stock = this.sowExternalVendorAccountService.callAction(stock_status, params);
        return [Promise.resolve(), stock];
      }

      return [];
    }

    /**
     * Fetches the price and stock status (if any) of a product from an external vendor
     * @param {object} external_vendor
     * @return {object}
     */
    async _fetchExternalVendorProductInfo(external_vendor) {
      const {
        actions: {data_sources},
        product_status,
      } = external_vendor.UI;
      const [price_response, stock_response] = await Promise.allSettled(
        this._getActions(data_sources, external_vendor),
      );

      const stock = this.ProductHelperService.getStockBasedOnResponse(stock_response, product_status);
      switch (price_response?.status) {
        case 'fulfilled':
          return {
            price: this.msHelperService.getDataValue(price_response.value.data.Price),
            ...stock,
          };
        case 'rejected':
          /* handle case where the external vendor item already had a price,
          or the product is unavailable (in which case we will get back that
          product_status is "sold_out" but the price request will error out) */
          if (this.mktHelperService.doesVendorHavePrice(external_vendor) || stock.product_status === 'sold_out') {
            return {
              price: external_vendor.price || external_vendor.list_price,
              ...stock,
            };
          } else {
            throw price_response.reason;
          }
        default:
          return {
            price: external_vendor.price,
            ...stock,
          };
      }
    }

    /**
     * Evaluates whether the error we received should result in the error state
     * being displayed or is simply indicating that sign in is required
     * @param {object} vendor
     * @param {object} error
     * @return {Function}
     */
    _handleConnectionError(vendor, error) {
      if (error?.error_code === this.ERROR_CODE.LOGIN_REQUIRED) {
        return this._updateVendorErrorState(vendor, false);
      }
      
      return this._updateVendorErrorState(vendor);
    }

    /**
     * Returns an object which reflects the initial state of all external vendors
     * @return {object}
     */
    _generateDefaultVendorProps() {
      return {
        has_error: false,
        is_external: true,
        on_sale: false,
        product_status: this.ProductHelperService.PRODUCT_STATUS.UNKNOWN,
        stock_text: null,
        promo_price: false,
        promo_text: '',
        strike_regular_price: false,
      };
    }

    /**
     * Sets is_loading to false and has_error to the arg it receives
     * @param {object} vendor
     * @param {boolean} has_error
     * @return {object}
     */
    _updateVendorErrorState(vendor, has_error = true) {
      const new_vendor = Object.assign(vendor, {});
      new_vendor.UI.has_error = has_error;
      new_vendor.UI.is_loading = false;
      return new_vendor;
    }

    /**
     * Updates a vendor with newly fetched stock/price info
     * @param {object} vendor
     * @param {object} product_info
     * @return {object}
     */
    _updateVendorPriceAndStock(vendor, product_info) {
      const new_vendor = Object.assign(vendor, {});
      new_vendor.price = product_info.price;
      new_vendor.UI.product_status = product_info.product_status;
      new_vendor.UI.stock_text = product_info.stock_text;
      new_vendor.UI.is_loading = false;

      return new_vendor;
    }

    /**
     * Fetches the price and stock of a product from an external vendor and updates the vendor
     * @param {object} vendor
     * @return {Promise<object>}
     */
    async _fetchExternalPricing(vendor) {
      try {
        if (vendor.UI.actions) {
          const product_info = await this._fetchExternalVendorProductInfo(vendor);
          return this._updateVendorPriceAndStock(vendor, product_info);
        }
        return vendor;
      } catch (error) {
        console.error(error);
        return this._handleConnectionError(vendor, error);
      }
    }

    /**
     * API response has the promotions as string, if promotions are "[]" return an empty array
     * @param {array} promotions
     * @returns An array of promotions
     */
    _parsePromo(promotions) {
      return Array.isArray(promotions) ? promotions : [];
    }
  }

  angular.module('sowMarketplace').service('ProductExternalVendorsService', ProductExternalVendorsService);
}

angular.module("app.shared.support")
  //
  // This is used to filter provinces based on a country. It is
  // meant to be used in forms where the user selects a country and then the
  // province list is restricted to provinces of that country.
  //
  .filter('provinceCountryFilter', function ($log) {
    return function (provinces, country) {
      provinces = provinces || [];
      return provinces.filter(function (item) {
        return country && (
          item.country_id ? item.country_id === country.id
            : item.country ? item.country.id === country.id
              : false
        );
      });
    };
  });

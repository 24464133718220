(function () {
  'use strict';

  angular.module('sowMarketplace')
  .controller('marketplaceAddressFormController', marketplaceAddressFormController)
  .directive('sowAddressForm', marketplaceAddressFormDirective);

  function marketplaceAddressFormDirective () {
    return {
      restrict: 'E',
      templateUrl: 'sow-mkt/directives/address-form.html',
      controller: 'marketplaceAddressFormController',
      controllerAs: 'mktafCtrl',
      scope: {
        'formname': '=',
        'data': '=',
      },
    };
  }

  function marketplaceAddressFormController ($scope, $rootScope) {
    /*jshint validthis:true*/
    var ctrl = this;

    init();

    return ctrl;

    function init () {
      
    }
    
  }

})();
(function () {
  'use strict';
  angular
    .module('app.marketplace.ui.purchaseorders')
    .controller('poActiveController', POActiveController);

  function POActiveController (PurchaseOrderService, SupplierDialog,  $q, $state,
    $mdDialog, $mdToast, $timeout, $filter, errorService, poHelperService,
    impHelperService, sowImplantService, sowMedicationService,$scope, $rootScope,
    $mdSidenav, sgToast, SupplierService, AccessService, sowAnalyticsService) {
    
    const ctrl = {
      ...this,
      $onInit: init,
      // state
      bundles: [],
      displayed_view: 'details',
      events: null,
      imp_expiry_years: impHelperService.generateExpiryYears(),
      is_po_editable: true,
      isDuplicating: false,
      isEditing: false,
      itemTypeOrder: {
        notreceived: 0,
        backordered: 1,
        shipped: 2,
        received: 3,
        substituted: 4,
        cancelled: 5
      },
      loading_events: false,
      po: {items:[]},
      selectableItems: 0,
      selectableShippedItems: 0,
      statusData: poHelperService.status_data,
      selectingAllNotReceived: false,
      selectingAllBackordered: false,
      selectingAllShipped: false,
      // methods
      cancelDialog,
      changeSelected,
      closeReceiveDialog,
      editDialog,
      editRep,
      editSupplier,
      getItems,
      getShippingText,
      getTotalUnits: poHelperService.getTotalUnits,
      handleAddEmailClick,
      handleAddNameClick,
      handleAddNumberClick,
      handleCancelClick,
      handleEditDateClick,
      handleUndoClick,
      implantQtyChange,
      initImplantBundles,
      isApprovalPending,
      medicationQtyChange,
      newReceiveDialog,
      receiveDialog,
      receiveItems,
      receiveItemsMobile,
      removeImplantBundle,
      removeMedicationBundle,
      selectAllNotReceived,
      selectAllBackordered,
      selectAllShipped,
      selectedItems,
      selectedItemsTotal,
      selectView,
      shouldShowColumn,
      showReceivingAlert,
      toggleShowBundle,
      undoDialog,
    };

    return ctrl;

    function init () {
      ctrl.loading = true;
      defineLocks();
      const poId = $state.params.poId;
      PurchaseOrderService.get(poId,true).then(function(result){
        updateCtrlPo(result);
        const bundle_data = poHelperService.makeBundles(ctrl.po);
        _.extend(ctrl, bundle_data);
        reorderItems();
        loadSupplier(result.supplier.id);
        loadSalesRep(result.supplier);
        $rootScope.$broadcast('po_loaded', result.id, result);
      }).finally(() => {
        ctrl.loading = false;
      });

      $scope.$on('purchase_order_print', function () {
        PurchaseOrderService.print(ctrl.po).catch(function (error) {
          errorService.uiErrorHandler(error);
        });
      });

      $scope.$on("edit_po", editPo);
      $scope.$on("duplicate_po", duplicatePo);
      $scope.$on('email_po', emailPo);
      $scope.$on('po_updated', function(event, po){
        updateCtrlPo(po);
      });
      $scope.$on('po-emailed', handleEmailEvent);
      $scope.$on('invalid-po-item-request', init);
    }

    function loadSupplier (id) {
      SupplierService.get(id)
      .then(function(res_supplier){
        ctrl.supplier = res_supplier;
        ctrl.account_number = ctrl.po.office_vendor_membership.account_number ?? ctrl.supplier.vendor_customer_number;
      })
      .catch(function(error){
        var t_message = $filter('translate')('ERRORS.SUPPLIER_INFO_LOAD')
        errorService.uiErrorHandler(error || t_message);
      })
    }

    function getItems(){
      ctrl.indexedGroups = [];
      return ctrl.po.items;
    }

    function reorderItems () {
      if(!_.size(ctrl.groups.notreceived)){
        return;
      }
      var ordered_list = _.sortBy(ctrl.groups.notreceived[0].items, function(item){
        return item.name;
      });
      _.set(ctrl.groups, 'notreceived[0].items', ordered_list);
    }

    function updateCtrlPo (result) {
      _deselectAllItems();
      ctrl.po = result;
      ctrl.selectableItems = _.reduce(ctrl.po.items, (sum, item) => {
        return sum + (_isNotReceived(item) ? 1 : 0);
      }, 0);
      ctrl.selectableShippedItems = _.reduce(ctrl.po.items, function (sum, item) {
        return sum + (item.status?.toLowerCase() === 'shipped' ? 1 : 0);
      }, 0);
      ctrl.selectableBackorderedItems = _.reduce(ctrl.po.items, (sum, item) => {
        return sum + (poHelperService.getItemType(item) === 'backordered' ? 1 : 0);
      }, 0);
      const bundle_data = poHelperService.makeBundles(ctrl.po);
      _.extend(ctrl, bundle_data);
      result = null;
      _checkIfOrderCanBeEdited();
      ctrl.loading = false;
    }

    /**
     * If the order contains a can_edit boolean, set the controller's
     * is_po_editable property to that value. Otherwise, no update is
     * required (since the API responses to certain update requests
     * include the PO without that property defined and in those cases
     * we need the previous value to persist)
     * @param order - The order object that is being checked.
     */
    function _checkIfOrderCanBeEdited(order = ctrl.po) {
      // the can_edit prop is undefined in the order object we get back
      // following certain update requests, so we only need to update
      // the controller if we've been provided a boolean can_edit status
      // in the updated PO - otherwise the previous state is still valid
      if (typeof order.can_edit === 'boolean') {
        ctrl.is_po_editable = order.can_edit;
      }
    }

    function selectedItems(){
      var items = [];
      items = _.filter(ctrl.po.items, ['selected',true]);

      return items;
    }

    function selectedItemsTotal(){
      var total = 0;
      var items = selectedItems();
      total = _.reduce(items, function(sum,item){
        return sum + item.receive_quantity || 0;
      },0);
      return total;
    }

    function receiveDialog(ev){
      var selected_items = selectedItems();
      var wrongItems = _.filter(selected_items, function(item){
        item.empty = item.receive_quantity === 0 | !item.receive_quantity;
        // item.over = item.receive_quantity > item.quantity;
        item.over = false;
        item.under = item.receive_quantity < 0;
        item.wrong = item.empty | item.over | item.under;
        return !!item.wrong;
      });

      if(wrongItems.length > 0){
        // don't even open dialog
        ctrl.wrongSelectedItems = true;
      }else{
        ctrl.wrongSelectedItems = false;

        //
        // Determine wether all inventory items already have
        // their supplier set to this PO's supplier or not.
        //
        var itemsBelongToSupplier = true;
        var implant_pois = [];
        var general_pois = [];
        var medication_pois = [];

        _.forEach(selected_items, function (poItem) {
          var poItemSupplierId = _.get(poItem, 'inventory_item.supplier_id');
          var poSupplierId = _.get(ctrl, 'po.supplier.id');

          if (_.get(poItem,'inventory_item.type',null) === 'Implant Item') {
            initImplantBundles(poItem);
            implant_pois.push(angular.copy(poItem));
          } else if(_.get(poItem,'inventory_item.type',null) === 'Medication Item'){
            initMedicationBundles(poItem);
            medication_pois.push(angular.copy(poItem));
          } else {
            general_pois.push(angular.copy(poItem));
          }

          if (poItemSupplierId !== poSupplierId) {
            itemsBelongToSupplier = false;
          }
        });

        ctrl.receiving = {
          'app_option': '',
          'product_label': "product" + (selectedItems().length === 1 ? "'s" : "s'"),
          'supplier': ctrl.po.supplier,
          'itemsBelongToSupplier': itemsBelongToSupplier,
          'update_supplier': !itemsBelongToSupplier,
          'date': new Date(),
          'general_received': false,
          'general_pois': general_pois,
          'implant_pois': implant_pois,
          'medication_pois': medication_pois
        };
        $mdSidenav('po-receive-slideout').onClose(updatePoAfterReceiving);
        $mdSidenav('po-receive-slideout').open();
      }
    }

    function newReceiveDialog (ev) {
      var selected_items = selectedItems();
      var itemsBelongToSupplier = true;
      var wrongItems = _.filter(selected_items, function(item){
        item.empty = item.receive_quantity === 0 | !item.receive_quantity;
        // item.over = item.receive_quantity > item.quantity;
        item.over = false;
        item.under = item.receive_quantity < 0;
        item.wrong = item.empty | item.over | item.under;
        return !!item.wrong;
      });

      if(wrongItems.length > 0){
        // don't even open dialog
        ctrl.wrongSelectedItems = true;
      }else{
        ctrl.wrongSelectedItems = false;
        if(!_.size(selected_items))
          return;

         _.forEach(selected_items, function (poItem) {
          var poItemSupplierId = _.get(poItem, 'inventory_item.supplier_id');
          var poSupplierId = _.get(ctrl, 'po.supplier.id');

          if (poItemSupplierId !== poSupplierId) {
            itemsBelongToSupplier = false;
          }
        });

        var order = _.extend({}, ctrl.po, {'items': selected_items, 'itemsBelongToSupplier': itemsBelongToSupplier});
        $rootScope.$broadcast('po-receive-open', order);
        return;
      }
    }

    function closeReceiveDialog ($event) {
      $mdSidenav('po-receive-slideout').close();
    }

    function initMedicationBundles (medication_poi) {
      medication_poi.app_option = '';
      medication_poi.medication_bundles = [{qty: medication_poi.receive_quantity }];
    }

    function initImplantBundles (implant_poi) {
      implant_poi.app_option = '';
      implant_poi.implant_bundles = [{qty: implant_poi.receive_quantity }];
    }

    function updatePoAfterReceiving () {
      if(ctrl.updated_po_info){
        updateCtrlPo(ctrl.updated_po_info);
        var t_message = $filter('translate')('TOAST.ITEMS_RECEIVED');
        sgToast.showSimple(t_message);
      }
    }

    function receiveItems(items, date, updateSupplier){
      var received_implant_items = _.filter(items, function(item){ return _.get(item,'inventory_item.type',null) === 'Implant Item'; });
      if (received_implant_items.length) {
        _.forEach(received_implant_items, singleImplantReceive);
      }

      var received_medication_items = _.filter(items, function(item){ return _.get(item,'inventory_item.type',null) === 'Medication Item'; });
      if (received_medication_items.length) {
        medication_receive(received_medication_items[0]);
      }

      PurchaseOrderService.receiveItems(ctrl.po, items, date, updateSupplier, true, ctrl.disable_supplier_price_update).then(function(result){
        ctrl.updated_po_info = result;
        _.forEach(items, function(item){
          item.selected = false;
          item.received = true;
        });

        checkAllReceived();
      });
    }

    function receiveItemsMobile (items) {
      PurchaseOrderService.receiveItems(ctrl.po, items, ctrl.receiving.date, ctrl.receiving.update_supplier, false, ctrl.disable_supplier_price_update).then(function(result){
        ctrl.updated_po_info = result;
        _.forEach(items, function(item){
          item.app_option = 'mobile';
          item.selected = false;
          item.received = true;
        });

        checkAllReceived();
      });
    }

    function checkAllReceived () {
      var all_done = true;

      ctrl.receiving.general_received = _.reduce(ctrl.receiving.general_pois, function(sum, i){
          return sum && i.received;
        }, true);

      ctrl.receiving.medications_received = _.reduce(ctrl.receiving.medication_pois, function (sum, i) {
          return sum && i.received;
        }, true);

      ctrl.receiving.implants_received = _.reduce(ctrl.receiving.implant_pois, function(sum, i){
          return sum && i.received;
        }, true);

      all_done = ctrl.receiving.general_received &&
        ctrl.receiving.implants_received &&
        ctrl.receiving.medications_received;

      if (all_done) {
        $timeout(function(){
          closeReceiveDialog();
        }, 1000);
      }
    }

    function singleImplantReceive (received_item){
      var savingItem = { 'price': received_item.unit_cost };
      sowImplantService.getInventoryGroups({ 'inventory_item_id': received_item.inventory_item.id })
      .then(function(response){
        savingItem.inventory_group = response[0].id || null;
        _.forEach(received_item.implant_bundles, function(bundle){
          var individual_implant = _.extend({}, savingItem, bundle);
          for (var i = bundle.qty - 1; i >= 0; i--) {
            sowImplantService.saveInventoryItem(individual_implant);
          }
        });
      });
    }

    /**
     * @method medication_receive
     * 
     * @description Creates arrays of promises saving stock medication items
     */
    function medication_receive(medication_item_received){

      var promises = [];
      // medication_item_received = received_medication_items[0];
      sowMedicationService.getMedications({inventory_item_id: [medication_item_received.inventory_item_id] })
        .then(function(medications){
          var medication = medications[0];
          if(medication){
            $q.all(create_promises(medication))
              .then(function(result){
                medication_item_received.received_count = result.length;
                medication_item_received.medication = medication;
              });
          }
        });

        function create_promises(medication){
          for (var index = 0; index < medication_item_received.medication_bundles.length; index++) {
            var bulk_stock = medication_item_received.medication_bundles[index];
            var iterations = bulk_stock.qty && bulk_stock.qty !=="" ? parseInt(bulk_stock.qty): 1;
            if (medication_item_received.buy_by_case) {
              iterations = parseInt(medication_item_received.inventory_item.order_package_quantity) * iterations;
            } 
            
            for (var j = 0; j < iterations; j++) {
              var datestring = "{0}/{1}/{2}".format(bulk_stock.expiry_month, '01', bulk_stock.expiry_year);
              var stock_obj = {
                'medication_id': medication.id,
                'expiry_date':  new Date(datestring),
                'lot_number': bulk_stock.lot_number
              };
              promises.push(sowMedicationService.createOrUpdateMedicationStock(stock_obj));
            }
          }

          return promises;
        }

    }



    function medicationQtyChange (item) {
      var total_qty = _.reduce(item.medication_bundles, function(sum, bundle){ return sum + parseInt(bundle.qty); }, 0);
      var diff = (item.receive_quantity - total_qty);
      if( diff > 0 ){
        item.medication_bundles.push({qty: diff});
      }
    }

    function removeMedicationBundle (item, bundle) {
      _.remove(item.medication_bundles, bundle);
      var last_bundle = _.last(item.medication_bundles);
      last_bundle.qty = (last_bundle.qty * 1) + (bundle.qty * 1);
    }

    function implantQtyChange (item) {
      var total_qty = _.reduce(item.implant_bundles, function(sum, bundle){ return sum + parseInt(bundle.qty); }, 0);
      var diff = (item.receive_quantity - total_qty);
      if( diff > 0 ){
        item.implant_bundles.push({qty: diff});
      }
    }

    function removeImplantBundle (item, bundle) {
      _.remove(item.implant_bundles, bundle);
      var last_bundle = _.last(item.implant_bundles);
      last_bundle.qty = (last_bundle.qty * 1) + (bundle.qty * 1);
    }

    function cancelDialog(item,ev){
      $mdDialog.show({
        controller: 'poDialogController',
        controllerAs: 'poCanCtrl',
        templateUrl: 'templates/marketplace/purchase-orders/modals/po-cancel-items.html',
        parent: angular.element(document.body),
        targetEvent: ev,
        clickOutsideToClose:true,
        locals:{
          cancellation:{
            date: new Date(),
            item: item
          }
        },
        bindToController: true
      })
      .then(function(answer) {
        //on dialog answered
        sowAnalyticsService.purchaseOrderCancelProductConfirmed();
        cancelSelectedItems(answer);
      }).catch(function() {
        //on dialog cancelled
      });
    }
    function cancelSelectedItems(answer){
      PurchaseOrderService.cancelItems(ctrl.po, [answer.item], answer.date)
        .then(result => {
          updateCtrlPo(result);
        })
        .catch(err => {
          closeReceiveDialog();
          errorService.uiErrorHandler(err);
          init();
        });
      // TO-DO: success and failure toast messages
    }

    function undoDialog(item, $event){
      $mdDialog.show({
        controller: 'poDialogController',
        controllerAs: 'poUndoCtrl',
        templateUrl: 'templates/marketplace/purchase-orders/modals/po-undo-last-action.html',
        parent: angular.element(document.body),
        targetEvent: $event,
        clickOutsideToClose:true,
        locals:{
          action: item.last_action,
          item: item
        },
        bindToController: true
      })
      .then(function(answer) {
        sowAnalyticsService.purchaseOrderUndoConfirmed(item);
        // on dialog answered
        PurchaseOrderService.undoLastAction(answer).then(function(result){
          updateCtrlPo(result);
        }).catch(function (error) {
          errorService.uiErrorHandler(error, 0);
        });
      }).catch(function() {
        //on dialog cancelled
      });
    }

    function editDialog(itemGroup, $event){
      $event.stopPropagation();

      // Not sure why it was done this way since we already had itemGroup.items, but this ended up in bug SOW-6310
      // var bundleItems = _.filter(ctrl.po.items, function(item){
      //   return item.group_id === itemGroup.group_id;
      // }) || [];
      var totalItems = _.reduce(itemGroup.items, function(sum, item){
        return sum + (item.quantity || 0);
      },0);

      //
      // If we just pass the date string to Date(), it will be interpreted as a
      // UTC date, and converted to a local time date, which will push the date
      // _backwards_ to the previous date when the timezone offset of negative
      // (e.g. -0500 will push the date back from midnight UTC on the date in
      // the date string to 7pm the previous day in that timezone).
      //
      var editDate = new Date(itemGroup.last_action_date);
      editDate = new Date(editDate.getUTCFullYear(), editDate.getUTCMonth(), editDate.getUTCDate(), 0, 0, 0);

      $mdDialog.show({
        controller: 'poDialogController',
        controllerAs: 'poEditCtrl',
        templateUrl: 'templates/marketplace/purchase-orders/modals/po-edit-action-date.html',
        parent: angular.element(document.body),
        targetEvent: $event,
        clickOutsideToClose:true,
        locals:{
          action: itemGroup.last_action,
          totalProducts: itemGroup.items.length,
          totalItems: totalItems,
          editing: {
            date: editDate,
          }
        },
        bindToController: true
      })
      .then(function(answer) {
        //on dialog answered
        sowAnalyticsService.purchaseOrderEditDateConfirmed();
        if(moment(answer.date).isSame(moment(editDate), 'day')){
          return;
        }
        _.map(itemGroup.items, function(item){
          PurchaseOrderService.editItemActionDate(item, answer.date).then(function(result){
            updateCtrlPo(result);
          });
        });

      }).catch(function() {
        //on dialog cancelled
      });
    }

    /**
     * Makes a copy of the current active PO with a new name and saves it as a draft
     * @param $event - The event that triggered the function.
     * @param page - The page you're on.
     */
    function duplicatePo ($event, page) {
      if (!ctrl.isDuplicating && page === "active") {
        ctrl.isDuplicating = true;
        var newPo = poHelperService.makeDuplicate(ctrl.po);
        if (ctrl.use_hrid) {
          return PurchaseOrderService.getHRID()
          .then(hrid => {
            newPo.name = PurchaseOrderService.getPoName(hrid, 'edi');
            newPo.hrid = hrid;
            makeNewDraft(newPo);
          })
          .catch(error => {
            errorService.uiErrorHandler(error);
          });
        } else {
          return PurchaseOrderService.count()
          .then(count => {
            newPo.name = PurchaseOrderService.getPoName(count + 1);
            makeNewDraft(newPo);
          }).catch(error => {
            errorService.uiErrorHandler(error);
          });
        }
      }
    }

    /**
     * Switches the current purchase order from active to draft status.
     * @param newPo - the new purchase order object
     * @returns The result of the promise.
     */
    function makeNewDraft (newPo) {
      // XGH warning: for some reason, whenever I tried saving the draft right away
      // the name input field was not yet filled in by the digest cycle
      $timeout(function () {
        return PurchaseOrderService.saveDraft(newPo).then(function (result) {
          var t_message = $filter('translate')('TOAST.DRAFT_CREATED');
          $state.go('app.orders.purchase.draft', {'poId': result.id}).then(function () {
            $mdToast.show(
              $mdToast.simple()
                .textContent((result.name + t_message))
                .position("bottom right")
                .hideDelay(2000)
            );
          });
        });
      }, 500);
    }

    function emailPo () {
      var rep = ctrl.sales_rep || null;
      poHelperService.emailPoDialog(ctrl.po, rep);
    }

    /**
     * If the item is selected, set the receive quantity to the item quantity. If the item is not
     * selected, set the receive quantity to 0. If all items are selected, set the all boolean to true
     * @param item - the item that was selected
     */
    function changeSelected(item, options){
      // originally only 'notreceived' items were selectable, now we use two lists
      // because on EDI POs only backordered and shipped items are
      const item_type = poHelperService.getItemType(item);
      const keys = {
        backordered: 'selectingAllBackordered',
        shipped: 'selectingAllShipped'
      };
      const selecting_all_key = keys[item_type] ?? 'selectingAllNotReceived';

      if (item.selected) {
        // if the receive quantity is empty or negative, set it to the item quantity
        if (!item.receive_quantity || item.receive_quantity < 0) {
          item.receive_quantity = item.quantity;
        }
        // if all items of this type are selected, ensure the select all checkbox is checked
        if (_.every(_.filter(ctrl.po.items, item => poHelperService.getItemType(item) === item_type), {selected: true})){
          _.set(ctrl, selecting_all_key, true);
        }
      } else {
        if (!options?.persist_receive_quantity) {
          item.receive_quantity = 0;
        }
        _.set(ctrl, selecting_all_key, false);
      }
    }

    /**
     * Select all items in the list that are not yet received
     */
    function selectAllNotReceived(){
      _.each(ctrl.po.items, function(item){
        // `last_action` is used for legacy (general) POs,
        // while EDI POs use `status` instead
        // if we ever include the 'notreceived' status in the future, this will also work as well
        
        if(_isNotReceived(item)){
          _.set(item, 'selected', ctrl.selectingAllNotReceived);
          changeSelected(item);
        }
      });
    }

    /**
     * Check if the item is not yet received
     * @param {poItem} item - the item to check
     * @return {boolean} - true if the item is not received
     * @private
     */
    function _isNotReceived (item) {
      return (_.isNil(item.last_action) && _.isNil(item.status)) || item.last_action?.toLowerCase() === 'notreceived';
    }

    function selectAllBackordered() {
      for (const item of ctrl.po.items) {
        if (poHelperService.getItemType(item) === 'backordered') {
          item.selected = ctrl.selectingAllBackordered;
          changeSelected(item);
        }
      }
    }

    /**
     * Select all items that have a status of "shipped"
     */
    function selectAllShipped () {
      _.each(ctrl.po.items, function(item){
        if(item.status?.toLowerCase() === 'shipped'){
          _.set(item, 'selected', ctrl.selectingAllShipped);
          changeSelected(item);
        }
      });
    }

    /**
     * Deselect all PO items (as well as the "select all" checkbox)
     */
    function _deselectAllItems () {
      _.map(ctrl.po.items, function(item){
        _.set(item, 'selected', false);
        changeSelected(item, { persist_receive_quantity: true });
      });
    }

    function editPo($event, page){
      if (!ctrl.isEditing) {
        ctrl.isEditing = true;
        if (page === "active") {
          $state.go('app.orders.purchase.draft',{poId: ctrl.po.id, editingActive: 1});
        }
      }
    }

    /**
     * Generates the localized text which will indicate shipping/courier info
     * @param {object} bundle
     * @return {string}
     */
    function getShippingText(bundle) {
      const date_text = $filter('date')(bundle.shipment?.shipped_on);

      let shipped_text = $filter('translate')('COMMON.SHIPPED');
      // if we have info about the courier we'll include it in the response
      const company = bundle.shipment?.courier?.name || bundle.shipment?.courier_description;
      if (company) {
        shipped_text = $filter('translate')('COMMON.SHIPPED_WITH', {company});
      }

      return `${shipped_text} - ${date_text}`;
    }

    /**
     * > Checks for a set of rules for displaying specific columns in groups for each po type
     * @param group_name - the name of the group of items (e.g. 'notreceived', 'received', 'cancelled')
     * @param column - the name of the column
     * @param po_type - The type of purchase order.
     * @returns A boolean value.
     */
    function shouldShowColumn (group_name, column, po_type = ctrl.po.type) {
      const is_approval_pending = ctrl.isApprovalPending();
      const column_rules = {
        edi: {
          // on EDI POs, users can receive items from the 'shipped', 'backordered', and 'notreceived' groups 
          shipped: {
            select: !is_approval_pending,
            received: true,
          },
          backordered: {
            action: !ctrl.disable_edit_backordered,
            select: !is_approval_pending && !ctrl.disable_edit_backordered,
          },
          notreceived: {
            select: !is_approval_pending,
            action: true,
          },
          received: {
            action: true,
            edit: false,
          },
        },
        regular: {
          // on regular POs, items are received from the 'notreceived' group
          notreceived: {
            select: !is_approval_pending,
            received: true,
            action: true,
            edit: true,
          },
          // The action column contains the 'undo' or 'cancel' buttons, while edit contains the 'edit' button
          cancelled: {
            action: true,
            edit: true,
          },
          received: {
            action: true,
            edit: true,
          },
        },
      };
      // if there's no defined rule, default value is false
      return _.get(column_rules, `${po_type}.${group_name}.${column}`, false);
    }

    function toggleShowBundle (bundle) {
      bundle.show = !bundle.show;
    }

    // deprecated
    function editSupplier (ev) {
      return SupplierDialog.show(ev, ctrl.po.supplier).then(function (supplier) {
        ctrl.po.supplier = supplier;
      });
    }

    function loadSalesRep(supplier) {
      let sales_rep = { supplier };
      SupplierService.getSalesReps({ supplier_id: supplier.id })
        .then(rep_response => {
          const selected_rep = rep_response.find(({ id }) => id === supplier.sales_rep_id);
          const first_rep = rep_response.find(({ is_primary }) => is_primary) || rep_response[0];
          if (selected_rep || first_rep) {
            sales_rep = selected_rep || first_rep;
          }
        })
        .catch(err => console.error(err))
        .finally(() => ctrl.sales_rep = sales_rep)
    }

    function editRep () {
      $mdDialog.show({
          controller: 'officeRepModalController',
          controllerAs: 'dialogCtrl',
          templateUrl: 'templates/marketplace/office/modals/office-rep.html',
          parent: angular.element(document.body),
          clickOutsideToClose:true,
          locals : {
            modalType : "edit",
            sales_rep : angular.copy(ctrl.sales_rep),
            suppliers_list: ctrl.suppliers,
          },
          bindToController: true,
        }).then(function(rep_data){
          saveRep(rep_data)
          .then(function(response){
            ctrl.sales_rep = response;
            var t_message = $filter('translate')('TOAST.REP_DETAILS_SAVED');
            sgToast.showSimple(t_message);
          });
        });
    }

    function saveRep (sales_rep) {
      return SupplierService.createOrUpdateSalesRep(sales_rep);
    }

    function defineLocks () {
      ctrl.disabled_sales_rep_add_new = AccessService.getProperty("sales_reps.disable_add_new");
      ctrl.disabled_sales_rep_edit = AccessService.getProperty("sales_reps.disable_edit");
      ctrl.disabled_sales_rep_delete = AccessService.getProperty("sales_reps.disable_delete");
      ctrl.disabled_add_new = AccessService.getProperty("purchase_orders.disable_add_new");
      ctrl.disabled_edit = AccessService.getProperty("purchase_orders.disable_edit");
      ctrl.disabled_delete = AccessService.getProperty("purchase_orders.disable_delete");
      ctrl.disabled_edit_active = AccessService.getProperty("purchase_orders.disable_edit_active");
      ctrl.disabled_delete_active = AccessService.getProperty("purchase_orders.disable_delete_active");
      ctrl.hide_billing_info = AccessService.getProperty("purchase_orders.hide_billing_info");
      ctrl.use_hrid = AccessService.getProperty('office.create_po_hrid');
      ctrl.disable_supplier_price_update = AccessService.getProperty('purchase_orders.disable_supplier_price_update');
      ctrl.hide_packaging_info = AccessService.getProperty('purchase_orders.enable_mrkt_product_pull');
      ctrl.disable_edit_backordered = AccessService.getProperty('purchase_orders.disable_edit_backordered');
    }

    /** 
     * Determines whether to display the alert with
     * instructions for receiving items. It shouldn't
     * be displayed if the PO is pending approval
     * or if all items have already been received.
     * 
     * @param {Object} po 
     * 
     * @return {Boolean} 
    */ 
    function showReceivingAlert(po) {
      po = po || ctrl.po;
      const is_pending = isApprovalPending();
      const all_items_received = _.get(po, 'received_status') === 'All';
      if (is_pending || all_items_received) {
        return false
      } else {
        return true
      };
    }

    function isApprovalPending(po = ctrl.po) {
      return _.get(po, 'status') === 'Pending';
    }

    /** 
     * Allows the user to select which view is displayed. 
     * 
     * @param {String} clicked_view 
     * 
     * @return {*} 
    */ 
    function selectView(clicked_view) {
      ctrl.displayed_view = clicked_view;

      if (clicked_view === 'log' && ctrl.events === null) {
        fetchAndParseEvents($state.params.poId);
      }
    }

    /** 
     * Fetches and parses Activity Log events for this PO
     *
     * @param {Object} po
     *
     * @return {*}
    */
    function fetchAndParseEvents(po_id = ctrl.po?.id) {
      ctrl.loading_events = true;
      PurchaseOrderService.fetchEvents(po_id)
        .then(events => {
          ctrl.events = poHelperService.parseEventsData(events);
        })
        .catch(err => console.error(err))
        .finally(() => {
          ctrl.loading_events = false;
        })
    }

    /** 
     * Determines whether a state refresh is required
     * because this PO has been emailed. 
     * 
     * @param {String} po_id 
     * 
     * @return {*} 
    */
    function handleEmailEvent(_$event, po_id) {
      const draft_id = _.get(ctrl, 'po.id');
      if (draft_id === po_id) {
        fetchAndParseEvents(po_id);
      }
    }

    function handleUndoClick(item, $event) {
      $event.stopPropagation();
      sowAnalyticsService.purchaseOrderUndoClicked();
      undoDialog(item, $event);
    }
    
    function handleCancelClick(item, $event) {
      sowAnalyticsService.purchaseOrderCancelProductClicked();
      cancelDialog(item, $event);
    }

    function handleEditDateClick(bundle, $event) {
      sowAnalyticsService.purchaseOrderEditDateClicked();
      editDialog(bundle, $event);
    }

    function handleAddNameClick() {
      sowAnalyticsService.purchaseOrderAddNameClicked();
      editRep();
    }

    function handleAddEmailClick() {
      sowAnalyticsService.purchaseOrderAddEmailClicked();
      editRep();
    }

    function handleAddNumberClick() {
      sowAnalyticsService.purchaseOrderAddNumberClicked();
      editRep();
    }

  } // End of active PO ctrl

}());

angular.module("app.shared.support")
  .directive('cardSlider', function ($compile) {
    return {
      restrict: 'AE',
      templateUrl: 'templates/shared/support/directives/card-slider.html',
      scope: {
        title: '@', //Title of slider
        titleHtml: '@', //Title of slider in raw html
        button: '=', //Object that must contain a .fn (function) or state_link (state name which to redirect to upon clicking). May contain .name for display.
        type: '@', //Instance type. Only 'product' or 'category' accepted. May make more generic and accept a templateUrl.
        cards: '=' //Array of 'cards' (i.e. array of product or category instances)
      },
      link: function ($scope, $element, $attributes) {
        $scope.slider_ele = $element;

        //Checks for custom title html. If exsits then replace title and compile.
        $scope.$watch('titleHtml', function (newTitleValue) {
          $element.find(".slider-title").html($scope.titleHtml);
          $compile($element.find(".slider-title").contents())($scope);
        });
      },
      controller: function ($scope, $state) {
        if ($scope.button && !$scope.button.fn) {
          if ($scope.button.state_link) {
            $scope.button.fn = function () {
              $state.go($scope.button.state_link, $scope.button.state_params);
            };
          }
        }
        $scope.getNumberCardsDisplayed = function () {
          var cardNum = (window.innerWidth - (108 * 2)) / (184 + 24);
          return Math.floor(cardNum);
        };
        $scope.slideIndex = 0;

        //Slide the view port by the number of visible cards.
        $scope.slide = function (direction) {
          var slider = $scope.slider_ele.find(".slider-inner");
          var x = parseInt(slider[0].style.webkitTransform.split(/[()]/)[1], 10) || 0; //Process to get the transformX value.
          if (direction === "left") {
            $scope.slideIndex -= 1;
            x = x + ($scope.getNumberCardsDisplayed() * (184 + 24)); //Math.floor to have the left most card 100% visible. Because we want to shift the view by X number of visible whole cards.
          } else if (direction === "right") {
            $scope.slideIndex++;
            x = x - ($scope.getNumberCardsDisplayed() * (184 + 24));
          }

          slider.css({
            'transform': 'translateX(' + x + 'px)',
            '-ms-transform': 'translateX(' + x + 'px)',
            '-webkit-transform': 'translateX(' + x + 'px)'
          });
        };
      }

    };
  });

(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.registration')
    .controller('InviteRequestSentCtrl', InviteRequestSentCtrl);

  function InviteRequestSentCtrl ($scope, sessionService) {
    /*jshint validthis: true */
    var ctrl = this;

    init();

    return ctrl;

    function init () {
      sessionService.logout();
    }
  }
}());

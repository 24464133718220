angular.module("app.marketplace.ui.office")
.controller("subscriptionReceiptController",
      function($scope, $timeout, $filter, elementService, errorService, sgToast, officeService, receipt) {
  $scope.receipt = receipt;
  $scope.printReceipt = function(){
    $timeout(function(){
      window.print();
    }, 1200);
  };
});

//Provides general services. Also includes some non-angular service funcitons.
angular.module("app.shared.support")
.run(function($rootScope, $timeout){
  // Attach useful nested checking object to window.
  // Fn can be used instead of an ugly nested checking if. if(obj && obj.test1 && obj.test1.test2 ...)
  window.checkNested = function(obj /* , level1, level2, ... levelN */) {
    var args = Array.prototype.slice.call(arguments, 1);

    for (var i = 0; i < args.length; i++) {
      if (!obj || obj[args[i]]===null || obj[args[i]]===undefined) {
        return false;
      }
      obj = obj[args[i]];
    }
    return true;
  };

  window.autofillDate = function (element, fieldName) {
    element[fieldName] = element[fieldName] === null ? null
                       : !element[fieldName] ? element[fieldName]
                       : new Date(element[fieldName]);
  };

  //
  // Check if value is 'empty'
  //
  window.isEmpty = function (value) {
    return value === undefined || value === null || value === '';
  };


  //
  // Quick 'n dirty string format function from here:
  // http://stackoverflow.com/questions/610406/javascript-equivalent-to-printf-string-format
  //
  if (!String.prototype.format) {
    String.prototype.format = function () {
      var args = arguments;
      return this.replace(/{(\d+)}/g, function (match, number) {
        return typeof args[number] !== 'undefined' ? args[number] : match;
      });
    };
  }
})



;

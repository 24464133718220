(function () {
  'use strict';

  angular
    .module("app.shared.system")
    .service('SessionStorageService', SessionStorageService);

  function SessionStorageService () {
    /*jshint validthis: true */
    var service = this;
    var appID = 'SWG-'; // String to go before every storage id.
    var sessionStorage = window.sessionStorage;

    service.get = supportCheckWrapper(get);
    service.set = supportCheckWrapper(set);
    service.remove = supportCheckWrapper(remove);
    service.clear = supportCheckWrapper(clear);
    service.isSupported = isSupported;

    return service;

    //
    // Build a storage key
    //
    function getStorageID (STORAGE_ID) {
      return (appID + STORAGE_ID);
    }

    //
    // Return a value from sessionStorage
    //
    function get (key) {
      var storageId = getStorageID(key);
      var storedValue = sessionStorage.getItem(storageId);
      return JSON.parse(storedValue);
    }

    //
    // Store a value in sessionStorage
    //
    function set (key, data) {
      var storageId = getStorageID(key);
      return sessionStorage.setItem(storageId, JSON.stringify(data));
    }

    //
    // Remove an item from sessionStorage
    //
    function remove (key) {
      var storageId = getStorageID(key);
      return sessionStorage.removeItem(storageId);
    }

    //
    // Clear _all_ items from sessionStorage
    //
    function clear () {
      return sessionStorage.clear();
    }

    //
    // A function wrapper that checks if sessionStorage is supported. If not,
    // it throws a readable error indicating what the issue is (rather than
    // whatever obscure error the browser might decide to throw).
    //
    function supportCheckWrapper (func) {
      return function () {
        if (isSupported()) {
          return func.apply(this, arguments);
        } else {
          throw (
              'Unable to detect sessionStorage support on this browser. Note: ' +
              'some browsers like Mobile Safari disable Storage API support when in ' +
              'private browsing mode. Please use SessionStorageService.isSupported() to ' +
              'check browser support.'
          );
        }
      };
    }

    //
    // Check if sessionStorage is supported.
    //
    // source: http://stackoverflow.com/questions/11214404/how-to-detect-if-browser-supports-html5-local-storage
    //
    function isSupported () {
      var mod = getStorageID('fghlkjgf;hlkjlsfgdgkhjns dflgfdhgifhfhOI G&*(ERHGPIUDRhgoiduhrg');
      try {
        sessionStorage.setItem(mod, mod);
        sessionStorage.removeItem(mod);
        return true;
      } catch (e) {
        return false;
      }
    }
  }
}());

(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.dashboard')
    .controller('dashboardActionBarController', dashboardActionBarController)
    .directive('dashboardActionBar', DashboardActionBar);

  function DashboardActionBar () {
    return {
      'restrict': 'AC',
      'templateUrl': 'templates/marketplace/dashboard/action-bar.html',
      'controller': 'InventoryActionBarController',
      'controllerAs': 'dashAbCtrl',
    };
  }

  function dashboardActionBarController () {
    /*jshint validthis: true */
    var ctrl = this;

    ctrl.newSdsDoc = newSdsDoc;

    init();

    return ctrl;

    function init () {

    }

    function newSdsDoc () {
      $rootScope.$broadcast('sds-new-doc');
    }
  }
}());

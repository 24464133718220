(function () {
  'use strict';

  angular.module('app.marketplace.ui.creditCards')
    .service('skyflowService', skyflowService);

  function skyflowService(skyflowVaultID, skyflowVaultURL, serverAPI, apiUrl, officeService, $log) {
    const service = this;

    // the API is Skyflow.default instead of simply Skyflow
    const SkyflowAPI = Skyflow.default || Skyflow;

    service.skyflowClient = null;

    service.insertNewCard = insertNewCard;

    initSkyFlowClient();

    return service;

    /**
     * Initiates Skyflow's SDK client object.
     * Had to be moved to a separate function because it depends on office_id, 
     * so it should be initialized every time office changes
     *
     */
     function initSkyFlowClient() {
      service.skyflowClient = SkyflowAPI.init({
        vaultID: skyflowVaultID,
        vaultURL: skyflowVaultURL,
        getBearerToken,
        options: {
          logLevel: SkyflowAPI.LogLevel,
          env: SkyflowAPI.Env,
        },
      });

      return service.skyflowClient;
    }

    /** 
     * Requests a Skyflow bearer token from our API.
     * This method is a property of the config
     * object we pass to SkyflowAPI.init because
     * SkyflowAPI will use it to generate a bearer
     * token every time it needs to make a request.
     * 
     * @return {String?}
    */
    async function getBearerToken() {
      const office_id = officeService.get().id;
      const url = `${apiUrl}/skyflow/${office_id}/get_access_token`;
      try {
        const res = await serverAPI.doAPICall(url, { method: 'GET' });
        return res.data.access_token;
      } catch (error) {
        $log.error(error);
      }
    }


    /** 
     * Uses Skyflow's API to insert a new card, then returns
     * the skyflow_id it gets back in the response. 
     * 
     * @param {Object} card 
     * 
     * @return {String?} 
    */
    async function insertNewCard(card) {
      const fields = _generateSkyflowFields(card);
      const records = _generateSkyflowRecords(fields);
      try {
        const res = await service.skyflowClient.insert(records);
        return res.records[0].fields.skyflow_id;
      } catch (error) {
        // throw the error so that the function which called
        // this method can handle it (since we may want to
        // handle it differently in different places)
        throw error;
      }
    }

    /** 
     * Parses a card to generate the fields which Skyflow expects. 
     * 
     * @param {Object} card 
     * 
     * @return {Object} 
    */
    function _generateSkyflowFields(card) {
      return {
        name: card.name,
        card_number: card.number,
        card_expiration_month: card.expiry.month,
        card_expiration_year: card.expiry.year,
        cvc: card.cvc,
      }
    }

    /** 
     * Generates a payload with the shape Skyflow expects. 
     * 
     * @param {Object} fields 
     * 
     * @return {Object} 
    */
    function _generateSkyflowRecords(fields) {
      return {
        records: [
          {
            table: 'card_details',
            fields,
          },
        ],
      }
    }

  }

}());

angular.module("app.shared.support")
  .filter('creditCardUrl', function () {
    return function (card_type) {
      if (!card_type) {
        return null;
      }
      var path = 'styles/img/credit_cards/';
      var _card = card_type.toLowerCase().replace(/ /g, '');
      var cards = [
        'visa',
        'mastercard',
        'americanexpress',
        'discover',
        'jcb',
        'dinersclub',
        'unionpay',
      ];

      var card_key = _.includes(cards, _card) ? _card : 'unknowncard';

      path += card_key + '.svg';

      return path;
    };
  });
(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.registration')
    .controller('VerifyInviteCtrl', VerifyInviteCtrl);

  function VerifyInviteCtrl ($filter, $rootScope, $state, $stateParams, registrationService, errorService, accountService, AccountModalService, sessionService, membershipService) {
    /*jshint validthis: true */
    var ctrl = this;

    return initialize();

    //
    // Handle this page when no token is provided.
    //
    function handleMissingToken () {
      return $state.go('app.registration.start');
    }

    //
    // Handle this page when provided token is invalid.
    //
    function handleInvalidToken () {
      var t_message = $filter('translate')('ERRORS.INVITE_TOKEN_VERIFY')
      errorService.uiErrorHandler(t_message);
      return $state.go('app.registration.start');
    }

    //
    // Initialize the controller
    //
    function initialize () {
      if (!$stateParams.token) {
        return handleMissingToken();
      }

      var isSignedIn = !!$rootScope.current_account;

      return registrationService.getUserInvitation($stateParams.token).then(function (user_invite) {
        //
        // If this invite is for the user, and they are logged in, redeem it, and switch to that office.
        //
        if (isSignedIn && $rootScope.current_account.email === user_invite.email) {
          return accountService.acceptMembershipInvite(user_invite.id).then(function () {
            return sessionService.refreshSession().then(function (session) {
              return membershipService.setMembershipFromOfficeId(user_invite.office_id).then(function () {
                return $state.go('app.inventory');
              });
            });
          });
        }

        //
        // If there is a user signed in, and the email does not match the
        // invite, go to an error page.
        //
        if (isSignedIn && $rootScope.current_account.email !== user_invite.email) {
          return $state.go('app.invitation.is_signed_in_error');
        }

        //
        // If there is no user signed in, and no user_id on the invite, give
        // the user a form to register via the invite.
        //
        if (!isSignedIn && !user_invite.user_id) {
          return $state.go('app.invitation.register', {'invite': user_invite});
        }

        //
        // If there is no user signed in, and there is a user_id on the invite,
        // give that user the ability to login, and redeem the invite.
        //
        if (user_invite.user_id) {
          return AccountModalService.show('Login', null, {'invite_token': user_invite.token});
        }
      }).catch(function (error) {
        errorService.uiErrorHandler(error);
        return handleInvalidToken();
      }).then(undefined, function (error) {
        errorService.uiErrorHandler(error);
      });
    }
  }

}());

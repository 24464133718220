(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.registration')
    .service('AssociationVerificationDialog', AssociationVerificationDialog);

  function AssociationVerificationDialog ($q, errorService, $mdDialog) {
    /*jshint validthis: true */
    var service = this;

    service.show = showDialog;

    return service;

    function showDialog (association, config, $event) {
      return $mdDialog.show({
        'controller': AssociationVerificationDialogCtrl,
        'controllerAs': '$ctrl',
        'templateUrl': 'templates/marketplace/registration/association-verification-dialog.html',
        'parent': angular.element(document.body),
        'targetEvent': $event,
        'fullscreen': true,
        'escapeToClose': false,
        'locals': {Association: config},
      });
    }
  }

  /* @ngInject */
  function AssociationVerificationDialogCtrl ($scope, $q, $timeout, $injector, $mdDialog, Association, errorService) {
    /*jshint validthis: true */
    var ctrl = this;

    $scope.data = {};
    $scope.association = Association;
    $scope.btnPrefix = 'verifyAssociationDialog';

    ctrl.errors = {};

    ctrl.cancel = cancelDialog;
    ctrl.verify = verifyMembership;

    return ctrl;

    //
    // Reset all errors
    //
    function resetErrors () {
      _.each(ctrl.errors, function(error, name){
        ctrl.errors[name] = false;
      });
    }

    //
    // A wrapper for toggling $scope.saving on/off.
    //
    function saving (func) {
      var deferred = $q.defer();
      $scope.saving = true;

      func()
        .then(deferred.resolve)
        .catch(deferred.reject)
        .finally(function () { 
          $scope.saving = false;
        });

      return deferred.promise;
    }

    //
    // I am Jack's Cancel Dialog Function
    //
    function cancelDialog () {
      $mdDialog.cancel();
    }

    //
    // Verify The Membership Credentials
    //
    function verifyMembership () {
      if (!$scope.VerificationForm.$valid) {
        $scope.VerificationForm.$setSubmitted();
        $timeout(function () {
          var scrollPane = $('[name="VerificationForm"] md-dialog-content');
          var scrollElem = $('[name="VerificationForm"] md-input-container.md-input-invalid').first();
          var topOfElement = scrollElem.offset().top - parseInt(scrollElem.css('margin-top')) - parseInt('16px');
          var topOfScrollPane = scrollPane.scrollTop() - scrollPane.offset().top;
          scrollPane.animate({'scrollTop': topOfScrollPane + topOfElement});
        }, 100);
        return;
      }

      resetErrors();

      saving(function () {
        return Association.verify($scope.data, $injector)
        .then(function (payload) {
          if (payload.result) {
            $mdDialog.hide(payload.data);
          } else {
            ctrl.errors.bad_credentials = true;
          }
        })
        .catch(function (error) {
          errorService.uiErrorHandler(error);
          ctrl.errors.server_error = true;
        })
        .finally(function(){
          var scrollPane = $('[name="VerificationForm"] > md-dialog-content');
          scrollPane.animate({scrollTop: scrollPane[0].scrollHeight});
        });
      });
    }
  }

}());

angular.module('app.marketplace.ui.shopping.search').controller('SearchController',
  function SearchController($scope, $stateParams, elementService, searchService, appEvents) {
    $scope.orderField = {};

    var getSearch = function(query, force){
      searchService.getSearch(query, force).then(function(searchElements){
        $scope.products = searchElements.products;
        $scope.product_templates = searchElements.product_templates;
        $scope.inventory_items = searchElements.inventory_items;
      });
    };

    if ($stateParams.urlQuery) {
      $scope.urlQuery = decodeURIComponent($stateParams.urlQuery);

      $scope.$on('officeService: set-office', function () {
        getSearch($scope.urlQuery, true);
      });

      $scope.$on(appEvents.clearSession, function () {
        getSearch($scope.urlQuery, true);
      });

      getSearch($scope.urlQuery);
    }

    $scope.setSort = function (type, field) {
      $scope.orderField[type] = field;
    };

    // Clear search bar and navigate to shopping home.
    $scope.clearSearch = function(){
      $scope.$emit('clearSearch');
      $scope.$state.go('app.shopping');
    };
  }
);

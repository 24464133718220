(function () {
    'use strict';
    angular.module('app.marketplace.ui.implants')
    .controller('ImplantEditRemovalController', ImplantEditRemovalController)
    .directive('sowSlideoutImpEditRemoval', sowSlideoutImpEditRemoval);

    function sowSlideoutImpEditRemoval () {
      return {
        replace: true,
        restrict: 'E',
        templateUrl: 'templates/marketplace/implants/directives/edit-removal-slideout.html',
        controller: 'ImplantEditRemovalController',
        controllerAs: 'ersCtrl',
        // link: function ($scope, $element, $attrs) {}
      };
    }

    function ImplantEditRemovalController ($scope, $window, $mdSidenav, $filter, errorService, appEvents, sgToast, sowImplantService, referringDoctorsService, impHelperService, membershipService, formService) {
      /*jshint validthis: true*/
      var ctrl = this;
      ctrl.sidenav_name = 'edit-implant-removal';

      ctrl.close = close;
      ctrl.saveItem = saveItem;
      ctrl.selectTooth = selectTooth;
      ctrl.openCalendar = openCalendar;
      init();

      return ctrl;

      function init () {
        $scope.$on(appEvents.impEditRemoval, open);
        $scope.$on(appEvents.impEditRemovalFetch, openFetch);
      }

      function open (event, item) {
        ctrl.item = angular.copy(item);
        loadOfficeData();
        defineLocks();
        return $mdSidenav(ctrl.sidenav_name).open();
      }

      function close () {
        setEmptyState();
        return $mdSidenav(ctrl.sidenav_name).close();
      }

      function setEmptyState () {
        _.set(ctrl, 'item', null);
      }

      function openFetch ($event, id, event_item) {
        ctrl.loading = true;
        event_item = event_item || {};
        
        return sowImplantService.getInventoryItem(id)
        .then(function(response){
          ctrl.item = angular.extend({}, ctrl.item, event_item, response);
          return open($event, ctrl.item);
        })
        .catch(errorService.uiErrorHandler)
        .finally(function(){
          ctrl.loading = false;
        });
      }

      function loadOfficeData () {
        sowImplantService.getImplantUsers().then(function(users){
          ctrl.office_users = impHelperService.getMedicUsers(users);
        })
        .catch(function(error){
          const t_message = $filter('translate')('ERRORS.OFFICE_USERS_LOAD');
          errorService.uiErrorHandler(error.message || t_message);
        });

        referringDoctorsService.getReferringDoctors()
        .then(function (doctors) {
          ctrl.referring_doctors = doctors;
        }).catch(function (error) {
          const t_message = $filter('translate')('ERRORS.DOCTORS_RETRIEVE');
          errorService.uiErrorHandler(error || t_message, 3000);
        });


        var countryId;
        countryId = sow.officeInfo().address.country.id;
        ctrl.current_tooth_format = impHelperService.getToothPlacementFormats(countryId, ctrl.item.tooth_placement_format);
      }

      function updateReferringDoc (item) {
        var selected_doc = _.find(ctrl.referring_doctors, ['id', item.referring_doctor_id]);
        if(!selected_doc) return;
        _.set(item, 'referring_doctor_name', selected_doc.name);
      }

      function selectTooth (number, section_v, section_h) {
        ctrl.item.tooth_placement_value = number;
        ctrl.teeth_section_v = section_v;
        ctrl.teeth_section_h = section_h;
      }

      function openCalendar($event){
        $event.stopPropagation();
        $timeout(function() {
          var thisElem = $($event.currentTarget)[0];
          var datepicker = $(thisElem).siblings('md-datepicker')[0];
          $(datepicker)[0].children[0].click();
        }, 400);
      }

      function saveItem () {
        if(!formService.checkRequiredFields(ctrl.removeImplantForm)){
          return 0;
        }
        
        updateReferringDoc(ctrl.item);
        // failproof for saving empty reservation IDs
        ctrl.item.reservation_id = _.trim(ctrl.item.reservation_id);
        ctrl.item.was_placed = true;
        ctrl.item.tooth_placement_value = ctrl.item.tooth_placement_value || '';

        ctrl.loading = true;
        
        return sowImplantService.saveInventoryItem(ctrl.item)
        .then(function(response){
          // TO-DO: toast message
          var t_message = $filter('translate')('TOAST.IMPLANT_CHANGES_SAVED');
          sgToast.showSimple(t_message);
          return close();
        })
        .catch(errorService.uiErrorHandler)
        .finally(function(){
          ctrl.loading = false;
        });
        
      }

      function defineLocks() {
        const membership = membershipService.get();
        const admin_tools_lock_condition = _.get(membership, "user_properties.admin_tools.disable_page", false);
        ctrl.disabled_admin_tools_page = admin_tools_lock_condition;

        const hide_report_prices_condition = _.get(membership, 'user_properties.reports.hide_report_prices', false);
        ctrl.hide_report_prices = hide_report_prices_condition;
      }

    }

}());

(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.getting-started')
    .controller('gettingStartedDialogCtrl', gettingStartedDialogCtrl)
    .directive('animateSwap', function($animate, $rootScope){
        return {
          restrict: 'A',
          transclude: 'element',
          terminal: true,
          priority: 600, // we use 600 here to ensure that the directive is caught before others
          link: function(scope, $element, attrs, ctrl, $transclude) {
            var previousElement, previousScope, previous_step;
            scope.$watchCollection(attrs.animateSwap || attrs['for'], function(value) {
              if (previousElement) {
                previous_step = previousElement ? parseInt(previousElement[0].attributes[2].nodeValue) : -1;
                if (previous_step !== -1) {
                  previousElement[0].className += " " + previous_step > value ? ' from-right' : ' from-left';
                }
                $animate.leave(previousElement);
              }
              if (previousScope) {
                previousScope.$destroy();
                previousScope = null;
              }

              if (value || value === 0) {
                previousScope = scope.$new();
                $transclude(previousScope, function(element) {
                  previous_step = previousElement ? parseInt(previousElement[0].attributes[2].nodeValue) : -1;
                  if(previous_step !== -1){
                    element[0].className += " " + previous_step > value ? ' from-right' : ' from-left';
                  }
                  previousElement = element;
                  $animate.enter(element, null, $element)
                    .then(function () {
                      element[0].classList.remove(previous_step > value ? 'from-right' : 'from-left');
                    });
                });
              }
            });
          }
        };
    });

  //
  // "Getting Started" Dialog Controller
  //
  /* @ngInject */
  function gettingStartedDialogCtrl ($scope, $state, $window, $mdDialog, gettingStartedDialog) {
    /*jshint validthis: true */
    var ctrl = this;
    var clickBinding = null;

    ctrl.step = 0;
    ctrl.close = closeDialog;
    ctrl.isAaoms = isAaoms;
    ctrl.hide = hide;
    ctrl.next_step = next_step;
    ctrl.previous_step = previous_step;
    ctrl.go_to_step = go_to_step;

    init();


    return ctrl;

    function init () {
      clickBinding = angular.element(document).on('click', clickHandler);
    }

    function next_step(){
      ctrl.step++;
    }

    function previous_step(){
      ctrl.step--;
    }

    function go_to_step(step){
      ctrl.step = step;
    }

    function isAaoms() {
      var membership = $scope.$root.current_office.association_membership || {};

      if (membership && membership.professional_association_id === 'aaoms') {
        return true;
      }

      return false;
    }

    //
    // Close the dialog
    //
    function closeDialog () {
      $mdDialog.cancel();
    }

    function hide () {
      $mdDialog.hide();
    }

    function clickHandler (ev) {
      var sref = _.get(ev, 'currentTarget.activeElement.attributes.ui-sref.nodeValue');
      if (sref) {
        closeDialog();
        if (clickBinding) {
          clickBinding();
        }
      }
    }
  }
}());

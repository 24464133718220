angular.module('app.marketplace.ui.cart')

.controller('checkoutController', function($scope, $filter, $log, sgToast, errorService, smoothScroll, cartService, officeService, legalService) {
  var _this = this;

  var CartItems = $scope.CartItems = {
    _timers: {},
    _oldQuantities: {},

    focusQuantity: function (item) {
      if (_.isNumber(item.quantity)) {
        CartItems._oldQuantities[item.id] = item.quantity;
      }
    },

    //
    // Remove An Item From the Cart
    //
    remove: function (item) {
      cartService.removeCartItem(item);
    },

    //
    // Return to stored quantity
    //
    revertQuantity: function (item) {
      item.quantity = CartItems._oldQuantities[item.id];
    },

    //
    // Submit Updates to a Cart Item
    //
    updateQuantity: function (item) {
      $scope.flags.cartItemsUpdating = true;

      if (!item.quantity && item.quantity !== 0) {
        $log.debug('DEBUG: Blank Field. Reverting Quantity.');
        $scope.$apply(function () {
          CartItems.revertQuantity(item);
          $scope.flags.cartItemsUpdating = false;
        });
        return;
      }

      cartService.updateCartItem(item, true).then(function(response){
        var t_message = $filter('translate')('TOAST.ITEM_UPDATED');
        sgToast.showSimple(t_message);
      }).catch(function(error) {
        errorService.uiErrorHandler(error);
      })['finally'](function () {
        $scope.flags.cartItemsUpdating = false;
      });
    }

  };


  //
  // Convenience Debug Logger
  //
  var _debug = function () {
    var args = Array.prototype.slice.call(arguments, 1);
    args[0] = sprintf('checkoutController: %s: %s', arguments[0], args[0]);
    return $log.debug.apply(undefined, args);
  };

  //
  // Reset all error flags to false
  //
  _this.resetErrors = function () {
    angular.forEach(_this.errors, function (value, key) {
      _this.errors[key] = false;
    });
  };

  //
  // Check if any error flags are set to true
  //
  _this.hasErrors = function () {
    var hasErrors = false;
    angular.forEach(_this.errors, function (value) {
      hasErrors = hasErrors || !!value;
    });
    return hasErrors;
  };

  //
  // Reset and update all error flags
  //
  _this.checkForErrors = function () {
    _this.resetErrors();
    angular.extend(_this.errors, {
      agree_to_terms_unchecked: !$scope.cart.agreeToTerms,
      credit_card_not_selected: !(
        $scope.cart.billing_info.card_id
        // TODO Check that the card id is valid?
      ),
      shipping_address_incomplete: !(
          $scope.cart.shipping_info &&
          $scope.cart.shipping_info.address1 &&
          $scope.cart.shipping_info.city &&
          $scope.cart.shipping_info.postal_code &&
          $scope.cart.shipping_info.province &&
          $scope.cart.shipping_info.country
      ),
      promoCodeNotApplied: _this.needToApplyPromoCode()
    });
    return _this.hasErrors();
  };

  //
  // Check if the promo code field has an unapplied promo code in it.
  //
  _this.needToApplyPromoCode = function () {
    return ($scope.cart.promo_code.code || $scope.cart.promo_code.curr_code) && $scope.cart.promo_code.code !== $scope.cart.promo_code.curr_code;
  };

  function handleOpenForm (openFlag, msgFlag, sectionId) {
    $scope.flags[msgFlag] = true;

    // The next time that the form is closed:
    //  - Reset the msgFlag to false
    //  - Remove this watcher
    var deregister = $scope.$watch('flags.' + openFlag, function (value) {
      if (!value) {
        $scope.flags[msgFlag] = false;
        deregister();
      }
    });

    smoothScroll.scrollToElementById(sectionId);
  }

  //
  // Checkout Cart / Complete Order
  //
  _this.completeOrder = function () {
    //
    // Make sure the "Edit Shipping Address" form is not open
    //
    if ($scope.flags.editingShippingInfo) {
      handleOpenForm('editingShippingInfo', 'showShippingAddressMsg', 'checkout-address-section');
      return;
    }

    //
    // Make sure the "Add Credit Card" form is not open
    //
    if ($scope.flags.addingCreditCard) {
      handleOpenForm('addingCreditCard', 'showBillingFormMsg', 'checkout-billing-section');
      return;
    }


    var hasErrors = _this.checkForErrors();
    if (hasErrors) {
      return;
    }
    $scope.apiErrors = null;
    $scope.flags.cartCheckingOut = true;

    cartService.completeCart($scope.cart).then(function(response) {
      $scope.flags.cartComplete = true;
      $scope.flags.cartCheckingOut = false;
      smoothScroll.scrollToElementById('initial');
    }).catch(function(error) {
      if(error && error.errors){
        $scope.apiErrors = error.errors;
      }
      $scope.cart.agreeToTerms = false;
      $scope.flags.cartCheckingOut = false;
    });
  };

  //
  // Should we lock the cart?
  //
  _this.shouldLockCart = function () {
    return (
      $scope.flags.cartCheckingOut ||
      $scope.flags.cartItemsUpdating ||
      $scope.flags.applyingPromoCode ||
      $scope.flags.cartIsEmpty ||
      $scope.flags.cartComplete ||
      $scope.flags.updatingBilling ||
      $scope.flags.updatingShipping
    );
  };

  //
  // Reset the Promo Code Field (remove error state)
  //
  function resetPromoCodeField () {
    $scope.PromoCodeForm.promo_code.$setValidity('api_error', true);
    $scope.promoCodeErrorMsg = null;
  }

  //
  // Remove a Promo Code
  //
  _this.removePromoCode = function () {
    console.debug('$scope.cart.promo_code = %', $scope.cart.promo_code);

    if (!$scope.cart.promo_code) {
      return;
    }

    $scope.flags.applyingPromoCode = true;

    cartService.removePromoCode()
    .then(function (response) {
      var t_message = $filter('translate')('TOAST.PROMO_CODE_REMOVED');
      sgToast.showSimple(t_message);
      $scope.PromoCodeForm.$setPristine();
      $scope.flags.applyingPromoCode = false;
      resetPromoCodeField();
    }).catch(function (error) {
      var t_message = $filter('translate')('ERRORS.PROMO_REMOVE')
      errorService.uiErrorHandler(t_message, 0);
      $scope.flags.applyingPromoCode = false;
    });
  };

  //
  // Apply a Promo Code
  //
  _this.applyPromoCode = function () {
    if (!$scope.cart.promo_code.code) {
      return false;
    }

    $scope.PromoCodeForm.promo_code.$setValidity('api_error', true);
    $scope.flags.applyingPromoCode = true;

    cartService.updatePromoCode($scope.cart.promo_code.code)
    .then(function (response) {
      var t_message = $filter('translate')('TOAST.PROMO_CODE_APPLIED');
      sgToast.showSimple(t_message);
      $scope.PromoCodeForm.$setPristine();
      $scope.flags.applyingPromoCode = false;
    }).catch(function (error) {
      $scope.PromoCodeForm.promo_code.$setValidity('api_error', false);
      $scope.promoCodeErrorMsg = error.message;

      var t_message = $filter('translate')('ERRORS.PROMO_ADD')
      errorService.uiErrorHandler(t_message, 0);
      $scope.flags.applyingPromoCode = false;
    });
  };

  //
  // Controller Initialization
  //
  _this.init = function () {
    _this.errors = {};

    angular.extend($scope, {
      errors: _this.errors,
      flags: {
        cartLocked: false,
        cartIsEmpty: false,
        cartComplete: false,
        cartCheckingOut: false,
        cartItemsUpdating: false,
        applyingPromoCode: false,

        editingShippingInfo: false,
        showShippingAddressMsg: false,

        addingCreditCard: false,
        showBillingFormMsg: false
      },
      resetPromoCodeField: resetPromoCodeField,
      removePromoCode: _this.removePromoCode,
      applyPromoCode: _this.applyPromoCode,
      needToApplyPromoCode: _this.needToApplyPromoCode,
      completeOrder: _this.completeOrder,

      //
      //
      //
      showTerms: function ($event) {
        $event.stopPropagation();
        legalService.showTermsModal($event);
      }
    });

    $scope.$watch('cart.billing_info.card_id', function (new_val, old_val) {
      if (new_val !== old_val && $scope.cart && !$scope.flags.cartCheckingOut && !$scope.flags.cartComplete) {
        var successMsg = null;

        if (old_val && !new_val) {
          successMsg = 'Card Removed';
        } else if (new_val) {
          successMsg = 'Card Selected';
        }
        
        $scope.flags.updatingBilling = true;

        cartService.updateBilling(
          $scope.cart.billing_info.card_id
        ).then(function (response) {
          if (successMsg) {
            sgToast.showSimple(successMsg);
          }
        }).catch(function (error) {
          cartService.refreshCart();
          var t_message = $filter('translate')('ERRORS.CARD_SELECT')
          errorService.uiErrorHandler(t_message, 0);
        })['finally'](function () {
          $scope.flags.updatingBilling = false;
        });
      }
    });

    $scope.$on('cartService: set-cart', function (event, cart) {
      $scope.cart = cart;
    }, true);

    $scope.$on('cartService: clear-cart', function (event) {
      $scope.cart = null;
    });

    //
    // Dynamically Update the cartLocked flag
    //
    $scope.$watch(function () { return _this.shouldLockCart(); },
                  function () { $scope.flags.cartLocked = _this.shouldLockCart(); });

    //
    // Wait for office to be loaded (prevents race conditions on
    // initialization).
    //
    officeService.get(true).then(function(office){
      $scope.cart = cartService.get();
    }).catch(function(error){
      var t_message = $filter('translate')('ERRORS.API_FAIL')
      errorService.uiErrorHandler(t_message, 0);
    });
  };

  _this.init();
  // _debug('init', '$scope.flags.cartComplete = %o', $scope.flags.cartComplete);
});

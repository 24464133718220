(function () {
  'use strict';
  
  angular
  .module('sowReports')
  .controller('ImplantsReportController', ImplantsReportController);
  
  function ImplantsReportController ($q, $state, $filter, $rootScope, errorService, sowImplantService, $timeout) {
    /*jshint validthis: true */
    var ctrl = this;
    
    ctrl.loading = true;
    ctrl.datasets = {
      'implants': {
        color: "#398ee3",
        product_name: 'Implant',
        api_name: 'Implant',
        set_name: 'implants',
        display_name: $filter('translate')('IMPLANTS.IMPLANTS'),
        icon:{
          svg: "styles/img/implants/implants-icon-v3.svg",
        }
      },
      'caps': {
        color: "#7a41cb",
        product_name: 'Cover Screw',
        api_name: 'Closure Cap',
        set_name: 'caps',
        display_name: $filter('translate')('IMPLANTS.COVER_SCREWS'),
        icon:{
          svg: "styles/img/implants/caps-icon.svg",
        }
      },
      'abutments': {
        color: "#2eb479",
        product_name: 'Healing Abutment',
        api_name: 'Healing Abutment',
        set_name: 'abutments',
        display_name: $filter('translate')('IMPLANTS.HEALING_ABUTMENTS'),
        icon:{
          svg: "styles/img/implants/healing-icon.svg",
        }
      },
      'bonegrafts': {
        color: "#33bbc4",
        product_name: 'Bone Graft',
        api_name: 'Bone Graft',
        set_name: 'bonegrafts',
        display_name: $filter('translate')('IMPLANTS.BONE_GRAFTS'),
        icon:{
          material: "grain",
        }
      },
      'membranes': {
        color: "#ff2566",
        product_name: 'Membrane',
        api_name: 'Membrane',
        set_name: 'membranes',
        display_name: $filter('translate')('IMPLANTS.MEMBRANES'),
        icon:{
          material: "healing",
        }
      }
    };
    ctrl.totals = {
      implants:  { total_qty: 0, total_spent: 0, consignment: 0, },
      caps:      { total_qty: 0, total_spent: 0, consignment: 0, },
      abutments: { total_qty: 0, total_spent: 0, consignment: 0, },
      bonegrafts:  { total_qty: 0, total_spent: 0, consignment: 0, },
      membranes:  { total_qty: 0, total_spent: 0, consignment: 0, },
    };
    ctrl.report_data = {
      implants:  [],
      caps:      [],
      abutments: [],
      bonegrafts: [],
      membranes: [],
    };
    ctrl.months = [];
    ctrl.selected_year = moment.utc().format("YYYY");
    ctrl.years_list = [];
    ctrl.period_description = '';
    ctrl.office_first_date = moment.utc(_.get($rootScope, 'current_office.implant_start_date'));
    if(!ctrl.office_first_date._isValid){
      ctrl.office_first_date = moment.utc();
    }
    ctrl.products = [];
    ctrl.displayMonths = [];
    ctrl.sortOrder = '-month';
    ctrl.searchText = '';
    ctrl.state = 'report';
    ctrl.selectedRange = 'This Year';
    ctrl.rangeOptions = ['This Year', 'All Time'];
    ctrl.current_dataset = "implants";
    
    ctrl.updateYear = updateYear;
    ctrl.changeDataset = changeDataset;
    
    initialize();
    
    return ctrl;
    
    //
    // Initialize the Controller
    //
    function initialize () {
      ctrl.totals = {
        implants:  { total_qty: 0, total_spent: 0, consignment: 0, average: 0},
        caps:      { total_qty: 0, total_spent: 0, consignment: 0, average: 0},
        abutments: { total_qty: 0, total_spent: 0, consignment: 0, average: 0},
        bonegrafts: { total_qty: 0, total_spent: 0, consignment: 0, average: 0},
        membranes: { total_qty: 0, total_spent: 0, consignment: 0, average: 0},
      };
      ctrl.months = [];
      
      generateYearsList();
      loadData();
      initChart();
    }
    
    function initChart () {
      var month_names = _.map(Array(12), function(x, index){
        x = moment().month(index).format('MMM');
        return x;
      });
      ctrl.chart = {
        // data: [0,0,0,0,0,0,0,0,0,0,0,0],
        'labels': month_names,
        'datasets': {
          'caps': [0,0,0,0,0,0,0,0,0,0,0,0],
          'implants': [0,0,0,0,0,0,0,0,0,0,0,0],
          'abutments': [0,0,0,0,0,0,0,0,0,0,0,0],
          'bonegrafts': [0,0,0,0,0,0,0,0,0,0,0,0],
          'membranes': [0,0,0,0,0,0,0,0,0,0,0,0],
        },
        'options': {
          'responsive': true,
          'maintainAspectRatio': true,
          'tooltips': {
            'enabled': true,
            'displayColors': false,
            'mode': 'single',
            'titleFontFamily': "'Roboto', 'Helvetica', 'Arial', 'Sans-Serif'",
            'footerFontFamily': "'Roboto', 'Helvetica', 'Arial', 'Sans-Serif'",
            'bodyFontFamily': "'Roboto', 'Helvetica', 'Arial', 'Sans-Serif'",
          },
          'scales': {
            'xAxes': [{
              'gridLines': {
                'display': false,
                'drawTicks': false,
                'color': 'rgba(128,142,155,0.25)',
                'zeroLineColor': 'rgba(128,142,155,0.25)',
              },
              'ticks': {
                'fontFamily': "'Roboto', 'Helvetica', 'Arial', 'Sans-Serif'",
                'beginAtZero': true,
              },
            }],
            'yAxes': [{
              'gridLines': {
                'display': true,
              },
              'ticks': {
                'stepSize': 5,
                'fontFamily': "'Roboto', 'Helvetica', 'Arial', 'Sans-Serif'",
                'beginAtZero': true,
              },
            }]
          },
          dataset: {
            barThickness: 10,
          },
          'onClick': function(MouseEvent, ChartElements){
            var myChart = this;
            
            var activePoints = myChart.getElementsAtEvent(MouseEvent);
            var firstPoint = activePoints[0] || null;
            var elements = myChart.getElementsAtXAxis(MouseEvent);
            var firstElement = elements[0] || null;
            
            var element = firstPoint || firstElement;
            if(!element){  
              return;
            }
            
            var label = myChart.data.labels[element._index];
            var value = myChart.data.datasets[element._datasetIndex].data[element._index];
            var month_date = moment.utc().month(label).year(ctrl.selected_year).date('01').format('YYYY-MM-DD');
            
            $state.go('app.reports.implants-detail',({month_filter: month_date, type: ctrl.datasets[ctrl.current_dataset].api_name}));
          },
        },
        'overrides': {
          'borderWidth': 0,
          'borderColor': ctrl.datasets[ctrl.current_dataset].color,
          'backgroundColor': ctrl.datasets[ctrl.current_dataset].color,
          'hoverBackgroundColor': '#de4745',
        }
      };
    }
    
    function loadData () {
      ctrl.loading = true;
      
      var year = null;
      if(ctrl.months.length > 0){
        year = moment(ctrl.months[0].date).format('YYYY');
      } else {
        year = ctrl.selected_year ? ctrl.selected_year : moment().format('YYYY');
      }
      ctrl.period_description = 'January ' + year + ' - December ' + year;
      
      
      var promises = [];
      
      promises = _.map(Object.keys(ctrl.datasets), function(dataset, index){
        return sowImplantService.yearlyReportData({year: ctrl.selected_year, category: ctrl.datasets[dataset].api_name})
        .catch(function (error){
          var t_prefix = $filter('translate')('ERRORS.FAILED_TO_LOAD')
          var t_suffix = $filter('translate')('ERRORS.DATA')
          errorService.uiErrorHandler(t_prefix+dataset+t_suffix);
        });
      });
      
      $q.all(promises)
      .then(function(responses){
        
        _.forEach(Object.keys(ctrl.datasets), function(dataset, index){
          var response = responses[index];
          ctrl.report_data[dataset] = response;
          fetchTotals(dataset, ctrl.report_data[dataset]);
        });
        
        $timeout(function() {
          // wait for digest cycle to end
          changeDataset(ctrl.current_dataset);
          ctrl.loading = false;
        }, 300);
        
      })
      .catch(function(error){
        var t_message = $filter('translate')('ERRORS.REPORT_DATA_LOAD')
        errorService.uiErrorHandler(t_message);
      });
    }
    
    function fetchTotals (category, data) {
      // ctrl.totals[category]
      _.forEach(data, function(item){
        item.name = moment.utc(item.month).format('MMMM, YYYY');
        item.month_date = moment.utc(item.month).format('YYYY-MM-DD');
        
        ctrl.totals[category].total_spent += parseFloat(item.total_spend);
        ctrl.totals[category].consignment += (item.total_consignment_placed * 1);
        ctrl.totals[category].total_qty   += (item.total_placed * 1);
        
        var month_label = moment.utc(item.month).format('MMM');
        var position = ctrl.chart.labels.indexOf(month_label);
        ctrl.chart.datasets[category][position] = item.total_placed;
      });
      ctrl.totals[category].average = Math.floor(ctrl.totals[category].total_qty / data.length) || 0;
    }
    
    function changeDataset (dataset) {
      ctrl.current_dataset = dataset;
      _.set(ctrl, "months", ctrl.report_data[dataset]);
      
      _.set(ctrl, "chart.overrides.borderColor", ctrl.datasets[dataset].color);
      _.set(ctrl, "chart.overrides.backgroundColor", ctrl.datasets[dataset].color);
    }
    
    function updateYear () {
      initialize();
    }
    
    function generateYearsList () {
      ctrl.years_list = [];
      
      var first_year = ctrl.office_first_date.format("YYYY") * 1;
      var current_year = moment.utc().format("YYYY") * 1;
      
      for (var i = first_year; i <= current_year; i++) {
        ctrl.years_list.push(i+'');
      }
    }
  }
}());

(function () {
  'use strict';

  /** @ngInject */
  class ExternalVendorAccountService {
    microserviceUrl;
    constructor(appEvents, errorService, ExternalVendorAccountCP, externalVendorApiUrl, membershipService, msHelperService, serverAPI, sgToast, VendorInventoryItemCP, officeService) {
      this.appEvents = appEvents;
      this.errorService = errorService;
      this.ExternalVendorAccount = ExternalVendorAccountCP.getClass();
      this.membershipService = membershipService;
      this.microserviceUrl = externalVendorApiUrl;
      this.msHelperService = msHelperService;
      this.officeService = officeService;
      this.serverAPI = serverAPI;
      this.sgToast = sgToast;
      this.VendorInventoryItem = VendorInventoryItemCP.getClass();
    }

    _getMembership () {
      return this.membershipService.onInit();
    }
    
    /**
     * > This function gets the accounts for a given office
     * @param officeId - the officeId to get the accounts for. If not provided, the current officeId
     * will be used.
     * @param forceInvalidate - If true, the cache will be ignored and the API will be called.
     * @returns A promise that resolves to an array of ExternalVendorAccount objects.
     */
    async getAccounts (forceInvalidate, officeId) {
      const method = forceInvalidate ? this.serverAPI.callAPI : this.serverAPI.callCacheAPI;
      const options = {'method': 'GET', 'params': {}};

      return this._getMembership()
      .then((membership) => {
        officeId ??= membership.office.id;
        const url = `${this.microserviceUrl}/vendor_integrations/v1/accounts?office_id=${membership.office.id}`;
        return method(url, options)
        .then(accounts_dict_json => {
          // TODO: fire appEvents.externalVendorAccountsLoaded
          _.each(accounts_dict_json, (account) => {
            account = new this.ExternalVendorAccount(account);
          });
          return accounts_dict_json;
        });
      });
    }

    /**
     * It returns a promise that resolves to an ExternalVendorAccount object
     * @param vendor - the vendor name
     * @param officeId - The officeId of the office you want to get the external vendor account for. If
     * you don't pass this in, it will use the current officeId.
     * @returns A promise that resolves to an ExternalVendorAccount object.
     */
    getOneAccount (vendor, officeId) {
      const options = {'method': 'GET', 'params': {}};
      return this._getMembership()
      .then((membership) => {
        officeId ??= membership.office.id;
        const url = `${this.microserviceUrl}/vendor_integrations/v1/accounts?office_id=${membership.office.id}&vendor_id=${vendor.id}`;
        return this.serverAPI.makeConcurrentCall(url, options)
        .then(response => {
          // TODO: fire appEvents.externalVendorAccountSingleLoaded
          return new this.ExternalVendorAccount(response);
        });
      });
    }

    /**
     * It takes a credential object and a vendor object, and returns a promise that resolves to an
     * ExternalVendorAccount object
     * @param credentials - the account object that you want to add.
     * @param vendor - The vendor object that you want to add the account to.
     * @returns A promise that resolves to an ExternalVendorAccount object.
     */
    linkAccount (credentials, vendor) {
      const url = `${this.microserviceUrl}/vendor_integrations/v1/${vendor?.connected_name}/link`;
      const options = {'method': 'POST', 'data': credentials };
      
      return this.serverAPI.makeConcurrentCall(url, options)
      .then(response => {
        // TODO: fire appEvents.externalVendorAccountAdded
        return new this.ExternalVendorAccount(response);
      });
    }

    /**
     * The function removes an account from the server
     * @param account - the account object that was returned from the getAccounts() call
     * @param vendor - the vendor object
     * @returns A promise that resolves to true.
     */
    unlinkAccount (account) {
      const vendorId = account.vendor_id;
      const options = {'method': 'DELETE'};
      
      return this._getMembership()
      .then((membership) => {
        const officeId = membership.office.id;
        const url = `${this.microserviceUrl}/vendor_integrations/v1/disconnect?office=${officeId}&vendor_id=${vendorId}`;
        return this.serverAPI.makeConcurrentCall(url, options)
        .then(response => {
          // TODO: fire appEvents.externalVendorAccountRemoved
          return true;
        });
      });
    }

    /**
     * It makes a call to the server to get the price of a product from a vendor
     * @param vendorInfo - Object containing `vendor_sku` and `uom`
     * @param officeId - The office ID of the office for which the price is being fetched.
     * @returns A promise that resolves to the product price for the given vendorInfo and officeId.
     */
    getProductPriceForAccount (vendorInfo, officeId) {
      return this._getMembership()
      .then((membership) => {
        officeId ??= membership.office.id;
        const url = `${this.microserviceUrl}/vendor_integrations/v1/${vendorInfo?.connected_name}/product_price`;
        const options = {
          'method': 'GET',
          'params': {
            vendor_sku: vendorInfo?.vendor_sku,
            office_id: officeId,
            uom: vendorInfo?.uom,
            account_number: vendorInfo?.account_number,
          }
        };
        // TODO: fire appEvents.externalVendorProductPriceLoaded
        const error_options = {
          show_error_toast: false,
        }
        return this.serverAPI.makeConcurrentCall(url, options, error_options);
      });
    }

    /**
     * It makes a call to the vendor's API to get the stock status of a list of items
     * @param {Object} vendorInfo - This is the vendor information object that is returned from the
     * vendor_integrations/v1/vendors endpoint.
     * @param {string[]} itemSkus - An array of item SKUs to check stock status for.
     * @param {string} officeId - The id of the office you want to check stock status for.
     * @returns A promise that resolves to a map of product statuses.
     */
    getStockStatus (vendorInfo, itemSkus, officeId) {
      return this._getMembership()
      .then((membership) => {
        officeId ??= membership.office.id;

        const url = `${this.microserviceUrl}/vendor_integrations/v1/${vendorInfo?.connected_name}/stock_status`;
        const options = {
          'method': 'GET',
          'params': {
            'office_id': officeId,
            'vendor_skus': _.join(itemSkus, ',')
          }
        };
        return this.serverAPI.makeConcurrentCall(url, options);
      });
    }

    /**
     * It takes an action, a set of values, and an officeId, and then calls the action's endpoint with
     * the values and officeId
     * @param {Object} action - The action object from the microservice's API.
     * @param {Object} values - the values of the form
     * @param {string} officeId - The office ID to use for the action. If not provided, the current office ID
     * will be used.
     * @returns {Object} A promise that resolves to the result of the call to the microservice.
     */
    callAction (action, values, officeId, error_options = { show_error_toast: false, throw_error: true }) {
      return this._getMembership()
      .then((membership) => {
        officeId ??= membership.office.id;
        const url = `${this.microserviceUrl}${action.endpoint}`;

        const action_params = this.msHelperService.paramsToKwargs({
          'office_id': officeId,
          ...values
        }, action);

        const options = {
          'method': action.method,
        };

        if (action.method === 'GET') {
          options.params = action_params;
        } else {
          options.data = action_params;
        }

        return this.serverAPI.makeConcurrentCall(url, options, error_options);
      });
    }

    /**
     * Gets the status of an order from an external vendor.
     * 
     * @param {string} monolith_order_id - The order id of the order in Monolith API
     * @param {string} reference_number - This is the reference number from the monolith order.
     * @param {string} vendor_connected_name - The name of the vendor you're connecting to.
     * @param {string} office_id - The office id of the office that the order was placed in.
     * 
     * @returns {Promise<ExternalVendorOrder>} A promise that resolves to the response from the server.
     */
    getOrderStatus (monolith_order_id, reference_number, vendor_connected_name, office_id) {
      return this._getMembership()
      .then((membership) => {
        office_id ??= membership.office.id;
        
        const url = `${this.microserviceUrl}/vendor_integrations/v1/${vendor_connected_name}/order_status`;
        const options = {
          'method': 'GET',
          'params': {
            monolith_order_id,
            reference_number,
            office_id
          }
        };
        return this.serverAPI.makeConcurrentCall(url, options, {show_error_toast: false});
      });
    }
  }

  angular.module('app')
    .service("sowExternalVendorAccountService", ExternalVendorAccountService);

})();

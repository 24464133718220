(function () {
  'use strict';

  angular
    .module("app.marketplace.ui.inventory")
    .controller("inventoryController", inventoryController);

  function inventoryController ($scope, $rootScope, $timeout, $filter, $q, elementService, sgToast, $mdDialog,
                                officeService, inventoryItemService, inventoryHelperService, sessionService,
                                errorService, smoothScroll, RedirectModalService, DeleteInventoryDraftPODialog, AddToCartDialog,
                                DeleteInventoryActivePODialog, gettingStartedDialog, sowBrowserFlags, RouteService) {

    /*jshint validthis: true */
    var ctrl = this;
    var timer = null;
    var inventory = null;

    ctrl.FEATURE_DATA = {
      'section': {
        'name': 'MAIN_MENU.GENERAL_INVENTORY',
        'icon': {'material': "view_list", 'css': 'orange' },
      },
      'plan': {
        'label': 'PAYWALL.PREMIUM_FEATURE',
        'css': 'premium',
      },
      'selling_points': [
        {
          'header': 'INVENTORY.PW_HEADER_ONE',
          'text':'INVENTORY.PW_TEXT_ONE',
          'img': "styles/img/paywalls/1-tracking"
        },
        {
          'header': 'INVENTORY.PW_HEADER_TWO',
          'text':'INVENTORY.PW_TEXT_TWO',
          'img': "styles/img/paywalls/2-add-green"
        },
        {
          'header': 'INVENTORY.PW_HEADER_THREE',
          'text':'INVENTORY.PW_TEXT_THREE',
          'img': "styles/img/paywalls/3-qr-code"
        },
      ]
    };

    // Save on close functionality
    var registeredItem = null; // List of registered items to check against when navigating within the state.

    $scope.hasFilter = hasFilter;
    $scope.getItems = getItems;
    $scope.updateSearch = updateSearch;
    $scope.clearSearch = clearSearch;
    $scope.getOverview = getOverview;
    $scope.urgentFilter = urgentFilter;

    $scope.getFilterBundleItems = getFilterBundleItems;
    $scope.getBundleItems = getBundleItems;
    $scope.getUrgentItems = getUrgentItems;

    $scope.isCurrentBundle = isCurrentBundle;
    $scope.openBundle = openBundle;
    $scope.closeBundle = closeBundle;

    $scope.addItem = addItem;
    $scope.addItemDialog = addItemDialog;
    $scope.addToCart = addToCart;
    $scope.toggleItem = toggleItem;
    $scope.viewItem = viewItem;
    $scope.editItem = editItem;
    $scope.removeItem = removeItem;
    $scope.duplicateItem = duplicateItem;
    $scope.checkItem = checkItem;
    $scope.hideHiddenInventoryBanner = hideHiddenInventoryBanner;
    $scope.registerItem = registerItem;
    $scope.generatePoStatusTooltip = inventoryHelperService.generateTooltip;
    $scope.inventorySlideout = inventorySlideout;

    $scope.printItemQrCode = printItemQrCode;
    $scope.showInventoryDialog = gettingStartedDialog.showInventoryDialog;

    ctrl.loadInventory = loadInventory;
    ctrl.initialize = initialize;

    $scope.inventoryLoaded = false;
    $scope.showLoader = false;

    ctrl.init = init;

    init();

    return ctrl;

    function init () {
      $scope.show_gsd =
        sow.officeFeatures().aaoms_marketplace ||
        sow.officeInfo().association_membership.professional_association_id ==='aaoms' ||
        sow.officeInfo().association_membership.professional_association_id ==='dentsply' ||
        sow.officeInfo().association_membership.professional_association_id ==='neodent';

      register_state_change();
      var loaderTimeout = $timeout(function () {
        $scope.showLoader = true;
      }, 800);

      ctrl.loadInventory().then(function () {
        $timeout.cancel(loaderTimeout);
        $scope.showLoader = false;
        $scope.inventoryLoaded = true;
        $rootScope.$broadcast('shopping-list-updated');
        ctrl.initialize();
      });

      // aaomsWelcomeDialog();
      $scope.$on("$destroy", clearCheckedItems);
    }

    function inventorySlideout ($event, item, id) {
      $rootScope.$broadcast('inventory-item-slideout', item, id, 'details');
    }

    function register_state_change() {
      $scope.$on('$stateChangeStart', function (event, toState, toParams) {
        $scope.currentItem = null;
      });
    }

    function clearCheckedItems () {
      if( _.size($scope.checkedItems) < 1 ) {
        return;
      }
      _.map($scope.checkedItems, function(checked_item) {
        checked_item.UI.checked = false;
      });
      $scope.checkedItems = angular.copy([], $scope.checkedItems);
    }

    function aaomsWelcomeDialog () {
      $scope.gsdInventory = gettingStartedDialog.showInventoryDialog;
      if ($rootScope.features.aaoms_marketplace && !sowBrowserFlags.flag('inventory-tutorial') ) {
        gettingStartedDialog.showInventoryDialog()
        .then(function(){
          sowBrowserFlags.flag('inventory-tutorial', true);
        });
      }
    }

    function loadInventory () {
      return officeService.get(true).then(function (office) {
        return officeService.getInventory(true, false).then(function (payload) {
          inventory = payload;
        });
      }).catch(function (error) {
        var t_message = $filter('translate')('ERRORS.INVENTORY_LOAD')
        errorService.uiErrorHandler(error || t_message, 0);
      });
    }

    function printItemQrCode (item) {
      return inventoryItemService.printLabelsForItems([item]);
    }

    function initialize () {
      // Ugly hardcoded redirect if logged out
      if (!inventory) {
        RouteService.goToDefault();
        return false;
      }

      $scope.currentBundle = "none";
      $scope.checkedItems = inventoryItemService.checkedItems;

      // Cleaner to use global map instead of inventory.items.
      $scope.itemsMap = elementService.elementMaps.inventoryItem;
      $scope.items = [];
      $scope.locations = inventory.locations;
      $scope.overview = inventory.overview;

      // All possible filters.
      $scope.filters = {
          inventory_search : '',
          search_text : '',
          below_min_level : false,
          expiring_soon : false,
          sort_field : false
      };
      $scope.sorts = {};
      $scope.sortable_fields = [
        {name: "name", display_name: "Product Name"},
        {name: "manufacturer_name", display_name: "Manufacturer Name"},
      ];
      $scope.sorts.sort_field = "name";
      $scope.$on('editInventoryItemController: bundle-updated', function(event, bundle){
        bundle.force_refresh = true;
      });

      // Display modal if their premium account expired
      if ($scope.current_office.account_type === "Free" &&
          $scope.current_office.trial_over &&
          !_.get($scope, 'current_membership.flags.prompted_for_premium_in_inventory_with_modal')){
        $scope.$watch('current_session.UI.prompted_for_premium_was_shown', function (newPromptValue, oldVal) {
          if (newPromptValue) {
            $mdDialog.show({
              controller: 'hiddenInventoryController',
              templateUrl: 'templates/marketplace/inventory/modals/inventory-hidden.html',
              parent: angular.element(document.body),
              clickOutsideToClose:true
            })
            ['finally'](function(){
              return sessionService.updateFlag('prompted_for_premium_in_inventory_with_modal', true);
            });
          }
        });
      }

      $scope.urgentItemsBundles = [
        {
          "name": "Product Alerts",
          "type": "urgent",
          "image": "error",
          "itemsFn": getUrgentItems,
          'classes': 'red-text'
        }
      ];

      $scope.overviewBundle = {
        "type": "all",
        "name": "all",
        "itemsFn": getUrgentItems
      };
    }

    //
    // Necessary to have items as an array.
    //
    // TODO reassess, make more efficent or replace ng-virtual-repeat.
    //
    function getItems () {
      $scope.items = [];
      _.map($scope.itemsMap, function(it, item){
        it['not_expand'] = false;
        it['po_status'] = _.get($scope, 'itemPoStatus['+it.id+']', null);
        $scope.items.push(it);
      });
      $scope.$root.$emit('inView: render'); // Force inView directive to recheck all elements.
      return $scope.items;
    }

    //
    // Update Search Filter
    //
    function updateSearch (search_text) {
      if (timer) {
        $timeout.cancel(timer);
      }

      timer = $timeout(function () {
        $scope.filters.inventory_search = search_text;
      }, 300);
    }

    //
    // Clear Search Filter
    //
    function clearSearch (dont_clear_state) {
      $scope.filters.search_text = '';
      $scope.filters.inventory_search = '';

      if (!dont_clear_state && $scope.$state.is('app.inventory.all')) {
        $scope.$state.go('app.inventory.all', {'itemId': ''}); // Clear state params
      }
    }

    //
    // Generate data needed to display the summary at the top of inventory
    // pages.
    //
    function getOverview (items) {
      if (!items) {
        items = $scope.getBundleItems($scope.overviewBundle, true);
      }

      var overview = {};
      if (items && items.length > 0){
        overview.nb_items = items.length;
        overview.total_value = 0;
        overview.nb_on_hand = 0;

        for (var i = 0; i < items.length; ++i) {
          if (items[i].on_hand) {
            if (items[i].on_hand > 0) {
              overview.nb_on_hand += items[i].on_hand;
            }
            if (items[i].price && items[i].price > 0) {
              overview.total_value += (items[i].on_hand * items[i].price);
            }
          }
        }
        return overview;
      }

      return overview;
    }

    //
    // Filter to only items that are urgent.
    //
    function urgentFilter (item) {
      return item.error_count > 0;
    }

    //
    // Apply Search Filter Criteria
    //
    function searchFilter (item) {
      var filters = $scope.filters;

      function searchTextFilter (item) {
        return !filters.inventory_search || (item.name || '').toLowerCase().indexOf(filters.inventory_search.toLowerCase()) !== -1;
      }

      function minLevelFilter (item) {
        return !filters.below_min_level || (item.errors && item.errors.below_minimum_level);
      }

      function expiringFilter (item) {
        return !filters.expiring_soon || (item.errors && (item.errors.expiring_soon || item.errors.expired));
      }

      return searchTextFilter(item) && minLevelFilter(item) && expiringFilter(item) ? item : undefined;
    }

    function hasFilter () {
      var filters = $scope.filters || {};

      return (
        filters.inventory_search ||
        filters.search_text ||
        filters.below_min_level ||
        filters.expiring_soon
      );
    }

    function getFilterBundleItems (bundle) {
      if(bundle.force_refresh || !bundle._filtered_items || !bundle._old_items || !angular.equals(bundle.oldFilters, $scope.filters) || !angular.equals(bundle.oldSorts, $scope.sorts) || bundle._items.length !== bundle._old_items.length){
        if(bundle.force_refresh){
          bundle._filtered_items = null;
          bundle.force_refresh = false;
        }
        if(bundle._old_items && bundle._items.length !== bundle._old_items.length){
          bundle.oldFilters = null; // Force filter refresh.
        }
        bundle._old_items = bundle._items;

        if(bundle.orderBy){
          bundle._items = $filter('orderBy')(bundle._items, bundle.orderBy, true);
        }

        if(bundle.filter){
          bundle._items = $filter('filter')(bundle._items, bundle.filter, true);
        }

        if(bundle.limitTo){
          bundle._items = $filter('limitTo')(bundle._items, bundle.limitTo, 0);
        }

        bundle._filtered_items = !bundle._filtered_items ? bundle._items : bundle._filtered_items;

        // if(!angular.equals(bundle.oldFilters, $scope.filters)){
          bundle._filtered_items = $filter('filter')(bundle._items, searchFilter);
          bundle.oldFilters = angular.copy($scope.filters);
        // }

        if(!angular.equals(bundle.oldSorts, $scope.sorts)){
          if($scope.sorts.sort_field){
            bundle._filtered_items = $filter('orderBy')(bundle._filtered_items, $scope.sorts.sort_field);
          }
          bundle.oldSorts = angular.copy($scope.sorts);
        }
      }

      return bundle._filtered_items;
    }

    function getBundleItems (bundle, do_filter, limit) {
      var type = bundle.type;
      bundle._items = $filter('orderBy')($scope.getItems(), "name");

      if (do_filter) { // Show filtered items
        // Update filter if item list is old or filter has been updated
        bundle._items = $scope.getFilterBundleItems(bundle);
      }

      var items = bundle._items;

      if (type === "urgent") {
        items = $filter('orderBy')($filter('filter')(items, $scope.urgentFilter), function(item) {
          return (item.errors.below_minimum_level ? 1 : 0) + (item.errors.expiring_soon ? 2 : 0);
        });
      }

      if (limit) {
        items = $filter('limitTo')(items, limit, 0);
      }

      // fix for displaying newly created items without limiting the list on screen - SOW-4094
      if ($scope.currentItem && $scope.currentGroup) {
        var _current_item_in_list = _.find(items, $scope.currentItem);
        if (!_current_item_in_list) {
          var _current_item = _.find(bundle._items, $scope.currentItem);
          items.unshift(_current_item);
        }
      }
      return items;
    }

    function getUrgentItems () {
      return $scope.getBundleItems($scope.urgentItemsBundles[0]);
    }

    function isCurrentBundle (bundle){
      return $scope.currentBundle === bundle;
    }

    function openBundle (bundle) {
      checkNavigate()
      .then(function(){
        $scope.currentItem = null;
        $scope.currentBundle = bundle;
        bundle.force_refresh = true;
        //Scroll to bundle.
        $timeout(function(){
          smoothScroll.scrollToElementById('bundle-'+bundle.name, {offset : -100, speed: 0, onlyUp: true});
        },280); //280 ms to compensate for transition animation.
      });
    }

    function closeBundle (bundle) {
      checkNavigate()
      .then(function(){
        $scope.currentBundle = "none";
        $scope.currentItem = null;
      });
    }

    function addItem (){
      $mdDialog.show({
        controller: 'addInventoryItemController',
        templateUrl: 'templates/marketplace/inventory/modals/add-inventory-item.html',
        parent: angular.element(document.body),
        locals:{
          productName: null
        },
        clickOutsideToClose:true
      });
    }

    function addToCart (item, clickEvent) {
      if(clickEvent && clickEvent.stopPropagation)
        clickEvent.stopPropagation();
      $scope.modifyingItem = true;
      return AddToCartDialog.show(item.product_id, clickEvent).finally(function () {
        $scope.modifyingItem = false;
      });
    }

    //
    // Toggle an item row open or closed.
    //
    function toggleItem (item, group) {
      checkNavigate(true)
      .then(function(){
        if ($scope.currentItem === item) {
          $scope.currentItem = null;
        } else {
          $scope.currentItem = item;
        }

        if ($scope.currentItem) {
          $scope.currentGroup = group;
        } else {
          $scope.currentGroup = null;
        }

        if($scope.currentItem && $scope.currentGroup){
          // Scroll to item.
          $timeout(function(){
            var ItemID = 'bundle-{0}-inventory-item-{1}'.format($scope.currentGroup.name, item.id);
            smoothScroll.scrollToElementById(ItemID, {offset : -100, speed: 12, onlyUp: false});
          }, 280); //280 ms to compensate for transition animation.
        }
      });
    }

    function viewItem (ev, item, edit) {
      // $scope.filters.search_text = item.name;
      // $scope.filters.inventory_search = $scope.filters.search_text;
      $scope.currentItem = item;
      $scope.currentGroup = $scope.overviewBundle;

      //Scroll to item.
      $timeout(function(){
        smoothScroll.scrollToElementById('bundle-' + $scope.currentGroup.name + '-inventory-item-'+item.id, {offset : -100});
      }, 300);
    }

    function editItem (ev, item) {
      $scope.viewItem(ev, item, true);
    }

    function removeItem (item) {
      $scope.modifyingItem = true;

      function _success () {
        // check for open bundle and close it
        if ($scope.currentItem === item) {
          closeBundle($scope.currentBundle);
        }
        $scope.modifyingItem = false;
        var t_message = $filter('translate')('TOAST.INVENTORY_ITEM_REMOVED');
        sgToast.showSimple(t_message);
        $mdDialog.hide();
      }

      function _error (error) {
        $scope.modifyingItem = false;
        var t_message = $filter('translate')('ERRORS.INVENTORY_ITEM_REMOVE')
        errorService.uiErrorHandler(error || t_message, 0);
      }

      elementService
        .remove('inventoryItem', item, true)
        .then(function (inventory_item) {
          _success();
          // alertsDataCarrierService.load_alerts();
        }).catch(function (error) {
          var poError = _.get(error, 'purchase_order_error');

          if (poError) {
            var drafts = _.filter(poError.purchase_orders, {'status': 'Draft'});
            var candidate = angular.extend(angular.copy(item), poError);

            if (drafts.length === poError.purchase_orders.length) {
              return DeleteInventoryDraftPODialog
                .show([candidate])
                .then(_success, _error);
            } else {
              return DeleteInventoryActivePODialog
                .show([candidate])
                .then(function () {
                  $scope.modifyingItem = false;
                  // sgToast.showSimple("Could not remove inventory item");
                  // errorService.uiErrorHandler("Could not remove inventory item");
                  $mdDialog.hide();
                  _success();
                });
            }
          } else {
            _error(error);
          }
        });
    }

    //
    // Duplicate an Inventory Item
    //
    function duplicateItem (ev, item) {
      $mdDialog.show({
        controller: 'duplicateItemController',
        templateUrl: 'templates/marketplace/inventory/modals/duplicate-item.html',
        parent: angular.element(document.body),
        targetEvent: ev,
        clickOutsideToClose:true,
        locals : {
          item : angular.copy(item)
        }
      }).then(function(new_inventory_item){
        $scope.viewItem(null, new_inventory_item, true);
      });
    }

    //
    // Mark an inventory item as "checked"
    //
    function checkItem (item, $event) {
      if($event && $event.stopPropagation)
        $event.stopPropagation();
      _.set(item, "UI.checked", !_.get(item, "UI.checked", false) );
      // item.UI.checked = !item.UI.checked;
      if(item.UI.checked){
        $scope.checkedItems.push(item);
      }else{
        _.map($scope.checkedItems, function(checked_item, i){
          if(checked_item.id === item.id){
            $scope.checkedItems.splice(i, 1);
          }
        });
      }
    }

    function hideHiddenInventoryBanner () {
      return sessionService.updateFlag('prompted_for_premium_in_inventory_with_banner', true);
    }

    //
    // Expose function to be used by inner controllers.
    //
    function registerItem (item, saveFn) {
      registeredItem = {item: item, saveFn: saveFn};
    }

    //
    // Check if an item has been registered (aka being edited). If so, show
    // redirect modal and save if accepted.
    //
    function checkNavigate (rejectOnSave) {
      if(!!registeredItem){
        var form = angular.element("form[name=inventoryItemForm]");
        if(form && form.length && form.scope().inventoryItemForm && form.scope().inventoryItemForm.$dirty){
          return RedirectModalService.show(null, 'SaveChanges')
          .then(function(){
            var saveFn = registeredItem.saveFn;
            registeredItem = null; //Clear to avoid infinite loop
            saveFn();
            if(rejectOnSave){
              return $q.reject(); //Avoid double toggle
            }
          }).catch(function(){
            return $q.resolve();
          });
        }else{
          return $q.resolve();
        }
      }else{
        return $q.resolve();
      }
    }

    /**
     * @desc Open dialog to add product in step 2 and product name filled with search text
     *
     * @param {*} name product name
     */

    function addItemDialog (name) {

      $mdDialog.show({
        controller: 'addInventoryItemController',
        templateUrl: 'templates/marketplace/inventory/modals/add-inventory-item.html',
        parent: angular.element(document.body),
        clickOutsideToClose:true,
        bindToController: true,
        locals:{
          productName: name
        }
      })
      .then(function(newItem){
        if(newItem && newItem.id){
          $state.go('app.inventory.all',{'itemId': newItem.id});
        }
      });
    }
  }
}());

(function () {
  'use strict';

  angular.module('sowMarketplace')
  .controller('marketplacePagePickerController', marketplacePagePickerController)
  .directive('mktPagePicker', marketplaceProductDirective);

  function marketplaceProductDirective () {
    return {
      restrict: 'E',
      templateUrl: 'sow-mkt/directives/mkt-page-picker.html',
      controller: 'marketplacePagePickerController',
      controllerAs: 'pickerCtrl',
      scope: {
        'products': '=',
      },
    };
  }

  function marketplacePagePickerController ($scope, $rootScope) {
    /*jshint validthis:true*/
    var ctrl = this;

    ctrl.setProductPage = setProductPage;

    init();

    return ctrl;

    function init () {
      ctrl.products = $scope.products;
      defineVisiblePages();
    }

    function setProductPage (pos) {
      if ( (ctrl.products.current_page === pos) || (pos < 0) || (pos > ctrl.products.pages.length-1) ) {
        return;
      }
      $rootScope.$broadcast('mkt-load-product-page', pos);
      ctrl.products.current_page = pos;
      defineVisiblePages();
      ctrl.products.display = angular.copy(ctrl.products.pages[pos]);
    }

    function defineVisiblePages () {
      _.set(ctrl, 'products.display_page_names', []);

      var ellipsis = {'name':'...', 'position':null, 'empty': true};
      var total = _.size(ctrl.products.page_names);
      var last = (total-1);
      var current = ctrl.products.current_page;
      var first_one = _.first(ctrl.products.page_names);
      var first_three = _.slice(ctrl.products.page_names, 0, 3);
      var last_one = _.last(ctrl.products.page_names);
      var last_three = _.slice(ctrl.products.page_names, last-2, total);
      var middle_three = _.slice(ctrl.products.page_names, (current-1), (current+2));

      if(total <= 5){
        // display all 5 pages
        _.set(ctrl, 'products.display_page_names', ctrl.products.page_names);
      } else {

        if(current <= 2) {
          // over 5 total, current is at start
          var beginning = (current === 2) ? _.union(first_three, middle_three) : first_three;
          _.set(ctrl.products, 'display_page_names', _.concat(beginning, ellipsis, last_one));

        } else if ( _.inRange(current, 3, (last - 2)) ) {
          // over 5 total, current is at middle
          _.set(ctrl.products, 'display_page_names', _.concat(first_one, ellipsis, middle_three, ellipsis, last_one));
        } else if ( _.inRange(current, (last - 3), last+1) ) {
          // over 5 total, current is at end
          var end = (current === last-2) ? _.union(middle_three, last_three) : last_three;
          _.set(ctrl.products, 'display_page_names', _.concat(first_one, ellipsis, end));
        }
      }
    }
    
  }

})();
angular.module('app.marketplace.ui.cart')

.directive('cartDrawer', function() {
  return {
    replace: true,
    restrict: 'E',
    templateUrl: 'templates/marketplace/cart/directives/cart-drawer.html',
    link: function ($scope, $element, $attrs) {
      // Note: This is a super-hack, but upgrading to Angular Material 0.11.4
      //       broke the <md-sidenav> in that the page content (behind the
      //       backdrop) overflows and scrolls with a fixed sidebar. For some
      //       reason setting <body> overflow to hidden is the only thing that
      //       works. Setting 'overflow: hidden' on the <div> that contains all
      //       of the page content doesn't even work.
      //
      //       I will also note that Angular Material is _attempting_ to do
      //       something about this. I see it setting 'overflow: hidden' on the
      //       parent element to the <md-sidenav> element. This doesn't seem to
      //       be working on our site though.
      $scope.$watch('cartDrawerIsOpen', function (value) {
        if (value) {
          angular.element('body').addClass('cart-drawer-is-showing');
        } else {
          angular.element('body').removeClass('cart-drawer-is-showing');
        }
      });
    }
  };
});

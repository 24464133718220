(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.purchaseorders')
    .filter('capitalize', capitalizeFilter);

  function capitalizeFilter() {
    return function (input) {
      return input && input[0].toUpperCase() + input.substr(1);
    };
  }

}());

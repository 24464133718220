(function () {
  'use strict';

  angular
    .module('sowSubscriptionPricing')
    .directive('sowSubscriptionPlanBox', sowSubscriptionPlanBox);

  function sowSubscriptionPlanBox ($log) {
    return {
      'restrict': 'E',
      'replace': true,
      'templateUrl': 'sow-subscription-pricing/sow-subscription-plan-box.html',
      'scope': {
        'planClass': '@',
        'planTitle': '@',
        'planPrice': '@',
        'planSavingsRaw': '@planSavings',
        'buttonText': '@',
        'isEnterprise': '@',
        'planPhonenumber': '@',
        'buttonAction': '&',
      },
      'link': function ($scope) {
        $scope.classes = [];
        $scope.planSavings = null;
        $scope.btnClasses = [];
        $scope.triggerAction = triggerAction;
        init();
        return;

        function init () {
          $scope.classes = $scope.planClass;
          $scope.$watch('planSavingsRaw', function (planSavings) {
            $scope.planSavings = planSavings ? parseFloat(planSavings) : null;
          });

          $scope.$watch('planPrice', function (planPrice) {
            var parts = planPrice.split('.');
            $scope.planPriceDollars = parts[0];
            $scope.planPriceCents = '';

            if (parts.length > 1) {
              $scope.planPriceDollars += '.';
              $scope.planPriceCents = parts[1].substring(0, 2);
              $scope.planPriceCents = $scope.planPriceCents.padEnd(2, '0');

              // if ($scope.planPriceCents.length === 1) {
              //   $scope.planPriceCents += '0';
              // }
            }
          });
        }

        function triggerAction ($event) {
          if (angular.isDefined($scope.buttonAction)) {
            $scope.buttonAction({'$event': $event});
          } else {
            $log.warn('sowSubscriptionPlanBox: Button clicked, but no action defined.');
          }
        }
      }
    };
  }

}());

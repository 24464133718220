(function () {
  'use strict';

  var imports = [
    'pascalprecht.translate',
    'ngAnimate',
    'ngMask',
    'ngMaterial',
    'ngMessages',
    'ngSanitize',
    'ui.router',
    'angulartics',
    // 'angulartics.google.analytics',
    // 'angularLazyImg',
    'app.config',
    'payment',
    'headroom',
    'angular-stripe',
    'app.marketplace.templates',
    'app.shared',
    'app.marketplace',
    'sowTutorialVideos',
    'sowSideNav',
    'md.data.table',
    'daterangepicker',
    'sowAlerts',
    'sowAlgolia',
    'sowAnalytics',
    'sowAssociations',
    'sowBudget',
    'sowCertifications',
    'sowCoreUI',
    'sowDownloadFile',
    'sowFeatureService',
    'sowMarketplace',
    'sowMedications',
    'sowOrders',
    'sowPaywall',
    'sowProduct',
    'sowReports',
    'sowSales',
    'sowSds',
    'sowSterilization',
    'sowSubscriptionPricing',
    'sowUserAdmin',
    'sowFilters',
    'sowApprovals',
    'vs-repeat',
    'sowCoreUI',
    'sowLanguage',
    'sowInterop',
    'sowWorkflows',
    'sowMicroservices',
  ];
  agGrid.initialiseAgGridWithAngular1(angular);
  angular
    .module("app", imports)
    .run(initializeServices)
    .run(initRootScope)
    .run(initWindow)
    // .run(initAlerts)
    .run(initErrorWatchers)
    .run(initAnalytics);

  //
  // Init $rootScope
  //
  // TODO Maybe move this into SystemController?
  //
  function initRootScope ($q, $rootScope, $state, $stateParams, $window, gettingStartedDialog, $mdSidenav, $mdMedia, $mdDialog, $log, $timeout, appEvents, sowLanguageService, WOFE_VERSION, BUILD_MODE, sowExternalVendorService, sowInteropService, RouteService, defaultDebounceTime, PatientService, $location, apiTokenService, localStorageService, accountService, sessionService, WorkflowsService) {
    $rootScope.$state = $state;
    $rootScope.$stateParams = $stateParams;
    $rootScope.$window = $window;
    $rootScope.ui_version = `${WOFE_VERSION} ${BUILD_MODE}`;

    $rootScope.$mdMedia = $mdMedia;
    $rootScope.$mdDialog = $mdDialog; //imported into scope to make dialog templates easier
    $rootScope.desktopSidenavStatus = null;
    $rootScope.defaultDebounceTime = defaultDebounceTime;
    $rootScope.demoUrl = 'https://calendly.com/sowingosales/consultation';
    const sowingoCMSHelpLink = 'https://sowingo.com/help'

    $rootScope.HelpMenuItems = [
      {tag: "LEARNING_CENTER", name: 'Getting Started', icon: {'material':'school'}, action: function (ev) { gettingStartedDialog.show(ev); }},
      {tag: "HELP_CENTER", name: 'Support Center', icon: {'material':'help'}, url: sowingoCMSHelpLink},
      {tag: "ABOUT_US", name: 'About Us', icon:{svg: 'sowingo-logo-bw-small'}, state: 'app.about'},
      {tag: "CONTACT_US", name: 'Contact Us', icon: {'material':'email'}, state: 'app.contact'},
    ];

    $rootScope.AppSwitcherItems = [
      {tag: "PROCURE", name: 'Sowingo Procure', state: 'app.dashboard'},
      {tag: "LINK", name: 'Sowingo Link', state: 'app.workflows'},
    ];

    $rootScope.main_apps = [
      {tag: "MARKETPLACE", name: 'Marketplace', icon: {'material':'store'}, state: 'app.shopping', class: 'green1', 'feature': 'old_marketplace'},
      {tag: "MARKETPLACE", name: 'Marketplace', icon: {'material':'store'}, state: 'app.mkt', class: 'green1', 'feature': 'marketplace', 'permission': 'marketplace_shopping_cart'},
      {tag: "SALES_TRACKER", name: 'Sales Tracker', icon: {'material':'label'}, state: 'app.sales', class: 'bright-orange', 'feature': 'sales_tracker', 'permission': 'marketplace_shopping_cart' },
      {tag: "PRICE_COMPARISON", name: 'Price Comparison', icon: {'material':'iso'}, state: 'app.price_comparison', class: 'red1', 'feature': 'price_comparison'},
      {tag: "FLYERS", name: 'Flyers', icon: {'material':'import_contacts'}, state: 'app.flyers', class: 'blue1', 'feature': 'flyers'},
      // {name: 'User Admin', icon: {'material':'account_circle'}, state: 'app.user_admin', class: 'green1', user_type: ['admin','manager']},
    ];

    $rootScope.office_apps = [
      {tag: "DASHBOARD", name: 'Dashboard', icon: {'material':'dashboard'}, state: 'app.dashboard', class: 'red3', 'permission':'dashboard'},
      {tag: "IMPLANTS", name: 'Implants', icon: {svg: 'styles/img/icons/ic-implant-tables.svg'}, state: 'app.implants', class: 'syscolor1', 'permission':'implants_implant_reports'},
      {tag: "MEDICATIONS", name: 'Medications', icon: {'material':'local_pharmacy'}, state: 'app.medications', class: 'red1', 'permission':'medication_inventory'},
      {tag: "GENERAL_INVENTORY", name: 'General Inventory', icon: {'material':'view_list'}, state: 'app.inventory', 'permission':'general_inventory', exclude: ['app.inventory.log_sheets','app.inventory.shopping_list'], class: 'orange1', 'feature': 'inventory_full'},
      {tag: "ORDERS", name: 'Orders', icon: {'material':'assignment'}, state: 'app.orders', class: 'green3', 'permission': 'marketplace_orders_purchase_orders', overrides: ['disable_purchase_orders', 'disable_marketplace_orders'], exclude: ['app.orders.shopping_list']},
      {tag: "REPORTS", name: 'Reports', icon: {'material':'assessment'}, state: 'app.reports', class: 'red1', 'permission_OR': ['spending_reports_budget', 'implants_implant_reports']},
      {tag: "BUDGET", name: 'Budget', icon: {svg: 'styles/img/icons/budget-calculator.svg'}, state: 'app.budget', class: 'blue1', 'permission':'spending_reports_budget'},
    ];

    $rootScope.sowingo_tools = [
      {tag: "STERILIZATION", name: 'Sterilization', icon: {'material': 'grain'}, state: 'app.stzn', class: 'blue1', 'permission':'sterilization'},
      {tag: "CERTIFICATION", name: 'Certifications', icon: {'material': 'verified_user'}, state: 'app.certifications.list', class: 'green1', 'permission':'certifications'},
      {tag: "SDS", name: 'SDS', icon: {'svg': '/styles/img/icons/sds-icon.svg'}, state: 'app.sdslist', class: 'orange1', feature: 'sds'},
    ];

    $rootScope.all_apps = _.concat($rootScope.main_apps, $rootScope.office_apps, $rootScope.sowingo_tools);

    $rootScope.current_year = getCurrentYear();

    sowInteropService.triggerAppInit();
    
    function getCurrentYear() {
      var today = new Date();
      return today.getFullYear();
    }

    $rootScope.menuHandle = function menuHandle(object){
      if (object.action) {
        object.action();
      } else if (object.state) {
        $state.go(object.state);
      } else if (object.url) {
        window.open(object.url);
      }
    };

    $rootScope.toggleMobileSidenav = function toggleMobileSidenav(){
      $mdSidenav('mobile-sidenav').toggle();
    };

    $rootScope.openCalendar = openCalendar;

    function openCalendar($event){
      $event.stopPropagation();
      $timeout(function() {
        var thisElem = $($event.currentTarget)[0];
        var datepicker = $(thisElem).siblings('md-datepicker')[0];
        $(datepicker)[0].children[0].click();
      }, 400);
    }

    printSowingoBanner($log, {'version': $rootScope.ui_version});
    
    sowLanguageService.determineUserLanguage();
    $rootScope.$on('$translate: set-language', (ev, key) => {
      sowLanguageService.setLanguage(key);
    });

    $rootScope.external_vendors = {};
    $rootScope.external_vendors_actions = {};
    // office-changed will fire whenever the office object in rootScope is set
    // that is: login, page refresh and changing from one office to another
    $rootScope.$on('officeService: office-changed', ($event, new_office) => {
      // Clear permissions since the office has changed
      if (new_office) {
        WorkflowsService.clearPermissions();
      }

      // When the office has access to the external vendors feature,
      // we fetch the available external vendors for them, as well as
      // their account information for them (if they were set previously)
      if (new_office?.feature_flags?.external_vendors) {
        $rootScope.ev_data_loaded = $q.all([
          sowExternalVendorService.getVendorsWithAccounts(true)
          .then((vendors_dict) => {
            $rootScope.external_vendors = vendors_dict;
          }),
          sowExternalVendorService.getVendorActions()
          .then((actions_dict) => {
            $rootScope.external_vendors_actions = actions_dict;
          }),
        ]);
      } else {
        // otherwise, keep the list empty so that all other offices
        // do not see the feature
        $rootScope.ev_data_loaded = $q.resolve(null);
        $rootScope.external_vendors = null;
      }

      PatientService.savePracticeInfoInLocalStorage({
        office_id: new_office?.id, 
        has_workflows: Boolean(new_office?.feature_flags?.workflows),
      });
    });
    
    // When a logged-out session is initialized, clear the ExternalAuth permissions
    $rootScope.$on(appEvents.loggedOutSession, () => {
      WorkflowsService.clearPermissions();
    });

    // Listens to a navigate event from the react app 
    // and redirects it to RouteService
    sowInteropService.listenToReactEvent('navigate', (_event, {toState, toParams, extendParams}) => {
      // special cases handled inside service method
      RouteService.goTo(toState, toParams, extendParams);
    });

    // Listens to a redirectTo event from the react app and calls RouteService.redirectTo
    sowInteropService.listenToReactEvent('redirectTo', (_event, {url}) => {
      RouteService.redirectTo(url);
    })

    sowInteropService.listenToReactEvent('auto-select-office', (_event, params) => {
      const {membershipId} = params.params;
      $location.search('officeMembershipId', membershipId)
      const token = localStorageService.get('token');
      const token_expiry = localStorageService.get('token_expiry');
      apiTokenService.setToken(token, token_expiry);

      const session = params.session;
      accountService.initialize(session);
      sessionService.setSession(session);
    })
  }

  function initWindow ($window, $rootScope, $mdDialog, sowLanguageService) {
    $window.sow = {
      officeInfo: officeInfo,
      officeFeatures: officeFeatures,
      userInfo: userInfo,
      membershipInfo: membershipInfo,
      userPermissions: userPermissions,
      odaDialog: odaDialog,
      setLanguage: setLanguage
    };

    function setLanguage (tag) {
      sowLanguageService.setLanguage(tag);
    }

    function odaDialog (){
      return $mdDialog.show({
        template: '<supply-hub-dashboard></supply-hub-dashboard>',
        parent: angular.element(document.body),
        clickOutsideToClose: true
      });
    }

    function officeInfo () {
      return angular.copy($rootScope.current_office);
    }
    function officeFeatures () {
      return angular.copy($rootScope.current_office?.feature_flags);
    }
    function userInfo () {
      return angular.copy($rootScope.current_account);
    }

    function membershipInfo () {
      return angular.copy($rootScope.current_membership);
    }

    function userPermissions () {
      return angular.copy($rootScope.user_permissions);
    }
  }

  //
  // Initialize $state Watchers for Error Service
  //
  // TODO Move this into errorService?
  //
  function initErrorWatchers ($rootScope, $log, $location, $state, errorService) {
    $rootScope.$on('$stateChangeSuccess', function(event, toState, toParams, fromState, fromParams) {
      $rootScope.previousState = fromState.name;
      $rootScope.previousStateParams = fromParams;
    });

    $rootScope.$on('$stateChangeError', function (event, toState, toParams, fromState, fromParams, error) {
      $rootScope.previousState = fromState.name;
      $log.warn('Caught State Change Error (%s => %s): toState(%o) fromState(%o) Error(%o)', toState.name, fromState.name, toState, fromState, error);
      event.preventDefault();
      errorService.setGlobalError(error);
      $state.go('error');
    });
  }

  function initAnalytics ($rootScope, $window) {
    // GTM depends on ui-router's state data such as title and parameters
    $rootScope.$on('$stateChangeSuccess', function(event, toState, toParams, fromState, fromParams) {
      var dataLayer = $window.dataLayer = $window.dataLayer || [];
      dataLayer.push({
        event: 'app-route-change',
        attributes: {
          'parameters': toParams,
          'route': toState.name,
          'title': _.get(toState, 'data.title'),
          'url': toState.url,
        }
      });
    });
    // while appcues needs to track in-state changes
    $rootScope.$on('$locationChangeSuccess', function(event, newUrl, oldUrl, newState, oldState){
      if($window.Appcues) {
        $window.Appcues.page();
      }
    });
  }

  //
  // Initialize Services
  //
  function initializeServices (ConfigService, elementRefreshService, apiTokenService, sessionService, sowFeatureService, sowLanguageService, zendeskChatService) {
    ConfigService.initialize();
    sowFeatureService.initialize();
    apiTokenService.initialize();
    elementRefreshService.init();
    sowLanguageService.determineUserLanguage();
    sowLanguageService.listenForGoogleTranslateChange();
    sessionService.initialize();
    zendeskChatService.configureZendeskTheme();
  }

  function printSowingoBanner (logger, info) {
    logger.log(
      '%c' +
      ' _____           _                             \n' +
      '|   __|___ _ _ _|_|___ ___ ___   ___ ___ _____ \n' +
      '|__   | . | | | | |   | . | . |_|  _| . |     |\n' +
      '|_____|___|_____|_|_|_|_  |___|_|___|___|_|_|_|\n' +
      '                      |___|                    \n' +
      ' \n' +
      'Version: %s' +
      ' \n',
      'font-family:monospace;',
      info.version
    );
  }

}());

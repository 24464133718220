(function () {
  'use strict';

  angular.module('sowCertifications', [
    'app.marketplace.elements',
    'sowMiniLoader',
  ])
  .config(certificationsConfig);

  function certificationsConfig($stateProvider, $urlRouterProvider) {
    $urlRouterProvider.when('/app/certifications', '/app/certifications/list?type=All');
    $urlRouterProvider.when('/app/certifications/', '/app/certifications/list?type=All');

    $stateProvider.state('app.certifications',{
      url: '/certifications',
      controller: 'certificationsSectionController',
      controllerAs: 'cerSecCtrl',
      templateUrl: 'sow-certifications/cert-index.html',
      data: {
        'title': 'Certifications',
        'require_log_in': true,
        // 'requires_feature': 'certifications'
      }
    })
    .state('app.certifications.list', {
      url: '/list?type',
      controller: 'certificationsListController',
      controllerAs: 'certlCtrl',
      templateUrl: 'sow-certifications/cert-list.html',
      data: {
        'title': 'Certifications',
        'require_log_in' : true,
        'action_bar_directive': 'action-bar-certifications'
      }
    })
    .state('app.certifications.detail', {
      url: '/detail/:id',
      controller: 'certificationsDetailController',
      controllerAs: 'certdCtrl',
      templateUrl: 'sow-certifications/cert-detail.html',
      data: {
        'title': 'Certification Detail',
        'require_log_in' : true,
        'action_bar_directive': 'action-bar-cert-detail'
      }
    });
  }

}());

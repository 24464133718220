(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.inventory')
    .controller('ProductWorkSheetCtrl', ProductWorkSheetCtrl);

  function ProductWorkSheetCtrl ($scope, $filter, $state, searchService, sgToast, inventoryItemService, smoothScroll, officeService, ExpiryDatesDialog, errorService) {
    /*jshint validthis: true */
    var ctrl = this;

    ctrl.rows = [];
    ctrl.locations = [];
    ctrl.currentLocation = undefined;
    ctrl.removeRow = removeRow;
    ctrl.cancel = cancel;
    ctrl.addNewRow = addNewRow;
    ctrl.inventorySearch = inventorySearch;
    ctrl.selectedItemChange = selectedItemChange;
    ctrl.manufacturerSearch = manufacturerSearch;
    ctrl.editExpiryDates = editExpiryDates;
    ctrl.save = save;
    ctrl.isRowDirty = isRowDirty;

    init();

    return ctrl;

    function init () {
      populateList();
      updateLocationsList();
    }

    //
    // Add empty rows until we have 20 items
    //
    function populateList () {
      while (ctrl.rows.length < 20) {
        addNewRow();
      }
    }

    //
    // Return true is the row has stuff in it.
    //
    function isRowDirty (row) {
      return (
        row.manufacturerName ||
        row.manufacturer ||
        row.inventoryItem ||
        row.inventoryItemName ||
        !(row.onHand === undefined || row.onHand === null || row.onHand === "") ||
        _.get(row, 'expiry_dates.length') > 0
      );
    }

    function validateOnHand (row) {
      var onHand = parseInt(row.onHand, 10);
      return !isNaN(onHand) && onHand >= 0;
    }

    //
    // Save the Location
    //
    function save (ev) {
      $('table td').removeClass('ng-invalid');
      $('md-select').removeClass('ng-invalid');

      if (!ctrl.currentLocation) {
        var selector = $('[name="ProductWorkSheet-LocationSelector"]');
        selector.addClass('ng-invalid');
        $('.main-view-port').animate({scrollTop: 0});
        return;
      }

      var nonEmptyRows = [];
      var breakEarly = false;

      _.forEach(ctrl.rows, function (row, index) {
        var rowSelector = 'product-work-sheet-row-{0}'.format(index);
        var isEmpty = !isRowDirty(row);

        if (isEmpty) {
          return true;
        }

        if (!(row.inventoryItem || row.inventoryItemName)) {
          var acInventorySelector = '#{0} td:nth-child(2)'.format(rowSelector);
          $(acInventorySelector).addClass('ng-invalid');
          smoothScroll.scrollToElementById(rowSelector, {offset: -100});
          breakEarly = true;
          return false;
        }

        if (!(row.manufacturer || row.manufacturerName)) {
          var acManufacturerSelector = '#{0} td:nth-child(3)'.format(rowSelector);
          $(acManufacturerSelector).addClass('ng-invalid');
          smoothScroll.scrollToElementById(rowSelector, {offset: -100});
          breakEarly = true;
          return false;
        }

        if (!validateOnHand(row)) {
          var onHandSelector = '#{0} td:nth-child(5)'.format(rowSelector);
          $(onHandSelector).addClass('ng-invalid');
          smoothScroll.scrollToElementById(rowSelector, {offset: -100});
          breakEarly = true;
          return false;
        }

        nonEmptyRows.push(row);
      });

      if (breakEarly) {
        return;
      }

      return inventoryItemService.batchLocationUpload(
        nonEmptyRows,
        ctrl.currentLocation
      ).then(function () {
        ctrl.currentLocation = undefined;
        ctrl.cancel();
        $('.main-view-port').animate({scrollTop: 0});
        sgToast.show(
          sgToast
            .simple()
            .textContent('Added to Inventory')
            .hideDelay(5000)
        );
      }).catch(function (error) {
        errorService.uiErrorHandler(error);
      });
    }

    //
    // Open the Expiration Date(s) Dialog
    //
    function editExpiryDates (row, ev) {
      row.expiry_dates = row.expiry_dates || [];

      return ExpiryDatesDialog.show(row.expiry_dates, ev).then(function (expiryDates) {
        row.expiry_dates = expiryDates;
      });
    }

    //
    // Update the list of inventory locations.
    //
    function updateLocationsList () {
      return officeService.getLocations().then(function (locations) {
        ctrl.locations = locations;
        return locations;
      });
    }

    //
    // Remove a row from the table (by index)
    //
    function removeRow (index) {
      ctrl.rows.splice(index, 1);
      populateList();
    }

    //
    // Cancel Changes
    //
    function cancel () {
      $state.reload();
    }

    //
    // Add a new (empty/blank) item to the list.
    //
    function addNewRow () {
      ctrl.rows.push({});
    }

    //
    // Update the selected inventory item.
    //
    function selectedItemChange (item) {
      if (!item) {
        return false;
      }

      item.onHand = 0;
      var manufacturer = _.get(item, 'inventoryItem.manufacturer');

      if (manufacturer && manufacturer.name) {
        item.manufacturer = manufacturer;
      }
    }

    //
    // Autocomplete results for products in the inventory.
    //
    function inventorySearch (row) {
      var searchText = row.inventoryItemName;

      if (!searchText) {
        return;
      }

      return searchService.inventorySearch(searchText).then(
        function (searchElements) {
          var search = row.inventoryItemSearch = {};
          search.items = searchElements;
          search.items = $filter('orderBy')([].concat(search.items), '-_score');
          search.items = $filter('limitTo')(search.items, 50);
          return search.items;
        }
      );
    }

    //
    // Get autocomplete results for Manufacturers
    //
    function manufacturerSearch (row) {
      var searchText = row.manufacturerName;

      if (!searchText) {
        return;
      }

      return searchService.manufacturerSearch(searchText).then(function (result) {
        return _.values(result);
      });
    }
  }
}());

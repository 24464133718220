(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.medications')
    .controller('addMedicationController', addMedicationController);

    function addMedicationController ($filter, $state, $stateParams, $mdDialog,
    officeService, sowMedicationService, StepsService, errorService) {
      /*jshint validthis:true*/
      var ctrl = this;

      ctrl.medication = {};

      ctrl.expandPackaging = expandPackaging;
      ctrl.close = close;
      ctrl.addMedication = addMedication;
      ctrl.viewItem = viewItem;
      ctrl.steps = new StepsService({
        current: 1,
        total: 4,
        validator: validateNextStep
      });

      ctrl.routes_of_admin = {
        "Oral": false,
        "IV": false,
        "Intramuscular": false,
        "Subcutaneous": false,
        "Topical": false,
        "Inhalation": false,
        "Intramucosal": false,
      };

      init();
      return ctrl;

      /**
       * Initializer
       */

      function init(){
        var office = officeService.get();
        ctrl.medication.office_id = office.id;
        ctrl.medication.is_emergency = false;
        ctrl.medication.buy_by_case = false;
        ctrl.medication.track_by = 'Package';
        ctrl.countryId = _.get(office, 'address.country.id');
        if($stateParams.type === 'emergency'){
          ctrl.medication.is_emergency = true;
        }
        if(ctrl.med_name){
          ctrl.medication.name = ctrl.med_name;
          ctrl.steps.current = 2;
        }
      }


      function expandPackaging(){
        ctrl.is_bulk = !ctrl.is_bulk;
        ctrl.medication.buy_by_case = ctrl.is_bulk;
      }

      function close () {
        $mdDialog.hide();
      }

      function addMedication(){
        $mdDialog.hide();
        prepareRequestPayload(ctrl.medication);

        sowMedicationService.createOrUpdateMedication(ctrl.medication)
          .then(function(medication){
            showScanAndLink();
            var params ={
              focus_product: medication.id,
              type: 'regular'
            };
            if(medication.is_emergency){
              params.type = 'emergency';
            }
            $state.go('app.medications.list', params, {reload: true});
          }).catch(function (error) {
            errorService.uiErrorHandler(error);
          });
      }

      function showScanAndLink() {
        $mdDialog.show({
          template: '<inventory-scan-link context="medication"></inventory-scan-link>',
          parent: angular.element(document.body),
          clickOutsideToClose:true
        });
      }

      function prepareRequestPayload(medication) {
        var manufacturer = medication.manufacturer;
        var supplier = medication.supplier;

        medication.manufacturer_id = manufacturer ? manufacturer.id : null;
        medication.manufacturer_name = manufacturer ? manufacturer.name : medication.manufacturerSearchText;

        medication.supplier_id = supplier ? supplier.id : null;
        medication.supplier_name = supplier ? supplier.name : medication.supplierSearchText;

        medication.routes_of_admin = Object.assign({}, ctrl.routes_of_admin, medication.routes_of_admin);
      }

      function validateNextStep(currentStep) {
        if (currentStep === 2 && !ctrl.medication.name) {
          // sgToast.showSimple('Missing Generic Name');
          var t_message = $filter('translate')('ERRORS.MISSING_GENERIC_NAME')
          errorService.uiErrorHandler(t_message);

          return false;
        }

        return true;
      }

      function viewItem(event, item){
        var params ={
          focus_product: item.id,
          type: 'regular'
        };

        if(item.is_emergency){
          params.type='emergency';
        }

        $state.go('app.medications.list',params);
        $mdDialog.cancel();
      }
    }
}());

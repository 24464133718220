angular.module('app.marketplace.system')
  //
  // Route Service Initialization
  //
  .run(function ($rootScope, $log, $state, RouteService, appEvents) {
    $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
      RouteService.handleLoggedInRedirect(event, toState, fromState);
      RouteService.handleMembershipTypeRestriction(event, toState, fromState);
    });

    $rootScope.$on(appEvents.setSession, function (event, session) {
      var isSignedIn = session && session.user && session.user.id && session.user.email !== 'guest_user@sowingo.com';
      RouteService.handleLoggedInRedirect(event, $state.current, null, isSignedIn);
    });

    $rootScope.$on('membershipService: set-membership', function (event, membership) {
      RouteService.handleMembershipTypeRestriction(event, $state.current, null, membership);
    });

    $rootScope.$on('membershipService: clear-membership', function (event, membership) {
      RouteService.handleMembershipTypeRestriction(event, $state.current, null, membership);
    });
  });
(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.medications')
    .service('dataSharingService', dataSharingService);

  function dataSharingService() {
    /*jshint validthis: true */
    var service = this;

    service.alert_count = null;
    service.inventory_item_id = null;
    service.checked_items = [];
    service.toRemoveItems = {};
    service.is_loading = true;

    return service;
  }

}());

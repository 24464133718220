angular.module("app.marketplace.elements")
.service("manufacturerService", ["$rootScope", "$mdDialog", "sessionService", "elementService", "manufacturerModel" , 
                          function($rootScope, $mdDialog, sessionService, elementService, manufacturerModel) { 
  var _this = this;
  
  return {
    _init : _init
    
  };
} ]);

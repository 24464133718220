 (function () {
  'use strict';

  angular
    .module('sowUserAdmin')
    .service('sowUserAdminDataService', sowUserAdminDataService);

  function sowUserAdminDataService () {
    /*jshint validthis: true */
    var service = this;

    service.USER_PERMISSIONS = {
      'master': [],
      'manager': ['dashboard', 'marketplace_shopping_cart',
                  'marketplace_orders_purchase_orders',
                  'spending_reports_budget', 'certifications'],
      'team': ['general_inventory', 'implants_implant_reports',
              'medication_inventory', 'sterilization']
    };
    service.PERMISSION_LABELS = {
      'certifications': "OFFICE.PERMISSIONS.CERTIFICATIONS",
      'dashboard': "OFFICE.PERMISSIONS.DASHBOARD",
      'general_inventory': "OFFICE.PERMISSIONS.INVENTORY",
      'implants_implant_reports': "OFFICE.PERMISSIONS.IMPLANTS",
      'marketplace_orders_purchase_orders' : "OFFICE.PERMISSIONS.ORDERS",
      'marketplace_shopping_cart' : "OFFICE.PERMISSIONS.MKT_CART_SALES",
      'medication_inventory': "OFFICE.PERMISSIONS.MEDICATIONS",
      'spending_reports_budget': "OFFICE.PERMISSIONS.REPORTS",
      'sterilization': "OFFICE.PERMISSIONS.STERILIZATIONS",
    };
    service.USER_PERMISSIONS.manager = _.concat(service.USER_PERMISSIONS.manager, service.USER_PERMISSIONS.team);
    service.USER_PERMISSIONS.master = _.concat(service.USER_PERMISSIONS.master, service.USER_PERMISSIONS.manager);

    return service;

    
  }

}());

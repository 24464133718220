(function () {
  'use strict';

  angular.module('sowInventory')
  .directive('sowIiSupplierForm', sowInventoryItemSupplierFormDirective)
  .controller('sowInventoryItemSupplierFormController', sowInventoryItemSupplierFormController);

  function sowInventoryItemSupplierFormDirective () {
    return {
      restrict: 'E',
      replace: true,
      templateUrl: 'sow-inventory/directives/item-supplier-form.html',
      controller: 'sowInventoryItemSupplierFormController',
      controllerAs: 'iisfCtrl',
      scope: {
        'item': '=',
      },
    };
  }

  function sowInventoryItemSupplierFormController ($scope, $q, sowInventoryService, errorService, elementService, membershipService) {
    var ctrl = this;
    ctrl.onSupplierSearch = onSupplierSearch;

    init();
    return ctrl;

    function init () {
      defineLocks();
    }

    function onSupplierSearch(name) {
      if (!name) {
        return false;
      }

      return elementService.get('supplier', null, {
        endpoint: 'search',
        name: name
      })
      .then(function (suppliers) {
        return _.map(suppliers);
      });
    }

    function defineLocks () {
      // SPD-1966 price lock
      // officeGroup master office users will be able to set prices, while others will not
      const membership = membershipService.get();
      const property_path = $scope.item.UI.is_medication ? 'user_properties.medications.disable_price' : 'user_properties.gen_inv.disable_price';

      const price_lock_condition = _.get(membership, property_path, false);
      ctrl.price_locked = price_lock_condition;
      const supplier_lock_condition = _.get(membership, "user_properties.gen_inv.disable_supplier", false);
      ctrl.supplier_locked = supplier_lock_condition;
      const sku_lock_condition = _.get(membership, "user_properties.gen_inv.disable_sku", false);
      ctrl.sku_locked = sku_lock_condition;
    }

  }
}());

(function () {
  'use strict';

  /** @ngInject */
  class AnalyticsHelperService {

    constructor($window, $location, $rootScope) {
      this.$location = $location;
      this.$rootScope = $rootScope;

      this.dataLayer = $window.dataLayer || [];
    }

    /**
     * Adds an eCommerce event to the dataLayer.
     * Note that `path` is a custom prop we've added.
     * @see {@link https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm}
     * @param {string} event
     * @param {object[]} products
     * @param {object|undefined} params
     */
    addEcommerceEvent(event, products, params) {
      this.dataLayer.push({ecommerce: null}); // Clear the previous ecommerce object
      this.dataLayer.push({
        event,
        path: this.$location.url(),
        ecommerce: this._getEcommerce(products, params),
      });
    }

    /**
     * Creates an eCommerce object and filters out the vendor properties we don't want to send to GA.
     * @see {@link https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm}
     * @param {string} event
     * @param {object[]} products
     * @param {object|undefined} params
     */
    addProductInfoEcommerceEvent(event, products, params) {
      this.dataLayer.push({ecommerce: null}); // Clear the previous ecommerce object
      this.dataLayer.push({
        event,
        path: this.$location.url(),
        ecommerce: this._createEcommerceWithoutVendor(products, params),
      });
    }

    /**
     * Adds a custom event to the dataLayer, the event includes: event name, current URL, and any parameters you
     * want to pass along
     * @param {string} event
     * @param {object} [parameters]
     */
    addCustomEvent(event, parameters) {
      const data_info = {
        event,
        path: this.$location.url(),
        ...(parameters && {
          attributes: {
            parameters,
          },
        }),
      };
  
      this.dataLayer.push(data_info);
    }

    /**
     * Returns an ecommerce object with some of its properties removed. Specifically, it removes the
     * properties related to the vendor inventory: vendor, coupon, discount, price, quantity, and vendor_type.
     * @param {object} products
     * @param {object} params
     * @returns {object}
     */
    _createEcommerceWithoutVendor(products, params) {
      const ecommerce = this._getEcommerce(products, params);

      // Remove the properties we don't want to send to GA
      const vendor_properties = ['vendor', 'coupon', 'discount', 'price', 'quantity', 'previous_quantity', 'vendor_type'];
      ecommerce.items = _.map(ecommerce.items, (item) => _.omit(item, vendor_properties));

      return ecommerce;
    }

    /**
     * Takes an array of products and generates the corresponding eCommerce object
     * @param {object[]} products
     * @param {object|undefined} params
     * @return {object}
     */
    _getEcommerce(products, params) {
      return {
        ...this._getDefaultEcommerceParams(products),
        ...params,
        marketplace_id: this._getMarketplaceId(),
        subscription_id: this._getSubscriptionId(),
        items: this._getItems(products),
      }
    }

    /**
     * Returns an object with the currency code of the current office 
     * and the total value of the products it receives
     * @param {object[]} products
     * @return {object}
     */
    _getDefaultEcommerceParams(products) {
      return {
        currency: this._getCurrencyCode(),
        value: this._getTotalValue(products),
      }
    }

    /**
     * Converts an array of products into an array of eCommerce items.
     * Note that previous_quantity is a custom prop we've added and
     * vendor is what Google refers to as an `affiliation`
     * @param {object[]} products
     * @return {object[]}
     */
    _getItems(products) {
      return products.map(product => {
        // add basic fields
        const item = {
          coupon: this._findAppliedPromo(product),
          index: product.index,
          item_brand: product.product?.manufacturer?.name ?? product.manufacturer?.name,
          item_id: product.id,
          item_name: product.product?.name ?? product.name,
          previous_quantity: product.previous_quantity,
          price: this._getProductPrice(product),
          quantity: product.quantity,
          vendor_type: this._getVendorType(product),
          vendor: product.vendor?.name_locale?.en ?? product.vendor?.name,
        }
        // add categories (if any exist)
        if (Array.isArray(product.categories)) {
          product.categories.forEach((category, index) => {
            let key = 'item_category';
            if (index > 0) {
              key += (index + 1); // eg. item_category2
            }
            item[key] = category.name;
          });
        }
        return item;
      });
    };

   /**
    * It returns the vendor type of a product.
    * @param {object} product
    * @returns {'external'|'internal'}
    */
    _getVendorType(product) {
      const vendor_type = _.get(product, 'UI.is_external', false) ? 'external' : 'internal';
      return vendor_type;
    }


    /**
     * If the user has met the threshold for the selected vendor's promo (if any)
     * to be applied, returns the type of promotion which was applied
     * @param {object} product
     * @return {string | undefined}
     */
    _findAppliedPromo(product) {
      if (product.coupon) {
        return product.coupon;
      }
      
      const promo = product.vendor_inventory_promotion ?? _.first(product.promotions);
      const promo_threshold = promo?.promotion_properties?.buy;
      if (product.quantity >= promo_threshold) {
        return promo.promotion_type;
      }
    }

    /**
     * Returns the currency code for the current office's country
     * @return {string}
     */
    _getCurrencyCode() {
      const country_id = this._getCountryId();
      const currency_code = _.get(this.$rootScope, `config.countriesMap.${country_id}.currency_code`);
      return currency_code;
    }

    /**
     * Returns the country id of the current office.
     * @return {string}
     */
    _getCountryId() {
      return this.$rootScope.current_office?.address?.country?.id;
    }

    /**
     * Returns the id of the current marketplace.
     * @return {string}
     */
    _getMarketplaceId() {
      return this.$rootScope.current_membership?.current_marketplace?.id;
    }

    /**
     * Returns the account type (premium, etc.) of the current marketplace.
     * @return {string}
     */
    _getSubscriptionId() {
      return this.$rootScope.current_office?.account_type;
    }

    /**
     * Returns the total value of a list of products
     * @param {object[]} products
     * @return {string}
     */
    _getTotalValue(products) {
      let total_value = 0;
      for (const product of products) {
        const quantity = product.quantity || 1;
        const product_total = quantity * this._getProductPrice(product);
        total_value += product_total;
      }
      return total_value.toFixed(2);
    }

    /**
     * Returns the price of a product.
     * @param {object} product
     * @return {string}
     */
    _getProductPrice(product) {
      const price = product.price || product.list_price || 0;
      return Number(price).toFixed(2);
    }

  }

  angular.module('sowAnalytics')
    .service('AnalyticsHelperService', AnalyticsHelperService);

})();

(function () {
  'use strict';

  angular.module('sowOrders')
    .controller('orderPromoQuantityController', orderPromoQuantityController)
    .directive('orderPromoQty', ordersPromoQuantityDirective);

  function ordersPromoQuantityDirective() {
    return {
      restrict: 'E',
      templateUrl: 'sow-orders/directives/order-promo-qty.html',
      controller: 'orderPromoQuantityController',
      controllerAs: 'orpqCtrl',
      scope: {
        'item': '=',
        'source': '=',
      },
    };
  }

  function orderPromoQuantityController($scope) {
    /*jshint validthis:true*/
    var ctrl = this;

    init();

    return ctrl;

    function init() {
      var total_qty = _.toNumber($scope.source);
      var ordered_qty = total_qty - $scope.item.quantity_free;
      var promo = _.get($scope.item, 'vendor_inventory_promotion')
      var has_promo = !_.isNil(_.get(promo, 'id'));

      if (has_promo && promo.promotion_type === 'BUY_GET') {
        var times_promo_was_met = Math.floor(total_qty / _.toNumber(promo.promotion_properties.buy));
        var extra_qty = (_.toNumber(promo.promotion_properties.get) * times_promo_was_met);
        $scope.extra_qty = extra_qty;
      }
      $scope.total_qty = total_qty;
      $scope.ordered_qty = ordered_qty;
    }

  }

})();
//
// This module is for misc. messages that users may be redirected to (e.g. from
// an email) that don't have other associations. For example, the "Email
// Unsubscribe Complete" page.
//
angular.module('app.marketplace.ui.messages', [])

  .config(function($stateProvider, $urlRouterProvider) {
    $stateProvider
      //
      // Unsubscribe page notifying users coming from an email link that they have been unsubscribed.
      //
      .state('app.unsubscribe', {
        url: '/unsubscribed',
        templateUrl : 'templates/marketplace/messages/views/unsubscribe.html'
      })

      .state('app.unsubscribe_failed', {
        url: '/unsubscribe_failed',
        templateUrl : 'templates/marketplace/messages/views/unsubscribe_failed.html'
      });
  })

;

(function () {
  'use strict';

  angular.module('app.marketplace.support.debug').directive('mozartsGhost', mozartsGhost);

  function mozartsGhost ($rootScope) {
    return {
      restrict: 'E',
      scope: {},
      template: [
        '<span class="mg-icon"> &pi; </span>',
        '<span class="mg-text"> v{{ui_version}} </span>',
      ].join("\n"),
      link: function ($scope) {
        $scope.ui_version = $rootScope.ui_version;
      }
    };
  }

}());

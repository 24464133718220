(function () {
  'use strict';
  angular.module('sowMarketplace')
  .controller('MarketplaceOrderController', MarketplaceOrderController);

  function MarketplaceOrderController ($scope, $state, $location, $mdDialog, sessionService, errorService, cartService, accountService, sowApprovalService, sowApprovalHelperService, coreUIService, mktHelperService, AccessService, sowAnalyticsService) {
    /*jshint validthis: true */
    const ctrl = this;
    ctrl.confirmation_form = null;
    ctrl.show_confirmation_form = false;
    ctrl.is_checked = false;
    ctrl.confirmation_required = false;
    ctrl.has_auto_card_submission_occurred = false;

    ctrl.hideForm = hideForm;
    ctrl.completeOrder = completeOrder;
    ctrl.scrollToTop = scrollToTop;
    ctrl.shouldShowCheckoutError = shouldShowCheckoutError;
    ctrl.hideCheckoutError = hideCheckoutError;
    ctrl.isOrderAvailable = isOrderAvailable;

    // -------------------- private methods (exposed for testing only) --------------------

    ctrl._handleCheckoutError = _handleCheckoutError;

    ctrl.$onInit = init;

    return ctrl;

    function init () {
      defineLocks();
      ctrl.breadcrumb_button = _createBreadcrumbButton();
      _checkForRedirect();
      _refreshSession();

      $scope.$on('cartService:end-action:item-change', _updateCart);
      $scope.$on('cartService: set-cart', _updateCart);
      $scope.$on('Marketplace:complete-order', completeOrder);
      $scope.$on('external-vendors-prices-refreshed', _handleExternalVendorPriceRefresh);
      $scope.$on('credit-card-auto-upload-failed', _handleCreditCardAutoUploadFailure);
    }

    /**
     * The cart service uses the current session; we need to refresh the session to update
     * the cart items every time the user visits the cart section.
     */
    function _refreshSession () {
      sessionService
        .refreshSession()
        .then(() => {
          _updateCart();
          _logBeginCheckout();
        })
        .catch(errorService.uiErrorHandler);
    }

    /**
     * Logs information about each cart vendor group prior to checkout
     */
    function _logBeginCheckout() {
      for (const group of ctrl.cart.vendor_groups) {
        const {checkout_type, products, subtotal, vendor, vendor_type} = _getGroupInfo(group);
        sowAnalyticsService.logBeginCheckout(products, {
          checkout_type,
          subtotal,
          value: ctrl.cart.total,
          vendor,
          vendor_type,
        });
      }
    }

    /**
     * Logs information about each cart vendor group following checkout
     */
    function _logCheckout(completed_order) {
      for (const group of completed_order.vendor_groups) {
        const {
          checkout_type,
          parent_id,
          products,
          promo_code,
          shipping_subtotal,
          subtotal,
          tax,
          vendor,
          vendor_type,
        } = _getGroupInfo(group);
        if (products.length) {
          sowAnalyticsService.logCheckout(products, {
            checkout_type,
            coupon: promo_code,
            order_hrid: ctrl.cart.order_id,
            parent_id,
            shipping: shipping_subtotal,
            subtotal,
            tax,
            value: completed_order.total,
            vendor,
            vendor_type,
          });
        }
      }
    }

    /**
     * Returns information about the cart vendor group it receives
     * @param {object} group
     * @return {object}
     */
    function _getGroupInfo(group) {
      const {
        checkout_type,
        id,
        is_external_vendor,
        items,
        items_subtotal,
        promo_code,
        shipping_subtotal,
        tax,
        vendor_name,
      } = group ?? {};
      return {
        checkout_type,
        id,
        parent_id: ctrl.cart.id,
        products: _createEventProducts(items),
        promo_code,
        shipping_subtotal,
        subtotal: items_subtotal,
        tax,
        vendor: vendor_name,
        vendor_type: is_external_vendor ? 'external' : 'internal',
      }
    }

    /**
     * Adds vendor inventory information to each of the items it receives,
     * preparing them to be logged as the `products` list of a GA event
     * @param {object[]} items
     * @return {object[]}
     */
    function _createEventProducts(items) {
      return _.map(items, item => _.extend({}, item.vendor_inventory, item));
    }

    /**
     * Looks for HTML text which is a form that the user needs to read and accept
     * in order to purchase an item.
     *
     * @return {*}
     */
    function _checkForConfirmationForm () {
      ctrl.confirmation_form = null;
      ctrl.confirmation_required = false;
      var need_consent = false;
      _.each(_.get(ctrl, 'cart.items', []), function (item) {
        need_consent = _.get(item, 'vendor_inventory.properties.need_consent', false);
        if (need_consent) {
          ctrl.confirmation_form = need_consent;
          ctrl.confirmation_required = true;
        }
      });
      if (!ctrl.confirmation_required) {
        ctrl.show_confirmation_form = false;
      }
    }

    function hideForm () {
      ctrl.show_confirmation_form = false;
    }

    function _openProcessingDialog () {
      // prevent flicker in event that dialog is already open
      if (ctrl.dialog) {
        return;
      }
      ctrl.dialog = $mdDialog.show({
        templateUrl: 'sow-mkt/modals/mkt-process-order.html',
        parent: angular.element(document.body),
        clickOutsideToClose: false,
      });
      return ctrl.dialog;
    }

    function _closeProcessingDialog () {
      ctrl.dialog = null;
      $mdDialog.cancel();
    }

    function _updateCart () {
      ctrl.cart = cartService.get();
      ctrl.cart_is_empty = _.size(_.get(ctrl, 'cart.items')) === 0;
      _checkForConfirmationForm();
    }

    function _refreshCart () {
      return cartService.refreshCart();
    }

    /**
     * If the current time is more than 15 minutes after the last time we refreshed the price, then
     * we need to refresh the price of product(s) which the user will purchase from external vendor(s)
     * @returns A boolean value.
     */
    function _isPriceRefreshRequired () {
      const max_minutes_before_refresh_required = 15;
      const now = moment();
      const minutes_since_last_price_refresh = now.diff(ctrl.last_external_vendor_price_refresh, 'minutes');
      const is_refresh_required = minutes_since_last_price_refresh >= max_minutes_before_refresh_required;
      return is_refresh_required;
    }

    function completeOrder () {
      if (ctrl.confirmation_required && !ctrl.is_checked) {
        ctrl.show_confirmation_form = true;
        return;
      }
      // check if we need to refresh prices for products purchased from external vendors
      if (_isPriceRefreshRequired()) {
        // show a dialog if so, informing the user and enabling them to manually refresh prices
        return _showPriceRefreshDialog();
      }
      if (ctrl.approval_required) {
        // dialog
        return sowApprovalService.getAuthorizationAndApprovers(null, 'cart_order', ctrl.cart.id)
        .then((auth_response) => {
          if (auth_response.need_approval) {
            // open dialog
            const user_id = accountService.get().id;
            return sowApprovalHelperService.openDialog(null, auth_response.approvers_list, user_id, 'cart_order', ctrl.cart.id)
            .then((dialog_response) => {
              // go to pending order page
              _refreshCart();
              _updateCart();
              _goToPendingOrderPage(dialog_response);
            });
          } else {
            // finish
            return _finishOrder();
          }
        });
      } else {
        return _finishOrder();
      }
    }

    function _finishOrder () {
      $scope.apiErrors = null;
      ctrl.saving = true;
      _openProcessingDialog();
      if (_checkIfCardShouldBeAdded()) {
        return _attemptToSaveCardBeforeCheckingOut();
      }
      const completed_order = angular.copy(ctrl.cart);

      return cartService.completeCart(ctrl.cart)
      .then(function () {
        _logCheckout(completed_order);
        _updateCart();
        // for single order, only HRID is used
        // for mixed checkout, we send the cart id instead

        const order_id = ctrl.cart.order_id;
        const checkout_id = ctrl.cart.id;
        let parameters = {order_number: order_id};

        if(_.size(_.get(ctrl, 'cart.po_hrids')) > 0) {
          _.set(parameters, 'search_param', 'checkout_id');
          _.set(parameters, 'order_number', checkout_id);
        }

        $state.go('app.mkt.finished', parameters);
      })
      .catch(function(error) {
        _handleCheckoutError(error);
      })
      .finally(function(){
        // reset auto card submission
        ctrl.has_auto_card_submission_occurred = false;
        ctrl.saving = false;
        _refreshCart();
        _closeProcessingDialog();
        ctrl.show_confirmation_form = false;
      });
    }

    function isOrderAvailable () {
      return !ctrl.show_confirmation_form && !ctrl.cart_is_empty
    }

    /**
     * If a credit card is required, and no card is on file, and we have not
     * yet attempted to auto upload a credit card before checking out, then
     * the card should be added before we attempt to submit the order
     * @return {boolean}
     */
    function _checkIfCardShouldBeAdded() {
      // only try to upload the card if this is the first attempt to auto upload
      // a card (since otherwise we could create an infinite loop)
      if (ctrl.has_auto_card_submission_occurred) {
        return false;
      }
      const is_card_required = ctrl.cart.show_cc_billing;
      return is_card_required;
    }

    /**
     * Broadcasts an event so that the billing controller attempts to upload a new credit card
     * using the data the user has entered into its form. The billing controller will then
     * restart the checkout process, and ideally we will now be able to complete the order.
     */
    function _attemptToSaveCardBeforeCheckingOut() {
      // prevent an infinite loop in the event that card upload fails
      ctrl.has_auto_card_submission_occurred = true;
      $scope.$broadcast('save-card-and-complete-order');
    }

    function scrollToTop () {
      $location.hash('top-of-confirmation-form');
    }

    /**
     * Ensures the appropriate error is displayed, updates the cart to account for the error,
     * and scrolls to the top of the page where the error is displayed.
     * @param {object} error - The error object returned from the API.
     */
    function _handleCheckoutError(error) {
      _handleCheckoutErrorCode(error.internal_code);
      _updateCart();
      scrollToTop();
    }

    /**
     * Gets the localized text for an error code and displays that text in an alert.
     * @param {string} error_code - The internal_code property of the error
     */
    function _handleCheckoutErrorCode(error_code) {
      const t_checkout_error_text = mktHelperService.getLocalizedErrorFromCode(error_code);
      ctrl.checkout_error_text = t_checkout_error_text;
      ctrl.show_checkout_error = true;
    }

    /**
     * Returns true if we should display an error and we have text to display, and false if not. 
     * @returns {boolean}
     */
    function shouldShowCheckoutError() {
      const checkout_error_condition = ctrl.checkout_error_text && ctrl.show_checkout_error;
      return Boolean(checkout_error_condition);
    }

    /**
     * Hides the checkout error banner.
     */
    function hideCheckoutError() {
      ctrl.show_checkout_error = false;
    }

    /**
     * Hide the processing dialog, reset the credit card auto-submit state, and display
     * the error we get from the API when the billing method is invalid.
     */
    function _handleCreditCardAutoUploadFailure() {
      ctrl.show_confirmation_form = false;
      _closeProcessingDialog();
      ctrl.has_auto_card_submission_occurred = false;
      // simulate the error we get back from the API when the credit card is invalid
      _handleCheckoutError({internal_code: 'CART_STRIPE_BILLING_ERROR'});
    }

    /**
     * Shows a dialog box with a button that, when clicked, broadcasts an event so that
     * items from external vendor(s) will have their prices refreshed
     * @returns A promise.
     */
    function _showPriceRefreshDialog() {
      const headline = 'MARKETPLACE.CART.SESSION_EXPIRED';
      const tagline = 'MARKETPLACE.CART.PLEASE_REFRESH';
      const actions = [
        {
          cta: 'MARKETPLACE.CART.REFRESH_PAGE',
          name: 'refresh-page-btn',
          classes: 'sow-primary-btn sow-lg-btn'
        },
      ];
      const hide_close_btn = true;
      return coreUIService.showDialog(
        headline,
        tagline,
        actions,
        hide_close_btn,
      ).then(() => {
        $scope.$broadcast('refresh-external-vendor-prices');
      });
    }

    /**
     * Sets the value of ctrl.last_external_vendor_price_refresh to the time which was
     * broadcasted. This allows us to keep track of how much time has elapsed since we
     * last refreshed external vendor prices (so we know if checkout can be completed
     * immediately or another refresh is required).
     * @param _event - The event itself (unused)
     * @param last_external_vendor_price_refresh - The time of the last external vendor price refresh.
     */
    function _handleExternalVendorPriceRefresh(_event, last_external_vendor_price_refresh) {
      ctrl.last_external_vendor_price_refresh = last_external_vendor_price_refresh;
    }

    /**
     * Returns an object with a label and state depending on whether the ctrl.is_full_page_cart exists or not.
     * @return {{label: string, state: string}}}
     */
    function _createBreadcrumbButton () {
      if (ctrl.is_full_page_cart) {
        return {
          label: 'MARKETPLACE.CHECKOUT.BACK_TO_SHOPPING_CART',
          route: 'app.mkt.cart',
        };
      } 

      return {
        label: 'MARKETPLACE.CHECKOUT.CONTINUE_SHOPPING',
        route: 'app.mkt',
      };
    }

    function defineLocks () {
      ctrl.approval_required = AccessService.getProperty('purchase_orders.approval_req');
      ctrl.is_full_page_cart = AccessService.hasOfficeProperty('shopping_cart', 'full_page_cart');
    }

    function _goToPendingOrderPage (approval_request) {
      $state.go('app.mkt.pending', {approval_request, 'request_id': approval_request.id, 'request_hrid': approval_request.hrid});
    }

    
    /**
     * While we still have both pages in prod, check if a redirect is necessary
     */
    function _checkForRedirect () {
      const flag = AccessService.getOfficeFeatureFlag('external_vendors');
      if (flag) {
        $state.go('app.mkt.vendor-order');
      }
    }
  }
})();

(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.dashboard')
    .controller('dashboardLocationsController', dashboardLocationsController);

  function dashboardLocationsController ($scope, $filter, elementService, $q, $timeout,
    inventoryItemService, sowDashboardService, errorService, vendorService, officeService, 
    AddToCartDialog, AddLocationsDialog, EditLocationDialog, DeleteLocationDialog, smoothScroll, sgToast) {
    /*jshint validthis: true */
    var ctrl = this;
    var highestNbItems = 0;
    var inventory = null;
    var registeredItem = null;

    // controller values
    ctrl.checkedItems = inventoryItemService.checkedItems;
    ctrl.locationBundles = [];

    // controller functions
    ctrl.sortingFn = sortingFn;
    ctrl.getInventoryValue = getInventoryValue;
    ctrl.openBundle = openBundle;
    ctrl.closeBundle = closeBundle;
    ctrl.isCurrentBundle = isCurrentBundle;
    ctrl.printQrCodes = printQrCodes;
    ctrl.editLocation = editLocation;
    ctrl.deleteLocation = deleteLocation;
    ctrl.addLocations = addLocations;


    //
    // scope item functions, necessary for legacy inventory item directive
    //
    $scope.toggleItem = toggleItem;
    $scope.viewItem = viewItem;
    $scope.editItem = editItem;
    $scope.removeItem = removeItem;
    $scope.duplicateItem = duplicateItem;
    $scope.checkItem = checkItem;
    $scope.addToCart = addToCart;
    $scope.getItemOnHand = getItemOnHand;

    $scope.supress_product_slideout = true;

    initialize();

    return ctrl;

    function initialize () {
      ctrl.loading = true;
      ctrl.locationBundles = [];

      $scope.$on("$destroy", clearCheckedItems);

      $scope.$on('editInventoryItemController: bundle-updated', function (event, bundle) {
        matchItems();
      });

      $scope.$on('officeService: locations-updated', function(ev, _locations){
        _.set(ctrl, 'locations', _locations);
        ctrl.loading = true;
        matchItems();
        ctrl.loading = false;
      });
      $scope.$on('officeService: location-created', function(ev, newLocation){
        ctrl.loading = true;
        ctrl.locations.push(newLocation);
        matchItems();
        ctrl.loading = false;
      });
      $scope.$on('officeService: location-removed', function(ev, location){
        removeLocalLocation(location);
      });

      fetchLocations();
    }

    function addLocations (ev) {

      AddLocationsDialog.show(ev, ctrl.locations);
    }

    function fetchLocations () {
      return sowDashboardService.getLocations().then(function(response){
        ctrl.locations = response;
        matchItems();
      })
      .catch(function(error){
        var t_message = $filter('translate')('ERRORS.LOCATIONS_LOAD')
        errorService.uiErrorHandler(error || t_message, 0);
      })
      .finally(function(){
        ctrl.loading = false;
      });
    }

    function loadLocationItems (bundle) {
      var lid = bundle.id;
      if(lid === 'no'){
        lid = null;
      }
      return sowDashboardService.getItemsByLocation(lid)
      .then(function(response){
        bundle = angular.extend(bundle, response, {'loaded':true});
        return bundle;
      });
    }

    function matchItems () {
      ctrl.locationBundles = [
        {
          "name": "(No Location)",
          "id": "no",
          "location": null,
          "loaded": false,
        },
      ];

      _.forEach(ctrl.locations, function(_location) {
        var _bundle = {
          "name": _location.name,
          "id": _location.id,
          "location": _location,
          "loaded": false,
          num_products: _location.num_products,
        };
        ctrl.locationBundles.push(_bundle);
      });
    }

    function editLocation  (bundle, event) {
      event.stopPropagation();

      EditLocationDialog.show(bundle, event).then(function () {
        fetchLocations();
      });
    }

    function deleteLocation (bundle, event) {
      event.stopPropagation();
      if (!bundle.loaded) {
        loadLocationItems(bundle)
        .then(function(){
          doit(bundle);
        });
      } else {
        doit(bundle);
      }

      function doit (bundle) {
        var location = _.extend({}, bundle.location, {'num_products': bundle.total_products});

        if (bundle.total_products > 0) {
          DeleteLocationDialog.show(location, event).then(function (shouldDelete) {
            if (shouldDelete) {
              remove(location);
            }
          });
        } else {
          remove(location);
        }
      }

      function remove (location) {
        officeService.removeLocation(location).then(function () {
          removeLocalLocation(location);
          var t_message = $filter('translate')('OFFICE.LOCATION.REMOVED');
          sgToast.showSimple(t_message);
        }).catch(function (error) {
          errorService.uiErrorHandler(error);
        });
      }
    }

    function printQrCodes (bundle, $event) {
      $event.preventDefault();
      $event.stopPropagation();

      if (!bundle.loaded) {
        return loadLocationItems(bundle).then(function(){
          return tryPrint(bundle);
        });
      } else {
        return tryPrint(bundle);
      }

      function tryPrint (bundle) {
        if(bundle.items.length > 0){
          return inventoryItemService.printLabelsForLocation(bundle.location);
        } else {
          var t_message = $filter('translate')('ERRORS.NO_ITEMS')
          return errorService.uiErrorHandler(t_message);
        }
      }
      
    }

    function removeLocalLocation (location) {
      _.remove(ctrl.locationBundles, function(bundle){
        return bundle.id === location.id;
      });
      _.remove(ctrl.locations, function(_location){
        return _location.id === location.id;
      });
      var t_message = $filter('translate')('OFFICE.LOCATION.REMOVED');
      sgToast.showSimple(t_message);
    }

    function loadInventory () {
      return officeService.get(true).then(function (office) {
        return officeService.getInventory().then(function (payload) {
          ctrl.inventory = payload;
        });
      }).catch(function (error) {
        var t_message = $filter('translate')('ERRORS.INVENTORY_LOAD')
        errorService.uiErrorHandler(error || t_message, 0);
      });
    }

    function clearCheckedItems () {
      _.map(ctrl.checkedItems, function(item){
        _.set(item, 'UI.checked', false);
      });
      ctrl.checkedItems = angular.copy([], ctrl.checkedItems);
    }

    function sortingFn (bundle) {
      return _.get(bundle, "location.name", "ZZZZZZZZZ");
    }

    function getInventoryValue(items) {
      return _.reduce(items, function(sum, item) {
        var amount = inventoryItemService.calculateInventoryValue(item);
        return sum + amount;
      }, 0);
    }

    //
    //
    //
    //
    //
    //

    function printItemQrCode (item) {
      return inventoryItemService.printLabelsForItems([item]);
    }


    function isCurrentBundle (bundle){
      return ctrl.currentBundle === bundle;
    }

    function openBundle (bundle) {
      if (!bundle.loaded) {
        loadLocationItems(bundle);
        // .then(function(bundle){
        //   if(_.size(bundle.items) < 1){
        //     return closeBundle(bundle);
        //   }
        // });
      }
      checkNavigate()
      .then(function(){
        $scope.currentItem = null;
        ctrl.currentBundle = bundle;
        bundle.force_refresh = true;
        //Scroll to bundle.
        $timeout(function(){
          smoothScroll.scrollToElementById('bundle-'+bundle.name, {offset : -100, speed: 0, onlyUp: true});
        },280); //280 ms to compensate for transition animation.
      });
    }

    function closeBundle (bundle) {
      checkNavigate()
      .then(function(){
        ctrl.currentBundle = "none";
        $scope.currentItem = null;
      });
    }

    function addItem (){
      $mdDialog.show({
        controller: 'addInventoryItemController',
        templateUrl: 'templates/marketplace/inventory/modals/add-inventory-item.html',
        parent: angular.element(document.body),
        locals:{
          productName: null
        },
        clickOutsideToClose:true
      });
    }

    function addToCart (item, clickEvent) {
      $scope.modifyingItem = true;
      return AddToCartDialog.show(item.product_id, clickEvent).finally(function () {
        $scope.modifyingItem = false;
      });
    }

    //
    // Toggle an item row open or closed.
    //
    function toggleItem (item, group) {
      checkNavigate(true)
      .then(function(){
        if ($scope.currentItem === item) {
          $scope.currentItem = null;
        } else {
          $scope.currentItem = item;
        }

        if ($scope.currentItem) {
          $scope.currentGroup = group;
        } else {
          $scope.currentGroup = null;
        }

        if($scope.currentItem && $scope.currentGroup){
          // Scroll to item.
          $timeout(function(){
            var ItemID = 'bundle-{0}-inventory-item-{1}'.format($scope.currentGroup.name, item.id);
            smoothScroll.scrollToElementById(ItemID, {offset : -100, speed: 12, onlyUp: false});
          }, 280); //280 ms to compensate for transition animation.
        }
      });
    }

    function viewItem (ev, item, edit) {
      $scope.filters.search_text = item.name;
      $scope.filters.inventory_search = $scope.filters.search_text;
      $scope.currentItem = item;
      $scope.currentGroup = $scope.overviewBundle;

      //Scroll to item.
      $timeout(function(){
        smoothScroll.scrollToElementById('bundle-' + $scope.currentGroup.name + '-inventory-item-'+item.id, {offset : -100});
      });
    }

    function editItem (ev, item) {
      $scope.viewItem(ev, item, true);
    }

    function removeItem (item) {
      $scope.modifyingItem = true;

      function _success () {
        $scope.modifyingItem = false;
        var t_message = $filter('translate')('TOAST.ITEM_REMOVED');
        sgToast.showSimple(t_message);
        $mdDialog.hide();
        removeLocalItem(item);
      }

      function _error (error) {
        $scope.modifyingItem = false;
        var t_message = $filter('translate')('ERRORS.ITEM_REMOVE')
        errorService.uiErrorHandler(error || t_message, 0);
      }

      elementService
        .remove('inventoryItem', item, true)
        .then(function (inventory_item) {
          _success();
        }).catch(function (error) {
          var poError = _.get(error, 'purchase_order_error');

          if (poError) {
            var drafts = _.filter(poError.purchase_orders, {'status': 'Draft'});
            var candidate = angular.extend(angular.copy(item), poError);

            if (drafts.length === poError.purchase_orders.length) {
              return DeleteInventoryDraftPODialog
                .show([candidate])
                .then(_success, _error);
            } else {
              return DeleteInventoryActivePODialog
                .show([candidate])
                .then(function () {
                  $scope.modifyingItem = false;
                  var t_message = $filter('translate')('TOAST.COULD_NOT_REMOVE_ITEM');
                  sgToast.showSimple(t_message);
                  $mdDialog.hide();
                  _success();
                });
            }
          } else {
            _error(error);
          }
        });
    }

    function removeLocalItem (item) {
      _.remove(ctrl.inventory.items, item);
      matchItems();
    }

    //
    // Duplicate an Inventory Item
    //
    function duplicateItem (ev, item) {
      $mdDialog.show({
        controller: 'duplicateItemController',
        templateUrl: 'templates/marketplace/inventory/modals/duplicate-item.html',
        parent: angular.element(document.body),
        targetEvent: ev,
        clickOutsideToClose:true,
        locals : {
          item : angular.copy(item)
        }
      }).then(function(new_inventory_item){
        $scope.viewItem(null, new_inventory_item, true);
      });
    }

    //
    // Mark an inventory item as "checked"
    //
    function checkItem (item) {
      _.set(item, "UI.checked", !_.get(item, "UI.checked", false) );
      // item.UI.checked = !item.UI.checked;
      if(item.UI.checked){
        ctrl.checkedItems.push(item);
      }else{
        _.map(ctrl.checkedItems, function(this_item, i){
          if(this_item.id === item.id){
            ctrl.checkedItems.splice(i, 1);
          }
        });
      }
    }

    function hideHiddenInventoryBanner () {
      return sessionService.updateFlag('prompted_for_premium_in_inventory_with_banner', true);
    }

    //
    // Expose function to be used by inner controllers.
    //
    function registerItem (item, saveFn) {
      registeredItem = {item: item, saveFn: saveFn};
    }

    //
    // Check if an item has been registered (aka being edited). If so, show
    // redirect modal and save if accepted.
    //
    function checkNavigate (rejectOnSave) {
      if(!!registeredItem){
        var form = angular.element("form[name=inventoryItemForm]");
        if(form && form.length && form.scope().inventoryItemForm && form.scope().inventoryItemForm.$dirty){
          return RedirectModalService.show(null, 'SaveChanges')
          .then(function(){
            var saveFn = registeredItem.saveFn;
            registeredItem = null; //Clear to avoid infinite loop
            saveFn();
            if(rejectOnSave){
              return $q.reject(); //Avoid double toggle
            }
          }).catch(function(){
            return $q.resolve();
          });
        }else{
          return $q.resolve();
        }
      }else{
        return $q.resolve();
      }
    }

    /**
     * @desc Open dialog to add product in step 2 and product name filled with search text
     *
     * @param {*} name product name
     */

    function addItemDialog (name) {

      $mdDialog.show({
        controller: 'addInventoryItemController',
        templateUrl: 'templates/marketplace/inventory/modals/add-inventory-item.html',
        parent: angular.element(document.body),
        clickOutsideToClose:true,
        bindToController: true,
        locals:{
          productName: name
        }
      })
      .then(function(newItem){
        if(newItem && newItem.id){
          $state.go('app.inventory.all',{'itemId': newItem.id});
        }
      });
    }

    function getItemOnHand (item) {
      return inventoryItemService.calculateInventoryOnHand(item);
    }
  }
}());

(function () {
  
  function ExternalVendorAccountCP (ExternalVendorCP) {
    const ExternalVendor = ExternalVendorCP.getClass();
    
    /**
    *
    * @class ExternalVendorAccount
    * @typedef ExternalVendorAccount
    */
    class ExternalVendorAccount {
      constructor(json_data) {
        this.user_id = json_data.user_id;
        this.vendor_id = json_data.vendor_id;
        this.connected_status = json_data.connected_status;
      }

      connected_status;
      get connected_status () {
        return this.connected_status;
      }
      
      vendor_id;
      get vendor_id () {
        return this.vendor_id;
      }
      set vendor_id (new_vendor_id) {
        this.vendor_id = new_vendor_id;
      }
            
      user_id;
      get user_id () {
        return this.user_id;
      }
      set user_id (new_user_id) {
        this.user_id = new_user_id;
      }
      
      
    }
    
    this.getClass = () => {
      return ExternalVendorAccount;
    }
  }
  
  angular.module('sowMarketplace')
  .service("ExternalVendorAccountCP", ExternalVendorAccountCP);
})();

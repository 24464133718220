angular.module("app.shared.support")
  .directive('noUserScroll', function () {
    return {
      restrict: 'A',
      link: function (scope, element, attrs) {
        element.on('wheel', function (event) {
          var is_side_scrolling = (event.originalEvent.deltaX !== 0);

          if (is_side_scrolling || event.shiftKey) {
            preventScrolling(event);
          }

        });

        function preventScrolling(event) {
          event.preventDefault();
          return false;
        }
      }
    };
  });

(function () {
  'use strict';

  const sowAlertComponent = {
    selector: 'sowAlert',
    templateUrl: 'sow-core-ui/components/sow-alert.html',
    bindings: {
      type: '@',
      header: '@',
      body: '@?',
      closeFn: '&?',
    },
    controller: 'sowAlertController',
    controllerAs: 'sowAlertCtrl',
  };

  function sowAlertController() {
    const ctrl = this;

    ctrl.ICON_SOURCES = {
      critical: 'styles/img/icons/icon_alert-circle.svg',
      information: 'styles/img/icons/icon_info.svg',
      success: 'styles/img/icons/icon_circle_check.svg',
      warning: 'styles/img/icons/icon_alert_triangle.svg',
    };

    ctrl.$onInit = () => {
      setIconSrc();
    }

    // Ensure that ctrl.icon_src always reflects alert type after update
    ctrl.$onChanges = () => {
      setIconSrc();
    }

    return ctrl;

    function setIconSrc() {
      // Default icon source is information (most common type)
      ctrl.icon_src = ctrl.ICON_SOURCES[ctrl.type] || ctrl.ICON_SOURCES['information'];
    }
  }

  angular.module('sowCoreUI')
    .controller(sowAlertComponent.controller, sowAlertController)
    .component(sowAlertComponent.selector, sowAlertComponent);

})();

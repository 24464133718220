class formService {
  /** 
   * Checks the required fields of a form and manually
   * toggles the dirty and touched properties of any invalid
   * fields so that their error state will be displayed. 
   * 
   * @param {Object} form 
   * 
   * @return {Boolean} 
  */
  checkRequiredFields(form) {
    form.$setSubmitted();
    if (form.$error) {
      for (const key in form.$error) {
        for (const input of form.$error[key]) {
          input.$setDirty();
          input.$setTouched();
        };
      };
    }
    return form.$valid;
  }

  getFieldsWithChangedValues({form, initial_form_data, updated_data, filtered_fields = []}) {
    if (form && initial_form_data && updated_data) {

      // Get all field names in the form that are not in the filtered_fields array
      const fields = form.$$controls.flatMap((control) => {
        if(!filtered_fields.includes(control.$name)) {
          return control.$name;
        }
        return []
      })

      // Check if the initial value of the field is different from the current value
      return fields.flatMap(field => {
        const initial_value = initial_form_data[field];
        const current_value = updated_data[field];

        // Check if field is a date
        if (initial_value instanceof Date && current_value instanceof Date) {
          if (initial_value.getTime() !== current_value.getTime()) {
            return field;
          } else {
            return []
          }
        }

        // Check if field is an object and compare the id
        if (_.isObject(initial_value) && _.isObject(current_value)) {
          if (initial_value.id !== current_value.id) {
            return field;
          } else {
            return []
          }
        }

        if (initial_value !== current_value) {
          return field;
        }
      
        return []
      })
    }
  }
}

angular.module('sowCoreUI')
  .service('formService', formService);

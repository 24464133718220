(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.inventory')
    .directive('actionBarInventory', ActionBarInventory)
    .controller('InventoryActionBarController', InventoryActionBarController);

  function ActionBarInventory () {
    return {
      restrict: 'AC',
      templateUrl: 'templates/marketplace/inventory/directives/action-bar-inventory.html',
      controller: 'InventoryActionBarController',
      controllerAs: 'invAbCtrl',
    };
  }

  //
  // A wrapper around a function 'action' that sets up an 'enabled' attribute.
  // The enabled attribute controls if the action is shown to the user, and
  // calling the action runs the action function.
  //
  function SelectAction (action) {
    var item = function () {
      return action.apply(null, arguments);
    };
    item.enabled = false;
    return item;
  }

  function InventoryActionBarController ($scope, $rootScope, $timeout, $state, $filter, elementService, $mdDialog,
    inventoryItemService, elementRefreshService, errorService, sgToast, sgDialog, DeleteInventoryDraftPODialog,
    DeleteInventoryActivePODialog, AddLocationsDialog, PurchaseOrderService, SupplierDialog, NavDropdownMenuService,
    inventorySharingService, alertsDataCarrierService, sowReportsService, AccessService, poHelperService)
  {
    /*jshint validthis: true */
    var ctrl = this;

    ctrl.addItem = addItemDialog;
    ctrl.newSdsDialog = newSdsDialog;
    ctrl.clearCheckedItems = clearCheckedItems;
    ctrl.showLoadingBackdrop = $scope.showLoadingBackdrop;
    ctrl.hideLoadingBackdrop = $scope.hideLoadingBackdrop;
    ctrl.features = $scope.features;
    ctrl.inventorySharingService = inventorySharingService;
    ctrl.alertsDataCarrierService = alertsDataCarrierService;
    ctrl.actions = {
      'createPurchaseOrder': createPurchaseOrder,
      'listPurchaseOrders': listPurchaseOrders,
      'addToPurchaseOrder': addToPurchaseOrder,
      'deleteItems': deleteItems,
      'addLocations': AddLocationsDialog.show,
      'addSupplier': SupplierDialog.show,
      'clearShoppingList': clearShoppingList,
      'toggleMenu': toggleMenu,
      'clickAway': clickAway,
    };


    var selectActions = ctrl.selectActions = {
      'deleteItems': SelectAction(deleteItems),
      'listPurchaseOrders': SelectAction(listPurchaseOrders),
      'addToPurchaseOrder': SelectAction(addToPurchaseOrder),
      'createPurchaseOrder': SelectAction(createPurchaseOrder),
      'addToShoppingList': SelectAction(addToShoppingList),
      'removeFromShoppingList': SelectAction(removeFromShoppingList),
      'printCodes': SelectAction(printCodes),
    };

    ctrl.exportSuppliers = sowReportsService.exportSuppliers;
    ctrl.exportLocations = sowReportsService.exportLocations;

    ctrl.$onInit = init;

    ctrl.addUser = addUser;
    ctrl.addRep = addRep;
    ctrl.addDoctor = addDoctor;
    ctrl.newUser = newUser;
    ctrl.addToOffice = addToOffice;

    // UI
    ctrl.hideListButtons = hideListButtons;
    ctrl.showNewItemButton = showNewItemButton;
    ctrl.showPrintMenu = showPrintMenu;
    ctrl.showCheckedItems = showCheckedItems;
    ctrl.shouldShowAddToShoppingListBtn = shouldShowAddToShoppingListBtn;
    ctrl.shouldShowCreateOrderBtn = shouldShowCreateOrderBtn;

    return ctrl;

    function addDoctor () {
      $rootScope.$broadcast('office-ab-add-doctor');
    }

    function addUser () {
      $rootScope.$broadcast('office-ab-add-user');
    }

    function addRep () {
      $rootScope.$broadcast('office-ab-add-rep');
    }

    function newUser () {
      $rootScope.$broadcast('new-user-modal');
    }

    function addToOffice () {
      $rootScope.$broadcast('user-add-to-office-modal');
    }

    function init () {
      ctrl.checkedItems = inventoryItemService.checkedItems;
      elementRefreshService.getCurrentVar('country', true);

      _defineLocks();

      toggleSelectActions();
      $scope.$on('$stateChangeSuccess', function (event, toState) { toggleSelectActions(toState); });
      $scope.$on('shopping-list-empty', function () { ctrl.shoppingListEmpty = true; });
      $scope.$on('shopping-list-not-empty', function () { ctrl.shoppingListEmpty = false; });

      updateShoppingListCount();
      $scope.$on('shopping-list-updated', updateShoppingListCount);
      $scope.$on("$destroy", clearCheckedItems);
    }

    function printCodes () {
      ctrl.is_printing = true;
      inventoryItemService
        .printLabelsForItems(ctrl.checkedItems)
        .then(() => {
          clearCheckedItems();
          sowReportsService.exportSuccessDialog('REPORTS.EMAIL.TYPE_CODES', null, 'pdf');
        })
        .finally(() => {
          ctrl.is_printing = false;
        })
    }

    function updateShoppingListCount () {
      inventoryItemService.loadShoppingList().then(function (items) {
        ctrl.slCount = _.get(items, 'length', 0);
      });
    }

    //
    // Refresh the select state actions to determine which should be
    // shown/hidden.
    //
    function toggleSelectActions (state) {
      _defineLocks();
      state = state || $state.current;
      selectActions.deleteItems.enabled = (
        state.name !== 'app.inventory.suppliers' &&
        state.name !== 'app.inventory.shopping_list'
      );
      selectActions.createPurchaseOrder.enabled = !_isCreatePoDisabled(state);
      selectActions.addToPurchaseOrder.enabled = !ctrl.disable_purchase_orders;
      selectActions.listPurchaseOrders.enabled = !ctrl.disable_purchase_orders;
      selectActions.addToShoppingList.enabled = (state.name !== 'app.inventory.shopping_list' && !ctrl.disable_shopping_list);
      selectActions.removeFromShoppingList.enabled = (state.name === 'app.inventory.shopping_list' && !ctrl.disable_shopping_list);
      selectActions.printCodes.enabled = (
        (state.name === 'app.inventory.all' || state.name === 'app.inventory.grid' || state.name === 'app.inventory.suppliers' || state.name === 'app.inventory.locations')
        && $scope.features.inventory_full
      );
    }

    //
    // Remove All Items From the Shopping List
    //
    function clearShoppingList (ev) {
      var t_message = $filter('translate')('TOAST.REMOVE_ALL_PRODUCTS');
      return sgDialog.showConfirm({
        'title': 'Clear Shopping List?',
        'hideTitle': true,
        'ok': 'Clear List',
        'okRed': true,
        'cancel': 'Cancel',
        'textContent': t_message,
      }).then(function () {
        inventoryItemService.loadShoppingList().then(function (items) {
          return removeFromShoppingList(items);
        });
      });
    }

    //
    // Remove all selected items from the shopping list.
    //
    function removeFromShoppingList (items, options) {
      items = items || ctrl.checkedItems;
      options = angular.extend({
        'suppressPopups': false,
      }, options);

      return inventoryItemService.batchRemoveFromShoppingList(items, {showToast: !options.suppressPopups}).then(() => {
        clearCheckedItems();
      });
    }

    //
    // Add all checked/selected inventory items to the Shopping List.
    //
    function addToShoppingList (items) {
      items = items || ctrl.checkedItems;
      const filtered_items = inventoryItemService.getNonDiscontinuedItems(items);

      return inventoryItemService.addToShoppingList(filtered_items).then(function (items) {
        var t_added = $filter('translate')('COMMON.ADDED_TO_SL');
        var t_product = $filter('translate')('COMMON.PRODUCT');
        var t_products = $filter('translate')('COMMON.PRODUCTS');
        var message = '{0} {1} {2}'.format(
          items.length,
          (items.length === 1 ? t_product : t_products),
          t_added
        );
        sgToast.showSimple(message);
        clearCheckedItems();
        return items;
      }).catch(function (error) {
        errorService.uiErrorHandler(error);
      });
    }

    function clickAway (name) {
      if (NavDropdownMenuService.isMenuActive(name)) {
        NavDropdownMenuService.hideMenu(name);
      }
    }

    function toggleMenu (name) {
      NavDropdownMenuService.toggleMenu(name);
    }

    function createPurchaseOrder () {
      const initial_data = poHelperService.getInitialPoData(ctrl.checkedItems);
      clearCheckedItems();
      $state.go('app.orders.purchase.draft', initial_data);
    }

    function listPurchaseOrders () {
      // load list
      var po_list = [];
      PurchaseOrderService.getAllByStatus('Draft')
      .then(function(response){
        po_list = _.values(response);
        if(!po_list.length){
          createPurchaseOrder();
          return;
        }
        po_list = _.sortBy(po_list, function(po){ return po.supplier.name; });
        // $scope.po_list = po_list;
        // display dialog
        $mdDialog.show({
          controller: 'poDialogController as dialogCtrl',
          templateUrl: 'templates/marketplace/inventory/modals/add-to-purchase-order.html',
          parent: angular.element(document.body),
          clickOutsideToClose:true,
          // scope: $scope,
          locals: {
            po_list: po_list,
            // cancel: $mdDialog.close,
          },
          bindToController: true,
        })
        .then(function(po){
          if(po){
            selectActions.addToPurchaseOrder(po);
          } else {
            selectActions.createPurchaseOrder();
          }
        });
      });

    }

    function addToPurchaseOrder (order) {
      const including_items = inventoryItemService.getNonDiscontinuedItems(ctrl.checkedItems, (inventoryItem) => {
        return {
          'inventory_item_id': inventoryItem.id,
          'sku': inventoryItem.sku,
          'unit_cost': inventoryItem.price || 0.00,
          'quantity': 1,
        };
      });

      order.items = _.concat(order.items, including_items);

      return PurchaseOrderService.saveDraft(order).then(function (po) {

        if ($state.includes('app.inventory.shopping_list')) {
          removeFromShoppingList(ctrl.checkedItems, {'suppressPopups': true}).then(function () {
            clearCheckedItems();
            $state.go('app.orders.purchase.draft',{poId: po.id});
          });
        } else {
          clearCheckedItems();
          $state.go('app.orders.purchase.draft',{poId: po.id});
        }
      }).catch(function (error) {
        /* the service shows an error toast in this case so we won't also do so here */
        if (error?.internal_code === 'PO_HRID_DUPLICATE') {
          return;
        }
        errorService.uiErrorHandler(error);
      });
    }

    function clearCheckedItems () {
      return inventoryItemService.clearCheckedItems();
    }

    function deleteItems (ev) {
      var itemCount = ctrl.checkedItems.length;
      var title;
      var t_yes = $filter('translate')('COMMON.YES');
      var t_no = $filter('translate')('COMMON.NO');

      if (itemCount > 1) {
        title = $filter('translate')('INVENTORY.DELETE_Q', {'x': itemCount});
      } else {
        title = $filter('translate')('INVENTORY.DELETE_Q');
      }

      var confirm = $mdDialog
        .confirm()
        .title(title)
        .targetEvent(ev)
        .ok(t_yes)
        .cancel(t_no);

      return $mdDialog.show(confirm).then(function() {
        ctrl.showLoadingBackdrop();
        var errorCount = 0;

        //
        // Wait on all promises to finish...
        //
        Q.allSettled(_.map(ctrl.checkedItems, function (item) {
          // SOW-5329 added bc supplier items are slim and lack this
          if (!_.get(item, 'office_id', null)) {
            _.set(item, 'office_id', sow.officeInfo().id);
          }
          return elementService.remove('inventoryItem', item, true)
          .then(function () {
            $rootScope.$broadcast('inventory-item-deleted', item);
            return item;
          }).catch(function (error) {
            if (_.has(error, 'purchase_order_error')) {
              error.purchase_order_error.item = item;
            }
            throw error;
          });
        })).then(function (results) {
          var dialogs = [];
          var itemsDeleted = 0;
          var errorCount = 0;
          var draftCandidates = [];
          var activeCandidates = [];

          _.forEach(results, function (result) {
            var state = result.state;
            var reason = result.reason;
            var value = result.value;

            if (state === 'fulfilled') {
              var index = _.findIndex(ctrl.checkedItems, {id: value.id});
              $rootScope.$broadcast('inventoryItem:removed', value);
              ctrl.checkedItems.splice(index, 1);
              itemsDeleted++;
            }

            if (state === 'rejected') {
              if (_.has(reason, 'purchase_order_error')) {
                var draft_pos = _.filter(reason.purchase_order_error.purchase_orders, {'status': 'Draft'});
                var candidate = angular.extend(
                  angular.copy(reason.purchase_order_error.item),
                  reason.purchase_order_error
                );

                if (draft_pos.length === reason.purchase_order_error.purchase_orders.length) {
                  draftCandidates.push(candidate);
                } else {
                  activeCandidates.push(candidate);
                }
              } else {
                errorCount++;
              }
            }
          });

          if (draftCandidates.length > 0) {
            dialogs.push(function () {
              return DeleteInventoryDraftPODialog.show(draftCandidates).then(function (deleted) {
                console.debug('DeleteInventoryDraftPODialog: deleted = %o', deleted);
                if (deleted && deleted.length >= 1) {
                  itemsDeleted += deleted.length;
                }
                _.forEach(deleted, function (deletedItemId) {
                  var index = _.findIndex(ctrl.checkedItems, {id: deletedItemId});
                  ctrl.checkedItems.splice(index, 1);
                });
              });
            });
          }

          if (activeCandidates.length > 0) {
            dialogs.push(function () {
              return DeleteInventoryActivePODialog.show(activeCandidates);
            });
          }

          dialogs.push(function () {
            var t_message = $filter('translate')('TOAST.PRODUCTS_DELETED', {'x': itemsDeleted});
            return sgToast.showSimple(t_message);
          });

          if (dialogs.length > 0) {
            var currentDialog = 0;
            var runDialog = function () {
              if (dialogs.length >= currentDialog + 1) {
                dialogs[currentDialog]().then(function () {
                  currentDialog += 1;
                  return runDialog();
                });
              }
            };

            ctrl.hideLoadingBackdrop();
            $timeout(function () { runDialog(); }, 1000);
          }
        });
      });
    }

    function addItemDialog () {

      $mdDialog.show({
        controller: 'addInventoryItemController',
        templateUrl: 'templates/marketplace/inventory/modals/add-inventory-item.html',
        parent: angular.element(document.body),
        locals: {
          productName: null
        },
        clickOutsideToClose:true
      })
      .then(function(newItem){
        if(newItem && newItem.id){
          $state.go('app.inventory.all',{'itemId': newItem.id});
        }
      });
    }

    function newSdsDialog () {
      $rootScope.$broadcast('sds-new-doc');
    }

    function _defineLocks () {
      ctrl.disabled_add_new = AccessService.getProperty('gen_inv.disable_add_new');
      ctrl.disabled_admin_tools_page = AccessService.getProperty('admin_tools.disable_page');
      ctrl.disabled_billing_page = AccessService.getProperty('billing.disable_page');
      ctrl.disabled_delete = AccessService.getProperty('gen_inv.disable_delete');
      ctrl.disabled_sales_reps_add_new = AccessService.getProperty('sales_reps.disable_add_new');
      ctrl.disabled_suppliers_add_new = AccessService.getProperty('suppliers.disable_add_new');
      ctrl.disabled_suppliers_page = AccessService.getProperty('suppliers.disable_page');
      
      // for showing/hiding the External Vendors page
      const has_enable_external_vendors = AccessService.getOfficeFeatureFlag('external_vendors');
      const has_disable_account_management = AccessService.getProperty('external_vendors.disable_manage_accounts');
      ctrl.disabled_external_vendors_page = !has_enable_external_vendors || has_disable_account_management;
      
      // for disabling all PO-related features
      const has_orders_access = AccessService.hasOrdersPermission();
      const has_po_access = AccessService.hasPurchaseOrdersAccess();
      ctrl.disable_purchase_orders = !has_po_access;
      
      // for disabling just the "new purchase order" button in multiple pages
      ctrl.has_inv_disable_create_po = AccessService.getProperty('gen_inv.disable_create_po');
      ctrl.has_suppliers_disable_create_po = AccessService.getProperty('suppliers.disable_create_po');
      ctrl.has_locations_disable_create_po = AccessService.getProperty('storage_loc.disable_create_po');
      
      // for shopping list actions
      ctrl.disable_shopping_list = !AccessService.hasShoppingListAccess();
    }

    
    /**
     * Checks the route and returns a boolean value based on the conditions specified.
     * 
     * this controller is used on all of these pages:
     * - general inventory
     * - medications
     * - suppliers
     * - locations
     * 
     * so we need to check for the route first, then check the access for each route.
     * @param {object} state - the ui-router state the user is in.
     * @return {boolean}
     */
    function _isCreatePoDisabled (state) {
      const route_name = state?.name || state?.$current.name || '';
      if (route_name.includes('suppliers')){
        return ctrl.has_suppliers_disable_create_po;
      }
      if (route_name.includes('locations')){
        return ctrl.has_locations_disable_create_po;
      }
      return ctrl.has_inv_disable_create_po;
    }

    // UI functions
    function hideListButtons () {
      return ctrl.checkedItems.length && ctrl.checkedItems.length > 0 || $state.is('app.inventory.add-new-product') || $state.is('app.inventory.create-product');
    }

    /**
     * Determines if create order button should be visible based on:
     * 1. If listPurchaseOrders is enabled
     * 2. If createPurchaseOrder is enabled
     * 3. If there are any available items in the inventory
     * @returns {boolean}
     */
    function shouldShowCreateOrderBtn () {
      return (
        ctrl.selectActions.listPurchaseOrders.enabled 
        && ctrl.selectActions.createPurchaseOrder.enabled 
        && haveAvailableCheckedItems()
      );
    }

    /**
     * Determines if add to shopping list button should be visible based on:
     * 1. If the selectActions.addToShoppingList is enabled
     * 2. If there are any available items in the inventory
     * @returns {boolean}
     */
    function shouldShowAddToShoppingListBtn () {
      return ctrl.selectActions.addToShoppingList.enabled && haveAvailableCheckedItems();
    }

    /**
     * Checks if there are any available checked items in the inventory.
     * @returns {boolean}
     */
    function haveAvailableCheckedItems () {
      return !inventoryItemService.allItemsDiscontinued(ctrl.checkedItems);
    }

    function showNewItemButton () {
      return !ctrl.disabled_add_new && $rootScope.canShowPremium && ($state.is('app.inventory.all') || $state.is('app.inventory.alerts'));
    }

    function showPrintMenu () {
      return $state.is('app.inventory.all') || $state.is('app.inventory.alerts');
    }

    function showCheckedItems () {
      return ctrl.checkedItems.length && ctrl.checkedItems.length > 0;
    }
  }
}());

(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.inventory')
    .service('InventorySupplierService', InventorySupplierService);

  function InventorySupplierService () {
    /*jshint validthis: true */
    var service = this;

    return service;
  }
}());

(function () {
  'use strict';

  angular
    .module('sowMarketplace')
    .service('adButlerService', adButlerService);

  function adButlerService ($window, $http, $rootScope, officeService, membershipService, sowLanguageService) {
    /*jshint validthis: true */
    var service = this;

    // HEADS-UP: Specific offices will have a disable_advertisements flag
    // we're checking for it in getMembershipFlag() down below

    service.BASE_BUTLER_URL = "https://butler.sowingo.com/adserve/";

    service.APPID = {
      "production": 180080,
      "staging": 180081
    };
    service.ZONES = {
      "production": {
        "leaderboard1": "456583",
        "leaderboard1-scaled": "617481",
        "leaderboard2": "456860",
        "leaderboard2-scaled": "622824",
        "box1": "456584",
        "box2": "456710",
        "box3": "464186",
        "landing-leaderboard": "461833",
        "landing-leaderboard-scaled":"461834",
        "landing-top-left": "461840",
        "landing-top-left-scaled": "461841",
        "landing-top-mid": "461842",
        "landing-top-right": "461843",
        "landing-bottom-left": "461835",
        "landing-bottom-mid": "461836",
        "landing-bottom-right": "461839",
        "landing-bottom-right-scaled": "461837",
        "landing-bg": "461690",
        "branded-product-dynamic": "464315"
      },
      "staging": {
        "box1": "456519",
        "box2": "456709",
        "box3": "464181",
        "leaderboard1": "456517",
        "leaderboard1-scaled": "617480",
        "leaderboard2": "456861",
        "leaderboard2-scaled": "622761",
        "landing-leaderboard": "461810",
        "landing-leaderboard-scaled":"461655",
        "landing-top-left": "461602",
        "landing-top-left-scaled": "461618",
        "landing-top-mid": "461605",
        "landing-top-right": "461606",
        "landing-bottom-left": "461608",
        "landing-bottom-mid": "461610",
        "landing-bottom-right": "461612",
        "landing-bottom-right-scaled": "461620",
        "landing-bg": "461664",
        "branded-product-dynamic": "464314"
      }
    };
    service.DIMENSIONS = {
      "box1": [300, 250],
      "box2": [300, 250],
      "box3": [300, 250],
      "leaderboard1": [728, 90],
      "leaderboard1-scaled": [320,50],
      "leaderboard2": [728, 90],
      "leaderboard2-scaled": [320,50],
      "landing-leaderboard": [728, 90],
      "landing-leaderboard-scaled": [320,50],
      "landing-top-left": [544, 250],
      "landing-top-left-scaled": [300, 250],
      "landing-top-mid": [300, 250],
      "landing-top-right": [300, 250],
      "landing-bottom-left": [300, 250],
      "landing-bottom-mid": [300, 250],
      "landing-bottom-right": [544, 250],
      "landing-bottom-right-scaled": [300, 250],
      "branded-product-dynamic": [306, 1044]
    };

    service.MAX_PRODUCT_HITS = 4;

    service.loadZone = loadZone;
    service.getEnvZoneId = getEnvZoneId;
    service.autoLoadZone = autoLoadZone;
    service.getZoneApiData = getZoneApiData;
    service.setDynamicAdAsInnerHTML = setDynamicAdAsInnerHTML;
    service.setABKW = setABKW;

    return service;

    function autoLoadZone (zone_name) {
      var id = getEnvZoneId(zone_name);
      var appId = getAppId();
      return loadZone(appId, id, service.DIMENSIONS[zone_name]);
    }
    
    function defineEnvironment () {
      var env = 'staging';
      var current_url = $window.location.toString();
      if (current_url.includes('app.sowingo.com')) {
        env = 'production';
      }

      return env;
    }
    
    function getEnvZoneId (zone_name, environment) {
      if(!environment) {
        environment = defineEnvironment();
      }
      return service.ZONES[environment][zone_name];
    }

    function getAppId (environment) {
      if(!environment) {
        environment = defineEnvironment();
      }
      return service.APPID[environment];
    }

    // dimensions: 
    // [728, 90] for leaderboard
    // [300, 250] for box
    function loadZone (appId, zone_id, dimensions) {
      let flag = getMembershipFlag();
      if (flag) return;
      dimensions = dimensions || [0,0];
      var AdButler = $window.AdButler || {};
      AdButler.ads = AdButler.ads || [];
      var abkw = $window.abkw || '';
      var tag = $window["plc"+zone_id] || 0;
      (function(){
        var divs = document.querySelectorAll(".plc"+zone_id+":not([id])");
        var div = divs[divs.length-1];
        if(!div) return;
        div.id = "placement_"+zone_id+"_"+tag;
        AdButler.ads.push({
          handler: function(opt){
            AdButler.register(appId, zone_id, dimensions, 'placement_'+zone_id+'_'+opt.place, opt); 
          }, 
          opt: { 
            place: tag++, 
            keywords: abkw, 
            domain: 'butler.sowingo.com', 
            click:'CLICK_MACRO_PLACEHOLDER' 
          }
        });
      })();
    }

    function getZoneApiData (zone_name, dimensions, zone_id, appId) {
      let flag = getMembershipFlag();
      if (flag) {
        let fail_promise = new Promise((resolve, reject) => {
          reject();
        });
        return fail_promise;
      }
      dimensions = dimensions || [0,0];
      appId = appId || getAppId();
      zone_id = zone_id || getEnvZoneId(zone_name, null);
      var abkw = $window.abkw || '';
      var this_url = "{0};ID={1};size={2}x{3};setID={4};type=json;kw={5};click=CLICK_MACRO_PLACEHOLDER".format(service.BASE_BUTLER_URL, appId, dimensions[0], dimensions[1], zone_id, abkw);
      return $http({ 'url': this_url, 'method': 'GET' })
      .then(function(response, status, headers, config) {
        return response.data.placements.placement_1;
      })
      .catch(function(response) {
        console.error(response);
      });
    }

    function setDynamicAdAsInnerHTML (zone_name, zone_id) {
      let flag = getMembershipFlag();
      if (flag) return;
      var promise = getZoneApiData('branded-product-dynamic')
      .then(function(data){
        
        var native_html_string = _.get(data, 'body');

        if (native_html_string) {
          // Find associated zone div elements and set as innerHTML
          zone_id = zone_id || getEnvZoneId(zone_name, null);
          var div_targets = document.querySelectorAll(".plc"+zone_id)
          _.forEach(div_targets, function (div_target) {
            div_target.innerHTML = native_html_string;
          })
          $rootScope.$broadcast('dynamic-ads-loaded', true);
        }
      })
      .catch(function(error){
        console.error(error);
      });
    }
    
    /**
     * Retrieves tags from all vendor inventory items in a hit.
     * @param {object} hit
     * @returns {Array} - An array of tags.
     */
    function _getTagsFromHit (hit) {
      const tags = [];
      if (hit) {
        _.forEach(hit.vendor_inventory, (vendor_inventory_item) => {
          if (vendor_inventory_item.tags) {
            tags.push(vendor_inventory_item.tags);
          }
        })
      }

      return tags;
    }

    // Generate AdButler Keywords (ABKW) to customize which ads they send us
    // Parses the number of hits as defined in `MAX_PRODUCT_HITS` from Algolia's
    // response triggered by search or filter
    // ABKW will be the unique results in each field
    // 'on_promo:' + vendor_sku indicates there are promotions currently applied to 
    // the item with that vendor_sku
    // returns: string of items separated by ',' of AdButler key words.
    function getABKW (hits) {

      var abkw = [];
      var truncated_hits = _.take(hits, service.MAX_PRODUCT_HITS);

      // Get tags from the first hit
      const first_hit_tags = _getTagsFromHit(truncated_hits[0]);

      if (first_hit_tags.length > 0) {
        abkw.push(first_hit_tags.join(','));
      }

      _.each(truncated_hits, function(hit){
        const category_id = _.get(hit, 'categories[0].id', null);
        var subcat_id = _.get(hit, 'subcategory.id', null);
        var manuf_id = _.get(hit, 'manufacturer.id', null);
   
        var vendor_inventory = _.get(hit, 'vendor_inventory');
        _.forEach(vendor_inventory, function(vendor_inventory_item) {
          var vendor_sku = _.get(vendor_inventory_item, 'vendor_sku', null);
          abkw.push(vendor_sku);          
          var is_on_promo = _.get(vendor_inventory_item, 'has_promotions', false);
          var promo_id = is_on_promo && vendor_sku ? 'on_promo:' + vendor_sku : null;
          if (promo_id) {
            abkw.push(promo_id)
          }          
        })

        if (!abkw.includes(category_id) && category_id) {
          abkw.push(category_id);
        }

        if (!abkw.includes(subcat_id) && subcat_id) {
          abkw.push(subcat_id);
        }

        if (!abkw.includes(manuf_id)) {
          abkw.push(manuf_id);
        }
      })

      var office = officeService.get();
      const office_group_id = _.get(office, 'office_group_id', null)
      if (office_group_id) {
        abkw.push(office_group_id);
      }
      var medical_field_id = _.get(office, 'medical_field_id', null);
      var medical_field_subtype = _.get(office, 'medical_field_subtype', null);
      medical_field_subtype = medical_field_subtype.toLowerCase().replace(' ', '_');
      const marketplace_id = _.get(office, 'marketplace[0]', null);
      if (!_.isNil(marketplace_id)) {
        abkw.push(marketplace_id);
      }
      const language_key = 'language:' + sowLanguageService.getCurrentLanguage();
      abkw.push(language_key);
      abkw.push(medical_field_id);
      abkw.push(medical_field_subtype);

      return abkw.join(',');
    }

    function setABKW (hits) {
      $window.abkw = getABKW(hits);
    }

    // as of June 2021 (SPD-2070) we are disabling ads for certain offices, 
    // this flag will be true in such cases
    function getMembershipFlag () {
      let membership = membershipService.get();
      let disable_ad_flag = _.get(membership, 'user_properties.disable_advertisements', null);
      return disable_ad_flag;
    }
    
  }
}());

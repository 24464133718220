(function () {
  'use strict';

  angular
    .module('app.marketplace.ui')
    .service('NavDropdownMenuService', NavDropdownMenuService);

  function NavDropdownMenuService() {
    /*jshint validthis: true */
    var service = this;

    service.menus = {};
    service.currentMenu = null;

    service.hideMenu = hideMenu;
    service.showMenu = showMenu;
    service.toggleMenu = toggleMenu;
    service.removeMenu = removeMenu;
    service.registerMenu = registerMenu;
    service.isMenuActive = isMenuActive;

    return service;

    function isMenuActive(name) {
      return service.currentMenu === name;
    }

    function toggleMenu(name) {
      if (service.currentMenu === name) {
        hideMenu(name);
      } else {
        showMenu(name);
      }
    }

    function registerMenu(name, scope) {
      service.menus[name] = scope;

      if (name === service.currentMenu) {
        service.currentMenu = null;
      }

      scope.$on('$destroy', function () {
        hideMenu(name);
      });
    }

    function removeMenu(name) {
      if (service.currentMenu === name) {
        hideMenu(name);
      }
      delete service.menus[name];
    }

    function hideMenu(name) {
      service.currentMenu = null;
      service.menus[name].menuIsOpen = false;
      service.menus[name].element.addClass('ng-hide');
    }

    function showMenu(name) {
      if (service.currentMenu) {
        hideMenu(service.currentMenu);
      }

      service.currentMenu = name;
      service.menus[name].menuIsOpen = true;
      service.menus[name].element.removeClass('ng-hide');
    }
  }

}());
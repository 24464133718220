(function () {
  'use strict';

  /* Override Angular's built in currency filter to internationalize based on the user's language setting */
  angular.module('app.shared.support').filter('currency', function () {
    return function (input) {
      const language = JSON.parse(localStorage.getItem('SWG-settings'))?.language ?? 'en';
      const format = language === 'fr' ? 'fr-FR' : 'en-US';
      const formatter = new Intl.NumberFormat(format, {
        style: 'currency',
        currency: 'USD',
      });
      // If the input is not a number or string, return it as is
      if (!['number', 'string'].includes(typeof input)) return input;
      // Remove any non-numeric characters from the input and convert it to a number
      const number = Number((input + '').replace(/[^0-9.-]+/g, ''));
      // If the input can not be parsed to a number, return it as is
      if (Number.isNaN(number)) return input;
      // In French it'll display $US after the dollar amount, but we just want the $
      return formatter.format(number).replace('US', '');
    };
  });

}());

(function () {
  'use strict';

  angular.module('sowMarketplace')
    .directive('sowMktAbSearch', sowMarketplaceActionBarSearchDirective);

  function sowMarketplaceActionBarSearchDirective() {
    return {
      restrict: 'E',
      templateUrl: 'sow-mkt/directives/sow-mkt-ab-search.html',
      controller: 'sowMktSearchController',
      controllerAs: 'mktsdCtrl',
      scope: {
        'destination': '=',
        'inputMode': '=',
      },
    };
  }

})();

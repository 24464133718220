angular.module('app.marketplace.ui.inventory')

.directive('inventoryQuantityExpected', function() {
  return {
    restrict: 'E',
    controllerAs: 'ctrl',
    scope: true,
    bindToController: {
      item: '='
    },
    controller: function($scope) {
      var ctrl = this;

      ctrl.getQuantity = getQuantity;

      /////////

      function getQuantity(item) {
        if (!item || !item.inventory_item) {
          return;
        }

        var caseSize = item.inventory_item.order_package_quantity;
        var itemsOnHand = item.inventory_item.on_hand;
        var casesOnHand = (itemsOnHand / caseSize);
        var casesToBuy = 0;
        var quantity = parseInt(item.quantity);
        if (quantity > 0) {
          casesToBuy = (quantity / caseSize);
        } else {
          quantity = 0;
        }


        if (item.inventory_item.tracking_method === 'Item' || !item.inventory_item.tracking_method) {
          return item.buy_by_case ? ( (quantity * caseSize) + itemsOnHand) : (quantity + itemsOnHand);
        }

        if (item.inventory_item.tracking_method === 'Package') {
          return item.buy_by_case ? Math.floor(casesOnHand + quantity) : Math.floor(casesOnHand + casesToBuy);
        }
      }
    },
    template: '<span ng-show="ctrl.item && ctrl.item.inventory_item">'
      + '{{ ctrl.getQuantity(ctrl.item) }}'
      + '</span>'
  };
});

angular.module("app.marketplace.ui.office")
.controller("officeUsersController",
      function($scope, $rootScope, elementService, sgToast, $mdDialog, officeService, errorService, $filter,
          users, membershipInvites, membershipRequests, $stateParams) {
      $scope.office = $scope.current_office;
      $scope.users = _.values(users);

      var confirmDialog = function(event, text, confirmText, cancelText){
        // Appending dialog to document.body to cover sidenav in docs app
        var confirm = $mdDialog.confirm()
              .textContent(text)
              .targetEvent(event)
              .ok(confirmText || 'Yes')
              .cancel(cancelText || 'No');
        return $mdDialog.show(confirm);
      };

      //------------------------------------------------------------------------
      //User fns

      var refreshUsers = function(){
        return elementService.get("account", null, {"endpoint" : "office_users", "office" : $scope.office})
        .then(function(users){
          $scope.users = _.values(users);
        }).catch(function(error){
          var t_message = $filter('translate')('ERRORS.USER_RETRIEVE_API')
          errorService.uiErrorHandler(error || t_message);
        });
      };

      $scope.addUser = function(a_title){
        a_title = a_title || null;
        $mdDialog.show({
          controller: 'officeUserModalController',
          templateUrl: 'templates/marketplace/office/modals/office-user.html',
          parent: angular.element(document.body),
          clickOutsideToClose:true,
          locals : {
            modalType : "new",
            user : {
              title: a_title,
            }
          }
        }).then(function(message){
          sgToast.showSimple(message);
          refreshInvitations();
          refreshUsers();
        });
      };
      $scope.$on('office-ab-add-user', function($event){
        $scope.addUser(null);
      });

      // necessary to 
      if ($stateParams.new_type) {
        $scope.addUser($stateParams.new_type);
      }

      $scope.editUser = function(ev, user){

        $mdDialog.show({
          controller: 'officeUserModalController',
          templateUrl: 'templates/marketplace/office/modals/office-user.html',
          parent: angular.element(document.body),
          clickOutsideToClose:true,
          targetEvent: ev,
          locals : {
            modalType : "edit",
            user : user
          }
        }).then(function(message){
          sgToast.showSimple(message);
        });
      };

      $scope.permissionToEdit = function(user) {
        var cm = $rootScope.current_membership;
        var is_master = user.membership_type === 'master';
        var is_mgr = user.membership_type === 'manager';
        var is_team = user.membership_type === 'team';
        var am_master = cm.membership_type === 'master';
        var am_mgr = cm.membership_type === 'manager';
        var am_team = cm.membership_type === 'team';
        var same_user = user.id === cm.id;
        
        return (same_user) || ( (am_master) || (am_mgr && !is_master) );
      }

      $scope.permissionToRemove = function(user) {
        var cm = $rootScope.current_membership;
        var is_master = user.membership_type === 'master';
        var is_mgr = user.membership_type === 'manager';
        var is_team = user.membership_type === 'team';
        var am_master = cm.membership_type === 'master';
        var am_mgr = cm.membership_type === 'manager';
        var am_team = cm.membership_type === 'team';
        var same_user = user.id === cm.id;
        
        // can't delete urself, masters delete all, mgr delete peers
        return !same_user && ( (am_master) || (am_mgr && !is_master) );
      }


      $scope.removeUser = function($event, user) {
          $scope.submittingDeletion = true;
          var t_prefix = $filter('translate')('TOAST.WANT_TO_REMOVE');
          var t_suffix = $filter('translate')('TOAST.FROM_YOUR_OFFICE');
          confirmDialog($event, t_prefix + user.name + t_suffix, 'Remove')
          .then(function(){
            return officeService.removeOfficeUser(user)
            .then(function (response) {
              var t_message = $filter('translate')('OFFICE.ADMIN.USER_REMOVED');
              sgToast.showSimple(t_message);
            }).catch(function(error) {
              var t_message = $filter('translate')('ERRORS.USER_REMOVE')
              errorService.uiErrorHandler(error || t_message, 0);
            });
          })['finally'](function(){
            $scope.submittingDeletion = false;
          });
      };

      //------------------------------------------------------------------------
      //Invitations
      $scope.pendingInvitationsFilter = function (item) {
        return !item.cancelled_at && !item.declined_at && !item.redeemed_on && (new Date(item.expires_at)).getTime() > (new Date()).getTime();
      };

      $scope.pending_invitations = $filter('filter')(_.values(membershipInvites), $scope.pendingInvitationsFilter);

      var refreshInvitations = function() {
          officeService.getPendingInvitations().then(function (invites) {
            if (invites && invites.length > 0) {
                $scope.pending_invitations = $filter('filter')(_.values(invites), $scope.pendingInvitationsFilter);
            }
        });
      };

      $scope.cancelUserInvite = function($event, invite) {
        $scope.submittingCancelInvite = true;

        confirmDialog($event, 'Cancel this invite?')
        .then(function(){
          return officeService.cancelUserInvite(invite)
          .then(function (response) {
            refreshInvitations();
            var t_message = $filter('translate')('TOAST.INVITE_CANCELLED_FOR');
            sgToast.showSimple(t_message + invite.email);
          }).catch(function(error) {
            var t_message = $filter('translate')('ERRORS.INVITE_CANCEL')
            errorService.uiErrorHandler(error || t_message, 0);
          });
        })['finally'](function(){
          $scope.submittingCancelInvite = false;
        });

      };
      $scope.resendUserInvite = function($event, invite) {
        $scope.submittingResendInvite = true;

        return officeService.resendUserInvite(invite)
        .then(function (response) {
          var t_message = $filter('translate')('TOAST.INVITE_RESENT_TO');
          sgToast.showSimple(t_message + invite.email);
        }).catch(function(error) {
          var t_message = $filter('translate')('ERRORS.INVITE_RESEND')
          errorService.uiErrorHandler(error || t_message, 0);
        })['finally'](function(){
          $scope.submittingResendInvite = false;
        });
      };

      //------------------------------------------------------------------------
      //Join Requests
      $scope.membershipRequestsFilter = function (item) {
        return !item.accepted_on && !item.declined_on && (new Date(item.expires_at)).getTime() > (new Date()).getTime();
      };
      $scope.membership_requests = $filter('filter')(_.values(membershipRequests), $scope.membershipRequestsFilter);
      var refreshRequests = function() {
        officeService.getMembershipRequests().then(function (requests) {
          if (requests && requests.length > 0) {
              $scope.membership_requests = $filter('filter')(_.values(requests), $scope.membershipRequestsFilter);
          }
        });
      };

      $scope.acceptRequest = function($event, request) {
        $scope.submittingAcceptRequest = true;

        return officeService.acceptMembershipRequest(request.id)
        .then(function (response) {
          refreshRequests();
          refreshUsers();
          var t_message = $filter('translate')('TOAST.JOIN_REQUEST_ACCEPTED_FOR');
          sgToast.showSimple(t_message + request.email);
        }).catch(function(error) {
          var t_message = $filter('translate')('ERRORS.REQUEST_ACCEPT')
          errorService.uiErrorHandler(error || t_message, 0);
        })['finally'](function(){
          $scope.submittingAcceptRequest = false;
        });

      };

      $scope.rejectRequest = function($event, request) {
        $scope.submittingRejectRequest = true;

        confirmDialog($event, 'Remove this Join Request?', 'Remove')
        .then(function(){
          officeService.rejectMembershipRequest(request.id)
          .then(function (response) {
            refreshRequests();
            var t_message = $filter('translate')('TOAST.JOIN_REQUEST_DENIED_FOR');
            sgToast.showSimple(t_message + request.email);
          }).catch(function(error) {
            var t_message = $filter('translate')('ERRORS.REQUEST_JOIN_DENY')
            errorService.uiErrorHandler(error || t_message, 0);
          });
        })['finally'](function(){
          $scope.submittingRejectRequest = false;
        });
      };
    });

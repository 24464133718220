(function () {
  'use strict';

  angular.module('app.marketplace.ui.inventory').directive('inventoryItemHeader', InventoryItemHeader);

  function InventoryItemHeader () {
    return {
      restrict: 'E',
      replace: true,
      templateUrl: 'templates/marketplace/inventory/inventory-item-header.html',
      controller: 'inventoryItemRowController'
    };
  }
}());

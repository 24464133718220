angular.module('app.marketplace.ui.contentBlock')
  .controller('contentBlockPageController', function ($scope, $sce, sgToast, $stateParams, elementService, cartService, block) {

    $scope.blockId = $stateParams.blockId || null;
    $scope.block = block;

    if ($scope.blockId && !$scope.block) {
      elementService.get('contentBlock', $scope.blockId)
        .then(function (block) {
          $scope.block = block;
        });
    }

    $scope.addToCart = function (product) {
      cartService.addToCart(product.id, 1)
        .then(
          function () {
            var t_message = $filter('translate')('TOAST.ITEM_ADDED');
            sgToast.showSimple(t_message);
          },
          function (error) {
            var t_message = $filter('translate')('ERRORS.ADD_TO_CART')
            errorService.uiErrorHandler(error || t_message);

          });
    };

    $scope.trustAsHtml = function (content) {
      return $sce.trustAsHtml(content);
    };
  });
(function () {
  'use strict';

  angular.module('sowAlerts')
    .directive('alertItemsBlock', alertItemsBlock);

  function alertItemsBlock() {
    return {
      'restrict': 'E',
      'replace': true,
      'templateUrl': 'sow-alerts/directives/alerts-items-block.html',
      'scope': {
        'flag': '=',
        'items': '=',
        'type': '@',
        'title': '@',
        'titleIcon': '@',
      },
      'controller': 'alertsIBCtrl',
      'controllerAs': 'alertsIBCtrl'
    };
  }

}());

angular.module('app.marketplace.ui.inventory')
.controller('packagingTypeController', packagingTypeController)
.directive('packagingType', function() {
  return {
    restrict: 'E',
    controller: 'packagingTypeController',
    controllerAs: 'ptypeCtrl',
    scope: true,
    bindToController: {
      item: '=',
      text: '@',
      context: '@'
    },
    template: `<div class="packaging-text">{{ptypeCtrl.getDisplayText()}}</div>`,
  };
});

function packagingTypeController ($filter) {
  var ctrl = this;

  ctrl.getText = getText;
  ctrl.getDisplayText = getDisplayText;
  
  ctrl.$onInit = function init () {
    ctrl.context = ctrl.context || 'purchasing';
  }
  
  return ctrl;
  
  /////////

  function showByCase (item) {
    if (ctrl.context === 'tracking' && item.inventory_item) {
      return item.inventory_item.tracking_method === 'Package';
    }
    return item.buy_by_case;
  }

  function getText (item) {
    const t_pack = $filter('translate')('COMMON.PACK');
    const t_item = $filter('translate')('COMMON.ITEM');
    const show_by_case = showByCase(item);

    return show_by_case ? t_pack : t_item;
  }

  function getDisplayText (item = ctrl.item, text = ctrl.text) {
    let prefix = text ? text+" " : '';
    let body = getText(item);
    let suffix = prefix ? '': '(s)';

    return `${prefix}${body}${suffix}`;
  }

  
}

(function () {
//   {
//     "promotions": [
//         {
//             "promotion_properties": {
//                 "buy": 2,
//                 "get": 1
//             },
//             "effective_price": 10.3733,
//             "end_date": "2022-06-30",
//             "promotion_notes": "Buy 2 boxes of SafeSeal® Quattro & get 1 more of the same free from stock. Cannot be combined with any other Medicom offer",
//             "id": "vpromo_9gxWAQ9MLnD2vx9qqqsoGf",
//             "promotion_type": "BUY_GET",
//             "start_date": "2021-04-01"
//         }
//     ],
//     "product_status": {
//         "sold_out": false,
//         "is_discontinued": false,
//         "is_published": true
//     },
//     "vendor_shipping_rates": [
//         {
//             "range_end": 250,
//             "rate": 10.75
//         }
//     ],
// }
  function VendorInventoryItemCP (VendorCP) {
    const Vendor = VendorCP.getClass();
    
    /**
    *
    * @class VendorInventoryItem
    * @typedef VendorInventoryItem
    */
    class VendorInventoryItem {
      constructor(json_data) {
        this.has_promotions = json_data.has_promotions;
        this.is_published = json_data.is_published;
        this.limit_per_order = json_data.limit_per_order;
        this.list_price = json_data.list_price;
        this.marketplace_id = json_data.marketplace_id;
        this.price = json_data.price;
        this.product_status = json_data.product_status;
        this.special_fee = json_data.special_fee;
        this.special_fee_amount = json_data.special_fee_amount;
        this.time_restricted_days_vendor_inventory_default = json_data.time_restricted_days_vendor_inventory_default;
        this.vendor_inventory_id = json_data.vendor_inventory_id;
        this.vendor_shipping_rates = json_data.vendor_shipping_rates;
        this.vendor_sku = json_data.vendor_sku;
        this.vendor = new Vendor(json_data.vendor);
      }

      has_promotions;
      get has_promotions () {
        return this.has_promotions;
      }

      is_published;
      get is_published () {
        return this.is_published;
      }

      limit_per_order;
      get limit_per_order () {
        return this.limit_per_order;
      }
      
      list_price;
      get list_price () {
        return this.list_price;
      }
      set list_price (new_list_price) {
        this.list_price = new_list_price;
      }
      

      marketplace_id;
      get marketplace_id () {
        return this.marketplace_id;
      }

      price;
      get price () {
        return this.price;
      }

      product_status;
      get product_status () {
        return this.product_status;
      }

      special_fee;
      get special_fee () {
        return this.special_fee;
      }

      special_fee_amount;
      get special_fee_amount () {
        return this.special_fee_amount;
      }

      time_restricted_days_vendor_inventory_default;
      get time_restricted_days_vendor_inventory_default () {
        return this.time_restricted_days_vendor_inventory_default;
      }

      vendor_inventory_id;
      get vendor_inventory_id () {
        return this.vendor_inventory_id;
      }

      vendor_shipping_rates;
      get vendor_shipping_rates () {
        return this.vendor_shipping_rates;
      }

      vendor_sku;
      get vendor_sku () {
        return this.vendor_sku;
      }

      vendor;
      get vendor () {
        return this.vendor;
      }
      

    }
    
    this.getClass = () => {
      return VendorInventoryItem;
    }
  }
  
  angular.module('sowMarketplace')
    .service("VendorInventoryItemCP", VendorInventoryItemCP);
})();

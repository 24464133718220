angular.module('app.marketplace.ui.office')

  .directive('subscriptionBillingRow', function() {
    return {
      restrict: 'E',
      templateUrl: 'templates/marketplace/office/directives/subscription-billing-row.html'
    };
  })

;

(function () {
  'use strict';

  angular.module('sowMarketplace')
    .controller('marketplaceAdCarouselController', marketplaceAdCarouselController)
    .directive('mktAdCarousel', marketplaceAdCarouselDirective);

  function marketplaceAdCarouselDirective() {
    return {
      restrict: 'E',
      templateUrl: 'sow-mkt/directives/mkt-ad-carousel.html',
      controller: 'marketplaceAdCarouselController',
      controllerAs: 'crslCtrl',
      scope: {
        'target': '=',
      },
    };
  }

  function marketplaceAdCarouselController($scope) {
    /*jshint validthis:true*/
    var ctrl = this;

    ctrl.current_item = 0;

    ctrl.nextItem = nextItem;
    ctrl.previousItem = previousItem;
    ctrl.goToItem = goToItem;

    init();

    return ctrl;

    function init() {
      // ctrl.interval = $interval(function(){
      //   nextItem();
      // }, 2000);
    }

    function nextItem() {
      if (ctrl.current_item === $scope.target.banners.length - 1) {
        goToItem(0);
      } else {
        goToItem(ctrl.current_item + 1);
      }
    }

    function previousItem() {
      if (ctrl.current_item === 0) {
        goToItem($scope.target.banners.length - 1);
      } else {
        goToItem(ctrl.current_item - 1);
      }
    }

    function goToItem(index, item) {
      ctrl.current_item = index;
    }
  }

})();
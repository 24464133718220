angular.module("app.marketplace.elements")
//This service is set to init and watch global values like "current_office" AFTER the first .get is called. 
//This avoids an api call for orders on site load.
.service("orderService", function($rootScope, $q, elementService, orderModel) { 
  var _this = this;
  _this.orders = {};
  _this.initialized = false;
  _this.UI = {}; //Used for tracking ui states and filters.

  var _setOrders = function(orders){
    _this.orders = orders; //Cant use angular.copy because it needs deep reference for updates.
    $rootScope.$broadcast('ordersService: set-orders', orders);
    _this.initialized = true;
  };
  var _clearOrders = function(){
    angular.copy({}, _this.orders);
    $rootScope.$broadcast('ordersService: clear-orders');
    _this.initialized = true;
  };
  //Convience function for waiting for initialized
  var _waitForInitialized = function(){
    if(_this.initialized){
      return $q.resolve(_this.orders);
    }else{
      if(!_this._initCalled){
        _this._init();
      }
      var deferred = $q.defer();
      var initWatch = $rootScope.$watch(function(){ 
        return _this.initialized; 
      }, function(newInitializedValue, oldInitializedValue){
        if(newInitializedValue){
          deferred.resolve(_this.orders);
          initWatch(); //Stop watch;
        }
      });
      return deferred.promise;
    }
  };
  
  var _getOrders = function(waitForInitialized){
    if(waitForInitialized){
      return _waitForInitialized()
      .then(function(orders){
        if(orders){
          return orders;
        }else if($rootScope.current_account){
          return $q.reject("No orders");
        }else{
          return $q.reject("Not logged in");
        }
      });
    }else{
      return  _this.orders;
    }
  };
  var _getItems = function(order){
    return elementService.get('orderItem', null, {'endpoint' : 'multiple', "order" : order})
    .then(function(items){
      if(items){
        order.items = $.map(items, function(value, index) {
            return [value];
        });
      }
      return items;
      
    });
  };
  var _getOrder = function(order){
    return elementService.get('order', null, {'endpoint' : 'by_hrid', 'return_type' : 'single' , 'data' : {'id' : order.hrid}})
    .then(function(order){
      return order;
    });
  };
  
  var _getPendingShipments = function(){
    return elementService.callEndpoint("order", {"endpoint" : "get_pending_orders", "return_type" : "multiple"})
    .then( function(response) {
      var shipments = response.data;
      _.each(shipments, function(shipment){
        shipment.shipped_items_count = 0;
        _.each(shipment.items, function(item){
          shipment.shipped_items_count += item.quantity;
        });
      });
      return shipments;
    });
  };
  var _receivedOrderShipment = function(order, shipment_id, do_import){
    var shipment = {
        received_on : new Date(),
        do_import : do_import,
        id : shipment_id
    };
    return elementService.callEndpoint("order", {"endpoint" : "update_shipment_received", "order" : order, "shipment" : shipment})
    .then( function(response) {
      if(response){
        _.map(response.data.changes, function(change){
          elementService.create("inventoryItem", change.item);
        });
        var orderIns = elementService.create("order", response.data.order);
        return orderIns;
      }
    });
  };

  var _refresh = function(){
    if($rootScope.current_office){
      _this.initialized = false;
      elementService.removeAll('order');
      return elementService.getElements('order', {'forceAPI' : true})
      .then(function(orders){
        _setOrders(orders);
        return orders;
      });
    }else{
      _clearOrders();
      return $q.reject("No office");
    }
  };

  _this._init = function(){
    _this._initCalled = true;
    if($rootScope.current_office){
      _refresh();
    }
    $rootScope.$on('officeService: set-office', function(event, office){
      _refresh();
    });
    $rootScope.$on('officeService: clear-office', function(){
      _clearOrders();
    });
  };
  
  return {
    init : _this._init,
    get : _getOrders,
    getItems : _getItems,
    getOrder : _getOrder,
    
    refresh : _refresh,
    
    getPendingShipments : _getPendingShipments,
    receivedOrderShipment : _receivedOrderShipment,
    
    UI : _this.UI
    
  };
});

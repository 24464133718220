(function () {
  'use strict';

  angular.module('sowAlerts')
    .controller('alertsIBCtrl', alertsIBCtrl);

  function alertsIBCtrl($scope, $state) {
    /*jshint validthis: true */
    var ctrl = this;

    $scope.get_card_class = function () {
      var one_no_items = (!$scope.items || !$scope.items[0].total || $scope.items[0].total == 0);
      var two_no_items = (!$scope.items || !$scope.items[1].total || $scope.items[1].total == 0);
      if (!$scope.flag || (one_no_items && two_no_items)) {
        return 'grey-img';
      } else {
        switch ($scope.type) {
          case "emergency_medications":
            return 'red-icon';
            break;
          case "medications":
            return 'red-icon';
            break;
          case "inventory":
            return 'orange-icon';
            break;
          case "certifications":
            return 'green-icon';
            break;
          case "sterilization":
            return 'blue-icon';
            break;
          default:
            break;
        }
      }
    }

    $scope.get_row_class = function (item) {
      if (!item || !item.total || item.total === 0) {
        return 'grey-img';
      } else if (item.color === 'orange') {
        return 'orange-icon';
      } else {
        return 'red-icon';
      }
    }

    $scope.goTo = function (item) {
      var type = $scope.type
      var has_flag = $scope.flag;
      switch (type) {
        case "medications":
          if (has_flag) {
            $state.go("app.medications.alerts", { 'filter': item.type });
          } else {
            $state.go("app.medications");
          }
          break;
        case "emergency_medications":
          if (has_flag) {
            $state.go("app.medications.alerts", { 'filter': item.type });
          } else {
            $state.go("app.medications");
          }
          break;
        case "inventory":
          if (has_flag) {
            $state.go("app.inventory.alerts", { 'filter': item.type });
          } else {
            $state.go("app.inventory");
          }
          break;
        case "certifications":
          if (has_flag) {
            $state.go("app.certifications.list");
          } else {
            $state.go("app.certifications");
          }
          break;
        case "sterilization":
          if (has_flag) {
            $state.go("app.stzn.testlist", { 'filter': item.type });
          } else {
            $state.go("app.stzn");
          }
          break;
        case "implants":
          if (has_flag) {
            $state.go("app.implants.alerts", item);
          } else {
            $state.go("app.implants");
          }
          break;
        default:
          break;
      }
    }

    function getLetterColor(manufacturer_name) {
      return "#" + intToARGB(hashCode(manufacturer_name));
    }
    $scope.getLetterColor = getLetterColor;

    // Hash any string into an integer value
    // Then we'll use the int and convert to hex.
    function hashCode(str) {
      var hash = 0;
      for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      return hash;
    }

    // Convert an int to hexadecimal with a max length
    // of six characters.
    function intToARGB(i) {
      var hex = ((i >> 24) & 0xFF).toString(16) +
        ((i >> 16) & 0xFF).toString(16) +
        ((i >> 8) & 0xFF).toString(16) +
        (i & 0xFF).toString(16);
      // Sometimes the string returned will be too short so we 
      // add zeros to pad it out, which later get removed if
      // the length is greater than six.
      hex += '000000';
      return hex.substring(0, 6);
    }

    return ctrl;

  }

}());

(function(){
  'use strict';
  angular.module("app.marketplace.ui.purchaseorders")
    .directive('actionBarPoDraft', poDraftActionBarDirective);

  function poDraftActionBarDirective() {
    return {
      restrict: 'AC',
      templateUrl: 'templates/marketplace/purchase-orders/directives/action-bar-po-draft.html',
      controller: 'poActionBarController',
      controllerAs: 'poAbCtrl'
    };
  }

}());
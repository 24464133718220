(function () {
  'use strict';

  angular
    .module('app.marketplace.elements')
    .service('vendorService', vendorService);

  function vendorService (elementService) {
    /*jshint validthis: true */
    var service = this;

    service.getVendor = getVendor;
    service.getAllVendors = getAllVendors;
    service.getShippingRates = getShippingRates;

    return service;

    //
    // Get Shipping Rates for All Vendors
    //
    function getShippingRates () {
      return elementService.callEndpoint('vendor', {
        'endpoint': 'get_shipping_rates',
      }).then(function (response) {
        return response.data;
      });
    }

    //
    // Lookup a Vendor
    //
    // vendorId can be either a Vendor's ID or a Vendor's url_name value.
    //
    function getVendor (vendorId) {
      return elementService.callEndpoint('vendor', {
        'endpoint': 'lookup_vendor',
        'vendor_id': vendorId,
      }).then(function (response) {
        var payload = response.data;
        var hits = payload.hits.hits;

        if (hits && hits.length > 0) {
          return hits[0]._source;
        } else {
          throw 'Could not lookup Vendor.';
        }
      });
    }

    //
    // Fetch All Vendors
    //
    function getAllVendors () {
      return elementService.callEndpoint('vendor', {
        'endpoint': 'get_all_vendors',
      }).then(function (response) {
        var hits = _.get(response, 'data.hits.hits') || [];
        return _.map(hits, '_source');
      });
    }
  }

}());

(function () {
  'use strict';

  angular.module('sowInventory')
  .directive('sowMedDetailsForm', sowMedicationDetailsFormDirective)
  .controller('sowMedicationDetailsFormController', sowMedicationDetailsFormController);

  function sowMedicationDetailsFormDirective () {
    return {
      restrict: 'E',
      replace: true,
      templateUrl: 'sow-inventory/directives/med-details-form.html',
      controller: 'sowMedicationDetailsFormController',
      controllerAs: 'imdfCtrl',
      scope: {
        'item': '=',
      },
    };
  }

  function sowMedicationDetailsFormController ($scope, $q, errorService, elementService) {
    var ctrl = this;

    ctrl.manufacturerSearch = manufacturerSearch;

    return ctrl;

    function manufacturerSearch(name) {
      if (!name) {
        return false;
      }

      return elementService.get('manufacturer', null, {
        endpoint: 'search',
        name: name
      })
      .then(function (manufacturers) {
        return _.map(manufacturers);
      });
    }

  }
}());

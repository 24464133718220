(function () {
  'use strict';

  var modelData = {
    "params": {
      "model_name": "supplier"
    }
  };

  var modelFields = {
    "id" : {
      "display_name" : "id",
      "required" : true,
      "api" : {
        "submit" : true
      },
      "type" : "integer"
    },
  };

  angular
    .module('app.marketplace.elements')
    .service('SupplierModel', SupplierModel);

  function SupplierModel (apiUrl) {
    /*jshint validthis: true */
    var model = this;

    model.data = modelData;
    model.fields = modelFields;
    model.autofill = autofill;
    model.model_data = {
      'name': 'supplier',
      'display_name': 'Supplier',
      'api': {
        'multiple': listSuppliers,
        'single': getSupplier,
        'remove': deleteSupplier,
        'create': createSupplier,
        'search': supplierSearch,
        'update': updateSupplier,
      }
    };

    return model;

    function autofill () {
    }

    function updateSupplier (options) {
      if(!_.has(options, 'supplier.id')) {
        throw 'Missing a suppiler id. Cannot update supplier.';
      }

      return {
        'path': '{0}/suppliers/{1}'.format(apiUrl, options.supplier.id),
        'params': {
          'method': 'put',
          'data': options.supplier,
        }
      };
    }

    function createSupplier (options) {
      if (!_.has(options, 'supplier.name')) {
        throw 'Cannot create a nameless supplier.';
      }

      return {
        'path': '{0}/suppliers'.format(apiUrl),
        'params': {
          'method': 'POST',
          'data': options.supplier,
        }
      };
    }

    function supplierSearch (options) {
      options.name = options.name || '';

      return {
        'path': '{0}/suppliers'.format(apiUrl),
        'params': {
          'method': 'GET',
          'params': {'name': options.name},
        }
      };
    }

    function getSupplier (options) {
      if (!_.has(options, 'data.id')) {
        throw 'Cannot fetch supplier without an id.';
      }

      return {
        'path': '{0}/suppliers/{1}'.format(apiUrl, _.get(options, 'data.id')),
        'params': {
          'method': 'GET',
          'params': {'extended': _.get(options, 'extended') ? 1 : 0},
        }
      };
    }

    function listSuppliers () {
      return {
        'path': '{0}/suppliers'.format(apiUrl),
        'params': {'method': 'GET'},
      };
    }

    function deleteSupplier (supplier) {
      if (!_.has(supplier, 'id')) {
        throw 'Cannot delete a supplier without an id.';
      }

      return {
        'path': '{0}/suppliers/{1}'.format(apiUrl, supplier.id),
        'params': {'method': 'DELETE'}
      };
    }
  }
}());

(function () {
  'use strict';

  angular.module('sowProduct')
    .controller('pdAvailableVendorsController', pdAvailableVendorsController)
    .directive('pdAvailableVendors', pdAvailableVendorsDirective);

  function pdAvailableVendorsDirective() {
    return {
      restrict: 'E',
      templateUrl: 'sow-product-details/directives/pd-available-vendors.html',
      controller: 'pdAvailableVendorsController',
      controllerAs: 'pdAVCtrl',
      scope: {
        product: '<',
        id: '<',
      }
    };
  }

  function pdAvailableVendorsController($scope, $rootScope, sgToast, productDetailsService, AccessService, ProductExternalVendorsService, sowAnalyticsService) {
    var ctrl = this;

    // -------------------------------- default state --------------------------------

    ctrl.show_connection_error_alert = false;

    // -------------------------------- public methods --------------------------------

    ctrl.selectVendor = selectVendor;
    ctrl.goToVendor = productDetailsService.goToVendor;
    ctrl.getVendorClassNames = getVendorClassNames;
    ctrl.canVendorBeSelected = canVendorBeSelected;
    ctrl.handleConnectAccountClick = handleConnectAccountClick;
    ctrl.handleLinkAccountDialogEvent = handleLinkAccountDialogEvent;
    ctrl.getPriceText = getPriceText;

    // ------------------------------ lifecycle methods ------------------------------

    ctrl.$onInit = () => {
      _defineLocks();
      ctrl.product = ctrl.product || $scope.product;
      ctrl.id = ctrl.id || $scope.id;
      _initializeVendors();
    }

    return ctrl;

    /**
     * A vendor should be disabled if it is internal or a connected
     * external vendor and it does not have stock of the product
     * @param {object} vendor
     * @return {boolean}
     */
    function _isDisabled(vendor) {
      if (vendor.UI.has_error) {
        return false;
      }

      if (!vendor.UI?.is_external || !vendor.UI?.is_disconnected) {
        return !_doesVendorHaveProduct(vendor);
      }

      return false;
    }

    /**
     * A vendor can be selected if it's not disabled and does not have an error
     * @param {object} vendor
     * @return {boolean}
     */
    function canVendorBeSelected(vendor) {
      return !_isDisabled(vendor) && !vendor.UI.has_error;
    }

    /**
     * Initializes the internal and external vendors (if any) of the product
     * @param {object} product
     * @param {string} product.id
     * @param {object[]} product.sorted_vendors
     */
    async function _initializeVendors({id, sorted_vendors} = ctrl.product) {
      // if we don't set loading of all external vendors to true there will
      // be a flicker before each of them displays their loading state,
      // and also before the selected vendor displays its shimmer in the
      // event that there are only external vendors for this product
      for (const vendor of sorted_vendors) {
        vendor.UI.is_loading = vendor.UI.is_external;
      }
      _setDisplayVendors(sorted_vendors, false);
      const {
        has_external,
        vendors,
      } = await ProductExternalVendorsService.getAvailableVendorsForProduct(id, sorted_vendors);
      _setDisplayVendors(vendors);
      if (has_external) {
        const vendors_with_pricing = await ProductExternalVendorsService.getAllExternalVendorPricing(vendors);
        _setDisplayVendors(vendors_with_pricing);
      }
    }

    /**
     * Sets the vendors to display, ensuring the first one is selected by default
     * @param {object[]} vendors
     */
    function _setDisplayVendors(vendors, refresh_list = true) {
      ctrl.display_vendors = vendors;
      _ensureAvailableVendorSelected();
      _ensureSelectedVendorUpdated();

      if (refresh_list) {
        $scope.$apply();
      }
    }

    /**
     * Ensures that the first vendor in the list is selected automatically
     * if the currently selected vendor does not have stock of the product
     * but the first vendor in the list (which is also cheapest) has stock
     */
    function _ensureAvailableVendorSelected() {
      const first_vendor = ctrl.display_vendors[0];
      const selected_vendor = ctrl.display_vendors.find(_isVendorCurrentlySelected);
      if (
        !_doesVendorHaveProduct(selected_vendor) &&
        _doesVendorHaveProduct(first_vendor)
      ) {
        selectVendor(first_vendor);
        $rootScope.$broadcast('pd-hide-discontinued-alert');
      }
    }

    /**
     * Ensures that updates to the selected vendor propagate (since if it's external we
     * need to update the UI in pd-selected-vendor during and after the loading state)
     */
    function _ensureSelectedVendorUpdated() {
      const selected_vendor = ctrl.display_vendors.find(_isVendorCurrentlySelected);
      if (selected_vendor.UI.is_external) {
        _handleVendorUpdate(selected_vendor);
      }
    }

    /**
     * It returns true if the vendor has a product and false otherwise
     * @param vendor - The vendor object that you want to check if it has the product.
     * @returns A function that takes a vendor as an argument and returns a boolean.
     */
    function _doesVendorHaveProduct(vendor) {
      return productDetailsService.doesVendorHaveProduct(vendor);
    }

    /**
     * Generates a dictionary where each key is a class name we may want to apply
     * in an ng-class and each value is a boolean which applies that class name
     * @param {object} vendor
     * @return {object}
     */
    function getVendorClassNames(vendor) {
      return {
        'read-only': _isReadOnly(vendor),
        disabled: _isDisabled(vendor),
        error: vendor.UI.has_error,
        loading: vendor.UI.is_loading,
        selected: ctrl.id === vendor.vendor.id,
      }
    }

    /**
     * If the vendor is external and not available, returns the localized
     * "N/A" text, otherwise returns the formatted price of the vendor
     * @param {object} vendor
     * @param {number|undefined} price
     * @return {string}
     */
    function getPriceText(vendor, price = vendor.price) {
      return productDetailsService.getPriceText(vendor, price)
    }

    /**
     * If the vendor id is the same as the selected vendor id, return true, otherwise return false
     * @param {object} vendor
     * @return {boolean}
     */
    function _isVendorCurrentlySelected(vendor) {
      const selected_vendor_id = ctrl.id;
      return selected_vendor_id === vendor.vendor?.id;
    }

    /**
     * If the user has selected a vendor other than the currently selected one
     * and that vendor is neither sold out nor discontinued,
     * emits the ID of that vendor and stores it on the controller
     *
     * @param {object} clicked_vendor The vendor the user just clicked on
     * @param {string} currently_selected_id The ID of the currently selected vendor
     * @return {*} 
     */
    function selectVendor(clicked_vendor, currently_selected_id = ctrl.id) {
      if (clicked_vendor?.vendor?.id !== currently_selected_id) {
        const old_vendor = ctrl.display_vendors.find(vendor => vendor.vendor.id === currently_selected_id);
        vendorChangeEvent(old_vendor, clicked_vendor);
      }

      if (canVendorBeSelected(clicked_vendor)) {
        const clicked_vendor_id = clicked_vendor.vendor.id;
        if (currently_selected_id !== clicked_vendor_id) {
          _handleVendorUpdate(clicked_vendor);
        }
      }
    }

    /**
     * Emits a new selected vendor up the scope chain because either
     * the user has selected it, we've automatically selected it, or
     * the vendor itself has been updated (price/stock/loading status)
     * @param {object} vendor
     */
    function _handleVendorUpdate(vendor) {
      // ensure that the selected status is immediately reflected here
      ctrl.id = vendor.vendor.id;
      $scope.$emit('pd-select-vendor', vendor);
    }

    /**
     * Returns a boolean indicating whether the vendor it's passed is read-only
     * @param {object} vendor
     * @return {boolean}
     */
    function _isReadOnly(vendor) {
      // Adding external vendors to cart is disabled by a user property
      if (ctrl.disable_external_vendor_selection) {
        return Boolean(vendor.UI?.is_external);
      }
      return false;
    }

    /**
     * Opens the modal which allows the user to connect to an external vendor
     * @param {object} $event
     * @param {object} vendor
     */
    function handleConnectAccountClick($event, vendor) {
      $event?.stopPropagation();
      const {vendor_inventory: {vendor: {id, name}}} = vendor;
      sowAnalyticsService.logOpenDialogConnectVendor({
        vendor_name: name,
      })
      ctrl.editing_external_vendor = $rootScope.external_vendors[id];
    }

    /**
     * Hides the modal if the user has elected to do so. Displays any toast message
     * it receives. If passed an external vendor, updates the external vendors, closes
     * the modal, and re-initializes the external vendors to reflect the new state.
     * @param {object} $event
     */
    function handleLinkAccountDialogEvent($event) {
      const {detail: {onClose, toastMessage, externalVendor}} = $event;
      if (onClose) {
        _hideModal();
      }
      if (toastMessage) {
        sgToast.showSimple(toastMessage);
      }
      // we only need to refresh if the vendor is now connected
      if (ProductExternalVendorsService.isVendorConnected(externalVendor?.account)) {
        _handleExternalVendorConnected(externalVendor);
      }
    }

    /**
     * Fetches updated info for a vendor which has just been connected by the user
     * @param {object} connected_vendor
     */
    async function _handleExternalVendorConnected(connected_vendor) {
      _hideModal();
      ProductExternalVendorsService.updateRootScopeExternalVendors(connected_vendor);
      const vendor_to_update = ctrl.display_vendors.find((vendor) => vendor.UI.id === connected_vendor.account.vendor_id);
      if (vendor_to_update) {
        vendor_to_update.UI.is_loading = true;
        _ensureSelectedVendorUpdated();
        const updated_vendors = await ProductExternalVendorsService.getConnectedVendorPricing(
          ctrl.display_vendors,
          connected_vendor,
        );
        _setDisplayVendors(updated_vendors);
      }
    }

    /**
     * Hides the link account modal
     */
    function _hideModal() {
      ctrl.editing_external_vendor = null;
    }

    function _defineLocks() {
      ctrl.disable_account_management = AccessService.getProperty('external_vendors.disable_manage_accounts');
      ctrl.disable_external_vendor_selection = AccessService.getProperty('external_vendors.disable_supplier_selection');
    }

    function _getVendorRank (vendor) {
      return _.findIndex(ctrl.display_vendors, {vendor: {id: vendor.vendor.id}});
    }

    function vendorChangeEvent (old_vendor, new_vendor) {
      const new_vendor_promo = _.first(new_vendor.promotions);
      const event_payload = {
        product_name: ctrl.product.name,
        product_id: ctrl.product.id,
        from_vendor: old_vendor.vendor.name,
        from_price: old_vendor.price,
        from_rank: _getVendorRank(old_vendor),
        to_vendor: new_vendor.vendor.name,
        to_price: new_vendor.price,
        to_rank: _getVendorRank(new_vendor),
        coupon: new_vendor_promo?.promotion_type,
      };

      sowAnalyticsService.logChangeSelectedVendor(ctrl.product, event_payload);
    }
  }

})();

 (function () {
  'use strict';

  angular
    .module('sowUserAdmin')
    .service('sowUserAdminHelperService', sowUserAdminHelperService);

  function sowUserAdminHelperService ($mdDialog, officeService, serverAPI, apiUrl, membershipService, sowUserAdminDataService) {
    /*jshint validthis: true */
    var service = this;

    service.USER_PERMISSIONS = sowUserAdminDataService.USER_PERMISSIONS;
    service.PERMISSION_LABELS = sowUserAdminDataService.PERMISSION_LABELS;
    
    service.getAdminOffices = getAdminOffices;
    service.addUserModal = addUserModal;
    service.editUserModal = editUserModal;
    service.addToOfficeModal = addToOfficeModal;
    service.removeFromOfficeModal = removeFromOfficeModal;

    return service;

    function getAdminOffices () {
      var all = membershipService.getAll().office;
      var admin_offices = [];
      var admin_offices_map = {};
      _.each(all, function(mship){
        if(mship.membership_type === 'master' || mship.membership_type === 'manager'){
          admin_offices.push(mship.office);
          admin_offices_map[mship.office.id] = mship.office;
        }
      });
      return {
        'list': admin_offices,
        'map': admin_offices_map
      };
    }

    function addUserModal () {
      return $mdDialog.show({
        'controller': 'UserAdminModalController',
        'controllerAs':'admmCtrl',
        'templateUrl': 'sow-user-admin/modals/adm-edit-user-modal.html',
        'locals': {
          'admin_offices': getAdminOffices(),
          'mode': 'new',
          'title': 'OFFICE.ADMIN.ADD_NEW_USER',
          'btn_label': 'OFFICE.ADMIN.ADD_USER',
        },
        'bindToController': true,
      });
    }

    function editUserModal (user, current_office) {
      return $mdDialog.show({
        'controller': 'UserAdminModalController',
        'controllerAs':'admmCtrl',
        'templateUrl': 'sow-user-admin/modals/adm-edit-user-modal.html',
        'locals': {
          'admin_offices': getAdminOffices(),
          'user': user,
          'current_office': current_office,
          'mode': 'edit',
          'title': 'OFFICE.PERMISSIONS.EDIT',
          'btn_label': 'OFFICE.ADMIN.SAVE_USER',
        },
        'bindToController': true,
      });
    }

    function addToOfficeModal (user) {
      return $mdDialog.show({
        'controller': 'UserAdminModalController',
        'controllerAs':'admmCtrl',
        'templateUrl': 'sow-user-admin/modals/adm-edit-user-modal.html',
        'locals': {
          'admin_offices': getAdminOffices(),
          'user': user,
          'mode': 'addto',
          'title': 'OFFICE.ADMIN.ADD_TO_OFFICE',
          'btn_label': 'OFFICE.ADMIN.ADD_TO_OFFICE',
        },
        'bindToController': true,
      });
    }

    function removeFromOfficeModal (user, current_office) {
      return $mdDialog.show({
        'controller': 'UserAdminModalController',
        'controllerAs':'admmCtrl',
        'templateUrl': 'sow-user-admin/modals/remove-user-modal.html',
        'locals': {
          'user': user,
          'current_office': current_office,
        },
        'bindToController': true,
      });
    }

    // <div flex="" class="sow-input-section layout-align-start-center layout-row flex" layout="row" layout-align="start center" id="inv-users-search-input">
    //   <input class="simple-input ng-pristine ng-valid flex ng-touched" novalidate="" placeholder="Search" ng-model="search_query" flex="" aria-invalid="false" style="">
    //   <md-icon class="material-icons md-sowingo-theme" role="img" aria-label="search">search</md-icon>
    // </div>

  }

}());

angular.module("app.marketplace.ui.office")
.controller("subscriptionBillingController",
      function($scope, $state, $timeout, $filter, elementService, errorService, sgToast, minTimeout,
          receipts, current_subscription, failed_payment, credit_cards) {
  $scope.all_receipts = receipts;
  $scope.current_subscription = current_subscription;
  $scope.failed_payment = failed_payment;
  $scope.credit_cards = credit_cards;
  
  //Deal with trial subscription by supplementing the necessary fields
  if($scope.current_office.trial_ends_on && !$scope.current_office.trial_over && !current_subscription){
     $scope.current_subscription = {
         current_period_end: $scope.current_office.trial_ends_on,
         current_period_start: $scope.current_office.created_at,
         plan : {
           name : 'Premium (Trial)',
           billing_cycle : 'per_month'
         },
         promotion : (
          $scope.current_office.trial_promotions_redeemed && $scope.current_office.trial_promotions_redeemed.length
            ? $scope.current_office.trial_promotions_redeemed[0]
            : 'None'
        )
     };
  }
  
  $scope.openReceipt = function(receipt){
    var url = $state.href('app.office.profile.subscription_receipt', {receiptId : receipt.id});
    window.open(url,'_blank');
  };
  
  
  //------------------------------------------------------------------------
  //Credit card fns
  
  var _setCurrentCardToDefault = function(new_default){
    if(!$scope.current_subscription || !$scope.current_subscription.default_source){
      return false;
    }
    new_default = new_default || $scope.current_subscription.default_source;
    _.map(credit_cards, function(card){
      if(card.id === new_default.id){
        $scope.currentCreditCard = card;
      }
    });
  };
  _setCurrentCardToDefault();
  
  $scope.$watch('currentCreditCard', function(newCardValue, oldVal){
    if(newCardValue!==oldVal){
      $scope.saveDefaultSource();
    }
  });
  $scope.saveDefaultSource = function(){
    if($scope.currentCreditCard.id !== $scope.current_subscription.default_source){
      $scope.updatingDefaultSource = minTimeout.start(1500);
      elementService.callEndpoint('office', {'endpoint' : 'set_default_credit_card', 'credit_card' : $scope.currentCreditCard})
      .then(function(result){
        if(!result){
          var t_message = $filter('translate')('ERRORS.DEFAULT_CARD_UPDATE');
          errorService.uiErrorHandler(error || t_message, 0);
        }else{
          $scope.current_subscription.default_source = $scope.currentCreditCard.id;
          var t_message = $filter('translate')('OFFICE.BILLING.CARD_UPDATED');
          sgToast.showSimple(t_message);
        }
      }).catch(function(error){
        var t_message = $filter('translate')('ERRORS.DEFAULT_CARD_UPDATE');
        errorService.uiErrorHandler(error || t_message, 0);
      })['finally'](function(){
        $scope.updatingDefaultSource.end()
        .then(function(){
          $scope.updatingDefaultSource = false;
        });
      });
    }
  };
  
  $scope.retryPayment = function(payment){
    if(!$scope.failed_payment || $scope.retryingPayment){
      return false;
    }
    $scope.retryingPayment = true;
    elementService.callEndpoint('subscription', {'endpoint' : 'retry_payment', 'failed_payment_id' : payment.id, 'stripe_card' : $scope.currentCreditCard.id})
    .then(function(result){
      $scope.retrySucceeded = true;
      $timeout(function(){
        $scope.failed_payment = null;
        $scope.retrySucceeded = false;
      }, 2000);
    }).catch(function(){
      $scope.retryFailed = true;
      $timeout(function(){
        $scope.retryFailed = false;
      }, 2000);
    })['finally'](function(){
      $scope.retryingPayment = false;
    });
  };
  
  
});

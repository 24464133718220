(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.implants')
    .controller('impAlertsCtrl', impAlertsCtrl);

  function impAlertsCtrl ($scope, $q, errorService, $timeout, $stateParams, $mdDialog, $anchorScroll, $mdSidenav, $filter,
    sgToast, inventoryItemService, sowImplantService, implantCarrierService, AccessService, mktHelperService, appEvents, membershipService) {
    /*jshint validthis: true */
    var ctrl = this;

    ctrl.TAGS = {
      "Bone Graft": "IMPLANTS.BONE_GRAFT",
      "Closure Cap": "IMPLANTS.COVER_SCREW",
      "Healing Abutment": "IMPLANTS.HEALING_ABUTMENT",
      "Implant": "IMPLANTS.IMPLANT",
      "Membrane": "IMPLANTS.MEMBRANE"
    };

    ctrl.dialogs = ["alert-edit-implant"];
    ctrl.add_to_shopping_list = add_to_shopping_list;
    ctrl.open_edit_slideout = open_edit_slideout;
    ctrl.open_delete_modal = open_delete_modal;
    ctrl.open_remove_slideout = open_remove_slideout;
    ctrl.filter_by = filter_by;
    ctrl.set_po_info = set_po_info;
    ctrl.build_date = build_date;
    ctrl.generate_id = generate_id;
    ctrl.on_remove = on_remove;
    ctrl.implantCarrierService = implantCarrierService;
    ctrl.hide = hide;
    ctrl.answer = answer;
    ctrl.cancel = cancel;
    ctrl.order_by = order_by;
    ctrl.check_item = check_item;
    ctrl.handleAddToCartClick = handleAddToCartClick;
    ctrl.isCellDisabled = isCellDisabled;
    ctrl.shouldShowBtn = shouldShowBtn;
    ctrl.shouldShowSpinner = shouldShowSpinner;
    ctrl.isLoading = true;
    ctrl.loading_product_details_id = null;

    ctrl.$onInit = init;

    return ctrl;

    /**
     * @method  init
     * @description initialize and gather all necessary data
     */
    function init () {
      $scope.$on('implant-alert-edited', fetchAllData);
      ctrl.active_tab = $stateParams.filter === "minimum" ? "minimum" : "expired" ;
      customize_moment_locale();
      fetchAllData();
      defineLocks();

      $scope.$on(appEvents.mktProductDetailsOpenFinished, () => {
        ctrl.loading_product_details_id = null;
      });

    }

    function fetchAllData () {
      return $q.all([load_imp_manufacturers(), load_imp_alerts()])
      .then(function(res){
        ctrl.expired = parse_imp_info_expired(angular.copy(res[0]), res[1].expiring_soon);
        ctrl.minimum = parse_imp_info_minimum(angular.copy(res[0]), res[1].below_minimum_level);
        ctrl.isLoading = false;
        if ($stateParams.filter) {
          $timeout(function() {

            scroll_to($stateParams.filter);

          }, 2000);

        }
      });
    }

    /**
     * @method check_item
     * @param {*} item
     */
    function check_item (item) {
      item.checked = !item.checked;
      if(item.checked){
        ctrl.implantCarrierService.checked_items.push(item);
      }else{
        _.map(ctrl.implantCarrierService.checked_items, function(checked_item, i){
          if(checked_item.id === item.id){
            ctrl.implantCarrierService.checked_items.splice(i, 1);
          }
        });
      }
    }
    /**
     * @method customize_moment_locale
     */
    function customize_moment_locale() {
      moment.updateLocale('en', {
        relativeTime : {
            future: "in %s",
            past:   "%s ago",
            s  : 'a few seconds',
            ss : '%d seconds',
            m:  "a minute",
            mm: "%d minutes",
            h:  "an hour",
            hh: "%d hours",
            d:  "a day",
            dd: "%d days",
            M:  "a month",
            MM: "%d months",
            y:  "1 year",
            yy: "%d years"
        }
    });
    }

    /**
     * @method scroll_to
     * @param {*} item
     */
    function scroll_to(item){
      let category = _.get(item, 'category', '');
      var id = item.manufacturer_url_name+'-'+ category.split(' ').join('-').toLowerCase();
      $anchorScroll(id);
    }

    /**
     * @method generate_id
     */
    function generate_id(manufacturer, category) {

      manufacturer.id = manufacturer.manufacturer_url_name+'-'+ category.split(' ').join('-').toLowerCase();
    }

    /**
     * @method load_imp_alerts
     * @description load all implants alerts from service
     */
    function load_imp_alerts(){
      return sowImplantService.getImplantAlerts()
      .then(function (alerts) {
        ctrl.minimum_total = alerts.below_minimum_level.length;
        ctrl.expired_total = alerts.expiring_soon.length;
        ctrl.implantCarrierService.total_alerts = ctrl.minimum_total + ctrl.expired_total;

        return Promise.resolve(alerts);
      }).catch(function (err) {
        var t_message = $filter('translate')('ERRORS.IMPLANT_ALERTS_LOAD')
        errorService.uiErrorHandler(t_message);
        return Promise.reject(t_message);
      });
    }

    /**
     * @method order_by
     */
    function order_by(categories) {

      var ordered_categories = ['Implant','Closure Cap','Healing Abutment','Bone Graft', 'Membrane'];

      var new_cats = ordered_categories.filter(function (category) {
        return categories.includes(category);
      });

      return new_cats;
    }

    function isCellDisabled(item) {
      return !item.product_id || ctrl.loading_product_details_id || item.UI.is_discontinued;
    }

    function shouldShowBtn(item) {
      return item.product_id && item.product_id !== ctrl.loading_product_details_id;
    }

    function shouldShowSpinner(item) {
      return item.product_id && item.product_id === ctrl.loading_product_details_id;
    }


    /**
     * @method load_imp_manufacturers
     * @description load all implants manufacturers
     */
    function load_imp_manufacturers() {
      return sowImplantService.getManufacturers()
        .then(function (manufacturers) {

          return Promise.resolve(manufacturers);
        }).catch(function (err) {
          var t_message = $filter('translate')('ERRORS.IMPLANT_MANUF_LOAD')
          errorService.uiErrorHandler(t_message);
          return Promise.reject(t_message);
        });
    }

    /**
     * @method parse_imp_info_minimum
     * @description execute cross matching with manufacturer categories and alertse below minimum level
     * @param {*} manufacturers
     * @param {*} alerts
     */
    function parse_imp_info_minimum(manufacturers, alerts) {
      var minimum = [];


      // Below Minimum level
      alerts.forEach(function(alert) {

        var manufacturer = {};
        manufacturer = manufacturers.filter(function (manuf) {
          return manuf.id === alert.manufacturer_id;
        });

        manufacturer = manufacturer[0];

        var found_manufacturer = minimum.find(function (el) {
          return el.manufacturer_url_name === manufacturer.url_name;
        });

        if (!found_manufacturer) {
          found_manufacturer = {
            manufacturer_name: manufacturer.name,
            manufacturer_url_name: manufacturer.url_name,
            logo_url: manufacturer.logo_url,
            categories: order_by(Object.keys(manufacturer.categories)).map(function (key) {
              var obj = manufacturer.categories[key];
              obj.key = key;
              return obj;
            })
           };
           minimum.push(found_manufacturer);
        }

        var category_obj = found_manufacturer.categories.find(function (category) {
          return category.key === alert.catalog_item.category;
        });
        if(!category_obj){
          return;
        }
        if(!_.get(category_obj, 'alerts', null)){
          _.set(category_obj, 'alerts', []);
        }

        _.set(alert, 'UI.is_discontinued', inventoryItemService.isItemDiscontinued(alert));
        category_obj.alerts.push(alert);

      });

      return minimum;

    }

    /**
     * @method parse_imp_info_expired
     * @description execute cross matching with manufacturer categories and alerts expiring soon
     * @param {*} manufacturers
     * @param {*} alerts
     */
    function parse_imp_info_expired(manufacturers, alerts){
      // Expiring soon
      var expired = [];
      alerts.forEach(function(alert, index) {
        // var formatted_alert = {};
        var manufacturer = manufacturers.filter(function (manuf) {
          return manuf.id === alert.inventory_group.manufacturer_id;
        });

        manufacturer = manufacturer[0];

        var found_manufacturer = expired.find(function (el) {
          return el.manufacturer_url_name === manufacturer.url_name;
        });

        if (!found_manufacturer) {
          found_manufacturer = {
            manufacturer_name: manufacturer.name,
            manufacturer_url_name: manufacturer.url_name,
            logo_url: manufacturer.logo_url,
            categories: order_by(Object.keys(manufacturer.categories)).map(function (key) {
              var obj = manufacturer.categories[key];
              obj.key = key;
              return obj;
            })
          };
          expired.push(found_manufacturer);
        }
        alert.manufacturer_name = found_manufacturer.manufacturer_name;
        build_date(alert);

        var category_obj = found_manufacturer.categories.find(function (category) {
          return category.key === alert.inventory_group.catalog_item.category;
        });

        if(!category_obj) {
          return;
        }
        if(!_.get(category_obj, 'alerts', null)){
         _.set(category_obj, 'alerts', []);
        }
        _.set(alert, 'UI.is_discontinued', inventoryItemService.isItemDiscontinued(alert.inventory_group));
        category_obj.alerts.push(alert);
      });



      return expired;


    }

    /***Dialog Operations ***/

    /**
     * @method hide
     * @description hide the dialog
     */
    function hide() {
      $mdDialog.hide();
    }

    function cancel() {
      $mdDialog.cancel();
    }

    function answer(answer) {
      $mdDialog.hide(answer);
    }

    /***End dialog operations ***/

    /**
     * @method open_edit_slideout
     */
    function open_edit_slideout(item) {

      ctrl.current_item = item;
      $scope.$broadcast('open-edit-implant-slideout', item);
      $mdSidenav('alert-edit-implant').open();

    }

    /**
     * @method open_delete_modal
     */
    function open_delete_modal(item) {

      $mdDialog.show({
        controller: 'impAlertsCtrl',
        controllerAs: 'impACtrl',
        templateUrl: 'templates/marketplace/implants/modals/imp-alert-delete.html',
        parent: angular.element(document.body),
        clickOutsideToClose: false,
        locals: {
          item_to_delete: item
        },
        bindToController: true,
      })
      .then(function (answer) {

        if (answer) {
          sowImplantService.removeInventoryItem(item.id)
            .then(async () => {

              $mdDialog.cancel();
              var t_message = $filter('translate')('TOAST.DELETED');
              sgToast.showSimple(item.inventory_group.catalog_item.name + t_message);
              // alertsDataCarrierService.load_alerts();
              if (item.add_to_shopping_list) {
                /* If the item does not have a product ID and the
                user has multi-supplier selection enabled, load the
                product ID of the item so we can open the product details
                slideout if they elect to add it to the shopping list */
                if (!item.product_id && ctrl.has_multi_supplier_selection) {
                  const membership = membershipService.get();
                  const marketplace_id = membership.current_marketplace.id;
                  const office_id = membership.office.id;
                  const {manufacturer_id, reference_no} = item.inventory_group;
                  const {data} = await sowImplantService.getMktProductID(marketplace_id, office_id, manufacturer_id, reference_no)
                  item.product_id = data;
                }
                add_to_shopping_list({
                  ...item,
                  inventory_item_id: item.inventory_group.inventory_item_id
                });
              }
              on_remove(item);


            }).catch(function(error){
              var t_message = $filter('translate')('ERRORS.SERVER_TRY_AGAIN')
              errorService.uiErrorHandler(t_message);
            });
        }
      });
    }

    /**
     * @method open_remove_slideout
     */
    function open_remove_slideout(item) {
      
      ctrl.current_item = item;
      // _.set(ctrl.current_item, 'was_placed_on', moment().format('YYYY-MM-DD'));
      _.set(ctrl.current_item, 'was_placed_on', new Date());
      $scope.$broadcast('open-remove-implant-slideout', ctrl.current_item);
      $mdSidenav('alert-remove-implant').open();
    }

    /**
     * @method  add_to_shopping_list
     * @description add the item to the shopping list
     * @param {*} item
     */
    function add_to_shopping_list(item){
      /* If multiple supplier selection is enabled and this item 
      has a product ID, open the slideout to allow vendor selection */
      if (ctrl.has_multi_supplier_selection && item.product_id) {
        return mktHelperService.productDetailsFetch(item.product_id, {
          action: 'ADD_TO_SHOPPING_LIST',
          item: {
            id: item.inventory_item_id,
          }
        });
      }
      /* If multiple supplier selection is not enabled or if there is
      no product ID for this item, add it directly to shopping list */
      inventoryItemService.addToShoppingList([{id: item.inventory_item_id}])
        .then(function(){
          var text = $filter('translate')('COMMON.ADDED_TO_SL');
          sgToast.showSimple(text);
        }).catch(function (err) {
          var t_message = $filter('translate')('ERRORS.SHOPPING_LIST_ADD')
          errorService.uiErrorHandler(t_message);
        });
    }

    /**
     * @method filter_by
     * @param {*} item
     */
    function filter_by(item){
      var search = ctrl.search ? ctrl.search.toLowerCase() : '';
      return item.manufacturer_name.toLowerCase().indexOf(search) > -1;
    }

    /**
     * @method set_po_info
     * @description set the info of POs
     * @param {*} item
     */
    function set_po_info(item) {
      if(item.po_info && !angular.equals({}, item.po_info)){
        item.purchase_order = true;
        var tooltip = '';
        var tooltip_text = '';
        var keys = Object.keys(item.po_info);
        var t_in = $filter('translate')('COMMON.IN');
        var t_from = $filter('translate')('COMMON.FROM');
        var t_and = $filter('translate')('COMMON.AND');

        if (keys.includes('Received')) {
          keys.splice(keys.indexOf('Received'),1);
        }
        var po_array = [];
        _.each(keys, function(key){
          po_array = po_array.concat(item.po_info[key]);
        });

        var poText = _.map(po_array, function (item) {
          return !item.po_supplier ? item.po_name
            : '{0} {1} {2}'.format(item.po_name, t_from, item.po_supplier);
        });

        tooltip = '{0} {1}'.format(t_in, _.compact([
          poText.slice(0, -1).join(',\n'),
          poText.slice(-1),
        ]).join('\n{0} '.format(t_and)));
        item.tooltip = tooltip;

        if(keys.indexOf("Active") > -1){
          item.tooltip_text = $filter('translate')('ORDERS.ACTIVE');
          item.class = 'active';
        } else if(keys.indexOf("Draft") > -1){
          item.tooltip_text = $filter('translate')('ORDERS.DRAFT_ORDER');
          item.class = 'draft';
        } else {
          item.purchase_order = false;
        }

      } else {
        item.purchase_order = false;
      }
    }

    /**
     * @method build_date
     * @description set date from month and year
     */
    function build_date(item) {
      item.expiry_date = moment().year(item.expiry_year).month(item.expiry_month);
      item.expired_passed_time = item.expiry_date.fromNow();
    }

    /**
     * @method on_remove
     * @description remove the item from the list of alerts
     * @param {*} item
     */
    function on_remove(item) {

      var el = _.find(ctrl.expired, function(alert) {
        return alert.manufacturer_name === item.manufacturer_name;
      });
      
      var alerts = _.find(el.categories, { 'key': item.inventory_group.catalog_item.category}).alerts;

      var removed = _.remove(alerts, function (al) {
        return al.id === item.id;
      });

      implantCarrierService.total_alerts = implantCarrierService.total_alerts - 1;
    }

    function defineLocks() {
      const master_permission_condition = AccessService.getPermission('master_permission');

      const orders_permission_condition = AccessService.getPermission('marketplace_orders_purchase_orders');
      const orders_access_condition = orders_permission_condition || master_permission_condition;

      const delete_lock_condition = AccessService.getProperty('implants.disable_delete', false);
      ctrl.delete_locked = delete_lock_condition;

      ctrl.disable_shopping_list = !AccessService.hasShoppingListAccess();

      const disable_mkt_orders_condition = AccessService.getOverride('disable_marketplace_orders', false);
      const hideCartButton = !AccessService.hasCartAccess();
      ctrl.disable_marketplace_orders = !orders_access_condition || disable_mkt_orders_condition || hideCartButton;

      ctrl.has_multi_supplier_selection = AccessService.getProperty('shopping_list.multi_supplier_selection');
    }

    /** 
     * Opens the Product Detail slideout of the marketplace
     * product which corresponds to the implant. 
     * 
     * @param {String} product_id
    */
    function handleAddToCartClick(product_id) {
      // return early if we're already loading a product
      // or if the clicked alert does not have a product_id
      if (ctrl.loading_product_details_id || !product_id) {
        return;
      }
      ctrl.loading_product_details_id = product_id;
      return mktHelperService.productDetailsFetch(product_id);
    }

  }
}());

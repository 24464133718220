angular.module('app.marketplace.ui.inventory')
  .directive('inventoryScanLink', function () {
    return {
      restrict: 'E',
      controllerAs: 'ctrl',
      templateUrl: 'templates/marketplace/inventory/directives/inventory-scan-link.html',
      scope: true,
      bindToController: {
        context: '@'
      },
      controller: function ($mdDialog) {
        var ctrl = this;

        ctrl.close = close;

        ctrl.$onInit = function init () {
          ctrl.imageSrc = '/styles/img/scan-and-link-' + ctrl.context + '.jpg';
        }

        return ctrl;

        //////////

        function close() {
          $mdDialog.cancel();
        }
      }
    };
  });



(function () {
  'use strict';

  angular
    .module('sowSds')
    .service('sowSdsService', sowSdsService);

  function sowSdsService (officeService, serverAPI, apiUrl, errorService, SDSHelperService) {
    /*jshint validthis: true */
    var service = this;

    service.getAllItems = getAllItems;
    service.getItem = getItem;
    service.createItem = createItem;
    service.deleteItem = deleteItem;
    service.updateItem = updateItem;

    service.docUpload = docUpload;
    service.fileRemove = fileRemove;
    service.imgRemove = imgRemove;
    service.pathRemove = pathRemove;


    return service;

    /**
     * A simple convenience wrapper around the call to send the request to the
     * API. It makes the functions simple than having this code block
     * duplicated everywhere.
     */
    function _callAPI (url, params) {
      return serverAPI
        .doAPICall(url, params)
        .then(function (response) {
          return response.data;
        });
    }

    /**
     * Generate a full API url. Since the prefix of these urls requires extra
     * work (pulling in and inserting the office id), it makes sense for us to
     * centralize that bit.
     */
    function _getUrl (path) {
      path = path || '';
      return '{0}/offices/{1}{2}'.format(apiUrl, sow.officeInfo().id, path);
    }

    function getAllItems () {
      var filters = {};
      var url = _getUrl('/sds');
      var params = {'method': 'GET', 'params': filters};
      return _callAPI(url, params);
    }

    function getItem (doc_id) {
      var filters = {};
      var url = _getUrl("/sds/{0}".format(doc_id));
      var params = {'method': 'GET', 'params': filters};
      return _callAPI(url, params);
    }

    // create SDS document, with parameters:
    // 
    // "document_path": [
    //     "https://domain.com/file.pdf"
    // ],
    // "product_manufacturer": "henry schein",
    // "product_name": "OTHERR medical stuff"
    // 
    function createItem (data) {
      var url = _getUrl('/sds');
      var params = {'method': 'POST', 'data': data};
      return _callAPI(url, params);
    }

    function updateItem (data) {
      var url = _getUrl('/sds/{0}'.format(data.id));
      // var props = {};
      // if(data.item_type === 'other') {
      //   props = data;
      // } else {
      //   props = _.pick(data, ['document_path']);
      // }
      var params = {'method': 'PATCH', 'data': data};
      return _callAPI(url, params);
    }

    function deleteItem (data) {
      var url = _getUrl('/sds/{0}'.format(data.id));
      var params = {'method': 'DELETE'};
      return _callAPI(url, params);
    }

    // S3 related functions

    
    function getAwsToken (filename) {
      var url = _getUrl('/aws_utils/s3_upload_token/sds/{0}'.format(filename));
      var params = {'method': 'GET'};

      return _callAPI(url, params)
      .catch(function(error){
        errorService.uiErrorHandler(error);
      });
    }
    function getImgToken (filename) {
      var url = _getUrl('/aws_utils/s3_upload_token/inventory/{0}'.format(filename));
      var params = {'method': 'GET'};

      return _callAPI(url, params)
      .catch(function(error){
        errorService.uiErrorHandler(error);
      });
    }

    function docUpload (file_data) {
      var filename = getDefaultFileName(file_data.binary.name);
      return getAwsToken(filename)
      .then(function(aws_data){
        var post_data = _.extend(_.omit(aws_data, ['aws_url']), {'file': file_data.binary});
        var form_data = new FormData();
        _.map(Object.keys(post_data), function(key){
          form_data.append(key, post_data[key]);
        });
        var params = {
          'method': 'POST', 
          'data': form_data, 
          'headers':{
            'enctype': 'multipart/form-data', 
            'Access-Control-Allow-Origin': '*',
            'Content-Type': undefined
          },
        };

        return _callAPI(aws_data.aws_url, params)
        .then(function(){
          var path = "{0}{1}/{2}".format(aws_data.aws_url, sow.officeInfo().id, filename);
          return {
            'aws_data': aws_data,
            'file_data': file_data.binary,
            'doc_path': path,
          };
        })
        .catch(function(error){
          // 403: token expired
          // 400: file larger than 50mb
          errorService.uiErrorHandler(error);
        });
      });
    }

    function getDefaultFileName (original_name) {
      var extension = _.last( _.split(original_name, '.') );
      var date = moment().utc().format('YYYYMMDD_HHmmss');
      return _.join([date,extension],'.');
    }

    // delete a file from S3 bucket
    function fileRemove (doc_url) {
      var filename = SDSHelperService.getUrlFilename(doc_url);

      return getAwsToken(filename)
      .then(function(aws_data){

        return _callAPI(doc_url, { 'method': 'DELETE', 'headers': aws_data })
        .then(function(response){
          return true;
        })
        .catch(function(error){
          errorService.uiErrorHandler(error);
          return false;
        });
      });
    }
    function imgRemove (doc_url) {
      var filename = SDSHelperService.getUrlFilename(doc_url);

      return getImgToken(filename)
      .then(function(aws_data){

        return _callAPI(doc_url, { 'method': 'DELETE', 'headers': aws_data })
        .then(function(response){
          return true;
        })
        .catch(function(error){
          errorService.uiErrorHandler(error);
          return false;
        });
      });
    }

    // remove a document_path from an sds document object (will be marked as 'document_path_deleted')
    /* Must set Content Type for a DELETE request which has a JSON body */
    function pathRemove (sds_document, path_index) {
      var url = _getUrl("/sds/{0}".format(sds_document.id));
      var options = {
        'method': 'DELETE',
        'data': {
          'document_id': path_index
        },
        'headers':{
          'Content-Type': 'application/json'
        },
      };

      return serverAPI
        .doAPICall(url, options)
        .then(function (response) {
          return response.data;
        });
    }

  }

}());
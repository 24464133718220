angular.module('app.marketplace.ui.cart').directive('cartGroupMessage',
  function cartGroupMessage($sce, $templateCache, $compile, addressService) {
    return {
      restrict: 'C',
      scope: {
        cartVendorGroup: '=cartGroup'
      },
      link: function ($scope, $element, $attr) {
        //
        // Turn on the Angular Material classes we're using
        //
        $element.addClass('layout-row');
        $element.addClass('layout-align-sm-center-center');
        $element.addClass('layout-align-end-center');

        //
        // Dynamically update the element's inner HTML from the passed template.
        //
        // SOURCE: http://onehungrymind.com/angularjs-dynamic-templates/
        var _updateTemplate = function (templateUrl) {
          var template = $templateCache.get(templateUrl);
          $element.html(template).show();
          $compile($element.contents())($scope);
        };

        //
        // Only show this if free shipping is possible.
        //
        $scope.$watch('cartVendorGroup.freeShippingPossible', function (newValue) {
          if (newValue) {
            $element.removeClass('ng-hide');
          } else {
            $element.addClass('ng-hide');
          }
        });

        //
        // Switch templates depending on the freeShippingRemainder
        //
        $scope.$watch('cartVendorGroup.freeShippingRemainder', function (newValue) {
          if (newValue) {
            _updateTemplate('templates/marketplace/cart/directives/free-shipping-not-met.html');
          } else {
            _updateTemplate('templates/marketplace/cart/directives/free-shipping.html');
          }
        });
      }
    };
  }
);

(function(){
  'use strict';

  angular
    .module('app.marketplace.ui.medications', [
      'app.marketplace.elements',
      'sowMiniLoader',
    ])
    .config(medicationsConfig);

  function medicationsConfig($stateProvider, $urlRouterProvider) {
    $urlRouterProvider.when('/app/medications', '/app/medications/list?type=All');
    $urlRouterProvider.when('/app/medications/', '/app/medications/list?type=All');

    $urlRouterProvider.when('/app/medications/count', '/app/medications/count/getstarted');

    $stateProvider.state('app.medications',{
      url: '/medications',
      controller: 'medSectionController',
      controllerAs: 'medSecCtrl',
      templateUrl: 'templates/marketplace/medications/index.html',
      data: {
        'title': 'Medications',
        'require_log_in': true,
        // 'requires_feature': 'medications',
      }
    });

    $stateProvider.state('app.medications.list', {
      url: '/list?type&focus_product',
      controller: 'medListController',
      controllerAs: 'medlCtrl',
      templateUrl: 'templates/marketplace/medications/med-list.html',
      data: {
        'title': 'Medications',
        'require_log_in' : true,
        'action_bar_directive': 'action-bar-medications'
      },
      params:{
        focus_by_inventory_id: null
      }
    });

    $stateProvider.state('app.medications.log', {
      url: '/log',
      data: {
        'title': 'Medication Log',
        'require_log_in' : true,
        'action_bar_directive': 'action-bar-medications'
      },
      template: '<medications-log-page params={{$stateParams}}></medications-log-page>',
    })

    $stateProvider.state('app.medications.detail', {
      url: '/detail?id&type',
      controller: 'medDetailController',
      controllerAs: 'medDetailCtrl',
      templateUrl: 'templates/marketplace/medications/med-details.html',
      data: {
        'title': 'Medication Details',
        'require_log_in' : true,
        'action_bar_directive': 'action-bar-medications-detail'
      }
    });

    $stateProvider.state('app.medications.alerts', {
      url: '/alerts',
      controller: 'medAlertsController',
      controllerAs: 'medaCtrl',
      templateUrl: 'templates/marketplace/medications/med-alerts.html',
      data: {
        'title': 'Medication Alerts',
        'require_log_in' : true,
        'action_bar_directive': 'action-bar-medications'
      },
      params:{
        filter:''
      }
    });

    $stateProvider.state('app.medications.count', {
      url: '/count',
      controller: 'medCountController',
      controllerAs: 'medcCtrl',
      templateUrl: 'templates/marketplace/medications/med-count.html',
      data: {
        'title': 'Medication Count',
        'require_log_in' : true,
      }
    });

    $stateProvider.state('app.medications.count.list', {
      url: '/list?close_date',
      controller: 'medCountListController',
      controllerAs: 'medclCtrl',
      templateUrl: 'templates/marketplace/medications/med-count-list.html',
      data: {
        'title': 'Medication Count',
        'require_log_in' : true,
        'action_bar_directive': 'action-bar-medications'
      }
    });

    $stateProvider.state('app.medications.count.detail', {
      url: '/detail/:date',
      controller: 'medCountDetailController',
      controllerAs: 'medcdCtrl',
      templateUrl: 'templates/marketplace/medications/med-count-detail.html',
      data: {
        'title': 'Medication Rx Count',
        'require_log_in' : true,
        'action_bar_directive': 'action-bar-medications-count-detail'
      }
    });

    $stateProvider.state('app.medications.count.getstarted', {
      url: '/getstarted',
      controller: 'medCountDetailController',
      controllerAs: 'medcdCtrl',
      templateUrl: 'templates/marketplace/medications/med-count-getstarted.html',
      data: {
        'title': 'Medication Count - Get Started',
        'require_log_in' : true,
        'action_bar_directive': 'action-bar-medications'
      }
    });

  }

}());

angular.module('app.marketplace.ui.shopping.search', [])

  .config(function($stateProvider, $urlRouterProvider) {
    $stateProvider
      .state('app.shopping.search', {
        url: '/search/:urlQuery',
        controller: 'SearchController',
        templateUrl: 'templates/marketplace/search/search.html'
      })

      .state('app.shopping.search.sowingo_products', {
        url: '/sowingo_products',
        templateUrl : 'templates/marketplace/content-block/content-block-page.html',
        controller : 'contentBlockPageController',
        resolve: {
          block: function ($stateParams, searchService) {
            return searchService.getSearch($stateParams.urlQuery).then(function (searchElements) {
              return {
                title: 'Sowingo Products',
                element_type: 'product',
                displayed_products: _.values(searchElements.products),
                sort: 'none'
              };
            });
          }
        }
      })

      .state('app.shopping.search.inventory_items', {
        url: '/inventory_items',
        templateUrl : 'templates/marketplace/content-block/content-block-page.html',
        controller : 'contentBlockPageController',
        resolve: {
          block: function ($stateParams, searchService) {
            return searchService.getSearch($stateParams.urlQuery).then(function (searchElements) {
              return {
                title: 'Matches in Your Inventory',
                element_type: 'inventoryItem',
                displayed_products: _.values(searchElements.inventory_items),
                sort: 'none'
              };
            });
          }
        }
      })

    ;
  })

;

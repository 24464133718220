//
// This service is intended to watch various settings/variables (like country) then
// call autofill on model instances that depend on these settings/variables
//
angular.module('app.marketplace.elements').service('elementRefreshService',
  function elementRefreshService($rootScope, $q, $log, elementService, ConfigService, appEvents, sessionService, officeService, alertsDataCarrierService) {
    'use strict';

    var _this = this;

    _this.watchVars = {
      'country' : {
        name : 'country',
        initialized : false,
        value : null,
        watchEvents : [appEvents.setSession, appEvents.clearSession, 'officeService: office-changed'],
        defaultFn : function(){
          return $rootScope.config.countriesMap['CA'];
        },
        getFn : function(wait){
          if (wait) {
            return _waitForInitialized(_this.watchVars.name);
          }
          else if ($rootScope.current_session && $rootScope.current_session.country) {
            return $rootScope.current_session.country;
          } else {
            return _this.watchVars.country.defaultFn();
          }
        },
        updateFn : function($event, session){
          var watchVar = _.get(_this, 'watchVars.country');

          //
          // We need to initialize this to _something_ before
          // ConfigService.waitUntilInitialized() resolves.
          //
          if (!watchVar.initialized) {
            watchVar.value = $rootScope.current_country = {'id': 'CA', 'name': 'Canada'};
          }

          return ConfigService.waitUntilInitialized().then(function () {
            var country;
            var countryId;
            var countriesMap = ConfigService.get('countriesMap');
            var prevCountry = _.get(_this, 'watchVars.country.value');


            // alertsDataCarrierService.load_alerts();

            if ($event.name === 'officeService: office-changed') {
              var office = officeService.get();
              countryId = _.get(office, 'address.country.id');
            } else {
              var session = sessionService.get();
              countryId = _.get(session, 'country.id');
            }

            if (countryId) {
              country = countriesMap[countryId];
            } else {
              country = watchVar.defaultFn();
            }

            if (!country || !country.id) {
              $log.warn('elementRefreshService: Cannot set country. Not a valid country:', country);
              return false;
            }

            //
            // Bail out early if the country isn't changing.
            //
            if (prevCountry && prevCountry.id === country.id && watchVar.initialized) {
              $log.debug('elementRefreshService: Country is already "%s". Nothing to do.', _.get(country, 'name'));
              watchVar.initialized = true;
              return true;
            }

            if (prevCountry.id === country.id) {
              $log.info('Initializing default country %o', country.name);
            } else {
              $log.info('Changing Country From %o To %o.', _.get(prevCountry, 'name'), country.name);
            }

            watchVar.value = $rootScope.current_country = angular.copy(country);
            watchVar.initialized = true;

            //
            // Generate $rootScope.current_provinces Array and
            // $rootScope.current_provinces_map Object for the current country
            //
            var provinces = $rootScope.config.countriesMap[watchVar.value.id].provinces;
            var tempCurrentProvincesMap = {};

            angular.forEach(provinces, function (province) {
              tempCurrentProvincesMap[province.id] = province;
            });

            // Use angular.copy to maintain linking
            angular.copy(provinces, $rootScope.config.current_provinces);
            angular.copy(tempCurrentProvincesMap, $rootScope.current_provinces_map);

            return watchVar.value;
          });
        }
      },
      'marketplace_medical_field' : {
        name : 'marketplace_medical_field',
        initialized : false,
        value : null,
        watchRootVars : ['marketplace_medical_field'],
        defaultFn : function(){
          return $rootScope.config.medical_fields_map['dentistry'];
        },
        getFn : function(wait){
          if (wait) {
            return _waitForInitialized(_this.watchVars.marketplace_medical_field);
          }
          return $rootScope.marketplace_medical_field && $rootScope.marketplace_medical_field.id ?
              $rootScope.marketplace_medical_field : _this.watchVars.marketplace_medical_field.defaultFn();
        },
        updateFn : function(varName, medical_field){
          _this.watchVars.marketplace_medical_field.initialized = true;
          return $q.when(medical_field);
        }
      }
    };

    //
    // Wait for variable to be initialized.
    //
    function _waitForInitialized(watchVar) {
      if (watchVar.initialized) {
        return $q.resolve(watchVar.value);
      }

      var deferred = $q.defer();

      var initWatch = $rootScope.$watch(watchVar.initialized, function (isInitialized, wasInitialized) {
        if (isInitialized) {
          deferred.resolve(watchVar.getFn());
          initWatch(); // Stop watch;
        }
      });

      return deferred.promise;
    }

    function _getCurrentVar(varName, wait){
      var currVar = _this.watchVars[varName];
      if (wait) {
        return _waitForInitialized(currVar);
      }else {
        return currVar.value;
      }
    }

    function _updateVar(currVar, $event, eventResponse){
      currVar.updateFn($event, eventResponse).then(function (result) {
        if (result && result !== true) {
          _recheckElements(currVar);
          $rootScope.$broadcast('elementRefreshService: country-changed', _this.watchVars.country);
        }
      });
    }

    //Convenience function to avoid creating functions in a loop.
    function _updateEle(currModel, currVar, currEle){
      return _waitForInitialized(currVar).then(function(){
        return currModel.autofill(currEle);
      });
    }

    //
    // Recheck all element models and instances
    //
    function _recheckElements(currVar) {
      // Cycle through element types
      _.map(elementService.elementNameMap, function(currModel, model_index){
        if (currModel.model_data.watch_vars && currModel.model_data.watch_vars[currVar.name]) {
          _.map(currModel, function(instance, instance_index){
            var currMap = elementService.elementMaps[model_index][instance_index];
            currModel.autofill(currMap);
            _updateEle(currModel, currVar, currMap);
          });
        }
      });
    }

    //
    // Create a $rootScope watcher for a "watchVar" (currVar) and a $rootScope
    // variable (varName)
    //
    function setupRootScopeWatcher (currVar, varName) {
      $rootScope.$watch(varName, function (newValue, oldValue) {
        _updateVar(currVar, varName, newValue);
      });
    }

    //
    // Setup $event handler on $rootScope for currVar / eventName
    //
    function setupEventHandler (currVar, eventName) {
      $rootScope.$on(eventName, function ($event, object) {
        _updateVar(currVar, $event, object);
      });
    }

    //
    // Initialize Service
    //
    function _init() {
      //
      // Cycle through all variables and watch necessary events and variables.
      //
      _.map(_this.watchVars, function(currVar, var_index){
        _.map(currVar.watchEvents, function(event, ev_index){
          setupEventHandler(currVar, event);
        });
        _.map(currVar.watchRootVars, function(rootvar){
          setupRootScopeWatcher(currVar, rootvar);
        });
      });
    }

    return {
      'init' : _init,
      'getCurrentVar' : _getCurrentVar
    };
  }
);

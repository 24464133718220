(function () {
  'use strict';

  const categoryCard = {
    bindings: {
      category: '<',
    },
    controller: 'categoryCardCtrl',
    controllerAs: 'catCardCtrl',
    selector: 'mktCategoryCard',
    templateUrl: 'sow-mkt/directives/mkt-card-category.html',
  };

  /** @ngInject */
  class categoryCardCtrl {
    constructor(mktHelperService) {
      this.mktHelperService = mktHelperService;
    }

    $onInit() {
      this.localized_category = this.mktHelperService.getLocalizedProp(this.category, 'name');
    }

    /**
     * Navigates to a page of products in the selected category
     * @param {object} category
     */
    goToCategory(category = this.category) {
      this.mktHelperService.goToCategory({cat_id: category.id, category});
    }

    /**
     * Finds the URL of the category's image
     * @param {object} category
     * @return {string}.
     */
    getImageUrl(category = this.category) {
      return category.images?.url_src;
    }
  }

  angular.module('sowMarketplace')
    .controller(categoryCard.controller, categoryCardCtrl)
    .component(categoryCard.selector, categoryCard);

})();

(function () {
  'use strict';

  angular
    .module('sowFeatureService', [])
    .service('sowFeatureService', FeatureService);

  //
  // The purpose of this service is to have a central place check if a feature
  // is enabled or not.
  //
  // TODO: Rearchitect this so that no feature-specific code is hosted in here.
  // Instead modules that enable functionality register themselves with this
  // service when they load.
  //
  function FeatureService ($rootScope, RouteService, sessionService, officeService) {
    /*jshint validthis: true */
    var service = this;

    var eventMap = {
      'sessionService: new-session': refreshFeatures,
      'sessionService: clear-session': refreshFeatures,
      'sessionService: set-session': refreshFeatures,
      'officeService: office-changed': refreshFeatures,
      // 'sessionService: initialized': refreshFeatures,
      '$stateChangeSuccess': handleRequiredFeatureSetting,
    };

    service.features = {};
    service.initialize = initialize;
    service.featureEnabled = featureEnabled;

    return service;

    function initialize () {
      $rootScope.features = service.features;

      _.forEach(eventMap, function (eventHandler, eventName) {
        $rootScope.$on(eventName, eventHandler);
      });
    }

    function handleRequiredFeatureSetting (event, toState, toStateParams) {
      var toStateRequiredFeature = _.get(toState, 'data.requires_feature');

      if (toStateRequiredFeature && !featureEnabled(toStateRequiredFeature)) {
        RouteService.goToDefault();
      }
    }

    function refreshFeatures () {
      _.mapKeys(service.features, function (_, feature) {
        service.features[feature] = false;
      });


      var office = officeService.get();

      if (office) {
        var features = office.feature_flags;
        angular.extend(service.features, features || {});
      }
    }

    function featureEnabled (feature) {
      return _.get(service.features, feature, undefined);
    }

    function setFeature (feature, state) {
      service.features[feature] = state;
    }
  }
}());

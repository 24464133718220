(function () {
  'use strict';

  angular.module('sowAlerts', [
    'app.marketplace.elements',
    'sowMiniLoader',
  ])
  .config(alertsConfig);

  function alertsConfig($stateProvider, $urlRouterProvider) {

    $urlRouterProvider.when('/app/alerts', '/app/alerts/home');
    // $urlRouterProvider.when('/app/alerts/', '/app/alerts/home/');

    $stateProvider.state('app.alerts',{
      url: '/alerts',
      controller: 'alertsSectionCtrl',
      controllerAs: 'alertsSecCtrl',
      templateUrl: 'sow-alerts/index.html',
      data: {
        'title': 'Alerts',
        'require_log_in': true,
        'action_bar_directive': 'action-bar-alerts'
      }
    });

    $stateProvider.state('app.alerts.home',{
      url: '/home',
      controller: 'alertsController',
      controllerAs: 'alertsCtrl',
      templateUrl: 'sow-alerts/alerts-home.html',
      data: {
        'title': 'Alerts',
        'require_log_in': true,
        'action_bar_directive': 'action-bar-alerts'
      }
    });

  }

}());

(function () {

  // SalesRep Class Provider
  function SalesRepCP (SupplierCP) {
    const Supplier = SupplierCP.getClass();
    /**
     *
     * @class SalesRep
     * @typedef SalesRep
     */
    class SalesRep {
      constructor(json_data) {
        this.id = json_data.id;
        this.can_access_inventory = json_data.can_access_inventory;
        this.name = json_data.name;
        this.email = json_data.email;
        this.phone_number = json_data.phone_number;
        this.is_primary = json_data.is_primary;
        this.supplier = new Supplier(json_data.supplier);
        this.supplier_id = json_data.supplier_id;
      }

      id;
      get id () {
        return this.id;
      }
      set id (new_id) {
        this.id = new_id;
      }

      can_access_inventory;
      get can_access_inventory () {
        return this.can_access_inventory;
      }
      set can_access_inventory (new_can_access_inventory) {
        this.can_access_inventory = new_can_access_inventory;
      }
      
      supplier_id;
      get supplier_id () {
        return this.supplier_id;
      }
      set supplier_id (new_supplier_id) {
        this.supplier_id = new_supplier_id;
      }
      
      name;
      get name () {
        return this.name;
      }
      set name (new_name) {
        this.name = new_name;
      }
      
      email;
      get email () {
        return this.email;
      }
      set email (new_email) {
        this.email = new_email;
      }
      
      phone_number;
      get phone_number () {
        return this.phone_number;
      }
      set phone_number (new_phone_number) {
        this.phone_number = new_phone_number;
      }
      
      is_primary;
      get is_primary () {
        return this.is_primary;
      }
      set is_primary (new_is_primary) {
        this.is_primary = new_is_primary;
      }
      
      supplier;
      get supplier () {
        return this.supplier;
      }
      set supplier (new_supplier) {
        this.supplier = new_supplier;
      }
      
    }

    this.getClass = () => {
      return SalesRep;
    }
  }

  angular.module('sowMarketplace')
    .service("SalesRepCP", SalesRepCP);
})();

(function(){
  'use strict';

  angular
    .module('app.marketplace.ui.implants', [
      'app.marketplace.elements',
      'sowImplants',
      'sowBrowserFlags',
    ])
    .config(implantsConfig);

  function implantsConfig($stateProvider, $urlRouterProvider, appEventsProvider) {
    appEventsProvider.registerEvent('impEditRemoval', 'imp-edit-removal');
    appEventsProvider.registerEvent('impEditRemovalFetch', 'imp-edit-removal-fetch');
    appEventsProvider.registerEvent('implantItemSaved', 'implant-item-saved');


    $urlRouterProvider.when('/app/implants', '/app/implants/manufacturers');
    $urlRouterProvider.when('/app/implants/', '/app/implants/manufacturers');

    $stateProvider.state('app.implants',{
      url: '/implants',
      controller: 'impSectionCtrl',
      controllerAs: 'impSecCtrl',
      templateUrl: 'templates/marketplace/implants/imp-index.html',
      data: {
        'title': 'Implants',
        'require_log_in': true,
        // 'require_feature': 'implants',
      }
    })
    .state('app.implants.alerts',{
      url: '/alerts?filter',
      controller: 'impAlertsCtrl',
      controllerAs: 'impACtrl',
      templateUrl: 'templates/marketplace/implants/imp-alerts.html',
      data: {
        'title': 'Implant Alerts',
        'require_log_in': true,
        'require_feature': 'implants',
        'action_bar_directive': 'action-bar-implants'
      },
      params:{
        filter:'',
        manufacturer_url_name:'',
        category:''
      }
    })
    .state('app.implants.table', {
      url: '/table/:mfr_id/?type&tab&c_tab&group_list',
      controller: 'impTableController',
      controllerAs: 'imptCtrl',
      templateUrl: 'templates/marketplace/implants/imp-table.html',
      data: {
        'title': 'Implants Table',
        'require_log_in' : true,
        'action_bar_directive': 'action-bar-implants'
      }
    })
    .state('app.implants.manufacturers',{
      url: '/manufacturers',
      controller: 'impManufacturersController',
      controllerAs: 'impmCtrl',
      templateUrl: 'templates/marketplace/implants/imp-manufacturers.html',
      data: {
        'title': 'Implant Inventory',
        'require_log_in' : true,
        'action_bar_directive': 'action-bar-implants'
      }
    })
    .state('app.implants.getstarted',{
      url: '/getstarted/:step/?mfr_id&adding&type',
      controller: 'impGetStartedController',
      controllerAs: 'impgCtrl',
      templateUrl: 'templates/marketplace/implants/imp-getstarted.html',
      data: {
        'title': 'Implants: Getting Started',
        'require_log_in' : true,
        'action_bar_directive': 'action-bar-implants'
      }
    })
    .state('app.implants.drills',{
      url: '/drills',
      controller: 'impDrillsController',
      controllerAs: 'impdCtrl',
      templateUrl: 'templates/marketplace/implants/imp-drills.html',
      data: {
        'title': 'Implant Drills',
        'require_log_in' : true,
        'action_bar_directive': 'action-bar-implants'
      }
    })
    .state('app.implants.edit-table', {
      url: '/edit-table?type&mfr_id',
      controller: 'impEditTableController',
      controllerAs: 'impeCtrl',
      templateUrl: 'templates/marketplace/implants/imp-edit-table.html',
      data: {
        'title': 'Implants Edit Tables',
        'require_log_in' : true,
        'action_bar_directive': 'action-bar-edit-implant-table'
      }
    })
    .state('app.implants.doctors', {
      url: '/doctors?add',
      templateUrl: 'templates/marketplace/implants/imp-referring-doctors.html',
      controller:"referringDoctorsController",
      controllerAs:"refDrCtrl",
      data: {
        title: 'Referring Doctors',
        'requires_feature': 'implants',
        'action_bar_directive': 'action-bar-implants'
      }
    })
    ;
  }

}());

(function () {
  'use strict';

  angular.module('sowMedications')
  .directive('sowMedStockEdit', sowMedicationStockEditDirective)
  .controller('sowMedicationStockEditController', sowMedicationStockEditController);

  function sowMedicationStockEditDirective () {
    return {
      restrict: 'E',
      replace: true,
      templateUrl: 'sow-medications/directives/edit-med-stock.html',
      controller: 'sowMedicationStockEditController',
      controllerAs: 'mseCtrl',
    };
  }

  function sowMedicationStockEditController ($scope, $q, $filter, $rootScope, $mdSidenav, $mdDialog, sowInventoryService, sowMedicationService, dataSharingService, errorService, alertsDataCarrierService, sgToast, formService) {
    var ctrl = this;

    ctrl.slideout_id = 'med-stock-edit';
    ctrl.locations = null;
    ctrl.quantity_max_value = 99999999;

    ctrl.saveCurrentItem = saveCurrentItem;
    ctrl.close = close;
    ctrl.onQuantityChange = onQuantityChange;

    init();

    return ctrl;

    function init () {
      // loadLocations();
      loadYears();
      $scope.$on('add-medstock-slideout', openAdd);
      $scope.$on('edit-medstock-slideout', openEdit);
      $scope.$on('close-medstock-slideout', close);
    }

    function openAdd (event, medication) {
      ctrl.mode = 'Add';
      ctrl.medication = medication;
      ctrl.medication_stock_item = {};
      ctrl.base_item = angular.copy(ctrl.medication_stock_item);
      open();
    }

    function openEdit (event, medication, stock_item) {
      ctrl.mode = 'Edit';
      var expiry = moment(stock_item.expiry_date);
      _.extend(stock_item, {
        year: expiry.year(),
        month: expiry.format('MMMM')
      });
      ctrl.medication = medication;
      ctrl.medication_stock_item = stock_item;
      ctrl.base_item = angular.copy(ctrl.medication_stock_item);
      open();
    }

    function open () {
      loadLocations();
      $mdSidenav(ctrl.slideout_id).open();
    }

    function close () {
      return checkUnsavedChanges().then(function(){
        $mdSidenav(ctrl.slideout_id).close();
        resetForm();
      });
    }

    function resetForm () {
      ctrl.edit_medication_form.$setPristine();
      ctrl.edit_medication_form.$setUntouched();
    }

    function checkUnsavedChanges () {
      var changed = !_.isEqual(ctrl.base_item, ctrl.medication_stock_item);
      var def = $q.defer();
      if (changed) {
        return unsavedChangesDialog()
        .then(function(save){
          if(save){
            saveCurrentItem();
          }
        });
      } else {
        def.resolve();
        return def.promise;
      }
    }

    function _isQuantityInvalid () {
      /*
        Quantity is invalid if the sum of the input value and the medication quantity
        is greater than the max value (99999999)
      */
      return _.toNumber(ctrl.medication_stock_item.qty) + ctrl.medication.quantity > ctrl.quantity_max_value;
    }

    function onQuantityChange () {
      if (_isQuantityInvalid()) {
        ctrl.edit_medication_form.qty.$setValidity('max', false);
      } else {
        ctrl.edit_medication_form.qty.$setValidity('max', true);
      }
    }

    function saveCurrentItem () {
      if (!formService.checkRequiredFields(ctrl.edit_medication_form) || _isQuantityInvalid()) {
        throw new Error();
      }

      var stock_item = ctrl.medication_stock_item;
      stock_item.expiry_date = moment().year(stock_item.year).month(stock_item.month).toDate();

      ctrl.saving = true;
      if(stock_item.id){
        // save existing stock item
        return sowMedicationService.createOrUpdateMedicationStock(stock_item)
        .then(function(response){
          var t_message = $filter('translate')('TOAST.MED_STOCK_SAVED');
          sgToast.showSimple(t_message);
          ctrl.base_item = ctrl.medication_stock_item = response;
          ctrl.close();
        });
      } else {
        return sowMedicationService
        .batchCreateMedicationStock({
          'medication_id': ctrl.medication.id,
          'expiry_date': stock_item.expiry_date,
          'lot_number': stock_item.lot_number,
          'location_id': stock_item.location_id,
          'quantity': stock_item.qty
        })
        .then(function (result) {
          var t_message = $filter('translate')('INVENTORY.UPDATED_ON_HAND');
            sgToast.showSimple(t_message);
          $rootScope.$broadcast('medication-stock-added', ctrl.medication, stock_item.qty);
          ctrl.base_item = ctrl.medication_stock_item;
          ctrl.close();
        })
        .catch(function () {
          var t_message = $filter('translate')('ERRORS.ON_HAND_UPDATE')
          errorService.uiErrorHandler(t_message);
        })
        .finally(function () {
          ctrl.saving = false;
        });
      }
    }

    function unsavedChangesDialog(){
      return $mdDialog.show({
        templateUrl: 'templates/marketplace/medications/modals/discard-edition-changes.html',
        parent: angular.element(document.body),
        clickOutsideToClose: true,
        controller: 'regularDialogController',
        controllerAs: 'dialogCtrl'
      });
    }

    function loadYears(){
      var twenty_years_from_now = [];
      for (var index = 0; index <= 20; index++) {
        twenty_years_from_now.push(moment().year() + index);
      }
      ctrl.years = twenty_years_from_now;
    }

    function loadLocations () {
      return sowInventoryService.getInventoryLocations()
      .then(function(response){
        ctrl.locations = _.sortBy(response,['name']);
      })
      .catch(function(error){
        var t_message = $filter('translate')('ERRORS.INVENTORY_LOCATIONS_LOAD_TRY_AGAIN')
        errorService.uiErrorHandler(error.message || t_message);
      });
    }
  }

}());

(function () {
  'use strict';

  angular
    .module('app.marketplace.elements')
    .service('subscriptionService', subscriptionService);

  function subscriptionService ($q, $filter, $rootScope, elementService, officeService) {
    /*jshint validthis: true */
    var service = this;

    service._currentSubscription = null;

    service.getPlans = getPlans;
    service.getPromotions = getPromotions;
    service.getCurrentRefund = getCurrentRefund;
    service.calculateUpgradeCost = calculateUpgradeCost;
    service.updateSubscription = updateSubscription;
    service.currentSubscription = currentSubscription;
    service.switchToMarketplace = switchToMarketplace;
    service.failedPayments = failedPayments;
    service.downgradeRefund = downgradeRefund;
    service.downgradeTo = downgradeTo;

    //
    // If the office changes, clear the cached current subscription.
    //
    $rootScope.$on('membershipService: set-membership', function () {
      service._currentSubscription = null;
    });

    return service;

    function downgradeRefund (planHrid, billingCycle) {
      return elementService
        .callEndpoint('subscription', {
          'endpoint': 'downgrade_refund',
          'billingCycle': billingCycle,
          'planHrid': planHrid,
        })
        .then(function (response) {
          return _.get(response, 'data.refund', 0);
        });
    }

    //
    // Get the Current Cancellation Refund
    //
    // If the user were to cancel right now, what would the refund amount be?
    //
    function getCurrentRefund () {
      service._currentSubscription = null;
      return currentSubscription().then(function (currentSubscription) {
        if (currentSubscription !== null) {
            return currentSubscription['current_refund'];
        } else {
            return null;
        }

      });
    }

    //
    // Get Current Subscription
    //
    // Note: Do _not_ use 'current_refund' without clearing the cache.
    //
    function currentSubscription () {
      if (service._currentSubscription) {
        return $q.when(service._currentSubscription);
      }

      return officeService.get(true).then(function (office) {
        var params = {
          'endpoint': 'current_subscription',
          'office_id': office.id,
        };
        return elementService.callEndpoint('subscription', params).then(function (response) {
          service._currentSubscription = response.data;
          if(service._currentSubscription && service._currentSubscription.office_subscription && service._currentSubscription.office_subscription.subscribed_on){
            service._currentSubscription.office_subscription.subscribed_on_m = moment(service._currentSubscription.office_subscription.subscribed_on);
            service._currentSubscription.office_subscription.subscribe_end_m = service._currentSubscription.office_subscription.subscribed_on_m.add(
                service._currentSubscription.remaining_months + 1, 'months');
          }
          return service._currentSubscription;
        });
      });
    }

    //
    // Switch to a Marketplace Plan
    //
    function switchToMarketplace (feedback) {
      var params = {
        'endpoint': 'update_subscription',
        'plan_id': null,
        'feedback': feedback,
      };

      return elementService.callEndpoint('subscription', params).then(function (response) {
        service._currentSubscription = null;
        return response.data;
      });
    }

    function downgradeTo (plan_id, feedback) {
      var params = {
        'endpoint': 'update_subscription',
        'plan_id': plan_id,
        'feedback': feedback,
      };

      return elementService
        .callEndpoint('subscription', params)
        .then(function (response) {
          service._currentSubscription = null;
          return response.data;
        });
    }

    //
    // Update the current office's subscription
    //
    function updateSubscription (subscriptionPlan, creditCard, promotion) {
      var params = {
        'endpoint': 'update_subscription',
        'plan_id': _.get(subscriptionPlan, 'id'),
        'card_id': _.get(creditCard, 'id'),
        'promo_code': _.get(promotion, 'code'),
      };

      return elementService.callEndpoint('subscription', params).then(function (response) {
        service._currentSubscription = null;
        return response.data;
      });
    }

    //
    // A quick function to unwrap the payload from an http response object.
    //
    function unwrapResponse (response) {
      return _.get(response, 'data');
    }

    //
    // Calculate the upgrade cost from subscription plan and subscription
    // promotion.
    //
    // Note: Currently only supports 'PERCENT_OFF' discount type.
    //
    function calculateUpgradeCost (plan, promo) {
      return elementService
        .callEndpoint(
          'subscription',
          {
            'endpoint': 'upgrade_cost',
            'stripe_coupon_id': promo ? promo.code : null
          }
        )
        .then(function (response) {
          if (response.data && response.data.length) {
            var found;

            _.forEach(response.data, function (upgradeInfo) {
              if (upgradeInfo.subscription_plan.id === plan.id && upgradeInfo.valid) {
                found = upgradeInfo;
                return;
              }
            });

            if (found) {
              return found;
            }
          }
          // Could not find cost. Throw error.
          throw "An error occurred while retrieving the subscription information. Please contact support at support@sowingo.com.";
        });
    }

    //
    // Fetch the current list of promotions based on various filter options.
    //
    // Note: some filter options have server-side defaults if you are not an
    // admin.
    //
    function getPromotions (options) {
      var params = {
        'endpoint': 'subscription_promotions'
      };

      if (_.has(options, 'professional_association_id')) {
        params.professional_association_id = options.professional_association_id;
      }

      if (_.has(options, 'country_id')) {
        params.country_id = options.country_id;
      }

      if (_.has(options, 'code')) {
        params.code = options.code;
      }

      return elementService
        .callEndpoint('subscription', params)
        .then(unwrapResponse);
    }

    //
    // Fetch the current list of subscription plans.
    //
    function getPlans (options) {
      var params = {
        'endpoint': 'subscription_plans'
      };

      if (_.has(options, 'country_id')) {
        params.country_id = options.country_id;
      }

      if (_.has(options, 'province_id')) {
        params.province_id = options.province_id;
      }

      return elementService
        .callEndpoint('subscription', params)
        .then(unwrapResponse);
    }

    //
    // Get Failed Subscription Payments
    //
    function failedPayments () {
      if (service._failedPayments) {
        return $q.when(service._failedPayments);
      }

      return officeService.get(true).then(function (office) {
        var params = {
          'endpoint': 'failed_payments',
          'office_id': office.id,
        };
        return elementService.callEndpoint('subscription', params).then(function (response) {
          service._failedPayments = response.data;
          return service._failedPayments;
        });
      });
    }
  }
}());

(function () {
  'use strict';

  angular.module('sowInventory')
  .directive('sowIiHeader', sowInventoryItemHeaderDirective)
  .controller('sowInventoryItemHeaderController', sowInventoryItemHeaderController);

  function sowInventoryItemHeaderDirective () {
    return {
      restrict: 'E',
      replace: true,
      templateUrl: 'sow-inventory/directives/item-header.html',
      controller: 'sowInventoryItemHeaderController',
      controllerAs: 'iihCtrl',
      scope: {
        'item': '=',
      }
    };
  }

  function sowInventoryItemHeaderController ($scope) {
    var ctrl = this;
    // no use for init in this case since it's initiated on first page load, there's no item set
    return ctrl;
  }

}());

angular
  .module('sowSterilization')
  .directive('disableMdDateInput', function() {
  return {
      restrict: 'A',
      link: function(_scope, element) {
        // Update input css to hide the caret
        element.css('caret-color', 'transparent');

        // Prevent the user from typing in the input
        element.on('keydown', function(event) {
          event.preventDefault();
        });
      }
  };
});

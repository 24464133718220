angular.module("app.marketplace.elements")

.service("cartModel", function($rootScope, apiUrl, commonApiService) {
  var _this = this;
  this.model_data = {
    "name" : "cart",
    "display_name" : "Cart",
    "api" : {
      "current" : function(options){
        return apiUrl + "/me/cart";
      },

      "update_shipping" : function(options){
        if(!checkNested(options,'shipping_info')){
          throw "Missing option(s) for cart path 'update_shipping'. Options: " + JSON.stringify(options);
        }
        return {
          path : apiUrl + "/me/cart/shipping_info",
          params : {
            method : "POST",
            data : {shipping_info: options.shipping_info}
          }
        };
      },

      //Call when card_id !== null
      "update_billing" : function(options){
        let id = options?.card?.skyflow_id || options?.card?.id;
        if (!id){
          throw "Missing option(s) for cart path 'update_billing'. Options: " + JSON.stringify(options);
        }
        let data = {};
        if (options?.card?.skyflow_id){
          data.skyflow_id = options.card.skyflow_id;
        } else {
          data.card_id = options?.card?.id;
        }
        return {
          path : apiUrl + "/me/cart/billing_info",
          params : {
            method : "POST",
            data : data
          }
        };
      },
      //Call when card_id === null
      "remove_billing" : function(options){
        if (options.card_id){
          throw "Incorrect option(s) for cart path 'remove_billing'. Options: " + JSON.stringify(options);
        }
        return {
          path : apiUrl + "/me/cart/billing_info",
          params : {
            method : "DELETE"
          }
        };
      },

      "current_promo_code" : function(options){
        return apiUrl + '/me/cart/promo_code';
      },
      "update_promo_code" : function(options){
        if(options.promo_code===undefined){
          throw "Missing option(s) for cart path 'update_promo_code'. Options: " + JSON.stringify(options);
        }
        var data = {promo_code : options.promo_code};
        return {
          path : apiUrl + '/me/cart/promo_code',
          params : {
            method : "POST",
            data : data
          }
        };
      },
      "remove_promo_code" : function(options){
        return {
          path : apiUrl + '/me/cart/promo_code',
          params : {
            method : "DELETE"
          }
        };
      },

      "complete_cart" : function(options){
        if(!checkNested(options,'cart')){
          throw "Missing option(s) for cart path 'complete_cart'. Options: " + JSON.stringify(options);
        }
        return {
          path : apiUrl + "/me/cart/checkout",
          params : {
            method : "PUT",
            data : null
          }
        };
      },

      "cart_stats": (options) => {
        const {cart_id, cart_vendor_group_id} = options?.data ?? {};
        if (!cart_id && !cart_vendor_group_id) {
          throw Error(`Missing option(s) for cart path 'cart_stats'. Options: ${JSON.stringify(options)}`);
        }
        const paramString = new URLSearchParams(cart_vendor_group_id ? {cart_vendor_group_id} : {cart_id}).toString();
        return {
          path: `${apiUrl}/me/cart/cart_stats?${paramString}`,
        }
      },

      "complete_vendor_group" : function(options){
        if(!checkNested(options,'group')){
          throw Error("Missing option(s) for cart path 'complete_vendor_group'. Options: " + JSON.stringify(options));
        }
        return {
          path : apiUrl + "/me/cart/checkout/vendor_group",
          params : {
            method : "PUT",
            data : options.group
          }
        };
      },

      //---------------------
      //Cart item endpoints
      //All of these endpoints return the cart

      //Returns vendor_group
      "create_cart_item" : function(options){
        if(!checkNested(options, 'item','product_id') || !checkNested(options, 'item','vendor_id') || !checkNested(options, 'item','quantity')){
          throw "Missing option(s) for cart item model path 'create'. Item: " + JSON.stringify(options);
        }
        return {
          path : apiUrl + "/me/cart/items",
          params : {
            method : "POST",
            data : {
              product_id: options.item.product_id,
              vendor_id: options.item.vendor_id,
              quantity: options.item.quantity
            }
          }
        };
      },
      //Returns vendor_group
      "update_cart_item" : function(options){
        if(!checkNested(options, 'item', 'id') || !checkNested(options, 'item', 'product_id') || !checkNested(options, 'item', 'vendor_id') || !checkNested(options, 'item', 'quantity')){
          throw "Missing option(s) for cart item model path 'update'. Item: " + JSON.stringify(options);
        }
        return {
          path : apiUrl + "/me/cart/product/" + options.item.product_id + "/vendor/" + options.item.vendor_id,
          params : {
            method : "PUT",
            data : {quantity: options.item.quantity}
          }
        };
      },
      //Returns vendor_group
      "remove_cart_item" : function(options){
        if(!checkNested(options, 'item', 'id') || !checkNested(options, 'item', 'product_id') || !checkNested(options, 'item', 'vendor_id')){
          throw "Missing option(s) for cart item model path 'remove'. Item: " + JSON.stringify(options);
        }
        return {
          path : apiUrl + "/me/cart/product/" + options.item.product_id + "/vendor/" + options.item.vendor_id,
          params : {
            method : "DELETE"
          }
        };
      }
    }
  };

  this.autofill = function(cart) {
    cart.promo_code = cart.promo_code || {};
    cart.promo_code.code = cart.promo_code.office_promo_code ? cart.promo_code.office_promo_code.code :
                            cart.promo_code.vendor_promo_code ? cart.promo_code.vendor_promo_code.code :
                            cart.promo_code.sowingo_promo_code ? cart.promo_code.sowingo_promo_code.code : cart.promo_code.code;
    cart.promo_code.curr_code = cart.promo_code.code; // Need to check if code has changed.

    _.map(_.toArray(cart.vendor_groups), function(currVG){
      //
      // Vendor Shipping Calculations
      //
      currVG.freeShippingPossible = (_.get(currVG, 'free_shipping_possible','').toLowerCase().trim() === 'true');

      if(!currVG.freeShippingPossible || parseFloat(currVG.items_subtotal) >= parseFloat(currVG.shipping_rate.free_shipping)) {
        currVG.freeShippingRemainder = 0.00;
      }else{
        currVG.freeShippingRemainder = parseFloat(currVG.shipping_rate.free_shipping) - parseFloat(currVG.items_subtotal);
      }

      currVG.promo_code_value_applied = parseFloat(currVG.promo_code_value_applied);
    });
    
    cart.item_total_pure = (cart.promo_code_applied ?  parseFloat(cart.items_subtotal) + parseFloat(cart.promo_code_value_applied) : cart.items_subtotal);

  };

  this.fields = {
    "id" : {
      "display_name" : "id",
      "required" : true,
      "api" : {
        "submit" : true
      },
      "type" : "integer"
    },
    "address" : {
      "fields" : {
        "province" : {

        }
      }
    }
  };

  this.data = {
    "params" : {
      "model_name" : "cart"
    }
  };

  var configWatch = $rootScope.$watch('config', function(newConfigValue, oldConfigValue){
    if(newConfigValue){
      _this.fields.address.fields.province.all_provinces = newConfigValue.current_provinces;
      _this.fields.address.fields.province.provinces_map = newConfigValue.current_provinces_map;
      configWatch();
    }
  });


});

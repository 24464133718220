(function () {
  'use strict';

  angular.module('app.shared.system')
    .run(errorServiceRun);

  function errorServiceRun($rootScope, errorService) {
    $rootScope.$on('errorService: set-global-error', function (event, error) {
      errorService.setGlobalError(error);
    });
    errorService.init();

  }
})();
(function () {
  'use strict';

  angular
    .module('app.marketplace.ui')
    .directive('navDropdownMenu', navDropdownMenu);

  function navDropdownMenu($window, NavDropdownMenuService) {
    var w = angular.element($window);

    return {
      restrict: 'C',
      scope: {
        menuName: '@',
        isOpen: '=',
        onToggle: '&',
        position: '@'
      },
      controller: function ($scope) {
        var _this = this;

        // TODO This does not make sure that the menu stays within the bounding
        //      box of the window.

        //
        // Determine where to stick the menu.
        //
        _this.repositionMenu = function () {
          var element = $scope.element;
          var menuWidth = _this.getMenuWidth();
          var parentWidth = _this.getParentWidth();
          var position = {
            top: null,
            left: null,
            right: null,
            bottom: null
          };

          if ($scope.position === 'left') {
            position.left = '-{0}px'.format(menuWidth - parentWidth);
          } else if ($scope.position === 'right') {
            position.left = 0;
          } else if ($scope.position === 'center') {
            position.left = '-{0}px'.format((menuWidth / 2) - (parentWidth / 2));
          } else {
            // $log.error('Unknown direction value: %o', $scope.direction);
            return;
          }

          element.css(position);
        };

        //
        // Return the width of the parent element (to the menu).
        //
        _this.getParentWidth = function () {
          return $scope.element.parent()[0].clientWidth;
        };

        //
        // Return the width of the menu element.
        //
        _this.getMenuWidth = function () {
          return $scope.element[0].clientWidth;
        };

        //
        // Return the width of the window viewport.
        //
        _this.getWindowWidth = function () {
          return $window.innerWidth;
        };
      },
      link: function (scope, element, attrs, ctrl) {
        NavDropdownMenuService.registerMenu(scope.menuName, scope);
        scope.element = element;
        scope.menuIsOpen = false;
        scope.position = scope.position || 'right';
        scope.$watch(ctrl.getMenuWidth, _.throttle(ctrl.repositionMenu, 10));
      }
    };
  }


}());


(function () {
  'use strict';

  angular.module('app.shared.system')
         .service('errorService', errorService);

  function errorService ($log, $rootScope, $state, sgToast) {
    /*jshint validthis: true */
    var service = this;

    service.errorHandler = errorHandler;
    service.warningHandler = warningHandler;
    service.setGlobalError = setGlobalError;
    service.uiErrorHandler = uiErrorHandler;
    service.uiWarningHandler = uiWarningHandler;
    service.showErrorToast = showErrorToast;
    service.hideErrorToast = hideErrorToast;
    service.unpackErrorResponse = unpackErrorResponse;
    service.init = init;

    return service;
    
    // 
    // Init service
    // Override console.error and console.exception
    //
    function init () {
      //Wrap console error and exception fns
      $log._error = $log.error;
      $log.error = function() {
        $rootScope.$broadcast("$log: errorTriggered", {$log: $log, arguments: arguments});
        return $log._error.apply($log, arguments);
      };
      $log._warn = $log.warn;
      $log.warn = function() {
        $rootScope.$broadcast("$log: warningTriggered", {$log: $log, arguments: arguments});
        return $log._warn.apply($log, arguments);
      };
    }

    //
    // Popup an error toast
    //
    function showErrorToast (message, delay, severity) {
      var toastOptions = sgToast.build();
      severity = severity ? severity.toLowerCase() : 'high';

      if (!delay && delay !== 0) {
        delay = 2000; // default delay
      }

      angular.extend(toastOptions._options, {
        templateUrl: 'templates/shared/support/errorToast.html',
        hideDelay: delay,
        controller: ErrorToastCtrl,
        locals: {
          message: message,
          delay: delay,
          severity: severity
        }
      });

      return sgToast.show(toastOptions);
    }

    function hideErrorToast () {
      return sgToast.hide(null);
    }

    //
    // Unpack An Error Response
    //
    function unpackErrorResponse (errorResponse) {
      if (_.isString(errorResponse)) {
        throw errorResponse;
      }

      var status = _.get(errorResponse, 'status');
      var payload = _.get(errorResponse, 'data');

      // Note: code dupe'd in apiService as well
      throw _.has(payload, 'error.message') ? _.get(payload, 'error.message')
          : status === 0 ? 'Unable to communicate with server. Please try again later.'
          : status === 500 ? 'The server encountered an error. Please try again later.'
          : status >= 502 && status <= 504 ? 'Server is unavailable. Please try again later.'
          : errorResponse;
    }

    /* @ngInject */
    function ErrorToastCtrl($scope, sgToast, message, delay, severity) {
      $scope.message = message;
      $scope.delay = delay;
      $scope.severity = severity;
      $scope.closeToast = function() {
        sgToast.hide();
      };
    }

    //
    // Set the Global Error
    //
    function setGlobalError (error) {
      $rootScope.UI = $rootScope.UI || {};
      $rootScope.UI.error = error;
    }

    //
    // Non-User Visible Error-Handling
    //
    function errorHandler (msg) { 
      if (angular.isUndefined(msg)) {
        $log.error('errorService: errorHandler: Got undefined msg!');
      } else {
        $rootScope.$broadcast("errorService: errorHandled", msg);
        $log.error(msg);
      }
      return msg;
    }
    //
    // Non-User Visible Warning-Handling
    //
    function warningHandler (msg) { 
      if (angular.isUndefined(msg)) {
        $log.error('errorService: errorHandler: Got undefined msg!');
      } else {
        $rootScope.$broadcast("errorService: warningHandled", msg);
        $log.warn(msg);
      }
      return msg;
    }

    //
    // User-Visible Error-Handling
    // @delay, delay until toast goes away. 0 will force an action by the user on the toast
    // @severity, severity of the error, will be used to determine the color of the toast
    // @stateName, if passed, it will be compared against the current state. If it has changed then error will not be shown.
    //
    function uiErrorHandler (error, delay, severity, stateName) {
      var message = error;
      var toast = null;

      if (angular.isObject(error) && error.message) {
        message = error.message;
      }
      
      if(stateName && $state.current.name !== stateName){
        return false; //Dont show error because state has changed. //TODO needs tested to make sure there are no errors blocked that should be shown.
      }else{
        toast = showErrorToast(message, delay, severity);
      }
      errorHandler(error);
      return toast;
    }
    //
    // User-Visible Warning-Handling
    // @delay, delay until toast goes away. 0 will force an action by the user on the toast
    //
    function uiWarningHandler (warning, delay, severity) {
      var message = warning;

      if (angular.isObject(warning) && warning.message) {
        message = warning.message;
      }
      
      showErrorToast(message, delay, severity);
      warningHandler(warning);
    }
  }
}());

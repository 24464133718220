angular.module('app.marketplace.ui.theme')
  .constant('SowingoThemeConfig', {
    'aliases': {
      'SowingoBlue': {
        'blue1': '400',
        'blue2': '600'
      },
      'SowingoGreen': {
        'green1': '400',
        'green2': '600'
      },
      'SowingoRed': {
        'red1': '400',
        'red2': '600'
      }
    },
    'paletteConfig': {
      'primary': {
        'default': '400',
        'hue-1': '600'
      },
      'accent': {
        'default': '400',
        'hue-1': '600'
      },
      'warn': {
        'default': '400',
        'hue-1': '600'
      }
    },
    'palettes': {
      'SowingoBlue': {
        '50': '8BC4FC',
        '100': '72B7FC',
        '200': '58AAFC',
        '300': '3F9EFC',
        '400': '398EE3',
        '500': '327EC9',
        '600': '2C6EB0',
        '700': '265E96',
        '800': '1F4E7D',
        '900': '193E63',
        'A100': '4FB2E3',
        'A200': '479EC9',
        'A400': '3E8AB0',
        'A700': '234E63',
        'contrastDefaultColor': 'light'
      },
      'SowingoGreen': {
        '50': '7CD9B7',
        '100': '66D9AF',
        '200': '50D9A7',
        '300': '3BD99F',
        '400': '33BE8C',
        '500': '2DA679',
        '600': '268C67',
        '700': '1F7354',
        '800': '185941',
        '900': '11402F',
        'A100': '47BFA7',
        'A200': '3DA691',
        'A400': '348C7B',
        'A700': '2A7364',
        'contrastDefaultColor': 'light'
      },
      'SowingoRed': {
        '50': 'F79897',
        '100': 'F7807E',
        '200': 'F76865',
        '300': 'F7504D',
        '400': 'DE4745',
        '500': 'C43F3D',
        '600': 'AB3735',
        '700': '912F2D',
        '800': '782725',
        '900': '5E1E1D',
        'A100': 'DE5D58',
        'A200': 'C45251',
        'A400': 'AB4846',
        'A700': '913D3C',
        'contrastDefaultColor': 'light'
      }
    }
  });

(function () {
  'use strict';

  angular.module('sowInventory')
  .directive('sowMedStock', sowMedicationStockDirective)
  .controller('sowMedicationStockController', sowMedicationStockController);

  function sowMedicationStockDirective () {
    return {
      restrict: 'E',
      replace: true,
      templateUrl: 'sow-inventory/directives/med-stock.html',
      controller: 'sowMedicationStockController',
      controllerAs: 'imsCtrl',
      scope: {
        'item': '=',
      },
    };
  }

  function sowMedicationStockController ($scope, $rootScope, $timeout, $filter, $stateParams, $q, $mdToast, $mdSidenav, $mdDialog, sowMedicationService, medHelperService, inventoryItemService, dataSharingService, errorService, alertsDataCarrierService, sgToast) {
    var ctrl = this;

    var undoTimeout = 5000;
    var undoHideTimeouts = {};

    ctrl.filters = {
      medication_id: null
    };
    
    ctrl.delete_single_medication = delete_single_medication;
    ctrl.editMedStock = editMedStock;
    ctrl.add_to_shopping_list = add_to_shopping_list;
    
    ctrl.addMedStockSlideout = addMedStockSlideout;
    ctrl.selectAll = selectAll;
    ctrl.selectedItems = selectedItems;
    ctrl.changeSelected = changeSelected;

    ctrl.bulkRemoveMedStock = bulkRemoveMedStock;
    ctrl.bulkDeleteMedStock = bulkDeleteMedStock;

    ctrl.clickRemove = clickRemove;
    ctrl.clickDelete = clickDelete;
    ctrl.sortOrder = 'expiry_date';

    ctrl.close_dialogs = close_dialogs;
    ctrl.close_side_nav = close_side_nav;
    ctrl.dialogs = ["med-edit-sidenav"];

    init();
    return ctrl;

    function init () {
      ctrl.years = load_years();

      $scope.$on('medication-slideout-opened', loadData);
      $scope.$on('med-stock-delete', bulkDeleteMedStock);
      $scope.$on('med-stock-remove', bulkRemoveMedStock);
      // $scope.$on('$stateChangeStart', stateChangeHandler);
    }

    function add_to_shopping_list (item) {
      return inventoryItemService.addToShoppingList([{id: item.inventory_item_id}])
      .then(function(response){
        var text = $filter('translate')('COMMON.ADDED_TO_SL');
      sgToast.showSimple(text);
      });
    }

    function bulkRemoveMedStock () {
      var items = selectedItems();
      if(!items.length)
        return;

      _.each(items, function(item){
        _.set(item, 'removing', true);
      });
      _.set($rootScope, 'bulk_removing_medstock', true);
      return batchRemoveStock(items)
      .then(function(response){
        _.each(items, function(item){
          _.set(item, 'removed', true);
          $timeout(function () {
            deleteLocalStockItem(item);
          }, 3000);
        });
        var t_message = $filter('translate')('TOAST.MED_ITEMS_REMOVED');
        sgToast.showSimple(t_message);
      }).finally(function(){
        _.set($rootScope, 'bulk_removing_medstock', false);
      });
      // var promises = _.map(items, clickRemove);

      // return $q.all(promises)
      // .then(function(responses){
      //   sgToast.showSimple('medication items removed');
      // })
      // .catch(errorService.uiErrorHandler);
    }

    function bulkDeleteMedStock () {
      var items = selectedItems();
      // TO-DO: warning dialog first
      if(!items.length)
        return;
      return deleteWarningDialog(null, items.length)
      .then(function(){
        return batchRemoveStock(items)
        .then(function(response){
          _.each(items, function(item){
            deleteLocalStockItem(item);
          });
          var t_message = $filter('translate')('TOAST.MED_ITEMS_DELETED');
          sgToast.showSimple(t_message);
        });
        // var promises = _.map(items, delete_single_medication);

        // return $q.all(promises)
        // .then(function(responses){
        //   sgToast.showSimple('medication items deleted');
        // })
        // .catch(errorService.uiErrorHandler);
      });
    }

    function loadData () {
      return get_medication_and_stock()
      .then(function(result){
        dataSharingService.is_loading = false;
        ctrl.medication = result.medication;
        dataSharingService.inventory_item_id = ctrl.medication.inventory_item_id;
        ctrl.dataSharingService = dataSharingService;
        ctrl.medication_stock = result.medication_stock;
        medHelperService.setExpiryErrors($scope.item, ctrl.medication_stock);
        $rootScope.$broadcast('item-errors-set', $scope.item);
      }).catch(function(err){
        var t_message = $filter('translate')('ERRORS.MEDS_LOAD')
        errorService.uiErrorHandler(t_message);
      });
    }

    function load_years(){
      var twenty_years_from_now = [];
      for (var index = 0; index <= 20; index++) {
        twenty_years_from_now.push(moment().year() + index);
      }
      return twenty_years_from_now;
    }

    function addMedStockSlideout (ev) {
      $rootScope.$broadcast('close-inventory-slideout');
      $rootScope.$broadcast('add-medstock-slideout', $scope.item);
    }

    function close_side_nav () {
      _.forEach(ctrl.dialogs, function(dialog){
        $mdSidenav(dialog).close();
      });
    }
    function close_dialogs(){
      close_side_nav();
      $mdDialog.cancel();
    }

    function editMedStock (item, $event) {
      $event.stopPropagation();
      $rootScope.$broadcast('close-inventory-slideout');
      $rootScope.$broadcast('edit-medstock-slideout', $scope.item, item);
    }

    function get_medication_and_stock(){
      return load_medication_stock({medication_id: $scope.item.id})
      .then(function(stock_response){
        return {
          'medication': $scope.item,
          'medication_stock': stock_response
        };
      });
    }

    //
    // User clicks the "Remove" button on a MedicationStockItem
    //
    function clickRemove (medicationStockItem) {
      var id = medicationStockItem.id;
      medicationStockItem.removing = true;
      return sowMedicationService.removeMedicationStock({id: id})
      .then(function() {
        medicationStockItem.removed = true;
        $timeout(function () {
          deleteLocalStockItem(medicationStockItem);
        }, 3000);
      })
      .catch(errorService.uiErrorHandler)
      .finally(function(){
        medicationStockItem.removing = false;
      });
    }

    function clickDelete (stock_item) {
      return deleteWarningDialog(stock_item)
      .then(function(){
        return delete_single_medication(stock_item, true);
      })
    }

    function deleteWarningDialog (stock_item, bulk_items) {
      return $mdDialog.show({
        controller: 'regularDialogController',
        controllerAs: 'dialogCtrl',
        templateUrl: 'templates/marketplace/medications/modals/delete-medication-stock.html',
        parent: angular.element(document.body),
        clickOutsideToClose: true,
        bindToController: true,
        locals:{
          medication_stock_item: stock_item,
          items_count: bulk_items
        }
      });
    }

    function open_delete_medication_stock_dialog(medication_stock_item){
      $mdDialog.show({
        controller: 'medDetailController',
        controllerAs: 'medDetailCtrl',
        templateUrl: 'templates/marketplace/medications/modals/delete-medication-stock.html',
        parent: angular.element(document.body),
        clickOutsideToClose: true,
        bindToController: true,
        locals:{
          medication_stock_item: medication_stock_item
        }
      });
    }

    function delete_single_medication(stock_item, notify){
      return sowMedicationService.removeMedicationStock(stock_item)
      .then(function(result){
        return deleteLocalStockItem(stock_item)
        .then(function(){
          if( !(notify === true) )
            return;
          var t_message = $filter('translate')('TOAST.ITEM_DELETED');
          $mdToast.show(
            $mdToast.simple()
              .textContent(t_message)
              .position("bottom right")
              .hideDelay(2000)
          );
        });
      }).catch(function (err) {
        var t_message = $filter('translate')('ERRORS.MED_DELETE')
        errorService.uiErrorHandler(t_message);
      });
    }

    function batchRemoveStock (stock_list) {
      return sowMedicationService.batchRemoveMedicationStock(stock_list)
      .catch(errorService.uiErrorHandler);
    }

    function deleteLocalStockItem (stock_item) {
      var def = $q.defer();
      var index = _.indexOf(ctrl.medication_stock, stock_item);
      if (index >= 0) {
        ctrl.medication_stock.splice(index, 1);
        $rootScope.$broadcast('medication-stock-removed', stock_item);
        def.resolve();
      }
      return def.promise;
    }

    function get_medication(filters){
      return sowMedicationService.getMedications(filters);
    }

    function load_medication_stock(filters){
      return sowMedicationService.getMedicationStock(filters);
    }

    function selectAll(){
      _.map(ctrl.medication_stock, function(item){
        if(!item.last_action){
          item.selected = ctrl.selectingAll;
          // if(ctrl.selectingAll === true){
            ctrl.changeSelected(item);
          // }
        }
      });
    }

    function changeSelected(item){
      $rootScope.$broadcast("med-stock-checkbox-clicked");
      if(item.selected === false ){
        ctrl.selectingAll = false;
      }else{
        if(ctrl.selectedItems().length === ctrl.medication_stock.length && ctrl.medication_stock.length > 0){
          ctrl.selectingAll = true;
        }
      }
    }

    function selectedItems(){
      var items = [];
      items = _.filter(ctrl.medication_stock, ['selected',true]);
      return items;
    }

  }
}());

(function () {
  'use strict';

  angular
    .module('app.shared.ui')
    .filter('packaging', packaging);

  function packaging() {
    return function(value) {
      if (!value) {
        return;
      }

      return value === 'Item' ? 'item' : 'pack';
    };
  }
}());

(function () {
  'use strict';
  angular
    .module('app.marketplace.ui.orders')
    .controller('ordersController', ordersController);
    
  function ordersController($rootScope, $state, $scope, membershipService, RouteService) {
    /*jshint validthis: true */
    var ctrl = this;

    init();

    return ctrl;

    //
    // Initialize the controller.
    //
    function init() {
      handleTopLevelRedirect();
      $scope.$on('$stateChangeSuccess', handleTopLevelRedirect);
    }

    //
    // If we are at the top-level $state (app.orders), we need to decide which
    // child $state to redirect the user to.
    //
    function handleTopLevelRedirect () {
      // We also need to ensure that the user has access to
      // the orders module and its subsidiary pages
      checkForUserAccess();

      if (!$state.is('app.orders')) {
        return;
      }
      const membership = membershipService.get();
      
      var user_permissions = _.get($rootScope, 'current_membership.user_permissions',[]);
      var office_has_flag = $rootScope.features['marketplace'] || false;

      var basic_permission = ( user_permissions.indexOf('marketplace_shopping_cart') >= 0 );
      var is_master = ( user_permissions[0] === 'master_permission' );
      const lock_condition = _.get(membership, 'user_properties.orders.disable_page', false);

      const user_has_permission = (basic_permission || is_master) && !lock_condition;
      
      if (office_has_flag && user_has_permission) {
        $state.go('app.orders.sowingo');
      } else {
        $state.go('app.orders.purchase.list');
      }
    }

    function checkForUserAccess() {
      if (ordersAccessDenied()) {
        return RouteService.goToDefault();
      };
      handleOverrides();
    }

    /** 
     * Checks user_permissions to determine whether the user
     * is forbidden from visiting app.orders at all.
     * 
     * @return {Boolean} 
    */
    function ordersAccessDenied() {
      if (!$state.includes('app.orders')) {
        return false;
      }
      const orders_permission = $rootScope.user_permissions.marketplace_orders_purchase_orders;
      const master_permission = $rootScope.user_permissions.master_permission;
      const is_access_denied = !orders_permission && !master_permission;
      return is_access_denied;
    }

    /** 
     * Checks the permission_overrides to see if the user is 
     * forbidden from visiting the specific section in question
     * and redirects them to the appropriate page if so.
     * 
     * @return {*}
    */
    function handleOverrides() {
      const membership = membershipService.get();
      const overrides = _.get(membership, 'user_properties.permission_overrides', {});
      if ($state.includes('app.orders.shopping_list')) {
        return handleShoppingListOverride(overrides);
      }
      if ($state.includes('app.orders.sowingo') || $state.includes('app.orders.awaiting-shipment')) {
        return handleMktOrdersOverride(overrides);
      }
      if ($state.includes('app.orders.purchase')) {
        return handlePurchaseOrdersOverride(overrides);
      }
    }

    function handleShoppingListOverride(overrides) {
      if (overrides.disable_shopping_list) {
        return RouteService.goToDefault();
      }
    }

    function handleMktOrdersOverride(overrides) {
      if (overrides.disable_marketplace_orders) {
        // if POs are also disabled
        return overrides.disable_purchase_orders
          // we send the user to the default screen
          ? RouteService.goToDefault()
          // otherwise we send them to the PO section
          : $state.go('app.orders.purchase');
      }
    }

    function handlePurchaseOrdersOverride(overrides) {
      if (overrides.disable_purchase_orders) {
        return RouteService.goToDefault();
      }
    }

  }
}());

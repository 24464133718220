angular.module("app.shared.support")

  //Directive to help an element dynamically stick to the top of the page after scrolling a certain distance
  //TODO evaluate efficiency and speed of directive.
  //TODO add width watch to dynamically change on width change.
  // DEPRICATED in favor of headroom.js
  .directive('jfSticky', function ($rootScope, $window, $state) {
    var mainPage = angular.element(document); //Must use this element and not the window because it does the scolling.

    //Get document scroll position and compensate for angular material's messing with the body element
    var _getDocTop = function () {
      var docTop = mainPage.scrollTop();

      var bodyEle = angular.element('body');
      var bodyTop = bodyEle.css('top');
      var bodyPosition = bodyEle.css('position');

      if (bodyPosition === 'fixed') {
        docTop = -1 * parseInt(bodyTop, 10);
      }

      return docTop;
    };

    //
    // Determine initValue from the width of the screen
    //
    function calcValue(values) {
      var width = mainPage.width();

      console.warn('THIS PROBABLY NEEDS TO TAKE THE SIDE NAVIGATION BAR INTO ACCOUNT!!!');

      //
      // If we're hiding the header, we need to pin this to the top
      //
      if (_.get($state, 'current.data.hideHeader')) {
        return 0;
      }

      if (width > 600 && (values['gt-sm'] || values['gt-sm'] === 0)) {
        return values['gt-sm'];
      } else if (width < 600 && (values['sm'] || values['sm'] === 0)) {
        return values['sm'];
      } else if (values['default'] || values['default'] === 0) {
        return values['default'];
      } else {
        return null;
      }
    }

    return {
      link: function (scope, elem, attrs, ctrl) {

        var minValues = {};
        var getMinValues = function () {
          minValues = {
            'default': attrs.jfStickyMin ? parseInt(attrs.jfStickyMin, 10) : null,
            'gt-sm': attrs.jfStickyMinGtSm ? parseInt(attrs.jfStickyMinGtSm, 10) : null,
            'sm': attrs.jfStickyMinSm ? parseInt(attrs.jfStickyMinSm, 10) : null
          };
          return minValues;
        };
        var minValue = calcValue(minValues);

        var initValues = {};
        var getInitValues = function () {
          initValues = {
            'default': attrs.jfStickyInit ? parseInt(scope.$eval(attrs.jfStickyInit), 10) : null,
            'gt-sm': attrs.jfStickyInitGtSm ? parseInt(attrs.jfStickyInitGtSm, 10) : null,
            'sm': attrs.jfStickyInitSm ? parseInt(attrs.jfStickyInitSm, 10) : null
          };
          return initValues;
        };
        var initValue = calcValue(initValues);

        scope.$watch(function () {
          return [attrs.jfStickyMin, attrs.jfStickyMinGtSm, attrs.jfStickyMinSm, scope.$eval(attrs.jfStickyInit), attrs.jfStickyInitGtSm, attrs.jfStickyInitSm];
        }, function () {
          minValue = calcValue(getMinValues());
          initValue = calcValue(getInitValues());
        }, true);


        mainPage.on("scroll", function () {
          initValue = calcValue(initValues);
          minValue = calcValue(minValues);

          if (_getDocTop() >= minValue) {
            if ((initValue || initValue === 0) && (minValue || minValue === 0)) {
              var diff = (initValue) - (_getDocTop() - minValue);
              elem.css('top', diff < minValue ? minValue : diff);
              elem.css('position', 'fixed');

              //Indicates that the element is now stuck
              if (diff < minValue) {
                elem.addClass('sticky-stuck');
              } else {
                elem.removeClass('sticky-stuck');
              }
            }
            elem.addClass('sticky');
          } else {
            //Clear classes
            elem.removeClass('sticky');
            elem.removeClass('sticky-top');
          }
          scope.$apply();
        });

        if (initValue || initValue === 0) {
          elem.css('top', initValue);
          elem.css('position', 'fixed');
        }
      },
      restrict: 'A'

    };
  });

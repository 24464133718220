(function () {
  'use strict';
  
  angular
  .module('sowReports')
  .controller('ReportSectionController', ReportSectionController);
  
  function ReportSectionController ($scope) {
    /*jshint validthis: true */
    var ctrl = this;
    
    ctrl.FEATURE_DATA = {
      'section': {
        'name': 'MAIN_MENU.REPORTS',
        'icon': {'material': "assessment", 'css': 'red' },
        
      },
      'plan': {
        'label': 'PAYWALL.PREMIUM_FEATURE',
        'css': 'premium',
      },
      'selling_points': [
        {
          'header': 'REPORTS.PW_HEADER_ONE',
          'text':'',
          'img': "styles/img/paywalls/1-reports"
        },
        {
          'header': 'REPORTS.PW_HEADER_TWO',
          'text':'REPORTS.PW_TEXT_TWO',
          'img': "styles/img/paywalls/2-minus"
        }
      ]
    };
    
    return ctrl;
  }
}());

(function () {
  'use strict';

  const sowUploadImg = {
    selector: 'sowUploadImg',
    templateUrl: 'sow-inventory/components/upload-img.html',
    controller: 'sowUploadImgCtrl',
    controllerAs: 'uImgCtrl',
    bindings: {
      item: '=',
      // imgPaths is an optional array of strings of paths where the image of this item
      // may be stored (in addition to the default paths below) and which will need the
      // new image source string when the user updates the image
      //
      // For example, if you need to include item.img_src and item.image.url_src:
      // <sow-upload-img item="item" img-paths="['img_src', 'image.img_src']"></sow-upload-img>
      imgPaths: '<?',
    },
  }

  const DEFAULT_IMAGE_PATHS = ['image_src', 'image_url_src', 'image.url_src', 'image_url'];

  // Acceptable image extensions, used for validation both in the HTML template and the JS
  const VALID_EXTENSIONS = ['jpg', 'jpeg', 'png'];

  /** @ngInject */
  class sowUploadImgCtrl {
    constructor($rootScope, $scope, sowSterilizationService, Upload) {
      this.$rootScope = $rootScope;
      this.$scope = $scope;

      this.sowSterilizationService = sowSterilizationService;

      this.Upload = Upload;
    }

    $onInit() {
      this.valid_extensions = VALID_EXTENSIONS;
      // In the HTML accept property we need to pass a string of a comma separated list of
      // the valid extensions, each prefixed with a period (eg. accept=".jpg, .jpeg, .png")
      this.valid_extensions_str = VALID_EXTENSIONS.map(extension => `.${extension}`).join(', ');

      // There are multiple places where the item's image may be stored (due to naming inconsistency) 
      // so we'll use this.image_paths to check all of them for existing images on initialization
      // and then set them all to the new image whenever the user updates it
      this.image_paths = this.getUniqueImagePaths();

      const existing_img = this.findExistingImg();
      this.updateImgSources(existing_img);
    }

    /** 
     * Returns an array of all unique paths where we may find image source URL strings
     * and which we must update whenever the user adds/updates/removes the item's image
     * 
     * @return {Array} 
    */
    getUniqueImagePaths() {
      const additional_paths = this.imgPaths || [];
      const all_paths = [...additional_paths, ...DEFAULT_IMAGE_PATHS];
      const unique_paths = new Set(all_paths);
      return [...unique_paths];
    }

    /** 
     * Finds the URL string of the item's existing image (if any). 
     * 
     * @return {String} null if no string found
    */
    findExistingImg() {
      for (const path of this.image_paths) {
        const url_string = _.get(this.item, path);
        if (typeof url_string === 'string' && url_string.length) {
          return url_string;
        }
      }
      return null;
    }

    /** 
     * Updates all of the item's image properties with a new value, then updates
     * the state of the controller accordingly. 
     * 
     * @param {String} updated_img null if we're resetting the image
     * 
     * @return {*} 
    */
    updateImgSources(updated_img) {
      this.image_src = updated_img;
      for (const path of this.image_paths) {
        _.set(this.item, path, updated_img);
      }
      // We always set url_240_box to null because we aren't necessarily storing an image with those dimensions
      this.item.image.url_240_box = null;

      if (updated_img) {
        this.img_progress = 100;
        this.img_name = _.last(updated_img.split('/'));
      } else {
        this.img_progress = -1;
        this.img_name = null;
      }
    }

    /** 
     * Sets all image fields to null. 
     * 
     * @return {*}
    */
    resetImg() {
      this.updateImgSources(null);
      this.$scope.$emit('update-file', this.item);
    }

    /** 
     * Generates a default file name for an image file. 
     * 
     * @param {String} original_name 
     * 
     * @return {String} 
    */
    getDefaultFileName(original_name) {
      const extension = _.last(original_name.split('.'));
      const date = moment()
        .utc()
        .format('inv_YYYYMMDD_HHmmss');
      return `${date}.${extension}`;
    };

    /** 
     * Generates AWS data for valid image files and calls the method which uploads the image to AWS. 
     * 
     * @param {Object} file 
     * 
     * @return {*} 
    */
    uploadImage(file) {
      this.invalid_file = false;
      if (file && file.name) {
        const extension = _.last(file.name.split('.'));
        if (!this.valid_extensions.includes(extension)) {
          this.invalid_file = true;
          this.resetImg();
          return false;
        } else {
          this.img_size = parseFloat((file.size / 1048576).toFixed(2)) + ' MB';
          this.sowSterilizationService
            .getUploadTokenByInventory(this.getDefaultFileName(file.name))
            .then(aws_data => {
              this.upload(aws_data, file);
            });
        }
      }
    };

    /** 
     * Uploads an image to AWS and updates the state accordingly. 
     * 
     * @param {Object} aws_data 
     * @param {Object} file 
     * 
     * @return {*} 
    */
    upload(aws_data, file) {
      this.$rootScope.$broadcast('inventory-upload-start');
      this.images_upload = this.Upload.upload({
        url: aws_data.aws_url,
        method: 'POST',
        transformRequest: (data, headersGetter) => {
          const headers = headersGetter();
          delete headers.Authorization;
          return data;
        },
        fields: {
          key: aws_data.key,
          AWSAccessKeyId: aws_data.AWSAccessKeyId,
          acl: aws_data.acl,
          policy: aws_data.policy,
          signature: aws_data.signature,
          'Content-Type': aws_data['Content-Type'],
          success_action_status: aws_data.success_action_status,
        },
        file,
        headers: {
          enctype: 'multipart/form-data',
          'Access-Control-Allow-Origin': '*',
          'Content-Type': undefined,
        }
      }).progress(evt => {
        setTimeout(() => {
          this.img_progress = parseInt(100.0 * evt.loaded / evt.total);
        });
      }).success(() => {
        const updated_img = aws_data.aws_url + aws_data.key;
        this.updateImgSources(updated_img);
        this.$scope.$emit('update-file', this.item);
        this.$rootScope.$broadcast('inventory-upload-end');
      }).error(() => {
        this.$rootScope.$broadcast('inventory-upload-end');
      }).xhr(xhr => {
        this.abort = () => {
          xhr.abort();
          this.img_progress = -1;
        };
      });
    };

  }

  angular.module('sowInventory')
    .controller(sowUploadImg.controller, sowUploadImgCtrl)
    .component(sowUploadImg.selector, sowUploadImg);

})();

(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.implants')
    .service('implantCarrierService', implantCarrierService)

  function implantCarrierService() {
    /*jshint validthis: true */
    var service = this;
    service.checked_items = [];

    service.total_alerts = 0;

    return this;
  }

}());

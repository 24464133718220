angular.module("app.marketplace.ui.orders-aaoms",[])
.config(function($stateProvider, $urlRouterProvider) {
  $stateProvider
    .state("app.orders.aaoms", {
      url: '/aaoms',
      templateUrl: "templates/marketplace/orders-aaoms/orders-aaoms.html",
      controller: "ordersController" ,
      data: {
        title: 'Marketplace',
        action_bar_directive: 'action-bar-orders'
      },
      redirectTo: 'app.orders.aaoms.soon' // Redirecting via $urlRouterProvider can cause template scope digest issues.
    })

    .state('app.orders.aaoms.soon', {
      url: '/soon',
      templateUrl: 'templates/marketplace/orders-aaoms/orders-soon.html'
    });
});

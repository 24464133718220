angular.module("app.shared.support")
  // Disable element on click for X ms
  .directive('rateLimit', function ($timeout) {
    return {
      restrict: 'A',
      link: function (scope, element, attrs) {
        if (attrs.rateLimit) {
          element.on('click', function () {
            attrs.$set('disabled', 'disabled');
            $timeout(function () {
              element.removeAttr('disabled');
            }, parseInt(attrs.rateLimit, 0));
          });
        }
      }
    };
  });

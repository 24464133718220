angular.module("app.shared.support")
.directive('carousel',function() {
  return {
    restrict: 'E',
    templateUrl: 'templates/shared/support/directives/carousel.html',
    transclude: true,
    scope: {
      nbCards: '=' //Number of carousel cards
    },
    link: function($scope, $element, $attributes){
      $scope.slider_ele = $element;
    },
    controller: function($scope, $state){
      $scope.slideIndex = 0;
      $scope.dotArray = _.range(0,$scope.nbCards);
      
      //Slide the view port by the number of visible cards.
      $scope.slide = function(direction){
        var slider = $scope.slider_ele.find(".carousel-inner");
        var x = 0; //Process to get the transformX value.
        
        //Assert direction and slide index
        if((direction === "left" && !$scope.slideIndex) ||
           (direction === "right" && $scope.slideIndex >= ($scope.nbCards -1))){
          return false;
        }
        
        if(direction === "left"){
          $scope.slideIndex -= 1;
          x = - ($scope.slideIndex * 100); //Math.floor to have the left most card 100% visible. Because we want to shift the view by X number of visible whole cards.
        }else if(direction === "right"){
          $scope.slideIndex++;
          x = - ($scope.slideIndex * 100);
        }

        slider.css({
          'transform': 'translateX('+x+'%)',
          '-ms-transform': 'translateX('+x+'%)',
          '-webkit-transform': 'translateX('+x+'%)'
        });
      };
    }
    
  };
});
(function () {
  "use strict";

  angular.module("app.shared.support").filter("currency-to-number", function () {
    return function (input) {
      // If the input is not a number or string, return it as is
      if (!["number", "string"].includes(typeof input)) return input;
      // Get the language from local storage, defaulting to English
      const language = JSON.parse(localStorage.getItem("SWG-settings"))?.language ?? "en";
      // Coerce the input to a string
      let res = input + "";
      // Replace commas with periods if the language is French
      if (language === "fr") res = res.replace(/,/g, ".");
      // Remove commas, whitespace, and dollar signs
      res = res.replace(/,|\s+|\$/g, "");
      // Return a number with two decimals at most
      return Number(Number(res).toFixed(2));
    };
  });

}());

angular.module("app.shared.support")
  .filter('toTitleCase', function () {
    return function (str) {
      if (!str) {
        return str;
      }
      return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    };
  });
angular.module("app.marketplace.ui.inventory")
 .controller("inventoryPrintController",
      function($scope, $timeout, elementService, $mdDialog, officeService, errorService) {

      var showedPrint = false;

      var printMe = function(){
        if (showedPrint) {
          return;
        } else {
          window.print();
          showedPrint = true;
        }
      };


     officeService.getInventory().then(function(inventory) {
       $scope.items = inventory.items;

       if ($scope.items.length > 0) {
          $timeout(printMe, 1200);
       }

     }).catch(function(error) {
       $scope.loading = false;
     });
 })


;

angular.module("app.marketplace.elements")
.service("externalOrderModel", function(apiUrl, commonApiService) {
  var _this = this;
  this.model_data = {
    "name" : "externalOrder",
    "display_name" : "Purchase Order",
    "api" : {
      "single" : function(options){
        if(!checkNested(options,'data','id')){
          throw "Missing option(s) for Purchase order model path 'single'. Options: " + JSON.stringify(options);
        }
        return commonApiService.getOfficeUrl() + "/external_orders/" + options.data.id;
      },
      "multiple" : function(options){
        return commonApiService.getOfficeUrl() + "/external_orders";
      },
      "statuses" : function(options){
        return apiUrl + "/external_order_statuses";
      },

      "delete_order_item": function (options) {
        console.debug('DEBUG: options %o', options);
        if (!options.office_id || !options.external_order_id || !options.external_order_item_id) {
          throw 'Missing option(s) for Purchase Order model path "delete_order_item."';
        }

        return {
          'path': apiUrl + '/offices/{0}/external_orders/{1}/items/{2}'.format(
              options.office_id,
              options.external_order_id,
              options.external_order_item_id
          ),
          'params': {
            'method': 'DELETE',
          },
        };
      },

      "create" : function(order){
//        if(!checkNested(options,'office_id')){
//          throw "Missing option(s) for external order model path 'create'. Options: " + JSON.stringify(options);
//        }

        order = $.extend(true, {}, order); //Deep clone to avoid issues
        if(order.items){
          for(var i=(order.items.length-1) ; i>=0 ; i--){
            //Convert source_items from searches to product_id or product_template_id
            order.items[i].product_id = order.items[i].source_item ? order.items[i].source_item.product_id : order.items[i].product_id;
            order.items[i].inventory_item_id = order.items[i].source_item ? order.items[i].source_item.id : order.items[i].inventory_item ? order.items[i].inventory_item.id : order.items[i].inventory_item_id;
            
            //Remove empty items and items that do not link to an existing inventory item.
            if(!order.items[i].id && (!order.items[i].name || !order.items[i].inventory_item_id)){
              order.items.splice(i,1);
            }
          }
        }

        return {
          path : commonApiService.getOfficeUrl() + "/external_orders",
          params : {
            method : "POST",
            data : order,
            update: 'full'
          }
        };
      },
      "update" : function(order){
        if(!checkNested(order,'id')){
          throw "Missing option(s) for Purchase order model path 'update'. Options: " + JSON.stringify(order);
        }

        order = $.extend(true, {}, order); //Deep clone to avoid issues
        if(order.items){
          for(var i=(order.items.length-1) ; i>=0 ; i--){
            //Convert source_items from searches to product_id or product_template_id
            order.items[i].product_id = order.items[i].source_item ? order.items[i].source_item.product_id : order.items[i].product_id;
            order.items[i].inventory_item_id = order.items[i].source_item ? order.items[i].source_item.id : order.items[i].inventory_item ? order.items[i].inventory_item.id : order.items[i].inventory_item_id;

            //Remove empty items and items that do not link to an existing inventory item.
            if(!order.items[i].id && (!order.items[i].name || !order.items[i].inventory_item_id)){
              order.items.splice(i,1);
            }
          }
        }

        return {
          path : commonApiService.getOfficeUrl() + "/external_orders/" + order.id,
          params : {
            method : "PUT",
            data : order,
            update: 'full'
          }
        };
      },
      "remove" : function(order){
        if(!checkNested(order,'id')){
          throw "Missing option(s) for Purchase order model path 'remove'. Options: " + JSON.stringify(order);
        }
        return {
          path : commonApiService.getOfficeUrl() + "/external_orders/" + order.id,
          params : {
            method : "DELETE",
            data : order
          }
        };
      }

    },
    'order_status_icons' : {
        'Received' : {
          'name': 'check',
          'class': 'green-icon'
        },
        'Draft' : {
          'name': 'mode_edit',
          'class': 'blue-icon'
        },
        'Placed' : {
          'name': 'local_shipping',
          'class': 'blue-icon'
        },
        'Cancelled' : {
          'name': 'not_interested',
          'class': 'red-icon'
        },
        'Backordered' : {
          'name': 'date_range',
          'class': 'blue-icon'
        }
    }

  };

  this.autofill = function(order) {
    if (order.vendor_name) {
      order.vendorSearchText = order.vendor_name; // Required to deal with md-autocomplete issue with existing values.
    }

    if (order.created_at) {
      order.created_at = new Date(order.created_at);
    }

    if (order.received_at) {
      order.received_at = new Date(order.received_at);
    }

    if (order.ordered_at) {
      order.ordered_at = new Date(order.ordered_at);
    }

    if (order.user) {
      order.user_id = order.user.id;
    }

    // Reorganizing dates for easier sorting
    order.sort_date = order.received_at || order.created_at;
  };

  this.fields = {
    "id" : {
      "display_name" : "id",
      "required" : true,
      "api" : {
        "submit" : true
      },
      "type" : "integer"
    },
    "user_id" : {
      "display_name" : "user_id",
      "required" : true,
      "api" : {
        "submit" : true
      },
      "type" : "string"
    },
    "user" : {
      "display_name" : "user",
      "required" : true,
      "api" : {
        "submit" : true
      },
      "type" : "object"
    },
    "status_id" : {
      "display_name" : "id",
      "required" : true,
      "api" : {
        "submit" : true
      },
      "type" : "integer"
    },
    "status_name" : {
      "display_name" : "id",
      "required" : true,
      "type" : "string"
    },
    "tax" : {
      "display_name" : "id",
      "required" : true,
      "api" : {
        "submit" : true
      },
      "type" : "number"
    },
    "shipping_total" : {
      "display_name" : "id",
      "required" : true,
      "api" : {
        "submit" : true
      },
      "type" : "number"
    },
    "notes" : {
      "display_name" : "id",
      "required" : true,
      "api" : {
        "submit" : true
      },
      "type" : "string"
    },
    "vendor_name" : {
      "display_name" : "id",
      "required" : true,
      "api" : {
        "submit" : true
      },
      "type" : "string"
    },
    "items" : {
      "display_name" : "id",
      "required" : true,
      "api" : {
        "submit" : true
      },
      "type" : "array"
    },
    "archived" : {
      "display_name" : "id",
      "required" : true,
      "api" : {
        "submit" : true
      },
      "type" : "boolean"
    },
    "modify_inventory" : {
      "display_name" : "id",
      "required" : true,
      "api" : {
        "submit" : true
      },
      "type" : "boolean"
    },
    "received_at" : {
      "display_name" : "received_at",
      "required" : true,
      "api" : {
        "submit" : true
      },
      "type" : "date"
    },
    "ordered_at" : {
      "display_name" : "ordered_at",
      "required" : true,
      "api" : {
        "submit" : true
      },
      "type" : "date"
    }
  };

  this.data = {
    "params" : {
      "model_name" : "externalOrder"
    }
  };

});

angular.module("app.marketplace.ui.inventory") 
.controller("inventoryActivityController",
    function($scope, $timeout, $mdDialog, $filter, $stateParams, elementService, errorService, officeService, inventoryItemService) {

  if($scope.clearSearch){
    $scope.clearSearch();
  }
  $scope.$watch(function(){
    return $stateParams.filterType;
  }, function(newFilterValue, oldVal){
    if(newFilterValue){
      $timeout(function(){
        _.map($scope.activityBundles, function(bundle){
          if(bundle.name===newFilterValue){
            $scope.currentBundle = bundle;
          }
        });
      });
    }
  });
  
  $scope.getItems = function(){
    var items = [];
    _.map(elementService.elementMaps.inventoryItem, function(item){
      items.push(item);
    });
    return items;
  };
  
  $scope.activityBundles = [
      {
        'name' : 'price',
        'display_name' : "Missing Price",
        'itemsFn' : function(){return $filter('filter')($scope.getItems(), {price:null, product_id:null});},
        'image' : 'attach_money',
        'secondary_class' : 'inventory-item-price',
        'field' : {
          'name' : 'price',
          'display_name' : 'Price',
          'icon' : 'attach_money',
          'pattern' : /^\-?\d+((\.|\,)\d+)?$/,
          'focus_next' : true
        },
        'color' : 'green-icon'
      },
      {
        'name' : 'barcode',
        'display_name' : "Missing Barcode", 
        'itemsFn' : function(){return $filter('filter')($scope.getItems(), {code:null, no_upc:'!true'});},
        'image' : 'view_week',
        'secondary_class' : 'inventory-item-barcode',
        'field' : {
          'name' : 'code',
          'icon' : 'view_week',
          'display_name' : 'Barcode',
          'no_value_fn' : function(item){
            item.code = null;
            item.no_upc = true;
          },
          'focus_next' : true
        },
        'color' : 'purple-icon'
      },
      {
        'name' : 'minimum_level',
        'display_name' : "Missing Minimum Level",
        'itemsFn' : function(){return $filter('filter')($scope.getItems(), {minimum_level:null});},
        'image' : 'shopping_basket',
        'secondary_class' : 'inventory-item-minlvl',
        'field' : {
          'name' : 'minimum_level',
          'display_name' : 'Min Level',
          'icon' : 'shopping_basket',
          'pattern' : /^\d+$/,
          'no_value_fn' : function(item){
            item.minimum_level = 0;
          },
          'focus_next' : true
        },
        'color' : 'blue-icon'
      }
  ];
  

  $scope.totalItemsToOptimize = function(){
    var totalItems = 0;
    
    _.map($scope.activityBundles, function(bundle){
      totalItems += bundle.itemsFn();
    });
    return totalItems;
  };

  var _expiringSoonErrorFilter = function(item){
    return item.errors && item.errors.expiring_soon;
  };
  var _belowMinLevelErrorFilter = function(item){
    return item.errors && item.errors.below_minimum_level;
  };
  $scope.alertBundles = [
      {
        'name' : 'expiring_soon',
        'display_name' : "Expiring soon",
        'itemsFn' : function(){return $filter('filter')($scope.getItems(), _expiringSoonErrorFilter );}
      },
      {
        'name' : 'below_min_level',
        'display_name' : "Below min level", 
        'itemsFn' : function(){return $filter('filter')($scope.getItems(), _belowMinLevelErrorFilter);}
      }
  ];
  
  $scope.activityItemCount = function(){
    var total = 0;
    _.map($scope.activityBundles, function(bundle){
      total += bundle.itemsFn().length;
    });
    return total;
  };
});

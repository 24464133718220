(function () {
    'use strict';
    angular.module('sowUserAdmin')
    .controller('UserAdminDetailController', UserAdminDetailController);

    function UserAdminDetailController ($q, $scope, $rootScope, $state, $filter, $stateParams, errorService, sgToast, membershipService, sowUserAdminService, sowUserAdminHelperService, AccessService) {
    /*jshint validthis: true */
    var ctrl = this;

    /* disable button initially until data fetched */
    ctrl.is_add_btn_disabled = true;
    ctrl.removing_office = null;
    ctrl.editing_office = null;

    ctrl.addToOfficeModal = addToOfficeModal;
    ctrl.editMembershipModal = editMembershipModal;
    ctrl.canEditUser = canEditUser;
    ctrl.removeOfficeModal = removeOfficeModal;
    ctrl.handleAdminToolsEvent = handleAdminToolsEvent;
    ctrl.handleDeleteModalEvent = handleDeleteModalEvent;
    ctrl.handleEditModalEvent = handleEditModalEvent;

    init();

    return ctrl;

    // private functions

    function init () {
      _defineLocks();
      _refreshUserList();
      $scope.$on('$destroy', exit);
      $scope.$on('user-add-to-office-modal', addToOfficeModal);
      $scope.$on('user-permissions-updated', updateUser);
      $scope.$on('user-invite-sent', init);
      $scope.$on('user-removed-from-office', updateUser);
    }

    /**
     * Loads users and offices, then applies filters in
     * order to generate the table displayed in the UI
     */
    function _refreshUserList() {
      ctrl.loading = true;
      $q.all([
        loadOffices(),
        loadUser(),
      ])
      .then(() => {
        filterUserOffices();
      })
      .finally(() => {
        ctrl.loading = false;
      });
    }

    function exit () {

    }

    // UI functions

    function addToOfficeModal (event, user) {
      return sowUserAdminHelperService.addToOfficeModal(ctrl.user);
    }

    function editMembershipModal (office) {
      if (ctrl.disable_edit_membership) return;
      if (ctrl.is_custom_flow) {
        ctrl.editing_office = office;
      } else {
        return sowUserAdminHelperService.editUserModal(ctrl.user, office);
      }
    }

    function canEditUser (mship) {
      var u_type = $rootScope.current_membership.membership_type;
      var type = mship.membership_type;
      if (type === 'master') {
        if(u_type !== 'master') {
          return false;
        }
      }
      return true;
    }

    function removeOfficeModal (office) {
      ctrl.removing_office = office;
    }

    // data functions

    function loadUser () {
      return sowUserAdminService.getUser($stateParams.userId)
      .then(function(response){
        ctrl.user = response;
      })
      .catch(goBack);

      function goBack() {
        return $state.go('app.user_admin');
      }
    }

    function loadOffices () {
      ctrl.memberships = membershipService.getAll().office;
      ctrl.admin_offices = sowUserAdminHelperService.getAdminOffices();
    }

    function updateUser (event, user_data) {
      ctrl.user = user_data;
      filterUserOffices();
    }

    function filterUserOffices () {
      var allowed_offices = [];
      _.each(ctrl.user.offices, function(office){
        if(ctrl.admin_offices.map[office.id]){
          allowed_offices.push(office);
        }
      });
      _.set(ctrl.user, 'offices', allowed_offices);
      _evaluateIfUserCanBeInvited();
    }

    /**
     * Evaluates whether the selected user can be invited to the
     * current office. They can't be added if they're already a
     * member and the custom add user flow is enabled (since it
     * enforces that the new user is added to the current office,
     * as opposed to providing a list of offices to select from)
     */
    function _evaluateIfUserCanBeInvited() {
      const is_already_member = _isUserAlreadyMember();
      ctrl.is_add_btn_disabled = ctrl.is_custom_flow && is_already_member;
    }

    /**
     * Checks if the user is already a member of the current office
     * @return {boolean}
     */
    function _isUserAlreadyMember() {
      const current_membership = membershipService.get();
      return _.some(ctrl.user?.offices, ({id}) => id === current_membership.office.id);
    }

    /**
     * Handles an event from the child component based on its payload
     * @param {object} event
     * @param {object} event.detail
     * @param {object} event.detail.addUserResponse
     * @param {string} event.detail.errorToastMessage
     * @param {boolean} event.detail.openAddUserModal
     */
    function handleAdminToolsEvent({detail: {addUserResponse, errorToastMessage, openAddUserModal}}) {
      if (addUserResponse) {
        const t_invite_sent = $filter('translate')('TOAST.INVITE_SENT');
        sgToast.showSimple(t_invite_sent);
        _refreshUserList();
      }
      if (errorToastMessage) {
        errorService.uiErrorHandler(errorToastMessage, 5000);
      }
      if (openAddUserModal) {
        $rootScope.$broadcast('user-add-to-office-modal');
      }
    }

    /**
     * Handles an event from the delete user modal based on its payload
     * @param {object} event
     * @param {object} event.detail
     * @param {unknown} event.detail.deleteUserError
     * @param {object} event.detail.deleteUserRes
     * @param {true} event.detail.hideDeleteDialog
     */
    function handleDeleteModalEvent({detail: {deleteUserError, deleteUserRes, hideDeleteDialog}}) {
      if (deleteUserError) {
        errorService.uiErrorHandler(deleteUserError, 5000);
      }
      if (deleteUserRes) {
        closeDeleteModal();
        $rootScope.$broadcast('user-permissions-updated', deleteUserRes);
        const t_user_removed = $filter('translate')('OFFICE.ADMIN.USER_REMOVED');
        sgToast.showSimple(t_user_removed);
      }
      if (hideDeleteDialog) {
        closeDeleteModal();
      }
    }

    /**
     * Handles an event from the edit permissions modal based on its payload
     * @param {object} event
     * @param {object} event.detail
     * @param {unknown} event.detail.updateUserError
     * @param {object} event.detail.updateUserRes
     * @param {true} event.detail.handleClose
     */
    function handleEditModalEvent({detail: {updateUserError, updateUserRes, handleClose}}) {
      if (updateUserError) {
        errorService.uiErrorHandler(updateUserError, 5000);
      }
      if (updateUserRes) {
        _refreshUserList();
        const t_user_updated = $filter('translate')('TOAST.USER_UPDATED');
        sgToast.showSimple(t_user_updated);
      }
      if (handleClose) {
        closeEditModal();
      }
    }

    function closeDeleteModal() {
      ctrl.removing_office = null;
    }

    function closeEditModal() {
      ctrl.editing_office = null;
    }

    function _defineLocks() {
      ctrl.disable_edit_membership = AccessService.getProperty('admin_tools.disable_edit_membership');
      ctrl.disable_remove_membership = AccessService.getProperty('admin_tools.disable_remove_membership');

      const customUri = AccessService.getProperty('admin_tools.custom_permission_type_uri');
      const customValue = AccessService.getProperty('admin_tools.custom_permission_type_value');
      ctrl.is_custom_flow = customUri || customValue;
    }
  }

}());

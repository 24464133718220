(function () {
  'use strict';

  angular.module('app.marketplace.ui').controller('TopNavigationBarCtrl', TopNavigationBarCtrl);

  function TopNavigationBarCtrl($scope, $rootScope, $state, AccountModalService, alertsDataCarrierService, sessionService, RouteService, AccessService) {
    var ctrl = this;

    const SOWINGO_APPS = Object.freeze({
      INVENTORY: 'inventory',
      PWF: 'workflow'
    });

    ctrl.hidden = false;
    ctrl.alertsDataCarrierService = alertsDataCarrierService;
    ctrl.openNewCart = openNewCart;
    ctrl.logout = logout;
    ctrl.isAppActive = isAppActive;
    ctrl.isCurrentApp = isCurrentApp;
    ctrl.shouldShowHelpMenu = shouldShowHelpMenu;
    ctrl.shouldShowAlerts = shouldShowAlerts;
    ctrl.shouldShowShoppingList = shouldShowShoppingList;
    ctrl.shouldShowCart = shouldShowCart;
    ctrl.signIn = signIn;

    $scope.login = login;

    ctrl.$onInit = init;

    return ctrl;

    function init () {
      defineLocks();
      ctrl.hidden = !!_.get($state, 'current.data.hideHeader');

      $scope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
        ctrl.hidden = !!_.get(toState, 'data.hideHeader');
      });

      $scope.$on('officeService: office-changed', defineLocks);
    }

    function login ($event) {
      AccountModalService.show('Login', $event);
    }

    function openNewCart () {
      $rootScope.$broadcast('sow-cart-open');
    }

    function signIn () {
      const params = $state.params;

      // If current page is a workflow page, redirect to the workflow redirect page
      // so that user can be redirected back to the workflow page after login is complete
      if ($state.current.name.includes('app.workflows')) {
        return $state.go('app.workflows.redirect', { 
          accessToken: params.accessToken,
          actionType: 'sign_in_view_profile',
          region: params.region,
          link: encodeURIComponent(window.location.href),
        });
      }

      return $state.go('required_login', { 
        'toState': $state.current.name, 
        'toParams': JSON.stringify(params) 
      });
    }

    function logout () {
      sessionService.logout()
      .then(function(){
        RouteService.exitApp();
      });
    }

    function defineLocks() {
      ctrl.disable_shopping_list = !AccessService.hasShoppingListAccess();

      ctrl.hideCartButton = !AccessService.hasCartAccess();
    }

    /**
     * If the menu item is a workflow menu item, return true if the current route is a workflow route.
     * Otherwise, return true if the current route is not a workflow route
     * 
     * PS: this will require a refactor as soon as we add one new "app" to the menu
     * @param menuItem - The menu item object that is being rendered.
     * @returns A boolean value.
     */
    function isAppActive (menuItem) {
      const isWorkflowMenu = menuItem.state.includes('workflow');
      const isWorkflowRoute = $state.current.name.includes('workflow');
      if (isWorkflowMenu) {
        return isWorkflowRoute;
      } else {
        return !isWorkflowRoute;
      }
    }

    /**
     * Check which "app" we're currently on, for showing/hiding elements on template for each case
     * @param appName - The name of the app you want to check.
     * @returns A boolean value.
     */
    function isCurrentApp (appName) {
      switch (appName) {
        case SOWINGO_APPS.INVENTORY:
          return isAppActive({state:'app'});
        case SOWINGO_APPS.PWF:
          return isAppActive({state:'workflow'});
        default:
          return false;
      }
    }

    function isLoggedIn () {
      return Boolean($rootScope.current_account?.id);
    }

    function shouldShowHelpMenu () {
      return isLoggedIn() && isCurrentApp(SOWINGO_APPS.INVENTORY);
    }

    function shouldShowAlerts () {
      return isLoggedIn() && isCurrentApp(SOWINGO_APPS.INVENTORY);
    }

    function shouldShowShoppingList () {
      return !ctrl.disable_shopping_list && isLoggedIn() && isCurrentApp(SOWINGO_APPS.INVENTORY);
    }

    function shouldShowCart () {
      return !ctrl.hideCartButton && isLoggedIn() && isCurrentApp(SOWINGO_APPS.INVENTORY);
    }
  }

}());

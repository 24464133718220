angular.module("app.shared.support")
  .filter('filterObjectBy', function () {
    return function (input, search) {
      if (!input) {
        return input;
      }
      if (!search) {
        return input;
      }
      // var expected = ('' + search).toLowerCase();
      var result = {};
      angular.forEach(input, function (value, key) {
        // var actual = ('' + value).toLowerCase();
        if (moment(value.month).isSame(search, 'month')) {
          result[key] = value;
        }
      });
      return result;
    };
  });
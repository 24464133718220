(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.implants')
    .controller('impSectionCtrl', impSectionController);

  function impSectionController ($scope, gettingStartedDialog, $rootScope, sowBrowserFlags) {
    /*jshint validthis: true */
    var ctrl = this;
    ctrl.impState = {};
    ctrl.FEATURE_DATA = {
      'section': {
        'name': 'IMPLANTS.PW_TITLE',
        'icon': {'svg': "styles/img/implants/implants-icon-v3.svg" },
        'css': 'syscolor',
      },
      'plan': {
        'label': 'PAYWALL.PREMIUM_PLUS_FEATURE',
        'css': 'plus',
      },
      'selling_points': [
        {
          'header': 'IMPLANTS.PW_HEADER_ONE',
          'text':'IMPLANTS.PW_TEXT_ONE',
          'img': "styles/img/paywalls/1-implant"
        },
        {
          'header': 'IMPLANTS.PW_HEADER_TWO',
          'text':'IMPLANTS.PW_TEXT_TWO',
          'img': "styles/img/paywalls/2-implant"
        },
        {
          'header': 'IMPLANTS.PW_HEADER_THREE',
          'text':'IMPLANTS.PW_TEXT_THREE',
          'img': "styles/img/paywalls/3-qr-code"
        }
      ]
    };

    ctrl.isLoading = isLoading;
    ctrl.doneLoading = doneLoading;

    init();

    return ctrl;

    function init () {
      // aaomsWelcomeDialog();
    }

    function aaomsWelcomeDialog () {
      $scope.gsdImplants = gettingStartedDialog.showImplantsDialog;
      if ($rootScope.features.aaoms_marketplace && !sowBrowserFlags.flag('implants-tutorial') ) {
        gettingStartedDialog.showImplantsDialog()
        .then(function(){
          sowBrowserFlags.flag('implants-tutorial', true);
        });
      }
    }

    function isLoading () {
      ctrl.impState.isLoading = true;
    }

    function doneLoading () {
      ctrl.impState.isLoading = false;
    }
  }
}());

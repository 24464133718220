(function () {
  'use strict';

  angular.module('app.marketplace.ui.creditCards')
         .service('creditCardService', creditCardService);

  function creditCardService ($q, officeService, elementService, stripe, addressService, skyflowService, $rootScope) {
    /*jshint validthis: true */
    var service = this;
    // basically works like an init function, but we're not setting one up on a service like this
    $rootScope.credit_cards_queue = [];

    service.getCards = getCards;
    service.removeCard = removeCard;
    service.createCardToken = createCardToken;
    service.insertCardIntoSkyflow = insertCardIntoSkyflow;
    service.updateCard = updateCard;
    service.addNewCard = addNewCard;
    service.setDefaultCard = setDefaultCard;
    service.parseCard = parseCard;
    service.addCardToQueue = addCardToQueue;
    service.processQueue = processQueue;

    return service;

    //
    // Create credit card token
    //
    function createCardToken (card_data) {
      var stripe_card_data = {
        'name': card_data.name,
        'number': card_data.number,
        'cvc': card_data.cvc,
        'exp_month': card_data.expiry.month,
        'exp_year': card_data.expiry.year,
      };

      angular.extend(stripe_card_data, addressService.convertToStripeAddress(card_data));

      var deferred = $q.defer();

      stripe.card.createToken(stripe_card_data, function (status, response) {
        if (response.error) {
          deferred.reject(response.error);
        } else {
          deferred.resolve(response);
        }
      });

      return deferred.promise;
    }
    
    //
    // Update credit card 
    //
    function updateCard (card_data) {
      var stripe_card_data = {
        'id': card_data.id,
        'name': card_data.name,
        'exp_month': card_data.expiry ? card_data.expiry.month : card_data.exp_month,
        'exp_year': card_data.expiry ? card_data.expiry.year : card_data.exp_year,
      };

      angular.extend(stripe_card_data, addressService.convertToStripeAddress(card_data));

      return officeService.updateCreditCard(card_data.id, stripe_card_data);
    }

    /** 
     * Fetches all credit cards of the current office and
     * adds a UI object to each with pertinent data. 
     * 
     * @return {Array} 
    */
    function getCards() {
      return officeService.getCreditCards()
        .then(cards => cards.map(card => parseCard(card)));
    }

    /** 
     * Parses a credit card to append a UI object.
     * 
     * @param {Object} card 
     * 
     * @return {Object} 
    */
    function parseCard(card) {
      if (card?.exp_month) {
        _.set(card, 'UI.is_expired', isCardExpired(card));
        _.set(card, 'UI.expiry_date', getExpiryDate(card));
      }
      return card;
    }

    /** 
     * Determines whether a credit card has expired already. 
     * 
     * @param {Object} card 
     * 
     * @return {Boolean} 
    */ 
     function isCardExpired(card) {
      const now = new Date();
      const current_year = now.getFullYear();
      const current_month = now.getMonth();
      if (current_year === card.exp_year) {
        return current_month >= card.exp_month;
      } else {
        return current_year > card.exp_year;
      }
    }

    /** 
     * Generates a MM/YY expiration date for a card. 
     * 
     * @param {Object} card 
     * 
     * @return {String} 
    */
    function getExpiryDate(card) {
      const month = card.exp_month.toString().padStart(2, '0');
      const year = card.exp_year.toString().slice(-2);
      return `${month}/${year}`;
    }

    //
    // Remove a card from the current office's Stripe customer account.
    //
    function removeCard (card) {
      return elementService.callEndpoint('office', {
        'endpoint': 'remove_credit_card',
        'credit_card': card,
      });
    }
    
    /** 
     * Sets the default card for the office's Stripe customer account. 
     * 
     * @param {Object} card 
     * 
     * @return {*} 
    */
    function setDefaultCard (card) {
      return elementService.callEndpoint('office', {
        'endpoint': 'set_default_credit_card',
        'credit_card': card,
      });
    }

    /**
     * Inserts a credit card into the Skyflow Vault
     *
     * @param {*} card
     * @return {*} 
     */
    async function insertCardIntoSkyflow(card) {
      try {
        return await skyflowService.insertNewCard(card);
      } catch (error) {
        throw error;
      }
    }

    /**
     * Creates a new card and saves it to the current Office
     * PS: during registration, the skyflow part is queued for later, 
     * since it requires the Office ID to happen
     *
     * @param {*} card
     * @return {*} 
     */
    async function addNewCard(card) {
      let token;
      const office_id = officeService.get()?.id;
      const address = addressService.extractAddress(card);
      try {
        token = await createCardToken(card);
        if( !_.isNil(office_id) ){
          return applySkyflowAndSave(card, token, address);
        } else {
          // this needs to be parsed after office was created
          return addCardToQueue(card, token, address);
        }
      } catch (error) {
        // One of the calls after createCardToken failed if we're
        // in the catch block and there is a token, so we delete the
        // card we just added to stripe in this case (since the DB
        // will not have a reference to it).
        if (token) {
          removeCard(token.card);
        }
        throw error;
      }
    }

    /**
     * Adds card to a local queue (in memory, will not persist) 
     * to be processed later through service.processQueue()
     *
     * @param {Object} card
     * @param {string} token
     * @param {Object} address
     * @return {*} 
     */
    function addCardToQueue (card, token, address) {
      let q_item = {card, token, address};
      $rootScope.credit_cards_queue.push(q_item);
      let deferred = $q.defer();
      deferred.resolve(q_item);
      return deferred.promise;
    }

    /**
     * Runs through each item in the credit card queue and adds them to skyflow vault
     *
     */
    function processQueue () {
      $rootScope.credit_cards_queue.forEach(q_item => {
        const skip_stripe = true;
        applySkyflowAndSave(q_item.card, q_item.token, q_item.address, skip_stripe);
      });
      $rootScope.credit_cards_queue = [];
    }

    /**
     * Adds Credit Card to Skyflow Vault & to Office Cards database in the API
     *
     * @param {Object} card
     * @param {string} token
     * @param {Object} address
     * @param {Boolean} skip_stripe
     * @return {*} 
     */
    async function applySkyflowAndSave (card, token, address, skip_stripe) {
      /* as of SPD-6971 we no longer use skyflow, but for simplicity's sake we've
      left this method with commented-out code in case we ever need it again */
      const on_fail_skyflow_cleanup = false; // make this true to reenable skyflow
      try {
        // const skyflow_id = await insertCardIntoSkyflow(card);
        const skyflow_id = null; // replace this with the line above to reenable skyflow
        token.skyflow_id = skyflow_id;
        return await officeService.storeCreditCard(token, address, on_fail_skyflow_cleanup, skip_stripe);
      } catch (error) {
        throw error
      }
    }

  }

}());

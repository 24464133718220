{
  'use strict';
  
  /** @ngInject */
  class AccessService {
    constructor (membershipService, accountService, localStorageService) {
      this.membershipService = membershipService;
      this.accountService = accountService;
      this.localStorageService = localStorageService;
    }
    
    /**
    * Fetch current user's membership info
    * @returns membership
    */
    _getMembership () {
      return this.membershipService.get();
    }
    
    /**
    * Fetch current user from the account service
    * @returns The user object
    */
    _getUser () {
      return this.accountService.get();
    }
    
    /**
    * It returns the value of the user_properties object at the path specified.
    * Returns false by default unless a default value is passed
    * @param {string} path
    * @param {unknown|undefined} default_value
    */
    getProperty (path, default_value = false) {
      const membership = this._getMembership();
      return _.get(membership, `user_properties.${path}`, default_value);
    }
    
    /**
     * Returns a boolean which indicates whether the user has either the
     * permission passed as an argument or the `master_permission`
     * @param {string} name the permission you want to check for
     * @return {boolean}
     */
    getPermission (name) {
      const membership = this._getMembership();
      const user_permissions = _.values(membership?.user_permissions);
      if (_.first(user_permissions) === 'master_permission') {
        return true;
      }
      return _.includes(user_permissions, name);
    }

    /**
    * It gets the permission override for a given name
    * @param name - The name of the permission override to get.
    * @returns The value of the property.
    */
    getOverride (name) {
      return this.getProperty(`permission_overrides.${name}`);
    }
    
    /**
    * Get the value of a flag for the current user.
    * @param name - The name of the flag.
    * @returns A boolean value.
    */
    getUserFlag (name) {
      const user = this._getUser();
      return _.get(user, `flags.${name}`, false);
    }
    
    /**
    * It returns a boolean value that indicates whether a feature flag is enabled or not
    * @param name - The name of the feature flag.
    * @returns A boolean value
    */
    getOfficeFeatureFlag (name) {
      const membership = this._getMembership();
      return _.get(membership, `office.feature_flags.${name}`, false);
    }
    
    /**
    * It returns the value of the flag with the given name.
    * @param name - The name of the flag you want to check.
    * @returns A boolean value.
    */
    getOfficeFlag (name) {
      const membership = this._getMembership();
      return _.get(membership, `office.flags.${name}`, false);
    }

    /**
     * Evaluates whether the current user has access to the shopping cart
     * @return {boolean}
     */
    hasCartAccess () {
      if (this.getOverride('disable_shopping_cart')) {
        return false;
      }
      const has_cart_permission = this.getPermission('marketplace_shopping_cart');
      const has_mkt_flag = this.getOfficeFeatureFlag('marketplace');
      return has_mkt_flag && has_cart_permission;
    }

    /**
     * Evaluates whether the current user has permission to access orders
     * @return {boolean}
     */
    hasOrdersPermission() {
      return this.getPermission('marketplace_orders_purchase_orders');
    }

    /**
     * Evaluates whether the current user has access to purchase orders
     * @return {boolean}
     */
    hasPurchaseOrdersAccess() {
      if (this.getOverride('disable_purchase_orders')) {
        return false;
      }
      const has_po_flag = this.getOfficeFeatureFlag('purchase_orders');
      return has_po_flag && this.hasOrdersPermission();
    }

    hasShoppingListAccess() {
      if (this.getOverride('disable_shopping_list')) {
        return false;
      }
      return this.getOfficeFeatureFlag('shopping_list') && this.hasOrdersPermission();
    }

    /**
     * Evaluates whether the current user has access to general inventory
     * @return {boolean}
     */
    hasGeneralInventoryAccess() {
      const has_inventory_flag = this.getOfficeFeatureFlag('inventory_full');
      const has_inventory_permission = this.getPermission('general_inventory');
      return has_inventory_flag && has_inventory_permission;
    }

    /**
     * Evaluates whether the current user has access to medications
     * @return {boolean}
     */
    hasMedicationsAccess() {
      const has_medications_flag = this.getOfficeFeatureFlag('medications');
      const has_medications_permission = this.getPermission('general_medications');
      return has_medications_flag && has_medications_permission;
    }

    /**
     * The function checks if the Algolia search version is V2
     * @returns {boolean}
     */
    hasAlgoliaV2Enabled() {
      return this.hasOfficeProperty('marketplace_algolia_search_version', 'V2');
    }

    hasSterilizationV2Enabled () {
      return this.hasOfficeProperty('sterilization_list_page_version', 'V2')
    }

    /**
      * Fetches a property of the current office (if it exists)
      * @param {string} path 
      * @return {unknown}
      */
    getOfficeProperty(path) {
      return _.get(this._getMembership(), `office.properties.${path}`);
    }

    /**
     * Gets an office property, and compares it to the value passed as an argument
     * @param {string} path
     * @param {string} property_value
     * @return {boolean}
     */
    hasOfficeProperty(path, property_value) {
      return this.getOfficeProperty(path) === property_value;
    }

    /**
     * Checks if the practice has PMS integration by retrieving the 'practice-info' from local
     * storage and returning the value of the 'hasPmsIntegration' property.
     * @returns {boolean}
     */
    hasPmsIntegration() {
      const practice_info = this.localStorageService.get('practice-info');
      return practice_info?.hasPmsIntegration;
    }
  }
  
  angular.module('sowCoreUI')
  .service('AccessService', AccessService);
}

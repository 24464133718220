(function () {
  'use strict';

  angular
  .module('sowCertifications')
  .controller('certificationRecordController', certificationRecordController);

  function certificationRecordController ($filter, $mdDialog, $window, sowCertificationService, $scope, $timeout, $q, errorService, sgToast) {
    /*jshint validthis: true */
    var ctrl = this;

    ctrl.FILES_LIMIT = 1;
    ctrl.FILE_SIZE_LIMIT = 50000000;
    ctrl.doc_files = [];
    ctrl.display_file_warning = false;
    ctrl.object_urls = [];
    ctrl.TODAY = new Date();
    ctrl.DATE_LIMIT = moment(ctrl.TODAY).add(12, 'months').toDate();
    ctrl.THREE_MONTHS_AGO = moment(ctrl.TODAY).subtract(3, 'months').toDate();
    ctrl.ten_years_ago = moment().subtract(10, 'years').toDate();

    $scope.allowDrop = ctrl.allowDrop = allowDrop;
    $scope.handleFileDrop = ctrl.handleFileDrop = handleFileDrop;
    $scope.handleNewFile = ctrl.handleNewFile = handleNewFile; 
    ctrl.cancel = cancelDialog;
    ctrl.removeFile = removeFile;
    ctrl.saveRecord = saveRecord;
    ctrl.triggerFileInput = triggerFileInput;
    ctrl.open_calendar = openCalendar

    init();

    return ctrl;

    function init () {
      ctrl.record = ctrl.record || {};

      if (ctrl.record.id && ctrl.record.document_path) {
        generateDocfiles();
      }

      $scope.$on('$destroy', function(){
        _.forEach(ctrl.object_urls, $window.URL.revokeObjectURL);
      });
    }

    function handleNewFile (file) {
      ctrl.display_file_warning = false;
      if(!file){
        return;
      }
      if(file.size > ctrl.FILE_SIZE_LIMIT){
        ctrl.display_file_warning = true;
        $timeout(function(){
          $scope.$apply();
        }, 300);
        return;
      }

      var docfile = {
        'binary': file,
        'name': file.name,
        'size': file.size,
        'display_size': getDisplayFileSize(file.size),
        'warning': (file.size > ctrl.FILE_SIZE_LIMIT),
      };

      if (file.type.startsWith('image/')) {
        var thumbnail_url = $window.URL.createObjectURL(file);
        ctrl.object_urls.push(thumbnail_url);

        _.set(docfile, 'preview', 'image');
        _.set(docfile, 'thumbnail_src', thumbnail_url);
      } else {
        _.set(docfile, 'preview', 'icon');
      }

      ctrl.doc_files.push(docfile);

      $timeout(function(){
        $scope.$apply();
        uploadFile(docfile);
      }, 700);
    }

    function handleFileDrop (event) {
      event.stopPropagation();
      event.preventDefault();
  
      var dt = event.dataTransfer;
      var files = dt.files;

      handleNewFile(files[0]);
    }

    function allowDrop ($event) {
      $event.preventDefault();
    }

    function removeFile (file) {
      var path_list = _.get(ctrl.record, 'document_path', []);
      var file_pos = path_list.indexOf(file.path);
      if ( ctrl.record.id && file.path && (file_pos >= 0) ) {
        // remove from UI, set as deleted
        // ctrl.record.document_path_deleted = _.union(ctrl.record.document_path_deleted, [file.path]);
        // ctrl.record.document_path.splice(file_pos, 1);
        sowCertificationService.pathRemove(ctrl.record, file_pos)
        .then(function(log_response){
          ctrl.record = log_response;
          generateDocfiles();
          _.remove(ctrl.doc_files, file);
        })
        .catch(function(e){
          var t_message = $filter('translate')('ERRORS.FILE_REMOVE')
          errorService.uiErrorHandler(t_message);
        });
        
      } else {
        // actually remove from S3, show toast
        sowCertificationService.docRemove(file.path)
        .then(function(){
          _.remove(ctrl.doc_files, file);
          var t_message = $filter('translate')('TOAST.FILE_REMOVED');
          sgToast.showSimple(t_message);
        })
        .catch(function(err){
          console.error(err);
        });
      }

      // _.remove(ctrl.doc_files, file);
      
      $timeout(function(){
        $scope.$apply();
      }, 700);
    }

    function uploadFile (docfile) {
      if( !docfile.binary ){
        return;
      }

      if(docfile.size < ctrl.FILE_SIZE_LIMIT){
        ctrl.uploading = true;
        return $q.when(
          sowCertificationService.docUpload(docfile, ctrl.certification),
          function(s3_response){
            ctrl.uploading = false;
            _.set(docfile, 'path', s3_response.doc_path);
            _.set(docfile, 'progress', '100%');
          },
          function(error){
            var t_message = $filter('translate')('ERRORS.DOCUMENT_UPLOAD')
            errorService.uiErrorHandler(t_message);
          },
          function(event){
          }
          );
      } else {
        var t_message = $filter('translate')('ERRORS.FILE_TOO_LARGE')
        errorService.uiErrorHandler(t_message);
      }
    }

    function generateDocfiles () {
      _.each(ctrl.record.document_path, function(docpath){
        if(docpath){
          ctrl.doc_files.push({
            'preview': 'icon',
            'path': docpath,
            'name': getUrlFilename(docpath),
          });
        }
        // ctrl.record.document_path = _.union(ctrl.record.document_path, [docfile.path]);
      });
    }

    function generateDocPaths () {
      _.each(ctrl.doc_files, function(docfile){
        ctrl.record.document_path = _.union(ctrl.record.document_path, [docfile.path]);
      });
    }

    function cancelDialog () {
      return $mdDialog.cancel();
    }

    function saveRecord () {
      // TO-DO: add doc paths 
      generateDocPaths();
      $mdDialog.hide(ctrl.record);
    }

    function triggerFileInput () {
      var input = angular.element('#file-input');
      // doing it immediately caused an infinite digest error
      $timeout(function() {
        input.click();
      }, 300);
      
    }

    function getUrlFilename (url) {
      return _.last(_.split(url, '/'));
    }

    // found this on SO =)
    function getDisplayFileSize(bytes) {
      var thresh = 1000;
      if(Math.abs(bytes) < thresh) {
          return bytes + ' B';
      }
      var units = ['kB','MB','GB','TB','PB','EB','ZB','YB'];
      var u = -1;
      do {
          bytes /= thresh;
          ++u;
      } while(Math.abs(bytes) >= thresh && u < units.length - 1);
      return bytes.toFixed(1)+' '+units[u];
    }

    /** 
     * Opens the datepicker calendar when the user clicks on the input which houses it. 
     * 
     * @param {Object} $event 
     * 
     * @event open:"md-datepicker"
     * @return {*} 
    */
    function openCalendar($event) {
      // This timeout ensures the datepicker already exists when we "click" it.
      $timeout(() => {
        const element = $($event.currentTarget)[0];
        const datepicker = $(element).siblings('md-datepicker')[0];
        $(datepicker)[0].children[0].click();
      });
    }

  }

}());
(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.implants')
    .service('impHelperService', impHelperService);

  function impHelperService (inventoryItemService, errorService, sgToast, $filter) {
    /*jshint validthis: true */
    var service = this;

    service.getToothPlacementFormats = getToothPlacementFormats;
    service.addToShoppingList = addToShoppingList;
    service.generateExpiryYears = generateExpiryYears;
    service.extractItemID = extractItemID;
    service.getMedicUsers = getMedicUsers;
    service.getMedicUserInvites = getMedicUserInvites;
    service.updateInventoryGroupOnSave = updateInventoryGroupOnSave;

    service.table_settings_functions = {
      convertToUiFormat: convertToUiFormat,
      convertToApiFormat: convertToApiFormat,
      updateSetting: updateSetting,
      changeAll: changeAll,
      toggle: toggle,
      isHalfChecked: isHalfChecked,
    };

    return service;

    function getToothPlacementFormats (country_id, format) {
      var formats_list = {
        "FDI": {
          'description': 'FDI World Dental Federation notation',
          'format_name': 'FDI',
          'upper_right': [18,17,16,15,14,13,12,11],
          'upper_left': [21,22,23,24,25,26,27,28],
          'lower_right': [48,47,46,45,44,43,42,41],
          'lower_left': [31,32,33,34,35,36,37,38],
          'baby_upper_right': [55,54,53,52,51],
          'baby_upper_left': [61,62,63,64,65],
          'baby_lower_right': [75,74,73,72,71],
          'baby_lower_left': [81,82,83,84,85],
        },
        "UNS": {
          'description': 'Universal Numbering System',
          'format_name': 'UNS',
          'upper_right': [1,2,3,4,5,6,7,8],
          'upper_left': [9,10,11,12,13,14,15,16],
          'lower_right': [32,31,30,29,28,27,26,25],
          'lower_left': [24,23,22,21,20,19,18,17],
          'baby_upper_right': ['A','B','C','D','E'],
          'baby_upper_left': ['F','G','H','I','J'],
          'baby_lower_right': ['T','S','R','Q','P'],
          'baby_lower_left': ['O','N','M','L','K'],
        },
        "PLM": {
          'description': 'Palmer Notation',
          'format_name': 'PLM',
          'upper_right': ['LT1','LT2','LT3','LT3','LT4','LT5','LT6','LT7','LT8'],
          'upper_left': ['RT1','RT2','RT3','RT3','RT4','RT5','RT6','RT7','RT8'],
          'lower_right': ['LB1','LB2','LB3','LB3','LB4','LB5','LB6','LB7','LB8'],
          'lower_left': ['RB1','RB2','RB3','RB3','RB4','RB5','RB6','RB7','RB8'],
          'baby_upper_right': ['LTA','LTB','LTC','LTD','LTE'],
          'baby_upper_left': ['RTA','RTB','RTC','RTD','RTE'],
          'baby_lower_right': ['LBA','LBB','LBC','LBD','LBE'],
          'baby_lower_left': ['RBA','RBB','RBC','RBD','RBE'],
        },
      };

      var country_defaults = {
        'AU': 'FDI',
        'CA':'FDI',
        'IR': 'FDI',
        'TT': 'FDI',
        'UK': 'FDI',
        'US':'UNS',
        'default': 'FDI'
      };
      if (format) {
        return formats_list[format];
      }
      if (country_id) {
        var format = formats_list[country_defaults[country_id]];
        if (!format) {
          format = formats_list[country_defaults['default']];
        }
        return format;
      }
      return formats_list;
    }

    function addToShoppingList (implant_group) {
      // items = items || $scope.checkedItems;
      // var itemCount = _.get(items, 'length');

      return inventoryItemService.addToShoppingList([{id: implant_group.inventory_item_id}])
      .then(function (items) {
        var t_added = $filter('translate')('COMMON.ADDED_TO_SL');
        var message = '{0} {1}'.format(implant_group.catalog_item.category, t_added)
        sgToast.showSimple(message);
        // clearCheckedItems();
        // return items;
      }).catch(function (error) {
        errorService.uiErrorHandler(error);
      });
    }

    function generateExpiryYears () {
      var years = [];
      var current_year = moment().format("YYYY") * 1;
      for (var i = 0; i <= 20; i++) {
        years[i] = current_year + i;
      }
      return years;
    }

    function convertToUiFormat (table_settings) {
      var local_settings = [];
      var tabs = Object.keys(table_settings.settings);

      _.forEach(tabs, function(setting_name){
        var tab = {
          name: setting_name,
          children: [],
          type: 'tab',
        };
        var tab_obj = _.get(table_settings, "settings['{0}']".format(setting_name), null);
        var tab_obj_hand = _.get(table_settings, "on_hand['{0}']".format(setting_name), null);
        var children = Object.keys(tab_obj);

        _.forEach(children, function(child_name){
          var child_obj = _.get(tab_obj, child_name);
          var child_obj_hand = _.get(tab_obj_hand, child_name);
          var grandchildren = Object.keys(child_obj);

          var child_setting = {
            name: child_name,
          };
          if(_.get(grandchildren,"length",0) > 0){
            var bool_gc = true;

            _.extend(child_setting, {
              type: "child_tab",
              children: [],
              on_hand: 0,
              locked: false,
              value: false,
              open: false,
            });

            _.forEach(grandchildren, function(gc_name){
              var gc_obj = _.get(child_obj, gc_name);
              var gc_obj_hand = _.get(child_obj_hand, gc_name);

              var gc_setting = {
                name: gc_name,
                value: gc_obj,
                on_hand: gc_obj_hand,
                locked: (gc_obj && gc_obj_hand*1 > 0),
                type: "size",
              };

              if(gc_setting.value === false){
                bool_gc = false;
              }
              child_setting.on_hand += gc_setting.on_hand;
              if(gc_setting.locked === true){
                child_setting.locked = true;
              }

              child_setting.children.push(gc_setting);
            });

            // in case all children settings are selected, mark this as selected as well
            child_setting.value = bool_gc;

          } else {
            _.extend(child_setting, {
              type: "size",
              value: child_obj,
              on_hand: child_obj_hand,
              locked: (child_obj && child_obj_hand*1 > 0),
            });
          }

          tab.children.push(child_setting);
        });

        local_settings.push(tab);
      });
      return local_settings;
      // ctrl.local_settings now has an array of tab settings, usable on the template
    }

    function convertToApiFormat (local_settings) {
      var original_settings = {};
      _.forEach(local_settings, function(setting){

        var setting_obj = {};

        _.forEach(setting.children, function(child){

          var child_obj = {};

          if(child.type === 'size'){
            // for 1-level tables
            child_obj = child.value;
          } else {
            // for tables with child tabs
            _.forEach(child.children, function(gc){
              // _.set(child_obj, gc.name, gc.value);
              child_obj[gc.name] = gc.value;
            });
          }
          // _.set(setting_obj, child.name, child_obj);
          setting_obj[child.name] = child_obj;

        });

        // _.set(original_settings, setting.name, setting_obj);
        original_settings[setting.name] = setting_obj;
      });

      return original_settings;
    }

    function updateSetting (parent, child, ctrl) {
      ctrl.changes_made = true;
      var all_selected = true;
      var some_selected = false;
      _.forEach(parent.children, function(child_setting){
        if(child_setting.value === false){
          all_selected = false;
        } else {
          some_selected = true;
        }
      });
      // parent.value = all_selected;
      parent.value = some_selected;
    }

    function changeAll (setting, ctrl) {
      ctrl.changes_made = true;
      if(setting.children && _.size(setting.children) > 0){
        _.forEach(setting.children, function(child){
          child.value = setting.value;
        });
      }
    }

    function toggle (setting) {
      setting.open = !setting.open;
    }

    function isHalfChecked (setting) {
      if(!_.size(setting.children)){
        return false;
      }
      var selected_children = 0;
      _.each(setting.children, function(child){
        if(child.value === true){
          selected_children++;
        }
      });
      return _.size(setting.children) > 0 && (selected_children < _.size(setting.children));
    }

    /**
     * > This function takes an item as an argument and returns the item with the id property set to
     * the first element of the id array.
     * We use this following a remove/delete event because the API returns an array with one string,
     * which is the ID of the removed or deleted item and is used to update the UI accordingly.
     * @param item - The item to be extracted.
     * @returns The item with an ID as string.
     */
    function extractItemID(item) {
      if (_.isArray(item?.id)) {
        item.id = item.id[0];
      }
      return item;
    }

    /**
     * If a user is active and either a medic or a master, they are shown in the list.
     * @param users - the list of users to filter
     * @returns An array of users that are active and either a medic or a master.
     */
    function getMedicUsers (users) {
      const filtered_list = _.filter(users, function(user){
        // list of accepted medical titles
        const medic_titles = ['Dentist', 'Hygienist']
        // first check is for active-only users, no matter if they're master or not
        const user_is_active = _.get(user, 'is_active', false);
        // second check is for a medic title (Dentist / Hygienist)
        const user_is_medic = medic_titles.includes(user.title);
        // third check is for master type & (medic title or empty title) (this will override the 2nd)
        // user.title === null is a legacy check because older accounts might not have titles
        const user_is_master = (user.membership_type === "master") && (user_is_medic || user.title === null);
        
        // so if a user is both active and (a medic OR a master), they are shown in the list
        return ( user_is_active && ( user_is_medic || user_is_master) );
      });
      return filtered_list;
    }


    /**
     * Get all the invites that are valid and also medic users (dentist, master, rules defined in function above)
     * @param invites - the list of invites
     * @returns An array of invites that are valid and either a medic or a master.
     */
    function getMedicUserInvites (invites) {
      const valid_invites = _.filter(invites, function(invite){
        const is_invite_valid = moment().isSameOrBefore(moment(invite.expires_at));
        return is_invite_valid;
      });

      const filtered_list = getMedicUsers(valid_invites);

      return filtered_list;
    }

    /**
     * Updates the notes, desired level, and minimum level of an inventory group
     * @param {object} inventory_group
     * @param {object} saved_group
     */
    function updateInventoryGroupOnSave (inventory_group, saved_group) {
      const { desired_level, minimum_level, notes, id } = saved_group;
      const updated_group = _.find(inventory_group, {id});
      _.set(updated_group, 'notes', notes);
      _.set(updated_group, 'desired_level', desired_level);
      _.set(updated_group, 'minimum_level', minimum_level);
    }
  }
 }());

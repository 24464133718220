(function() {
  'use strict';
  
  String.prototype.splice = function(idx, rem, s) {
    return (this.slice(0, idx) + s + this.slice(idx + Math.abs(rem)));
  };
  
  angular
  .module('sowBudget', ['chart.js'])
  .config(ConfigureState);

  function ConfigureState ($stateProvider, $urlRouterProvider) {
    $urlRouterProvider.when('/app/budget', '/app/budget/budget-manager');
    
    $stateProvider.state('app.budget', {
      url: '/budget',
      controller: '',
      controllerAs: '',
      template: '<ui-view></ui-view>',
      data: {
        'require_log_in': true,
        'action_bar_directive': 'budget-action-bar',
      }
    })
    .state('app.budget.budget-manager', {
      url: '/budget-manager',
      // controller: 'BudgetController',
      controllerAs: 'BudgetCtrl',
      template: '<budget></budget>',
      // templateUrl: 'sow-budget/views/budget-manager.html',
      data: {
        'require_log_in': true,
        'action_bar_directive': 'budget-action-bar',
      }
    })
    .state('app.budget.budget-detail', {
      url: '/budget-detail?year',
      controller: 'BudgetDetailController',
      controllerAs: 'BudgetCtrl',
      templateUrl: 'sow-budget/views/budget-detail.html',
      data: {
        'title': 'Budget Detail',
        'super_title': 'Budget',
        'super_state': 'app.budget'
      }
    })
  }
}());

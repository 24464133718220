(function () {
  'use strict';

  angular
    .module('sowMarketplace')
    .service('sowMktService', sowMktService)
    .config(registerEventsMkt);

  function registerEventsMkt (appEventsProvider) {
    appEventsProvider.registerEvent('mktProductUpdate', 'sowMktService: product-update');
  }

  function sowMktService($filter, appEvents, $rootScope, serverAPI, apiUrl, SessionStorageService, $q, membershipService, sgToast, errorService, officeService, sowAnalyticsService) {
    /*jshint validthis: true */
    var service = this;

    service.getCategories = getCategories;
    service.getManufacturers = getManufacturers;
    service.getAds = getAds;
    service.searchQuery = searchQuery;
    service.fetchProduct = fetchProduct;
    service.fetchUrlProduct = fetchUrlProduct;
    service.fetchInventoryInfo = fetchInventoryInfo;
    service.fetchProductOrderInfo = fetchProductOrderInfo;
    service.getRelatedProducts = getRelatedProducts;
    service.getVendorDiscounts = getVendorDiscounts;
    service.toggleFavourite = toggleFavourite;
    service.storefrontQuery = storefrontQuery;
    service.getAuthUsers = getAuthUsers;
    service.sendAuthData = sendAuthData;

    return service;

    /**
     * Returns a promise that resolves to the result of a GET request to the url
     * `/marketplace_landing/categories` if the `storefront_order` parameter is true,
     * or to `/categories` if it's false. Will use cached data by default (if it exists)
     * and this can be overridden using the force_api param.
     * @param {boolean | undefined} storefront_order  - determines which endpoint to use
     * @param {boolean | undefined} force_api - whether to ignore cached data and call API
     * @returns A promise that will resolve to the data returned from the API call or cache
     */
    function getCategories (storefront_order = false, force_api = false) {
      var url = storefront_order ? '/marketplace_landing/categories' : '/categories';
      var filters = {};
      return _getUrl(url)
      .then(function(url){
        var params = {'method': 'GET', 'params': filters};
        return force_api ? _callAPI(url, params) : _callCacheAPI(url, params);
      });
    }

    function getManufacturers (storefront_order) {
      var url = storefront_order ? '/marketplace_landing/manufacturers' : '/manufacturers';
      return _getUrl(url)
      .then(function(url){
        var params = {'method': 'GET'};
        return _callCacheAPI(url, params);
      });
    }

    function getAuthUsers () {
      var url = apiUrl+'/me/cart/authorization';
      var filters = {};
      var params = {'method': 'GET', 'params': filters};
      return _callAPI(url, params);
    }

    function sendAuthData (auth_data) {
      var url = apiUrl+'/me/cart/authorization';
      var options = {'method': 'POST', 'data': auth_data};
      return _callAPI(url, options);
    }

    // GET Params:
    // `query` - elasticsearch query, e.g. "gloves"
    // 'tag' - return only products with tag
    // `category_id` - search only a specific category
    // `manufacturer_id`
    // `subcategory_id`
    function searchQuery (params, body, custom_url) {
      var this_url = custom_url || '/marketplace_products';
      if(!params.page){
        var page = _.get(body, 'page', 0);
        params.page = ++page;
      }
      body = _.omit(body, ['page']);
      return _getUrl(this_url)
      .then(function(url){
        var options = {'method': 'POST', 'params': params, 'data': body};
        return _callAPI(url, options);
      });
    }

    // dedicated function for marketplace landing lists
    // `key` parameter can be one of the following:
    // - recently_purchased
    // - frequently_purchased
    // - most_popular_products
    // - vendor_promotions
    function storefrontQuery (key) {
      var url = "/marketplace_landing/{0}".format(key);
      return searchQuery({}, {}, url);
    }

    function fetchProduct (id) {
      var query = {
        page: 1,
        product_id: id
      };

      return _getUrl('/marketplace_products')
      .then(function(url){
        var params = {'method': 'POST', 'params': query};
        return _callAPI(url, params);
      });
    }

    /**
     * Fetches a product from the marketplace and then fetches info about the corresponding
     * inventory item (if one exists and the include_inventory_info flag is true).
     * @param {string} url_name - the url_name of the product to fetch
     * @param {boolean} include_inventory_info - whether or not to add inventory info to the product
     * @returns a promise that resolves to an object with a single key, `products`, whose value is an
     * array of objects. The first item in the array is the product which matches the url name, and it
     * will include inventory information if the include_inventory_info argument was truthy.
     */
    function fetchUrlProduct(url_name, include_inventory_info = false) {
      const params = {
        page: 1,
        url_name,
      };

      return _getUrl('/marketplace_products')
        .then(mkt_url => {
          const mkt_model = {
            method: 'POST',
            params,
          };
          const mkt_product_promise = _callAPI(mkt_url, mkt_model);

          // if the include_inventory_info info flag is falsy, return the product call
          if (!include_inventory_info) {
            return mkt_product_promise;
          }

          // otherwise fetch info about the corresponding inventory item (if one exists)
          // and then update the product with the inventory info before returning it
          const inv_info_promise = fetchInventoryInfo({ url_name });
          const item_promises = [
            mkt_product_promise,
            inv_info_promise,
          ];
          return Promise.allSettled(item_promises)
            .then(([mkt_product_res, inv_info_res]) => {
              const inv_info = inv_info_res.value || {};
              const mkt_product_obj = mkt_product_res.value;
              const mkt_product = mkt_product_obj?.products?.[0] || {};
              for (const key in inv_info) {
                mkt_product[key] = inv_info[key];
              }
              return new Promise(resolve => {
                resolve(mkt_product_obj);
              });
            });
        });
    }

    /**
     * Takes a params object with either a product_id or url_name of a
     * marketplace item and returns a promise that resolves to an object
     * containing information related to said marketplace item's corresponding
     * inventory, medications, and implants items (if any)
     * @param {object} params - includes either product_id or url_name
     * @returns A promise that resolves to an object
     */
    function fetchInventoryInfo(params) {
      const office_id = officeService.get().id;
      const url = `${apiUrl}/offices/${office_id}/inventory/product_detail_search`;
      const model = {
        method: 'GET',
        params,
      }
      return _callAPI(url, model);
    }

    /**
     * Fetches all marketplace orders and purchase orders which include an
     * inventory/medications/implants item that's linked to the marketplace
     * product with the product_id in the params
     * @param {object} params - includes a product_id
     * @returns A promise that resolves to an object.
     */
    function fetchProductOrderInfo(params) {
      const office_id = officeService.get().id;
      const url = `${apiUrl}/offices/${office_id}/order_product_status/search`;
      const model = {
        method: 'GET',
        params,
      }
      return _callAPI(url, model);
    }

    function getRelatedProducts (id) {
      var query = {
        page: 1,
        product_id: id,
        related_products: true
      };

      return _getUrl('/marketplace_products')
      .then(function(url){
        var params = {'method': 'POST', 'params': query};
        return _callAPI(url, params);
      });
    }

    /**
     * A simple convenience wrapper around the call to send the request to the
     * API. It makes the functions simple than having this code block
     * duplicated everywhere.
     */
    function _callAPI (url, params) {
      return serverAPI
        .doAPICall(url, params)
        .then(function (response) {
          return response.data;
        })
        .catch(errorService.uiErrorHandler);
    }

    function _callCacheAPI (url, params) {
      var request_hash = hash( JSON.stringify({'url':url,'params':params}) );
      
      var existing_data = SessionStorageService.get(request_hash);
      if(existing_data) {
        var deferred = $q.defer();
        deferred.resolve(existing_data);
        return deferred.promise;
      }

      return serverAPI
        .doAPICall(url, params)
        .then(function (response) {
          try {
            SessionStorageService.set(request_hash, response.data);
          } catch (error) {
            SessionStorageService.clear();
          }
          return response.data;
        });
    }

    /**
     * Generate a full API url. Since the prefix of these urls requires extra
     * work (pulling in and inserting the office id), it makes sense for us to
     * centralize that bit.
     */
    function _getUrl (path) {
      path = path || '';
      // return sessionService.onInit()
      // .then(function(session_data){
      //   return '{0}/marketplaces/{1}{2}'.format(apiUrl, session_data.current_membership.office.id, path);
      // });
      return membershipService.onInit()
      .then(function(membership_data){
        return '{0}/marketplaces/{1}{2}'.format(apiUrl, membership_data.office.id, path);
      });
    }

    function getVendorDiscounts () {
      return _getUrl('/vendor_discounts')
      .then(function(url){
        var params = {'method': 'GET'};
        return _callAPI(url, params);
      });
    }

    function getAds () {
      return _getUrl('/marketplace_landing/banner_ads')
      .then(function(url){
        var params = {'method': 'GET'};
        return _callAPI(url, params);
      });
    }

    function hash (s) {
        /* Simple hash function. */
        var a = 1, c = 0, h, o;
        if (s) {
            a = 0;
            /*jshint plusplus:false bitwise:false*/
            for (h = s.length - 1; h >= 0; h--) {
                o = s.charCodeAt(h);
                a = (a<<6&268435455) + o + (o<<14);
                c = a & 266338304;
                a = c!==0?a^c>>21:a;
            }
        }
        return String(a);
    }

    function postFavourite (product) {
      return _getUrl('/marketplace_favourites')
      .then(function(url){
        var options = {'method': 'POST', 'data': {'product_ids': product.id}};
        return _callAPI(url, options)
        .then(function(response){
          var t_message = $filter('translate')('TOAST.ADDED_TO_FAVOURITES');
          sgToast.showSimple(t_message);
          var updated_product = _.extend(product, {'is_favourite_product': response});
          $rootScope.$broadcast(appEvents.mktProductUpdate, updated_product);
          sowAnalyticsService.logToggleFavourite('add_to_wishlist', updated_product);
          return updated_product;
        });
      });
    }

    function deleteFavourite (product) {
      return _getUrl('/marketplace_favourites')
      .then(function(url){
        var options = {'method': 'DELETE', 'headers': {'Content-Type' : 'application/json;charset=UTF-8'}, 'data': {'product_ids': product.id}};
        return _callAPI(url, options)
        .then(function(response){
          var t_message = $filter('translate')('TOAST.REMOVED_FROM_FAVOURITES');
          sgToast.showSimple(t_message);
          var updated_product = _.extend(product, {'is_favourite_product': response});
          $rootScope.$broadcast(appEvents.mktProductUpdate, updated_product);
          sowAnalyticsService.logToggleFavourite('remove_from_wishlist', updated_product);
          return updated_product;
        });
      });
    }

    function toggleFavourite (product) {
      if(product.is_favourite_product){
        return deleteFavourite(product);
      } else {
        return postFavourite(product);
      }
    }

  }

}());

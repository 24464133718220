(function () {
  'use strict';

  angular
    .module('sowPaywall', [])
    .controller('sowPaywallController', PaywallController)
    .directive('sowPaywall', PaywallDirective);

  function PaywallDirective ($compile) {
    return {
      'replace': true,
      'restrict': 'E',
      'templateUrl': 'sow-paywall/paywall-template.html',
      'controller': 'sowPaywallController',
      'controllerAs': 'pwCtrl',
      'bindToController': {
        'feature': '=',
      },
    };
  }

  function PaywallController ($attrs, $state ) {
    /*jshint validthis: true */
    var ctrl = this;

    ctrl.goToUpgradePlan = goToUpgradePlan;
    ctrl.goToCalendly = goToCalendly;
    
    init();

    return ctrl;

    function init () {
      
    }

    function goToUpgradePlan () {
      $state.go('app.office.plan');
    }

    function goToCalendly () {
      var url = "https://calendly.com/sowingosales/consultation";
      var tab = window.open(url, '_blank');
      tab.focus();
    }
    
  }
}());

(function () {
  'use strict';

  angular
    .module('app.shared.support')
    .directive('ngRightClick', ngRightClick);

  function ngRightClick ($parse) {
    return function(scope, element, attrs) {
      var fn = $parse(attrs.ngRightClick);
      element.on('contextmenu', function (event) {
        scope.$apply(function() {
          event.preventDefault();
          fn(scope, {$event: event});
        });
      });
    };
  }
}());

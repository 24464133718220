angular.module('app.marketplace.ui.inventory')

.directive('inventoryTrackingForm', function() {
  return {
    restrict: 'E',
    controllerAs: 'ctrl',
    templateUrl: 'templates/marketplace/inventory/directives/inventory-tracking-form.html',
    scope: true,
    bindToController: {
      context: '@',
      itemClone: '=',
      form: '=',
      action: '@'
    },
    controller: function($mdDialog, membershipService) {
      var ctrl = this;

      ctrl.allowTrackingByCase = allowTrackingByCase;
      ctrl.clearBarCode = clearBarCode;
      
      ctrl.showScanAndLink = showScanAndLink;
      ctrl.$onInit = () => {
        defineLocks();
      }

      ctrl.$onInit = function init () {
        ctrl.isCreate = ctrl.action === 'create';
        ctrl.isMedication = ctrl.context !== 'product';
        ctrl.itemClone.tracking_method = ctrl.itemClone.tracking_method || 'Item';
      }

      return ctrl;

      //////////////

      function clearBarCode() {
        ctrl.itemClone.code = null;
      }

      function showScanAndLink() {
        $mdDialog.show({
          template: '<inventory-scan-link context="' + ctrl.context + '"></inventory-scan-link>',
          parent: angular.element(document.body),
          clickOutsideToClose:true
        });
      }

      function allowTrackingByCase() {
        return !ctrl.isMedication && ctrl.itemClone.buy_by_case;
      }

      function defineLocks() {
        const membership = membershipService.get();
        
        const edit_min_level_condition = _.get(membership, "user_properties.medications.disable_min_level", false);
        ctrl.disabled_edit_minimum_level = ctrl.isMedication && edit_min_level_condition;

        const edit_desired_level_condition = _.get(membership, "user_properties.medications.disable_desired_level", false);
        ctrl.disabled_edit_desired_level = ctrl.isMedication && edit_desired_level_condition;
      }
    }
  };
});



(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.medications')
    .controller('medAlertsController', medAlertsController);

  function medAlertsController($rootScope, $filter, $stateParams, sgToast, $mdToast, $mdDialog, $q, sowMedicationService, inventoryItemService, dataSharingService, errorService, alertsDataCarrierService, AccessService) {
    /*jshint validthis: true */
    var ctrl = this;

    ctrl.removeAlert = removeAlert;
    ctrl.delete_medication = delete_medication;
    ctrl.cancel = cancel;
    ctrl.shopToast = shopToast;
    ctrl.check_item = check_item;
    ctrl.dataSharingService = dataSharingService;
    ctrl.showProductDetails = showProductDetails;

    ctrl.filters = {
      expiring: null,
      minimum: null
    };

    ctrl.$onInit = init;

    return ctrl;

    function init () {
      defineLocks();

      dataSharingService.is_loading = true;
      ctrl.is_loading = true;
      ctrl.active_tab = $stateParams.filter === "minimum" ? "minimum" : "expired" ;
      ctrl.filters.expiring = ctrl.active_tab === "expired" ? true: false;
      ctrl.expiring = {};
      ctrl.minimum = {};
      load_alerts();
      // mockupData();
    }

    function check_item (medication) {
      medication.checked = !medication.checked;
      if(medication.checked){
        ctrl.dataSharingService.checked_items.push(medication);
      }else{
        _.each(ctrl.dataSharingService.checked_items, function(checked_item, i){
          if(checked_item.id === medication.id){
            ctrl.dataSharingService.checked_items.splice(i, 1);
          }
        });
      }
    }

    function load_alerts(){
      ctrl.sortOrder = "expiry_date";
      sowMedicationService.getMedicationAlerts()
        .then(function(alerts){
          dataSharingService.alert_count = alerts.expiring.length + alerts.minimum_level.length;
          parse_alerts(alerts);
          dataSharingService.is_loading = false;
        }).catch(function(err){
          var t_message = $filter('translate')('ERRORS.ALERTS_LOAD')
          errorService.uiErrorHandler(t_message);
        });
    }

    function parse_alerts(alerts){
      var expired_or_expiring_ids = _.map(alerts.expiring, function(alert){
        return alert.medication_id;
      });

      var emergency_items = [];
      var regular_items = [];
      var t_in = $filter('translate')('COMMON.IN');
      var t_from = $filter('translate')('COMMON.FROM');
      var t_and = $filter('translate')('COMMON.AND');
      _.each(alerts.minimum_level, function(medication){

        if(! angular.equals({}, medication.po_status)){
          medication.purchase_order = true;
          var tooltip = '';
          var tooltip_text = '';
          var keys = Object.keys(medication.po_status);
          if (keys.includes('Received')) {
            keys.splice(keys.indexOf('Received'),1);
          }
          var po_array = [];
          _.each(keys, function(key){
            po_array = po_array.concat(medication.po_status[key]);
          });

          var poText = _.map(po_array, function (item) {
            return !item.po_supplier ? item.po_name
              : '{0} {1} {2}'.format(item.po_name, t_from, item.po_supplier);
          });

          tooltip = '{0} {1}'.format(t_in, _.compact([
            poText.slice(0, -1).join(',\n'),
            poText.slice(-1),
          ]).join('\n{0} '.format(t_and)));
          medication.tooltip = tooltip;

          if(keys.indexOf("Active") > -1){
            medication.tooltip_text = $filter('translate')('ORDERS.ACTIVE');
            medication.class = 'active';
          } else if(keys.indexOf("Draft") > -1){
            medication.tooltip_text = $filter('translate')('ORDERS.DRAFT_ORDER');
            medication.class = 'draft';
          } else {
            medication.purchase_order = false;
          }
        }

        if(medication.is_emergency){
            emergency_items.push(medication);
          } else {
            regular_items.push(medication);
          }
      });

      ctrl.minimum = {
        emergency_items: emergency_items,
        regular_items: regular_items
      };



      load_medications(expired_or_expiring_ids)
        .then(function(medications){
          dataSharingService.is_loading = false;

          var emergency_items = [];
          var regular_items = [];
          _.each(alerts.expiring, function(stock_item){

            stock_item.medication = _.find(medications, { 'id': stock_item.medication_id});
            var now = moment();
            var expiry_date = moment(stock_item.expiry_date);
            if(expiry_date.isSameOrBefore(now)){
              stock_item.expired = true;
              var days_past = now.diff(expiry_date, 'days');
              if(days_past < 30){
                stock_item.time_past = days_past + " days ago";
              } else {
                stock_item.time_past = expiry_date.fromNow();
              }
              stock_item.alert_level = "red";
            } else {
              var days_left = expiry_date.diff(now, 'days');
              stock_item.expired = false;
              if(days_left < 30){
                stock_item.time_left = "in " + days_left + " days";
                // stock_item.alert_level = "red";
              } else {
                stock_item.time_left = expiry_date.fromNow();
                // stock_item.alert_level = "orange";
              }
              stock_item.alert_level = "orange";
            }

            if(_.get(stock_item, 'medication.is_emergency', null)){
              emergency_items.push(stock_item);
            } else {
              regular_items.push(stock_item);
            }

          });

          ctrl.expiring = {
            emergency_items: emergency_items,
            regular_items: regular_items
          };

          ctrl.is_loading = false;


        }).catch(function (error){
          ctrl.is_loading = false;
          var t_message = $filter('translate')('ERRORS.MEDS_LOAD')
          errorService.uiErrorHandler(t_message);
        });

    }

    function load_medications(ids){
      return sowMedicationService.getMedications({id:_.uniq(ids)});
    }


    function cancel(){
      $mdDialog.cancel();
    }

    function delete_medication(collection, item){

      var promises = [sowMedicationService.removeMedicationStock({id: item.id}) ];

      if (item.add_to_shopping_list){
        promises.push(inventoryItemService.addToShoppingList([{id: item.medication.inventory_item_id}]));
      }

      $q.all(promises)
        .then(function(result){
          var removed = _.remove(collection, function(element) {
            return element.id === item.id;
          });
          alertsDataCarrierService.load_alerts();
          var t_message = $filter('translate')('TOAST.MED_DELETED');
          $mdToast.show(
            $mdToast.simple()
              .textContent(t_message)
              .position("bottom right")
              .hideDelay(2000)
          );

        });

      $mdDialog.hide();
    }

    function showProductDetails (item) {
      $rootScope.$broadcast('mkt-details-open-fetch', item.product_id);
    }

    function removeAlert (collection, item, event){
      if(item.medication && item.medication.quantity - 1 <= item.medication.minimum_level){
        $mdDialog.show({
          controller: 'medAlertsController',
          controllerAs: 'medAlertCtrl',
          templateUrl: 'templates/marketplace/medications/modals/delete-medication.html',
          parent: angular.element(document.body),
          clickOutsideToClose: true,
          bindToController: true,
          locals:{
            medication:item,
            collection: collection
          }
        });
      } else {
        delete_medication(collection, item);
      }
    }

    function shopToast (item) {
      inventoryItemService.addToShoppingList([{id: item.inventory_item_id}])
        .then(function(){
          var text = $filter('translate')('COMMON.ADDED_TO_SL');
          sgToast.showSimple(text);
        });

    }

    function defineLocks() {
      ctrl.disable_shopping_list = !AccessService.hasShoppingListAccess();

      ctrl.hideCartButton = !AccessService.hasCartAccess();
    }

  }
}());

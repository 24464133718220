(function () {
  'use strict';

  angular
    .module('app.marketplace.system')
    .service('StartupDialogService', StartupDialogService);

  //
  // This service is a central location to register dialogs that are meant to
  // be shown on login / startup.
  //
  // Possible Improvement: Allow registered dialogs to include a 'rank' setting
  //  that is used to sort the order in which the dialogs are processed. This
  //  would allow to keep the ad-hoc "registerDialog" model, while being able
  //  to enforce some sort of ordering.
  //
  function StartupDialogService ($log) {
    /*jshint validthis: true */
    var service = this;
    var registeredDialogs = [];

    service.registerDialog = registerDialog;
    service.showDialogs = showDialogs;

    return service;

    //
    // Register a dialog with the service.
    //
    function registerDialog (dialog) {
      registeredDialogs.push(dialog);
    }

    //
    // Run through the list of dialog shows and launch the first one that
    // evaluates seems like we should show it. If the dialog did not register a
    // shouldShowFunc setting, then we assume that it must always be shown.
    //
    function showDialogs () {
      _.forEach(registeredDialogs, function (dialog) {
        if (!dialog.shouldShowFunc || dialog.shouldShowFunc()) {
          dialog.show();
          return false;
        }
      });
    }
  }

}());

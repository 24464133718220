angular.module("app.marketplace.elements")

  //
  // Initialize the Service
  //
  .run(function ($rootScope, $state, $log, officeService, RouteService, sowAnalyticsService, SessionStorageService) {
    $rootScope.$on('officeService: office-changed', function (event, newOffice, oldOffice) {
      // clear session cache to avoid specific office data to be used in different offices
      SessionStorageService.clear();
      // set google tag manager userID to this office's id
      var new_id = _.get(newOffice, 'id', false);
      if (new_id)
        sowAnalyticsService.setOffice(newOffice);

      if ($state.current['abstract']) {
        // $log.debug('DEBUG: office-changed handler: Skipping state reload. Current state is marked as abstract.');
        return;
      }

      if ($state.includes('app.registration') ||
        $state.includes('app.office.create') ||
        $state.current.name === 'app.invitation.register' ||
        $state.current.name === 'app.invite_request_response') {

        $log.debug('DEBUG: office-changed handler: Skipping state reload. (current state: {0})'.format($state.current.name));
        return;

      }

      $log.debug('DEBUG: office-changed handler: reloading state. (current state: {0}'.format($state.current.name));

      // SPD-1115: from now on we route them as though they had just signed in to this office
      if (!$state.params.officeMembershipId && !$state.params.toState?.includes('app.workflows')) {
        RouteService.goToDefault();
      }

      // $state.go($state.current, {}, {reload: true}).then(function () {
      //   if (RouteService.shouldRedirectOnSignIn($state.current)) {
      //     // TODO This should probably only get triggered on login, but Jesse was
      //     //      the one that wanted the change, so ask him first I guess.
      //     $log.debug('DEBUG: office-changed handler: Redirecting to default after state reload. (current state: {0})'.format($state.current.name));
      //     RouteService.goToDefault();
      //   }
      // });
    });

    officeService.init();
  });

(function () {
  'use strict';
  
  angular
  .module('app.marketplace.system')
  .service('ConfigService', ConfigService);
  
  function ConfigService ($q, $log, $injector, $rootScope, elementService, localStorageService, serverAPI, apiUrl) {
    /*jshint validthis: true */
    var service = this;
    var useLocalStorage = localStorageService.isSupported();
    
    service.initialized = false;
    
    service.get = get;
    service.set = set;
    service.update = update;
    service.initialize = initialize;
    service.waitUntilInitialized = waitUntilInitialized;
    service.getUserSetting = getUserSetting;
    service.setUserSetting = setUserSetting;
    
    service.init_promise = $q.defer();
    
    return service;
    
    //
    // Initialize Service
    //
    function initialize () {
      if (useLocalStorage) {
        service.settings = localStorageService.get('settings');
      }
      
      if (!service.settings) {
        service.settings = {
          current_provinces: [],
          current_provinces_map: [],
          countries: {},
          countriesMap: {}
        };
      }
      
      loadConstants();
      
      //Set outside of the $http call to ensure that a country field is always set.
      $rootScope.current_country = {'id': 'CA', 'name': 'Canada'};
      const configUrl = `${apiUrl}/config`;
      service.init_promise = serverAPI.callAPI(configUrl, null, {show_error_toast: false}).then((configResponse) => {
        loadConfig(configResponse);
        service.initialized = true;
        
        $rootScope.config = service.settings;
        $rootScope.current_medical_field = service.settings.medical_fields_map['dentistry'];
        
        saveToLocalStorage();
        return service.initialized;
      }).catch(function (error) {
        $log.error('ConfigService: Error During Initialization: %o', error);
        throw error;
      });

      return service.init_promise;
    }
    
    function getUserSetting (settingName) {
      if ($rootScope.current_account) {
        var settings = localStorageService.get('per-user-settings') || {};
        var accountId = _.get($rootScope, 'current_account.id');
        return _.get(settings, '{0}.{1}'.format(accountId, settingName));
      } else {
        return undefined;
      }
    }
    
    function setUserSetting (settingName, settingValue) {
      if ($rootScope.current_account) {
        var settings = localStorageService.get('per-user-settings') || {};
        var accountId = _.get($rootScope, 'current_account.id');
        _.set(settings, '{0}.{1}'.format(accountId, settingName), settingValue);
        localStorageService.set('user-settings', settings);
      } else {
        $log.warn("Can't set user setting. Not logged in.");
      }
    }
    
    //
    // Save settings to local storage
    //
    function saveToLocalStorage () {
      if (useLocalStorage) {
        localStorageService.set('settings', service.settings);
      }
    }
    
    //
    // Return a promise that resolves when the service has been initialized
    //
    function waitUntilInitialized () {
      return service.init_promise;
    }
    
    //
    // fetch a setting by name
    //
    function get (name) {
      return service.settings[name];
    }
    
    //
    // change a setting by name
    //
    function set (name, value) {
      service.settings[name] = value;
      saveToLocalStorage();
      return value;
    }
    
    //
    // Update the configuration by calling out to the API
    //
    function update () {
      return elementService.callEndpoint('session', {endpoint: 'config'}).then(function (response) {
        loadConfig(response.data);
        saveToLocalStorage();
        return service.settings;
      }).catch(function (error) {
        $log.error('ConfigService: Error Updating Config: %o', error);
      });
    }
    
    //
    // Load configuration provided by the API.
    //
    function loadConfig (config) {
      // Use $.extend to keep current_provinces and current_provinces_map
      $.extend(service.settings, config);
      
      // Cleanup Country data:
      // 1) Remove the "All Countries" entry (id === '*').
      // 2) Generate countriesMap that maps countryId => country.
      // 3) Make sure provinces are sorted.
      //
      var countries = service.get('countries');
      var countriesMap = service.get('countriesMap');
      var countryKeys = [];
      var catchallIndex;
      
      angular.forEach(countries, function (country, index) {
        if (country.id === '*') {
          catchallIndex = index;
          return;
        }
        
        countryKeys.push(country.id);
        countriesMap[country.id] = country;
        country.provinces = _.sortBy(country.provinces, 'name');
      });
      
      // Note: do not modify the countries list while we are iterating
      if (catchallIndex !== undefined) {
        countries.splice(catchallIndex, 1);
      }
      
      // Remove any countries from countriesMap that are _not_ in the config
      // object we are loading.
      //
      var keysToRemove = _.difference(_.keys(countriesMap), countryKeys);
      
      angular.forEach(keysToRemove, function (key) {
        _.unset(countriesMap, key);
      });
      
      //
      // Generate the Medical Fields mapping
      //
      var medical_fields = service.get('medical_fields');
      var medical_fields_map = {};
      service.set('medical_fields_map', medical_fields_map);
      
      _.map(medical_fields, function(field){
        medical_fields_map[field.id] = field;
      })
    }
    
    //
    // Load in constants via the $injector
    //
    // FIXME This is a transition helper, eventually we shouldn't be doing
    //       something like this.
    //
    function loadConstants () {
      var constants = [
        'algoliaAPIKey',
        'algoliaAppId',
        'algoliaPrefixIndex',
        'apiUrl',
        'AppStoreUrl',
        'defaultDebounceTime',
        'defaultPaginationOptions',
        'externalVendorApiUrl',
        'marketplaceUrl',
        'membershipTypes',
        'NoImageUrl',
        'officeAccountTypes',
        'searchUrl',
        'skyflowVaultID',
        'skyflowVaultURL',
        'splashDelay',
        'SponsorLogos',
        'stripePublishableKey',
        'TemplatePaths',
        'textInputUpdateDelay',
        'titleConfig',
        'workflowApiUrl',
        'googleMapsApiKey',
        'froalaApiKey',
        'sentryUrl',
        'sterilizationImagesUrl',
        'appEnvironment',
        'firebase_api_key',
        'firebase_app_id',
        'firebase_messaging_sender_id',
        'paper_trail_token'
      ];
      
      for (var i = 0; i < constants.length; ++i) {
        service.set(constants[i], $injector.get(constants[i]));
      }
    }
    
  }
}());

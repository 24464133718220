('use strict');
{
  /** @ngInject */
  class EVActionsHelperService {
    constructor($rootScope, msHelperService) {
      this.$rootScope = $rootScope;
      this.msHelperService = msHelperService;

      this.ACTIONS = {
        ADD_TO_CART: 'add_to_cart',
        BILLING_AND_SHIPPING: 'billing_and_shipping_method',
        CLEAR_CART: 'clear_cart',
        ORDER_REVIEW: 'order_review',
        PLACE_ORDER: 'place_order',
        PRICE_STATUS: 'price_status',
        STOCK_STATUS: 'stock_status',
      };
    }

    /**
     * It returns the actions for the current vendor
     * @param {object} current_vendor
     * @param {string} current_vendor.vendor_id
     * @returns {object}
     */
    getCurrentVendorActions (current_vendor) {
      if (current_vendor?.id) {
        return _.get(this.$rootScope.external_vendors_actions, current_vendor.id);
      }

      return null;
    }

    isExternalVendor (current_vendor) {
      return current_vendor?.checkout_type === 'external_vendor';
    }

    /**
     * It returns the action info for the current vendor
     * @param {object} current_vendor_actions
     * @param {string} action
     * @returns {object}
     */
    getActionInfo (current_vendor_actions, action) {
      return _.get(current_vendor_actions?.data_sources, action, null);
    }
  }

  angular.module('sowMarketplace').service('EVActionsHelperService', EVActionsHelperService);
}

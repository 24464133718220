angular.module('app.marketplace.ui.implants')

  .directive('sowSlideoutEditImplant', function () {
    return {
      replace: true,
      restrict: 'E',
      templateUrl: 'templates/marketplace/implants/directives/edit-slideout.html',
      link: function ($scope, $element, $attrs) {

      }
    };
  });
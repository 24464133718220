(function () {
  'use strict';

  angular
    .module('app.shared.support')
    .directive('ceiFix', ceiFix);

  //
  // Hardcoded fix/workaround for angular-payment issue
  // https://github.com/seandesmond/angular-payment/issues/14
  //
  function ceiFix ($timeout) {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function (scope, elm, attrs, ngModelCtrl) {
        $timeout(function(){
          if(ngModelCtrl.$modelValue && ngModelCtrl.$modelValue.month && ngModelCtrl.$modelValue.year){
            ngModelCtrl.$viewValue = ngModelCtrl.$modelValue.month + ' / ' + ngModelCtrl.$modelValue.year;
            ngModelCtrl.$setValidity('required', true);
            ngModelCtrl.$setValidity('cardExpiry', true);
            ngModelCtrl.$render();
            scope.$digest();
          }
        });
      }
    };
  }
}());

(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.registration')
    .directive('officeSearchForm', officeSearchForm);

  function officeSearchForm ($state, registrationService, errorService) {
    return {
      'restrict': 'E',
      'replace': true,

      'template': [
        '<div>',
        '  <form name="OfficeSearchForm" novalidate>',
        '    <md-autocomplete required',
        '                     md-floating-label="Office Name"',
        '                     md-search-text="searchText"',
        '                     md-min-length="4"',
        '                     md-autoselect="true"',
        '                     md-item-text="item.name"',
        '                     md-input-name="autocomplete_office_name"',
        '                     md-selected-item="selectedOffice"',
        '                     md-delay="300"',
        '                     md-menu-class="office-name-autocomplete-item"',
        '                     md-items="item in getMatches(searchText)">',

        '      <md-item-template>',
        '        <span class="item-title">',
        '          {{item.name}}',
        '        </span>',
        '        <span class="item-metadata">',
        '          <small class="grey-text"> {{item.location}} </small>',
        '        </span>',
        '      </md-item-template>',

        '      <md-not-found>',
        '        No matches found.',
        '      </md-not-found>',

        '      <ng-messages for="OfficeSearchForm.autocomplete_office_name.$error">',
        '        <ng-message when="required"> {{\'ERRORS.REQUIRED\' | translate}} </ng-message>',
        '      </ng-messages>',
        '    </md-autocomplete>',
        '  </form>',

        '  <md-button class="md-primary md-raised centered-text mb-24px" ng-disabled="!selectedOffice || submitting" ng-click="sendRequest(selectedOffice)" name="officeSearchForm-btn-sendInviteRequest">',
        '    Send Request',
        '  </md-button>',
        '</div>',
      ].join("\n"),

      'link': function ($scope) {

        $scope.getMatches = function (searchText) {
          return registrationService.searchOfficeNames(searchText);
        };

        $scope.sendRequest = function (selectedOffice) {
          $scope.submitting = true;
          return registrationService.requestOfficeInvite(selectedOffice.id).then(function () {
            $state.go('app.registration.invite_request_sent');
          }).catch(function (error) {
            errorService.uiErrorHandler(error);
          })['finally'](function(){
            $scope.submitting = false;
          });
        };
      }
    };
  }

}());

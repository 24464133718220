(function () {
  'use strict';
  angular.module('sowSales')
  .controller('SalesLandingController', SalesLandingController);

  function SalesLandingController($scope, $rootScope, $state, $stateParams, $anchorScroll, $location, appEvents, sowAlgoliaService, mktHelperService, AccessService) {
    /*jshint validthis: true */
    var ctrl = this;

    ctrl.active_filter = $stateParams.sale_filter || 'sales_all';
    ctrl.loading = {
      'categories': false,
      'products': false,
    };
    ctrl.display_products = null;
    ctrl.current_page = 0;

    ctrl.setActiveFilter = setActiveFilter;
    ctrl.isFilterActive = isFilterActive;
    ctrl.initAlgolia = initAlgolia;
    ctrl.open = open;

    ctrl.$onInit = init;

    return ctrl;

    function init () {
      _defineLocks();
      _addSaleFilters();
      $scope.$on('$destroy', function(){
        $rootScope.algolia_data = null;
      });
      $scope.$on('algolia_data', handleAlgoliaData);
      $scope.$on('algolia_loading', function(event, val){
        ctrl.loading.products = val;
        // if(val){
        //   ctrl.display_products = null;
        // }
      });
      $scope.$on(appEvents.mktProductUpdate, updateLocalProduct);
      $scope.$on('filters-ready', function () {
        initAlgolia();
      })

      ctrl.loading.products = true;
    }

    function _defineLocks() {
      ctrl.disable_shopping_list = !AccessService.hasShoppingListAccess();
    }

    function _addSaleFilters() {
      ctrl.sale_filters = {
        sales_all: {
          empty_msg: 'MARKETPLACE.SALES_TRACKER.EMPTY_ALL',
          label: 'MARKETPLACE.SALES_TRACKER.ALL',
          link_msg: 'All Sale Products',
        },
        sales_min_level: {
          empty_msg: 'MARKETPLACE.SALES_TRACKER.EMPTY_MINLVL',
          label: 'MARKETPLACE.SALES_TRACKER.BELOW_MIN',
          link_msg: 'All Products Below Minimum Level',
        },
        sales_inventory: {
          empty_msg: 'MARKETPLACE.SALES_TRACKER.EMPTY_INVENTORY',
          label: 'MARKETPLACE.SALES_TRACKER.OWNED',
          link_msg: 'All Inventory Products on Sale',
        },
      };
      if (!ctrl.disable_shopping_list) {
        ctrl.sale_filters.sales_shopping_list = {
          empty_msg: 'MARKETPLACE.SALES_TRACKER.EMPTY_SHOPLIST',
          label: 'COMMON.SHOPPING_LIST',
          link_msg: 'All Products in Shopping List',
        };
      }
    }

    function scrollTo (id) {
      var newHash = id;
      if ($location.hash() !== newHash) {
        $location.hash(id);
      } else {
        $anchorScroll();
      }
    }

    function scrollToTop () {
      return scrollTo("#appContainer");
    }

    function setActiveFilter (filter_name, clear_query) {
      // ctrl.active_filter = filter_name;
      var params = _.extend({}, $stateParams, {'sale_filter':filter_name});
      if(clear_query)
        _.set(params,'query', null);
      $state.transitionTo($state.current, params);
    }

    function isFilterActive (filter_name) {
      return ctrl.active_filter === filter_name;
    }

    function handleAlgoliaData (event, search_response) {
      if(!search_response){
        return;
      }
      var hits = _.get(search_response, 'results[0].hits', []);
      var total = _.get(search_response, 'results[0].nbHits', 0);
      ctrl.search_data = search_response;
      ctrl.current_page = _.get(search_response, 'results[0].page', 0);
      
      ctrl.loading.categories = false;
      scrollToTop();
      if(_.size(hits) < 1){
        ctrl.display_products = null;
        return;
      }
      
      ctrl.display_products = _.map(hits, setProductVendorInfo);
      ctrl.loading.products = false;
    }

    function initAlgolia () {
      addFilters(null);
      
      function addFilters (filters_array) {
        var options = {};
        if(filters_array){
          var facets = _.map(filters_array, function(filter) {
            return 'filters.'+filter;
          });
          _.set(options, 'facets', facets);
        }
        var ais = sowAlgoliaService.initAisInstance(options);
        ais.start();
      }
    }

    function updateLocalProduct ($event, new_item) {
      var item = _.find(ctrl.display_products, ['id', new_item.id]);
      _.extend(item, _.pick(new_item, ['is_favourite_product']));
    }

    function setProductVendorInfo (product) {
      product.vendor_ui = getVendorList(product);

      function getVendorList (product) {
        var vendor_list = [];
        var actual_vendors = _.map(_.take(product.vendor_inventory, 5), function(item){
          _.set(item, 'UI.full', true);

          // promotion UI info
          var promo_count = _.size(item.promotions);
          _.set(item, 'UI.on_sale', (promo_count > 0) );
          _.set(item, 'UI.promo_count', promo_count );

          _.each(item.promotions, function(promo){
            var promo_type = _.get(promo, 'promotion_type', null);
            _.set(item, 'UI.is_manufacturer_promo', promo_type && promo_type.includes('MANUFACTURER'), null);
              const localized_notes = mktHelperService.getLocalizedPromoNotes(promo);
              _.set(item, 'UI.promo_notes', localized_notes);
          });          

          return item;
        });

        var missing_vendors_qty = (5 - actual_vendors.length);
        if (missing_vendors_qty) {
          var missing_vendors_list = [];
          for (var i = 0; i<missing_vendors_qty; i++) {
            var mvi = {
              'id':i,
              'UI':{
                'empty': true
              },
            };
            missing_vendors_list.push(mvi);
          }
          vendor_list = _.concat(actual_vendors, missing_vendors_list);
        } else {
          vendor_list = _.concat(_.take(actual_vendors, 4), [{'UI':{'see_more':true},'id':'see'}]);
        }

        return vendor_list;
      }

      return product;
    }


    function open() {
      $rootScope.$broadcast('algolia-filters-open');
    }
    
  }

}());

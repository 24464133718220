(function(){
  'use strict';
  angular.module("app.marketplace.ui.implants")
    .controller('impActionBarController', implantABController);

  function implantABController ($state, $stateParams, $mdDialog, $filter, sgToast, errorService, sowImplantService, $rootScope,implantCarrierService,alertsDataCarrierService, PurchaseOrderService, inventoryItemService, sowReportsService, AccessService, poHelperService) {
    /*jshint validthis:true*/
    var ctrl = this;

    ctrl.clearSelection = clearSelection;
    ctrl.init = init;
    ctrl.implantCarrierService = implantCarrierService;
    ctrl.alertsDataCarrierService = alertsDataCarrierService;
    ctrl.list_purchase_orders = list_purchase_orders;
    ctrl.add_to_shopping_list = add_to_shopping_list;
    ctrl.addDoctor = addDoctor;
    ctrl.exportImplantInventory = exportImplantInventory;
    ctrl.shouldHideCreateOrderBtn = shouldHideCreateOrderBtn;
    ctrl.shouldHideShoppingListBtn = shouldHideShoppingListBtn;

    ctrl.$onInit = init;

    return ctrl;

    function addDoctor () {
      $rootScope.$broadcast('ab-add-doctor');
    }

    function init () {
      _defineLocks();
      if ($stateParams.mfr_id) {
        sowImplantService.getManufacturer($stateParams.mfr_id)
        .then(function(response){
          ctrl.manufacturer = response;
        });
      }
    }

    /**
     * Determines if create order button should be hidden based on:
     * 1. If the office has disabled purchase orders
     * 2. If all the checked implants are discontinued
     * @returns {boolean}
     */
    function shouldHideCreateOrderBtn () {
      return ctrl.disable_purchase_orders || inventoryItemService.allItemsDiscontinued(ctrl.implantCarrierService.checked_items);
    }

    /**
     * Determines if shopping list button should be hidden based on:
     * 1. If the office has disabled shopping list
     * 2. If all the checked implants are discontinued
     * @returns {boolean}
     */
    function shouldHideShoppingListBtn () {
      return ctrl.disable_shopping_list || inventoryItemService.allItemsDiscontinued(ctrl.implantCarrierService.checked_items);
    }

    function add_to_shopping_list(){
      const items = inventoryItemService.getNonDiscontinuedItems(ctrl.implantCarrierService.checked_items, 
        (item) => ({id: item.inventory_item_id})
      );

      return inventoryItemService.addToShoppingList(items)
      .then(function(res){
        var text = $filter('translate')('COMMON.ADDED_TO_SL');
      sgToast.showSimple(text);
        clear_checked_items();
      });
    }

    function list_purchase_orders () {
      // load list
      var po_list = [];
      PurchaseOrderService.getAllByStatus('Draft')
      .then(function(response){
        po_list = _.values(response);
        if(!po_list.length){
          create_purchase_order();
          return;
        }
        po_list = _.sortBy(po_list, function(po){ return po.supplier.name; });
        // $scope.po_list = po_list;
        // display dialog
        $mdDialog.show({
          controller: 'poDialogController as dialogCtrl',
          templateUrl: 'templates/marketplace/medications/modals/add-to-purchase-order.html',
          parent: angular.element(document.body),
          clickOutsideToClose:true,
          // scope: $scope,
          locals: {
            po_list: po_list,
            // cancel: $mdDialog.close,
          },
          bindToController: true,
        })
        .then(function(po){
          if(po){
            add_to_purchase_order(po);
          } else {
            create_purchase_order();
          }
        });
      });

    }

    function add_to_purchase_order (order) {
      const including_items = inventoryItemService.getNonDiscontinuedItems(ctrl.implantCarrierService.checked_items, (item) => {
        return {
          'inventory_item_id': item.inventory_item_id,
          'quantity': 1,
          'unit_cost': item.default_price || 0,
        };
      });

      order.items = _.concat(order.items, including_items);

      return PurchaseOrderService.saveDraft(order).then(function (po) {
          clear_checked_items();
          $state.go('app.orders.purchase.draft',{poId: po.id});

      }).catch(function (error) {
        /* the service shows an error toast in this case so we won't also do so here */
        if (error?.internal_code === 'PO_HRID_DUPLICATE') {
          return;
        }
        errorService.uiErrorHandler(error);
      });
    }

    function create_purchase_order () {
      const initial_data = poHelperService.getInitialPoDataFromImplants(ctrl.implantCarrierService.checked_items);
      clear_checked_items();
      $state.go('app.orders.purchase.draft', initial_data);
    }

    function clearSelection () {
      $rootScope.$broadcast('implants-step2-clear-selection');
    }

    function clear_checked_items () {
      _.map(ctrl.implantCarrierService.checked_items, function(item){
        item.checked = false;
      });
      ctrl.implantCarrierService.checked_items = angular.copy([], ctrl.implantCarrierService.checked_items);
    }

    function exportImplantInventory () {
      return sowReportsService.exportImplantInventory();
    }

    function _defineLocks() {
      const has_po_access = AccessService.hasPurchaseOrdersAccess();
      const has_disable_create_po = AccessService.getProperty('implants.disable_create_po');
      ctrl.disable_purchase_orders = !has_po_access || has_disable_create_po;

      ctrl.disable_shopping_list = !AccessService.hasShoppingListAccess();
    }

  }

}());

(function () {
  'use strict';

  angular.module('sowProduct')
    .controller('pdHeaderController', pdHeaderController)
    .directive('pdHeader', pdHeaderDirective);

  function pdHeaderDirective() {
    return {
      restrict: 'E',
      templateUrl: 'sow-product-details/directives/pd-header.html',
      controller: 'pdHeaderController',
      controllerAs: 'pdHeaderCtrl',
      scope: {
        product: '='
      }
    };
  }

  function pdHeaderController($scope, $rootScope, productDetailsService, $state, RouteService, sowLanguageService, mktHelperService) {
    var ctrl = this;

    ctrl.goBack = goBack;
    ctrl.goToManufacturer = productDetailsService.goToManufacturer;
    ctrl.goToCategory = goToCategory;
    ctrl.closeAlert = closeAlert;
    ctrl.scrollToRelatedProducts = productDetailsService.scrollToRelatedProducts;
    ctrl.localize = mktHelperService.getLocalizedProp;

    init();

    return ctrl;

    function init() {
      ctrl.language = sowLanguageService.getCurrentLanguage();
      ctrl.product = ctrl.product || $scope.product;
      ctrl.show_alert = !_.isNil(_.get(ctrl, 'product.UI.alerts[0]', null));
      $scope.$on('pd-hide-discontinued-alert', () => ctrl.show_alert = false);
    }

    /**
    * Back Link:
    * - If the previous page exists - takes back to the last page_url
    * - If the previous page does not exist (like advertisement, email etc), the "Back" link will take user to marketplace landing page
    * @return {*}
    */
    function goBack() {
      if ($rootScope.previousState) {
        RouteService.back();
      } else {
        $state.go('app.mkt');
      }
    }

    /**
    * navigates to category/subcategory by generating appropriate param(s) {cat_id} or {cat_id, sub_id}
    * when user clicks on corresponding breadcrumb
    * 
    * TODO: refactor mkt URL to accomodate multiple sub_id params (or one sub_ids param)
    * 
    * @param {string} selected_id The ID of the category or subcategory represented by the breadcrumb the user clicked
    * @param {array} categories Array of category objects {name, id}
    * @return {*}
    */
    function goToCategory(selected_id, categories) {
      categories = categories || ctrl.product.categories;
      var category_ids = _.map(categories, function (category) {
        return category.id;
      });
      var parent_category_id = category_ids[0];
      var params = { 'cat_id': parent_category_id };
      if (selected_id === parent_category_id) {
        return $state.go("app.mkt.search", params);
      }
      for (var i = 1; i < category_ids.length; i++) {
        var current_id = category_ids[i];
        params[i > 1 ? 'sub_id' + i.toString() : 'sub_id'] = current_id;
        if (current_id === selected_id) {
          return $state.go("app.mkt.search", params);
        }
      }
    }

    function closeAlert () {
      ctrl.show_alert = false;
    }

  }

})();

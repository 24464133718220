(function () {
  'use strict';
  
  angular
  .module('sowReports')
  .controller('MedicationDetailReportController', MedicationDetailReportController)
  .directive('actionBarReportMedication', medABDirective)
    
  function medABDirective() {
    return {
      restrict: 'AC',
      templateUrl: 'sow-reports/directives/action-bar-medication.html',
      controller: 'MedicationDetailReportController',
      controllerAs: 'medDetailReportCtrl'
    };
  }
  
  function MedicationDetailReportController ($q, $rootScope, $stateParams, ChartJSDefaults, sowMedicationService) {
    /*jshint validthis: true */
    var ctrl = this;
    // ctrl.selectedRange = 'This Year';
    // ctrl.rangeOptions = ['This Year', 'All Time'];
    ctrl.update_range = update_range;
    
    init();
    
    return ctrl;
    
    //
    // Initialize the controller.
    //
    function init () {
      init_range();
      generate_years();
      
      $q.all([
        load_reports(),
        load_medication(),
        load_medication_summary()
      ]).then(function(result){
        var data = result[0];
        ctrl.medication = result[1][0];
        ctrl.medication_summary = result[2];
        ctrl.medication_summary.reorder_date_text = moment(ctrl.medication_summary.reorder_date).toNow(true); // 4 years
        ctrl.medication_summary.stock_out_date_text = moment(ctrl.medication_summary.stock_out_date).toNow(true); // 4 years
        ctrl.current_office = result[3];
        
        chart_on_hand_med(data);
        chart_inventory_removed_med(data);
        chart_inventory_added_med(data);
      });
      
    }
    
    function init_range () {
      if (ctrl.selected_year) {
        
        ctrl.start_of_year = moment().year(ctrl.selected_year).startOf('year');
        ctrl.end_of_year = moment().year(ctrl.selected_year).endOf('year');
      } else{
        ctrl.selected_year = moment().format("YYYY");
        ctrl.start_of_year = moment().startOf('year');
        ctrl.end_of_year = moment();
      }
    }
    
    function chart_on_hand_med (data) {
      var ordered = _.orderBy(data, "date");
      var chart_on_hand = [];
      _.each(ordered, function(item){
        chart_on_hand.push({
          x:  moment.utc(item.date),
          y: item.on_hand
        });
      });
      ctrl.labels = chart_on_hand.map(function(obj){ return obj.x;});
      ctrl.has_on_hand = chart_on_hand.length > 0;
      ctrl.chart_on_hand = {
        'data': [chart_on_hand],
        'labels':ctrl.labels,
        "colors": [
          [ "rgba(57, 142, 227, 1)" ],
          [ "rgba(101, 219, 255, 1)"]
        ],
        'options': ChartJSDefaults.createLineChartOptions({
          'legend': {'display': false},
          'tooltips': {
            'callbacks': {
              'title': function (tooltipItems, data) {
                var date = tooltipItems[0].xLabel;
                return date.format('MMMM Do YYYY');
              },
            },
          },
          'scales': {
            'xAxes': [{
              'type': 'time',
              'position': 'bottom',
              'time': {
                'unit': 'month',
                'displayFormats': {
                  month: 'MMM YYYY',
                },
                unitStepSize: 1
                
              },
            }],
          },
        }),
        "overrides": [
          ChartJSDefaults.createLineChartOverride('rgba(57, 142, 227, 1)'),
          ChartJSDefaults.createLineChartOverride('rgba(101, 219, 255, 1)'),
        ]
      };
    }
    
    function chart_inventory_removed_med (data) {
      var ordered = _.orderBy(data, "date");
      var groups = [];
      _.each(ordered,function(item){
        item.date_label = moment(item.date).format('MMM YYYY');
        if(!groups[item.date_label]){
          groups[item.date_label] = 0;
        }
        groups[item.date_label] = groups[item.date_label] + item.removed;
      });
      
      ctrl.has_on_inventory = Object.values(groups).length > 0;
      
      ctrl.chart_inventory_removed = {
        'data': Object.values(groups),
        'labels': Object.keys(groups),
        'col_data': [],
        'fill': false,
        'options': {
          'fill': false,
          'responsive': true,
          'maintainAspectRatio': true,
          'tooltips': {
            'enabled': true,
            'displayColors': false,
            'mode': 'single',
            'titleFontFamily': "'Roboto', 'Helvetica', 'Arial', 'Sans-Serif'",
            'footerFontFamily': "'Roboto', 'Helvetica', 'Arial', 'Sans-Serif'",
            'bodyFontFamily': "'Roboto', 'Helvetica', 'Arial', 'Sans-Serif'",
          },
          'scales': {
            'xAxes': [{
              'interval': 1000,
              'gridLines': {
                'display': false,
                'drawTicks': false,
                'color': 'rgba(128,142,155,0.25)',
                'zeroLineColor': 'rgba(128,142,155,0.25)',
              },
              'ticks': {
                'fontFamily': "'Roboto', 'Helvetica', 'Arial', 'Sans-Serif'",
                'beginAtZero': true,
              },
            }],
            'yAxes': [{
              'gridLines': {
                'display': true
              },
              'ticks': {
                'fontFamily': "'Roboto', 'Helvetica', 'Arial', 'Sans-Serif'",
                'beginAtZero': true,
              },
            }]
          },
          dataset: {
            barThickness: 10,
          },
        },
        'overrides': {
          'fill': false,
          'borderWidth': 0,
          'borderColor': '#fba65b',
          'backgroundColor': '#fba65b',
          'hoverBackgroundColor': '#de4745',
        }
      };
      
    }
    
    function chart_inventory_added_med (data) {
      var ordered = _.orderBy(data, "date");
      var groups = [];
      _.each(ordered,function(item){
        item.date_label = moment(item.date).format('MMM YYYY');
        if(!groups[item.date_label]){
          groups[item.date_label] = 0;
        }
        groups[item.date_label] = groups[item.date_label] + item.added;
      });
      
      ctrl.has_on_inventory_added =  Object.values(groups).length > 0;
      
      ctrl.chart_inventory_added = {
        'data': Object.values(groups),
        'labels': Object.keys(groups),
        'col_data': [],
        'fill': false,
        'options': {
          'fill': false,
          'responsive': true,
          'maintainAspectRatio': true,
          'tooltips': {
            'enabled': true,
            'displayColors': false,
            'mode': 'single',
            'titleFontFamily': "'Roboto', 'Helvetica', 'Arial', 'Sans-Serif'",
            'footerFontFamily': "'Roboto', 'Helvetica', 'Arial', 'Sans-Serif'",
            'bodyFontFamily': "'Roboto', 'Helvetica', 'Arial', 'Sans-Serif'",
          },
          'scales': {
            'xAxes': [{
              'interval': 1000,
              'gridLines': {
                'display': false,
                'drawTicks': false,
                'color': 'rgba(128,142,155,0.25)',
                'zeroLineColor': 'rgba(128,142,155,0.25)',
              },
              'ticks': {
                'fontFamily': "'Roboto', 'Helvetica', 'Arial', 'Sans-Serif'",
                'beginAtZero': true,
              },
            }],
            'yAxes': [{
              'gridLines': {
                'display': true
              },
              'ticks': {
                'fontFamily': "'Roboto', 'Helvetica', 'Arial', 'Sans-Serif'",
                'beginAtZero': true,
              },
            }]
          },
          dataset: {
            barThickness: 10,
          }
        },
        'overrides': {
          'fill': false,
          'borderWidth': 0,
          'borderColor': '#33be8c',
          'backgroundColor': '#33be8c',
          'hoverBackgroundColor': '#de4745',
        }
      };
      
    }
    
    function load_medication_summary () {
      var date_range = {
        'start_date': ctrl.start_of_year.toDate(),
        'end_date':ctrl.end_of_year.toDate()
      };
      return sowMedicationService.getMedicationSummary({id: $stateParams.medicationId}, date_range);
    }
    
    function load_reports () {
      var date_range = {
        'start_date': ctrl.start_of_year.toDate(),
        'end_date':ctrl.end_of_year.toDate()
      };
      return sowMedicationService.getMedicationStockHistory({id: $stateParams.medicationId}, date_range);
    }
    
    function load_medication () {
      return sowMedicationService.getMedications({id: $stateParams.medicationId});
    }
    
    function generate_years () {
      ctrl.years_list = [];
      
      ctrl.office_first_date = moment.utc(_.get($rootScope, 'current_office.medications_start_date'));
      var first_year = ctrl.office_first_date.format("YYYY") * 1;
      var current_year = moment.utc().format("YYYY") * 1;
      
      for (var i = first_year; i <= current_year; i++) {
        ctrl.years_list.push(i+'');
      }
    }
    
    function update_range () {
      init_range();
      
      load_reports()
      .then(function(reports){
        chart_on_hand_med(reports);
        chart_inventory_removed_med(reports);
        chart_inventory_added_med(reports);
      });
      
    }
    
  }
}());

{
  'use strict';
  /** @ngInject */
  class CountryService {
    constructor(ConfigService, apiUrl, serverAPI, sessionService) {
      this.ConfigService = ConfigService;
      this.base_url = apiUrl;
      this.serverAPI = serverAPI;
      this.sessionService = sessionService;
      this.ECountryId = Object.freeze({
        AUSTRALIA: 'au',
        CANADA: 'ca',
        IRELAND: 'ir',
        TRINIDAD: 'tt',
        UNITED_KINGDOM: 'uk',
        USA: 'us',
      });
      this.ECountryCode = Object.freeze(_.mapValues(this.ECountryId, (value) => value.toUpperCase()))
    }

    /**
     * Makes a GET request to the monolith API to retrieve country and
     * province data
     * @param {string[]|null} countries 
     */
    _getCountryProvinceData(countries) {
      const url = `${this.base_url}/country_province_data`;
      const params = {}
      if (countries) {
        params.countries = countries.join(',');
      }

      const config = {
        method: 'GET',
        params,
      };
      return this.serverAPI.callAPI(url, config);
    }

    /**
     * Returns a list of countries with their provinces
     * @param {string|null} selected_country
     * @returns {{id: string, name: string, provinces: string}[]}
     */
    getAvailableCountryData (selected_country) {
      if (selected_country) {
        return this._getCountryProvinceData([selected_country]);
      }

      return this._getCountryProvinceData();
    }

    /**
     * Takes an array of countries and a country ID, and returns the
     * country object with the matching ID.
     * @param {{id: string, name: string, provinces: string}[]} countries
     * @param {string} country_id
     * @returns {object}
     */
    getCountryById (countries, country_id) {
      return countries.find((country) => country.id === country_id);
    }

    /**
     * Takes an array of country objects and returns an array of their
     * corresponding ids.
     * @param {{id: string, name: string, provinces: string}[]} countries
     * @returns {string[]}
     */
    getCountriesIds (countries) {
      return countries.map((country) => country.id);
    }

    /**
     * Returns the country ID from the current session,
     * defaulting to Canada if not found.
     * @returns {string} CA|US|TT|IR|UK|AU
     */
    getCurrentSessionCountryId () {
      const session = this.sessionService.get();
      return _.get(session, 'country.id', this.ECountryCode.CANADA);
    }

    /**
     * Returns a modified URL based on the current country ID.
     * @param {string} url
     * @returns {string}
     */
    createLocalizedUrl(url) {
      const country_id = this.getCurrentSessionCountryId();
      const {ECountryId, ECountryCode} = this;

      switch (country_id) {
        case ECountryCode.USA:
          return url.replace(`-${ECountryId.CANADA}`, `-${ECountryId.USA}`);
        default:
        case ECountryCode.CANADA:
          return url;
      }
    }
  }

  angular.module('app.marketplace.system').service('CountryService', CountryService);
}

angular.module('app.marketplace.system')
  .run(initializeStateLoadingPage);

//Loading screen for when state changes take too long.
function initializeStateLoadingPage($rootScope, $state, $interval, $timeout, $window, RouteService, sessionService) {
  var minDisplayMS = 1000;
  var _loadingPage = function (show) {
    if (show) {
      $rootScope.showLoadingOverlay = (new Date()).getTime();
    } else if ($rootScope.showLoadingOverlay && ((new Date()).getTime() - $rootScope.showLoadingOverlay) > minDisplayMS) {
      $rootScope.showLoadingOverlay = false;
    } else {
      $timeout(function () {
        $rootScope.showLoadingOverlay = false;
      }, (minDisplayMS - ((new Date()).getTime() - $rootScope.showLoadingOverlay)));
    }
  };

  var intervalPromise = null;
  $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
    if (!$rootScope.coreServicesInitialized && !intervalPromise) {
      var count = 0;
      intervalPromise = $interval(function () {
        count++;
        if (count === 1) {
          _loadingPage(true);
        } else if (count >= 15) {
          RouteService.goToDefault();
          _loadingPage(false);
          $interval.cancel(intervalPromise);
        }
      }, 1000);
    }
  });

  $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
    if (intervalPromise) {
      $interval.cancel(intervalPromise);
      _loadingPage(false);
    }
  });

  $rootScope.$on('$stateChangeError', function (event, toState, toParams, fromState, fromParams) {
    if (intervalPromise) {
      $interval.cancel(intervalPromise);
      _loadingPage(false);
    }
  });

}

(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.inventory')
    .service('EditLocationDialog', EditLocationDialog);

  function EditLocationDialog ($mdDialog) {
    /*jshint validthis: true */
    var service = this;

    service.show = showDialog;

    return service;

    function showDialog (location, event) {
      return $mdDialog.show({
        'controller': 'EditLocationController',
        'controllerAs': '$ctrl',
        'templateUrl': 'templates/marketplace/inventory/edit-location-dialog.html',
        'targetEvent': event,
        'parent': angular.element(document.body),
        'clickOutsideToClose': true,
        'locals': {'location': location},
      });
    }
  }

}());

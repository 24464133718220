(function () {
    'use strict';
    angular.module('sowUserAdmin')
    .controller('AllOfficeDashboardSpending', AllOfficeDashboardSpending);

    function AllOfficeDashboardSpending ($scope, errorService, $timeout, $filter , sgToast, sowAllOfficeService) {
    /*jshint validthis: true */
    var ctrl = this;
    ctrl.office_count = null;
    ctrl.selectedYear = moment().year();
    ctrl.rangeOptions = [{
      name: moment().year(),
      value: moment().year()
    }];

    ctrl.defaultRow = {
        categoryID: '',
        categoryName: '',
        apr: {month: 4, valueData: 0, percent: 0},
        aug: {month: 8, valueData: 0, percent: 0},
        dec: {month: 12, valueData: 0, percent: 0},
        feb: {month: 2, valueData: 0, percent: 0},
        jan: {month: 1, valueData: 0, percent: 0},
        jul: {month: 7, valueData: 0, percent: 0},
        jun: {month: 6, valueData: 0, percent: 0},
        mar: {month: 3, valueData: 0, percent: 0},
        may: {month: 5, valueData: 0, percent: 0},
        nov: {month: 11, valueData: 0, percent: 0},
        oct: {month: 10, valueData: 0, percent: 0},
        sep: {month: 9, valueData: 0, percent: 0},
        total: {valueData: 0, percent: 0, month: 13}
    };
    var t_office_name = $filter('translate')('COMMON.OFFICE_NAME');
    var t_total = $filter('translate')('COMMON.TOTAL');
    
    var columnDefs = [
      { headerName: t_office_name, field: 'categoryName', width: 160, minWidth:160, pinned: 'left', filter: 'agTextColumnFilter',sortingOrder: ['desc','asc'],unSortIcon: true, filterParams: { cellHeight: 20 },cellClass: 'cell-wrap-text',
      autoHeight: true },
      { headerName: 'JAN', field: 'jan', suppressSorting: true, minWidth: 67.5, cellRenderer: 'showCellRenderer' },
      { headerName: 'FEB', field: 'feb', suppressSorting: true, minWidth: 67.5, cellRenderer: 'showCellRenderer' },
      { headerName: 'MAR', field: 'mar', suppressSorting: true, minWidth: 67.5, cellRenderer: 'showCellRenderer' },
      { headerName: 'APR', field: 'apr', suppressSorting: true, minWidth: 67.5, cellRenderer: 'showCellRenderer' },
      { headerName: 'MAY', field: 'may', suppressSorting: true, minWidth: 67.5, cellRenderer: 'showCellRenderer' },
      { headerName: 'JUN', field: 'jun', suppressSorting: true, minWidth: 67.5, cellRenderer: 'showCellRenderer' },
      { headerName: 'JUL', field: 'jul', suppressSorting: true, minWidth: 67.5, cellRenderer: 'showCellRenderer' },
      { headerName: 'AUG', field: 'aug', suppressSorting: true, minWidth: 67.5, cellRenderer: 'showCellRenderer' },
      { headerName: 'SEP', field: 'sep', suppressSorting: true, minWidth: 67.5, cellRenderer: 'showCellRenderer' },
      { headerName: 'OCT', field: 'oct', suppressSorting: true, minWidth: 67.5, cellRenderer: 'showCellRenderer' },
      { headerName: 'NOV', field: 'nov', suppressSorting: true, minWidth: 67.5, cellRenderer: 'showCellRenderer' },
      { headerName: 'DEC', field: 'dec', suppressSorting: true, minWidth: 67.5, cellRenderer: 'showCellRenderer' },
      {
          headerName: t_total, field: 'total', minWidth: 93.5, pinned: 'right', cellRenderer: 'showCellRenderer',sortingOrder: ['desc','asc'],unSortIcon: true, comparator: function (a, b) {
              var x = 1 * a.valueData, y = 1 * b.valueData;
              return x == y ? 0 : (x > y) ? 1 : -1;
          }
      },
  ];

    ctrl.init = function(){
      ctrl.updateYearOptions();
      ctrl.getOfficeSpending();
    }

    ctrl.getOfficeSpending = function(){
      var start_date = moment(ctrl.selectedYear.toString(), 'YYYY').startOf('year').format('YYYY-MM-DD');
      var end_date = moment(ctrl.selectedYear.toString(), 'YYYY').endOf('year').format('YYYY-MM-DD');
      sowAllOfficeService.getOfficeSpending(start_date, end_date).then(function(result){
      ctrl.tableData = ctrl.setDataForTable(result);
      ctrl.percentTotal = ctrl.setMonthlyTotal(ctrl.tableData);
      var dataWithPercent = ctrl.calPercentData(ctrl.tableData, ctrl.percentTotal);
      ctrl.office_count = dataWithPercent.length;

      var sort = [
        {colId: 'categoryName', sort: 'desc'}
      ];
      ctrl.gridOptions.api.setSortModel(sort);
      ctrl.gridOptions.api.setRowData(dataWithPercent);
      ctrl.gridOptions.api.setPinnedBottomRowData(ctrl.percentTotal);
      ctrl.gridOptions.api.sizeColumnsToFit();
      });
    }

    ctrl.calPercentData = function (tableData, percentTotal) {
      for (var i = 0; i < tableData.length; i++) {
          var item = tableData[i];
          if (percentTotal[0].jan.valueData > 0) {
              item.jan.percent = Math.round((item.jan.valueData * 100) / percentTotal[0].jan.valueData);
          }
          if (percentTotal[0].feb.valueData > 0) {
              item.feb.percent = Math.round((item.feb.valueData * 100) / percentTotal[0].feb.valueData);
          }
          if (percentTotal[0].mar.valueData > 0) {
              item.mar.percent = Math.round((item.mar.valueData * 100) / percentTotal[0].mar.valueData);
          }
          if (percentTotal[0].apr.valueData > 0) {
              item.apr.percent = Math.round((item.apr.valueData * 100) / percentTotal[0].apr.valueData);
          }

          if (percentTotal[0].may.valueData > 0) {

              item.may.percent = Math.round((item.may.valueData * 100) / percentTotal[0].may.valueData);
          }

          if (percentTotal[0].jun.valueData > 0) {

              item.jun.percent = Math.round((item.jun.valueData * 100) / percentTotal[0].jun.valueData);
          }
          if (percentTotal[0].jul.valueData > 0) {

              item.jul.percent = Math.round((item.jul.valueData * 100) / percentTotal[0].jul.valueData);
          }
          if (percentTotal[0].aug.valueData > 0) {

              item.aug.percent = Math.round((item.aug.valueData * 100) / percentTotal[0].aug.valueData);
          }
          if (percentTotal[0].sep.valueData > 0) {
              item.sep.percent = Math.round((item.sep.valueData * 100) / percentTotal[0].sep.valueData);
          }
          if (percentTotal[0].oct.valueData > 0) {

              item.oct.percent = Math.round((item.oct.valueData * 100) / percentTotal[0].oct.valueData);
          }
          if (percentTotal[0].nov.valueData > 0) {
              item.nov.percent = Math.round((item.nov.valueData * 100) / percentTotal[0].nov.valueData);
          }
          if (percentTotal[0].dec.valueData > 0) {
              item.dec.percent = Math.round((item.dec.valueData * 100) / percentTotal[0].dec.valueData);
          }
          if (percentTotal[0].total.valueData > 0) {
              item.total.percent = Math.round((item.total.valueData * 100) / percentTotal[0].total.valueData);
          }
      }

      return tableData;
  }


    ctrl.setMonthlyTotal = function(dataTable){
      var data = [];
      var t_monthly_total = $filter('translate')('REPORTS.MULTI.MONTHLY_TOTAL');
      var percent = {
          categoryName: t_monthly_total,
          categoryID: '',
          jan: {
              month: 1,
              valueData: dataTable.sumCategoryValue('jan'),
              percent: 100
          },
          feb: {
              month: 2,
              valueData: dataTable.sumCategoryValue('feb'),
              percent: 100
          },
          mar: {
              month: 3,
              valueData: dataTable.sumCategoryValue('mar'),
              percent: 100
          },
          apr: {
              month: 4,
              valueData: dataTable.sumCategoryValue('apr'),
              percent: 100
          },
          may: {
              month: 5,
              valueData: dataTable.sumCategoryValue('may'),
              percent: 100
          },
          jun: {
              month: 6,
              valueData: dataTable.sumCategoryValue('jun'),
              percent: 100
          },
          jul: {
              month: 7,
              valueData: dataTable.sumCategoryValue('jul'),
              percent: 100
          },
          aug: {
              month: 8,
              valueData: dataTable.sumCategoryValue('aug'),
              percent: 100
          },
          sep: {
              month: 9,
              valueData: dataTable.sumCategoryValue('sep'),
              percent: 100
          },
          oct: {
              month: 10,
              valueData: dataTable.sumCategoryValue('oct'),
              percent: 100
          },
          nov: {
              month: 11,
              valueData: dataTable.sumCategoryValue('nov'),
              percent: 100
          },
          dec: {
              month: 12,
              valueData: dataTable.sumCategoryValue('dec'),
              percent: 100
          },
          total: {
              valueData: dataTable.sumCategoryValue('total'),
              percent: 100,
              month: 13
          }
      };
      data.push(percent);
      return data;
    }

    ctrl.setDataForTable = function(rs){
      var data = [];
      rs.forEach(function(el){
        var d = angular.copy(ctrl.defaultRow);
        d.categoryID = el.office.id;
        d.categoryName = el.office.name;

        var total = 0;

        var orders =  el.report.orders;
        if(orders.length > 0){
          orders.forEach(function(el){
            var monthly_orders = el.monthly_orders;
            monthly_orders.forEach(function(element){
              var m = element.month.toLowerCase();
              d[m].valueData = element.total;

              total = _.sum([ parseFloat(total), parseFloat(element.total)]);
            });
          });
        }

        d.total.valueData = total;
        data.push(d);
      });
      return data;
    }

    Array.prototype.sumCategoryValue = function (prop) {
        let total = 0;
        for (var i = 0, _len = this.length; i < _len; i++) {
            total = _.sum([ parseFloat(total), parseFloat(this[i][prop].valueData)]);
        }
        return total;
    }

    ctrl.clearSearch = function(){
      ctrl.categoryKeySearch = '';
      ctrl.searchCategory();
    }

    ctrl.searchCategory = function () {
      var athleteFilterComponent = ctrl.gridOptions.api.getFilterInstance('categoryName');
      athleteFilterComponent.setModel({
          type: 'contains',
          filter: ctrl.categoryKeySearch
      });
      ctrl.gridOptions.api.onFilterChanged();
    }
    
    ctrl.updateYearOptions = function() {
      var first_date = moment($scope.$root.current_office.created_at).startOf('year');
      var last_date = moment();
      var years_count = last_date.diff(first_date, 'y');

      for (var i = 1; i <= years_count; i++) {
        var year_number = moment().subtract(i,'y').format('YYYY');
        ctrl.rangeOptions.push({
            name: year_number,
            value: year_number
        });
      }
    }

    ctrl.updateCurrentYear = function(){
      ctrl.getOfficeSpending();
    }

    ctrl.gridOptions = {
      angularCompileRows: true,
      components: {
          showCellRenderer: ShowCellRenderer
      },
      columnDefs: columnDefs,
      rowData: null,
      enableColResize: true,
      enableSorting: true,
      rowHeight: 70,
      onCellClicked: handleClick,
      pinnedBottomRowData: null,
      domLayout: 'autoHeight',
      onGridReady: function(params) {
          setTimeout(function () {
              ctrl.gridOptions.api?.resetRowHeights?.();
          }, 500);
      },
      onColumnResized: onColumnResized
    };

    function handleClick(event) {
      // ctrl.handleClickFunction(event);
    }

    function onColumnResized(event) {
      if (event.finished) {
          ctrl.gridOptions.api?.resetRowHeights?.();
      }
    }

    function ShowCellRenderer() { }

    ShowCellRenderer.prototype.init = function (params) {
        var cellBlank = !params.value;
        if (cellBlank) { return null; }
        this.ui = document.createElement('div');

        if(ctrl.selectedYear == moment().year()){
          var month = moment().month() + 1;
          var a  = [];

          for(var i = 1; i <= month; i++){
            a.push(i);
          }

          var index = a.indexOf(params.value.month);
          if(index > -1 || params.value.month == 13){
            this.ui.innerHTML =
                '<div class="value">'
                + $filter('currency')(params.value.valueData, '$', 2) + '' +
                '</div>' +
                '<div class="percent">'
                + params.value.percent + '%' +
                '</div>';
          }else{
            this.ui.innerHTML =
                '<div class="value">'
                + '-' +
                '</div>' ;
          }
        }else{
          this.ui.innerHTML =
              '<div class="value">'
              + $filter('currency')(params.value.valueData, '$', 2) + '' +
              '</div>' +
              '<div class="percent">'
              + params.value.percent + '%' +
              '</div>';
        }
        
    };

    ShowCellRenderer.prototype.getGui = function () {
        return this.ui;
    };

    ctrl.init();
   
  }

}());

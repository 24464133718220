(function () {
  'use strict';
  
  angular
  .module('sowReports')
  .controller('ImplantsDetailReportController', ImplantsDetailReportController);
  
  function ImplantsDetailReportController ($stateParams, sgToast, $filter, sowImplantService, errorService, $state, impHelperService, elementService, $mdDialog) {
    /*jshint validthis: true */
    var ctrl = this;
    
    ctrl.sets = {
      'Implant': {
        color: "#398ee3",
        product_name: 'Implant',
        api_name: 'Implant',
        set_name: 'implants',
        display_name: $filter('translate')('IMPLANTS.IMPLANTS'),
        icon:{
          svg: "styles/img/implants/implants-icon-v3.svg",
        }
      },
      'Closure Cap': {
        color: "#7a41cb",
        product_name: 'Cover Screw',
        api_name: 'Closure Cap',
        set_name: 'caps',
        display_name: $filter('translate')('IMPLANTS.COVER_SCREWS'),
        icon:{
          svg: "styles/img/implants/caps-icon.svg",
        }
      },
      'Healing Abutment': {
        color: "#2eb479",
        product_name: 'Healing Abutment',
        api_name: 'Healing Abutment',
        set_name: 'abutments',
        display_name: $filter('translate')('IMPLANTS.HEALING_ABUTMENTS'),
        icon:{
          svg: "styles/img/implants/healing-icon.svg",
        }
      },
      'Bone Graft': {
        color: "#33bbc4",
        product_name: 'Bone Graft',
        api_name: 'Bone Graft',
        set_name: 'bonegrafts',
        display_name: $filter('translate')('IMPLANTS.BONE_GRAFTS'),
        icon:{
          material: "grain",
        }
      },
      'Membrane': {
        color: "#ff2566",
        product_name: 'Membrane',
        api_name: 'Membrane',
        set_name: 'membranes',
        display_name: $filter('translate')('IMPLANTS.MEMBRANES'),
        icon:{
          material: "healing",
        }
      }
    };
    ctrl.sortOrder = '-date_name';
    ctrl.searchText = '';
    ctrl.current_dataset = "implants";
    ctrl.search_option = 'reference_no';
    ctrl.search_query = '';
    ctrl.months_list = [];
    ctrl.search_by_placeholder = {
      reference_no: "{{'COMMON.REF' | translate}} #",
      lot_number:"{{'COMMON.LOT_NUMBER' | translate}}",
      placement_id:"Placement ID"
    };
    
    ctrl.totals = {
      qty: 0,
      spent: 0,
      consignment: 0,
    };
    
    ctrl.searchFilter = searchFilter;
    ctrl.updateMonth = updateMonth;
    ctrl.undo = undo;
    
    initialize();
    
    return ctrl;
    
    //
    // Initialize the Controller
    //
    function initialize () {
      // ctrl.selected_month = moment($stateParams.month_filter).format('YYYY-MM');
      ctrl.set = ctrl.sets[$stateParams.type];
      setupChart();
      ctrl.month_name = moment($stateParams.month_filter).format('MMMM YYYY');
      load_membership()
      .then(loadItems())
      .then(generateMonthsList)
      .then(function(){
        ctrl.selected_month = $stateParams.month_filter;
      });
      
    }
    
    function load_membership(){
      return  elementService.get('membership', null, {
        endpoint: 'current',
        return_type: 'single',
      }).then(function (membership) {
        
        ctrl.office_first_date = moment.utc(membership.office.implant_start_date);
      });
    }
    
    function setupChart () {
      ctrl.chart = {
        data:[],
        labels: [],
        'options': {
          'responsive': true,
          'maintainAspectRatio': true,
          'tooltips': {
            'enabled': true,
            'displayColors': false,
            'mode': 'single',
            'titleFontFamily': "'Roboto', 'Helvetica', 'Arial', 'Sans-Serif'",
            'footerFontFamily': "'Roboto', 'Helvetica', 'Arial', 'Sans-Serif'",
            'bodyFontFamily': "'Roboto', 'Helvetica', 'Arial', 'Sans-Serif'",
          },
          'scales': {
            'xAxes': [{
              'interval': 1000,
              'gridLines': {
                display: false,
                'drawTicks': true,
                'color': 'rgba(128,142,155,0.25)',
                'zeroLineColor': 'rgba(128,142,155,0.25)',
              },
              'ticks': {
                'fontFamily': "'Roboto', 'Helvetica', 'Arial', 'Sans-Serif'",
                'beginAtZero': true,
              },
            }],
            'yAxes': [{
              'interval': 1000,
              'gridLines': {
                'display': true
              },
              'ticks': {
                'fontFamily': "'Roboto', 'Helvetica', 'Arial', 'Sans-Serif'",
                'beginAtZero': true,
                callback: function(value, index, values) {
                  if (Math.floor(value) === value) {
                    return value;
                  }
                }
              },
            }]
          },
          dataset: {
            barThickness: 2,
          }
        },
        'overrides': {
          'borderWidth': 0,
          'borderColor': "#398ee3",
          'backgroundColor': "#398ee3",
          'hoverBackgroundColor': '#de4745',
        }
      };
      
      var colors = {
        'Implant': "#398ee3",
        'Closure Cap': "#7a41cb",
        'Cover Screw': "#7a41cb",
        'Healing Abutment': "#2eb479",
        'Bone Graft': "#33bbc4",
        'Membrane': "#ff2566",
      };
      
      _.extend(ctrl.chart, {
        'overrides': {
          'borderColor': colors[$stateParams.type],
          'backgroundColor': colors[$stateParams.type],
        }
      });
      
      for (var i = 0; i < moment($stateParams.month_filter).daysInMonth(); i++) {
        var date_label = moment($stateParams.month_filter).startOf('month').add(i,'d').format("MMM D");
        ctrl.chart.labels.push(date_label);
        ctrl.chart.data.push(0);
      }
    }
    
    function loadItems () {
      
      return sowImplantService.monthlyReportData({category:$stateParams.type, month:$stateParams.month_filter})
      .then(function(response){
        ctrl.report_data = $filter('orderBy')(prepData(response),'placed_on');
        
        // if this office has no start date on record for some reason, set it as the first item's date
        if(!ctrl.office_first_date || !ctrl.office_first_date._isValid){
          ctrl.office_first_date = moment.utc(ctrl.report_data[0].placed_on).startOf('month');
        }
      });
    }
    
    function prepData (data) {
      _.forEach(data, function(item){
        // for this item
        item.position = getToothName(item);
        item.sizes = _.replace(item.implant_name, item.implant_properties.product_line, '');
        item.date_name = moment(item.placed_on).format('MMM DD, YYYY');
        
        // for chart
        var date_name = moment(item.placed_on).format('MMM D');
        var position = ctrl.chart.labels.indexOf(date_name);
        if(position >= 0){
          ctrl.chart.data[position] += 1;
        } else {
          ctrl.chart.data.push(1);
          ctrl.chart.labels.push(date_name);
        }
        
        // for totals
        ctrl.totals.spent += parseFloat(item.price) || 0;
        ctrl.totals.consignment += item.on_consignment ? 1 : 0;
      });
      ctrl.totals.qty = data.length;
      
      return data;
    }
    
    function getToothName (item) {
      var tooth_name = "";
      
      if(!item.tooth_placement_format){
        if(item.tooth_placement_value){
          return item.tooth_placement_value;
        } else {
          return '';
        }
      }
      
      if(item.tooth_placement_value === 'LZ'){
        tooth_name = "Zygoma L";
        return tooth_name;
      }
      if(item.tooth_placement_value === 'RZ'){
        tooth_name = "Zygoma R";
        return tooth_name;
      }
      
      var format = impHelperService.getToothPlacementFormats(null, item.tooth_placement_format);
      var tooth_number = item.tooth_placement_value * 1;
      if(format.upper_left.indexOf(tooth_number) >= 0){
        tooth_name = "UL-" + item.tooth_placement_value;
      }
      if(format.upper_right.indexOf(tooth_number) >= 0){
        tooth_name = "UR-" + item.tooth_placement_value;
      }
      if(format.lower_left.indexOf(tooth_number) >= 0){
        tooth_name = "LL-" + item.tooth_placement_value;
      }
      if(format.lower_right.indexOf(tooth_number) >= 0){
        tooth_name = "LR-" + item.tooth_placement_value;
      }
      
      return tooth_name;
    }
    
    function searchFilter (record) {
      return _.startsWith( _.lowerCase(record[ctrl.search_option]), _.lowerCase(ctrl.search_query));
    }
    
    function updateMonth () {
      $state.go('app.reports.implants-detail',{month_filter: ctrl.selected_month, type: $stateParams.type});
    }
    
    function generateMonthsList () {
      ctrl.months_list = [];
      
      var first_month = moment(angular.copy(ctrl.office_first_date)).startOf('month');
      var current_month = moment.utc().startOf('month');
      
      for (var i = first_month; i.isSameOrBefore(current_month); i.add(1,'month') ) {
        var i_month = {
          date: i.format("YYYY-MM-DD"),
          name: i.format("MMMM YYYY"),
        };
        ctrl.months_list.push(i_month);
      }
    }
    
    function undo(item) {
      $mdDialog.show({
        controller: 'poDialogController as dialogCtrl',
        templateUrl: 'sow-reports/modals/implant-return-to-inventory.html',
        parent: angular.element(document.body),
        clickOutsideToClose:true,
        // scope: $scope,
        locals: {
          item: item,
          // cancel: $mdDialog.close,
        },
        bindToController: true,
      })
      .then(function (answer) {
        item.id = item.implant_inventory_item_id;
        sowImplantService.getInventoryItem(item.implant_inventory_item_id)
        .then(function (item) {
          item.was_placed = false;
          sowImplantService.saveInventoryItem(item)
          .then(function (response) {
            const saved_item = impHelperService.extractItemID(response);
            _.remove(ctrl.report_data, ['implant_inventory_item_id', saved_item.id]);
            var type = $stateParams.type !=='Closure Cap' ? $stateParams.type : 'Cover Screw';
            var t_message = $filter('translate')('TOAST.HAS_BEEN_RETURNED_TO_INV');
            sgToast.showSimple( $stateParams.type + t_message);
          }).catch(function (error) {
            var t_message = $filter('translate')('ERRORS.INVENTORY_RETURN')
            errorService.uiErrorHandler(t_message);
          });
        }).catch(function (error) {
          var t_message = $filter('translate')('ERRORS.IMPLANT_LOAD')
          errorService.uiErrorHandler(t_message);
        });
      });
    }
  }
}());

(function () {
  'use strict';
  angular.module('sowCoreUI')
  .controller('sowDialogController', sowDialogController);

  // this will be the base for all dialog controllers, a quick way of implementing it would be to inject this 
  // in the child controller and do `_.extend(this, sowDialogController);`
  // so that you get all of the basic functionality and can improve on it later on
  function sowDialogController ($mdDialog) {
    const ctrl = this;
    
    ctrl.cancelDialog = cancelDialog;
    ctrl.hide = hide;
    ctrl.answer = answer;
    ctrl.handleActionClick = handleActionClick;

    init();

    return ctrl;

    function init () {

    }

    function cancelDialog () {
      return $mdDialog.cancel();
    }

    /**
     * Hides the dialog and provides a return value of `content`
     * inside the `.then()` block wherever the dialog was called from
     *
     * @param {*} content
     * @return {*} 
     */
    function answer (content) {
      return $mdDialog.hide(content);
    }

    function hide () {
      return $mdDialog.hide();
    }

    /**
     * Expects the action object to have a 'callback' function, 
     * otherwise will close the dialog by default
     *
     * @param {*} action
     */
    function handleActionClick (action) {
      if (!action.callback) {
        return answer(action?.return_value);
      } else {
        return action.callback();
      }
    }

  }

}());

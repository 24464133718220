(function () {
  'use strict';

  angular
    .module('sowCertifications')
    .controller('certificationsListController', certListController);

  function certListController ($stateParams, $mdDialog, $filter, sowCertificationService, certificationDataCarrierService, errorService) {
    /*jshint validthis: true */
    var ctrl = this;

    ctrl.cert_types = {
      "All":{
        text:"CERTIFICATIONS.ALL_OPTIONS",
        search_placeholder: "CERTIFICATIONS.SEARCH_ALL",
        name: "COMMON.ALL"
      },
      "Equipment":{
        text:"equipment.",
        search_placeholder: "CERTIFICATIONS.SEARCH_EQUIPMENTS",
        name: "CERTIFICATIONS.EQUIPMENT"
      },
      "License":{
        text:"licenses.",
        search_placeholder: "CERTIFICATIONS.SEARCH_LICENSES",
        name: "CERTIFICATIONS.LICENSE"
      },
      "Permit":{
        text:"permits.",
        search_placeholder: "CERTIFICATIONS.SEARCH_PERMITS",
        name: "CERTIFICATIONS.PERMIT"
      },
    };
    ctrl.selected_tag = 'All';
    
    ctrl.new_certification_dialog = new_certification_dialog;
    ctrl.certificationDataCarrierService = {};
    ctrl.is_all_tag_active = true;
    ctrl.cleanSelectedTags = cleanSelectedTags;
    ctrl.getCategoryLabel = getCategoryLabel;
    ctrl.selectTag = selectTag;
    ctrl.getEvent = getEvent;
    ctrl.filterData = filterData;

    init();

    ctrl.search = "";
    // ctrl.search_filter = search_filter;
    ctrl.check_if_all_tag_active = check_if_all_tag_active;
    ctrl.order_by = order_by;
    ctrl.filter_by = filter_by;

    ctrl.reminder = {
      "Biennial": 24,
      "Annual": 12,
      "Semi-Annual": 6,
      "Monthly": 1,
      "Tri-Monthly": 3,
      "Triennial": 36,
      "Quadrennial": 48
    };
    ctrl.TODAY = moment().toDate();
    return ctrl;

    function init () {
      const id = sow.officeInfo().association_membership.professional_association_id;
      ctrl.show_gsd =
        sow.officeFeatures().aaoms_marketplace || 
        id ==='aaoms' || id ==='dentsply' || id ==='neodent';

      ctrl.certificationDataCarrierService.is_loading = true;
      ctrl.certificationDataCarrierService = certificationDataCarrierService;
      if($stateParams.type !== "All"){
        loadCertifications({category: $stateParams.type});
      } else {
        loadCertifications({});
      }
    }

    function getEvent (a,b) {
      console.info("caught a12 event in a1", a,b);
    }

    function selectTag (tag, key) {
      tag.active = !tag.active; 
      ctrl.check_if_all_tag_active();
      ctrl.selected_tag = key;
      filterData();
    }

    function filter_by(item){
      var search = ctrl.search ? ctrl.search.toLowerCase() : '';

      var keys = Object.keys(ctrl.cert_types);
      var selecteds = keys.filter(function(key){
        return ctrl.cert_types[key].active;
      });

      if(selecteds.length ){
        return selecteds.includes(item.category) && item.name.toLowerCase().indexOf(search) > -1;
      } else {
        return item.name.toLowerCase().indexOf(search) > -1;
      }
    }

    function cleanSelectedTags(){
      var keys = Object.keys(ctrl.cert_types);
      keys.forEach(function(key){
        ctrl.cert_types[key].active = false;
      });
      ctrl.is_all_tag_active = true;
      filterData();
    }

    function check_if_all_tag_active(){


      var keys = Object.keys(ctrl.cert_types);
      var selecteds = keys.filter(function(key){
        return ctrl.cert_types[key].active;
      });

      ctrl.is_all_tag_active = selecteds.length ? false : true;

      if(selecteds.includes("All") || !selecteds.length) {
        ctrl.is_all_tag_active = true;
      }

      if (ctrl.is_all_tag_active) {
        keys.forEach(function(key){
          ctrl.cert_types[key].active = false;
        });
      }
    }

    function new_certification_dialog () {
      $mdDialog.show({
          controller: 'certificationsDetailController',
          controllerAs: 'certdCtrl',
          templateUrl: 'sow-certifications/modals/cert-edit-certification.html',
          parent: angular.element(document.body),
          clickOutsideToClose: true,
          locals: {

          },
          bindToController: true,
        });
    }

    function order_by(cert){
      if(moment(cert.expiry_date).isValid()){
        return cert.expiry_date;
      }
      return cert.name;
    }

    function loadCertifications(obj_filter){
       sowCertificationService.getCertifications(obj_filter)
        .then( function(data){

          var res = [];
          ctrl.is_empty_state = false;
          if(data.length === 0 && ctrl.search ===''){
            ctrl.is_empty_state = true;
          }

          parse_certifications(data);
          ctrl.certificationDataCarrierService.is_loading = false;

        }).catch(function(err){
          errorService.uiErrorHandler(err);
        });
    }

    function parse_certifications (certifications) {
      ctrl.certifications = [];
      ctrl.alert_certifications = [];
      _.forEach(certifications, function(cert){
        var diff = null;

        cert.time_left = moment(cert.last_certified_on).add(ctrl.reminder[cert.renewal_type], 'M').toNow(true);
        diff = moment(cert.last_certified_on).add(ctrl.reminder[cert.renewal_type], 'M').diff(moment(), 'days');

        cert.expiry_date = moment(cert.last_certified_on).add(ctrl.reminder[cert.renewal_type], 'M').toDate();

        if (diff <= 60 && diff > 30) {
          cert.mid_alert = true;
          ctrl.certifications.push(cert);
        } else if ( diff <= 30 ){
          if (diff <= 7 && cert.renewal_type === "Monthly") {
            cert.high_alert = true;
            ctrl.alert_certifications.push(cert);
          } else {
            ctrl.alert_certifications.push(cert);
          }

        } else{
           ctrl.certifications.push(cert);
        }
      });

      var has_licenses = _.find(certifications, { 'category': 'License' });
      var has_permits = _.find(certifications, { 'category': 'Permit' });
      var has_equipments = _.find(certifications, { 'category': 'Equipment' });

      certificationDataCarrierService.set_category('license',has_licenses && !angular.equals({}, has_licenses));
      certificationDataCarrierService.set_category('permit',has_permits && !angular.equals({}, has_permits));
      certificationDataCarrierService.set_category('equipment',has_equipments && !angular.equals({}, has_equipments));

      filterData();
    }

    function getCategoryLabel (certification) {
      var tag = "CERTIFICATIONS.{0}".format(certification.category.toUpperCase());
      var t_category = $filter('translate')(tag);
      return t_category;
    }

    function getFilteredList (list) {
      list = $filter('filter')(list, ctrl.filter_by);
      list = $filter('orderBy')(list, ctrl.order_by);
      return list;
    }

    function filterData () {
      ctrl.filtered_certifications = getFilteredList(ctrl.certifications);
      ctrl.filtered_alerts_certs = getFilteredList(ctrl.alert_certifications);
    }
  }
}());

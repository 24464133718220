angular.module('app.marketplace.ui.inventory')
.controller('inventoryOnHandController', inventoryOnHandController)
.directive('inventoryOnHand', function() {
  return {
    restrict: 'E',
    controller: 'inventoryOnHandController',
    controllerAs: 'iohCtrl',
    scope: true,
    bindToController: {
      item: '='
    },
    template: '<span>{{ iohCtrl.getTotal(iohCtrl.item) }}</span>'
  };
});

function inventoryOnHandController (inventoryItemService, $scope) {
  var ctrl = this;
  ctrl.getTotal = getTotal;

  function getTotal (inventory_item) {
    if(!inventory_item){
      return;
    }
    return inventoryItemService.calculateInventoryOnHand(inventory_item);
  }
}

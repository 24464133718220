{
  'use strict';

  /** @ngInject */
  class LanguageService {
    defaultLanguage = 'en';
    constructor($filter, $rootScope, ConfigService, $window, $translate, AppLanguages, RouteService, zendeskChatService) {
      this.$filter = $filter;
      this.$rootScope = $rootScope;
      this.ConfigService = ConfigService;
      this.$window = $window;
      this.$translate = $translate;
      this.AppLanguages = AppLanguages;
      this.RouteService = RouteService;
      this.zendeskChatService = zendeskChatService;
    }

    /**
     * It sets the language of the app to the language passed in as a parameter
     * (if the language is not supported, we default to english).
     * Refreshes the page if there was a previously selected language and a
     * new one has now been selected
     * @param tag - The language tag to set the language to.
     */
    setLanguage (tag) {
      const previous_language = this.ConfigService.get('language');

      this.$translate.use(tag);
      this.ConfigService.set('language', tag);
      this.$rootScope.current_language = tag;

      this.zendeskChatService.changeZendeskLanguage(tag)
      if (previous_language && previous_language !== tag) {
        // there was a previous setting and the user has updated it, so reload
        // the page to ensure API data is fetched in the updated language
        this.RouteService.reloadPage();
      }
    }

    /**
     * It listens for changes in the language of the page, and sets the language
     * of the app to the new language if it is supported.
     * @param $window - The window object
     */
    listenForGoogleTranslateChange () {
      // The languages supported by Sowingo: ['en', 'fr']
      const VALID_SOWINGO_LANGUAGES = Object.keys(this.AppLanguages);
      // Wait for 200ms to ensure the language has been set by Google Translate
      const WAIT_TIME = 200;

      // This mutation listens for changes in the language attribute of the page
      const handleLanguageChange = () =>{
        // When the language changes, set the language of the app to the new language
        const language = document.documentElement.lang;

        // If the detected language is not supported, exit early
        if (!VALID_SOWINGO_LANGUAGES.includes(language)) {
          return;
        }

        setTimeout(() => {
          // Use Sowingo's locales instead of Google Translate's
          this.setLanguage(language);
        }, WAIT_TIME);
      }

      const observer = new MutationObserver(handleLanguageChange);
      
      observer.observe(document.documentElement, {
        attributes: true, // Listen for changes to the attributes of the element
        attributeFilter: ['lang'], // Listen for changes to the `lang` attribute
      });
    }

    /**
     * If the user has chosen a language, use it. Otherwise, use the browser's language
     */
    determineUserLanguage () {
      const chosen_language = this.ConfigService.get('language')
      const browser_language = _.get(this.$window, 'navigator.language', null);
      if (chosen_language) {
        this.setLanguage(chosen_language);
      } else if (!chosen_language && browser_language){
        const tag = browser_language.split('-')[0];
        this.setLanguage(tag);
      }
    }

    /**
     * It returns an array of all the available languages in the application
     * @returns An array of available languages.
     */
    getAvailableLanguages () {
      return this.AppLanguages;
      // return this.$translate.getAvailableLanguageKeys();
    }

    /**
     * It takes a key, and returns the translation of that key
     * @param key - The key to the locale string you want to get translated.
     * @param options - An object that can contain parameters to the locale string, 
     * property names depend on the specific string being used
     * @returns The value of the key in the translation file.
     */
    translateKey (key, options) {
      return this.$filter('translate')(key, options);
      // TODO: try to use $translate.instant(); (depends on loader, might not update digest cycle)
    }

    /**
     * Get the currently selected language (default is 'en')
     * @returns string with language in ISO 639-1 format (two letters)
     */
    getCurrentLanguage () {
      const storedValue = this.ConfigService.get('language');
      return storedValue || this.defaultLanguage;
    }
  }

  angular.module('sowLanguage')
    .service("sowLanguageService", LanguageService);

}

(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.subscriptions')
    .directive('premiumPlan', premiumPlanDirective);

  function premiumPlanDirective ($log, $filter) {
    return {
      'restrict': 'E',
      'replace': true,
      'controller': 'sowPlanController',
      'controllerAs': 'planCtrl',
      'templateUrl': 'templates/marketplace/subscriptions/plan-style-b.html',
      'scope': {
        'selectedPlan': '=',
        'isCurrent': '@',
        'isLarge': '@',
        'isTrial': '@',
        'isDowngrade': '@',
        'action': '&',
        'noButton': '@',
        'overrideButtonText': '@buttonText',
        'discountValue': '@',
      },
      'link': function ($scope, $element, $attrs, $ctrl) {
        var isCurrent,isDowngrade;
        $ctrl.init({
          'class': 'premium-plan',
          'name': 'Premium',
        });

        if ($scope.overrideButtonText) {
          $ctrl.setButtonText($scope.overrideButtonText);
        }

        function updateState () {
          isCurrent = $scope.isCurrent ? $scope.$parent.$eval($scope.isCurrent) : false;
          isDowngrade = $scope.isDowngrade ? $scope.$parent.$eval($scope.isDowngrade) : false;
          $scope.buttonText = getButtonText();
        }

        function getButtonText() {
          if(isDowngrade){
            return 'Upgrade to Premium';
          }
          if (isCurrent) {
            return 'Current Plan';
          }
        }

        $scope.$watch('isCurrent', updateState);
      }
    };
  }
}());

(function () {
  'use strict';

  angular
    .module('app.marketplace.system')
    .controller('DefaultController', DefaultController);


  function DefaultController($scope, $rootScope, RouteService, sessionService) {
    /*jshint validthis: true */
    var ctrl = this;

    init();

    return ctrl;

    function init() {
      $rootScope.showLoadingOverlay = true;
      $scope.$on("$destroy", function () {
        $rootScope.showLoadingOverlay = false;
      });
      sessionService.onInit()
        .then(function () {
          RouteService.goToDefault();
        });

    }
  }

}());

(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.marketing')
    .directive('marketingActionBar', MarketingActionBar);

  function MarketingActionBar () {
    return {
      'restrict': 'AC',
      'controller': MarketingActionBarController,
      'controllerAs': '$actionBarCtrl',
      'templateUrl': 'templates/marketplace/marketing/action-bar.html',
    };
  }

  function MarketingActionBarController ($rootScope, $state) {
    /*jshint validthis: true */
    var ctrl = this;

    ctrl.isActive = isActive;
    ctrl.click = click;

    return ctrl;

    function isActive (item) {
      return item.state && $state.is(item.state);
    }

    function click ($event, item) {
      $rootScope.menuHandle(item);
    }
  }
}());

(function () {
  'use strict';

  angular.module('app.marketplace.ui.progress_header')
         .directive('progressHeader', progressHeaderDirective);

  function progressHeaderDirective () {
    return {
      restrict: 'E',
      replace: true,
      controller: progressHeaderCtrl,
      controllerAs: '$ctrl',
      template: [
        '<nav class="progress-header" layout layout-align="center center">',
        '  <div layout="column"',
        '       layout-align="center center"',
        '       class="progress-item"',
        '       ng-repeat="stage in $ctrl.stages"',
        '       ng-class="{complete: stage.complete, current: stage.current}">',
        '    <div class="dot"><div class="inner-dot"></div></div>',
        '  </div>',
        '</nav>',
      ].join("\n"),
      scope: {
        stages: '=',
        currentStage: '=',
        numberOfDots: '='
      }
    };
  }

  function progressHeaderCtrl ($scope, $log, $state) {
    /*jshint validthis: true */
    var ctrl = this;

    ctrl.current = null;
    ctrl.stages = [];

    return init();

    //
    // Initialize the controller
    //
    function init () {
      update();
      $scope.$watch('currentStage', update);
    }

    //
    // Update the controller's state
    //
    function update () {
      var current = null;

      ctrl.stages = $scope.stages.map(function (item, $index) {
        if ($scope.currentStage === item) {
          current = $index;
        }

        return {
          index: $index,
          complete: (current === null || $index < current),
          current: (current === $index),
        };
      });
      if($scope.numberOfDots){
        ctrl.stages = ctrl.stages.slice(0,$scope.numberOfDots);
      }

      if (current === null) {
        $log.error('Unable to determine what the current stage is. stages:%o currentStage:%o',
            $scope.stages, $scope.currentStage);
      }
    }
  }

}());

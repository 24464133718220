(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.subscriptions')
    .directive('associationDiscount', associationDiscountDirective);

  function associationDiscountDirective ($log) {
    return {
      'restrict': 'E',
      'replace': true,
      'templateUrl': 'templates/marketplace/subscriptions/association-discount.html',
      'scope': {
        'professional_association_id': '@professionalAssociationId',
        'discount': '=',
      }
    };
  }
}());

angular.module("app.marketplace.ui.office")
.controller("officeCreationController",
    [ "$scope", "elementService", "$filter", "$mdDialog", "officeModel", "membershipService", "sessionService",
      function($scope, elementService, $filter, $mdDialog, officeModel, membershipService, sessionService) {

      $scope.officeModel = officeModel;
      $scope.new_office = {
          teamMembers : [{}] //Init and add an initial email slot
      };

      $scope.close = function(){
        $mdDialog.cancel();
      };

      $scope.submit = function(){
        if($scope.officeForm.$valid){
          $scope.submittingForm = true;

          $scope.new_office.invites = [];

          for(var i in $scope.new_office.teamMembers){
            if($scope.new_office.teamMembers[0].email){
              $scope.new_office.invites.push($scope.new_office.teamMembers[0].email);
            }
          }
          _.map($scope.new_office.teamMembers, function(member){
            if(member.email){
              $scope.new_office.invites.push(member.email);
            }
          });

          elementService.submit("office", {"endpoint" : "create", "element" : $scope.new_office})
          .then(function (office) {
            $scope.submittingForm = false;
            membershipService.setCurrent(office.memberships[0])
            .then(function(){
              sessionService.refreshSession();//TODO reasses this should not be required but the convoluted design of session+office+memberships requires this refresh.
            });

            $mdDialog.hide("Office created");
          }).catch(function(error) {
            var t_message = $filter('translate')('ERRORS.OFFICE_CREATE')
            errorService.uiErrorHandler(error || t_message, 0);
            $scope.submittingForm = false;
            $mdDialog.cancel();
          });
        }
      };
    }]);

(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.shopping')
    .controller('ShopByVendorCtrl', ShopByVendorCtrl);

  function ShopByVendorCtrl ($scope, $filter, $rootScope, $log, $timeout, $stateParams, productService, vendorService, errorService) {
    /*jshint validthis: true */
    var ctrl = this;
    var delayedFilters = null;

    $scope.products = [];
    $scope.vendor = null;
    $scope.loadingVendor = false;
    $scope.loadingProducts = false;
    $scope.loadingError = false;

    ctrl.categoryFilterOptions = [];
    ctrl.subcategoryFilterOptions = [];
    ctrl.sortByOptions = ['Product Name', 'Manufacturer Name', 'Price: High to Low', 'Price: Low to High'];
    ctrl.sortByField = 'Product Name';
    ctrl.categoryFilter = '';
    ctrl.subCategoryFilter = '';
    ctrl.products = [];

    ctrl.filterChanged = filterChanged;
    ctrl.updateFilters = updateProductFilters;
    ctrl.shouldShowVendor = shouldShowVendor;

    initialize();

    return ctrl;

    function initialize () {
      $scope.$watchCollection('categories_array', rebuildFilterOptions);
      $scope.$watch(function () { return ctrl.categoryFilter; }, function () { ctrl.subCategoryFilter = ''; });

      if ($stateParams.vendorId) {
        $scope.loadingVendor = true;

        vendorService.getVendor($stateParams.vendorId).then(function (vendor) {
          $scope.vendor = vendor;
          $scope.loadingVendor = false;
          refreshProducts();
        });

        $scope.$watch('current_medical_field', refreshProducts());
      }
    }

    //
    // Return true/false to control if a vendor should be shown or hidden in
    // the "all suppliers" list.
    //
    function shouldShowVendor (vendor) {
      return $filter('supplierProductCount')(vendor) > 0;
    }

    //
    //
    //
    function filterChanged () {
      if (delayedFilters) {
        delayedFilters();
      }

      delayedFilters = $timeout(function () {
        updateProductFilters();
        delayedFilters = null;
      }, 100);
    }

    //
    // Quickly extract the product price for the current vendor
    //
    function extractProductPrice (product) {
      var vendor = _.find(product.vendors, {id: $scope.vendor.id});

      if (vendor) {
        return vendor.current_price;
      } else {
        return undefined;
      }
    }

    //
    // Update Product List Filters / Sorts
    //
    function updateProductFilters () {
      var products = ctrl.products.slice();
      var sortExpression;
      var reverseSort;

      //
      // Apply Category Filters
      //
      if (ctrl.subCategoryFilter) {
        products = products.filter(function (product) {
          return _.includes(_.map(product.categories, 'id'), ctrl.subCategoryFilter);
        });
      } else if (ctrl.categoryFilter) {
        products = products.filter(function (product) {
          return _.includes(_.map(product.categories, 'parent.id'), ctrl.categoryFilter);
        });
      }

      //
      // Generate Sort Expression
      //
      if (ctrl.sortByField === 'Manufacturer Name') {
        sortExpression = 'manufacturer.name';
        reverseSort = false;
      } else if (ctrl.sortByField === 'Price: High to Low') {
        sortExpression = extractProductPrice;
        reverseSort = true;
      } else if (ctrl.sortByField === 'Price: Low to High') {
        sortExpression = extractProductPrice;
        reverseSort = false;
      } else {
        sortExpression = 'name';
        reverseSort = false;
      }

      $scope.products = $filter('orderBy')(products, sortExpression, reverseSort);
    }

    //
    // Rebuild Filter Options
    //
    // Parse the unfiltered list of products and generate a list of all
    // Categories / Subcategories to display 
    //
    function rebuildFilterOptions () {
      var categories = {};
      var subcategories = {};

      function addCategory (category) {
        if (!category.parent && !categories[category.id]) {
          categories[category.id] = category;
        }

        else if (category.parent && !subcategories[category.id]) {
          subcategories[category.id] = category;
        }

        if (category.parent) {
          addCategory(category.parent);
        }
      }

      _.forEach(ctrl.products, function (product) {
        if (product.categories && product.categories.length > 0) {
          _.forEach(product.categories, addCategory);
        }
      });

      ctrl.categoryFilterOptions = _.values(categories);
      ctrl.subcategoryFilterOptions = _.values(subcategories);
    }

    //
    // Reload List of Products from API
    //
    function refreshProducts () {
      if (!$scope.vendor) {
        return;
      }

      ctrl.products = [];
      $scope.loadingProducts = true;
      $scope.loadingError = false;

      var filteredProductIds = filterProductIds($scope.vendor.product_ids);

      return productService.getProductsById(filteredProductIds).then(function (products) {
        ctrl.products = products;
        updateProductFilters();
        rebuildFilterOptions();
      }).then(null, function (error) {
        errorService.uiErrorHandler(error);
        $scope.loadingError = true;
      })['finally'](function () {
        $scope.loadingProducts = false;
      });
    }

    //
    // Filter a list of "product id objects" into a list of product id strings.
    //
    function filterProductIds (unfilteredProductIds) {
      var countryId = $rootScope.current_country.id;
      var medicalFieldId = $rootScope.current_medical_field.id;

      var filteredProductIds = _.filter(unfilteredProductIds, function (product) {
        return (
          product.country_id === countryId &&
          (!product.medical_field_id || product.medical_field_id === medicalFieldId)
        );
      });

      return _.map(filteredProductIds, 'product_id');
    }
  }
}());

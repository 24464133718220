(function () {
  'use strict';

  const poActivityLog = {
    selector: 'poActivityLog',
    templateUrl: 'sow-orders/components/po-activity-log.html',
    controller: 'poActivityLogCtrl',
    controllerAs: 'poALCtrl',
    bindings: {
      events: '=',
      loading: '=',
    },
  }

  /** @ngInject */
  class poActivityLogCtrl {
    constructor(poHelperService) {
      this.poHelperService = poHelperService
    }

    $onInit() {
      this.status_data = this.poHelperService.status_data;
    }

  }

  angular.module('sowOrders')
    .controller(poActivityLog.controller, poActivityLogCtrl)
    .component(poActivityLog.selector, poActivityLog);

})();
(function () {
  'use strict';
  angular.module("app.marketplace.ui.medications")
    .controller('medicationsActionBarController', medABController);

  function medABController($q, $rootScope, $state, $stateParams, $mdDialog, $filter, sgToast, sowMedicationService, errorService, inventoryItemService, dataSharingService, PurchaseOrderService, DeleteInventoryActivePODialog, membershipService, poHelperService, AccessService) {
    /*jshint validthis:true*/
    var ctrl = this;

    ctrl.shopToast = shopToast;
    ctrl.addItem = addItem;
    ctrl.add_to_shopping_list = add_to_shopping_list;
    ctrl.backToMedications = backToMedications;

    ctrl.list_purchase_orders = list_purchase_orders;
    ctrl.clear_checked_items = clear_checked_items;
    ctrl.delete_items = delete_items;

    ctrl.$onInit = init;

    return ctrl;

    function init() {
      ctrl.dataSharingService = dataSharingService;

      if (!ctrl.dataSharingService.alert_count) {
        sowMedicationService.getMedicationAlerts()
          .then(function (alerts) {
            dataSharingService.alert_count = alerts.expiring.length + alerts.minimum_level.length;
          });
      }
      defineLocks();
    }

    function backToMedications() {
      $state.go('app.medications.list', $stateParams);
    }

    function add_to_shopping_list() {
      var items = [];
      _.each(ctrl.dataSharingService.checked_items, function (item) {
        items.push({
          id: item.inventory_item_id
        });
      });

      inventoryItemService.addToShoppingList(items)
        .then(function (res) {
          var text = $filter('translate')('COMMON.ADDED_TO_SL');
          sgToast.showSimple(text);
          clear_checked_items();

        });
    }

    function delete_items(ev) {
      var allowed_items = _.filter(ctrl.dataSharingService.checked_items, function (item) {
        var active_orders = _.get(item, 'po_status.Active');
        return (_.size(active_orders) === 0);
      });
      var forbidden_items = _.filter(ctrl.dataSharingService.checked_items, function (item) {
        var active_orders = _.get(item, 'po_status.Active');
        return (_.size(active_orders) > 0);
      });
      var itemCount = allowed_items.length;
      var title;
      var t_yes = $filter('translate')('COMMON.YES');
      var t_no = $filter('translate')('COMMON.NO');

      if (itemCount > 1) {
        title = $filter('translate')('MEDICATIONS.DELETE_Q', { 'x': itemCount });
      } else {
        title = $filter('translate')('MEDICATIONS.DELETE_Q');
      }

      var confirm = $mdDialog
        .confirm()
        .title(title)
        .targetEvent(ev)
        .ok(t_yes)
        .cancel(t_no);

      if (_.size(forbidden_items)) {
        return DeleteInventoryActivePODialog.show(forbidden_items, ev)
          .then(function () {
            if (_.size(allowed_items)) {
              return confirmDelete();
            } else {
              clear_checked_items();
            }
          });
      } else {
        return confirmDelete();
      }

      function confirmDelete() {
        return $mdDialog.show(confirm)
          .then(function () {
            var promises = [];

            promises = _.map(allowed_items, function (item) {
              return sowMedicationService.deleteMedication(item);
            });

            $q.all(promises)
              .then(function (result) {
                $state.reload()
                  .then(function () {
                    var t_message = $filter('translate')('TOAST.MEDS_DELETED');
                    sgToast.showSimple(t_message);
                  });

                clear_checked_items();
              })
              .catch(function (error) {
                errorService.uiErrorHandler(error);
              });
          });
      }
    }

    function clear_checked_items() {
      _.map(ctrl.dataSharingService.checked_items, function (checked_item) {
        checked_item.checked = false;
      });
      ctrl.dataSharingService.checked_items = angular.copy([], ctrl.dataSharingService.checked_items);
    }


    function list_purchase_orders() {
      // load list
      var po_list = [];
      PurchaseOrderService.getAllByStatus('Draft')
        .then(function (response) {
          po_list = _.values(response);
          if (!po_list.length) {
            create_purchase_order();
            return;
          }
          po_list = _.sortBy(po_list, function (po) { return po.supplier.name; });
          // display dialog
          $mdDialog.show({
            controller: 'poDialogController as dialogCtrl',
            templateUrl: 'templates/marketplace/medications/modals/add-to-purchase-order.html',
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            locals: {
              po_list: po_list,
            },
            bindToController: true,
          })
            .then(function (po) {
              if (po) {
                add_to_purchase_order(po);
              } else {
                create_purchase_order();
              }
            });
        });

    }

    function add_to_purchase_order(order) {
      var including_items = _.map(ctrl.dataSharingService.checked_items, function (medicationItem) {
        return {
          'inventory_item_id': medicationItem.inventory_item_id,
          'sku': medicationItem.buy_by_case ? medicationItem.case_sku : medicationItem.pkg_sku,
          'unit_cost': medicationItem.buy_by_case ? medicationItem.case_price : medicationItem.price || 0.00,
          'quantity': 1,
          'po_buy_by_case': medicationItem.buy_by_case,
        };
      });

      order.items = _.concat(order.items, including_items);

      return PurchaseOrderService.saveDraft(order).then(function (po) {
        clear_checked_items();
        $state.go('app.orders.purchase.draft', { poId: po.id });

      }).catch(function (error) {
        /* the service shows an error toast in this case so we won't also do so here */
        if (error?.internal_code === 'PO_HRID_DUPLICATE') {
          return;
        }
        errorService.uiErrorHandler(error);
      });
    }

    function create_purchase_order () {
      const initial_data = poHelperService.getInitialPoData(ctrl.dataSharingService.checked_items);
      clear_checked_items();
      $state.go('app.orders.purchase.draft', initial_data);
    }

    function shopToast(inventory_item_id) {
      inventoryItemService.addToShoppingList([{ id: inventory_item_id }])
        .then(function (res) {
          var text = $filter('translate')('COMMON.ADDED_TO_SL');
          sgToast.showSimple(text);
        });

    }

    function addItem(medication_type) {
      $mdDialog.show({
        controller: 'addMedicationController as addMedCtrl',
        templateUrl: 'templates/marketplace/medications/modals/add-medication.html',
        parent: angular.element(document.body),
        clickOutsideToClose: true,
        bindToController: true,
        locals: {
          medication_type: medication_type
        }
      });
    }

    function defineLocks () {
      const membership = membershipService.get();
      
      const add_new_lock_condition = _.get(membership, "user_properties.medications.disable_add_new", false);
      ctrl.disabled_add_new = add_new_lock_condition;

      const delete_lock_condition = _.get(membership, "user_properties.medications.disable_delete", false);
      ctrl.disabled_delete = delete_lock_condition;
      
      const master_permission_condition = _.values(membership?.user_permissions).includes('master_permission');

      const orders_permission_condition = _.values(membership?.user_permissions).includes('marketplace_orders_purchase_orders');
      const orders_access_condition = orders_permission_condition || master_permission_condition;

      const disable_po_condition = _.get(membership, 'user_properties.permission_overrides.disable_purchase_orders', false);
      ctrl.disable_purchase_orders = !orders_access_condition || disable_po_condition;

      ctrl.disable_shopping_list = !AccessService.hasShoppingListAccess();
    }

  }

}());

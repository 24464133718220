(function () {
  'use strict';
  angular
    .module('app.marketplace.ui.purchaseorders')
    .controller('poDraftController', PODraftController);

  function PODraftController ($q, $scope, $filter, $mdDialog, $mdSelect, $state, $mdToast, $timeout, $rootScope,
    errorService, PurchaseOrderService, SupplierDialog, officeService,  searchService, SupplierService,  
    poHelperService, accountService, inventoryItemService, AccessService, sowAnalyticsService, sowApprovalService, sowApprovalHelperService, sowExternalVendorService, sowExternalVendorAccountService, msHelperService) {
    /*jshint validthis: true */
    var ctrl = this;
    ctrl.discarding = false;
    ctrl.saving = false;
    ctrl.editingActive = $state.params.editingActive;
    ctrl.itemTypeOrder = {
      notreceived: 0,
      Received: 1,
      Cancelled: 2
    };
    var t_valid_qty = $filter('translate')('ERRORS.VALID_QUANTITY');
    var t_in_inventory = $filter('translate')('ERRORS.IN_INVENTORY');
    var t_select_date = $filter('translate')('ERRORS.SELECT_DATE');
    var t_add_id = $filter('translate')('ERRORS.ADD_ID');
    var t_add_product = $filter('translate')('ERRORS.ADD_PRODUCT');
    var t_need_supplier = $filter('translate')('ERRORS.NEED_SUPPLIER');
    var t_select_user = $filter('translate')('ERRORS.SELECT_USER');
    ctrl.errorMessages = [
      { msg: t_valid_qty, show: false },
      { msg: t_in_inventory, show: false },
      { msg: t_select_date, show: false },
      { msg: t_add_id, show: false },
      { msg: t_add_product, show: false },
      { msg: t_need_supplier, show: false },
      { msg: t_select_user, show: false }
    ];
    ctrl.bundles = [];
    ctrl.can_update_prices = false;
    ctrl.can_update_stock = false;
    ctrl.stock_update_loading = false;

    //properties:
    ctrl.officeUsers = [];
    ctrl.officeSuppliers = [];

    ctrl.poDraft = {};
    ctrl.initialPo = {};
    ctrl.suggested_quantities = {};
    ctrl.default_tax = 0;
    ctrl.displayed_view = 'details';
    ctrl.events = null;
    ctrl.loading_events = false;

    //functions:
    ctrl.itemSearch = itemSearch;
    ctrl.skuLookup = skuLookup;
    ctrl.selectedItemChanged = selectedItemChanged;
    ctrl.notActionedItem = notActionedItem;
    ctrl.poHasChanged = poHasChanged;
    ctrl.focusField = focusField;
    ctrl.isFieldFocused = isFieldFocused;
    ctrl.clearFocus = clearFocus;
    ctrl.validateItemQuantity = validateItemQuantity;
    ctrl.validateItemProduct = validateItemProduct;

    ctrl.getTotalUnits = getTotalUnits;
    ctrl.getItemsTotal = getItemsTotal;
    ctrl.getTotalTax = getTotalTax;
    ctrl.getTotalCost = getTotalCost;
    ctrl.removeItem = removeItem;
    ctrl.newSupplierDialog = newSupplierDialog;
    ctrl.editSupplier = editSupplier;
    ctrl.openCalendar = poHelperService.openCalendar;
    ctrl.poHelperService = poHelperService;

    ctrl.saveDraft = saveDraft;
    ctrl.activateOrder = activateOrder;

    ctrl.isDuplicating = false;
    ctrl.isImplantItem = isImplantItem;
    ctrl.selectView = selectView;
    ctrl.showTrackingModal = showTrackingModal;
    ctrl.handleSupplierChange = handleSupplierChange;

    ctrl.shouldDisablePriceInput = shouldDisablePriceInput;
    ctrl.shouldShowStockColumn = shouldShowStockColumn;
    ctrl.shouldShowPriceInput = shouldShowPriceInput;
    ctrl.canInteractWith = canInteractWith;
    ctrl.shouldShowFees = shouldShowFees;
    ctrl.shouldShowDiscounts = shouldShowDiscounts;
    ctrl.onAddAccountNumberClick = onAddAccountNumberClick;
    ctrl.onEditAccountNumberClick = onEditAccountNumberClick;
    ctrl.handleAccountNumberModalChange = handleAccountNumberModalChange;
    ctrl.getAccountNumberHtmlTemplate = getAccountNumberHtmlTemplate;
    ctrl.handleSupplierModalChange = handleSupplierModalChange;

    const po_id = $state.params.poId;

    ctrl.$onInit = init;

    return ctrl;

    function init () {
      ctrl.loading = true;
      defineLocks();
      $scope.$on("duplicate_po", duplicatePo);
      $scope.$on("email_po", emailPo);
      $scope.$on('purchase_order_print', printHandler);
      // Unsaved Warning Dialog
      $scope.$on('$stateChangeStart', stateChangeHandler);
      $scope.$on('po-emailed', handleEmailEvent);
      $scope.$on('officeService: office-changed', () => {
        ctrl.has_office_changed = true;
      });

      // External vendor data will be updated once any of these are changed
      $rootScope.$watch('external_vendors', () => _setExternalVendors());
      $rootScope.$watch('external_vendors_actions', () => _setExternalVendors());
      $scope.$watch(() => ctrl.poDraft?.supplier?.id, () => _setExternalVendors());

      ctrl.is_date_picker_hidden = ctrl.auto_update_date_on_active;
      
      if (!po_id) {
        setupNewDraft();
        getSuppliers();
      }else{
        //fill poDraft object with data from po Service
        PurchaseOrderService.get(po_id).then(result => {
          updatePo(result);
          reorderItems();
          makeBundles(result.items);
          $rootScope.$broadcast('po_loaded', result.id, result);
          _setExternalVendors();
        }).finally(() => {
          ctrl.loading = false;
          getSuppliers();
        });
      }

      officeService.getTaxRate(ctrl.tax_exempt).then(function(result){
        ctrl.default_tax = result.hst;
        ensureValidItems();
      });
      officeService.getOfficeUsers().then(function(users){
        ctrl.officeUsers = _.filter(users, 'is_active');
      });
    }

    function reorderItems () {
      var ordered_list = _.sortBy(ctrl.poDraft.items, function(item){
        return item.name;
      });
      for (const item of ordered_list) {
        matchBuyingAndTracking(item);
      }
      _.set(ctrl.poDraft, 'items', ordered_list);
      inventoryItemService.getSuggestedQuantities(ordered_list)
        .then(res => ctrl.suggested_quantities = res || {})
        .catch(err => console.error(err));
    }

    async function setupNewDraft () {
      if ($state.params.shopping_list_po && ctrl.use_single_supplier) {
        ctrl.lock_supplier = true;
      }
      const current_user = accountService.get();
      const initial_items = $state.params.initial_items || [{}];
      const initial_supplier = $state.params.supplier;

      const new_PO = {
        name: null,
        items: initial_items,
        po_date: new Date(),
        supplier: initial_supplier,
        created_by: current_user,
        status: 'Draft',
        notes: '',
        shipping_cost: 0
      };
      if (ctrl.use_hrid) {
        try {
          const hrid = await PurchaseOrderService.getHRID();
          new_PO.name = PurchaseOrderService.getPoName(hrid, 'edi');
          new_PO.hrid = hrid;
        } catch (error) {
          errorService.uiErrorHandler(error);
        }
        
      } else {
        try {
          const count = await PurchaseOrderService.count();
          new_PO.name = PurchaseOrderService.getPoName(count + 1);
        } catch (error) {
          errorService.uiErrorHandler(error);
        }
      }
      
      updatePo(new_PO);
      reorderItems();
    }

    function printHandler () {
      PurchaseOrderService.print(ctrl.poDraft).catch(function (error) {
        errorService.uiErrorHandler(error);
      });
    }

    function stateChangeHandler (event, toState, toParams, fromState, fromParams) {
      if (!['app.orders.purchase.active', 'app.orders.purchase.draft'].includes(toState.name) && ctrl.poHasChanged() && !ctrl.discarding && !ctrl.has_office_changed) {
        event.preventDefault();

        $mdDialog.show({
            controller: 'poDialogController',
            controllerAs: 'poDialogCtrl',
            templateUrl: 'templates/marketplace/purchase-orders/modals/po-unsaved-warning.html',
            parent: angular.element(document.body),
            clickOutsideToClose: true,
          })
        .then(function (answer) {
          // Allow state change
          if (answer) {
            if (!ctrl.poDraft.status || ctrl.poDraft.status === 'Draft') {
              ctrl.saveDraft().then(function () {
                $state.go(toState.name, toParams);
              });
            } else {
              ctrl.activateOrder().then(function () {
                $state.go(toState.name, toParams);
              });
            }
          } else {
            ctrl.discarding = true;
            ctrl.poDraft = {};
            $state.go(toState.name, toParams);
          }
        });
        }
      }

    function poHasChanged(){
      var statement = false;

      var newPO = _.omit(ctrl.poDraft, _.isNull);
      var oldPO = _.omit(ctrl.initialPo, _.isNull);

      statement = angular.toJson(newPO) !== angular.toJson(oldPO);

      return statement;
    }

    function getSuppliers () {
      ctrl.is_loading_suppliers = true;
      SupplierService.getSupplierSalesReps()
        .then(suppliers => {
          ctrl.officeSuppliers = $filter('orderBy')(_.values(suppliers), 'name');
          generateSupplierRepOptions();
          ctrl.sales_reps = [];
          for (const supplier of ctrl.officeSuppliers) {
            for (const rep of supplier.sales_reps || []) {
              ctrl.sales_reps.push(rep);
            }
          }
          ensureValidSelectedOption();
        })
        .finally(() => {
          ctrl.is_loading_suppliers = false;
        })
    }

    /**
     * Ensures that the selected supplier/rep option is valid in case this
     * PO was created from Shopping List and $state.params includes the ID
     * of a supplier with at least one sales rep. If so we must find the
     * option which corresponds to that supplier's first/primary rep.
     *
     * @return {*}
    */
    function ensureValidSelectedOption() {
      const selected_option_id = _.get(ctrl, 'poDraft.supplier.option_id');
      const supplier_rep_options = _.values(ctrl.supplier_rep_options);
      if (selected_option_id && supplier_rep_options.length) {
        const valid_selected_option = supplier_rep_options.find(({ option_id }) => option_id === selected_option_id);
        if (!valid_selected_option) {
          const supplier_id = _.get($state, 'params.supplier.id', _.get(ctrl, 'poDraft.supplier.id'));
          const selected_supplier = _.values(ctrl.officeSuppliers).find(({ id }) => id === supplier_id);
          const selected_rep = findSelectedRep(selected_supplier);
          if (selected_rep) {
            const valid_supplier = supplier_rep_options.find(({ option_id }) => option_id === selected_rep.id) || {};
            _updateOrderSupplier(valid_supplier);
          }
        }
      }
    }

    /** 
     * Ensures that no items have a nullish unit_cost. 
     * 
     * @return {*} 
    */
    function ensureValidItems() {
      _.each(ctrl.poDraft.items, (item) => {
        if (_.isEmpty(item)) {
          return;
        }
        const inventory_item_price = item.inventory_item?.price || 0;
        item.unit_cost = item.unit_cost ?? inventory_item_price;
        if (ctrl.default_tax) {
          // if the item has a nullish tax_rate property, set
          // that property to the default tax rate (in case the
          // PO draft was created using the shopping list)
          item.tax_rate ??= ctrl.default_tax;
        }
      });
    }

    function generateSupplierRepOptions() {
      const options = [];
      for (const supplier of ctrl.officeSuppliers) {
        const vendors_data = ctrl.has_multi_account_numbers ? 
          { 
            vendor_customer_number_list: supplier.vendor_customer_number_list,
            vendor_customer_number: supplier.vendor_customer_number 
          } : {};

        if (_.get(supplier, 'sales_reps.length')) {
          for (const rep of supplier.sales_reps) {
            options.push({
              id: supplier.id,
              is_primary_rep: rep.is_primary,
              name: supplier.name,
              option_id: generateOptionID(supplier.id, rep.id),
              po_info: supplier.po_info,
              sales_rep_email: rep.email,
              sales_rep_id: rep.id,
              sales_rep_name: rep.name,
              show_rep_info: supplier.sales_reps.length > 1 && (rep.name || rep.email),
              ...vendors_data,
            });
          }
        } else {
          options.push({
            id: supplier.id,
            name: supplier.name,
            option_id: generateOptionID(supplier.id),
            po_info: supplier.po_info,
            ...vendors_data,
          });
        }
      }
      ctrl.supplier_rep_options = options;
    }

    function itemSearch(name) {
      if (!name) {
        $scope.search = {};
        return $scope.search.all;
      }

      return searchService
        .inventorySearch(name, ctrl.locked_supplier_id)
        .then(function(searchElements) {
          var copiedSearch = angular.copy(searchElements);

          var results = [];

          _.each(copiedSearch, function(item, i) {
            if(!itemAccess(item)){
              return;
            }
            // prevent double item search
            var unit_on_list = _.find(ctrl.poDraft.items, function(poi){
              var same_id = (item.id === _.get(poi, 'inventory_item.id', null));
              return same_id && !_.get(poi, 'inventory_item.po_buy_by_case', null);
            });
            var pack_on_list = _.find(ctrl.poDraft.items, function(poi){
              var same_id = (item.id === _.get(poi, 'inventory_item.id', null));
              return same_id && _.get(poi, 'inventory_item.po_buy_by_case', _.get(poi, 'inventory_item.buy_by_case', null));
            });

            if (item.buy_by_case && !pack_on_list) {
              var copied = angular.copy(item);
              copied.po_buy_by_case = true;
              results.push(copied);
            }
            if(!item.buy_by_case && !unit_on_list) {
              var newItem = angular.copy(item);
              newItem.po_buy_by_case = false;
              results.push(newItem);
            }
            
          });

          $scope.search = {};
          $scope.search.inventory_items = _.uniq(results);

          // Only allowing selecting of inventory items,
          // $scope.search.products, $scope.search.product_templates
          $scope.search.all = $filter('orderBy')($scope.search.inventory_items, '-_score');

          $scope.search.all = $filter('limitTo')($scope.search.all, 50);


          return $scope.search.all;
        });
    }

    function itemAccess (item) {
      var implant_flag = _.get(sow.officeFeatures(), 'implants', null);
      var inventory_flag = _.get(sow.officeFeatures(), 'inventory_full', null);
      var meds_flag = _.get(sow.officeFeatures(), 'medications', null);
      if(!implant_flag && item.item_type === 'Implant Item'){
        return false;
      }
      if(!inventory_flag && (item.item_type === 'Inventory Item' || item.item_type === 'General Item' || item.item_type === 'Marketplace Item')){
        return false;
      }
      if(!meds_flag && item.item_type === 'Medication Item'){
        return false;
      }
      return true;
    }

    function selectedItemChanged(item){
      validateItemProduct(item);

      if (!item || !item.inventory_item) {
        return false;
      } else {
        var index = ctrl.poDraft.items.indexOf(item);
        var inventory_item = item.inventory_item;

        item.buy_by_case = item.inventory_item.po_buy_by_case || false;

        item.images = inventory_item.images || inventory_item.image || null;
        item.sku = inventory_item.sku;
        item.name = inventory_item.name || "";
        item.unit_cost = poHelperService.getUnitCost(item);
        var minimum_needed = inventory_item.minimum_level - inventory_item.on_hand;
        item.quantity = 1;
        item.tax_rate = ctrl.default_tax;
        item.item_type = inventory_item.item_type;
        _.set('item.UI.invalid_product', false);

        ctrl.poDraft.items[index] = item;
        _evaluateSupplier(item);
        _refreshExternalData([item]);
      }
    }

    /**
     * If the user is restricted to using a single supplier, then find the supplier of the item,
     * find the selected rep of that supplier, and set the supplier of the PO to that rep
     * @param {Object} item - the item being added to the PO
     */
    function _evaluateSupplier(item) {
      if (ctrl.use_single_supplier) {
        const item_supplier_id = item.inventory_item?.supplier_id;
        const item_supplier = ctrl.officeSuppliers.find(({ id }) => id === item_supplier_id);
        const selected_rep = findSelectedRep(item_supplier);
        const option_id = selected_rep?.id || item_supplier_id;
        const selected_supplier = ctrl.supplier_rep_options.find(option => option.option_id === option_id);
        if (selected_supplier) {
          _updateOrderSupplier(selected_supplier);
        }
      }
    }

    /**
     * Updates the supplier of the order and calls a handler
     * @param {Object} new_supplier - The new supplier object that was selected.
     */
    function _updateOrderSupplier(new_supplier) {
      ctrl.poDraft.supplier = new_supplier;
      handleSupplierChange(new_supplier);
    }

    /**
     * If the user is restricted to using a single supplier and a supplier has been selected,
     * make sure search results only include products from that supplier
     * @param {Object} selected_supplier - The supplier that was selected.
     */
    function handleSupplierChange(selected_supplier = ctrl.poDraft.supplier) {
      if (ctrl.use_single_supplier && selected_supplier?.id) {
        ctrl.locked_supplier_id = selected_supplier.id;
      }
      const po_info = selected_supplier?.po_info;
      const use_shipping = po_info?.po_type === 'edi' && !_.isEmpty(po_info?.shipping_option);
      if (use_shipping) {
        ctrl.show_shipping_options = true;
        ctrl.poDraft.shipping_option_selected = selected_supplier.po_info.shipping_option[0];
      } else {
        ctrl.show_shipping_options = false;
        ctrl.poDraft.shipping_option_selected = null;
      }

      // account number only exists if office has multi account numbers
      // selected_supplier can be null if the user has not selected a supplier
      if (ctrl.has_multi_account_numbers && selected_supplier?.id) {
        _setAccountNumberDropdownValue(selected_supplier);
      }
    }

    function _setAccountNumberDropdownValue (selected_supplier) {
      const { vendor_customer_number_list = [], vendor_customer_number } = selected_supplier;
      const office_vendor_membership_id = ctrl.poDraft.office_vendor_membership?.id;

      /* API returns an office vendor membership id when the saved PO has a specific account number
       * If the PO has a saved account number, set the account number to that value
       * otherwise, set the account number to the default account number for the selected supplier
      */
      if (office_vendor_membership_id) {
        const selected_account_number = vendor_customer_number_list.find(({id}) => id === office_vendor_membership_id);
        ctrl.poDraft.account_number = selected_account_number;
      } else if (vendor_customer_number) {
        const default_account_number = vendor_customer_number_list.find(({account_number}) => account_number === vendor_customer_number);
        ctrl.poDraft.account_number = default_account_number;
      } else {
        ctrl.poDraft.account_number = null;
        if (!(ctrl.disabled_supplier_edit || ctrl.new_supplier_locked)) { 
          _openAccountNumberDropdown();
        }
      }
    }

    function skuLookup(item) {
      if (!item.sku) {
        return false;
      }

      return searchService
        .inventorySearch(item.sku)
        .then(function (results) {
          results = _.filter(results || [], function (result) {
            return result.sku === item.sku;
          });

          var shouldUseItem = (results && results.length === 1 && !item.inventory_item);

          if (shouldUseItem) {
            item.inventory_item = results[0];
          }
        });
    }

    function getTotalUnits(){
      var total = _.reduce(ctrl.poDraft.items, function(sum, item){
        var qty = 0;
        if(item.inventory_item){
          qty = item.quantity * 1;
        }
        return sum + qty;
      }, 0);
      return total;
    }
    function getItemsTotal(){
    	var total = _.reduce(ctrl.poDraft.items, function(sum, item){
    		return sum + ((item.unit_cost || 0) * (item.quantity || 0));
    	}, 0);
    	return total;
    }
    function getTotalTax(){
      var tax = _.reduce(ctrl.poDraft.items, function(sum, item){
        return sum + ((item.unit_cost || 0) * (item.quantity || 0) * ((item.tax_rate || 0)/100) );
      }, 0);
      return tax;
    }
    function getTotalCost(){
      const itemsTotal = ctrl.getItemsTotal();
      const taxTotal = ctrl.getTotalTax();
      const shipping = parseFloat(ctrl.poDraft.shipping_cost) || 0;
      const fees = parseFloat(ctrl.poDraft.additional_fees) || 0;
      const discounts = parseFloat(ctrl.poDraft.discounts) || 0;
      const total = (itemsTotal + taxTotal + shipping + fees) - discounts;
      if(total < 0) return 0;
      return total;
    }
    function removeItem(item){
      sowAnalyticsService.purchaseOrderCancelProductConfirmed();
      var index = ctrl.poDraft.items.indexOf(item);
      var removedItem = ctrl.poDraft.items.splice(index,1);
    }

    function editSupplier(ev) {
      const selected_supplier = ctrl.officeSuppliers.find(({ id }) => id === ctrl.poDraft.supplier.id);
      const selected_rep = findSelectedRep();
      // Open the new supplier dialog if the user has multiple account numbers
      if (ctrl.has_multi_account_numbers) {
        ctrl.selected_supplier_id = selected_supplier.id;
        _openSupplierModal();
      } else {
        SupplierDialog.show(ev, selected_supplier, selected_rep, {}).then(_handleEditSupplierResponse)
      }
    }

    function _handleEditSupplierResponse (res) {
      const updated_supplier = ctrl.officeSuppliers.find(({ id }) => id === res.supplier.id);
      for (const key in res.supplier) {
        updated_supplier[key] = res.supplier[key];
      }
      const sales_rep_id = _.get(res, 'sales_rep.id');
      if (sales_rep_id) {
        const rep_index = ctrl.sales_reps.findIndex(({ id }) => id === sales_rep_id);
        if (rep_index > -1) {
          ctrl.sales_reps.splice(rep_index, 1, res.sales_rep);
          updated_supplier.sales_reps = _.get(updated_supplier, 'sales_reps', []).map(rep => rep.id === sales_rep_id ? res.sales_rep : rep);
        } else {
          ctrl.sales_reps.push(res.sales_rep);
          updated_supplier.sales_reps = updated_supplier.sales_reps || [];
          updated_supplier.sales_reps.push(res.sales_rep);
        }
      }
      ctrl.officeSuppliers = $filter('orderBy')(ctrl.officeSuppliers, 'name');
      generateSupplierRepOptions();
      const selected_rep = findSelectedRep();
      const selected_option_id = sales_rep_id || _.get(selected_rep, 'id') || res.supplier.id;
      const selected_supplier = ctrl.supplier_rep_options.find(({ option_id }) => option_id === selected_option_id);
      _updateOrderSupplier(selected_supplier);
    }

    function findSelectedRep(supplier = ctrl.poDraft.supplier) {
      const sales_rep_id = _.get(supplier, 'sales_rep_id');
      if (sales_rep_id) {
        return ctrl.sales_reps.find(({ id }) => id === sales_rep_id);
      }
      const supplier_id = _.get(supplier, 'id');
      if (supplier_id) {
        const supplier = ctrl.officeSuppliers.find(({ id }) => id === supplier_id);
        const reps = _.get(supplier, 'sales_reps') || [];
        return reps.find(rep => rep.is_primary) || reps[0];
      }
      return null;
    }

    function newSupplierDialog(ev) {
      $mdSelect.hide();
      // Open the new supplier dialog if the user has multiple account numbers
      if (ctrl.has_multi_account_numbers) {
        ctrl.selected_supplier = null;
        _openSupplierModal();
      } else {
        SupplierDialog.show(ev, null).then(_handleNewSupplierResponse);
      }
    }

    function _handleNewSupplierResponse (res) {
      const sales_rep_id = _.get(res, 'sales_rep.id');
      if (sales_rep_id) {
        res.supplier.sales_reps = [res.sales_rep];
        ctrl.sales_reps.push(res.sales_rep);
      }
      ctrl.officeSuppliers.push(res.supplier);
      ctrl.officeSuppliers = $filter('orderBy')(ctrl.officeSuppliers, 'name');
      generateSupplierRepOptions();
      const selected_option_id = sales_rep_id || res.supplier.id;
      const selected_supplier = ctrl.supplier_rep_options.find(({ option_id }) => option_id === selected_option_id);
      _updateOrderSupplier(selected_supplier);
    }

    function rowIsEmpty (row) {
      return (
        (!row.searchText && !row.inventory_item) // Product Name Field
        &&
        (!row.quantity && row.quantity !== 0) // Quantity Field
        &&
        !row.sku // SKU Field
        &&
        (!row.unit_cost && row.unit_cost !== 0) // Unit Cost ($) Field
        &&
        (!row.tax_rate && row.tax_rate !== 0) // Tax Rate Field
      );
    }

    function saveDraft (isDupe) {
      ctrl.saving = true;
      if(simpleValidation()){
        for (const item of _.values(ctrl.poDraft.items)) {
          item.tax_rate = _.get(item, 'tax_rate', ctrl.default_tax);
        }

        const duplicated_po = angular.copy(ctrl.poDraft)
        if (isDupe) {
          delete duplicated_po.id;
          duplicated_po.po_date = moment().format();
        }

        if (ctrl.is_date_picker_hidden) {
          duplicated_po.po_date = moment().format();
        }
        
        return PurchaseOrderService.saveDraft(duplicated_po)
        .then(function(result){
          var t_created = $filter('translate')('TOAST.DRAFT_CREATED');
          var t_saved = $filter('translate')('PO.DRAFT_SAVED');
          $mdToast.show(
            $mdToast.simple()
              .textContent(
                isDupe ? (result.name + ' ' + t_created) : t_saved
              )
              .position("bottom right")
              .hideDelay(2000)
          );

          _removeShoppingListItems();

          updatePo(result);
          ctrl.discarding = true; // XGH hack, sorry
          
          // SPD-4317
          if (isDupe) {
            $state.transitionTo($state.current,{poId: result.id});
          } else {
            // SOW-4200
            $state.go('app.orders.purchase.list'); // SOW-4028
          }

        })
        .catch(errorDialog)
        .finally(() => {
          ctrl.saving = false;
          ctrl.isDuplicating = false;
        });
      }
      else {
        ctrl.saving = false;
        ctrl.isDuplicating = false;
        return $q.reject('Error saving draft.');
      }
    }

    /**
     * If any of the items currently in the order were added using the
     * shopping list, removes those inventory items from the shopping list
     */
    function _removeShoppingListItems() {
      /* exit early if items were not added using shopping list */
      if (!$state.params.shopping_list_po) {
        return;
      }
      /* find any inventory items which were added using the shopping list */
      const items_to_remove = _.flatMap($state.params.initial_items, ({inventory_item}) =>
        /* filter out any which are not currently in the order */
        _.some(ctrl.poDraft.items, item => item.inventory_item.id === inventory_item.id)
          ? [inventory_item]
          : [],
      );
      /* remove any remaining items from the shopping list */
      if (items_to_remove.length > 0) {
        inventoryItemService.batchRemoveFromShoppingList(items_to_remove);
      }
    }

    function activateOrder(){
      ctrl.saving = true;
      //should change for new validation
      if(fullValidation()){
        if(ctrl.poDraft.status === 'Active' || ctrl.poDraft.status === 'Received' || ctrl.poDraft.status === 'Cancelled'){
          return PurchaseOrderService.editActive(ctrl.poDraft).then(function(result){
            _removeShoppingListItems();
            var t_message = $filter('translate')('PO.ORDER_SAVED');
            $mdToast.show(
              $mdToast.simple()
                .textContent(t_message)
                .position("bottom right")
                .hideDelay(2000)
              );
            $state.go('app.orders.purchase.active',{poId:result.id});
          }).then(null, function (error) {
            errorDialog(error);
            throw error;
          })
          .finally(function(){
            ctrl.saving = false;
          });
        }else{
          if (ctrl.is_date_picker_hidden) {
            ctrl.poDraft.po_date = moment().format();
          }
          return PurchaseOrderService.saveDraft(ctrl.poDraft)
          .then(saved_draft => {
            if (ctrl.approval_required) {
              const user_id = sow.userInfo().id;
              const approval_type = 'purchase_order';
              const reference_id = saved_draft.id;
              sowApprovalService.getAuthorizationAndApprovers(user_id, approval_type, reference_id)
                .then(res => {
                  if (res.need_approval) {
                    const rep_email = _.get(saved_draft, 'supplier.sales_rep_email');
                    ctrl.missing_email = !(rep_email);
                    if(ctrl.missing_email){
                      return;
                    }
                    const authorized_approvers = res.approvers_list || [];
                    if (authorized_approvers.length) {
                      sowApprovalHelperService.openDialog(null, authorized_approvers, user_id, approval_type, reference_id)
                        .then(selected_approver => {
                          if (selected_approver) {
                            $state.go('app.orders.purchase.active', { poId: reference_id });
                          }
                        });
                    } else {
                      // case where no approvers found
                      // TODO: add something in the UI here
                    }
                  } else {
                    activateDraft(saved_draft);
                  }
                })
                .catch(err => errorService.uiErrorHandler(err))
                .finally(() => ctrl.saving = false);
            } else {
              activateDraft(saved_draft);
            }
          }).then(null, function (error) {
            ctrl.saving = false;
            errorDialog(error);
            throw error;
          })
        }

      }
      else {
        ctrl.saving = false;
        return $q.reject('Validation Failed');
      }

    }

    async function activateDraft(saved_draft) {
      ctrl.saving = true;
      try {
        const activated_po = await PurchaseOrderService.makeActive(saved_draft);
        _removeShoppingListItems();
        const t_message = $filter('translate')('PO.ORDER_CREATED');
        $mdToast.show(
          $mdToast.simple()
            .textContent(t_message)
            .position('bottom right')
            .hideDelay(2000)
        );
        $state.go('app.orders.purchase.active', { poId: activated_po.id });
        const recipients = poHelperService.getRecipients(activated_po);

        // When auto checkout is enabled and there is at least one recipient...
        if (ctrl.auto_checkout && recipients) {
          if (activated_po.type === 'edi') {
            // ...automatically email the PO to the user (EDI is already emailed to suppliers).
            poHelperService.emailPurchaseOrder(activated_po, null, true, true);
          } else {
            // ...automatically email the PO to the recipient(s) and user.
            poHelperService.emailPurchaseOrder(activated_po, recipients, true, true);
          }
          // When only one of those conditions is truthy...
        } else if (ctrl.auto_checkout || recipients) {
          // ...automatically open the email PO dialog.
          poHelperService.emailPoDialog(activated_po, recipients);
        }

      } catch (err) {
        errorDialog(err);
        throw err;
      } finally {
        ctrl.saving = false;
      }

    }

    function errorDialog(errorObj){
      /* the service shows an error toast in this case so we won't also do so here */
      if (errorObj?.internal_code === 'PO_HRID_DUPLICATE') {
        return;
      }
      var message = errorObj.message;
      var t_title = $filter('translate')('ERRORS.SOMETHING_WENT_WRONG');
      var t_btn = $filter('translate')('ACTIONS.CLOSE');
      $mdDialog.show(
        $mdDialog.alert()
          .clickOutsideToClose(true)
          .title(t_title)
          .textContent(message)
          .ariaLabel('activate order error dialog')
          .ok(t_btn)
        );
    }

    /** 
     * Creates a unique ID for each of the suppliers/sales_reps in the "Suppliers" dropdown.
     * The ID will be the sales rep's ID if one exists, and the supplier's ID if not.
     * 
     * @param {String} supplier_id 
     * @param {String} sales_rep_id 
     * 
     * @return {String} 
    */
    function generateOptionID(supplier_id, sales_rep_id) {
      return sales_rep_id || supplier_id;
    }

    function updatePo(newPo){
      newPo.po_date = new Date(newPo.po_date);
      // If there's an associated supplier, add the appropriate option_id (unique identifier)
      if (_.get(newPo, 'supplier.id')) {
        newPo.supplier.option_id = generateOptionID(newPo.supplier.id, _.get(newPo, 'supplier.sales_rep_id'));
      }
      ctrl.poDraft = newPo;
      ensureValidSelectedOption();
      ensureValidItems();
      handleSupplierChange();
      ctrl.show_fees = shouldShowFees();
      ctrl.show_discounts = shouldShowDiscounts();
      ctrl.loading = false;
      // leaving some time for the autocomplete properties to fill up once the item list is initiated
      $timeout(function(){
        ctrl.discarding = false;
        ctrl.initialPo = angular.copy(ctrl.poDraft);
      },800);
    }

    function duplicatePo ($event, page) {
      if (!ctrl.isDuplicating && page === 'draft') {
        ctrl.isDuplicating = true;
        if (ctrl.use_hrid) {
          return PurchaseOrderService.getHRID()
          .then(hrid => {
            ctrl.poDraft = poHelperService.makeDuplicate(ctrl.poDraft);
            ctrl.poDraft.name = PurchaseOrderService.getPoName(hrid, 'edi');
            ctrl.poDraft.hrid = hrid;
            $timeout(function () {
              ctrl.saveDraft(true);
            }, 500);
          })
          .catch(error => {
            errorService.uiErrorHandler(error);
          });
        } else {
          return PurchaseOrderService.count()
          .then(count => {
            ctrl.poDraft.name = PurchaseOrderService.getPoName(count + 1);
            $timeout(function () {
              ctrl.saveDraft(true);
            }, 500);
          }).catch(error => {
            errorService.uiErrorHandler(error);
          });
        }
      }
    }

    function emailPo () {
      const rep = findSelectedRep();
      poHelperService.emailPoDialog(ctrl.poDraft, rep);
    }

    function notActionedItem(item){
      return !item.last_action;
    }

    function makeBundles(items){
      ctrl.bundles = [];
      _.forEach(items, function(item){
        if(item.last_action){
          var bundle = {
            last_action_date: item.last_action_date.substr(0,10),
            last_action: item.last_action,
            type: ctrl.itemTypeOrder[item.last_action]
          };
          var bundleIndex = getBundleIndex(bundle);
          if(bundleIndex >= 0){
            ctrl.bundles[bundleIndex].items.push(item);
          }else{
            bundle.items = [item];
            ctrl.bundles.push(bundle);
          }
        }
      });
    }

    function getBundleIndex(bundle){
      var index = -1;
      _.forEach(ctrl.bundles, function(b, i){
        if( bundle.type === b.type && bundle.last_action_date === b.last_action_date){
          index = i;
        }
      });
      return index;
    }

    function simpleValidation(){
      initErrorMessages();
      var valid = true;

      ctrl.purchaseOrderForm.$setSubmitted();
      valid = valid && formValidation(false);

      ctrl.poDraft.items = _.filter(ctrl.poDraft.items, function (item) {
        return !rowIsEmpty(item);
      });

      if(ctrl.poDraft.items.length > 0){
        valid = valid && validateItems();
      }

      return valid;
    }

    function formValidation(full){
      var valid = true;

      if(!ctrl.poDraft.name){
        valid = false;
        ctrl.errorMessages[3].show = true;
      }

      if(!ctrl.poDraft.po_date){
        valid = false;
        ctrl.errorMessages[2].show = true;
      }

      if(full){
        if(!ctrl.poDraft.supplier){
          valid = false;
          ctrl.errorMessages[5].show = true;
        }

        if(!ctrl.poDraft.created_by){
          valid = false;
          ctrl.errorMessages[6].show = true;
        }
      }

      return valid;
    }

    function fullValidation(){
      initErrorMessages();
      var valid = true;

      ctrl.purchaseOrderForm.$setSubmitted();
      // valid = valid && !ctrl.purchaseOrderForm.$invalid;
      // if(ctrl.purchaseOrderForm.$invalid){
      //   valid = false;
      // }
      valid = valid && formValidation(true);

      ctrl.poDraft.items = _.filter(ctrl.poDraft.items, function (item) {
        return !rowIsEmpty(item);
      });

      if(ctrl.poDraft.items.length > 0){
        valid = valid && validateItems();
      }else{
        valid = false;
        ctrl.errorMessages[4].show = true;
        ctrl.poDraft.items.push({});
      }

      return valid;
    }

    function validateItemQuantity (item) {
      if (ctrl.purchaseOrderForm.$submitted) {
        item.UI = item.UI || {};
        item.UI.invalid_qty = (item && item.inventory_item && (!item.quantity || item.quantity < 1));
        ctrl.errorMessages[0].show = item.UI.invalid_qty;
      }
    }

    function validateItemProduct (item) {
      if (ctrl.purchaseOrderForm.$submitted) {
        item.UI = item.UI || {};
        item.UI.invalid_product = item && !item.inventory_item;
        ctrl.errorMessages[1].show = item.UI.invalid_product;
      }
    }

    /** 
     * Ensures that the buying and tracking properties of each
     * PO item match those of the corresponding inventory item. 
     * 
     * @param {Object} item 
     * 
     * @return {*} 
    */
    function matchBuyingAndTracking(item) {
      item.buy_by_case = _.get(item, 'inventory_item.buy_by_case');
      item.tracking_method = _.get(item, 'inventory_item.tracking_method');
    }

    function validateItems(){
      var valid = true;
      ctrl.wrongItems = _.filter(ctrl.poDraft.items, function(item){
        item.UI = item.UI || {};
        validateItemProduct(item);
        validateItemQuantity(item);
        item.UI.wrong = !!(item.UI.invalid_qty | item.UI.invalid_product);

        if(item.UI.wrong){
          valid = false;
        }
        return !!item.UI.wrong;
      });
      return valid;
    }

    function initErrorMessages(){
      _.forEach(ctrl.errorMessages, function(error){
        error.show = false;
      });
    }

    function focusField (item, field) {
      ctrl.focused_item = item;
      ctrl.focused_field = field;
    }

    function isFieldFocused (item, field) {
      return (ctrl.focused_item === item && ctrl.focused_field === field);
    }

    function clearFocus () {
      ctrl.focused_item = null;
      ctrl.focused_field = null;
    }

    function isImplantItem(item) {
      return item.item_type === 'Implant Item';
    }

    function defineLocks () {
      const price_lock_condition = AccessService.getProperty('purchase_orders.disable_price');
      const disable_price_condition = AccessService.getProperty('disable_po_price');

      ctrl.approval_required = AccessService.getProperty('purchase_orders.approval_req');
      ctrl.auto_checkout = AccessService.getProperty('purchase_orders.auto_checkout');
      ctrl.disabled_po_id = AccessService.getProperty('purchase_orders.disable_po_id');
      ctrl.auto_update_date_on_active = AccessService.getProperty('purchase_orders.auto_update_date_on_active');
      ctrl.disabled_supplier_edit = AccessService.getProperty('suppliers.disable_edit');
      ctrl.new_supplier_locked = AccessService.getProperty('suppliers.disable_add_new');
      ctrl.price_locked = price_lock_condition || disable_price_condition;
      ctrl.tax_exempt = AccessService.getProperty('office.tax_exempt');
      ctrl.use_single_supplier = AccessService.getProperty('shopping_list.use_single_supplier');
      ctrl.use_hrid = AccessService.getProperty('office.create_po_hrid');
      ctrl.has_multi_account_numbers = AccessService.getOfficeProperty('multi_account_numbers');
    }

    /** 
     * Allows the user to select which view is displayed. 
     * 
     * @param {String} clicked_view 
     * 
     * @return {*} 
    */
    function selectView(clicked_view) {
      ctrl.displayed_view = clicked_view;

      if (clicked_view === 'log' && ctrl.events === null) {
        fetchAndParseEvents(po_id);
      }
    }

    /** 
     * Fetches and parses Activity Log events for this PO 
     *
     * @param {String} po_id
     *
     * @return {*}
    */
    function fetchAndParseEvents(po_id = ctrl.poDraft?.id) {
      ctrl.loading_events = true;
      PurchaseOrderService.fetchEvents(po_id)
        .then(events => {
          ctrl.events = poHelperService.parseEventsData(events);
        })
        .catch(err => console.error(err))
        .finally(() => {
          ctrl.loading_events = false;
        })
    }

    /** 
     * Determines whether a state refresh is required
     * because this PO has been emailed. 
     * 
     * @param {String} po_id 
     * 
     * @return {*} 
    */
    function handleEmailEvent($event, po_id) {
      const draft_id = _.get(ctrl, 'poDraft.id');
      if (draft_id === po_id) {
        fetchAndParseEvents(draft_id);
      }

    }
    

    function updateSuggestedQuantities(updated_suggestions) {
      for (const key in updated_suggestions) {
        ctrl.suggested_quantities[key] = updated_suggestions[key];
      }
    }

    function showTrackingModal(item) {
      if (!item.inventory_item) {
        return null;
      }
      const showModal = () => $mdDialog.show({
        templateUrl: 'templates/marketplace/purchase-orders/modals/po-quantity-modal.html',
        controller: 'poQuantityModalCtrl',
        controllerAs: 'poQMCtrl',
        clickOutsideToClose: true,
        locals: {
          item,
          suggested_quantities: ctrl.suggested_quantities,
        },
      });
      if (ctrl.suggested_quantities[item.inventory_item.id]) {
        showModal();
      } else {
        inventoryItemService.getSuggestedQuantities([item])
          .then(res => {
            updateSuggestedQuantities(res);
            showModal();
          })
          .catch(err => {
            const error_msg = err.status === 404 ? $filter('translate')('ERRORS.NO_LONGER_AVAILABLE') : err.message;
            errorService.uiErrorHandler(error_msg);
          });
      }
    }

    /**
     * Sets all external vendor data within the controller, 
     * to be later used in the action calls.
     * Then refresh the external data (price/stock)
     * @param {object} po - the purchase order object
     */
    function _setExternalVendors (po = ctrl.poDraft) {
      if(!po.supplier?.id) return;
      ctrl.external_vendors = $rootScope.external_vendors;
      ctrl.current_ev = poHelperService.getEvInfoFromSupplier(po.supplier, ctrl.external_vendors);
      ctrl.ev_actions = _.get($rootScope.external_vendors_actions, ctrl.current_ev?.vendor?.id);
      _refreshExternalData();
    }

    /**
     * > Get the stock status of the items in the PO from the supplier's external vendor account
     * @param [po] - the purchase order object
     * @returns An array of objects with the following structure:
     * ```
     * [
     *   {
     *     "productId": "026.0054",
     *     "confirmedQuantity": 40,
     *     "stockStatus": "in.stock",
     *     "deliveryDays": 0,
     *     "country": "Canada",
     *     "showDeliveryDays": false,
     *     "estimatedDeliveryDate": "2022-12-01",
     *     "showEstimatedDeliveryDate": false
     *   }
     * ]
     * ```
     */
    function _getEvStock (items = ctrl.poDraft.items) {
      if(!ctrl.current_ev) return null;
      const stock_action = ctrl.ev_actions?.data_sources?.stock_status;
      if(!stock_action) return null;

      ctrl.can_update_stock = true;
      const vendor_skus = items.map(item => {
        _setIsLoading(item, 'stock');
        return item.sku;
      });
      ctrl.stock_update_loading = true;
      return sowExternalVendorAccountService.callAction(stock_action, {vendor_skus})
      .then((response) => {
        _handleStockUpdate(response);
      })
      .finally(() => {
        _setAllLoaded('stock', items);
      });
    }

    /**
     * Uses API data to update the stock info of external vendor items in the PO
     * @param {object} stock_info
     */
    function _handleStockUpdate (stock_info) {
      ctrl.poDraft.items.forEach(po_item => {
        if (!po_item.sku) return;
        const ev_item = _.find(stock_info, {id: po_item.sku});
        const ev_item_stock_data = ev_item?.data?.Stock;
        if (ev_item_stock_data) {
          _.set(po_item, 'UI.stock_info', msHelperService.getDataValue(ev_item_stock_data));
        }
      });
    }

    /**
     * Get the price of the items in the PO from the supplier's external vendor account
     * @param {Array} items
     * @returns An array of objects 
     */
    function _getEvPrice (items = ctrl.poDraft.items) {
      if(!ctrl.current_ev) return null;
      const price_action = ctrl.ev_actions?.data_sources?.price_status;
      if(!price_action) return null;

      ctrl.can_update_prices = true;
      ctrl.price_update_loading = true;
      const price_calls = _makePriceCalls(price_action, items);

      return $q.all(price_calls)
      .finally(() => ctrl.price_update_loading = false);
    }

    function _setIsLoading (item, column) {
      _.set(item, `UI.${column}_loading`, true);
    }

    function _setAllLoading (column, items = ctrl.poDraft.items) {
      _.set(ctrl, `${column}_update_loading`, true);
      _.each(items, (po_item) => _setIsLoading(po_item, column));
    }

    function _setIsLoaded (item, column) {
      _.set(item, `UI.${column}_loading`, false);
    }

    function _setAllLoaded (column, items = ctrl.poDraft.items) {
      _.set(ctrl, `${column}_update_loading`, false);
      _.each(items, (po_item) => _setIsLoaded(po_item, column));
    }

    /**
     * It makes a call to the server to get the prices of a list of products
     * @param {object} price_action - the price action object from the vendor account
     * @param {Array} items - the items to update
     * @returns An array of promises.
     */
    function _makePriceCalls (price_action, items = ctrl.poDraft.items) {
      _setAllLoading('price', items);
      
      if(price_action.batch_capable) {
        const vendor_skus = _.map(items, 'sku');
        // one call to rule them all
        const single_call = sowExternalVendorAccountService.callAction(price_action, {vendor_skus})
        .then((price_update_response) => {
          _.each(items, (po_item) => {
            _handlePriceUpdate(po_item, price_update_response);
          });
        })
        .finally(() => _setAllLoaded('price', items));

        return [single_call];
      } else {
        return _.map(items, (po_item) => {
          // multiple calls, one call per item
          return sowExternalVendorAccountService.callAction(price_action, {vendor_sku: po_item.sku})
            .then((price_update_response) => _handlePriceUpdate(po_item, price_update_response))
            .finally(() => _setIsLoaded(po_item, 'price'));
        });
      }
    }

    /**
     * Uses API data to update the unit cost of an external vendor item in the PO
     * @param {object} item
     * @param {object} price_info
     */
    function _handlePriceUpdate (item, price_info) {
      if(!item.sku) return;
      const item_price_info = _.find(price_info, {id: item.sku});
      const new_price = msHelperService.getDataValue(item_price_info?.data?.Price);
      if(item_price_info && new_price) {
        item.unit_cost = new_price;
      }
    }

    /**
     * Determine wether the price input should be disabled or not.
     * @param {object} item - the item object
     * @return {boolean} true if the price input should be disabled, false otherwise.
     */
    function shouldDisablePriceInput (item) {
      // for users with access, during loading
      if (ctrl.can_update_prices) {
        return _isLoading('price', item);
      }
      // for users with no access and price is loaded or wasn't ever loaded anyway
      return ctrl.price_locked;
    }

    function shouldShowStockColumn () {
      return ctrl.can_update_stock;
    }

    function shouldShowPriceInput (item) {
      return item.inventory_item && !_isLoading('price', item);
    }

    function _isLoading (column, item) {
      if (!item) return _.get(ctrl, `${column}_update_loading`);
      return _.get(item, `UI.${column}_loading`);
    }

    function _refreshExternalData (items) {
      _getEvStock(items);
      _getEvPrice(items);
    }

    /**
     * Determine wether the user can interact with the column or not.
     * @param {string} column - the column name
     * @param {object} item - the item object
     * @returns {boolean} A boolean value.
     */
    function canInteractWith (column, item) {
      if (column === 'unit_cost') {
        return !shouldDisablePriceInput(item);
      } 
      if (['tax', 'quantity'].includes(column)) {
        return !_isLoading('price', item);
      }
      if (['save', 'create'].includes(column)) {
        return !ctrl.saving && !_isLoading('price') && !_isLoading('stock');
      }
      return true;
    }

    function shouldShowFees () {
      return ctrl.show_fees || Boolean(ctrl.poDraft.additional_fees > 0);
    }

    function shouldShowDiscounts () {
      return ctrl.show_discounts || Boolean(ctrl.poDraft.discounts > 0);
    }

    function onAddAccountNumberClick () {
      $mdSelect.hide();
      _openAccountNumberModal();
    }

    function onEditAccountNumberClick() {
      ctrl.selected_account_number = {...ctrl.poDraft.account_number}
      _openAccountNumberModal();
    }

    function _openAccountNumberModal () {
      ctrl.show_account_number_modal = true;
    }

    function _openSupplierModal () {
      ctrl.show_supplier_modal = true;
    }

    /**
     * Updates the selected supplier vendor_customer_number_list with the new account number
     * If the new account number is default, set the other account numbers to false. (Only one default account number allowed)
     * @param new_account_number 
     */
    function _handleNewAccountNumberResponse (new_account_number) {
      ctrl.poDraft.supplier.vendor_customer_number_list = [ 
        new_account_number,
        ...ctrl.poDraft.supplier.vendor_customer_number_list.map(account => {
          return {
            ...account,
            // If the new account number is default, set the other account numbers to false
            is_default: new_account_number.is_default ? false : account.is_default
          }
        })
      ]
    }

    /**
     * Updates the existing account number in the selected supplier vendor_customer_number_list
     * @param new_account_number 
     */
    function _handleEditAccountNumberResponse (edited_account_number) {
      ctrl.poDraft.supplier.vendor_customer_number_list = 
        ctrl.poDraft.supplier.vendor_customer_number_list.map(prev_account => {
          return prev_account.id === edited_account_number.id ? edited_account_number : prev_account
        })
    }

    function handleAccountNumberModalChange ({detail}) {
      const {action, accountNumber} = detail;

      if (accountNumber?.id) {
        // Add the new account number to the supplier's account number list
        if (action === 'created') {
          _handleNewAccountNumberResponse(accountNumber);
        } 

        // Update the account number in the supplier's account number list
        if (action === 'updated') {
         _handleEditAccountNumberResponse(accountNumber);
        }

        // Update dropdown selection
        ctrl.poDraft.account_number = accountNumber;
      }

      // Close the modal and reset the selected account number
      ctrl.show_account_number_modal = false;
      ctrl.selected_account_number = null;
    }

    function handleSupplierModalChange ({detail}) {
      const {action, supplier, sales_rep} = detail

      if (supplier?.id) {
        if (action === 'created') {
          _handleNewSupplierResponse({supplier, sales_rep})
        } 

        if (action === 'updated') {
          _handleEditSupplierResponse({supplier, sales_rep})
        }

        // Open the account number dropdown if the supplier has no vendor_customer_number_list items
        if (supplier?.vendor_customer_number_list?.length === 0) {
          _openAccountNumberDropdown();
        }
      }

      // Close the modal and reset the selected supplier
      ctrl.show_supplier_modal = false;
      ctrl.selected_supplier_id = null;
    }

    function _openAccountNumberDropdown () {
      setTimeout(() => {
        document.getElementById("po-draft-account-number-selector")?.click()
      }, 500);
    }

    /**
    * Generates HTML content based on the selected account number in a purchase order draft.
    * If an account number is selected, it will display the account number and description (if available).
    * If no account number is selected, it will display a placeholder message. 
    */
    function getAccountNumberHtmlTemplate () {
      if (ctrl.poDraft.account_number?.id) {
        const account_number = `<p>${ctrl.poDraft.account_number.account_number}`

        if (ctrl.poDraft.account_number.description) {
          return account_number + `<span class="Typography--color-grey-600"> - ${ctrl.poDraft.account_number.description}</span></p>`
        }

        return `${account_number}</p>`
      }

      return $filter('translate')('OFFICE.BILLING.SELECT')
    }

  }//end of draft controller
}());

(function () {
  'use strict';
  angular.module("app")
    .config(trustedDomains);

  function trustedDomains($sceDelegateProvider) {
    $sceDelegateProvider.resourceUrlWhitelist([
      // Allow same origin resource loads.
      'self',
      "https://www.youtube.com/embed/**",
      "https://player.vimeo.com/video/**"
    ]);
  }

}());

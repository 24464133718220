(function () {
  'use strict';

  angular.module('sowInventory')
  .directive('sowItemRow', sowItemRow)
  .controller('sowItemRowController', sowItemRowController);

  function sowItemRow () {
    return {
      restrict: 'E',
      replace: true,
      templateUrl: 'sow-inventory/directives/sow-item-row.html',
      controller: 'sowItemRowController',
      controllerAs: 'sirCtrl',
      scope: {
        'item': '=',
      }
    };
  }

  function sowItemRowController ($scope, mktHelperService, inventoryHelperService, AccessService) {
    var ctrl = this;

    ctrl.itemSlideout = inventoryHelperService.itemSlideout;
    ctrl.checkItem = inventoryHelperService.checkItem;
    ctrl.getTrackingLabel = inventoryHelperService.getTrackingLabel;
    ctrl.getBuyingLabel = inventoryHelperService.getBuyingLabel;
    ctrl.shouldShowExpiryAlert = inventoryHelperService.shouldShowExpiryAlert;
    ctrl.shouldShowDraftPOStatus = inventoryHelperService.shouldShowDraftPOStatus;
    ctrl.noop = noop;
    ctrl.openProductSlideout = openProductSlideout;
    ctrl.openShoppingListSlideout = openShoppingListSlideout;
    
    ctrl.$onInit = init;
    return ctrl;

    function init () {
      inventoryHelperService.setItemAlerts($scope.item);
      _defineLocks();
    }

    /**
     * Opens the product details modal, and sets the has_multi_supplier_selection UI property to true.
     * @param {object} item
     * @param {object} $event
    */
   function openShoppingListSlideout (item, $event) {
      // Using this property, we hide the related products section and show the Shopping List button
      const options = {
        action: 'ADD_TO_SHOPPING_LIST',
        item,
      }

      $event?.stopPropagation();
      return mktHelperService.productDetailsFetch(item.product_id, options);
    }

    function openProductSlideout (item, $event) {
      $event?.stopPropagation();
      return mktHelperService.productDetailsFetch(item.product_id)
    }

    /**
     * Prevents the propagation of an event, this is needed because the parent element has a click event
     * that opens the product slideout
     * @param {object} event
     * @returns {void}
     */
    function noop (event) {
      event.stopPropagation();
    }

    function _defineLocks () {
      ctrl.disable_shopping_list = !AccessService.hasShoppingListAccess();
      ctrl.has_multi_supplier_selection = AccessService.getProperty('shopping_list.multi_supplier_selection')
      ctrl.hasCartAccess = AccessService.hasCartAccess();
    }
  }

}());

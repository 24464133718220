(function () {
  'use strict';
  angular.module('sowProduct', [])
  .config(productDetailsConfig);
  
  function productDetailsConfig($stateProvider) {
    
    $stateProvider
    .state('app.product-details', {
      url: '/product-details/:url_name/?section&image&vendor_id',
      templateUrl: 'sow-product-details/views/product-details.html',
      controller: 'productDetailsController',
      controllerAs: 'pdCtrl',
      params: {
        filter: '',
        product: null,
        related_products: null
      },
      data: {
        'title': 'Product Details',
        'action_bar_directive': 'action-bar-mkt',
        'require_log_in' : true,
      }
    });
  }
  
})();

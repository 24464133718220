angular.module("app.shared.system")
  // Service that provides functions for checking the online status of the app
  .service("onlineUtils", ["$window", "$rootScope", function ($window, $rootScope) {

    $rootScope.online = navigator.onLine;
    $window.addEventListener("offline", function () {
      $rootScope.$apply(function () {
        $rootScope.online = false;
      });
    }, false);
    $window.addEventListener("online", function () {
      $rootScope.$apply(function () {
        $rootScope.online = true;
      });
    }, false);

    $rootScope.$watch(function () {
      return navigator.onLine;
    }, function (newOnlineVal, oldVal) {
      $rootScope.online = !!newOnlineVal;
    });

    if (window.cordova) {
      $rootScope.$watch(function () {
        return navigator.connection && navigator.connection.type;
      }, function (newConnectionVal, oldVal) {
        $rootScope.online = newConnectionVal && newConnectionVal !== "none";
      });
    }

    return {
      isOnline: function () {
        if ($rootScope.db.active) {
          return $rootScope.online;
        } else {
          return false;
        }
      }
    };
  }]);

angular.module('app.marketplace.ui.registration', [
  'ui.router',
  'app.marketplace.ui.progress_header',
  'app.marketplace.ui.medical_field_selector',
  'app.marketplace.ui.specialty_selector',
  'app.marketplace.ui.location_selector',
])

.config(function ($stateProvider, $urlRouterProvider) {
  // $urlRouterProvider.when('/app/registration', '/app/registration/');
  $urlRouterProvider.when('/app/invitation', '/app/invitation/');

  $stateProvider.state('app.registration', {
    url: '/registration',
    abstract: true,
    templateUrl: 'templates/marketplace/registration/views/index.html',
    controller: 'RegistrationController',
    data: {
      'title': 'Registration',
      'redirect_logged_in': false,
      'hideFooter': true,
      'hideHeader': true,
      'hideSidenav': true,
    },
    params: {
      email: null,
      name: null,
      officeName: null,
      country: null,
      toState: null,
      toParams: null,
      officePhoneNumber: null,
      officeLocationId: null,
      accountType: null,
      selectAccountOverride: null,
      officeAddress: {
        address1: null,
        address2: null,
        city: null,
        country_id: null,
        province_id: null,
        postal_code: null,
      },
    }
  });

  //
  // "Create Account" Page
  //
  $stateProvider.state('app.registration.start', {
    url: '',
    controller: 'CreateUserCtrl',
    controllerAs: '$createUserCtrl',
    templateUrl: 'templates/marketplace/registration/views/create-user.html',
    data: {
      'redirect_logged_in': true,
    },
  });

  //
  // "Account Created" Page
  //
  $stateProvider.state('app.registration.account_created', {
    url: '',
    controller: 'AccountCreatedCtrl',
    templateUrl: 'templates/marketplace/registration/views/account-created.html',
    data: {
      'no_office_whitelisted': true
    },
    params: {
      'redirection': true,
    },
    resolve: {
      //
      // Check if the user has pending invitations.
      //
      'pending_invites': function (accountService, errorService) {
        return accountService.waitForAccount().then(function (account) {
          return accountService.getMembershipInvites().then(null, function (error) {
            errorService.uiErrorHandler(error);
          });
        }).catch(function () {
          return [];
        });
      }
    }
  });

  //
  // "Create Office" Page
  //
  $stateProvider.state('app.registration.create_office', {
    url: '',
    controller: 'CreateOfficeCtrl',
    controllerAs: '$ctrl',
    templateUrl: 'templates/marketplace/registration/views/create-office.html',
    data: {
      'no_office_whitelisted': true
    },
  });

  //
  // "Request Invite" Page
  //
  $stateProvider.state('app.registration.request_invite', {
    url: '',
    controller: 'RequestInviteCtrl',
    templateUrl: 'templates/marketplace/registration/views/request-invite.html',
    data: {
      'no_office_whitelisted': true
    },
  });

  //
  // "Invitation Request Sent" Page
  //
  $stateProvider.state('app.registration.invite_request_sent', {
    url: '',
    controller: 'InviteRequestSentCtrl',
    templateUrl: 'templates/marketplace/registration/views/request-sent.html',
    data: {no_office_whitelisted: true},
  });

  //
  // "Pending Invitations" Page
  //
  $stateProvider.state('app.registration.pending_invites', {
    url: '',
    controller: 'PendingInvitesCtrl',
    templateUrl: 'templates/marketplace/registration/views/pending-invites.html',
    data: {
      'no_office_whitelisted': true,
    },
    params: {
      'pending_invites': [],
    },
  });

  //
  // Old URL Redirect
  //
  $stateProvider.state('app.invite_redirect', {
    url: '/invite/:token',
    redirectTo: 'app.invitation.verify',
  });

  //
  // "Invitation" Section
  //
  $stateProvider.state('app.invitation', {
    url: '/invitation/:token',
    abstract: true,
    template: '<ui-view/>',
    data: {
      'redirect_logged_in': false,
      'hideFooter': true,
      'hideHeader': true,
      'hideSidenav': true,
    }
  });

  //
  // "Verify Invite" Page
  //
  $stateProvider.state('app.invitation.verify', {
    url: '',
    controller: 'VerifyInviteCtrl',
    templateUrl: 'templates/marketplace/registration/views/verify-invite.html',
  });

  //
  // "Register via Invite" Page
  //
  $stateProvider.state('app.invitation.register', {
    url: '',
    params: {'invite': {}},
    controller: 'InviteRegisterCtrl',
    templateUrl: 'templates/marketplace/registration/views/register-invite.html',
  });

  //
  // "Is Signed In Error" Page
  //
  // When the user is signed in, but the invite token doesn't match this user
  // or email address.
  //
  $stateProvider.state('app.invitation.is_signed_in_error', {
    url: '',
    templateUrl: 'templates/marketplace/registration/views/is-signed-in-error.html',
  });

  //
  // Capture Response Clicks on Invite Request Emails
  //
  $stateProvider.state('app.invite_request_response', {
    'url': '/invite_req/:request_id/:response',
    'controller': 'InviteRequestResponseCtrl',
    'templateUrl': 'templates/marketplace/registration/views/invite-request-response.html',
    'data': {
      'hideFooter': true,
      'hideHeader': true,
      'hideSidenav': true,
    },
  });
});

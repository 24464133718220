(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.inventory')
    .controller('InventoryPrintSuppliersCtrl', InventoryPrintSuppliersCtrl);

  //
  // !WARNING¡ This duplicates code from inventorySuppliersController. It's now
  // possible for this code to become out of sync. Please clean this up as soon
  // as possible. The code is also modified slightly to fit this use-case. It's
  // a bit of technical debt that needs to be repaid by merging the common
  // elements both controllers together.
  //

  function InventoryPrintSuppliersCtrl ($scope, $rootScope, $filter, elementService, inventoryItemService) {
    /*jshint validthis: true */
    var ctrl = this;
    var $printCtrl = $scope.$printCtrl;

    $scope.getItems = getItems;
    $scope.fetchBundleItems = fetchBundleItems;
    $scope.bundles = [];

    init();

    return ctrl;

    function init () {
      $printCtrl.init();
      $printCtrl.title = 'Your Suppliers for {0}'.format($rootScope.current_office.name);
      getItems();
      refreshSuppliers(true);
      $printCtrl.print();
    }

    //
    // getItems
    //
    function getItems () {
      $scope.items = [];
      var supplierMap = {};

      _.forEach(elementService.elementMaps.inventoryItem, function (item, item_id) {
        
        if(item.type === "Implant Item" && item.on_hand === 0) {
          return;
        } else {
          $scope.items.push(item);
        }

        if (!supplierMap[item.supplier_id]) {
          supplierMap[item.supplier_id] = 1;
        } else {
          supplierMap[item.supplier_id]++;
        }
        
      });

      _.forEach($scope.suppliers, function (supplier) {
        supplier.inventory_item_count = supplierMap[supplier.id] || 0;
      });

      return $scope.items;
    }

    //
    // Function to speed up template. Avoids _itemsFn from being called multiple times.
    //

    function fetchBundleItems (bundle) {
      bundle.items = $scope.getBundleItems(bundle, true);
      bundle.total_value = 0.00;
      bundle.total_on_hand = 0;
      var remove_items = [];

      _.forEach(bundle.items, function (item) {
        if(item.type === 'Implant Item' && item.on_hand === 0){
          remove_items.push(item);
          return;
        }
        if (item.on_hand) {
          bundle.total_on_hand += item.on_hand;
        }

        if (item.on_hand && item.price) {
          bundle.total_value += item.on_hand * item.price;
        }
      });

      _.forEach(remove_items, function(r_item){
        _.remove(bundle.items, r_item);
      });

      return bundle.items;
    }

    //
    // refreshSuppliers
    //

    function refreshSuppliers (force_bundle_refresh) {
      $printCtrl.startLoading();
      inventoryItemService.buildSupplierBundles().then(function (result) {
        $scope.bundles = result.bundles;
        // $scope.getItems();
        _.map($scope.bundles, fetchBundleItems);
      })['finally'](function () {
        $printCtrl.endLoading();
      });
    }
  }
}());

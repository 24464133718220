(function () {
  'use strict';
  
  angular
  .module('sowReports')
  .controller('ProductReportController', ProductReportController);
  
  function ProductReportController ($scope, $filter, officeService, errorService) {
    /*jshint validthis: true */
    var ctrl = this;
    
    ctrl.products = [];
    ctrl.displayProducts = [];
    ctrl.sortOrder = 'product_name';
    ctrl.searchText = '';
    ctrl.state = 'report';
    ctrl.selectedRange = 'This Year';
    ctrl.rangeOptions = ['This Year', 'All Time'];
    var t_today = $filter('translate')('TIME.TODAY');

    ctrl.updateRange = updateRange;
    ctrl.updateFilter = updateFilter;
    
    initialize();
    
    return ctrl;
    
    //
    // Initialize the Controller
    //
    function initialize () {
      updateRange();
    }
    
    //
    // Update the list of products/rows to display based on the filter
    // parameters.
    //
    function updateFilter () {
      ctrl.displayProducts = _.filter(ctrl.products, filterRow);
    }
    
    //
    // Filter a row/product by the search text
    //
    function filterRow (row) {
      var lcSearchText = (ctrl.searchText || '').toLowerCase();
      var lcProductName = (row.product_name || '').toLowerCase();
      var lcManufacturerName = (row.current_manufacturer_name || '').toLowerCase();
      
      return (
        lcProductName.includes(lcSearchText) ||
        lcManufacturerName.includes(lcSearchText)
      );
    }
      
    //
    // Update Data for the New Date Range
    //
    function updateRange () {
      ctrl.state = 'loading';
      
      if (ctrl.selectedRange === 'This Year') {
        ctrl.startMonth = moment().startOf('year');
        ctrl.endMonth = moment();
      } else if (ctrl.selectedRange === 'All Time') {
        var currentOffice = $scope.$root.current_office;
        ctrl.startMonth = moment(currentOffice.created_at);
        ctrl.endMonth = moment();
      } else {
        ctrl.startMonth = null;
        ctrl.endMonth = null;
        return;
      }
      
      return officeService.getProductStatsSummary(
        ctrl.startMonth.format(),
        ctrl.endMonth.endOf('month').format())
        .then(function (stats) {
          ctrl.products = stats;
          
          if (ctrl.products.length < 1) {
            ctrl.state = 'empty';
          } else {
            ctrl.state = 'report';
          }
          
          updateFilter();
          updateRangeLabels();
        }).catch(function (error) {
          ctrl.state = 'error';
          errorService.uiErrorHandler(error);
        });
    }
        
    //
    // Update The Display Labels for the Date Range
    //
    function updateRangeLabels () {
      var format = 'MMMM YYYY';
      
      ctrl.startMonthLabel = (
        moment.isMoment(ctrl.startMonth) ? ctrl.startMonth.format(format)
        : ctrl.startMonth instanceof Date ? moment(ctrl.startMonth).format(format)
        : null
      );
      
      ctrl.endMonthLabel = (
        isToday(ctrl.endMonth) ? t_today
        : moment.isMoment(ctrl.endMonth) ? ctrl.endMonth.format(format)
        : ctrl.endMonth instanceof Date ? moment(ctrl.endMonth).format(format)
        : null
      );
    }
            
    //
    // Just a quick check if a date is for today.
    //
    function isToday (date) {
      date = (
        moment.isMoment(date) ? date
        : date instanceof Date ? moment(date)
        : null
      );
      return date && date.isSame(moment(), 'day');
    }
  }
}());

angular.module('app.marketplace.ui.theme')
  .service('SowingoColorService', function (SowingoColors) {
    var _this = this;

    var _handleAlpha = function (alpha) {
      if (_.isNumber(alpha) && alpha > 1) {
        throw new Error('alpha > 1: ' + alpha);
      }

      if (_.isNumber(alpha) && alpha < 0) {
        throw new Error('alpha < 0: ' + alpha);
      }

      return _.isNumber(alpha) ? alpha : 1;
    };

    var _isValidColor = function (name) {
      return _.has(SowingoColors, name);
    };

    //
    // Generate a hex color value
    //
    _this.hex = function (name) {
      if (!_isValidColor(name)) {
        return '';
      }

      var color = SowingoColors[name];

      return (
        color.red.toString(16) +
        color.green.toString(16) +
        color.blue.toString(16)
      );
    };

    //
    // Generate a hash-hex color value (for css).
    //
    _this.hashedHex = function (name) {
      return '#' + _this.hex(name);
    };

    //
    // Generate a 'rgba()' form color
    //
    _this.rgba = function (name, alpha) {
      if (!_isValidColor(name)) {
        return '';
      }

      var color = SowingoColors[name];
      alpha = _handleAlpha(alpha);

      return 'rgba(' + [color.red, color.green, color.blue, alpha].join(', ') + ')';
    };

    //
    // Generate a 'rgb()' form color
    //
    _this.rgb = function (name) {
      if (!_isValidColor(name)) {
        return '';
      }

      var color = SowingoColors[name];

      return 'rgb(' + [color.red, color.green, color.blue].join(', ') + ')';
    };
  });

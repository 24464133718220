(function () {
  'use strict';
  
  angular
  .module('sowReports')
  .controller('SpendingCategoryController', SpendingCategoryController);
  
  function SpendingCategoryController($scope, $state, $window, sowReportsService2, $filter, orderByFilter, AccessService) {
    var ctrl = this;
    ctrl.yearLocal = $window.localStorage.getItem('yearLocal');
    ctrl.selectedYear = moment().year();
    if(ctrl.yearLocal){
      ctrl.selectedYear = ctrl.yearLocal;
    }   
    ctrl.labelNumber = 6;
    ctrl.dataResul;
    ctrl.rangeOptions = [{
      name: moment().year(),
      value: moment().year()
    }];
    var t_spending = $filter('translate')('COMMON.SPENDING');
    var t_cat_name = $filter('translate')('COMMON.CATEGORY_NAME');
    var t_total = $filter('translate')('COMMON.TOTAL');

    ctrl.chartSetting = {
      options: {
        responsive: true,
        maintainAspectRatio: true,
        legend: {
          display: false
        },
        tooltips: {
          enabled: true,
          displayColors: false,
          mode: 'single',
          callbacks: {
            label: function (tooltipItems, data) {
              return $filter('currency')(tooltipItems.xLabel, '$', 2);
            },
          },
        },
        scales: {
          xAxes: [{
            interval: 1000,
            gridLines: {
              drawTicks: true,
              color: 'rgba(128,142,155,0.25)',
              zeroLineColor: 'rgba(128,142,155,0.25)',
            },
            ticks: {
              beginAtZero: true,
              callback: function (value, index, values) {
                if (index == 0) {
                  return value;
                } else {
                  return $filter('currency')(value, '$', 0);
                }
              }
            }
          }],
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        },
        dataset: {
          barThickness: 20,
        },
        legend: {
          display: false,
          position: 'right',
          labels: {
            boxWidth: 10
          }
        }
      },
      series: [t_spending],
      type: "horizontalBar",
      colors: [{
        backgroundColor: '#9667d8',
        pointBackgroundColor: 'rgba(148,159,177,1)',
        pointHoverBackgroundColor: 'rgba(148,159,177,1)',
        borderColor: '#9667d8',
        pointBorderColor: '#fff',
        pointHoverBorderColor: 'rgba(148,159,177,0.8)'
      }]
    };
    
    //table data       
    var columnDefs = [
      { headerName: t_cat_name, field: 'categoryName', width: 160, pinned: 'left', filter: 'agTextColumnFilter',sortingOrder: ['desc','asc'],unSortIcon: true, filterParams: { cellHeight: 20 },cellClass: 'cell-wrap-text',
      autoHeight: true },
      { headerName: 'JAN', field: 'jan', width: 67.5, suppressSorting: true, cellRenderer: 'showCellRenderer' },
      { headerName: 'FEB', field: 'feb', width: 67.5, suppressSorting: true, cellRenderer: 'showCellRenderer' },
      { headerName: 'MAR', field: 'mar', width: 67.5, suppressSorting: true, cellRenderer: 'showCellRenderer' },
      { headerName: 'APR', field: 'apr', width: 67.5, suppressSorting: true, cellRenderer: 'showCellRenderer' },
      { headerName: 'MAY', field: 'may', width: 67.5, suppressSorting: true, cellRenderer: 'showCellRenderer' },
      { headerName: 'JUN', field: 'jun', width: 67.5, suppressSorting: true, cellRenderer: 'showCellRenderer' },
      { headerName: 'JUL', field: 'jul', width: 67.5, suppressSorting: true, cellRenderer: 'showCellRenderer' },
      { headerName: 'AUG', field: 'aug', width: 67.5, suppressSorting: true, cellRenderer: 'showCellRenderer' },
      { headerName: 'SEP', field: 'sep', width: 67.5, suppressSorting: true, cellRenderer: 'showCellRenderer' },
      { headerName: 'OCT', field: 'oct', width: 67.5, suppressSorting: true, cellRenderer: 'showCellRenderer' },
      { headerName: 'NOV', field: 'nov', width: 67.5, suppressSorting: true, cellRenderer: 'showCellRenderer' },
      { headerName: 'DEC', field: 'dec', width: 67.5, suppressSorting: true, cellRenderer: 'showCellRenderer' },
      {
        headerName: t_total, field: 'total', width: 93.5, pinned: 'right', cellRenderer: 'showCellRenderer',sortingOrder: ['desc','asc'],unSortIcon: true, comparator: function (a, b) {
          var x = 1 * a.valueData, y = 1 * b.valueData;
          return x == y ? 0 : (x > y) ? 1 : -1;
        }
      },
    ];
    
    ctrl.gridOptions = {
      angularCompileRows: true,
      api: {},
      components: {
        showCellRenderer: ShowCellRenderer
      },
      columnDefs: columnDefs,
      rowData: null,
      enableColResize: true,
      enableSorting: true,
      rowHeight: 70,
      onCellClicked: handleClick,
      pinnedBottomRowData: null,
      domLayout: 'autoHeight',
      onGridReady: function(params) {
        // in this example, the CSS styles are loaded AFTER the grid is created,
        // so we put this in a timeout, so height is calculated after styles are applied.
        setTimeout(function () {
          ctrl.gridOptions.api?.resetRowHeights?.();
        }, 500);
      },
      onColumnResized: onColumnResized
    };
    
    Array.prototype.sum = function (prop) {
      var total = 0
      for (var i = 0, _len = this.length; i < _len; i++) {
        const num = Number(this[i][prop]);
        if (!Number.isNaN(num)) {
          total += num;
        }
      }
      return total
    }
    
    Array.prototype.sumCategoryValue = function (prop) {
      var total = 0
      for (var i = 0, _len = this.length; i < _len; i++) {
        const num = Number(this[i][prop].valueData);
        if (!Number.isNaN(num)) {
          total += num;
        }
      }
      return total
    }

    function ShowCellRenderer() { }
    
    ShowCellRenderer.prototype.init = function (params) {
      var cellBlank = !params.value;
      if (cellBlank) { return null; }
      
      this.ui = document.createElement('div');
      this.ui.innerHTML =
      '<div class="value">'
      + $filter('currency')(params.value.valueData, '$', 2) + '' +
      '</div>' +
      '<div class="percent">'
      + params.value.percent + '%' +
      '</div>';
    };
    
    ShowCellRenderer.prototype.getGui = function () {
      return this.ui;
    };

    ctrl.updateCurrentYear = updateCurrentYear;
    ctrl.updateYearOptions = updateYearOptions;
    ctrl.getSpeedingOfficeReport = getSpeedingOfficeReport;
    ctrl.calPercentData = calPercentData;
    ctrl.setPercentTotal = setPercentTotal;
    ctrl.resetChart = resetChart;
    ctrl.changeChartData = changeChartData;
    ctrl.setDataForTable = setDataForTable;
    ctrl.setDataAndLabelForChart = setDataAndLabelForChart;
    ctrl.sort = sort;
    ctrl.sumArray = sumArray;
    ctrl.searchCategory = searchCategory;
    ctrl.handleClickFunction = handleClickFunction;
    ctrl.goToCategory = goToCategory;
    ctrl.chartClick = chartClick;
    ctrl.clearSearch = clearSearch;

    init();

    return ctrl;

    function init () {
      defineLocks();
      ctrl.resetChart();
      ctrl.getSpeedingOfficeReport(ctrl.selectedYear);
      ctrl.updateYearOptions();
    }

    function defineLocks() {
      ctrl.gl_budget_enabled = AccessService.getOfficeProperty('gl_budget_enabled');
    }
    
    function updateCurrentYear () {
      $window.localStorage.setItem('yearLocal', ctrl.selectedYear);
      ctrl.resetChart();
      if (ctrl.selectedYear) {
        ctrl.getSpeedingOfficeReport(ctrl.selectedYear);
      }
    }
    
    function updateYearOptions () {
      var first_date = moment($scope.$root.current_office.created_at).startOf('year');
      var last_date = moment();
      var years_count = last_date.diff(first_date, 'y');
      
      for (var i = 1; i <= years_count; i++) {
        var year_number = moment().subtract(i, 'y').format('YYYY');
        ctrl.rangeOptions.push({
          name: year_number,
          value: year_number
        });
      }
    }
    
    function getSpeedingOfficeReport (selectedYear) {
      sowReportsService2.getSpeedingCategoryReport(selectedYear).then(function (data) {
        ctrl.dataResul = data;
        var category = data[0].report.categories;
        ctrl.tableData = ctrl.setDataForTable(category);
        ctrl.percentTotal = ctrl.setPercentTotal(ctrl.tableData);
        var dataWithPercent = ctrl.calPercentData(ctrl.tableData, ctrl.percentTotal);
        
        var sort = [
          {colId: 'categoryName', sort: 'desc'}
        ];
        ctrl.gridOptions.api.setSortModel(sort);
        ctrl.gridOptions.api.setRowData(dataWithPercent);
        ctrl.gridOptions.api.setPinnedBottomRowData(ctrl.percentTotal);
        ctrl.changeChartData(data);
      });
    }
    
    function calPercentData (tableData, percentTotal) {
      for (var i = 0; i < tableData.length; i++) {
        var item = tableData[i];
        if (percentTotal[0].jan.valueData > 0) {
          item.jan.percent = Math.round((item.jan.valueData * 100) / percentTotal[0].jan.valueData);
        }
        if (percentTotal[0].feb.valueData > 0) {
          item.feb.percent = Math.round((item.feb.valueData * 100) / percentTotal[0].feb.valueData);
        }
        if (percentTotal[0].mar.valueData > 0) {
          item.mar.percent = Math.round((item.mar.valueData * 100) / percentTotal[0].mar.valueData);
        }
        if (percentTotal[0].apr.valueData > 0) {
          item.apr.percent = Math.round((item.apr.valueData * 100) / percentTotal[0].apr.valueData);
        }
        
        if (percentTotal[0].may.valueData > 0) {
          
          item.may.percent = Math.round((item.may.valueData * 100) / percentTotal[0].may.valueData);
        }
        
        if (percentTotal[0].jun.valueData > 0) {
          
          item.jun.percent = Math.round((item.jun.valueData * 100) / percentTotal[0].jun.valueData);
        }
        if (percentTotal[0].jul.valueData > 0) {
          
          item.jul.percent = Math.round((item.jul.valueData * 100) / percentTotal[0].jul.valueData);
        }
        if (percentTotal[0].aug.valueData > 0) {
          
          item.aug.percent = Math.round((item.aug.valueData * 100) / percentTotal[0].aug.valueData);
        }
        if (percentTotal[0].sep.valueData > 0) {
          item.sep.percent = Math.round((item.sep.valueData * 100) / percentTotal[0].sep.valueData);
        }
        if (percentTotal[0].oct.valueData > 0) {
          
          item.oct.percent = Math.round((item.oct.valueData * 100) / percentTotal[0].oct.valueData);
        }
        if (percentTotal[0].nov.valueData > 0) {
          item.nov.percent = Math.round((item.nov.valueData * 100) / percentTotal[0].nov.valueData);
        }
        if (percentTotal[0].dec.valueData > 0) {
          item.dec.percent = Math.round((item.dec.valueData * 100) / percentTotal[0].dec.valueData);
        }
        if (percentTotal[0].total.valueData > 0) {
          item.total.percent = Math.round((item.total.valueData * 100) / percentTotal[0].total.valueData);
        }
      }
      
      return tableData;
    }
    
    function setPercentTotal (dataTable) {
      var data = [];
      var t_monthly_total = $filter('translate')('REPORTS.MULTI.MONTHLY_TOTAL');
      var percent = {
        categoryName: t_monthly_total,
        categoryID: '',
        jan: {
          month: 1,
          valueData: dataTable.sumCategoryValue('jan'),
          percent: 100
        },
        feb: {
          month: 2,
          valueData: dataTable.sumCategoryValue('feb'),
          percent: 100
        },
        mar: {
          month: 3,
          valueData: dataTable.sumCategoryValue('mar'),
          percent: 100
        },
        apr: {
          month: 4,
          valueData: dataTable.sumCategoryValue('apr'),
          percent: 100
        },
        may: {
          month: 5,
          valueData: dataTable.sumCategoryValue('may'),
          percent: 100
        },
        jun: {
          month: 6,
          valueData: dataTable.sumCategoryValue('jun'),
          percent: 100
        },
        jul: {
          month: 7,
          valueData: dataTable.sumCategoryValue('jul'),
          percent: 100
        },
        aug: {
          month: 8,
          valueData: dataTable.sumCategoryValue('aug'),
          percent: 100
        },
        sep: {
          month: 9,
          valueData: dataTable.sumCategoryValue('sep'),
          percent: 100
        },
        oct: {
          month: 10,
          valueData: dataTable.sumCategoryValue('oct'),
          percent: 100
        },
        nov: {
          month: 11,
          valueData: dataTable.sumCategoryValue('nov'),
          percent: 100
        },
        dec: {
          month: 12,
          valueData: dataTable.sumCategoryValue('dec'),
          percent: 100
        },
        total: {
          valueData: dataTable.sumCategoryValue('total'),
          percent: 100
        }
      };
      
      data.push(percent);
      return data;
    }
    
    function resetChart () {
      ctrl.currentChartShow = {
        labels: [],
        data: [],
        year: ctrl.selectedYear,
        totalSpent: 0,
        highestSpending: {
          total: 0,
          name: null,
          id: null
        },
        average: 0
      };
    }
    
    function changeChartData (data) {
      var category = data[0].report.categories;
      
      if (category.length > 0) {
        ctrl.setDataAndLabelForChart(category);
        ctrl.currentChartShow.highestSpending.total = data[0].report.highest_spending_category.category_total;
        ctrl.currentChartShow.highestSpending.name = data[0].report.highest_spending_category.category_name;
        ctrl.currentChartShow.highestSpending.id = data[0].report.highest_spending_category.category_id;
      } else {
        // handle chart empty
      }
    }
    
    function setDataForTable (categories) {
      var data = [];
      
      for (var i = 0; i < categories.length; i++) {
        var perItem = {
          categoryName: '',
          categoryID: '',
          jan: {
            month: 1,
            valueData: 0,
            percent: 0
          },
          feb: {
            month: 2,
            valueData: 0,
            percent: 0
          },
          mar: {
            month: 3,
            valueData: 0,
            percent: 0
          },
          apr: {
            month: 4,
            valueData: 0,
            percent: 0
          },
          may: {
            month: 5,
            valueData: 0,
            percent: 0
          },
          jun: {
            month: 6,
            valueData: 0,
            percent: 0
          },
          jul: {
            month: 7,
            valueData: 0,
            percent: 0
          },
          aug: {
            month: 8,
            valueData: 0,
            percent: 0
          },
          sep: {
            month: 9,
            valueData: 0,
            percent: 0
          },
          oct: {
            month: 10,
            valueData: 0,
            percent: 0
          },
          nov: {
            month: 11,
            valueData: 0,
            percent: 0
          },
          dec: {
            month: 12,
            valueData: 0,
            percent: 0
          },
          total: {
            valueData: 0,
            percent: 0
          }
        }
        var category = categories[i];
        var order = category.orders;
        
        perItem.categoryName = category.category_name;
        perItem.categoryID = category.category_id;
        perItem.total.valueData = category.category_total;
        
        for (var j = 0; j < order.length; j++) {
          var month = (moment(order[j].order_date, 'MMM, YYYY').format('MMM')).toLowerCase();
          perItem[month].valueData = parseFloat(order[j].monthly_total);
        }
        
        data.push(perItem);
      }
      
      return data;
    }
    
    function setDataAndLabelForChart (category) {
      category = ctrl.sort(category, 'category_total', true);
      var labelLength = ctrl.labelNumber;
      var chartData = [];
      var label = [];
      ctrl.currentChartShow.data = [];
      if (category.length < ctrl.labelNumber) {
        labelLength = category.length;
      }
      for (var i = 0; i < labelLength; i++) {
        var item = category[i];
        chartData.push(item.category_total);
        label.push(item.category_name);
      }
      
      ctrl.currentChartShow.labels = label;
      ctrl.currentChartShow.data.push(chartData);
      var totalSpend = category.sum('category_total');
      ctrl.currentChartShow.totalSpent = totalSpend;
      ctrl.currentChartShow.average = totalSpend / 12;
    }
    
    function sort (array, sortByName, sortOrder) {
      var newArray = [];
      if (array.length > 0) {
        newArray = orderByFilter(array, sortByName, sortOrder);
      }
      return newArray;
    }
    
    function sumArray (a, b) {
      return a + b;
    }
    
    function onColumnResized(event) {
      if (event.finished) {
        ctrl.gridOptions.api?.resetRowHeights?.();
      }
    }
    
    function searchCategory () {
      
      var athleteFilterComponent = ctrl.gridOptions.api.getFilterInstance('categoryName');
      athleteFilterComponent.setModel({
        type: 'contains',
        filter: ctrl.categoryKeySearch
      });
      ctrl.gridOptions.api.onFilterChanged();
    }
    
    function createData (count, prefix) {
      var result = [];
      result.push(ctrl.percentTotal);
      return result;
    }
    
    function handleClick(event) {
      ctrl.handleClickFunction(event);
    }
    
    function handleClickFunction (event) {
      if ((event.column.colId == "categoryName" || event.column.colId == "total") && event.rowPinned != "bottom") {
        $state.go('app.reports.spending.category-detail', { categoryID: event.data.categoryID, year: ctrl.selectedYear, categoryName: event.data.categoryName });
      } else if (event.column.colId != "total" && event.column.colId != "categoryName" && event.rowPinned != "bottom") {
        $state.go('app.reports.spending.category-month', { categoryID: event.data.categoryID, year: ctrl.selectedYear, month: event.value.month, categoryName: event.data.categoryName });
      }
    }
    
    function goToCategory (name, id){
      $state.go('app.reports.spending.category-detail', { categoryID: id, year: ctrl.selectedYear, categoryName: name });
    }
    
    function chartClick (points){
      if(points.length > 0){
        var categoryName =points[0]._model.label;
        var report = ctrl.dataResul[0].report;
        var category = report.categories;
        var id = "";
        for( var i = 0; i < category.length; i++){
          if(category[i].category_name == categoryName){
            id = category[i].category_id;
          }
        }
        
        $state.go('app.reports.spending.category-detail', { categoryID: id, year: ctrl.selectedYear, categoryName: categoryName});
      }
    }
    
    function clearSearch (){
      ctrl.categoryKeySearch = '';
      ctrl.searchCategory();
    }
  }
}());

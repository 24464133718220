{
  /** @ngInject */
  class InteropService {
    // This service is meant to provide interoperability between the angular app and the react app.
    // It is not meant to be used as a means of direct data access,
    // but rather as a means of communication and updating stale data
    WOFE_PREFIX = 'wofe:';
    REACT_PREFIX = 'react:';
    constructor($rootScope, $window, WOFE_VERSION) {
      this.$rootScope = $rootScope;
      this.$window = $window;
      this.WOFE_VERSION = WOFE_VERSION;
    }

    /**
     * Creates a new event with the name prefixed by WOFE_PREFIX, and then dispatches
     * that event to the window, where it can be listened to by react code.
     * 
     * @param eventName - The name of the event you want to broadcast.
     * @param eventData - The data you want to send with the event.
     */
    dispatchToWindow (eventName, eventData) {
      const event = new CustomEvent(`${this.WOFE_PREFIX} ${eventName}`, {
        detail: {
          eventName: eventName,
          eventData: eventData
        }
      });
      window.dispatchEvent(event);
    }

    /**
     * Listens to a react event with the name prefixed by REACT_PREFIX, 
     * and then runs the callback function
     * 
     * @param eventName - The name of the event you want to listen to.
     * @param callback - The function that will be called when the event is triggered. 
     * (expects arguments event and eventData)
     */
    listenToReactEvent (eventName, callback) {
      window.addEventListener(`${this.REACT_PREFIX} ${eventName}`, (event) => {
        callback(event, event.detail?.eventData);
      });
    }


    /**
     * Dispatches a storage event to the window, where it can be picked up by react code.
     */
    triggerStorageChange () {
      this.dispatchToWindow('local-storage-change');
    }


    triggerAppInit () {
      this.dispatchToWindow('app-init', {npm_package_version: this.WOFE_VERSION});
      this.logEvent({
        level: 'INFO',
        message: `App initialized with version ${this.WOFE_VERSION}`,
        defaultMethodName: 'triggerAppInit'
      });
    }

    /**
     * Logs an event by dispatching it to the window.
     * 
     * Example usage:
     * ```javascript
     * sowInteropService.logEvent({
     *  level: 'INFO',
     *  message: 'This is an info message',
     *  defaultMethodName: 'methodName'
     * })
     * ```
     *
     * @param {string} level - The level of the log (e.g., 'INFO' | 'WARN' | 'ERROR' | 'DEBUG').
     * @param {string} message - The message to log.
     * @param {string} [defaultMethodName=''] - The default method name associated with the log event.
     */
    logEvent ({level, message, defaultMethodName = ''}) {
      this.dispatchToWindow('log-event', {level, message, defaultMethodName});
    }

    /**
     * Broadcasts a message with event data to a specified channel over all tabs.
     * 
     * @param {string} channel_name - The name of the broadcast channel you
     * want to send the message to. Defaults to the session channel.
     * @param {object} event_data - The data you want to send through
     * the broadcast channel.
     */
    broadcastToTabs (channel_name, event_data = {}) {
      if (!this.$window.BroadcastChannel) return;
      const parsed_event_data = removeFunctionsFromObject(event_data);
      const channel = new this.$window.BroadcastChannel(channel_name);
      channel.postMessage(parsed_event_data);
    }

    /**
     * Listens to events in a given channel and executes a callback when new messages are received.
     * 
     * @param {string} channel_name - The name of the broadcast channel you
     * want to receive the message from. Defaults to the session channel.
     * @param {Function} callback - A function to be executed whenever a message
     * is received on the specified channel.
     * @return {BroadcastChannel} - The instance of the broadcast channel you subscribed to.
     */
    subscribeToTabs (channel_name, callback) {
      if (!this.$window.BroadcastChannel) return;
      const channel = new this.$window.BroadcastChannel(channel_name);
      channel.addEventListener('message', callback);
      return channel;
    }

    /**
     * The function `unsubscribe` closes a broadcast channel and removes the event listener for
     * incoming messages.
     * @param {string} channel_name - The name of the broadcast channel you want to unsubscribe from. 
     * Defaults to the session channel.
     */
    unsubscribeFromTabs (channel_name) {
      if (!this.$window.BroadcastChannel) return;
      const channel = new this.$window.BroadcastChannel(channel_name);
      channel.removeEventListener('message', channel.onmessage);
      channel.close();
    }

  }

  angular.module('sowInterop')
    .service("sowInteropService", InteropService);

  // this was necessary for the broadcastToTabs function to work
  // because the BroadcastChannel API does not allow functions to be sent through the channel
  // https://developer.mozilla.org/en-US/docs/Web/API/Web_Workers_API/Structured_clone_algorithm#things_that_dont_work_with_structured_clone
  function removeFunctionsFromObject(obj) {
    const newObj = {...obj};
    for (const key in newObj) {
      if (_.isFunction(newObj[key])) {
        delete newObj[key];
      } else if (!_.isNil(newObj[key]) && _.isObject(newObj[key])) {
        newObj[key] = removeFunctionsFromObject(newObj[key]);
      }
    }
    return newObj;
  }
}

(function () {
  'use strict';
  
  angular
  .module('sowReports')
  .controller('ImplantsPlacementPatientDetailController', ImplantsPlacementPatientDetailController);
  
  function ImplantsPlacementPatientDetailController(sgToast, $state, $filter, sowImplantService , sowImplantPlacementService, $mdDialog, errorService , orderByFilter, appEvents, $rootScope, membershipService) {
    /*jshint validthis:true*/
    var ctrl = this;
    
    ctrl.indexSort = 0;
    ctrl.propertyName = 'new_date';
    ctrl.reverse = false;
    ctrl.sets = {
      'Implant': {
        color: "#398ee3",
        set_name: 'implants',
        display_name: $filter('translate')('IMPLANTS.IMPLANTS'),
        category: 'Implant',
        display_name: $filter('translate')('IMPLANTS.IMPLANTS'),
        placements: [],
        img: {
          svg: "styles/img/implants/implants-icon-v3.svg",
        }
      },
      'Closure Cap': {
        color: "#7a41cb",
        set_name: 'caps',
        display_name: $filter('translate')('IMPLANTS.COVER_SCREWS'),
        category: 'Closure Cap',
        display_name: $filter('translate')('IMPLANTS.COVER_SCREWS'),
        placements: [],
        img: {
          svg: "styles/img/implants/caps-icon.svg",
        }
      },
      'Healing Abutment': {
        color: "#2eb479",
        set_name: 'abutments',
        display_name: $filter('translate')('IMPLANTS.HEALING_ABUTMENTS'),
        category: 'Healing Abutment',
        display_name: $filter('translate')('IMPLANTS.HEALING_ABUTMENTS'),
        placements: [],
        img: {
          svg: "styles/img/implants/healing-icon.svg",
        }
      },
      'Bone Graft': {
        color: "#33bbc4",
        set_name: 'bonegraft',
        category: 'Bone Graft',
        display_name: $filter('translate')('IMPLANTS.BONE_GRAFTS'),
        placements: [],
        img: {
          material: "grain",
        }
      },
      'Membrane': {
        color: "#ff2566",
        set_name: 'membranes',
        display_name: $filter('translate')('IMPLANTS.MEMBRANES'),
        category: 'Membrane',
        display_name: $filter('translate')('IMPLANTS.MEMBRANES'),
        placements: [],
        img: {
          material: "healing",
        }
      }
    };
    
    ctrl.editRemoval = editRemoval;
    ctrl.getPatientDetail = getPatientDetail;
    ctrl.setColorAndImg = setColorAndImg;
    ctrl.undo = undo;
    ctrl.sortBy = sortBy;

    init();

    return ctrl;

    function init () {
      defineLocks();
      ctrl.getPatientDetail();
    }
    
    function getPatientDetail () {
      sowImplantPlacementService.getPatientReportDetail($state.params.patient_id).then(function (result) {
        ctrl.data = Object.values(ctrl.setColorAndImg(result));
        ctrl.sortBy(ctrl.propertyName, ctrl.indexSort);
      });
    }
    
    function setColorAndImg (result) {
      var p = result.product_placements;
      var s = angular.copy(ctrl.sets);
      p.forEach(function(element) {
        var pla = element.placements;
        pla.forEach(function(el){
          el.new_date = moment(el.date, 'DD MMM, YYYY').format('YYYYMMDDHHmmss');
          el.d = moment(el.date, 'DD MMM, YYYY').format('DD MMM, YYYY');
        });
        if (s[element.category]) {
          s[element.category].placements = pla;
          s[element.category].all_placements_are_external = pla.every(el => el.is_external_id);
        }
      });
      return s;
    }
    
    function undo (item) {
      item.implant_inventory_item_id = item.imp_inventory_item_id;
      item.position = item.tooth_placement_format + ' - ' + item.tooth_placement_value;
      $mdDialog.show({
        controller: 'poDialogController as dialogCtrl',
        templateUrl: 'sow-reports/modals/implant-return-to-inventory.html',
        parent: angular.element(document.body),
        clickOutsideToClose: true,
        // scope: $scope,
        locals: {
          item: item,
          // cancel: $mdDialog.close,
        },
        bindToController: true,
      })
      .then(function (answer) {
        item.id = item.implant_inventory_item_id;
        sowImplantService.getInventoryItem(item.implant_inventory_item_id)
        .then(function (item) {
          item.was_placed = false;
          sowImplantService.saveInventoryItem(item)
          .then(function (item) {
            var t_message = $filter('translate')('TOAST.PATIENT_HAS_BEEN_RETURNED');
            sgToast.showSimple(t_message);
            ctrl.getPatientDetail();
          }).catch(function (error) {
            var t_message = $filter('translate')('ERRORS.INVENTORY_RETURN')
            errorService.uiErrorHandler(t_message);
          });
        }).catch(function (error) {
          var t_message = $filter('translate')('ERRORS.IMPLANT_LOAD')
          errorService.uiErrorHandler(t_message);
        });
      });
    }
    
    function editRemoval (item) {
      $rootScope.$broadcast(appEvents.impEditRemovalFetch, item.imp_inventory_item_id, item);
    }
    
    function sortBy (propertyName, index) {
      ctrl.indexSort = index;
      ctrl.reverse = (ctrl.propertyName === propertyName) ? !ctrl.reverse : false;
      ctrl.propertyName = propertyName;
      var allProduct = ctrl.data[index].placements;
      if (allProduct.length > 0) {
        let comparator;
        // for tooth numbers only, we're using a different comparator function, see below
        if (propertyName === 'tooth_placement_value') {
          comparator = toothComparison;
        }
        ctrl.data[index].placements = orderByFilter(allProduct, ctrl.propertyName, ctrl.reverse, comparator);
      }
    }

    /**
     * Comparator function to replace the default one from AngularJS,
     * this one takes into account that the contents of both strings might be numeric
     * and compares based on that. Otherwise it compares alphabetically by default.
     * @see https://docs.angularjs.org/api/ng/filter/orderBy
     * 
     * @param currentObject - The current object being iterated over.
     * @param comparedObject - The object that is being compared to the current object.
     * @returns a number.
     */
    function toothComparison (currentObject, comparedObject) {
      const currentValue = currentObject.value;
      const comparedValue = comparedObject.value;
      if (hasOnlyNumbers(currentValue) && hasOnlyNumbers(comparedValue)) {
        return (parseInt(currentValue) < parseInt(comparedValue) ) ? -1 : 1;
      } else {
        return currentValue.localeCompare(comparedValue);
      }
    }

    function hasOnlyNumbers (text) {
      return /^\d+$/.test(text);
    }

    function defineLocks () {
      const membership = membershipService.get();

      const hide_report_prices_condition = _.get(membership, 'user_properties.reports.hide_report_prices', false);
      ctrl.hide_report_prices = hide_report_prices_condition;
    }
    
  }
  
  
}());

(function(){
  'use strict';
  angular.module("app.marketplace.ui.implants")
    .directive('actionBarImplants',impActionBarDirective);

  function impActionBarDirective() {
    return {
      restrict: 'AC',
      templateUrl: 'templates/marketplace/implants/directives/action-bar-implants.html',
      controller: 'impActionBarController',
      controllerAs: 'impAbCtrl'
    };
  }

}());

angular.module("app.shared.support")
  .constant('TimeUtils', {
    _months: {
      long: {
        0: 'January',
        1: 'February',
        2: 'March',
        3: 'April',
        4: 'May',
        5: 'June',
        6: 'July',
        7: 'August',
        8: 'September',
        9: 'October',
        10: 'November',
        11: 'December'
      },
      short: {
        0: 'Jan',
        1: 'Feb',
        2: 'Mar',
        3: 'Apr',
        4: 'May',
        5: 'Jun',
        6: 'Jul',
        7: 'Aug',
        8: 'Sep',
        9: 'Oct',
        10: 'Nov',
        11: 'Dec'
      },
      tiny: {
        0: 'JA',
        1: 'FE',
        2: 'MR',
        3: 'AL',
        4: 'MA',
        5: 'JN',
        6: 'JL',
        7: 'AU',
        8: 'SE',
        9: 'OC',
        10: 'NO',
        11: 'DE'
      }
    },

    getCurrentYear: function () {
      var now = new Date();
      return now.getFullYear();
    },

    paddedMonth: function (month) {
      return sprintf('%02d', month);
    },

    toMonthString: function (date) {
      return sprintf('%04d-%02d', date.getFullYear(), date.getMonth() + 1);
    },

    toReadableMonth: function (date) {
      return sprintf('%s %s',
        this._months.long[date.getMonth()],
        date.getFullYear()
      );
    },

    toMonthKey: function (date) {
      return (date.getFullYear() * 100) + (date.getMonth() + 1);
    },

    toMonth: function (date) {
      var newDate = angular.copy(date);
      newDate.setDate(1);
      newDate.setHours(0);
      newDate.setMinutes(0);
      newDate.setSeconds(0);
      newDate.setMilliseconds(0);
      return newDate;
    },

    decrementMonths: function (date, months) {
      var newDate = this.toMonth(date);
      var newMonth = date.getMonth() - months;

      if (newMonth < 0) {
        newDate.setFullYear(date.getFullYear() - 1);
        newDate.setMonth(12 + newMonth);
      } else {
        newDate.setMonth(newMonth);
      }

      return newDate;
    }
  });
(function () {
  'use strict';
  angular.module('sowSterilization')
  .controller('stznAddNewTestController', stznAddNewTestController);

  function stznAddNewTestController ($scope, $rootScope, $filter, $mdDialog, errorService, $q, $state, $window, $timeout,
    sowSterilizationService, stznHelperService, sowMachineService, formService, AccessService, selected_record, sgToast) {
    /*jshint validthis:true*/
    var ctrl = this;
    ctrl.users = null;
    ctrl.sterilizers = null;
    ctrl.indicatorCompanies = null;
    ctrl.saving_record = true;
    ctrl.saving_file = false;
    ctrl.test_type = 'spore';
    ctrl.object_urls = [];
    ctrl.aws_data = null;
    ctrl.chemical_indicator_options = stznHelperService.chemical_indicator_options;

    ctrl.cancelDialog = cancelDialog;
    ctrl.saveChemTest = saveChemTest;
    ctrl.saveBioTestClick = saveBioTestClick;
    ctrl.saveUltraTest = saveUltraTest;
    ctrl.saveWasherTest = saveWasherTest;
    ctrl.setRecordToLatest = setRecordToLatest;
    ctrl.openMachinesTab = openMachinesTab;
    ctrl.handleSterilizerChanged = handleSterilizerChanged;
    ctrl.handleModeChangedChemical = handleModeChangedChemical;
    ctrl.saveSporeTestClick = saveSporeTestClick;
    ctrl.setPassedResult = setPassedResult;
    ctrl.setFailedResult = setFailedResult;
    ctrl.editModalSaveChanges = editModalSaveChanges;
    ctrl.handleMachineSelection = handleMachineSelection;
    ctrl.handleStartDateChange = handleStartDateChange;
    ctrl.handleIncubationDurationChange = handleIncubationDurationChange;
    ctrl.openCycleLoadContainer = openCycleLoadContainer;
    ctrl.handleChemicalFormChanges = handleChemicalFormChanges;
    ctrl.chem_initial_values = {
      cycle_time: '',
      temperature: '',
      pressure: '',
    }

    //input type file needs scope fn
    $scope.handleFileChanged = ctrl.handleFileChanged = handleFileChanged; 
    ctrl.resetFile = resetFile;

    // functions imported from helper service for better reusability
    ctrl.acIndicatorCompanyNames = stznHelperService.acIndicatorCompanyNames;
    ctrl.confirmFailDialog = stznHelperService.confirmActionDialog;
    
    // Set the cycle number and load number limits to 7 digits
    ctrl.cycle_number_max_limit = 9999999;
    ctrl.load_number_max_limit = 9999999;
    ctrl.cycle_number_min_limit = 0;
    ctrl.load_number_min_limit = 0;
    ctrl.cycle_number_field_name = $filter('translate')('STERILIZATION.CYCLE_NUMBER');
    ctrl.load_number_field_name = $filter('translate')('STERILIZATION.LOAD_NUMBER');
  
    // Set the Incubation Duration Dropdown options
    const default_incubation = 24; // 24 hours
    const default_incubation_time_value = default_incubation * 60; // 24 hours in minutes
    ctrl.$onInit = init;

    return ctrl;

    function init () {
      _defineLocks();
      ctrl.incubation_options = _generateIncubationOptions();
      if (selected_record) {
        _initEditModal();
      }

      ctrl.saving_record = true;
      $q.all([
        loadMachines(),
        loadLatest(),
        ]).then(function(responses){
          ctrl.saving_record = false;
          setRecordToLatest();
        });

      _addDateWatchers();
    }

    /**
     * Adds watchers to the date-time input fields to ensure 
     * that the date is not cleared when the time is cleared.
     */
    function _addDateWatchers () {
      // In the case that the date time input is cleared, we need to keep the date but remove the time
      $scope.$watch(() => ctrl.record_data?.tested_at, (new_date, old_date) => {
        if (old_date && ctrl.record_data && new_date === undefined) {
          ctrl.record_data.tested_at = _deleteTimeFromDate(old_date)
        }
      });

      $scope.$watch(() => ctrl.record_data?.result_recorded_at, (new_date, old_date) => {
        if (old_date && ctrl.record_data && new_date === undefined) {
          ctrl.record_data.result_recorded_at = _deleteTimeFromDate(old_date)
        }
      });
    }

    function _deleteTimeFromDate (date) {
      return moment(date).startOf('day').toDate();
    }

    function _initEditModal () {
      ctrl.is_edit_modal = true;
      ctrl.test_type = selected_record.type;
      ctrl.edit_modal_type = _getEditModalTitle(selected_record.type);
      ctrl.record_data = parseRecord(selected_record);
    }

    function _getEditModalTitle (type) {
      const modal_type_locale_key = {
        spore: 'CS_TEST',
        biological: 'BS_TEST',
        chemical: 'SC_TEST',
        ultrasonic_cleaner: 'UC_TEST',
        washer_disinfector: 'WD_TEST',
      }[type];

      if (modal_type_locale_key) {
        return $filter('translate')(`STERILIZATION.${modal_type_locale_key}`);
      }
    }

    /**
     * Generates incubation options for the incubation duration dropdown.
     * It return an array of objects with a value and label property.
     * The value is the number of minutes and the label is the formatted string.
     * @returns {Array<{value: number; label: string}>}
     */
    function _generateIncubationOptions() {      
      const incubation_durations_minutes = [10, 20, 24, 30].map((minutes) => {
        return {
          value: minutes,
          label: `${minutes} ${$filter('translate')('TIME.MINUTES')}`
        }
      })

      const incubation_durations_hours = [1, 2, 3, 4, 10, default_incubation].map((hours) => {
        // if the hours value is 1, we want to display 'hour' instead of 'hours'
        const hours_label = hours === 1 ? $filter('translate')('TIME.HOUR') : $filter('translate')('TIME.HOURS');

        return {
          value: hours * 60,
          label: `${hours} ${hours_label}`
        }
      })

      return [...incubation_durations_minutes, ...incubation_durations_hours];
    }

    function handleIncubationDurationChange () {
      _setRecordedAtValue(ctrl.record_data);
    }

    function handleStartDateChange () {
      _setRecordedAtValue(ctrl.record_data)
    }


    function _setRecordedAtValue (record = ctrl.record_data) {
      record.result_recorded_at = stznHelperService.setEndDateUsingIncubationData({
        tested_at: record.tested_at,
        incubator_time: record.incubator_time,
      })
      return record;
    }

    /**
     * Sets load numbers and cycle numbers based on the test type
     * @returns {void}
     */
    function _getLoadAndCycleNumbers (data = ctrl.record_data) {
      switch (ctrl.test_type) {
        case 'biological':
          return {
            bio_load_number: data.sterilizer?.bio_load_number,
            cumulative_load_number: data.sterilizer?.cumulative_load_number,
          }
        case 'chemical':
          return {
            chem_load_number: data.sterilizer?.chem_load_number,
            cumulative_load_number: data.sterilizer?.cumulative_load_number,
          }
        case 'ultrasonic_cleaner':
          return {
            cycle_number: data.ultra_machine?.cycle_number,
            cumulative_load_number: data.ultra_machine?.cumulative_load_number,
          }
        case 'washer_disinfector':
          return {
            cycle_number: data.washer_machine?.cycle_number,
            cumulative_load_number: data.washer_machine?.cumulative_load_number,
          }
        default:
          return {}
      }
    }

    function _removeUIFieldFromImages (images) {
      return _.map(images, (image) => {
        return _.omit(image, ['UI']);
      })
    }

    /**
     * Parses the record data for a chemical test to ensure that the cycle_time, temperature, and pressure
     * are floats or null.
     * @param {Object} record
     */
    function _parseChemicalRecord (record) {
      // Backend expects cycle_time, temperature, and pressure to be integers
      const chemical_fields = ['cycle_time', 'temperature', 'pressure'];
      if (ctrl.test_type === 'chemical') {

        // On the edit modal, we only want to parse the field if it exists in the record
        if(ctrl.is_edit_modal) {
          for (const field of chemical_fields) {
            if (record[field] !== undefined) {
              record[field] = _setFieldAsNullOrFloat(record[field]);
            }
          }
        } else {
          // For a POST request, we need to ensure that the fields are set to null if they are NaN
          for (const field of chemical_fields) {
            record[field] = _setFieldAsNullOrFloat(record[field]);
          }
        }
      }
    }

    /**
     * If the value is NaN, return null, otherwise return the value as a float.
     * @param {any} value
     * @returns {number|null}
     */
    function _setFieldAsNullOrFloat (value) {
      return _.isNaN(value) ? null : parseFloat(value);
    }

    function _setChemicalInitialValues (record) {
      ctrl.chem_initial_values = {
        pressure: record.pressure,
        temperature: record.temperature,
        cycle_time: record.cycle_time,
      }
    }

    // data functions

    function saveRecord ($event) {
      ctrl.saving_record = true;

      ctrl.record_data = {
        ...ctrl.record_data,
        images: _removeUIFieldFromImages(ctrl.record_data.images),
      }

      // Delete result_recorded_at if test type is not biological or spore 
      if(!_isBiologicalOrSpore()) {
        delete ctrl.record_data.result_recorded_at;
      }

      // Prevent react form to have old values
      if (ctrl.test_type === 'chemical') {
        _setChemicalInitialValues(ctrl.record_data);
      }
      
      return sowSterilizationService.saveNewRecord(ctrl.record_data)
      .then(function(record_response){
        $rootScope.$broadcast('stzn-new-test-saved', record_response);
        return $mdDialog.hide(record_response);
      })
      .catch(function(error){
        var t_message = $filter('translate')('ERRORS.RECORD_SAVE')
        errorService.uiErrorHandler(t_message);
      })
      .finally(function(){
        ctrl.saving_record = false;
      });
    }

    function setRecordMachineData () {
      var sterilizer_selected = _.get(ctrl.record_data, 'sterilizer.name', null);
      var preset_selected = _.get(ctrl.record_data, 'sterilizer_mode.name', null);
      if (sterilizer_selected) {
        if (!preset_selected) {
          resetMode();
        }
      } else {
        resetSterilizer();
        resetMode();
      }
      _.set(ctrl.record_data, 'sterilizer_mode_id', _.get(ctrl.record_data, 'sterilizer_mode.id', null));
      _.set(ctrl.record_data, 'sterilizer_mode_name', _.get(ctrl.record_data, 'sterilizer_mode.name', null));
      _.set(ctrl.record_data, 'sterilizer_id', _.get(ctrl.record_data, 'sterilizer.id', null));
      _.set(ctrl.record_data, 'machine_type', _.get(ctrl.record_data, 'sterilizer.machine_type', null));
      _.set(ctrl.record_data, 'machine_name', _.get(ctrl.record_data, 'sterilizer.name', null));
      _.set(ctrl.record_data, 'machine_serial', _.get(ctrl.record_data, 'sterilizer.machine_serial', null));
    }

    function saveChemTest (result) {
      if(ctrl.saving_record){
        return 0;
      }
      if(!formService.checkRequiredFields(ctrl.chemicalForm)){
        return 0;
      }
      updateRecordType();
      setRecordMachineData();
      _.set(ctrl.record_data, 'result', result);
      _parseChemicalRecord(ctrl.record_data);

      if(result === 'Passed'){
        return saveRecord();
      } else {
        return ctrl.confirmFailDialog(ctrl.record_data).then(function(result){
          if(result === true){
            saveRecord();
          }
        });
      }
    }

    function saveUltraTest (result) {
      if(ctrl.saving_record){
        return 0;
      }
      if(!formService.checkRequiredFields(ctrl.ultrasonicForm)){
        return 0;
      }
      updateRecordType();
      ctrl.record_data.ultrasonic_cleaner_id = ctrl.record_data.ultrasonic_cleaner_id || null;
      _.set(ctrl.record_data, 'result', result);
        
      if(result === 'Passed'){
        return saveRecord();
      } else {
        return ctrl.confirmFailDialog(ctrl.record_data).then(function(result){
          if(result === true){
            saveRecord();
          }
        });
      }
    }
    
    function saveWasherTest (result) {
      if(ctrl.saving_record){
        return 0;
      }
      updateRecordType();
      ctrl.record_data.washer_disinfector_id = ctrl.record_data.washer_disinfector_id || null;
      _.set(ctrl.record_data, 'result', result);
      if(!formService.checkRequiredFields(ctrl.washerForm)){
        return 0;
      }

      if(result === 'Passed'){
        return saveRecord();
      } else {
        return ctrl.confirmFailDialog(ctrl.record_data).then(function(result){
          if(result === true){
            saveRecord();
          }
        });
      }
      
    }

    function saveBioTestClick () {
      _setIncubationCycle()
      saveBioTest()
    }

    function saveSporeTestClick () {
      _setIncubationCycle()
      return saveSporeTest()
    }

    function _setIncubationCycle () {
      ctrl.record_data.result = "Incubation Cycle"
    }

    function saveBioTest () {
      if(ctrl.saving_record || !formService.checkRequiredFields(ctrl.biologicalForm)){
        return 0;
      }

      updateRecordType();
      setRecordMachineData();

      return saveRecord();
    }

    function saveSporeTest () {
      if(ctrl.saving_record || !formService.checkRequiredFields(ctrl.sporeForm)){
        return 0;
      }

      // A Spore test does not have a machine, sterilizer is hardcoded to Control Spore Test
      // so the backend can differentiate between a spore test and a biological test
      ctrl.record_data.sterilizer = {
        'name': 'Control Spore Test',
        'id': null,
      }
      ctrl.record_data.sterilizer_mode = {
        'id': null,
        'name': 'N/A',
      }

      updateRecordType();
      setRecordMachineData();

      return saveRecord();
    }

    function setPassedResult() {
      if (ctrl.test_type === 'spore') {
        ctrl.record_data.result = 'Control Passed';
        return saveSporeTest()
      }
      
      if (ctrl.test_type === 'biological') {
        ctrl.record_data.result = 'Passed';
        return saveBioTest()
      }
    }

    function setFailedResult() {
      if (ctrl.test_type === 'spore') {
        ctrl.record_data.result = 'Control Failed';
        return saveSporeTest()
      }

      if(!formService.checkRequiredFields(ctrl.biologicalForm)){
        return 0;
      }

      // Confirm dialog uses the machine data, so we need to set it before showing the dialog
      updateRecordType();
      setRecordMachineData();
      ctrl.record_data.result = 'Failed';

      // if the form is valid, we can show the confirmation dialog
      return ctrl.confirmFailDialog(ctrl.record_data).then(function(result){
        if(result === true){
          return saveRecord();       
        } else {
          ctrl.record_data.result = null;
        }
      });
    }


    // UI functions

    function updateRecordType () {
      _.set(ctrl.record_data, 'type', ctrl.test_type);
    }

    function setRecordToLatest (type) {
      // Selected record indicates that we are editing a test: Edit modal
      const latest_record = selected_record ?? _.omit(ctrl.latest_records[ctrl.test_type], ['images']);
      ctrl.record_data = parseRecord(latest_record);

      // basically findSterilizers for ultra & washer
      if (latest_record.washer_disinfector_id) {
        _.set(ctrl.record_data, 'washer_machine', _.find(ctrl.washer_machines, ['id', latest_record.washer_disinfector_id]));
      }
      if (latest_record.ultrasonic_cleaner_id) {
        _.set(ctrl.record_data, 'ultra_machine', _.find(ctrl.ultrasonic_machines, ['id', latest_record.ultrasonic_cleaner_id]));
      }
      setRecordMachineData();
      _saveInitialFormData();
      _setCycleAndLoadForAddModal();

      _handleUltrasonicFields();
    }

    /**
     * For the 'ultrasonic_cleaner' test type, it hides the fields if both
     * cycle number and cumulative load number are not set.
     * 
     * It also sets the initial values for the solution range error and 
     * the solution filled at and emptied at fields.
     */
    function _handleUltrasonicFields() {
      if (ctrl.test_type === 'ultrasonic_cleaner') {
        // Set initial values to null to prevent validation errors
        ctrl.record_data.solution_filled_at = moment(ctrl.record_data.tested_at).startOf('day').toDate();
        ctrl.record_data.solution_emptied_at = moment(ctrl.record_data.tested_at).startOf('day').toDate();

        // Hide the cycle and load number fields for ultrasonic cleaner 
        // when the cycle number and load number are not set
        if (!ctrl.record_data.cycle_number && !ctrl.record_data.cumulative_load_number) {
          ctrl.show_cycle_load_container = false;
        } else {
          ctrl.show_cycle_load_container = true;
        }
      }
    }

    function openCycleLoadContainer () {
      ctrl.show_cycle_load_container = true;
    }

    function _setCycleAndLoadForAddModal () {
      // With the edit modal we want to use the values from the selected record
      if (!ctrl.is_edit_modal && ctrl.test_type !== 'spore') {
        _updateRecordDataWithCycleAndLoad()
      }
    }

    function _saveInitialFormData () {
      // Save the initial form data to compare with the updated data when saving
      ctrl.initial_form_data = _.cloneDeep(ctrl.record_data);
    }

    function parseRecord (item) {
      var record = angular.copy(item);

      // search for machine id bc api won't serve it
      if(record.type === 'biological' || record.type === 'chemical'){
        findSterilizerObjects(record);
        _setChemicalInitialValues(record);
      }

      if (ctrl.is_edit_modal) {
        record.tested_at = moment(record.tested_at).seconds(0).milliseconds(0).toDate();

        if (record.result_recorded_at) {
          record.result_recorded_at = moment(record.result_recorded_at).seconds(0).milliseconds(0).toDate();
        }

        record.images = record.images || [];

      } else {
        // Remove seconds and milliseconds from current date
        const date_with_no_seconds = moment(new Date()).seconds(0).milliseconds(0).toDate()
        _.set(record, 'tested_at', date_with_no_seconds);

        // Set Incubation End Date using tested_at value
        if (_isBiologicalOrSpore()) {
          _setRecordedAtValue(record);
          _setIncubationDuration(record);
        }

        // Set default chemical indicator to Class 5 if it is not set
        if (record.type === 'chemical' && !record.chemical_indicator) {
          record.chemical_indicator = 'class5';
        }

        record.images = [];
      }

      return record;
    }

    /**
     * Sets the incubation time for a record if it is not already defined.
     * The default incubation time is 24 hours.
     * @param {object} record
     */
    function _setIncubationDuration (record) {
      if (!record.incubator_time) {
        record.incubator_time = default_incubation_time_value;
      }
      return record;
    }

    function _isBiologicalOrSpore () {
      return ['biological', 'spore'].includes(ctrl.test_type);
    }

    function findSterilizerObjects (record) {
      var found_sterilizer_id = null;
      var locations = {
        'biological': ctrl.bio_sterilizers,
        'chemical': ctrl.chem_sterilizers
      };
      _.forEach(locations[record.type], function(sterilizer){
        if(found_sterilizer_id){
          return;
        }

        // Loop through the modes to find the sterilizer and sterilizer_mode
        // only if the sterilizer_mode_id is set
        if (record.sterilizer_mode_id) {
          _.forEach(sterilizer.modes, function(mode){
            if(mode.id === record.sterilizer_mode_id){
              found_sterilizer_id = sterilizer.id;
              _.set(record, 'sterilizer', sterilizer);
              _.set(record, 'sterilizer_id', sterilizer.id);
              _.set(record, 'sterilizer_mode', mode);
              return;
            }
          });
        }

        // If the sterilizer_mode_id is not set, we can still set the sterilizer using the sterilizer_id
        if (record.sterilizer_id && !record.sterilizer_mode_id) {
          if(sterilizer.id === record.sterilizer_id){
            found_sterilizer_id = sterilizer.id;
            _.set(record, 'sterilizer', sterilizer);
            _.set(record, 'sterilizer_id', sterilizer.id);
            return;
          }
        }
      });
      return record;
    }

    function cancelDialog () {
      return $mdDialog.cancel();
    }

    function loadMachines () {
      var promises = [
        loadSterilizers(),
        loadUltrasonicMachines(),
        loadWasherMachines()
      ];

      return $q.all(promises);
    }

    function loadLatest () {
      return sowSterilizationService.getLatestRecords()
      .then(function(results){
        ctrl.latest_records = results;
      });
    }

    /**
     * Returns the active form based on the controller `test_type` value.
     * @returns {Object}
     */
    function _getActiveForm () {
      const forms = {
        spore: ctrl.sporeForm,
        biological: ctrl.biologicalForm,
        chemical: ctrl.chemicalForm,
        ultrasonic_cleaner: ctrl.ultrasonicForm,
        washer_disinfector: ctrl.washerForm,
      }

      return forms[ctrl.test_type]
    }

    /**
     * Retrieves and filters out changed form field values, including
     * specific handling for the 'sterilizer' field.
     * @param {Object} active_form sporeForm, biologicalForm, chemicalForm, ultrasonicForm, washerForm
     * @returns {Object}
     */
    function getFormChangedValues(active_form) {
      const updated_data = _.cloneDeep(ctrl.record_data);

      const form_fields = formService.getFieldsWithChangedValues({
        form: active_form, 
        initial_form_data: ctrl.initial_form_data,
        updated_data, 
        // tested_at contains the time, so we need to filter it out
        filtered_fields: ['test_time']
      });

      const new_data = _.pick(updated_data, form_fields);

      // Check if the sterilizer has changed and if so, add the sterilizer_id
      _checkIfSterilizerHasChanged(form_fields, new_data, 'sterilizer');

      // Check if the sterilizer_mode has changed and if so, add the sterilizer_mode_id
      _checkIfSterilizerHasChanged(form_fields, new_data, 'sterilizer_mode');

      // Check if test result has changed and if so, add the result
      if (ctrl.initial_form_data.result !== ctrl.record_data.result) {
        new_data.result = ctrl.record_data.result;
      }

      return new_data;
    }

    /**
     * Checks if a sterilizer/sterilizer_mode field in a form has changed and adds the id to the new_data object.
     * @param {Array} form_fields - list of fields that have changed
     * @param {Object} new_data - the updated record data
     * @param {'sterilizer'|'sterilizer_mode'} field_name
     */
    function _checkIfSterilizerHasChanged (form_fields, new_data, field_name) {
      if (form_fields?.includes(field_name)) {
        _.set(new_data, `${field_name}_id`, _.get(ctrl.record_data, `${field_name}.id`, null));

        // Backend needs the id, not the object
        delete new_data[field_name];
      }

      return new_data;
    }

    /**
     * Removes the `$$hashKey` property from each image object in the `images` array.
     * @param {Array} images 
     * @returns {Array} images with the `$$hashKey` property removed
     */
    function removeHashKeyFromImages (images) {
      return images.map((image) => _.omit(image, ['$$hashKey']))
    }

    function _handleImagesChanges (new_data) {
      const new_images = removeHashKeyFromImages(ctrl.record_data.images);

      // Check if the images have changed and if so, remove the UI field from the images
      if (!_.isEqual(ctrl.initial_form_data.images, new_images)) {
        new_data.images = _removeUIFieldFromImages(new_images);
      }
    }

    function editModalSaveChanges () {
      const active_form = _getActiveForm();

      // Check that form is valid
      if (!formService.checkRequiredFields(active_form)) {
        return
      }

      const new_data = getFormChangedValues(active_form);  
      _handleImagesChanges(new_data);

      _parseChemicalRecord(new_data);

      // If there are no changes, do not update the record
      if (_.isEmpty(new_data)) {
        return $mdDialog.hide();
      }

      return updateSelectedRecord(new_data)
    }

    function updateSelectedRecord (new_data) {
      ctrl.saving_record = true;
      return sowSterilizationService.updateRecord(selected_record.id, new_data, ctrl.test_type)
      .then(function(response){
        const t_message = $filter('translate')('TOAST.CHANGES_UPDATED');
        sgToast.showSimple(t_message);
        $rootScope.$broadcast('stzn-new-test-edited', response, selected_record);
        $mdDialog.hide(response);
      })
      .catch(function(error){
        const t_message = $filter('translate')('ERRORS.RECORD_UPDATE');
        errorService.uiErrorHandler(t_message);
      })
      .finally(function(){
        ctrl.saving_record = false;
      });
    }

    function loadSterilizers () {
      return sowMachineService.getSterilizers().then(function (sterilizers) {
        ctrl.chem_sterilizers = sterilizers;
        ctrl.bio_sterilizers = sterilizers;
      }).catch(function (error) {
        var t_message = $filter('translate')('ERRORS.STERILIZERS_LOAD');
        errorService.uiErrorHandler(t_message);
      });
    }


    function loadUltrasonicMachines () {
      return sowMachineService.getUltrasonicCleaners().then(function (ultrasonic_machines) {
        ctrl.ultrasonic_machines = ultrasonic_machines;
      }).catch(function (error) {
        var t_message = $filter('translate')('ERRORS.CLEANERS_LOAD');
        errorService.uiErrorHandler(t_message);
      });
    }

    function loadWasherMachines () {
      return sowMachineService.getWasherDisinfectors().then(function (washer_machines) {
        ctrl.washer_machines = washer_machines;
      }).catch(function (error) {
        var t_message = $filter('translate')('ERRORS.WASHERS_LOAD');
        errorService.uiErrorHandler(t_message);
      });
    }

    function openMachinesTab (type) {
      var url = $state.href('app.stzn.machinelist', {add_new:type});
      $window.open(url,'_blank');
      resetMode();
      resetSterilizer();
    }

    function handleSterilizerChanged () {
      resetMode();
      _updateRecordDataWithCycleAndLoad();
      _selectFirstMode();
    }

    /**
     * Selects the first mode from the sterilizer's list of modes, if available.
     */
    function _selectFirstMode () {
      if (ctrl.record_data.sterilizer?.modes?.length > 0) {
        ctrl.record_data.sterilizer_mode = ctrl.record_data.sterilizer.modes[0];
        // Change temperature, pressure, and cycle time based on the selected mode
        handleModeChangedChemical();
      }
    }

    function handleMachineSelection () {
      _updateRecordDataWithCycleAndLoad();
    }

    /**
     * Updates the cumulative load number and cycle number based on the selected machine
     */
    function _updateRecordDataWithCycleAndLoad () {
      ctrl.record_data = {
        ...ctrl.record_data,
        ..._getLoadAndCycleNumbers(ctrl.record_data)
      }
    }

    function handleModeChangedChemical () {
      _.extend(ctrl.record_data, _.pick(ctrl.record_data.sterilizer_mode, ['cycle_time', 'pressure', 'temperature']));
      _setChemicalInitialValues(ctrl.record_data);
    }

    function resetMode () {
      ctrl.record_data.sterilizer_mode = ctrl.record_data.sterilizer_mode_id = ctrl.record_data.sterilizer_mode_name = null;
    }

    function resetSterilizer () {
      ctrl.record_data.sterilizer = ctrl.record_data.sterilizer_id = ctrl.record_data.sterilizer_name = null;
    }

    function handleFileChanged (file) {
      ctrl.show_file_size_warning = false;
      if(!file){
        return;
      }

      return uploadFile({
        name: file.name,
        size: file.size,
        binary: file
      });
    }

    /**
     * Clears the value of a file input element with the id 'stzn_chem_file_input'.
     */
    function _resetFileInput() {
      // Clear the file input as the user has already uploaded the file and we allow them to multiple files
      const fileInput = document.getElementById('stzn_chem_file_input');
      if (fileInput) {
        fileInput.value = null;
      } 
    }

    /**
     * Removes a selected image from the `record_data.images` array.
     * @param {object} image
     */
    function resetFile (image) {
      _.remove(ctrl.record_data.images, image);
    }

    function uploadFile (file) {
      if(file.size < 50000000){
        ctrl.saving_file = true;
        return sowSterilizationService.uploadImage(file.binary)
        .then(async function(s3_response){
          _resetFileInput();
          const path = s3_response.image_path;
          const is_image = stznHelperService.isImage(path);

          if (is_image) {
            await stznHelperService.waitUntilImageIsLoaded(path);
          }

          ctrl.record_data.images.push({
            name: file.name,
            path,
            metadata: {
              type: 'OTHER'
            },
            UI: {
              has_preview: is_image,
              thumbnail_url: path,
            }
          });
          ctrl.saving_file = false;
        })
        .catch(function(error){
          ctrl.saving_file = false;
          var t_message = $filter('translate')('ERRORS.IMAGE_UPLOAD');
          errorService.uiErrorHandler(t_message);
        });
      } else {
        ctrl.show_file_size_warning = true;
        var t_message = $filter('translate')('ERRORS.FILE_TOO_LARGE');
        errorService.uiErrorHandler(t_message);
      }
    }

    function handleChemicalFormChanges ({detail}) {
      if (detail.action === 'handle_change') {
        ctrl.record_data = {
          ...ctrl.record_data,
          ...detail.data
        }
      }
    }

    function _defineLocks() {
      ctrl.show_incubation_button = AccessService.getOfficeProperty('show_incubation_button');
      ctrl.show_ultrasonic_solution_time = Boolean(AccessService.getOfficeProperty('sterilization_show_ultrasonic_solution_time'));
    }

  }

}());

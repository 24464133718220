(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.inventory')
    .controller('InventoryPrintLocationsCtrl', InventoryPrintLocationsCtrl);

  //
  // !WARNING¡ This duplicates code from inventoryLocationsController. It's now
  // possible for this code to become out of sync. Please clean this up as soon
  // as possible. Also note that the code is modified and not a direct
  // one-to-one copy. This is a bit of technical debt that needs to be repaid.
  //

  function InventoryPrintLocationsCtrl ($scope, $rootScope, $filter, elementService, officeService, inventoryItemService) {
    /*jshint validthis: true */
    var ctrl = this;
    var $printCtrl = $scope.$printCtrl;

    $scope.getItems = getItems;
    $scope.fetchBundleItems = fetchBundleItems;

    $scope.bundles = [];

    init();

    return ctrl;

    //
    // Initialize Controller
    //

    function init () {
      $printCtrl.init();
      $printCtrl.title = 'Your Locations for {0}'.format($rootScope.current_office.name);
      getItems();
      refreshLocations().then(function () {
        $printCtrl.endLoading();
        $printCtrl.print();
      }).then(null, function (error) {
        $printCtrl.setError(error);
      });
    }

    //
    // getItems
    //

    function getItems () {
      $scope.items = [];
      
      _.map(elementService.elementMaps.inventoryItem, function(this_item){
        if(this_item.type === "Implant Item" && this_item.on_hand === 0) {
          // continue;
        } else {
          $scope.items.push(this_item);
        }
      });

      return $scope.items;
    }

    //
    // refreshLocations
    //

    function refreshLocations () {
      return inventoryItemService.buildLocationBundles().then(function (result) {
        $scope.locations = result.locations;
        $scope.bundles = result.bundles;
        _.map($scope.bundles, fetchBundleItems);
      });
    }

    //
    // fetchBundleItems
    //

    function fetchBundleItems (bundle) {
      bundle.items = $scope.getBundleItems(bundle, true);
      bundle.total_value = 0.00;
      bundle.total_on_hand = 0;
      var remove_items = [];

      _.forEach(bundle.items, function (item) {
        if(item.type === 'Implant Item' && item.on_hand === 0){
          remove_items.push(item);
          return;
        }
        if (item.on_hand) {
          bundle.total_on_hand += item.on_hand;
        }

        if (item.on_hand && item.price) {
          bundle.total_value += item.on_hand * item.price;
        }
      });

      _.forEach(remove_items, function(r_item){
        _.remove(bundle.items, r_item);
      });

      return bundle.items;
    }
  }
}());

angular.module("app.marketplace.elements")

//
// officeService
//
// Events:
//  "officeService: clear-office"
//      When the current office is set to null.
//
//  "officeService: initialized"      When the current office is set for the
//                                    first time.
//
//  "officeService: set-office"       When the current office changes (from
//                                    whatever to an actual office).
//
//  "officeService: office-changed"   When the new current office is not the
//                                    same as the old current office (this
//                                    also triggers when going from *no*
//                                    office to an office as well).
//
.service('officeService',
  function officeService($rootScope, $filter, $q, $log, sgToast, $mdDialog, $filter, elementService, membershipService, officeAccountTypes, MembershipTypes, useElasticInventoryEndpoint, apiUrl, serverAPI, sowUserAdminDataService, sowInteropService) {
    var _this = this;
    _this.office = null;
    _this.inventory = null;
    _this.inventory_log = null;
    _this.inventory_activity = null;
    _this.initialized = false; // Set to true after the service has been initialized
    _this.users = null;
    _this.officeAccountTypes = officeAccountTypes;
    _this.locations = null;
    _this.membership = null;

    function isODAMember () {
      return _.get(_this, 'office.association_membership.professional_association_id') === 'oda';
    }

    
    /**
     * sends an event to window so we can catch it in react
     */
    function notifyReact () {
      sowInteropService.triggerStorageChange();
    }

    //
    // POST a new credit card to the office's stripe account
    //
    function storeCreditCard (token, address, on_fail_skyflow_cleanup, skip_stripe) {
      return elementService.callEndpoint('office', {
        'endpoint': 'store_credit_card',
        'credit_card': {
          'id': token.id,
          'skyflow_id': token.skyflow_id,
          'billing': address,
          'on_fail_skyflow_cleanup': on_fail_skyflow_cleanup,
          'skip_stripe': skip_stripe,
        }
      });
    }
    //
    // PUT update a credit card to the office's stripe account
    //
    function updateCreditCard (id, data) {
      return elementService.callEndpoint('office', {
        'endpoint': 'update_credit_card',
        'credit_card': data,
        'credit_card_id': id
      });
    }

    //
    // Clear the Office's Inventory
    //
    function clearInventory () {
      return elementService.callEndpoint('office', {
        'endpoint': 'clear_inventory',
        'office_id': _this.office.id,
      }).then(function () {
        return _this._refreshOfficeInventory(_this.office);
      });
    }

    //
    // Reject a Membership Request
    //
    function getMembershipRequests () {
      return elementService.callEndpoint('office', {
        'endpoint': 'get_membership_request'
      }).then(function (response) {
        return response.data;
      });
    }

    //
    // Reject a Membership Request
    //
    function rejectMembershipRequest (request_id) {
      return elementService.callEndpoint('office', {
        'endpoint': 'reject_membership_request',
        'request_id': request_id,
      }).then(function (response) {
        return response.data;
      });
    }

    //
    // Fetch stats for a specific product in inventory over a date range.
    //
    function getProductStats (productId, startDate, endDate) {
      return elementService.callEndpoint('office', {
        'endpoint': 'product_stats',
        'start_date': startDate,
        'end_date': endDate,
        'product_id': productId,
      }).then(function (response) {
        return response.data;
      });
    }

    //
    // Fetch a list of product stats summaries
    //
    function getProductStatsSummary (startDate, endDate) {
      return elementService.callEndpoint('office', {
        'endpoint': 'product_stats_summary',
        'start_date': startDate,
        'end_date': endDate,
      }).then(function (response) {
        return response.data;
      });
    }

    //
    // Fetch Supplier Statistics Over a Date Range
    //
    function getSupplierStats (startDate, endDate) {
      return elementService.callEndpoint('office', {
        'endpoint': 'supplier_stats',
        'start_date': startDate,
        'end_date': endDate,
      }).then(function (response) {
        return response.data;
      });
    }

    //
    // Accept a Membership Request
    //
    function acceptMembershipRequest (request_id) {
      return elementService.callEndpoint('office', {
        'endpoint': 'accept_membership_request',
        'request_id': request_id,
      }).then(function (response) {
        return response.data;
      });
    }

    //
    // Returns a boolean that is true if the current office is currently under
    // a free trial, and false otherwise.
    //
    function isFreeTrialActive () {
      var trial_end = moment(new Date(_this.office.trial_ends_on));
      var now = moment();
      var is_trial_active = now.isBefore(trial_end);

      return is_trial_active;
    }

    //
    // Set the Current Office
    //
    var _setOffice = function(office){
      var oldOffice = _this.office;
      var newOffice = office;
      var sameOffice = oldOffice && newOffice.id === oldOffice.id;

      //
      // Update Current Office
      //
      _this.office = office;
      $rootScope.current_office = office;
      $rootScope.current_medical_field = $rootScope.config && $rootScope.config.medical_fields_map ? $rootScope.config.medical_fields_map[office.medical_field_id] : 'dentistry';

      //
      //
      //
      elementService.removeAll('subscriptionReceipt');

      //
      // Quick Booleans for Controlling View Display
      //
      $rootScope.canShowPremium = isOfficeSubscriptionLevel('Premium');
      $rootScope.canShowPremiumPlus = isOfficeSubscriptionLevel('Premium Plus');

      // Clear session/office related variables.
      if(_this.inventory && _this.inventory.items){ //Force refresh if inventory has been loaded once this session.
        _this._refreshOfficeInventory(office);
      }
      _this.locations = null;
      _this.inventory_log = null;
      _this.users = null;

      // Update local store
      var data = {
        id: office.id,
        name: office.name,
        address: office.address ? office.address.address1 : null
      };

      // Notify app
      $rootScope.$broadcast('officeService: set-office', office);

      if (!sameOffice) {
        // make sure we only clear the inventory when the office has changed
        _this.inventory = null;
        $rootScope.$broadcast('officeService: office-changed', newOffice, oldOffice);
        sowInteropService.broadcastToTabs('officeService: office-changed', {newOffice, oldOffice});
        // Send event to react to notify office has changed
        sowInteropService.dispatchToWindow('office-changed', {newOffice, oldOffice});
        notifyReact();
      }

      _initializeOfficeService();
    };

    //
    // Mark officeService as Initialized, and broadcast event.
    //
    var _initializeOfficeService = function () {
      if (!_this.initialized) {
        $rootScope.$broadcast('officeService: initialized', true);
        _this.initialized = true;
      }
    };

    //
    // Clear the Current Office
    //
    var _clearOffice = function() {
      var oldOffice = _this.office;
      _this.office = null;
      $rootScope.current_office = null;
      $rootScope.canShowPremium = null;
      $rootScope.canShowPremiumPlus = null;
      elementService.removeAll('subscriptionReceipt');
      $rootScope.$broadcast('officeService: clear-office');
      if (oldOffice) {
        $rootScope.$broadcast('officeService: office-changed', null, oldOffice);
        notifyReact();
      }
      _initializeOfficeService();
    };

    //
    // Convience function for waiting for initialized
    //
    var _waitForInitialized = function(){
      if (_this.initialized) {
        return $q.resolve(_this.office);
      } else {
        var deferred = $q.defer();
        var getInitialized = function () {
          return _this.initialized;
        };
        var initWatch = $rootScope.$watch(getInitialized, function(newValue, oldValue){
          if (newValue) {
            deferred.resolve(_this.office);
            initWatch(); // Stop watch;
          }
        });
        return deferred.promise;
      }
    };

    //
    // Return the Current office
    //
    // waitForInitialized will wait for the service to be initialized
    //
    var _getOffice = function (waitForInitialized) {
      if (waitForInitialized) {
        return _waitForInitialized().then(function (office) {
          return office ? office
               : $rootScope.current_account ? $q.reject('No office')
               : $q.reject('Not logged in');
        });
      }else{
        return _this.office;
      }
    };

    //
    // Fetch the List of Office Users
    //
    var _getOfficeUsers = function () {
      return _getOffice(true).then(function(office){
        if (_this.users) {
          return $q.resolve(_this.users);
        } else {
          return elementService.callEndpoint('office', {
            endpoint: 'get_users',
            office: _this.office,
            params: {'params':{'current_office': true}},
          }).then(function (response) {
            _this.users = response.data;
            return _this.users;
          });
        }
      });
    };

    //
    // Fetch the List of Office Pending Invitations
    //
    var _getPendingInvitations = function () {
      return _getOffice(true).then(function(office){
          return elementService.callEndpoint('office', {
            endpoint: 'invites',
            office: _this.office
          }).then(function (response) {
            _this.pending_invitations = [];
            for (i = 0; i < response.data.length; i++) {
                var item = response.data[i];
                item.membership_display_name = MembershipTypes[item.membership_type];
                _this.pending_invitations.push(item);
            }

            return _this.pending_invitations;
          });
      });
    };

    //
    // Invite a new User to the Office
    //
    // TODO reassess. I dont know if this enpoint should be in account or office.
    var _inviteOfficeUser = function (user) {
      if (!user.office_ids) {
        _.set(user, 'office_ids', [_.get($rootScope, 'current_office.id', null)]);
      }
      if (!user.user_permissions) {
        var permissions = _.get(sowUserAdminDataService, 'USER_PERMISSIONS['+user.membership_type+']', []);
        _.set(user, 'user_permissions', permissions);
      }
      return elementService.callEndpoint('office', {
        endpoint: 'invite_user',
        user: user,
        office: _this.office
      });
    };
    //
    // Resend a user invitation
    //
    var _resendUserInvite = function (invite) {
      return elementService.callEndpoint('office', {
        endpoint: 'resend_user_invite',
        invite: invite
      });
    };
    //
    // Cancel a user invite
    //
    var _cancelUserInvite = function (invite) {
      return elementService.callEndpoint('office', {
        endpoint: 'cancel_user_invite',
        invite: invite
      });
    };

    //
    // Make a Change to an Office User
    //
    // TODO reassess. I dont know if this enpoint should be in account or office.
    var _updateOfficeUser = function (user) {
      return elementService.callEndpoint('account', {
        endpoint: 'update_office_user',
        doUpdate: true,
        user: user,
        office: _this.office
      });
    };

    var _updateOfficeInvite = function (invite) {
      var url = '{0}/offices/invites'.format(apiUrl);
      var options = {
        'method': 'POST',
        'data': invite,
      };
      return serverAPI
        .doAPICall(url, options)
        .then(function(response){
          return response.data;
        });
    }

    //
    // Remove an Office User
    //
    // TODO reassess. I dont know if this enpoint should be in account or office.
    var _removeOfficeUser = function (user) {
      // Was debating on calling .remove but since this is more of a membership
      // removal and not a user deletion I do not think it is a good idea.
      return elementService.callEndpoint('account', {
        endpoint: 'remove_office_user',
        doUpdate: true,
        user: user,
        office: _this.office
      });
    };

    //
    // Check if it is the only office. Set office to inactive. Change current
    // office if it was the current.
    //
    var _removeOffice = function(office){
      var memberships = membershipService.getAll();

      if (memberships.office.length === 1) {
        return $q.reject("This is your only office. You can't remove it.");
      } else {
        var t_message = $filter('translate')('TOAST.REMOVE_OFFICE')
        var confirm = $mdDialog.confirm()
              .title('Remove Office?')
              .textContent(t_message)
              .ok('Yes')
              .cancel('Cancel');

        var remove = function (office) {
          return elementService.submit('office', {'endpoint': 'remove', 'element': office});
        };

        var membershipChanged = function () {
          var t_message = $filter('translate')('TOAST.OFFICE_CHANGED');
          sgToast.showSimple(t_message);
          return 'done';
        };

        return $mdDialog.show(confirm).then(function() {
          return remove(office).then(function success(response) {
            if (_this.office.id === office.id) {
              for (var i = 0; i < memberships.office.length; i++) {
                var membership = memberships.office[i];

                if (membership.office.id !== office.id) {
                  return membershipService.setCurrent(membership).then(membershipChanged);
                }
              }

              return 'done';
            }
          });
        });
      }

    };

    //
    // Refresh the Office Inventory
    //
    _this._refreshOfficeInventory = function(office, all){
      var elastic = useElasticInventoryEndpoint; //Temp config constant. Both marketplace and mobile will use the new endpoint once testing is done.
      return elementService.callEndpoint("inventoryItem", {
        "all": (all ? true : false),
        "endpoint": (elastic ? "office_inventory_elastic" : "office_inventory"),
        "office" : office,
        "doUpdate" : elastic
      }).then(function(response){
        if(elastic){
          //Build inventory list from response.
          _this.inventory = _this.inventory || {items : []};
          _this.inventory.items = angular.copy([], _this.inventory.items || []);
          _.map(response, function(item, index){
            _this.inventory.items.push(item);
          });
        }else{
          //Retain links
          if(_this.inventory){
            _this.inventory.locations = angular.copy(response.data.locations, _this.inventory.locations || []);
          }
          //Replace inventory object and ensure items have an office id (reassess?)
          _this.inventory = angular.copy(response.data, _this.inventory || {});
          _.map(_this.inventory.items, function(item, index){
            item.office_id = office.id;
          });
        }
        angular.copy({}, elementService.elementMaps.inventoryItem); //Clear previous items

        //Create items as instances
        _this.inventory.items = elementService.createMultiple("inventoryItem", _this.inventory.items);

        return _this.inventory;
      });
    };

    //
    // Get Office Inventory
    //
    var _getInventory = function (force, all) {
      if (typeof(all)==='undefined')
        all=true
      return _waitForInitialized().then(function (office) {
        if (!force && _this.inventory && _this.inventory.items) {
          return $q.resolve(_this.inventory);
        } else if(office){
          return _this._refreshOfficeInventory(_this.office, all);
        } else {
          return $q.reject("Error: Not logged in");
        }
      });
    };

    //
    // Get the Office Inventory Log
    //
    var _getInventoryLog = function (force) {
      if (!force && _this.inventory_log && _this.inventory_log.length) {
        return $q.resolve(_this.inventory_log);
      } else {
        return _getOffice(true).then(function (office) {
          return elementService.callEndpoint('inventoryItem', {
            endpoint: 'office_inventory_log',
            office: office
          }).then(function(response){
            _this.inventory_log = response.data;
            _.map(_this.inventory_log, function(log,i){
              // Filog for moving the GMT time to local time
              if (!log.created_at._d) {
                log.created_at = moment(log.created_at+" +0000","YYYY-MM-DD HH:mm:ss Z");
              }
              if (log.stock_variation) {
                log.stock_variation = parseInt(log.stock_variation, 10);
              }
              if (log.price) {
                log.price = parseFloat(log.price);
              }
            });
            return _this.inventory_log;
          });
        });
      }
    };

    //
    // Get the Office Inventory Log
    //
    var _getInventoryActivity = function (force, page, per_page) {
      if (!force && _this.inventory_activity && _this.inventory_activity.length) {
        return $q.resolve(_this.inventory_activity);
      } else {
        return _getOffice(true).then(function (office) {
          return elementService.callEndpoint('inventoryItem', {
            endpoint: 'office_inventory_activity',
            office: office,
            page: page,
            per_page: per_page
          }).then(function(response){
            _this.inventory_activity = response.data;
            _.map(_this.inventory_activity, function(activity){
              // Fix for moving the GMT time to local time
              if (!activity.created_at._d) {
                activity.title = $filter('toTitleCase')(activity.title);
                activity.created_at = moment(activity.created_at+" +0000","YYYY-MM-DD HH:mm:ss Z");
              }
            });
            return _this.inventory_activity;
          });
        });
      }
    };

    //
    //
    //
    var _renameLocation = function (location_id, location_name) {
      var options = {
        'endpoint': 'rename_location',
        'location_id': location_id,
        'location_name': location_name,
      };

      return elementService.callEndpoint('office', options).then(function (response) {
        var newLocation = response.data;
        var oldName = null;

        if(_this.inventory){
          _.forEach(_this.inventory.locations, function (location) {
            if (location.id === newLocation.id) {
              oldName = location.name;
              angular.extend(location, newLocation);
            }
          });
        }

        _.forEach(_this.locations, function (location) {
          if (location.id === newLocation.id) {
            angular.extend(location, newLocation);
          }

          return newLocation;
        });

        console.debug('inventory %o', _this.inventory);

        _.forEach(elementService.elementMaps.inventoryItem, function (item, item_id) {
          _.forEach(item.locations, function (location) {
            if (location.id === location_id) {
              location.name = newLocation.name;
              return false;
            }
          });
        });

        if(_this.inventory){
          $rootScope.$broadcast('officeService: locations-updated', _this.inventory.locations);
        }
      });
    };

    //
    //  Get all locations for the office. 
    //
    var _getLocations = function(){
      if(_this.locations){
        return $q.resolve(_this.locations);
      }else{
        return elementService.callEndpoint('office', {'endpoint' : 'get_locations'}).then(function(response){
          _this.locations = response.data;
          return _this.locations;
        });
      }
    };
    
    //
    // Create a new Inventory Location
    //
    var _addLocation = function (newLocation) {
      if (_this.inventory && _this.inventory.locations) {
        var exists = false;
        _.map(_this.inventory.locations, function(location, loc_name){
          if (location.name.toLowerCase() === newLocation.name.toLowerCase()) {
            if (!location.id) {
              location = newLocation;
            }
            exists = true;
          }
        });
        if (!exists) {
          _this.inventory.locations.push(newLocation);
        }
        $rootScope.$broadcast('officeService: locations-updated', _this.inventory.locations);
      }
    };
    //
    // Create a new Inventory Location
    //
    var _createLocation = function (location_name) {
      if (location_name) {
        location_name = location_name.trim();
      }
      if (!location_name) {
        return $q.reject(false);
      }

      return elementService.callEndpoint('office', {endpoint: 'create_location', location: {name: location_name}
      }).then(function (response) {
        var newLocation = response.data;
        _addLocation(newLocation);
        $rootScope.$broadcast('officeService: location-created', newLocation);
        return newLocation;
      });
    };

    //
    // Remove inventory Location
    //
    var _removeLocation = function (location) {
      if (!location || !location.id) {
        return $q.reject(false);
      }

      return elementService.callEndpoint("office", {
        "endpoint": "remove_location",
        "location": location
      }).then(function (message) {
        $rootScope.$broadcast('officeService: location-removed', location);
        if(_this.inventory){
          _.map(_this.inventory.locations, function(this_location, i){
            if (this_location.id === location.id) {
              _this.inventory.locations.splice(i, 1);
            }
          });
        }

        //
        // Remove References from any inventory items.
        //
        _.forEach(elementService.elementMaps.inventoryItem, function (item) {
          _.forEach(item.locations, function (_location, index) {
            if (_location && location.id === _location.id) {
              item.locations.splice(index, 1);
            }
          });
        });
        if(_this.inventory){
          $rootScope.$broadcast('officeService: locations-updated', _this.inventory.locations);
        }
      });
    };

    //
    // Fetch the Credit Cards Associated with this Office
    //
    var _getCreditCards = function () {
      return elementService.callEndpoint('office', {
        endpoint: 'me_credit_cards'
      }).then(function(response){
        return response.data;
      });
    };

    //
    // Check the Office's Subscription Level
    //
    var isOfficeSubscriptionLevel = function (goal) {
      var types = _this.officeAccountTypes;
      return types.indexOf(_this.office.account_type) >= types.indexOf(goal);
    };

    //
    // Get specific data field for the dashboard.
    // Uses a custom endpoint to help the dashboard load quickly.
    //
    var _getDashboardData = function(element_type, data_field){
      if(!data_field){
        return [];
      }

      //Call endpoint with data field name. Endpoint will know what to return.
      return elementService.callEndpoint("office", {"endpoint" : "dashboard_data", 'data_field' : data_field})
          .then(function(response){
            //Depending on the element_type, a unique post-processing will be done, or a generic elementService.createMultiple will be used.
            if (element_type === "pending_shipments") {
              var shipments = response.data.pending_shipments;
              _.each(shipments, function(shipment, shipment_index){
                shipment.shipped_items_count = 0;
                _.each(shipment.items, function(item){
                  shipment.shipped_items_count += item.quantity;
                });
              });
              return shipments;
            } else if (element_type === "inventory_logs") {
              var inventory_logs = response.data.inventory_logs;
              _.each(inventory_logs, function(log){
                // Fix for moving the GMT time to local time
                if (!log.created_at._d) {
                  log.created_at = moment(log.created_at+" +0000","YYYY-MM-DD HH:mm:ss Z");
                }
                if (log.stock_variation) {
                  log.stock_variation = parseInt(log.stock_variation, 10);
                }
                if (log.price) {
                  log.price = parseFloat(log.price);
                }
              });
              return inventory_logs;
            }else if(element_type === "overview_report"){
              return response.data.overview_report;
            }else if(element_type){
              return $.map(elementService.createMultiple(element_type, response.data[data_field] || {}), function (value, index) {
                return [value];
              });
            }
          });
    };

    function sendInvites (invites) {
      return $q.all(invites.map(function (invite) {
        return _inviteOfficeUser(invite);
      }));
    }

    //
    // Initialize officeService
    //
    var _init = function () {
      $rootScope.$on('membershipService: set-membership', function (scope, membership) {
        var office = elementService.create('office', membership.office);
        _setOffice(office);
        _this.membership = membership;
        sowInteropService.broadcastToTabs('membership-changed', membership);
      });

      $rootScope.$on('membershipService: clear-membership', function () {
        _clearOffice();
        _this.membership = null;
        sowInteropService.broadcastToTabs('membership-changed', null);
      });
  
      // reload the page if the membership has changed in another tab
      sowInteropService.subscribeToTabs('membership-changed', (event) => {
        if (event.data?.id !== _this.membership?.id) {
          window.location.reload();
        }
      });
    };

    function getUnusedDiscounts () {
      return elementService.callEndpoint('office', {
        'endpoint': 'get_unused_subscription_discounts',
        'office_id': _this.office.id,
      }).then(function (response) {
        return response.data;
      });
    }

    function updateOffice (office) {
      return elementService.submit('office', {
        endpoint: 'update',
        element: office,
      }).then(function success (office) {
        $rootScope.$broadcast('office-model-updated', office);
        return office;
      });
    }

    //
    // Update the Office Address
    //
    function updateAddress (address) {
      var office = angular.copy(_this.office);
      angular.extend(office.address, address);

      return elementService.submit('office', {
        'endpoint': 'update',
        'element': office,
      }).then(function (response) {
        return _.get(response, 'data.address');
      });
    }

    //
    // Return the office's address
    //
    function getOfficeAddress () {
      if (!_this.office) {
        $log.warn('getOfficeAddress called with no office set.');
      }
      return _.get(_this, 'office.address');
    }

    function getTaxRate (tax_exempt) {
      var options = {
        'endpoint': 'tax_rate',
        'office_id': _this.office.id,
        'tax_exempt': tax_exempt,
      };
      return elementService
        .callEndpoint('office', options)
        .then(function (response) {
          return response.data;
        });
    }

    return {
      init: _init,
      onInit: _waitForInitialized,
      get : _getOffice,

      getOfficeAddress: getOfficeAddress,

      isFreeTrialActive: isFreeTrialActive,
      isODAMember: isODAMember,

      getMsdsMap: function () { return _.get(_this, 'inventory.msds'); },
      getTaxRate: getTaxRate,
      updateOffice: updateOffice,
      removeOffice : _removeOffice,
      getCreditCards: _getCreditCards,
      storeCreditCard: storeCreditCard,
      updateCreditCard: updateCreditCard,
      isOfficeSubscriptionLevel: isOfficeSubscriptionLevel,
      updateAddress: updateAddress,

      // Office User
      getOfficeUsers: _getOfficeUsers,
      inviteOfficeUser : _inviteOfficeUser,
      resendUserInvite : _resendUserInvite,
      cancelUserInvite : _cancelUserInvite,
      updateOfficeUser : _updateOfficeUser,
      updateOfficeInvite: _updateOfficeInvite,
      removeOfficeUser : _removeOfficeUser,
      sendInvites: sendInvites,

      // Office Inventory
      getInventory : _getInventory,
      getInventoryLog : _getInventoryLog,
      getInventoryActivity : _getInventoryActivity,
      getLocations : _getLocations,
      addLocation : _addLocation,
      createLocation : _createLocation,
      removeLocation : _removeLocation,
      renameLocation: _renameLocation,
      clearInventory: clearInventory,

      getDashboardData : _getDashboardData,

      //
      // Membership Request Handling
      //
      acceptMembershipRequest: acceptMembershipRequest,
      rejectMembershipRequest: rejectMembershipRequest,
      getMembershipRequests: getMembershipRequests,

      // Invitations
      getPendingInvitations: _getPendingInvitations,

      // Discounts
      getUnusedDiscounts: getUnusedDiscounts,

      getSupplierStats: getSupplierStats,
      getProductStatsSummary: getProductStatsSummary,
      getProductStats: getProductStats,
    };
  }
);

(function () {
  'use strict';

  const sowMiniLoader = {
    selector: 'sowMiniLoader',
    controller: 'sowMiniLoaderCtrl',
    template: `
      <lottie-player ng-hide="$ctrl.use_fallback" src="https://sowingo-static.s3.amazonaws.com/animation/animation.json"
        background="transparent" speed="1" style="width: 300px; height: 300px;" loop autoplay id="mini-lottie">
      </lottie-player>
      
      <div ng-show="$ctrl.use_fallback">
        <md-progress-circular md-mode="indeterminate" md-diameter="100" style="position: relative"></md-progress-circular>
        <img src="styles/img/branding/sowingo-logo.png" style="position: relative; top: -66px; left: 34px; max-width: 32px;">
      </div>
    `,
  }

  /** @ngInject */
  class sowMiniLoaderCtrl {
    constructor($scope) {
      this.$scope = $scope;
    }

    $onInit() {
      const elem = document.querySelector('#mini-lottie');
      // Watch for changes to lottie's isLoaded property to determine whether to show the fallback spinner
      this.$scope.$watch(() => {
        return _.get(elem, '_lottie.isLoaded', false);
      }, (is_lottie_loaded) => {
        this.use_fallback = !is_lottie_loaded;
      });
    }

  }

  angular.module('sowMiniLoader', [])
    .controller(sowMiniLoader.controller, sowMiniLoaderCtrl)
    .component(sowMiniLoader.selector, sowMiniLoader);

})();
(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.inventory')
    .directive('inventoryItemsBlock', InventoryItemsBlock);

  function InventoryItemsBlock () {
    return {
      'restrict': 'E',
      'replace': true,
      'templateUrl': 'templates/marketplace/inventory/directives/inventory-item/inventory-items-block.html',
      'scope': {
        'items': '=',
        'title': '@',
        'titleIcon': '@',
        'errors': '@',
        'filterType': '@'
      },
      'controller': InventoryItemsBlockController,
    };
  }

  function InventoryItemsBlockController ($scope, $filter, $state) {
    $scope.openItem = function(item){
      $state.go("app.inventory.all",{'itemId': item.id});
    };
  }
}());

(function () {
    'use strict';
    angular.module('sowUserAdmin', [])
    .config(sowUserAdminConfig);

    function sowUserAdminConfig ($stateProvider, $urlRouterProvider) {
      $urlRouterProvider.when('/app/all_office_dashboard', '/app/reports/office_dashboard_spending');
      $stateProvider.state('app.user_admin', {
        url: '/office/profile/user-permissions',
        controller: 'UserAdminListController',
        controllerAs: 'admlCtrl',
        templateUrl: 'sow-user-admin/views/user-adm-list.html',
        data: {
          'title': 'Users Admin',
          'require_log_in' : true,
          'action_bar_directive': 'action-bar-office-profile',
          // 'requires_feature': 'dashboard',
        },
      })
      .state('app.user_detail', {
        url: '/user_detail/:userId',
        controller: 'UserAdminDetailController',
        controllerAs: 'admdCtrl',
        templateUrl: 'sow-user-admin/views/user-adm-detail.html',
        data: {
          'title': 'Users Admin',
          'require_log_in' : true,
          'action_bar_directive': 'action-bar-office-profile',
          // 'requires_feature': 'dashboard',
        },
      });
    }

}());

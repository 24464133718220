(function () {
    'use strict';
    angular.module('sowMarketplace')
    .controller('MktCartController', MktCartController)
    .directive('sowMktCart', sowSlideoutMktCart);

    function sowSlideoutMktCart () {
      return {
        templateUrl: 'sow-mkt/slideouts/mkt-cart.html',
        controller: 'MktCartController',
        controllerAs: 'mktcCtrl',
      };
    }

    function MktCartController ($scope, $mdSidenav, $filter, sowMktService, mktHelperService, cartService, sessionService, $state, sowAnalyticsService, errorService, sgToast, AccessService) {
      /*jshint validthis: true*/
      var ctrl = this;
      var CID = 'sow-mkt-cart';
      var _oldQuantities = {};

      ctrl.review_disabled = false;
      ctrl.vendor_bundles = [];
      ctrl.added_product = null;

      ctrl.updateItem = updateItem;
      ctrl.removeItem = removeItem;
      ctrl.focusItem = focusItem;
      ctrl.close = close;
      ctrl.checkout = checkout;
      ctrl.goToProductDetailsPage = goToProductDetailsPage;
      ctrl.getBillingText = getBillingText;
      ctrl.getShippingText = getShippingText;
      ctrl.handleSlideoutEvent = handleSlideoutEvent;
      ctrl.localize = mktHelperService.getLocalizedProp;

      ctrl.$onInit = init;

      return ctrl;

      function init () {
        _defineLocks();
        updateCart();
        $scope.$on('sow-cart-open', _showShoppingCart);
        $scope.$on('added-to-cart', _handleAddedToCart);
        $scope.$on('sow-cart-close', close);
        multiEventsCallback([
          'cartService: set-cart',
          'cartService: itemAddedToCart',
          'cartService: clear-cart',
          'sessionService: new-session',
          'sessionService: clear-session',
          'sessionService: set-session',
          'officeService: office-changed',
        ], updateCart);
        $scope.$on('cartService:begin-action:item-change', disableReview);
        $scope.$on('cartService:end-action:item-change', evaluateReviewPermission);
        $scope.$on('officeService: office-changed', init);
      }

      /**
       * If the user has access to the full page cart, navigates there.
       * Otherwise, opens the shopping cart slideout.
       */
      function _showShoppingCart() {
        if (ctrl.full_page_cart) {
          $state.go('app.mkt.cart');
        } else {
          open();
        }
      }

      /**
       * Opens the added to cart slideout if the user has access to the full page cart.
       * Otherwise, shows a toast and then opens the shopping cart slideout.
       * @param _event
       * @param product
       * @param quantity
       * @param vendor
       */
      function _handleAddedToCart(_event, product, quantity, vendor) {
        if (ctrl.full_page_cart) {
          ctrl.added_product = {product: {...product, quantity}, vendor};
        } else {
          const t_added_item = cartService.getItemAddedText(quantity);
          sgToast.showSimple(t_added_item);
          setTimeout(() => {
            open();
          }, 1200);
        }
      }

      /**
       * Handles events from the added to cart slideout
       */
      function handleSlideoutEvent({detail: {hideSlideout}}) {
        if (hideSlideout) {
          ctrl.added_product = null;
        }
      }

      function multiEventsCallback (event_list, callbackFn) {
        _.each(event_list, function(event_name){
          $scope.$on(event_name, callbackFn);
        });
      }

      function evaluateReviewPermission () {
        updateCart();
        // improve conditions in future (address forms state, etc)
        var conditions = (!ctrl.cartIsEmpty);
        if(conditions) {
          enableReview();
        } else {
          disableReview();
        }
      }

      function enableReview () {
        ctrl.review_disabled = false;
      }

      function disableReview () {
        ctrl.review_disabled = true;
      }

      function open () {
        updateCart();
        $mdSidenav(CID).open();
      }

      function close () {
        $mdSidenav(CID).close();
      }

      function updateCart () {
        sessionService.get(true)
        .then(function(){
          ctrl.cart = cartService.get();
          ctrl.cartIsEmpty = !checkNested(ctrl, 'cart', 'items', 'length') || ctrl.cart.items.length < 1;
          // loadDiscounts();
          if(ctrl.cartIsEmpty) {
            disableReview();
          } else {
            enableReview();
          }
          setPromoInfo();
        });
      }

      function setPromoInfo () {
        if(ctrl.cartIsEmpty){
          return;
        }
        _.each(ctrl.cart.vendor_groups, function(group){
          _.each(group.items, function(item){
            _.set(item, 'promotions', [item.vendor_inventory_promotion]);
            _.set(item, 'UI.on_sale', !_.isNil(_.get(item, 'vendor_inventory_promotion.id')) );
            _.set(item, 'UI.promo_count', 1);

            _.each(item.promotions, function(promo){
              var promo_type = _.get(promo, 'promotion_type', null);
              _.set(item, 'UI.is_manufacturer_promo', promo_type && promo_type.includes('MANUFACTURER'), null);
              const localized_notes = mktHelperService.getLocalizedPromoNotes(promo);
              _.set(item, 'UI.promo_notes', localized_notes);
            });            
          });
        });
      }

      function loadDiscounts () {
        return sowMktService.getVendorDiscounts()
        .then(function(response){
          ctrl.savings_total = 0;
          if(ctrl.cartIsEmpty || !response.length) {
            return;
          }
          _.forEach(response, function(vendor){
            var discount = (vendor.discount*1)/100;
            var cart_vendor_group = _.find(ctrl.cart.vendor_groups, ['vendor_id', vendor.vendor_id]);
            if(!cart_vendor_group || (vendor.discount*1) <= 0) {
              return;
            }
            var gross = cart_vendor_group.items_subtotal / (1 - discount);
            var savings = gross - cart_vendor_group.items_subtotal;
            ctrl.savings_total += savings;
          });

        });
      }

      function removeItem (item) {
        if(item.updating){
          return;
        }
        item.updating = true;

        ctrl.saving = true;
        return cartService.removeCartItem(item)
        .then(function (response) {
          updateCart();
        })
        .catch(function (error) {
          errorService.uiErrorHandler(error);
        })
        .finally(function () {
          item.updating = false;
          ctrl.saving = false;
        });
      }

      function updateItem (item, blur) {
        if (item.updating) {
          return;
        }
        var isEmpty = _.includes([null, undefined, '', NaN], item.quantity);
        var oldQuantity = _oldQuantities[item.id];

        if (isEmpty) {
          if(blur){
            item.quantity = oldQuantity;
            return;
          } else {
            return;
          }
        }

        const max_quantity_limit = 999;
        if (item.quantity > max_quantity_limit) {
          const quantity_locale = $filter('translate')('COMMON.QUANTITY');
          const message = $filter('translate')('ERRORS.VALUE_LESS_THAN', { value: max_quantity_limit, field_name: quantity_locale });
          errorService.uiErrorHandler(message);
          item.quantity = oldQuantity;
          return;
        } 

        item.updating = true;
        return cartService.updateCartItem(item)
        .then(function (response) {
          var updated_items = _.get(response, 'items', []);
          var item_with_updated_quantity = _.find(updated_items, function(updated_item) { 
            return updated_item.id === item.id;
          })
          var updated_qty = item_with_updated_quantity.quantity;
          if (updated_qty === oldQuantity) {
            var t_cannot_exceed = $filter('translate')('TOAST.CANNOT_EXCEED_LIMIT');
            sgToast.showSimple(t_cannot_exceed);
          } else {
            const {product, vendor_inventory_promotion} = item_with_updated_quantity;
            const updated_item = {
              ...item_with_updated_quantity,
              ...product,
              promotions: [vendor_inventory_promotion],
              previous_quantity: oldQuantity
            }
            sowAnalyticsService.logCartItemQuantityUpdate(updated_item);
            updateCart();
          }
        })
        .catch(function (error) {
          item.quantity = oldQuantity;
          errorService.uiErrorHandler(error);
        })
        .finally(function () {
          item.updating = false;
        });
      }

      function focusItem (item) {
        _oldQuantities[item.id] = item.quantity;
      }

      function checkout () {
        close();
        _redirectToLegacyOrVendorCheckout();
      }

      /**
       * If the external_vendors feature flag is enabled, go to the vendor-order page, otherwise go to
       * the order page
       */
      function _redirectToLegacyOrVendorCheckout () {
         const is_external_vendor = AccessService.getOfficeFeatureFlag('external_vendors');
         if (is_external_vendor) {
           $state.go('app.mkt.vendor-order');
         } else {
           $state.go('app.mkt.order');
         }
      }

      function goToProductDetailsPage (product) {
        close();
        return mktHelperService.goToProductDetailsPage(product)
      }

      function getBillingText(vendor) {
        switch (vendor.checkout_type) {
          case 'purchase_order':
            return $filter('translate')('MARKETPLACE.CART.EMAILED_TO_SUP')
          case 'marketplace.creditcart.charge_on_checkout':
          case 'marketplace.creditcart.charge_on_shipping':
          case 'marketplace.creditcart.charge_by_vendor':
            return $filter('translate')('MARKETPLACE.CART.CHARGED_ON_CC');
          case 'external_vendor':
            return null;
          case 'marketplace.invoice':
          default:
            return $filter('translate')('MARKETPLACE.CART.INVOICES_DIRECTLY');
        }
      }

      /**
       * If the vendor is external, return $0.00. If the vendor has a shipping subtotal, return the
       * shipping subtotal and the word "shipping". Otherwise, return the phrase "free shipping"
       * @param vendor - The vendor object
       */
      function getShippingText(vendor) {
        if (vendor.is_external_vendor) {
          return $filter('currency')(0, '$');
        } else if (vendor.shipping_subtotal > 0) {
          return `${$filter('currency')(vendor.shipping_subtotal, '$')} ${$filter('translate')('COMMON.SHIPPING')}`;
        } else {
          return $filter('translate')('MARKETPLACE.DETAIL.FREE_SHIPPING');
        }
      }

      function _defineLocks() {
        ctrl.full_page_cart = AccessService.hasOfficeProperty('shopping_cart', 'full_page_cart');
      }

    }

}());

(function () {
  'use strict';

  angular.module('sowInventory')
  .directive('sowIiDetailsForm', sowInventoryItemDetailsFormDirective)
  .controller('sowInventoryItemDetailsFormController', sowInventoryItemDetailsFormController);

  function sowInventoryItemDetailsFormDirective () {
    return {
      restrict: 'E',
      replace: true,
      templateUrl: 'sow-inventory/directives/item-details-form.html',
      controller: 'sowInventoryItemDetailsFormController',
      controllerAs: 'iidfCtrl',
      scope: {
        'item': '=',
      },
    };
  }

  function sowInventoryItemDetailsFormController ($scope, $rootScope, $filter, sowSdsService, sgToast, sowInventoryService, errorService, elementService, Upload, sowSterilizationService, membershipService, SDSHelperService) {
    var ctrl = this;

    ctrl.categories = null;
    ctrl.file_info = {};
    ctrl.locations = null;

    ctrl.addExpiryDate = addExpiryDate;
    ctrl.addLocation = addLocation;
    ctrl.locationSearch = locationSearch;
    ctrl.manufacturerSearch = manufacturerSearch;
    ctrl.selectedManufacturerChange = selectedManufacturerChange;

    ctrl.getDefaultFileName = function(original_name) {
      var extension = _.last(_.split(original_name, "."));
      var format = "YYYYMMDD_HHmmss";
      var date = moment()
        .utc()
        .format(format);
      return _.join([date, extension], ".");
    };

    ctrl.removeFile = function(file_path){
      sowSdsService.fileRemove(file_path)
        .then(function(){
          var t_message = $filter('translate')('TOAST.FILE_REMOVED');
          sgToast.showSimple(t_message);
        })
        .catch(function(err){
          return true;
        });
    }

    ctrl.resetFileUpload = function(){
      // ctrl.removeFile($scope.item.sds_url);
      ctrl.file_progress = -1;
      ctrl.file_src = null;
      $scope.item.sds_url = null;
    }

    ctrl.uploadFileToAWS = function(aws_data, file) {
      $rootScope.$broadcast('inventory-upload-start');
      Upload.upload({
        url: aws_data.aws_url,
        method: "POST",
        transformRequest: function (data, headersGetter) {
          var headers = headersGetter();
          delete headers.Authorization;
          return data;
        },
        fields: {
          key: aws_data.key,
          AWSAccessKeyId: aws_data.AWSAccessKeyId,
          acl: aws_data.acl,
          policy: aws_data.policy,
          signature: aws_data.signature,
          "Content-Type": aws_data['Content-Type'],
          success_action_status: aws_data.success_action_status
        },
        file: file,
        headers: {
          enctype: "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
          "Content-Type": undefined
        }
      }).progress(function(evt) {
          ctrl.file_progress = parseInt(100.0 * evt.loaded / evt.total);
      }).success(function(response, status, headers, config) {
        var full_url = aws_data.aws_url + aws_data.key;
        $scope.item.sds_url =  [full_url];
        ctrl.file_src =  full_url;
        if($scope.item.msds_url){
          $scope.item.msds_url.push(full_url);
        }else{
          $scope.item.msds_url = [full_url];
        }
        $scope.$emit('update-file', $scope.item);
        $rootScope.$broadcast('inventory-upload-end', $scope.item);
      }).error(function(response, status, headers, config) {
        $rootScope.$broadcast('inventory-upload-end', $scope.item);
      }).xhr(function(xhr) {
          ctrl.abortFile = function() {
            xhr.abort();
            setTimeout(function(){
              ctrl.file_progress = -1;
              })
          };
      });
    };

    ctrl.uploadFile = function(file, errFiles) {
      ctrl.file_progress = -1;
      ctrl.file_info = file;
      if (file) {
        $rootScope.$broadcast('inventory-upload-start');
        ctrl.file_size = parseFloat((file.size / 1048576).toFixed(2)) + " MB";
        var fileName = file.name;
        sowSterilizationService
          .getFileUploadTokenInventory(ctrl.getDefaultFileName(fileName))
          .then(function(el) {
            ctrl.uploadFileToAWS(el, file);
          });
      }
    };

    init();

    return ctrl;

    function init () {
      updateCtrlInfo();
      loadCategories();
      loadLocations();
      defineLocks();

      $scope.$on('inventory-item-slideout', updateCtrlInfo);
    }
    
    function updateCtrlInfo () {
      var sds_url = _.get($scope, 'item.sds_url', _.get($scope, 'item.msds_url', null));
      if(sds_url){
          ctrl.file_src = _.last(sds_url);
          ctrl.file_progress = 100;
          var i = ctrl.file_src.split('/');
          ctrl.file_info.name = _.last(i);
      }
    
      const unique_paths = SDSHelperService.filterUniqueSdsUrls(sds_url);
      ctrl.sds_paths = SDSHelperService.getDisplayNamesForUrls(unique_paths);
    }

    function getDisplayName(path) {
      const name = _.last(path.split('/'));
      // prevent UI from displaying "SDS-SDS-"
      const parsed = name.split('SDS-')[1];
      return parsed || name;
    }

    // load categories & make search function for manufacturers
    function loadCategories () {
      return sowInventoryService.getInventoryCategories()
      .then(function(response) {
        var categories = response;
        var removed_cat = _.remove(categories,function(obj){ return obj.name === "Other";});
        let subcategories = _.remove(categories,function(obj){ return !_.isNil(obj.parent_id);});
        categories = _.sortBy(categories, ['name']);
        // categories.push(removed_cat[0]);
        ctrl.categories = categories;
        ctrl.subcategories = subcategories;
      });
    }

    function loadLocations () {
      var locals = null;
      if (ctrl.locations) {
        locals = angular.copy(ctrl.locations);
      }
      return sowInventoryService.getInventoryLocations()
      .then(function(response){
        ctrl.locations = _.sortBy(response,['name']);
        if (locals) {
          var new_location = _.first(_.differenceBy(angular.copy(ctrl.locations), locals, 'id'));
          if (new_location) {
            _.set($scope.item, "location_id", new_location.id);
          }
        }
      })
      .catch(function(error){
        var t_message = $filter('translate')('ERRORS.INVENTORY_LOCATIONS_LOAD_TRY_AGAIN')
        errorService.uiErrorHandler(error.message || t_message);
      });
    }

    function addExpiryDate () {
      $scope.item.expiry_dates = $scope.item.expiry_dates || [];

      if($scope.item.new_expiry_date){
        var newDate = true;
        for(var di in $scope.item.expiry_dates){
          if(moment(new Date($scope.item.expiry_dates[di].date)).startOf('day').isSame(moment($scope.item.new_expiry_date).startOf('day'))){
            newDate = false;
            break;
          }
        }
        if(newDate){
          $scope.item.expiry_dates = formatExpiryDates(
            $scope.item.expiry_dates,
            $scope.item.new_expiry_date
          );
        }
      }
      delete $scope.item.new_expiry_date; //clear
    }

    function addLocation (location, locationName) {
      if (!$scope.item || (!locationName && (!location || !location.name))) {
        return false;
      }

      if (!$scope.item.locations) {
        $scope.item.locations = [];
      }

      location = location || {name: locationName};

      //
      // Check for a case-insensitive name match in pre-existing locations if
      // we were just passed a location name.
      //
      if (!location.id) {
        _.forEach(ctrl.locations, function (loc) {
          if (loc.name.toLowerCase() === location.name.toLowerCase()) {
            location = loc;
            return false;
          }
        });
      }

      var existsOnItem = false;

      _.forEach($scope.item.locations, function (loc) {
        if (location.id && loc.id === location.id) {
          existsOnItem = true;
          return false;
        }

        if (loc.name.toLowerCase() === location.name.toLowerCase()) {
          existsOnItem = true;
          return false;
        }
      });

      if (!existsOnItem) {
        $scope.item.locations.push(location);
      }

      //
      // TODO This could get merged into the loop over ctrl.locations
      // above.
      //
      var newLoc = true;
      for (var li in ctrl.locations) {
        if (ctrl.locations[li].name.toLowerCase() === location.name.toLowerCase()) {
          newLoc = false;
          break;
        }
      }

      if (newLoc) {
        ctrl.locations.push(location);
      }

      // DOM Manipulatiion like this should be done in a directive link
      // method as opposed to a controller.
      var inputEle = angular.element(document.getElementById('location-add-input'));
      if (inputEle) {
        inputEle.val('');
        inputEle.scope().$mdAutocompleteCtrl.hidden = true;
        inputEle.blur();
      }
    }

    function locationSearch (query) {
      var results = [];
      if (!query) {
        results = ctrl.locations;
      } else {
        for (var l in ctrl.locations) {
          if (ctrl.locations[l].name.toLowerCase().indexOf(query.toLowerCase()) > -1) {
            results.push(ctrl.locations[l]);
          }
        }
      }
      return results;
    }

    function manufacturerSearch (name) {
      if (!name) {
        return false;
      }

      return elementService.get('manufacturer', null, {
        endpoint: 'search',
        name: name
      })
      .then(function (manufacturers) {
        return _.map(manufacturers, 'name');
        // return _.toArray(manufacturers);
      });
    }

    // first try
    // function updateItemManufacturer (manufacturer) {
    //   _.set($scope.item, 'manufacturer_name', _.get(manufacturer, 'name', null));
    //   _.set($scope.item, 'manufacturer_id', _.get(manufacturer, 'id', null));
    // }

    function updateItemManufacturer (manufacturer_name) {
      _.set($scope.item, 'manufacturer_name', manufacturer_name || null);
      _.set($scope.item, 'manufacturer_id', null);
    }

    function selectedManufacturerChange (manufacturer) {
      updateItemManufacturer(manufacturer);
    }

    // POST to /user-products expects an array of date string. PUT to
    // /inventory-items expects an array of objects with a date field.
    function formatExpiryDates (expiry_dates, new_expiry_date) {
      var dates = angular.copy(expiry_dates);

      var item = {date: new_expiry_date};

      dates.push(item);
      return dates;
    }

    function defineLocks () {
      const membership = membershipService.get();
      
      const manufacturer_lock_condition = _.get(membership, "user_properties.gen_inv.disable_manufacturer", false);
      ctrl.manufacturer_locked = manufacturer_lock_condition;
      const category_lock_condition = _.get(membership, "user_properties.gen_inv.disable_category", false);
      ctrl.category_locked = category_lock_condition;
      const subcategory_lock_condition = _.get(membership, "user_properties.gen_inv.disable_subcategory", false);
      ctrl.subcategory_locked = subcategory_lock_condition;
    }

  }
}());

(function () {
  'use strict';

  angular.module('app.marketplace.ui.office')
    .controller('ChangeMembershipController', ChangeMembershipController);

  function ChangeMembershipController($scope, $state, elementService, $mdDialog, membershipService, AccountModalService) {
    const ctrl = this;

    init();

    return ctrl;

    function init() {
      defineLocks();

      $scope.memberships = membershipService.getAll().office;

      $scope.createOffice = function () {
        $state.go('app.office.create');
        $mdDialog.cancel();
      };

      $scope.order_by = function (item) {
        return item.office.name;
      };

      //
      // Return the user's selection to accountModalService
      //
      $scope.selectMembership = function (membership) {
        $mdDialog.hide(membership);
      };

    }

    function defineLocks() {
      const membership = membershipService.get();

      const disable_create_new_condition = _.get(membership, "user_properties.office.disable_create_new", false);
      ctrl.disable_create_new = disable_create_new_condition;
    }

  }

}());

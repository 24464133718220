angular.module('app.marketplace.system').service('searchService',
  function searchService($rootScope, $q, $stateParams, elementService, officeService, inventoryItemService) {
    var searchResults = {}; // Cached search results to avoid multiple api calls

    var _getSearch = function(query, options){
      options = options || {};
      return $q(function(resolve, reject) {
        var force = options.force;
        var alreadyEncoded = options.alreadyEncoded;
        var isAutocomplete = options.autocomplete;
        var medicalFieldID = options.medicalField ? options.medicalField.id : $stateParams.medicalField || $rootScope.current_medical_field.id;


        if(!force && searchResults[query]){
          resolve(searchResults[query]);
        }

        var office_id = officeService.get() ? officeService.get().id : null;

        //Unconventional endpoint returns inventory items, products, and user_products.
        // Due to the non-standard return of the endpoint, this call needs a custom then
        // that creates instances for all elements.
        elementService.callEndpoint("product", {
          endpoint: "search",
          query: query,
          office_id: office_id,
          alreadyEncoded: alreadyEncoded,
          medical_field_id: medicalFieldID
        }).then(function(response){
          if(!checkNested(response,'data','products')){
            // Error handling should have already been done and this should not
            // be reached but I'm being defensive >:|
            return false;
          }

          var ids = {};
          _.map(response.data.user_products, function(product){
            ids[product.id] = product;
          });

          var searchElements = {
            products: elementService.createMultiple('product', response.data.products),
            product_templates: elementService.createMultiple('productTemplate', response.data.user_products), //user_products is actually product templates. Name is being kept currently for backwards compatibility. TODO fix/clean api endpoint.
            inventory_items: elementService.createMultiple('inventoryItem', response.data.inventory_items)
          };

          searchResults[query] = searchElements;
          
          $rootScope.$broadcast('searchService: did-search', {'isAutocomplete': isAutocomplete, 'query': query, 'response': response.data});

          resolve(searchResults[query]);
        }).catch(function (error) {
          reject(error);
        });
      });

    };
    
    var _inventorySearch = function(search, supplier_id){
      return inventoryItemService.searchInventory(search, supplier_id);
    };

    var _doSearch = function(query){
      return _getSearch(query, true);
    };

    //
    // Clear results when the country changes
    //
    $rootScope.$on('elementRefreshService: country-changed', function () {
      searchResults = {};
    });

    function _manufacturerSearch (name) {
      return elementService.get('manufacturer', null, {
        'endpoint': 'search',
        'name': name,
      });
    }

    return {
      getSearch : _getSearch,
      manufacturerSearch: _manufacturerSearch,
      inventorySearch : _inventorySearch,
      doSearch : _doSearch // Forces new search to be done.
    };
  }
);

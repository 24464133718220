angular.module('app.marketplace.ui.orders').controller('sowingoOrdersController',
  function sowingoOrdersController($scope, $q, $filter, $timeout, $state, $stateParams, $rootScope, $mdDialog, sgToast, elementService, errorService, smoothScroll, orderService, returnService, exchangeService) {
    var _this = this;

    // deprecated
    // if (!$rootScope.features.marketplace && $rootScope.features.aaoms_marketplace) {
    //   $state.go('app.orders.aaoms');
    // } else if(!$rootScope.features.marketplace && !$rootScope.features.aaoms_marketplace) {
    //   $state.go('app.dashboard');
    // }

    var tags = [
      {
        name: "All Orders",
        active: true,
        model: ""
      },
      {
        name: "Returns",
        active: false,
        model: 'return'
      },
      {
        name: "Exchanges",
        active: false,
        model: 'exchange'
      }
    ];
    //
    // Initialize Controller
    //
    _this.init = function () {
      loadAllOrders();
      angular.extend($scope, {
        // curr_items: _.uniqBy(all_orders, ['hrid']),
        // curr_items: all_orders,
        // all_items: all_orders,
        tags: tags,
        set_tag_active: set_tag_active,
        check_if_all_tag_active: check_if_all_tag_active,
        openOrder: _this.openOrder,
        closeOrder: _this.closeOrder,
        toggleOrder: _this.toggleOrder,
        receivedOrderShipment: _this.receivedOrderShipment,
        isCancelledOrder: isCancelledOrder,
        openProductDetails: openProductDetails,
      });

      // angular.forEach($scope.curr_items, function (item) {
      //   if (item.order_id === $stateParams.orderId || (item.params.model_name === 'order' && item.hrid === $stateParams.orderId)) {
      //     _this.openOrder(item);
      //     return false;
      //   }
      // });

      if ($stateParams.orderId) {
        var index = _.findIndex($scope.curr_items, function(item){
          return (item.order_id === $stateParams.orderId || (item.params.model_name === 'order' && item.hrid === $stateParams.orderId));
        });
        if (index >= 0) {
          _this.openOrder($scope.curr_items[index]);
        } else {
          loadOrder({'id':$stateParams.orderId, 'hrid':$stateParams.orderId, 'order_id':$stateParams.orderId})
          .then(function(order_response){
            $scope.curr_items.push(order_response);
            _this.openOrder(order_response);
          });
        }
      }

      $scope.$watch(_getFilter, _updateFilter, true);
    };

    function loadAllOrders () {
      var orderServices = [orderService, returnService, exchangeService]; // Order services to use.
      var all_orders = [];

      // Get list of orders from each service.
      var _getOrders = function (index, service) {
        return service.get(true).then(function (orders) {
          return $.map(orders, function (value, index) {
            return [value];
          });
        });
      };

      // Get list of orders from each service
      var serviceCalls = [];
      angular.forEach(orderServices, function (service, index) {
        service.init();
        service.refresh();
        serviceCalls.push(_getOrders(index, service));
      });

      // After all calls are done combine results
      return $q.all(serviceCalls).then(function (results) {
        // Combine all passed order arrays into 1 array. Then sort it by date.
        all_items = $filter('orderBy')([].concat.apply([], results), '-sort_date');
        $scope.all_items = all_items;
        $scope.curr_items = all_items;
        return all_items;
      }).catch(function (error) {
        var t_message = $filter('translate')('ERRORS.ORDERS_LOAD')
        errorService.uiErrorHandler(error || t_message, 0);
      });
    }

    function openProductDetails (id) {
      $rootScope.$broadcast('mkt-details-open-fetch', id);
    }

    function set_tag_active(tag) {
      _.forEach($scope.tags, function(tag){
        tag.active = false;
      });
      tag.active = true;
      orderService.UI.filter = {
        filter_instance_type: tag.model
      };
    }

    function check_if_all_tag_active(tag){
      $scope.is_all_tag_active = true;

      _.forEach($scope.tags, function(tag){
        if(tag.active){
          $scope.is_all_tag_active = false;
        }
      });

      if( !$scope.is_all_tag_active ){
        orderService.UI.filter = {
          filter_instance_type: tag.model
        };
      } else {
        orderService.UI.filter = {
          filter_instance_type: ''
        };
      }
    }

    function isCancelledOrder (order) {
      return order.item_count <= order.cancelled_item_count;
    }

    //
    // Open Order
    //
    // TODO scroll to order
    //
    _this.openOrder = function (order) {
      $scope.currentBundle = order;
      if(!_.get(order, 'UI.loaded')){
        _.set(order, 'UI.showLoadingBar', true);
        loadOrder(order)
        .then(function(response){
          var index = _.findIndex($scope.curr_items, ['hrid', response.hrid]);
          // assuming this is already loaded into curr_items, no need to check index>=0 here
          order = $scope.curr_items[index] = _.extend(order,response); 
          _.set(order, 'UI.showLoadingBar', false);
          _.set(order, 'UI.loaded', true);
          $scope.currentBundle = order;
        });
      }
      $timeout(function(){
        smoothScroll.scrollToElementById('order-'+order.id, {offset : -100});
      }, 500);
      
    };

    function loadOrder (params) {
      return orderService.getOrder(params)
      .then(function(order_response){
        return order_response;
      })
      .catch(function (error) {
        var t_message = $filter('translate')('ERRORS.ORDER_ITEMS_RETRIEVE')
        errorService.uiErrorHandler(error || t_message);
      });
    }

    function loadItems (order) {
      return orderService.getItems(order)
      .then(function(items_response){
        // _.set(order, 'items', items_response);
        return items_response;
      });
    }

    //
    // Collapse Order View
    //
    _this.closeOrder = function (order) {
      $scope.currentBundle = null;
    };


    //
    // Toggle an Order Open/Closed.
    //
    _this.toggleOrder = function(order){
      if($scope.currentBundle === order){
        $scope.closeOrder(order);
      }else{
        $scope.openOrder(order);
      }
    };

    //
    // Process received shipment
    //
    _this.receivedOrderShipment = function(order, group, shipment, do_import){
      if(do_import===null || do_import===undefined){
        var confirm = $mdDialog.confirm()
              .title('Import to Inventory?')
              .textContent('Update your inventory with shipped items? (recommended)')
              .ok('Yes')
              .cancel('No');
        return $mdDialog.show(confirm).then(function() {
          _this.receivedOrderShipment(order, group, shipment, true);
        }).catch(function() {
          _this.receivedOrderShipment(order, group, shipment, false);
        });
      }

      orderService.receivedOrderShipment(order, shipment.id, do_import)
      .then(function(response){
        var t_message = $filter('translate')('TOAST.ITEMS_ADDED_TO_INV');
        var t_message2 = $filter('translate')('TOAST.SHIPMENT_UPDATED');
        sgToast.showSimple(do_import ? t_message : t_message2);
      }).catch(function(error){
        var t_message = $filter('translate')('ERRORS.ORDER_ITEMS_RETRIEVE')
        errorService.uiErrorHandler(error || t_message);
      });
    };


    //
    // Get the current filter
    //
    var _getFilter = function () {
      return orderService.UI.filter;
    };

    //
    // Update Filter
    //
    var _updateFilter = function (newFilter) {
      if (newFilter && (newFilter.user_id || newFilter.filter_instance_type)) {
        $scope.curr_items = $filter('filter')($scope.all_items, _orderFilter);
      } else {
        $scope.curr_items = $scope.all_items;
      }
    };

    //
    // Order Filter Function
    //
    var _orderFilter = function (item) {
      var filter = orderService.UI.filter;
      return !(
        (filter.user_id && item.user.id !== filter.user_id) ||
        (filter.filter_instance_type && item.params.model_name !== filter.filter_instance_type)
      );
    };

    _this.init();
    return _this;
  }
);

(function () {
    'use strict';
  
    angular.module('sowProduct')
      .controller('pdRelatedProductsController', pdRelatedProductsController)
      .directive('pdRelatedProducts', pdRelatedProductsDirective);
  
    function pdRelatedProductsDirective() {
      return {
        restrict: 'E',
        templateUrl: 'sow-product-details/directives/pd-related-products.html',
        controller: 'pdRelatedProductsController',
        controllerAs: 'pdRelatedProductsCtrl',
        scope: {
          products: '='
        }
      };
    }
  
    function pdRelatedProductsController () {
      var ctrl = this;
  
      init();
  
      return ctrl;
  
      function init() {
  
      }
  
    }
  
  })();

(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.inventory')
    .service('DeleteInventoryDraftPODialog', DeleteInventoryDraftPODialog);

  function DeleteInventoryDraftPODialog ($mdDialog) {
    /*jshint validthis: true */
    var service = this;

    service.show = show;

    return service;

    function show (items, $event) {
      return $mdDialog.show({
        'parent': angular.element(document.body),
        'targetEvent': $event,
        'controller': DialogCtrl,
        'controllerAs': '$dialogCtrl',
        'templateUrl': 'templates/marketplace/inventory/delete-inventory-draft-po-dialog.html',
        'clickOutsideToClose': false,
        'escapeToClose': false,
        'locals': {
          'items': items,
        }
      });
    }
  }

  /* @ngInject */
  function DialogCtrl ($scope, $q, $mdDialog, elementService, errorService, PurchaseOrderService, items) {
    /*jshint validthis: true */
    var ctrl = this;

    $scope.items = items;

    ctrl.cancel = cancel;
    ctrl.deleteItems = deleteItems;

    return ctrl;

    function cancel () {
      $mdDialog.hide([]);
    }

    function deleteItem (item) {
      var item_id = item.id;

      return $q.all(_.map(
        _.filter(item.purchase_orders, {'status': 'Draft'}),
        function (po) {
          return PurchaseOrderService.deletePOItem({'id': po.purchase_order_item_id});
        }
      )).then(function () {
        return elementService.remove('inventoryItem', item, true).then(function () {
          return item_id;
        }).catch(function (error) {
          throw 'Failed to delete inventory item.';
        });
      }).catch(function (error) {
        throw 'Failed to remove item from all purchase order(s).';
      });
    }

    function deleteItems () {
      $scope.processing = true;

      Q.allSettled(_.map(items, deleteItem)).then(
        function (results) {
          var deleted = [];
          var failed = [];

          _.forEach(results, function (result) {
            var state = result.state;

            if (state === 'fulfilled') {
              deleted.push(result.value);
            }

            if (state === 'rejected') {
              errorService.uiErrorHandler(result.reason);
            }
          });

          $mdDialog.hide(deleted);
        }
      );
    }
  }
}());

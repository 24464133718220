(function () {
  'use strict';

  const sowMktCartReview = {
    selector: 'sowMktCartReview',
    templateUrl: 'sow-mkt/directives/mkt-cart-review.html',
    controller: 'marketplaceCartReviewController',
    controllerAs: 'mktcrCtrl',
  };

  angular.module('sowMarketplace')
    .controller(sowMktCartReview.controller, marketplaceCartReviewController)
    .component(sowMktCartReview.selector, sowMktCartReview);

  /** @ngInject */
  function marketplaceCartReviewController ($scope, $rootScope, $filter, cartService, $state, errorService, mktHelperService, sgToast, sowExternalVendorAccountService, sowExternalVendorService, sowAnalyticsService, msHelperService, AccessService) {
    /*jshint validthis:true*/
    const ctrl = this;

    // -------------------------------- default state --------------------------------

    const _oldQuantities = {};
    ctrl.savings_total = 0;
    ctrl.is_loading_external_vendor_prices = false;
    ctrl.items_with_failed_price_fetch = [];

    // -------------------------------- public methods --------------------------------

    ctrl.updateItem = updateItem;
    ctrl.removeItem = removeItem;
    ctrl.focusItem = focusItem;
    ctrl.goToSearch = goToSearch;
    ctrl.getItemPrice = getItemPrice;
    ctrl.getItemSubtotal = getItemSubtotal;
    ctrl.getVendorSubtotal = getVendorSubtotal;
    ctrl.getTotalQuantity = getTotalQuantity;
    ctrl.getBillingText = getBillingText;
    ctrl.isVendorInvalid = isVendorInvalid;
    ctrl.isVendorLoading = isVendorLoading;
    ctrl.shouldShowSpendMoreAlert = shouldShowSpendMoreAlert;
    ctrl.shouldShowFreeShippingAlert = shouldShowFreeShippingAlert;
    ctrl.shouldShowUnknownShippingAlert = shouldShowUnknownShippingAlert;
    ctrl.shouldShowConnectionFailedAlert = shouldShowConnectionFailedAlert;
    ctrl.goToProductDetailsPage = mktHelperService.goToProductDetailsPage;
    ctrl.getPromoText = mktHelperService.getPromoText;
    ctrl.hasPromoPrice = hasPromoPrice;
    ctrl.isManufacturerPromo = isManufacturerPromo;
    ctrl.getQuantityText = getQuantityText;
    ctrl.getVendorShipping = getVendorShipping;
    
    // ------------------------------ lifecycle methods ------------------------------
    
    ctrl.$onInit = init;
    
    // -------------------- private methods (exposed for testing only) --------------------

    ctrl._refreshExternalVendorPrices = _refreshExternalVendorPrices;

    return ctrl;

    function init () {
      _defineLocks();
      updateCart();
      _refreshExternalVendorPrices();
      $scope.$on('cartService:end-action:submit-item', updateCart);
      $scope.$on('cartService: set-cart', updateCart);
      $scope.$on('cartService: clear-cart', updateCart);
      $scope.$on('cartService: itemAddedToCart', updateCart);
      $scope.$on('external-vendors-prices-refreshed', () => {
        ctrl.is_loading_external_vendor_prices = false;
      });
      $scope.$on('refresh-external-vendor-prices', _refreshExternalVendorPrices);
    }

    function updateCart () {
      ctrl.cart = cartService.get();
      $scope.cart = ctrl.cart;
      ctrl.cartIsEmpty = !checkNested(ctrl, 'cart', 'items', 'length') || ctrl.cart.items.length < 1;
      cartService.setShippingAlerts();
      setPromoInfo();
    }

    /**
     * Checks to see if there are any external vendors in the cart and if so, calls the
     * _loadExternalVendorPrices() function
     * @returns null if no external vendors exist in the cart.
     */
    function _refreshExternalVendorPrices() {
      const external_vendors = ctrl.cart.vendor_groups.filter(vendor => vendor.is_external_vendor);
      // exit early if no vendors are external
      if (external_vendors.length === 0) {
        return null;
      }
      _loadExternalVendorPrices(external_vendors);
    }

    /**
     * Loops through each external vendor group in the cart, loops through each item in that vendor
     * group, and then makes an API request to the microservice to get the price of that item.
     * @param external_vendors - an array of objects, each of which represents a vendor whose items
     * need to have their prices updated.
     */
    function _loadExternalVendorPrices(external_vendors) {
      // set the loading state
      ctrl.is_loading_external_vendor_prices = true;
      // track how many vendors still have at least one item with a
      // price that we need to fetch from the microservice and update
      let pending_vendor_requests = external_vendors.length;
      // create the object which the monolith expects as the payload
      // of the PATCH to refresh the prices of items whose updated
      // info we're about to fetch from the microservice
      const cart_item_ids_to_update = {};

      for (const vendor of external_vendors) {
        // the API request and error handling require some data from the vendor
        const { connected_name, vendor_id } = vendor;
        // track how many items from this vendor need to be updated with a fresh price
        let pending_item_requests = vendor.items.length;

        for (const item of vendor.items) {
          // the API request and error handling also require some data from the item itself
          const { uom, vendor_sku, id, quantity } = item;
          // initialize the variable which will hold the updated price
          let price = null;
          // create the Object we use to fetch the price for the current account
          const item_info = { connected_name, uom, vendor_sku };
          // attempt to fetch the updated price
          sowExternalVendorAccountService.getProductPriceForAccount(item_info)
            .then(product_info => {
              price = msHelperService.getDataValue(product_info?.data?.Price);
            })
            .catch(error => {
              console.error(error);
            })
            .finally(() => {
              if (price) {
                // success only occurred if we received a truthy price
                cart_item_ids_to_update[id] = { quantity, price };
              } else {
                // otherwise we trigger this item's error state
                _handleExternalItemWithoutPrice(id, vendor_id);
              }
              pending_item_requests--;
              // if this vendor does not have any more pending items...
              if (pending_item_requests === 0) {
                // ...it is no longer pending
                pending_vendor_requests--;
                // if no more vendors are pending...
                if (pending_vendor_requests === 0) {
                  // ...we're ready to handle the responses
                  _handleFetchedExternalVendorPrices(cart_item_ids_to_update);
                }
              }
            });
        }
      }
    }

    /**
     * Creates an Object with the ID and vendor ID of the item for which we couldn't
     * fetch an updated price, then adds that Object to the list of failed items
     * @param id - the id of the item
     * @param vendor_id - the vendor id of the item's chosen vendor.
     */
    function _handleExternalItemWithoutPrice(id, vendor_id) {
      const failed_item = { id, vendor_id };
      ctrl.items_with_failed_price_fetch.push(failed_item);
    }

    /**
     * Receives the external vendor prices fetched from the microservice, broadcasts the time
     * that the prices were fetched, checks if the order can be completed, and updates
     * the monolith with the prices from the microservice
     * @param cart_item_ids_to_update - an array of cart item ids that need to be updated with the
     * prices from the microservice
     * @returns null if there are no prices to update.
     */
    function _handleFetchedExternalVendorPrices(cart_item_ids_to_update) {
      // check if order can be completed
      _evaluateCheckoutValidity();
      // handle case where there aren't any updated prices
      if (_.isEmpty(cart_item_ids_to_update)) {
        // broadcast the moment that the prices were refreshed to allow auto-refresh after
        // 15 minutes and to ensure that the loading state does not persist (when we update
        // the monolith this broadcast is made after that request resolves/rejects)
        $rootScope.$broadcast('external-vendors-prices-refreshed', moment());
        // exit early since we don't have any updated prices to send to the monolith
        return null;
      }
      // update the monolith with the prices from the microservice
      sowExternalVendorService.batchUpdateItemPrice({ cart_item_ids_to_update });
    }

    /**
     * If there are no items with failed price fetches, then broadcast a message that prices have been
     * fetched for all external vendor items. Otherwise, broadcast a message that a price fetch has
     * failed for an external vendor item
     */
    function _evaluateCheckoutValidity() {
      if (ctrl.items_with_failed_price_fetch.length === 0) {
        $rootScope.$broadcast('prices-fetched-for-all-external-vendor-items');
      } else {
        $rootScope.$broadcast('price-fetch-failed-for-external-vendor-item');
      }
    }

    function getBillingText(vendor) {
      switch (vendor.checkout_type) {
        case 'purchase_order':
          return vendor.supplier.sales_rep_email
            ? $filter('translate')('ORDERS.WILL_BE_SENT_TO', { x: vendor.supplier.sales_rep_email })
            : $filter('translate')('ORDERS.NO_EMAIL_SETUP');
        case 'marketplace.creditcart.charge_on_checkout':
        case 'marketplace.creditcart.charge_on_shipping':
        case 'marketplace.creditcart.charge_by_vendor':
          return $filter('translate')('MARKETPLACE.CART.CHARGED_ON_CC');
        case 'external_vendor':
          return null;
        case 'marketplace.invoice':
        default:
          return $filter('translate')('MARKETPLACE.CART.INVOICES_DIRECTLY');
      }
    }

    function isVendorInvalid(vendor) {
      return vendor.checkout_type.split('.')[0] === 'purchase_order' && !vendor.supplier.sales_rep_email;
    }

    /**
     * > If the vendor is an external vendor and the external vendor prices are loading, then return
     * true, else return false
     * @param vendor - The vendor object that is being iterated over in the ng-repeat.
     * @returns A boolean value.
     */
    function isVendorLoading(vendor) {
      return ctrl.is_loading_external_vendor_prices && vendor.is_external_vendor;
    }

    /**
     * > If the item price fetch failed, or if the item is an external vendor price and the external
     * vendor prices are loading, then return "N/A". Otherwise return the subtotal.
     * @param item - The item object
     * @returns The subtotal cost of the item ("N.A" if loading or unavailable).
     */
    function getItemSubtotal (item) {
      // handle external vendor items which are loading or which could not be updated
      if (_isItemPriceNA(item)) {
        return $filter('translate')('MARKETPLACE.CART.NA');
      }
      // handle all other cases
      return _calculateItemSubtotal(item);
    }

    /**
     * Calculates the item's subtotal and returns it. Factors in promos and quantity.
     * @param item - the item object
     * @returns the price of the item, factoring in quantity and any applied promos.
     */
    function _calculateItemSubtotal(item) {
      let subtotal_cost = item.quantity * item.price;
      if (item.UI?.is_manufacturer_promo === false) {
        const subtotal_price = item.vendor_inventory_promotion?.effective_price || item.price;
        const free_quantity = item.vendor_inventory_promotion?.quantity_free || 0;
        const subtotal_qty = item.quantity + free_quantity;
        subtotal_cost = subtotal_price * subtotal_qty;
      }
      return $filter('currency')(subtotal_cost, '$', 2);
    }

    /**
     * > If the item's price is not available, return "N/A". Otherwise, return the item's price
     * @param item - the item object
     * @returns the price of the item ("N.A" if loading or unavailable).
     */
    function getItemPrice(item) {
      // handle external vendor items which are loading or which could not be updated
      if (_isItemPriceNA(item)) {
        return $filter('translate')('MARKETPLACE.CART.NA');
      }
      // handle all other cases
      return $filter('currency')(item.price, '$', 2);
    }

    /**
     * > If the vendor is external and the price is loading, or if the vendor is external and the price
     * of one or more of its items failed to load, then return "N/A". Otherwise, return the subtotal cost
     * @param vendor - the vendor object
     * @returns the subtotal cost of this vendor's items in the cart ("N.A" if loading or unavailable).
     */
    function getVendorSubtotal(vendor) {
      // handle external vendors which are loading or whose item(s) could not be updated
      if (_isVendorPriceNA(vendor)) {
        return $filter('translate')('MARKETPLACE.CART.NA');
      }
      // handle all other cases
      return $filter('currency')(vendor.items_subtotal, '$', 2);
    }

    /** Returns the vendor shipping cost
    * @param vendor - the vendor object
    * @return {string} - the shipping cost of this vendor's items in the cart 
    */
    function getVendorShipping(vendor) {
      if (_isFreeShipping(vendor)) {
        return $filter('translate')('MARKETPLACE.DETAIL.FREE');
      }

      return $filter('currency')(vendor.shipping_subtotal, '$', 2);
    }

    /**
     * Returns true if the shipping value is equal to zero
     * @param {string} shipping
     * @return {boolean}
     */
    function _isFreeShipping (vendor) {
      const is_free = _.toNumber(vendor.shipping_subtotal) === 0;
      return !_isPOCheckoutVendor(vendor) && is_free;
    }

    /**
     * If the item price fetch failed or the external vendor price is loading, then the item price is
     * not available and we need to display "N/A" in the UI.
     * @param item - The item object
     * @returns A boolean value.
     */
     function _isItemPriceNA(item) {
      const did_item_price_fetch_fail = ctrl.items_with_failed_price_fetch.some(({ id }) => id === item.id);
      const is_external_vendor_price_loading = item.is_external_vendor_pricing && ctrl.is_loading_external_vendor_prices;
      return did_item_price_fetch_fail || is_external_vendor_price_loading;
    }

    /**
     * If the item price fetch failed for any of this vendor's items or the external vendor price is
     * loading, then the vendor total price is not available and we need to display "N/A" in the UI.
     * @param vendor - The vendor object
     * @returns A boolean value.
     */
    function _isVendorPriceNA(vendor) {
      const did_vendor_price_fetch_fail = ctrl.items_with_failed_price_fetch.some(({ vendor_id }) => vendor_id === vendor.vendor_id);
      const is_vendor_loading = isVendorLoading(vendor);
      return did_vendor_price_fetch_fail || is_vendor_loading;
    }

    function setPromoInfo () {
      if(ctrl.cartIsEmpty){
        return;
      }
      _.each(ctrl.cart.vendor_groups, function(group){
        _.each(group.items, function(item){
          _.set(item, 'promotions', [item.vendor_inventory_promotion]);
          _.set(item, 'UI.on_sale', !_.isNil(_.get(item, 'vendor_inventory_promotion.id')) );
          _.set(item, 'UI.promo_count', 1);
          var promo_type = _.get(item, 'promotions[0].promotion_type', null);
          _.set(item, 'UI.is_manufacturer_promo', promo_type && promo_type.includes('MANUFACTURER'))
          var promo_notes = _.get(item, 'promotions[0].notes') ? item.promotions[0].notes : _.get(item, 'promotions[0].promotion_notes', null);
          _.set(item, 'UI.promo_notes', promo_notes);
        });
      });
    }
    
    function removeItem (item) {
      if (item.updating) {
        return;
      }
      item.updating = true;

      return cartService.removeCartItem(item)
      .then(function (response) {
        updateCart();
        // update the items_with_failed_price_fetch to ensure the removed item is no longer included
        ctrl.items_with_failed_price_fetch = ctrl.items_with_failed_price_fetch.filter(({ id }) => id !== item.id);
        _evaluateCheckoutValidity();
      })
      .catch(function (error) {
        errorService.uiErrorHandler(error);
      })
      .finally(function () {
        item.updating = false;
      });
    }

    function updateItem (item, blur) {
      if(item.updating) {
        return;
      }
      var isEmpty = _.includes([null, undefined, ''], item.quantity);
      var oldQuantity = _oldQuantities[item.id];

      if (isEmpty) {
        if(blur){
          item.quantity = oldQuantity;
          return;
        } else {
          return;
        }
      }

      const max_quantity_limit = 999;
      if (item.quantity > max_quantity_limit) {
        const quantity_locale = $filter('translate')('COMMON.QUANTITY');
        const message = $filter('translate')('ERRORS.VALUE_LESS_THAN', { value: max_quantity_limit, field_name: quantity_locale });
        errorService.uiErrorHandler(message);
        item.quantity = oldQuantity;
        return;
      } 

      if (_.toNumber(item.quantity) < 1) {
        return removeItem(item);
      }

      item.updating = true;
      return cartService.updateCartItem(item)
      .then(function (response) {
        var updated_items = _.get(response, 'items', []);
        var item_with_updated_quantity = _.find(updated_items, function(updated_item) { 
          return updated_item.id === item.id;
        })
        var updated_qty = item_with_updated_quantity.quantity;
        if (updated_qty === oldQuantity) {
          var t_cannot_exceed = $filter('translate')('TOAST.CANNOT_EXCEED_LIMIT');
          sgToast.showSimple(t_cannot_exceed);
        } else {
          const {product, vendor_inventory_promotion} = item_with_updated_quantity;
          const updated_item = {
            ...item_with_updated_quantity,
            ...product,
            promotions: [vendor_inventory_promotion],
            previous_quantity: oldQuantity
          }
          sowAnalyticsService.logCartItemQuantityUpdate(updated_item);
          updateCart();
        }
      })
      .catch(function (error) {
        item.quantity = oldQuantity;
        errorService.uiErrorHandler(error);
      })
      .finally(function () {
        item.updating = false;
      });
    }

    function focusItem (item) {
      _oldQuantities[item.id] = item.quantity;
    }

    function goToSearch (vendor) {
      $state.go('app.mkt.search', {'query': vendor.vendor_name});
    }

    /** 
     * Calculates the total number of products which the user is ordering
     * from a particular vendor. Each item has a quantity property to 
     * indicate how many of that item the user has added to their cart, and
     * by summing these we can determine how many total products will be
     * provided by the vendor.
     * NOTE: does not factor in free products from promos.
     * 
     * @param {Array} items
     * 
     * @return {Number} 
    */
    function getTotalQuantity(items) {
      // Map each item to its quantity property and then sum these quantities
      if(!items) {
        return 0;
      }
      return items
      .map(({ quantity }) => quantity)
      .reduce((a, b) => a + b);
    }

    /**
     * Returns a string representing the quantity of an item, including any free quantity if applicable.
     * @param {object} item - product item
     * @return {string} - 5 + 1 FREE or 5
     */
    function getQuantityText (item) {
      const free_quantity = _.get(item.promotions?.[0], 'quantity_free', 0);
      if (free_quantity > 0) {
        return `${item.quantity} + ${free_quantity}${$filter('translate')('MARKETPLACE.DETAIL.FREE')}`;
      }

      return item.quantity;
    }

    /**
     * Returns true if the vendor's checkout_type is 'purchase_order' and false otherwise
     * @param {Object} vendor - The vendor object
     * @returns {Boolean}
     */
    function _isPOCheckoutVendor(vendor) {
      const is_po_checkout = vendor.checkout_type === 'purchase_order';
      return is_po_checkout;
    }

    /**
     * If the created order for this vendor will NOT be a purchase order and the user can spend
     * more to qualify for free shipping, then show the spend more alert for this vendor
     * @param {Object} vendor - The vendor object
     * @returns {Boolean}
     */
    function shouldShowSpendMoreAlert(vendor) {
      const show_alert_condition = !_isPOCheckoutVendor(vendor) && vendor.shipping_alerts?.spend_more;
      return Boolean(show_alert_condition) && !ctrl.is_full_page_cart;
    }

    /**
     * If the created order for this vendor will NOT be a purchase order and shipping will be free,
     * then show the free shipping alert for this vendor
     * @param {Object} vendor - The vendor object
     * @returns {Boolean}
     */
    function shouldShowFreeShippingAlert(vendor) {
      const show_alert_condition = !_isPOCheckoutVendor(vendor) && vendor.shipping_alerts?.free;
      return Boolean(show_alert_condition) && !ctrl.is_full_page_cart;
    }

    /**
     * If the created order for this vendor will be a purchase order, then show the
     * unknown shipping alert for this vendor
     * @param {Object} vendor - The vendor object
     * @returns {Boolean}
     */
    function shouldShowUnknownShippingAlert(vendor) {
      const show_alert_condition = _isPOCheckoutVendor(vendor);
      return Boolean(show_alert_condition) && !ctrl.is_full_page_cart;
    }

    /**
     * > If the vendor_id of any of the items in the items_with_failed_price_fetch array is equal to
     * the vendor_id of the vendor we were passed, then show the connection failed alert for that vendor
     * @param vendor - The vendor object
     * @returns A boolean value.
     */
    function shouldShowConnectionFailedAlert(vendor) {
      const did_vendor_connection_fail = ctrl.items_with_failed_price_fetch.some(({ vendor_id }) => vendor_id === vendor.vendor_id);
      return did_vendor_connection_fail;
    }

    function hasPromoPrice (promo) {
      return promo && promo?.effective_price !== promo?.unit_price;
    }

    // Checks if promotion_type is "MANUFACTURER_BUY_GET"
    function isManufacturerPromo (promo) {
      return promo?.promotion_type?.includes('MANUFACTURER');
    }

    function _defineLocks () {
      ctrl.is_full_page_cart = AccessService.hasOfficeProperty('shopping_cart', 'full_page_cart');
    }


  }

})();

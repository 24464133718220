(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.dashboard')
    .controller('dashboardSuppliersController', dashboardSuppliersController);

  function dashboardSuppliersController ($scope, $state, $filter, $timeout, smoothScroll, $q,
    inventoryItemService, SupplierService, sowDashboardService, elementService,
    errorService, sgToast, AddToCartDialog, inventoryHelperService, membershipService) {
    /*jshint validthis: true */
    var ctrl = this;
    var highestNbItems = 0;
    var inventory = null;
    var registeredItem = null;
    const t_account = $filter('translate')('COMMON.ACCOUNT');
    ctrl.checkedItems = inventoryItemService.checkedItems;

    ctrl.suppliers = {};
    ctrl.vendorLogos = {};
    ctrl.supplierBundles = [];
    ctrl.NO_SUPPLIER = {
      "name": "(No Supplier)",
      "id": "no",
      "supplier": null,
      "sales_rep": null,
      "items": [],
      "total_value": 0.00,
      "product_count": 0,
      "loaded": false,
    };

    ctrl.addSupplier = () => {
      $state.go('app.office.profile.add-supplier');
    };
    ctrl.editSupplier = editSupplier;
    ctrl.deleteSupplier = deleteSupplier;
    ctrl.sortingFn = sortingFn;
    ctrl.getInventoryValue = getInventoryValue;
    ctrl.openBundle = openBundle;
    ctrl.closeBundle = closeBundle;
    ctrl.isCurrentBundle = isCurrentBundle;
    ctrl.goTo = goTo;

    //
    // scope item functions, necessary for legacy inventory item directive
    //
    $scope.removeItem = removeItem;
    $scope.checkItem = checkItem;
    $scope.printItemQrCode = printItemQrCode;
    $scope.addToCart = addToCart;
    $scope.generateTooltip = inventoryHelperService.generateTooltip;

    $scope.supress_product_slideout = true;

    init();

    return ctrl;

    function init () {
      dataSetup();

      if (ctrl.clearSearch) {
        ctrl.clearSearch();
      }

      $scope.$on('editInventoryItemController: bundle-updated', function (event, bundle) {
        _refreshSuppliers()
        .then(function(){
          matchItems();
        });
      });

      $scope.$on('inventoryItem:removed', function (event, _item) {
        removeLocalItem(_item);
      });

      $scope.$on('supplier_created', (event, new_supplier) => {
        _handleSupplierUpdate({supplier: new_supplier}, null);
      });

      $scope.$on('sales_rep_created', (event, new_sales_rep) => {
        _handleSupplierUpdate({sales_rep: new_sales_rep, supplier: new_sales_rep.supplier}, null);
      });

      $scope.$on("$destroy", clearCheckedItems);

      defineLocks();
    }

    function dataSetup () {
      ctrl.loading = true;
      ctrl.supplierBundles = [];

      var promises = [
        SupplierService.getAll(true),
        SupplierService.getSalesReps(),
        inventoryItemService.getInventoryPOStatus(),
        SupplierService.getSupplierDeleteStatus(),
      ];

      $q.all(promises)
      .then(function(results){
        ctrl.suppliers_map = results[0];
        ctrl.sales_reps = results[1];
        $scope.po_status = ctrl.po_status = results[2];
        ctrl.can_delete = results[3] || {};

        ctrl.items_map = elementService.elementMaps.inventoryItem;

        matchItems();

        ctrl.loading = false;
      });
    }

    function loadSupplierItems (bundle) {
      var sid = bundle.id;
      if(sid === 'no'){
        sid = null;
      }
      return sowDashboardService.getItemsBySupplier(sid)
      .then(function(response){
        bundle = angular.extend(bundle, response, {'loaded':true});
        return bundle;
      });
    }

    function goTo(ev, item) {
      ev.preventDefault();
      ev.stopPropagation();

      if (item.type === "General Item" || item.type === "Marketplace Item") {
        $state.go('app.inventory.all', { itemId: item.id });
      }
      if (item.type === "Medication Item") {
        $state.go('app.medications.list',{ focus_by_inventory_id: item.id });
      }
      // if (item.type === "Implant Item") {
      //   $state.go('app.implants.manufacturers');
      // }
    }

    function matchItems () {
      var previous_open_bundle = null;
      if(ctrl.currentBundle){
        previous_open_bundle = ctrl.currentBundle.id;
      }
      ctrl.supplierBundles = ctrl.show_no_supplier ? [ctrl.NO_SUPPLIER] : [];
      ctrl.suppliers_accounted_for = {};

      // filling in for suppliers with no items
      _.forEach( Object.keys(ctrl.suppliers_map), (supplier_id) => {
        const bundle = _makeSupplierBundle(supplier_id);
        ctrl.supplierBundles.push(bundle);
      });

      if(previous_open_bundle){
        const bundle = _.find(ctrl.supplierBundles, ["id", previous_open_bundle]);
        if (bundle) {
          openBundle(bundle);
        }
      }
    }

    /**
     * Maps a supplier from the ctrl.suppliers_map to a bundle, to be used in the UI
     * 
     * @param supplier_id - The ID of the supplier we're making a bundle for
     * @returns An object with the following properties:
     *   ```
     *   can_delete: // Indicates if this supplier can be deleted.
     *   id: // The id of the supplier.
     *   loaded: // Indicates wether this supplier's items have been loaded from the API yet.
     *   name: // The name of the supplier.
     *   sales_rep: // A sales rep object mapped to this supplier
     *   supplier: // The supplier object itself.
     *   UI.name_and_account: // The name of the supplier, and the account number if it exists.
     *   ```
     */
    function _makeSupplierBundle (supplier_id) {
      const supplier = ctrl.suppliers_map[supplier_id];
      const sales_rep = _.find(ctrl.sales_reps, ['supplier.id', supplier_id]);

      // If we have a boolean value for this ID in the can_delete Object we'll use it to
      // determine whether to allow deletion, otherwise we enable deletion by default
      const can_delete = typeof ctrl.can_delete[supplier_id] === 'boolean' ? ctrl.can_delete[supplier_id] : true;
      let name_and_account = supplier.name;
      if (supplier.vendor_customer_number){
        name_and_account = `${supplier.name} (${t_account}: ${supplier.vendor_customer_number})`;
      }
      const bundle = {
        can_delete,
        id: supplier.id,
        loaded: false,
        name: supplier.name,
        sales_rep,
        supplier,
        UI: {
          name_and_account
        }
      };
      return bundle;
    }

    function editSupplier (ev, bundle) {
      ev.preventDefault();
      ev.stopPropagation();
      const supplier = bundle.supplier;
      $state.go('app.office.profile.edit-supplier', { id: supplier.id });
    }

    
    /**
     * It updates the suppliers map and supplier bundles with the new supplier data
     * @param result - The result of the update.
     * @param existing_rep - The existing sales rep object.
     */
    function _handleSupplierUpdate (result, existing_rep) {
      if (result.sales_rep) {
        if (existing_rep) {
          const index = _.findIndex(ctrl.sales_reps, ['id', existing_rep.id]);
          ctrl.sales_reps[index] = result.sales_rep;
        } else {
          ctrl.sales_reps.push(result.sales_rep);
        }
      }
      ctrl.suppliers_map[result.supplier.id] = result.supplier;
      SupplierService.getSupplierDeleteStatus([result.supplier.id])
      .then(res => {
        for (const key in res) {
          if (typeof res[key] === 'boolean') {
            ctrl.can_delete[key] = res[key];
          }
        }
      });

      const bundle_index = _.findIndex(ctrl.supplierBundles, ['id', result.supplier.id]);
      const supplier_bundle = _makeSupplierBundle(result.supplier.id);
      if (bundle_index === -1) {
        ctrl.supplierBundles.push(supplier_bundle);
      } else {
        ctrl.supplierBundles[bundle_index] = supplier_bundle;
      }

      _.forEach(supplier_bundle.items, function (item) {
        inventoryItemService.getItem(item.id, {'force': true});
      });
    }

    //
    // Action to delete a supplier
    //
    function deleteSupplier (event, supplier) {
      event.preventDefault();
      event.stopPropagation();

      return SupplierService.remove(supplier, event).then(function (wasDeleted) {
        if (wasDeleted) {
          removeLocalSupplier(supplier);
        }
      }).catch(function (error) {
        errorService.uiErrorHandler(error);
      });
    }

    function removeLocalSupplier (supplier) {
      _.remove(ctrl.supplierBundles, function(item) {
        return item.id === supplier.id;
      });
    }

    function clearCheckedItems () {
      _.map(ctrl.checkedItems, function(item){
        _.set(item, 'UI.checked', false);
      });
      ctrl.checkedItems = angular.copy([], ctrl.checkedItems);
    }

    function sortingFn (bundle) {
      return _.get(bundle, "supplier.name", "ZZZZZZZZZ");
    }

    function _refreshReps () {
      return SupplierService.getSalesReps()
      .then(function(reps_response){
        ctrl.sales_reps = reps_response;
      });
    }

    function _refreshSuppliers () {
      return SupplierService.getAll()
      .then(function(sups_response){
        ctrl.suppliers_map = sups_response;
      });
    }

    function getInventoryValue(items) {
      return _.reduce(items, function(sum, item) {
        var amount = inventoryItemService.calculateInventoryValue(item);
        return sum + amount;
      }, 0);
    }

    function searchFilter (item) {
      var filters = $scope.filters;

      function searchTextFilter (item) {
        return !filters.inventory_search || (item.name || '').toLowerCase().indexOf(filters.inventory_search.toLowerCase()) !== -1;
      }

      function minLevelFilter (item) {
        return !filters.below_min_level || (item.errors && item.errors.below_minimum_level);
      }

      function expiringFilter (item) {
        return !filters.expiring_soon || (item.errors && (item.errors.expiring_soon || item.errors.expired));
      }

      return searchTextFilter(item) && minLevelFilter(item) && expiringFilter(item) ? item : undefined;
    }

    function hasFilter () {
      var filters = $scope.filters || {};

      return (
        filters.inventory_search ||
        filters.search_text ||
        filters.below_min_level ||
        filters.expiring_soon
      );
    }

    //
    //
    //
    //
    //
    //

    function printItemQrCode (item) {
      return inventoryItemService.printLabelsForItems([item]);
    }


    function isCurrentBundle (bundle){
      return ctrl.currentBundle === bundle;
    }

    function openBundle (bundle) {
      if(!bundle.loaded){
        loadSupplierItems(bundle);
        // .then(function(bundle){
        //   if(_.size(bundle.items) < 1){
        //     return closeBundle(bundle);
        //   }
        // }); 
      }
      checkNavigate()
      .then(function(){
        $scope.currentItem = null;
        ctrl.currentBundle = bundle;
        bundle.force_refresh = true;
        $timeout(function(){
          smoothScroll.scrollToElementById('bundle-'+bundle.id, {offset : -100, speed: 0, onlyUp: false});
        },280); //280 ms to compensate for transition animation.
      });
    }

    function closeBundle (bundle) {
      checkNavigate()
      .then(function(){
        ctrl.currentBundle = "none";
        $scope.currentItem = null;
      });
    }

    function addItem (){
      $mdDialog.show({
        controller: 'addInventoryItemController',
        templateUrl: 'templates/marketplace/inventory/modals/add-inventory-item.html',
        parent: angular.element(document.body),
        locals:{
          productName: null
        },
        clickOutsideToClose:true
      });
    }

    function addToCart (item, clickEvent) {
      $scope.modifyingItem = true;
      return AddToCartDialog.show(item.product_id, clickEvent).finally(function () {
        $scope.modifyingItem = false;
      });
    }

    //
    // Toggle an item row open or closed.
    //
    function toggleItem (item, group) {
      checkNavigate(true)
      .then(function(){
        if ($scope.currentItem === item) {
          $scope.currentItem = null;
        } else {
          $scope.currentItem = item;
        }

        if ($scope.currentItem) {
          $scope.currentGroup = group;
        } else {
          $scope.currentGroup = null;
        }

        if($scope.currentItem && $scope.currentGroup){
          // Scroll to item.
          $timeout(function(){
            var ItemID = 'bundle-{0}-inventory-item-{1}'.format($scope.currentGroup.name, item.id);
            smoothScroll.scrollToElementById(ItemID, {offset : -100, speed: 12, onlyUp: false});
          }, 280); //280 ms to compensate for transition animation.
        }
      });
    }

    function viewItem (ev, item, edit) {
      $scope.filters.search_text = item.name;
      $scope.filters.inventory_search = $scope.filters.search_text;
      $scope.currentItem = item;
      $scope.currentGroup = $scope.overviewBundle;

      //Scroll to item.
      $timeout(function(){
        smoothScroll.scrollToElementById('bundle-' + $scope.currentGroup.name + '-inventory-item-'+item.id, {offset : -100});
      });
    }

    function editItem (ev, item) {
      $scope.viewItem(ev, item, true);
    }

    function removeItem (item) {
      $scope.modifyingItem = true;

      function _success () {
        $scope.modifyingItem = false;
        var t_message = $filter('translate')('TOAST.ITEM_REMOVED');
        sgToast.showSimple(t_message);
        $mdDialog.hide();
        removeLocalItem(item);
      }

      function _error (error) {
        $scope.modifyingItem = false;
        var t_message = $filter('translate')('ERRORS.ITEM_REMOVE')
        errorService.uiErrorHandler(error || t_message, 0);
      }

      elementService
        .remove('inventoryItem', item, true)
        .then(function (inventory_item) {
          _success();
        }).catch(function (error) {
          var poError = _.get(error, 'purchase_order_error');

          if (poError) {
            var drafts = _.filter(poError.purchase_orders, {'status': 'Draft'});
            var candidate = angular.extend(angular.copy(item), poError);

            if (drafts.length === poError.purchase_orders.length) {
              return DeleteInventoryDraftPODialog
                .show([candidate])
                .then(_success, _error);
            } else {
              return DeleteInventoryActivePODialog
                .show([candidate])
                .then(function () {
                  $scope.modifyingItem = false;
                  var t_message = $filter('translate')('TOAST.COULD_NOT_REMOVE_ITEM');
                  sgToast.showSimple(t_message);
                  $mdDialog.hide();
                  _success();
                });
            }
          } else {
            _error(error);
          }
        });
    }

    function removeLocalItem (item) {
      var item_bundle = _.find(ctrl.supplierBundles, function(_bundle){
        return _bundle.id === _.get(item, 'supplier.id', _.get(item, 'supplier_id'));
      });
      if(item_bundle){
        _.remove(item_bundle.items, function(_item){

          return _item.id === item.id;
        });
      }
    }

    //
    // Mark an inventory item as "checked"
    //
    function checkItem (item) {
      _.set(item, "UI.checked", !_.get(item, "UI.checked", false) );
      // item.UI.checked = !item.UI.checked;
      if(item.UI.checked){
        ctrl.checkedItems.push(item);
      }else{
        _.map(ctrl.checkedItems, function(checked_item, i){
          if(checked_item.id === item.id){
            ctrl.checkedItems.splice(i, 1);
          }
        });
      }
    }

    function hideHiddenInventoryBanner () {
      return sessionService.updateFlag('prompted_for_premium_in_inventory_with_banner', true);
    }

    //
    // Expose function to be used by inner controllers.
    //
    function registerItem (item, saveFn) {
      registeredItem = {item: item, saveFn: saveFn};
    }

    //
    // Check if an item has been registered (aka being edited). If so, show
    // redirect modal and save if accepted.
    //
    function checkNavigate (rejectOnSave) {
      if(!!registeredItem){
        var form = angular.element("form[name=inventoryItemForm]");
        if(form && form.length && form.scope().inventoryItemForm && form.scope().inventoryItemForm.$dirty){
          return RedirectModalService.show(null, 'SaveChanges')
          .then(function(){
            var saveFn = registeredItem.saveFn;
            registeredItem = null; //Clear to avoid infinite loop
            saveFn();
            if(rejectOnSave){
              return $q.reject(); //Avoid double toggle
            }
          }).catch(function(){
            return $q.resolve();
          });
        }else{
          return $q.resolve();
        }
      }else{
        return $q.resolve();
      }
    }

    /**
     * @desc Open dialog to add product in step 2 and product name filled with search text
     *
     * @param {*} name product name
     */

    function addItemDialog (name) {

      $mdDialog.show({
        controller: 'addInventoryItemController',
        templateUrl: 'templates/marketplace/inventory/modals/add-inventory-item.html',
        parent: angular.element(document.body),
        clickOutsideToClose:true,
        bindToController: true,
        locals:{
          productName: name
        }
      })
      .then(function(newItem){
        if(newItem && newItem.id){
          $state.go('app.inventory.all',{'itemId': newItem.id});
        }
      });
    }

    function defineLocks () {
      const membership = membershipService.get();

      const add_condition = _.get(membership, "user_properties.suppliers.disable_add_new", false);
      ctrl.disabled_add_new = add_condition;
      const edit_condition = _.get(membership, "user_properties.suppliers.disable_edit", false);
      ctrl.disabled_edit = edit_condition;
      const delete_condition = _.get(membership, "user_properties.suppliers.disable_delete", false);
      ctrl.disabled_delete = delete_condition;
      const show_no_supplier_condition = _.get(membership, "user_properties.suppliers.show_no_supplier", false);
      ctrl.show_no_supplier = show_no_supplier_condition;
    }
  }
}());

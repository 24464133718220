(function () {
  'use strict';

  const pdSaleInfo = {
    selector: 'pdSaleInfo',
    templateUrl: 'sow-product-details/directives/pd-sale-info.html',
    controller: 'pdSaleInfoCtrl',
    controllerAs: 'pdSICtrl',
    bindings: {
      vendor: '<',
    },
  }

  /** @ngInject */
  class pdSaleInfoCtrl {
    constructor(productDetailsService) {
      this.productDetailsService = productDetailsService;
    }

    getPriceText() {
      return this.productDetailsService.getPriceText(this.vendor);
    }
  }

  angular.module('sowOrders')
    .controller(pdSaleInfo.controller, pdSaleInfoCtrl)
    .component(pdSaleInfo.selector, pdSaleInfo);

})();

{
  class msHelperService {
    constructor() {}

    /**
     * It takes a dictionary of parameters and an action reference, and returns a dictionary of kwargs
     * @param params - the parameters that were passed to the action
     * @param action_reference - This is the reference to the action that we're calling. It's an object
     * that looks like this:
     * ```json
     * {
     *   endpoint: /vendor_integrations/v1/{vendor}/stock_status
     *   method: GET
     *   kwargs: {
     *     vendor_skus: {
     *       default: null,
     *       required: true,
     *       type: 'list[str]',
     *     },
     *     office_id: {
     *       default: null,
     *       required: true,
     *       type: 'str',
     *     },
     *   }
     * }
     * ```
     * @returns An object with the key/value pairs of the params object, but with the values
     * transformed according to the action_reference object.
     */
    paramsToKwargs (params, action_reference) {
      if (!action_reference) return params;
      const results = {};
      const action_obj = action_reference.method === 'GET' ? action_reference.kwargs : action_reference.json;
      const all_keys = _.uniq([..._.keys(params), ..._.keys(action_obj)]);

      _.each(all_keys, (key) => {
        let initial_value = params[key];
        const ref = action_obj[key];

        if (ref) {
          // if there's any kwarg with a default value that we have not sent, 
          // we'll add it here
          if (ref.default && _.isNil(initial_value)) {
            initial_value = results[key] = ref.default;
          }
          // if the initial value is undefined even after overwriting with the default,
          // we'll throw an error if the field is required
          // otherwise this would end up in a network error
          if (ref.required && _.isNil(initial_value)) {
            throw new Error(`Parameter '${key}' is required for '${action_reference.endpoint}'`);
          }
          results[key] = this._getKwargValue(initial_value, ref, action_reference.method);
        } else {
          // if there's no reference from the action, just pass the value as is
          // (this would be the case of something we added afterwards and is not expected by the endpoint)
          results[key] = initial_value;
        }
      });

      return results;
    }

    /**
     * It takes a string, finds the first instance of a bracketed value, and returns that value
     * eg: with an input of "list[str]", the return would be "str"
     * @param str - The string to be parsed.
     * @returns The value inside the brackets.
     */
    _getBracketValue (str) {
      const regex = /\[(.*?)\]/;
      const matches = str.match(regex);
      return matches[1];
    }

    /**
     * It takes a value and a reference to a kwarg, 
     * and returns the value in the correct format for the kwarg (in python type notation)
     * eg: if the kwarg is a list of strings, and the value is an array of numbers,
     * it will return a string with the numbers separated by commas
     * 
     * This function is recursive, so it can handle nested lists
     * 
     * @see https://docs.python.org/3/library/typing.html
     * @param value - the value of the argument
     * @param ref - the reference object for the kwarg
     * @returns The value of the kwarg_value variable.
     */
    _getKwargValue (value, ref, method) {
      let result;
      const date_format = 'YYYY-MM-DD';
      const datetime_format = 'YYYY-MM-DDTHH:mm:ss';
      const time_format = 'HH:mm:ss';
      const separator = ',';

      // Return early to avoid converting the value
      if (_.isNil(value)) {
        return undefined;
      }
      
      switch(true) {
        case /^list\[.*\]?$/.test(ref.type):
          // for something like list[str], we'll run the same function recursively
          // for each item in the list
          const list_type = this._getBracketValue(ref.type);
          const value_list = _.map(value, (item) => {
            return this._getKwargValue(item, {type: list_type}, method);
          });
          // [123, 456.47, '789'] -> "123, 456.47, 789"
          if (method === 'GET') {
            result = _.join(value_list, separator);
          } else {
            result = value_list;
          }
          break;
        case /^str$/.test(ref.type):
          result = String(value);
          break;
        case /^int$/.test(ref.type):
          result = parseInt(value);
          break;
        case /^float$/.test(ref.type):
          result = parseFloat(value);
          break;
        case /^bool$/.test(ref.type):
          result = Boolean(value);
          break;
        case /^dict$/.test(ref.type):
          result = JSON.stringify(value);
          break;
        case /^date$/.test(ref.type):
          result = moment(value).format(date_format);
          break;
        case /^datetime$/.test(ref.type):
          result = moment(value).format(datetime_format);
          break;
        case /^time$/.test(ref.type):
          result = moment(value).format(time_format);
          break;
        default:
          result = value;
      }
      
      // some arguments have a default value, so we'll use that if the value is undefined	
      if (_.isNil(result) && ref.default) {
        // if for some reason the default value is out of standard, we'll conform it
        result = this._getKwargValue(ref.default, ref, method);
      }
      
      return result;
    }

    /**
     * > We have a list of fields that we want to use to get the value from a data_object. We return the
     * first one that is not null
     * @param {object} data_object - the object that contains the data
     * @return {unknown} The first available option from the data_object.
     */
    getDataValue (data_object) {
      if (!data_object) return null;
      const DATA_FIELD = Object.freeze({
        "VALUE": "value",
        "STRING": "text_string",
        "HTML": "text_html"
      });
      const ENUM_TYPE = Object.freeze({
        "vendor_sku.Price": [DATA_FIELD.VALUE],
        "vendor_sku.Stock": [DATA_FIELD.HTML, DATA_FIELD.STRING, DATA_FIELD.VALUE],
      });

      // this is a fallback strategy in case the preferred field is not available
      const options = ENUM_TYPE[data_object.enum_type];
      // we get the first one that meets criteria (not null)
      const first_available_option = _.find(options, (option) => {
        return !_.isNil(data_object[option]);
      });
      // and return that from the data_object
      const value = data_object[first_available_option] || data_object.value;
      const result = this._getKwargValue(value, {type: data_object.value_type});
      if (_.isNil(result)) {
        return null;
      }
      return result;
    }

  }

  angular
  .module('sowMicroservices', [])
  .service('msHelperService', msHelperService);  
}

(function () {
  angular.module('sowMarketplace')
  .controller('PendingOrderController', PendingOrderController);

  function PendingOrderController ($state, $stateParams, sowApprovalService, mktHelperService) {
    const ctrl = this;

    ctrl.$onInit = init;
    ctrl.goToRequestPage = goToRequestPage;

    return ctrl;

    function init () {
      if($stateParams.request_id) {
        loadRequest($stateParams.request_id);
      } 
    }


    function loadRequest (request_id) {
      return sowApprovalService.getRequest(request_id)
      .then((request_response) => {
        ctrl.approval_request = request_response;

        setOrders();
      });
    }

    function goToRequestPage (request_id, approval_request) {
      return $state.go('app.approvals.request', {request_id, approval_request});
    }

    function setOrders () {
      ctrl.all_orders = [
        mktHelperService.parsePending(ctrl.approval_request.reference, ctrl.approval_request)
      ];
    }

  }
})();

angular.module("app.marketplace.elements")
.service("exchangeService", function($rootScope, $q, elementService, exchangeModel) { 
  var _this = this;
  _this.exchanges = null;
  _this.initialized = false;

  var _setExchanges = function(exchanges){
    _this.exchanges = exchanges;
    $rootScope.$broadcast('exchangesService: set-exchanges', exchanges);
    _this.initialized = true;
  };
  var _clearExchanges = function(){
    _this.exchanges = null;
    $rootScope.$broadcast('exchangesService: clear-exchanges');
    _this.initialized = true;
  };
  //Convience function for waiting for initialized
  var _waitForInitialized = function(){
    if(_this.initialized){
      return $q.resolve(_this.exchanges);
    }else{
      if(!_this._initCalled){
        _this._init();
      }
      var deferred = $q.defer();
      var initWatch = $rootScope.$watch(function(){ 
        return _this.initialized; 
      }, function(newInitializedValue, oldInitializedValue){
        if(newInitializedValue){
          deferred.resolve(_this.exchanges);
          initWatch(); //Stop watch;
        }
      });
      return deferred.promise;
    }
  };
  
  var _getExchanges = function(waitForInitialized){
    if(waitForInitialized){
      return _waitForInitialized()
      .then(function(exchanges){
        if(exchanges){
          return exchanges;
        }else if($rootScope.current_account){
          return $q.reject("No exchanges");
        }else{
          return $q.reject("Not logged in");
        }
      });
    }else{
      return  _this.exchanges;
    }
  };

  var _refresh = function(){
    if($rootScope.current_office){
      _this.initialized = false;
      angular.copy({}, elementService.elementMaps.exchange); //Clear previous items
      return elementService.getElements('exchange', {'forceAPI' : true})
      .then(function(exchanges){
        _setExchanges(exchanges);
        return exchanges;
      });
    }else{
      _clearExchanges();
      return $q.reject("No office");
    }
  };
  _this._init = function(){
    _this._initCalled = true;
    if($rootScope.current_office){
      _refresh();
    }
    $rootScope.$on('officeService: set-office', function(event, office){
      _refresh();
    });
    $rootScope.$on('officeService: clear-office', function(){
      _clearExchanges();
    });
  };
  
  return {
    init : _this._init,
    get : _getExchanges,
    refresh : _refresh
    
  };
});

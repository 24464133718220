(function(){
  'use strict';
  angular.module("app.marketplace.ui.purchaseorders")
    .directive('actionBarPoActive',poActiveActionBarDirective);

  function poActiveActionBarDirective() {
    return {
      restrict: 'AC',
      templateUrl: 'templates/marketplace/purchase-orders/directives/action-bar-po-active.html',
      controller: 'poActionBarController',
      controllerAs: 'poAbCtrl'
    };
  }

}());
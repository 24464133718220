angular.module("app.shared.support")
  // For percentage input fields (first demand was purchase order tax)
  .directive('ngPercentage', function ngPercentage($filter) {
    return {
      require: 'ngModel',
      link: function ($scope, $element, $attrs, ngModel) {

        // force render the model with updated value
        function reformatViewValue() {
          var formatters = ngModel.$formatters;
          var viewValue = ngModel.$$rawModelValue;

          for (var idx = formatters.length - 1; idx >= 0; --idx) {
            viewValue = formatters[idx](viewValue);
          }

          ngModel.$setViewValue(viewValue);
          ngModel.$render();
        }

        // check if the model has a valid percentage value (decimal or integer)
        function _validatePercentage(modelValue) {
          var isValid = !!(
            !modelValue ||
            (angular.isNumber(modelValue) && isFinite(modelValue)) ||
            (angular.isString(modelValue) && modelValue.match(/^\d+(\.\d+)?$|^\d*\.\d+$/))
          );

          return isValid;
        }

        // remove percentage sign and whitespaces
        function _parsePercentage(inputValue) {
          return inputValue.replace(/\s*\%$/, '').replace(/\s*$/, '');
        }

        // format model to decimal value with percentage sign
        function _formatPercentage(modelValue) {
          modelValue = modelValue || 0;
          return _validatePercentage(modelValue) ? $filter('number')(modelValue, 2) + "%" : modelValue;
        }

        // initialize ngModel pipelines
        ngModel.$validators.percentage = _validatePercentage;
        ngModel.$parsers.unshift(_parsePercentage);
        ngModel.$formatters.unshift(_formatPercentage);

        // reformat when user leaves input field
        $element.on('blur', function () {
          ngModel.$commitViewValue();
          reformatViewValue();
        });

      }
    };
  });
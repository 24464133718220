(function () {
  'use strict';

  angular.module('app.marketplace.ui.office')
         .directive('officeInfoForm', officeInfoFormDirective);

  function officeInfoFormDirective () {
    return {
      'restrict': 'E',
      'replace': true,
      'controller': officeInfoFormCtrl,
      'templateUrl': 'templates/marketplace/office/office-info-form.html',
      'scope': {
        'office': '=',
        'medicalField': '=',
        '$form': '=formCtrl',
        'btnPrefix': '@',
        'condense': '@',
      }
    };
  }

  function officeInfoFormCtrl ($scope) {
    $scope.$watch('condense', function () {
      $scope.hideEmailRow = $scope.$parent.$eval($scope.condense);
    });

    $scope.btnPrefix = $scope.btnPrefix || 'officeInfoForm';
  }

}());

angular
    .module("app.marketplace.elements")

    /*
     * Service for accessing, maintaining, and updating element types and
     * instances.
     * 
     * TODO assess if/can this service be moved to shared
     * 
     */
    .service(
        "elementService",
            function(onlineUtils, serverAPI, ElementModelMap, $injector, $rootScope, $q, $timeout, apiUrl, searchUrl, elasticUtils) {

              // Element model/class vars and initialization
              var _elementNameMap = {};
              _.forEach(ElementModelMap, function (modelService, modelName) {
                _elementNameMap[modelName] = $injector.get(modelService);
              });

              var _elementVCount = {}; // Vid count tracking for all elements
              var _elementMaps = {};// Instance tracking
              var _elementTypeList = [];
              var _elements = null;
              _.map(_elementNameMap, function(this_map, i){
                _elementVCount[i] = 1;
                _elementMaps[i] = {};
                _elementTypeList.push(i);
              });

              var _compareUpdate, _validateFields, _createInstance, _submit, _updateInstance, _validateElement = null;

              /*
               * Get model service for element type
               */
              var _getModel = function(elementName) {
                if (!elementName || !_elementNameMap[elementName]) {
                  return false;
                }
                return _elementNameMap[elementName];
              };

              /*
               * Create new instance of element type
               */
              var _create = function(elementType, options) {
                if (!options) {
                  options = {};
                }

                var elementService = _getModel(elementType);

                var newElement = $.extend(true, options || {}, elementService.data);
                _setGenMethods(elementService, newElement); // Set generic methods for

                newElement.vID = _elementVCount[elementService.model_data.name]++;
                var trackID = newElement.id || "v" + newElement.vID;

                // Save UI vars
                var trackUI = _elementMaps[elementService.model_data.name][trackID] && _elementMaps[elementService.model_data.name][trackID].UI ? _elementMaps[elementService.model_data.name][trackID].UI
                    : {};
                newElement.UI = trackUI;

                // Call autofill fn if model/class has one.
                if (elementService.autofill) {
                  elementService.autofill(newElement);
                }

                _validateElement(newElement);
                


                // Update tracking map
                if(_elementMaps[elementService.model_data.name][trackID] && newElement!==_elementMaps[elementService.model_data.name][trackID]){
                  //Copy without loosing reference.
                  angular.copy(newElement, _elementMaps[elementService.model_data.name][trackID]);
                }else{
                  _elementMaps[elementService.model_data.name][trackID] = newElement;
                }

                return _elementMaps[elementService.model_data.name][trackID];
              };
              var _createElements = function(elements, elementService) {
                if (elements && elements.length && elementService) {
                  _.map(elements, function(element){
                    element = _create(elementService.model_data.name, element);
                  })
                }
                return elements;
              };
              //Used to update/create element instances after an endpoint was called.
              var updateElements = function(elementService, elements){
                if (!elements) {
                  return;
                }
                var elementList = $.isArray(elements) ? elements : [elements];

                var allElementInsMap = {}; // Map of new _elements
                _.map(elementList, function(currElementIns){
                  if(currElementIns) {
                    currElementIns.b_data = $.extend(true, {}, currElementIns);
                    // Create element while keeping untouched b-data
                    currElementIns = _create(elementService.model_data.name, currElementIns);
                    allElementInsMap[currElementIns.id] = currElementIns;
                  }
                });

                return allElementInsMap;
              };

              // Sets generic methods for instance that every instance should
              // have.
              var _setGenMethods = function(elementService, elementIns) {

                elementIns.get = function(name) {
                  if (!elementService.fields[name]) {
                    throw "Field " + name + " does not exist in model.";
                  }
                  _validateInitField(elementService, elementIns, name);
                  return elementIns[name];
                };
                elementIns.set = function(name, value) {
                  if (!elementService.fields[name]) {
                    throw "Field " + name + " does not exist in model.";
                  }
                  if (elementService.fields[name].type === "object" || elementService.fields[name].type === "array") {
                    if (value) {
                      // Copies values while maintaining reference links to elementIns[name]
                      angular.copy(value, elementIns[name]);
                    }
                  } else {
                    elementIns[name] = value;
                  }
                };
                elementIns.getFieldParams = function(name) {
                  if (!elementService.fields[name]) {
                    throw "Field " + name + " does not exist in " + elementService.model_data.name + " model.";
                  }
                  return elementService.fields[name];
                };
              };
              // Helps initialized and check fields
              var _validateInitField = function(elementService, elementIns, name) {
                var fieldType = elementService.fields[name].type;
                if (!elementIns[name]) {
                  if (fieldType === "object") {
                    elementIns[name] = {};
                  }
                  if (fieldType === "array") {
                    elementIns[name] = [];
                  }

                  if (elementService.fields[name].default_value) {
                    if (fieldType === "object" || fieldType === "array") {
                      elementIns[name] = $.extend(true, elementIns[name], elementService.fields[name].default_value);
                    }
                    // May not want to store date object in the field
                    // else if (fieldType === "date" || fieldType ===
                    // "duration") {
                    // elementIns[name] = new Date(elementIns[name]);
                    // }
                    else {
                      elementIns[name] = elementService.fields[name].default_value;
                    }
                  }
                }
              };
              //Check session related variables and set related variables
              _validateElement = function(element){
                if(!element){
                  return false;
                }
                return true;
              };

              /*
               * Update backend data object
               * 
               * options.fields used to only update certain fields
               */
              var _updateBdata = function(elementIns, elementType, options) {
                if (!elementIns || !elementIns || !elementType) {
                  return false;
                }
                var _updateFields = function(fieldObj, currBdata, bDataUpdate, fields){
                  _.map(fieldObj, function(field, field_name){
                    var currFieldParams = fields[field_name];

                    if (currFieldParams && currFieldParams.api && currFieldParams.api.submit && (!fields || currFieldParams)) {
                      if(currFieldParams.fields && field){
                        //If field params has a .fields attribute then call function again (recursively!)
                        currBdata[field_name] = currBdata[field_name] || {};
                        bDataUpdate[field_name] = bDataUpdate[field_name] || {};
                        _updateFields(field, currBdata[field_name], bDataUpdate[field_name], currFieldParams.fields);
                      }else{
                        currBdata[field_name] = field;
                        bDataUpdate[field_name] = field;
                      }
                    }
                  });
                };

                var elementService = _getModel(elementType);
                var fields = elementService.fields;

                var currBdata = elementIns.b_data || {};
                var bDataUpdate = {};
                // Get all fields that should be submitted to the api (have
                // api.submit==true)
                _updateFields(elementIns, currBdata, bDataUpdate, fields);
                bDataUpdate.image_src = elementIns.image_src;
                bDataUpdate.sds_url = elementIns.sds_url;
                return bDataUpdate;
              };

              // -----------------------------------------------------------------------------------

              /*
               * General method for calling model endpoints
               * 
               * TODO add acceptable list of path types
               */
              var _callEndpoint = function(elementType, options) {
                if (!elementType || !options || !options.endpoint ) {
                  throw "Missing field or option for _callEndpoint.";
                }

                var elementService = _getModel(elementType);
                if (!elementService.model_data.api || !elementService.model_data.api[options.endpoint]) {
                  throw "Path type, " + options.endpoint + ", does not exist for " + elementType;
                }

                var path = elementService.model_data.api[options.endpoint](options);
                if (path === false) {
                  // Assumed error was thrown by api endpoint fn.
                  return false;
                }if(!angular.isObject(path)){
                  path = {"path":path,"method":"GET"};
                }

                var params = {};
                if(path.params){
                  $.extend(true, params,path.params);
                }
                $.extend(true, params,options.params);

                return serverAPI.doAPICall(path.path, params).then(function(response){
                  if(options.doUpdate){
                    //If path from model indicates that the endpoint is returning element data,
                    // then update the current element instance in the global map.
                    var elements = null;
                    if(path.type==="elastic"){
                      elements = elasticUtils.convertResponse(response);
                    }else{
                      elements = response.status && response.config ? response.data : response;
                    }
                    
                    var allElementInsMap = updateElements(elementService, elements);
                    return  options.return_type==='single' && elements ? allElementInsMap[elements.id] : allElementInsMap;
                  }else{
                    return response;
                  }
                });
              };
              /*
               * Method for retrieving _elements from db and local
               * storage
               * 
               * Params:
               * - elementType : name of model to call endpoint from
               * - id (optional) : id of instance to retrieve
               * - options (optional if id is provided) : additional params
               *      - endpoint : name of the endpoint to use (if "id" is provided then defaults to "single")
               *      - forceAPI : if "true" then endpont is always called. if "false" then in memory instance is returned if present
               *      - return_type : "single" or "multiple". Dictates how to work with the returned data
               *      - clearMap : if true then clears the global map before initializing the returned elements.
               * 
               * TODO add acceptable list of path types
               */
              var _get = function _get(elementType, id, options) {
                if (!elementType || (!id && (!options || !options.endpoint || (options.endpoint === "single" && !options.id)))) {
                  throw "Missing field or option for api get.";
                }

                // If single instance is requested, there is no force, and it
                // exists in memory, then provide the instance in memory.
                if (id && (!options || !options.forceAPI) && _elementMaps[elementType][id]) {
                  return $q(function(resolve, reject) {
                    resolve(_elementMaps[elementType][id]);
                  });
                } else if (id) {
                  // If id is provided, and force is applied or does not exist
                  // in memory, then setup options to do an api call.
                  if (!options) {
                    options = {"data":{}};
                  }else if(!options.data) {
                    options.data = {};
                  }
                  if(!options.endpoint){
                    options.endpoint = "single";
                  }
                  options.data.id = id;
                }

                var elementService = _getModel(elementType);
                if (!elementService.model_data.api || !elementService.model_data.api[options.endpoint]) {
                  throw "Path type, " + options.endpoint + ", does not exist for " + elementType;
                }

                var path = elementService.model_data.api[options.endpoint](options);
                if (path === false) {
                  // Assumed error was thrown by api endpoint fn.
                  return false;
                }

                if (!angular.isObject(path)) {
                  path = {path: path,
                          params: {method: 'GET'}};
                }

                var params = {};
                if(path.params){
                  $.extend(true, params, path.params);
                }
                $.extend(true, params, options.params);

                return serverAPI.doAPICall(path.path, params).then(function(response) {
                  if (!response || response.success === false || response.success === 'failed') {
                    throw 'Path ' + path.path + ' returned a failure response.';
                  }

                  var elements = null;
                  if (path.type === "elastic") {
                    elements = elasticUtils.convertResponse(response);
                  } else {
                    elements = response.status && response.config ? response.data : response;
                  }
                  
                  //Clear global map if specified.
                  if(options.clearMap){
                    angular.copy({}, _elementMaps[elementType]); 
                  }

                  var allElementInsMap = updateElements(elementService, elements);
                  return id ? allElementInsMap[id] 
                      : options.return_type==='single' && elements ? allElementInsMap[elements.id]  
                      : allElementInsMap;
                });
              };

              //Convience function for a "multiple" get
              var _getElements = function(elementType, options) {
                if (!elementType || !_getModel(elementType)) {
                  throw "_getElements: Missing elementType.";
                }
                var elementService = _getModel(elementType);

                if (!options) {
                  options = {};
                }
                if (!options.endpoint) {
                  options.endpoint = "multiple";
                }
                
                if((!options || !options.forceAPI) && !angular.equals({}, _elementMaps[elementService.model_data.name])){
                  return $q.resolve(_elementMaps[elementService.model_data.name]);
                }else{
                  return _get(elementType, null, options);
                }
              };
              /*
               * General method for posting new/modified _elements to api
               */
              _submit = function(elementType, options) {
            
                if (!elementType || !options || !options.endpoint || (options.endpoint === "single" && !options.element)) {
                  throw "_submit: Missing field or option.";
                }

                var elementService = _getModel(elementType);
                if (!elementService.model_data.api || !elementService.model_data.api[options.endpoint]) {
                  throw "Path type, " + options.endpoint + ", does not exist for " + elementType;
                }

                var postElements = [];
                if (!options.elements && options.element) {
                  postElements.push(options.element);
                } else if (options.elements) {
                  postElements = options.elements;
                }

                // Separate fn required to avoid pointer issues.
                var serverSubmitElement = function(path, currOptions, currElementIns) {
                  return serverAPI.doAPICall(path, currOptions).then(function(response) {
                    if (!response || response.success === false || response.success === "failed") {
                      throw "Path " + path + " returned a failure response.";
                    }

                    var newElementData = response.data ? response.data : response;
                    if (!newElementData) {
                      return;
                    }

                    var currReElementIns = _updateInstance(elementService, currElementIns, newElementData, currOptions.update);

                    // update _elementMaps. Should be unnecessary with currReElementIns
                    _elementMaps[elementType][currReElementIns.id] = currReElementIns;
                    
                    return currReElementIns;
                  });
                };

                var reElementIns = [];
                var allCalls = [];

                var currElementIns = null;
                var currOptions = null;
                // Go through element instances and update if necessary.
                _.map(postElements, function(currElementIns){
                  currOptions = {
                      isAsync : true
                  };
                  var bDataUpdate = _updateBdata(currElementIns, elementType, options);
                  currOptions = $.extend(currOptions, bDataUpdate);
                  //Get path, params and data to send via model endpoint 
                  var path = elementService.model_data.api[options.endpoint](currOptions);
                  if (path === false) {
                    // Assumed error was thrown by api endpoint fn.
                    return false;
                  }if(!angular.isObject(path)){
                    //If path is a string then repalce with an object structure and assume method is POST.
                    path = {"path":path, "params" : {"method":"POST"}}; 
                  }

                  //Combine param objects
                  var params = {};
                  if(path.params){
                    $.extend(true, params,path.params);
                  }
                  $.extend(true, params,currOptions.params);

                  // Would do comparison here to see if update is necessary.
                  allCalls.push(serverSubmitElement(path.path, params, currElementIns));
                });

                if (allCalls && allCalls.length > 0) {
                  return $q.all(allCalls).then(function(arrayOfResults) {
                    if (arrayOfResults && arrayOfResults.length === 1) {
                      return arrayOfResults[0];
                    } else {
                      return arrayOfResults;
                    }
                  });
                } else {
                  return true;
                }
              };

              // Validate instance's fields against their parameters (correct
              // type etc.)
              // TODO
              _validateFields = function(elementType, currElement, options) {
                if (!options) {
                  options = {};
                }
                if (!elementType || !currElement) {
                  throw "_validateFields: Missing type or instance.";
                }
                var elementService = _getModel(elementType);

                var valResult = null;
                var valResults = [];
                _.map(elementService.fields, function(currField, field_name){
                  // valResult = validateSrv.validateField(currElement[field_name], fieldParams[field_name]);
                  valResult = {
                    'pass': true
                  };
                  if (!valResult || !valResult.pass) {
                    valResults.push(valResult);
                  }
                });

                if (valResults.length > 0) {
                  return valResults[0];
                  // TODO
                } else {
                  return {
                    "pass" : true
                  };
                }
              };
              
              //Update element object without loosing fields or references.
              _updateInstance = function(elementService, element, newData, updateType) {
                updateType = updateType || 'partial';//Default update type is partial.
                element = element.id ? _elementMaps[elementService.model_data.name][element.id] : element; //Compensate for editing-clones.
                if(updateType === 'partial'){
                  //Add new data to existing data
                  element.b_data = $.extend(true, element.b_data || {}, newData);
                  element = $.extend(true, element || {}, newData);
                }else if(updateType === 'full'){
                  //Replace existing data with new data
                  
                  //Remove temp instance from global map.
                  if(element && !element.id){
                    _remove(elementService.model_data.name, element, false);
                  }
                  
                  element = _create(elementService.model_data.name, newData);
                  element.b_data = angular.copy($.extend(true, {}, newData), element.b_data || {});
                }
                if (elementService.autofill) {
                  elementService.autofill(element);
                }
                return element;
              };
              
              // Remove instance from global map. Also remove from backend if
              // apiRemove==true
              var _remove = function(elementType, currElement, apiRemove) {
                var elementService = _getModel(elementType);
                if (!elementType || !currElement || !elementService) {
                  throw "_remove: Missing type, model, or instance.";
                }
                if (apiRemove && (!elementService.model_data.api || !elementService.model_data.api.remove)) {
                  throw "_remove: Missing remove api path type.";
                }

                if (apiRemove && currElement.id) {
                  var params = elementService.model_data.api.remove(currElement);
                  if (params === false) {
                    // Assumed error was thrown by api endpoint fn.
                    return false;
                  }
                  return serverAPI.doAPICall(params.path, params.params).then(function(response) {
                    if (!response || response.success === false || response.success === "failed") {
                      throw "Path " + path + " returned a failure response.";
                    }
                    delete _elementMaps[elementType][currElement.id];
                  });
                } else {
                  delete _elementMaps[elementType][(currElement.id || ("v"+currElement.vID))];
                }
                return $q.resolve(true);
              };
              //Convenience function for clearing elements of a certain type from local memory.
              var _removeAll = function(elementType){
                var elementService = _getModel(elementType);
                _.map(_elementMaps[elementService.model_data.name], function(this_map){
                  _remove(elementType,this_map);
                });
              };

              // ---------------------------------------

              // Init universal methods for element models
              var _initModelMethods = function() {
                
              };
              // Would put various listeners here. (Like one for the web socket)
              var _initListeners = function() {
              };
              var _initRootMethods = function() {
                $rootScope.get = _get;
              };
              var _initService = function() {
                _initModelMethods();
                _initRootMethods();
                _initListeners();
                $rootScope._elementMaps = _elementMaps; //Adding element maps to the root scope to avoid circular dependencies.
              };
              _initService();

              /*
               * Public methods
               */
              return {

                create : _create,
                remove : _remove,
                removeAll : _removeAll,
                
                //Convience function for creating multiple instances at a time.
                createMultiple : function(elementType, elements){
                  return _createElements(elements, _getModel(elementType));
                },

                /*
                 * Validate element fields for db submission
                 */
                validateFields : _validateFields,
                validateElement : _validateElement,

                get : _get, //Element(s) Retrieval method 
                getElements : _getElements, //Get all elements of a type. (Convience funciton)
                submit : _submit, //Element(s) update method
                callEndpoint : _callEndpoint, //General method for calling a custom endpoint.

                elementNameMap : _elementNameMap,
                elementTypeList : _elementTypeList,
                elementMaps : _elementMaps,
                elements : _elements

              };

            });

(function () {
  'use strict';
  
  angular
  .module('sowReports')
  .controller('ImplantsPlacedListController', ImplantsPlacedListController);
  
  function ImplantsPlacedListController($stateParams, sgToast, $state, $filter, sowImplantService, sowImplantPlacementService, $mdDialog, errorService, orderByFilter, $rootScope, appEvents, membershipService) {
    /*jshint validthis:true*/
    var ctrl = this;
    
    ctrl.indexSort = 0;
    ctrl.propertyName = 'new_date';
    ctrl.reverse = true;
    ctrl.isConsignment = 'all';
    
    ctrl.date = {
      startDate: moment().startOf('year'),
      endDate: moment().endOf('day'),
    };
    
    ctrl.searchProductType = 'reference_no';
    ctrl.placehodlderText = $filter('translate')('COMMON.REF')+'#';
    ctrl.productTypeSearch = [
      {
        name: $filter('translate')('COMMON.REF')+'#',
        value: 'reference_no',
        placeHolder: $filter('translate')('COMMON.REF')+'#'
      },
      {
        name: $filter('translate')('COMMON.LOT_NUMBER'),
        value: 'lot_number',
        placeHolder: $filter('translate')('COMMON.LOT_NUMBER')
      },
      {
        name: $filter('translate')('REPORTS.PLACEMENT.PATIENT_NAME'),
        value: 'placement_id',
        placeHolder: $filter('translate')('REPORTS.PLACEMENT.PATIENT_NAME')
      },
      {
        name: $filter('translate')('COMMON.REF_DOC'),
        value: 'referred_by',
        placeHolder: $filter('translate')('COMMON.REF_DOC')
      },
      {
        name: $filter('translate')('REPORTS.PLACEMENT.SURGEON_NAME'),
        value: 'surgeon',
        placeHolder: $filter('translate')('REPORTS.PLACEMENT.SURGEON_NAME')
      }
    ];
    
    ctrl.sets = {
      'Implant': {
        color: "#398ee3",
        set_name: 'implants',
        display_name: $filter('translate')('IMPLANTS.IMPLANTS'),
        category: 'Implant',
        placements: [],
        manufacturer: [],
        img: {
          svg: "styles/img/implants/implants-icon-v3.svg",
        }
      },
      'Closure Cap': {
        color: "#7a41cb",
        set_name: 'caps',
        display_name: $filter('translate')('IMPLANTS.COVER_SCREWS'),
        category: 'Closure Cap',
        placements: [],
        manufacturer: [],
        img: {
          svg: "styles/img/implants/caps-icon.svg",
        }
      },
      'Healing Abutment': {
        color: "#2eb479",
        set_name: 'abutments',
        display_name: $filter('translate')('IMPLANTS.HEALING_ABUTMENTS'),
        category: 'Healing Abutment',
        placements: [],
        manufacturer: [],
        img: {
          svg: "styles/img/implants/healing-icon.svg",
        }
      },
      'Bone Graft': {
        color: "#33bbc4",
        set_name: 'bonegraft',
        display_name: $filter('translate')('IMPLANTS.BONE_GRAFT'),
        category: 'Bone Graft',
        placements: [],
        manufacturer: [],
        img: {
          material: "grain",
        }
      },
      'Membrane': {
        color: "#ff2566",
        set_name: 'membranes',
        display_name: $filter('translate')('IMPLANTS.MEMBRANES'),
        category: 'Membrane',
        placements: [],
        manufacturer: [],
        img: {
          material: "healing",
        }
      }
    };
    
    ctrl.productListKey = '';
    ctrl.productList = [
      {
        name: $filter('translate')('ACTIONS.VIEW_ALL_PRODUCTS'),
        value: ''
      },
      {
        name: $filter('translate')('IMPLANTS.HEALING_ABUTMENT'),
        value: 'Healing Abutment'
      },
      {
        name: $filter('translate')('IMPLANTS.BONE_GRAFT'),
        value: 'Bone Graft'
      },
      {
        name: $filter('translate')('IMPLANTS.IMPLANT'),
        value: 'Implant'
      },
      {
        name: $filter('translate')('IMPLANTS.COVER_SCREW'),
        value: 'Closure Cap'
      },
      {
        name: $filter('translate')('IMPLANTS.MEMBRANE'),
        value: 'Membrane'
      }
    ];

    ctrl.timeRange = [
      {
        name: 'YTD',
        value: 'ytd'
      },
      {
        name: 'MTD',
        value: 'mtd'
      },
      {
        name: 'QTD',
        value: 'qtd'
      }
    ];
    
    ctrl.daterangeOption = {
      locale: {
        format: 'MMM D, YYYY'
      }
      ,
      eventHandlers: {
        'apply.daterangepicker': function (event, picker) {
          ctrl.timeRangeSelected = '';
          ctrl.changeDate();
        }
      }
    };

    ctrl.timeRangeSelected = 'ytd';

    ctrl.changeDate = changeDate;
    ctrl.changedKeySearch = changedKeySearch;
    ctrl.changeSeletedTime = changeSeletedTime;
    ctrl.editRemoval = editRemoval;
    ctrl.getPlacedList = getPlacedList;
    ctrl.search = search;
    ctrl.searchProduct = searchProduct;
    ctrl.setColorAndImg = setColorAndImg;
    ctrl.sortBy = sortBy;
    ctrl.undo = undo;

    init();

    return ctrl;
   
    /**
     * Find the index of the category in the ctrl.data that matches the category in the product list
     * key
     * @returns The index of the category in the ctrl.data.
     */
    function _getCategoryIndex(defaultIndex) {
      const isSameProductCategory = (implantsData) => (implantsData.category === ctrl.productListKey);
      const categoryIndex = _.findIndex(ctrl.data, isSameProductCategory);
      return categoryIndex > 0 ? categoryIndex : defaultIndex;
    }
    
    function changedKeySearch () {
      ctrl.productTypeSearch.forEach(function (el) {
        if (el.value == ctrl.searchProductType) {
          ctrl.placehodlderText = el.placeHolder;
        }
      });
    }
    
    function changeDate () {
      var d = angular.copy(ctrl.date);
      d.previousStartDate = moment(d.startDate).subtract(1, 'y');
      d.previousEndDate = moment(d.endDate).subtract(1, 'y');
      
      ctrl.date = d;
      ctrl.getPlacedList();
    }
    
    function changeSeletedTime (val,y) {
      if (ctrl.timeRangeSelected != val) {
        ctrl.timeRangeSelected = val;
        var dt2 = angular.copy(ctrl.date);
        if (val == 'mtd') {
          if (y) {
            dt2.startDate = moment().subtract(1, 'y').startOf('month');
            dt2.endDate = moment().subtract(1, 'y').endOf('month');
          } else {
            dt2.startDate = moment().startOf('month');
            dt2.endDate = moment().endOf('day');
          }
        } else if (val == 'qtd') {
          if (y) {
            dt2.startDate = moment().subtract(1, 'y').startOf('quarter');
            dt2.endDate = moment().subtract(1, 'y').endOf('quarter');
          } else {
            dt2.startDate = moment().startOf('quarter');
            dt2.endDate = moment().endOf('day');
          }
        } else if (val == 'ytd') {
          if (y) {
            dt2.startDate = moment().subtract(1, 'y').startOf('year');
            dt2.endDate = moment().subtract(1, 'y').endOf('year');
          } else {
            dt2.startDate = moment().startOf('year');
            dt2.endDate = moment().endOf('day');
          }
        }
        ctrl.date = dt2;
        ctrl.getPlacedList();
        // Update startDate url param with the selected timeRange without reloading the component
        $state.transitionTo($state.current, _.extend($stateParams, {startDate: ctrl.timeRangeSelected}), {notify: false});
      }
    }
    
    function init () {
      defineLocks();
      if($state.params.isCustom == 'false'){
        var y = null;
        if($state.params.year){
          y = $state.params.year;
          ctrl.date = {
            startDate: moment().subtract(1, 'y').startOf('year'),
            endDate: moment().subtract(1, 'y').endOf('year'),
          };
        }
        ctrl.changeSeletedTime($state.params.startDate, y);
      }else{
        ctrl.date.startDate = $state.params.startDate;
        ctrl.date.endDate = $state.params.endDate;
        ctrl.timeRangeSelected = '';
      }
      ctrl.getPlacedList();
      $rootScope.$on(appEvents.implantItemSaved, function($event, saved_item){
        ctrl.getPlacedList();
      });
    }
    
    function getPlacedList () {
      var start_date = moment(ctrl.date.startDate).format('YYYY-MM-DD');
      var end_date = moment(ctrl.date.endDate).format('YYYY-MM-DD');
      sowImplantPlacementService.getPlacedList(start_date, end_date).then(function (rs) {
        ctrl.data = Object.values(ctrl.setColorAndImg(rs));
        ctrl.propertyName = 'new_date';
        ctrl.reverse = true;
        ctrl.sortBy(ctrl.propertyName, ctrl.indexSort, true);
      });
    }
    
    function sortBy (propertyName, sectionIndex, isReverse) {
      const categoryIndex = _getCategoryIndex(sectionIndex);
      ctrl.indexSort = sectionIndex;
      if(!isReverse){
        ctrl.reverse = (ctrl.propertyName === propertyName) ? !ctrl.reverse : false;
      }
      ctrl.propertyName = propertyName;
      const allProducts = ctrl.data[categoryIndex].placements;
      if (allProducts.length > 0) {
        ctrl.data[categoryIndex].placements = orderByFilter(allProducts, ctrl.propertyName, ctrl.reverse);
      }
    }
    
    function search (value, index, arr) {
      var consi = '';
      if(ctrl.isConsignment == 'consignment'){
        consi = 'consignment';
      }
      var keyword = ctrl.searchBy;
      var regex = new RegExp(keyword, 'i');
      var re = new RegExp(consi, 'i');
      var searchValue = value[ctrl.searchProductType];
      var searchPro = value.consignment;
      
      var val = regex.test(searchValue);
      var v = re.test(searchPro);
      
      if(!v || !val){
        return false;
      }
      return true;
    }

    function searchProduct (value, index, arr) {
      var keyword = ctrl.productListKey;
      if (keyword === undefined || keyword === '') {
        return true;
      }
      var regex = new RegExp(keyword, 'i');
      var searchValue = value.category;
      return regex.test(searchValue);
    }
    
    function setColorAndImg (result) {
      var p = result.product_placements;
      var m = result.manufacturers;
      var s = angular.copy(ctrl.sets);
      p.forEach(function (element) {
        var pla = element.placements;
        pla.forEach(function(el){
          el.new_date = moment(el.date, 'DD MMM, YYYY HH:mm:ss').format('YYYYMMDDHHmmss');
          el.date = moment(el.date, 'DD MMM, YYYY HH:mm:ss').format('DD MMM, YYYY');
        });
        if(s[element.category]) {
          s[element.category].placements = pla;
          s[element.category].all_placements_are_external = pla.every(el => el.is_external_id);
        }
        element.placements.forEach(function(el){
          if(el.on_consignment){
            el.consignment = "consignment";
          }else{
            el.consignment = '';
          }
        })
      });
      
      m.forEach(function (el) {
        el.placement.forEach(function (element) {
          element.logo = el.manufacturer_logo_url;
          element.name = el.manufacturer_name;
          s[element.category]?.manufacturer?.push(element);
        });
      });
      return s;
    }
    
    function editRemoval (item) {
      $rootScope.$broadcast(appEvents.impEditRemovalFetch, item.imp_inventory_item_id, item);
    }
    
    function undo (item) {
      item.implant_inventory_item_id = item.imp_inventory_item_id;
      item.position = item.tooth_placement_format + ' - ' + item.tooth_placement_value;
      $mdDialog.show({
        controller: 'poDialogController as dialogCtrl',
        templateUrl: 'sow-reports/modals/implant-return-to-inventory.html',
        parent: angular.element(document.body),
        clickOutsideToClose: true,
        locals: {
          item: item,
        },
        bindToController: true,
      })
      .then(function (answer) {
        item.id = item.implant_inventory_item_id;
        sowImplantService.getInventoryItem(item.implant_inventory_item_id)
        .then(function (item) {
          item.was_placed = false;
          sowImplantService.saveInventoryItem(item)
          .then(function (item) {
            var type = $stateParams.type !== 'Closure Cap' ? $stateParams.type : 'Cover Screw';
            var t_message = $filter('translate')('TOAST.IMPLANT_HAS_BEEN_RETURNED');
            sgToast.showSimple(t_message);
            ctrl.getPlacedList();
          }).catch(function (error) {
            var t_message = $filter('translate')('ERRORS.INVENTORY_RETURN')
            errorService.uiErrorHandler(t_message);
          });
        }).catch(function (error) {
          var t_message = $filter('translate')('ERRORS.IMPLANT_LOAD')
          errorService.uiErrorHandler(t_message);
        });
      });
    }

    function defineLocks () {
      const membership = membershipService.get();

      const hide_report_prices_condition = _.get(membership, 'user_properties.reports.hide_report_prices', false);
      ctrl.hide_report_prices = hide_report_prices_condition;
    }
  }
}());

(function () {
  'use strict';

  angular.module('sowProduct')
    .controller('pdProductInfoController', pdProductInfoController)
    .directive('pdProductInfo', pdProductInfoDirective);

  function pdProductInfoDirective() {
    return {
      restrict: 'E',
      templateUrl: 'sow-product-details/directives/pd-product-info.html',
      controller: 'pdProductInfoController',
      controllerAs: 'pdPICtrl',
      scope: {
        product: '='
      }
    };
  }

  function pdProductInfoController($scope, $filter, productDetailsService, mktHelperService) {
    var ctrl = this;

    ctrl.toggleSection = toggleSection;
    ctrl.subsections_to_display = [
      'minimum_level',
      'on_hand',
      'desired_level',
      'fill_qty',
    ];

    ctrl._makeVideoItem = makeVideoItem;
    ctrl.localize = mktHelperService.getLocalizedProp;

    init();

    return ctrl;

    function init() {
      ctrl.product = ctrl.product || $scope.product;
      ctrl.inventory_status = _getInventoryStatus() || {};
      initSections();
      _fetchAsyncData();
    }

    /**
    * Parses the product's data to populate the accordion menu.
    * Sets each sections's boolean "content" property, which determines whether or not to display that section in the UI.
    * 
    * @param {Object} product 
    * 
    * @return {Object} 
    */
    function initSections(product) {
      product = product || ctrl.product;
      const on_hand = parseInt(ctrl.inventory_status?.on_hand);
      const minimum_level = parseInt(ctrl.inventory_status?.minimum_level);
      const low_stock = (minimum_level > -1) && (on_hand <= minimum_level);
      const is_inventory_info_available = on_hand > -1;
      const badges = {
        'low_stock': {
          'name': $filter('translate')('INVENTORY.LOW_STOCK'),
          'classes': 'attention',
        },
      };
      ctrl.sections = {
        "description": {
          name: 'description',
          open: true,
          title: 'INVENTORY.ACTIVITY.TABLE.DESCRIPTION',
          content: !_.isNil(product.description)
        },
        "specifications": {
          name: 'specifications',
          open: false,
          title: 'MARKETPLACE.DETAIL.SPECIFICATIONS',
          content: !_.isNil(product.filters)
        },
        "information": {
          name: 'information',
          open: false,
          title: 'MARKETPLACE.DETAIL.ADDITIONAL_INFO',
          content: !_.isNil(product.additional_info)
        },
        "inventory": {
          name: 'inventory',
          content: is_inventory_info_available,
          open: false,
          title: 'COMMON.INVENTORY',
          subsections: ctrl.inventory_subsections,
          badges: _.map(badges, function(badge, key){
            var condition = (low_stock && key === 'low_stock');
            return condition ? badge : null;
          })
        },
        "documents": {
          name: 'documents',
          open: false,
          title: 'MARKETPLACE.DETAIL.PRODUCT_DOCS',
          sds: _.map(_.get(product, 'documents.sds_urls'), function (url) {
            return {
              'url': url,
              'filename': _.last(url.split("/"))
            };
          }),
          docs: _.map(_.get(product, 'documents.document_urls'), function (url) {
            return {
              'url': url,
              'filename': _.last(url.split("/"))
            };
          }),
          content: _.get(product, 'documents.sds_urls') || _.get(product, 'documents.document_urls')
        },
        "videos": {
          name: 'videos',
          open: false,
          title: 'MARKETPLACE.DETAIL.PRODUCT_VIDEOS',
          content: !_.isNil(_.get(product, 'documents.video_urls')),
          items: _.map(_.get(product, 'documents.video_urls'), makeVideoItem)
        },
        "reviews": {
          name: 'reviews',
          open: false,
          title: 'MARKETPLACE.DETAIL.REVIEWS',
          content: null
        },
      }
      findLastSection();
    }

    /** 
     * Finds the last section of the accordion menu which will be displayed in the UI and sets its "last" property to true (will be undefined for all others).
     * Important for styling the menu (first and last sections each have unique border radius).
     * 
     * @param {Object} sections
     * 
     * @return {*}
     */
    function findLastSection (sections) {
      sections = sections || ctrl.sections;
      var keys = Object.keys(sections).reverse();
      for (var i = 0; i < keys.length; i++) {
        var current_section = ctrl.sections[keys[i]];
        if (current_section.content) {
          current_section.last = true;
          break;
        }
      }
    }

    /**
     * Fetches data from the server and generates the subsections of
     * the inventory item page again with the updated data.
     */
    function _fetchAsyncData(product = ctrl.product) {
      const product_id = product?.id;
      if (!product_id) {
        return;
      }
      productDetailsService.generateActiveOrderTooltip(product_id)
        .then(active_order_tooltip => {
          ctrl.inventory_status = _.extend(
            { active_order_tooltip },
            ctrl.inventory_status,
          );
        });
    }

    function _getInventoryStatus(product = ctrl.product) {
      return productDetailsService.getProductInventoryStatus(product);
    }

    /**
     * Creates a 'video item' object containing the video type, id, and url.
     *
     * @param {String} url
     * 
     * @return {Object}
     */
    function makeVideoItem (url) {
      var templates = {
        'youtube': "https://www.youtube.com/embed/{0}?controls=0",
        'vimeo': "https://player.vimeo.com/video/{0}",
        'default': "{1}"
      };
      var type = 'default';
      var id;
      var play_url;
      if (url.includes("youtube.com") || url.includes("youtu.be")) {
        id = getId(url);
        // getId(url) will return null if the ID does not have a length of 11,
        // so in these cases we set type to 'default'
        type = id ? 'youtube' : 'default';
      } else if (url.includes("vimeo.com")) {
        id = _.last(url.split('/'));
        // Vimeo IDs are all composed of only integers,
        // so use 'default' type if ID contains anything else
        type = parseInt(id) ? 'vimeo' : 'default';
      } else {
        type = 'default';
        play_url = url;
      }
      
      play_url = templates[type].format(id, url);

      return {
        'type': type,
        'id': id,
        'play_url': play_url
      };

      /** 
       * Parses the URL of a YouTube video to extract its ID.
       * Returns null if the ID does not have a length of 11, since only IDs with that length are used by YouTube.
       * 
       * @param {String} url 
       * 
       * @return {String}
      */
      function getId(url) {
        var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        var match = url.match(regExp);
    
        return (match && match[2].length === 11)
          ? match[2]
          : null;
      }
    }

    /**
    * Toggles a section of the accordion menu when the user clicks it.
    * 
    * @param {String} section_name
    * 
    * @return {*}
    */
    function toggleSection(section_name) {
      var section = ctrl.sections[section_name]
      section.open = !section.open;
    }

  }

})();

(function () {
  'use strict';

  angular
    .module('sowTutorialVideos')
    .directive('tutorialVideo', TutorialVideoDirective);

  function TutorialVideoDirective ($log) {
    return {
      'restrict': 'E',
      'templateUrl': 'sow-tutorial-videos/tutorial-video.html',
      'controller': TutorialVideoController,
      'controllerAs': 'tvCtrl',
      'scope': {
        'videoId': '=',
        'videoTitle': '@',
      },
      'link': function ($scope, $element, $attrs, $ctrl) {
        $ctrl.init($scope.videoId);
      }
    };
  }

  function TutorialVideoController ($scope, $sce, sowVimeoService, errorService) {
    /*jshint validthis: true */
    var ctrl = this;

    ctrl.video = null;
    ctrl.state = "not loaded";

    ctrl.init = init;
    ctrl.toggleVideo = toggleVideo;

    return ctrl;

    function init (videoId) {
      ctrl.state = "loading";

      return sowVimeoService
        .getVideo(videoId)
        .then(function (video) {
          ctrl.video = {
            'id': video.id,
            'title': $scope.videoTitle || video.title,
            'thumbnail': video.thumbnail_large,
            'url': "https://player.vimeo.com/video/{0}?title=0&byline=0&portrait=0".format(video.id),
          };
          ctrl.video['src'] = $sce.trustAsResourceUrl(ctrl.video['url']);
          ctrl.state = "loaded";
        })
        .catch(function (error) {
          ctrl.state = "not loaded";
          errorService.uiErrorHandler(error);
        });
    }

    function toggleVideo () {
    }
  }

}());

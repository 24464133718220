(function () {
  'use strict';

  angular.module('sowProduct')
    .controller('pdAccordionController', pdAccordionController)
    .directive('pdAccordion', pdAccordionDirective);

  function pdAccordionDirective() {
    return {
      restrict: 'E',
      templateUrl: 'sow-product-details/directives/pd-accordion-item.html',
      controller: 'pdAccordionController',
      controllerAs: 'pdaiCtrl',
      transclude: true,
      scope: {
        section: '='
      }
    };
  }

  function pdAccordionController ($scope) {
    var ctrl = this;

    ctrl.toggleItem = toggleItem;

    init();

    return ctrl;

    function init() {
      
    }

    function toggleItem (section) {
      section.open = !section.open;
    }

  }

})();

(function () {
  'use strict';

  angular.module('sowMarketplace')
    .directive('mktProductCardShimmer', marketplaceProductCardShimmerDirective);

  function marketplaceProductCardShimmerDirective() {
    return {
      restrict: 'E',
      templateUrl: 'sow-mkt/directives/mkt-product-card-shimmer.html',
    };
  }

})();

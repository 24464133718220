(function () {
  'use strict';

  angular
    .module('app.shared.system.api')
    .service('apiTokenService', apiTokenService);

  //
  // Service for managing the api token
  //
  function apiTokenService (localStorageService, generalUtils) {
    /*jshint validthis: true */
    var service = this;

    var apiToken;
    var apiTokenExpiry;

    service.initialize = initialize;
    service.setToken = setToken;
    service.getToken = getToken;
    service.clearToken = clearToken;
    service.hasValidToken = hasValidToken;
    service.isTokenExpired = isTokenExpired;

    return service;

    /** Called when the page first loads
     * If localStorage is supported, it retrieves the user's token and expiry from local storage or the URL parameters
     * otherwise it sets the user's token and expiry to undefined.
     */
    function initialize () {
      if (localStorageService.isSupported()) {
        const tokenFromUrlParams = generalUtils.getValueFromUrlParams('userToken')

        // If the user's token is present in the URL parameters,
        // set the token to the value from the URL parameters and set the token expiry to the current time (this value will be overwritten by the API)
        if (tokenFromUrlParams) {
          const tokenExpiry = new Date().toISOString();
          setToken(tokenFromUrlParams, tokenExpiry);
        } else {
          apiToken = localStorageService.get('token');
          apiTokenExpiry = localStorageService.get('token_expiry');
        }
      } else {
        apiToken = undefined;
        apiTokenExpiry = undefined;
      }
    }

    //
    // Check that there is a token, and that it's valid.
    //
    function hasValidToken () {
      return apiToken && apiTokenExpiry && !isTokenExpired();
    }

    //
    // Clear the token information
    //
    function clearToken () {
      apiToken = null;
      apiTokenExpiry = null;

      if (localStorageService.isSupported()) {
        localStorageService.set('token', null);
        localStorageService.set('token_expiry', null);
        localStorageService.remove('token');
        localStorageService.remove('token_expiry');
      }
    }

    //
    // Set the token and expiration date
    //
    function setToken (token, expiry) {
      apiToken = token;
      apiTokenExpiry = expiry;

      if (localStorageService.isSupported()) {
        localStorageService.set('token', token);
        localStorageService.set('token_expiry', expiry);
      }
    }

    //
    // Get the token
    //
    function getToken () {
      return apiToken;
    }

    //
    // Is the token expired?
    //
    function isTokenExpired () {
      var date = generalUtils.shortISODate(new Date());
      return !apiTokenExpiry || apiTokenExpiry <= date;
    }
  }
}());

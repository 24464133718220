(function () {
  'use strict';

  angular
    .module('app.shared.support')
    .directive('selectOnClick2', function ($window) {
      return {
        restrict: 'A',
        link: function (scope, element, attrs) {
          element.on('click', function () {
            if (!$window.getSelection().toString()) {
              // Required for mobile Safari
              try {
                this.setSelectionRange(0, this.value.length);
              }
              catch (error) {
                // This seems to work better than setSelectionRange because
                // setSelectionRange doesn't work on <input type="number">
                // fields, but I can't find any information on what sort of
                // browser support there is for this method.
                this.select();
              }
            }
          });
        }
      };
  });
}());

(function () {
  'use strict';
  angular.module('sowMarketplace')
  .controller('MarketplaceLandingController', MarketplaceLandingController);

  function MarketplaceLandingController($rootScope, $state, $q, $window, errorService, sowMktService, appEvents, sessionService, adButlerService) {
    /*jshint validthis: true */
    var ctrl = this;

    ctrl.search_query = null;
    ctrl.mkt_tagline = null;
    ctrl.mkt_img_src = null;
    ctrl.search_category = null;
    ctrl.categories = [];
    ctrl.ads_loaded = false;
    ctrl.loading_data = true;
    ctrl.ad_layouts = {
      'header':null,
      'middle':null,
      'footer':null
    };
    ctrl.butler_ads = {
      "box1": {},
      "box2": {},
      "box3": {},
      // "box4": {},
      "landing-leaderboard": {},
      "landing-leaderboard-scaled": {},
      "leaderboard2": {},
      "landing-top-left": {},
      "landing-top-left-scaled": {},
      "landing-top-mid": {},
      "landing-top-right": {},
      "landing-bottom-left": {},
      "landing-bottom-mid": {},
      "landing-bottom-right": {},
      "landing-bottom-right-scaled": {},
      "landing-bg": {}
    };

    ctrl.goToCategory = goToCategory;
    ctrl.goToSearch = goToSearch;
    ctrl.handleAdClick = handleAdClick;

    init();

    return ctrl;

    function init () {
      sessionService.refreshSession()
      .then(function(session_data){
        // loadCategories();
        loadAllData();
        loadAllAds();
      });
      // cleans text & category on topnav input
      $rootScope.$broadcast('mkt-search-clean-input');

      $rootScope.$on(appEvents.setSession, function (event, newSession) {
        ctrl.mkt_tagline = _.get(newSession, 'current_marketplace.tagline');
        ctrl.mkt_img_src = _.get(newSession, 'current_marketplace.image_src');
      });

    }

    function loadAllData () {
      ctrl.loading_data = true;
      const use_storefront_order = true;
      const force_api = true;

      var promises = [
        // SPD-1211: change this from the marketplace landing endpoint to the sales tracker endpoint, 
        // because the current one is listing more than just promos
        // SPD-1820: reverted once again to using vendor_promotions, seems like the endpoint is now 
        // fixed and shows the correct items and sorting
        // sowMktService.searchQuery({'sales_all':true})
        sowMktService.storefrontQuery('vendor_promotions')
        .then(function(response){
          ctrl.vendor_promotions = _.get(response, 'results[0].hits', []);
        }),
        sowMktService.getAds()
        .then(function(response){
          ctrl.ads = response;
          _.each(ctrl.ads, function(ad_layout){
            ctrl.ad_layouts[ad_layout.position] = ad_layout;
          });
        }),
        sowMktService.getCategories(use_storefront_order, force_api)
        .then(function(response){
          ctrl.categories = _.get(response, 'categories', []);
        }),
        sowMktService.getManufacturers(use_storefront_order)
        .then(function(response){
          var list = _.get(response, 'manufacturers', []);
          ctrl.manufacturers = _.filter( list, function(item){ return item.featured; } );
        }),
        sowMktService.searchQuery({'favourites': true}, {})
        .then(function(response){
          ctrl.favourites = _.get(response, 'results[0].hits', []);
        }),
        sowMktService.storefrontQuery('frequently_purchased')
        .then(function(response){
          ctrl.frequently_purchased = _.get(response, 'results[0].hits', []);
        }),
        sowMktService.storefrontQuery('most_popular_products')
        .then(function(response){
          ctrl.most_popular_products = _.get(response, 'results[0].hits', []);
        })
      ];
      return $q.all(promises)
      .then(function(responses){
        // reserved for loading UI purposes
        ctrl.loading_data = false;
      });
    }

    function loadAllAds () {
      // var all_promises = [];

      // SPD-2066
      // before loading any ads, we're setting the marketplace ID so that adbutler has more data
      // to decide which campaign is going to be served
      adButlerService.setABKW();

      _.each(Object.keys(ctrl.butler_ads), function(key){
        if(key === 'landing-bg'){
          adButlerService.getZoneApiData(key)
          .then(function(data){
            _.extend(ctrl.butler_ads[key], data);
            var image = _.get(data, 'image_url');
            if(image) {
              ctrl.bg_property = "url('{0}')".format(image);
              ctrl.ads_loaded = true;
            }
          })
          .catch(function(response){
            ctrl.ads_loaded = false;
            return;
          });
        } else {
          adButlerService.autoLoadZone(key);
        }
      });
    }

    function handleAdClick (key) {
      $window.open(ctrl.butler_ads[key].redirect_url, ctrl.butler_ads[key].target);
    }

    function goToCategory (category) {
      $state.go('app.mkt.search',{'cat_id':category.id, 'category':category});
    }

    function goToSearch () {
      if (!ctrl.search_query) {
        return;
      }
      $state.go('app.mkt.search',{'query':ctrl.search_query,'cat_id':ctrl.search_category});
    }

    function loadCategories () {
      return sowMktService.getCategories()
      .then(function(cat_response){
        ctrl.categories = cat_response.categories;
      })
      .catch(function(error){
        errorService.uiErrorHandler(error);
      });
    }
  }

}());

(function () {
  'use strict';
  angular.module('sowUserAdmin')
  .controller('UserAdminModalController', UserAdminModalController);

  function UserAdminModalController ($scope, $rootScope, $filter, $mdDialog, sgToast, errorService, sowUserAdminService, sowUserAdminHelperService) {
    /*jshint validthis: true */
    var ctrl = this;

    ctrl.offices_map = {};
    ctrl.offices = [];
    ctrl.office_search = '';
    ctrl.select_all_offices = false;
    ctrl.MEMBERSHIP_TYPES = {
      'master': { name: "OFFICE.ADMIN.MASTER_ACCESS", description: "OFFICE.ADMIN.MASTER_ACCESS_INFO"},
      'manager': { name: 'OFFICE.ADMIN.MANAGER_ACCESS', description: "OFFICE.ADMIN.MANAGER_ACCESS_INFO"},
      'custom': { name: 'COMMON.CUSTOM', description: null},
    };
    ctrl.PERMISSION_LABELS = sowUserAdminHelperService.PERMISSION_LABELS;
    ctrl.USER_PERMISSIONS = sowUserAdminHelperService.USER_PERMISSIONS;
    ctrl.user_data = {
      offices: {},
      permissions: {}
    };

    ctrl.cancel = closeDialog;
    ctrl.toggleAllOffices = toggleAllOffices;
    ctrl.permissionProfileChanged = permissionProfileChanged;
    ctrl.permissionClicked = permissionClicked;
    ctrl.removeUserFromOffice = removeUserFromOffice;

    init();

    return ctrl;

    // private functions

    function init () {
      setupOffices();
      switch (ctrl.mode) {
        case "new":
          setupNewUser();
          ctrl.saveFn = inviteUser;
          break;
        case "edit":
          setupExistingUser();
          ctrl.saveFn = saveUser;
          break;
        case "addto":
          setupExistingUser();
          ctrl.saveFn = inviteExistingUser;
          break;
        default:
          // statements_def
          break;
      }
      if(!ctrl.user){
        
      } else {
        
      }
      $scope.$on('$destroy', exit);
    }

    function exit () {

    }

    // UI functions

    function closeDialog () {
      return $mdDialog.cancel();
    }

    function toggleAllOffices () {
      _.each(ctrl.user_data.offices, function(user_office){
        if(user_office.disabled)
          return;
        _.set(user_office, 'selected', ctrl.select_all_offices);
      });
    }

    function permissionProfileChanged () {
      var profile = ctrl.user.membership_type;
      if(profile === 'custom')
        return;
      setPermissions(ctrl.USER_PERMISSIONS[profile]);
    }

    /**
     * > If the first item in the list is 'master_permission', then all permissions should
     * be selected. Otherwise, only permissions whose keys are in the list should be selected.
     * @param {string[]} list - The list of permissions that the user has.
     */
    function setPermissions (list) {
      if (list[0] === 'master_permission') {
        for (const permission of ctrl.user_data.permissions) {
          permission.selected = true;
        }
      } else {
        for (const permission of ctrl.user_data.permissions) {
          permission.selected = list.includes(permission.key);
        }
      }
    }

    function permissionClicked () {
      _.set(ctrl, 'user.membership_type', 'custom');
    }


    // data functions

    function setupOffices () {
      ctrl.offices_map = {};
      ctrl.offices = [];
      ctrl.offices_ids = {};
      _.each(ctrl.memberships, function(mship){
        if(mship.office.id && (mship.membership_type === 'manager' || mship.membership_type === 'master')){
          _.set(ctrl.offices_map, mship.office.id, mship.office);
          _.set(ctrl.offices_ids, mship.office.id, false);
          ctrl.offices.push(mship.office);
        }
      });
      ctrl.ADMIN_OFFICES = sowUserAdminHelperService.getAdminOffices();
    }

    function setupNewUser () {
      // _.set(ctrl,'mode', 'new');
      _.set(ctrl, 'user_data', {
        'offices': [],
        'permissions': [],
      });
      _.each(ctrl.ADMIN_OFFICES.list, function(office){
        ctrl.user_data.offices.push(_.extend(office,{'selected': false}));
      });
      _.each(ctrl.USER_PERMISSIONS.master, function(permission_key){
        ctrl.user_data.permissions.push({
          key: permission_key,
          selected: false,
        });
      });
    }

    function setupExistingUser () {
      _.set(ctrl, 'user_data', {
        'offices': [],
        'permissions': [],
      });
      if (ctrl.current_office) {
        var office_data = {
          'membership_type': ctrl.current_office.membership_type,
          'title': ctrl.current_office.title,
          'user_permissions': ctrl.current_office.user_permissions,
        };
        ctrl.user = _.extend({}, _.omit(ctrl.user, ['title','role','membership_type']), office_data);
      }
      _.each(ctrl.ADMIN_OFFICES.list, function(office){
        var user_has_office = ( _.findIndex(ctrl.user.offices, ['id', office.id]) >= 0 )
        ctrl.user_data.offices.push(
          _.extend({}, office,{
            'selected': user_has_office,
            'disabled': user_has_office && ctrl.mode !== 'new',
          }));
      });
      _.each(ctrl.USER_PERMISSIONS.master, function(permission_key){
        var user_has_permission = ( _.findIndex(ctrl.user.user_permissions, permission_key) >= 0 )
        ctrl.user_data.permissions.push({
          key: permission_key,
          selected: user_has_permission,
        });
      });
      setPermissions(ctrl.user.user_permissions);
    }    

    function inviteUser () {
      convertUserData();
      if(!_.size(ctrl.user.office_ids)){
        var t_message = $filter('translate')('ERRORS.PLEASE_SELECT_OFFICE');
        return errorService.uiErrorHandler(t_message);
      }
      ctrl.saving = true;
      return sowUserAdminService.sendInvite(ctrl.user)
      .then(function(response){
        $rootScope.$broadcast('user-invite-sent', response);
        var t_message = $filter('translate')('TOAST.INVITE_SENT');
        sgToast.showSimple(t_message);
        closeDialog();
      })
      .catch(errorService.uiErrorHandler)
      .finally(function(){
        ctrl.saving = false;
      });
    }

    function inviteExistingUser () {
      convertUserData(true);
      if(!_.size(ctrl.user.office_ids)){
        var t_message = $filter('translate')('ERRORS.PLEASE_SELECT_OFFICE');
        return errorService.uiErrorHandler(t_message);
      }
      ctrl.saving = true;
      return sowUserAdminService.sendInvite(ctrl.user)
      .then(function(response){
        $rootScope.$broadcast('user-invite-sent', response);
        var t_message = $filter('translate')('TOAST.INVITE_SENT');
        sgToast.showSimple(t_message);
        closeDialog();
      })
      .catch(errorService.uiErrorHandler)
      .finally(function(){
        ctrl.saving = false;
      });
    }

    function saveUser () {
      ctrl.saving = true;
      convertUserData();
      return sowUserAdminService.updateUser(ctrl.user, ctrl.current_office)
      .then(function(response){
        $rootScope.$broadcast('user-permissions-updated', response);
        var t_message = $filter('translate')('TOAST.USER_UPDATED');
        sgToast.showSimple(t_message);
        closeDialog();
      })
      .catch(errorService.uiErrorHandler)
      .finally(function(){
        ctrl.saving = false;
      });
    }

    function removeUserFromOffice () {
      ctrl.saving = true;
      // convertUserData();
      return sowUserAdminService.disableUser(ctrl.user, ctrl.current_office)
      .then(function(response){
        $rootScope.$broadcast('user-permissions-updated', response);
        var t_message = $filter('translate')('OFFICE.ADMIN.USER_REMOVED');
        sgToast.showSimple(t_message);
        closeDialog();
      })
      .catch(errorService.uiErrorHandler)
      .finally(function(){
        ctrl.saving = false;
      });
    }

    function convertUserData (limit_new_offices) {
      var office_ids = [];
      _.each(ctrl.user_data.offices, function(obj){
        if(obj.selected){
          if(limit_new_offices && obj.disabled) {
            return;
          } else {
            office_ids.push(obj.id);
          }
        }
      });

      var permissions = [];
      _.each(ctrl.user_data.permissions, function(obj){
        if(obj.selected){
          permissions.push(obj.key);
        }
      });

      _.set(ctrl, 'user.office_ids', office_ids);
      _.set(ctrl, 'user.user_permissions', permissions);
    }
    
  }

}());

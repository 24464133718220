{
  'use strict';
  /** @ngInject */
  class PwfRedirectService {
    constructor(WorkflowsService, serverAPI) {
      this.WorkflowsService = WorkflowsService;
      this.serverAPI = serverAPI;
    }

    registerNewPwfOffice({email, country, officeId}) {
      const url = `${this.WorkflowsService.getBaseUrl()}/LinkRedirect`;
      const config = {
        method: 'GET',
        params: {
          actionType: 'new_office_created',
          email,
          localTime: moment().format(),
          region: country === 'US' ? 'US' : 'CA',
          link: '',
          officeId,
        },
      };
      return this.serverAPI.callAPI(url, config);
    }
  }

  angular.module('sowWorkflows').service('PwfRedirectService', PwfRedirectService);
}

//
// Password Reset Form Controller
//
angular.module('app.marketplace.ui.account').controller('PasswordResetFormController',
  function PasswordResetFormController($scope, $state, $stateParams, errorService, accountService, AccountModalService, sowAnalyticsService) {
    var _this = this;
    _this.data = {};
    _this.success = false;

    _this.submit = function ($event) {
      if ($event) {
        $event.preventDefault();
        $event.stopPropagation();
      }

      if (!$scope.PasswordResetForm.$valid) {
        return;
      }

      $scope.ok = function () {
        AccountModalService.hide();
      };

      return accountService._resetPassword({
        email: encodeURIComponent(_this.data.email),
      }).then(function () {
        _this.success = true;
        sowAnalyticsService.confirmPasswordReset();
      }).catch(function (error) {
        errorService.uiErrorHandler(error);
      });
    };

    _this.goToLogin = () => {
      /* practice login uses "credentials" param but classic login doesn't */
      if ($stateParams.credentials) {
        $state.go('practice_login', $stateParams);
      } else {
        $state.go('login');
      }
    }
  }
);

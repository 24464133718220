angular.module('app.marketplace.elements')

.service('sterilizerService', function ($filter, $q, sgToast, elementService, officeService) {
  var _this = this;

  _this.sterilizers = null;

  var _refreshSterilizers = function () {
    return elementService.callEndpoint('sterilizer', {
      endpoint: 'multiple',
      office: officeService.get()
    }).then(function success(response) {
      _this.sterilizers = response.data;
      elementService.createMultiple('sterilizer', response.data);
      return _this.sterilizers;
    });
  };

  var _getSterilizers = function (force) {
    if (!force && _this.sterilizers) {
      return $q.when(_this.sterilizers);
    } else {
      return _refreshSterilizers();
    }
  };

  var _createSterilizer = function (data) {
    data.office_id = officeService.get().id;
    return elementService.submit('sterilizer', {
      endpoint: 'create',
      element: data
    }).then(function (sterilizer) {
      var t_message = $filter('translate')('TOAST.STERILIZER_CREATED');
      sgToast.showSimple(t_message + sterilizer.name);
      _this.sterilizers.push(sterilizer);
      return sterilizer;
    });
  };

  var _updateSterilizer = function (data) {
    data.office_id = officeService.get().id;
    return elementService.submit('sterilizer', {
      endpoint: 'update',
      element: data
    }).then(function (uSterilizer) {
      var t_message = $filter('translate')('TOAST.STERILIZER_UPDATED');
      sgToast.showSimple(t_message);
      angular.forEach(_this.sterilizers, function (sterilizer) {
        if (sterilizer.id === uSterilizer.id) {
          angular.extend(sterilizer, uSterilizer);
        }
      });
      return uSterilizer;
    });
  };

  return {
    getSterilizers: _getSterilizers,
    createSterilizer: _createSterilizer,
    updateSterilizer: _updateSterilizer
  };
});

(function () {
  'use strict';

  angular
    .module('app.marketplace.elements')
    .service('MonitorService', MonitorService);

  function MonitorService ($rootScope, elementService) {
    /*jshint validthis: true */
    var service = this;

    // DailyRecord API
    service.updateRecordValues = updateRecordValues;
    service.saveValueSet = saveValueSet;
    service.openOfficeForDate = openOfficeForDate;
    service.closeOfficeForDate = closeOfficeForDate;
    service.clearRecordData = clearRecordData;
    service.getRecord = getRecord;
    service.getRecords = getRecords;
    service.getRecordForDate = getRecordForDate;

    // DailyRecordField API
    service.getField = getField;
    service.getFields = getFields;
    service.getFieldsForDate = getFieldsForDate;
    service.updateField = updateField;
    service.newField = newField;
    service.batchFieldUpdate = batchFieldUpdate;

    service.clearCache = clearCache;

    // Daily Record Reports API
    service.getCollectionsReport = getCollectionsReport;
    service.getFinancialReport = getFinancialReport;
    service.getProcedureReport = getProcedureReport;
    service.getWellBeingReport = getWellBeingReport;
    service.getProductionReport = getProductionReport;
    service.getDailyStatusReport = getDailyStatusReport;

    init();

    return service;

    //
    // Initialize the service.
    //
    function init () {
      // We need to make sure that cached objects are dumped when switching
      // offices.
      $rootScope.$on('officeService: office-changed', clearCache);
    }

    //
    // Clear all records held in the elementService cache.
    //
    function clearCache () {
      elementService.removeAll('dailyRecord');
      elementService.removeAll('DailyRecordField');
    }

    //
    // Get Record For Date
    //
    function getRecordForDate (date) {
      return getRecords({
        'start_date': date,
        'end_date': date,
      }).then(function (records) {
        if (records.length > 1) {
          throw 'Found multiple records for the same date!';
        } else if (records.length === 1) {
          return records[0];
        } else {
          return null;
        }
      });
    }

    //
    // Update a dailyRecord with values. values is an object containing
    // key-value pairs that we wish to update on the record. Example:
    //
    //    updateRecordValue('2011-01-01', {
    //       'comment': 'This is a \ncomment',
    //       'deposit_date': '2011-01-08',
    //    });
    //
    // In the example, only 'comment' and 'deposit_date' would be updated on
    // the record for '2011-01-01'. If no record exists for '2011-01-01', one
    // will first be created.
    //
    function updateRecordValues (date, values) {
      return getRecordForDate(date).then(function (record) {
        if (!record) {
          return elementService.callEndpoint('dailyRecord', {
            'endpoint': 'create_record',
            'record': {
              'date': date,
              'skipped': false,
            },
          }).then(function (response) {
            var record = elementService.create('dailyRecord', response.data);
            return update(record, values);
          });
        } else {
          return update(record, values);
        }
      });

      // --- Perform the actual update ---
      //
      function update (record, values) {
        var _record = angular.extend({'id': record.id}, values);

        return elementService.callEndpoint('dailyRecord', {
          'endpoint': 'update_record',
          'record': _record,
        }).then(function (response) {
          var record = elementService.create('dailyRecord', response.data);
          return record;
        });
      }
    }

    //
    // Update a particular set of values on a record. Possible values for
    // valueSet are: 'wellbeing_values', 'collections_values',
    // 'procedure_values', or 'production_values'
    //
    // The following constraints apply to each 'value' in the list:
    //
    // 1. Each 'value' in the list should have the format:
    //
    //    {
    //       'field_id': ...,
    //       'value': ...,
    //    }
    //
    //    with one exception: 'production_values' should look like:
    //
    //    {
    //       'field_id': ...,
    //       'value': ...,
    //       'open_blocks': ...,
    //       'hours_worked': ...,
    //    }
    //
    // 2. There should be no duplicate items in the list for a particular
    //    'field_id' value.
    //
    function saveValueSet (date, valueSet, values) {
      return getRecordForDate(date).then(function (record) {
        if (!record) {
          return elementService.callEndpoint('dailyRecord', {
            'endpoint': 'create_record',
            'record': {
              'date': date,
              'skipped': false,
            },
          }).then(function (response) {
            var record = elementService.create('dailyRecord', response.data);
            return update(record, valueSet, values);
          });
        } else {
          return update(record, valueSet, values);
        }
      });

      // --- Perform the actual update ---
      //
      function update (record, valueSet, values) {
        var data = {'id': record.id};
        data[valueSet] = values;

        return elementService.callEndpoint('dailyRecord', {
          'endpoint': 'update_record',
          'record': data,
        }).then(function (response) {
          var record = elementService.create('dailyRecord', response.data);
          return record;
        });
      }
    }

    //
    // "Open Office" aka Don't Skip this dailyRecord.
    //
    function openOfficeForDate (date) {
      return getRecordForDate(date).then(function (record) {
        if (record) {
          return elementService.callEndpoint('dailyRecord', {
            'endpoint': 'update_record',
            'record': {
              'id': record.id,
              'skipped': false,
            }
          }).then(function (response) {
            var record = elementService.create('dailyRecord', response.data);
            return record;
          });
        } else {
          return elementService.callEndpoint('dailyRecord', {
            'endpoint': 'create_record',
            'record': {
              'date': date,
              'skipped': false,
            },
          }).then(function (response) {
            var record = elementService.create('dailyRecord', response.data);
            return record;
          });
        }
      });
    }

    //
    // "Close Office" aka Skip this dailyRecord.
    //
    // Note: All previously recorded data for this record is deleted by this
    // action.
    //
    function closeOfficeForDate (date) {
      return getRecordForDate(date).then(function (record) {
        if (record) {
          return elementService.callEndpoint('dailyRecord', {
            'endpoint': 'update_record',
            'record': {
              'id': record.id,
              'skipped': true,
            }
          }).then(function (response) {
            var record = elementService.create('dailyRecord', response.data);
            return record;
          });
        } else {
          return elementService.callEndpoint('dailyRecord', {
            'endpoint': 'create_record',
            'record': {
              'date': date,
              'skipped': true,
            },
          }).then(function (response) {
            var record = elementService.create('dailyRecord', response.data);
            return record;
          });
        }
      });
    }

    //
    // Clear Recorded Data from a dailyRecord.
    //
    function clearRecordData (recordId, opts) {
      var options = angular.extend({
        'endpoint': 'clear_data',
        'recordId': recordId,
      }, opts);

      return elementService.callEndpoint('dailyRecord', options).then(function (response) {
        var record = elementService.create('dailyRecord', response.data);
        return record;
      });
    }

    //
    // Get a single dailyRecord by ID.
    //
    function getRecord (recordId, opts) {
      var options = {
        'forceAPI': _.has(opts, 'force') ? opts.force : true,
      };
      return elementService.get('dailyRecord', recordId, options);
    }

    //
    // Fetch a range of dailyRecord instances.
    //
    function getRecords (options) {
      var sortOrder = (_.get(options, 'sort_order') || 'desc').toLowerCase();

      if (sortOrder !== 'desc' && sortOrder !== 'asc') {
        throw 'Invalid sort_order Value: {0}'.format(sortOrder);
      }

      return elementService.callEndpoint('dailyRecord', {
        'endpoint': 'get_records',
        'filters': {
          'no_skipped': _.get(options, 'no_skipped', false) ? 1 : 0,
          'start_date': _.get(options, 'start_date'),
          'end_date': _.get(options, 'end_date'),
          'limit': _.get(options, 'limit') || 16,
          'sort_order': sortOrder,
        },
      }).then(function (response) {
        var records = elementService.createMultiple('dailyRecord', response.data);
        return records;
      });
    }

    //
    // Update an existing DailyRecordField.
    //
    function updateField (fieldData, options) {
      options = options || {};

      return elementService.callEndpoint('DailyRecordField', {
        'endpoint': 'updateField',
        'data': fieldData,
      }).then(function (response) {
        var field = elementService.create('DailyRecordField', response.data);
        return field;
      });
    }

    //
    // Create a new DailyRecordField.
    //
    function newField (fieldData, options) {
      options = options || {};

      return elementService.callEndpoint('DailyRecordField', {
        'endpoint': 'newField',
        'data': fieldData,
      }).then(function (response) {
        var field = elementService.create('DailyRecordField', response.data);
        return field;
      });
    }

    //
    // Return a list of DailyRecordFields
    //
    function getFields (options) {
      return elementService.callEndpoint('DailyRecordField', {
        'endpoint': 'get_fields',
        'forceAPI': _.get(options, 'forceAPI', true),
        'filters': {
          'date': _.get(options, 'date'),
          'office_id': _.get(options, 'office_id'),
        },
      }).then(function (response) {
        var fields = elementService.createMultiple('DailyRecordField', response.data);
        return fields;
      });
    }

    //
    // Get a single DailyFieldRecord by ID
    //
    function getField (id, options) {
      options = angular.extend({
        'forceAPI': true,
      }, options);
      return elementService.get('DailyRecordField', id, options);
    }

    //
    // Fetch the DailyRecordFields that are applicable for a specific date.
    //
    function getFieldsForDate (date) {
      return getFields({
        'date': date,
      }).then(function (fields) {
        return _.values(fields);
      });
    }

    //
    // Process a list of field objects, calling updateField or newField as
    // appropriate. Please note that this uses the Q.allSettled interface,
    // which always hits the promise's success callback, but passes a list the
    // gives you the result of each item in the array (e.g. result.state ===
    // 'fulfilled' or result.state === 'rejected'). This is because $q.all
    // immediately hits the reject() callback as soon as the first promise
    // fails, and doesn't give you feedback on any of the other promises.
    //
    function batchFieldUpdate (fields) {
      return Q.allSettled(_.map(fields, function (field) {
        return field.id ? updateField(field) : newField(field);
      }));
    }

    //
    // Fetch the Collections report from the API.
    //
    // - 'filter' and 'date' are required.
    // - 'filter' can be one of 'year', 'month', 'day'
    //
    function getCollectionsReport (filter, date, office_id) {
      return elementService.callEndpoint('dailyRecord', {
        'endpoint': 'collections_report',
        'filters': {
          'filter': filter,
          'date': date,
          'office_id': office_id
        }
      }).then(function (response) {
        return response.data;
      });
    }

    //
    // Fetch the Production report from the API.
    //
    // (office_id is optional)
    //
    function getProductionReport (filter, month, office_id) {
      return elementService.callEndpoint('dailyRecord', {
        'endpoint': 'production_report',
        'filters': {
          'month': month,
          'office_id': office_id
        }
      }).then(function (response) {
        return response.data;
      });
    }

    //
    // Fetch the Well-Being report from the API.
    //
    // - 'filter' and 'date' are required.
    // - 'filter' can be one of 'year', 'month', 'day'
    //
    function getWellBeingReport (filter, date, office_id) {
      return elementService.callEndpoint('dailyRecord', {
        'endpoint': 'wellbeing_report',
        'filters': {
          'filter': filter,
          'date': date,
          'office_id': office_id
        }
      }).then(function (response) {
        return response.data;
      });
    }

    //
    // Fetch the Financial report from the API.
    //
    // - 'filter' and 'date' are required.
    // - 'filter' can be one of 'year', 'month', 'day'
    //
    function getFinancialReport (filter, date, office_id) {
      return elementService.callEndpoint('dailyRecord', {
        'endpoint': 'financial_report',
        'filters': {
          'filter': filter,
          'date': date,
          'office_id': office_id
        }
      }).then(function (response) {
        return response.data;
      });
    }

    //
    // Fetch the Procedure report from the API.
    //
    // - 'filter' and 'date' are required.
    // - 'filter' can be one of 'year', 'month', 'day'
    //
    function getProcedureReport (filter, date, office_id) {
      return elementService.callEndpoint('dailyRecord', {
        'endpoint': 'procedure_report',
        'filters': {
          'filter': filter,
          'date': date,
          'office_id': office_id
        }
      }).then(function (response) {
        return response.data;
      });
    }

    function getDailyStatusReport () {
      return elementService.callEndpoint('dailyRecord', {
        'endpoint': 'get_daily_status_report',
      }).then(function (response) {
        return response.data;
      });
    }
  }
}());

//Mixin for mdAutocomplete to facilitate md-blur functionality
angular.module("app.shared.support")
  .directive('mdBlur', function ($mdUtil, $timeout) {
    return {
      require: "^mdAutocomplete",
      link: function ($scope, $element, $attributes, $mdAutocompleteCtrl) {
        $timeout(function () {
          var input = $element.find("input");
          var element = $element[0];
          var nativeBlur = $mdAutocompleteCtrl.blur;
          $mdAutocompleteCtrl.blur = function () {
            nativeBlur.call($mdAutocompleteCtrl);
            $mdUtil.nextTick(function () {
              $scope.$eval($attributes.mdBlur, { "$mdAutocomplete": $mdAutocompleteCtrl });
            });
          };
        });
      }
    };
  });
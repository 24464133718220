(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.registration')
    .controller('PendingInvitesCtrl', PendingInvitesCtrl);

  function PendingInvitesCtrl ($scope, $log, $state, $stateParams, accountService, sessionService, membershipService, errorService) {
    /*jshint validthis: true */
    var ctrl = this;

    $scope.pending_invites = angular.copy($stateParams.pending_invites);
    $scope.acceptInvite = acceptInvite;
    $scope.declineInvite = declineInvite;

    initialize();

    return ctrl;

    function initialize () {
      $scope.$watch('pending_invites.length', function (newLength) {
        if (newLength < 1) {
          $state.go('app.registration.request_invite');
        }
      });
    }

    function acceptInvite (invite) {
      var office_id = _.get(invite, 'office.id');

      return membershipService.suppressSwitcher(function () {
        return accountService.acceptMembershipInvite(invite.id).then(function (invite) {
          return sessionService.refreshSession().then(function (session) {
            return membershipService.setMembershipFromOfficeId(office_id).then(function () {
              return $state.go('app.inventory');
            });
          });
        }).catch(function (error) {
          errorService.uiErrorHandler(error);
        });
      });
    }

    function declineInvite (invite) {
      return accountService.declineMembershipInvite(invite.id).then(function () {
        //
        // Remove the declined invite from the list.
        //
        $scope.pending_invites = $scope.pending_invites.filter(function (_invite) {
          return _invite.id !== invite.id;
        });
      }).catch(function (error) {
        errorService.uiErrorHandler(error);
      });
    }
  }
}());

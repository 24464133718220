(function () {
  'use strict';

  angular.module('sowOrders')
    .service('sowShipmentService', sowShipmentService);

  function sowShipmentService(serverAPI, apiUrl) {
    /*jshint validthis: true */
    var service = this;

    service.receiveItems = receiveItems;

    return service;

    function receiveItems(order) {
      var url = _getUrl('/received', order.order_hrid, order.shipment_id);
      var params = {
        'id': order.shipment_id,
        'do_import': _.get(order, 'do_import', false),
        'items': order.items,
        'received_on': order.receiving_date,
      };
      if (order.update_inventory) {
        _.forEach(params.items, function (item) {
          _.set(item, 'update_inventory', true);
        });
      }
      var options = { 'method': 'PUT', 'data': params };
      return _callAPI(url, options);
    }

    /**
     * duplicated everywhere.
     */
    function _callAPI(url, params) {
      return serverAPI
        .doAPICall(url, params)
        .then(function (response) {
          return response.data;
        });
    }
    function _getUrl(path, order_hrid, shipment_id) {
      path = path || '';
      return '{0}/orders/{1}/shipments/{2}{3}'.format(apiUrl, order_hrid, shipment_id, path);
    }
  }

}());
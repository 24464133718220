(function () {
  'use strict';

  angular.module('sowInventory')
    .directive('sowAgItemOnHand', sowInventoryAgGridItemOnHandDirective);

  function sowInventoryAgGridItemOnHandDirective() {
    return {
      restrict: 'E',
      replace: true,
      templateUrl: 'sow-inventory/ag-directives/ag-item-on-hand.html',
      controller: 'sowInventoryItemOnHandController',
      controllerAs: 'iioCtrl',
      scope: {
        'item': '=',
      }
    };
  }

}());

angular.module("app.marketplace.elements")
.service("membershipModel", ["apiUrl", "MembershipTypes", function(apiUrl, MembershipTypes) {
  var _this = this;
  this.model_data = {
    "name" : "membership",
    "display_name" : "Membership",
    "api" : {
      "single" : function(options){ 
      },
      "current" : function(options){ 
        return apiUrl + "/me/memberships/current";
      },
      "set_current" : function(options){ 
        if(!checkNested(options,'membership_id')){
          throw "Missing option(s) for account path 'set_current'. Options: " + JSON.stringify(options);
        }
        return {
          path : apiUrl + "/me/memberships/current",
          params : {
            method : "PUT",
            data : {
              membership_id : options.membership_id
            }
          }
        };
      }

    }

  };

  this.autofill = function(membership) {
    membership.membership_display_name = MembershipTypes[membership.membership_type];
  };
  
  this.fields = {
    "id" : {
      "display_name" : "id",
      "required" : true,
      "api" : {
        "submit" : true
      },
      "type" : "integer"
    }
  };

  this.data = {
    "params" : {
      "model_name" : "membership"
    }
  };

} ]);
(function () {
  'use strict';

  angular
    .module('sowSterilization')
    .service('sowMachineService', sowMachineService);

  function sowMachineService (officeService, serverAPI, apiUrl) {
    /*jshint validthis: true */
    var service = this;

    service.createSterilizer = createSterilizer;
    service.getSterilizers = getSterilizers;
    service.updateSterilizer = updateSterilizer;
    service.deleteSterilizer = deleteSterilizer;
    service.createUltrasonicCleaner = createUltrasonicCleaner;
    service.getUltrasonicCleaners = getUltrasonicCleaners;
    service.updateUltrasonicCleaner = updateUltrasonicCleaner;
    service.deleteUltrasonicCleaner = deleteUltrasonicCleaner;
    service.getWasherDisinfectors = getWasherDisinfectors;
    service.updateWasherDisinfector = updateWasherDisinfector;
    service.createWasherDisinfector = createWasherDisinfector;
    service.deleteWasherDisinfector = deleteWasherDisinfector;
    service.deleteMachine = deleteMachine;

    return service;

    /**
     * A simple convenience wrapper around the call to send the request to the
     * API. It makes the functions simple than having this code block
     * duplicated everywhere.
     */
    function _callAPI (url, params) {
      return serverAPI
        .doAPICall(url, params)
        .then(function (response) {
          return response.data;
        });
    }

    /**
     * Generate a full API url. Since the prefix of these urls requires extra
     * work (pulling in and inserting the office id), it makes sense for us to
     * centralize that bit.
     */
    function _getUrl (path) {
      path = path || '';
      return '{0}/offices/{1}{2}'.format(apiUrl, sow.officeInfo().id, path);
    }

    /**
     * Create a Sterilizer machine
     * Method: POST
     * Parameters: data
     * data = {"name": String, "machine_type": String, "machine_serial": String}
     * example: data = {"name":"Biosonic UC300 Cleaner",
     *                  "machine_type":"Sterilizer",
     *                  "machine_serial": "1212UC001"}
     */
    function createSterilizer (data) {
      var url = _getUrl('/sterilizers');
      var params = {'method': 'POST', 'data': data};
      return _callAPI(url, params);
    }

    /**
     * Fetch a listing of Sterilizer 
     * Method: GET
     * Parameters: none
     * Return type:  Array
     * example: Array(2)
                      0:{id:"xiJTsyHoktvJk7ax9PZ3fa",
                         is_deleted:false,
                         machine_serial:"NM0034",
                         machine_type:"Sterilizer",
                         name:"Another New Machine",
                         office_id:"off_zWdpMG99QPokBhDtXTF5WQ"}
                      1:{id:""NVhZowmPShcrZgvCNUzQTZ"",
                         is_deleted:false,
                         machine_serial:"NM0030",
                         machine_type:"Sterilizer",
                         name:"New Machine",
                         office_id:"off_zWdpMG99QPokBhDtXTF5WQ"}
     */
    function getSterilizers () {
      var filters = {
        timezone: _getTimeZone(),
      };
      var url = _getUrl('/sterilizers');
      var params = {'method': 'GET', 'params': filters};
      return _callAPI(url, params);
    }

    /**
     * Update a single Sterilizer
     * Method: PUT
     * Parameters: id, data
     * id = String
     * data = {"name": String, "machine_type": String, "machine_serial": String}
     * example: id = "xiJTsyHoktvJk7ax9PZ3fa",
     *          data = {"name":"Biosonic UC301 Cleaner",
     *                  "machine_type":"Washer Disinfector",
     *                  "machine_serial": "0012UC002"}
     */
    function updateSterilizer (data) {
      var url = _getUrl('/sterilizers/{0}'.format(data.id));
      var params = {'method': 'PUT', 'data': data};
      return _callAPI(url, params);
    }

    /**
     * Delete a single Sterilizer
     * Method: DELETE
     * Parameters: id
     * id = String
     * example: id = "xiJTsyHoktvJk7ax9PZ3fa"
     */
    function deleteSterilizer (id) {
      var url = _getUrl('/sterilizers/{0}'.format(id));
      var params = {'method': 'DELETE'};
      return _callAPI(url, params);
    }

    /**
     * Create a Ultrasonic Cleaner sterilizer
     * Method: POST
     * Parameters: data
     * data = {"name": String, "machine_type": String, "machine_serial": String}
     * example: data = {"name":"Biosonic UC300 Cleaner",
     *                  "machine_type":"Ultrasonic Cleaner",
     *                  "machine_serial": "1212UC001"}
     */
    function createUltrasonicCleaner (data) {
      var url = _getUrl('/ultrasonic_cleaners');
      var params = {'method': 'POST', 'data': data};
      return _callAPI(url, params);
    }

    /**
     * Fetch a listing of Ultrasonic Cleaner sterilizers
     * Method: GET
     * Parameters: none
     * Return type:  Array
     * example: Array(2)
                      0:{id:"xiJTsyHoktvJk7ax9PZ3fa",
                         is_deleted:false,
                         machine_serial:"NM0034",
                         machine_type:"Ultrasonic Cleaner",
                         name:"Another New Machine",
                         office_id:"off_zWdpMG99QPokBhDtXTF5WQ"}
                      1:{id:""NVhZowmPShcrZgvCNUzQTZ"",
                         is_deleted:false,
                         machine_serial:"NM0030",
                         machine_type:"Ultrasonic Cleaner",
                         name:"New Machine",
                         office_id:"off_zWdpMG99QPokBhDtXTF5WQ"}
     */
    function getUltrasonicCleaners () {
      var filters = {
        timezone: _getTimeZone(),
      };
      var url = _getUrl('/ultrasonic_cleaners');
      var params = {'method': 'GET', 'params': filters};
      return _callAPI(url, params);
    }

    /**
     * Update a single Ultrasonic Cleaner sterilizer
     * Method: PUT
     * Parameters: id, data
     * id = String
     * data = {"name": String, "machine_type": String, "machine_serial": String}
     * example: id = "xiJTsyHoktvJk7ax9PZ3fa",
     *          data = {"name":"Biosonic UC301 Cleaner",
     *                  "machine_type":"Washer Disinfector",
     *                  "machine_serial": "0012UC002"}
     */
    function updateUltrasonicCleaner (data) {
      var url = _getUrl('/ultrasonic_cleaners/{0}'.format(data.id));
      var params = {'method': 'PUT', 'data': data};
      return _callAPI(url, params);
    }

    /**
     * Delete a single Ultrasonic Cleaner sterilizer
     * Method: DELETE
     * Parameters: id
     * id = String
     * example: id = "xiJTsyHoktvJk7ax9PZ3fa"
     */
    function deleteUltrasonicCleaner (id) {
      var url = _getUrl('/ultrasonic_cleaners/{0}'.format(id));
      var params = {'method': 'DELETE'};
      return _callAPI(url, params);
    }

    /**
     * Create a Washer Disinfector sterilizer
     * Method: POST
     * Parameters: data
     * data = {"name": String, "machine_type": String, "machine_serial": String}
     * example: data = {"name":"PG 8581 [WW AD LD 208V]",
     *                  "machine_type":"Washer Disinfector",
     *                  "machine_serial": "1214122T2"}
     */
    function createWasherDisinfector (data) {
      var url = _getUrl('/washer_disinfectors');
      var params = {'method': 'POST', 'data': data};
      return _callAPI(url, params);
    }

    /**
     * Fetch a listing of Washer Disinfector sterilizers
     * Method: GET
     * Parameters: none
     * example: * example: Array(2)
                      0:{id:"xiJTsyHoktvJk7ax9PZ3fa",
                         is_deleted:false,
                         machine_serial:"NM0030",
                         machine_type:"Washer Disinfector",
                         name:"Another New Machine",
                         office_id:"off_zWdpMG99QPokBhDtXTF5WQ",
                         cycle_number:1}
                      1:{id:""NVhZowmPShcrZgvCNUzQTZ"",
                         is_deleted:false,
                         machine_serial:"WD0030",
                         machine_type:"Washer Disinfector",
                         name:"New Machine",
                         office_id:"off_zWdpMG99QPokBhDtXTF5WQ",
                         cycle_number:1}
     */
    function getWasherDisinfectors () {
      var filters = {
        timezone: _getTimeZone(),
      };
      var url = _getUrl('/washer_disinfectors');
      var params = {'method': 'GET', 'params': filters};
      return _callAPI(url, params);
    }

    /**
     * Update a single Washer Disinfector sterilizer
     * Method: PUT
     * Parameters: id, data
     * id = String
     * data = {"name": String, "machine_type": String, "machine_serial": String}
     * example: id = "fVx7CPAP4pVzAtMvxuQwCb",
     *          data = {"name":"PG 001 [DD AD LD 111V]",
     *                  "machine_type":"Washer Disinfector",
     *                  "machine_serial": "973497497TT"}
     */
    function updateWasherDisinfector (data) {
      var url = _getUrl('/washer_disinfectors/{0}'.format(data.id));
      var params = {'method': 'PUT', 'data': data};
      return _callAPI(url, params);
    }

    /**
     * Delete a single Washer Disinfector sterilizer
     * Method: DELETE
     * Parameters: id
     * id = String
     * example: id = "fVx7CPAP4pVzAtMvxuQwCb"
     */
    function deleteWasherDisinfector (id) {
      var url = _getUrl('/washer_disinfectors/{0}'.format(id));
      var params = {'method': 'DELETE'};
      return _callAPI(url, params);
    }

    function deleteMachine (machine) {
      var callback = null;
      switch(machine.machine_type) {
        case 'Washer Disinfector':
          callback = deleteWasherDisinfector;
          break;
        case 'Ultrasonic Cleaner':
          callback = deleteUltrasonicCleaner;
          break;
        default:
          callback = deleteSterilizer;
      }

      return callback(machine.id);
    }

    function _getTimeZone() {
      return Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

  }

}());


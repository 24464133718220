(function () {
  'use strict';

  angular.module('sowMarketplace')
  .controller('marketplaceQtyInputController', marketplaceQtyInputController)
  .directive('sowQtyInput', sowQtyInput);

  function sowQtyInput () {
    return {
      restrict: 'E',
      templateUrl: 'sow-mkt/directives/mkt-quantity-input.html',
      controller: 'marketplaceQtyInputController',
      controllerAs: 'mktqiCtrl',
      scope: {
        focusFn: '<',
        isDisabled: '<',
        noDebounce: '<',
        product: '=',
        updateFn: '<',
      },
    };
  }

  function marketplaceQtyInputController ($scope) {
    const ctrl = {
      ...this,
      mode: 'dropdown',
      focus_input: false,
      setMode,
      updateValue,
      shouldDisableInteraction,
    };

    init();
    
    return ctrl;

    function init () {
      ctrl.product = $scope.product;
      if(!$scope.focusFn){
        $scope.focusFn = angular.noop;
      }
      if(!$scope.updateFn){
        $scope.updateFn = angular.noop;
      }
      if($scope.product.quantity >= 10){
        ctrl.mode = 'field';
      }
      ctrl.quantity_limit = _.get(ctrl, 'product.vendor_inventory.limit_per_order', null);
    }

    function setMode (mode) {
      mode = mode || ctrl.mode;
      _.set(ctrl, 'mode', mode);
      if(mode === 'field'){
        $scope.product.quantity = 10;
        ctrl.focus_input = true;
      }
    }

    function updateValue () {
      if(!$scope.product.id) {
        return;
      }
      var elem = angular.element('#input-'+$scope.product.id);
      if(!elem){
        return;
      }
    }

    /**
     * Indicates whether interaction with the item's quantity 
     * should be disabled based on certain conditions.
     * @return {boolean} 
     */
    function shouldDisableInteraction () {
      return $scope.isDisabled || $scope.product.updating || ctrl.quantity_limit === 1;
    }

  }

})();

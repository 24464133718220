(function () {
  'use strict';

  angular
    .module('sowTutorialVideos')
    .directive('tutorialBox', TutorialBoxDirective);

  function TutorialBoxDirective ($log) {
    return {
      'restrict': 'E',
      'transclude': true,
      'templateUrl': 'sow-tutorial-videos/tutorial-box.html',
      'controller': TutorialBoxController,
      'controllerAs': 'tbCtrl',
      'scope': {
        'controlVar': '@',
        'onDismiss': '&',
      },
      'link': function ($scope, $element, $attr, $ctrl) {
        $ctrl.init($scope.controlVar, $scope.onDismiss);
      }
    };
  }

  function TutorialBoxController ($scope, sowBrowserFlags) {
    /*jshint validthis: true */
    var ctrl = this;

    ctrl.show = false;
    ctrl.canDismiss = true;
    ctrl.flagName = undefined;

    ctrl.init = init;
    ctrl.dismiss = dismiss;

    return ctrl;

    function init (controlVar, dismissCallback) {
      ctrl.flagName = controlVar;
      ctrl.dismissCallback = dismissCallback;

      //
      // If the `control-var=""` option was passed in to the directive (and
      // therefore to this init function), then we use it; otherwise, we just
      // lock outselves into an "always on" state.
      //
      if (ctrl.flagName) {
        ctrl.show = !sowBrowserFlags.flag(ctrl.flagName);
        ctrl.canDismiss = true;
      } else {
        ctrl.show = true;
        ctrl.canDismiss = false;
      }
    }

    function dismiss (ev) {
      if (ctrl.canDismiss) {
        sowBrowserFlags.flag(ctrl.flagName, true);
        ctrl.show = false;

        if (ctrl.dismissCallback) {
          ctrl.dismissCallback();
        }
      }
    }
  }
}());

(function () {
  'use strict';
  
  angular
  .module('sowReports')
  .controller('SpendingCategoryDetailController', SpendingCategoryDetailController);
  
  function SpendingCategoryDetailController($scope, $window, $state, sowReportsService2, orderByFilter) {
    var ctrl = this;
    ctrl.isChartEmpty = false;
    ctrl.rangeOptions = [{
      name:moment().format('YYYY').toString(),
      value:moment().format('YYYY').toString()
    }];

    ctrl.resetChart = resetChart;
    ctrl.updateCurrentYear = updateCurrentYear;
    ctrl.getCategoryDetail = getCategoryDetail;
    ctrl.changeDataChart = changeDataChart;
    ctrl.setDataToChart = setDataToChart;
    ctrl.setSpendingPerMonth = setSpendingPerMonth;
    ctrl.goBack = goBack;
    ctrl.sumArray = sumArray;
    ctrl.sort = sort;
    ctrl.updateMonthYearOptions = updateMonthYearOptions;
    ctrl.goToMonthDetail = goToMonthDetail;
    ctrl.chartClick = chartClick;

    init();

    return ctrl;
    
    function init () {
      ctrl.categoryID = $state.params.categoryID;
      ctrl.selectedYear = $state.params.year;
      ctrl.categoryName = $state.params.categoryName;
      ctrl.resetChart();
      ctrl.updateMonthYearOptions();
      ctrl.getCategoryDetail(ctrl.selectedYear, ctrl.categoryID);
    }
    
    function resetChart (){
      ctrl.isChartEmpty = false;
      ctrl.currentChartShow = {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        datas: [],
        year: ctrl.selectedYear,
        categoryName: ctrl.categoryName,
        totalSpent: 0,
        average: 0,
        orderPlaced: 0,
        tableData: []
      };
    }
    
    function updateCurrentYear (){
      $window.localStorage.setItem('yearLocal', ctrl.selectedYear);
      ctrl.resetChart();
      ctrl.getCategoryDetail(ctrl.selectedYear, ctrl.categoryID);
    }
    
    function getCategoryDetail (year, categoryID){
      sowReportsService2.getCategoryDetail(year, categoryID).then(function(data){
        ctrl.changeDataChart(data);
      });
    }
    
    function changeDataChart (data){
      var report = data[0].report;
      var orders = report.orders;
      
      if(orders.length > 0){
        ctrl.setDataToChart(orders);
      }else{
        ctrl.isChartEmpty = true;
      }
    }
    
    function setDataToChart (orders){
      var data = ctrl.setSpendingPerMonth(orders).chartData;
      var tableData = ctrl.setSpendingPerMonth(orders).tableData;
      var totalSpent = data.reduce(ctrl.sumArray,0);
      var average = totalSpent/12;
      
      ctrl.currentChartShow.datas.push(data);
      ctrl.currentChartShow.tableData = ctrl.sort(tableData,'monthNumber', true);
      ctrl.currentChartShow.totalSpent = totalSpent;
      ctrl.currentChartShow.average = average;
      ctrl.currentChartShow.orderPlaced = tableData.sum("orders");
    }
    
    function setSpendingPerMonth (monthlyOrder){
      var arrayMonth =[];
      var chartData = [];
      for(var i = 1; i < 13 ; i ++){
        arrayMonth.push({
          orders: 0,
          total_spent: 0,
          month: moment(i, 'M').format('MMM')
        });
      }
      for(var i=0; i < arrayMonth.length; i++){
        for(var j=0; j < monthlyOrder.length; j++){
          var m = moment(monthlyOrder[j].month, 'MMM, YYYY').format('MMM');
          if(arrayMonth[i].month == m){
            arrayMonth[i].orders = monthlyOrder[j].orders;
            arrayMonth[i].total_spent = monthlyOrder[j].total_spent;
          }
        }
        arrayMonth[i].monthNumber = parseInt(moment(arrayMonth[i].month, 'MMM').format("M"));
        arrayMonth[i].month = moment(arrayMonth[i].month, 'MMM').format("MMMM");
      } 
      
      for(var i = 0; i < arrayMonth.length; i++){
        chartData.push(parseFloat(arrayMonth[i].total_spent));
      }
      
      if(ctrl.selectedYear == moment().year()){
        var currentMonth = moment().month()+1;
        arrayMonth.length = currentMonth;
      }
      
      var dataReturn = {
        chartData: chartData,
        tableData: arrayMonth
      }
      return dataReturn;
    }
      
    function goBack (){
      $state.go('app.reports.spending.category');
    }
    
    function sumArray (a, b){
      return a + b;
    }
    
    function sort (array, sortByName, sortOrder){
      var newArray = [];
      if(array.length > 0){
        newArray = orderByFilter(array, sortByName, sortOrder);
      }
      return newArray ;
    }
    
    function updateMonthYearOptions () {
      var first_date = moment($scope.$root.current_office.created_at).startOf('year');
      var last_date = moment();
      var month_count = last_date.diff(first_date, 'y');
      for (var i = 1; i <= month_count; i++) {
        ctrl.rangeOptions.push({
          name: moment().subtract(i,'y').format('YYYY'),
          value: moment().subtract(i,'y').format('YYYY')
        });
      }
    }
    
    function goToMonthDetail (monthNumber){
      $state.go('app.reports.spending.category-month', {categoryID: ctrl.categoryID, year: ctrl.selectedYear, month: monthNumber, categoryName: ctrl.categoryName})
    }
    
    function chartClick (points){
      if(points.length > 0){
        var month =moment(points[0]._model.label, 'MMM').format("M");
        ctrl.goToMonthDetail(month);
      }
    }
  }
}());
(function () {
  
  function ExternalVendorInventoryCP (VendorInventoryItemCP) {
    const VendorInventoryItem = VendorInventoryItemCP.getClass();
    /**
    *
    * @class ExternalVendorInventory
    * @typedef ExternalVendorInventory
    */
    class ExternalVendorInventory {
      constructor(json_data) {
        this.connected_name = json_data.connected_name;
        this.uom = json_data.uom;
        this.vendor_sku = json_data.vendor_sku;
        if(json_data.vendor_inventory){
          this.vendor_inventory = new VendorInventoryItem(json_data.vendor_inventory);
        } else {
          this.vendor_inventory = null;
        }
      }

      connected_name;
      get connected_name () {
        return this.connected_name;
      }

      uom;
      get uom () {
        return this.uom;
      }
      
      vendor_sku;
      get vendor_sku () {
        return this.vendor_sku;
      }
      
      vendor_inventory;
      get vendor_inventory () {
        return this.vendor_inventory;
      }
      
      
    }

    this.getClass = () => {
      return ExternalVendorInventory;
    }
  }
  
  angular.module('app')
    .service("ExternalVendorInventoryCP", ExternalVendorInventoryCP);
})();

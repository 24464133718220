(function () {
  'use strict';

  angular.module('sowMarketplace')
    .directive('mktAdLayout', marketplaceAdLayoutDirective);

  function marketplaceAdLayoutDirective() {
    return {
      restrict: 'E',
      templateUrl: 'sow-mkt/directives/mkt-ad-layout.html',
      scope: {
        'target': '=',
      },
    };
  }

})();
(function () {
  'use strict';

  angular
  .module('sowCertifications')
  .service('sowCertificationService', sowCertificationService);

  function sowCertificationService (serverAPI, apiUrl, errorService) {
    /*jshint validthis: true */
    var service = this;

    service.getCertifications = getCertifications;
    service.getCertification = getCertification;
    service.deleteCertification = deleteCertification;
    service.createOrUpdateCertification = createOrUpdateCertification;

    service.getCertificationLogs = getCertificationLogs;
    service.deleteCertificationLog = deleteCertificationLog;
    service.createOrUpdateCertificationLog = createOrUpdateCertificationLog;

    service.docUpload = docUpload;
    service.docRemove = docRemove;
    service.pathRemove = pathRemove;

    return service;

    /*
     * Delete a Certification Log Entry
     *
     */
    function deleteCertificationLog (certification_log) {
      var url = '{0}/certifications/logs/{1}'.format(apiUrl, certification_log.id);
      var options = {'method': 'DELETE'};

      return serverAPI
        .doAPICall(url, options)
        .then(function (response) {
          return response.data;
        });
    }

    /*
     * Delete a Certification
     *
     */
    function deleteCertification (certification) {
      var url = '{0}/certifications/{1}'.format(apiUrl, certification.id);
      var options = {'method': 'DELETE'};

      return serverAPI
        .doAPICall(url, options)
        .then(function (response) {
          return response.data;
        });
    }

    /*
     * Get a list of Certification Records
     *
     * Filters:
     *
     * - 'id' -- A list of id values to filter for. This acts as an OR-clause
     *   for all ids in the list.
     *
     * - 'category' -- Filter by a certification category.
     *
     * - 'office_id' -- Filter for all Certification Records associated with
     *   this office. (Note: The API forces this value to the id of the current
     *   office for all office sessions).
     *
     */
    function getCertifications (filters) {
      filters = filters || {};

      var url = '{0}/certifications'.format(apiUrl);
      var options = {
        'method': 'GET',
        'params': _.pick(filters, ['id', 'category', 'office_id', 'search']),
      };

      return serverAPI
        .doAPICall(url, options)
        .then(function (response) {
          return response.data;
        });
    }

    /*
     * Fetch a Single Certification Record by ID
     *
     */
    function getCertification (id) {
      var url = '{0}/certifications/{1}'.format(apiUrl, id);
      var options = {'method': 'GET'};

      return serverAPI
        .doAPICall(url, options)
        .then(function (response) {
          return response.data;
        });
    }

    /*
     * Fetch a List of Certification Log Entries
     *
     * Filters:
     *
     * - 'id' -- A list of id values to filter for. This acts as an OR-clause
     *   for all ids in the list.
     *
     * - 'certification_id' -- Filters for all Certficiation Log Entries for a
     *   particular certification record.
     *
     * - 'office_id' -- Filter for all Certification Log Entries associated
     *   with this office. (Note: The API forces this value to the id of the
     *   current office if this is an office session).
     *
     */
    function getCertificationLogs (filters) {
      filters = filters || {};
      var url = '{0}/certifications/logs'.format(apiUrl);
      var options = {
        'method': 'GET',
        'params': _.pick(filters, ['id', 'certification_id', 'office_id']),
      };

      return serverAPI
        .doAPICall(url, options)
        .then(function (response) {
          return response.data;
        });
    }

    /*
     * Create a Certification Log Entry
     *
     * Required Attributes:
     *
     * - 'certification_id' -- The id of an existing Certification. (Note: If you
     *   are updating an existing Certification Log Entry, this _must_ match
     *   the current value. It cannot be updated.)
     *
     * - 'certification_date' -- The date that the log entry is for.
     *
     * Optional Attributes:
     *
     * - 'id' -- This attribute is required if you are attempting to update an
     *   existing record rather than creating a new record. If you provide this
     *   attribute and it doesn't match an existing id on the backend, the API
     *   will return an error.
     *
     * - 'notes' -- a string containing notes from the user that are related to
     *   this log entry.
     *
     */
    function createOrUpdateCertificationLog (certification_log) {
      var attrs = ['id', 'certification_id', 'certification_date', 'notes', 'document_path'];
      var url = '{0}/certifications/logs'.format(apiUrl);
      var options = {
        'method': 'POST',
        'data': _.pick(certification_log, attrs)
      };
      if (options.data.document_path == null){
        options.data.document_path = []
      }

      return serverAPI
        .doAPICall(url, options)
        .then(function (response) {
          return response.data;
        });
    }


    /*
     * Create or Update a Certification Record
     *
     *  Required Attributes:
     *    - name
     *    - renewal_type
     *    - category
     *    - handler
     *
     *  Optional Attributes:
     *
     *    - 'id' -- This attributes is required if you are attempting to update
     *      an existing record rather than create a new record. If you provide
     *      this attribute and it doesn't match an existing id on the backend,
     *      the API will return an error.
     *
     *    - 'office_id' -- Note: defaults to the office_id of the current session's office.
     *
     */
    function createOrUpdateCertification (certification) {
      var attrs = ['id', 'name', 'office_id', 'renewal_type', 'category', 'handler', 'notes'];
      var url = '{0}/certifications'.format(apiUrl);
      var options = {
        'method': 'POST',
        'data': _.pick(certification, attrs),
      };

      return serverAPI
        .doAPICall(url, options)
        .then(function (response) {
          return response.data;
        });
    }

    function getUploadToken (certification_id, filename) {

      var url = _getUrl('/aws_utils/s3_upload_token/certifications/{0}/{1}'.format(certification_id, filename));
      var params = {'method': 'GET'};

      return _callAPI(url, params)
      .catch(function(error){
        errorService.uiErrorHandler(error);
      });
    }

    function docUpload (file_data, certification) {
      var filename = getDefaultFileName(file_data.binary.name);
      return getUploadToken(certification.id, filename)
      .then(function(aws_data){
        var post_data = _.extend(_.omit(aws_data, ['aws_url']), {'file': file_data.binary});
        var form_data = new FormData();
        _.map(Object.keys(post_data), function(key){
          form_data.append(key, post_data[key]);
        });
        var params = {
          'method': 'POST',
          'data': form_data,
          'headers':{
            'enctype': 'multipart/form-data',
            'Access-Control-Allow-Origin': '*',
            'Content-Type': undefined
          },
          'uploadEventHandlers': {
            'progress': file_data.setProgress,
          },
          'eventHandlers': {
            'progress': file_data.setProgress,
          },
        };

        return _callAPI(aws_data.aws_url, params)
        .then(function(){
          var path = "{0}{1}/{2}/{3}".format(aws_data.aws_url, sow.officeInfo().id, certification.id, filename);
          return {
            'aws_data': aws_data,
            'file_data': file_data.binary,
            'doc_path': path,
          };
        })
        .catch(function(error){
          // 403: token expired
          // 400: file larger than 50mb
          errorService.uiErrorHandler(error);
        });
      });
    }

    function getDefaultFileName (original_name) {
      var extension = _.last( _.split(original_name, '.') );
      var date = moment().utc().format('YYYYMMDD_HHmmss');
      return _.join([date,extension],'.');
    }

    function docRemove (doc_url) {
      var filename = getUrlFilename(doc_url);
      filename = _.split(filename, '/');

      return getUploadToken(filename[0],filename[1])
      .then(function(aws_data){

        return _callAPI(doc_url, { 'method': 'DELETE', 'headers': aws_data })
        .then(function(response){
          return true;
        })
        .catch(function(error){
          errorService.uiErrorHandler(error);
          return false;
        });
      });
    }

    /* Must set Content Type for a DELETE request which has a JSON body */
    function pathRemove (certification_log, path_index) {
      var attrs = ['id', 'name', 'office_id', 'renewal_type', 'category', 'handler', 'notes'];
      var url = '{0}/certifications/logs/{1}'.format(apiUrl, certification_log.id);
      var options = {
        'method': 'DELETE',
        'data': {
          'document_id': path_index
        },
        'headers':{
            'Content-Type': 'application/json'
        },
      };

      return serverAPI
        .doAPICall(url, options)
        .then(function (response) {
          return response.data;
        });
    }

    function getUrlFilename (url) {
      var arr = _.split(url, '/');
      var filename = _.join(_.takeRight(arr, 2), '/');
      return filename;
    }

    function _callAPI (url, params) {
      return serverAPI
        .doAPICall(url, params)
        .then(function (response) {
          return response.data;
        });
    }

    function _getUrl (path) {
      path = path || '';
      return '{0}/offices/{1}{2}'.format(apiUrl, sow.officeInfo().id, path);
    }

  }

}());

(function () {
  'use strict';

  angular
    .module("app.marketplace.ui.shopping")
    .controller('productController', ProductController);

  function ProductController ($scope, $state, $stateParams, $filter, sgToast, elementService, errorService, productService, cartService, TitleService, inventoryItemService, officeService) {
    /*jshint validthis: true */
    var ctrl = this;

    $scope.productId = _.get($scope, 'product.id') || $stateParams.productId || null;
    $scope.product_quantity = 1;
    $scope.current_image_index = 0;
    $scope.navToProduct = navToProduct;
    $scope.addToCart = addToCart;
    $scope.addToInventory = addToInventory;

    ctrl.sticky_vendor_id = null;
    ctrl.setStickyVendor = setStickyVendor;
    ctrl.updateDisplayedVendor = updateDisplayedVendor;
    ctrl.init = init;

    ctrl.init();

    return ctrl;

    function init () {
      //
      // If we're on the Product Detail page and there is no productId
      // parameter, we should redirect to the main shopping page.
      //
      // TODO This logic would be probably better served in an event-handler in
      // RouteService or in this module's index.js than here. We really should
      // be catching this at the $state change level.
      //
      if ($state.current.name === "app.shopping.product" && !$stateParams.productId) {
        // Redirect if no params!
        $state.go('app.shopping');
      }

      //
      // If are passed a vendor_id through the $stateParams, set it to be the
      // "sticky" (aka preferred) vendor to display.
      //
      if ($stateParams.vendor_id) {
        ctrl.sticky_vendor_id = $stateParams.vendor_id;
      }

      if ($scope.productId) {
        elementService.get('product', $scope.productId).then(function (product) {
          if (product) {
            $scope.product = product;
            $scope.jumper_product_id = product.id;
            ctrl.updateDisplayedVendor();

            //
            // We reuse this controller to display product cards in a list, so we
            // need to make sure that this is the main controller of the state
            // before we touch the document title.
            //
            if ($state.current.controller === 'productController' && $stateParams.productId === $scope.product.id) {
              TitleService.setTitle(product.name + ' » Marketplace');
            }

            if ($stateParams.productId === product.id) {
              // Check if product is in inventory.
              productService.checkInventory($scope.product); // No Error handling in case they are logged out. TODO re-evaluate?
              officeService.get(true).then(function () {productService.getOrderHistory(product);}); // Get order history if logged in.
            }
          }
        });
      } else {
        ctrl.updateDisplayedVendor();
      }
    }

    //
    // Set the sticky/preferred vendor
    //
    function setStickyVendor (vendor_id) {
      ctrl.sticky_vendor_id = vendor_id;
      ctrl.updateDisplayedVendor();
    }

    //
    // Update the Vendor that we are displaying to the user
    //
    function updateDisplayedVendor () {
      if (ctrl.sticky_vendor_id) {
        var vendor = _.find($scope.product.vendors, {'id': ctrl.sticky_vendor_id});

        if (vendor) {
          $scope.display_vendor = vendor;
          return;
        }
      }

      $scope.display_vendor = $scope.product.UI.active_vendor;
    }

    //
    // Navigate to a Product by ID
    //
    function navToProduct (productId) {
      if (productId) {
        $state.go('app.shopping.product', {productId: productId});
      }
    }

    //
    // Add a Product from a Vendor to the Cart
    //
    function addToCart (product, quantity, vendor_id) {
      cartService
        .addToCart(product.id, quantity, vendor_id)
        .then(function(response){
          sgToast.showSimple(response);
        }).catch(function (error) {
          var t_message = $filter('translate')('ERRORS.ADD_ITEM_TO_CART')
          errorService.uiErrorHandler(error || t_message, 0);
        });
    }

    //
    // Add a Product to the Inventory
    //
    function addToInventory (product, quantity) {
      inventoryItemService
        .createItemFromProduct(product, quantity)
        .then(function(response){
          var t_message = $filter('translate')('TOAST.INVENTORY_UPDATED');
          sgToast.showSimple(t_message);
          productService.checkInventory(product).then(function(item){
          }).catch(function(error){
            var t_message = $filter('translate')('ERRORS.INVENTORY_CHECK')
            errorService.uiErrorHandler(error || t_message);
          });
        }).catch(function(error){
          var t_message = $filter('translate')('ERRORS.INVENTORY_ADD_ITEM')
          errorService.uiErrorHandler(error || t_message, 0);
        });
    }
  }
}());

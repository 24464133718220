(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.creditCards')
    .service('CreditCardDialog', CreditCardDialog);

  function CreditCardDialog ($mdDialog) {
    /*jshint validthis: true */
    var service = this;

    service.show = showDialog;

    return service;

    function showDialog ($event, type, card) {
      return $mdDialog.show({
        'templateUrl': 'templates/marketplace/credit-cards/edit-credit-card-dialog.html',
        'controller': CreditCardDialogCtrl,
        'controllerAs': '$dialogCtrl',
        'targetEvent': $event,
        'escapeToClose': true,
        'parent': angular.element(document.body),
        'locals': {
          formType: type,
          card: card
        }
      });
    }
  }

  /* @ngInject */
  function CreditCardDialogCtrl ($scope, $rootScope, $timeout, $mdDialog, creditCardService, errorService, officeService, addressService, ConfigService, formType, card, formService, sowAnalyticsService) {
    /*jshint validthis: true */
    var ctrl = this;
    var officeAddress = null;
    var countriesMap = ConfigService.get('countriesMap');

    var billingAddress = {
      'address1': null,
      'address2': null,
      'city': null,
      'postal_code': null,
      'province': null,
      'country': $rootScope.current_country || {'id': 'CA', 'name': 'Canada'},
    };
    if(card){
      billingAddress = addressService.convertFromStripeAddress(card);
      card.expiry = {
          month: card.exp_month,
          year: card.exp_year
      };
    }
    
    $scope.saveAsOfficeAddress = false;
    $scope.isOfficeAddressComplete = false;
    $scope.useOfficeAddress = false;
    
    $scope.card = card ? $.extend({}, card) : {};
    
    
    $scope.countries = ConfigService.get('countries');
    $scope.provinces = [];
    $scope.formType = formType || 'add'; //Default to add credit card

    ctrl.close = closeDialog;
    ctrl.handleSubmit = handleSubmit;
    ctrl.toggleOpenSuiteField = toggleOpenSuiteField;
    ctrl.handleCheckboxChange = handleCheckboxChange;

    init();

    return ctrl;

    function init () {
      officeAddress = officeService.getOfficeAddress();
      $scope.officeAddress = officeAddress;
      $scope.isOfficeAddressComplete = addressService.validateAddress(officeAddress);
      $scope.useOfficeAddress = $scope.isOfficeAddressComplete;
      $scope.show_suite_field = $scope.card.address2 ? true : false;

      $scope.$watch('useOfficeAddress', handleUseOfficeAddressChange);
      $scope.$watch('card.country.id', handleCountryChange);
    }

    function closeDialog () {
      sowAnalyticsService.billingAddPaymentMethodCancel();
      return $mdDialog.cancel();
    }

    //
    // When the country changes, we need to:
    // - Update the list of provinces for the <md-select> control.
    // - Reset the address's province if it doesn't match the country.
    //
    function handleCountryChange (newCountryId) {
      $scope.provinces = countriesMap[newCountryId].provinces;

      var selectedProvince = _.get($scope, 'card.province');
      var selectedProvinceId = _.get($scope, 'card.province.id');

      //
      // If for some reason selectedProvince is an object with no 'id'
      // attribute, we need to clear this out lest we end up with undesirable
      // form / UI behaviour
      //
      if (!selectedProvinceId && selectedProvince) {
        $scope.card.province = null;
      }

      //
      // If there is a selected province id, we need to make sure that it
      // matches the new country. If not, we need to reset it to null. If we
      // don't do this, the <md-select> will think that the field has a value,
      // and form validation won't catch the error.
      //
      if (selectedProvinceId) {
        var provinceMatchesCountry = false;

        _.forEach($scope.provinces, function (province) {
          if (province.id === selectedProvinceId) {
            provinceMatchesCountry = true;
            return false;
          }
        });

        if (!provinceMatchesCountry) {
          $scope.card.province = null;
        }
      }
    }

    function handleUseOfficeAddressChange (newValue, oldValue) {
      var cardAddress = {};
      if (newValue) {
        angular.extend(cardAddress, officeAddress);
      } else {
        angular.extend(cardAddress, billingAddress);
      }
      delete cardAddress.id; //Avoid id issues
      angular.extend($scope.card, cardAddress);
      
    }

    //
    // Update the Office Address if the checked the appropriate box
    //
    function updateOfficeAddress () {
      return officeService.updateAddress(addressService.extractAddress($scope.card)).then(function (address) {
        return address;
      }).catch(function (error) {
        var errmsg = 'Office address not updated.';

        if (error) {
          errmsg += _.isString(error) ? (' ' + error)
                  : _.has(error, 'message') ? (' ' + error.message)
                  : '';
        }

        errorService.uiWarningHandler(errmsg, 2000, 'medium');
      });
    }

    function handleSubmit(e) {
      e.preventDefault();
      if (formService.checkRequiredFields($scope.AddCreditCardForm)) {
        const office = officeService.get();
        const plan_name = office.account_type;
        sowAnalyticsService.billingSavePaymentMethodConfirmed(plan_name);
        return formType === 'add' ? addCard() : saveChanges();
      }
    }

    function addCard () {
      if (!$scope.AddCreditCardForm.$valid) {
        $scope.AddCreditCardForm.$setSubmitted();

        $timeout(function () {
          var scrollPane = $('[name="AddCreditCardForm"] md-dialog-content');
          var scrollElem = $('[name="AddCreditCardForm"] md-input-container.md-input-invalid').first();
          var topOfElement = scrollElem.offset().top - parseInt(scrollElem.css('margin-top')) - parseInt('16px');
          var topOfScrollPane = scrollPane.scrollTop() - scrollPane.offset().top;
          scrollPane.animate({'scrollTop': topOfScrollPane + topOfElement});
        }, 100);

        return;
      }

      $scope.saving = true;

      return creditCardService.addNewCard($scope.card)
        .then(response => {
          var card = response.data;

          if ($scope.saveAsOfficeAddress) {
            return updateOfficeAddress().then(null, function (error) {
              errorService.uiWarningHandler('Office Address Not Updated.');
            })['finally'](function () {
              $mdDialog.hide(card);
            });
          } else {
            $mdDialog.hide(card);
          }
        })
        .catch(error => {
          errorService.uiErrorHandler(error, 6000);
        })
        .finally(() => {
          $scope.saving = false;
        });
    }

    function saveChanges () {
      if (!$scope.AddCreditCardForm.$valid) {
        $scope.AddCreditCardForm.$setSubmitted();

        $timeout(function () {
          var scrollPane = $('[name="AddCreditCardForm"] md-dialog-content');
          var scrollElem = $('[name="AddCreditCardForm"] md-input-container.md-input-invalid').first();
          var topOfElement = scrollElem.offset().top - parseInt(scrollElem.css('margin-top')) - parseInt('16px');
          var topOfScrollPane = scrollPane.scrollTop() - scrollPane.offset().top;
          scrollPane.animate({'scrollTop': topOfScrollPane + topOfElement});
        }, 100);

        return;
      }

      $scope.saving = true;

      return creditCardService.updateCard($scope.card).then(function (updated_card) {
        $.extend(card, updated_card);
        $mdDialog.hide(updated_card);
      }).then(null, function (error) {
        errorService.uiErrorHandler(error, 0);
      })['finally'](function () {
        $scope.saving = false;
      });
    }

    function toggleOpenSuiteField() {
      $scope.show_suite_field = true;
    }

    function handleCheckboxChange() {
      return $scope.useOfficeAddress
        ? sowAnalyticsService.billingAddressChecked()
        : sowAnalyticsService.billingAddressUnchecked();
    }

  }
}());

(function () {
  'use strict';

  angular.module('sowMarketplace')
  .controller('marketplacePromoQtyController', marketplacePromoQtyController)
  .directive('mktPromoQty', marketplacePromoQtyDirective);

  function marketplacePromoQtyDirective () {
    return {
      restrict: 'E',
      templateUrl: 'sow-mkt/directives/mkt-promo-qty.html',
      controller: 'marketplacePromoQtyController',
      controllerAs: 'mktprqCtrl',
      scope: {
        'item': '=',
        'quantity': '=',
      },
    };
  }

  function marketplacePromoQtyController ($scope, $rootScope) {
    /*jshint validthis:true*/
    var ctrl = this;

    ctrl.getFreeQty = getFreeQty;

    init();

    return ctrl;

    function init () {
      
    }

    function getFreeQty () {
      var promo_id = _.get($scope.item, 'vendor_inventory_promotion.id', null);
      var promo = (promo_id ? $scope.item.vendor_inventory_promotion : $scope.item.promotions[0])
      return Math.floor($scope.quantity / promo.promotion_properties.buy) * promo.promotion_properties.get;
    }
    
  }

})();
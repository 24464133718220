(function () {
  
  // Supplier Class Provider
  function SupplierCP () {
    /**
    *
    * @class Supplier
    * @typedef Supplier
    */
    class Supplier {
      constructor(json_data) {
        this.id = json_data.id;
        this.name = json_data.name;
        this.can_access_inventory = json_data.can_access_inventory;
        this.notes = json_data.notes;
        this.vendor_id = json_data.vendor_id;
        this.office_id = json_data.office_id;
      }

      id;
      get id () {
        return this.id;
      }

      name;
      get name () {
        return this.name;
      }
      set name (new_name) {
        this.name = new_name;
      }
      
      can_access_inventory;
      get can_access_inventory () {
        return this.can_access_inventory;
      }
      set can_access_inventory (new_can_access_inventory) {
        this.can_access_inventory = new_can_access_inventory;
      }

      notes;
      get notes () {
        return this.notes;
      }
      set notes (new_notes) {
        this.notes = new_notes;
      }
      
      vendor_id;
      get vendor_id () {
        return this.vendor_id;
      }
      set vendor_id (new_vendor_id) {
        this.vendor_id = new_vendor_id;
      }

      office_id;
      get office_id () {
        return this.office_id;
      }
      set office_id (new_office_id) {
        this.office_id = new_office_id;
      }
      
      
    }
    
    this.getClass = () => {
      return Supplier;
    }
  }
  
  angular.module('sowMarketplace')
  .service("SupplierCP", SupplierCP);
})();

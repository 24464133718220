(function () {
  "use strict";

  /** @ngInject */
  class SDSHelperService {
    /**
     * Filters and returns a list of unique SDS URLs.
     *
     * sds_urls example:
     * ```
     * [
     *  "https://s3.com/sds_documents/072806529.pdf",
     *  "[u'https://s3.com/sds_documents/072806529.pdf', u'https://s3.com/sds_documents/072806529.pdf']",
     *  "https://s3.com/sds_documents/072806529.pdf",
     * ]
     * ```
     * This function processes an array of SDS URLs, ensuring that only unique URLs are included in the returned list.
     * It also handles cases where some paths might be stringified lists.
     *
     * @param {string[]} sds_urls - An array of SDS URLs to be filtered.
     * @returns {string[]} An array of unique SDS URLs.
     */
    filterUniqueSdsUrls(sds_urls) {
      // Remove duplicates and empty strings or null values
      const unique_paths = [...new Set(sds_urls)].filter(Boolean);

      // Remove paths that start with "[u" and end with "]"
      // Due to data issue some paths are stringified lists:
      // "[u'https://s3.com/sds_documents/072806529.pdf', u'https://s3.com/sds_documents/072806529.pdf']",
      const filtered_paths = unique_paths.filter(
        (path) => !path.startsWith("[u") && !path.endsWith("]")
      );

      return filtered_paths;
    }

    /**
     * Generates an array of objects containing display names and URLs.
     *
     * @param {string[]} sds_urls - An array of URLs.
     * @returns {Object[]} An array of objects, each containing a display name and a URL.
     * @returns {string} return[].display_name - The display name derived from the URL.
     * @returns {string} return[].href - The original URL.
     */
    getDisplayNamesForUrls(sds_urls) {
      return sds_urls.map((url) => {
        return {
          display_name: this.getUrlFilename(url),
          href: url,
        };
      });
    }

    /**
     * Extracts the filename from a given URL.
     *
     * @param {string} url - The URL from which to extract the filename.
     * @returns {string} The filename extracted from the URL.
     */
    getUrlFilename (url) {
      // https://s3.com/sds_documents/072806529.pdf
      const arr = _.split(url, '/');
      // ['https:', '', 's3.com', 'sds_documents', '072806529.pdf']
      const filename = _.last(arr);
      // 072806529.pdf
      return filename;
    }
  }

  angular.module("sowSds").service("SDSHelperService", SDSHelperService);
})();

(function () {
  'use strict';

  angular.module("app")
    .config(initializeTopLevelStates);

  function initializeTopLevelStates($stateProvider, $urlRouterProvider, $rootScopeProvider, $locationProvider) {
    $locationProvider.hashPrefix('');
    // $rootScopeProvider.digestTtl(100);
    //
    // Set routing
    // previously we used to set this to a URL, but that would cause an infinite digest, so now we're 
    // navigating to the route instead
    $urlRouterProvider.otherwise(function($injector, $location){
      $injector.invoke(['$state', function($state) {
        $state.transitionTo('app.dashboard.overview');
      }]);
    });

    //Core services
    //Override of service to force child states to wait for this resolve
    var coreElementServices = ['accountService', 'officeService', 'sessionService', 'membershipService'];
    var appResolveObject = {
      // Initialization of this Fancy Application's configuration settings
      appInit: ['$rootScope', '$log', 'ConfigService', function ($rootScope, $log, ConfigService) {
        return ConfigService.waitUntilInitialized();
      }]
    };
    var initService = function (serviceName) {
      var initServiceFn = function (service) {
        return service.onInit().then(function () {
          return service;
        });
      };
      initServiceFn.$inject = [serviceName];
      return initServiceFn;
    };
    _.map(coreElementServices, function (service) {
      appResolveObject[service] = initService(service);
    });


    $stateProvider
      //
      // Root Application State
      //
      .state("app", {
        url: '/app?language',
        templateUrl: "templates/marketplace/main/mainPage.html",
        controller: "SystemController",
        resolve: appResolveObject,
        redirectTo: 'app.dashboard.overview',
      })
      .state("app.default", {
        url: '/default',
        template: "'default page'",
        controller: "DefaultController",
        data: {
          'title': 'Default Page redirect',
          'require_log_in': true
        }
      })
      .state('login', {
        'url': '/login/:toState',
        'templateUrl': 'templates/marketplace/registration/views/login-user.html',
        'controller': 'LoginFormController',
        'controllerAs': 'lfCtrl',
      })
      .state('required_login', {
        'url': '/required_login/:toState/?toParams',
        'templateUrl': 'templates/marketplace/registration/views/login-user.html',
        'controller': 'LoginFormController',
        'controllerAs': 'lfCtrl',
        'data': {
          'title': 'Login'
        },
        'params': {
          'email': null,
          'officeMembershipId': null
        }
      })
      .state('practice_login', {
        data: {
          title: 'Login',
        },
        params: {
          credentials: null,
          email: null,
          name: null,
          officeId: null,
          officeName: null,
          officeMembershipId: null,
          toState: null,
          toParams: null,
        },
        template: '<practice-login params={{$stateParams}}></practice-login>',
        url: '/practice_login?credentials&email&name&officeId&officeName&officeMembershipId&toState&toParams',
      })
      .state('forgot_password', {
        'url': '/forgot_password?credentials&email&name&officeId&officeName&officeMembershipId&toState&toParams',
        'templateUrl': 'templates/marketplace/registration/views/forgot-password.html',
        'controller': 'PasswordResetFormController',
        'controllerAs': 'prCtrl',
        params: {
          credentials: null,
          email: null,
          name: null,
          officeId: null,
          officeName: null,
          officeMembershipId: null,
          toState: null,
          toParams: null,
        },
      })
      //
      // Error State
      //
      .state('error', {
        url: '/error',
        templateUrl: "templates/marketplace/error.html",
        controller: 'errorStateController'
      })
      //
      // Testing State
      //
      .state("test", {
        url: '/test',
        controller: 'TestingController',
        templateUrl: "templates/shared/support/testing.html"
      });
  }
})();

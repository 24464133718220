(function(){
  'use strict';
  angular.module("app.marketplace.ui.purchaseorders")
    .controller('poActionBarController', poActionBarController);

  function poActionBarController($scope, $state, PurchaseOrderService, $rootScope, poHelperService, AccessService, sowAnalyticsService) {
    /*jshint validthis: true */
    var ctrl = this;
    // ctrl.poId = $state.params.poId;
    ctrl.status = $state.current.name.split('.')[2];
    ctrl.editingActive = $state.params.editingActive;
    ctrl.editEnabled = true;
    ctrl.po_loaded = false;

    ctrl.goBack = goBack;
    ctrl.deleteButton = deleteButton;
    ctrl.duplicateButton = duplicateButton;
    ctrl.editButton = editButton;
    ctrl.emailButton = emailButton;
    ctrl.getPoId = getPoId;
    ctrl.print = print;
    ctrl.shouldShowDelete = poHelperService.shouldShowDelete;

    init();

    return ctrl;

    function init () {
      handleStateChange($state.current, $state.params);
      $scope.$on('$stateChangeSuccess', handleStateChange);
      $scope.$on('po_loaded', handleLoadedPO);

      $scope.$on('po_updated', function(event, po){
        ctrl.editEnabled = !['Received', 'Cancelled'].includes(po.status)
      })
      defineLocks();
    }

    function handleStateChange (state, params) {
      if (state.name === 'app.orders.purchase.active') {
        ctrl.editEnabled = true;

        PurchaseOrderService.get(params.poId).then(function (po) {
          const cancel_edit_conditions = (
            !po.can_edit
            || ['Received', 'Cancelled'].includes(po.status)
            || po.type === 'edi'
          );
          if (cancel_edit_conditions) {
            ctrl.editEnabled = false;
          }
          if (po.status === 'Pending') {
            ctrl.is_pending_approval = true;
          }
        });
      }
    }

    function getPoId(){
      return $state.params.poId;
    }

    function goBack(){
      $state.go('app.orders.purchase.list', PurchaseOrderService.getLastStatusFilter() );
    }

    function deleteButton ($event) {
      sowAnalyticsService.purchaseOrderActionBarDeleteClicked();
      var po = {'id': getPoId(), 'UI':{} };
      //this is an extreme workaround for not making another network request.
      var hasReceivedItems = $('.po-items-header.received').length > 0;
      poHelperService.deletePoDialog(po, hasReceivedItems).then(function(result){
        ctrl.goBack();
      });
    }

    function print () {
      sowAnalyticsService.purchaseOrderActionBarPrintClicked();
      $rootScope.$broadcast('purchase_order_print');
    }

    function duplicateButton($event, page){
      sowAnalyticsService.purchaseOrderDuplicateClicked();
      $rootScope.$broadcast("duplicate_po",page);
    }

    function editButton($event, page){
      sowAnalyticsService.purchaseOrderEditClicked(page);
      $rootScope.$broadcast("edit_po",page);
    }

    function emailButton(){
      sowAnalyticsService.purchaseOrderActionBarEmailClicked();
      $rootScope.$broadcast("email_po");
    }

    function defineLocks () {
      ctrl.disabled_delete = AccessService.getProperty("purchase_orders.disable_delete");
      ctrl.disabled_delete_active = AccessService.getProperty("purchase_orders.disable_delete_active");
      ctrl.disabled_edit = AccessService.getProperty("purchase_orders.disable_edit");
      ctrl.disabled_edit_active = AccessService.getProperty("purchase_orders.disable_edit_active");
    }

    /** 
     * Sets flag when current purchase order is loaded.
     * 
     * @param {String} loaded_po_id 
     * 
     * @return {*} 
    */
    function handleLoadedPO($event, loaded_po_id, po_response) {
      if (loaded_po_id === getPoId()) {
        ctrl.po_loaded = true;
        ctrl.po = po_response;
      }
    }

  }
}());

angular.module('app.marketplace.ui.account').controller('accountPasswordController',
  function accountPasswordController($scope, $filter, sgToast, elementService, errorService, accountService) {
    var _this = this;

    $scope.passwordReset = false;

    //
    // Reset Form
    //
    _this.resetForm = function () {
      $scope.updatePasswordForm.$setPristine();
      $scope.updatePasswordForm.current_password.$setUntouched();
      $scope.updatePasswordForm.password.$setUntouched();
      $scope.updatePasswordForm.confirm_password.$setUntouched();
    };

    //
    // Send Password Reset Email
    //
    _this.sendPasswordResetEmail = function () {
      $scope.submitting = true;
      $scope.passwordReset = false;

      return accountService._resetPassword({
        email: encodeURIComponent($scope.$root.current_account.email),
      }).then(function () {
        $scope.passwordReset = true;
      }).then(null, function (error) {
        errorService.uiErrorHandler(error);
      })['finally'](function () {
        $scope.submitting = false;
      });
    };

    //
    // Update Password
    //
    _this.updatePassword = function() {
      if(!$scope.updatePasswordForm.$valid) {
        return;
      }

      if ($scope.edit_password.current_password === $scope.edit_password.password) {
        var t_message = $filter('translate')('ERRORS.PASSWORD_SAME')
        errorService.uiErrorHandler(t_message, 0);
        return false;
      }

      $scope.submitting = true;

      elementService.callEndpoint('account', {
        'endpoint': 'update_password',
        'edit_password': $scope.edit_password
      }).then(function(response) {
        $scope.submitting = false;
        var t_message = $filter('translate')('TOAST.PASSWORD_UPDATED');
        sgToast.showSimple(t_message);
        $scope.edit_password = {};
        _this.resetForm();
      }).catch(function(error) {
        $scope.submitting = false;
        var t_message = $filter('translate')('ERRORS.PROFILE_UPDATE')
        errorService.uiErrorHandler(error || t_message, 0);
      });
    };

    //
    // Initialization
    //
    _this.init = function(account){
      angular.extend($scope, {
        'account': account,
        'edit_account': angular.copy(account),
        'updatePassword': _this.updatePassword,
        'resetPassword': _this.sendPasswordResetEmail,
      });

      if ($scope.accountInfoForm) {
        $scope.accountInfoForm.$setPristine();
      }

      $scope.$on('accountService: set-account', function(scope, account) {
        _this.init(account);
      });

      $scope.$on('accountService: clear-account', function() {
        _this.init();
      });
    };

    _this.init(accountService.get());
  }
);

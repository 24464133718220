(function () {
  'use strict';
  angular.module('sowMarketplace')
  .controller('MarketplaceCategoriesController', MarketplaceCategoriesController);

  function MarketplaceCategoriesController(adButlerService, $window) {
    /*jshint validthis: true */
    const ctrl = this;

    ctrl.$onInit = init;

    return ctrl;

    function init () {
      const is_mobile = $window.innerWidth < 960;
      const top_ad = is_mobile ? 'leaderboard1-scaled' : 'leaderboard1';
      const bottom_ad = is_mobile ? 'leaderboard2-scaled' : 'leaderboard2';
      adButlerService.autoLoadZone(top_ad);
      adButlerService.autoLoadZone(bottom_ad);
    }

  }

}());

(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.suppliers')
    .service('SupplierDialog', SupplierDialog);

  function SupplierDialog ($mdDialog) {
    /*jshint validthis: true */
    var service = this;

    service.show = showDialog;

    return service;

    function showDialog (ev, supplier, rep, options) {
      rep = rep || {'supplier':supplier};
      options = options || {};
      return $mdDialog.show({
        'controller': SupplierDialogCtrl,
        'controllerAs': '$ctrl',
        'templateUrl': 'templates/marketplace/suppliers/supplier-dialog.html',
        'targetEvent': ev,
        'parent': angular.element(document.body),
        'clickOutsideToClose': true,
        'locals': {
          'sales_rep': rep,
          'supplier': supplier,
          'options': options,
        },
      });
    }
  }

  function SupplierDialogCtrl ($q, $scope, $rootScope, $filter, $timeout, supplier, sales_rep, options, $mdDialog, sgToast, SupplierService, membershipService, errorService) {
    /*jshint validthis: true */
    var ctrl = this;
    // options = options || null;

    $scope.lockForm = false;

    ctrl.mode = null;
    ctrl.close = closeDialog;
    ctrl.save = save;

    ctrl.show_notes = false;
    ctrl.show_account_num = false;
    ctrl.toggleOpen = toggleOpen;
    ctrl.setAccountNumValidity = setAccountNumValidity;

    init();

    return ctrl;

    function init () {
      if (supplier && supplier.id) {
        setEditMode();
      } else {
        setAddMode();
      }
      ctrl.initial_sales_rep = angular.copy(ctrl.data.sales_rep);
      defineLocks();
      $scope.lockForm = shouldFormBeLocked();
    }

    function setAddMode () {
      $scope.dialogTitle = 'PO.SUPPLIER_NEW';
      $scope.saveButtonLabel = 'ACTIONS.CREATE_SUPPLIER';
      ctrl.data = {'sales_rep':{'is_primary':true,},'supplier':{}, 'options': options};
      ctrl.addMode = true;
      ctrl.editMode = false;
    }

    function setEditMode () {
      $scope.dialogTitle = 'PO.SUPPLIER_EDIT';
      $scope.saveButtonLabel = 'PO.SUPPLIER_SAVE';
      if (!sales_rep) {
        sales_rep = {'supplier': angular.extend({},supplier), 'is_primary': true, };
      }
      ctrl.data = {'supplier': angular.extend({}, supplier), 'sales_rep': angular.extend({}, sales_rep), 'options': options};
      if (ctrl.data.supplier.notes) {
        ctrl.show_notes = true;
      }
      if (ctrl.data.supplier.vendor_customer_number) {
        ctrl.show_account_num = true;
      }
      ctrl.addMode = false;
      ctrl.editMode = true;
    }

    function closeDialog () {
      $mdDialog.cancel();
    }

    function save (ev) {
      $scope.lockForm = true;

      // Abort if the form doesn't validate.
      if (!ctrl.SupplierForm.$valid) {
        ctrl.SupplierForm.$setSubmitted(true);
        if (ctrl.SupplierForm.$error) {
          _.forEach(Object.keys(ctrl.SupplierForm.$error), function(key){
            _.forEach(ctrl.SupplierForm.$error[key], function(form_input){
              form_input.$setDirty();
              form_input.$setTouched();
            });
          });
        }
        $scope.lockForm = false;
        return $q.reject('Form validation failed.');
      }

      var method = null;
      var toast_message = null;
      let event_name;

      if (ctrl.editMode) {
        method = SupplierService.update;
        toast_message = 'PO.SUPPLIER_UPDATED';
        event_name = 'supplier_updated';
      } else {
        method = SupplierService.create;
        toast_message = 'PO.SUPPLIER_CREATED';
        event_name = 'supplier_created';
      }

      method(ctrl.data.supplier)
      .then(function(supplier_response){
        var returning_value = {'supplier': supplier_response, 'sales_rep': null };

        // kept this one for backwards compatibility, not sure where it's used
        $rootScope.$broadcast('suppliers_updated');

        // new event to pass on supplier data
        $rootScope.$broadcast(event_name, supplier_response);
        
        sgToast.showSimple($filter('translate')(toast_message));

        if(hasSalesRepChanged()) {
          ctrl.data.sales_rep = angular.extend({},ctrl.data.sales_rep, {'supplier': supplier_response });

          SupplierService.createOrUpdateSalesRep(ctrl.data.sales_rep)
          .then(function(rep_response){
            returning_value.sales_rep = rep_response;
            $rootScope.$broadcast('sales_reps_updated');

            const event_name = ctrl.data.sales_rep.id ? 'sales_rep_updated' : 'sales_rep_created';
            $rootScope.$broadcast(event_name, rep_response);
            
            $mdDialog.hide(returning_value);
          });
        } else {
          $mdDialog.hide(returning_value);
        }
      }).catch(error => {
        switch (error.internal_code) {
          case 'ACCOUNT_NUMBER_ALREADY_USED_ERROR':
            setAccountNumValidity(false);
            break;
          default:
            errorService.uiErrorHandler(error.message);
        }
      })['finally'](function () {
        $scope.lockForm = false;
      });
    }

    function setAccountNumValidity(is_unique) {
      ctrl.SupplierForm.accountNumber.$setValidity('unique', !!is_unique);
    }

    function hasSalesRepChanged() {
      for (const key of ['name', 'email', 'phone_number', 'is_primary']) {
        if (ctrl.data.sales_rep[key] !== ctrl.initial_sales_rep[key]) {
          return true;
        }
      }
      return false;
    }

    function defineLocks () {
      const membership = membershipService.get();

      const supplier_edit_condition = _.get(membership, "user_properties.suppliers.disable_edit", false);
      ctrl.disabled_supplier_edit = supplier_edit_condition;
      const supplier_add_new_condition = _.get(membership, "user_properties.suppliers.disable_add_new", false);
      ctrl.disabled_supplier_add_new = supplier_add_new_condition;

      const add_condition = _.get(membership, "user_properties.sales_reps.disable_add_new", false);
      ctrl.disabled_sales_rep_add_new = add_condition;
      const edit_condition = _.get(membership, "user_properties.sales_reps.disable_edit", false);
      ctrl.disabled_sales_rep_edit = edit_condition;
      const delete_condition = _.get(membership, "user_properties.sales_reps.disable_delete", false);
      ctrl.disabled_sales_rep_delete = delete_condition;
    }

    function shouldFormBeLocked() {
      if (ctrl.editMode) {
        return ctrl.disabled_supplier_edit;
      }
      if (ctrl.addMode) {
        return ctrl.disabled_supplier_add_new;
      }
      return false;
    }

    /** 
     * Toggles open an optional field and autofocuses on it. 
     * 
     * @param {String} section 
     * 
     * @return {*} 
    */
    function toggleOpen(section) {
      switch(section) {
        case 'notes':
          ctrl.show_notes = true;
          setFocus('supplier-dialog-notes');
          break;
        case 'account_num':
          ctrl.show_account_num = true;
          setFocus('supplier-dialog-acct-num');
          break;
        default:
          return null;
      }
    }

    /** 
     * Sets focus state of an HTML element.
     * Timeout ensures element exists. 
     * 
     * @param {String} id 
     * 
     * @return {*} 
    */
    function setFocus(id) {
      $timeout(() => {
        const element = document.getElementById(id);
        if (element?.focus) {
          element.focus();
        }
      });
    }

  }
}());

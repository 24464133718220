(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.legal')
    .service('legalService', legalService);

  function legalService ($mdDialog) {
    /*jshint validthis: true */
    var service = this;

    service.showTermsModal = showTermsModal;
    service.showPrivacyPolicyModal = showPrivacyPolicyModal;

    return service;

    function showPrivacyPolicyModal ($event) {
      return $mdDialog.show({
        templateUrl: 'templates/marketplace/legal/modals/privacy-policy.html',

        parent: angular.element(document.body),
        fullscreen: true,
        targetEvent: $event,
        clickOutsideToClose: true,

        controller: function ($scope, $mdDialog) {
          $scope.cancel = function () {
            $mdDialog.cancel();
          };
        }
      });
    }

    function showTermsModal ($event) {
      return $mdDialog.show({
        templateUrl: 'templates/marketplace/legal/modals/terms-of-service.html',

        parent: angular.element(document.body),
        fullscreen: true,
        targetEvent: $event,
        clickOutsideToClose: true,

        controller: function TermsOfServiceModalCtrl($scope, $mdDialog) {
          $scope.cancel = function () {
            $mdDialog.cancel();
          };
        }
      });
    }
  }
}());

(function () {
  'use strict';
  
  angular
  .module('sowReports')
  .controller('SupplierReportController', SupplierReportController);
  
  function SupplierReportController ($scope, $filter, officeService) {
    /*jshint validthis: true */
    var ctrl = this;
    
    ctrl.selectedRange = 'This Year';
    ctrl.rangeOptions = ['This Year'];
    var t_today = $filter('translate')('TIME.TODAY');
    ctrl.updateRange = updateRange;
    ctrl.isEmpty = false;
    ctrl.hide_chart = false;
    ctrl.chart = {};
    
    ctrl.stats = {
      'totalSpent': 0,
      'avgSpent': 0,
      'highVolumeSupplier': '',
      'highVolumeAmount': 0,
      'mostOrdersCount': 0,
      'mostOrdersSupplier': '',
      'totalShippingFees': 0,
    };
    
    ctrl.suppliers = [];
    
    ctrl.chart = {
      'data': [],
      'labels': [],
      'options': {
        'responsive': true,
        'maintainAspectRatio': true,
        'tooltips': {
          'enabled': true,
          'displayColors': false,
          'mode': 'single',
          'titleFontFamily': "'Roboto', 'Helvetica', 'Arial', 'Sans-Serif'",
          'footerFontFamily': "'Roboto', 'Helvetica', 'Arial', 'Sans-Serif'",
          'bodyFontFamily': "'Roboto', 'Helvetica', 'Arial', 'Sans-Serif'",
          'callbacks': {
            'label': function (tooltipItems, data) {
              return ' ' + $filter('currency')(tooltipItems.xLabel, '$', 2);
            },
          },
        },
        'scales': {
          'xAxes': [{
            'interval': 1000,
            'gridLines': {
              'drawTicks': true,
              'color': 'rgba(128,142,155,0.25)',
              'zeroLineColor': 'rgba(128,142,155,0.25)',
            },
            'ticks': {
              'fontFamily': "'Roboto', 'Helvetica', 'Arial', 'Sans-Serif'",
              'beginAtZero': true,
              'callback': function (value) {
                return $filter('currency')(value, '$', 0);
              },
            },
          }],
          'yAxes': [{
            'gridLines': {
              'display': false
            },
            'ticks': {
              'fontFamily': "'Roboto', 'Helvetica', 'Arial', 'Sans-Serif'",
            },
          }]
        },
        dataset: {
          barThickness: 18,
        }
      },
      'overrides': {
        'borderWidth': 0,
        'borderColor': 'rgba(51,187,196,1)',
        'backgroundColor': 'rgba(51,187,196,1)',
        'hoverBackgroundColor': 'rgba(51,187,196,0.5)',
      }
    };
    
    initialize();
    
    return ctrl;
    
    //
    // Initialize this controller
    //
    function initialize () {
      ctrl.isEmpty = true;
      updateYearOptions();
      updateRange();
    }
    
    function updateYearOptions () {
      var first_date = moment($scope.$root.current_office.created_at);
      var last_date = moment();
      var years_count = last_date.diff(first_date, 'y');
      
      if(years_count > 0){
        ctrl.rangeOptions.push('All Time');
      }
      
      for (var i = 1; i <= years_count; i++) {
        var year_number = moment().subtract(i,'y').format('YYYY');
        ctrl.rangeOptions.push(year_number);
      }
    }
    
    //
    // Update the Chart Data
    //
    function buildChart () {
      ctrl.suppliers = _.chain(ctrl.suppliers)
      .sortBy(function (item) { return parseFloat(item.total_spent, 10); })
      .reverse()
      .value();
      
      ctrl.chart.data = _.map(ctrl.suppliers.slice(0, 6), 'total_spent');
      ctrl.chart.labels = _.map(ctrl.suppliers.slice(0, 6), 'supplier_name');
    }
    
    //
    // Rebuild All of the Supplier Stats
    //
    function rebuildStats (newStats) {
      ctrl.suppliers = newStats;
      ctrl.isEmpty = ( _.isNil(newStats) || !_.size(newStats) );
      
      ctrl.stats.totalSpent = 0;
      ctrl.stats.totalShippingFees = 0;
      ctrl.stats.mostOrdersCount = 0;
      ctrl.stats.mostOrdersSupplier = '';
      ctrl.stats.highVolumeAmount = 0;
      ctrl.stats.highVolumeSupplier = '';
      ctrl.stats.avgSpent = 0;
      var months = {};
      
      _.forEach(newStats, function (supplier) {
        ctrl.stats.totalSpent += parseFloat(supplier['total_spent'] || 0, 10);
        ctrl.stats.totalShippingFees += parseFloat(supplier['shipping_fee_total'] || 0, 10);
        
        _.forEach(supplier.months, function (spent, month) {
          months[month] = (months[month] || 0) + spent;
        });
        
        if (supplier.order_count > ctrl.stats.mostOrdersCount) {
          ctrl.stats.mostOrdersCount = supplier.order_count;
          ctrl.stats.mostOrdersSupplier = supplier.supplier_name;
        }
        
        if (supplier.total_spent > ctrl.stats.highVolumeAmount) {
          ctrl.stats.highVolumeAmount = supplier.total_spent;
          ctrl.stats.highVolumeSupplier = supplier.supplier_name;
        }
      });
      
      var monthCount = 0;
      var spentTotal = 0;
      
      _.forEach(months, function (spent) {
        if (spent > 0) {
          monthCount++;
          spentTotal += spent;
        }
      });
      
      ctrl.stats.avgSpent = spentTotal / monthCount;
      ctrl.hide_chart = (parseInt(spentTotal) === 0);
    }
    
    function updateRange () {
      if (ctrl.selectedRange === 'This Year') {
        ctrl.startMonth = moment().startOf('year');
        ctrl.endMonth = moment();
      }
      
      else if (ctrl.selectedRange === 'All Time') {
        var currentOffice = $scope.$root.current_office;
        ctrl.startMonth = moment(currentOffice.created_at);
        ctrl.endMonth = moment();
      }
      
      else if (ctrl.selectedRange*1) {
        ctrl.startMonth = moment(ctrl.selectedRange+'-01-01').startOf('y');
        ctrl.endMonth = moment(ctrl.selectedRange+'-01-01').endOf('y');
      }
      
      else {
        ctrl.startMonth = null;
        ctrl.endMonth = null;
        return;
      }
      
      return officeService.getSupplierStats(
        ctrl.startMonth.format(),
        ctrl.endMonth.endOf('month').format()
        )
        .then(function (stats) {
          rebuildStats(stats);
          buildChart();
          updateRangeLabels();
        });
    }
      
    function updateRangeLabels () {
      var format = 'MMMM YYYY';
      
      ctrl.startMonthLabel = (
        moment.isMoment(ctrl.startMonth) ? ctrl.startMonth.format(format)
        : ctrl.startMonth instanceof Date ? moment(ctrl.startMonth).format(format)
        : null
        );
        
      ctrl.endMonthLabel = (
        isToday(ctrl.endMonth) ? t_today
        : moment.isMoment(ctrl.endMonth) ? ctrl.endMonth.format(format)
        : ctrl.endMonth instanceof Date ? moment(ctrl.endMonth).format(format)
        : null
        );
    }
    
    function isToday (date) {
      date = (
        moment.isMoment(date) ? date
        : date instanceof Date ? moment(date)
        : null
        );
        return date && date.isSame(moment(), 'day');
    }
  }
}());
        
(function () {
  'use strict';
  
  angular
  .module('sowReports')
  .controller('ProductDetailReportController', ProductDetailReportController);
  
  function ProductDetailReportController ($scope, $filter, $stateParams, officeService, errorService, ChartJSDefaults) {
    /*jshint validthis: true */
    var ctrl = this;
    
    ctrl.state = 'report';
    ctrl.supplierTable = $scope.supplierTable = {};
    ctrl.product = null;
    ctrl.productId = $scope.productId = $stateParams.productId;
    ctrl.selectedRange = 'This Year';
    ctrl.rangeOptions = ['This Year', 'All Time'];
    var t_today = $filter('translate')('TIME.TODAY');

    ctrl.updateRange = updateRange;
    
    ctrl.onHandReport = {
      'data': [],
      // 'labels': MonthShortLabels,
      'colors': [
        ['rgba(57, 142, 227, 1)'],
        ['rgba(101, 219, 255, 1)'],
      ],
      'options': ChartJSDefaults.createLineChartOptions({
        'legend': {'display': false},
        'tooltips': {
          'callbacks': {
            'title': function (tooltipItems, data) {
              var date = tooltipItems[0].xLabel;
              return date.format('MMMM Do YYYY');
            },
          },
        },
        'scales': {
          'xAxes': [{
            'type': 'time',
            'position': 'bottom',
            'time': {'unit': 'month'},
          }],
        },
      }),
      'overrides': [
        ChartJSDefaults.createLineChartOverride('rgba(57, 142, 227, 1)'),
        ChartJSDefaults.createLineChartOverride('rgba(101, 219, 255, 1)'),
      ],
    };
    
    ctrl.addedToInventoryReport = {
      'data': [],
      'options': ChartJSDefaults.createBarChartOptions({}),
      'overrides': ChartJSDefaults.createBarChartOverride('#33be8c'),
    };
    
    ctrl.removedFromInventoryReport = {
      'data': [],
      'options': ChartJSDefaults.createBarChartOptions({}),
      'overrides': ChartJSDefaults.createBarChartOverride('#fba65b'),
    };
    
    initialize();
    
    return ctrl;
    
    //
    // Initialize the controller.
    //
    function initialize () {
      updateRange();
    }
    
    //
    // Update Supplier Table Information
    //
    function updateSupplierTable () {
      var suppliers = ctrl.product.supplier_summary;
      var data = [];
      
      if (suppliers.length > 0) {
        data = _.map(suppliers, function (supplier) {
          return [supplier.supplier_name, supplier.order_count];
        });
      }
      // else if (ctrl.product.supplier_name) {
      //   data = [
      //     [ctrl.product.supplier_name, 0]
      //   ];
      // }
      
      angular.extend(ctrl.supplierTable, {
        'hasSupplier': data.length > 0,
        'hasOrders': suppliers.length > 0,
        'data': data,
      });
    }
    
    //
    // Compute the Avg. Usage Stats, and Predicted Inventory Levels
    //
    function updateAvgUsageStats () {
      var firstMonthWithData = _.chain(ctrl.product.in_and_out)
      .map('date')
      .map(function (item) { return new Date(item); })
      .min()
      .value();
      
      var lastMonthWithData = _.chain(ctrl.product.in_and_out)
      .map('date')
      .map(function (item) { return new Date(item); })
      .max()
      .value();
      
      var totalMonths = Math.abs(
        moment
        .utc(firstMonthWithData)
        .startOf('month')
        .diff(lastMonthWithData, 'months')
        ) + 1;
        
      var totalDays = Math.abs(
        moment
        .utc(firstMonthWithData)
        .diff(lastMonthWithData, 'days')
        );
          
      var totalOut = _
      .chain(ctrl.product.in_and_out)
      .reduce(function (p, c) { return p + (c['out'] || 0); }, 0)
      .value();
          
      // This handles division by zero --------.
      //                                       |
      var monthlyUsage = totalOut / (totalMonths || 1);
      var dailyUsage = totalOut / (totalDays || 1);
          
      ctrl.avgMonthlyUsage = calculateInventoryAmounts(monthlyUsage);
      ctrl.hasMinLevel = ctrl.product.min_level >= 0;
      ctrl.minLevelWarning = ctrl.hasMinLevel && ctrl.product.on_hand <= ctrl.product.min_level;
      ctrl.isZeroLevel = ctrl.product.on_hand <= 0;
      ctrl.noEstimates = (dailyUsage <= 0);
      
      if (!ctrl.noEstimates) {
        ctrl.daysToMinLevel = Math.ceil(Math.abs(ctrl.product.on_hand - ctrl.product.min_level) / Math.ceil(dailyUsage));
        ctrl.daysToZeroLevel = Math.ceil(ctrl.product.on_hand / Math.ceil(dailyUsage));
        ctrl.estMinLevelDate = moment.utc().add(ctrl.daysToMinLevel, 'days').toDate();
        ctrl.estZeroLevelDate = moment.utc().add(ctrl.daysToZeroLevel, 'days').toDate();
      }
    }
        
    //
    // Rebuild the "Removed From Inventory" Report
    //
    function buildInventoryOutReport (dataset) {
      var currentYear = moment.utc(moment().startOf('year').format('YYYY-MM-DD'));
      var lastYear = currentYear.clone().subtract(1, 'year');
      var data = {};
      var includeLastYear = ctrl.selectedRange === 'All Time' && ctrl.startMonth.year() <= lastYear.year();
      var minDate;
      
      _.forEach(dataset, function (item) {
        var date = moment.utc(item['date']);
        var month = date.format('YYYY-MM');
        var inventoryOut = calculateInventoryAmounts(item.out);
        
        if (currentYear.isSame(date, 'year')) {
          data[month] = (data[month] || 0) + inventoryOut;
        }
        
        else if (lastYear.isSame(date, 'year') && includeLastYear) {
          data[month] = (data[month] || 0) + inventoryOut;
        }
        
        if (!minDate) {
          minDate = date.clone();
        } else if (minDate.isAfter(date)) {
          minDate = date.clone();
        }
      });
      
      
      //
      // Build Labels
      //
      var currentMonth = minDate.clone().startOf('month');
      var lastMonth = moment.utc().startOf('month');
      var labels = [];
      var chartData = [];
      
      while (currentMonth.isBefore(lastMonth) || currentMonth.isSame(lastMonth)) {
        labels.push(currentMonth.format('MMM YYYY'));
        chartData.push(data[currentMonth.format('YYYY-MM')] || 0);
        currentMonth.add(1, 'month');
      }
      
      ctrl.removedFromInventoryReport.labels = labels;
      ctrl.removedFromInventoryReport.data = chartData;
    }
        
    //
    // Rebuild the "Added to Inventory" Report
    //
    function buildInventoryInReport (dataset) {
      var currentYear = moment.utc(moment().startOf('year').format('YYYY-MM-DD'));
      var lastYear = currentYear.clone().subtract(1, 'year');
      var data = {};
      var includeLastYear = ctrl.selectedRange === 'All Time' && ctrl.startMonth.year() <= lastYear.year();
      var minDate;
      
      _.forEach(dataset, function (item) {
        var date = moment.utc(item['date']);
        var month = date.format('YYYY-MM');
        var inventoryIn = calculateInventoryAmounts(item.in);
        
        if (currentYear.isSame(date, 'year')) {
          data[month] = (data[month] || 0) + inventoryIn;
        }
        
        else if (lastYear.isSame(date, 'year') && includeLastYear) {
          data[month] = (data[month] || 0) + inventoryIn;
        }
        
        if (!minDate) {
          minDate = date.clone();
        } else if (minDate.isAfter(date)) {
          minDate = date.clone();
        }
      });
      
      
      //
      // Build Labels
      //
      var currentMonth = minDate.clone().startOf('month');
      var lastMonth = moment.utc().startOf('month');
      var labels = [];
      var chartData = [];
      
      while (currentMonth.isBefore(lastMonth) || currentMonth.isSame(lastMonth)) {
        labels.push(currentMonth.format('MMM YYYY'));
        chartData.push(data[currentMonth.format('YYYY-MM')] || 0);
        currentMonth.add(1, 'month');
      }
      
      ctrl.addedToInventoryReport.labels = labels;
      ctrl.addedToInventoryReport.data = chartData;
    }
    
    //
    // Convert a date to a floating-point number between 1 and 13 that
    // represents the date's position within the year.
    //
    function dateToDecimal (date) {
      date = moment.utc(date);
      return (date.month() + 1) + (date.date() / date.daysInMonth());
    }
    
    function decimalToDate (dec, year) {
      var date = moment.utc();
      date.year(year);
      date.month(Math.floor(dec) - 1);
      date.date(Math.ceil(
        (dec - Math.floor(dec)) * date.daysInMonth()
        ));
        return date;
    }
      
    //
    // Rebuild the "Inventory On Hand" Report
    //
    function buildInventoryOnHandReport (dataset) {
      var currentYear = moment.utc(moment().startOf('year').format('YYYY-MM-DD'));
      var lastYear = currentYear.clone().subtract(1, 'year');
      var currentYearData = Array();
      var lastYearData = Array();
      var includeLastYear = ctrl.selectedRange === 'All Time' && ctrl.startMonth.year() <= lastYear.year();
      
      ctrl.onHandReport.data = [currentYearData];
      
      _.forEach(dataset, function (item) {
        var date = moment.utc(item['date']);
        var net = item.net;
        
        if (currentYear.isSame(date, 'year')) {
          currentYearData.push({
            'x': moment.utc(date),
            'y': calculateInventoryAmounts(net),
          });
        }
        
        else if (lastYear.isSame(date, 'year') && includeLastYear) {
          currentYearData.push({
            'x': moment.utc(date),
            'y': calculateInventoryAmounts(net)
          });
        }
      });
      
    }
    
    function calculateInventoryAmounts(value) {
      if (!value) {
        return 0;
      }
      
      if (ctrl.product.tracking_method === 'Package') {
        return value / ctrl.product.order_package_quantity;
      }
      
      return value;
    }
    
    //
    // Rebuild Data After Source Data has Change (e.g. loading a different range)
    //
    function rebuildData () {
      var dataset = ctrl.product.in_and_out;
      buildInventoryInReport(dataset);
      buildInventoryOutReport(dataset);
      buildInventoryOnHandReport(dataset);
    }
          
    //
    // Update Data for the New Date Range
    //
    function updateRange () {
      ctrl.state = 'loading';
      
      if (ctrl.selectedRange === 'This Year') {
        ctrl.startMonth = moment.utc().startOf('year');
        ctrl.endMonth = moment.utc();
      } else if (ctrl.selectedRange === 'All Time') {
        var currentOffice = $scope.$root.current_office;
        ctrl.startMonth = moment.utc(currentOffice.created_at);
        ctrl.endMonth = moment.utc();
      } else {
        ctrl.startMonth = null;
        ctrl.endMonth = null;
        return;
      }
      
      return officeService.getProductStats(
        ctrl.productId,
        ctrl.startMonth.format(),
        ctrl.endMonth.clone().endOf('day').format())
        .then(function (productStats) {
          ctrl.product = $scope.product = productStats;
          ctrl.state = 'report';
          rebuildData();
          updateSupplierTable();
          updateAvgUsageStats();
          updateRangeLabels();
          
          ctrl.priceIsNaN = isNaN(parseInt($scope.product.price, 10));
          
        }).catch(function (error) {
          ctrl.state = 'error';
          errorService.uiErrorHandler(error);
        });
    }
            
    //
    // Update The Display Labels for the Date Range
    //
    function updateRangeLabels () {
      var format = 'MMMM YYYY';
      
      ctrl.startMonthLabel = (
        moment.isMoment(ctrl.startMonth) ? ctrl.startMonth.format(format)
        : ctrl.startMonth instanceof Date ? moment.utc(ctrl.startMonth).format(format)
        : null
      );
        
      ctrl.endMonthLabel = (
        isToday(ctrl.endMonth) ? t_today
        : moment.isMoment(ctrl.endMonth) ? ctrl.endMonth.format(format)
        : ctrl.endMonth instanceof Date ? moment.utc(ctrl.endMonth).format(format)
        : null
      );
    }
        
    //
    // Just a quick check if a date is for today.
    //
    function isToday (date) {
      date = (
        moment.isMoment(date) ? date
        : date instanceof Date ? moment.utc(date)
        : null
      );
      return date && date.isSame(moment.utc(), 'day');
    }
  }
}());
              
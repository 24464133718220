(function () {
  'use strict';

  /** @ngInject */
  function sowApprovalDialogCtrl ($scope, $mdDialog, sowApprovalService, errorService, formService) {
    // constructor {
    //   this.$scope = $scope;
    //   this.$mdDialog = $mdDialog;
    //   this.sowApprovalService = sowApprovalService;
    // }
    let ctrl = this;
    ctrl.state = 'form';
    ctrl.response = null;

    ctrl.closeDialog = closeDialog;
    ctrl.finishDialog = finishDialog;
    ctrl.save = save;

    return ctrl;

    function closeDialog () {
      return $mdDialog.cancel();
    }

    function save () {
      if (!formService.checkRequiredFields(ctrl.RequestForm)){
        return;
      }
      ctrl.disable_form = true;
      enterLoadingState();
      return sowApprovalService.create(ctrl.request)
      .then((response) => {
        // success
        ctrl.response = response;
        enterFinishedState();
      })
      .catch((err) => {
        // fail
        errorService.uiErrorHandler(err);
        closeDialog();
      })
      .finally(() => {
        ctrl.disable_form = false;    
      });
    }

    function enterLoadingState () {
      ctrl.state = 'loading';
    }

    function enterFinishedState () {
      ctrl.state = 'finished';
    }

    function finishDialog () {
      $mdDialog.hide(ctrl.response);
    }
  }

  angular.module('sowApprovals')
    .controller('sowApprovalDialogCtrl', sowApprovalDialogCtrl);

})();

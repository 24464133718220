(function () {
  
  function VendorCP () {
    /**
    *
    * @class Vendor
    * @typedef Vendor
    */
    class Vendor {
      constructor(json_data) {
        this.id = json_data.id;
        this.name = json_data.name;
        this.image = json_data.image;
        this.is_active = json_data.is_active;
        this.url_name = json_data.url_name;
      }

      id;
      get id () {
        return this.id;
      }

      name;
      get name () {
        return this.name;
      }
      
      image;
      get image () {
        return this.image;
      }

      is_active;
      get is_active () {
        return this.is_active;
      }

      url_name;
      get url_name () {
        return this.url_name;
      }
    }
    
    this.getClass = () => {
      return Vendor;
    }
  }
  
  angular.module('sowMarketplace')
  .service("VendorCP", VendorCP);
})();

(function () {
  'use strict';

  angular.module('sowInventory')
  .service('inventoryHelperService', inventoryHelperService);

  function inventoryHelperService ($rootScope, $filter, inventoryItemService, appEvents) {
    var service = this;

    service.generateTooltip = generateTooltip;
    service.setItemAlerts = setItemAlerts;
    service.itemSlideout = itemSlideout;
    service.checkItem = checkItem;
    service.getTrackingLabel = getTrackingLabel;
    service.getBuyingLabel = getBuyingLabel;
    service.shouldShowExpiryAlert = shouldShowExpiryAlert;
    service.shouldShowDraftPOStatus = shouldShowDraftPOStatus;

    return service;

    /**
     * Generate tooltip text based on a list of PO statuses
     *
     * @param {*} poStatusList
     * @return {*} 
     */
    function generateTooltip (poStatusList) {
      if (!poStatusList) {
        return '';
      }
      var t_in = $filter('translate')('COMMON.IN');
      var t_and = $filter('translate')('COMMON.AND');
      var t_from = $filter('translate')('COMMON.FROM');

      var poText = _.map(poStatusList, function (item) {
        return item.po_name || item.order_hrid;
      });

      // return early if there is no order text to display
      if (!poText?.length) {
        return '';
      }

      return t_in+' ' + _.compact([
        poText.slice(0, -1).join(', '),
        poText.slice(-1),
      ]).join(', ' + t_and + ' ');
    }

    /**
     * Set an item's errors (eg expired/expiring soon, min level, etc)
     *
     * @param {*} item
     */
    function setItemAlerts (item) {
      var actual_qty = inventoryItemService.calculateInventoryOnHand(item) || 0;
      var valid_minimum = _.isNumber(item.minimum_level) && item.minimum_level >= 0;
      var is_below = actual_qty <= item.minimum_level;
      if (valid_minimum && is_below) {
        _.set(item, 'errors.below_minimum_level', true);
      }
      if ( item.expiry_date ) {
        var expiry_limit = moment().add(30, 'days');
        var is_expired = moment().isSameOrAfter(moment(item.expiry_date));
        var is_soon = moment(item.expiry_date).diff(expiry_limit, 'days') <= 30;
        _.set(item, 'errors.expired', is_expired);
        _.set(item, 'errors.expiring_soon', is_soon);
      }
    }

    /**
     * Open a slideout for details on the `inventoryItem`
     *
     * @param {*} inventoryItem
     * @param {*} event
     */
    function itemSlideout (inventoryItem, event) {
      if(event && event.stopPropagation)
        event.stopPropagation();
      $rootScope.$broadcast('inventory-item-slideout', null, inventoryItem.id, 'details');
    }

    /**
     * Mark the `inventoryItem` as checked in the main service
     * (mostly used for action bars since it has shared scope)
     *
     * @param {*} inventoryItem
     * @param {*} $event
     * @return {*} 
     */
    function checkItem (inventoryItem, $event) {
      if($event.stopPropagation)
        $event.stopPropagation();
      if(!inventoryItem)
        return;

      return inventoryItemService.checkItem(inventoryItem);
    }

    /**
     * Get the localized label for an `inventoryItem`'s tracking method
     *
     * @param {*} inventoryItem
     * @return {*} 
     */
    function getTrackingLabel (inventoryItem) {
      const tracking_method = _.get(inventoryItem, 'tracking_method') || 'ITEM';
      var t_label = $filter('translate')('COMMON.'+tracking_method.toUpperCase());
      return t_label;
    }

    /**
     * Get the localized label for an `inventoryItem`'s buying method
     *
     * @param {*} inventoryItem
     * @return {*} 
     */
    function getBuyingLabel (inventoryItem) {
      let label;
      if (inventoryItem.buy_by_case) {
        label = "PACKAGE";
      } else {
        label = "ITEM";
      }
      var t_label = $filter('translate')('COMMON.'+label);
      return t_label;
    }

    /**
     * If the item has expired or is expiring soon, then show the expiry alert
     * @param {object} item
     * @return {boolean}
     */
    function shouldShowExpiryAlert(item){
      const should_show_alert = item.has_expired_item || item.errors.expired || item.errors.expiring_soon;
      return should_show_alert;
    }

    /**
     * "If there are draft PO's and no active PO's, then show the draft PO status."
     * 
     * @param {object} item
     * @returns {boolean}
     */
    function shouldShowDraftPOStatus(item) {
      const active_qty = _.get(item, 'po_status.Active.length', 0);
      const draft_qty = _.get(item, 'po_status.Draft.length', 0);
      return draft_qty > 0 && active_qty === 0;
    }
  }

}());

angular.module("app.shared.support")
  //Replaces header with custom html header if provided
  .directive('inboxStyleBundleHeaderTransclude', function ($log) {
    return {
      require: ['?^inboxStyleBundle'],
      link: function (scope, element, attrs, controller) {
        scope.$watch(function () { return controller[0].heading; }, function (heading) {
          if (heading) {
            element.html('');
            element.append(heading);
          }
        });
      }
    };
  });
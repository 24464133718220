angular.module("app.marketplace.elements")
.service("returnService", function($rootScope, $q, elementService, returnModel) { 
  var _this = this;
  _this.returns = null;
  _this.initialized = false;

  var _setReturns = function(returns){
    _this.returns = returns;
    $rootScope.$broadcast('returnsService: set-returns', returns);
    _this.initialized = true;
  };
  var _clearReturns = function(){
    _this.returns = null;
    $rootScope.$broadcast('returnsService: clear-returns');
    _this.initialized = true;
  };
  //Convience function for waiting for initialized
  var _waitForInitialized = function(){
    if(_this.initialized){
      return $q.resolve(_this.returns);
    }else{
      if(!_this._initCalled){
        _this._init();
      }
      var deferred = $q.defer();
      var initWatch = $rootScope.$watch(function(){ 
        return _this.initialized; 
      }, function(newInitializedValue, oldInitializedValue){
        if(newInitializedValue){
          deferred.resolve(_this.returns);
          initWatch(); //Stop watch;
        }
      });
      return deferred.promise;
    }
  };
  
  var _getReturns = function(waitForInitialized){
    if(waitForInitialized){
      return _waitForInitialized()
      .then(function(returns){
        if(returns){
          return returns;
        }else if($rootScope.current_account){
          return $q.reject("No returns");
        }else{
          return $q.reject("Not logged in");
        }
      });
    }else{
      return  _this.returns;
    }
  };

  var _refresh = function(){
    if($rootScope.current_office){
      _this.initialized = false;
      angular.copy({}, elementService.elementMaps['return']); //Clear previous items
      return elementService.getElements('return', {'forceAPI' : true})
      .then(function(returns){
        _setReturns(returns);
        return returns;
      });
    }else{
      _clearReturns();
      return $q.reject("No office");
    }
  };
  _this._init = function(){
    _this._initCalled = true;
    if($rootScope.current_office){
      _refresh();
    }
    $rootScope.$on('officeService: set-office', function(event, office){
      _refresh();
    });
    $rootScope.$on('officeService: clear-office', function(){
      _clearReturns();
    });
  };
  
  return {
    init : _this._init,
    get : _getReturns,
    refresh : _refresh
    
  };
});

(function () {
  'use strict';
  
  angular
  .module('sowReports')
  .controller('ImplantsPlacementDoctorController', ImplantsPlacementDoctorController);
  
  function ImplantsPlacementDoctorController($scope, $state, $filter, orderByFilter, sowImplantPlacementService) {
    var ctrl = this;
    ctrl.propertyName = 'productTotal';
    ctrl.reverse = false;
    ctrl.selectedYear = moment().year();
    ctrl.rangeOptions = [{
      name: moment().year(),
      value: moment().year()
    }];
    ctrl.a = 0 ;

    ctrl.calTotal = calTotal;
    ctrl.calYearTotal = calYearTotal;
    ctrl.changeReferDoctorData = changeReferDoctorData;
    ctrl.currenMonth = currenMonth;
    ctrl.dfOneYear = dfOneYear;
    ctrl.getreferDoctorList = getreferDoctorList;
    ctrl.goToDetail = goToDetail;
    ctrl.onChangeYear = onChangeYear;
    ctrl.setDataForMonth = setDataForMonth;
    ctrl.sortBy = sortBy;
    ctrl.updateYearOptions = updateYearOptions;

    init();

    return ctrl;
    
    function init () {
      ctrl.updateYearOptions();
      ctrl.getreferDoctorList();
    }
    
    function getreferDoctorList () {
      var start_date = moment(ctrl.selectedYear, 'YYYY').startOf('y').format('YYYY-MM-DD');
      var end_date = moment(ctrl.selectedYear, 'YYYY').endOf('y').format('YYYY-MM-DD');
      var previous_start_date = moment(ctrl.selectedYear, 'YYYY').subtract(1, 'y').startOf('y').format('YYYY-MM-DD');
      var pervious_end_date = moment(ctrl.selectedYear, 'YYYY').subtract(1, 'y').endOf('y').format('YYYY-MM-DD');
      
      sowImplantPlacementService.getReferringDoctorReport(start_date, end_date, previous_start_date, pervious_end_date).then(function (data) {
        ctrl.changeReferDoctorData(data);
      })
    }
    
    function dfOneYear () {
      var y = {
        jan: 0,
        feb: 0,
        mar: 0,
        apr: 0,
        may: 0,
        jun: 0,
        jul: 0,
        aug: 0,
        sep: 0,
        oct: 0,
        nov: 0,
        dec: 0
      }
      return y;
    }
    
    function currenMonth (){
      var monthMoment = moment().month();
      var monthArr = [];
      for(var i = monthMoment+2; i < 13 ; i++){
        var monthName = moment(i, 'MM').format('MMM').toLowerCase();
        monthArr.push(monthName);
      }
      var currentYear = angular.copy(ctrl.dfOneYear());
      
      monthArr.forEach(function(el){
        currentYear[el] = '-';
      });
      return currentYear;
    }
    
    function setDataForMonth (data, year, keys) {
      var currentYear = angular.copy(ctrl.dfOneYear());
      if(year == moment().year()){
        currentYear = ctrl.currenMonth();
      }
      var yearMoment = moment().year();
      
      if(year == yearMoment){
        currentYear = ctrl.currenMonth();
      }
      if (data.length > 0) {
        data.forEach(function(el){
          var month_name = moment(el.month, 'MMM, YYYY').format('MMM').toLowerCase();
          if (month_name in currentYear) {
            currentYear[month_name] = el[keys];
          }
        });
      }
      var yearObj = Object.values(angular.copy(currentYear));
      yearObj.forEach(function(el, index){
        if(el == '-'){
          yearObj[index] = 0;
        }
      })
      
      currentYear.total = _.sum(yearObj);
      currentYear.name = year;
      return currentYear;
    }
    
    function changeReferDoctorData (data) {
      var current = data.current;
      var previous = data.previous;
      var referDoctorList = {};
      
      ctrl.currenMonth();
      
      if (current.length > 0) {
        current.forEach(function(element){
          element.year = [];
          
          var currentYear = ctrl.setDataForMonth(element.patients_referred, ctrl.selectedYear, 'referral_count');
          var previousYear = angular.copy(ctrl.dfOneYear());
          previousYear.name = ctrl.selectedYear - 1;
          previousYear.total = 0;
          
          element.year.push(currentYear);
          element.year.push(previousYear);
          referDoctorList[element.referring_doctor_id] = element;
        });
      }
      
      if (previous.length > 0) {
        previous.forEach(function(element){
          var id = element.referring_doctor_id;
          if (id in referDoctorList) {
            var previousYear = ctrl.setDataForMonth(element.patients_referred, ctrl.selectedYear - 1, 'placed_count');
            referDoctorList[id].year[1] = previousYear;
          } else {
            element.year = [];
            
            var previousYear = ctrl.setDataForMonth(element.patients_referred, ctrl.selectedYear - 1, 'placed_count');
            
            var yearMoment = moment().year();
            var currentYear = angular.copy(ctrl.dfOneYear());
            
            if(ctrl.selectedYear == yearMoment){
              currentYear = ctrl.currenMonth();
            }
            currentYear.name = ctrl.selectedYear;
            currentYear.total = 0;
            
            element.year.push(currentYear);
            element.year.push(previousYear);
            referDoctorList[id] = element;
          }
        });
      }
      
      var referDoctorArr = Object.values(referDoctorList);
      ctrl.data = ctrl.calTotal(referDoctorArr);
      
      ctrl.totalYear = ctrl.calYearTotal(referDoctorArr);
      ctrl.sortBy(ctrl.propertyName);
    }
    
    function calTotal (referDoctorArr) {
      referDoctorArr.forEach(function(element){
        element.percent = {
          jan: {
            color: '',
            value: ''
          },
          feb: {
            color: '',
            value: ''
          },
          mar: {
            color: '',
            value: ''
          },
          apr: {
            color: '',
            value: ''
          },
          may: {
            color: '',
            value: '',
          },
          jun: {
            color: '',
            value: ''
          },
          jul: {
            color: '',
            value: ''
          },
          aug: {
            color: '',
            value: ''
          },
          sep: {
            color: '',
            value: ''
          },
          oct: {
            color: '',
            value: ''
          },
          nov: {
            color: '',
            value: ''
          },
          dec: {
            color: '',
            value: ''
          },
          total: {
            color: '',
            value: ''
          }
          
        };
        var year = element.year;
        var yArr = Object.keys(year[0]);
        yArr.forEach(function (el) {
          if (el != 'name') {
            if (year[0][el] == '-') {
              element.percent[el].color = 'df-color';
              element.percent[el].value = '-';
            } else {
              if (year[1][el] > 0) {
                var val = (parseFloat(year[0][el]) - parseFloat(year[1][el])) * 100;
                if (val != 0 && parseFloat(year[1][el]) != 0) {
                  val = val / parseFloat(year[1][el]);
                }
                
                if (val > 0) {
                  element.percent[el].color = 'green-color';
                } else if (val < 0) {
                  element.percent[el].color = 'red-color';
                } else {
                  element.percent[el].color = 'df-color';
                }
              } else {
                if(year[0][el] == 0){
                  var val = '-';
                  element.percent[el].color = 'green-color';
                }else{
                  var val = '';
                  element.percent[el].color = 'green-color';
                }
                
              }
              element.percent[el].value = val;
            }
          }
        });
      });
      
      var max = 0;
      var referring_doctor_id = '';
      referDoctorArr.forEach(function(element){
        var mo = [];
        if(element.year[0].name == moment().year()){
          for(var i = 1; i <= moment().month() + 1; i++){
            mo.push(moment(i, 'M').format('MMM').toLowerCase());
          }
        }else{
          mo = Object.keys(ctrl.dfOneYear());
        }
        
        element.topProduct = false;
        element.producerNumber = element.patients_referred.length;
        
        var count = 0;
        mo.forEach(function(el){
          count += element.year[0][el];
        });
        
        element.productTotal = count;
        if(count > max){
          max = count;
          referring_doctor_id = element.referring_doctor_id;
        }
        
      });
      referDoctorArr.forEach(function(element){
        if(element.referring_doctor_id == referring_doctor_id){
          element.topProduct = true;
        }
      });
      
      return referDoctorArr;
    }
    
    function calYearTotal (referDoctorArr){
      var keys = ["jan",
      "feb",
      "mar",
      "apr",
      "may",
      "jun",
      "jul",
      "aug",
      "sep",
      "oct",
      "nov",
      "dec","total"]
      var item = {year: []};
      _.each(referDoctorArr[0]?.year, function(d){
        item.year.push({});
      })
      _.each(referDoctorArr, function(d, i){
        _.each(d.year, function(d2, j) {
          _.each(keys, function(k) {
            if(!item.year[j][k]){
              item.year[j][k] = 0;
            }
            if(d2[k] != '-'){
              item.year[j][k] += d2[k];
            }else{
              item.year[j][k] = '-';
            }
          });
        });
      });
      
      item.name = $filter('translate')('REPORTS.PLACEMENT.YEARLY_TOTAL');
      _.set(item, 'year[0].name', moment(ctrl.selectedYear, 'YYYY').format('YYYY'));
      _.set(item, 'year[1].name', moment(ctrl.selectedYear, 'YYYY').subtract(1, 'y').format('YYYY'));
      item.percent = {
        jan: {
          color: '',
          value: ''
        },
        feb: {
          color: '',
          value: ''
        },
        mar: {
          color: '',
          value: ''
        },
        apr: {
          color: '',
          value: ''
        },
        may: {
          color: '',
          value: '',
        },
        jun: {
          color: '',
          value: ''
        },
        jul: {
          color: '',
          value: ''
        },
        aug: {
          color: '',
          value: ''
        },
        sep: {
          color: '',
          value: ''
        },
        oct: {
          color: '',
          value: ''
        },
        nov: {
          color: '',
          value: ''
        },
        dec: {
          color: '',
          value: ''
        },
        total: {
          color: '',
          value: ''
        }
        
      };
      
      var yArr = _.keys(item.year[0]);
      yArr.forEach(function (el) {
        if (el != 'name') {
          if (item.year[0][el] == '-') {
            item.percent[el].color = 'df-color';
            item.percent[el].value = '-';
          } else {
            if (item.year[1][el] > 0) {
              var val = (parseFloat(item.year[0][el]) - parseFloat(item.year[1][el])) * 100;
              if (val != 0 && parseFloat(item.year[1][el]) != 0) {
                val = val / parseFloat(item.year[1][el]);
              }
              
              if (val > 0) {
                item.percent[el].color = 'green-color';
              } else if (val < 0) {
                item.percent[el].color = 'red-color';
              } else {
                item.percent[el].color = 'df-color';
              }
            } else {
              if(item.year[0][el] == 0){
                var val = '-';
                item.percent[el].color = 'green-color';
              }else{
                var val = '';
                item.percent[el].color = 'green-color';
              }
              
            }
            
            item.percent[el].value = val;
          }
        }
      });
      return item;
    }
    
    function sortBy (propertyName) {
      ctrl.reverse = (ctrl.propertyName === propertyName) ? !ctrl.reverse : false;
      ctrl.propertyName = propertyName;
      var allProduct = ctrl.data;
      if (allProduct.length > 0) {
        ctrl.data = orderByFilter(allProduct, ctrl.propertyName, ctrl.reverse);
      }
    }
    
    function updateYearOptions () {
      var first_date = moment($scope.$root.current_office.created_at);
      var last_date = moment();
      var years_count = last_date.diff(first_date, 'y');
      
      for (var i = 1; i <= years_count; i++) {
        var year_number = moment().subtract(i, 'y').format('YYYY');
        ctrl.rangeOptions.push({
          name: year_number,
          value: year_number
        });
      }
    }
    
    function onChangeYear () {
      ctrl.getreferDoctorList();
    }
    
    function goToDetail (id, name){
      $state.go('app.reports.referring-doctor-detail', {id: id, name : name, year: ctrl.selectedYear});
    }
  }
}());



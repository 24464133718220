
angular.module('app.marketplace.system')
  .service('RedirectModalService', function ($mdDialog, $filter) {
    var _this = this;

    function translate(key) {
      return $filter('translate')(key);
    }

    function _getModals() {
      var _modals = {
        LeavePage: {
          title: translate('DIALOGS.LEAVE_PAGE'),
          html: translate('DIALOGS.LEAVE_INFO'),
          confirmText: translate('ACTIONS.LEAVE'),
          cancelText: translate('ACTIONS.STAY')
        },
        SaveChanges: {
          title: translate('DIALOGS.SAVE_CHANGES'),
          html: translate('DIALOGS.SAVE_INFO'),
          confirmText: translate('ACTIONS.SAVE'), // Save-function linking not implemented in redirect handlers.
          cancelText: translate('ACTIONS.DISCARD')
        },
        CancelUpdate: {
          title: translate('DIALOGS.CANCEL_UPDATE'),
          html: translate('DIALOGS.CANCEL_INFO'),
          confirmText: translate('COMMON.YES'),
          cancelText: translate('COMMON.NO')
        }
      };
      return _modals;
    }

    var _show = function ($event, type, options) {
      return _newModal($event, _getModals()[type]);
    };

    var _newModal = function ($event, options) {
      var confirm = $mdDialog.confirm()
        .title(options.title)
        .htmlContent(options.html)
        .targetEvent($event)
        .ok(options.confirmText || translate('COMMON.YES'))
        .cancel(options.cancelText || translate('COMMON.NO'));
      return $mdDialog.show(confirm);
    };

    return {
      $modal: null,
      show: _show
    };
  });
angular.module("app.marketplace.ui.shopping")
  .controller("shoppingController",
    function ($scope, $q, $log, $filter, $state, $stateParams, productService, categoryService, vendorService) {
      var _this = this;
      $scope.categories = {};
      $scope.suppliers = {};
      if ($scope.$root.features.aaoms_marketplace && $state.includes('app.shopping')) {
        $state.go('app.shopping_aaoms');
      }
      if (!$scope.$root.features.aaoms_marketplace && $state.includes('app.shopping_aaoms')) {
        $state.go('app.shopping');
      }

      _this.loadSuppliers = function () {
        $scope.suppliers.loading = true;
        $scope.suppliers.error = null;

        return vendorService.getAllVendors().then(function (vendors) {
          $scope.suppliers.loading = false;
          $scope.suppliers.list = vendors;
        }).catch(function (error) {
          $scope.suppliers.loading = false;
          $scope.suppliers.error = error;
          $scope.suppliers.list = null;
        });
      };

      _this.loadCategories = function () {
        var defer = $q.defer();
        $scope.categories.loading = true;
        $scope.categories.promise = defer.promise;

        return categoryService.getCategoriesWithProducts().then(function (categories) {
          $scope.categories.array = categories;
          $scope.categories.filtered = $filter('filter')($scope.categories.array, { 'parent_id': null });
          $scope.categories.top = $filter('filterMedicalField')($scope.categories.filtered, $scope.marketplace_medical_field);
          $scope.categories.loading = false;
          defer.resolve($scope.categories);
          return $scope.categories;
        }).catch(function (error) {
          defer.reject(error);
          throw error;
        });
      };

      _this.loadFeaturedProducts = function () {
        return productService.getFeaturedProducts().then(function (products) {
          $scope.products_featured = products;
          $scope.products_featured_array = _.values(products);
          $scope.products_featured_array = $filter('filterProducts')($scope.products_featured_array);
        });
      };

      _this.loadOnSaleProducts = function () {
        return productService.getOnSaleProducts().then(function (products) {
          $scope.products_on_sale = products;
          $scope.products_on_sale_array = _.values(products);
          $scope.products_on_sale_array = $filter('filterProducts')($scope.products_on_sale_array);
        });
      };

      //Watch medical field state param and update local value. Use current_medical_field if null.
      if (!$stateParams.medicalField ||
        ($scope.current_account && $scope.current_account.id &&
          $stateParams.medicalField !== $scope.current_medical_field.id)) {
        $state.go($state.current, { 'medicalField': $scope.current_medical_field && $scope.current_medical_field.id ? $scope.current_medical_field.id : $scope.$root.marketplace_medical_field.id });
      }
      $scope.$root.marketplace_medical_field = $stateParams.medicalField ? $scope.config.medical_fields_map[$stateParams.medicalField] : null;
      $scope.$watch(function () { 
        return $stateParams.medicalField; 
      }, function (newMedicalFieldValue, oldMedicalFieldValue) {
        $scope.$root.marketplace_medical_field = $scope.config.medical_fields_map[newMedicalFieldValue];
      });

      //
      // Initialization
      //
      _this.loadCategories();
      _this.loadFeaturedProducts();
      _this.loadOnSaleProducts();
      _this.loadSuppliers();
    });

(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.shopping')
    .directive('actionBarShopping', ShoppingActionBar);

  function ShoppingActionBar () {
    return {
      restrict: 'AC',
      replace: true,
      templateUrl: 'templates/marketplace/shopping/directives/action-bar-shopping.html',
      controller: ShoppingActionBarController,
    };
  }

  function ShoppingActionBarController ($scope, $mdMenu, categoryService, vendorService, NavDropdownMenuService) {
    /*jshint validthis: true */
    var ctrl = this;

    $scope.suppliers = {};
    $scope.categories_array = [];
    $scope.toggleMenu = toggleMenu;
    $scope.clickAway = clickAway;

    init();

    return ctrl;

    function init () {
      loadCategories();
      loadSuppliers();
    }

    function clickAway (name) {
      if (NavDropdownMenuService.isMenuActive(name)) {
        NavDropdownMenuService.hideMenu(name);
      }
    }

    function toggleMenu (name) {
      NavDropdownMenuService.toggleMenu(name);
    }

    function loadCategories () {
      return categoryService.getCategoriesWithProducts().then(function (categories) {
        $scope.categories_array = categories;
      });
    }

    function loadSuppliers () {
      $scope.suppliers.loading = true;

      vendorService.getAllVendors().then(function (vendors) {
        $scope.suppliers.loading = false;
        $scope.suppliers.list = vendors;
      }).catch(function (error) {
        $scope.suppliers.loading = false;
        $scope.suppliers.error = error;
        $scope.suppliers.list = null;
      });

    }
  }

}());

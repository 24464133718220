angular.module('app.marketplace.ui.contentBlock')
  .directive('contentBlocks', function () {
    return {
      restrict: 'E',
      replace: true,
      templateUrl: 'templates/marketplace/content-block/directives/content-blocks.html',
      controller: 'contentBlocksController',
      scope: {
        'type': '@',
        'restriction': '@'
      }
    };
  })
  .controller('contentBlocksController', function ($scope, $q, $filter, $timeout, $state, contentBlockService) {
    $timeout(function () {
      contentBlockService.get(true)
        .then(function (contentBlocks) {
          var filteredContentBlocks = [];

          _.map(contentBlocks, function (block, b) {
            var pushed = false;
            //Check current url
            for (var j in block["display_from_urls"]) {
              var field_value = block["display_from_urls"][j];
              if (field_value === location.hash) {
                filteredContentBlocks.push(block);
                pushed = true;
                break;
              }
            }

            if ($scope.restriction && !pushed) {
              var restriction_value = "";
              var restriction_field = "";
              if ($scope.restriction.indexOf("url:") === 0) {
                restriction_value = $scope.restriction.replace("url:", "");
                restriction_field = "display_from_urls";
              } else if ($scope.restriction.indexOf("category:") === 0) {
                restriction_value = $scope.restriction.replace("category:", "");
                restriction_field = "display_from_categories";
              } else if ($scope.restriction.indexOf("product:") === 0) {
                restriction_value = $scope.restriction.replace("product:", "");
                restriction_field = "display_from_products";
              }

              if (restriction_value && restriction_field) {
                //Check passed restriction url
                _.map(block[restriction_field], function (field_value2, i) {
                  if ((restriction_field === "display_from_products" || restriction_field === "display_from_categories") && field_value2.id === restriction_value) {
                    filteredContentBlocks.push(block);
                  } else if (restriction_field === "display_from_urls" && field_value2 === restriction_value && restriction_value !== "current") {
                    filteredContentBlocks.push(block);
                  }
                });
              }
            }
          });

          $scope.filtered_blocks = filteredContentBlocks;

          //Filter according to type and order them.
          var filteredBlocks = [];
          _.map($scope.filtered_blocks, function (block2, c) {
            if (!$scope.type) {
              filteredBlocks.push(block2);
            } else if ($scope.type === 'before' && block2.layout_order_index < 0) {
              filteredBlocks.push(block2);
            } else if ($scope.type === 'after' && block2.layout_order_index > 0) {
              filteredBlocks.push(block2);
            }
          });

          $scope.filtered_blocks = $filter('orderBy')(filteredBlocks, 'layout_order_index');

          return $scope.filtered_blocks;
        });
    });
  });

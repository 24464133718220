angular.module('app.marketplace.ui.contentBlock')
  .directive('contentBlock', function () {
    return {
      restrict: 'E',
      replace: true,
      templateUrl: 'templates/marketplace/content-block/directives/content-block.html',
      controller: 'contentBlockController'
    };
  })
  .controller('contentBlockController', function ($scope, $q, $state, $window, $sce) {
    $scope.imageClicked = function () {
      if ($scope.block.link_url) {
        if ($scope.block.link_url[0] === "#") {
          $window.open($scope.block.link_url, "_self");
        } else {
          $window.open($scope.block.link_url);
        }
      } else {
        $state.go('app.shopping.content_block', { 'blockId': $scope.block.id });
      }
    };
    $scope.titleClicked = function () {
      if ($scope.block.link_url) {
        $window.open($scope.block.link_url);
      } else {
        $state.go('app.shopping.content_block', { 'blockId': $scope.block.id });
      }
    };


    $scope.trustAsHtml = function (content) {
      return $sce.trustAsHtml(content);
    };

  });
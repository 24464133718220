(function () {
  'use strict';

  angular.module('sowReports').filter('percentage', ['$filter', function ($filter) {
    return function (input, decimals) {
      function isFloat(n) {
        return Number(n) === n && n % 1 !== 0;
      }

      if (Number.isInteger(input) || isFloat(input)) {
        if (input > 0) {
          return '+' + $filter('number')(input, decimals) + '%';
        }
        return $filter('number')(input, decimals) + '%';
      } else if (input == '') {
        return '-';
      } else {
        return input;
      }
    };
  }])

})();
angular.module('app.shared.ui')
  .service('VideoModalService', function ($document, $rootScope) {
    var _this = this;
    var openScope = null;

    _this.open = function (modalScope) {
      if (!openScope) {
        $document.on('click', closeModal);
        $document.on('keydown', keyFilter);
      }

      if (openScope && openScope !== modalScope) {
        openScope.isOpen = false;
      }

      openScope = modalScope;
    };

    _this.close = function (modalScope) {
      if (openScope === modalScope) {
        openScope = null;
        $document.off('click', closeModal);
        $document.off('keydown', keyFilter);
      }
    };

    var closeModal = function (event) {
      console.debug('closeModal: event=%o', event);
      console.debug('closeModal: openScope=%o', openScope);

      if (!openScope) {
        return;
      }

      var toggleElements = openScope.getToggleElements();

      if (!event || !toggleElements || toggleElements.length < 1) {
        return;
      }

      var containsEventTarget = _.some(toggleElements, function (toggleElement) {
        return toggleElement.length && toggleElement[0].contains(event.target);
      });

      if (containsEventTarget) {
        return;
      }

      var videoElement = openScope.getVideoElement();
      if (event && videoElement && videoElement.length && videoElement[0].contains(event.target)) {
        return;
      }

      openScope.isOpen = false;

      if (!$rootScope.$$phase) {
        openScope.$apply();
      }
    };

    var keyFilter = function (event) {
      console.debug('keyFilter: event=%o', event);
      if (event.which === 27) {
        closeModal();
      }
    };
  });

(function () {
  
  function ManufacturerCP () {
    
    /**
    *
    * @class Manufacturer
    * @typedef Manufacturer
    */
    class Manufacturer {
      constructor(json_data) {
        this.id = json_data.id;
        this.name = json_data.name;
        this.sku = json_data.sku;
      }

      id;
      get id () {
        return this.id;
      }

      name;
      get name () {
        return this.name;
      }

      sku;
      get sku () {
        return this.sku;
      }
    }

    this.getClass = () => {
      return Manufacturer;
    }
  }
  
  angular.module('sowMarketplace')
    .service("ManufacturerCP", ManufacturerCP);
})();

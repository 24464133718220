(function () {
  'use strict';

  angular
    .module('app.shared.system.api', [])  // Dependent on sessionService, should be migrated to /shared.
    .config(setupInterceptor);

  //
  // Setup the $http interceptor.
  //
  function setupInterceptor ($httpProvider) {
    $httpProvider.interceptors.push('userTokenHandler');
  }
}());

(function () {
  'use strict';

  angular.module('sowMarketplace')
    .directive('mktAdBanner', marketplaceAdBannerDirective);

  function marketplaceAdBannerDirective() {
    return {
      restrict: 'E',
      templateUrl: 'sow-mkt/directives/mkt-ad-banner.html',
      controller: 'marketplaceBannerAdController',
      controllerAs: 'mktbCtrl',
      scope: {
        'target': '=',
      },
    };
  }

})();
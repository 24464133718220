angular.module("app.shared.support")
  .directive('updateOnScroll', function ($compile) {
    return {
      restrict: 'A',
      scope: {
        'onChange': '@',
        'threshold': '@'
      },
      link: function (scope, element, attrs) {
        angular.element(window).on("scroll", function () {
          var newVal = angular.element(document).scrollTop();
          var viewHeight = angular.element(window).height();

          if ((newVal + viewHeight) > parseInt(scope.threshold)) {
            scope.$parent.$apply(scope.onChange);
          }
        });
      }
    };
  });

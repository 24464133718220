angular.module('app.marketplace.ui').controller('MobileSidenavController',
  function MobileSidenavController($scope, $log, $timeout, $mdSidenav, $rootScope, $state) {
    // $scope.on('click .sidenav-list-item',function(){
    // 	$mdSidenav('mobile-sidenav').close();
    // });
    $scope.helpHidden = true;
    $scope.toggleHelp = function toggleHelp(){
    	$scope.helpHidden = !$scope.helpHidden;
    };
    $scope.marketplaceHidden = true;
    $scope.toggleMarketplace = function toggleMarketplace(){
    	$scope.marketplaceHidden = !$scope.marketplaceHidden;
    };
    $scope.switcherHidden = true;
    $scope.toggleSwitcher = function toggleSwitcher(){
    	$scope.switcherHidden = !$scope.switcherHidden;
    };
    $scope.closeSidenav = function closeSidenav(){
    	$mdSidenav('mobile-sidenav').close();
    };
    $scope.menuHandle = function menuHandle(menuItem){
    	$scope.closeSidenav();
    	$rootScope.menuHandle(menuItem);
    };

    $scope.itemIsActive = function itemIsActive (menuItem) {
      var excludes = menuItem.exclude || [];
      return ($state.includes(menuItem.state) && !_.some(excludes, $state.includes));
    }
  }
);

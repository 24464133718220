(function () {
  'use strict';

  angular.module('sowInventory')
    .controller('sowInventoryAgItemOptionsController', sowInventoryAgItemOptionsController);

  function sowInventoryAgItemOptionsController($filter, $rootScope, $mdDialog, sgToast, elementService, errorService, mktHelperService, inventoryItemService, DeleteInventoryActivePODialog, DeleteInventoryDraftPODialog) {
    var ctrl = this;

    ctrl.editItem = editItem;
    ctrl.deleteItem = deleteItem;
    ctrl.printItemQrCode = printItemQrCode;
    ctrl.duplicateItem = duplicateItem;
    ctrl.addToCart = addToCart;

    init();

    return ctrl;

    function init() {

    }

    function addToCart(ev, item) {
      prevent(ev);
      return showProductDetails(item);
    }

    function editItem(ev, item) {
      prevent(ev);
      return itemSlideout(item);
    }

    function duplicateItem(ev, item) {
      prevent(ev)
      return $mdDialog.show({
        controller: 'duplicateItemController',
        templateUrl: 'templates/marketplace/inventory/modals/duplicate-item.html',
        parent: angular.element(document.body),
        targetEvent: ev,
        clickOutsideToClose: true,
        locals: {
          item: angular.copy(item)
        }
      }).then(function (new_inventory_item) {
        // $scope.viewItem(null, new_inventory_item, true);
      });
    }

    function deleteItem(ev, item) {
      prevent(ev);

      function _success() {
        var t_message = $filter('translate')('TOAST.INVENTORY_ITEM_REMOVED');
        sgToast.showSimple(t_message);
        $mdDialog.hide();
      }

      function _error(error) {
        var t_message = $filter('translate')('ERRORS.ITEM_REMOVE')
        errorService.uiErrorHandler(error || t_message, 0);
      }

      return elementService
        .remove('inventoryItem', item, true)
        .then(function (inventory_item) {
          _success();
        }).catch(function (error) {
          var poError = _.get(error, 'purchase_order_error');

          if (poError) {
            var drafts = _.filter(poError.purchase_orders, { 'status': 'Draft' });
            var candidate = angular.extend(angular.copy(item), poError);

            if (drafts.length === poError.purchase_orders.length) {
              return DeleteInventoryDraftPODialog
                .show([candidate])
                .then(_success, _error);
            } else {
              return DeleteInventoryActivePODialog
                .show([candidate])
                .then(function () {
                  $mdDialog.hide();
                  _success();
                });
            }
          } else {
            _error(error);
          }
        });
    }

    function printItemQrCode(ev, item) {
      prevent(ev);
      return inventoryItemService.printLabelsForItems([item]);
    }

    function prevent(ev, item) {
      if (ev.stopPropagation)
        ev.stopPropagation();
      if (ev.preventDefault)
        ev.preventDefault();
    }

    function showProductDetails(item) {
      return mktHelperService.productDetailsFetch(item.product_id);
    }

    function itemSlideout(item) {
      // using id signature in order to load item again from slideout code
      // to revert, send whole item in first parameter instead
      $rootScope.$broadcast('inventory-item-slideout', null, item.id);
    }
  }

}());

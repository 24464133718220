angular.module('app.marketplace.ui.orders', [])
.config(function($stateProvider, $urlRouterProvider) {
  $urlRouterProvider.when('/app/orders/sowingo', '/app/orders/sowingo/');

  $stateProvider
    .state('app.orders', {
      url: '/orders',
      controller: 'ordersController',
      templateUrl: 'templates/marketplace/orders/orders.html',
      data: {
        'title': 'Orders',
        'action_bar_directive': 'action-bar-orders',
        'require_log_in' : true
      },
      // redirectTo: 'app.orders.sowingo'
    })

    // .state('app.orders.sowingo', {
    //   url: '/sowingo/:orderId',
    //   controller: 'sowingoOrdersController',
    //   templateUrl: 'templates/marketplace/orders/views/sowingo-orders.html',
    //   data: {
    //     'title': 'Marketplace Orders',
    //     // 'requires_feature': 'marketplace',
    //   },
    //   resolve: {
    //     // all_orders: function ($filter, $q, errorService, orderService, returnService, exchangeService) {
          
    //     // }
    //   }
    // })

    .state('app.orders.shopping_list', {
      'url': '/shopping-list',
      'controller': 'ShoppingListController',
      'controllerAs': 'slCtrl',
      'templateUrl': 'templates/marketplace/inventory/shopping-list.html',
      'data': {
        'title': 'Inventory Shopping List',
        // 'requires_feature': 'shopping_list',
      },
    });
});

(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.inventory')
    .controller('inventoryLogController', InventoryLogController);

  function InventoryLogController ($scope, $timeout, $filter, $window, elementService, $mdDialog, officeService, errorService, inventoryActivityItems, smoothScroll) {
    $scope.logs = [];
    $scope.per_page = 25;
    $scope.page = 1;
    $scope.logs = inventoryActivityItems || [];
    $scope.canLoadMore = $scope.logs.length === 25;

    var scrollPane = smoothScroll.getScrollPane();

    angular.element(scrollPane).on('scroll', function() {
      var currentScrollPosition = smoothScroll.getCurrentScrollPosition(scrollPane);
      var totalContentHeight = scrollPane.scrollHeight;
      var visibleContentHeight = scrollPane.clientHeight;
      var scrollThreshold = totalContentHeight - visibleContentHeight - 50;

      if (scrollPane &&
          currentScrollPosition >= scrollThreshold &&
          $scope.canLoadMore &&
          !$scope.showListLoad) {

        $scope.showListLoad = true;
        $scope.page += 1;

        return officeService.getInventoryActivity(true, $scope.page).then(function (logs) {
          $scope.logs = $scope.logs.concat(logs);

          if (logs.length < $scope.per_page) {
            $scope.canLoadMore = false;
          }
        }).catch(function (error) {
          var t_message = $filter('translate')('ERRORS.LOGS_LOAD')
          return errorService.uiErrorHandler(error || t_message, 0);
        })['finally'](function (){
          $scope.showListLoad = false;
        });
      }
    });
  }
}());

angular.module('app.marketplace.ui.inventory').directive('emptyInventory', function () {
  return {
    restrict: 'E',
    replace: true,
    // Note: We only use this controller to get access to the "addItem" modal
    //       which is only defined here. We should probably have a more general
    //       place to put that functionality.
    controller: 'InventoryActionBarController',
    templateUrl: 'templates/marketplace/inventory/directives/empty-inventory.html'
  };
});

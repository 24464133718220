angular.module('app.shared.system.sgDialog', []).service('sgDialog', function ($mdDialog) {
  var _this = this;

  var _defaults = {
      parent: angular.element(document.body),
      clickOutsideToClose: true
  };

//  .title('This is an alert title')
//  .textContent('You can specify some description text in here.')
//  .ariaLabel('Alert Dialog Demo')
//  .ok('Got it!')
//  .targetEvent(ev)

  var _addDefaultSettings = function (presets) {
    angular.extend(presets._options, _defaults);
    return presets;
  };

  //
  // Return a copy of the default settings
  //
  _this.defaultSettings = function () {
    return angular.copy(_defaults);
  };

  //
  // Just a pass-through for $mdDialog.show
  //
  _this.show = function (optionsOrPreset) {
    return $mdDialog.show(optionsOrPreset);
  };

  //
  // Wrapper around $mdDialog with our defaults applied to the $mdDialogPreset
  //
  _this.alert = function () {
    return _addDefaultSettings($mdDialog.alert());
  };

  //
  // Wrapper around $mdDialog.confirm()
  //
  _this.confirm = function () {
    return _addDefaultSettings($mdDialog.confirm());
  };

  //
  // Wrapper around $mdDialog.prompt()
  //
  _this.prompt = function () {
    return _addDefaultSettings($mdDialog.prompt());
  };

  //
  // Show an alert $mdDialog with our default config
  //
  _this.showAlert = function (options) {
    var dialog = _this.alert();
    angular.extend(dialog._options, options);
    return _this.show(dialog);
  };
  
  //
  // Show a confirm $mdDialog with our default config
  //
  _this.showConfirm = function (options) {
    var dialog = _this.confirm();

    if (options.icon || options.okRed) { // Check for custom options requiring a custom template.
      dialog._options.templateUrl = 'templates/shared/support/confirmDialog.html';
      dialog._options.controller = [
        '$scope',
        '$mdDialog',
        function ($scope, $mdDialog) {
          $scope.closeDialog = function(){
            $mdDialog.cancel();
          };

          $scope.confrimDialog = function(){
            $mdDialog.hide();
          };
        },
      ];
    }

    angular.extend(dialog._options, options);
    return _this.show(dialog);
  };
});

angular.module("app.marketplace.ui.inventory") 
.directive('inventoryActivityHeader',[ function() {
  return {
    restrict: 'E',
    replace: true,
    templateUrl: 'templates/marketplace/inventory/directives/activity-header.html',
    link: function($scope, $element, $attributes){ 
    },
    controller: 'inventoryActivityController'
  };
}]);



angular.module('app.marketplace.ui.inventory')

.directive('searchInventoryItem', function() {
  return {
    restrict: 'E',
    controllerAs: 'ctrl',
    templateUrl: 'templates/marketplace/inventory/directives/search-inventory-items.html',
    scope: true,
    bindToController: {
      context: '@',
      itemClone: '=',
      createNewItem: '&'
    },
    controller: function(inventoryItemService, errorService, $mdDialog, $filter,
      $state, sowMedicationService, searchInventoryService, $scope) {
      var ctrl = this;
      var limit = 2;
      var search = inventorySearch;
      
      ctrl.suggestionItems = {limit: limit, results: []};
      ctrl.inventoryItems = {limit: limit, results: []};

      ctrl.displayShowMoreText = displayShowMoreText;
      ctrl.getShowMoreText = getShowMoreText;
      ctrl.itemSearch = itemSearch;
      ctrl.reset = reset;
      ctrl.toggleShowMoreItems = toggleShowMoreItems;
      ctrl.viewItem = viewItem;
      ctrl.useSuggestionItem = useSuggestionItem;
      
      ////////
      
      ctrl.$onInit = function onInit () {
        if (ctrl.context === 'medication' || ctrl.context === 'emergencyMedication') {
          // possibly we could change this in the future, related to SOW-4854
          search = medicationSearch;
        }
        ctrl.isMedication = ctrl.context === 'medication';
        ctrl.config = searchInventoryService.map[ctrl.context];
      }

      function itemSearch(name) {
        if (!name) {
          return false;
        }

        search(name);
      }

      function inventorySearch(name) {
        inventoryItemService.searchGenericProducts(name)
          .then(function(data) {
            ctrl.inventoryItems.results = data.inventory_items;
            ctrl.suggestionItems.results = data.generic_products;
          })
          .catch(function(error){
            var t_message = $filter('translate')('ERRORS.TPI_SEARCH')
            errorService.uiErrorHandler(error || t_message);
          });
      }

      function medicationSearch(name) {
        sowMedicationService.getMedicationSuggestions(name)
          .then(function(data) {
            // filter for regular or emergency meds
            var emergency_only = ctrl.context === 'emergencyMedication';
            
            ctrl.inventoryItems.results = _.filter(data.in_inventory, function(item){
              return emergency_only ? item.is_emergency : !item.is_emergency;
              
            });
            ctrl.suggestionItems.results = _.filter(data.suggestions, function(item){
              return emergency_only ? item.is_emergency : !item.is_emergency;
            });;
          });
      }

      function displayShowMoreText(list) {
        return _.size(list.results) > limit;
      }

      function getShowMoreText(list) {
        var remainingItems = list.results.length - limit;

        return list.limit === list.results.length
          ? 'Hide ' + remainingItems + ' Items'
          : remainingItems + ' more';
      }

      function reset() {
        ctrl.itemClone.name = '';
        ctrl.inventoryItems.results = [];
        ctrl.suggestionItems.results = [];
      }

      function toggleShowMoreItems(list) {
        if (list.limit === list.results.length) {
          list.limit = limit;
        } else {
          list.limit = list.results.length;
        }
      }

      function viewItem(item) {
        $mdDialog.cancel();
        var state = getViewItemState(item, ctrl.context);
        $state.go(state.name, state.params,{ignoreConfirmNavigation: true});
      }

      function getViewItemState(item, context) {
        if (context === 'product') {
          return {
            name:  'app.inventory.all',
            params: {itemId: item.id}
          };
        }

        return {
          name:  'app.medications.list',
          params: {
            focus_product: item.id,
            type: context === 'medication' ? 'regular' : 'emergency'
          }
        };
      }

      function useSuggestionItem (suggestion_item) {
        // apply suggestion item's properties to the itemClone for later use in the form
        _.extend(ctrl.itemClone, _.omit(suggestion_item, ['unit_of_measurement', 'track_by', 'id']), {
          'manufacturer': {
            'id': suggestion_item.manufacturer_id, 
            'name': suggestion_item.manufacturer_name
          },
          'is_emergency': ctrl.context === 'emergencyMedication' ? true : false
        });
        ctrl.createNewItem();
      }
    }
  };
});

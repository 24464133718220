(function () {
  'use strict';

  var modelData = {
    "params": {
      "model_name": "DailyRecordField"
    }
  };

  var modelFields = {
    "id": {
      "display_name": "id",
      "required": true,
      "api": {
        "submit": true
      },
      "type": "integer"
    },
  };

  angular
    .module('app.marketplace.elements')
    .service('DailyRecordFieldModel', DailyRecordFieldModel);

  function DailyRecordFieldModel(apiUrl) {
    /*jshint validthis: true */
    var model = this;

    model.data = modelData;
    model.fields = modelFields;
    model.autofill = autofill;
    model.model_data = {
      'name': 'DailyRecordField',
      'display_name': 'Daily Record Field',
      'api': {
        'single': getField,
        'newField': createField,
        'updateField': updateField,
        'get_fields': getFields,
      }
    };

    return model;

    function momentToNativeDate (_moment) {
      return moment(_moment.format('YYYY-MM-DD')).toDate();
    }

    //
    //
    //
    function autofill (field) {
      field.UI = field.UI || {};
      field.UI.startDate = field.start_date ? moment.utc(field.start_date) : null;
      field.UI.startDate_ = field.start_date ? momentToNativeDate(field.UI.startDate) : null;
      field.UI.endDate = field.end_date ? moment.utc(field.end_date) : null;
      field.UI.endDate_ = field.end_date ? momentToNativeDate(field.UI.endDate) : null;
    }

    //
    // Create a new DailyRecordField.
    //
    function createField (options) {
      var fields = ['start_date', 'name', 'sort_rank', 'end_date', 'field_type', 'office_id'];
      var data = _.pick(options.data, fields);
      var requiredFields = ['name', 'field_type'];
      var fieldTypes = ['collection', 'production', 'procedure', 'wellbeing'];

      _.forEach(requiredFields, function (requiredField) {
        var value = _.get(data, requiredField);

        if (!value) {
          throw 'Cannot create new field. Missing "{0}".'.format(requiredField);
        }

        if (requiredField === 'field_type' && !_.includes(fieldTypes, value)) {
          throw 'Invalid Field Type: {0}'.format(value);
        }
      });

      return {
        'path': '{0}/monitor/fields'.format(apiUrl),
        'params': {
          'method': 'POST',
          'data': data,
        },
      };
    }

    //
    // Update a DailyRecordField.
    //
    function updateField (options) {
      var fields = ['id', 'sort_rank', 'start_date', 'end_date', 'name'];
      var data = _.pick(options.data, fields);
      var requiredFields = ['id'];

      _.forEach(requiredFields, function (requiredField) {
        if (!_.has(data, requiredField)) {
          throw 'Missing required field: {0}'.format(requiredField);
        }
      });

      return {
        'path': '{0}/monitor/fields/{1}'.format(apiUrl, data.id),
        'params': {
          'method': 'PUT',
          'data': data,
        },
      };
    }

    //
    // Return a range of DailyRecordField objects based on filter parameters.
    //
    function getFields (options) {
      var get_params = options.filters || {};

      return {
        'path': '{0}/monitor/fields'.format(apiUrl),
        'params': {
          'method': 'GET',
          'params': get_params,
        }
      };
    }

    //
    // Fetch a single DailyRecordField model by id.
    //
    function getField (options) {
      if (!_.has(options, 'data.id')) {
        throw 'Missing field_set_id. Cannot fetch DailyRecordField object.';
      }

      return {
        'path': '{0}/monitor/fields/{1}'.format(apiUrl, options.data.id),
        'params': {'method': 'GET'},
      };
    }
  }
}());

(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.office')
    .controller('OfficeSubscriptionUpgradeCtrl', OfficeSubscriptionUpgradeCtrl);

  function OfficeSubscriptionUpgradeCtrl ($scope, $timeout, $filter, $log, $state, $stateParams, SubscriptionPromoCodeDialog, subscriptionService,
                                          legalService, sessionService, errorService, RouteService, officeService) {
    /*jshint validthis: true */
    var ctrl = this;
    var plan_hrid = $stateParams.plan;

    var planStyles = {
      'premium': {
        // Product has decided to change the Premium plan
        // title -> "Premium" to "Sterilization & Compliance"
        'name': 'Sterilization & Compliance',
        'class': 'upgrade-to-premium',
        'badge': 'premium-badge',
      },
      'premium_plus': {
        'name': 'Premium Plus',
        'class': 'upgrade-to-premium-plus',
        'badge': 'premium-plus-badge',
      },
      'professional': {
        'name': 'Professional',
        'class': 'upgrade-to-professional',
        'badge': 'professional-badge'
      }
    };

    $scope.plans = [];
    $scope.currentPlan = null;
    $scope.currentPromotion = $stateParams.promotion;
    $scope.billingPeriod = $stateParams.billing_cycle;
    $scope.monthlyCost = null;
    $scope.chargedAmount = null;
    $scope.loadingPlans = true;
    $scope.disableBillingCycleSwitch = !!$stateParams.yearly_only;
    $scope.toggleBillingPeriod = toggleBillingPeriod;
    $scope.mdSwitchClass = {};

    ctrl.cancel = cancelUpgrade;
    ctrl.showTerms = legalService.showTermsModal;
    ctrl.clearDiscount = clearDiscount;
    ctrl.goToYourAccount = goToYourAccount;
    ctrl.completePurchase = completePurchase;
    ctrl.openDiscountDialog = openDiscountDialog;

    init();

    return ctrl;

    //
    // Initialize the controller
    //
    function init () {
      var youShouldNeverHaveComeHere = !$stateParams.from_click || !$stateParams.billing_cycle;

      if (youShouldNeverHaveComeHere) {
        toPlansPage();
      }

      setTargetPlan();
      fetchSubscriptionPlans();
      fetchDefaultDiscounts();
      $scope.$watch('billingPeriod', updateCurrentPlan);
      $scope.$watch('submitting', updateMdSwitchClass);
      $scope.$watch('disableBillingCycleSwitch', updateMdSwitchClass);
      if ($scope.currentPromotion) {
        $scope.currentPromotion = parsePromotion($scope.currentPromotion)
      }
    }

    function toPlansPage () {
      return $state.go('app.office.profile.subscription.view');
    }

    function setTargetPlan () {
      $scope.planClass = _.get(planStyles, plan_hrid + '.class');
      $scope.planBadge = _.get(planStyles, plan_hrid + '.badge');
      $scope.planName = _.get(planStyles, plan_hrid + '.name');
    }

    //
    // Update the mdSwitchClass object
    //
    function updateMdSwitchClass () {
      $scope.yearlyClass = {
        'bold-text': $scope.billingPeriod === 'per_year',
        'purple-text': $scope.billingPeriod === 'per_year' && plan_hrid === 'premium',
        'green-text': $scope.billingPeriod === 'per_year' && plan_hrid === 'premium_plus',
      };
      $scope.mdSwitchClass = {
        'purple-text': plan_hrid === 'premium' && $scope.billingPeriod === 'per_month',
        'green-text': plan_hrid === 'premium_plus' && $scope.billingPeriod === 'per_month',
        'bold-text': $scope.billingPeriod === 'per_month',
        'buttonize': !($scope.submitting || $scope.disableBillingCycleSwitch),
        'clickable': !($scope.submitting || $scope.disableBillingCycleSwitch),
      };
    }

    //
    //
    //
    function toggleBillingPeriod () {
      if ($scope.submitting || $scope.disableBillingCycleSwitch) {
        return;
      }

      $scope.billingPeriod = ($scope.billingPeriod === 'per_month' ? 'per_year' : 'per_month');
    }

    //
    //
    //
    function goToYourAccount () {
      RouteService.goToDefault();
    }

    //
    // If this office is a member of an association, then see if there are any
    // default subscsription promotions for that association.
    //
    function fetchDefaultDiscounts () {
      var office;

      officeService.get(true).then(function (_office) {
        office = _office;

        if (office.account_type !== 'Free' && !office.trial_ends_on) {
          subscriptionService
            .currentSubscription()
            .then(function (subscription) {
              if (subscription.plan.billing_cycle === $scope.billingPeriod && subscription.plan.hrid === plan_hrid) {
                $log.warn('Cannot upgrade to current plan. Redirecting away...');
                RouteService.goToDefault();
              }

              if (subscription.plan.billing_cycle === 'per_year' && $scope.billingPeriod === 'per_month' && subscription.plan.hrid === plan_hrid) {
                $log.warn('Cannot change from Annual => Monthly');
                RouteService.goToDefault();
              }
            })
            .finally(function () {
              $scope.currentSubscriptionInit = true;
            });
        } else {
          $scope.currentSubscriptionInit = true;
        }

        return officeService.getUnusedDiscounts();
      }).then(function (unusedDiscounts) {
        if ($scope.currentPromotion) {
          return;
        }
        else if (unusedDiscounts && unusedDiscounts.length > 0) {
          setCurrentPromotion(unusedDiscounts[0]);
          return;
        }

        var hasAssociationMembership = _.get(office, 'association_membership');
        var associationId = _.get(office, 'association_membership.professional_association_id');

        if (!associationId) {
          return;
        }

        return subscriptionService.getPromotions({'professional_association_id': associationId}).then(function (promotions) {
          if (promotions && promotions.length === 1) {
            setCurrentPromotion(promotions[0]);
          }
        });
      });
    }

    //
    // Update the monthly from the current plan options.
    //
    function updateMonthlyCost () {
      if (!$scope.currentPlan || !$scope.currentSubscriptionInit) {
        return;
      }

      $scope.loadingPlans = true;

      return subscriptionService
        .calculateUpgradeCost($scope.currentPlan, $scope.currentPromotion)
        .then(function (upgradeCost) {
          $scope.upgradeCost = upgradeCost;
        })
        .catch(function (error) {
          var t_message = $filter('translate')('ERRORS.SUB_RETRIEVE')
          errorService.uiErrorHandler(error || t_message, 0);
        })
        .finally(function () {
          $scope.loadingPlans = false;
        });
    }

    //
    // Action: User cancels upgrade process.
    //
    function cancelUpgrade () {
      return $state.go('app.office.profile.subscription.view');
    }

    //
    // Update $scope.currentPlan
    //
    function updateCurrentPlan () {
      $scope.currentPlan = null;

      _.forEach($scope.plans, function (plan) {
        if (plan.hrid === plan_hrid && plan.billing_cycle === $scope.billingPeriod) {
          $scope.currentPlan = plan;
          return false;
        }
      });

      if (!$scope.currentPlan && !$scope.loadingPlans) {
        var t_message = $filter('translate')('ERRORS.PLAN_LOAD')
        errorService.uiErrorHandler(t_message);
      }

      $scope.isPremium = (plan_hrid === 'premium');
      $scope.isPremiumPlus = (plan_hrid === 'premium_plus');

      updateMdSwitchClass();
      updateMonthlyCost();
    }

    //
    // Fetch subscription plans
    //
    function fetchSubscriptionPlans () {
      return subscriptionService.getPlans().then(function (plans) {
        $scope.loadingPlans = false;
        $scope.plans = plans;
        updateCurrentPlan();
      });
    }

    //
    // Set the current promotion, and update any other various things.
    //
    function setCurrentPromotion (promotion) {
      const current_promo =  parsePromotion(promotion);
      $scope.currentPromotion = current_promo;
      updateMonthlyCost();
    }

    /** 
     * Parses the selected promotion to generate UI properties. 
     * 
     * @param {Object} promotion 
     * 
     * @return {Object} 
    */
    function parsePromotion(promotion) {
      const { discount_type, discount_value } = promotion;
      const discount_text = $filter('translate')(`UPGRADE.${discount_type}`, { x: discount_value })
      _.set(promotion, 'UI.discount_text', discount_text);
      return promotion;
    }

    //
    // Open the "Add Discount" dialog.
    //
    function openDiscountDialog ($event) {
      return SubscriptionPromoCodeDialog.show($event).then(function (promotion) {
        setCurrentPromotion(promotion);
      });
    }

    //
    // Remove the current promotion.
    //
    function clearDiscount ($event) {
      if ($scope.submitting) {
        return;
      }

      setCurrentPromotion(null);
    }

    //
    // Complete the purchase and make the corresponding subscription changes to
    // the account.
    //
    function completePurchase () {
      $scope.submitting = true;
      var hasCreditCard = _.has($scope, 'currentCreditCard.id');

      if (!hasCreditCard) {
        var t_message = $filter('translate')('ERRORS.NO_CARD')
        errorService.uiErrorHandler(t_message);
        $scope.submitting = false;
        return false;
      }

      return subscriptionService.updateSubscription(
        $scope.currentPlan,
        $scope.currentCreditCard,
        $scope.currentPromotion
      ).then(function () {
        return sessionService.refreshSession().then(function () {
          $state.go('app.office.profile.subscription.upgrade_complete');
        });
      }).then(null, function (error) {
        errorService.uiErrorHandler(error, 0);
      })['finally'](function () {
        $scope.submitting = false;
      });
    }
  }

}());

(function () {
  'use strict';

  angular.module('sowMarketplace')
  .controller('marketplaceCardListController', marketplaceCardListController)
  .directive('mktCardList', marketplaceCardListDirective);

  function marketplaceCardListDirective () {
    return {
      restrict: 'E',
      templateUrl: 'sow-mkt/directives/mkt-card-list.html',
      controller: 'marketplaceCardListController',
      controllerAs: 'mktclCtrl',
      scope: {
        'type': '=',
        'items': '=',
        'destination': '=',
        'name': '=',
        'params': '='
      },
    };
  }

  function marketplaceCardListController ($scope, $state, mktHelperService, appEvents, $filter) {
    /*jshint validthis:true*/
    var ctrl = this;

    ctrl.goToDestination = goToDestination;
    ctrl.goLeft = goLeft;
    ctrl.goRight = goRight;

    ctrl.VS_OPTIONS = {
      'horizontal': true,
      // 'size': 72,
      // 'scroll-margin': 72*4,
      'autoresize': true
    };
    ctrl.SRC_EMPTY_FAVS = $filter('translate')('IMAGES.MKT_EMPTY_FAVOURITES_CARDS');
    ctrl.SRC_EMPTY_BUY_AGAIN = $filter('translate')('IMAGES.MKT_EMPTY_BUY_AGAIN_CARDS');

    init();

    return ctrl;

    function init () {
      if($scope.type === 'product'){
        $scope.$on(appEvents.mktProductUpdate, updateLocalProduct);
      }
    }

    function goToDestination () {
      return $state.go($scope.destination, $scope.params);
    }

    function goLeft ($event) {
      horizontalScroll($event, -1);
    }

    function goRight ($event) {
      horizontalScroll($event, 1);
    }

    function horizontalScroll ($event, page_value) {
      var main_div = _.get($event, 'currentTarget.parentElement.parentElement.parentElement', null);
      var list_div = main_div.querySelector('.mkt-card-list-wrapper');
      
      list_div.scrollLeft = list_div.scrollLeft + (list_div.clientWidth*page_value);
    }

    function updateLocalProduct ($event, new_item) {
      if(_.size($scope.items) === 0) return;
      var item = _.find($scope.items, ['id',new_item.id]);
      if(!item) return;
      new_item = mktHelperService.setProductVendorInfo(new_item);
      // angular.copy(new_item, item);
      _.extend(item, _.pick(new_item, ['is_favourite_product']));
    }
  }

})();

angular.module("app.shared.support")

  .directive('postalCode', [
    function () {
      // Regexp validates Canadian Postal Codes **only**
      var POSTAL_CODE_REGEXP = /^[ABCEGHJKLMNPRSTVXY]{1}\d{1}[A-Z]{1} *\d{1}[A-Z]{1}\d{1}$/i;
      return {
        require: 'ngModel',
        link: function ($scope, element, attrs, ctrl) {
          ctrl.$parsers.unshift(function (value) {
            var valid = POSTAL_CODE_REGEXP.test(value);
            ctrl.$setValidity('postalCode', valid);
            return valid ? value : undefined;
          });

          ctrl.$formatters.unshift(function (value) {
            ctrl.$setValidity('postalCode', POSTAL_CODE_REGEXP.test(value));
            return value;
          });
        }
      };
    }
  ]);
angular.module("app.marketplace.ui.account")
.controller("accountSettingsController",
      function($scope, $filter, sgToast, elementService, errorService, accountService) {


      //------------------------------------------------------------------------

      $scope.account = null;
      var _init = function(account){
        $scope.account = account;
        $scope.edit_account = angular.copy(account);
        if($scope.accountInfoForm){
          $scope.accountInfoForm.$setPristine();
        }
      };

      $scope.$on('accountService: set-account', function(scope, account){
        _init(account);
      });
      $scope.$on('accountService: clear-account', function(){
        _init();
      });
      _init(accountService.get());


      //------------------------------------------------------------------------

      $scope.updateAccount = function(){
        var form = $scope.accountInfoForm || $scope.emailPrefsForm;

        if(!form.$valid) {
          return;
        }

        $scope.submitting = true;
        elementService.submit('account', {
          endpoint: "update",
          element: $scope.edit_account
        }).then(function(account) {
          $scope.submitting = false;
          var t_message = $filter('translate')('TOAST.PROFILE_UPDATED');
          sgToast.showSimple(t_message);
          _init(account);
        }).catch(function(error) {
          $scope.submitting = false;
          var t_message = $filter('translate')('ERRORS.PROFILE_UPDATE')
          errorService.uiErrorHandler(error || t_message, 0);
        });
      };
    });

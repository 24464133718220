(function () {
  'use strict';

  angular
    .module('sowAlerts')
    .service('sowAlertService', sowAlertService);


    function sowAlertService(serverAPI, apiUrl) {
      /*jshint validthis: true */
      var service = this;

      service.getDashboardAlerts = getDashboardAlerts

      return service

      /**
       * @method getDashboardAlerts
       * @description Fetch the summarized dashboard alerts
       * 
       */
      function getDashboardAlerts() {
        
        var url = '{0}/dashboard/alerts'.format(apiUrl);
        var options = {'method': 'GET'};

        return serverAPI
          .doAPICall(url, options)
          .then(function (response) {
            return response.data;
          });
      
      }

      
    }

}());
angular.module('app.marketplace.ui.shopping.search').controller('SearchHeaderController',
  function SearchHeaderController($scope, $state, $timeout, $sce, $filter, elementService, productService, searchService) {
    $scope.ctrl = {}; //Avoid scope issues.
    $scope.toggle = function () {
      $scope.searchBarOpen = !$scope.searchBarOpen;
    };

    // Note: 'msb' == 'mobile search bar'
    $scope.msbKeypress = function ($event) {
      if ($event.keyCode === 27) {
        $scope.toggle();
      } else if ($event.keyCode === 13) {
        $scope.doSearch($scope.ctrl.searchQuery);
      }
    };

    $scope.doSearch = function(query){
      if(angular.isString(query) && query.length > 0){
        var escapedQuery = encodeURIComponent(query);
        $state.go('app.shopping.search',{'urlQuery':escapedQuery});
        $scope.toggle();
        $scope.ctrl.searchFocused = false;
      }
    };
    
    //Clear search string on state change.
    $scope.$on('$stateChangeSuccess', 
        function(event, toState, toParams, fromState, fromParams){
      if(!toState || !toState.name || !fromState || !fromState.name || 
          fromState.name.charAt(4) !== toState.name.charAt(4)){ //Fourth character because base state will always be app.#
        if(toState && toState.name.indexOf("app.shopping.search") !== -1){
          return false; //Dont clear because in search state.
        }else{
          $scope.ctrl.searchQuery = "";
        }
      }
    });
    //Watch search clear from search page.
    $scope.$on('clearSearch', function(){
      $scope.ctrl.searchQuery = "";
    });

    $scope.querySearch = function(query){
      if(!query || query.length < 2){
        $scope.products = [];
        return false;
      }
      return searchService.getSearch(query, {
        'autocomplete': true
      }).then(function(searchElements){
        var results = [];

        // TODO I resolved a conflict here by adding this filtering that was
        //      happening on the US branch. Confirm that this isn't an issue.
        //      -- Brandon

        //
        // Filter down to only results from the current session's country
        //
        var i, len = searchElements.products.length;
        for (i = 0; i < len; ++i) {
          if (searchElements.products[i].UI.is_published) {
            results.push(searchElements.products[i]);
          }
        }

        $scope.products = results;
      });
    };
    $scope.$watch('ctrl.searchQuery', function(newSearchValue, oldSearchValue){
      $scope.querySearch(newSearchValue);
    });

    $scope.highlight = function(text, search) {
      if(!text){
        return text;
      }
      if (!search) {
          return $sce.trustAsHtml(text);
      }
      return $sce.trustAsHtml(text.replace(new RegExp(search, 'gi'), '<span class="highlighted-text">$&</span>'));
    };
  }
);

angular.module('app.marketplace.ui.orders').directive('sowingoOrderIcon',
  function orderIcon () {
    return {
      restrict: 'A',
      scope: {
        'order': '=sowingoOrderIcon'
      },
      link: function ($scope, $element, $attr) {
        if ($scope.order.status === 'Open') {
          $element.addClass('blue-icon');
          $element.text('mode_edit');
          return true;
        } else {
          $element.addClass('green-icon');
        }

        $element.text(
          $scope.order.params.model_name === 'order' ? 'check'
          : $scope.order.params.model_name === 'return' ? 'undo'
          : $scope.order.params.model_name === 'exchange' ? 'swap_horiz'
          : ''
        );
      }
    };
  }
);

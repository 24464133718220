(function () {
    'use strict';
    angular.module('sowUserAdmin')
    .controller('UserAdminListController', UserAdminListController);

    function UserAdminListController ($q, $scope, $state, $filter, $rootScope, sgToast, errorService, membershipService, sowUserAdminService, sowUserAdminHelperService) {
    /*jshint validthis: true */
    var ctrl = this;

    ctrl.memberships = [];
    ctrl.filters = {
      'office': null,
      'search': null,
      'type': 'all',
    };
    ctrl.listOrder = 'name';
    ctrl.total_pending = 0;

    ctrl.applyFilters = applyFilters;
    ctrl.openUserPage = openUserPage;
    ctrl.handleAdminToolsEvent = handleAdminToolsEvent;

    ctrl.$onInit = init;

    return ctrl;

    // private functions

    function init () {
      setOfficeToCurrent();
      _refreshUserList();
      $scope.$on('new-user-modal', newUserModal);
      $scope.$on('user-invite-sent', getInvitesSent);
    }

    /**
     * Loads users and offices, then applies filters in
     * order to generate the table displayed in the UI
     */
    function _refreshUserList() {
      ctrl.loading = true;
      $q.all([
        loadUsers(),
        loadOffices()
      ])
      .finally(() => {
        applyFilters();
        ctrl.loading = false;
      });
    }

    // UI functions

    function openUserPage (user) {
      if(!user.UI.is_invite)
        $state.go('app.user_detail',{'userId':user.user_id});
    }

    function newUserModal () {
      return sowUserAdminHelperService.addUserModal();
    }

    /**
     * > Sets the office filter to the current office
     * This is used at initialization so that the first list 
     * the user sees represents the office they're logged in to
     */
    function setOfficeToCurrent () {
      const current_office = membershipService.get().office;
      _.set(ctrl.filters, 'office', current_office.id);
    }

    // data functions

    function loadUsers () {
      return sowUserAdminService.getAllUsersAndInvites()
      .then(function(response){
        ctrl.users = [];
        _.each(response, function(user){
          _.set(user, 'UI.is_invite', (user.invited_on && moment().isSameOrBefore(moment(user.expires_at))))
          if(!user.UI.is_invite && !user.is_active){
            return;
          }
          if(user.offices){
            filterUserOffices(user);
            if(user.offices.length > 1){
              const t_offices = $filter('translate')('COMMON.OFFICES')
              const multiple_office_text = `${user.offices.length} ${t_offices}`;
              _.set(user, 'UI.office.name', multiple_office_text);
              _.set(user, 'UI.office.permission_type', 'multiple');
              _.set(user, 'UI.multiple', true);
            } else {
              _.set(user,'UI.office', user.offices[0]);
            }

          }
          ctrl.users.push(user);
        });
        ctrl.display_items = ctrl.users;
      });
    }

    function filterUserOffices (user) {
      var allowed_offices = [];
      _.each(user.offices, function(office){
        if(ctrl.admin_offices.map[office.id]){
          allowed_offices.push(office);
        }
      });
      _.set(user, 'offices', allowed_offices);
    }

    function getInvitesSent (event, response) {
      _.each(response.invite, function(invite){
        _.set(invite, 'UI.is_invite', true);
        ctrl.users.push(invite);
      });
      applyFilters();
    }

    function loadOffices () {
      ctrl.memberships = membershipService.getAll().office;
      ctrl.admin_offices = sowUserAdminHelperService.getAdminOffices();
    }

    function applyFilters (filter_params) {
      ctrl.filters = _.extend(ctrl.filters, filter_params);
      ctrl.total_pending = 0;
      var filtered_items = [];

      _.each(ctrl.users, function(user){
        // if(_.isNil(user.is_active))
        //   return;
        if(ctrl.filters.office){
          var right_office = false;
          _.each(user.offices, function(office){
            if (office.id === ctrl.filters.office) {
              right_office = true;
            }
          });
          if(user.office_id && user.office_id === ctrl.filters.office){
            right_office = true;
          }
          if(!right_office)
            return;
        }
        if(_.size(ctrl.filters.search)){
          var name = user.name.toLowerCase();
          if(!_.includes(name, ctrl.filters.search.toLowerCase()))
            return;
        }
        if(ctrl.filters.type === 'pending' && !user.UI.is_invite){
          return;
        }
        filtered_items.push(user);
        if(user.UI.is_invite){
          ctrl.total_pending++;
        }

      });
      ctrl.display_items = filtered_items;
    }

    /**
     * Handles an event from the child component based on its payload
     * @param {object} event
     * @param {object} event.detail
     * @param {object} event.detail.addUserResponse
     * @param {string} event.detail.errorToastMessage
     * @param {boolean} event.detail.openAddUserModal
     */
    function handleAdminToolsEvent({detail: {addUserResponse, errorToastMessage, openAddUserModal}}) {
      if (addUserResponse) {
        const t_invite_sent = $filter('translate')('TOAST.INVITE_SENT');
        sgToast.showSimple(t_invite_sent);
        _refreshUserList();
      }
      if (errorToastMessage) {
        errorService.uiErrorHandler(errorToastMessage, 5000);
      }
      if (openAddUserModal) {
        newUserModal();
      }
    }
  }

}());

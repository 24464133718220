(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.shopping')
    .service('AddToCartDialog', AddToCartDialog);

  function AddToCartDialog ($mdDialog) {
    /*jshint validthis: true */
    var service = this;

    service.show = showDialog;

    return service;

    function showDialog (productId, targetEvent) {
      return $mdDialog.show({
        'controller': AddToCartController,
        'controllerAs': 'dialogCtrl',
        'templateUrl': 'templates/marketplace/shopping/add-to-cart-dialog.html',
        'targetEvent': targetEvent,
        'parent': angular.element(document.body),
        'clickOutsideToClose': true,
        'locals': {'productId': productId},
      });
    }

    /* @ngInject */
    function AddToCartController ($scope, $mdDialog, productId, productService, cartService, errorService, sgToast) {
      /*jshint validthis: true */
      var ctrl = this;

      ctrl.loading = false;
      ctrl.lockButtons = false;
      ctrl.close = closeDialog;
      ctrl.addToCart = addToCart;

      initializeCtrl(productId);

      return ctrl;

      function initializeCtrl (productId) {
        loadProduct(productId);
        registerClickHandler();
      }

      //
      // Close the dialog without adding to cart.
      //
      function closeDialog () {
        $mdDialog.cancel('cancelled');
        unregisterClickHandler();
      }

      //
      // Setup the binding to handle page clicks.
      //
      function registerClickHandler () {
        angular.element(document).on('click', clickHandler);
      }

      //
      // Stop listening for click events.
      //
      function unregisterClickHandler () {
        angular.element(document).off('click', clickHandler);
      }

      //
      // Intercept click events, and close the dialog if we've clicked on a
      // ui-sref link. This is so that the ui-sref can make links work natively
      // without needing to use ngClick and co-opt native browser behaviour
      // (just to get the dialog to close).
      //
      function clickHandler (clickEvent) {
        var sref = _.get(clickEvent, 'currentTarget.activeElement.attributes.ui-sref.nodeValue');

        if (sref) {
          closeDialog();
        }
      }

      //
      // Add Product from Vendor to Cart
      //
      function addToCart (vendor, event) {
        ctrl.lockButtons = true;

        return cartService.addToCart(productId, 1, vendor.id).then(function (mesg) {
          sgToast.showSimple(mesg);
          $mdDialog.hide();
          unregisterClickHandler();
        }).catch(function (error) {
          errorService.uiErrorHandler(error);
        }).finally(function () {
          ctrl.lockButtons = false;
        });
      }

      //
      // Load the Product Details
      //
      function loadProduct (productId) {
        ctrl.loading = true;
        return productService.getProductsById([productId]).then(function (products) {
          if (products.length < 1) {
            throw 'Unable to find product: ' + productId + '.';
          }

          ctrl.loading = false;
          $scope.product = products[0];

          return $scope.product;
        }).then(null, function (error) {
          ctrl.loading = false;
        });
      }
    }
  }
}());

(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.inventory')
    .controller('inventorySuppliersController', InventorySuppliersController);

  function InventorySuppliersController ($scope, $log, elementService,
    SowingoColorService, inventoryItemService, SupplierDialog, SupplierService,
    errorService, vendorService) {
    /*jshint validthis: true */
    var ctrl = this;
    var highestNbItems = 0;

    $scope.suppliers = {};
    $scope.vendorLogos = {};
    $scope.supplierBundles = [];
    $scope.getColor = getColor;
    $scope.getItems = getItems;
    $scope.fetchBundleItems = fetchBundleItems;
    $scope.addSupplier = SupplierDialog.show;
    $scope.editSupplier = editSupplier;
    $scope.deleteSupplier = deleteSupplier;
    $scope.noSupplierComparator = noSupplierComparator;
    $scope.getInventoryValue = getInventoryValue;

    init();

    return ctrl;

    function editSupplier (ev, supplier) {
      ev.preventDefault();
      ev.stopPropagation();

      var existing_rep = _.find($scope.sales_reps, function(this_rep){
        return this_rep.supplier.id === supplier.id && this_rep.is_primary;
      }) || null;

      SupplierDialog.show(ev, supplier, existing_rep).then(function (result) {
        var bundle;

        _.forEach($scope.supplierBundles, function (_bundle) {
          if (_bundle.id === supplier.id) {
            bundle = _bundle;
            return false;
          }
        });

        _.forEach(bundle.items, function (item) {
          inventoryItemService.getItem(item.id, {'force': true});
        });
        if (result.sales_rep) {
          if (existing_rep) {
            existing_rep = result.sales_rep;
          } else {
            $scope.sales_reps.push(result.sales_rep);
          }
        }
      });
    }

    //
    // Action to delete a supplier
    //
    function deleteSupplier (event, supplier) {
      event.preventDefault();
      event.stopPropagation();

      return SupplierService.remove(supplier, event).then(function (wasDeleted) {
        if (wasDeleted) {
          _refreshSuppliers();
        }
      }).catch(function (error) {
        errorService.uiErrorHandler(error);
      });
    }

    //
    //
    //

    function init () {

      if ($scope.clearSearch) {
        $scope.clearSearch();
      }

      vendorService.getAllVendors().then(function (vendors) {
        console.debug('vendors: %o', vendors);

        _.forEach(vendors, function (vendor) {
          $scope.vendorLogos[vendor.id] = _.get(vendor, 'logo.url_src');
        });
      });

      _refreshReps();

      $scope.$watchCollection('filters', function () {
        refreshBundleItems();
      });

      getItems();
      _refreshSuppliers();

      $scope.$on('editInventoryItemController: bundle-updated', function (event, bundle) {
        _refreshSuppliers();
      });

      $scope.$on('suppliers_updated', function () {
        _refreshSuppliers();
      });


      $scope.$on('sales_reps_updated', function () {
        _refreshReps();
        _refreshSuppliers();
      });
    }

    //
    //
    //

    function getColor (nb_items) {
      if (highestNbItems < nb_items) {
        highestNbItems = nb_items;
      }

      var opacity =  nb_items / highestNbItems;
      opacity = opacity < 0.3 ? 0.3 : opacity;

      return SowingoColorService.rgba('orange1', opacity);
    }

    //
    //
    //

    function getItems () {
      $scope.items = [];
      var supplierMap = {};

      _.forEach(elementService.elementMaps.inventoryItem, function (item, item_id) {
        if (!supplierMap[item.supplier_id]) {
          supplierMap[item.supplier_id] = 1;
        } else {
          supplierMap[item.supplier_id]++;
        }

        $scope.items.push(item);
      });

      _.forEach($scope.suppliers, function (supplier) {
        supplier.inventory_item_count = supplierMap[supplier.id] || 0;
      });

      refreshBundleItems();

      return $scope.items;
    }

    function refreshBundleItems () {
      _.forEach($scope.supplierBundles, function (bundle) {
        bundle.items = $scope.getBundleItems(bundle, true);
      });
    }

    //
    // Function to speed up template. Avoids _itemsFn from being called
    // multiple times.
    //

    function fetchBundleItems (bundle) {
      bundle.items = $scope.getBundleItems(bundle, true);
      return bundle.items;
    }

    //
    // noSupplierComparator
    //

    function noSupplierComparator(v1) {
      if (v1.name === '(No Suppliers)' ) {
        return -1;
      }

      return 1;

    }

    //
    // _refreshSuppliers
    //

    function _refreshSuppliers (force_bundle_refresh) {
      var options = {};
      return inventoryItemService.buildSupplierBundles(options).then(function (result) {
        $scope.suppliers = result.suppliers;

        //
        // Create a temp lookup table for supplier bundles.
        //
        var tmpBundleMap = {};

        _.forEach($scope.supplierBundles, function (bundle) {
          tmpBundleMap[bundle.id] = bundle;
        });

        //
        // Process the new list of bundles.
        //
        _.forEach(result.bundles, function (bundle) {
          if (!bundle.id && bundle.name !== '(No Supplier)') {
            return true;
          }

          if (tmpBundleMap[bundle.id]) {
            bundle = angular.extend(tmpBundleMap[bundle.id], bundle);
            delete tmpBundleMap[bundle.id];
          } else {
            $scope.supplierBundles.push(bundle);
          }
          bundle.items = $scope.getBundleItems(bundle, true);
        });

        //
        // Remove any bundles that were _not_ in the new list from the
        // supplierBundles list.
        //
        _.forEach(tmpBundleMap, function (bundle) {
          var idx = _.findIndex($scope.supplierBundles, {id: bundle.id});
          if (idx !== -1) {
            $scope.supplierBundles.splice(idx, 1);
          }
        });

        // $scope.supplierBundles.sort(function (a, b) {
        //   var aName = a.name.toLowerCase();
        //   var bName = b.name.toLowerCase();
        //   return (
        //     aName < bName || bName === '(no supplier)' ? -1
        //     : aName > bName || aName === '(no supplier)'? 1
        //     : 0
        //   );
        // });

        $scope.getItems();
      });
    }

    function _refreshReps () {
      SupplierService.getSalesReps()
      .then(function(reps_response){
        $scope.sales_reps = reps_response;
      });
    }

    function getInventoryValue(items) {
      return _.reduce(items, function(sum, item) {
        var amount = inventoryItemService.calculateInventoryValue(item);
        return sum + amount;
      }, 0);
    }
  }
}());

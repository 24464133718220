(function () {
  'use strict';

  angular.module('sowSales')
  .controller('salesProductController', salesProductController)
  .directive('saleProduct', salesProductDirective);

  function salesProductDirective () {
    return {
      restrict: 'E',
      templateUrl: 'sow-sales/directives/sale-product.html',
      controller: 'salesProductController',
      controllerAs: 'spCtrl',
      scope: {
        'product': '=',
      },
    };
  }

  function salesProductController ($scope, $rootScope, $state, sowMktService, mktHelperService) {
    /*jshint validthis:true*/
    var ctrl = this;

    ctrl.productDetails = productDetails;
    ctrl.goToCategory = goToCategory;
    ctrl.goToSubcategory = goToSubcategory;
    ctrl.goToManufacturer = goToManufacturer;
    ctrl.addQuantity = addQuantity;

    init();

    return ctrl;

    function init () {
      setPromoInfo($scope.product);
      setQtyInfo($scope.product);
    }

    function productDetails (product) {
      // $rootScope.$broadcast('mkt-details-open', product);
      mktHelperService.productDetailsFetch(product.id);
    }

    function goToCategory (category) {
      var params = {
        'cat_id': category.id,
        'category': category
      };
      mktHelperService.goToCategory(params);
    }

    function goToSubcategory (category, subcategory) {
      var params = { 
        'sub_id': subcategory.id, 
        'subcategory': subcategory, 
        'cat_id': category.id, 
        'category': category
      };
      mktHelperService.goToSubcategory(params);
    }

    function goToManufacturer (manufacturer) {
      var params = {
        'mf_id': manufacturer.id,
        'manufacturer': manufacturer
      };
      mktHelperService.goToManufacturer(params);
    }

    function addQuantity ($event, vendor_item) {
      $event.preventDefault();
      $event.stopPropagation();
      return mktHelperService.addToCartQuantityDialog($event, $scope.product, vendor_item);
    }

    function setPromoInfo (product) {
      var promo = _.get(product, 'vendor_inventory[0].promotions[0]', {});
      var vi = _.get(product, 'vendor_inventory[0]', {});
      _.set(product, 'UI.vendor_item', vi);
      _.set(product, 'UI.vendor', vi.vendor);
      _.set(product, 'UI.promo', promo);
      _.set(product, 'UI.promo_end_label', moment(promo.end_date).format('MMMM DD, YYYY'));
    }

    function setQtyInfo (product) {
      if (!_.isNil(product.on_hand)) {
        if(!_.isNil(product.minimum_level) && (product.minimum_level >= product.on_hand)) {
          _.set(product, 'UI.min_lvl_alert', true);
        } else {
          _.set(product, 'UI.qty_on_hand', true);
        }
      }
    }
    
  }

})();
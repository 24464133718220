(function () {
  'use strict';

  angular
    .module('sowAlerts')
    .controller('alertsSectionCtrl', alertsSectionCtrl);

  function alertsSectionCtrl (alertsDataCarrierService) {
    /*jshint validthis: true */
    var ctrl = this;

    init();

    return ctrl;

    function init () {
      ctrl.alertsDataCarrierService = alertsDataCarrierService;
    }

  }
}());

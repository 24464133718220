(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.registration')
    .controller('RequestInviteCtrl', RequestInviteCtrl);


  function RequestInviteCtrl ($scope, $log, registrationService, errorService) {
    /*jshint validthis: true */
    var ctrl = this;

    $scope.getMatches = getMatches;
    $scope.sendRequest = sendRequest;

    return ctrl;

    function getMatches (searchText) {
      return registrationService.searchOfficeNames(searchText);
    }

    function sendRequest (selectedOffice) {
      return registrationService.requestOfficeInvite(selectedOffice.id).then(function () {
        $state.go('app.registration.invite_request_sent');
      }).catch(function (error) {
        errorService.uiErrorHandler(error);
      });
    }
  }

}());

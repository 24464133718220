{
  'use strict';
  
  /** @ngInject */
  class RegistrationHelperService {
    constructor () {
      this.PLANS = Object.freeze({
        free: 'free',
        premium: 'premium',
        premium_plus: 'premium_plus',
        professional: 'professional',
      });
    }
  }
  
  angular.module('app.marketplace.ui.registration')
  .service('RegistrationHelperService', RegistrationHelperService);
}

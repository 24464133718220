(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.inventory')
    .controller('InventoryLocationsController', InventoryLocationsController);

  function InventoryLocationsController ($scope, $filter, elementService, officeService, SowingoColorService, inventoryItemService, AddLocationsDialog, sgToast, EditLocationDialog, DeleteLocationDialog) {

    $scope.printQrCodes = function (bundle, $event) {
      $event.preventDefault();
      $event.stopPropagation();
      return inventoryItemService.printLabelsForLocation(bundle.location);
    };

    $scope.addLocations = AddLocationsDialog.show;

    $scope.locationActionClick = function (bundle, event) {
      if (bundle.items.length <= 0) {
        event.preventDefault();
        event.stopPropagation();
      }
    };

    $scope.editLocation = function (bundle, event) {
      event.stopPropagation();
      EditLocationDialog.show(bundle, event).then(function () {
        _refreshLocations();
      });
    };

    $scope.deleteLocation = function (bundle, event) {
      event.stopPropagation();
      var location = bundle.location;

      function remove () {
        officeService.removeLocation(location).then(function () {
          _refreshLocations();
          var t_message = $filter('translate')('OFFICE.LOCATION.REMOVED');
          sgToast.showSimple(t_message);
        }).catch(function (error) {
          console.error(error);
          var t_message = $filter('translate')('TOAST.ERROR_REMOVING_LOCATION');
          sgToast.showSimple(t_message);
        });
      }

      if (location.num_products > 0) {
        DeleteLocationDialog.show(location, event).then(function (shouldDelete) {
          if (shouldDelete) {
            remove();
          }
        });
      } else {
        remove();
      }
    };

    $scope.$on('inventory_locations_updated', function () {
      _refreshLocations();
    });

    if ($scope.clearSearch) {
      $scope.clearSearch();
    }

    // Field information for recount page. Maybe migrate to somewhere else? Most
    // convenient here.
    $scope.on_hand_field = {
       "name" : "on_hand",
       "display_name" : "On Hand",
       'icon' : 'widgets',
       'onsubmit' : function(item){
         item.UI.onhand_updated=true;
         item.UI.focus_field=false;
       },
       'focus_next' : true
    };

    var highestNbItems = 0;
    $scope.getColor = function(nb_items){
      if (highestNbItems === 0) {
        SowingoColorService.rgba('orange1', 0.3);
      }

      if (highestNbItems < nb_items) {
        highestNbItems = nb_items;
      }

      var opacity =  nb_items / highestNbItems;
      opacity = opacity < 0.3 ? 0.3 : opacity;

      return SowingoColorService.rgba('orange1', opacity);
    };

    $scope.getItems = function(){
      $scope.items = [];
      var locMap = {};

      function tallyLocation (location) {
        if (!locMap[location.id]) {
          locMap[location.id] = 1;
        } else {
          locMap[location.id]++;
        }
      }

      _.forEach(elementService.elementMaps.inventoryItem, function (item, item_id) {
        _.forEach(item.locations, tallyLocation);
        $scope.items.push(item);
      });

      _.forEach($scope.locations, function (location) {
        location.num_products = locMap[location.id] || 0;
      });

      return $scope.items;
    };


    var _refreshLocations = function(){
      return inventoryItemService.buildLocationBundles().then(function (result) {
        $scope.locations = result.locations;
        $scope.locationBundles = result.bundles;
        $scope.getItems();
      });
    };

    _refreshLocations();

    // Function to speed up template. Avoids _itemsFn from being called multiple
    // times.
    //
    // WARNING: This is only called in the template. If you remove the ng-if in
    //          the template that calls this, it will never be called.
    $scope.fetchBundleItems = function(bundle){
      bundle.items = $scope.getBundleItems(bundle, true);
      return bundle.items;
    };
  }
}());

(function () {
  'use strict';

  angular
    .module('app.shared.system.api')
    .factory('userTokenHandler', userTokenHandler);

  function userTokenHandler ($q, $injector, $rootScope, apiTokenService, apiUrl) {
    return {
      request: handleRequest,
      response: handleResponse,
      responseError: handleResponseError,
    };

    //
    // Extract the hostname from a url
    //
    function normalizeURI (url) {
      var link = document.createElement('a');
      link.href = url;

      var protocolMap = {
        'http': '80',
        'https': '443',
      };

      var hostname = link.hostname;
      var protocol = link.protocol;
      var port = link.port;

      if (!port && protocolMap[link.protocol]) {
        port = protocolMap[link.protocol];
      }

      return [
        link.hostname,
        link.port,
      ].join(':');
    }

    //
    // Check if the request is/was a Sowingo request
    //
    function isSowingoRequest (request) {
      return normalizeURI(apiUrl) === normalizeURI(request.url);
    }

    //
    // Add the 'User-Token' header to out-going requests when appropriate.
    //
    function handleRequest (request) {
      if (isSowingoRequest(request) && apiTokenService.hasValidToken()) {
        request.headers['User-Token'] = apiTokenService.getToken();
      }
      return request || $q.when(request);
    }

    //
    // If we get a HTTP 401 back, we need to clear the token.
    //
    function handleResponse (response) {
      if (response.status === 401) {
        apiTokenService.clearToken();
        return $q.reject(response);
      }
      return response || $q.when(response);
    }

    //
    // If we get a HTTP 401 back, we need to clear the token.
    //
    function handleResponseError (rejection) {
      if (rejection.status === 401) {
        apiTokenService.clearToken();
      }
      return $q.reject(rejection);
    }
  }

}());


angular.module('app.marketplace.ui.inventory')

.directive('inventoryBuyingForm', function() {
  return {
    restrict: 'E',
    controllerAs: 'iBFCtrl',
    templateUrl: 'templates/marketplace/inventory/directives/inventory-buying-form.html',
    scope: true,
    bindToController: {
      context: '@',
      itemClone: '=',
      action: '@',
      form: '='
    },
    controller: function($scope) {
      var ctrl = this;

      ctrl.changeBoughtInPackage = changeBoughtInPackage;
      ctrl.checkPoStatus = checkPoStatus;

      
      ctrl.$onInit = init;

      return ctrl;

      //////////////

      function init () {
        if (!_.isBoolean(ctrl.itemClone.buy_by_case)) {
          ctrl.itemClone.buy_by_case = false;
        }
        if (!ctrl.itemClone[ctrl.caseQuantity]) {
          ctrl.itemClone[ctrl.caseQuantity] = null;
        }
        ctrl.caseQuantity = ctrl.context === 'product' ? 'order_package_quantity' : 'case_quantity';
        ctrl.isCreate = ctrl.action === 'create';
        var status = checkPoStatus(ctrl.itemClone);
        _.set(ctrl.itemClone, 'disable_buy_options', status);
      }

      function changeBoughtInPackage(value) {
        if (!value) {
          ctrl.itemClone.tracking_method = 'Item';
          ctrl.itemClone[ctrl.caseQuantity] = null;
        }
      }

      function checkPoStatus (item) {
        if (!item.id) {
          return false;
        }
        var stats = _.get($scope, '$parent.itemPoStatus[item.id]', null);
        if(!stats){
          stats = _.get(item, 'po_status', null);
        }
        return stats && (_.get(stats, 'Active.length', 0) > 0 || _.get(stats, 'Draft.length', 0) > 0);
      }
    }
  };
});

angular.module("app.shared.system")
  // NativeStorage storage is also used in conjunciton with localStorage to compensate for iOS issues.
  .service("localStorageService", ["$rootScope", "sowInteropService", function ($rootScope, sowInteropService) {
    var appID = "SWG-"; // String to go before every storage id.

    /**
     * sends an event to window so we can catch it in react
     */
     function notifyReact () {
      sowInteropService.triggerStorageChange();
    }

    var getStorageID = function (STORAGE_ID, ignoreAccount) {
      ignoreAccount = true; //Hard coded for now. TODO reassess functionality inclusion.
      var combinedID = appID + STORAGE_ID;
      if (!ignoreAccount && $rootScope && $rootScope.account && $rootScope.account.name) {
        combinedID = $rootScope.account.name + "_" + combinedID;
      }
      return combinedID;
    };

    this.get = function (STORAGE_ID, ignoreAccount) {
      var value = JSON.parse(localStorage.getItem(getStorageID(STORAGE_ID, ignoreAccount)));
      return value;
    };
    this.set = function (STORAGE_ID, data, ignoreAccount) {
      if (window.NativeStorage) {
        NativeStorage.putString(getStorageID(STORAGE_ID, ignoreAccount), JSON.stringify(data));
      }
      const value = localStorage.setItem(getStorageID(STORAGE_ID, ignoreAccount), JSON.stringify(data));
      notifyReact();
      return value;
    };
    this.remove = function (STORAGE_ID, data, ignoreAccount) {
      if (window.NativeStorage) {
        NativeStorage.remove(getStorageID(STORAGE_ID, ignoreAccount));
      }
      const value = localStorage.removeItem(getStorageID(STORAGE_ID, ignoreAccount));
      notifyReact();
      return value;
    };
    this.clear = function (STORAGE_ID, data, ignoreAccount) {
      const value = localStorage.clear();
      notifyReact();
      return value;
    };

    //
    // Check if localStorage is supported.
    //
    // source: http://stackoverflow.com/questions/11214404/how-to-detect-if-browser-supports-html5-local-storage
    //
    this.isSupported = function () {
      var mod = getStorageID('fghlkjgf;hlkjlsfgdgkhjns dflgfdhgifhfhOI G&*(ERHGPIUDRhgoiduhrg');
      try {
        localStorage.setItem(mod, mod);
        localStorage.removeItem(mod);
        return true;
      } catch (e) {
        return false;
      }
    };

  }]);

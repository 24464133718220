(function () {
  'use strict';
  angular.module('sowSterilization')
  .controller('stznConfirmActionController', stznConfirmActionController);

  function stznConfirmActionController ($mdDialog, stznHelperService, $filter) {
    /*jshint validthis:true*/
    var ctrl = this;

    ctrl.records_type_names = stznHelperService.records_type_names;
    
    ctrl.cancelDialog = cancelDialog;
    ctrl.closeDialog = closeDialog;
    ctrl.getDurationLabel = getDurationLabel;
    ctrl.getIncubationTimeLabel = getIncubationTimeLabel;

    init();

    return ctrl;

    function init () {
      ctrl.dialog_version = stznHelperService.getRecordVersion(ctrl.record_data);
      var type_name = ctrl.records_type_names[ctrl.dialog_version];
      var action = ctrl.dialog_version === "control" ? "STERILIZATION.PASS":"STERILIZATION.FAIL";
      type_name = $filter('translate')(type_name);
      action = $filter('translate')(action);
      
      ctrl.dialog_title = "{0} {1} Confirmation".format(action, type_name);

      // Change end time only if it is not set, avoiding overwriting the user's input
      if (ctrl.record_data.type === 'biological' && !ctrl.record_data.result_recorded_at) {
        // set up the end time and date to display on screen
        const end_date = stznHelperService.setEndDateUsingIncubationData({
          tested_at: ctrl.record_data.tested_at,
          incubator_time: ctrl.record_data.incubator_time,
        })
        _.set(ctrl.record_data, 'result_recorded_at', end_date);
      }

    }

    function cancelDialog () {
      return $mdDialog.cancel();
    }

    function closeDialog (result) {
      return $mdDialog.hide(result);
    }

    function getDurationLabel () {
      return stznHelperService.getDurationLabel(ctrl.record_data);
    }

    function getIncubationTimeLabel () {
      return moment(ctrl.record_data.tested_at).format('MMMM DD, YYYY  h:mm A');
    }

  }

}());

(function () {
    'use strict';
    angular.module('sowSales')
        .controller('SalesSectionController', SalesSectionController)

    function SalesSectionController() {
        /*jshint validthis: true */
        var ctrl = this;

        ctrl.FEATURE_DATA = {
            'section': {
                'name': 'MAIN_MENU.SALES_TRACKER',
                'icon': { 'material': "label", 'css': 'bright-orange', },
            },
            'plan': {
                'label': 'PAYWALL.PREMIUM_FEATURE',
                'css': 'premium',
            },
            'selling_points': [
                {
                    'header': 'MARKETPLACE.SALES_TRACKER.PW_HEADER_ONE',
                    'text': null,
                    'img': "styles/img/paywalls/1-pricetag"
                },
                {
                    'header': 'MARKETPLACE.SALES_TRACKER.PW_HEADER_TWO',
                    'text': null,
                    'img': "styles/img/paywalls/2-money"
                }
            ]
        };

        return ctrl;
    }

}());

angular.module('app.shared.system.sgToast', []).service('sgToast', function ($mdToast) {
  var _this = this;

  var _defaults = {
    'parent': angular.element('body'),
    'position': 'bottom right',
    'hideDelay': 2000
  };

  var _addDefaultSettings = function (presets) {
    angular.extend(presets._options, _defaults);
    return presets;
  };

  //
  // Return a copy of the default settings
  //
  _this.defaultSettings = function () {
    return angular.copy(_defaults);
  };

  //
  // Wrapper around $mdToast with our defaults applied to the $mdToastPreset
  //
  _this.simple = function () {
    return _addDefaultSettings($mdToast.simple());
  };

  //
  // Just a pass-through for $mdToast.show
  //
  _this.show = function (optionsOrPreset) {
    return $mdToast.show(optionsOrPreset);
  };

  //
  // Transparent wrapper around $mdToast.updateTextContent
  //
  _this.updateTextContent = function () {
    return $mdToast.updateTextContent();
  };

  //
  // Wrapper around $mdToast.build()
  //
  _this.build = function () {
    return _addDefaultSettings($mdToast.build());
  };

  //
  // A pass-through for $mdToast.cancel (DEPRECATED)
  //
  _this.cancel = function (response) {
    return $mdToast.cancel(response);
  };

  //
  // A pass-through for $mdToast.hide
  //
  _this.hide = function (response) {
    return $mdToast.hide(response);
  };

  //
  // Show a simple $mdToast with our default config
  //
  _this.showSimple = function (message) {
    return _this.show(_this.simple().textContent(message));
  };
});

(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.account')
    .controller('PasswordResetCtrl', PasswordResetCtrl);

  function PasswordResetCtrl ($scope, $stateParams, accountService) {
    /*jshint validthis: true */
    var ctrl = this;

    ctrl.validateToken = validateToken;
    ctrl.changePassword = changePassword;
    ctrl.init = init;

    init();

    return ctrl;

    //
    // Validate Token
    //
    function validateToken (token) {
      $scope.viewState = 'validating';

      return accountService.validatePasswordResetToken(token).then(function () {
        $scope.viewState = 'validToken';
      }).catch(function (error) {
        $scope.viewState = (error === 'Invalid Token') ? 'invalidToken'
                         : 'serverError';
      });
    }

    //
    // Submit the "Change Password" Form.
    //
    function changePassword () {
      if (!$scope.ResetPasswordForm.$valid) {
        $scope.ResetPasswordForm.$setSubmitted(true);
        return;
      }

      $scope.submitting = true;

      return accountService.resetPasswordWithToken(
          $stateParams.token,
          $scope.password,
          $scope.confirm_password
      ).then(function (response) {
        $scope.submitting = false;
        $scope.viewState = 'complete';
      }).catch(function (error) {
        $scope.submitting = false;
      });
    }

    //
    // Initialize Controller / $scope
    //
    function init () {
      angular.extend($scope, {
        viewState: 'validating',
        submitting: false,
        flags: {
          validating: false,
          validToken: false
        },
        changePassword: ctrl.changePassword
      });

      //
      // Update $scope.flags Based on $scope.viewState
      //
      $scope.$watch('viewState', function (newValue) {
        angular.extend($scope.flags, {
          complete: (newValue === 'complete'),
          validating: (newValue === 'validating'),
          validToken: (newValue === 'validToken'),
          serverError: (newValue === 'serverError'),
          invalidToken: (newValue === 'invalidToken')
        });
      });

      if ($stateParams.token) {
        ctrl.validateToken($stateParams.token);
      } else {
        $scope.viewState = 'invalidToken';
      }
    }
  }
}());

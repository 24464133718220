(function () {
  'use strict';

  var hexRegex1 = /^#([0-9A-F]{2})([0-9A-F]{2})([0-9A-F]{2})$/i;
  var hexRegex2 = /^#([0-9A-F])([0-9A-F])([0-9A-F])$/i;
  var rgbaRegex = /^rgba\(\s*([0-9.]+)\s*,\s*([0-9.]+)\s*,\s*([0-9.]+)\s*,\s*([0-9.]+)\*\)$/i;
  var rgbRegex = /^rgb\(\s*([0-9.]+)\s*,\s*([0-9.]+)\s*,\s*([0-9.]+)\s*\)$/i;
  
  function genRGBA(red, green, blue, alpha) {
    return 'rgba({0}, {1}, {2}, {3})'.format(red, green, blue, alpha);
  }
  
  function setAlpha (colorString, alphaValue) {
    colorString = _.trim(colorString);
    var match = (hexRegex1.exec(colorString) || rgbaRegex.exec(colorString) || rgbRegex.exec(colorString));
    var isHex = (match && match[0][0] === '#');
    
    if (!match) {
      throw 'Invalid Color Value: {0}'.format(colorString);
    }
    
    return genRGBA(
      isHex ? parseInt(match[1], 16) : match[1],
      isHex ? parseInt(match[2], 16) : match[2],
      isHex ? parseInt(match[3], 16) : match[3],
      alphaValue
    );
  }

  var ChartJSDefaults = {
    'options': {
      'responsive': true,
      'maintainAspectRatio': true,
      'tooltips': {
        'enabled': true,
        'displayColors': false,
        'mode': 'single',
        'titleFontFamily': "'Roboto', 'Helvetica', 'Arial', 'Sans-Serif'",
        'footerFontFamily': "'Roboto', 'Helvetica', 'Arial', 'Sans-Serif'",
        'bodyFontFamily': "'Roboto', 'Helvetica', 'Arial', 'Sans-Serif'"
      },
      'legend': {
        'fullWidth': false,
        'position': 'right',
        'labels': {
          'boxWidth': 12,
          'fontFamily': "'Roboto', 'Helvetica', 'Arial', 'Sans-Serif'",
          'generateLabels': function (chart) {
            var data = chart.data;

            return _.isArray(data.datasets) ? data.datasets.map(function (dataset, i) {
              return {
                'text': ' ' + dataset.label,
                'fillStyle': dataset.borderColor,
                'hidden': !chart.isDatasetVisible(i),
                'lineCap': dataset.borderCapStyle,
                'lineDash': dataset.borderDash,
                'lineDashOffset': dataset.borderDashOffset,
                'lineJoin': dataset.borderJoinStyle,
                'lineWidth': dataset.borderWidth,
                'strokeStyle': dataset.borderColor,
                // Below is extra data used for toggling the datasets
                'datasetIndex': i
              };
            }) : [];
          },
        }
      }
    },
    'createLineChartOptions': function (options) {
      return _.merge({}, this.options, {
        'scales': {
          'xAxes': [{
            'ticks': {
              'fontFamily': "'Roboto', 'Helvetica', 'Arial', 'Sans-Serif'",
            },
            'gridLines': {
              'display': false,
            }
          }],
          'yAxes': [{
            'ticks': {
              'fontFamily': "'Roboto', 'Helvetica', 'Arial', 'Sans-Serif'",
              'beginAtZero': true,
            }
          }],
        }
      }, options);
    },
    'createLineChartOverride': function (color, options) {
      options = options || {};
      return _.merge({}, {
        'fill': false,
        'lineTension': 0,
        'borderColor': color,
        'borderWidth': 5,
        'pointRadius': 5,
        'pointBorderWidth': 2,
        'pointBorderColor': 'rgba(255, 255, 255, 1)',
        'pointBackgroundColor': color,
        'pointHoverRadius': 7,
        'pointHoverBorderColor': 'rgba(255, 255, 255, 1)',
        'pointHoverBorderWidth': 1
      }, options);
    },
    'createBarChartOptions': function (options) {
      return _.merge({}, this.options, {
        'scales': {
          'yAxes': [{
            'ticks': {
              'fontFamily': "'Roboto', 'Helvetica', 'Arial', 'Sans-Serif'",
              'beginAtZero': true,
            },
            'gridLines': {
              'drawTicks': true,
              'color': 'rgba(128,142,155,0.25)',
              'zeroLineColor': 'rgba(128,142,155,0.25)',
            },
          }],
          'xAxes': [{
            'gridLines': {
              'display': false,
            },
            'ticks': {
              'fontFamily': "'Roboto', 'Helvetica', 'Arial', 'Sans-Serif'",
            }
          }]
        }
      }, options);
    },
    'createBarChartOverride': function (color, options) {
      options = options || {};

      return _.merge({
        'borderWidth': 0,
        'borderColor': color,
        'backgroundColor': color,
        'hoverBorderWidth': 2,
        'hoverBorderColor': color,
        'hoverBackgroundColor': setAlpha(color, 0.8)
      }, options);
    }
  };

  angular.module('sowReports')
    .constant('ChartJSDefaults', ChartJSDefaults)

})();

(function () {
  'use strict';

  angular
    .module('sowReports')
    .controller('ImplantsPlacementController', ImplantsPlacementController);

  function ImplantsPlacementController (membershipService) {
    var ctrl = this;

    init();

    function init () {
      defineLocks();
    }

    function defineLocks () {
      const membership = membershipService.get();

      const patient_condition = _.get(membership, 'user_properties.reports.disable_patient', false);
      ctrl.patient_locked = patient_condition;

      const ref_doc_condition = _.get(membership, 'user_properties.reports.disable_ref_doc', false);
      ctrl.ref_doc_locked = ref_doc_condition;
    }
  }
}());


//Directive for displaying a breadcrumbs list. TODO generalize atm it is designed only for the .shopping states. 
angular.module("app.shared.support")
.directive('breadcrumbs',[ function() {
  return {
    restrict: 'E',
    replace: true,
    transclude: true,
    templateUrl: 'templates/shared/support/directives/breadcrumbs.html',
    scope: {
      element: '='
    },
    controller: function($scope){
      $scope.$watch('element', function(newElementValue, oldElementValue){
        if(newElementValue){
          $scope.category = null; 
          $scope.parent_category = null;
          if($scope.element.params.model_name==="product"){
            $scope.category = $scope.element.categories[0];
            if($scope.category.parent && $scope.category.parent.id){
              $scope.parent_category = $scope.category.parent;
            }
          }else if($scope.element.params.model_name==="category"){
            if($scope.element.parent && $scope.element.parent.id){
              $scope.parent_category = $scope.element.parent;
            }
          }
        }
      });
    }
  };
}]);

angular.module("app.marketplace.elements")

.service("productTemplateModel", ["searchUrl", "apiUrl", function(searchUrl, apiUrl) {
  var _this = this;
  this.model_data = {
    "name" : "productTemplate",
    "display_name" : "Product Template",
    "api" : {  
      }
    };

  this.autofill = function(element) {
  };
  
  this.fields = {
    "id" : {
      "display_name" : "id",
      "required" : true,
      "api" : {
        "submit" : true
      },
      "type" : "integer"
    }
  };

  this.data = {
    "params" : {
      "model_name" : "productTemplate"
    } 
  };

} ]);
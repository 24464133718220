(function () {
  'use strict';

  angular.module('sowMarketplace')
  .controller('marketplacePriceInfoController', marketplacePriceInfoController)
  .directive('mktPriceInfo', marketplacePriceInfoDirective);

  function marketplacePriceInfoDirective () {
    return {
      restrict: 'E',
      templateUrl: 'sow-mkt/directives/mkt-price-info.html',
      controller: 'marketplacePriceInfoController',
      controllerAs: 'mktpiCtrl',
      scope: true,
    };
  }

  function marketplacePriceInfoController ($scope, $rootScope) {
    /*jshint validthis:true*/
    var ctrl = this;

    init();

    return ctrl;

    function init () {
    }
    
  }

})();
(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.marketing')
    .controller('MobileAppInfoController', MobileAppInfoController);

  function MobileAppInfoController ($location) {
    /*jshint validthis: true */
    var ctrl = this;

    ctrl.goToSection = goToSection;

    return ctrl;

    function goToSection (sectionId) {
      $location.hash(sectionId);
    }
  }

}());

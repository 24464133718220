(function () {
  'use strict';
  
  const sowMktBilling = {
    selector: 'sowMktBilling',
    templateUrl: 'sow-mkt/directives/mkt-billing.html',
    controller: 'marketplaceBillingController',
    controllerAs: 'mktbCtrl',
  };

  angular.module('sowMarketplace')
    .controller(sowMktBilling.controller, marketplaceBillingController)
    .component(sowMktBilling.selector, sowMktBilling);

  /** @ngInject */
  function marketplaceBillingController ($scope, $rootScope, $filter, stripe, $mdDialog, errorService, cartService, officeService, addressService, elementService, creditCardService, formService, sowAnalyticsService) {
    /*jshint validthis:true*/
    var ctrl = this;
    ctrl.use_shipping_address = true;
    ctrl.loading = false;
    ctrl.new_card = null;
    ctrl.submit_order_once_billing_updated = false;

    ctrl.handleAddCardClick = handleAddCardClick;
    ctrl.editCard = editCard;
    ctrl.removeCard = removeCard;
    ctrl.saveCard = saveCard;
    ctrl.cancelEdit = cancelEdit;
    ctrl.updateSelectedCard = updateSelectedCard;
    ctrl.setShowSuiteField = setShowSuiteField;
    ctrl.discardNewCard = discardNewCard;
    ctrl.addCreditCard = addCreditCard;
    ctrl.handleCheckboxChange = handleCheckboxChange;
    ctrl.handleCardClick = handleCardClick;

    init();

    return ctrl;

    function init () {
      updateCart();
      updateBilling();
      $scope.$on('mkt-shipping-address-updated', updateCart);
      $scope.$on('cartService: set-cart', updateCart);
      $scope.$on('cartService: clear-cart', updateCart);
      $scope.$on('cartService: itemAddedToCart', updateCart);
      $scope.$on('save-card-and-complete-order', _saveCardAndCompleteOrder);
    }

    function updateCart () {
      ctrl.cart = cartService.get();
      ctrl.shipping_address = ctrl.cart.shipping_info;
    }

    /**
     * Attempts to save the credit card information and then completes the order
     * @returns A promise
     */
    function _saveCardAndCompleteOrder() {
      ctrl.submit_order_once_billing_updated = true;
      return addCreditCard();
    }

    function loadCards() {
      ctrl.loading = true;
      return creditCardService.getCards()
        .then(fetched_cards => {
          ctrl.credit_cards = fetched_cards || [];
          if (ctrl.credit_cards.length === 0) {
            newCard();
          }
        })
        .catch(_error => {
          const t_message = $filter('translate')('ERRORS.CREDIT_CARDS_LOAD')
          errorService.uiErrorHandler(t_message, 0);
        })
        .finally(() => {
          ctrl.loading = false;
        });
    }

    function editCard ($event, credit_card, index) {
      $event.preventDefault();
      $event.stopPropagation();
      _.set(credit_card, 'UI.edit', true);
      _.set(ctrl, 'editing', true);
      // stripe save card
      // .then editing = false;
    }

    function updateBilling () {
      return loadCards()
      .then(function() {
        setDefaultCard();
      });
    }

    function setDefaultCard () {
      // if current id is not on the list, set to list[0]
      var exists = _.find(ctrl.credit_cards, ['id',ctrl.selected_card_id]);
      var first = _.first(ctrl.credit_cards);
      if(!exists) {
        if(first) {
          setCard(first);
        } else {
          setCard(null);
        }
      } else {
        updateSelectedCard();
      }
    }

    function setCard (card) {
      ctrl.selected_card = card;
      ctrl.selected_card_id = card?.id;
      updateSelectedCard();
    }

    function removeCard($event, credit_card) {
      $event.preventDefault();
      $event.stopPropagation();
      const confirm_deletion = $mdDialog.confirm({
        clickOutsideToClose: true,
        template: `
          <md-dialog class="delete-cc-dialog" aria-label="Delete credit card dialog"> 
            <h3 class="sow-h3">
              {{'MARKETPLACE.CARD.DELETE_CC' | translate}}
            </h3>
            <p>
              {{'MARKETPLACE.CARD.DELETE_CC_MSG' | translate}}
            </p>
            <div class="confirmation-btns">
              <button class="sow-lg-btn sow-delete-btn" ng-click="handleClick(true)">
              {{'MARKETPLACE.CARD.DELETE_CARD' | translate}}
              </button>
              <button class="sow-lg-btn sow-cancel-btn" ng-click="handleClick()">
              {{'ACTIONS.CANCEL' | translate}}
              </button>
            </div>
          </md-dialog>
        `,
        /** @ngInject */
        controller: ($scope, $mdDialog) => {
          $scope.handleClick = deletion_confirmed => { // boolean indicating whether the delete button was clicked
            $mdDialog.hide(deletion_confirmed);
          };
        },
      });
      $mdDialog.show(confirm_deletion).then(deletion_confirmed => {
        if (deletion_confirmed) {
          ctrl.saving = true;
          return creditCardService.removeCard(credit_card)
            .then(() => updateBilling())
            .catch(err => errorService.uiErrorHandler(err))
            .finally(() => ctrl.saving = false); 
        }
      });
    }

    function handleCardClick(card) {
      // only log the event if the user clicked
      // a card which was not already selected
      if (card.id === ctrl.selected_card_id) {
        return;
      }
      ctrl.selected_card = card;
      // the first card we get from the API
      // is the default card for this office
      const default_card = ctrl.credit_cards[0];
      const default_status = card.id === default_card.id;
      const expiry_year = card.exp_year;
      const params = { default_status, expiry_year };
      return sowAnalyticsService.orderReviewPaymentMethodSelectorClicked(params);
    }

    function handleCheckboxChange() {
      if (ctrl.use_shipping_address) {
        ctrl.new_card.address_data = angular.copy(ctrl.shipping_address);
      }
      return ctrl.use_shipping_address
        ? sowAnalyticsService.orderReviewCcAddressCheck()
        : sowAnalyticsService.orderReviewCcAddressUncheck();
    }

    function handleAddCardClick() {
      const office = officeService.get();
      const plan_name = office.account_type;
      sowAnalyticsService.orderReviewAddPaymentMethodClicked(plan_name);
      newCard();
    }

    function newCard () {
      ctrl.new_card = { address_data: angular.copy(ctrl.shipping_address) };
      ctrl.use_shipping_address = true;
      setShowSuiteField();
      editingForm();
    }

    function saveCard (card, $event) {
      ctrl.new_card = card;
      addCreditCard($event);

    }

    function editingForm () {
      $rootScope.billing_form_unsaved = true;
      $rootScope.$broadcast('billing-address-edit');
    }

    function savingForm () {
      $rootScope.billing_form_unsaved = false;
      $rootScope.$broadcast('billing-address-saved');
    }

    function cancelEdit (card, $event) {
      _.set(card, 'UI.edit', false);
      if(!card.id){
        _.remove(ctrl.credit_cards, card);
      }
      savingForm();
    }

    function addCreditCard(e) {
      e?.preventDefault?.();

      // in the event that the card form is not toggled open when the user
      // has elected to place their order, this method will be called and
      // we need to simply auto submit the order
      if (!ctrl.NewCreditCardForm) {
        return _handleAutoOrderSubmit();
      }

      // if the form is toggled open but invalid, we need to alert the user
      if (!formService.checkRequiredFields(ctrl.NewCreditCardForm)) {
        return _handleInvalidCard();
      }

      const office = officeService.get();
      const plan_name = office.account_type;
      sowAnalyticsService.orderReviewSavePaymentMethodConfirmed(plan_name);

      ctrl.saving = true;

      //
      // Pull in the address information. addressService should validate it
      // and give us a sgToast if there is an issue.
      //
      var address = addressService.extractAddress(
        ctrl.use_shipping_address ? ctrl.shipping_address : ctrl.new_card.address_data,
        ctrl.use_shipping_address ? {errorPrefix: 'Invalid Shipping Address'} : undefined
      );

      if (address) {
        angular.extend(ctrl.new_card, address);
      } else {
        return;
      }

      return _uploadNewCreditCard();
    }

    /** 
     * Uploads a new card and then adds the newly saved card to the credit_cards array
     * @returns A promise.
     */
    function _uploadNewCreditCard() {
      return creditCardService.addNewCard(ctrl.new_card)
        .then(res => {
          const new_card = res.data;
          $scope.$emit('creditCards: creditCardAdded', new_card);
          ctrl.credit_cards.push(new_card);
          setCard(new_card);
          ctrl.new_card = null;
          savingForm();
          loadCards();
        })
        .catch(error => {
          _handleUploadNewCardError(error);
        })
        .finally(() => {
          ctrl.saving = false;
        });
    }

    /**
     * Handles errors that occur when a user tries to upload a new card to their account
     * @param {object} error - The error object returned by the API.
     */
    function _handleUploadNewCardError(error) {
      _handleInvalidCard();

      let error_message = '';

      // handle nested error message
      switch (error?.message?.error_body?.code) {
        case 'incorrect_zip':
          error_message = 'The billing address does not match the one associated with the card';
        case 'card_declined':
          error_message = error?.message?.error_body?.message;
      }

      // handle Skyflow error
      if (typeof error?.description === 'string') {
        error_message = error.description;
      }
      // handle Stripe error
      if (typeof error?.message === 'string') {
        error_message = error.message;
      }

      // default
      if (!error_message) {
        const t_card_creation_err = $filter('translate')('ERRORS.CARD_CREATION');
        error_message = t_card_creation_err;
      }

      errorService.uiErrorHandler(error_message, 3000);
    }

    /**
     * If the submit_order_once_billing_updated variable is true, then emit the
     * 'Marketplace:complete-order' event to check out the order
     */
    function _handleAutoOrderSubmit() {
      if (ctrl.submit_order_once_billing_updated) {
        $scope.$emit('Marketplace:complete-order');
      }
      // reset the state
      ctrl.submit_order_once_billing_updated = false;
    }

    /**
     * If there are no credit cards on file, call _handleAutoOrderSubmit.
     * Otherwise, if the submit_order_once_billing_updated variable is true, then
     * emit the 'credit-card-auto-upload-failed' event to alert the user to
     * the billing issue without actually submitting the order (since actually
     * submitting will result in it being placed using the previously selected
     * card but we just want to show the invalid credit card error)
     */
    function _handleInvalidCard() {
      // if there are no credit cards on file, exit early and handle auto submit
      // so that if we're attempting to automatically upload the card prior to
      // order submission, the order will be submitted and the API will return
      // an invalid credit card error, resulting in an error banner in the UI and a
      // slack message which lets the support team know to reach out to the office
      if (!ctrl.credit_cards?.length) {
        return _handleAutoOrderSubmit();
      }

      // otherwise, if we're auto-uploading the card prior to order submission,
      // emit an event so that the order controller will display the message
      // we get back after submitting an order without a valid card to charge
      if (ctrl.submit_order_once_billing_updated) {
        $rootScope.$broadcast('credit-card-auto-upload-failed');
      }
      // reset the state
      ctrl.submit_order_once_billing_updated = false;
    }

    /**
     * Updates the billing information of the user's account with the selected card's
     * ID and skyflow ID
     */
    function updateSelectedCard () {
      // updated to send skyflow id instead of the actual card's id
      cartService.updateBilling(ctrl.selected_card)
        .finally(() => {
          _handleAutoOrderSubmit();
        });
    }

    /**
     * Displays the "suite number" field of the form if a suite number is included
     * in the address or if the user clicks the "add suite number" button.
     *
     * @param {Boolean} button_clicked passed when the user clicks the button
     *
     * @return {*}
    */ 
    function setShowSuiteField(button_clicked) {
      if (button_clicked) {
        ctrl.show_suite_field = true;
      } else {
        ctrl.show_suite_field = _.get(ctrl, 'new_card.address_data.address2') ? true : false;
      }
    }

    function discardNewCard() {
      sowAnalyticsService.orderReviewAddPaymentMethodCancel();
      ctrl.new_card = null;
    }

  }

})();

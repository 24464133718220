angular.module("app.shared.support")
  .directive('categoryCard', function () {
    return {
      restrict: 'A',
      replace: true,
      templateUrl: 'templates/marketplace/elements/category/category-card.html',
      controller: 'categoryController',
      link: function ($scope, $element, $attributes) {
        $scope.options = {};
        $scope.options.hideAdd = $attributes.hideAdd; //Optional to indicate that the add button should be hidden.
        $scope.options.name = $attributes.name; //Optional to change what field is being used as a display name.
      }
    };
  });
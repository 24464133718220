(function () {
  'use strict';

  angular.module('sowSterilization')
    .directive('sowStznTestFormBio', stznTestFormBiologicalDirective);

  function stznTestFormBiologicalDirective() {
    return {
      restrict: 'E',
      templateUrl: 'sow-sterilization/directives/stzn-bio-test-form.html'
    };
  }

})();
(function() {

  'use strict';

  angular.module('app.marketplace.ui.subscriptions')
         .directive('subscriptionPromoCode', SubscriptionPromoCode);

  var defaultPromoCodes = {
    'oda': 'ODA2016'
  };

  /* @ngInject */
  function SubscriptionPromoCodeCtrl ($scope, $log, elementService) {
    var ctrl = this;

    $scope.btnPrefix = $scope.btnPrefix || 'subscription';
    $scope.status = {ok: true, msg: null};

    ctrl.showInput = false;
    ctrl.codeIsValid = null;
    ctrl.errorMsg = null;
    ctrl.isValidating = false;

    ctrl.handleCodeChange = handleCodeChange;
    ctrl.toggleInput = toggleInput;
    ctrl.validate = validatePromoCode;
    ctrl.clear = clearPromoCode;

    return init();

    //
    // initialize controller
    //
    function init () {
      // we _must_ display the <input> if there is something in $scope.promo_code.
      $scope.$watch(function () {
        return ctrl.promo_code;
      }, function (newPromoCode) {
        ctrl.showInput = !!newPromoCode;
        ctrl.codeIsValid = null;
        setFieldValidity(true);
        updateStatus();
      });

      $scope.$watch('country_id', function () {
        if (ctrl.promo_code) {
          validatePromoCode();
        }
      });

      // we need to revalidate the promo code if the association changes, since
      // some promo codes depend on the association
      $scope.$watch('associationId', function () {
        if (ctrl.promo_code) {
          validatePromoCode();
        }
      });

      $scope.$watch('$ctrl.errorMsg', updateStatus);

      if ($scope.promo_code) {
        // If there is an initial value for the promo code, copy it over.
        ctrl.promo_code = $scope.promo_code;
      } else if ($scope.associationId && defaultPromoCodes[$scope.associationId]) {
        ctrl.promo_code = defaultPromoCodes[$scope.associationId];
      }
    }

    //
    // Update the Promo Code Status
    //
    function updateStatus () {
      $scope.status = {
        'ok': !ctrl.errorMsg && (!ctrl.promo_code || ctrl.codeIsValid),
        'msg': (
            ctrl.errorMsg ? ctrl.errorMsg
            : ctrl.codeIsValid === null ? 'Promo code has not been validated'
            : null
        )
      };
    }

    //
    // Quick Wrapper Around $setValidity
    //
    function setFieldValidity (value) {
      $scope.$form.promo_code_field.$setValidity('api_error', value);
    }

    //
    // Validate the Promo Code
    //
    function validatePromoCode (clearOnFailure) {
      ctrl.isValidating = true;

      var promise = elementService.callEndpoint('subscription', {
        endpoint: 'validate_subscription_promo_code',
        code: ctrl.promo_code,
        country_id: $scope.country_id,
        professional_association_id: $scope.associationId
      }).then(function (response) {

        if (response && response.data) {
          if (response.data.valid) {
            ctrl.codeIsValid = true;
            $scope.promo_code = ctrl.promo_code;
            setFieldValidity(true);
            ctrl.errorMsg = null;
          } else {
            $scope.promo_code = null;
            ctrl.codeIsValid = false;
            setFieldValidity(false);
            ctrl.errorMsg = 'Promo code is not valid.';
          }
        }

      }).catch(function (error) {

        setFieldValidity(false);
        $scope.promo_code = null;
        ctrl.errorMsg = error.message;

      })['finally'](function () {

        ctrl.isValidating = false;

        if (clearOnFailure && !ctrl.codeIsValid) {
          clearPromoCode();
        }

        updateStatus();
      });
    }

    //
    // Remove / Clear the Promo Code
    //
    function clearPromoCode () {
      ctrl.promo_code = null;
      $scope.promo_code = null;
      ctrl.codeIsValid = false;
      ctrl.errorMsg = null;
      setFieldValidity(true);
      updateStatus();
    }

    //
    // Handle Updates to the Promo Code (via <input>)
    //
    function handleCodeChange () {
      ctrl.codeIsValid = false;
    }

    //
    // Toggle the showInput flag.
    //
    function toggleInput (value) {
      ctrl.showInput = (arguments.length > 0 ? value : !ctrl.showInput);
    }
  }

  function SubscriptionPromoCode () {
    return {
      restrict: 'E',
      replace: true,
      controller: SubscriptionPromoCodeCtrl,
      controllerAs: '$ctrl',
      template: [
        '<div style="box-sizing: content-box; min-height: 94px;">',
        '  <div flex ng-show="$ctrl.showInput" layout layout-align="center start">',
        '    <md-input-container class="mr-16px">',
        '      <label> Promo&nbsp;Code </label>',
        '      <input type="text" name="promo_code_field" ng-disabled="$ctrl.codeIsValid || $ctrl.isValidating"',
        '             ng-change="$ctrl.handleCodeChange()" ng-model="$ctrl.promo_code">',
        '      <ng-messages for="$form.promo_code_field.$error" md-auto-hide="false">',
        '        <ng-message when="api_error">{{$ctrl.errorMsg}}</ng-message>',
        '      </ng-messages>',

        '    <div class="green-text" ng-show="$ctrl.codeIsValid">',
        '      Promo code is valid.',
        '    </div>',

        '    </md-input-container>',
        '    <div class="mt-24px" ng-show="$ctrl.codeIsValid">',
        '      <a ng-click="$ctrl.clear()" name="{{btnPrefix}}-link-removePromoCode"> Remove </a>',
        '    </div>',

        '    <div class="mt-24px" ng-hide="$ctrl.codeIsValid || $ctrl.isValidating" ng-disabled="$ctrl.isValidating" style="width: 50px;">',
        '      <a ng-click="$ctrl.validate()" name="{{btnPrefix}}-link-validatePromoCode"> Add </a>',
        '    </div>',
        '    <md-progress-circular class="mt-12px" ng-show="$ctrl.isValidating" style="display: inline-block"></md-progress-circular>',
        '  </div>',

        '  <div ng-hide="$ctrl.showInput" class="mt-28px">',
        '    <a class="text-nowrap" ng-click="$ctrl.toggleInput()" name="{{btnPrefix}}-link-addPromoCode"> Have A Promo Code? </a>',
        '  </div>',
        '</div>'
      ].join("\n"),
      scope: {
        '$form': '=form',
        'promo_code': '=promoCode',
        'country_id': '=countryId',
        'associationId': '=',
        'status': '=',
      }
    };
  }

})();

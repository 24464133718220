{
  ('use strict');
  /** @ngInject */
  class ZendeskChatService {
    constructor(accountService) {
      this.accountService = accountService;
      this._zendesk_plugin = window.$zopim;

      window.onbeforeprint = () => {
        zE('webWidget', 'hide');
      };
      window.onafterprint = () => {
        zE('webWidget', 'show');
      };
    }

    /**
     * Configure Zendesk Chat
     * @param {object} session
     * @link https://developer.zendesk.com/api-reference/widget/core/#prefill
     */
    updateZendeskFormWithUserInfo(session) {
      this._zendesk_plugin(() => {
        const user = session?.user;
        if (user && !this.accountService.isGuestUser(user)) {
          // https://developer.zendesk.com/api-reference/widget/core/#prefill
          zE('webWidget', 'prefill', {
            name: {
              value: user.name,
            },
            email: {
              value: user.email,
            },
          });
        }
      });
    }

    /**
     * Changes the language of the chat widget
     * @link https://developer.zendesk.com/api-reference/widget/api/#zesetlocale
     * @param {string} language
     */
    changeZendeskLanguage(language) {
      this._zendesk_plugin(() => {
        zE('webWidget', 'setLocale', language);
      });
    }

    /**
     * Configure the Zendesk theme
     * @link https://developer.zendesk.com/api-reference/widget/core/#updatesettings
     */
    configureZendeskTheme() {
      this._zendesk_plugin(() => {
        // https://developer.zendesk.com/api-reference/widget/settings/
        zE('webWidget', 'updateSettings', {
          webWidget: {
            launcher: {
              chatLabel: {
                '*': 'Help',
                fr: 'Aide',
              },
            },
            chat: {
              title: {
                '*': 'Sowingo Support',
                fr: "L'Équipe de soutien Sowingo",
              },
              prechatForm: {
                greeting: {
                  '*': 'Please fill out the form below to chat with us',
                  fr: "Veuillez remplir le formulaire ci-dessous pour discuter avec un membre de l'équipe de soutien Sowingo.s",
                },
              },
              offlineForm: {
                greeting: {
                  '*': 'Please fill out the form below to chat with us',
                  fr: "Veuillez remplir le formulaire ci-dessous pour discuter avec un membre de l'équipe de soutien Sowingo.s",
                },
              },
            },
          },
        });

        // Set Timeout is needed to ensure the icon is updated after the chat button is loaded
        setTimeout(this._updateZendeskIcon.bind(this), 500);
      });
    }

    /**
     * Updates the Zendesk icon by removing the existing SVG element and
     * appending a new help icon.
     */
    _updateZendeskIcon() {
      zE(() => {
        const iframe = document.querySelector('#launcher');
        const button = iframe?.contentWindow.document.getElementsByTagName('button')[0];

        if (iframe) {
          // This is needed to avoid an overlap between the chat button and slideouts or dialogs
          iframe.style.zIndex = '295';
        }

        // Replace the chat bubble icon with the help icon
        if (button) {
          const icon = button.getElementsByTagName('span')[0];
          // clone the icon element
          const newIcon = icon.cloneNode(true);
          newIcon.removeChild(newIcon.childNodes[0]);
          newIcon.appendChild(this._createHelpIcon());

          if (newIcon) {
            // remove the existing icon from the button
            button.removeChild(icon);
            // prepend the new icon
            button.prepend(newIcon);
          }
        }
      });
    }

    /**
     * Creates the help icon SVG element
     * the svg element is the same as Zendesk's help icon
     * @return {SVGElement}
     */
    _createHelpIcon() {
      // Create an SVG element
      const svgNS = 'http://www.w3.org/2000/svg';
      const svgElement = document.createElementNS(svgNS, 'svg');
      svgElement.setAttribute('xmlns', svgNS);
      svgElement.setAttribute('xmlns:xlink', 'http://www.w3.org/1999/xlink');
      svgElement.setAttribute('x', '0');
      svgElement.setAttribute('y', '0');
      svgElement.setAttribute('width', '20px');
      svgElement.setAttribute('height', '20px');
      svgElement.setAttribute('viewBox', '0 0 20 20');
      svgElement.setAttribute('xml:space', 'preserve');
      svgElement.setAttribute('aria-hidden', 'true');

      // Create the nested <g> elements
      for (let i = 0; i < 3; i++) {
        const gElement = document.createElementNS(svgNS, 'g');
        svgElement.appendChild(gElement);
      }

      // Create the main group containing the path and circle
      const mainGroup = document.createElementNS(svgNS, 'g');
      svgElement.appendChild(mainGroup);

      // Create the path element
      const pathElement = document.createElementNS(svgNS, 'path');
      pathElement.setAttribute(
        'd',
        'M11,12.3V13c0,0-1.8,0-2,0v-0.6c0-0.6,0.1-1.4,0.8-2.1c0.7-0.7,1.6-1.2,1.6-2.1c0-0.9-0.7-1.4-1.4-1.4 c-1.3,0-1.4,1.4-1.5,1.7H6.6C6.6,7.1,7.2,5,10,5c2.4,0,3.4,1.6,3.4,3C13.4,10.4,11,10.8,11,12.3z',
      );
      mainGroup.appendChild(pathElement);

      // Create the circle element
      const circleElement = document.createElementNS(svgNS, 'circle');
      circleElement.setAttribute('cx', '10');
      circleElement.setAttribute('cy', '15');
      circleElement.setAttribute('r', '1');
      mainGroup.appendChild(circleElement);

      // Create the path element outside the main group
      const outsidePathElement = document.createElementNS(svgNS, 'path');
      outsidePathElement.setAttribute(
        'd',
        'M10,2c4.4,0,8,3.6,8,8s-3.6,8-8,8s-8-3.6-8-8S5.6,2,10,2 M10,0C4.5,0,0,4.5,0,10s4.5,10,10,10s10-4.5,10-10S15.5,0,10,0 L10,0z',
      );
      svgElement.appendChild(outsidePathElement);

      return svgElement;
    }
  }

  angular.module('app').service('zendeskChatService', ZendeskChatService);
}

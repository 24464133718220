angular.module("app.marketplace.elements")

.service('sterilizerModel', function (apiUrl) {
  var _this = this;

  _this.model_data = {
    name: 'sterilizer',
    display_name: 'Sterilizer',
    api: {
      single: function (options) {
        if (!checkNested(options, 'data', 'id') || !checkNested(options, 'office', 'id')) {
          throw 'Missing option(s) for sterilizer model path "single". Options: ' + JSON.stringify(options);
        }
        return sprintf('%s/offices/%s/sterilizers/%s', apiUrl, officeId, options.data.id);
      },

      multiple: function (options) {
        if (!checkNested(options, 'office', 'id')) {
          throw 'Missing option(s) for sterilizer model path "multiple". Options: ' + JSON.stringify(options);
        }
        return sprintf('%s/offices/%s/sterilizers', apiUrl, options.office.id);
      },

      update: function (sterilizer) {
        if (!sterilizer) {
          throw 'Missing sterilizer for sterilizer path "create".';
        }

        if (!checkNested(sterilizer, 'office_id')) {
          throw 'Missing sterilizer.office_id for sterilizer path "create".';
        }

        if (!checkNested(sterilizer, 'id')) {
          throw 'Missing sterilizer.id for sterilizer path "update".';
        }

        sterilizer = angular.copy(sterilizer);

        return {
          path: sprintf('%s/offices/%s/sterilizers/%s', apiUrl, sterilizer.office_id, sterilizer.id),
          params: {
            method: 'PUT',
            data: sterilizer
          }
        };
      },

      create: function (sterilizer) {
        if (!sterilizer) {
          throw 'Missing sterilizer for sterilizer path "create".';
        }

        if (!checkNested(sterilizer, 'office_id')) {
          throw 'Missing sterilizer.office_id for sterilizer path "create".';
        }

        sterilizer = angular.copy(sterilizer);

        return {
          path: sprintf('%s/offices/%s/sterilizers', apiUrl, sterilizer.office_id),
          params: {
            method: 'POST',
            data: sterilizer
          }
        };
      }
    }
  };

  _this.autofill = function (sterilizer) {
    autofillDate(sterilizer, 'created_at');
    autofillDate(sterilizer, 'updated_at');
    angular.forEach(sterilizer.modes, function (mode) {
      autofillDate(mode, 'deleted_at');
    });
  };

  _this.fields = {
    id: {
      display_name: 'id',
      required: true,
      api: {
        submit: true
      },
      type: 'integer'
    },
    name: {
      display_name: 'Sterilizer Name',
      required: true,
      api: {
        submit: true
      },
      type: 'string'
    },
    modes: {
      display_name: 'Sterilizer Modes',
      required: true,
      api: {
        submit: true
      },
      type: 'list'
    },
    office_id: {
      display_name: 'Office ID',
      required: true,
      api: {
        submit: true
      },
      type: 'string'
    }
  };
});

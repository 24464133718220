(function () {
  'use strict';

  const sowApprovalsSection = {
    selector: 'sowApprovalsSection',
    controller: 'sowApprovalsSectionCtrl',
    controllerAs: 'sowCtrl',
    templateUrl: '',
    bindings: {
      
    },
  }

  /** @ngInject */
  class sowApprovalsSectionCtrl {
    constructor($scope) {
      this.$scope = $scope;
    }

    $onInit() {
      
    }
    
  }

  angular.module('sowApprovals', [])
    .controller(sowApprovalsSection.controller, sowApprovalsSectionCtrl)
    .component(sowApprovalsSection.selector, sowApprovalsSection)
    .config(approvalsConfig);

    function approvalsConfig($stateProvider, $urlRouterProvider) {
  
      // $urlRouterProvider.when('/app/approvals', '/app/approvals/home');
      // $urlRouterProvider.when('/app/approvals/', '/app/approvals/home/');
  
      $stateProvider.state('app.approvals',{
        url: '/approvals',
        controller: sowApprovalsSection.controller,
        controllerAs: sowApprovalsSection.controllerAs,
        templateUrl: 'sow-approvals/views/section.html',
        data: {
          'title': 'approvals',
          'require_log_in': false,
          'action_bar_directive': 'action-bar-approvals'
        }
      });
  
      $stateProvider.state('app.approvals.token-accept',{
        url: '/accept/:token',
        template: '<sow-token-accept></sow-token-accept>',
        data: {
          'title': 'Accept token',
          'require_log_in': false,
          'action_bar_directive': 'action-bar-approvals'
        },
        params: {
          mode: 'accept'
        }
      });

      $stateProvider.state('app.approvals.token-cancel',{
        url: '/cancel/:token',
        template: '<sow-token-cancel></sow-token-cancel>',
        data: {
          'title': 'Cancel token',
          'require_log_in': false,
          'action_bar_directive': 'action-bar-approvals'
        },
        params: {
          mode: 'cancel'
        }
      });

      $stateProvider.state('app.approvals.token-decline',{
        url: '/decline/:token',
        template: '<sow-token-decline></sow-token-decline>',
        data: {
          'title': 'Decline token',
          'require_log_in': false,
          'action_bar_directive': 'action-bar-approvals'
        },
        params: {
          mode: 'decline'
        }
      });
  
    }
})();

(function () {
  
  function ProductCP (ManufacturerCP, VendorInventoryItemCP) {
    const Manufacturer = ManufacturerCP.getClass();
    const VendorInventoryItem = VendorInventoryItemCP.getClass();
    /**
    *
    * @class Product
    * @typedef Product
    */
    class Product {
      constructor(json_data) {
        this.advertising_badges = json_data.advertising_badges;
        this.categories = json_data.categories;
        this.content_per_unit = json_data.content_per_unit;
        this.description = json_data.description;
        this.documents = json_data.documents;
        this.filters = json_data.filters;
        this.id = json_data.id;
        this.images = json_data.images;
        this.implant_inventory_status = json_data.implant_inventory_status;
        this.is_chat_enable = json_data.is_chat_enable;
        this.is_favourite_product = json_data.is_favourite_product;
        this.item_type = json_data.item_type;
        this.main_image = json_data.main_image;
        this.main_image_240_box = json_data.main_image_240_box;
        this.main_image_240_wide = json_data.main_image_240_wide;
        this.manufacturer = new Manufacturer(json_data.manufacturer);
        this.manufacturer_sku = json_data.manufacturer_sku;
        this.medication_inventory_status = json_data.medication_inventory_status;
        this.name = json_data.name;
        this.office_inventory_status = json_data.office_inventory_status;
        this.product_type = json_data.product_type;
        this.sds_url = json_data.sds_url;
        this.unit_name = json_data.unit_name;
        this.url_name = json_data.url_name;
        this.vendor_inventory = json_data.vendor_inventory?.map((item) => {
          return new VendorInventoryItem(item);
        });
      }

      id;
      get id () {
        return this.id;
      }
      
      name;
      get name () {
        return this.name;
      }      

      advertising_badges;
      get advertising_badges () {
        return this.advertising_badges;
      }

      categories;
      get categories () {
        return this.categories;
      }

      content_per_unit;
      get content_per_unit () {
        return this.content_per_unit;
      }

      description;
      get description () {
        return this.description;
      }

      
      documents;
      get documents () {
        return this.documents;
      }
      
      filters;
      get filters () {
        return this.filters;
      }
      
      images;
      get images () {
        return this.images;
      }
      
      implant_inventory_status;
      get implant_inventory_status () {
        return this.implant_inventory_status;
      }
      
      is_chat_enable;
      get is_chat_enable () {
        return this.is_chat_enable;
      }
      
      is_favourite_product;
      get is_favourite_product () {
        return this.is_favourite_product;
      }
      
      item_type;
      get item_type () {
        return this.item_type;
      }
      
      main_image;
      get main_image () {
        return this.main_image;
      }
      
      main_image_240_box;
      get main_image_240_box () {
        return this.main_image_240_box;
      }
      
      main_image_240_wide;
      get main_image_240_wide () {
        return this.main_image_240_wide;
      }
      
      manufacturer;
      get manufacturer () {
        return this.manufacturer;
      }
      
      manufacturer_sku;
      get manufacturer_sku () {
        return this.manufacturer_sku;
      }
      
      medication_inventory_status;
      get medication_inventory_status () {
        return this.medication_inventory_status;
      }
      
      office_inventory_status;
      get office_inventory_status () {
        return this.office_inventory_status;
      }
      
      product_type;
      get product_type () {
        return this.product_type;
      }
      
      sds_url;
      get sds_url () {
        return this.sds_url;
      }
      
      unit_name;
      get unit_name () {
        return this.unit_name;
      }
      
      url_name;
      get url_name () {
        return this.url_name;
      }
      
      vendor_inventory;
      get vendor_inventory () {
        return this.vendor_inventory;
      }
      
    }
    
    this.getClass = () => {
      return Product;
    }
  }
  
  angular.module('sowMarketplace')
  .service("ProductCP", ProductCP);
})();

(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.specialty_selector')
    .directive('specialtySelector', specialtySelectorDirective);

  function specialtySelectorDirective () {
    return {
      'restrict': 'E',
      'replace': true,
      'scope': {
        'medicalField': '=',
        'select': '&',
      },
      template: `
        <div class="specialty-selector" layout layout-wrap layout-align="center center">
          <div class="field-type" layout layout-wrap layout-align="center center" ng-repeat="specialty in medicalField.subtypes">
            <div flex="100" layout layout-align="center center">
              <div class="field-type-icon" ng-click="selectSpecialty(specialty)" name="specialtySelector-btn-{{specialty}}">
                <span class="png-icon inactive"><img ng-src="styles/img/branding/specialty-placeholder.png"></span>
                <span class="png-icon hover"><img ng-src="styles/img/branding/specialty-placeholder-selected.png"></span>
              </div>
            </div>
            <div class="centered-text text-nowrap" flex="100">
            {{'SPECIALTIES.'+specialty | translate}}
            </div>
          </div>
        </div>
      `,
      link: function ($scope) {
        $scope.selectSpecialty = function (specialty) {
          $scope.select({'specialty': specialty});
        };
      }
    };
  }
}());

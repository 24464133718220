angular.module("app.marketplace.ui.inventory")
  .controller("editInventoryItemController",
      function($scope, $filter, sgToast, $timeout, elementService, officeService, errorService, inventoryItemService,inventorySharingService) {
      var locations = [];

      $scope.item_clone = angular.copy($scope.item);

      if ($scope.item_clone.supplier_id) {
        $scope.item_clone.supplier = {
          'id': $scope.item_clone.supplier_id,
          'name': $scope.item_clone.supplier_name || $scope.item_clone.supplier,
        };
      }

      //Refresh item clone if source item is updated.
      $scope.$watch('item.updated_at', function(newItemUpdatedValue, oldVal){
        if(newItemUpdatedValue !== oldVal){
          $scope.item_clone = angular.copy($scope.item);
        }
      });
      $scope.$watch('item.UI.do_save', function(newSaveValue, oldVal){
        if(newSaveValue && $scope.inventoryItemForm && $scope.inventoryItemForm.$dirty && $scope.inventoryItemForm.$valid){
          $scope.saveEdit();
          $scope.item.UI.do_save = false;
        }
      });

      $scope.inventorySharingService = inventorySharingService;

      $scope.examples = [
        'Carbide Bur - HP 701 (Pack of 10)',
        'Velocity Nitrile Powder Free Gloves - Small (Box of 100)',
        'Monoject Dental Needle - 30G Extra Short Metal Hub (Box of 100)',
        'Filtek Z350 XT Flowable Restorative - A3 (2g Syringe)',
        'H-File #45 - 21mm (Pack of 6)',
        'Look Suture - Plain Gut 3-0 C7 (Box of 12)',
        'Self Sealing Sterilization Pouch - 2.5” x 6.5” (Box of 200)',
      ];

      $scope.tabs = {
        active: 'details',
        setActive: function(tab) {
          this.active = tab;
        },
        isActive: function(tab) {
          return this.active === tab ? 'active' : '';
        }
      };      

      if (!$scope.locations) {
        officeService
          .getInventory()
          .then(function(inventory){
            locations = angular.extend([], inventory.locations);
          }).catch(function(error){
            var t_message = $filter('translate')('ERRORS.INVENTORY_LOCATIONS_LOAD')
            errorService.uiErrorHandler(error || t_message, 0);
          });
      } else {
        locations = angular.extend([], $scope.locations);
      }

      //----------

      $scope.locationSearch = function (query) {
        if (!query) {
          return locations;
        }

        var results = [];

        _.map(locations, function(this_location){
          if (this_location.name.toLowerCase().indexOf(query.toLowerCase()) > -1) {
            results.push(this_location);
          }
        });

        return results;
      };

      //----------

      $scope.addLocation = function(location, locationName){
        if (!$scope.item_clone || (!locationName && (!location || !location.name))) {
          return false;
        }

        if (!$scope.item_clone.locations) {
          $scope.item_clone.locations = [];
        }

        location = location || {name: locationName};

        //
        // Check for a case-insensitive name match in pre-existing locations if
        // we were just passed a location name.
        //
        if (!location.id) {
          _.forEach(locations, function (loc) {
            if (loc.name.toLowerCase() === location.name.toLowerCase()) {
              location = loc;
              return false;
            }
          });
        }

        var existsOnItem = false;

        _.forEach($scope.item_clone.locations, function (loc) {
          if (location.id && loc.id === location.id) {
            existsOnItem = true;
            return false;
          }

          if (loc.name.toLowerCase() === location.name.toLowerCase()) {
            existsOnItem = true;
            return false;
          }
        });

        if (!existsOnItem) {
          $scope.item_clone.locations.push(location);
        }

        //
        // TODO This could get merged into the loop over $scope.locations
        // above.
        //
        var newLoc = true;
        _.map(locations, function(this_location) {
          if (this_location.name.toLowerCase() === location.name.toLowerCase()) {
            newLoc = false;
          }
        });

        if (newLoc) {
          locations.push(location);
        }

        // Clear input element text
        $scope.item_clone.locationSearchText = '';
        $timeout(function () {
          var inputEle = angular.element(document.getElementById("location-add-input"));
          if (inputEle) {
            inputEle.val('');
          }
        });
      };

      //----------

      $scope.addExpiryDate = function(){
        if($scope.item_clone.new_expiry_date){
          var newDate = true;
          _.map($scope.item_clone.expiry_dates, function(this_date, di){
            if(moment(new Date(this_date.date)).startOf('day').isSame(moment($scope.item_clone.new_expiry_date).startOf('day'))){
              newDate = false;
            }
          });
          if(newDate){
            $scope.item_clone.expiry_dates.push({date: $scope.item_clone.new_expiry_date});
          }
        }
        delete $scope.item_clone.new_expiry_date; //clear
      };

      //Workaround used to watch removals.
      $scope.$watch('item_clone.expiry_dates.length', function(newExpiryValue, oldVal){
        if(newExpiryValue!==oldVal && $scope.inventoryItemForm){
          $scope.inventoryItemForm.$setDirty();
        }
      });
      $scope.$watch('item_clone.locations.length', function(newLocationsValue, oldVal){
        if(newLocationsValue!==oldVal && $scope.inventoryItemForm){
          $scope.inventoryItemForm.$setDirty();
        }
      });

      //-------------------------
      $scope.manufacturerSearch = function(name) {
        if (!name) {
          return false;
        }

        return elementService.get("manufacturer", null, {"endpoint" : "search", "name" : name})
        .then(function (manufacturers) {
          var names = [];
          _.map(manufacturers, function(manufacturer){
            names.push(manufacturer.name);
          });
          return names;
        });
      };

      $scope.close = function(){
        $scope.toggleItem($scope.item);
      };

      $scope.cancelEdit = function(){
        $scope.showEditForm = false;
        $scope.item_clone = angular.copy($scope.item);
      };

      $scope.saveEdit = function(){
        $scope.submitting = true;
        $scope.inventoryItemForm.$setSubmitted(true);

        if ($scope.inventoryItemForm.$invalid) {
          $scope.submitting = false;
          return;
        }

        // Add location if it is in the inputfield but they did not press enter.
        if ($scope.item_clone.locationSearchText) {
          $scope.addLocation(null, $scope.item_clone.locationSearchText);
        }

        // Add expiration date if it is in the inputField but they did not press enter.
        if ($scope.item_clone.new_expiry_date) {
          $scope.addExpiryDate();
        }

        // This workaround allows users to add manufacturer names that are not
        // in the autocomplete list.
        $scope.item_clone.manufacturer_name = $scope.item_clone.manufacturer_name || $scope.item_clone.manufacturerSearchText;

        var tmpSupplier = $scope.item_clone.supplier || $scope.item_clone.supplierSearchText;
        $scope.item_clone.supplier = tmpSupplier && !_.isString(tmpSupplier) ? tmpSupplier.name : tmpSupplier;

        return inventoryItemService.updateItem($scope.item_clone).then(function (inventory_item) {
          $scope.submitting = false;
          var t_message = $filter('translate')('TOAST.INVENTORY_UPDATED');
          sgToast.showSimple(t_message);
          $scope.inventoryItemForm.$setPristine();
          $scope.toggleItem($scope.item);

          var masterLocationsList = $scope.$parent.locations;

          _.forEach($scope.item_clone.locations, function (location) {
            var index = _.findIndex(masterLocationsList, {'id': location.id});
            if (index === -1 && _.isArray(masterLocationsList)) {
              masterLocationsList.push(location);
            }
          });

          if ($scope.bundle) {
            $scope.$emit("editInventoryItemController: bundle-updated", $scope.bundle); // Allow for bundle refresh
          }
        }).catch(function (error) {
          $scope.submitting = false;
          var t_message = $filter('translate')('ERRORS.INVENTORY_UPDATE')
          errorService.uiErrorHandler(error || t_message, 0);
        });
      };

      if ($scope.registerItem) {
        $scope.registerItem($scope.item, $scope.saveEdit);
      }
  });

(function () {
  'use strict';

  angular
    .module('app.marketplace.ui.dashboard')
    .service('sowDashboardService', sowDashboardService);

  function sowDashboardService(serverAPI, apiUrl, $rootScope) {
    /*jshint validthis: true */
    var service = this;

    service.getSupplierSpendingStats = getSupplierSpendingStats;
    service.getCurrentInventoryValue = getCurrentInventoryValue;
    service.getSpendingByCategory = getSpendingByCategory;
    service.getItemsBySupplier = getItemsBySupplier;
    service.getItemsByLocation = getItemsByLocation;
    service.getLocations = getLocations;

    return service;

    function getOfficeId() {
      return $rootScope.current_office.id;
    }

    /**
     *
     * @method getSupplierSpendingStats
     *
     * @description get multi-office spending by supplier
     *
     * @param {*} filters an object with filter by year default to current
     *
     * @returns an object with offices and each spending by supplier
     *
     */
    function getSupplierSpendingStats (filters) {
      filters = filters || {};
      var url = '{0}/dashboard/supplier_spending'.format(apiUrl);
      var params = {
        'methods': 'GET',
        'params': _.pick(filters, ['year'])
      };

      return serverAPI
        .doAPICall(url, params)
        .then(function (response) {
          return response.data;
        });
    }

    /**
     *
     * @method getCurrentInventoryValue
     *
     * @description get multi-office current inventory value
     *
     * @returns an object with offices and each inventory value
     *
     */
    function getCurrentInventoryValue () {
      var url = '{0}/dashboard/current_inventory'.format(apiUrl);
      var params = { 'methods': 'GET' };

      return serverAPI
        .doAPICall(url, params)
        .then(function (response) {
          return response.data;
        });
    }

    /**
     *
     * @method getSpendingByCategory
     *
     * @description get multi-office spending by category
     *
     * @param {*} filters an object with filter by year default to current
     *
     * @returns an object with offices and each category spending
     *
     */
    function getSpendingByCategory (filters) {
      filters = filters || {};
      var url = '{0}/dashboard/spending_by_category'.format(apiUrl);
      var params = {
        'methods': 'GET',
        'params': _.pick(filters, ['year'])
      };

      return serverAPI
        .doAPICall(url, params)
        .then(function (response) {
          return response.data;
        });
    }

    function getItemsBySupplier (sid) {
      sid = sid || null;
      var office_id = getOfficeId();
      var url = '{0}/offices/{1}/inventory/dashboard_suppliers'.format(apiUrl, office_id);
      var params = {
        'methods': 'GET',
        'params': {'supplier_id': sid}
      };

      return serverAPI
        .doAPICall(url, params)
        .then(function (response) {
          return response.data;
        });
    }

    function getItemsByLocation (lid) {
      lid = lid || null;
      var office_id = getOfficeId();
      var url = '{0}/offices/{1}/inventory/dashboard_locations'.format(apiUrl, office_id);
      var params = {
        'methods': 'GET',
        'params': {'location_id': lid}
      };

      return serverAPI
        .doAPICall(url, params)
        .then(function (response) {
          return response.data;
        });
    }

    function getLocations () {
      var office_id = getOfficeId();
      var url = '{0}/offices/{1}/inventory/locations'.format(apiUrl, office_id);

      return serverAPI
        .doAPICall(url, {'methods': 'GET'})
        .then(function (response) {
          return response.data;
        });
    }


  }
}());
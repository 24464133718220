/*
 *  Controls and manages the error state.
 *
 */
angular.module('app.marketplace.system').controller('errorStateController',
  function errorStateController($rootScope, $state, RouteService) {
    if(!$rootScope.previousState && $rootScope.previousState!=="error"){
      //Redirect if no previous state. Aka refresh.
      RouteService.goToDefault();
    }
  }
);

(function () {
  'use strict';
  angular.module('sowFilters')
    .service('sowFilterService', sowFilterService);

  function sowFilterService($rootScope, $filter) {
    var service = this;

    service.getFilteredItems = getFilteredItems;
    service.getFilterStateFromData = getFilterStateFromData;
    service.toggleOption = toggleOption;
    service.deselectOption = deselectOption;
    service.clearAll = clearAll;
    service.triggerApplyFilters = triggerApplyFilters;
    var t_none = "({0})".format($filter('translate')('COMMON.NONE'));
    
    return service;

    /**
     * loops through `all_items` and checks for all possible values for the supplied `filter_groups`, then returns a stateful filtergroup object
     *
     * @param {Array} all_items
     * @param {Array} filter_groups
     * 
     * @return {FiltersState} 
     */
    function getFilterStateFromData (all_items, filter_groups) {
      // prevent object-like input (transforms into array)
      var items_list = _.values(all_items);
      // loop through all items
      _.each(items_list, function(item){
        // loop through all filters
        _.each(filter_groups, function(group){
          _sanitizeGroup(group);
          _updateGroup(group, item);
        });
      });
      // After setting up the groups, generate a sorted array
      // of option names so we can display them in alphabetical order
      _.each(filter_groups, function(group) {
        group.option_names = _.orderBy(Object.keys(group.options));
      })

      return filter_groups;
    }

    function _updateGroup (group, item) {
      var item_value = _.get(item, group.key);
      if(_.isNil(item_value)){
        item_value = t_none;
      }
      // if (!item_value) return;
      // this is supposed to account for locations array
      if (group.list_key && _.isArray(item_value)) {
        _.each(item_value, function(list_value){
          _updateOption(group, list_value[group.list_key], item);
        });
      } else {
        _updateOption(group, item_value, item);
      }
    }

    function _updateOption (group, value, item) {
      if(!group.options[value]){
        _createOption(group, value);
      }
      group.options[value].item_ids.push(item.id);
    }

    function _sanitizeGroup (group) {
      group.options = group.options || {};
      
    }

    function _createOption (group, value) {
      group.options[value] = {
        'active': false,
        // 'name': value,
        'item_ids': []
      };
    }

    function toggleOption (option, list, items) {
      option.active = !option.active;
    }

    function deselectOption (option) {
      option.active = false;
      triggerApplyFilters();
    }

    function _getUniqueIds (filter_groups) {
      var all_active_ids = [];
      _.each(filter_groups, function(group, group_index){
        all_active_ids[group_index] = [];
        
        // for every group (eg Manufacturer, category, location)
        // we store all IDs for the selected group, and then return an intersection of them
        // eg. items that are 
        // (MANUFACTURER:3M *OR* MANUFACTURER:PATTERSON) *AND* (CATEGORY:GLOVES *OR* CATEGORY:GAUZE) *AND* (LOCATION:OFFICE)
        // and so on with extra filter groups
        // PS: this might become an issue if there are no items that fulfill the current selected requirements

        _.each(group.options, function(option){
          if(option.active){
            all_active_ids[group_index] = _.concat(all_active_ids[group_index], option.item_ids);
          }
        });
      });

      // var ids_on_all_groups = angular.copy(all_active_ids[0]);
      var ids_on_all_groups = _.flattenDeep(all_active_ids);
      
      // tried getting the intersection between filter groups, that didn't work

      // _.each(all_active_ids, function(id_group, group_index){
      //   if(id_group.length){
      //     if (!ids_on_all_groups.length) {
      //       ids_on_all_groups = angular.copy(id_group);
      //     }
      //     ids_on_all_groups = _.intersection(ids_on_all_groups, id_group);

      //   }
      // });
      return ids_on_all_groups;
    }

    function getFilteredItems (items_map, filter_groups) {
      var ids = _getUniqueIds(filter_groups);
      var items;
      if(ids.length) {
        items = _.pick(items_map, ids);
      } else {
        items = items_map;
      }
      
      var list = _.values(items);

    
      var search_group = _.find(filter_groups, function(group){ return group.type === 'search';});
      if (search_group) {
        list = _applySearchFilter(list, search_group);
      }

      return list;
    }

    function _applySearchFilter (whole_list, search_group) {
      var list = [];
      if(!search_group.search_query){
        list = whole_list;
      } else {
        list = _.filter(whole_list, function(item){
          var st = _.lowerCase(search_group.search_query);
          var condition = false;
          _.each(search_group.search_properties, function(prop){
            condition = condition || _.includes(_.lowerCase(_.get(item, prop)), st);
          });
          return condition;
        });
      }
      return list;
    }

    function clearAll (filter_groups) {
      _.each(filter_groups, function(group){
        if(group.type === 'search'){
          group.search_query = '';
        }
        _.each(group.options, function(option){
          option.active = false;
        });
      });
      triggerApplyFilters();
    }

    function triggerApplyFilters () {
      $rootScope.$broadcast('gen-inv-apply-filters');
    }

  }
})();

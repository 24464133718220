angular.module('app.marketplace.elements')

.service('registrationModel', function (serverAPI, apiUrl) {
  var _this = this;


  this.model_data = {
    name: 'registration',
    display_name: 'Registration',
    api: {
      //
      // Request an Office Invitation
      //
      'request_office_invite': function (options) {
        if (!options.office_id) {
          throw 'Missing "office_id" for registration path "request_office_invite". Options: ' + JSON.stringify(options);
        }

        return {
          path: '{0}/offices/{1}/invitation_requests'.format(apiUrl, options.office_id),
          params: {
            method: 'POST',
            data: {}
          },
        };
      },

      'respond_to_invite': function (options) {
        if (!options.payload || !options.token) {
          throw 'Missing option(s) for registration path "respond_to_invite". Options: ' + JSON.stringify(options);
        }

        return {
          path: apiUrl + '/register/invitation/' + options.token,
          params: {
            method: 'POST',
            data: options.payload
          }
        };
      },

      //
      // Create a New Office
      //
      'create_office': function (options) {
        if (!options.office) {
          throw 'Missing "office" from options for registration path "create_office". Options: ' + JSON.stringify(options);
        }

        return {
          path: apiUrl + '/offices',
          params: {
            method: 'POST',
            data: options.office,
          }
        };
      },

      //
      // Endpoint to search for offices by name
      //
      'office_search': function (options) {
        if (!options.searchText) {
          throw 'Missing searchText for registration path "office_search". Options: ' + JSON.stringify(options);
        }
        return {
          path: apiUrl + '/offices/names',
          params: {
            method: 'GET',
            params: {name: options.searchText}
          }
        };
      },

      //
      // Endpoint to check if an email is already in use.
      //
      'check_email': function (options) {
        if (!options.email) {
          throw 'Missing option(s) for registration path "check_email". Options: ' + JSON.stringify(options);
        }
        return {
          path: apiUrl + '/users',
          params: {
            method: 'HEAD',
            params: {email: options.email}
          }
        };
      },

      get_user_invite: function (options) {
        if (!options.token) {
          throw 'Missing option(s) for registration path "get_user_invite". Options: ' + JSON.stringify(options);
        }
        return {
          path: apiUrl + '/user_invites/' + options.token,
          params: {
            method: 'GET'
          }
        };
      },

      register: function (options) {
        if (!options.data) {
          throw 'Missing option(s) for registration path "register". Options: ' + JSON.stringify(options);
        }

        return {
          path: apiUrl + '/register',
          params: {
            method: 'POST',
            data: options.data
          }
        };
      },

      verify_organization: function (options) {
        if (!options.organization || !options.identifier || !options.credentials) {
          throw 'Missing option(s) for registration path "verify_organization". Options: ' + JSON.stringify(options);
        }
        return {
          path: apiUrl + '/register/verify',
          params: {
            method: 'POST',
            data: {
              organization: options.organization,
              identifier: options.identifier,
              credentials: options.credentials
            }
          }
        };
      },

      confirm_email: function (options) {
        if (!options.token || !options.email) {
          throw 'Missing option(s) for registration path "confirm_email". Options: ' + JSON.stringify(options);
        }
        return {
          path: apiUrl + '/register/confirm',
          params: {
            method: 'POST',
            data: {
              token: options.token,
              email: options.email
            }
          }
        };
      },
    }
  };

  this.data = {
    params: {
      model_name: 'registration'
    }
  };
});

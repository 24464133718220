angular.module("app.marketplace.ui.shopping-aaoms",[])
.config(function($stateProvider, $urlRouterProvider) {
  $stateProvider
    .state("app.shopping_aaoms", {
      url: '/shopping_aaoms',
      templateUrl: "templates/marketplace/shopping-aaoms/shopping-aaoms.html",
      controller: "shoppingController" ,
      data: {
        title: 'Marketplace',
        action_bar_directive: 'action-bar-shopping-aaoms'
      },
      redirectTo: 'app.shopping_aaoms.soon' // Redirecting via $urlRouterProvider can cause template scope digest issues.
    })

    .state('app.shopping_aaoms.soon', {
      url: '/soon',
      templateUrl: 'templates/marketplace/shopping-aaoms/shopping-soon.html'
    });
});

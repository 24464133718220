(function () {
  'use strict';

  angular
    .module('sowUserAdmin')
    .service('sowUserAdminService', sowUserAdminService);

  function sowUserAdminService ($q, officeService, serverAPI, apiUrl) {
    /*jshint validthis: true */
    var service = this;

    service.getAllUsers = getAllUsers;
    service.getAllInvites = getAllInvites;
    service.getAllUsersAndInvites = getAllUsersAndInvites;
    service.getUser = getUser;
    service.sendInvite = sendInvite;
    service.deleteUser = deleteUser;
    service.updateUser = updateUser;
    service.disableUser = disableUser;

    return service;

    /**
     * A simple convenience wrapper around the call to send the request to the
     * API. It makes the functions simple than having this code block
     * duplicated everywhere.
     */
    function _callAPI (url, params) {
      return serverAPI
        .doAPICall(url, params)
        .then(function (response) {
          return response.data;
        });
    }

    /**
     * Generate a full API url. Since the prefix of these urls requires extra
     * work (pulling in and inserting the office id), it makes sense for us to
     * centralize that bit.
     */
    function _getUrl (path) {
      path = path || '';
      return '{0}/offices/{1}{2}'.format(apiUrl, sow.officeInfo().id, path);
    }

    function getAllUsers () {
      var filters = {};
      var url = _getUrl('/users');
      var params = {'method': 'GET', 'params': filters};
      return _callAPI(url, params);
    }

    function getAllInvites () {
      var filters = {};
      var url = _getUrl('/invites');
      var params = {'method': 'GET', 'params': filters};
      return _callAPI(url, params);
    }

    function getAllUsersAndInvites () {
      return $q.all([
        getAllUsers(),
        getAllInvites()
        ])
      .then(function(results){
        return _.concat(results[0], results[1]);
      });
    }

    function getUser (id) {
      var filters = {'id':id};
      var url = _getUrl("/users".format(id));
      var params = {'method': 'GET', 'params': filters};
      return _callAPI(url, params)
      .then(function(response){
        return _.first(response);
      });
    }

    function sendInvite (data) {
      var url = _getUrl('/invites');
      var params = {'method': 'POST', 'data': data};
      return _callAPI(url, params);
    }

    function updateUser (user_data, office) {
      user_data = _.pick(user_data, ['user_permissions','membership_type','title']);
      var url = '{0}/offices/{1}/users/{2}'.format(apiUrl, office.id, office.office_membership_id);
      var params = {'method': 'PUT', 'data': user_data};
      return _callAPI(url, params);
    }

    function disableUser (user_data, office) {
      // user_data = _.pick(user_data, ['user_permissions','membership_type','title']);
      var url = '{0}/offices/{1}/users/{2}'.format(apiUrl, office.id, office.office_membership_id);
      var params = {'method': 'DELETE'};
      return _callAPI(url, params);
    }

    function deleteUser (data) {
      var url = _getUrl('/users/{0}'.format(data.id));
      var params = {'method': 'DELETE'};
      return _callAPI(url, params);
    }

  }

}());
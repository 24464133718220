angular.module("app.shared.support")
  // Use inbox-style-Item-content below an inbox-style-Item to provide a heading containing HTML
  .directive('inboxStyleItemContent', function ($timeout) {
    return {
      transclude: true,   // Grab the contents to be used as the heading
      template: '',       // In effect remove this element!
      replace: true,
      require: '^inboxStyleItem',
      link: function (scope, element, attrs, controller, transclude) {
        controller.scope.$watch('open', function (newOpenValue) {
          if (newOpenValue) {
            controller.setContent(transclude(scope, angular.noop));
          }
        });
      }
    };
  });

(function () {
  'use strict';

  angular.module('sowMedications')
  .controller('regularDialogController', regularDialogController);

  function regularDialogController ($scope, $mdDialog) {
    var ctrl = this;

    ctrl.answer = $scope.answer = answer;
    ctrl.hide = $scope.hide = hide;
    ctrl.cancel = $scope.cancel = cancel;

    return ctrl;

    function hide () {
      $mdDialog.hide();
    };

    function cancel () {
      $mdDialog.cancel();
    };

    function answer (option) {
      $mdDialog.hide(option);
    };
  }
}());

angular.module('app.marketplace.ui.legal', [])

.config(function ($stateProvider, $urlRouterProvider) {
  $stateProvider
    .state('app.legal', {
      'url': '/legal',
      'templateUrl': 'templates/marketplace/legal/index.html',
      'data': {
        'action_bar_directive': 'legal-action-bar',
      }
    })
    .state('app.legal.terms', {
      'url': '/terms',
      'templateUrl': 'templates/marketplace/legal/terms.html'
    })
    .state('app.legal.privacy', {
      'url': '/privacy',
      'templateUrl': 'templates/marketplace/legal/privacy-policy.html'
    })
  ;
})

;

(function () {
  'use strict';
  angular.module('sowSterilization')
    .controller('SterilizationIndexController', SterilizationIndexController);

  function SterilizationIndexController() {
    /*jshint validthis: true */
    var ctrl = this;

    ctrl.FEATURE_DATA = {
      'section': {
        'name': 'MAIN_MENU.STERILIZATION',
        'icon': { 'material': 'grain', 'css': 'blue', },

      },
      'plan': {
        'label': 'PAYWALL.PREMIUM_FEATURE',
        'css': 'premium',
      },
      'selling_points': [
        {
          'header': 'STERILIZATION.PW_HEADER_ONE',
          'text': 'STERILIZATION.PW_TEXT_ONE',
          'img': "styles/img/paywalls/1-check-purple"
        },
        {
          'header': 'STERILIZATION.PW_HEADER_TWO',
          'text': 'STERILIZATION.PW_TEXT_TWO',
          'img': "styles/img/paywalls/2-app"
        }
      ]
    };

    return ctrl;
  }
})();
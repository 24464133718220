(function () {
  'use strict';
  angular.module('sowInventory')
    .controller('genInvFilterController', genInvFilterController)
    .directive('inventoryFilterSlideout', inventoryFilterSlideout);

  function inventoryFilterSlideout() {
    return {
      restrict: 'E',
      templateUrl: 'sow-inventory/slideouts/inventory-filter-slideout.html',
      controller: 'genInvFilterController',
      controllerAs: 'filterCtrl',
      scope: true
      // scope: {
      //   groups: '=',
      //   items: '=',
      //   list: '='
      // }
    };
  }

  function genInvFilterController($rootScope, $mdSidenav, sowFilterService) {

    var CID = 'gen-inv-filters';

    var ctrl = {};

    ctrl.close = close;
    ctrl.toggleGroup = toggleGroup;
    ctrl.toggleOption = toggleOption;
    ctrl.deselectOption = sowFilterService.deselectOption;
    ctrl.clearAll = sowFilterService.clearAll;

    init();

    return ctrl;

    function init() {
      $rootScope.$on('gen-inv-filters-open', open);
      // $rootScope.$on('filters-set-data', updateFilterData);
    }

    function open() {
      $mdSidenav(CID).open();
    }

    function close() {
      $mdSidenav(CID).close();
    }

    function toggleGroup(group) {
      group.open = !group.open;
      // broadcast state to list page (maybe use rootscope?)
    }

    function toggleOption (option) {
      // option.active = !option.active;
      sowFilterService.triggerApplyFilters();
    }

  }

})();



angular.module('app.marketplace.ui.implants')

.directive('sowSlideoutAlertsEditImplant', function() {
  return {
    transclude: true,
    restrict: 'E',
    scope: {
      item: '=',
      removeItem: '&onRemove'
    },
    bindToController: true,
    templateUrl: 'templates/marketplace/implants/directives/alerts-edit-slideout.html',
    controllerAs: 'ctrl',
    controller: 'impAlertEditSlideoutController',
  };
})

.controller('impAlertEditSlideoutController', impAlertEditSlideoutController);

      function impAlertEditSlideoutController($scope, $rootScope,  $filter, $mdDialog, $mdSidenav, sgToast, errorService, inventoryItemService, impHelperService, sowImplantService, AddLocationsDialog, alertsDataCarrierService, membershipService, AccessService, PatientService, PatientHelperService, mktHelperService ) {

      var ctrl = this;

      ctrl.table_types = {
        "Implant": {
          product_name: "Implant", title: "Implants", short_name: 'implants',
          icon: { svg: "styles/img/implants/implants-icon-v3.svg", color: "blue", },
        },
        "Closure Cap": {
          product_name: "Closure Cap", title: "Cover Screws", short_name: 'caps',
          icon: { svg: "styles/img/implants/caps-icon.svg", color: "purple", },
        },
        "Healing Abutment": {
          product_name: "Healing Abutment", title: "Healing Abutments", short_name: 'abutments',
          icon: { svg: "styles/img/implants/healing-icon.svg", color: "orange", },
        },
        "Bone Graft": {
          product_name: "Bone Graft", title: "Bone Grafts", short_name: 'grafts',
          icon: { material: "grain", color: "green3", },
        },
        "Membrane": {
          product_name: "Membrane", title: "Membranes", short_name: 'membranes',
          icon: { material: "healing", color: "red-pink", },
        },
      };

      ctrl.alertsDataCarrierService = alertsDataCarrierService;

      ctrl.add_to_shopping_list = add_to_shopping_list;

      ctrl.imp_expiry_years = impHelperService.generateExpiryYears();

      ctrl.addLocations = AddLocationsDialog.show;

      ctrl.update_item = update_item;

      ctrl.delete_item = delete_item;

      ctrl.close_dialogs = close_dialogs;
      ctrl.shouldHideShoppingListButton = shouldHideShoppingListButton;
      ctrl.searchPatients = searchPatients;
      ctrl.selectedPatientRemovalChanged = selectedPatientRemovalChanged;

      ctrl.$onInit = init;

      return ctrl;

      function init(params) {
        load_locations();
        defineLocks();


        $scope.$on('open-edit-implant-slideout', handleOpen);

        // Close the slideout when the shopping list is updated
        $scope.$on('shopping_list_updated', () => {
          close_dialogs('alert-edit-implant');
        });
      }


      /**
       * @method  add_to_shopping_list
       * @description add the item to the shopping list
       * @param {*} item
       */

      function add_to_shopping_list(item){
        if (ctrl.has_multi_supplier_selection && ctrl.product_id) {
          return mktHelperService.productDetailsFetch(ctrl.product_id, {
            action: 'ADD_TO_SHOPPING_LIST',
            item: {
              id: item.inventory_group.inventory_item_id,
            }
          });
        }
        inventoryItemService.addToShoppingList([{id: item.inventory_group.inventory_item_id}])
          .then(function(){
            var text = $filter('translate')('COMMON.ADDED_TO_SL');
      sgToast.showSimple(text);
          }).catch(function (err) {
            var t_message = $filter('translate')('ERRORS.SHOPPING_LIST_ADD')
            errorService.uiErrorHandler(t_message);
          });
      }

      /**
       * @method load_locations
       * @description load inventory locations
       */

      function load_locations () {
        var locals = null;
        if (ctrl.inventory_locations) {
          locals = angular.copy(ctrl.inventory_locations);
        }

        sowImplantService.getInventoryLocations()
          .then(function(response){
            ctrl.inventory_locations = _.sortBy(response,['name']);
            if (locals) {
              var new_location = _.first(_.differenceBy(angular.copy(ctrl.inventory_locations), locals, 'id'));
              if (new_location) {
                _.set(ctrl.item, "location_id", new_location.id);
              }
            }
          });
      }

      /**
       * @method update_item
       * @description save the updated item
       */

      function update_item() {
        
        sowImplantService.saveInventoryItem(ctrl.item)
        .then(function(response){
          ctrl.item.expiry_date = moment().year(ctrl.item.expiry_year).month(ctrl.item.expiry_month);
          ctrl.item.expired_passed_time = ctrl.item.expiry_date.fromNow();
          var location_obj = ctrl.inventory_locations.find(function (location) {
            return location.id === ctrl.item.location_id;
          });
          if(ctrl.item.location_id){
            ctrl.item.location_name = location_obj.name;
          }
          
          // alertsDataCarrierService.load_alerts();

          var t_message = $filter('translate')('TOAST.CHANGES_SAVED');
          sgToast.showSimple(t_message);
          $rootScope.$broadcast('implant-alert-edited', ctrl.item);
          close_dialogs('alert-edit-implant');
        });
      }

      /**
       * @method close_dialogs
       * @description close slideout based on name
       */
      function close_dialogs(name) {
        $mdSidenav(name).close();
      }

      /**
       * @method  delete_item
       */
      function delete_item(inventory_item) {
        $mdDialog.show({
          controller: 'poDialogController',
          controllerAs: 'dialogCtrl',
          templateUrl: 'templates/marketplace/implants/modals/imp-delete-warning.html',
          parent: angular.element(document.body),
          clickOutsideToClose: false,
          locals: {
            product_name: ctrl.table_types[ctrl.item.inventory_group.catalog_item.category].product_name,
            manufacturer_name: inventory_item.manufacturer_name,
            item: {
              lot_number: inventory_item.lot_number,
              ref_number: inventory_item.inventory_group.reference_no,
              expiry: inventory_item.expiry_year +'-'+ $filter('leftpad')(inventory_item.expiry_month,2),
            },
          },
          bindToController: true,
        })
        .then(function (answer) {
          if (answer) {
            sowImplantService.removeInventoryItem(inventory_item.id)
            .then(function(response){
              ctrl.removeItem({item: inventory_item});
              var t_message = $filter('translate')('TOAST.DELETED');
              sgToast.showSimple(ctrl.table_types[ctrl.item.inventory_group.catalog_item.category].product_name +t_message);
              // alertsDataCarrierService.load_alerts();
              close_dialogs('alert-edit-implant');
            }).catch(function(error){
              var t_message = $filter('translate')('ERRORS.SERVER_TRY_AGAIN')
              errorService.uiErrorHandler(t_message);
            });
          }
        });
      }

      /**
       * Searches for patients in the API and returns an array of parsed patient objects.
       * Triggered by the autocomplete element when typing patient names or IDs into it.
       * 
       * @param {string} query_term
       * @return {Promise<ParsedPatient[]>}
       */
      function searchPatients () {
        const query_term = ctrl.patient_search_text || '';
        return PatientService.searchPatients(query_term).then((response) => {
          return _.map(response.patients, (patient) => PatientHelperService.parsePatient(patient));
        });
      }

      function selectedPatientRemovalChanged () {
        ctrl.item.reservation_id = ctrl.selected_patient_removal?.id ?? null;
      }

      /**
       * Gets the patient info from the API and populates the patient removal field.
       * @param {object} _event
       * @param {object} selected_item 
       */
      function handleOpen(_event, selected_item)  {
        ctrl.patient_search_text = '';
        ctrl.selected_patient_removal = null;
        if (ctrl.has_pwf_access && selected_item.reservation_id) {
          ctrl.loading_patient = true;
          PatientService.getPatient(selected_item.reservation_id)
            .then((response) => {
              const patient = PatientHelperService.parsePatient(response);
              ctrl.selected_patient_removal = patient;
              selectedPatientRemovalChanged()
            }).finally(() => {
              ctrl.loading_patient = false;
            })
        }

        /* If the item can be added to the shopping list and the
        user has multi-supplier selection enabled, load the
        product ID of the item so we can open the product details
        slideout if they elect to add it to the shopping list */
        if (!shouldHideShoppingListButton() && ctrl.has_multi_supplier_selection) {
          ctrl.is_loading_product_id = true;
          const membership = membershipService.get();
          const marketplace_id = membership.current_marketplace.id;
          const office_id = membership.office.id;
          const {manufacturer_id, reference_no} = selected_item.inventory_group;
          sowImplantService.getMktProductID(marketplace_id, office_id, manufacturer_id, reference_no)
            .then(({data}) => {
              ctrl.product_id = data;
            })
            .finally(() => {
              ctrl.is_loading_product_id = false;
            });
        }
      }

      /**
       * Determines if shopping list button should be hidden based on:
       * 1. If the office has disabled shopping list
       * 2. If the implant is discontinued (item.UI.is_discontinued)
       * @returns {boolean}
       */
      function shouldHideShoppingListButton() {
        // When the slideout is closed the ctrl.item is undefined
        const is_item_discontinued = ctrl.item?.UI.is_discontinued;

        return ctrl.disable_shopping_list || is_item_discontinued;
      }
      
        function defineLocks() {
          const membership = membershipService.get();

          const delete_lock_condition = _.get(membership, 'user_properties.implants.disable_delete', false);
          ctrl.delete_locked = delete_lock_condition;

          ctrl.disable_shopping_list = !AccessService.hasShoppingListAccess();

          const disable_price_edit_condition = _.get(membership, 'user_properties.implants.disable_price', false);
          ctrl.disable_price_edit = disable_price_edit_condition;

          ctrl.has_pwf_access = AccessService.hasPmsIntegration();
          ctrl.has_multi_supplier_selection = AccessService.getProperty('shopping_list.multi_supplier_selection');
        }

}
